//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";

// UTILS
import { Toast } from "../../../../utils/toast";

//STYLES
import "./change-sla-meta.scss";

//COMPONENTS
import { Modal } from "../..";
import { Button, Typography } from "../../../general";
import { Input } from "../../../data-entry";

//SERVICES - api, conectors...
import * as APIDashboard from "../../../../services/api/dashboard";

//GLOBAL STATE - redux, env...
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../Loading";

//ASSETS - icons, images...

export default function ChangeSLAMeta({
  isOpen,
  onClose,
  onCreate,
  valueBase,
  darkMode,
}) {
  //REDUX - Selectors
  const token = useSelector((state) => state.auth.token);

  //GENERAL
  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [value, setValue] = useState(valueBase);

  //FUNCTIONS
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleChangeSla() {
    setPageStatus("change-sla");
    const response = await APIDashboard.changeSlaMeta(token, value);

    if (response.status === 200) {
      const data = response.data;

      console.log("RESULTADO", data);
      await onCreate();
      onCloseCustom();
      setPageStatus("awaiting");
    } else {
      Toast(
        "Falha, não foi possível alterar o SLA!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  function onCloseCustom() {
    onClose();
    setValue(value);
  }

  //USE EFFECTS

  return (
    <Modal.Main
      className={`modal-change-sla ${darkMode ? "dark-mode" : ""}`}
      title="Editar SLA"
      isOpen={isOpen}
      onClose={onCloseCustom}
      width="704px"
      footer={
        <>
          <Button
            className="modal-change-sla__btn-cancel"
            onClick={onCloseCustom}
            disabled={pageStatus === "creating-case"}
          >
            <Typography.H4 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H4>
          </Button>

          <Button
            className="modal-change-sla__btn-confirm"
            onClick={() => handleChangeSla()}
            disabled={pageStatus === "creating-case"}
          >
            {pageStatus !== "change-sla" ? (
              <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
            ) : (
              <Loading.Dots width={70} height={70} />
            )}
          </Button>
        </>
      }
    >
      <section className="modal-change-sla__content">
        <Typography.H5 color={darkMode ? "white" : "gray-180"}>
          Digite ou selecione o SLA
        </Typography.H5>
        <Input type="number" value={value} onChange={setValue} ma />

        <Slider
          aria-label="SLA"
          valueLabelDisplay="auto"
          value={value}
          onChange={handleSliderChange}
          marks={marks}
        />
      </section>
    </Modal.Main>
  );
}
