//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APISla from "./../../../../services/api/sla";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES

import "./delete-sla.scss";

export default function DeleteSLA({
  isOpen,
  onClose,
  deletedSlaId,
  slaData,
  handleTableDataLookup,
  returnToSlaList = false,
  onDelete
}) {
  //REDUX - Selectors
  const token = useSelector((state) => state.auth.token);

  //GENERAL

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [deletedSla, setDeletedSla] = useState(false);

  //FUNCTIONS
  function onCloseCustom() {
    onClose();
  };

  async function handleDeleteSla() {
    setPageStatus("delete-sla");

    try {
      const response = await APISla.deleteSla(deletedSlaId, token)

      if (response.status === 200) {
        onDelete();
        onCloseCustom();
        Toast(
          "SLA excluído!",
          "SLA excluído com sucesso.",
          "success"
        );

        setDeletedSla(true);

        if (returnToSlaList) {
          returnToSlaList()
        }
      } else {
        const responseData = await response.json();
        throw new Error(responseData.message || "Falha ao excluir o SLA.");
      }
    } catch (error) {
      console.error("Erro ao excluir o SLA:", error);

      Toast(
        "Não foi possível excluir o SLA!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    } finally {
      setPageStatus("awaiting");
    }
  };

  //USE MEMOS

  //USE EFFECTS 
  useEffect(() => {
    if (deletedSla) {
      handleTableDataLookup()
    }
  }, [deletedSla, slaData]);

  return (
    <Modal.Main
      className="modal-delete-sla"
      title="Excluir SLA"
      width="443px"
      height="280px"
      titleColor={"black"}
      isOpen={isOpen}
      onClose={onCloseCustom}
      shouldCloseOnOverlayClick={false}
      headerContainerStyle={{ alignItems: "center" }}
      icon={
        <div className="modal-delete-sla__icon">
          <TrashIcon />
        </div>
      }
      footer={
        <div className="container-footer">
          <Button
            onClick={() => onCloseCustom()}
            className="modal-delete-sla__btn-cancel"
            disabled={pageStatus === "delete-sla"}
          >
            <Typography.H4 weight={"bold"} color="blue-selected-4">
              Cancelar
            </Typography.H4>
          </Button>

          <Button
            className="modal-delete-sla__btn-confirm"
            disabled={pageStatus === "delete-sla"}
            onClick={handleDeleteSla}
          >
            {pageStatus !== "delete-sla" ? (
              <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
            ) : (
              <Loading.Dots />
            )}
          </Button>
        </div>
      }
    >
      <Typography.H4
        color="gray-180"
        weight="400"
        className="information-text"
      >
        Você tem certeza que deseja excluir? Ao excluir todos os dados, serão excluídos e esta ação não poderá ser desfeita.
      </Typography.H4>
    </Modal.Main>
  )
}

