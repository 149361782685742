import React, { useEffect, useState } from "react";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import BoxStatus from "../BoxStatus";
import { Typography } from "../../general";
import { BarChart } from "../../data-graphics";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as FileSearching } from "../../../assets/icons/File-searching.svg";

const jsonStatus = [
  {
    title: "Concluídos",
    color: "green",
  },
  {
    title: "Cancelado",
    color: "gray",
  },
  {
    title: "Pendente",
    color: "yellow",
  },
  {
    title: "Em andamento",
    color: "blue",
  },
  {
    title: "Escalonado",
    color: "orange",
  },
];

export default function CardActivity({ card, darkMode }) {
  //GENERAL

  //STATES
  const [status, setStatus] = useState(jsonStatus);
  const [dataState, setDataState] = useState([]);

  //REDUX - Selectors

  //FUNCTIONS

  return (
    <div className="card-dashboard__activity">
      <Typography.H4 color={darkMode ? "white" : "gray-180"} weight={700}>
        {card.title}
      </Typography.H4>

      <div className="card-dashboard__activity-box">
        {card.emptySpace ? (
          <div className="card-dashboard-emptySpace">
            <FileSearching width={120} height={120} />
            <Typography.H5 color={darkMode ? "white" : "gray-180"}>
              Oops! A sua pesquisa não gerou resultados.
            </Typography.H5>
          </div>
        ) : (
          <BarChart
            darkMode={darkMode}
            data={card.data}
            keys={[
              "concluidos",
              "cancelado",
              "pendente",
              "em andamento",
              "escalonado",
            ]}
          />
        )}
      </div>

      {!card.emptySpace && (
        <div className="card-dashboard__activity-reasons">
          {status.map((todo) => {
            return (
              <div className="card-dashboard__activity-reasons__reason">
                <BoxStatus color={todo.color} />
                <Typography.H6 color={darkMode ? "white" : "gray-180"}>
                  {todo.title}
                </Typography.H6>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
