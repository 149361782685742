//GLOBAL - components from npm
import React, { useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";

//STYLES
import "./audio.scss";

//COMPONENTS
import { Typography } from "../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import urlIngWeb from "../../../utils/env";

//ASSETS - icons, images...
import { ReactComponent as Play } from "../../../assets/icons/PlayIcon.svg";
import { ReactComponent as Pause } from "../../../assets/icons/PauseIcon.svg";

export default function Audio({ content, link, audio }) {
  //GENERAL
  const ref = useRef();

  //STATES
  const [velocityAudio, setVelocityAudio] = useState(1);
  const [showTime, setShowTime] = useState(true);
  const [openVelocity, setOpenVelocity] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const increaseSpeed = () => {
    if (velocityAudio === 1) {
      setVelocityAudio(1.5);
      ref.current.audio.current.playbackRate = 1.5;
    } else if (velocityAudio === 1.5) {
      setVelocityAudio(2);
      ref.current.audio.current.playbackRate = 2;
    } else {
      setVelocityAudio(1);
      ref.current.audio.current.playbackRate = 1;
    }
  };

  function getDuration() {
    ref.current.audio.current.currentTime = 0;
    ref.current.audio.current.removeEventListener("timeupdate", getDuration);
  }

  function handlePlay() {
    setShowTime(false);
    setOpenVelocity(true);
  }

  function handleEnded() {
    setShowTime(true);
    setOpenVelocity(false);
  }

  //USE EFFECTS

  return (
    <AudioPlayer
      layout="horizontal-reverse"
      ref={ref}
      src={`${audio ? link : `${urlIngWeb}${link}`}`}
      onLoadedData={() => {
        if (ref.current.audio.current.duration === Infinity) {
          ref.current.audio.current.currentTime = 1e101;
          ref.current.audio.current.addEventListener("timeupdate", getDuration);
        }
      }}
      autoPlayAfterSrcChange={false}
      showJumpControls={false}
      onPlay={() => handlePlay()}
      onEnded={() => handleEnded()}
      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
      customProgressBarSection={[
        RHAP_UI.PROGRESS_BAR,
        `${showTime && RHAP_UI.DURATION}`,

        openVelocity && (
          <button
            className="rhap_additional-controls"
            onClick={() => increaseSpeed()}
          >
            <Typography.H5 color="blue-selected-4" weight="500">
              {velocityAudio.toFixed(1)}x
            </Typography.H5>
          </button>
        ),
        <div>{content}</div>,
      ]}
      customIcons={{
        play: <Play />,
        pause: <Pause />,
      }}
      // other props here
    />
  );
}
