//GLOBAL - components from npm
import React from "react";

//STYLES
import "./status-symbol.scss";
//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconStatusCanceled } from "./../../../assets/icons/StatusCanceled.svg";
import { ReactComponent as IconStatusComplete } from "./../../../assets/icons/StatusComplete.svg";
import { ReactComponent as IconStatusInProgress } from "./../../../assets/icons/StatusInProgress.svg";
import { ReactComponent as IconStatusPending } from "./../../../assets/icons/StatusPending.svg";
import { ReactComponent as IconStatusScaled } from "./../../../assets/icons/StatusScaled.svg";
import { ReactComponent as IconStatusNew } from "./../../../assets/icons/StatusNew.svg";

export default function StatusSymbol({ id = 1 }) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS
  function renderStatus() {
    switch (id) {
      case 1:
        return <IconStatusInProgress />;
      case 2:
        return <IconStatusPending />;
      case 3:
        return <IconStatusComplete />;
      case 4:
        return <IconStatusCanceled />;
      case 5:
        return <IconStatusScaled />;
      case 6:
        return <IconStatusNew />;

      default:
        break;
    }
  }

  //USE EFFECTS

  return <div className={`status-symbol`}>{renderStatus()}</div>;
}
