//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { cloneDeep } from "lodash";
import { sub, endOfDay } from "date-fns";

// UTILS
import { Toast } from "./../utils/toast";
import getLocalStorage from "./../utils/getLocalStorage";

//STYLES
import "../styles/main.scss";

// HOOKS
import useQuery from "./../hooks/useQuery";

//COMPONENTS
import Pages from "./../pages";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { Loading } from "../components/feedback";
import { BrowserCommsAPI } from "../components/other";
import Header from "../components/header";
import NavBar from "../components/nav-bar";

//SERVICES - api, conectors...
import * as APIAuth from "../services/api/auth";
import * as APICase from "../services/api/case";
import * as APIMimetypes from "../services/api/mimetypes";

//GLOBAL STATE - redux, env...
import { useDispatch, useSelector } from "react-redux";
import {
  doLogin,
  doLogout,
  doExternalLogin,
  doProfilePermissions,
} from "./../store/auth";
import {
  setTableFilter,
  setInitialSort,
  setVariableTableFilter,
} from "./../store/tableFilters";
import { setStorageFilter, setFilter } from "./../store/filters";

// ASSETS

export default function Routes() {
  //GENERAL
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const exhibColumn = useSelector((state) => state.tableFilters.exhibColumn);
  const advancedsCases = useSelector((state) => state.filters.advancedsCases);
  const fieldsAdditional = useSelector(
    (state) => state.filters.fieldsAdditional
  );

  // LOCAL STORAGE
  const token = getLocalStorage("token", null);
  const externalAccess = getLocalStorage("externalAccess", null);
  const columnExhib = getLocalStorage("columnExhib", null);
  let casesFilter = getLocalStorage("casesFilter", null);
  let advancedsFilter = getLocalStorage("advancedsFilter", null);
  let additionalFields = getLocalStorage("additionalFields", null);
  let exhibVariable = getLocalStorage("exhibVariable", null);
  let dashboardFilters = getLocalStorage("dashboardFilters", null);
  // QUERYS
  // http://www.ingdesk.com.br/?login-master=XXXXXX&login-user=XXXXXX&password-master=XXXXXX
  const loginMasterQuery = query.get("login-master");
  const loginUserQuery = query.get("login-user");
  const passwordMasterQuery = query.get("password-master");
  const applyFilter = query.get("filters-allow");

  //STATES
  const [pageStatus, setPageStatus] = useState("requesting");
  const [getPermissions, setGetPermissions] = useState(false);
  const [tokenExternal, setTokenExternal] = useState("");

  // FUNCTIONS
  async function checkToken() {
    setPageStatus("requesting");

    const response = await APIAuth.checkSession(token);

    if (response.status === 200) {
      getProfilePermissions(response.data.dados[0].id_perfil);
      await getListAdditionalFields();

      if (!externalAccess) {
        dispatch(
          doLogin({
            token: token,
            userData: response.data.dados[0],
          })
        );
      }

      // getInfosGeneral(response.data.dados[0].id_perfil);

      if (externalAccess) {
        dispatch(
          doExternalLogin({
            payload: true,
            localStorageKey: "externalAccess",
            localStorageValue: true,
          })
        );
      }

      applyGeneralFilters();
    } else {
      dispatch(doLogout());
    }

    setPageStatus("awaiting");
  }

  async function doQueryLogin() {
    setPageStatus("requesting");

    const externalLoginData = {
      login_master: loginMasterQuery,
      login_user: loginUserQuery,
      password_master: passwordMasterQuery,
    };

    const response = await APIAuth.loginMasterV2(externalLoginData);

    if (response.status === 401) {
      Toast(
        "Dados incorretos!",
        "Verifique seus dados e tente novamente",
        "error"
      );
      history.push("/externo/login");
    } else if (response.status === 200) {
      setTokenExternal(response.data.token);
      dispatch(
        doExternalLogin({
          payload: true,
          localStorageKey: "externalAccess",
          localStorageValue: true,
        })
      );
      await getInfosGeneral(response.data.dados[0].id_perfil);
      dispatch(
        doLogin({
          token: response.data.token,
          userData: response.data.dados[0],
          localStorageKey: "token",
          localStorageValue: response.data.token,
        })
      );

      // await getProfilePermissions(response.data.dados[0].id_perfil);
      // await getListAdditionalFields();

      // if (columnExhib) {
      //   let newColumns = cloneDeep(exhibColumn);
      //   for (const column of columnExhib) {
      //     if (column && Object.keys(column).length > 0) {
      //       const findColumn = newColumns.find(
      //         (columnEx) => columnEx.title === column.title
      //       );
      //       if (findColumn) {
      //         findColumn.exhib = column.exhib;
      //       }
      //     }
      //   }

      //   dispatch(
      //     setTableFilter({
      //       value: newColumns,
      //       localStorageKey: "columnExhib",
      //       localStorageValue: newColumns,
      //     })
      //   );
      // }

      // if (casesFilter) {
      //   if (
      //     casesFilter.dateEnd ||
      //     casesFilter.dateStart ||
      //     casesFilter.title ||
      //     casesFilter.status.length > 0
      //   ) {
      //     let newCasesFilter = casesFilter;
      //     if (casesFilter.dateStart) {
      //       newCasesFilter = {
      //         ...newCasesFilter,
      //         dateStart: new Date(casesFilter.dateStart),
      //       };
      //     }
      //     if (casesFilter.dateEnd) {
      //       newCasesFilter = {
      //         ...newCasesFilter,
      //         dateEnd: new Date(casesFilter.dateEnd),
      //       };
      //     }

      //     dispatch(setStorageFilter({ to: "cases", value: newCasesFilter }));
      //   }
      // }

      // if (advancedsFilter && advancedsFilter.active) {
      //   if (advancedsFilter.active) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "active",
      //         value: advancedsFilter.active,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.prioritys) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "prioritys",
      //         value: advancedsFilter.prioritys,
      //       })
      //     );
      //   }

      //   if (advancedsFilter.reasons) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "reasons",
      //         value: advancedsFilter.reasons,
      //       })
      //     );
      //   }

      //   if (advancedsFilter.subReasons) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "subReasons",
      //         value: advancedsFilter.subReasons,
      //       })
      //     );
      //   }

      //   if (advancedsFilter.clients) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "clients",
      //         value: advancedsFilter.clients,
      //       })
      //     );
      //   }

      //   if (advancedsFilter.requesteds) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "requesteds",
      //         value: advancedsFilter.requesteds,
      //       })
      //     );
      //   }

      //   if (advancedsFilter.agents) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "agents",
      //         value: advancedsFilter.agents,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.companys) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "companys",
      //         value: advancedsFilter.companys,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.channels) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "channels",
      //         value: advancedsFilter.channels,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.escalate) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "escalate",
      //         value: advancedsFilter.escalate,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.organizations) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "organizations",
      //         value: advancedsFilter.organizations,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.ticketPrimary) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "ticketPrimary",
      //         value: advancedsFilter.ticketPrimary,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.ticketSecondary) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "ticketSecondary",
      //         value: advancedsFilter.ticketSecondary,
      //       })
      //     );
      //   }
      //   if (advancedsFilter.ticketLated) {
      //     dispatch(
      //       setFilter({
      //         to: "advancedsCases",
      //         type: "ticketLated",
      //         value: advancedsFilter.ticketLated,
      //       })
      //     );
      //   }
      // }

      // if (additionalFields) {
      //   dispatch(
      //     setStorageFilter({
      //       to: "fieldsAdditional",
      //       value: additionalFields,
      //     })
      //   );
      // }

      // if (dashboardFilters && dashboardFilters.active) {
      //   dispatch(
      //     setFilter({ to: "filtersDashboard", type: "active", value: true })
      //   );
      //   dispatch(
      //     setFilter({
      //       to: "filtersDashboard",
      //       type: "clients",
      //       value: dashboardFilters.clients,
      //     })
      //   );
      //   dispatch(
      //     setFilter({
      //       to: "filtersDashboard",
      //       type: "companys",
      //       value: dashboardFilters.companys,
      //     })
      //   );
      //   dispatch(
      //     setFilter({
      //       to: "filtersDashboard",
      //       type: "requesteds",
      //       value: dashboardFilters.requesteds,
      //     })
      //   );
      //   dispatch(
      //     setFilter({
      //       to: "filtersDashboard",
      //       type: "agents",
      //       value: dashboardFilters.agents,
      //     })
      //   );
      //   dispatch(
      //     setFilter({
      //       to: "filtersDashboard",
      //       type: "prioritys",
      //       value: dashboardFilters.prioritys,
      //     })
      //   );
      //   dispatch(
      //     setFilter({
      //       to: "filtersDashboard",
      //       type: "channels",
      //       value: dashboardFilters.channels,
      //     })
      //   );
      //   dispatch(
      //     setFilter({
      //       to: "filtersDashboard",
      //       type: "organizations",
      //       value: dashboardFilters.organizations,
      //     })
      //   );
      // }
    } else {
      Toast(
        "Falha, não foi possível fazer login!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
      history.push("/externo/login");
    }

    setPageStatus("awaiting");
  }

  async function getInfosGeneral(profileId) {
    // console.log("ENTROU AQUI", userInfo);
    await getProfilePermissions(profileId);
    await getListAdditionalFields();

    applyGeneralFilters();
  }

  async function getProfilePermissions(profileId) {
    try {
      const response = await APICase.getPermissions(profileId, token);

      if (response.status === 200) {
        const data = response.data;

        const newObject = {
          casos: data.filter((result) => result.id_tela_campo === 1)[0],
          solicitacao: data.filter((result) => result.id_tela_campo === 2)[0],
          motivo: data.filter((result) => result.id_tela_campo === 3)[0],
          assunto: data.filter((result) => result.id_tela_campo === 4)[0],
          descricao: data.filter((result) => result.id_tela_campo === 5)[0],
          status: data.filter((result) => result.id_tela_campo === 6)[0],
          contato: data.filter((result) => result.id_tela_campo === 7)[0],
          canalComunicacao: data.filter(
            (result) => result.id_tela_campo === 8
          )[0],
          comentarios: data.filter((result) => result.id_tela_campo === 9)[0],
          interacoes: data.filter((result) => result.id_tela_campo === 10)[0],
          tarefas: data.filter((result) => result.id_tela_campo === 11)[0],
          comunicados: data.filter((result) => result.id_tela_campo === 12)[0],
          historico: data.filter((result) => result.id_tela_campo === 13)[0],
          acaoInteracoes: data.filter(
            (result) => result.id_tela_campo === 14
          )[0],
          submotivo: data.filter((result) => result.id_tela_campo === 15)[0],
          camposAdicionais: data.filter(
            (result) => result.id_tela_campo === 16
          )[0],
          organizacao: data.filter((result) => result.id_tela_campo === 17)[0],
          empresa: data.filter((result) => result.id_tela_campo === 18)[0],
          proprietario: data.filter((result) => result.id_tela_campo === 19)[0],
        };

        dispatch(
          doProfilePermissions({
            permissionProfile: newObject,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getListAdditionalFields() {
    try {
      const response = await APICase.getAdditionalFields(token);

      if (response.status === 200) {
        const data = response.data;

        dispatch(
          setStorageFilter({
            to: "principalAdditionalField",
            value: data,
          })
        );

        let filterColumn = [];
        data.forEach((datum) => {
          let defaultValue = false;

          if (exhibVariable) {
            let findValue = exhibVariable.find(
              (variable) => variable.id === datum.id_campo_adicional
            );
            defaultValue = findValue ? findValue.exhib : false;
          }

          const columnValor = {
            id: datum.id_campo_adicional,
            title: datum.desc_campo_adicional,
            exhib: defaultValue,
          };

          filterColumn.push(columnValor);
        });

        dispatch(
          setVariableTableFilter({
            value: filterColumn,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  function applyGeneralFilters() {
    if (applyFilter) {
      dispatch(
        setStorageFilter({
          to: "filtersCrm",
          value: true,
        })
      );
    }

    if (columnExhib) {
      let newColumns = cloneDeep(exhibColumn);
      for (const column of columnExhib) {
        if (column && Object.keys(column).length > 0) {
          const findColumn = newColumns.find(
            (columnEx) => columnEx.title === column.title
          );
          if (findColumn) {
            findColumn.exhib = column.exhib;
          }
        }
      }

      dispatch(
        setTableFilter({
          value: newColumns,
          localStorageKey: "columnExhib",
          localStorageValue: newColumns,
        })
      );
    }

    if (casesFilter) {
      if (
        casesFilter.dateStart ||
        casesFilter.title ||
        casesFilter.status.length > 0 ||
        casesFilter.dateEnd
      ) {
        let newCasesFilter = casesFilter;
        if (casesFilter.dateStart) {
          newCasesFilter = {
            ...newCasesFilter,
            dateStart: new Date(casesFilter.dateStart),
          };
        }
        if (casesFilter.dateEnd) {
          newCasesFilter = {
            ...newCasesFilter,
            dateEnd:
              new Date(casesFilter.dateEnd) > new Date()
                ? new Date(casesFilter.dateEnd)
                : endOfDay(new Date()),
          };
        }

        dispatch(setStorageFilter({ to: "cases", value: newCasesFilter }));
      }
    }

    if (advancedsFilter && advancedsFilter.active) {
      if (advancedsFilter.active) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "active",
            value: advancedsFilter.active,
          })
        );
      }
      if (advancedsFilter.prioritys) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "prioritys",
            value: advancedsFilter.prioritys,
          })
        );
      }

      if (advancedsFilter.reasons) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "reasons",
            value: advancedsFilter.reasons,
          })
        );
      }

      if (advancedsFilter.subReasons) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "subReasons",
            value: advancedsFilter.subReasons,
          })
        );
      }

      if (advancedsFilter.clients) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "clients",
            value: advancedsFilter.clients,
          })
        );
      }

      if (advancedsFilter.requesteds) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "requesteds",
            value: advancedsFilter.requesteds,
          })
        );
      }

      if (advancedsFilter.agents) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "agents",
            value: advancedsFilter.agents,
          })
        );
      }
      if (advancedsFilter.companys) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "companys",
            value: advancedsFilter.companys,
          })
        );
      }
      if (advancedsFilter.channels) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "channels",
            value: advancedsFilter.channels,
          })
        );
      }
      if (advancedsFilter.escalate) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "escalate",
            value: advancedsFilter.escalate,
          })
        );
      }
      if (advancedsFilter.organizations) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "organizations",
            value: advancedsFilter.organizations,
          })
        );
      }
      if (advancedsFilter.ticketPrimary) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "ticketPrimary",
            value: advancedsFilter.ticketPrimary,
          })
        );
      }
      if (advancedsFilter.ticketSecondary) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "ticketSecondary",
            value: advancedsFilter.ticketSecondary,
          })
        );
      }
      if (advancedsFilter.ticketLated) {
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "ticketLated",
            value: advancedsFilter.ticketLated,
          })
        );
      }

      // if (
      //   advancedsFilter.ticketLated ||
      //   advancedsFilter.ticketPrimary ||
      //   advancedsFilter.ticketSecondary ||
      //   advancedsFilter.agents.length > 0 ||
      //   advancedsFilter.channels.length > 0 ||
      //   advancedsFilter.clients.length > 0 ||
      //   advancedsFilter.companys.length > 0 ||
      //   advancedsFilter.prioritys.length > 0 ||
      //   advancedsFilter.subReasons.length > 0 ||
      //   advancedsFilter.reasons.length > 0 ||
      //   advancedsFilter.requesteds.length > 0 ||
      //   advancedsFilter.escalate.length > 0
      // ) {
      //   dispatch(
      //     setStorageFilter({ to: "advancedsCases", value: advancedsFilter })
      //   );
      // }
    }

    if (additionalFields) {
      dispatch(
        setStorageFilter({
          to: "fieldsAdditional",
          value: additionalFields,
        })
      );
    }

    if (dashboardFilters && dashboardFilters.active) {
      dispatch(
        setFilter({ to: "filtersDashboard", type: "active", value: true })
      );
      dispatch(
        setFilter({
          to: "filtersDashboard",
          type: "clients",
          value: dashboardFilters.clients,
        })
      );
      dispatch(
        setFilter({
          to: "filtersDashboard",
          type: "companys",
          value: dashboardFilters.companys,
        })
      );
      dispatch(
        setFilter({
          to: "filtersDashboard",
          type: "requesteds",
          value: dashboardFilters.requesteds,
        })
      );
      dispatch(
        setFilter({
          to: "filtersDashboard",
          type: "agents",
          value: dashboardFilters.agents,
        })
      );
      dispatch(
        setFilter({
          to: "filtersDashboard",
          type: "prioritys",
          value: dashboardFilters.prioritys,
        })
      );
      dispatch(
        setFilter({
          to: "filtersDashboard",
          type: "channels",
          value: dashboardFilters.channels,
        })
      );
      dispatch(
        setFilter({
          to: "filtersDashboard",
          type: "organizations",
          value: dashboardFilters.organizations,
        })
      );
    }
  }

  //USE EFFECTS
  useEffect(() => {
    if (token && !externalAccess) {
      checkToken();
      setTokenExternal(false);
    } else setPageStatus("awaiting");
  }, [token]);

  useEffect(() => {
    // #TOIMPROVE: Login externo está disparando a verificação de token
    if (loginMasterQuery && loginUserQuery && passwordMasterQuery)
      doQueryLogin();

    if (externalAccess && !token) {
      dispatch(doLogout());
    }
  }, []);

  useEffect(() => {
    if (externalAccess && !location.pathname.startsWith("/externo"))
      history.push(`/externo${location.pathname}`);
  });

  // useEffect(() => {
  //   if (getPermissions) getProfilePermissions(getPermissions);
  // }, [getPermissions]);

  switch (pageStatus) {
    case "awaiting":
      return (
        <Switch>
          {/* ------------- AUTH ------------- */}
          <PublicRoute path="/login" exact>
            <Pages.Auth.Login />
          </PublicRoute>

          <PublicRoute path="/cadastre-se" exact>
            <div>Cadastro</div>
          </PublicRoute>

          <PublicRoute path="/esqueci-senha" exact>
            <div>Esqueci minha senha</div>
          </PublicRoute>

          {/* **** EXTERNAL **** */}
          <PublicRoute path="/externo/login" exact>
            <Pages.Auth.Login />
          </PublicRoute>

          {/* ------------- INTEGRATION (BROWSER COMMS) ------------- */}
          <PrivateRoute path="/externo/integracao" exact>
            <BrowserCommsAPI />
          </PrivateRoute>

          {/* ------------- TASKS ------------- */}
          <PrivateRoute path="/tarefas" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.Tasks.List />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/tarefas/:id" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.Tasks.Details />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/templates" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.Templates />
            </div>
          </PrivateRoute>

          {/* **** EXTERNAL **** */}
          <PrivateRoute path="/externo/tarefas" exact>
            <Pages.Tasks.List />
          </PrivateRoute>

          {/* ------------- CONTACT ------------- */}
          <PrivateRoute path="/contato" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.Contact />
            </div>
          </PrivateRoute>

          {/* ------------- SLA ------------- */}
          <PrivateRoute path="/sla" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.SLA />
            </div>
          </PrivateRoute>

          {/* ------------- TYPES SOLICITATIONS AND MOTIVES ------------- */}
          <PrivateRoute path="/typeSolicitation" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.TypeSolicitationReason.Datas />
            </div>
          </PrivateRoute>

          {/* ------------- TYPE MANAGEMENT ------------- */}
          <PrivateRoute path="/type-management" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.TypeManagement />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/externo/tarefas/:id" exact>
            <Pages.Tasks.Details />
          </PrivateRoute>

          {/* **** ACTIVE INTERACTION **** */}
          <PrivateRoute path="/externo/interacao-ativa/tarefas" exact>
            <Pages.ActiveInteraction.TasksAndCases />
          </PrivateRoute>

          <PrivateRoute path="/externo/interacao-ativa/tarefas/novo" exact>
            <Pages.ActiveInteraction.Create />
          </PrivateRoute>

          {/* ------------- DASHBOARD ------------- */}
          <PrivateRoute path="/dashboard" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.Dashboard />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/externo/dashboard" exact>
            <Pages.Dashboard />
          </PrivateRoute>

          {/* ------------- CASES ------------- */}
          <PrivateRoute path="/casos" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <div className="container-root-pages__main">
                <Pages.Cases.List />
              </div>
            </div>
          </PrivateRoute>

          <PrivateRoute path="/casos/:id" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <div className="container-root-pages__main">
                <Pages.Cases.Details />
              </div>
            </div>
          </PrivateRoute>

          {/* **** CASOS EM MASSA **** */}
          <PrivateRoute path="/adicionar-casos-em-massa" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <div className="container-root-pages__main">
                <Pages.CreateBatchCases />
              </div>
            </div>
          </PrivateRoute>

          {/* ------------- Records ------------- */}
          <PrivateRoute path="/cadastro" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <div className="container-root-pages__main">
                <Pages.Records.RecordType />
              </div>
            </div>
          </PrivateRoute>

          {/* **** EXTERNAL **** */}
          <PrivateRoute path="/externo/casos" exact>
            <Pages.Cases.List />
          </PrivateRoute>

          <PrivateRoute path="/externo/criar-casos" exact>
            <Pages.ActiveInteraction.CreateCases />
          </PrivateRoute>

          <PrivateRoute path="/externo/casos/:id" exact>
            <Pages.Cases.Details />
          </PrivateRoute>

          <PrivateRoute path="/externo/adicionar-casos-em-massa" exact>
            <Pages.CreateBatchCases />
          </PrivateRoute>

          {/* -------------- REGISTERSLAMOTIVE --------------*/}
          <PrivateRoute path="/cadastrar-solicitacao-motivos">
            <Pages.RegisterSolicitationMotive />
          </PrivateRoute>

          {/* ------------- HOME ------------- */}
          <PrivateRoute path="/" exact>
            <Header />
            <div className="container-root-pages">
              <NavBar />
              <Pages.Tasks.List />
            </div>
          </PrivateRoute>

          {/* **** EXTERNAL **** */}
          <PrivateRoute path="/externo" exact>
            <Pages.Tasks.List />
          </PrivateRoute>

          {/* ------------- NOT FOUND - 404 ERROR ------------- */}
          <Route path="*">
            <Pages.Page404 />
          </Route>
        </Switch>
      );
    case "requesting":
      return <Loading.Paperplane width={300} height={300} fullPage />;
    default:
      return "";
  }
}
