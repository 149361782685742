//GLOBAL - components from npm
import React from "react";

//STYLES
import "./balloon.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function Balloon({ color }) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return <div className={`balloon `} style={{ backgroundColor: color }}></div>;
}
