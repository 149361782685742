//GLOBAL - components from npm
import React, { useState, useEffect, useRef } from "react";

//COMPONENTS
import { Button, Typography } from "../../general";
import { Tippy } from "../../feedback";

// CUSTOM HOOKS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as ArrowDownIcon1 } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowDownIcon2 } from "../../../assets/icons/arrow-down-2.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search-normal.svg";

//STYLES
import "./custom-dropdown.scss";

export default function CustomDropdown({
  options = [
    {
      id: 1,
      label: "value 1",
      caption01: "caption01",
      caption02: "caption02",
    },
    {
      id: 2,
      label: "value 2",
      caption01: "caption01",
      caption02: "caption02",
    },
    {
      id: 3,
      label: "value 3",
      caption01: "caption01",
      caption02: "caption02",
    },
    {
      id: 4,
      label: "value 4",
      caption01: "caption01",
      caption02: "caption02",
    },
    {
      id: 5,
      label: "value 5",
      caption01: "caption01",
      caption02: "caption02",
    },
  ],
  optionsLimit,
  setOptionsLimit,
  noTippy,
  onSelect,
  placeholder,
  value,
  error = false,
  editable = true,
  showCustomEmptyState = false,
  className,
  customEmptyStateIcon,
  customEmptyStateMessage,
  onCustomEmptyStateClick,
  changeDownArrowIcon,
  buttonText = "Cadastrar",
  onEnterPress
}) {
  //GENERAL
  const selectRef = useRef(null);
  const optionsPerPage = 30;
  const optionsListRef = useRef(null);

  //STATES
  const [searchValue, setSearchValue] = useState("");
  const [openList, setOpenList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);

  //CUSTOM HOOKS

  //REDUX - Selectors

  //FUNCTIONS
  const ArrowDownIcon = changeDownArrowIcon ? ArrowDownIcon2 : ArrowDownIcon1;

  const handleInputChange = (event) => {
    const value = event.target.value;

    setSearchValue(value);

    if (value) {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  };

  const handleOptionClick = (option) => {
    onSelect(option);
    setSearchValue(option.label);
    setOpenList(false);
    setSelectedOption(option);
  };

  const handleSelectClick = () => {
    if (editable && !openList) {
      setOpenList(true);
    }
  };

  const handleLoadMore = () => {
    setLoading(true);

    const currentScrollPosition = optionsListRef.current.scrollTop;

    setTimeout(() => {
      setLoading(false);

      const newLoadedOptionsCount = optionsLimit + optionsPerPage;

      setOptionsLimit(newLoadedOptionsCount);

      const nextOptions = options.slice(0, newLoadedOptionsCount);

      optionsListRef.current.scrollTop = currentScrollPosition;

      setFilteredOptions(nextOptions);
    }, 1000);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnterPress(searchValue);
    }
  };

  //USE EFFECTS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpenList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const optionById = options.find((option) => option.id === value);

    if (optionById) {
      setSelectedOption(optionById);
      setSearchValue(optionById.label);
    } else {
      setSelectedOption(null);
      setSearchValue("");
    }
  }, [value, options]);

  useEffect(() => {
    if (loading) setOpenList(true);
  }, [loading, openList]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (onEnterPress) {
          onEnterPress(event);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEnterPress]);

  return (
    <div
      className={`custom-select ${openList ? "open" : ""}${className ? className : ""}`}
      ref={selectRef}
    >
      <div
        className={`search-input-container ${error ? "search-input-container--error" : ""}`}
      >
        {noTippy ? (
          <input
            type="text"
            disabled={!editable}
            className={`search-input ${openList && "blue-border"}`}
            placeholder={placeholder ? placeholder : "Pesquisar..."}
            value={searchValue}
            onChange={handleInputChange}
            onClick={handleSelectClick}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Tippy content={searchValue} disabled={!searchValue}>
            <input
              type="text"
              disabled={!editable}
              className={`search-input ${openList && "blue-border"}`}
              placeholder={placeholder ? placeholder : "Pesquisar..."}
              value={searchValue}
              onChange={handleInputChange}
              onClick={handleSelectClick}
              onKeyDown={handleKeyDown}
            />
          </Tippy>
        )}

        <button className="search-icon" onClick={handleSelectClick}>
          {openList ? <SearchIcon /> : <ArrowDownIcon />}
        </button>
      </div>

      {
        openList && (
          <ul
            className={`options ${showCustomEmptyState && !filteredOptions.length && !loading ? "custom-empty-options" : ""}`}
            ref={optionsListRef}
          >
            {filteredOptions.slice(0, optionsLimit).map((option) => (
              <>
                {noTippy ? (
                  <li
                    key={option.id}
                    onClick={() => handleOptionClick(option)}
                    className={`options__item ${selectedOption === option ? "selected" : ""}`}
                  >
                    <Typography.H5 weight="bold" className="options__label">
                      {option.label}
                    </Typography.H5>

                    <div className="options__more-information">
                      {option.caption01 && (
                        <Typography.H5
                          color="gray-180"
                          className="options__label"
                        >
                          {option.caption01}
                        </Typography.H5>
                      )}

                      {option.caption01 && option.caption02 && (
                        <Typography.H5
                          color="gray-180"
                          className="options__label"
                        >
                          •
                        </Typography.H5>
                      )}

                      {option.caption02 && (
                        <Typography.H5
                          color="gray-180"
                          className="options__label"
                        >
                          {option.caption02}
                        </Typography.H5>
                      )}
                    </div>
                  </li>
                ) : (
                  <Tippy content={option.label} key={option.id}>
                    <li
                      onClick={() => handleOptionClick(option)}
                      className={`options__item ${selectedOption === option ? "selected" : ""}`}
                    >
                      <Typography.H5 weight="bold" className="options__label">
                        {option.label}
                      </Typography.H5>

                      <div className="options__more-information">
                        {option.caption01 && (
                          <Typography.H5
                            color="gray-180"
                            className="options__label"
                          >
                            {option.caption01}
                          </Typography.H5>
                        )}

                        {option.caption01 && option.caption02 && (
                          <Typography.H5
                            color="gray-180"
                            className="options__label"
                          >
                            •
                          </Typography.H5>
                        )}

                        {option.caption02 && (
                          <Typography.H5
                            color="gray-180"
                            className="options__label"
                          >
                            {option.caption02}
                          </Typography.H5>
                        )}
                      </div>
                    </li>
                  </Tippy>
                )}
              </>
            ))}

            {loading && (
              <li className="loading">
                <Typography.H6>Carregando...</Typography.H6>
              </li>
            )}

            {showCustomEmptyState === true ? (
              <>
                {filteredOptions.length === 0 &&
                  !loading &&
                  showCustomEmptyState && (
                    <li className="custom-empty-state">
                      {customEmptyStateIcon && (
                        <div className="empty-state-icon">
                          {customEmptyStateIcon}
                        </div>
                      )}
                      {customEmptyStateMessage && (
                        <Typography.H4
                          weight="400"
                          className="customEmptyStateMessage"
                        >
                          {customEmptyStateMessage}
                        </Typography.H4>
                      )}
                      {onCustomEmptyStateClick && (
                        <Button
                          type="secondary"
                          onClick={onCustomEmptyStateClick}
                        >
                          {buttonText}
                        </Button>
                      )}
                    </li>
                  )}
              </>
            ) : (
              <>
                {!filteredOptions.length && !loading && (
                  <li className="empty-state">
                    <Typography.H6>Nenhuma opção encontrada.</Typography.H6>
                  </li>
                )}
              </>
            )}

            {optionsLimit <= options.length && (
              <li className="load-more" onClick={handleLoadMore}>
                <Typography.H6 color="blue-selected-4">
                  Carregar mais
                </Typography.H6>
              </li>
            )}
          </ul>
        )
      }
    </div >
  );
}