//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";

//STYLES
import "./button-search.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Input } from "..";
import { ImageProfile } from "../../data-display";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";
import { ReactComponent as IconAddElements } from "../../../assets/icons/AddElements.svg";
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDownTwo.svg";

export default function ButtonSearch({
  className = "",
  list,
  type,
  onClick,
  value,
  placeholder,
  createBy = false,
}) {
  //GENERAL

  //STATES
  const [inputSearch, setInputSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [valueButton, setValueButton] = useState(value);

  //REDUX - Selectors

  //FUNCTIONS
  const show = () => setVisible(true);

  const hide = () => {
    setVisible(false);
    setInputSearch("");
  };

  const listFilter = (item) => {
    const listFind = list.findIndex((it) => it.label === valueButton);
    list.unshift(list.splice(listFind, 1)[0]);

    if (inputSearch) {
      if (item.label?.toLowerCase().includes(inputSearch.toLowerCase())) {
        return item;
      } else {
        return;
      }
    } else {
      return item;
    }
  };

  //USE EFFECTS
  useEffect(() => {
    setValueButton(value);
  }, [value]);

  return (
    <div className={`${className} button-search`}>
      <Tippy
        theme="popover-default"
        // #TOVERIFY: TRIGGER
        // trigger="click"
        visible={visible}
        onClickOutside={hide}
        followCursor="horizontal"
        content={
          <div className={`popover-button-search`}>
            <Input
              rightIcon={<IconSearch />}
              type="text"
              placeholder="Busca"
              autoComplete="on"
              value={inputSearch}
              onChange={(value) => setInputSearch(value)}
            />
            <div className="popover-button-search__list">
              {createBy && (
                <div className="popover-button-search__content active">
                  <ImageProfile
                    profile={{ nome_usuario: createBy }}
                    tooltip={false}
                  />
                  <div className="content-text">
                    <Typography.H4 weight={"bold"} color="black">
                      {createBy}
                    </Typography.H4>
                    <Typography.H6 color="gray-180">
                      Criador do caso
                    </Typography.H6>
                  </div>
                </div>
              )}
              {list.filter(listFilter).map((item) => (
                <div
                  className={`popover-button-search__content ${
                    value === item.label ? "active" : ""
                  }`}
                  key={item.id}
                  onClick={() => {
                    onClick(item);
                    setValueButton(item.label);
                    hide();
                  }}
                >
                  <ImageProfile
                    profile={{ nome_usuario: item.label }}
                    tooltip={false}
                  />
                  <div className="content-text">
                    <Typography.H4 weight={"bold"} color="black">
                      {item.label}
                    </Typography.H4>
                    {value === item.label && (
                      <Typography.H6 color="gray-180">
                        Última atualização
                      </Typography.H6>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
        placement="bottom"
        delay={[0, 0]}
        allowHTML
        interactive
      >
        <div
          className="popover-button-search__value"
          onClick={(e) => {
            e.stopPropagation();
            show();
          }}
        >
          {type === "image" ? (
            <>
              <div className="user-name">
                {createBy && (
                  <ImageProfile
                    profile={{ nome_usuario: createBy }}
                    tooltip={true}
                    className="create-user"
                  />
                )}
                {valueButton ? (
                  <ImageProfile
                    profile={{ nome_usuario: valueButton }}
                    tooltip={true}
                  />
                ) : (
                  <div className="icon-add">
                    <IconAddElements />
                  </div>
                )}
              </div>
              {/* <Typography.H5 color="primary-dark">
                {valueButton ? valueButton : placeholder}
              </Typography.H5> */}
              <div className="icon-down">
                <IconArrowDown />
              </div>
            </>
          ) : (
            <Typography.H5 color="primary-dark">
              {valueButton ? valueButton : placeholder}
            </Typography.H5>
          )}
        </div>
      </Tippy>
    </div>
  );
}
