//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { Button, Typography } from "../../../components/general";
import { GrDrag } from "react-icons/gr";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//COMPONENTS
import { Loading } from "../../../components/feedback";
import { FieldContainer } from "../../../components/data-display";
import { Input, Select, SelectColor } from "../../../components/data-entry";
import { Toast } from "../../../utils/toast";

//SERVICES - api, conectors...
import * as APICase from "../../../services/api/case";
import * as APIStatus from "../../../services/api/status";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as AddCircleIcon } from "../../../assets/icons/add-circle.svg";
import { ReactComponent as MinusCircleIcon } from "../../../assets/icons/minus-cirlce.svg";

//STYLES
import "./status.scss";

export default function Status({ returnToRecord }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL
    const statusColors = [
        { id: 1, label: "Verde", color: "#45c777" },
        { id: 2, label: "Azul", color: "#455cc7" },
        { id: 3, label: "Roxo", color: "#9b3fc8" },
        { id: 4, label: "Amarelo", color: "#ffbb00" },
        { id: 5, label: "Cinza", color: "#75757599" },
        { id: 6, label: "Azul claro", color: "#bdeeff" },
        { id: 7, label: "Azul médio", color: "#5b78ff" },
        { id: 8, label: "Laranja", color: "#ff6b00" },
        { id: 9, label: "Vermelho", color: "#f7404e" },
    ]

    //STATE
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [loading, setLoading] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [originalStatusList, setOriginalStatusList] = useState([]);
    const [excludedStatus, setExcludedStatus] = useState([]);
    const [stockOptions] = useState([
        { id: 1, label: "Sim" },
        { id: 2, label: "Não" },
    ]);

    //FUNCTIONS
    const generateRandomId = (length = 8) => {
        let randomId = '';

        for (let i = 0; i < length; i++) {
            randomId += Math.round(Math.random() * 9);
        }

        return Number(randomId);
    };

    const handleStatusAddition = () => {
        setStatusList(prevStatusList => [
            ...prevStatusList,
            {
                descricao_status: "",
                ordem_apresentacao: statusList.length,
                termino: false,
                id_usuario_insercao: null,
                cor_alerta: null,
                status_id: null
            }
        ]);
    };

    const handleRemoveStatus = (idx) => {
        const statusDataList = statusList[idx];

        setExcludedStatus((prevData) => [
            ...prevData,
            statusDataList
        ]);

        setStatusList(prevStatusList => prevStatusList.filter((_, index) => index !== idx));
    };

    const handleStatusNameChange = (value, idx) => {
        setStatusList(prevStatusList => {
            return prevStatusList.map((status, index) => {
                if (index === idx) {
                    return { ...status, descricao_status: value };
                }
                return status;
            });
        });
    };

    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const updatedStatusList = [...statusList];
        const [reorderedItem] = updatedStatusList.splice(result.source.index, 1);

        updatedStatusList.splice(result.destination.index, 0, reorderedItem);

        const updatedStatusListWithOrder = updatedStatusList.map((status, index) => ({
            ...status,
            ordem_apresentacao: index
        }));

        for (const statusData of updatedStatusListWithOrder) {
            const response = await APIStatus.updateStatus(token, {
                ...statusData,
                ordem_apresentacao: statusData.ordem_apresentacao
            });

            if (response.status === 200) {
                setStatusList(updatedStatusListWithOrder);
            } else {
                Toast(
                    'Não foi possível alterar a posição do status!',
                    'Verifique sua conexão e tente novamente mais tarde!',
                    'error',
                )
            }
        }
    };

    async function handleDataOperation() {
        try {
            setPageStatus("data-operation");

            let hasError = false;
            let newStatus = false;
            let updatedStatus = false;
            let deletedStatus = false;

            const colorMapping = {
                "Vermelho": "red",
                "Azul": "blue",
                "Cinza": "gray",
                "Roxo": "purple",
                "Verde": "green",
                "Amarelo": "yellow",
                "Laranja": "orange",
                "Azul médio": "primary-dark",
                "Azul claro": "blue-light"
            };

            for (const statusData of statusList) {
                const originalStatusListIndex = originalStatusList.findIndex(item => item.status_id === statusData.status_id);
                const originalStatusListData = originalStatusListIndex !== -1 ? originalStatusList[originalStatusListIndex] : null;

                const isModifiedStatusListData = !originalStatusListData || (
                    originalStatusListData.descricao_status !== statusData.descricao_status ||
                    originalStatusListData.id_cor_alerta !== statusData.id_cor_alerta ||
                    originalStatusListData.termino !== statusData.termino
                );

                const alertColor = colorMapping[statusData.cor_alerta] || statusData.cor_alerta;

                if (!statusData.status_id) {
                    const data = {
                        descricao_status: statusData.descricao_status,
                        ordem_apresentacao: statusData.ordem_apresentacao,
                        termino: statusData.termino,
                        id_usuario_insercao: userData.userId,
                        cor_alerta: alertColor
                    };

                    if (statusData.id_cor_alerta && statusData.descricao_status) {
                        const response = await APIStatus.createStatus(token, data);

                        if (response.status !== 200) {
                            hasError = true;
                        }

                        newStatus = true;
                    }
                } else {
                    if (isModifiedStatusListData) {
                        const newData = {
                            id_ticket_status: Number(statusData.id_ticket_status),
                            descricao_status: statusData.descricao_status,
                            termino: statusData.termino,
                            ordem_apresentacao: statusData.ordem_apresentacao,
                            cor_alerta: alertColor
                        };

                        const response = await APIStatus.updateStatus(token, newData);

                        if (response.status !== 200) {
                            hasError = true;
                        }

                        updatedStatus = true;
                    }
                }
            }

            if (excludedStatus.length > 0) {
                for (const item of excludedStatus) {
                    if (item.status_id) {
                        const data = {
                            id_usuario_exclusao: userData.userId,
                            id_ticket_status: Number(item.id_ticket_status)
                        };

                        const response = await APIStatus.deleteStatus(token, data);

                        if (response.status !== 200) {
                            hasError = true;
                        }

                        deletedStatus = true;
                    }
                }
            }

            if (!hasError) {
                if (newStatus || updatedStatus || deletedStatus) {
                    Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");

                    newStatus = false;
                    updatedStatus = false;
                    deletedStatus = false;

                    getStatusList();
                }

                setPageStatus("awaiting");
            } else {
                Toast(
                    "Não foi possível enviar os dados do(s) status!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );

                throw new Error("Alguma operação falhou");
            }
        } catch (error) {
            console.error("Erro ao processar dados dos status:", error);

            Toast("Erro!", "Um erro inesperado aconteceu.", "error");
        } finally {
            setPageStatus("awaiting");
        }
    };

    async function getStatusList() {
        setLoading(true);

        const response = await APICase.getListStatus(token);

        try {
            if (response.status === 200) {
                const updateStatusList = response.data.map(st => ({
                    ...st,
                    id_ticket_status: st.id_ticket_status.toString()
                }));

                setStatusList(updateStatusList);
                setOriginalStatusList(updateStatusList);
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } catch (error) {
            console.error("Erro ao processar a resposta da API:", error);

            Toast(
                "Erro ao processar a resposta da API",
                "Por favor, tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    //USE MEMOS

    //USE EFFECTS
    useEffect(() => {
        getStatusList();
    }, []);

    useEffect(() => {
        const colorIdMapping = {
            "green": 1,
            "blue": 2,
            "purple": 3,
            "yellow": 4,
            "gray": 5,
            "blue-light": 6,
            "primary-dark": 7,
            "orange": 8,
            "red": 9
        };

        // Criar um conjunto para armazenar todas as cores únicas em statusList
        const uniqueColors = new Set(statusList.map(status => status.cor_alerta));

        // Verificar se alguma cor única não está no mapeamento e adicionar com um novo ID
        uniqueColors.forEach((color) => {
            if (!(color in colorIdMapping)) {
                colorIdMapping[color] = Object.keys(colorIdMapping).length + 1;
            }
        });

        statusList.forEach(status => {
            Object.assign(status, {
                action_id: status.termino ? 1 : 2,
                action_desc: status.termino ? "Sim" : "Não",
                id_cor_alerta: colorIdMapping[status.cor_alerta],
                status_id: generateRandomId()
            });
        });

        originalStatusList.forEach((status, index) => {
            status.status_id =
                typeof statusList[index]?.status_id === 'string' ?
                    generateRandomId() : statusList[index]?.status_id;
        });
    }, [statusList.length === 0, originalStatusList]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <main className="page-status">
                <header className="page-status__header">
                    <div className="page-status__header__title">
                        <ArrowLefIcon onClick={returnToRecord} />

                        <Typography.H2 color="gray-180" weight="bold">
                            Cadastro de status
                        </Typography.H2>
                    </div>

                    <div className="page-status__header__save-or-cancel">
                        <Button
                            className="btn-cancel-status"
                            disabled={pageStatus === "data-operation"}
                            onClick={returnToRecord}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-status"
                            disabled={pageStatus === "data-operation"}
                            onClick={handleDataOperation}
                        >
                            {pageStatus !== "data-operation" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="status-card">
                    {loading ? (
                        <div className="status-card__loading">
                            <Loading.Paperplane />
                        </div>
                    ) : (
                        <Droppable droppableId="status">
                            {(provided) => (
                                <div
                                    className="status-card-content"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {statusList.map((status, index) => {
                                        return (
                                            <Draggable
                                                key={status.id_ticket_status}
                                                draggableId={status.id_ticket_status}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="status-card-content__details"
                                                    >
                                                        <div
                                                            className={`grdrag-container ${index === 0 && "padding-top"}`}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <GrDrag />
                                                        </div>

                                                        <FieldContainer title={index === 0 && "Nome do status"}>
                                                            <Input
                                                                placeholder="Digite o nome do status aqui"
                                                                value={status.descricao_status}
                                                                onChange={(e) => handleStatusNameChange(e, index)}
                                                            />
                                                        </FieldContainer>

                                                        <FieldContainer title={index === 0 && "Selecione uma cor"}>
                                                            <SelectColor
                                                                changeDownArrowIcon
                                                                colors={statusColors}
                                                                value={status.id_cor_alerta}
                                                                onSelect={(color) => {
                                                                    const colorSelect = cloneDeep(statusList);

                                                                    colorSelect[index].id_cor_alerta = color.id;
                                                                    colorSelect[index].cor_alerta = color.label;

                                                                    setStatusList(colorSelect);
                                                                }}
                                                            />
                                                        </FieldContainer>

                                                        <FieldContainer title={index === 0 && "Este status pode finalizar um caso?"}>
                                                            <Select
                                                                changeDownArrowIcon
                                                                placeholder="Selecione"
                                                                options={stockOptions}
                                                                value={status.action_id}
                                                                onSelect={(userPermission) => {
                                                                    setStatusList((prevState) => prevState.map((item, i) => {
                                                                        if (i === index) {
                                                                            return {
                                                                                ...item,
                                                                                action_id: userPermission.id,
                                                                                termino: userPermission.id === 1 ? true : false,
                                                                            };
                                                                        }
                                                                        return item;
                                                                    }));
                                                                }}
                                                            />
                                                        </FieldContainer>

                                                        <div className={`status-card-content__details__actions ${index === 0 && "padding-top"}`}>
                                                            <MinusCircleIcon
                                                                className={`remove-status ${statusList.length === 1 && "disabled"}`}
                                                                onClick={(e) => {
                                                                    if (statusList.length === 1) return;

                                                                    e.preventDefault();
                                                                    handleRemoveStatus(index);
                                                                }}
                                                            />

                                                            <AddCircleIcon
                                                                className="add-status"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleStatusAddition();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    )}
                </div>
            </main>
        </DragDropContext>
    )
}
