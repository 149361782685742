//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { format, getHours, addHours, isPast } from "date-fns";
import { cloneDeep } from "lodash";

// UTILS
import postMessage from "../../../utils/postMessage";

//STYLES
import "./reminder-list.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Reminder } from "../../data-entry";
import { DatePicker } from "../../data-entry";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...
import * as APIReminder from "../../../services/api/reminder";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
// import { ReactComponent as IconNotifications } from "../../../assets/icons/Notifications.svg";
import { ReactComponent as IconNotification } from "../../../assets/icons/NotificationThree.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/Close.svg";

export default function ReminderList({
  idTask,
  hideConcluded = true,
  marginTop
}) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);

  //STATES
  const [visible, setVisible] = useState(false);
  const [reminders, setReminders] = useState([]);

  //REDUX - Selectors

  //FUNCTIONS
  const show = () => setVisible(true);

  const hide = () => {
    setVisible(false);
    getReminder();
  };

  const getReminder = async () => {
    const response = await APIReminder.listReminder(idTask, token);

    if (hideConcluded) {
      const concludedReminders = response.data.filter(item => !item.concluido)
      setReminders(concludedReminders);
    } else {
      setReminders(response.data);
    }
  }

  const postReminder = async (data, array, index) => {
    const dataFormated = { ...data };
    dataFormated.data_fim = format(dataFormated.data_fim, "yyyy-MM-dd HH:mm:ss");

    const response = await APIReminder.createReminder(dataFormated, token);

    if (array) {
      const arrayReminder = [...array];

      arrayReminder[index].id_tarefas_lembrete = response.data.Dados.id_tarefas_lembrete;
      arrayReminder[index].concluido = false;
      setReminders(arrayReminder);

    }

  };

  const putReminder = async (data) => {
    const dataFormated = { ...data };
    dataFormated.data_fim = format(dataFormated.data_fim, "yyyy-MM-dd HH:mm:ss");

    const response = await APIReminder.updateReminder(dataFormated, token, data.id_tarefas_lembrete);

  };

  const deleteReminder = async (data) => {
    await APIReminder.deleteReminder(token, data.id_tarefas_lembrete);

    // only send overdue reminders
    if (isPast(new Date(data.data_fim))) {
      postMessage('remindersDeleted', [data], true);
    }
  };

  const filterReminder = (reminder) => {
    if (hideConcluded) {
      if (!reminder.concluido) {
        return reminder;
      } else {
        return;
      }
    } else {
      return reminder
    }
  }

  //USE EFFECTS
  useEffect(() => {
    getReminder();
  }, [])

  return (
    <Tippy
      theme="popover-default"
      // #TOVERIFY: TRIGGER
      // trigger="click"
      visible={visible}
      onClickOutside={hide}
      content={
        <div className="reminder-list-popover">
          <div className="reminder-list-popover__header">
            <Typography.H4 color="gray-400">Lembretes</Typography.H4>
            <IconClose
              onClick={(e) => {
                e.preventDefault();
                hide();
              }}
            />
          </div>
          {reminders.filter(filterReminder).length > 0 ? (
            <>
              <div className="reminder-list-popover__dates">
                {reminders.filter(filterReminder).map((reminder, index) => (
                  visible &&
                  <Reminder
                    key={index}
                    reminder={reminder}
                    setReminder={(data) => {
                      // if reminder is overdue, send message to ingweb to remove old reminder notification
                      if (isPast(new Date(reminder.data_fim))) {
                        postMessage('remindersDeleted', [reminder], true);
                      }

                      const arrayReminders = cloneDeep(reminders);
                      if (arrayReminders[index].data_fim) {
                        arrayReminders[index].data_fim = data;
                        putReminder(arrayReminders[index]);
                        setReminders(arrayReminders);
                      } else {
                        arrayReminders[index].data_fim = data;
                        postReminder(arrayReminders[index], arrayReminders, index);
                      }

                    }}
                    onClick={() => {
                      const arrayReminders = [...reminders];

                      const arrayRemindersFiltered = arrayReminders.filter(
                        (item, indexReminder) =>
                          indexReminder !== index

                      );
                      setReminders(arrayRemindersFiltered);
                      if (reminder.id_tarefas_lembrete) {
                        deleteReminder(reminder);
                      }
                    }}
                  />
                ))}
              </div>
              <button
                onClick={() => {
                  const arrayRemmindes = [...reminders];
                  arrayRemmindes.push({
                    data_fim: "",
                    data_inicio: new Date(),
                    id_tarefa: idTask,
                    id_tarefas_lembrete: ""
                  });
                  setReminders(arrayRemmindes);
                }}
              >
                <Typography.Paragraph color="primary-dark">
                  Adicionar lembrete
                </Typography.Paragraph>
              </button>
            </>
          ) : (
            visible &&
            <div className="reminder-list-popover__empty-state">
              <DatePicker.DateRange
                selectRangeType="start"
                minDate={new Date()}
                setDate={(event) => {
                  const arrayReminder = []
                  const reminder = {
                    data_fim: event,
                    data_inicio: new Date(),
                    id_tarefa: idTask,
                    id_tarefas_lembrete: ""
                  };
                  arrayReminder.push(reminder)
                  postReminder(reminder, arrayReminder, 0);
                }}
                dateFormat="dd 'de' MMMM', 'u"
                placeholderText="Selecione uma data"
                width="180px"
              />
              <DatePicker.TimeRange
                minTime={new Date()}
                setDate={(event) => {
                  const arrayReminder = []
                  const reminder = {
                    data_fim: event,
                    data_inicio: new Date(),
                    id_tarefa: idTask
                  };
                  arrayReminder.push(reminder)
                  postReminder(reminder, arrayReminder, 0);
                }}
                dateFormat="HH:mm"
                placeholderText="Hora"
                width="85px"
              />
            </div>
          )}
        </div>
      }
      placement="bottom"
      delay={[0, 0]}
      zIndex={5}
      allowHTML
      interactive
    >
      <div
        className={`reminder-list 
        ${reminders.filter(filterReminder).length > 0 ? "active" : ""}`}
        onClick={() => show()}
        style={{ marginTop: marginTop ? marginTop : "" }}
      >
        <IconNotification />
        <Typography.H6 color="blue-selected-4" /* color="primary-dark" */>
          Adicionar e visualizar lembretes
        </Typography.H6>
      </div>
    </Tippy>
  );
}
