//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";

//STYLES
import "./button-agent-search.scss";

//COMPONENTS
import { Button, Typography } from "../../general";
import { Checkbox, Input, SelectSearchTag } from "..";
import { FieldContainer, ImageProfile } from "../../data-display";
import { Modal, Tippy } from "../../feedback";

//SERVICES - api, conectors...
import * as APICase from "../../../services/api/case";

//GLOBAL STATE - redux, env...
import { useSelector, useDispatch } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";
import { ReactComponent as IconAddElements } from "../../../assets/icons/AddElements.svg";
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDownTwo.svg";
import { ReactComponent as ProfileUser } from "../../../assets/icons/history-icons/HistoricUser.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";

export default function ButtonAgentSearch({
  className = "",
  list,
  type,
  onClick,
  value,
  placeholder,
  createBy = false,
  disabled = false,
}) {
  //GENERAL

  //STATES
  const [inputSearch, setInputSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [valueButton, setValueButton] = useState(value.label);
  const [agent, setAgent] = useState(value);
  const [openModal, setOpenModal] = useState(false);
  const [sendNotif, setSendNotif] = useState(false);

  //REDUX - Selectors
  const user = useSelector((state) => state.auth.userData);

  //FUNCTIONS
  const show = () => setVisible(true);

  const hide = () => {
    setVisible(false);
    setInputSearch("");
  };

  const listFilter = (item) => {
    if (valueButton) {
      const listFind = list.findIndex((it) => it.label === valueButton);
      list.unshift(list.splice(listFind, 1)[0]);
    }

    if (inputSearch) {
      if (item.label?.toLowerCase().includes(inputSearch.toLowerCase())) {
        return item;
      } else {
        return;
      }
    } else {
      return item;
    }
  };

  const handleCloseModal = () => {
    if (sendNotif) {
      setSendNotif(false);
    }
    setOpenModal(false);
    if (!value.label) {
      setValueButton(value.label);
    }
  };

  //USE EFFECTS
  useEffect(() => {
    setValueButton(value.label);
  }, [value]);

  return (
    <>
      <div className={`${className} button-agent-search`}>
        <div className="user-name">
          <ProfileUser className="create-icon" />
          {createBy && (
            <div className="tag-profile">
              <ImageProfile
                profile={{ nome_usuario: createBy }}
                tooltip={true}
                className="new-create-user"
                changeName={true}
                theName={"Criador"}
              />
              <Typography.H4 color={"blue-selected-4"}>
                {createBy}
              </Typography.H4>
            </div>
          )}
        </div>

        <div className="popover-button-search__value">
          <div
            className="user-name"
            // style={{ cursor: disabled ? "auto" : "pointer" }}
          >
            {value.id ? (
              <div className="tag-profile">
                <ImageProfile
                  profile={{ nome_usuario: value.label }}
                  tooltip={true}
                  className="new-create-user"
                  changeName={true}
                  theName={"Proprietário"}
                />
                <Typography.H4 color={"blue-selected-4"}>
                  {value.label}
                </Typography.H4>
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onClick({ id: user.userId, label: user.userName });
                }}
                style={{ cursor: "pointer" }}
              >
                <Typography.H4 color={"blue-selected-4"}>
                  Atribuir a mim
                </Typography.H4>
              </div>
            )}

            <div
              className="icon-down"
              onClick={(e) => {
                e.stopPropagation();

                setOpenModal(true);
              }}
            >
              <EditIcon height={24} width={24} />
            </div>
          </div>

          {/* <div className="icon-down">
                <IconArrowDown />
              </div> */}
        </div>

        <Modal.Main
          // height={"630px"}
          isOpen={openModal}
          onClose={() => handleCloseModal()}
          title="Alterar responsável pelo caso"
          children={
            <div className="modal_change_owner">
              <FieldContainer titleSize="small" title={"Selecione um usuário"}>
                <Tippy
                  theme="popover-default"
                  // #TOVERIFY: TRIGGER
                  // trigger="click"
                  visible={visible}
                  onClickOutside={hide}
                  followCursor="horizontal"
                  content={
                    <div className={`popover-button-search`}>
                      <Input
                        rightIcon={<IconSearch />}
                        type="text"
                        placeholder="Busca"
                        autoComplete="on"
                        value={inputSearch}
                        onChange={(value) => setInputSearch(value)}
                      />
                      <div className="popover-button-search__list">
                        {list.filter(listFilter).map((item) => (
                          <div
                            className={`popover-button-search__content ${
                              value.id === item.id ? "active" : ""
                            }`}
                            key={item.id}
                            onClick={() => {
                              setAgent(item);
                              setValueButton(item.label);
                              hide();
                            }}
                          >
                            <ImageProfile
                              profile={{ nome_usuario: item.label }}
                              tooltip={false}
                            />
                            <div className="content-text">
                              <Typography.H4 weight={"bold"} color="black">
                                {item.label}
                              </Typography.H4>
                              {value.id === item.id && (
                                <Typography.H6 color="gray-180">
                                  Proprietário do Caso
                                </Typography.H6>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                  placement="bottom"
                  delay={[0, 0]}
                  allowHTML
                  interactive
                >
                  <div
                    className="user-name"
                    onClick={(e) => {
                      e.stopPropagation();
                      show();
                    }}
                  >
                    {valueButton ? (
                      <div className="tag-profile">
                        <ImageProfile
                          profile={{ nome_usuario: valueButton }}
                          tooltip={true}
                          className="new-create-user"
                        />
                        <Typography.H4 color={"blue-selected-4"}>
                          {valueButton}
                        </Typography.H4>
                      </div>
                    ) : (
                      <>&nbsp;</>
                    )}

                    <div className="icon-down">
                      <IconArrowDown />
                    </div>
                  </div>
                </Tippy>
              </FieldContainer>

              <Typography.H5 color={"gray-180"}>
                O novo responsável também será proprietário desses registros
                relacionados a {createBy} que pertecem a você.
              </Typography.H5>
              <Typography.H5 color={"gray-180"}>
                <ul>
                  <li>Comentários e anexos</li>
                  <li>Tarefas abertas</li>
                </ul>
              </Typography.H5>

              <div className="checkbox-box">
                <Checkbox isChecked={sendNotif} onChange={setSendNotif} />
                <Typography.H4 weight={500} color={"gray-600"}>
                  Enviar notificação
                </Typography.H4>
              </div>
            </div>
          }
          footer={
            <div className="modal_change_owner__buttons">
              <Button
                className="field-container-edit-title__btn-cancel"
                type="tertiary"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                <Typography.H4 weight={"bold"} color="red-2">
                  Cancelar
                </Typography.H4>
              </Button>
              <Button
                className="field-container-edit-title__btn-confirm"
                onClick={() => {
                  onClick(agent, sendNotif);

                  setTimeout(() => {
                    handleCloseModal();
                  }, 1000);
                }}
              >
                <Typography.H4 weight={"bold"}>
                  Alterar responsável
                </Typography.H4>
              </Button>
            </div>
          }
        />
      </div>
    </>
  );
}
