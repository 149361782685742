import H1 from './H1';
import H2 from './H2';
import H3 from './H3';
import H4 from './H4';
import H5 from './H5';
import H6 from './H6';
import Paragraph from './Paragraph';
import Hyperlink from './Hyperlink';

const Typography = { H1, H2, H3, H4, H5, H6, Paragraph, Hyperlink }

export default Typography;