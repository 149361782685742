//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import md5 from "md5";

// UTILS

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { FieldContainer } from "../../../../components/data-display";
import { Input } from "../../../../components/data-entry";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIAuth from "../../../../services/api/auth";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconInfoCircle } from "../../../../assets/icons/InfoCircle.svg";
import { ReactComponent as IconSuccess } from "../../../../assets/icons/Success.svg";

//STYLES
import "./change-password.scss";

export default function ChangePassword({ userName, setChangePassword }) {
  //GENERAL

  //STATE
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordResetData, setPasswordResetData] = useState({
    login: userName || "",
    password: "",
    passwordnew: "",
  });

  //REDUX - Selectors

  //FUNCTIONS
  async function handleChangePassword(event) {
    event.preventDefault();

    const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*.])(.{6,})$/;
    const checkPassword = regex.test(passwordResetData.passwordnew);

    if (
      !passwordResetData.password ||
      !newPassword ||
      !passwordResetData.passwordnew
    ) {
      Toast(
        "Não foi possível alterar sua senha!",
        "Por favor, preencha todos os campos.",
        "error"
      );

      return;
    }

    if (
      passwordResetData.password.length < 6 ||
      newPassword.length < 6 ||
      passwordResetData.passwordnew.length < 6
    ) {
      Toast(
        "Não foi possível alterar sua senha!",
        "Por favor, digite uma senha de 6 caracteres.",
        "error"
      );

      return;
    }

    if (newPassword !== passwordResetData.passwordnew) {
      Toast(
        "Não foi possível alterar sua senha!",
        "A nova senha e a senha repetida não correspondem.",
        "error"
      );

      return;
    }

    if (!checkPassword) {
      Toast(
        "Não foi possível alterar sua senha!",
        "Nova senha deve conter pelo menos uma letra maiúscula, um caractere especial e ter no mínimo 6 caracteres.",
        "error"
      );

      return;
    }

    passwordResetData.password = md5(passwordResetData.password);
    passwordResetData.passwordnew = md5(passwordResetData.passwordnew);

    const response = await APIAuth.passwordReset(passwordResetData);

    if (response.status === 200) {
      Toast("Senha alterada!", "Senha alterada com sucesso.", "success");

      setPasswordChanged(true);
      setPasswordResetData({
        login: userName || "",
        password: "",
        passwordnew: "",
      });
      setNewPassword("");
    } else {
      if (passwordResetData.login === "") {
        Toast(
          "Não foi possível alterar sua senha!",
          "Preencha seu login na página anterior.",
          "error"
        );
      } else {
        Toast(
          "Não foi possível alterar sua senha!",
          "Sua senha atual está incorreta.",
          "error"
        );
      }
    }
  }

  //USE EFFECTS
  useEffect(() => {
    setPasswordResetData((prevData) => ({
      ...prevData,
      login: userName || "",
    }));
  }, [userName]);

  return (
    <main className="main-change-password">
      {passwordChanged ? (
        <section className="main-change-password__logIn">
          <div className="main-change-password__logIn__icon">
            <IconSuccess />
          </div>

          <Typography.H1 color="blue-selected-4" weight="bold">
            Senha alterada com sucesso!
          </Typography.H1>

          <Button width="202px" onClick={() => setChangePassword(false)}>
            <Typography.H4 weight={"bold"}>Fazer login</Typography.H4>
          </Button>
        </section>
      ) : (
        <>
          <header className="main__header">
            <Typography.H1 color="blue-selected-4" weight="bold">
              Alterar minha senha
            </Typography.H1>
          </header>

          <section className="main-change-password__section">
            <form
              className="change-password-form"
              onSubmit={handleChangePassword}
            >
              <div className="change-password-form__content">
                <FieldContainer title="Senha atual">
                  <Input
                    type="password"
                    placeholder="Senha atual"
                    value={passwordResetData.password}
                    onChange={(currentPassword) =>
                      setPasswordResetData({
                        ...passwordResetData,
                        password: currentPassword,
                      })
                    }
                  />

                  <div className="alert-message">
                    <div>
                      <IconInfoCircle />
                    </div>

                    <Typography.H6 color="gray-180">
                      Se você esqueceu a senha atual, entre em contato com o
                      administrador do sistema e solicite a redefinição da sua
                      senha.
                    </Typography.H6>
                  </div>
                </FieldContainer>

                <FieldContainer title="Digite a nova senha">
                  <Input
                    type="password"
                    placeholder="Digite a nova senha"
                    value={newPassword}
                    onChange={(newPassword) => setNewPassword(newPassword)}
                  />
                </FieldContainer>

                <FieldContainer title="Repita a nova senha">
                  <Input
                    type="password"
                    placeholder="Repita a nova senha"
                    value={passwordResetData.passwordnew}
                    onChange={(repeatPassword) =>
                      setPasswordResetData({
                        ...passwordResetData,
                        passwordnew: repeatPassword,
                      })
                    }
                  />
                </FieldContainer>
              </div>

              <div className="change-password-form__buttons">
                <Button
                  width="100%"
                  onClick={() => setChangePassword(false)}
                  className="change-password-form__buttons__btn-cancel"
                >
                  <Typography.H4 weight={"bold"} color="red-2">
                    Cancelar
                  </Typography.H4>
                </Button>

                <Button width="100%" htmlType="submit">
                  <Typography.H4 weight={"bold"}>Alterar senha</Typography.H4>
                </Button>
              </div>
            </form>
          </section>
        </>
      )}
    </main>
  );
}
