//GLOBAL - components from npm
import React, { useState } from "react";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES
import "./generic-delete-modal.scss";

export default function GenericDeleteModal({
    isOpen,
    onClose,
    title,
    onDeleteAction,
    isToast,
    height,
    increaseHeight = false,
    content = "Você tem certeza que deseja excluir? Ao excluir todos os dados, serão excluídos e esta ação não poderá ser desfeita.",
    deletedItem = "Item",
    cancelText = "Cancelar",
    confirmText = "Excluir"
}) {
    //REDUX - Selectors

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");

    //FUNCTIONS
    function onCloseCustom() {
        onClose();
    };

    const handleDeleteItem = async () => {
        setPageStatus("deleting");

        try {
            await onDeleteAction();
            onCloseCustom();

            if (isToast) {
                Toast(
                    `${deletedItem} deletado!`,
                    `${deletedItem} deletado com sucesso.`,
                    "success"
                );
            }
        } catch (error) {
            console.error("Erro ao excluir o item:", error);

            if (isToast) {
                Toast(
                    `Erro ao excluir ${deletedItem}!`,
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } finally {
            setPageStatus("awaiting");
        }
    };

    //USE MEMOS

    //USE EFFECTS 

    return (
        <Modal.Main
            className="delete-modal"
            title={title}
            width="443px"
            height={increaseHeight ? height : "280px"}
            titleColor="black"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            headerContainerStyle={{ alignItems: "center" }}
            icon={<div className="delete-modal__icon"><TrashIcon /></div>}
            footer={
                <div className="container-footer">
                    <Button
                        onClick={onClose}
                        className="delete-modal__btn-cancel"
                        disabled={pageStatus === "deleting"}
                    >
                        <Typography.H4 weight="bold" color="blue-selected-4">
                            {cancelText}
                        </Typography.H4>
                    </Button>

                    <Button
                        className="delete-modal__btn-confirm"
                        disabled={pageStatus === "deleting"}
                        onClick={handleDeleteItem}
                    >
                        {pageStatus !== "deleting" ? (
                            <Typography.H4 weight={"bold"} color="white">
                                {confirmText}
                            </Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4 color="gray-180" weight="400" className="information-text">
                {content}
            </Typography.H4>
        </Modal.Main>
    );
}