//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { FiCheck } from 'react-icons/fi'
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { FieldContainer, Table } from "../../../../components/data-display";
import { Input } from "../../../../components/data-entry";
import { Toast } from "../../../../utils/toast";
import { Loading } from "../../../../components/feedback";

//SERVICES - api, conectors...
import * as APIProfile from "../../../../services/api/profile";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";

// STYLES
import "./create-profile.scss";

export default function CreateProfile({ toGoBack, onCreate }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL
    const permissions = {
        include: 'insercao',
        change: 'edicao',
        delete: 'exclusao',
        view: 'visualizacao'
    };

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [loading, setLoading] = useState(false);
    const [validationWarn, setValidationWarn] = useState(false);
    const [fields, setFileds] = useState([]);
    const [profilesData, setProfilesData] = useState(() => {
        return { nome_perfil: "" }
    });

    console.log("fields", fields)

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleCheckboxChange(id, permission) {
        setFileds(prevData =>
            prevData.map(item =>
                item.idcampo === id ? { ...item, [permission]: !item[permission] } : item
            )
        );
    };

    function handleSelectAll() {
        setFileds(prevData => {
            const allChecked = prevData.every(
                item =>
                    Object.values(item).every(value => typeof value !== 'boolean' || value === true)
            );

            // Define o estado de seleção para o oposto do estado atual de todos os itens
            return prevData.map(item => {
                const updatedItem = { ...item };

                for (const permission in permissions) {
                    if (Object.prototype.hasOwnProperty.call(permissions, permission)) {
                        updatedItem[permissions[permission]] = !allChecked;
                    }
                }

                return updatedItem;
            });
        });
    };

    function refactorCasesAreaListing(data) {
        if (!data) throw new Error('Error when fetching data!');

        return data.map((item) => {
            const id = item.idcampo;

            const newObject = {
                caseArea: {
                    data: item.campo,
                    config: {
                        className: "caseArea"
                    },
                },
                include: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`include-${id}`}
                        >
                            <input
                                type="checkbox"
                                id={`include-${id}`}
                                name={`include-${id}`}
                                checked={item.insercao || false}
                                onChange={() => handleCheckboxChange(id, 'insercao')}
                            />

                            <span className={`checkmark ${item.insercao ? "checked" : ""}`}>
                                {item.insercao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "include"
                    },
                },
                change: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`change-${id}`}
                        >
                            <input
                                type="checkbox"
                                name={`change-${id}`}
                                id={`change-${id}`}
                                checked={item.edicao || false}
                                onChange={() => handleCheckboxChange(id, 'edicao')}
                            />

                            <span className={`checkmark ${item.edicao ? "checked" : ""}`}>
                                {item.edicao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "change"
                    },
                },
                delete: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`delete-${id}`}
                        >
                            <input
                                type="checkbox"
                                name={`delete-${id}`}
                                id={`delete-${id}`}
                                checked={item.exclusao || false}
                                onChange={() => handleCheckboxChange(id, 'exclusao')}
                            />

                            <span className={`checkmark ${item.exclusao ? "checked" : ""}`}>
                                {item.exclusao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "delete"
                    },
                },
                view: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`view-${id}`}
                        >
                            <input
                                type="checkbox"
                                name={`view-${id}`}
                                id={`view-${id}`}
                                checked={item.visualizacao || false}
                                onChange={() => handleCheckboxChange(id, 'visualizacao')}
                            />

                            <span className={`checkmark ${item.visualizacao ? "checked" : ""}`}>
                                {item.visualizacao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "view"
                    },
                },
            };

            return newObject;
        });
    };

    function isFieldsValid() {
        if (!profilesData.nome_perfil) return false;

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao adicionar perfil!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    async function handleCreateProfile() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        }

        setPageStatus("creating-profile");

        let profileCreated;
        let hasError = false;

        try {
            const response = await APIProfile.createProfile(token, profilesData);

            if (response.status === 200) {
                profileCreated = response.data.Dados[0].id_perfil;
            } else {
                hasError = true;
            }

            for (const item of fields) {
                const data = {
                    id_campo: item.idcampo,
                    visualizacao: item.visualizacao,
                    edicao: item.edicao,
                    insercao: item.insercao,
                    exclusao: item.exclusao,
                    id_perfil: profileCreated
                };

                const response = await APIProfile.createProfileFields(token, data);

                if (response.status !== 200) {
                    hasError = true;
                    break;
                }
            }
        } catch (error) {
            console.error("Erro ao criar perfil:", error);
            hasError = true;

            Toast(
                "Erro ao criar perfil!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        }

        if (hasError) {
            Toast(
                "Não foi possível adicionar o perfil!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        } else {
            Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");

            onCreate();
            toGoBack();
            setPageStatus("awaiting");
        }
    };

    async function getListOfFields(profileId) {
        setLoading(true);

        try {
            const response = await APIProfile.getListOfFields(token, profileId);

            if (response.status === 200) {
                setFileds(response.data);
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } catch (error) {
            console.error("Erro ao processar a resposta da API:", error);

            Toast(
                "Erro ao processar a resposta da API",
                "Por favor, tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    // USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Área do caso",
                accessor: "caseArea",
                id: "caseArea",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Incluir",
                accessor: "include",
                id: "include",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Alterar",
                accessor: "change",
                id: "change",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Excluir",
                accessor: "delete",
                id: "delete",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Visualizar",
                accessor: "view",
                id: "view",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ]
    );

    const casesAreaData = useMemo(() => {
        if (fields) {
            const refactoredData = refactorCasesAreaListing(fields);
            return refactoredData;
        }
    }, [fields]);

    // USE EFFECTS
    useEffect(() => {
        getListOfFields();
    }, []);

    useEffect(() => {
        fields.forEach((field) => {
            const casesArea = {
                insercao: false,
                edicao: false,
                exclusao: false,
                visualizacao: false
            }

            Object.assign(field, casesArea)
        })
    }, [fields.length === 0]);

    return (
        <main className="page-create-profile">
            <header className="page-create-profile__header">
                <div className="page-create-profile__header__title">
                    <ArrowLefIcon onClick={toGoBack} />

                    <Typography.H2 color="gray-180" weight="bold">
                        Adicionar perfil
                    </Typography.H2>
                </div>

                <div className="page-create-profile__header__save-or-cancel">
                    <Button
                        className="btn-cancel-profile"
                        onClick={toGoBack}
                    >
                        <Typography.H4 weight="bold" color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="btn-cancel-proflie"
                        disabled={pageStatus === "creating-profile"}
                        onClick={handleCreateProfile}
                    >
                        {pageStatus !== "creating-profile" ? (
                            <Typography.H4 weight="bold" color="white">
                                Salvar
                            </Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            </header>

            <div className="table-card">
                <div className="table-card-content">
                    <div className="table-card-content__container-input">
                        <FieldContainer title="Nome" required>
                            <Input
                                width="100%"
                                placeholder="Digite o nome do perfil aqui"
                                value={profilesData.nome_perfil}
                                onChange={(event) => setProfilesData({ ...profilesData, nome_perfil: event })}
                                error={validationWarn ? !profilesData.nome_perfil : false}
                            />
                        </FieldContainer>

                        <div>
                            <Button
                                width="160px"
                                className="select-all"
                                onClick={handleSelectAll}
                            >
                                <Typography.H4 weight="bold" color="white">
                                    Marcar todos
                                </Typography.H4>
                            </Button>
                        </div>
                    </div>


                    {loading ? (
                        <div className="table-card-content__loading">
                            <Loading.Paperplane />
                        </div>
                    ) : (
                        <div className="table-card-content__table-container">
                            <Table
                                fixedHeader
                                noPagination
                                className="table-create-profile"
                                columns={tableColumns}
                                data={casesAreaData}
                            />
                        </div>
                    )}
                </div>
            </div>
        </main>
    )
}
