//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaskedInput from "react-input-mask";
import { cloneDeep, isNull } from "lodash";
import { format, isValid, parse } from "date-fns";
import Switch from "react-switch";

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { Loading, Modal } from "../../../../components/feedback";
import { FieldContainer } from "../../../../components/data-display";
import { Toast } from "../../../../utils/toast";
import { Input, Select } from "../../../../components/data-entry";

//SERVICES - api, conectors...
import * as APISla from "../../../../services/api/sla";
import * as APIOrganization from "../../../../services/api/organization";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Trash-3.svg";
import { ReactComponent as AddCircleIcon } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as MinusCircleIcon } from "./../../../../assets/icons/minus-cirlce.svg";
import { ReactComponent as IconError } from "./../../../../assets/icons/error.svg";

//STYLES
import "./update-organization.scss";

export default function UpdateOrganization({
    toGoBack,
    onUpdate,
    organizationId,
    detailedOrganization,
    setUpdatedOrganization,
    handleTableDataLookup
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [loading, setLoading] = useState(false);
    const [validationWarn, setValidationWarn] = useState(false);
    const [shiftWorkersHourAssociation, setShiftWorkersHourAssociation] = useState(true);
    const [isBasicDataModified, setIsBasicDataModified] = useState(false);
    const [isOpenModalDeleteOrganization, setIsOpenModalDeleteOrganization] = useState(false);
    const [isStartHourBigger, setIsStartHourBigger] = useState(false);
    const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
    const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);
    const [data, setData] = useState(null);
    const [slaOptions, setSlaOption] = useState([]);
    const [daysWeek, setDaysWeek] = useState([]);
    const [slaAssociations, setSlaAssociations] = useState([]);
    const [originalSlaAssociations, setOriginalSlaAssociations] = useState([]);
    const [deletedAssociationsSla, setDeletedAssociations] = useState([]);
    const [deletedDaysWeek, setDeletedDaysWeek] = useState([]);
    const [errors, setErrors] = useState([{
        start_time: "",
        end_time: "",
    }]);

    console.log("UpdateOrganization", data)

    //FUNCTIONS
    const setDataModifiedBasicData = (newData) => {
        setData(newData);
        setIsBasicDataModified(true);
    };

    const updateOriginalSlaAssociations = (data) => {
        setOriginalSlaAssociations(data);
    };

    const generateRandomId = (length = 8) => {
        let randomId = '';

        for (let i = 0; i < length; i++) {
            randomId += Math.round(Math.random() * 9);
        }

        return Number(randomId);
    };

    const allSlaAssociations = () => {
        const allAssociated = slaOptions.filter(sla => {
            return !slaAssociations.some(item => item.sla_id === sla.id);
        }).length === 0;

        return allAssociated;
    };

    const allDaysWeek = (slaIndex) => {
        const allAssociated = daysWeek.filter(day => {
            return !slaAssociations[slaIndex].days.some(item => item.id_dayWeek === day.id);
        }).length === 0;

        return allAssociated;
    };

    const handleAddSla = () => {
        setSlaAssociations(prevState => [
            ...prevState,
            {
                sla_id: null,
                id_sla_organizacao: null,
                desc_sla: "",
                days: [{
                    id_dayWeek: null,
                    id_dayWeek_sla: null,
                    desc_dayWeek: "",
                    start_time: "",
                    end_time: ""
                }]
            }
        ]);
    };

    const handleRemoveSla = (slaIndex) => {
        const slaAssociationsData = slaAssociations[slaIndex];

        setDeletedAssociations((prevState) => [
            ...prevState,
            slaAssociationsData
        ]);

        setSlaAssociations(prevState => prevState.filter((_, index) => index !== slaIndex));
    };

    const handleAddDayWeek = (slaIndex) => {
        setSlaAssociations(prevState => prevState.map((item, index) => {
            if (index === slaIndex) {
                return {
                    ...item,
                    days: [
                        ...item.days,
                        {
                            id_dayWeek: null,
                            id_dayWeek_sla: null,
                            desc_dayWeek: "",
                            start_time: "",
                            end_time: ""
                        }
                    ]
                };
            }
            return item;
        }));
    };

    const handleRemoveDayWeek = (slaIndex, dayIndex) => {
        const daysWeekData = slaAssociations[slaIndex].days[dayIndex];

        setDeletedDaysWeek((prevState) => [
            ...prevState,
            daysWeekData
        ]);

        setSlaAssociations(prevState => prevState.map((item, index) => {
            if (index === slaIndex) {
                return {
                    ...item,
                    days: item.days.filter((_, i) => i !== dayIndex)
                };
            }
            return item;
        }));
    };

    const handleStartTimeChange = (slaIndex, dayIndex, e) => {
        const value = e;

        const updatedShiftWorkerData = [...slaAssociations];

        updatedShiftWorkerData[slaIndex].days[dayIndex].start_time = value;

        setSlaAssociations(updatedShiftWorkerData);
    };

    const handleEndTimeChange = (slaIndex, dayIndex, e) => {
        const value = e;

        const updatedShiftWorkerData = [...slaAssociations];

        updatedShiftWorkerData[slaIndex].days[dayIndex].end_time = value;

        setSlaAssociations(updatedShiftWorkerData);
    };

    const handleBlur = (slaIndex, dayIndex) => {
        const originalStartTime = slaAssociations[slaIndex].days[dayIndex].start_time;
        const originalEndTime = slaAssociations[slaIndex].days[dayIndex].end_time;

        const startTime = originalStartTime ? originalStartTime.split(':').slice(0, 2).join(':') : null;
        const endTime = originalEndTime ? originalEndTime.split(':').slice(0, 2).join(':') : null;

        const parsedStartTime = startTime ? parse(startTime, "HH:mm", new Date()) : null;
        const parsedEndTime = endTime ? parse(endTime, "HH:mm", new Date()) : null;

        let startError = "";
        let endError = "";

        if (startTime && !isValid(parsedStartTime)) {
            startError = "Hora inicial inválida";

            setIsInvalidStartTime(true);
        } else {
            setIsInvalidStartTime(false);
        }

        if (endTime && !isValid(parsedEndTime)) {
            endError = "Hora final inválida";

            setIsInvalidEndTime(true);
        } else {
            setIsInvalidEndTime(false);
        }

        if (!startError && !endError && parsedStartTime && parsedEndTime && parsedStartTime > parsedEndTime) {
            Toast(
                "Atenção!",
                "Hora inicial não pode ser maior que hora final.",
                "warn",
            );

            setIsStartHourBigger(true);
        } else {
            setIsStartHourBigger(false);
        }

        const updatedErrors = [...errors];

        updatedErrors[slaIndex] = updatedErrors[slaIndex] || {};
        updatedErrors[slaIndex].days = updatedErrors[slaIndex].days || [];
        updatedErrors[slaIndex].days[dayIndex] = {
            start_time: startError,
            end_time: endError
        };

        setErrors(updatedErrors);
    };

    function getValues() {
        const organizations = cloneDeep(detailedOrganization);

        organizations.forEach((org) => {
            if (org.id_organizacao === organizationId) {
                setData(org)
            }
        });
    };

    function isFieldsValid() {
        if (!data?.nome_organizacao) {
            return false
        }

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao atualizar organização!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    async function handleUpdateOrganization() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        } else if (
            isStartHourBigger ||
            isInvalidStartTime ||
            isInvalidEndTime
        ) return;

        let hasError = false;
        let isNewOrganization = false;
        let isOganizationEdited = false;

        try {
            setPageStatus("updating-organization");

            if (isBasicDataModified) {
                const newData = {
                    nome_organizacao: data?.nome_organizacao,
                    codigo_organizacao: data?.codigo_externo,
                    id_organizacao: data?.id_organizacao
                }

                const response = await APIOrganization.updateOrganization(token, newData);

                if (response.status !== 200) {
                    console.log("isBasicDataModified error:", {
                        status: response.status,
                        reply: response
                    })
                    hasError = true;
                }
            }

            await Promise.all(slaAssociations.map(async (slaData) => {
                await Promise.all(slaData.days.map(async (day) => {
                    if (day.start_time && day.end_time) {
                        if (typeof day.start_time === 'string' && typeof day.end_time === 'string') {
                            const startTimeParts = day.start_time.split(':');
                            const endTimeParts = day.end_time.split(':');

                            day.start_time = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1]);
                            day.end_time = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1]);

                            day.start_time = format(day.start_time, 'HH:mm:ss');
                            day.end_time = format(day.end_time, 'HH:mm:ss');
                        }
                    }

                    const originalSlaDataIndex =
                        originalSlaAssociations.findIndex(item => item.sla_id === slaData.sla_id);
                    const originalSlaData =
                        originalSlaDataIndex !== -1 ? originalSlaAssociations[originalSlaDataIndex] : null;
                    const originalDay =
                        originalSlaData &&
                        originalSlaData.days.find(item => item.id_dayWeek_sla === day.id_dayWeek_sla);

                    const isSlaModified = !originalSlaData || (
                        originalSlaData.sla_id !== slaData.sla_id ||
                        originalSlaData.id_sla_organizacao !== slaData.id_sla_organizacao ||
                        originalSlaData.desc_sla !== slaData.desc_sla
                    );

                    const isDayModified = !originalDay || (
                        originalDay.id_dayWeek !== day.id_dayWeek ||
                        originalDay.start_time !== day.start_time ||
                        originalDay.end_time !== day.end_time
                    );

                    const newData = {
                        idsla: slaData.sla_id,
                        dia: day.id_dayWeek,
                        hora_inicio: day.start_time,
                        hora_fim: day.end_time,
                    };

                    if (!slaData.id_sla_organizacao || !day.id_dayWeek_sla) {
                        const isDataValid =
                            !isNull(slaData.sla_id) &&
                            !isNull(day.id_dayWeek) &&
                            day.start_time &&
                            day.end_time;

                        if (isDataValid) {
                            const response = await APIOrganization.createSlaForOrganization(token, {
                                ...newData, id_organizacao: data?.id_organizacao
                            });

                            if (response.status !== 200) {
                                hasError = true;
                            }

                            isNewOrganization = true
                        }
                    } else {
                        if (isSlaModified || isDayModified) {
                            const response = await APIOrganization.updateSlaForOrganization(token, {
                                ...newData,
                                id_sla_organizacao: slaData.id_sla_organizacao
                            });

                            if (response.status !== 200) {
                                hasError = true;
                            }

                            isOganizationEdited = true
                        }
                    }
                }))
            }))

            if (deletedAssociationsSla.length > 0) {
                for (const delSlaAssociation of deletedAssociationsSla) {
                    if (delSlaAssociation.sla_id) {
                        const response = await APIOrganization.deleteGeneralSlaForOrganization(token, {
                            id_organizacao: data?.id_organizacao,
                            id_sla: delSlaAssociation.sla_id
                        });

                        if (response.status !== 200) {
                            hasError = true;
                            break;
                        }
                    }
                }
            }

            if (deletedDaysWeek.length > 0) {
                for (const delDaysWeek of deletedDaysWeek) {
                    if (delDaysWeek.id_sla_organizacao) {
                        const response = await APIOrganization.deleteSlaForOrganization(token, {
                            id_sla_organizacao: delDaysWeek.id_sla_organizacao,
                        });

                        if (response.status !== 200) {
                            hasError = true;
                            break;
                        }
                    }
                }
            }

            if (!hasError) {
                if (
                    isBasicDataModified ||
                    deletedAssociationsSla.length > 0 ||
                    deletedDaysWeek.length > 0 ||
                    isNewOrganization ||
                    isOganizationEdited
                ) {
                    Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
                }

                onUpdate();
                toGoBack();
                setUpdatedOrganization(true);
                setIsBasicDataModified(false);
            } else {
                Toast(
                    "Não foi possível atualizar a organização!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );

                throw new Error("Alguma operação falhou");
            }
        } catch (error) {
            console.error("Erro ao atualizar organização:", error);

            Toast(
                "Erro ao atualizar organização!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        } finally {
            setPageStatus("awaiting");
        }
    };

    async function getDaysOfWeek() {
        const response = await APIOrganization.getDaysOfWeek(token);

        if (response.status === 200) {
            let dataUpdatedDayWeek = [];

            response.data.forEach((item) => {
                let requestedItemDayWeek = {};

                requestedItemDayWeek.id = item.id_dia;
                requestedItemDayWeek.label = item.desc_dia;

                dataUpdatedDayWeek.push(requestedItemDayWeek);
            });

            setDaysWeek(dataUpdatedDayWeek);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getSlaList() {
        const response = await APISla.listSla(token);

        if (response.status === 200) {
            let dataUpdatedSla = [];

            response.data.forEach((item) => {
                let requestedItemSla = {};

                requestedItemSla.id = item.id_sla;
                requestedItemSla.label = item.desc_sla;

                dataUpdatedSla.push(requestedItemSla);
            });

            setSlaOption(dataUpdatedSla);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getSlaAssociatedWithOrganization(organizationId, slaId) {
        setLoading(true);

        try {
            const response =
                await APIOrganization.getSlaAssociatedWithOrganization(token, organizationId, slaId);

            let slaAssociationsData = null;

            if (response.status === 200) {
                slaAssociationsData = response.data;

                const newValues = {
                    sla_id: null,
                    id_sla_organizacao: null,
                    desc_sla: "",
                    days: [{
                        id_dayWeek: null,
                        id_dayWeek_sla: null,
                        desc_dayWeek: "",
                        start_time: "",
                        end_time: ""
                    }]
                };

                if (slaAssociationsData.length > 0) {
                    slaAssociationsData = slaAssociationsData.reduce((acc, slaAssociation) => {
                        const existingSla = acc.find(item => item.sla_id === slaAssociation.idsla);

                        if (existingSla) {
                            existingSla.days.push({
                                id_dayWeek: slaAssociation.dia,
                                id_dayWeek_sla: generateRandomId(),
                                start_time: slaAssociation.hora_inicio,
                                end_time: slaAssociation.hora_fim,
                                desc_dayWeek: slaAssociation.desc_dia,
                                id_sla_organizacao: slaAssociation.id_sla_organizacao,
                            });
                        } else {
                            acc.push({
                                sla_id: slaAssociation.idsla,
                                id_sla_organizacao: slaAssociation.id_sla_organizacao,
                                desc_sla: slaAssociation.desc_sla,
                                days: [{
                                    id_dayWeek: slaAssociation.dia,
                                    id_dayWeek_sla: generateRandomId(),
                                    start_time: slaAssociation.hora_inicio,
                                    end_time: slaAssociation.hora_fim,
                                    desc_dayWeek: slaAssociation.desc_dia,
                                    id_sla_organizacao: slaAssociation.id_sla_organizacao,
                                }]
                            });
                        }

                        return acc;
                    }, []);
                } else {
                    slaAssociationsData.push(newValues);
                }

                updateOriginalSlaAssociations(slaAssociationsData);
                setSlaAssociations(slaAssociationsData);
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } catch (error) {
            console.error("Erro ao processar a resposta da API:", error);

            Toast(
                "Erro ao processar a resposta da API",
                "Por favor, tente novamente mais tarde.",
                "error"
            );
        } finally {
            setLoading(false);
        }
    };

    async function getSelectionData() {
        try {
            await Promise.allSettled([
                getSlaList(),
                getDaysOfWeek(),
            ])
        } catch (error) {
            console.error("Erro ao obter informações dos selects", error);
        }
    };

    //USE MEMOS

    //USE EFFECTS
    useEffect(() => {
        getSelectionData();
    }, []);

    useEffect(() => {
        if (organizationId) {
            getValues(organizationId);
            getSlaAssociatedWithOrganization(organizationId);
        }
    }, [organizationId]);

    // useEffect(() => {
    //     if (slaAssociations.length > 0) {
    //         setShiftWorkersHourAssociation(true);
    //     } else {
    //         setShiftWorkersHourAssociation(false);
    //     }
    // }, [slaAssociations.length])

    return (
        <>
            <main className="page-update-organization">
                <header className="page-update-organization__header">
                    <div className="page-update-organization__header__title">
                        <ArrowLefIcon
                            onClick={() => {
                                toGoBack();
                            }}
                        />

                        <Typography.H2 color="gray-180" weight="bold">
                            Adicionar organização
                        </Typography.H2>
                    </div>

                    <div className="page-update-organization__header__save-or-cancel">
                        <Button
                            className="btn-remove-organization"
                            icon={<CloseIcon />}
                            onClick={() => setIsOpenModalDeleteOrganization(true)}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Excluir organização
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-cancel-organization"
                            disabled={pageStatus === "updating-organization"}
                            onClick={() => {
                                toGoBack();
                            }}
                        >
                            <Typography.H4 weight="bold" color="blue-selected-4">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-organization"
                            disabled={pageStatus === "updating-organization"}
                            onClick={handleUpdateOrganization}
                        >
                            {pageStatus !== "updating-organization" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="section-container">
                    <section className="basic-data-section">
                        <header className="basic-data-section-header">
                            <div className="basic-data-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Dados básicos
                                </Typography.H4>
                            </div>

                            <div className="basic-data-section-header__division" />
                        </header>

                        <div className="basic-data-content-container">
                            <div className="basic-data-content-container__content">
                                <div className="columns-data">
                                    <FieldContainer title="Nome" required>
                                        <Input
                                            type="text"
                                            placeholder="Digite o nome aqui"
                                            value={data?.nome_organizacao}
                                            onChange={(event) => setDataModifiedBasicData({ ...data, nome_organizacao: event })}
                                            error={validationWarn ? !data?.nome_organizacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Cód externo(Opcional)">
                                        <Input
                                            type="text"
                                            placeholder="Digite o cód externo aqui"
                                            value={data?.codigo_externo}
                                            onChange={(event) => setDataModifiedBasicData({ ...data, codigo_externo: event })}
                                        />
                                    </FieldContainer>

                                    <FieldContainer untitled>
                                        <div className="container-hour-association">
                                            <Switch
                                                id="hour-association"
                                                checked={shiftWorkersHourAssociation}
                                                onChange={() => setShiftWorkersHourAssociation(!shiftWorkersHourAssociation)}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                onColor={"#b8c1ea"}
                                                onHandleColor={"#455CC7"}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                width={34}
                                                height={14}
                                                handleDiameter={20}
                                            />

                                            <label htmlFor="hour-association">
                                                <Typography.H5 color="gray-180">
                                                    Associar horário dos plantonistas
                                                </Typography.H5>
                                            </label>
                                        </div>
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    {shiftWorkersHourAssociation && (
                        <section className="shift-worker-section">
                            <header className="shift-worker-section-header">
                                <div className="shift-worker-section-header__content">
                                    <Typography.H4 weight="700" color="gray-180">
                                        Associar horário dos plantonistas
                                    </Typography.H4>
                                </div>

                                <div className="shift-worker-section-header__division" />
                            </header>

                            <div className="shift-worker-content-container">
                                {!loading ? (
                                    <>
                                        {slaAssociations.map((item, slaIndex) => {
                                            const filteredSlaAssociations = slaOptions.filter(sla => {
                                                return !slaAssociations
                                                    .slice(0, slaIndex)
                                                    .some(prevItem => prevItem.sla_id === sla.id)
                                            });

                                            return (
                                                <div key={slaIndex}>
                                                    <div
                                                        className="shift-worker-content-container__content"
                                                    >
                                                        <div className="sla-column">
                                                            <FieldContainer title="Sla">
                                                                <Select
                                                                    changeDownArrowIcon
                                                                    placeholder="Selecione"
                                                                    value={item.sla_id}
                                                                    options={filteredSlaAssociations}
                                                                    onSelect={(sla) => {
                                                                        const slaSelect = cloneDeep(slaAssociations);

                                                                        slaSelect[slaIndex].sla_id = sla.id;
                                                                        slaSelect[slaIndex].desc_sla = sla.label;

                                                                        setSlaAssociations(slaSelect);
                                                                    }}
                                                                />
                                                            </FieldContainer>

                                                            {slaAssociations.length > 1 && (
                                                                <span
                                                                    className="delete-sla"
                                                                    onClick={() => handleRemoveSla(slaIndex)}
                                                                >
                                                                    <CloseIcon />

                                                                    <Typography.H4 weight="bold" color="red-2">
                                                                        Excluir SLA
                                                                    </Typography.H4>
                                                                </span>
                                                            )}
                                                        </div>

                                                        <div className="days-week-column">
                                                            {item.days.map((day, dayIndex) => {
                                                                const dayErrors = errors[slaIndex]?.days?.[dayIndex] || {};

                                                                const filteredDaysWeek = daysWeek.filter(day => {
                                                                    return !slaAssociations[slaIndex].days
                                                                        .slice(0, dayIndex)
                                                                        .some(prevItem => prevItem.id_dayWeek === day.id)
                                                                });

                                                                return (
                                                                    <div
                                                                        key={dayIndex}
                                                                        className="days-week-column__content"
                                                                    >
                                                                        <FieldContainer title="Dias da semana">
                                                                            <Select
                                                                                changeDownArrowIcon
                                                                                uncheckTheOption
                                                                                placeholder="Selecione"
                                                                                value={day.id_dayWeek}
                                                                                options={filteredDaysWeek}
                                                                                onSelect={(day_week) => {
                                                                                    const dayWeekSelect = cloneDeep(slaAssociations);

                                                                                    dayWeekSelect[slaIndex].days[dayIndex].id_dayWeek = day_week.id;
                                                                                    dayWeekSelect[slaIndex].days[dayIndex].desc_dayWeek = day_week.label;

                                                                                    setSlaAssociations(dayWeekSelect);
                                                                                }}
                                                                            />
                                                                        </FieldContainer>

                                                                        <FieldContainer title="Hora inicial">
                                                                            <label className="input-container">
                                                                                <div className="input-wrapper">
                                                                                    <MaskedInput
                                                                                        mask="99:99"
                                                                                        className="input-wrapper__input"
                                                                                        placeholder="HH:MM"
                                                                                        value={day.start_time}
                                                                                        onChange={(e) => handleStartTimeChange(slaIndex, dayIndex, e.target.value)}
                                                                                        onBlur={() => handleBlur(slaIndex, dayIndex)}
                                                                                    />
                                                                                </div>

                                                                                {day.start_time !== "" && dayErrors.start_time && (
                                                                                    <div className="input-msg input-msg--error">
                                                                                        <div className="input-msg__icon">
                                                                                            <IconError />
                                                                                        </div>

                                                                                        <Typography.Paragraph className="input-msg__title">
                                                                                            {dayErrors.start_time}
                                                                                        </Typography.Paragraph>
                                                                                    </div>
                                                                                )}
                                                                            </label>
                                                                        </FieldContainer>

                                                                        <FieldContainer title="Hora final">
                                                                            <label className="input-container">
                                                                                <div className="input-wrapper">
                                                                                    <MaskedInput
                                                                                        mask="99:99"
                                                                                        className="input-wrapper__input"
                                                                                        placeholder="HH:MM"
                                                                                        value={day.end_time}
                                                                                        onChange={(e) => handleEndTimeChange(slaIndex, dayIndex, e.target.value)}
                                                                                        onBlur={() => handleBlur(slaIndex, dayIndex)}
                                                                                    />
                                                                                </div>

                                                                                {day.end_time !== "" && dayErrors.end_time && (
                                                                                    <div className="input-msg input-msg--error">
                                                                                        <div className="input-msg__icon">
                                                                                            <IconError />
                                                                                        </div>

                                                                                        <Typography.Paragraph className="input-msg__title">
                                                                                            {dayErrors.end_time}
                                                                                        </Typography.Paragraph>
                                                                                    </div>
                                                                                )}
                                                                            </label>
                                                                        </FieldContainer>

                                                                        <div className="days-week-column__content__actions">
                                                                            <MinusCircleIcon
                                                                                className={`delete-day-week ${item.days.length === 1 && "disabled"}`}
                                                                                onClick={(e) => {
                                                                                    if (item.days.length === 1) return;

                                                                                    e.preventDefault();
                                                                                    handleRemoveDayWeek(slaIndex, dayIndex);
                                                                                }}
                                                                            />

                                                                            {!allDaysWeek(slaIndex) && (
                                                                                <AddCircleIcon
                                                                                    className="add-day-week"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleAddDayWeek(slaIndex)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>

                                                    {slaAssociations.length - 1 === slaIndex && !allSlaAssociations() && (
                                                        <span
                                                            className="add-sla"
                                                            onClick={() => handleAddSla()}
                                                        >
                                                            <AddCircleIcon />

                                                            <Typography.H4 weight="400" color="blue-selected-4">
                                                                Adicionar SLA
                                                            </Typography.H4>
                                                        </span>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <div className="shift-worker-content-container__loading">
                                        <Loading.Paperplane />
                                    </div>
                                )}
                            </div>
                        </section>
                    )}
                </div>
            </main>

            <Modal.DeleteOrganization
                isOpen={isOpenModalDeleteOrganization}
                onClose={(event) => setIsOpenModalDeleteOrganization(event)}
                returnToOrganizationList={toGoBack}
                organizations={detailedOrganization}
                organizationId={data?.id_organizacao}
                onDelete={onUpdate}
                handleTableDataLookup={handleTableDataLookup}
            />
        </>
    )
}
