//* controlador de aborto + tempo limite para a solicitação HTTP
export async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 8000 } = options;

    const abortController = new AbortController();
    const id = setTimeout(() => abortController.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: abortController.signal
    });
    clearTimeout(id);
    return response;
};