//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIStaggered from "../../../../services/api/staggered";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES
import "./delete-staggered.scss";

export default function DeleteStaggered({
    isOpen,
    onClose,
    staggereds,
    staggeredId,
    handleTableDataLookup,
    returnToStaggeredList = false,
    onDelete
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [deletedStaggered, setDeletedStaggered] = useState(false);

    //FUNCTIONS
    function onCloseCustom() {
        onClose();
    };

    async function handleDeleteStaggered() {
        setPageStatus("delete-staggered");

        const data = {
            id_escalonamento: staggeredId
        };

        try {
            const response = await APIStaggered.deleteStaggered(token, data)

            if (response.status === 200) {
                onDelete();
                onCloseCustom();
                Toast(
                    "Escalonado excluído!",
                    "Escalonado excluído com sucesso.",
                    "success"
                );

                setDeletedStaggered(true);

                if (returnToStaggeredList) {
                    returnToStaggeredList()
                }
            } else {
                const responseData = await response.json();
                throw new Error(responseData.message || "Falha ao excluir escalonado.");
            }
        } catch (error) {
            console.error("Erro ao excluir escalonado:", error);

            Toast(
                "Não foi possível excluir escalonado!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        } finally {
            setPageStatus("awaiting");
        }
    };

    //USE MEMOS

    //USE EFFECTS 
    useEffect(() => {
        if (deletedStaggered) {
            handleTableDataLookup()
        }
    }, [deletedStaggered, staggereds]);

    return (
        <Modal.Main
            className="modal-delete-staggered"
            title="Excluir escalonado"
            width="443px"
            height="280px"
            titleColor={"black"}
            isOpen={isOpen}
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            headerContainerStyle={{ alignItems: "center" }}
            icon={
                <div className="modal-delete-staggered__icon">
                    <TrashIcon />
                </div>
            }
            footer={
                <div className="container-footer">
                    <Button
                        onClick={() => onCloseCustom()}
                        className="modal-delete-staggered__btn-cancel"
                        disabled={pageStatus === "delete-staggered"}
                    >
                        <Typography.H4 weight={"bold"} color="blue-selected-4">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-delete-staggered__btn-confirm"
                        disabled={pageStatus === "delete-staggered"}
                        onClick={handleDeleteStaggered}
                    >
                        {pageStatus !== "delete-staggered" ? (
                            <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4
                color="gray-180"
                weight="400"
                className="information-text"
            >
                Você tem certeza que deseja excluir? Ao excluir todos os dados, serão excluídos e esta ação não poderá ser desfeita.
            </Typography.H4>
        </Modal.Main>
    )
}
