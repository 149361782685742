//GLOBAL - components from npm
import React, { useEffect, useState } from "react";

// UTILS

//COMPONENTS
import { FieldContainer } from "../../../data-display";
import { Input } from "../../../data-entry";
import { Modal } from "./../../../feedback";
import { maskCpfCnpj } from "../../../../utils/masks";

//SERVICES - api, conectors...

//ASSETS - icons, images...
import { ReactComponent as ImgShop } from "./../../../../assets/images/shop.svg";

//STYLES
import "./view-data-company.scss";

export default function ViewDataCompany({ isOpen, onClose, companysData }) {
  //REDUX - Selectors

  //GENERAL
  const companyId = isOpen || null;

  //STATES
  const [value, setValue] = useState(null);

  //FUNCTIONS
  function onCloseCustom() {
    onClose();
  }

  function getRegisteredData(id) {
    companysData?.forEach((item) => {
      if (item.id_empresa === id) {
        setValue({ ...item, cnpj: item.cnpj && maskCpfCnpj(item.cnpj) });
      }
    });
  }

  //USE EFFECTS
  useEffect(() => {
    if (companyId) {
      getRegisteredData(companyId);
    }
  }, [companyId]);

  return (
    <Modal.Main
      className="modal-viewDataCompany"
      title="Empresa"
      width="1000px"
      isOpen={isOpen}
      onClose={onCloseCustom}
    >
      <section className="modal-create-company__content">
        <div className="modal-create-icon">
          <ImgShop className="icon-shop" />
        </div>

        <div className="container-full-line">
          <FieldContainer required title="Nome da empresa">
            <Input
              disabled={true}
              type="text"
              placeholder="Empresa"
              value={value?.nome}
            />
          </FieldContainer>
          <FieldContainer title="CNPJ">
            <Input
              disabled={true}
              type="text"
              id="cnpj"
              placeholder="XX.XXX.XXX/XXXX-XX"
              maxLength="18"
              value={value?.cnpj}
            />
          </FieldContainer>
          <FieldContainer title="Código Externo">
            <Input
              disabled={true}
              type="text"
              placeholder="exemplo123"
              value={value?.codigo_empresa}
            />
          </FieldContainer>
          <FieldContainer title="E-mail">
            <Input
              disabled={true}
              type="email"
              placeholder="email@hotmail.com"
              value={value?.email}
            />
          </FieldContainer>
        </div>

        <div className="container-full-line" style={{ marginTop: "10px" }}>
          <FieldContainer title="Inscrição estudal">
            <Input
              disabled={true}
              type="text"
              placeholder="XXX.XXX.XXX-XX"
              value={value?.inscricaoestadual}
            />
          </FieldContainer>
          <FieldContainer title="Incrição mucipal">
            <Input
              disabled={true}
              placeholder="XX.XXX.XXX-XX"
              type="text"
              value={value?.inscricaomunicipal}
            />
          </FieldContainer>
          <FieldContainer title="Site">
            <Input
              disabled={true}
              type="text"
              placeholder="www.exemplo.com"
              value={value?.site}
            />
          </FieldContainer>
        </div>
      </section>
    </Modal.Main>
  );
}
