//GLOBAL - components from npm
import React from 'react';

//STYLES
import './page-404.scss';

//COMPONENTS
import { Typography } from '../../components/general';
import { Logo } from '../../components/data-display';

//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function Page404() {
  //GENERAL


  //STATES


  //REDUX - Selectors


  //FUNCTIONS


  //USE EFFECTS


  return (
    <div className='page-404'>
      <div className='page-404__content'>
        <Logo width={400} height={100} className='logo-404' />
        <Typography.H1 color='gray-500' className='title-404'>404 - Página não encontrada</Typography.H1>
        <Typography.H4 color='gray-300' className='desc-404'>Não foi possível encontrar a página buscada</Typography.H4>
      </div>
    </div>
  )
}
