//GLOBAL - components from npm
import { createSlice } from "@reduxjs/toolkit";
import { sub, endOfDay } from "date-fns";

import { connection_platform } from "./../services/api/configConnection";

const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    tasks: {
      title: "",
      dateStart: null,
      dateEnd: null,
      status: [
        {
          id: 2,
          label: "A fazer",
        },
        {
          id: 3,
          label: "Em andamento",
        },
      ],
    },
    cases: {
      // title: "",
      dateStart: sub(new Date(), {
        days: connection_platform.numberDays,
        // months: 1,
      }),
      dateEnd: endOfDay(new Date()),
      status: [],
    },
    advancedsCases: {
      active: false,
      reasons: [],
      subReasons: [],
      prioritys: [],
      clients: [],
      requesteds: [],
      companys: [],
      agents: [],
      channels: [],
      escalate: [],
      organizations: [],
      userGroups: [],
      ticketPrimary: false,
      ticketSecondary: false,
      ticketLated: false,
      ticketExceededsla: false,
    },
    enterprises: {
      active: false,
      nome: "",
      cnpj: "",
    },
    contacts: {
      active: false,
      name: "",
      email: "",
      telephone: "",
      id_enterprise: "",
      externcode: "",
    },
    darkMode: false,
    fieldsAdditional: [],
    principalAdditionalField: [],
    filtersDashboard: {
      active: false,
      clients: [],
      companys: [],
      requesteds: [],
      agents: [],
      prioritys: [],
      channels: [],
      organizations: [],
      initialDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      finalDate: new Date(),
    },
    filtersCrm: false,
  },
  reducers: {
    setFilter(state, action) {
      state[action.payload.to][action.payload.type] = action.payload.value;
    },
    setStorageFilter(state, action) {
      state[action.payload.to] = action.payload.value;
    },
  },
});

export const { setFilter, setStorageFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
