//GLOBAL - components from npm
import React from "react";
import Switch from "react-switch";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

// UTILS

//HOOKS
import { useLocalStorage } from "../../../../hooks/useLocalStorage";

//ASSETS - icons, images...

//STYLES
import "./export-cases.scss";

export default function ExportCases({ isOpen, onClose, onExportTable }) {
    //REDUX - Selectors

    //GENERAL

    //STATES
    const [includeCaseDescription, setIncludeCaseDescription] = useLocalStorage("includeCaseDescription", false);
    const [includeCaseComments, setIncludeCaseComments] = useLocalStorage("includeCaseComments", false);

    //FUNCTIONS
    function handleCasesExport() {
        onExportTable(includeCaseDescription, includeCaseComments)
        onClose()
    }

    return (
        <Modal.Main
            className="modal-export-cases"
            title="Exportar casos"
            width="490px"
            isOpen={isOpen}
            onClose={onClose}
            footer={
                <>
                    <Button
                        className="modal-export-cases__btn-export"
                        onClick={handleCasesExport}
                    >
                        <Typography.H4 weight="bold" color="white">
                            Exportar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-export-cases__btn-cancel"
                        onClick={onClose}
                    >
                        <Typography.H4 weight="bold" color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>
                </>
            }
        >
            <div className="export-cases-content">
                <Typography.H4 color="gray-180" weight="400">
                    Você tem certeza que deseja extrair o relatório de casos?
                </Typography.H4>

                <div className="export-cases-switch">
                    <Switch
                        id="include-case-description"
                        checked={includeCaseDescription}
                        onChange={() => setIncludeCaseDescription(!includeCaseDescription)}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor={"#b8c1ea"}
                        onHandleColor={"#455CC7"}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        width={34}
                        height={14}
                        handleDiameter={20}
                    />

                    <label
                        htmlFor="include-case-description"
                        className="export-cases-switch__label"
                    >
                        <Typography.H5 color="gray-180">
                            Incluir sempre a descrição do caso.
                        </Typography.H5>
                    </label>
                </div>

                <div className="export-cases-switch">
                    <Switch
                        id="include-case-comment"
                        checked={includeCaseComments}
                        onChange={() => setIncludeCaseComments(!includeCaseComments)}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor={"#b8c1ea"}
                        onHandleColor={"#455CC7"}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        width={34}
                        height={14}
                        handleDiameter={20}
                    />

                    <label
                        htmlFor="include-case-comment"
                        className="export-cases-switch__label"
                    >
                        <Typography.H5 color="gray-180">
                            Incluir sempre os comentários do caso.
                        </Typography.H5>
                    </label>
                </div>
            </div>
        </Modal.Main>
    )
}
