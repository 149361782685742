import { BroadcastChannel } from "broadcast-channel";
import { connection_platform } from "../services/api/configConnection";

const parentWindow = window.parent;

export default function postMessage(id, payload, alsoInternal) {
  // supported parent windows origins
  const origins = connection_platform.urlsPostMessage;

  // debugging purpose only - if origins has localhost, insert also localhost:3001 ~ localhost:3005
  if (origins.includes("http://localhost:3000")) {
    for (let count = 1; count <= 9; count++) {
      origins.push(`http://localhost:300${count}`);
    }
  }

  // build the message
  const message = {
    origin: "ingdesk",
    id,
    payload,
  };

  // send internal message (example: communicate iframe BrowserCommsAPI [notifications] with iframe Task List)
  if (alsoInternal) {
    const channel = new BroadcastChannel("ingdesk-internal");
    channel.postMessage(message);
    // console.debug('(INGDESK) MESSAGE SENT TO INGDESK INTERNAL: ', message);
  }

  // send message to each of origins destiny

  origins.forEach((origin) => {
    parentWindow.postMessage(message, origin);
    console.log("INGENIUM_INGDESK_POSTMESSAGE", { origin, message });
  });

  console.debug("(INGDESK) MESSAGE SENT TO INGWEB: ", message);
}
