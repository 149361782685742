import instance from "./configAxios";

export async function getListOfProfiles(token, profileId) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = { // para buscar um perfil
            id_perfil: profileId
        }

        const response = await instance.post('/buscar-perfil', data, config);

        return response;
    } catch (error) {
        console.log('getListOfProfiles -> Error en el servidor:', error);

        return error
    }
};

export async function getListOfFields(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-campos', {}, config);

        return response;
    } catch (error) {
        console.log('getListOfFields -> Error en el servidor:', error);

        return error
    }
};

export async function createProfile(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = nome_perfil

        const response = await instance.post('/insert-perfil', data, config);

        return response;
    } catch (error) {
        console.log('createProfile -> Error en el servidor:', error);

        return error
    }
};

export async function updateProfile(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = nome_perfil, id_perfil

        const response = await instance.put('/update-perfil', data, config);

        return response;
    } catch (error) {
        console.log('updateProfile -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteProfile(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = nome_perfil, id_perfil

        const response = await instance.put('/delete-perfil', data, config);

        return response;
    } catch (error) {
        console.log('deleteProfile -> Error en el servidor:', error);

        return error;
    }
};

export async function createProfileFields(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_campo, visualizacao, edicao, insercao, exclusao, id_perfil
        const response = await instance.post('/insert-campos-perfil', data, config);

        return response;
    } catch (error) {
        console.log('createProfileFields -> Error en el servidor:', error);

        return error
    }
};

export async function updateProfileFields(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_campo, visualizacao, edicao, insercao, exclusao, id_perfil

        const response = await instance.put('/update-campos-perfil', data, config);

        return response;
    } catch (error) {
        console.log('updateProfileFields -> Error en el servidor:', error);

        return error
    }
};

