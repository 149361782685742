//GLOBAL - components from npm
import React, { useRef, useState, useEffect, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useReactMediaRecorder } from "react-media-recorder";
import axios from "axios";

// UTILS
import { Toast } from "../../../../utils/toast";
import formatTime from "../../../../utils/formatTime";

//STYLES
import "./send-communicated-maximize.scss";

//COMPONENTS
import { Modal } from "./../../../feedback";
import { Button, Typography } from "./../../../general";
import {
  FieldContainer,
  FilterTag,
  ColumnAttach,
  QuillToolbar,
} from "../../../data-display";
import { AttachFile, FillContactSearch } from "../../../data-entry";

//SERVICES - api, conectors...
import instance from "./../../../../services/api/configAxios";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconAttachment } from "../../../../assets/icons/Attachment.svg";
import { ReactComponent as SendTwoWhite } from "../../../../assets/icons/SendTwoWhite.svg";
import { ReactComponent as IconPlusCircle } from "../../../../assets/icons/PlusCircle.svg";
import { ReactComponent as Pause } from "../../../../assets/icons/Pause-2.svg";
import { ReactComponent as Play } from "../../../../assets/icons/PlayIcon.svg";
import { ReactComponent as IconTrash } from "../../../../assets/icons/Trash.svg";
import { ReactComponent as IconSend } from "../../../../assets/icons/Send.svg";
import { connection_platform } from "../../../../services/api/configConnection";

const Delta = ReactQuill.Quill.import("delta");

export default function SendCommunicatedMaximize({
  isOpen,
  onClose,
  emailList,
  setEmailList,
  setValueQuill,
  valueQuill,
  // modules,
  // formats,
  setArrayFiles,
  setArrayFilesOptions,
  setDeleteFiles,
  deleteFiles,
  arrayFiles,
  isSending,
  sendCommunicator,
  screenType,
  getListClients,
  contentTippyQuill,
  removeAll,
  editVariable,
  token,
  communicatedState,
  renderReactQuill,
}) {
  //REDUX - Selectors

  //GENERAL
  const refModalPage = useRef(false);
  const audioTime = useRef(0);

  const modules = useMemo(
    () => ({
      history: {
        delay: 1000,
        maxStack: 1000,
        userOnly: false,
      },
      imageDropAndPaste: {
        handler: imageHandler,
      },
      toolbar: {
        container: "#toolbar-modal",
      },
    }),
    []
  );
  const formats = [
    "bold",
    "italic",
    "underline",
    "attach",
    "link",
    "template",
    "audio",
    "variableEdit",
    "image",
  ];

  //STATES
  const [audioOpen, setAudioOpen] = useState(false);
  const [timeAudio, setTimeAudio] = useState("00:00");
  const [sendAudio, setSendAudio] = useState(false);
  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({ audio: true });

  //FUNCTIONS
  const checkMicrophone = () => {
    navigator.permissions
      .query({ name: "microphone" })
      .then(function (permissionStatus) {
        if (permissionStatus.state !== "granted") {
          Toast(
            "Microfone Desativado!",
            "Por favor, ative a permissão do microfone.",
            "warn"
          );
          getLocalStream();
        } else {
          startRecording();
          setAudioOpen(true);
        }
      });
  };

  function getLocalStream() {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        window.localStream = stream; // A
        window.localAudio.srcObject = stream; // B
        window.localAudio.autoplay = true; // C
      })
      .catch((err) => {
        console.log("u got an error:" + err);
      });
  }

  const postFileAudio = async () => {
    const response = await axios({
      url: mediaBlobUrl,
      method: "GET",
      responseType: "blob",
    });

    const arrayFile = [];
    const file = new File([response.data], `mensagem_de_voz_(${timeAudio})`, {
      type: response.data.type,
    });

    const form = new FormData();
    form.append("files", file);
    try {
      const config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      };
      const response = await instance.post(
        "/insert-file-no-base",
        form,
        config
      );
      file.path = response.data[0].path;
    } catch (error) {
      return Toast(
        "Erro ao anexar arquivo!",
        `Erro ao anexar arquivo no servidor!`,
        "error"
      );
    }

    arrayFile.push({ file: file });
    setArrayFilesOptions(arrayFile);

    setTimeAudio("00:00");
    clearBlobUrl();
  };

  async function imageHandler(imageDataUrl, type, imageData) {
    const blob = imageData.toBlob();
    const file = imageData.toFile();

    //generate a form data
    // const formData = new FormData()

    // // append blob data
    // formData.append('file', blob)

    // // or just append the file
    // formData.append('file', file)

    // upload image to your server

    const response = await axios({
      url: imageDataUrl,
      method: "GET",
      responseType: "blob",
    });

    const arrayFile = [];
    const newFile = new File([response.data], `teste.png`, {
      type: response.data.type,
    });

    const form = new FormData();
    form.append("files", newFile);
    try {
      const config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      };
      const response = await instance.post(
        "/insert-file-no-base",
        form,
        config
      );
      file.path = response.data[0].path;
    } catch (error) {
      console.log("caiu aq", error);
    }
    // arrayFile.push({ file: file });
    // setArrayFilesOptions(arrayFile);

    let index = (this.quill.getSelection() || {}).index;
    if (index === undefined || index < 0) index = this.quill.getLength();
    this.quill.insertEmbed(
      index,
      "image",
      `${connection_platform.urlViewDocsApache}${file.path}`,
      "user"
    );

    return new Delta().insert(``);

    // return new Delta().insert("Learn more from this resource", {
    //   link: node.getAttribute("src"),
    // });
    // callUploadAPI(your_upload_url, formData, (err, res) => {
    //   if (err) return
    //   // success? you should return the uploaded image's url
    //   // then insert into the quill editor
    //
    // })
  }

  //Função que é ativada ao fechar o modal, resetando os valores dos componentes.
  function onCloseCustom() {
    onClose();
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      audioTime.current = audioTime.current + 1;
      setTimeAudio(formatTime(audioTime.current));
    }, 1000);

    if (!audioOpen || status === "paused") {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [audioOpen, status]);

  useEffect(() => {
    if (mediaBlobUrl && sendAudio) {
      postFileAudio();
    }
  }, [mediaBlobUrl]);

  return (
    <Modal.Main
      className="modal-send-communicated"
      title="Mensagem"
      isOpen={isOpen}
      onClose={onCloseCustom}
      width="70%"
      height="70%"
      screenType={screenType}
      footer={
        <>
          <Button
            className="modal-create-case__btn-cancel"
            onClick={() => onCloseCustom()}
          >
            <Typography.H5 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H5>
          </Button>

          <Button
            icon={<SendTwoWhite />}
            className="btn-secondary"
            onClick={() => sendCommunicator()}
            disabled={
              valueQuill !== "" && valueQuill !== "<p><br></p>"
                ? isSending === true
                  ? true
                  : false
                : true
            }
          >
            Enviar
          </Button>
        </>
      }
    >
      <section className="modal-send-communicated__content">
        <FieldContainer
          title="Adicionar remetentes"
          titleSize="small"
          required
          className="field-container-case-client-list grid-span-2"
          htmlFor=""
        >
          <div className="modal-send__container-recipients__recipient">
            {emailList.map((email, index) => (
              <FilterTag
                key={index}
                label={email.label}
                type="agents"
                agent={{ ...email, nome_usuario: email.label.toUpperCase() }}
                onClose={() => {
                  const newEmailList = emailList.filter(
                    (item) => item.label !== email.label
                  );
                  setEmailList(newEmailList);
                }}
              />
            ))}
            <div className="modal-send__container-recipients__recipient-button">
              <FillContactSearch
                paginated
                placeholder="Busque pelo nome do contato"
                icon={<IconPlusCircle />}
                getOptions={getListClients}
                setEmailList={setEmailList}
                emailList={emailList}
                onSelect={(client) => {
                  const currentEmailList = [...emailList];
                  setEmailList([...currentEmailList, client]);
                }}
              />
            </div>
          </div>
        </FieldContainer>

        <div className="modal-send-communicated__send-content">
          {audioOpen && (
            <div className="audio-recorder">
              <button
                onClick={() => {
                  setSendAudio(false);
                  stopRecording();
                  setAudioOpen(false);
                  setTimeAudio("00:00");
                  clearBlobUrl();
                  audioTime.current = 0;
                }}
                className="buttons-action__cancelButton"
              >
                <IconTrash />
              </button>
              <div className="audio-recorder_audioComment">
                <div className="audio-recorder_audioComment__recordBar"></div>
                <Typography.H6
                  className={"audio-recorder_audioComment__timeProgress"}
                  weight="600"
                >
                  {timeAudio}
                </Typography.H6>
              </div>
              {status !== "paused" && (
                <button
                  onClick={() => {
                    pauseRecording();
                  }}
                  className="buttons-action__actionButton"
                >
                  <Pause />
                </button>
              )}
              {status === "paused" && (
                <button
                  onClick={() => {
                    resumeRecording();
                  }}
                  className="buttons-action__actionButton"
                >
                  <Play />
                </button>
              )}
              <button
                onClick={() => {
                  setSendAudio(true);
                  setAudioOpen(false);
                  audioTime.current = 0;
                  stopRecording();
                }}
                className="buttons-action__sendButton"
              >
                <IconSend />
              </button>
            </div>
          )}
          <QuillToolbar
            setFiles={setArrayFilesOptions}
            screen="-modal"
            removeAll={removeAll}
            sendQuill={refModalPage.current && refModalPage}
            openAudio={() => checkMicrophone()}
            changeExib={audioOpen ? "audio" : ""}
            contentTemplate={contentTippyQuill()}
            editVariable={editVariable}
            placement={"bottom-end"}
          />
          <ReactQuill
            ref={refModalPage}
            className="quill-modal"
            value={valueQuill}
            onChange={(content, delta, source) => setValueQuill(content)}
            modules={modules}
            formats={formats}
          />
          <ColumnAttach
            files={arrayFiles}
            setFiles={setArrayFiles}
            limit={3}
            type="insert"
            className="comment-carousel"
            setDeleteFiles={setDeleteFiles}
            deleteFiles={deleteFiles}
          />
        </div>
      </section>
    </Modal.Main>
  );
}
