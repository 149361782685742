import { createSlice, current } from "@reduxjs/toolkit";

const browserCommsSlice = createSlice({
  name: "browserComms",
  initialState: {
    sentOverdueTasks: [],
    sentReminders: [],
    alertsCase: [],
  },
  reducers: {
    insertSentItem(state, action) {
      if (Array.isArray(action.payload.data)) {
        state[action.payload.into] = [
          ...state[action.payload.into],
          ...action.payload.data,
        ];
      } else {
        state[action.payload.into].push(action.payload.data);
      }
    },
    removeSentItem(state, action) {
      const itemIndex = state[action.payload.from].findIndex(
        (item) => item[action.payload.idLabel] == action.payload.id
      );
      if (itemIndex !== -1) state[action.payload.from].splice(itemIndex, 1);
    },
  },
});

export const { removeSentItem, insertSentItem } = browserCommsSlice.actions;
export default browserCommsSlice.reducer;
