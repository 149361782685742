//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { FiCheck } from 'react-icons/fi'

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { FieldContainer, Table } from "../../../../components/data-display";
import { Input } from "../../../../components/data-entry";
import { Toast } from "../../../../utils/toast";
import { Loading, Modal } from "../../../../components/feedback";

//SERVICES - api, conectors...
import * as APIProfile from "../../../../services/api/profile";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Trash-3.svg";

// STYLES
import "./update-profile.scss";

export default function UpdateProfile({
    toGoBack,
    profileId,
    detailedProfile,
    setUpdatedProfile,
    onUpdate,
    handleTableDataLookup
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL
    const permissions = {
        include: 'insercao',
        change: 'edicao',
        delete: 'exclusao',
        view: 'visualizacao'
    };

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [isDataModified, setIsDataModified] = useState(false);
    const [isModifiedDescription, setIsModifiedDescription] = useState(false);
    const [validationWarn, setValidationWarn] = useState(false);
    const [isOpenModalDeleteProfile, setIsOpenModalDeleteProfile] = useState(false);
    const [data, setData] = useState(null);
    const [authorizedOrganizations, setAuthorizedOrganizations] = useState([])

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleCheckboxChange(id, permission) {
        setAuthorizedOrganizations(prevData =>
            prevData.map(item =>
                item.idcampo === id ? { ...item, [permission]: !item[permission] } : item
            )
        );
        setIsDataModified(true);
    };

    function handleSelectAll() {
        setAuthorizedOrganizations(prevData => {
            // Verifica se todos os itens estão marcados
            const allChecked = prevData.every(
                item =>
                    Object.values(item).every(value => typeof value !== 'boolean' || value === true)
            );
            // Define o estado de seleção para o oposto do estado atual de todos os itens
            return prevData.map(item => {
                const updatedItem = { ...item };

                for (const permission in permissions) {
                    if (Object.prototype.hasOwnProperty.call(permissions, permission)) {
                        updatedItem[permissions[permission]] = !allChecked;
                    }
                }

                return updatedItem;
            });
        });
        setIsDataModified(true);
    };

    function setModifiedDescription(newData) {
        setData(newData);
        setIsModifiedDescription(true)
    }

    function isFieldsValid() {
        if (!data?.desc_perfil) return false;

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao adicionar perfil!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    function getValues(profileId) {
        const profiles = cloneDeep(detailedProfile);

        profiles.forEach((item) => {
            if (item.id_perfil === profileId) {
                setData(item);
                setAuthorizedOrganizations(item.organizacoes_autorizadas);
            }
        });
    };

    function refactorCasesAreaListing(data) {
        if (!data) throw new Error('Error when fetching data!');

        return data.map((item) => {
            const id = item.idcampo;

            const newObject = {
                caseArea: {
                    data: item.campo,
                    config: {
                        className: "caseArea"
                    },
                },
                include: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`include-${id}`}
                        >
                            <input
                                type="checkbox"
                                id={`include-${id}`}
                                name={`include-${id}`}
                                checked={item.insercao || false}
                                onChange={() => handleCheckboxChange(id, 'insercao')}
                            />

                            <span className={`checkmark ${item.insercao ? "checked" : ""}`}>
                                {item.insercao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "include"
                    },
                },
                change: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`change-${id}`}
                        >
                            <input
                                type="checkbox"
                                name={`change-${id}`}
                                id={`change-${id}`}
                                checked={item.edicao || false}
                                onChange={() => handleCheckboxChange(id, 'edicao')}
                            />

                            <span className={`checkmark ${item.edicao ? "checked" : ""}`}>
                                {item.edicao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "change"
                    },
                },
                delete: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`delete-${id}`}
                        >
                            <input
                                type="checkbox"
                                name={`delete-${id}`}
                                id={`delete-${id}`}
                                checked={item.exclusao || false}
                                onChange={() => handleCheckboxChange(id, 'exclusao')}
                            />

                            <span className={`checkmark ${item.exclusao ? "checked" : ""}`}>
                                {item.exclusao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "delete"
                    },
                },
                view: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`view-${id}`}
                        >
                            <input
                                type="checkbox"
                                name={`view-${id}`}
                                id={`view-${id}`}
                                checked={item.visualizacao || false}
                                onChange={() => handleCheckboxChange(id, 'visualizacao')}
                            />

                            <span className={`checkmark ${item.visualizacao ? "checked" : ""}`}>
                                {item.visualizacao && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "view"
                    },
                },
            };

            return newObject;
        });
    };

    async function handleUpdateProfile() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        }

        try {
            setPageStatus("updating-profile");

            let hasError = false;

            const newData = {
                id_perfil: data?.id_perfil,
                nome_perfil: data?.desc_perfil
            }

            if (isModifiedDescription) {
                const response = await APIProfile.updateProfile(token, newData);

                if (response.status !== 200) {
                    hasError = true;
                }
            }

            for (const item of authorizedOrganizations) {
                if (isDataModified) {
                    const newData = {
                        id_campo: item.idcampo,
                        visualizacao: item.visualizacao,
                        edicao: item.edicao,
                        insercao: item.insercao,
                        exclusao: item.exclusao,
                        id_perfil: data?.id_perfil
                    }

                    const response = await APIProfile.updateProfileFields(token, newData);

                    if (response.status !== 200) {
                        hasError = true;
                        break;
                    }
                } else {
                    toGoBack();
                }
            }

            if (hasError) {
                Toast(
                    "Não foi possível atualizar o perfil!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );

                throw new Error("Alguma operação falhou");
            } else {
                if (isModifiedDescription || isDataModified) {
                    Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
                }

                onUpdate();
                toGoBack();
                setUpdatedProfile(true);
            }


        } catch (error) {
            console.error("Erro ao atualizar perfil:", error);
            Toast("Erro ao atualizar perfil!", "Um erro inesperado aconteceu.", "error");
        } finally {
            setPageStatus("awaiting");
        }
    };

    // USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Área do caso",
                accessor: "caseArea",
                id: "caseArea",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Incluir",
                accessor: "include",
                id: "include",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Alterar",
                accessor: "change",
                id: "change",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Excluir",
                accessor: "delete",
                id: "delete",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Visualizar",
                accessor: "view",
                id: "view",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ]
    );

    const casesAreaData = useMemo(() => {
        if (authorizedOrganizations) {
            const refactoredData = refactorCasesAreaListing(authorizedOrganizations);
            return refactoredData;
        }
    }, [authorizedOrganizations])

    // USE EFFECTS
    useEffect(() => {
        if (profileId) {
            getValues(profileId);
        }
    }, [profileId]);

    return (
        <>
            <main className="page-update-profile">
                <header className="page-update-profile__header">
                    <div className="page-update-profile__header__title">
                        <ArrowLefIcon onClick={toGoBack} />

                        <Typography.H2 color="gray-180" weight="bold">
                            Adicionar perfil
                        </Typography.H2>
                    </div>

                    <div className="page-update-profile__header__save-or-cancel">
                        <Button
                            className="btn-remove-profile"
                            icon={<CloseIcon />}
                            onClick={() => setIsOpenModalDeleteProfile(true)}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Excluir perfil
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-cancel-profile"
                            onClick={() => {
                                toGoBack()
                            }}
                        >
                            <Typography.H4 weight="bold" color="blue-selected-4">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-proflie"
                            disabled={pageStatus === "updating-profile"}
                            onClick={handleUpdateProfile}
                        >
                            {pageStatus !== "updating-profile" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="table-card">
                    <div className="table-card-content">
                        <div className="table-card-content__container-input">
                            <FieldContainer title="Nome" required>
                                <Input
                                    width="100%"
                                    placeholder="Digite o nome do perfil aqui"
                                    value={data?.desc_perfil}
                                    onChange={(event) => setModifiedDescription({ ...data, desc_perfil: event })}
                                    error={validationWarn ? !data?.desc_perfil : false}
                                />
                            </FieldContainer>

                            <div>
                                <Button
                                    width="160px"
                                    className="select-all"
                                    onClick={handleSelectAll}
                                >
                                    <Typography.H4 weight="bold" color="white">
                                        Marcar todos
                                    </Typography.H4>
                                </Button>
                            </div>
                        </div>


                        <div className="table-card-content__table-container">
                            <Table
                                fixedHeader
                                noPagination
                                className="table-update-profile"
                                columns={tableColumns}
                                data={casesAreaData}
                            />
                        </div>
                    </div>
                </div>
            </main>

            <Modal.DeleteProfile
                isOpen={isOpenModalDeleteProfile}
                onClose={(event) => setIsOpenModalDeleteProfile(event)}
                profileId={data?.id_perfil}
                profileName={data?.desc_perfil}
                returnToProfileList={toGoBack}
                profiles={detailedProfile}
                onDelete={onUpdate}
                handleTableDataLookup={handleTableDataLookup}
            />
        </>
    )
}
