//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import Papa from "papaparse";

//COMPONENTS
import { Button, Typography } from "../../components/general";

import { Tippy } from "../../components/feedback";

//SERVICES - api, conectors...
import * as APIContactImport from "../../services/api/contactImport"

//GLOBAL STATE - redux, env...

// UTILS
import { Toast } from "../../utils/toast";
import urlIngWeb from "../../utils/env";

//ASSETS - icons, images...
import { ReactComponent as IconUploadFile } from "../../assets/icons/UploadFile.svg";
import { ReactComponent as IconXLS } from "../../assets/icons/type-files/IconXLS.svg";
import { ReactComponent as IconDownload } from "../../assets/icons/Document-download.svg";
import { ReactComponent as IconTrash } from "../../assets/icons/Trash-3.svg";
import { ReactComponent as IconSuccess } from "../../assets/icons/Success.svg";

//STYLES
import "./contact-import.scss"
export default function ContactImport() {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL
    const location = useLocation();
    const inputFile = useRef(null);

    //STATES
    const [pageStatus, setPageStatus] = useState("requesting");
    const [fileName, setFileName] = useState("");
    const [filePath, setFilePath] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [size, setSize] = useState(null);
    const [importedContactsCount, setImportedContactsCount] = useState(0);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [fileImportProgress, setFileImportProgress] = useState(0);

    console.log("fileData", fileData)

    //FUNCTIONS
    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     setSize(file.size)

    //     reader.onload = async (event) => {
    //         const data = event.target.result;

    //         if (file.name.endsWith('.xlsx')) {
    //             const workbook = XLSX.read(data, { type: 'binary' });
    //             const sheetName = workbook.SheetNames[0];
    //             const sheet = workbook.Sheets[sheetName];
    //             const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    //             const formData = new FormData();
    //             formData.append("data", file)

    //             console.log("parsedData", parsedData)

    //             const firstRow = parsedData[0];
    //             const hasNameAndPhone = firstRow.some(cell => cell.includes('Nome do contato')) &&
    //                 firstRow.some(cell => cell.includes('Telefone 1'));

    //             if (parsedData.length > 1 && hasNameAndPhone) {

    //                 const response = await APIContactImport.contactImport(formData, token, onProgress);

    //                 if (response.status === 200) {
    //                     setFileName(response.data.Arquivo)
    //                     setFilePath(response.data.Caminho)
    //                     setFileData(parsedData);
    //                     setErrorMessage(false)
    //                     setImportedContactsCount(parsedData.length - 1);
    //                 } else {
    //                     Toast(
    //                         'Falha, não foi possível anexar o arquivo!',
    //                         'Verifique sua conexão e tente novamente mais tarde',
    //                         'error',
    //                     )
    //                 }
    //             } else {
    //                 setFileData(null);
    //                 setErrorMessage(true)
    //                 setImportedContactsCount(0);
    //             }
    //         } else if (file.name.endsWith('.csv')) {
    //             Papa.parse(data, {
    //                 complete: (result) => {
    //                     const firstRow = result.meta.fields;
    //                     const hasNameAndPhone = firstRow.some(cell => cell.includes('Nome do contato')) &&
    //                         firstRow.some(cell => cell.includes('Telefone 1'));

    //                     if (result.data.length > 0 && hasNameAndPhone) {
    //                         setFileData(result.data);
    //                         setErrorMessage(false)
    //                         setImportedContactsCount(result.data.length);
    //                     } else {
    //                         setFileData(null);
    //                         setErrorMessage(true)
    //                         setImportedContactsCount(0);
    //                     }
    //                 },
    //                 header: true,
    //             })
    //         }
    //     };

    //     reader.readAsBinaryString(file);
    // }

    const isPhoneNumber = (number) => {
        const phoneNumberRegex = /^\d{8,}$/;

        return phoneNumberRegex.test(number);
    };

    const validateData = (data) => {
        const nameContactIndex = data[0].indexOf('Nome do contato');
        const tele1Index = data[0].indexOf('Telefone 1');

        if (nameContactIndex !== -1 && tele1Index !== -1) {
            for (let i = 1; i < data.length; i++) {
                const row = data[i];
                const nomeContato = row[nameContactIndex]?.toString().trim();
                const telefone1 = row[tele1Index]?.toString().trim();

                if (!nomeContato || !telefone1) {
                    return false;
                }
            }
            return true;
        }

        return false;
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        const formData = new FormData();
        formData.append("data", file)

        setSize(file.size)

        reader.onload = async (event) => {
            const data = event.target.result;
            let parsedData = [];

            if (file.name.endsWith('.xlsx')) {
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            } else if (file.name.endsWith('.csv')) {
                const parseResult = await Papa.parsePromise(data);

                parsedData = parseResult.data;
            }

            console.log("parsedData", parsedData)

            if (parsedData.length > 1 && validateData(parsedData)) {
                const response = await APIContactImport.contactImport(formData, token, onProgress);

                if (response.status === 200) {
                    setFileName(response.data.Arquivo)
                    setFilePath(response.data.Caminho)
                    setFileData(parsedData);
                    setErrorMessage(false)
                } else {
                    Toast(
                        'Falha, não foi possível anexar o arquivo!',
                        'Verifique sua conexão e tente novamente mais tarde',
                        'error',
                    )
                }
            } else {
                setFileName("")
                setFilePath("")
                setFileData(null);
                setErrorMessage(true)
            }
        };

        reader.readAsBinaryString(file);
    }

    const handleImportButtonClick = async () => {
        if (fileData != null) {
            const totalContacts = fileData.length - 1;
            let processedContacts = 0;

            // loop de colunas
            for (let columnIndex = 0; columnIndex < fileData[0].length; columnIndex++) {
                const columnName = fileData[0][columnIndex].toLowerCase();

                console.log("columnName", columnName)

                if (columnName.includes('telefone')) {
                    const phones = [];

                    // loop de linhas
                    for (let rowIndex = 1; rowIndex < fileData.length; rowIndex++) {
                        const contact = fileData[rowIndex];
                        const phoneNumber = contact[columnIndex]?.toString().trim();

                        console.log("contact", contact)

                        if (phoneNumber && isPhoneNumber(phoneNumber)) {
                            phones.push(phoneNumber);
                        }
                    }

                    console.log("phones", phones)

                    for (const phone of phones) {
                        try {
                            // Enviar phones para o endpoint usando axios;
                            console.log("endPoint", phone)

                            processedContacts++;
                            let progressPercentage = (processedContacts / totalContacts) * 100;

                            console.log("progressPercentage", progressPercentage)

                            setFileImportProgress(progressPercentage)
                        } catch (error) {
                            console.error('Erro ao enviar os dados para o endpoint:', error);
                        }
                    }

                    setImportedContactsCount(phones.length)
                }
            }
        }

        setSuccessMessage(true);
        setFileData(null);
        setFileUploadProgress(0);
        setFileName("");
        setFilePath("");
    }

    const handleFileRemove = () => {
        setFileData(null);
        setFileUploadProgress(0);
        setFileName("");
        setFilePath("");
    }

    function onProgress(percentCompleted) {
        setFileUploadProgress(percentCompleted);
    }

    function handleDownload() {
        fetch(`${urlIngWeb + filePath}`).then((response) => {
            response.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = ""
                a.click()
            })
        })
    }

    //USE EFFECTS
    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(false);
            }, 10000);

            return () => {
                clearTimeout(timer)
            }
        }
    }, [successMessage])

    return (
        <main className={`contact-import ${location.pathname === "/contact-import" ? "page-modify" : ""}`}>
            <header className="contact-import__header">
                <Typography.H1 color="blue-selected-4" weight="bold">
                    Importar contatos
                </Typography.H1>
            </header>


            {successMessage ? (
                <div className="success-message-container">
                    <div className="success-message-container__icon">
                        <IconSuccess />
                    </div>


                    <Typography.H1
                        color="black"
                        weight="700"

                    >
                        Contatos importados com sucesso!
                    </Typography.H1>

                    <Typography.H5
                        color="gray-180"
                        weight="400"

                    >
                        280.000 novos contatos foram importados.
                    </Typography.H5>
                </div>
            ) : (
                <article className="contact-import__file-progess">
                    <section className="file-upload-container">
                        <Typography.H5
                            color="gray-180"
                            weight="400"

                        >
                            Para importar os contatos da, selecione um arquivo CSV ou EXL.
                        </Typography.H5>

                        {fileUploadProgress > 0 ? (
                            <div className="container-progress-bar">
                                <div className="container-progress-bar__content">
                                    <IconXLS />

                                    <div>
                                        <header className="container-progress-bar__content__header">
                                            <div className="file-information">
                                                <div className="file-information__name-archive">
                                                    <Typography.H5
                                                        color="gray-600"
                                                        weight="bold"
                                                    >
                                                        <Tippy content={fileName}>
                                                            <div className="file-information__name-archive__text">
                                                                {fileName.length > 20 ? (
                                                                    fileName.slice(0, 20).concat("...")
                                                                ) : (
                                                                    fileName
                                                                )}
                                                            </div>
                                                        </Tippy>
                                                    </Typography.H5>
                                                </div>

                                                <Typography.H6 color="gray-180">
                                                    {
                                                        size <= 1000000 ?
                                                            (size / 1000).toFixed(2) + 'kb' :
                                                            (size / 1000 / 1024).toFixed(2) + 'mb'
                                                    }
                                                </Typography.H6>
                                            </div>

                                            {fileUploadProgress === 100 ? (
                                                <div className="file-download">
                                                    <IconDownload />

                                                    <Typography.H5
                                                        color="blue-selected-4"
                                                        weight="400"
                                                    >
                                                        Download
                                                    </Typography.H5>
                                                </div>
                                            ) : (
                                                <Typography.H6 color="gray-180">
                                                    {
                                                        fileUploadProgress > 0 && `${fileUploadProgress}%`
                                                    }
                                                </Typography.H6>
                                            )}
                                        </header>

                                        <div className="progress">
                                            <div
                                                className="line"
                                                style={{
                                                    width: `${fileUploadProgress}%`,
                                                    height: "4px",
                                                    backgroundColor: "#455cc7",
                                                    borderRadius: "100px"
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <IconTrash
                                        className="remove-file-icon"
                                        onClick={handleFileRemove}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={`attachment ${errorMessage ? "red-border" : ""}`}>
                                <IconUploadFile />

                                <div className="attachment__information">
                                    <Typography.H5 weight="500" color="black">
                                        Arraste e solte um arquivo CSV ou EXL
                                    </Typography.H5>

                                    <button
                                        className="select-file-button"
                                        onClick={() => inputFile.current.click()}
                                    >
                                        Selecione o arquivo
                                    </button>
                                </div>

                                <input
                                    type="file"
                                    accept=".csv, .xlsx, .xls"
                                    onChange={handleFileChange}
                                    ref={inputFile}
                                />
                            </div>
                        )}

                        {errorMessage && (
                            <Typography.H5
                                color="red-2"
                                weight="400"

                            >
                                Nenhum contato foi encontrado. Verifique o arquivo e tente novamente.
                            </Typography.H5>
                        )}

                        <Button
                            width="200px"
                            htmlType="button"
                            className="import-file-button"
                            disabled={fileUploadProgress === 0}
                            onClick={handleImportButtonClick}
                        >
                            Importar arquivo
                        </Button>

                        {/* <div>{fileImportProgress.toFixed(2)}%</div> */}
                    </section>

                    <section className="template-download-container">
                        <Typography.H5
                            color="gray-180"
                            weight="400"
                            className="template-download-container__text"
                        >
                            Não tem um arquivo CSV ou EXL? <br />
                            Faça o download do nosso template e utilize.
                        </Typography.H5>

                        <div className="template-file">
                            <div>
                                <IconXLS />

                                <Typography.H5 weight="500" color="black">
                                    modelo_importacao_contatos.exl
                                </Typography.H5>
                            </div>

                            <div className="template-download">
                                <Typography.H5
                                    color="blue-selected-4"
                                    weight="400"
                                >
                                    Download
                                </Typography.H5>
                            </div>
                        </div>
                    </section>
                </article>
            )}
        </main>
    )
}
