//GLOBAL - components from npm
import React, { useState, useRef } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";

//STYLES
import "./dropdown-priority.scss";

//COMPONENTS
import { Tippy } from "../../../components/feedback";
import { Typography } from "../../../components/general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconPriorityLow } from "../../../assets/icons/PriorityLow.svg";
import { ReactComponent as IconPriorityDefault } from "../../../assets/icons/PriorityDefault.svg";
import { ReactComponent as IconPriorityAverage } from "../../../assets/icons/PriorityAverage.svg";
import { ReactComponent as IconPriorityHigh } from "../../../assets/icons/PriorityHigh.svg";
import { ReactComponent as IconPriorityCritical } from "../../../assets/icons/PriorityCritical.svg";
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";

import { ReactComponent as FlagPriorityAverage } from "../../../assets/icons/PriorityAverage2.svg";
import { ReactComponent as FlagPriorityCritical } from "../../../assets/icons/PriorityCritical2.svg";
import { ReactComponent as FlagPriorityHigh } from "../../../assets/icons/PriorityHigh2.svg";
import { ReactComponent as FlagPriorityLow } from "../../../assets/icons/PriorityLow2.svg";

export default function DropdownPriority({
  value = {
    id: 0,
    label: " ",
  },
  options = [
    {
      id: 0,
      label: "",
    },
    {
      id: 1,
      label: "Baixa",
    },
    {
      id: 2,
      label: "Média",
    },
    {
      id: 3,
      label: "Alta",
    },
    {
      id: 4,
      label: "Crítica",
    },
  ],
  width,
  onClick,
  className,
  label,
  error,
  disable,
  autoWidth
}) {
  //GENERAL
  const ref = useRef();

  //STATES
  const [isOpen, setIsOpen] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  useOutsideClick(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  // const filterPriority = (option) => {
  //   if (value) {
  //     if (option.id === value.id) {
  //       return;
  //     } else {
  //       return option;
  //     }
  //   } else {
  //     if (option.label === "Baixa") {
  //       return;
  //     } else {
  //       return option;
  //     }
  //   }
  // };

  const handlePriority = (value) => {
    const option = options.find((opt) => opt.id === value);
    switch (option.id) {
      case 3:
        return (
          <Tippy content={option.label} visible={label && false}>
            <FlagPriorityLow />
          </Tippy>
        );

      case 2:
        return (
          <Tippy content={option.label} visible={label && false}>
            <FlagPriorityAverage />
          </Tippy>
        );

      case 0:
        return (
          <Tippy content={option.label} visible={label && false}>
            <IconPriorityDefault />
          </Tippy>
        );

      case 1:
        return (
          <Tippy content={option.label} visible={label && false}>
            <FlagPriorityHigh />
          </Tippy>
        );

      case 4:
        return (
          <Tippy content={option.label} visible={label && false}>
            <FlagPriorityCritical />
          </Tippy>
        );

      default:
        break;
    }
  };

  //USE EFFECTS

  return (
    <div
      className={`dropdown-priority ${className} ${
        error ? "dropdown-priority--error" : ""
      }`}
      style={{ width: width ? width : "" }}
      ref={ref}
    >
      <button
        className={`dropdown-priority-btn  ${isOpen ? "active" : ""} ${autoWidth ? "autoWidth" : ""}`}
        onClick={disable ? () => setIsOpen(!isOpen) : () => {}}
      >
        <div
          className="dropdown-priority-btn-container"
          // style={{ justifyContent: "flex-start" }}
        >
          <div className="dropdown-priority-btn-container__content">
            {handlePriority(value.id)}
            {label && (
              <Typography.H5 color="gray-400">{value.label}</Typography.H5>
            )}
          </div>
          <div className="dropdown-priority-btn-container__icon">
            {disable && <IconArrowDown />}
          </div>
        </div>
      </button>

      <div className={`dropdown-priority-list ${isOpen ? "active" : ""}`}>
        {options.map((option, index) => {
          if (option.id !== value.id)
            return (
              <button
                type="button"
                key={index}
                className={`dropdown-priority-list__item ${
                  option.id === value.id ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  if (onClick) {
                    onClick(option);
                  }
                  setIsOpen(false);
                }}
              >
                {handlePriority(option.id)}
                {value.label && (
                  <Typography.H5 color="gray-400">{option.label}</Typography.H5>
                )}
              </button>
            );
        })}
      </div>
    </div>
  );
}
