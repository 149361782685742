//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";

// UTILS

//STYLES
import "./pie-chart.scss";

//COMPONENTS
import { Button, Typography } from "../../general";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function PieChart({
  data,
  height,
  internalRadius = 0, //0.5, 0.8
  centerText = false,
  darkMode = false,
  onClick = () => null,
}) {
  //FUNCTIONS
  const arcLabelComponentsFunc = ({ datum, label, style }) => {
    return (
      <g
        transform={style.transform.toJSON()}
        className="new-g"
        style={{ pointerEvents: "none" }}
      >
        <text
          textAnchor="middle"
          dominantBaseline="central"
          className="custom-text"
        >
          {label}
        </text>
      </g>
    );
  };

  const tooltipFunc = ({ datum: { label, value, color } }) => {
    return (
      <div className="tooltip-text">
        <Typography.H6 color={"white"}>
          {label}
          <strong>
            {value} caso{value > 1 ? "s" : ""}
          </strong>
        </Typography.H6>
      </div>
    );
  };

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });

    return (
      <>
        <text
          className={`centered-text-1 ${darkMode ? "dark-mode" : ""}`}
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="up"
        >
          {total}
        </text>
        <text
          className={`centered-text-2 ${darkMode ? "dark-mode" : ""}`}
          x={centerX}
          y={centerY + 10}
          textAnchor="middle"
          dominantBaseline="hanging"
        >
          Solicitações
        </text>
      </>
    );
  };

  return (
    <div style={{ height: height ? height : "98%", width: "100%" }}>
      <ResponsivePie
        data={data}
        onClick={onClick}
        // height={height}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        colors={{ datum: "data.color" }}
        // colors={(datum) => datum.color}
        innerRadius={internalRadius}
        // padAngle={0.7}
        // cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={2}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 0.6]],
        }}
        arcLabelsRadiusOffset={0.55}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          centerText && CenteredMetric,
        ]}
        arcLabelsComponent={arcLabelComponentsFunc}
        tooltip={tooltipFunc}
        legends={[]}
      />
    </div>
  );
}
