//GLOBAL - components from npm
import React, { useEffect, useState } from "react";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import { Input } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";
import { useSelector } from "react-redux";
import { FieldContainer } from "../../../data-display";
import Loading from "../../Loading";

//SERVICES - api, conectors...
import * as APIStaggered from "../../../../services/api/staggered";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...

//STYLES
import "./update-staggered.scss";

export default function UpdateStaggered({
    isOpen,
    onClose,
    staggeredId,
    detailedStaggered,
    setUpdatedStaggered,
    onUpdate
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [data, setData] = useState(null);

    //FUNCTIONS
    function getValues(staggeredId) {
        detailedStaggered.forEach((item) => {
            if (item.id_escalonamento === staggeredId) {
                setData(item);
            }
        })
    };

    function onCloseCustom() {
        onClose();
        setValidationWarn(false);
    };

    function isFieldsValid() {
        if (!data?.descricao) {
            return false
        }

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao editar escalonado!",
            "Preencha o nome do escalonado.",
            "error",
        );
    };

    async function handleCreateStaggered() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        }

        try {
            setPageStatus("updating-staggered");

            const newData = {
                descricao: data?.descricao,
                id_escalonamento: staggeredId
            }

            const response = await APIStaggered.updateStaggered(token, newData);

            if (response.status === 200) {
                Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
                onUpdate();
                onCloseCustom();
                setUpdatedStaggered(true);
            } else {
                Toast(
                    "Não foi possível editar escalonado!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }

            setPageStatus("awaiting");
        } catch (error) {
            console.log("Erro ao editar escalonado:", error);
        }
    };

    //USE EFFECTS
    useEffect(() => {
        if (staggeredId) {
            getValues(staggeredId);
        }
    }, [staggeredId]);

    return (
        <Modal.Main
            className="modal-update-staggered"
            title="Editar escalonado"
            width="540px"
            height=""
            isOpen={isOpen}
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            footer={
                <div className="container-footer">
                    <Button
                        onClick={() => onCloseCustom()}
                        className="modal-update-staggered__btn-cancel"
                        disabled={pageStatus === "updating-staggered"}
                    >
                        <Typography.H4 weight="bold" color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-update-staggered__btn-confirm"
                        disabled={pageStatus === "updating-staggered"}
                        onClick={handleCreateStaggered}
                    >
                        {pageStatus !== "updating-staggered" ? (
                            <Typography.H4 weight={"bold"} color="white">Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <FieldContainer title="Nome" required>
                <Input
                    type="text"
                    placeholder="Digite o nome aqui"
                    value={data?.descricao}
                    onChange={(event) => setData({ ...data, descricao: event })}
                    error={validationWarn ? !data?.descricao : false}
                />
            </FieldContainer>
        </Modal.Main>
    )
}
