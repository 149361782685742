//GLOBAL - components from npm
import React, { useEffect, useState, useRef } from "react";

//STYLES
import "./comment-list.scss";

//COMPONENTS
import { Input, SendingComment } from "../../data-entry";
import {
  Comment,
  CarouselAttach,
  ImageProfile,
  AttachTypeFiles,
  ColumnAttach,
} from "../../data-display";
import { Typography } from "../../general";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import urlIngWeb from "../../../utils/env";
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconCancel } from "../../../assets/icons/Cancel.svg";
import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";
import { ReactComponent as IconMic } from "../../../assets/icons/Mic.svg";
import { ReactComponent as IconComment } from "../../../assets/icons/Comment.svg";
import { ReactComponent as IconMessage } from "../../../assets/icons/Message-text.svg";
import { ReactComponent as IconEyeSlash } from "../../../assets/icons/Eye-slash.svg";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as IconUser } from "../../../assets/icons/ImgUser.svg";

export default function CommentList({
  onAdd,
  onUpdate,
  onDelete,
  activeBorderBottom,
  commentsList = [
    {
      id_comentario: 1,
      desc_comentario: "teste",
      data_insercao: "2022-01-04T13:59:57.653Z",
      data_exclusao: null,
      id: 33,
      id_mimetype: 2,
      id_tipo_comentario: 1,
      usuario_insercao: 6,
      nome_usuario: "Glauber",
      filesAssociated: [
        {
          id_comentario_arquivo: 217,
          path_arquivos:
            "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
          data_insercao: "2022-01-04T14:00:03.326Z",
          data_exclusao: null,
          id_mimetype: 22,
          id_comentario: 333,
        },
      ],
      files: [
        {
          file: {
            name: "anexo",
            size: 1000,
            type: 2,
            path_arquivos:
              "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
            extension: ".gif",
            id: 20,
          },
        },
      ],
      commentParent: {
        id_comentario: 2,
        desc_comentario: "teste 2",
        id_tipo_comentario: 2,
        usuario_insercao: 6,
        nome_usuario: "Glauber",
        filesAssociated: [
          {
            id_comentario_arquivo: 217,
            path_arquivos:
              "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
            data_insercao: "2022-01-04T14:00:03.326Z",
            data_exclusao: null,
            id_mimetype: 22,
            id_comentario: 333,
          },
        ],
      },
    },
  ],
  permissionUsers = null,
  commentType = 1
}) {
  //GENERAL
  const refSendingComment = useRef();
  const refAudio = useRef();
  const ref = useRef();
  const refIndexComment = useRef();
  const user = useSelector((state) => state.auth.userData);

  //STATES
  const [files, setFiles] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentResponse, setCommentResponse] = useState();
  const [audioDuration, setAudioDuration] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const [loadingComment, setLoadingComment] = useState("awaiting");
  const [loadingUpdateComment, setLoadingUpdateComment] = useState("awaiting");
  const [permited, setPermited] = useState(true);
  const [sortedList, setSortedList] = useLocalStorage("sortedListComments", false);

  //REDUX - Selectors

  //FUNCTIONS

  //Função que verifica se o comentário foi respondido por outro comentário.
  const verifyCommentAnswered = (comment) => {
    const commentAnswered = commentsList.find(
      (item) => item.commentParent.id_comentario === comment.id_comentario
    );

    if (commentAnswered) {
      return true;
    } else {
      return false;
    }
  };

  //Função que filtra o comentário com base no input de pesquisa (inputSearch)
  const commentFilter = (comment) => {
    if (inputSearch) {
      const commentFilter = comment.desc_comentario
        ?.toLowerCase()
        .includes(inputSearch.toLowerCase());

      if (commentFilter) {
        return comment;
      } else {
        return;
      }
    } else {
      return comment;
    }
  };

  function getDuration() {
    refAudio.current.currentTime = 0;
    refAudio.current.removeEventListener("timeupdate", getDuration);
    let data = new Date(null);
    data.setSeconds(refAudio.current.duration);
    let duracao = data.toISOString().substr(14, 5);
    setAudioDuration(duracao);
  }

  const handleIndex = (index) => {
    if (index === commentsList.filter(commentFilter).length - 1) {
      refIndexComment.current = index;
    }
  };

  const handleOrderClick = (comments) => {
    let newList = [...comments];

    newList.sort((a, b) => {
      if (a.data_insercao < b.data_insercao) return 1;
      if (a.data_insercao > b.data_insercao) return -1;
      return 0;
    });

    return newList;
  };

  //USE EFFECTS

  //useeffect para fazer o scroll ficar na parte inferior dos comentários.
  useEffect(() => {
    if (
      commentsList.filter(commentFilter).length ===
      refIndexComment.current + 1 &&
      loadingComment === "awaiting" &&
      ref.current
    ) {
      ref.current?.scrollTo({
        top: ref.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [loadingComment, inputSearch]);

  useEffect(() => {
    if (permissionUsers) {
      if (!permissionUsers.visualizacao) {
        setPermited(false);
      }
    }
  }, [permissionUsers]);

  useEffect(() => {
    if (commentsList && sortedList) {
      const sortList = handleOrderClick(commentsList);
      setComments(sortList);
    } else {
      setComments(commentsList);
    }
  }, [commentsList, sortedList]);

  return (
    <>
      {commentType === 2 ? (
        <div className="container-comments">
          <div className={activeBorderBottom ? "container-comments__header border-bottom" : "container-comments__header"}>
            <div className="container-comments__header__content">
              <Typography.H5 color="blue-selected-4" weight="bold">
                Comentários
              </Typography.H5>

              <div
                className="ordination"
                onClick={() => setSortedList(!sortedList)}
              >
                <Typography.H5 color="gray-180" weight="400">
                  Mais recentes primeiro
                </Typography.H5>

                <IconArrowLeft className={sortedList ? "active" : ""} />
              </div>
            </div>

            {permissionUsers ? (
              permissionUsers.insercao ? (
                <>
                  <label className="comment-content-container">
                    <div className="comment-content-container__add-comment">
                      <IconUser />

                      <SendingComment
                        autoWidth
                        audioToTheCenter
                        ref={refSendingComment}
                        onAdd={onAdd}
                        setFiles={setFiles}
                        files={files}
                        commentResponse={commentResponse}
                        setCommentResponse={setCommentResponse}
                        setLoadingComment={setLoadingComment}
                        loadingComment={loadingComment}
                      />
                    </div>

                    <div className="comment-content-container__responses">
                      {files.length > 0 && (
                        <ColumnAttach setFiles={setFiles} files={files} limit={3} />
                      )}

                      {commentResponse && (
                        <div className="container-response-list">
                          {commentResponse.id_tipo_comentario === 3 && (
                            <audio
                              ref={refAudio}
                              src={`${urlIngWeb}${commentResponse.filesAssociated[0].path_arquivos}`}
                              onLoadedMetadata={() => {
                                if (refAudio.current.duration === Infinity) {
                                  refAudio.current.currentTime = 1e101;
                                  refAudio.current.addEventListener(
                                    "timeupdate",
                                    getDuration
                                  );
                                }
                              }}
                            ></audio>
                          )}

                          <div className="response-list">
                            <Typography.Paragraph color="gray-125">
                              Respondendo para {commentResponse.nome_usuario}
                            </Typography.Paragraph>

                            <Typography.Paragraph
                              color="gray-180"
                              className="comment-list-response__text"
                            >
                              {commentResponse.desc_comentario
                                ? `${commentResponse.desc_comentario}`
                                : ""}{" "}
                              {commentResponse.id_tipo_comentario === 3 && (
                                <Typography.Paragraph>
                                  &nbsp;
                                  <IconMic />
                                  &nbsp; Mensagem de Voz - {audioDuration}
                                </Typography.Paragraph>
                              )}
                            </Typography.Paragraph>
                          </div>

                          <IconCancel onClick={() => setCommentResponse()} />
                        </div>
                      )}
                    </div>
                  </label>
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                <label className="comment-content-container">
                  <div className="comment-content-container__add-comment">
                    <IconUser />

                    <SendingComment
                      audioToTheCenter
                      wFull
                      ref={refSendingComment}
                      onAdd={onAdd}
                      setFiles={setFiles}
                      files={files}
                      commentResponse={commentResponse}
                      setCommentResponse={setCommentResponse}
                      setLoadingComment={setLoadingComment}
                      loadingComment={loadingComment}
                    />
                  </div>

                  <div className="comment-content-container__responses">
                    {files.length > 0 && (
                      <ColumnAttach setFiles={setFiles} files={files} limit={3} />
                    )}

                    {commentResponse && (
                      <div className="container-response-list">
                        {commentResponse.id_tipo_comentario === 3 && (
                          <audio
                            ref={refAudio}
                            src={`${urlIngWeb}${commentResponse.filesAssociated[0].path_arquivos}`}
                            onLoadedMetadata={() => {
                              if (refAudio.current.duration === Infinity) {
                                refAudio.current.currentTime = 1e101;
                                refAudio.current.addEventListener(
                                  "timeupdate",
                                  getDuration
                                );
                              }
                            }}
                          ></audio>
                        )}

                        <div className="response-list">
                          <Typography.Paragraph color="gray-125">
                            Respondendo para {commentResponse.nome_usuario}
                          </Typography.Paragraph>

                          <Typography.Paragraph
                            color="gray-180"
                            className="comment-list-response__text"
                          >
                            {commentResponse.desc_comentario
                              ? `${commentResponse.desc_comentario}`
                              : ""}{" "}
                            {commentResponse.id_tipo_comentario === 3 && (
                              <Typography.Paragraph>
                                &nbsp;
                                <IconMic />
                                &nbsp; Mensagem de Voz - {audioDuration}
                              </Typography.Paragraph>
                            )}
                          </Typography.Paragraph>
                        </div>

                        <IconCancel onClick={() => setCommentResponse()} />
                      </div>
                    )}
                  </div>
                </label>
              </>
            )}
          </div>

          <div className="container-comments__list">
            {permited && comments.length > 0 ? (
              <>
                {comments.map((comment, index) => (
                  <>
                    <Comment
                      commentType={2}
                      formatDateDistanceToNow
                      key={index}
                      comment={comment}
                      setCommentResponse={(event) => {
                        refSendingComment.current.focus();
                        setCommentResponse(event);
                      }}
                      onUpdate={onUpdate}
                      onDelete={onDelete}
                      setLoadingComment={setLoadingComment}
                      setLoadingUpdateComment={setLoadingUpdateComment}
                      loadingUpdateComment={loadingUpdateComment}
                      commentAnswered={verifyCommentAnswered(comment)}
                      inputSearch={inputSearch}
                      permissionUsers={permissionUsers}
                    />
                    {handleIndex(index)}
                  </>
                ))}
              </>
            ) : (
              <div className="container-comments__list__empty-state">
                <div
                  className={`container-icon ${permissionUsers && !permissionUsers.visualizacao
                    ? "red-color"
                    : ""
                    }`}
                >
                  {permissionUsers ? (
                    permissionUsers.visualizacao ? (
                      <IconMessage />
                    ) : (
                      <IconEyeSlash />
                    )
                  ) : (
                    <IconMessage />
                  )}
                </div>
                <Typography.H4 color="black">
                  {permissionUsers && !permissionUsers.visualizacao
                    ? "Você não tem permissão para visualizar os comentários"
                    : "Envie um comentário por texto ou grave uma mensagem."}
                </Typography.H4>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="comment-list">
          {commentsList.length > 0 && permited && (
            <Input
              value={inputSearch}
              onChange={(value) => setInputSearch(value)}
              className="comment-list-search"
              rightIcon={<IconSearch />}
              placeholder="Buscar por palavras-chave"
            />
          )}
          <div className="comment-list-container">
            {permited && commentsList.length > 0 ? (
              <div className="comment-list__list" ref={ref}>
                {commentsList.filter(commentFilter).length > 0 ? (
                  <>
                    {commentsList.filter(commentFilter).map((comment, index) => (
                      <>
                        <Comment
                          key={index}
                          comment={comment}
                          setCommentResponse={(event) => {
                            refSendingComment.current.focus();
                            setCommentResponse(event);
                          }}
                          onUpdate={onUpdate}
                          onDelete={onDelete}
                          setLoadingComment={setLoadingComment}
                          setLoadingUpdateComment={setLoadingUpdateComment}
                          loadingUpdateComment={loadingUpdateComment}
                          commentAnswered={verifyCommentAnswered(comment)}
                          inputSearch={inputSearch}
                          permissionUsers={permissionUsers}
                        />
                        {handleIndex(index)}
                      </>
                    ))}
                  </>
                ) : (
                  <div className="comment-list-container__empty-state">
                    <div className="container-icon">
                      <IconMessage />
                    </div>
                    <Typography.H3 color="black">
                      Nenhum comentário encontrado.
                    </Typography.H3>
                  </div>
                )}
              </div>
            ) : (
              <div className="comment-list-container__empty-state">
                <div
                  className={`container-icon ${permissionUsers && !permissionUsers.visualizacao
                    ? "red-color"
                    : ""
                    }`}
                >
                  {permissionUsers ? (
                    permissionUsers.visualizacao ? (
                      <IconMessage />
                    ) : (
                      <IconEyeSlash />
                    )
                  ) : (
                    <IconMessage />
                  )}
                </div>
                <Typography.H4 color="black">
                  {permissionUsers && !permissionUsers.visualizacao
                    ? "Você não tem permissão para visualizar os comentários"
                    : "Envie um comentário por texto ou grave uma mensagem."}
                </Typography.H4>
              </div>
            )}
          </div>
          {permissionUsers ? (
            permissionUsers.insercao ? (
              <div className={`send-comment-container`}>
                {commentResponse && (
                  <div className="comment-list-response-container">
                    {commentResponse.id_tipo_comentario === 3 && (
                      <audio
                        ref={refAudio}
                        src={`${urlIngWeb}${commentResponse.filesAssociated[0].path_arquivos}`}
                        onLoadedMetadata={() => {
                          if (refAudio.current.duration === Infinity) {
                            refAudio.current.currentTime = 1e101;
                            refAudio.current.addEventListener(
                              "timeupdate",
                              getDuration
                            );
                          }
                        }}
                      ></audio>
                    )}
                    <div className="comment-list-response">
                      <Typography.Paragraph color="gray-125">
                        Respondendo para {commentResponse.nome_usuario}
                      </Typography.Paragraph>

                      <Typography.Paragraph
                        color="gray-180"
                        className="comment-list-response__text"
                      >
                        {commentResponse.desc_comentario
                          ? `${commentResponse.desc_comentario}`
                          : ""}{" "}
                        {commentResponse.id_tipo_comentario === 3 && (
                          <Typography.Paragraph>
                            &nbsp;
                            <IconMic />
                            &nbsp; Mensagem de Voz - {audioDuration}
                          </Typography.Paragraph>
                        )}
                      </Typography.Paragraph>
                    </div>
                    <IconCancel onClick={() => setCommentResponse()} />
                  </div>
                )}
                {files.length > 0 && (
                  <ColumnAttach files={files} setFiles={setFiles} limit={5} />
                )}
                <SendingComment
                  ref={refSendingComment}
                  onAdd={onAdd}
                  setFiles={setFiles}
                  files={files}
                  commentResponse={commentResponse}
                  setCommentResponse={setCommentResponse}
                  setLoadingComment={setLoadingComment}
                  loadingComment={loadingComment}
                />
              </div>
            ) : (
              <></>
            )
          ) : (
            <div className={`send-comment-container`}>
              {commentResponse && (
                <div className="comment-list-response-container">
                  {commentResponse.id_tipo_comentario === 3 && (
                    <audio
                      ref={refAudio}
                      src={`${urlIngWeb}${commentResponse.filesAssociated[0].path_arquivos}`}
                      onLoadedMetadata={() => {
                        if (refAudio.current.duration === Infinity) {
                          refAudio.current.currentTime = 1e101;
                          refAudio.current.addEventListener(
                            "timeupdate",
                            getDuration
                          );
                        }
                      }}
                    ></audio>
                  )}
                  <div className="comment-list-response">
                    <Typography.Paragraph color="gray-125">
                      Respondendo para {commentResponse.nome_usuario}
                    </Typography.Paragraph>

                    <Typography.Paragraph
                      color="gray-180"
                      className="comment-list-response__text"
                    >
                      {commentResponse.desc_comentario
                        ? `${commentResponse.desc_comentario}`
                        : ""}{" "}
                      {commentResponse.id_tipo_comentario === 3 && (
                        <Typography.Paragraph>
                          &nbsp;
                          <IconMic />
                          &nbsp; Mensagem de Voz - {audioDuration}
                        </Typography.Paragraph>
                      )}
                    </Typography.Paragraph>
                    {/* <Typography.Paragraph
                          className="comment-list-response__text"
                          color="gray-200"
                        >
                          Respondendo para {commentResponse.nome_usuario}
                          {commentResponse.desc_comentario
                          ? `${commentResponse.desc_comentario}`: ""}{" "}
                          {commentResponse.id_tipo_comentario === 3 && (
                            <>
                              &nbsp;
                              <IconMic />
                              &nbsp; Mensagem de Voz - {audioDuration}
                            </>
                            )}
                        </Typography.Paragraph> 
                      */}
                  </div>
                  <IconCancel onClick={() => setCommentResponse()} />
                </div>
              )}
              {files.length > 0 && (
                <ColumnAttach files={files} setFiles={setFiles} limit={5} />
              )}
              <SendingComment
                ref={refSendingComment}
                onAdd={onAdd}
                setFiles={setFiles}
                files={files}
                commentResponse={commentResponse}
                setCommentResponse={setCommentResponse}
                setLoadingComment={setLoadingComment}
                loadingComment={loadingComment}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
