//GLOBAL - components from npm
import React, { useRef, useEffect, useState } from "react";

// UTILS
import { Toast } from "../../../utils/toast";

//STYLES
import "./attach-file.scss";

//COMPONENTS
import { Typography } from "../../general";

//SERVICES - api, conectors...
import * as APIMimetype from "../../../services/api/mimetypes";
import instance from "../../../services/api/configAxios";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconPaperClip } from "../../../assets/icons/PaperClip.svg";

export default function AttachFile({
  setFiles,
  text,
  textIcon,
  icon,
  imageFile = false,
  isDownload,
  screen,
}) {
  //GENERAL
  const inputRef = useRef();
  const token = useSelector((state) => state.auth.token);
  // const mimetypeList = useSelector((state) => state.filters.mimetypes);

  //STATES
  const [mimetypeList, setMimetypeList] = useState();
  const [mimetypeImg, setMimetypeImg] = useState([]);
  const [templateImg, setTemplateImg] = useState([]);
  //const [filesList, setFilesList] = useState()

  //REDUX - Selectors

  //FUNCTIONS
  const listMimetypes = async () => {
    const response = await APIMimetype.listMimetypes(token);

    try {
      setMimetypeList(response.data);
    } catch (err) {
      console.log("Error: ", err.message);
      return;
    }
  };

  const listMimetypesImg = async () => {
    const imgList = mimetypeList?.filter((type) => type.typename === "image");
    setMimetypeImg(imgList);
  };

  const doAttach = async (e) => {
    const arrayFiles = e.target.files;
    const arrayFileMimetype = [];

    for (const file of arrayFiles) {
      const mimetype = mimetypeList.find((item) => item.template === file.type);
      const mimetypeVerify = mimetypeImg.find(
        (item) => item.template === file.type
      );

      if (imageFile && !mimetypeVerify) {
        inputRef.current.value = "";
        return Toast(
          "Erro ao anexar arquivo!",
          `O arquivo ${file.name} não é suportado.`,
          "error"
        );
      }

      if (isDownload === true) {
        const form = new FormData();
        form.append("files", file);
        try {
          const config = {
            headers: {
              "x-access-token": token,
              "Content-Type": "application/json",
            },
          };
          const response = await instance.post(
            "/insert-file-no-base",
            form,
            config
          );
          file.path = response.data[0].path;
        } catch (error) {
          return Toast(
            "Erro ao anexar arquivo!",
            `Erro ao anexar arquivo no servidor!`,
            "error"
          );
        }
      }

      const fileMimetype = {
        id_mimetype: mimetype.id_mimetype,
        file: file,
        path: file.path,
      };

      arrayFileMimetype.push(fileMimetype);
    }

    setFiles(arrayFileMimetype);
    inputRef.current.value = "";
  };

  //USE EFFECTS
  useEffect(() => {
    listMimetypes();
  }, []);

  useEffect(() => {
    if (mimetypeList) {
      listMimetypesImg();
    }
  }, [mimetypeList]);

  useEffect(() => {
    const arrayTemplate = [];
    mimetypeImg.forEach((img) => arrayTemplate.push(img.template));

    setTemplateImg(arrayTemplate);
  }, [mimetypeImg]);

  return (
    <div className="attach-file">
      <input
        type="file"
        ref={inputRef}
        multiple
        onChange={(e) => doAttach(e)}
        accept={imageFile && templateImg?.join(", ")}
      />
      {text ? (
        <div
          className="attach-file__text"
          onClick={() => inputRef.current.click()}
        >
          <Typography.H5 color="primary-dark">{text}</Typography.H5>
        </div>
      ) : textIcon ? (
        <div
          className={
            screen === "communicated"
              ? "attach-file__icon-communicated"
              : "attach-file__icon"
          }
          onClick={() => inputRef.current.click()}
        >
          {icon}
          <Typography.H5 color="primary-dark">{textIcon}</Typography.H5>
        </div>
      ) : (
        <IconPaperClip onClick={() => inputRef.current.click()} />
      )}
    </div>
  );
}
