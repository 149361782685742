//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// UTILS

//COMPONENTS
import { Modal } from "./../../../feedback";
import { Button, Typography } from "./../../../general";
import { FieldContainer } from "../../../data-display";
import { Input } from "../../../data-entry";
import { Toast } from "./../../../../utils/toast";
import { maskCpfCnpj } from "../../../../utils/masks";
import Loading from "../../Loading";

//SERVICES - api, conectors...
import * as APIContact from "./../../../../services/api/APIContact";

//ASSETS - icons, images...
import { ReactComponent as ImgShop } from "./../../../../assets/images/shop.svg";

//STYLES
import "./update-company.scss";

export default function UpdateCompany({
  isOpen,
  onClose,
  onEdit,
  companysData,
}) {
  //REDUX - Selectors
  const token = useSelector((state) => state.auth.token);

  //GENERAL
  const companyId = isOpen || null;
  var validURL =
    /((https?:\/\/)|(ftp:\/\/)|(^))([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+([a-zA-Z]{2,9})(:\d{1,4})?([-\w\/#~:.?+=&amp;%@~]*)/;
  var validCNPJ = /(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/;

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [validationWarn, setValidationWarn] = useState(false);
  const [inputErrorEmail, setInputErrorEmail] = useState(false);
  const [inputErrorCnpj, setInputErrorCnpj] = useState(false);
  const [inputErrorSite, setInputErrorSite] = useState(false);
  const [onBlurInputErrorEmail, setonBlurInputErrorEmail] = useState(false);
  const [onBlurInputErrorCnpj, setonBlurInputErrorCnpj] = useState(false);
  const [onBlurInputErrorSite, setonBlurInputErrorSite] = useState(false);
  const [data, setData] = useState();
  const [id, setId] = useState();

  //FUNCTIONS
  function getValues(idCompany) {
    companysData?.forEach((item) => {
      if (item.id_empresa === idCompany) {
        setData({
          ...item,
          cnpj: item.cnpj && maskCpfCnpj(item.cnpj),
        });
        setId(item.id_empresa);
      }
    });
  }

  function onCloseCustom() {
    onClose();
    setTimeout(() => {
      setInputErrorSite(false);
      setInputErrorCnpj(false);
      setInputErrorEmail(false);
      setInputErrorSite(false);
      setPageStatus("awaiting");
    }, 250);
  }

  function isFormValidated() {
    if (!data?.nome) return false;
    else if (data?.email && !/\S+@\S+\.\S+/.test(data?.email)) return false;
    else if (
      (data?.cnpj && validCNPJ.test(data?.cnpj) === false) ||
      data?.cnpj === "00.000.000/0000-00" ||
      data?.cnpj === "11.111.111/1111-11" ||
      data?.cnpj === "22.222.222/2222-22" ||
      data?.cnpj === "33.333.333/3333-33" ||
      data?.cnpj === "44.444.444/4444-44" ||
      data?.cnpj === "55.555.555/5555-55" ||
      data?.cnpj === "66.666.666/6666-66" ||
      data?.cnpj === "77.777.777/7777-77" ||
      data?.cnpj === "88.888.888/8888-88" ||
      data?.cnpj === "99.999.999/9999-99"
    )
      return false;
    else if (data?.site && validURL.test(data?.site) === false) return false;

    return true;
  }

  async function handleEditCompany() {
    if (isFormValidated()) {
      setPageStatus("edit-company");

      const object = { ...data };

      const updatedCompany = {
        id_empresa: id,
        nome: data?.nome,
        nome_fantasia: data?.nome_fantasia,
        cnpj: data?.cnpj
          ? (object.cnpj = object.cnpj.replace(/[-/\.//"]/g, ""))
          : data?.cnpj,
        email: data?.email,
        site: data?.site,
        inscricaoestadual: data?.inscricaoestadual,
        inscricaomunicipal: data?.inscricaomunicipal,
        codigo_empresa: data?.codigo_empresa,
      };

      const response = await APIContact.updateEnterprise(token, updatedCompany);

      if (response.status === 200) {
        Toast(
          "Empresa Atualizada!",
          "Empresa atualizada com sucesso.",
          "success"
        );
        onEdit();
        onCloseCustom();
      } else {
        Toast(
          "Não foi possível atualizar empresa",
          "Tente novamente mais tarde!",
          "error"
        );
      }

      setPageStatus("awaiting");
    } else if (!data?.nome) {
      setValidationWarn(true);

      Toast(
        "Erro ao atualizar empresa!",
        "Verifique o preenchimento dos campos em destaque",
        "error"
      );
    } else if (data?.email && !/\S+@\S+\.\S+/.test(data?.email)) {
      setInputErrorEmail(true);
      setonBlurInputErrorEmail(true);
    } else if (
      (data?.cnpj && validCNPJ.test(data?.cnpj) === false) ||
      data?.cnpj === "00.000.000/0000-00" ||
      data?.cnpj === "11.111.111/1111-11" ||
      data?.cnpj === "22.222.222/2222-22" ||
      data?.cnpj === "33.333.333/3333-33" ||
      data?.cnpj === "44.444.444/4444-44" ||
      data?.cnpj === "55.555.555/5555-55" ||
      data?.cnpj === "66.666.666/6666-66" ||
      data?.cnpj === "77.777.777/7777-77" ||
      data?.cnpj === "88.888.888/8888-88" ||
      data?.cnpj === "99.999.999/9999-99"
    ) {
      setInputErrorCnpj(true);
      setonBlurInputErrorCnpj(true);
    } else if (data?.site && validURL.test(data?.site) === false) {
      setInputErrorSite(true);
      setonBlurInputErrorSite(true);
    }
  }

  function onBlurEventInputError() {
    if (data?.email && !/\S+@\S+\.\S+/.test(data?.email)) {
      setInputErrorEmail(true);
      setonBlurInputErrorEmail(true);
    } else {
      setInputErrorEmail(false);
      setonBlurInputErrorEmail(false);
    }

    if (
      (data?.cnpj && validCNPJ.test(data?.cnpj) === false) ||
      data?.cnpj === "00.000.000/0000-00" ||
      data?.cnpj === "11.111.111/1111-11" ||
      data?.cnpj === "22.222.222/2222-22" ||
      data?.cnpj === "33.333.333/3333-33" ||
      data?.cnpj === "44.444.444/4444-44" ||
      data?.cnpj === "55.555.555/5555-55" ||
      data?.cnpj === "66.666.666/6666-66" ||
      data?.cnpj === "77.777.777/7777-77" ||
      data?.cnpj === "88.888.888/8888-88" ||
      data?.cnpj === "99.999.999/9999-99"
    ) {
      setInputErrorCnpj(true);
      setonBlurInputErrorCnpj(true);
    } else {
      setInputErrorCnpj(false);
      setonBlurInputErrorCnpj(false);
    }

    if (data?.site && validURL.test(data?.site) === false) {
      setInputErrorSite(true);
      setonBlurInputErrorSite(true);
    } else {
      setInputErrorSite(false);
      setonBlurInputErrorSite(false);
    }
  }

  //USE EFFECTS
  useEffect(() => {
    if (companyId) {
      getValues(companyId);
    }
  }, [companyId]);

  return (
    <Modal.Main
      className="modal-create-company"
      title="Empresa"
      width="1000px"
      isOpen={isOpen}
      onClose={onCloseCustom}
      shouldCloseOnOverlayClick={false}
      footer={
        <div className="container-footer">
          <Button
            className="modal-create-company__btn-cancel"
            onClick={() => onCloseCustom()}
            disabled={pageStatus === "edit-company"}
          >
            <Typography.H4 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H4>
          </Button>

          <Button
            className="modal-create-company__btn-confirm"
            disabled={pageStatus === "edit-company"}
            onClick={() => handleEditCompany()}
          >
            {pageStatus !== "edit-company" ? (
              <Typography.H4 weight={"bold"}>Salvar alterações</Typography.H4>
            ) : (
              <Loading.Dots />
            )}
          </Button>
        </div>
      }
    >
      <section className="modal-create-company__content">
        <div className="modal-create-icon">
          <ImgShop className="icon-shop" />
        </div>

        <div className="container-full-line">
          <FieldContainer required title="Nome da empresa">
            <Input
              type="text"
              id="nome_completo"
              placeholder="Digite seu nome"
              onChange={(event) => setData({ ...data, nome: event })}
              value={data?.nome}
              error={validationWarn ? !data?.name : false}
            />
          </FieldContainer>
          <FieldContainer title="CNPJ">
            <Input
              type="text"
              id="cnpj"
              placeholder="XX.XXX.XXX/XXXX-XX"
              onBlur={onBlurEventInputError}
              onChange={(event) =>
                setData({ ...data, cnpj: maskCpfCnpj(event) })
              }
              value={data?.cnpj}
              typeInputMessage={inputErrorCnpj ? "error" : false}
              error={
                onBlurInputErrorCnpj
                  ? (data?.cnpj && validCNPJ.test(data?.cnpj) === false) ||
                    data?.cnpj === "00.000.000/0000-00" ||
                    data?.cnpj === "11.111.111/1111-11" ||
                    data?.cnpj === "22.222.222/2222-22" ||
                    data?.cnpj === "33.333.333/3333-33" ||
                    data?.cnpj === "44.444.444/4444-44" ||
                    data?.cnpj === "55.555.555/5555-55" ||
                    data?.cnpj === "66.666.666/6666-66" ||
                    data?.cnpj === "77.777.777/7777-77" ||
                    data?.cnpj === "88.888.888/8888-88" ||
                    data?.cnpj === "99.999.999/9999-99"
                  : false
              }
              customInputMessage={"Cnpj inválido"}
              maxLength="18"
              // maxLength="14"
            />
          </FieldContainer>
          <FieldContainer title="Código Externo">
            <Input
              type="text"
              placeholder="exemplo123"
              onChange={(event) => setData({ ...data, codigo_empresa: event })}
              value={data?.codigo_empresa}
            />
          </FieldContainer>
          <FieldContainer title="E-mail">
            <Input
              type="email"
              placeholder="email@hotmail.com"
              onBlur={onBlurEventInputError}
              onChange={(event) => setData({ ...data, email: event })}
              value={data?.email}
              typeInputMessage={inputErrorEmail ? "error" : false}
              error={
                onBlurInputErrorEmail
                  ? data?.email && !/\S+@\S+\.\S+/.test(data?.email)
                  : false
              }
              customInputMessage={"E-mail inválido"}
            />
          </FieldContainer>
        </div>

        <div className="container-full-line" style={{ marginTop: "10px" }}>
          <FieldContainer title="Inscrição estudal">
            <Input
              type="number"
              placeholder="XXX.XXX.XXX-XX"
              onChange={(event) =>
                setData({ ...data, inscricaoestadual: event })
              }
              value={data?.inscricaoestadual}
              maxLength="14"
            />
          </FieldContainer>
          <FieldContainer title="Incrição mucipal">
            <Input
              type="number"
              placeholder="XX.XXX.XXX-XX"
              onChange={(event) =>
                setData({ ...data, inscricaomunicipal: event })
              }
              value={data?.inscricaomunicipal}
              maxLength="14"
            />
          </FieldContainer>
          <FieldContainer title="Site">
            <Input
              type="text"
              placeholder="www.exemplo.com"
              onBlur={onBlurEventInputError}
              onChange={(event) => setData({ ...data, site: event })}
              value={data?.site}
              typeInputMessage={inputErrorSite ? "error" : false}
              error={
                onBlurInputErrorSite
                  ? data?.site && validURL.test(data?.site) === false
                  : false
              }
              customInputMessage={"Url inválida"}
            />
          </FieldContainer>
        </div>
      </section>
    </Modal.Main>
  );
}
