//GLOBAL - components from npm
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Toast } from "./../../../utils/toast";

//STYLES

//COMPONENTS
import { Modal } from "./../../../components/feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...

export default function List() {
  //GENERAL

  //STATES
  const [isOpenModalAddCase, setIsOpenModalAddCase] = useState(true);
  const history = useHistory();

  const createSuccess = () => {
    console.log("CASO CRIADO")
  }

  return (
    <>
      <Modal.CreateCase
        isOpen={isOpenModalAddCase}
        onClose={() => {
          history.push("/externo/casos")
        }}
        onCreate={() => {
          createSuccess()
        }}
        isDirect={true}
      />
    </>
  );
}
