const renderPhone = (phoneNumber) => {
  switch (phoneNumber.length) {
    case 11:
      if (phoneNumber.startsWith("0", 0)) {
        return `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(
          3,
          4
        )}-${phoneNumber.substr(7)}`;
      } else {
        return `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(
          2,
          5
        )}-${phoneNumber.substr(7)}`;
      }
    case 10:
      return `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(
        2,
        4
      )}-${phoneNumber.substr(6)}`;

    case 9:
      return `${phoneNumber.substr(0, 5)}-${phoneNumber.substr(5)}`;

    case 8:
      return `${phoneNumber.substr(0, 4)}-${phoneNumber.substr(4)}`;

    default:
      return `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(
        2,
        5
      )}-${phoneNumber.substr(7)}`;
  }
};

export default renderPhone;
