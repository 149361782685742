import React from "react";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import { Typography } from "../../general";
import { RadialBarChart } from "../../data-graphics/";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as GpsIcon } from "../../../assets/icons/Gps.svg";
import { ReactComponent as IconMoreVertical } from "../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as FileSearching } from "../../../assets/icons/File-searching.svg";

export default function CardResolution({ card, openModal, darkMode }) {
  //GENERAL
  const data = [
    {
      id: card.title,
      data: [
        {
          x: "Total",
          y: card.percentage === "100.0" ? 100 : card.percentage,
          color: "#00ACC1",
          colorTrack: "rgba(0, 172, 193, .15)",
        },
      ],
    },
  ];

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  return (
    <div className="card-dashboard__resolution">
      <div className="card-dashboard__resolution-box_header">
        <Typography.H4 color={darkMode ? "white" : "gray-180"} weight={700}>
          {card.title}
        </Typography.H4>
        <button className="card-btn-options" onClick={openModal} type="button">
          <IconMoreVertical />
        </button>
      </div>
      <hr className="card-dashboard__resolution-border"></hr>

      {card.emptySpace ? (
        <div className="card-dashboard-emptySpace column">
          <FileSearching width={120} height={120} />
          <Typography.H5 color={darkMode ? "white" : "gray-180"}>
            Oops! A sua pesquisa não gerou resultados.
          </Typography.H5>
        </div>
      ) : (
        <>
          <div className="card-dashboard__resolution-view">
            <RadialBarChart
              data={data}
              darkMode={darkMode}
              internalRadius={0.7}
              centerSize={32}
              centerWeight={700}
              bottom={-100}
              centerFill={"#00ACC1"}
              halfCenter
            />
          </div>
          <div className="card-dashboard__resolution-container__box">
            <div className="card-dashboard__resolution-container__box-card">
              <GpsIcon className={darkMode ? "darkModeIcon" : ""} />
            </div>
            <Typography.H6
              className="card-dashboard__resolution-subtitle"
              color={darkMode ? "white" : "gray-180"}
            >
              {card.subtitle}
            </Typography.H6>
          </div>
        </>
      )}
    </div>
  );
}
