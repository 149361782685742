//GLOBAL - components from npm
import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useReactMediaRecorder } from "react-media-recorder";
import parse from "html-react-parser";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";

// UTILS
import { Toast } from "../../../../utils/toast";
import formatTime from "../../../../utils/formatTime";

//STYLES
import "./case-communications.scss";

//COMPONENTS
import { Loading, Modal, Tippy } from "./../../../../components/feedback";
import {
  Select,
  AttachFile,
  ButtonSelectSearch,
  FillContactSearch,
  Audio,
  Input,
} from "../../../../components/data-entry";
import {
  Communicated,
  FieldContainer,
  Channel,
  FilterTag,
  ColumnAttach,
  QuillToolbar,
  FieldContainerEdit,
} from "../../../../components/data-display";
import { Button, Typography } from "../../../../components/general";

//SERVICES - api, conectors...
import email from "../../../../services/api/configEmail";
import * as APIClient from "./../../../../services/api/client";
import * as APICase from "./../../../../services/api/case";
import * as APIFiles from "../../../../utils/files";
import instance from "./../../../../services/api/configAxios";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconUndo } from "../../../../assets/icons/Undo.svg";
import { ReactComponent as IconSend } from "../../../../assets/icons/Send.svg";
import { ReactComponent as IconPlusCircle } from "../../../../assets/icons/PlusCircle.svg";
import { ReactComponent as IconMic } from "../../../../assets/icons/Mic.svg";
import { ReactComponent as IconTrash } from "../../../../assets/icons/Trash.svg";
import { ReactComponent as IconRec } from "../../../../assets/icons/Rec.svg";
import { ReactComponent as IconRecord } from "../../../../assets/icons/Record.svg";
import { ReactComponent as SendTwo } from "../../../../assets/icons/SendTwo.svg";
import { ReactComponent as SendTwoWhite } from "../../../../assets/icons/SendTwoWhite.svg";
import { ReactComponent as SmsEdit } from "../../../../assets/icons/SmsEdit.svg";
import { ReactComponent as IconAttachment } from "../../../../assets/icons/Attachment.svg";
import { ReactComponent as IconMaximize } from "../../../../assets/icons/Maximize.svg";
import { ReactComponent as IconEyeSlash } from "../../../../assets/icons/Eye-slash.svg";
import { ReactComponent as Pause } from "../../../../assets/icons/Pause-2.svg";
import { ReactComponent as Play } from "../../../../assets/icons/PlayIcon.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/Arrow-left.svg";
import { red } from "@mui/material/colors";
import { connection_platform } from "../../../../services/api/configConnection";

Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);
const Delta = ReactQuill.Quill.import("delta");

export default function CaseCommunications(props) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);
  let refPage = useRef(false);

  const myUndo = () => {
    let myEditor = this.refPage.current.getEditor();
    return myEditor.history.undo();
  };

  const myRedo = () => {
    let myEditor = this.refPage.current.getEditor();
    return myEditor.history.redo();
  };

  const modules = useMemo(
    () => ({
      history: {
        delay: 1000,
        maxStack: 1000,
        userOnly: false,
      },
      imageDropAndPaste: {
        handler: imageHandler,
      },
      toolbar: {
        container: "#toolbar-maximized",
      },
    }),
    []
  );

  const formats = [
    "undo",
    "redo",
    "bold",
    "italic",
    "underline",
    "attach",
    "link",
    "template",
    "audio",
    "variableEdit",
    "image",
  ];
  const headerEmail = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const channelList = [
    // { id: 1, label: "Whatsapp" },
    // { id: 2, label: "Facebook" },
    { id: 3, label: "Email" },
  ];
  const audioTime = useRef(0);

  const user = useSelector((state) => state.auth.userData);
  const agent = {
    id: user.userId,
    name: user.userName,
  };

  //STATES
  const [loading, setLoading] = useState(true);
  const [communicatedState, setCommunicatedState] = useState("list");
  const [valueQuill, setValueQuill] = useState("");
  const [arrayFiles, setArrayFiles] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [sendChannels, setSendChannels] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [audioOpen, setAudioOpen] = useState(false);
  const [timeAudio, setTimeAudio] = useState("00:00");
  const [sendAudio, setSendAudio] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [test, setTest] = useState(false);
  const [listCategories, setListCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [templatesList, setTemplatesList] = useState([]);
  const [template, setTemplate] = useState(null);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [isOpenModalSendCommunicated, setIsOpenModalSendCommunicated] =
    useState(false);
  const [communicationList, setCommunicatorList] = useState([]);
  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({ audio: true });
  const [isOpenModalVariableEdit, setIsOpenModalVariableEdit] = useState(false);
  const [variableEdit, setVariableEdit] = useState("false");
  const [valueHtml, setValueHtml] = useState("");

  //REDUX - Selectors
  const filtersContacts = useSelector((state) => state.filters.contacts);

  //FUNCTIONS
  const getCommunicationInfo = async () => {
    setLoading(true);

    setLoading(false);
  };

  const handleQuillValueChange = (newQuillValue) =>
    setTimeout(() => {
      setValueQuill(newQuillValue);
    });

  //Função que recebe o canal e coloca no estado de 'sendChannel' que guarda os canais que irão receber o comunicado.
  const handleChannelList = (channel) => {
    const currentChannelList = [...sendChannels];
    const currentChannel = currentChannelList.find(
      (item) => item.id === channel.id
    );

    if (currentChannel) {
      setSendChannels(
        currentChannelList.filter((item) => item.id !== channel.id)
      );
    } else {
      currentChannelList.push(channel);
      setSendChannels(currentChannelList);
    }
  };

  async function getListCompanysEmails(searchParams, page) {
    const response = await APIClient.getListCompanysEmails(token, page, searchParams)
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.email,
      };

      updatedData.push(updatedClient);
    })

    return updatedData;
  }

  //Função que busca a lista de clientes e transforma no padrão do componente.
  async function getListClients(searchParams, page) {
    const response = await APIClient.all(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.email,
      };

      updatedData.push(updatedClient);
    });

    return updatedData;
  }

  //Função que busca a lista de comunicados e transforma no padrão do componente.
  async function getListCommunicators() {
    const response = await APICase.getListCommunicators(
      token,
      props.case.id_ticket
    );
    const data = response.data;
    const updatedData = [];
    let updatedFile = [];
    let updatedFileAssociated = [];
    let updatedMidia = [];

    data.forEach((communicator) => {
      communicator.arquivos.forEach((file) => {
        const structFile = {
          file: {
            name: file.path.split("/").pop(),
            size: 1000,
            type: file.mimetype_id,
            path_arquivos: file.path,
            extension: ".png",
            id: file.id_comunicado_arquivo,
          },
        };
        const structFileAssociated = {
          id_comunicado_arquivo: file.id_comunicado_arquivo,
          path_arquivos: file.path,
          data_insercao: "2022-01-04T14:00:03.326Z",
          data_exclusao: null,
          id_mimetype: file.mimetype_id,
          id_comunicado: communicator.id_comunicado,
        };
        updatedFileAssociated.push(structFileAssociated);
        updatedFile.push(structFile);
      });
      communicator.midias.forEach((midia) => {
        updatedMidia.push(midia.id_midia);
      });
      const updatedComunicado = {
        id_comunicado: communicator.id_comunicado,
        desc_comunicado: communicator.desc_comunicado,
        data_insercao: communicator.data_insercao,
        data_exclusao: null,
        id: communicator.id_comunicado,
        id_mimetype: 1,
        id_tipo_comunicado: 1,
        usuario_insercao: communicator.id_usuario_insercao,
        nome_usuario: communicator.nome,
        filesAssociated: updatedFileAssociated,
        files: updatedFile,
        midias: updatedMidia,
        destinatarios: communicator.destinatarios,
      };
      updatedData.push(updatedComunicado);
      updatedFile = [];
      updatedFileAssociated = [];
      updatedMidia = [];
    });
    setCommunicatorList(updatedData);
    setLoading(false);
  }

  //Função que filtra a lista de comunicados com base no que foi escrito no input de pesquisa (inputSeach).
  const filteredCommunicated = (communicated) => {
    if (inputSearch) {
      const communicatedFilter = communicated.desc_comunicado
        ?.toLowerCase()
        .includes(inputSearch.toLowerCase());

      if (communicatedFilter) {
        return communicated;
      } else {
        return;
      }
    } else {
      return communicated;
    }
  };

  const checkMicrophone = () => {
    navigator.permissions
      .query({ name: "microphone" })
      .then(function (permissionStatus) {
        if (permissionStatus.state !== "granted") {
          Toast(
            "Microfone Desativado!",
            "Por favor, ative a permissão do microfone.",
            "warn"
          );
          getLocalStream();
        } else {
          startRecording();
          setAudioOpen(true);
        }
      });
  };

  //Função que busca a lista de categorias de template na API e transforma no modelo padrão dos componentes.
  const getListCategories = async () => {
    const response = await APICase.getCategoriesTemplate(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_template_categoria;
        requestItem.label = datum.descricao;

        dataUpdated.push(requestItem);
      });

      setListCategories(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  const setArrayFilesOptions = (file) => {
    let files = arrayFiles;
    file.forEach((todo) => {
      files.push(todo);
    });
    setArrayFiles(files);
    setTest(!test);
  };

  async function changeCategory(idCategory) {
    setArrayFiles([]);

    const response = await APICase.getSubCategoriesTemplate(token, idCategory);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_template_subcategoria;
        requestItem.label = datum.descricao;
        requestItem.mimetype = datum.id_mimetype;
        requestItem.message = datum.path_template_subcategoria_arquivo;

        dataUpdated.push(requestItem);
      });
      setTemplate(null);
      setTemplatesList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  const changeTemplate = async (idSubCategory) => {
    setArrayFiles([]);

    const response = await APICase.getPathsSubCategoriesTemplate(
      token,
      idSubCategory
    );

    let ArrayPaths = response.data;
    let message = "";
    for (const path of ArrayPaths) {
      if (path.id_mimetype === 1) {
        message = path.path_template_subcategoria_arquivo;
      }
      if (path.id_mimetype !== 1) {
        let files = arrayFiles;
        const response = await APIFiles.handleFiles(
          path.path_template_subcategoria_arquivo
        );
        const fileObject = {
          file: {
            name: response.name,
            size: response.size,
            type: response.typeMime,
            path: path.path_template_subcategoria_arquivo,
            path_arquivos: path.path_template_subcategoria_arquivo,
            extension: response.type,
            id_mimetype: path.id_mimetype,
            // id: path.id_template_subcategoria,
          },
        };
        files.push(fileObject);
        setArrayFiles(files);
      }
    }

    message = message.replace(/\r\n/g, "<br />");
    setVariableEdit(() => message);
    message = verifierVariable(message);
    setTemplate(idSubCategory);
    setValueQuill(message);
  };

  const verifierVariable = (message) => {
    message = message.replace(
      /\${SYS.NOMECLIENTE}/gi,
      `${props.case.nome_contato}`
    );
    message = message.replace(
      /\${SYS.NUMEROCASO}/gi,
      `${props.case.codigo_ticket}`
    );
    message = message.replace(/\${SYS.SAUDACAO}/gi, verifierGreeting());
    return message;
  };

  function verifierGreeting() {
    let complete = new Date();
    let hours = complete.getHours();
    let minutes = complete.getMinutes();
    let seconds = complete.getSeconds();
    if (hours < 12 && minutes < 60 && seconds < 60) return "Bom dia";
    else if (hours < 18 && minutes < 60 && seconds < 60) return "Boa tarde";
    else if (hours < 23 && minutes < 60 && seconds < 60) return "Boa noite";

    return "";
  }

  const sendCommunicator = async () => {
    const updatedRecipient = [];
    const updatedFile = [];
    let mensagem_erro = "";
    setIsSending(true);

    let listMails = emailList.filter((email) => email.caption1 !== "");
    listMails = listMails.map((email) => email.caption1);
    let listFiles = arrayFiles.map((file) => file.file.path);
    let listChannels = sendChannels.map((channel) => channel.id);

    if (emailList.length === 0 && sendChannels.length !== 0) {
      Toast("Comunicado não enviado!", "Campo 'Destinario' em Branco", "error");
      setIsSending(false);
      return true;
    }

    setIsOpenModalSendCommunicated(false);

    if (sendChannels.filter((option) => option.id === 3).length !== 0) {
      const jsonMail = {
        ASSUNTO: `Número do Caso [${props.case.codigo_ticket}] - ${props.case.descricao_solicitacao} - Status [${props.case.descricao_ticket_status}]`,
        DESTINATARIO: listMails.join(),
        COPIA: "",
        ANEXO: listFiles.join(),
        CORPOEMAIL: valueQuill,
      };
      try {
        const sendMail = await email.post(`/`, jsonMail);
        const response = sendMail.data;

        if (response.SUCCESS === "FALSE") {
          // Toast(
          //   "Comunicado não enviado!",
          //   "Erro ao enviar email, verifique o email.",
          //   "error"
          // );
          mensagem_erro = response.MSG;
          // setIsSending(false);
          // return true;
        }
      } catch (error) {
        Toast("Comunicado não enviado!", "Erro ao enviar email", "error");
        setIsSending(false);
        return true;
      }
    }

    arrayFiles.forEach((file) => {
      const structFile = {
        filename: file.file.path,
        id_mimetype: 3,
      };

      updatedFile.push(structFile);
    });

    sendChannels.length > 0 &&
      emailList
        .filter((recipient) => recipient.caption1)
        .forEach((recipient) => {
          const structRecipient = {
            id_contato: recipient.id ?? null,
            desc_destinatario: recipient.caption1,
            id_midia: 3,
          };

          updatedRecipient.push(structRecipient);
        });

    const data = mensagem_erro
      ? {
          id_ticket: props.case.id_ticket,
          desc_comunicado: valueQuill,
          destinatarios: updatedRecipient,
          midias: listChannels,
          files: updatedFile,
          mensagem_erro: mensagem_erro,
        }
      : {
          id_ticket: props.case.id_ticket,
          desc_comunicado: valueQuill,
          destinatarios: updatedRecipient,
          midias: listChannels,
          files: updatedFile,
        };

    const response = await APICase.createCommunicator(token, data);
    getListCommunicators();

    Toast("Comunicado enviado!", "Comunicado enviado com sucesso", "success");
    setIsOpenModalVariableEdit(false);
    setCommunicatedState("list");
    setValueQuill("");
    setValueHtml("");
    setArrayFiles([]);
    // setSendChannels([]);
    setEmailList([]);
    setCategory(null);
    setTemplate(null);
    setIsSending(false);
    if (props.case.email) {
      setEmailList([
        {
          label: props.case.nome_contato,
          caption1: props.case.email,
        },
      ]);
      setSendChannels([
        {
          id: 3,
          label: "Email",
        },
      ]);
    }
    props.handleInteractionSaveImmediate(props.case.id_ticket);
    props.updateAgent(agent);
  };

  function getLocalStream() {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        window.localStream = stream; // A
        window.localAudio.srcObject = stream; // B
        window.localAudio.autoplay = true; // C
      })
      .catch((err) => {
        console.log("u got an error:" + err);
      });
  }

  const visibleSelectContact = () => {
    let element = document.getElementById("select-search-button");
    element.getElementsByClassName("button-select-search   ")[0].click();
  };

  const visibleFillSelectContact = () => {
    let element = document.getElementById("fill-search-button");
    element.getElementsByClassName("fill-contact-search   ")[0].click();
  };

  const postFileAudio = async () => {
    const response = await axios({
      url: mediaBlobUrl,
      method: "GET",
      responseType: "blob",
    });

    const arrayFile = [];
    const file = new File(
      [response.data],
      `mensagem_de_voz_(${timeAudio}).mp3`,
      {
        type: response.data.type,
      }
    );

    const form = new FormData();
    form.append("files", file);
    try {
      const config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      };
      const response = await instance.post(
        "/insert-file-no-base",
        form,
        config
      );
      file.path = response.data[0].path;
    } catch (error) {
      return Toast(
        "Erro ao anexar arquivo!",
        `Erro ao anexar arquivo no servidor!`,
        "error"
      );
    }

    arrayFile.push({ file: file });
    setArrayFilesOptions(arrayFile);

    setTimeAudio("00:00");
    clearBlobUrl();
  };

  const tippyContent = () => {
    return (
      <div className="case-communications__container-templates">
        <div className="container-templates-header">
          <div className="header-area">
            {templatesList.length > 0 && (
              <ArrowLeft onClick={() => setTemplatesList([])} />
            )}
            <Typography.H4 color={"blue-selected-4"} weight={700}>
              Template de mensagens
            </Typography.H4>
          </div>
          <Typography.H4 color={"gray-180"} weight={700}>
            {templatesList.length === 0
              ? "Selecione a categoria do template"
              : `Categoria ${category}`}
          </Typography.H4>
        </div>
        <div className="container-templates-body">
          {templatesList.length === 0 &&
            listCategories
              .sort((a, b) => a.id - b.id)
              .map((categorie) => (
                <div
                  className="categorie-item"
                  key={categorie.id}
                  onClick={() => {
                    setCategory(categorie.id);
                    changeCategory(categorie.id);
                  }}
                >
                  <Typography.H5 color={"gray-180"} weight={500}>
                    {categorie.label}
                  </Typography.H5>
                  <ArrowRight />
                </div>
              ))}
          {templatesList.length > 0 &&
            templatesList
              .sort((a, b) => a.id - b.id)
              .map((categorie) => (
                <div
                  className="categorie-item"
                  key={categorie.id}
                  onClick={() => {
                    changeTemplate(categorie.id);
                  }}
                >
                  <Typography.H5 color={"gray-180"} weight={500}>
                    {categorie.label}
                  </Typography.H5>
                  <ArrowRight />
                </div>
              ))}
        </div>

        {/* <FieldContainer
          title="Categoria de Templates:"
          htmlFor=""
          className="category-template"
        >
          <Select
            value={category}
            options={listCategories}
            onSelect={(option) => {
              setCategory(option.id);
              changeCategory(option.id);
            }}
            width="100%"
            placeholder="Selecionar opção"
          />
        </FieldContainer> */}
        {/* <FieldContainer
          title="Templates:"
          htmlFor=""
          className="subcategory-template"
        >
          <Select
            value={template}
            options={templatesList}
            onSelect={(option) => {
              changeTemplate(option.id);
            }}
            width="100%"
            placeholder="Selecionar opção"
          />
        </FieldContainer> */}
      </div>
    );
  };

  const handleHasImage = () => {
    const principal = document
      .querySelector(".ql-editor")
      .querySelectorAll("img");

    if (principal.length > 0) {
      for (const img of principal) {
        img.style.maxWidth = "100%";
        img.style.objectFit = "cover";
      }

      setValueQuill(document.querySelector(".ql-editor").innerHTML);
    }
  };

  async function imageHandler(imageDataUrl, type, imageData) {
    const blob = imageData.toBlob();
    const file = imageData.toFile();

    //generate a form data
    // const formData = new FormData()

    // // append blob data
    // formData.append('file', blob)

    // // or just append the file
    // formData.append('file', file)

    // upload image to your server

    const response = await axios({
      url: imageDataUrl,
      method: "GET",
      responseType: "blob",
    });

    const arrayFile = [];
    const newFile = new File([response.data], `teste.png`, {
      type: response.data.type,
    });

    const form = new FormData();
    form.append("files", newFile);
    try {
      const config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      };
      const response = await instance.post(
        "/insert-file-no-base",
        form,
        config
      );
      file.path = response.data[0].path;
    } catch (error) {
      console.log("caiu aq", error);
    }
    // arrayFile.push({ file: file });
    // setArrayFilesOptions(arrayFile);

    let index = (this.quill.getSelection() || {}).index;
    if (index === undefined || index < 0) index = this.quill.getLength();
    this.quill.insertEmbed(
      index,
      "image",
      `${connection_platform.urlViewDocsApache}${file.path}`,
      "user"
    );
    
    return new Delta().insert(``);

    // return new Delta().insert("Learn more from this resource", {
    //   link: node.getAttribute("src"),
    // });
    // callUploadAPI(your_upload_url, formData, (err, res) => {
    //   if (err) return
    //   // success? you should return the uploaded image's url
    //   // then insert into the quill editor
    //
    // })
  }

  function renderReactQuill() {
    return (
      <ReactQuill
        key={"reactQuill"}
        ref={refPage}
        value={valueQuill}
        onChange={(content, delta, source) => setValueQuill(content)}
        modules={modules}
        formats={formats}
      />
    );
  }

  //USE EFFECTS
  useEffect(async () => {
    setLoading(true);
    // getCommunicationInfo();
    getListCategories();
    getListCommunicators();
  }, []);

  useEffect(() => {
    if (props.case.email) {
      setEmailList([
        {
          label: props.case.nome_contato,
          caption1: props.case.email,
        },
      ]);
      setSendChannels([
        {
          id: 3,
          label: "Email",
        },
      ]);
    } else {
      setEmailList([]);
      setSendChannels([
        {
          id: 3,
          label: "Email",
        },
      ]);
    }
  }, [props.case.email]);

  useEffect(() => {
    if (mediaBlobUrl && sendAudio) {
      postFileAudio();
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      audioTime.current = audioTime.current + 1;
      setTimeAudio(formatTime(audioTime.current));
    }, 1000);

    if (!audioOpen || status === "paused") {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [audioOpen, status]);

  return (
    <>
      {loading ? (
        <Loading.Paperplane />
      ) : (
        <div className="case-communications">
          {communicatedState === "text" ? (
            <>
              <div className="case-communications__container">
                <div className="case-communications__container-channels">
                  <Typography.H5 color="gray-400">
                    Canais de notificações
                  </Typography.H5>
                  <div className="case-communications__container-channels-box">
                    <div className="case-communications__container-channels-box-options">
                      {channelList.map((channel) => (
                        <div className="case-communications__container-channels-box-options__option">
                          <input
                            type="checkbox"
                            onClick={() => handleChannelList(channel)}
                            defaultChecked={
                              sendChannels.find(
                                (item) => item.id === channel.id
                              )
                                ? true
                                : false
                            }
                          />
                          <Typography.H5 color="gray-180">
                            {channel.label}
                          </Typography.H5>
                        </div>
                      ))}
                    </div>
                    <div
                      id="select-search-button"
                      style={{ position: "relative" }}
                    >
                      <ButtonSelectSearch
                        paginated
                        placeholder="Busque pelo nome do contato"
                        icon={<IconPlusCircle />}
                        // getOptions={getListClients}
                        getOptions={getListCompanysEmails}
                        onSelect={(client) => {
                          const currentEmailList = [...emailList];
                          setEmailList([...currentEmailList, client]);
                        }}
                      />
                    </div>
                    <div className="case-communications__container-channels__box-contact">
                      <Button
                        className="btn-secondary"
                        onClick={() => visibleSelectContact()}
                      >
                        Adicionar contato
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="case-communications__container-send">
                  <FieldContainer
                    title="Mensagem"
                    htmlFor=""
                    className="case-communications__container-quill"
                  >
                    <div className="case-communications__container-principal">
                      <div className="case-communications__container-principal__container">
                        <div
                          className={`case-communications__container-recipients__recipient ${
                            sendChannels.length === 0 ? "disabled" : ""
                          }`}
                        >
                          {sendChannels.length > 0 &&
                            emailList.map((email, index) => (
                              <Tippy
                                content={
                                  email.caption1
                                    ? email.caption1
                                    : "Nenhum email registrado."
                                }
                              >
                                <div>
                                  <FilterTag
                                    key={index}
                                    label={email.label}
                                    type="agents"
                                    agent={{
                                      ...email,
                                      nome_usuario: email.label.toUpperCase(),
                                    }}
                                    onClose={() => {
                                      const newEmailList = emailList.filter(
                                        (item) => item.label !== email.label
                                      );
                                      setEmailList(newEmailList);
                                    }}
                                  />
                                </div>
                              </Tippy>
                            ))}
                          <div id="case-communications__container-recipients__recipient-button">
                            <FillContactSearch
                              paginated
                              placeholder="Busque pelo nome do contato"
                              icon={<IconPlusCircle />}
                              // getOptions={getListClients}
                              getOptions={getListCompanysEmails}
                              setEmailList={setEmailList}
                              emailList={emailList}
                              onSelect={(client) => {
                                const currentEmailList = [...emailList];
                                setEmailList([...currentEmailList, client]);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="mail-maximize"
                          onClick={() => setIsOpenModalSendCommunicated(true)}
                        >
                          <IconMaximize />
                        </div>
                      </div>
                      <div className="case-communications-send-content">
                        <div className="ql-attachment"></div>

                        {renderReactQuill()}

                        <ColumnAttach
                          files={arrayFiles}
                          setFiles={setArrayFiles}
                          limit={3}
                          type="insert"
                          className="comment-carousel"
                          setDeleteFiles={setDeleteFiles}
                          deleteFiles={deleteFiles}
                          download
                        />

                        {audioOpen && (
                          <div className="audio-recorder">
                            <button
                              onClick={() => {
                                setSendAudio(false);
                                stopRecording();
                                setAudioOpen(false);
                                setTimeAudio("00:00");
                                clearBlobUrl();
                                audioTime.current = 0;
                              }}
                              className="buttons-action__cancelButton"
                            >
                              <IconTrash />
                            </button>
                            <div className="audio-recorder_audioComment">
                              <div className="audio-recorder_audioComment__recordBar"></div>
                              <Typography.H6
                                className={
                                  "audio-recorder_audioComment__timeProgress"
                                }
                                weight="600"
                              >
                                {timeAudio}
                              </Typography.H6>
                            </div>
                            {status !== "paused" && (
                              <button
                                onClick={() => {
                                  pauseRecording();
                                }}
                                className="buttons-action__actionButton"
                              >
                                <Pause />
                              </button>
                            )}
                            {status === "paused" && (
                              <button
                                onClick={() => {
                                  resumeRecording();
                                }}
                                className="buttons-action__actionButton"
                              >
                                <Play />
                              </button>
                            )}
                            <button
                              onClick={() => {
                                setSendAudio(true);
                                setAudioOpen(false);
                                audioTime.current = 0;
                                stopRecording();
                              }}
                              className="buttons-action__sendButton"
                            >
                              <IconSend />
                            </button>
                          </div>
                        )}
                        <QuillToolbar
                          setFiles={setArrayFilesOptions}
                          screen="-maximized"
                          removeAll={() => {
                            setValueQuill("");
                            setArrayFiles([]);
                            setTemplate(null);
                          }}
                          sendQuill={refPage.current && refPage}
                          openAudio={() => checkMicrophone()}
                          changeExib={audioOpen ? "audio" : ""}
                          contentTemplate={tippyContent()}
                          editVariable={
                            template === 1
                              ? () => setIsOpenModalVariableEdit(true)
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </FieldContainer>
                </div>
                <div className="case-communications__container-actions">
                  <Button
                    className="btn-tertiary"
                    onClick={() => setCommunicatedState("list")}
                  >
                    Cancelar
                  </Button>
                  <Button
                    icon={<SendTwoWhite />}
                    className="btn-secondary"
                    onClick={() => sendCommunicator()}
                    disabled={
                      valueQuill !== "" && valueQuill !== "<p><br></p>"
                        ? isSending === true
                          ? true
                          : false
                        : true
                    }
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </>
          ) : communicatedState === "audio" ? (
            <>
              <div className="case-communications__header">
                <IconUndo onClick={() => setCommunicatedState("list")} />
                <Typography.H4 color="gray-400">
                  Comunicados {">"} Comunicar por Audio
                </Typography.H4>
              </div>
              <div className="case-communications__main case-communications-audio">
                <FieldContainer
                  title="Por quais canais você deseja notificar?"
                  className="case-communications-channel-container"
                  htmlFor=""
                >
                  <div className="case-communications-channel-list">
                    {channelList.map((channel, index) => (
                      <Channel
                        key={index}
                        media={channel.label.toLowerCase()}
                        onClick={() => handleChannelList(channel)}
                        className={
                          sendChannels.find((item) => item.id === channel.id)
                            ? ""
                            : "channel--disabled"
                        }
                      />
                    ))}
                  </div>
                </FieldContainer>
                <>
                  {audioOpen === "recording" ? (
                    <div className="audio-recorder-container">
                      <div
                        className="audio-recorder-stop"
                        onClick={() => {
                          setSendAudio(true);
                          setTimeAudio("00:00");
                          setAudioOpen("sending");
                          audioTime.current = 0;
                          stopRecording();
                        }}
                      >
                        <IconRecord />
                        <Typography.H5 color="primary-dark" weight="500">
                          Parar de gravar
                        </Typography.H5>
                      </div>
                      <div className="audio-recorder">
                        <IconTrash
                          onClick={() => {
                            setSendAudio(false);
                            stopRecording();
                            setAudioOpen("");
                            setTimeAudio("00:00");
                            clearBlobUrl();
                            audioTime.current = 0;
                          }}
                        />
                        <div className="audio-recorder-send">
                          <div className="audio-recorder-send-time">
                            <Typography.Paragraph color="gray-400">
                              {timeAudio}
                            </Typography.Paragraph>
                          </div>
                          <IconRec />
                        </div>
                      </div>
                    </div>
                  ) : audioOpen === "sending" ? (
                    <div className="audio-recorder-container">
                      <div
                        className="audio-recorder-stop"
                        onClick={() => {
                          clearBlobUrl();
                          setAudioOpen("");
                        }}
                      >
                        <IconMic />
                        <Typography.H5 color="primary-dark" weight="500">
                          Excluir audio atual e gravar novamente
                        </Typography.H5>
                      </div>
                      <div className="audio-recorder-box">
                        <Audio link={mediaBlobUrl} audio />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="case-communications-voice-recorder"
                      onClick={() => checkMicrophone()}
                    >
                      <IconMic />
                      <Typography.H5 color="primary-dark" weight="500">
                        gravar mensagem de voz.
                      </Typography.H5>
                    </div>
                  )}
                </>
              </div>
              <div className="case-communications__footer">
                <Button
                  icon={<IconSend />}
                  onClick={() => console.log(mediaBlobUrl)}
                >
                  Enviar
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="case-communications__list">
                {communicationList.filter(filteredCommunicated).length !== 0 &&
                props.permissions.visualizacao ? (
                  communicationList
                    .filter(filteredCommunicated)
                    .map((communication) => (
                      <div key={communication.id_comentario}>
                        <Communicated
                          communicated={communication}
                          inputSearch={inputSearch}
                        />
                      </div>
                    ))
                ) : (
                  <div className="case-communications__empty">
                    <div
                      className={`case-communications__empty-container ${
                        !props.permissions.visualizacao ? "red-color" : ""
                      }`}
                    >
                      {props.permissions.visualizacao ? (
                        <SendTwo />
                      ) : (
                        <IconEyeSlash />
                      )}
                    </div>
                    <div className="case-communications__empty-text">
                      <Typography.H4 color="black">
                        {!props.permissions.visualizacao
                          ? "Você não tem permissão para visualizar os comunicados."
                          : "Nenhum comunicado encontrado"}
                      </Typography.H4>
                    </div>
                  </div>
                )}
              </div>
              {props.permissions.insercao && (
                <div className="case-communications__footer">
                  <Button
                    icon={<SmsEdit />}
                    className="btn-secondary"
                    onClick={() => setCommunicatedState("text")}
                    disabled={props.permissions.insercao ? false : true}
                  >
                    Enviar um comunicado
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <Modal.SendCommunicatedMaximize
        isOpen={isOpenModalSendCommunicated}
        onClose={(event) => {
          setIsOpenModalSendCommunicated(event);
        }}
        emailList={emailList}
        setEmailList={setEmailList}
        onCreate={() => {
          // updateCases();
        }}
        modules={modules}
        formats={formats}
        setArrayFiles={setArrayFiles}
        setArrayFilesOptions={setArrayFilesOptions}
        setDeleteFiles={setDeleteFiles}
        deleteFiles={deleteFiles}
        setValueQuill={setValueQuill}
        valueQuill={valueQuill}
        arrayFiles={arrayFiles}
        isSending={isSending}
        sendCommunicator={sendCommunicator}
        screenType="modal--communicated"
        // getListClients={getListClients}
        getListClients={getListCompanysEmails}
        contentTippyQuill={tippyContent}
        removeAll={() => {
          setValueQuill("");
          setArrayFiles([]);
          setTemplate(null);
        }}
        editVariable={
          template === 1 ? () => setIsOpenModalVariableEdit(true) : ""
        }
        token={token}
        communicatedState={communicatedState}
        renderReactQuill={handleQuillValueChange}
      />

      <Modal.EditVariableCommunication
        isOpen={isOpenModalVariableEdit}
        onClose={() => {
          setIsOpenModalVariableEdit(false);
        }}
        onSend={() => sendCommunicator()}
        valueQuill={variableEdit}
        setValueQuill={setValueQuill}
        infosCase={props.case}
        isSending={isSending}
      />
    </>
  );
}
