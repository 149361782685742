//GLOBAL - components from npm
import React, { useState } from "react";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import { Input } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";
import { useSelector } from "react-redux";
import { FieldContainer } from "../../../data-display";
import Loading from "../../Loading";

//SERVICES - api, conectors...
import * as APIStaggered from "../../../../services/api/staggered";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...

//STYLES
import "./create-staggered.scss";

export default function CreateStaggered({
    isOpen,
    onClose,
    onCreate
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [description, setDescription] = useState({ descricao: "" });

    //FUNCTIONS
    function onCloseCustom() {
        onClose();
        setDescription({ descricao: "" });
        setValidationWarn(false);
    };

    function isFieldsValid() {
        if (!description.descricao) {
            return false
        }

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao criar escalonado!",
            "Preencha o nome do escalonado.",
            "error",
        );
    };

    async function handleCreateStaggered() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        }

        try {
            setPageStatus("creating-staggered");

            const response = await APIStaggered.createStaggered(token, description);

            if (response.status === 200) {
                Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
                onCreate();
                onCloseCustom();
            } else {
                Toast(
                    "Não foi possível adicionar escalonado!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }

            setPageStatus("awaiting");
        } catch (error) {
            console.log("Erro ao criar escalonado:", error);
        }
    };

    return (
        <Modal.Main
            className="modal-create-staggered"
            title="Adicionar escalonado"
            width="540px"
            height=""
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <div className="container-footer">
                    <Button
                        onClick={() => onCloseCustom()}
                        className="modal-create-staggered__btn-cancel"
                        disabled={pageStatus === "creating-staggered"}
                    >
                        <Typography.H4 weight="bold" color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-create-staggered__btn-confirm"
                        disabled={pageStatus === "creating-staggered"}
                        onClick={handleCreateStaggered}
                    >
                        {pageStatus !== "creating-staggered" ? (
                            <Typography.H4 weight={"bold"} color="white">Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <FieldContainer title="Nome" required>
                <Input
                    type="text"
                    placeholder="Digite o nome aqui"
                    value={description.descricao}
                    onChange={(event) => setDescription({ ...description, descricao: event })}
                    error={validationWarn ? !description.descricao : false}
                />
            </FieldContainer>
        </Modal.Main>
    )
}
