//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";
// import { FaUserFriends } from 'react-icons/fa';

//STYLES
import "./profile-list.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Input } from "..";
import { ImageProfile } from "../../data-display";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...
import * as APITask from "../../../services/api/task";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";
import { ReactComponent as IconAddUser } from "../../../assets/icons/AddUser.svg";
import { ReactComponent as IconAddCircle } from "../../../assets/icons/add-circle.svg";
import { ReactComponent as IconAddElements } from "../../../assets/icons/AddElements.svg";

export default function ProfileList({
  className = "",
  disabledVisibleView,
  showButtonAddAgent,
  viewOnly = false,
  profiles,
  idTask,
  type,
  setProfiles,
  error,
  buttonTooltip,
  allProfileList,
  placeholderTippy,
  stringFormatId,
}) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);
  const ref = useRef();
  const user = useSelector((state) => state.auth.userData);

  //STATES
  const [profilesList, setProfilesList] = useState(profiles);
  const [inputSearch, setInputSearch] = useState("");
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleView, setVisibleView] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const show = () => setVisibleAdd(true);

  const hide = () => {
    setVisibleAdd(false);
    setInputSearch("");
  };

  const showView = () => setVisibleView(true);

  const hideView = () => {
    setVisibleView(false);
    setInputSearch("");
  };

  const postAssociateProfile = async (id_user, id_task) => {
    const body = {
      id_user,
      id_task,
    };

    await APITask.associateUser(body, token);
  };

  const deleteDisassociateProfile = async (id_user, id_task) => {
    await APITask.disassociateUser(id_user, id_task, token);
  };

  const renderProfiles = () => {
    const orderedProfile = organizeProfileList();

    const filteredProfile = orderedProfile?.filter(profileFilter);

    const ProfileList = filteredProfile?.map(handleProfiles);

    return ProfileList;
  };

  const organizeProfileList = () => {
    const profileUsed = profilesList?.filter((item) =>
      stringFormatId
        ? allProfileList?.find(
            (profile) => profile.id_usuario.toString() === item.id_usuario
          )
        : allProfileList?.find(
            (profile) => profile.id_usuario === item.id_usuario
          )
    );

    const otherProfiles = [];

    for (const profile of allProfileList) {
      const hasProfile = profilesList?.find(
        stringFormatId
          ? (item) => item.id_usuario === profile.id_usuario.toString()
          : (item) => item.id_usuario === profile.id_usuario
      );

      if (!hasProfile) {
        otherProfiles.push(profile);
      }
    }

    if (viewOnly || visibleView) {
      return profileUsed;
    }

    const allProfilesOrganized = profileUsed?.concat(otherProfiles);

    return allProfilesOrganized;
  };

  const profileFilter = (profile) => {
    if (inputSearch) {
      if (
        profile.nome_usuario?.toLowerCase().includes(inputSearch.toLowerCase())
      ) {
        return profile;
      } else {
        return;
      }
    } else {
      return profile;
    }
  };

  const handleProfiles = (profile) => {
    if (profilesList.find((item) => item.id_usuario === profile.id_usuario)) {
      return (
        <div
          className="popover-profile__content active"
          key={profile.id_usuario}
          onClick={() => {
            handleProfileActive(profile);
          }}
        >
          <ImageProfile profile={profile} />
          <Typography.H5 color="gray-400">{profile.nome_usuario}</Typography.H5>
        </div>
      );
    } else {
      return (
        <div
          className="popover-profile__content"
          key={profile.id_usuario}
          onClick={() => {
            handleProfileInactive(profile);
          }}
        >
          <ImageProfile profile={profile} />
          <Typography.H5 color="gray-400">{profile.nome_usuario}</Typography.H5>
          <IconAddUser />
        </div>
      );
    }
  };

  const handleProfileActive = (profile) => {
    if (viewOnly || visibleView) {
      return;
    }
    const arrayProfile = [...profilesList];
    const arrayProfileFilter = arrayProfile.filter(
      (item) => item.id_usuario !== profile.id_usuario
    );
    setProfilesList(arrayProfileFilter);

    if (type === "add") {
      setProfiles(arrayProfileFilter);
    }
    if (idTask) {
      deleteDisassociateProfile(profile.id_usuario, idTask);
    }
  };

  const handleProfileInactive = (profile) => {
    const arrayProfile = [...profilesList];
    arrayProfile.push(profile);
    setProfilesList(arrayProfile);

    if (type === "add") {
      return setProfiles(arrayProfile);
    }

    if (idTask) {
      postAssociateProfile(profile.id_usuario, idTask);
    }
  };

  //USE EFFECTS
  useEffect(() => {
    if (profiles && profiles.length < 1) {
      setProfilesList([]);
    }
  }, [profiles]);

  return !viewOnly || (viewOnly && profilesList?.length > 0) ? (
    <div
      className={`profile-list ${className} ${
        viewOnly ? "profile-list--view-only" : ""
      }`}
      ref={ref}
    >
      <div className="profile-list__profiles">
        {profilesList?.slice(0, 4).map((profile, index) => (
          <ImageProfile
            // iconHistory="user"
            key={index}
            profile={profile}
            iconClassName={"profile-list-user"}
            className={`profile-list__profiles__img profile-list__profiles__img--${index}`}
          />
        ))}
      </div>

      {(!viewOnly || (viewOnly && profilesList?.length > 4)) && (
        <div className="profile-list__popover">
          {profilesList?.length > 4 && (
            <div className="profile-list__popover__view">
              <Tippy
                theme="popover-default"
                // #TOVERIFY: TRIGGER
                // trigger="click"
                visible={visibleView}
                onClickOutside={hideView}
                content={
                  <div className={`popover-profile`}>
                    <Input
                      rightIcon={<IconSearch />}
                      type="text"
                      placeholder="Busca"
                      autoComplete="on"
                      value={inputSearch}
                      onChange={(value) => setInputSearch(value)}
                    />
                    <div className="popover-profile__list popover-profile__view">
                      {visibleView ? renderProfiles() : ""}
                    </div>
                  </div>
                }
                placement="bottom"
                delay={[0, 0]}
                allowHTML
                interactive
              >
                <button
                  style={disabledVisibleView && { cursor: "default" }}
                  onClick={(e) => {
                    if (disabledVisibleView) return null;
                    else {
                      e.stopPropagation();
                      visibleView ? hideView() : showView();
                    }
                  }}
                >
                  +{profilesList.length - 4}
                  {/* <Typography.H6 color="primary-dark">
                    +{profilesList.length - 4}
                  </Typography.H6> */}
                </button>
              </Tippy>
            </div>
          )}
          {!viewOnly && user.permissionId === 2 && (
            <Tippy
              theme="popover-default"
              // #TOVERIFY: TRIGGER
              // trigger="click"
              visible={visibleAdd}
              onClickOutside={hide}
              content={
                <div className={`popover-profile`}>
                  <Input
                    rightIcon={<IconSearch />}
                    type="text"
                    placeholder="Busca"
                    autoComplete="on"
                    value={inputSearch}
                    onChange={(value) => setInputSearch(value)}
                  />
                  <div className="popover-profile__list">
                    {visibleAdd ? renderProfiles() : ""}
                  </div>
                </div>
              }
              placement="bottom"
              delay={[0, 0]}
              allowHTML
              interactive
            >
              {buttonTooltip ? (
                <div style={{ display: "flex", height: "24px" }}>
                  <Tippy
                    content={
                      placeholderTippy ? placeholderTippy : "Adicionar agentes"
                    }
                  >
                    <button
                      className={`btn-add-elements ${
                        error ? "btn-add-elements--error" : ""
                      }`}
                      onClick={() => {
                        visibleAdd ? hide() : show();
                      }}
                    >
                      <IconAddCircle />
                    </button>
                  </Tippy>
                </div>
              ) : (
                <>
                  {showButtonAddAgent === false ? null : (
                    <button
                      className={`btn-add-elements ${
                        error ? "btn-add-elements--error" : ""
                      }`}
                      onClick={() => {
                        visibleAdd ? hide() : show();
                      }}
                    >
                      <IconAddCircle />
                    </button>
                  )}
                </>
              )}
            </Tippy>
          )}
          {/* <div className={`${openProfileList ? "active" : ""} popover-profile popover-profile--${openProfileList} popover-profile--${viewOnly ? 'view-only' : ''}`}>
                  <Input
                    rightIcon={<IconSearch />}
                    type="text"
                    placeholder="Busca"
                    autoComplete="on"
                    value={inputSearch}
                    onChange={(value) => setInputSearch(value)}
                  />
                  <div className="popover-profile__list">
                    {renderProfiles()}
                  </div>
                </div> */}
          {/* {openProfileList && <div className={`list-profile__after list-profile__after--${openProfileList} list-profile__after--${viewOnly ? 'view-only' : ''}`}></div>} */}
        </div>
      )}
    </div>
  ) : (
    ""
  );
}
