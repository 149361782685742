import Main from "./Main";
import CreateTask from "./CreateTask";
import CreateCase from "./CreateCase";
import SendCommunicatedMaximize from "./SendCommunicatedMaximize";
import CreateCompany from "./CreateCompany";
import CreateContact from "./CreateContact";
import UpdateSLA from './UpdateSLA';
import DeleteSLA from './DeleteSLA';
import ViewDataContact from "./ViewDataContact";
import ViewDataCompany from "./ViewDataCompany";
import UpdateContact from "./UpdateContact";
import UpdateCompany from "./UpdateCompany";
import ChangeSLAMeta from "./ChangeSLAMeta";
import ManageMotives from './ManageMotives';
import DeleteTypeSolicitation from './DeleteTypeSolicitation';
import EditVariableCommunication from "./EditVariableCommunication";
import DeleteStatus from './DeleteStatus';
import UpdateStatus from './UpdateStatus';
import CreateType from './CreateType';
import UpdateType from './UpdateType';
import AssociatiTypeReason from './AssociatiTypeReason';
import AssociatiSubreason from './AssociatiSubreason';
import DeleteReason from './DeleteReason';
import DeleteSubreason from './DeleteSubreason';
import UpdateReason from './UpdateReason';
import UpdateSubreason from './UpdateSubreason';
import CreateShiftWorker from './CreateShiftWorker';
import UpdateShiftWorker from './UpdateShiftWorker';
import DeleteShiftWorker from './DeleteShiftWorker';
import DeleteUser from './DeleteUser';
import RedefinePassword from './RedefinePassword';
import DeleteProfile from './DeleteProfile';
import DeleteOrganization from './DeleteOrganization';
import CreateStaggered from './CreateStaggered';
import UpdateStaggered from './UpdateStaggered';
import DeleteStaggered from './DeleteStaggered';
import GenericDeleteModal from './GenericDeleteModal';
import ExportCases from './ExportCases';

const Modal = {
  Main,
  CreateTask,
  CreateCase,
  SendCommunicatedMaximize,
  CreateCompany,
  CreateContact,
  UpdateContact,
  UpdateCompany,
  ViewDataContact,
  ViewDataCompany,
  ChangeSLAMeta,
  UpdateSLA,
  DeleteSLA,
  ManageMotives,
  DeleteTypeSolicitation,
  EditVariableCommunication,
  DeleteStatus,
  UpdateStatus,
  CreateType,
  UpdateType,
  AssociatiTypeReason,
  AssociatiSubreason,
  DeleteReason,
  DeleteSubreason,
  UpdateReason,
  UpdateSubreason,
  CreateShiftWorker,
  UpdateShiftWorker,
  DeleteShiftWorker,
  DeleteUser,
  RedefinePassword,
  DeleteProfile,
  DeleteOrganization,
  CreateStaggered,
  UpdateStaggered,
  DeleteStaggered,
  GenericDeleteModal,
  ExportCases
};

export default Modal;
