//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { format } from "date-fns";

// UTILS
import formatDate from "../../../../utils/formatDate";

//STYLES
import "./delete-status.scss";

//COMPONENTS
import { Modal } from "../..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { DropdownStatus } from "../../../layout";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APITask from "./../../../../services/api/task";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...

export default function DeleteStatus({
  isOpen,
  onClose,
  taskStatus,
  onDelete,
  boardId,
  setIsFrameDeleted
}) {
  //REDUX - Selectors
  const token = useSelector((state) => state.auth.token);

  //GENERAL

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [statusList, setStatusList] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [id, setId] = useState(null);
  const [taskData, setTaskData] = useState({ id_fila: "2" });

  //FUNCTIONS
  function onCloseCustom() {
    onClose();
    setTaskData({ id_fila: "2" });
  }

  function getStatusId(statusId) {
    taskStatus?.forEach((item) => {
      if (item.id_status === statusId) {
        setId(item.id_status);
        setTasks(item.tasks);
      }
    });
  }

  async function handleDeleteStatus() {
    setPageStatus("delete-status");

    let response;

    if (tasks.length > 0) {
      const updatePromises = tasks.map((task) => {
        task.id_fila = parseInt(taskData.id_fila);
        task.id_tarefa = parseInt(task.id_tarefa);

        if (parseInt(taskData.id_fila) === 4) {
          task.dt_fechamento = formatDate(new Date());
        } else {
          task.dt_fechamento = null;
        }

        task.dt_ultima_atualizacao = format(new Date(), "yyyy-MM-dd");
        task.dt_fechamento = task.dt_fechamento
          ? format(new Date(task.dt_fechamento), "yyyy-MM-dd'T'HH:mm:ss.SSS") : task.dt_fechamento;
        task.prazo_inicio = format(new Date(task.prazo_inicio), "yyyy-MM-dd'T'HH:mm:ss.SSS");
        task.dt_abertura = format(new Date(task.dt_abertura), "yyyy-MM-dd");

        return APITask.taskUpdateMove(parseInt(task.id_tarefa), task, token);
      });

      await Promise.all(updatePromises);

      response = await APITask.deleteStatus(id, token);
    } else {
      response = await APITask.deleteStatus(id, token);
    }

    if (response.status === 200) {
      Toast("Status Deletado!", "Status deletado com sucesso.", "success");
      onDelete();
      onCloseCustom();

      setIsFrameDeleted(true);
    } else {
      Toast(
        "Falha ao deletar o status!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }

    setPageStatus("awaiting");
  }

  async function getListStatus() {
    try {
      const response = await APITask.listStatus(token);

      if (response.status === 200) {
        const updatedData = response.data
          .filter(status => status.id_fila.toString() !== boardId)
          .map(datum => {
            return {
              id: datum.id_fila.toString(),
              label: datum.descricao,
              cor:
                datum.id_fila.toString() === "2" ?
                  "#5b78ff" : datum.id_fila.toString() === "3" ? "#ffbb00" :
                    datum.id_fila.toString() === "4" ? "#008908" : datum.cor_fila
            };
          });

        setStatusList(updatedData);
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  //USE EFFECTS
  useEffect(() => {
    getListStatus();
  }, [boardId]);

  useEffect(() => {
    if (boardId) {
      getStatusId(boardId);
    }
  }, [boardId]);

  return (
    <Modal.Main
      className="modal-delete-status"
      title={
        tasks?.length > 0
          ? "Atenção! Para remover esta coluna é necessário mover as tarefas para uma outra coluna."
          : "Você tem certeza de que gostaria de excluir essa coluna?"
      }
      isOpen={isOpen}
      onClose={onCloseCustom}
      width="443px"
      height={tasks?.length > 0 ? "304px" : "215px"}
      footer={
        <>
          <Button
            className="modal-delete-status__btn-cancel"
            onClick={() => onCloseCustom()}
            disabled={pageStatus === "delete-status"}
          >
            <Typography.H4 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H4>
          </Button>

          <Button
            className="modal-delete-status__btn-confirm"
            disabled={pageStatus === "delete-status"}
            onClick={handleDeleteStatus}
          >
            {pageStatus !== "delete-status" ? (
              <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
            ) : (
              <Loading.Dots width={70} height={70} />
            )}
          </Button>
        </>
      }
    >
      {tasks?.length > 0 && (
        <section className="modal-delete-status__content">
          <Typography.H5 color="gray-180" weight="400">
            Mover tarefas da coluna para:
          </Typography.H5>

          <div className="modal-delete-status__content__select-status">
            <DropdownStatus
              searchStatus
              isNewStatusList
              maxHeight="95px"
              screen="create"
              value={taskData.id_fila}
              newStatusListOptions={statusList}
              onClick={(event) => {
                let taskDataUpdated = { ...taskData };

                taskDataUpdated.id_fila = event.id;

                setTaskData(taskDataUpdated);
              }}
            />
          </div>
        </section>
      )}
    </Modal.Main>
  );
}
