//GLOBAL - components from npm
import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";

// UTILS

//STYLES
import "./bar-chart.scss";

//COMPONENTS
import { Typography } from "../../general";
// import { withTheme } from "@emotion/react";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function BarChart({
  data,
  keys = ["value"],
  botAxis = false,
  showLabel = false,
  darkMode = false,
  layout = "vertical",
  onClick = () => null,
  isTicketSizeZero,
  padding,
  colorsEventChange,
}) {
  const [canClick, setCanClick] = useState("");

  const theme = {
    axis: {
      domain: {
        line: {
          stroke: darkMode ? "#ffffff" : "rgba(0, 0, 0, 0.1)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontFamily: "Inter",
          fontSize: 10,
          fill: darkMode ? "#FFF" : "#000",
        },
      },
    },
    grid: {
      line: {
        stroke: darkMode ? "#ffffff" : "rgba(0, 0, 0, 0.1)",
        strokeWidth: 1,
      },
    },
  };

  const BarComponent = ({ bars, labelSkipWidth }) => {
    const lengthMax = data.length > 15 ? 6 : 5;
    return (
      <g className={canClick}>
        {bars.map(({ width, height, y, X, data: { data } }) => {
          // only show this custom outer label on bars that are too small
          // data.label.length > 7 && width < labelSkipWidth
          return width <= data.label.length * lengthMax ? (
            <text
              transform={`translate(${width + 10}, ${y + height / 2})`}
              fill={darkMode ? "#FFF" : "#000"}
              text-anchor="left"
              dominant-baseline="central"
              className="text-style"
            >
              {`${data.label}`}
            </text>
          ) : (
            <text
              transform={`translate(${width - (width - 6)}, ${y + height / 2})`}
              fill={"#FFF"}
              text-anchor="right"
              dominant-baseline="central"
              className="text-style"
            >
              {`${data.label}`}
            </text>
          );
        })}
      </g>
    );
  };

  return (
    <div style={{ height: "98%", width: "99%" }}>
      <ResponsiveBar
        data={data}
        onClick={({ data }) => {
          if (data.onClick) {
            onClick(data["onClick"], data["label"], data["id"]);
            setCanClick("canClick");
          }
        }}
        onMouseEnter={({ data }, event) => {
          if (data.onClick) {
            event.target.style.cursor = "pointer";
          }
        }}
        enableLabel={showLabel}
        theme={theme}
        keys={keys}
        margin={{
          top: 20,
          right: 10,
          bottom: 25,
          left: layout === "vertical" ? 40 : 5,
        }}
        padding={padding ? padding : 0.4}
        layout={layout}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        // colors={({ id, data }) => String(data[`${id}Color`])}
        colors={({ id, data }) => {
          return colorsEventChange ? String(data.color) : String(data[`${id}Color`]);
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        enableGridX={layout === "horizontal" ? true : false}
        enableGridY={layout === "horizontal" ? false : true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: isTicketSizeZero ? 0 : 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={
          layout === "vertical"
            ? {
              tickSize: isTicketSizeZero ? 0 : 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -40,
            }
            : null
        }
        labelSkipWidth={100}
        labelSkipHeight={12}
        labelTextColor={"white"}
        role="application"
        ariaLabel="Nivo bar chart demo"
        // barAriaLabel={function (e) {
        //   return (
        //     e.id + ": " + e.formattedValue + " in country: " + e.indexValue
        //   );
        // }}
        tooltip={({ id, value, color, data }) => (
          <div className="tooltip-text">
            <div
              style={{ width: 12, height: 12, backgroundColor: color }}
            ></div>
            <Typography.H6 color={"white"}>
              {data.label
                ? `${data.label.charAt(0).toUpperCase() + data.label.substring(1)
                } - ${value} casos`
                : `${id.charAt(0).toUpperCase() + id.substring(1)
                } - ${value} casos`}
            </Typography.H6>
          </div>
        )}
        layers={[
          "grid",
          "axes",
          "bars",
          "markers",
          "legends",
          "annotations",
          layout !== "vertical" ? BarComponent : null,
        ]}
      />
    </div>
  );
}
