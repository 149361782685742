//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from 'react';

//UTILS 
import useOutsideClick from "../../../hooks/useOutsideClick"

//STYLES
import './tag.scss'

//COMPONENTS
import { Typography } from "../../../components/general";
import { Tippy } from "../../../components/feedback";


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconClose } from '../../../assets/icons/Close.svg'
import { ReactComponent as IconMenuTag } from '../../../assets/icons/MenuTag.svg'


export default function Tag({
    name,
    className = '',
    icon,
    onClickIcon,
    disabled,
    onClick,
    options,
    editTag,
    hideMenu,
    withheldName
}) {

    //GENERAL
    const ref = useRef()


    //STATES
    const [visible, setVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [valueTag, setValueTag] = useState(name)


    //REDUX - Selectors


    //FUNCTIONS
    const show = (e) => {
        e.stopPropagation();
        setVisible(true)
    };

    const hide = () => {
        setVisible(false);
    };

    // const closeInput = () => {
    //     setEdit(false);
    //     setValueTag("");
    // }

    useOutsideClick(ref, () => {
        if (edit) {
            setEdit(false);
            setValueTag(name);
        }
    });

    const onEditTag = async (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            editTag(valueTag);
            setEdit(false);
        }
    }


    //USE EFFECTS
    useEffect(() => {
        if (edit) {
            ref.current.focus();
            setVisible(false);
        }
    }, [edit])



    if (edit) {
        return (
            <div className={`tag-input ${disabled ? 'tag-input--disabled' : ''}`}>
                <input
                    ref={ref}
                    className="tag-input__input"
                    value={valueTag}
                    onChange={(e) => setValueTag(e.target.value)}
                    onKeyPress={(e) => onEditTag(e)}
                    style={{ width: `${valueTag.length}ch` }}
                />
            </div>
        )
    } else {
        return (
            <Tippy content={name}>
                <div
                    className={`tag 
                        ${className} ${icon ? 'tag-icon' : ''} 
                        ${options ? 'tag--options' : ''} 
                        ${disabled ? 'tag--disabled' : ''}`
                    }
                    onClick={(e) => onClick ? onClick(e) : ''}
                >
                    {/* <Typography.Paragraph
                        color={disabled ? "primary-dark" : "gray-50"}
                        weight="bold">
                        {name}
                    </Typography.Paragraph> */}

                    <Typography.H5 color='blue-selected-4'>
                        {withheldName ? (
                            <>
                                {name.length > 10 ?
                                    name.substring(0, 10) + "..." : name}
                            </>
                        ) : <>{name}</>}
                    </Typography.H5>

                    {options &&
                        <Tippy
                            theme="popover-default"
                            // #TOVERIFY: TRIGGER
                            // trigger="click"
                            visible={visible}
                            onClickOutside={hide}
                            placement="bottom"
                            delay={[0, 0]}
                            allowHTML
                            interactive
                            content={
                                <div className="popover-tag">
                                    <div
                                        className="popover-tag__item"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setEdit(true);
                                        }}
                                    >
                                        <Typography.H6 weight='500' color='blue-selected-4' /* color='primary-dark' */>
                                            Editar
                                        </Typography.H6>
                                    </div>
                                </div>
                            }
                        >
                            {
                                hideMenu ? null :
                                    <div className="tag__menu">
                                        <IconMenuTag
                                            onClick={(e) => show(e)}
                                            className="menu-tag"
                                        />
                                    </div>
                            }
                        </Tippy>
                    }

                    {icon && <IconClose onClick={() => onClickIcon(name)} />}

                    {/* {
                        icon &&
                        <span className="tag__close">
                            <IconClose onClick={() => onClickIcon(name)} />
                        </span>
                    } */}
                </div>
            </Tippy>
        )
    }
}
