//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { cloneDeep } from "lodash";

//STYLES
import "./popover-columnsFilter.scss";
import "tippy.js/dist/tippy.css";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy } from "../../feedback";
import { Input } from "../../data-entry";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconSearch } from "../../../assets/icons/Search-normal.svg";

export default function PopoverColumnsFilter({
  className,
  children,
  zIndex = 10,
  menuData = [
    {
      title: "Coluna1",
      exhib: true,
      filterDisplay: true,
    },
    {
      title: "Coluna2",
      exhib: false,
      filterDisplay: true,
    },
  ],
  setChangesActive,
  theme = "popover-default",
  maxWidth = "none",
  delay = [0, 0],
  trigger = "click",
  placement = "bottom",
  interactive = true,
  menuVariableData,
  setChangesActiveVariable,
  ...rest
}) {
  //GENERAL

  //STATES
  const [columns, setColumns] = useState(menuData);
  const [columnsVariable, setColumnsVariable] = useState(menuVariableData);
  const [inputValue, setInputValue] = useState("");

  //REDUX - Selectors

  //FUNCTIONS
  const handleChange = (idInput) => {
    const changeValue = [...columns.map((column) => ({ ...column }))];

    let columnExib = changeValue.find((value) => value.title === idInput);
    columnExib.exhib = !columnExib.exhib;

    setColumns(changeValue);
    setChangesActive(changeValue);
  };

  const handleChangeVariable = (idInput) => {
    const changeValue = cloneDeep(columnsVariable);
    // [...columnsVariable.map((column) => ({ ...column }))]

    let columnExib = changeValue.find((value) => value.id === idInput);
    columnExib.exhib = !columnExib.exhib;

    setColumnsVariable(changeValue);
    setChangesActiveVariable(changeValue);
  };

  const handleChangeInput = (value) => {
    setInputValue(value);
  };

  const handleFilter = (value) => {
    if (inputValue) {
      if (value.title.toLowerCase().includes(inputValue.toLowerCase())) {
        return value;
      } else {
        return;
      }
    } else {
      return value;
    }
  };

  const renderContent = (data, data2) => {
    return (
      <ul className={`popover-columns-filter ${className}`}>
        <Input
          type="text"
          leftIcon={<IconSearch />}
          className="input-popover"
          value={inputValue}
          onChange={handleChangeInput}
        />
        <ul className="popover-columns-filter__content">
          {data.filter(handleFilter).map((menu, key) => {
            return (
              <li key={key} className={`columns-checkbox `}>
                <Switch
                  id={menu.title}
                  checked={menu.exhib}
                  onChange={() => handleChange(menu.title)}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor={"#b8c1ea"}
                  onHandleColor={"#455CC7"}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  width={34}
                  height={14}
                  handleDiameter={20}
                />
                <label htmlFor={menu.title}>
                  <Typography.H5 color={"black"} weight="400">
                    {menu.title}
                  </Typography.H5>
                </label>
              </li>
            );
          })}
          {data2 && data2.length > 0 && (
            <>
              <li className="title-fieldsAdditional">Campos Personalizados</li>
              {data2.filter(handleFilter).map((menu, key) => {
                return (
                  <li key={key} className={`columns-checkbox `}>
                    <Switch
                      id={menu.title}
                      checked={menu.exhib}
                      onChange={() => handleChangeVariable(menu.id)}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor={"#b8c1ea"}
                      onHandleColor={"#455CC7"}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      width={34}
                      height={14}
                      handleDiameter={20}
                    />
                    <label htmlFor={menu.title}>
                      <Typography.H5 color={"black"} weight="400">
                        {menu.title}
                      </Typography.H5>
                    </label>
                  </li>
                );
              })}
            </>
          )}
        </ul>
      </ul>
    );
  };

  //USE EFFECTS
  // useEffect(() => {
  //   if (inputValue === "") {
  //     setColumns(menuData);
  //   }
  // }, [inputValue, menuData]);

  return (
    <Tippy
      theme={theme}
      trigger={trigger}
      content={renderContent(columns, columnsVariable)}
      placement={placement}
      delay={delay}
      zIndex={zIndex}
      allowHTML
      interactive={interactive}
      {...rest}
    >
      {children}
    </Tippy>
  );
}
