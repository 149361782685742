//GLOBAL - components from npm
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { NumericFormat } from 'react-number-format'
import MaskedInput from "react-text-mask";
// import InputMasks from "the-mask-input";

//STYLES
import "./input.scss";

//COMPONENTS
import { Typography } from "../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconError } from "./../../../assets/icons/error.svg";
import { ReactComponent as IconEye } from "./../../../assets/icons/Eye-02.svg";
import { ReactComponent as IconEyeSlash } from "./../../../assets/icons/EyeSlash-02.svg";

export default function Input({
  className = "",
  inputClassName = "",
  error,
  alert,
  id,
  name,
  type = "text",
  autoComplete,
  value,
  onChange,
  onValueChange,
  prefix,
  placeholder,
  typeInputMessage,
  customInputMessage,
  leftIcon,
  rightIcon,
  rightIconClassname = "",
  onKeyPress,
  onKeyDown,
  disabled,
  onBlur,
  onInput,
  step,
  pattern,
  mask,
  maxLength,
  width,
  height,
  inputRef,
  noBreadthRightIcon,
  withSpacingRightIcon
}) {
  //GENERAL

  //STATES
  const [passwordVisible, setPasswordVisible] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const handleShowPassword = () => {
    setPasswordVisible((oldState) => !oldState);
  };

  const renderInputMessage = () => {
    switch (typeInputMessage) {
      case "error":
        return (
          <div className="input-msg input-msg--error">
            <div className="input-msg__icon">
              <IconError />
            </div>

            <Typography.Paragraph className="input-msg__title">
              {customInputMessage ? customInputMessage : "Error message"}
            </Typography.Paragraph>
          </div>
        );

      default:
        break;
    }
  };

  const dynamicMask = (rawValue) => {
    if (rawValue.length <= 5) {
      return [/\d/, /\d/, ":", /\d/, /\d/];
    }
    return [/\d/, /\d/, /\d/, ":", /\d/, /\d/];
  };

  //USE EFFECTS

  switch (type) {
    case "password": {
      return (
        <label
          style={{ width: width ? width : "" }}
          className={`input-container input-container--password 
          ${className} 
          ${error ? "input-container--error" : ""}
          ${alert ? "input-container--alert" : ""}
          `}
        >
          <div className="input-wrapper" /*style={{ width: width ? width : "" }}*/>
            {leftIcon && (
              <div className="input-wrapper__left-icon">{leftIcon}</div>
            )}
            <input
              onKeyDown={onKeyDown}
              id={id}
              autoComplete={autoComplete}
              type={passwordVisible ? "text" : "password"}
              className={`input-wrapper__input input-wrapper__input--right-icon ${leftIcon ? "input-wrapper__input--left-icon" : ""
                }`}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value);
                }
              }}
            />
            <button
              type="button"
              className={`input-wrapper__right-icon ${passwordVisible ? "active" : ""
                }`}
              onClick={() => handleShowPassword()}
            >
              {passwordVisible ? <IconEyeSlash /> : <IconEye />}
            </button>
          </div>
          {typeInputMessage && renderInputMessage()}
        </label>
      );
    }

    case "textarea": {
      return (
        <textarea
          className={`input-container input-container--textarea ${className} ${error ? "input-container--error" : ""
            }${alert ? "input-container--alert" : ""}`}
          cols="20"
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      );
    }

    case "email":
    case "text":
    case "number":
    case "time":
      return (
        <label
          style={{ width: width ? width : "" }}
          className={`input-container ${className} ${error ? "input-container--error" : ""
            }${alert ? "input-container--alert" : ""}`}
        >
          <div className="input-wrapper" /*style={{ width: width ? width : "" }}*/>
            {leftIcon && (
              <div className="input-wrapper__left-icon">{leftIcon}</div>
            )}
            <input
              disabled={disabled}
              ref={inputRef}
              id={id}
              name={name}
              autoComplete={autoComplete}
              type={type}
              onBlur={onBlur}
              step={step}
              pattern={pattern}
              onInput={(event) => {
                if (onInput) {
                  onInput(event)
                }
              }}
              maxLength={maxLength}
              style={{ width: width ? width : "", height: height ? height : "" }}
              className={`input-wrapper__input ${leftIcon ? "input-wrapper__input--left-icon" : ""
                } ${rightIcon ? "input-wrapper__input--right-icon" : ""}`}
              placeholder={placeholder}
              value={value}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                if (onKeyPress) {
                  onKeyPress(e);
                }
              }}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e);
                }
              }}
            />
            {rightIcon && (
              <div
                className={`
                  input-wrapper__right-icon ${rightIconClassname}
                  ${noBreadthRightIcon ? "noBreadth" : ""}
                  ${withSpacingRightIcon ? "withSpacing" : ""} 
                `}
              >
                {rightIcon}
              </div>
            )}
          </div>
          {typeInputMessage && renderInputMessage()}
        </label>
      );

    case "numberMask":
      return (
        <label
          style={{ width: width ? width : "" }}
          className={`input-container ${className} ${error ? "input-container--error" : ""
            }${alert ? "input-container--alert" : ""}`}
        >
          <div className="input-wrapper" /*style={{ width: width ? width : "" }}*/>
            {leftIcon && (
              <div className="input-wrapper__left-icon">{leftIcon}</div>
            )}
            <InputMask
              style={{ width: width ? width : "" }}
              mask={mask}
              maskChar=" "
              disabled={disabled}
              id={id}
              name={name}
              autoComplete={autoComplete}
              type={type}
              className={`input-wrapper__input ${leftIcon ? "input-wrapper__input--left-icon" : ""
                } ${rightIcon ? "input-wrapper__input--right-icon" : ""}`}
              placeholder={placeholder}
              value={value}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                if (onChange) {
                  const unmask = e.target.value.replace(/[^\d]/g, "");
                  onChange(unmask);
                }
              }}
              onKeyPress={(e) => {
                if (onKeyPress) {
                  onKeyPress(e);
                }
              }}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e);
                }
              }}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e);
                }
              }}
            />
            {rightIcon && (
              <div
                className={`
                input-wrapper__right-icon ${rightIconClassname}
                ${noBreadthRightIcon ? "noBreadth" : ""}
                ${withSpacingRightIcon ? "withSpacing" : ""} 
            `}
              >
                {rightIcon}
              </div>
            )}
          </div>
          {typeInputMessage && renderInputMessage()}
        </label>
      );

    case "monetary":
      return (
        <label
          style={{ width: width ? width : "" }}
          className={`input-container 
            ${className} 
            ${error ? "input-container--error" : ""}
            ${alert ? "input-container--alert" : ""}`}
        >
          <div className="input-wrapper">
            {leftIcon && (
              <div className="input-wrapper__left-icon">{leftIcon}</div>
            )}
            <NumericFormat
              style={{ width: width ? width : "" }}
              id={id}
              name={name}
              autoComplete={autoComplete}
              className={`input-wrapper__input 
                ${leftIcon ? "input-wrapper__input--left-icon" : ""} 
                ${rightIcon ? "input-wrapper__input--right-icon" : ""}`}
              placeholder={placeholder}
              onClick={(e) => e.stopPropagation()}
              value={value}
              onValueChange={onValueChange}
              onKeyPress={(e) => {
                if (onKeyPress) {
                  onKeyPress(e);
                }
              }}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e);
                }
              }}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e);
                }
              }}
              prefix={prefix}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
            />
            {rightIcon && (
              <div
                className={`input-wrapper__right-icon 
                  ${rightIconClassname}
                  ${noBreadthRightIcon ? "noBreadth" : ""}
                  ${withSpacingRightIcon ? "withSpacing" : ""} `}
              >
                {rightIcon}
              </div>
            )}
          </div>
          {typeInputMessage && renderInputMessage()}
        </label>
      );

    case "dynamicMask":
      return (
        <label
          style={{ width: width ? width : "" }}
          className={`input-container 
            ${className} 
            ${error ? "input-container--error" : ""}
            ${alert ? "input-container--alert" : ""}`}
        >
          <div className="input-wrapper">
            {leftIcon && (
              <div className="input-wrapper__left-icon">{leftIcon}</div>
            )}
            <MaskedInput
              style={{ width: width ? width : "" }}
              id={id}
              name={name}
              autoComplete={autoComplete}
              mask={dynamicMask}
              placeholder={placeholder}
              onClick={(e) => e.stopPropagation()}
              value={value}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value);
                }
              }}
              onKeyPress={(e) => {
                if (onKeyPress) {
                  onKeyPress(e);
                }
              }}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e);
                }
              }}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e);
                }
              }}
              guide={false}
              keepCharPositions={true}
              render={(ref, props) => (
                <input
                  ref={ref}
                  {...props}
                  className={`input-wrapper__input 
                  ${leftIcon ? "input-wrapper__input--left-icon" : ""} 
                  ${rightIcon ? "input-wrapper__input--right-icon" : ""}`}
                />
              )}
            />
            {rightIcon && (
              <div
                className={`input-wrapper__right-icon 
                  ${rightIconClassname}
                  ${noBreadthRightIcon ? "noBreadth" : ""}
                  ${withSpacingRightIcon ? "withSpacing" : ""} `}
              >
                {rightIcon}
              </div>
            )}
          </div>
          {typeInputMessage && renderInputMessage()}
        </label>
      );

    default:
      break;
  }
}
