//GLOBAL - components from npm
import React, { useEffect, useRef, useState } from "react";
import ColorPicker, { themes } from 'react-pick-color';

// UTILS

//STYLES
import "./update-status.scss";

//COMPONENTS
import { Modal, Tippy } from "../..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { Input } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APITask from "./../../../../services/api/task";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";
import { FieldContainer } from "../../../data-display";

//ASSETS - icons, images...

export default function UpdateStatus({
    isOpen,
    onClose,
    boardEditId,
    taskStatus,
    onEdit,
    setIsFrameUpdated
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL
    const colorPickerRef = useRef(null);


    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
    const [value, setValue] = useState(null);
    const [originalValue, setOriginalValue] = useState(null);
    const [id, setId] = useState(null);

    // queueColor

    //FUNCTIONS
    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setIsColorPickerVisible(false);
        }
    };;

    function onCloseCustom() {
        onClose();
        setValue(originalValue);
    };

    function getUpdateData(statusId) {
        taskStatus?.forEach((item) => {
            if (item.id_status === statusId) {
                setValue(item);
                setOriginalValue(item); 
                setId(item.id_status);
            }
        })
    };

    async function updateStatus() {
        setPageStatus("update-status")

        const updateStatus = {
            id_queue: id,
            desc_queue: value?.descricao_status,
            cor_fila: value?.queueColor
        }

        const response = await APITask.updateStatus(id, token, updateStatus);

        if (response.status === 200) {
            Toast("Status Atualizado!", "Status atualizado com sucesso.", "success");
            onEdit();
            onCloseCustom();

            setIsFrameUpdated(true);
        } else {
            Toast(
                "Falha, não foi possível atualizar o status!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setPageStatus("awaiting");
    };


    //USE EFFECTS
    useEffect(() => {
        if (boardEditId) {
            getUpdateData(boardEditId);
        }
    }, [boardEditId, onEdit]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Modal.Main
            className="modal-update-status"
            title="Editar Status"
            isOpen={isOpen}
            onClose={onCloseCustom}
            width="310px"
            height="295px"
            footer={
                <>
                    <Button
                        className="modal-update-status__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "update-status"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-update-status__btn-confirm"
                        disabled={pageStatus === "update-status"}
                        onClick={() => updateStatus()}
                    >
                        {pageStatus !== "update-status" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots width={70} height={70} />
                        )}
                    </Button>
                </>
            }
        >
            <section className="modal-update-status__content">
                <FieldContainer title={"Descrição status"}>
                    <Input
                        type="text"
                        placeholder="Descrição status"
                        value={value?.descricao_status}
                        onChange={(event) => setValue({ ...value, descricao_status: event })}
                    />
                </FieldContainer>

                <FieldContainer title="Cor do quadro">
                    <div className="color-select">
                        <Tippy content="Clique para selecionar">
                            <div
                                className="color-preview"
                                style={{ backgroundColor: value?.queueColor }}
                                onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
                            />
                        </Tippy>

                        {isColorPickerVisible && (
                            <div ref={colorPickerRef} className="color-picker">
                                <ColorPicker
                                    color={value?.queueColor}
                                    onChange={color => setValue({ ...value, queueColor: color.hex })}
                                    theme={themes.dark}
                                    hideAlpha={true}
                                    hideInputs={true}
                                />
                            </div>
                        )}
                    </div>
                </FieldContainer>
            </section>
        </Modal.Main>
    );
}
