import React from "react";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import { Typography } from "../../general";
import { RadialBarChart } from "../../data-graphics/";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as FileSearching } from "../../../assets/icons/File-searching.svg";

export default function CardVaried({ card, showIconType, darkMode, onClick }) {
  //GENERAL
  const data = [
    {
      id: card.title,
      data: [
        {
          x: "Total",
          y: card.percentage === "100.0" ? 100 : card.percentage,
          color: "#455CC7",
          colorTrack: "#BFC3CA",
        },
      ],
    },
  ];

  //STATES

  //REDUX - Selectors

  //FUNCTIONS
  console.log("PERCENTAGE", card.valueIndicator);

  return (
    <div className="card-dashboard__varied">
      <Typography.H4 color={darkMode ? "white" : "gray-180"} weight={700}>
        {card.title}
      </Typography.H4>

      {card.emptySpace ? (
        <div className="card-dashboard-emptySpace column">
          <FileSearching width={120} height={120} />
          <Typography.H5 color={darkMode ? "white" : "gray-180"}>
            Oops! A sua pesquisa não gerou resultados.
          </Typography.H5>
        </div>
      ) : (
        <>
          <div
            className="card-dashboard__varied-view"
            onMouseMove={(e) =>
              (e.target.style.cursor =
                card.valueIndicator > 0 ? "pointer" : "default")
            }
            onClick={card.percentage > 0 ? onClick : null}
          >
            <RadialBarChart
              data={data}
              // onClick={onClick}
              darkMode={darkMode}
              internalRadius={0.7}
              centerSize={32}
              centerWeight={700}
              halfCenter
              centerValue={card.valueIndicator}
              bottom={-80}
              // borderWidth={10}
            />
          </div>
          <div className="card-dashboard__varied-container__box">
            <Typography.H6
              className="card-dashboard__varied-subtitle"
              color={darkMode ? "white" : "gray-180"}
            >
              {card.subtitle}
            </Typography.H6>
          </div>
        </>
      )}
    </div>
  );
}
