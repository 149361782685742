//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { format, isSameMonth } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";

//STYLES
import "./list.scss";

//COMPONENTS
import {
  PageTitle,
  TaskListCategory,
} from "./../../../components/data-display";
import {
  Input,
  DatePicker,
  FilterList,
  ProfileList,
} from "./../../../components/data-entry";
import { Loading, Modal, Drawer, Tippy } from "./../../../components/feedback";
import { Button, Typography } from "./../../../components/general";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import Kanban from "./Kanban/Kanban";
// import Timeline from "./Timeline/Timeline";

//SERVICES - api, conectors...
import * as APITask from "./../../../services/api/task";
import * as APITag from "../../../services/api/tag";
import { connection_platform } from "../../../services/api/configConnection";

// UTILS
import formatDate from "./../../../utils/formatDate";
import { Toast } from "./../../../utils/toast";

//GLOBAL STATE - redux, env...
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "./../../../store/filters";

//ASSETS - icons, images...
import { ReactComponent as ImgEmptyState } from "./../../../assets/images/empty-state.svg";
import { ReactComponent as IconSearch } from "./../../../assets/icons/Search.svg";
import { ReactComponent as IconHamburguer } from "./../../../assets/icons/Hamburguer.svg";
// import { ReactComponent as IconTimeline } from "./../../../assets/icons/Timeline.svg";
import { ReactComponent as IconKanban } from "./../../../assets/icons/Kanban.svg";
import { ReactComponent as IconStatusComplete } from "./../../../assets/icons/StatusComplete.svg";
import { ReactComponent as IconStatusInProgress } from "./../../../assets/icons/StatusInProgress.svg";
import { ReactComponent as IconStatusPending } from "./../../../assets/icons/StatusPending.svg";
// import { ReactComponent as IconAddTask } from "./../../../assets/icons/IconAdd.svg";
// import { ReactComponent as IconFilterSearch } from "./../../../assets/icons/Filter-search.svg";
// import { ReactComponent as IconAddCircle } from "./../../../assets/icons/AddCircle.svg";
// import { ReactComponent as IconFilter } from "./../../../assets/icons/Filter.svg";
// import { ReactComponent as IconTable } from './../../../assets/icons/Table.svg';
// import { ReactComponent as IconAll } from './../../../assets/icons/All.svg';
// import { ReactComponent as IconSort } from './../../../assets/icons/Sort.svg';
import { ReactComponent as ExcelDownloadIcon } from "./../../../assets/icons/ExcelDownloadIcon.svg";

export default function List() {
  //GENERAL
  const categoryIdOrder = [3, 2, 4];
  const dispatch = useDispatch();
  const location = useLocation();

  const usersTasks = [];

  //STATES
  const [profiles, setProfiles] = useState(usersTasks);
  const [isDrawerFiltersOpen, setIsDrawerFiltersOpen] = useState(false);
  const [isOpenModalAddTask, setIsOpenModalAddTask] = useState({
    modal: false,
    queueId: null
  });
  const [pageStatus, setPageStatus] = useState("requesting");
  const [typeView, setTypeView] = useLocalStorage("taskViewType", "normal");
  const [tasks, setTasks] = useState([]);
  const [monthlyTasks, setMonthlyTasks] = useState([]);
  const [status, setStatus] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [taskStatusData, setTaskStatusData] = useState([]);
  // const [colorTaskTimeline, setColorTaskTimeline] = useState();
  // const [timelineData, setTimelineData] = useState([]);
  // const [category, setCategory] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allProfileList, setAllProfileList] = useState([]);
  const [filterUserTasks, setFilterUserTasks] = useLocalStorage("filterUserTasks", false);
  const [isFrameCreated, setIsFrameCreated] = useState(false);
  const [isFrameDeleted, setIsFrameDeleted] = useState(false);
  const [isFrameUpdated, setIsFrameUpdated] = useState(false);
  const [filtersList, setFiltersList] = useState({
    status: [
      {
        id: 2,
        label: "A fazer",
      },
      {
        id: 3,
        label: "Em andamento",
      },
      {
        id: 4,
        label: "Concluídos",
      },
    ],
  });
  const [customEmptyTaskData, setCustomEmptyTaskData] = useState();


  //REDUX - Selectors
  const token = useSelector((state) => state.auth.token);
  const filtersActive = useSelector((state) => state.filters.tasks);
  const userData = useSelector((state) => state.auth.userData);
  const permissionProfile = useSelector((state) => state.auth.permissionProfile);

  //FUNCTIONS
  const convertTaskIdToString = (tasks) => {
    return tasks.map((task) => ({
      ...task,
      id_tarefa: String(task.id_tarefa),
    }));
  };

  const handleFilterUserTasks = (data) => {
    const updatedList = data.filter(
      (item) => item.id_solicitante === userData.userId
    );

    if (updatedList) {
      return updatedList;
    }
  };

  const handleFilteredUsersTasks = (data) => {
    if (profiles.length > 0) {
      const updatedList = data.filter((item) =>
        profiles?.some(
          (users) => users.id_usuario === item.id_solicitante
        )
      );

      if (updatedList) {
        return updatedList;
      }
    }
  };

  const handleAddBoard = async (valueStatus, statusDesc, queueColor) => {
    setTaskStatus([
      ...taskStatus,
      {
        id_status: null,
        descricao_status: statusDesc,
        cor_fila: queueColor,
        tasks: [],
      },
    ]);

    const updatedValueStatus = {
      ...valueStatus,
      cor_fila: queueColor,
    };

    const response = await APITask.createStatus(token, updatedValueStatus);

    if (response.status === 200) {
      getStatus();
      Toast("Quadro adicionado!", "Quadro adicionado com sucesso.", "success");

      setIsFrameCreated(true);
    } else {
      Toast(
        "Não foi possível adicionar esse quadro!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  };

  async function updateMonthlyTasks() {
    // setPageStatus("requesting");

    const response = await APITask.getTasks(
      filtersActive.title,
      filtersActive.dateStart && formatDate(filtersActive.dateStart),
      filtersActive.dateEnd && formatDate(filtersActive.dateEnd),
      token
    );

    if (response.status === 200) {
      setTasks(response.data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }

    // setPageStatus("awaiting");
  }

  async function getStatus() {
    const response = await APITask.listStatus(token);
    const data = response?.data;

    if (response.status === 200) {
      setStatus(data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getTags() {
    const response = await APITag.listTags(token);
    const data = response?.data;

    if (response.status === 200) {
      setAllTags(data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getProfiles() {
    const response = await APITask.listUsers(token);
    const data = response?.data;

    if (response.status === 200) {
      setAllProfileList(data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getDataInfo() {
    try {
      await Promise.allSettled([
        getTags(),
        getProfiles(),
        getStatus(),
        updateMonthlyTasks(),
      ])
    } catch (error) {
      console.log("Error ao obter dados do endPoint:", error);
    }
  }

  // function formataStringData(data) {
  //   var dia = data.split("/")[0];
  //   var mes = data.split("/")[1];
  //   var ano = data.split("/")[2];

  //   return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
  // }

  function refactorTaskList(data) {
    let dataByDate = [];

    data.forEach((task) => {
      const resolutionDate = new Date(task.prazo_resolucao);

      // push unique dates into array
      const resolutionMonthYear = format(resolutionDate, "MMMM'/'y", {
        locale: ptBR,
      });

      if (
        !dataByDate.find((month) => isSameMonth(month.date, resolutionDate))
      ) {
        dataByDate.push({
          title: resolutionMonthYear,
          date: resolutionDate,
          categories: [],
        });
      }

      // push unique categories into dates
      const taskMonthIndex = dataByDate.findIndex((i) =>
        isSameMonth(i.date, resolutionDate)
      );

      if (
        !dataByDate[taskMonthIndex].categories.find(
          (category) => category.id === task.id_fila
        )
      ) {
        dataByDate[taskMonthIndex].categories.push({
          id: task.id_fila,
          title: task.descricao_fila,
          tasks: [],
        });
      }

      // push tasks into respective dates and categories
      const taskCategoryIndex = dataByDate[taskMonthIndex].categories.findIndex(
        (i) => i.id === task.id_fila
      );

      dataByDate[taskMonthIndex].categories[taskCategoryIndex].tasks.push(task);
    });

    // ordering dataByDate
    dataByDate.sort((a, b) => a.date - b.date);

    // ordering by categoryIdOrder
    dataByDate.forEach((datum) => {
      datum.categories.sort(
        (a, b) => categoryIdOrder.indexOf(a.id) - categoryIdOrder.indexOf(b.id)
      );
    });

    return dataByDate;
  }

  function filterTasks(data, param, type) {
    let updatedData = data.filter((datum) => {
      return filtersActive[type].some((filter) => {
        return datum[param] === filter.id;
      });
    });
    return updatedData;
  }

  function renderTasksList() {
    const taskListLength = typeView === "kanban" ? taskStatusData.length : monthlyTasks.length;

    switch (pageStatus) {
      case "requesting":
        return <Loading.Paperplane className="loading-task-list" />;

      case "awaiting":
        if (taskListLength > 0) {
          return (
            <>
              {typeView === "kanban" ? (
                <section className="section-tasks-kanban">
                  <Kanban
                    token={token}
                    userData={userData}
                    permissionProfile={permissionProfile.tarefas}
                    taskStatus={taskStatus}
                    setTaskStatus={setTaskStatus}
                    setIsOpenModalAddTask={setIsOpenModalAddTask}
                    addBoard={handleAddBoard}
                    getStatus={getStatus}
                    updateMonthlyTasks={updateMonthlyTasks}
                    setIsFrameDeleted={setIsFrameDeleted}
                    setIsFrameUpdated={setIsFrameUpdated}
                  />
                </section>
              ) : (
                <>
                  {typeView === "timeline" ? (
                    <section className="section-tasks-timeline">
                      {/* <Timeline
                        timelineData={timelineData}
                        category={category}
                        setTimelineData={setTimelineData}
                      /> */}
                    </section>
                  ) : (
                    <section className="section-tasks-list">
                      {/* <header className="section-tasks-list__header">
                        <Typography.H1
                          className="tasks-list-header__title"
                          weight="bold"
                          color='blue-selected-4'
                        >
                          {month.title}
                        </Typography.H1>
                      </header> */}

                      {monthlyTasks.map((month, monthKey) => {
                        return (
                          <section
                            key={`month-${monthKey}`}
                            className="tasks-list"
                          >
                            <header className="tasks-list-header">
                              <Typography.H1
                                className="tasks-list-header__title"
                                weight="bold"
                                color="gray-500"
                              >
                                {month.title}
                              </Typography.H1>
                            </header>

                            <ul className="tasks-list-categories">
                              {month.categories.map((category, categoryKey) => {
                                return (
                                  <TaskListCategory
                                    key={`${month.title}-category-${categoryKey}`}
                                    categoryData={category}
                                    updateMonthlyTasks={updateMonthlyTasks}
                                    onClickNewTask={() => {
                                      setCustomEmptyTaskData({
                                        // id_fila: category.id,
                                        prazo_resolucao: month.date,
                                      });
                                      setIsOpenModalAddTask({ modal: true, queueId: category.id });
                                    }}
                                    getTags={getTags}
                                    allTags={allTags}
                                    allProfileList={allProfileList}
                                  />
                                );
                              })}
                            </ul>
                          </section>
                        );
                      })}
                    </section>
                  )}
                </>
              )}
            </>
          );
        } else {
          return (
            <div className="empty-state-task-list">
              <ImgEmptyState className="empty-state-task-list__img" />
              <Typography.H3 color="gray-400">
                Não encontramos nenhum resultado.
              </Typography.H3>
              <Typography.H3 color="gray-400">
                Que tal reformular sua busca?
              </Typography.H3>
            </div>
          );
        }
      default:
        break;
    }
  }

  function handleFilterList(data) {
    const filteredTasks = filterTasks(data, "id_fila", "status");
    const dataByDate = refactorTaskList(filteredTasks);
    setMonthlyTasks(dataByDate);
  }

  function handleExportTable() {
    let tasksExcel = [...tasks];
    let excelTable = [];
    const excelHeader = [
      "Descrição",
      "Assunto",
      "Data de abertura",
      "Ultima atualização",
      "Data de fechamento",
      "Prazo início",
      "Prazo de resolução",
      "Empresa",
      "Nome fantasia",
      "Status",
      "Criticidade",
      "Usuário de criação",
      "Responsável",
      "Fila",
      "Contato",
      "Empresa do contato",
      "Ticket associado",
    ];
    // const headerDisplay = headerAdjust.map((column) => column.display);
    const headerConfigs = [];
    if (filtersActive.status.length > 0) {
      const idsPermitidos = filtersActive.status.map((status) => status.id);
      tasksExcel = tasksExcel.filter((tarefa) =>
        idsPermitidos.includes(tarefa.id_fila)
      );
    }

    if (filterUserTasks) {
      tasksExcel = handleFilterUserTasks(tasksExcel);
    } else if (profiles.length > 0) {
      tasksExcel = handleFilteredUsersTasks(tasksExcel);
    }

    tasksExcel.forEach((datum) => {
      const tasksRefactored = {
        descricao: datum.descricao_tarefa,
        assunto: datum.assunto,
        dataAbertura: `${datum.dt_abertura_data} ${datum.dt_abertura_hora}`,
        dataUltimaAtualizacao: `${datum.dt_ultima_atualizacao_data} ${datum.dt_ultima_atualizacao_hora}`,
        dataFechamento: `${datum.dt_fechamento
          ? format(new Date(datum.dt_fechamento), "dd/MM/yyyy HH:mm:ss")
          : "Sem data"
          }`,
        prazoInicio: `${datum.dt_ultima_atualizacao_data} ${datum.dt_ultima_atualizacao_hora}`,
        prazoResolucao: `${datum.prazo_resolucao_data} ${datum.prazo_resolucao_hora}`,
        empresa: datum.nome_empresa,
        nomeFantasia: datum.nome_fantasia,
        status: datum.descricao_status,
        criticidade: datum.descricao_criticidade,
        usuarioCriacao: datum.nome_usuario_solicitante,
        responsavel: datum.nome_usuario_responsavel,
        fila: datum.descricao_fila,
        contato: datum.nome_contato,
        empresaContato: datum.nome_empresa,
        ticketAssociado: datum.codigo_ticket,
      };
      excelTable.push(tasksRefactored);
    });

    let worksheet;

    worksheet = XLSX.utils.json_to_sheet(excelTable);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tarefas");
    XLSX.utils.sheet_add_aoa(worksheet, [excelHeader]);
    const max_width = excelTable.reduce(
      (w, r) => Math.max(w, r.assunto.length),
      10
    );
    while (headerConfigs.length < excelHeader.length) {
      excelHeader.forEach((value) => {
        const objectHeader = {
          wch: max_width,
          hidden: false,
        };

        headerConfigs.push(objectHeader);
      });
    }

    worksheet["!cols"] = headerConfigs;
    XLSX.writeFile(workbook, "ListagemDeTarefas.xlsx");
  }

  //USE EFFECTS
  useEffect(() => {
    getDataInfo();
  }, []);

  useEffect(() => {
    const comInterval = setInterval(async () => {
      updateMonthlyTasks();
    }, connection_platform.time_atualization);
    return () => clearInterval(comInterval);
  }, [tasks]);

  useEffect(() => {
    if (tasks && filtersActive) {
      const filteredTasks = filterTasks(tasks, "id_fila", "status");
      const dataByDate = refactorTaskList(filteredTasks);
      setMonthlyTasks(dataByDate);
      setPageStatus("awaiting");
    }
  }, [tasks]);

  useEffect(() => {
    if (tasks) {
      const tasksWithStringId = convertTaskIdToString(tasks);

      if (filterUserTasks) {
        const filteredTasksStatus = handleFilterUserTasks(tasksWithStringId);

        setTaskStatusData(filteredTasksStatus);

        if (filteredTasksStatus) {
          handleFilterList(filteredTasksStatus);
        }

        setPageStatus("awaiting");
      } else if (profiles.length > 0) {
        const filteredUsesrTasks = handleFilteredUsersTasks(tasksWithStringId);

        setTaskStatusData(filteredUsesrTasks);

        if (filteredUsesrTasks) {
          handleFilterList(filteredUsesrTasks);
        }

        setPageStatus("awaiting");
      } else {
        setTaskStatusData(tasksWithStringId);
        handleFilterList(tasksWithStringId);
      }
    }
  }, [tasks, filterUserTasks, profiles.length]);

  useEffect(() => {
    if (pageStatus === "awaiting") updateMonthlyTasks();
  }, [
    filtersActive.dateStart,
    filtersActive.dateEnd,
    filtersActive.status,
    filterUserTasks,
    profiles.length,
  ]);

  useEffect(() => {
    if (filtersActive.title?.length === 0 && pageStatus === "awaiting") {
      updateMonthlyTasks();
    }
  }, [filtersActive.title]);

  useEffect(() => {
    status?.forEach((item) => {
      item = Object.assign(item, { status_icon: null });

      if (item.id_fila === 2) {
        item.status_icon = <IconStatusInProgress />;
      } else if (item.id_fila === 3) {
        item.status_icon = <IconStatusPending />;
      } else if (item.id_fila === 4) {
        item.status_icon = <IconStatusComplete />;
      }
    });
  }, [status]);

  useEffect(() => {
    let updateTasksListKanban = [];

    status?.forEach((s) => {
      const tasksData = taskStatusData?.filter((t) => t.id_fila === s.id_fila);

      let queueColor = s.cor_fila;

      if (s.id_fila === 2) {
        queueColor = "#5b78ff";
      } else if (s.id_fila === 3) {
        queueColor = "#ffbb00";
      } else if (s.id_fila === 4) {
        queueColor = "#008908";
      }

      if (tasksData) {
        updateTasksListKanban.push({
          id_status: s.id_fila.toString(),
          descricao_status: s.descricao,
          permit_delete: s.sistema,
          queueColor: queueColor,
          icon: s.status_icon,
          queueId: tasksData[0]?.id_fila,
          tasks: tasksData,
        });
      }
    });

    setTaskStatus(updateTasksListKanban);
  }, [status, taskStatusData]);

  return (
    <>
      <main
        className={`page-tasks-list 
          ${location.pathname === `/tarefas` ||
            location.pathname === "/" ? "tasks-list-modify" : ""
          }`}
      >
        <PageTitle
          className="header-tasks-list"
          classTitle="header-tasks-list__title"
          title="Tarefas"
          leftContent={
            <>
              <Input
                onKeyPress={(event) =>
                  (event.code === "Enter" || event.code === "NumpadEnter") &&
                  updateMonthlyTasks()
                }
                onChange={(event) =>
                  dispatch(
                    setFilter({ to: "tasks", type: "title", value: event })
                  )
                }
                value={filtersActive.title}
                className="header-tasks-list__input-search"
                type="text"
                placeholder="Pesquisar por titulo da tarefa"
                rightIcon={
                  <button onClick={() => updateMonthlyTasks()}>
                    <IconSearch />
                  </button>
                }
              />
              {/* {typeView !== "kanban" && ( */}
              <div className="header-tasks-list__date-range-container">
                <DatePicker.DateRange
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  className="startDate"
                  selectRangeType="start"
                  selected={filtersActive.dateStart}
                  setDate={(event) =>
                    dispatch(
                      setFilter({
                        to: "tasks",
                        type: "dateStart",
                        value: event,
                      })
                    )
                  }
                  startDate={filtersActive.dateStart}
                  endDate={filtersActive.dateEnd}
                  dateFormat="dd 'de' MMMM', 'u"
                  placeholderText="Escolha uma data inicio"
                  isClearable={filtersActive.dateStart}
                />

                <Typography.H5 color="gray-180">até</Typography.H5>

                <DatePicker.DateRange
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  className="header-tasks-list__date-range-container__endDate"
                  selectRangeType="end"
                  selected={filtersActive.dateEnd}
                  setDate={(event) =>
                    dispatch(
                      setFilter({
                        to: "tasks",
                        type: "dateEnd",
                        value: event,
                      })
                    )
                  }
                  startDate={filtersActive.dateStart}
                  endDate={filtersActive.dateEnd}
                  dateFormat="dd 'de' MMMM', 'u"
                  minDate={filtersActive.dateStart}
                  placeholderText="Escolha uma data fim"
                  isClearable={filtersActive.dateEnd}
                />
              </div>
              {/* )} */}
              {/* {typeView === "kanban" && ( */}

              {/* )} */}
              {/* #POS-MVP: Implementar filtros avançados */}
              {/* <Button type='tertiary' icon={<IconFilter />} border onClick={() => setIsDrawerFiltersOpen(true)}>
                <Typography.H6 color='gray-400'>Filtrar</Typography.H6>
              </Button> */}
            </>
          }
          rightContent={
            <>
              <div className="types-views">
                <Tippy content="Lista">
                  <div
                    className={
                      typeView === "normal"
                        ? "preview-option normal active"
                        : "preview-option"
                    }
                    onClick={() => setTypeView("normal")}
                  >
                    <IconHamburguer />
                  </div>
                </Tippy>

                <Tippy content="Kanban">
                  <div
                    className={
                      typeView === "kanban"
                        ? "preview-option kanban active"
                        : "preview-option"
                    }
                    onClick={() => setTypeView("kanban")}
                  >
                    <IconKanban />
                  </div>
                </Tippy>

                {/* <Tippy content="Timeline">
                  <div
                    className={typeView === "timeline" ? "preview-option timeline active" : "preview-option"}
                    onClick={() => setTypeView("timeline")}
                  >
                    <IconTimeline />
                  </div>
                </Tippy> */}
              </div>

              <div className="buttons">
                {/* <Button
                  width="160px"
                  className={"btn-more-filters"}
                  type="fourthiary"
                  icon={<IconFilterSearch />}
                  onClick={() => setIsDrawerFiltersOpen(true)}
                  border
                >
                  <Typography.H5 weight="bold" color={"blue-selected-4"}>
                    Mais filtros
                  </Typography.H5>
                </Button> */}

                <Button
                  className="btn-create-task"
                  iconFillType="stroke"
                  onClick={() => {
                    setIsOpenModalAddTask({ modal: true });
                  }}
                >
                  <Typography.H4 weight="bold" color={"white"}>
                    + Tarefa
                  </Typography.H4>
                </Button>
              </div>
            </>
          }
        />
        <section className="container-sections-tasks">
          <section className="section-tasks-filters">
            {typeView === "kanban" || typeView === "timeline" ? null : (
              <FilterList
                label="Selecione por status:"
                className="section-tasks-filters-status"
                filtersList={filtersList.status}
                filtersActive={filtersActive.status}
                setFiltersActive={(event) =>
                  dispatch(
                    setFilter({ to: "tasks", type: "status", value: event })
                  )
                }
                allButton={false}
              />
            )}
            <div className="header-tasks-list__filter">
              <ProfileList
                buttonTooltip
                type="add"
                placeholderTippy="Filtrar tarefas de usuários"
                profiles={profiles}
                allProfileList={allProfileList}
                setProfiles={setProfiles}
              />

              <div
                className={
                  filterUserTasks
                    ? "header-tasks-list__filter__button active"
                    : "header-tasks-list__filter__button"
                }
                onClick={() => setFilterUserTasks(!filterUserTasks)}
              >
                <Typography.H5
                  weight="500"
                  color={filterUserTasks ? "white" : "blue-selected-4"}
                >
                  Ver somente minhas tarefas
                </Typography.H5>
              </div>
            </div>
            {typeView === "kanban" || typeView === "timeline" ? null : (
              <Tippy content="Exportar para Excel">
                <div
                  className="excel-table"
                  onClick={() => handleExportTable()}
                >
                  <ExcelDownloadIcon />
                </div>
              </Tippy>
            )}
          </section>

          {renderTasksList()}
        </section>
      </main>

      <Drawer.AdvancedFilters
        isOpen={isDrawerFiltersOpen}
        onClose={() => setIsDrawerFiltersOpen(!isDrawerFiltersOpen)}
        screen={"Tasks"}
      />

      <Modal.CreateTask
        isOpen={isOpenModalAddTask.modal}
        onClose={(event) => {
          setTimeout(() => {
            setCustomEmptyTaskData({
              id_fila: 2,
            });
          }, 500);
          setIsOpenModalAddTask({
            modal: event,
            queueId: null
          });
        }}
        taskStatus={taskStatus}
        onCreate={updateMonthlyTasks}
        customEmptyTaskData={customEmptyTaskData}
        queueId={isOpenModalAddTask.queueId}
        isFrameCreated={isFrameCreated}
        isFrameDeleted={isFrameDeleted}
        isFrameUpdated={isFrameUpdated}
      />
    </>
  );
}
