//GLOBAL - components from npm
import React, { useState } from "react";
import { useParams } from "react-router";

// UTILS
import { Toast } from "../../../../utils/toast";

//STYLES
import "./task-list.scss";

//COMPONENTS
import { Typography, Button } from "../../../../components/general";
import { CardTask } from "../../../../components/data-display";
import { Modal } from "./../../../../components/feedback";

//SERVICES - api, conectors...
import * as APICase from "../../../../services/api/case";
import * as APITask from "./../../../../services/api/task";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconEmptyState } from "../../../../assets/images/empty-state.svg";
import { ReactComponent as IconEyeSlash } from "../../../../assets/icons/Eye-slash.svg";
import { ReactComponent as IconClipboardTick } from "../../../../assets/icons/ClipboardTick.svg";

export default function TaskList({
  caseDetail,
  onRefreshPage,
  customEmptyTaskData,
  clientData,
  tagLimit,
  getDetails,
  permissions,
  customEmptyTaskDataSplitview,
  createTaskSplitview,
  deleteTaskSplitview,
  disassociateTaskSplitview,
  handleCreateTask,
  handleDeleteTask,
  handleDisassociateTask
}) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.userData);
  const { id } = useParams();

  //STATES
  const [isOpenModalAddTask, setIsOpenModalAddTask] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS

  //Função que desassocia a tarefa do caso e associa o agente que fez essa desassociação ao caso.
  const disassociateTask = async (idTask) => {
    if (disassociateTaskSplitview) {
      handleDisassociateTask(idTask, id, token)
    } else {
      const data = {
        id_task: idTask,
        id_ticket: null,
        id_campo: 16,
        id_ticket_atual: id,
      };
      const response = await APITask.associateTaskToCase(data, token);

      if (response.status === 200) {
        Toast(
          "Tarefa Desvinculada!",
          "Tarefa desvinculada com sucesso.",
          "success"
        );
      } else {
        Toast(
          "Falha, não foi possível desvincular tarefa!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );
      }

      if (response.status === 200 && caseDetail.usersAssociated.length === 0) {
        const dataAssociate = {
          id_usuario: user.userId,
          id_ticket: id,
        };

        await APICase.associateUser(dataAssociate, token);
      }

      if (
        caseDetail.usersAssociated.length > 0 &&
        caseDetail.usersAssociated[0].id_usuario !== user.userId &&
        !caseDetail.usersAssociated[0].gestor
      ) {
        const dataAssociate = {
          id_usuario: user.userId,
          id_ticket: id,
        };

        await APICase.disassociateUser(
          id,
          caseDetail.usersAssociated[0].id_usuario,
          token
        );

        await APICase.associateUser(dataAssociate, token);
      }

      await getDetails();
    }
  };

  //Função que deleta a tarefa e associa o agente que fez essa remoção ao caso.
  const deleteTask = async (idTask) => {
    if (deleteTaskSplitview) {
      handleDeleteTask(idTask, id, token);
    } else {
      const response = await APITask.deleteTask(idTask, user.userId, token);

      if (response.status === 200) {
        Toast("Tarefa Deletada!", "Tarefa deletada com sucesso.", "success");
      } else {
        Toast(
          "Falha, não foi possível deletar tarefa!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );
      }

      if (response.status === 200 && caseDetail.usersAssociated.length === 0) {
        const dataAssociate = {
          id_usuario: user.userId,
          id_ticket: id,
        };

        await APICase.associateUser(dataAssociate, token);
      }

      if (
        caseDetail.usersAssociated.length > 0 &&
        caseDetail.usersAssociated[0].id_usuario !== user.userId &&
        !caseDetail.usersAssociated[0].gestor
      ) {
        const dataAssociate = {
          id_usuario: user.userId,
          id_ticket: id,
        };

        await APICase.disassociateUser(
          id,
          caseDetail.usersAssociated[0].id_usuario,
          token
        );

        await APICase.associateUser(dataAssociate, token);
      }

      await getDetails();
    }
  };

  //USE EFFECTS

  return (
    <>
      <section className="case-details-task">
        {caseDetail.tasksAssociated.length > 0 && permissions.visualizacao && (
          <div className="case-details-task__title">

            {/* <Typography.H4 color="gray-400">
              Tarefas vinculadas ao caso
            </Typography.H4> */}

            Tarefas vinculadas ao caso ({`${caseDetail.tasksAssociated.length}`.padStart(2, "0")})

            {/* <div className="case-details-task__number-title">
              {`${caseDetail.tasksAssociated.length}`.padStart(2, "0")}
            </div> */}
          </div>
        )}
        {caseDetail.tasksAssociated.length > 0 && permissions.visualizacao ? (
          <div className="case-details-task-list">
            {caseDetail.tasksAssociated.map((task, index) => (
              <CardTask
                key={index}
                deadline={task.prazo_resolucao}
                title={task.assunto}
                description={task.descricao}
                tags={task.tagsAssociated}
                profiles={task.usersAssociated}
                status={task.id_fila}
                lembretes={task.remindersAssociated}
                id={task.id_tarefa}
                idSolicitante={task.id_solicitante}
                onDelete={() => deleteTask(task.id_tarefa)}
                onUnlink={() => disassociateTask(task.id_tarefa)}
                tagLimit={tagLimit}
                permissions={permissions}
              />
            ))}
          </div>
        ) : (
          <div className="case-details-task-list__empty-state">
            {permissions.visualizacao ? (
              <div className="container-empty-state">
                {/* <IconEmptyState /> */}
                <IconClipboardTick />
              </div>
            ) : (
              <div className="container-icon">
                <IconEyeSlash />
              </div>
            )}
            <Typography.H4 color="gray-400">
              {!permissions.visualizacao
                ? "Você não tem permissão para visualizar as tarefas."
                : "Sem tarefas vinculadas a esse caso."}
            </Typography.H4>
          </div>
        )}

        {/* {permissions.insercao && (
          <Button onClick={() => setIsOpenModalAddTask(true)}>
            Adicionar nova tarefa
          </Button>
        )} */}

        <div className="case-details-task__container-button">
          {permissions.insercao && (
            <Button
              className="button-add-task"
              onClick={() => setIsOpenModalAddTask(true)}
              icon={"+"}
            >
              <Typography.H4 weight="bold" color={"white"}>
                Adicionar tarefa
              </Typography.H4>
            </Button>
          )}
        </div>
      </section>
      <Modal.CreateTask
        className="cases-details-create-task"
        isOpen={isOpenModalAddTask}
        onClose={(event) => {
          setIsOpenModalAddTask(event);
        }}
        onCreate={async () => {
          if (createTaskSplitview) {
            handleCreateTask(id, token)
          } else {
            if (caseDetail.usersAssociated.length === 0) {
              const dataAssociate = {
                id_usuario: user.userId,
                id_ticket: id,
              };

              await APICase.associateUser(dataAssociate, token);
            }

            if (
              caseDetail.usersAssociated.length > 0 &&
              caseDetail.usersAssociated[0].id_usuario !== user.userId &&
              !caseDetail.usersAssociated[0].gestor
            ) {
              const dataAssociate = {
                id_usuario: user.userId,
                id_ticket: id,
              };

              await APICase.disassociateUser(
                id,
                caseDetail.usersAssociated[0].id_usuario,
                token
              );

              await APICase.associateUser(dataAssociate, token);
            }
            onRefreshPage();
          }
        }}
        customEmptyTaskData={customEmptyTaskData}
        customEmptyClientData={clientData}
        customEmptyTaskDataSplitview={customEmptyTaskDataSplitview}
      />
    </>
  );
}
