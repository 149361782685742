import React, { useEffect, useRef, useState } from "react";
import ColorPicker, { themes } from 'react-pick-color';

import { Input } from "../../../../components/data-entry";
import { Typography } from "../../../../components/general";
import { FieldContainer } from "../../../../components/data-display";
import { Toast } from "../../../../utils/toast";
import { Tippy } from "../../../../components/feedback";

import { ReactComponent as IconClose } from "../../../../assets/icons/Close.svg";

export default function Editable({
    onSubmit,
    taskStatus
}) {
    const colorPickerRef = useRef(null);

    const [isEditable, setIsEditable] = useState(false);
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
    const [color, setColor] = useState('#F4511E');
    const [valueStatus, setValueStatus] = useState(() => { return { descricao: "" }; });

    const submission = (e) => {
        e.preventDefault();

        let index = taskStatus?.findIndex(value => value.descricao_status === valueStatus.descricao);

        if (index < 0) {
            if (valueStatus.descricao && onSubmit) {
                onSubmit(valueStatus, valueStatus.descricao, color);

                setValueStatus({ ...valueStatus, descricao: "" });
                setColor('#F4511E');
                setIsEditable(false);
            }
        } else {
            setValueStatus({ ...valueStatus, descricao: "" });

            Toast(
                'Não foi possível adicionar o novo quadro!',
                'Esse quadro ja existe, adicione outro com nome diferente',
                'warn'
            );
        }
    };

    const handleClickOutside = (event) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setIsColorPickerVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="section-tasks-kanban__board-container__frame-container">
            <div className="new-frame">
                {isEditable ? (
                    <form className="editable-edit" onSubmit={submission}>
                        <FieldContainer title="Nome do quadro">
                            <Input
                                type="text"
                                placeholder="Digite o nome do quadro"
                                value={valueStatus.descricao}
                                onChange={(description) => {
                                    setValueStatus({
                                        ...valueStatus, descricao: description
                                    })
                                }}
                            />
                        </FieldContainer>

                        <FieldContainer title="Cor do quadro">
                            <div className="color-select">
                                <Tippy content="Clique para selecionar">
                                    <div
                                        className="color-preview"
                                        style={{ backgroundColor: color }}
                                        onClick={() => setIsColorPickerVisible(!isColorPickerVisible)}
                                    />
                                </Tippy>

                                {isColorPickerVisible && (
                                    <div ref={colorPickerRef} className="color-picker">
                                        <ColorPicker
                                            color={color}
                                            onChange={color => setColor(color.hex)}
                                            theme={themes.dark}
                                            hideAlpha={true}
                                            hideInputs={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </FieldContainer>

                        <div className="editable-edit__footer">
                            <button type="submit" disabled={!valueStatus.descricao}>
                                <Typography.H5 weight="500" color={"white"}>
                                    Adicionar
                                </Typography.H5>
                            </button>

                            <div
                                className="editable-edit__footer__close"
                                onClick={() => {
                                    setIsEditable(false)
                                    setValueStatus({ ...valueStatus, descricao: "" });
                                    setColor('#F4511E');
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="add-frame" onClick={() => setIsEditable(true)}>
                        <Typography.H4 color="blue-selected-4">
                            + Adicionar novo quadro
                        </Typography.H4>
                    </div>
                )}
            </div>
        </div>
    )
}
