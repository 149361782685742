//GLOBAL - components from npm
import React from 'react';

//STYLES
import './split.scss';

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function Split({
  className = '',
  type,
  sectionA = 'Section A - Content Example',
  sectionB = 'Section B - Content Example'
}) {

  //GENERAL


  //STATES


  //REDUX - Selectors


  //FUNCTIONS
  function renderContent() {
    switch (type) {

      case ('inverted'):
        return (
          <div className={`page-split ${className}`}>
            <section className="page-split__section page-split__section--b">
              {sectionB}
            </section>

            <section className="page-split__section page-split__section--a">
              {sectionA}
            </section>
          </div>
        )

      default:
        return (
          <div className={`page-split ${className}`}>
            <section className="page-split__section page-split__section--a">
              {sectionA}
            </section>

            <section className="page-split__section page-split__section--b">
              {sectionB}
            </section>
          </div>
        )
    }
  }

  //USE EFFECTS


  return (
    renderContent()
  )
}
