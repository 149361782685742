import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SelectedMotives from 'react-select';

import { FieldContainer } from "../../../components/data-display";
import { Button, Typography } from "../../../components/general";
import { PopoverMenu } from "../../../components/navigation";
import { Input, Select } from "../../../components/data-entry";
import { Loading, Modal } from "../../../components/feedback";
import { Toast } from "../../../utils/toast";

import * as APITypeSolicitation from "./../../../services/api/typeSolicitation";
import * as APIMotive from "./../../../services/api/motives";

import { ReactComponent as IconArrowDown } from "./../../../assets/icons/ArrowDown.svg";
import { ReactComponent as IconMoreVertical } from "./../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/Edit-2.svg";
import { ReactComponent as TrashIcon } from "./../../../assets/icons/Trash-2.svg";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/Close02.svg";

export default function List({
    datum,
    listSla,
    listOptionEditable,
    listSolicitation,
    solicitationData,
    setSolicitationData,
    solicitationDataUniqueValues,
    motivesData,
    setMotivesData,
    getMotivesList,
}) {
    const {
        id_solicitacao,
        descricao_solicitacao,
        id_sla,
        id_motivo,
        desc_sla,
        desc_motivo,
        editavel,
    } = datum;

    const token = useSelector((state) => state.auth.token);

    const customStyles = {
        input: (provided) => ({
            ...provided,
            flexGrow: "1",
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            color: "#27282e",
            transition: "all 0.25s",
            outline: "none",
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: "160px",
            position: "absolute",
            display: "flex",
            zIndex: 15,
            flexDirection: "column",
            borderTop: "0",
            overflow: "auto",
            border: "1px solid #2684FF",
            boxShadow: "none",
            borderColor: "#455cc7",
        }),
        control: (provided) => ({
            ...provided,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "flex-start",
            padding: "12px 16px",
            gap: "12px",
            width: "100%",
            height: "120px",
            background: "rgba(255, 255, 255, 0.31)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            cursor: "pointer",
            boxShadow: "none",
            overflow: "auto"
        }),
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            minHeight: "min-content",
            padding: "12px",
            textAlign: "left",

            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#828292",
            cursor: "pointer",

            ":active": {
                backgroundColor: "#ebf2ff",
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            left: "-10px",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            display: "none",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#bfc3ca",
        }),
        multiValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            gap: "4px",
            griGap: "4px",
            padding: "4px 4px 4px 8px",
            backgroundColor: "#f4f4f4",
            borderRadius: "24px",
            minWidth: "max-content",
            height: "25px",
            marginRight: "8px",
            marginBottom: "4px",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "112.5%",
            padding: "5px",
            alignItems: "flex-end",
            color: "#455cc7",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            height: "20px",
            width: "20px",
            background: "#bfc3ca",
            color: "#fff",
            borderRadius: "50%",
            // display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "0.5em",
            fontSize: "18px",
            display: "none",
            cursor: "pointer",

            ":hover": {
                backgroundColor: "none",
            },
        }),
    };

    const [pageStatus, setPageStatus] = useState("requesting");
    const [openCard, setOpenCard] = useState(true);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [updateSolicitation, setUpdateSolicitation] = useState(false);
    const [activeButton] = useState(false);
    const [dataUpdate, setDataUpdate] = useState();
    const [idSolicitation, setIdSolicitation] = useState();
    const [idMotive, setIdMotive] = useState();
    const [selectMotive, setSelectMotive] = useState(null);
    const [motives, setMotives] = useState([]);
    const [motiveIdList, setMotiveIdList] = useState([]);

    const motivesIdArray = useMemo(() => {
        return { list: [] }
    }, []);

    const renderFiltersMotive = () => {
        return motives?.map((item) => {
            if (item.key_solicitation === id_solicitacao) {
                if (item.id != null) {
                    return (
                        <div className="tag-item" key={item.id}>
                            <span className="text">{item.label}</span>
                        </div>
                    )
                } else return null
            } else return null
        })
    };

    function onCloseCustom() { };

    function renderPopoverMenuContentList(idSolicitation) {
        return [
            {
                title: (
                    <>
                        <Typography.H5 color="gray-180" weight="500">
                            <EditIcon />
                            Editar
                        </Typography.H5>
                    </>
                ),
                onClick: () => { setUpdateSolicitation(idSolicitation, true) },
            },
            {
                title: (
                    <>
                        <Typography.H5 color="red" weight="500">
                            <TrashIcon className="popover-icon" />
                            Excluir
                        </Typography.H5>
                    </>
                ),
                onClick: () => { setIsOpenModalDelete(idSolicitation, true) },
            },
        ];
    };

    function getValues(idSolicitation) {
        solicitationDataUniqueValues?.forEach((item) => {
            if (item.id_solicitacao === idSolicitation) {
                setDataUpdate(item);
                setIdSolicitation(item.id_solicitacao);
                setIdMotive(item.id_motivo);
            }
        })
    };

    async function handleEditSolicitation() {
        setPageStatus("update-solicitation");

        const obj = { ...dataUpdate };

        const updateSolicitation = {
            id_solicitacao: idSolicitation,
            descricao_solicitacao: dataUpdate?.descricao_solicitacao,
            id_sla: dataUpdate?.id_sla,
            editavel: dataUpdate?.id_editavel === 1 ? obj.editavel = true : obj.editavel = false,
            id_tipo_solicitacao_sla: dataUpdate?.id_tipo_solicitacao_sla || null,
            id_motivo: selectMotive ? obj.id_motivo = motivesIdArray.list : []
        };

        const response = await APITypeSolicitation.updateSolicitation(token, updateSolicitation);

        if (response.status === 200) {
            Toast("Solicitação atualizada!", "Solicitação atualizada com sucesso.", "success");
            listSolicitation();
        } else {
            Toast(
                "Não foi possível atualizar a solicitação",
                "Tente novamente mais tarde!",
                "error"
            )
        }

        setPageStatus("awaiting");
    };

    function changeCard() {
        if (updateSolicitation) {
            return (
                <div className="card-container-content">
                    <div className="card-container-content__content">
                        <FieldContainer required title="Nome da solicitação">
                            <Input
                                placeholder="Nome da solicitação"
                                value={dataUpdate?.descricao_solicitacao}
                                onChange={(event) => setDataUpdate({ ...dataUpdate, descricao_solicitacao: event })}
                            />
                        </FieldContainer>

                        <FieldContainer required title="SLA">
                            <Select
                                placeholder="SLA"
                                value={dataUpdate?.id_sla}
                                options={listSla}
                                onSelect={(option) => {
                                    const obj = { ...dataUpdate };

                                    obj.id_sla = option.id;
                                    obj.desc_sla = option.label;

                                    setDataUpdate(obj);
                                }}
                            />
                        </FieldContainer>

                        <FieldContainer required title="Editável">
                            {!dataUpdate?.id_sla ? (
                                <Select
                                    placeholder="Editável"
                                    disabled={true}
                                />
                            ) : (
                                <Select
                                    placeholder="Editável"
                                    value={dataUpdate?.id_editavel}
                                    options={listOptionEditable}
                                    onSelect={(edit) => {
                                        const obj = { ...dataUpdate }

                                        obj.id_editavel = edit.id;
                                        obj.desc_editavel = edit.label;

                                        setDataUpdate(obj);
                                    }}
                                />
                            )}
                        </FieldContainer>
                    </div>

                    {desc_motivo != null ? (
                        <div className="card-container-content__content-reasons">
                            <FieldContainer title="Motivos">
                                <SelectedMotives
                                    isMulti
                                    placeholder="Adicionar motivo"
                                    name="motives"
                                    id="list-motives"
                                    openMenuOnFocus={false}
                                    openMenuOnClick={false}
                                    styles={customStyles}
                                    noOptionsMessage={() => { return <p>Sem opções</p> }}
                                    backspaceRemovesValue={false}
                                    onChange={(newReason) => setSelectMotive(newReason)}
                                    defaultValue={
                                        motives?.map((item) => {
                                            if (item.key_solicitation === id_solicitacao) {
                                                if (item.id != null) {
                                                    return {
                                                        value: item.id,
                                                        label: item.label,
                                                    }
                                                } else return null
                                            } else return null
                                        })
                                    }
                                    options={
                                        motivesData?.map((motive) => {
                                            return {
                                                value: motive.id_motivo,
                                                label: motive.desc_motivo,
                                            };
                                        })
                                    }
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: "#455cc7",
                                        },
                                    })}
                                />
                            </FieldContainer>
                        </div>
                    ) : null}

                    {updateSolicitation === false ? null :
                        <footer className={`buttons-container ${updateSolicitation ? "margintop_20" : null}`}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                    cursor: `${!activeButton ? "default" : "pointer"}`,
                                }}
                            >
                                <Button
                                    className="buttons-container__btn-cancel"
                                    disabled={pageStatus === "update-solicitation"}
                                    onClick={() => {
                                        setUpdateSolicitation(false);
                                        onCloseCustom()
                                    }}
                                >
                                    <Typography.H4 weight={"bold"} color="red-2">
                                        Cancelar
                                    </Typography.H4>
                                </Button>

                                <Button
                                    className="buttons-container__btn-confirm"
                                    disabled={pageStatus === "update-solicitation"}
                                    onClick={() => handleEditSolicitation()}
                                >
                                    {
                                        pageStatus !== "update-solicitation" ? (
                                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                                        ) : (
                                            <Loading.Dots />
                                        )
                                    }
                                </Button>
                            </div>
                        </footer>
                    }
                </div>
            )
        } else {
            return (
                <div className={`card-container-content ${desc_motivo != null ? "height_270" : "height_93"}`}>
                    <div className="card-container-content__content">
                        <FieldContainer required title="Nome da solicitação">
                            <p className="text">{descricao_solicitacao}</p>
                        </FieldContainer>

                        <FieldContainer required title="SLA">
                            <p className="text">{id_sla != null ? desc_sla : "Nenhum SLA associado"}</p>
                        </FieldContainer>

                        <FieldContainer required title="Editável">
                            <p className="text">{dataUpdate?.editavel === true ? "Sim" : "Não"}</p>
                        </FieldContainer>
                    </div>

                    {desc_motivo != null ? (
                        <div className="card-container-content__content-reasons">
                            <FieldContainer title="Motivos">
                                <div className="container-reasons">
                                    <div className="container-reasons__tags">
                                        <ul className="list-tags">
                                            {renderFiltersMotive()}
                                        </ul>
                                    </div>
                                </div>
                            </FieldContainer>
                        </div>
                    ) : null}
                </div>
            )
        }
    };

    useEffect(() => {
        let updateMotiveId = [];

        solicitationData?.forEach(s => {
            updateMotiveId.push({
                key_solicitation: s.id_solicitacao,
                id: s.id_motivo,
                label: s.desc_motivo
            })
        })

        setMotiveIdList(updateMotiveId)
    }, [solicitationData]);

    useEffect(() => {
        var reduced = [];

        motiveIdList?.forEach(val => {
            var duplicated = reduced.findIndex(redItem => {
                return val.id === redItem.id && val.key_solicitation === redItem.key_solicitation
            }) > - 1;

            if (!duplicated) {
                reduced.push(val);
            }
        });

        setMotives(reduced);
    }, [motiveIdList])

    useEffect(() => {
        if (id_solicitacao) {
            getValues(id_solicitacao)
        }
    }, [id_solicitacao]);

    useEffect(() => {
        if (selectMotive) {
            selectMotive?.forEach((value, index) => {
                if (selectMotive.length - 1 === index) {
                    motivesIdArray.list.push(value.value);
                }
            })
        } else return null;
    }, [selectMotive]);

    return (
        <>
            <header className={openCard ? "card-header active" : "card-header"}>
                <IconArrowDown onClick={() => setOpenCard(!openCard)} />

                <h3 className="card-header__title">
                    {descricao_solicitacao} -
                    {
                        id_sla != null ?
                            <span style={{ marginLeft: "4px" }}>
                                {desc_sla}
                            </span> :
                            <span style={{ marginLeft: "4px" }}>
                                Nenhum SLA associado
                            </span>
                    }
                </h3>

                <button className="card-header__btn-options" type="button">
                    <PopoverMenu
                        placement="bottom-end"
                        menuData={renderPopoverMenuContentList(id_solicitacao)}
                    >
                        <IconMoreVertical />
                    </PopoverMenu>
                </button>
            </header>

            {
                openCard ? (
                    <>{changeCard()}</>
                ) : null
            }

            <Modal.DeleteTypeSolicitation
                isOpen={isOpenModalDelete}
                onClose={(event) => setIsOpenModalDelete(event)}
                onDelete={listSolicitation}
            />
        </>
    )
}


