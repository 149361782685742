//GLOBAL - components from npm
import React from 'react';
import {
	Route,
	Redirect
} from 'react-router-dom';

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...
import { useSelector } from 'react-redux';

//ASSETS - icons, images...


export default function PublicRoute({ children, ...rest }) {
	//GENERAL
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

	//STATES


	//REDUX - Selectors


	//FUNCTIONS


	//USE EFFECTS


	return (
		<Route
			{...rest}
			render={
				({ location }) => (
					isAuthenticated ? (
						<Redirect to={{
							pathname: '/',
							state: { from: location }
						}} />
					) : (
						children
					)
				)
			}
		/>
	)
}
