import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode";

export async function caseDetail(id, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    //Antigo endpoint
    // const response = await instance.get(`/details-ticket/${id}`, config);
    const response = await instance.get(`/details-ticket-v2/${id}`, config);

    response.data.nome_contato = unicodeToChar(response.data.nome_contato);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getPriorityList(token, iduser) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-criticality`, config);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getCases(
  token,
  codeTicket,
  datestart,
  dateend,
  filtersAdvanceds,
  variableField,
  fieldsAdditional,
  organizationsProfile,
  idGroup,
  userId
) {
  try {
    let config = {};

    if (filtersAdvanceds && filtersAdvanceds.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          codigo_ticket: codeTicket,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          idgrupo: idGroup,
          userId,
          datestart,
          dateend,
          latecase: filtersAdvanceds.ticketLated,
          primarycase: filtersAdvanceds.ticketPrimary,
          secondcase: filtersAdvanceds.ticketSecondary,
          exceededsla: filtersAdvanceds.ticketExceededsla,
          contact: filtersAdvanceds.clients.map((option) => option.id).join(),
          company: "",
          id_empresa: filtersAdvanceds.companys
            .map((option) => option.id)
            .join(),
          requested: filtersAdvanceds.requesteds
            .map((option) => option.id)
            .join(),
          reason: filtersAdvanceds.reasons.map((option) => option.id).join(),
          subreason: filtersAdvanceds.subReasons
            .map((option) => option.id)
            .join(),
          agent: filtersAdvanceds.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersAdvanceds.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersAdvanceds.channels.map((option) => option.id).join(),
          escalate: filtersAdvanceds.escalate.map((option) => option.id).join(),
          additional_fields: fieldsAdditional
            .map((opt) => `${opt.id};${opt.value}`)
            .join("|"),
          organizations: filtersAdvanceds.organizations
            .map((option) => option.id)
            .join(),
          id_grupos: filtersAdvanceds.userGroups
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          codigo_ticket: codeTicket,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          idgrupo: idGroup,
          id_grupos: filtersAdvanceds.userGroups
            .map((option) => option.id)
            .join(),
          userId,
          datestart,
          dateend,
        },
      };
    }

    let response = [];

    if (filtersAdvanceds.active) {
      if (fieldsAdditional.length > 0) {
        //Antigo endpoint
        // response = await instance.get(
        //   "/list-tickets-filter-advanceds-new-af",
        //   config
        // );
        response = await instance.get(
          "/list-tickets-filter-advanceds-af-v2",
          config
        );
      } else {
        //Antigo endpoint
        // response = await instance.get(
        //   "/list-tickets-filter-advanceds-new",
        //   config
        // );
        response = await instance.get(
          "/list-tickets-filter-advanceds-v2",
          config
        );
      }
    } else {
      //Antigo endpoint
      // response = await instance.get("/list-tickets-new", config);
      response = await instance.get("/list-tickets-v2", config);
    }

    for (const item of response.data) {
      item.nome_contato = unicodeToChar(item.nome_contato);
    }

    return response;
  } catch (error) {
    return error;
  }
}

export async function getCasesToTasks(token, codeTicket, datestart, dateend) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      params: {
        codigo_ticket: codeTicket,
        datestart,
        dateend,
      },
    };

    const response = await instance.get("/list-tickets-associate", config);

    for (const item of response.data) {
      item.nome_contato = unicodeToChar(item.nome_contato);
    }

    return response;
  } catch (error) {
    return error;
  }
}

export async function createCase(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    //ANTIGO ENDPOINT
    // const response = await instance.post("/create-ticket", data, config);
    const response = await instance.post("/create-ticket-v2", data, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteCase(id, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.delete(`/delete-ticket/${id}`, config);

    return response;
  } catch (error) {
    return error;
  }
}

export async function updateCase(id, data, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    //ANTIGO ENDPOINT
    // const response = await instance.put(`/update-ticket/${id}`, data, config);
    const response = await instance.put(
      `/update-ticket-v2/${id}`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateCasePosition(body, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(`/update-ticket-status`, body, config);

    return response;
  } catch (error) {
    return error;
  }
}

export async function associateUser(data, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      `/create-associate-ticket-to-user`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function disassociateUser(idCase, idUser, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.delete(
      `/remove-associate-ticket-to-user/${idCase}/${idUser}`,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getRequestList(token, iduser, idorganizacao) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    let response;
    if (idorganizacao) {
      response = await instance.get(
        `/list-types-request-v2?iduser=${iduser}&idorganizacao=${idorganizacao}`,
        config
      );
    } else {
      response = await instance.get(
        `/list-types-request?iduser=${iduser}`,
        config
      );
    }

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getSlaRule(
  token,
  id_motivo,
  id_solicitacao,
  id_organizacao
) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    let response;
    if (id_organizacao) {
      response = await instance.get(
        `/get-sla-v3?id_solicitacao=${id_solicitacao}&id_submotivo=${null}&id_motivo=${id_motivo}&id_organizacao=${id_organizacao}`,
        config
      );
    } else {
      response = await instance.get(
        `/get-sla?id_solicitacao=${id_solicitacao}&id_motivo=${id_motivo}`,
        config
      );
    }
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getSlaRule2(
  token,
  id_submotivo,
  id_solicitacao,
  id_motivo,
  id_organizacao
) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    let response;

    if (id_organizacao) {
      response = await instance.get(
        `/get-sla-v3?id_solicitacao=${id_solicitacao}&id_submotivo=${id_submotivo}&id_motivo=${id_motivo}&id_organizacao=${id_organizacao}`,
        config
      );
    } else {
      response = await instance.get(
        `/get-sla-v2?id_solicitacao=${id_solicitacao}&id_submotivo=${id_submotivo}&id_motivo=${id_motivo}`,
        config
      );
    }

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getListStatus(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get("/list-ticket-status", config);

    return response;
  } catch (error) {
    return error;
  }
}

export async function getListHistoric(token, idCase) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(
      `/list-historic-operations/${idCase}`,
      config
    );

    for (const item of response.data) {
      item.valor_atual =
        item.valor_atual !== null ? unicodeToChar(item.valor_atual) : null;
      item.valor_novo = unicodeToChar(item.valor_novo);
    }

    return response;
  } catch (error) {
    return error;
  }
}

export async function getHistoryListV2(token, caseId) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.get(`/list-historic-operations-v2/${caseId}`, config);

    response.data.forEach(item => {
      item.valor_atual = item.valor_atual !== null ? unicodeToChar(item.valor_atual) : null;
      item.valor_novo = unicodeToChar(item.valor_novo);
    });

    return response;
  } catch (error) {
    console.log("getHistoricListV2 ERROR:", error);

    return error;
  }
}

export async function getReasonsList(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-reasons`, config);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getSubReasonsList(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-subreason`, config);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getReasonsListToType(
  token,
  idTypeRequest,
  idOrganizacao
) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    let response;

    if (idOrganizacao) {
      response = await instance.get(
        `/list-reasons-to-type-v2?id_tipo_solicitacao=${idTypeRequest}&id_organizacao=${idOrganizacao}`,
        config
      );
    } else {
      response = await instance.get(
        `/list-reasons-to-type/${idTypeRequest}`,
        config
      );
    }

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getSubReasonsListToType(token, idReason, idOrganizacao) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    let response;
    if (idOrganizacao) {
      response = await instance.get(
        `/list-subreasons-to-reason-v2?id_motivo=${idReason}&id_organizacao=${idOrganizacao}`,
        config
      );
    } else {
      response = await instance.get(
        `/list-subreasons-to-reason/${idReason}`,
        config
      );
    }

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getChannelList(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-channels`, config);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getUserGroups(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post('/buscar-grupo-usuario', {}, config);

    return response;
  } catch (error) {
    console.log('Error ao fazer requisição HTTP:', error);

    return error;
  }
}

export async function getHistoryFieldsList(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-fields`, config);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getEscalateList(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-escalations`, config);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getListIntegrations(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-integrations`, config);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getCategoriesTemplate(token, idCase) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-categories-templates`, config);

    return response;
  } catch (error) {
    return error;
  }
}

export async function getSubCategoriesTemplate(token, idCategory) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(
      `/list-subcategories-templates/${idCategory}`,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function getListCommunicators(token, idTicket) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(
      `/list-communicators/${idTicket}`,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function getPathsSubCategoriesTemplate(token, idSubCategory) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(
      `/list-paths-subcategories-templates/${idSubCategory}`,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function getWorkTimeAndHolidaySla(token, idSla) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(
      `/list-worktimes-and-holidays-sla/${idSla}`,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function createStaggerSectorCase(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      "/create-registry-stagger-case",
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function createCommunicator(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post("/create-communicator", data, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getPermissions(idProfile, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.get(
      `/user-profile?id_perfil=${idProfile}`,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getAdditionalFields(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.get(`/list-additional-fields`, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function createTicketAdditionalFields(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      `/create-ticket-additional-fields`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateTicketAdditionalFields(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      `/update-ticket-additional-fields`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function getStatusRequestType(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      `/get-tipo-solicitacao-status`,
      data,
      config
    );

    return response;
  } catch (error) {
    console.log("Error in getStatusRequestType", error);
  }
}

export async function insertLogTicket(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(`/insert-log-ticket`, data, config);

    return response;
  } catch (error) {
    console.log("Error in insertLogTicket", error);
  }
}

export async function insertAlerts(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(`/insert-alertas`, data, config);

    return response;
  } catch (error) {
    console.log("Error in insertLogTicket", error);
  }
}

export async function searchAlerts(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(`/buscar-alertas`, data, config);

    return response;
  } catch (error) {
    console.log("Error in insertLogTicket", error);
  }
}

export async function updateAlertsRead(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.put(
      `/update-leitura-alertas`,
      data,
      config
    );

    return response;
  } catch (error) {
    console.log("Error in insertLogTicket", error);
  }
}
