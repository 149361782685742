//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

// UTILS

//COMPONENTS
import { Modal } from "./../../../feedback";
import { Button, Typography } from "./../../../general";
import { FieldContainer } from "../../../data-display";
import { Input, Select, SelectSearchItems } from "../../../data-entry";
import { Toast } from "./../../../../utils/toast";
import { onKeyPress } from "./../../../../utils/onKeyPress";
import { maskCep, maskCpfCnpj, maskRg } from "../../../../utils/masks";
import { validateRg } from "../../../../utils/validateRg"
import { fetchWithTimeout } from "../../../../utils/fetchWithTimeout";
import { parsePhoneNumber } from "libphonenumber-js/max";
import Loading from "../../Loading";

//SERVICES - api, conectors...
import * as APIClient from "./../../../../services/api/client";
import * as APITypesPhones from "./../../../../services/api/typesPhones";
import * as APICompany from "./../../../../services/api/company";

//ASSETS - icons, images...
import { ReactComponent as IconUser } from "./../../../../assets/images/user.svg";
import { ReactComponent as IconAddCircle } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as IconMinusCircle } from "./../../../../assets/icons/minus-cirlce.svg";

//STYLES
import "./create-contact.scss";

export default function CreateContact({
    isOpen,
    onClose,
    onCreate,
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const filtersEnterprises = useSelector((state) => state.filters.enterprises);

    //GENERAL
    var cpfValid = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [inputErrorEmail, setInputErrorEmail] = useState(false);
    const [inputErrorCpf, setInputErrorCpf] = useState(false);
    const [inputErrorRg, setInputErrorRg] = useState(false);
    const [inputErrorCep, setInputErrorCep] = useState(false);
    const [onBlurInputErrorEmail, setonBlurInputErrorEmail] = useState(false);
    const [onBlurInputErrorCpf, setonBlurInputErrorCpf] = useState(false);
    const [onBlurInputErrorRg, setonBlurInputErrorRg] = useState(false);
    const [selectErrorPhoneType, setSelectErrorPhoneType] = useState(false);
    const [inputErrorTel, setInputErrorTel] = useState(false);
    const [inputTelAlert, setInputTelAlert] = useState(false);
    const [loadingEnterprises, setLoadingEnterprises] = useState(false);
    const [typeListPhone, setTypeListPhone] = useState();
    const [enterprises, setEnterprises] = useState([]);
    const [enterprisesLimit, setEnterprisesLimit] = useState(50);
    const [emptyContactData] = useState(() => {
        return {
            numbers: [
                {
                    telephone: "",
                    type_telephone: null
                },
            ],
            name: "",
            displayname: "",
            cpf: "",
            rg: "",
            cep: "",
            address: "",
            number: "",
            email: "",
            district: "",
            externcode: "",
            username: "",
            id_empresa: null,
        }
    });
    const [contactData, setContactData] = useState({ ...emptyContactData });

    //FUNCTIONS
    async function validatePhone(number) {
        if (!number) {
            setPageStatus("awaiting");
            return false;
        };

        const numberParse = parsePhoneNumber(number, "BR");

        if (numberParse.isValid()) {
            setInputErrorTel(false);

            const response = await APIClient.getIdClient(token, number);

            if (response.status === 200) {
                if (response.data.length > 0) {
                    setInputTelAlert(true);

                    Toast(
                        "Atenção!",
                        "O número que você está tentando adicionar, já está cadastrado. Inclua outro número para conseguir vincular esse contato.",
                        "warn"
                    );

                    return false;
                } else {
                    setInputTelAlert(false);
                }
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde",
                    "error"
                );

                return false;
            }
        } else {
            setInputErrorTel(true);

            Toast(
                "Erro ao criar contato!",
                "Verifique se o número do contato foi digitado corretamente.",
                "error"
            );

            return false;
        }
    };

    async function getListTypesPhones() {
        setPageStatus("requesting");

        const response = await APITypesPhones.getTypesPhones(token);

        if (response.status === 200) {
            let dataUpdated = [];

            response.data.forEach((phone) => {
                let requestTypesPhones = {};

                requestTypesPhones.id = phone.id_tipo_telefone;
                requestTypesPhones.label = phone.desc_tipo_telefone;

                dataUpdated.push(requestTypesPhones);
            });

            setTypeListPhone(dataUpdated);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    async function getListEnterprises(page, title, limit) {
        setLoadingEnterprises(true);

        const response = await APICompany.newListAllCompanysUsers(
            token,
            page,
            title,
            limit,
            filtersEnterprises
        );
        const data = response.data;
        const updatedData = [];

        data.forEach((company) => {
            let updatedCompany = {};

            updatedCompany.id = company.id_empresa
            updatedCompany.label = company.nome

            updatedData.push(updatedCompany);
        });

        setLoadingEnterprises(false)
        setEnterprises(updatedData)
    };

    function loadMoreEnterprises() {
        setEnterprisesLimit(enterprisesLimit + 50)
    };

    function cleanFieldsCep() {
        setContactData({ ...contactData, address: "", district: "", uf: "" })
    };

    async function searchCep(e) {
        const cep = e.target.value.replace(/\D/g, "");

        if (cep != "") {
            var validacep = /^[0-9]{8}$/;

            if (validacep.test(cep)) {

                document.getElementById("cep").value = cep.substring(0, 5)
                    + "-"
                    + cep.substring(5);

                //* Preenche os campos com "..." enquanto consulta webservice.
                // setContactData({ ...contactData, address: "...", district: "...", uf: "..." })

                try {
                    const response = await fetchWithTimeout(`https://viacep.com.br/ws/${cep}/json/`, {
                        timeout: 6000
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json()
                        }
                    }).then(data => {
                        if (data.erro) {
                            setInputErrorCep(true);

                            Toast(
                                "Erro ao adicionar CEP", "Cep não encontrado", "error"
                            )
                        } else {
                            setInputErrorCep(false);

                            setContactData({
                                ...contactData,
                                address: data.logradouro,
                                district: data.localidade,
                                uf: data.uf
                            })
                        }
                    })

                    return response;
                } catch (error) {
                    if (error) {
                        Toast(
                            "Erro ao adicionar CEP", "Não foi possível carregar as informações", "error"
                        )
                    }
                }
            } else {
                //cep é inválido.
                cleanFieldsCep();
                Toast(
                    "Erro ao adicionar CEP", "Formato de CEP inválido", "error"
                )
            }
        } else {
            //cep sem valor, limpa formulário.
            cleanFieldsCep();
        }
    };

    const handleAddInputsContact = () => {
        setContactData({
            ...contactData, numbers: [
                ...contactData.numbers, { telephone: "", type_telephone: null }
            ]
        })
    };

    const handleRemoveInputsContact = (position) => {
        setContactData({
            ...contactData, numbers: [
                ...contactData.numbers.filter((_, index) => index !== position)
            ]
        })
    };

    function onCloseCustom() {
        onClose();
        setTimeout(() => {
            setContactData({ ...emptyContactData, numbers: [{ telephone: "", type_telephone: null }] });
            setSelectErrorPhoneType(false)
            setValidationWarn(false);
            setInputErrorEmail(false);
            setInputErrorCpf(false);
            setInputErrorRg(false);
            setInputErrorCep(false);
            setInputErrorTel(false);
            setInputTelAlert(false);
            setonBlurInputErrorEmail(false);
            setPageStatus("awaiting");
        }, 250)
    };

    function isFormValidated() {
        var telephone = contactData.numbers.find(item => item.telephone);

        if (!contactData.name) return false;
        else if (contactData.email && !/\S+@\S+\.\S+/.test(contactData.email)) return false;
        else if (!telephone && !contactData.email) return false;
        else if (telephone && !contactData.email) return true;
        else if (contactData.email && !telephone) return true;
        else if (
            (contactData.cpf && cpfValid.test(contactData.cpf)) === false ||
            contactData.cpf === "000.000.000-00" ||
            contactData.cpf === "111.111.111-11" ||
            contactData.cpf === "222.222.222-22" ||
            contactData.cpf === "333.333.333-33" ||
            contactData.cpf === "444.444.444-44" ||
            contactData.cpf === "555.555.555-55" ||
            contactData.cpf === "666.666.666-66" ||
            contactData.cpf === "888.888.888-88" ||
            contactData.cpf === "999.999.999-99"
        ) return false;
        else if (
            (contactData.rg && validateRg(contactData.rg) === false) ||
            contactData.rg === "000.000.000-0" ||
            contactData.rg === "111.111.111-1" ||
            contactData.rg === "222.222.222-2" ||
            contactData.rg === "333.333.333-3" ||
            contactData.rg === "444.444.444-4" ||
            contactData.rg === "555.555.555-5" ||
            contactData.rg === "666.666.666-6" ||
            contactData.rg === "777.777.777-7" ||
            contactData.rg === "888.888.888-8" ||
            contactData.rg === "999.999.999-9"
        ) return false;

        return true;
    };

    async function handleCreateContact() {
        if (isFormValidated()) {
            setPageStatus("creating-contact");

            var telephone = contactData.numbers.find(item => item.telephone);

            if (contactData.cpf) contactData.cpf = contactData.cpf.replace(/[-/\.//"]/g, '');

            if (contactData.rg) contactData.rg = contactData.rg.replace(/[-/\.//"]/g, '');

            if (contactData.cep) contactData.cep = contactData.cep.replace(/[-/\.//"]/g, '');

            if (document.getElementById("telephone")?.value === "") contactData.numbers = [];

            if (document.getElementById("nome_apresentacao")?.value)
                contactData.displayname = document.getElementById("nome_apresentacao")?.value;

            const response = await APIClient.createContactUnify(token, contactData);

            if (response.status === 200) {
                Toast("Contato adicionado!", "Contato adicionado com sucesso.", "success");
                onCreate();
                onCloseCustom();
            } else {
                Toast(
                    "Não foi possível adicionar o contato",
                    "Tente novamente mais tarde!",
                    "error"
                )
            }

            setPageStatus("awaiting");
        } else if (!contactData.name || !contactData.displayname) {
            setValidationWarn(true);

            Toast(
                "Erro ao adicionar contato!",
                "Verifique o preenchimento dos campos em destaque",
                "error",
            )
        } else if (contactData.email && !/\S+@\S+\.\S+/.test(contactData.email)) {
            setInputErrorEmail(true);
            setonBlurInputErrorEmail(true);
        } else if (
            (contactData.cpf && cpfValid.test(contactData.cpf)) === false ||
            contactData.cpf === "000.000.000-00" ||
            contactData.cpf === "111.111.111-11" ||
            contactData.cpf === "222.222.222-22" ||
            contactData.cpf === "333.333.333-33" ||
            contactData.cpf === "444.444.444-44" ||
            contactData.cpf === "555.555.555-55" ||
            contactData.cpf === "666.666.666-66" ||
            contactData.cpf === "888.888.888-88" ||
            contactData.cpf === "999.999.999-99"
        ) {
            setInputErrorCpf(true);
            setonBlurInputErrorCpf(true);
        } else if (
            (contactData.rg && validateRg(contactData.rg)) ||
            contactData.rg === "000.000.000-0" ||
            contactData.rg === "111.111.111-1" ||
            contactData.rg === "222.222.222-2" ||
            contactData.rg === "333.333.333-3" ||
            contactData.rg === "444.444.444-4" ||
            contactData.rg === "555.555.555-5" ||
            contactData.rg === "666.666.666-6" ||
            contactData.rg === "777.777.777-7" ||
            contactData.rg === "888.888.888-8" ||
            contactData.rg === "999.999.999-9"
        ) {
            setInputErrorRg(true);
            setonBlurInputErrorRg(true);
        } else if (!telephone && !contactData.email) {
            setInputErrorEmail(true);
            setInputErrorTel(true);
            setSelectErrorPhoneType(true);

            Toast(
                "Erro ao adicionar contato!",
                "Preencha o email ou pelo menos um número de contato/tipo de telefone",
                "error",
            )

            return false;
        }
    };

    function onBlurEventInputError() {
        var telephone = contactData.numbers.some(item => item.telephone.length >= 11);

        if (contactData.email && !/\S+@\S+\.\S+/.test(contactData.email)) {
            setInputErrorEmail(true);
            setonBlurInputErrorEmail(true);
        } else {
            setInputErrorEmail(false);
            setonBlurInputErrorEmail(false);
        };

        if (telephone) {
            setInputErrorEmail(false);
            setonBlurInputErrorEmail(false);
        }

        if (
            (contactData.cpf && cpfValid.test(contactData.cpf)) === false ||
            contactData.cpf === "000.000.000-00" ||
            contactData.cpf === "111.111.111-11" ||
            contactData.cpf === "222.222.222-22" ||
            contactData.cpf === "333.333.333-33" ||
            contactData.cpf === "444.444.444-44" ||
            contactData.cpf === "555.555.555-55" ||
            contactData.cpf === "666.666.666-66" ||
            contactData.cpf === "888.888.888-88" ||
            contactData.cpf === "999.999.999-99"
        ) {
            setInputErrorCpf(true);
            setonBlurInputErrorCpf(true);
        } else {
            setInputErrorCpf(false);
            setonBlurInputErrorCpf(false);
        };

        if (
           ( contactData.rg && validateRg(contactData.rg)) ||
            contactData.rg === "000.000.000-0" ||
            contactData.rg === "111.111.111-1" ||
            contactData.rg === "222.222.222-2" ||
            contactData.rg === "333.333.333-3" ||
            contactData.rg === "444.444.444-4" ||
            contactData.rg === "555.555.555-5" ||
            contactData.rg === "666.666.666-6" ||
            contactData.rg === "777.777.777-7" ||
            contactData.rg === "888.888.888-8" ||
            contactData.rg === "999.999.999-9"
        ) {
            setInputErrorRg(true);
            setonBlurInputErrorRg(true);
        } else {
            setInputErrorRg(false);
            setonBlurInputErrorRg(false);
        };
    };

    //USE EFFECTS
    useEffect(() => {
        getListTypesPhones();
        getListEnterprises(null, null, enterprisesLimit);
    }, [enterprisesLimit]);

    useEffect(() => {
        if (emptyContactData) {
            setContactData({ ...emptyContactData })
        }
    }, [emptyContactData]);

    useEffect(() => {
        if (document.getElementById("nome_apresentacao")?.value) {
            contactData.displayname = document.getElementById("nome_apresentacao")?.value
        }
    }, [contactData]);

    return (
        <Modal.Main
            className="modal-create-contact"
            title="Adicionar contato"
            width="1001px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <>
                    <Button
                        className="modal-create-contact__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "creating-contact"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-create-contact__btn-confirm"
                        disabled={pageStatus === "creating-contact"}
                        onClick={() => {
                            handleCreateContact()
                        }}
                    >
                        {pageStatus !== "creating-contact" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </>
            }
        >
            <section className="modal-create-contact__content">
                <div className="container-full-line">
                    <div className="img">
                        <IconUser className="icon-user" />
                    </div>

                    {/* <FieldContainer title="Falar com o cliente:">
                        <div className="channels-content">
                            {renderChannel()}
                        </div>
                    </FieldContainer> */}

                </div>

                <div className="container-full-line">
                    <FieldContainer required title="Nome completo">
                        <Input
                            type="text"
                            id="nome_completo"
                            placeholder="Digite seu nome"
                            onChange={(event) => setContactData({ ...contactData, name: event })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.name}
                            error={validationWarn ? !contactData.name : false}
                        />
                    </FieldContainer>
                    <FieldContainer required title="Nome de apresentação">
                        <Input
                            type="text"
                            id="nome_apresentacao"
                            placeholder="Digite seu nome apresentação"
                            onChange={(event) => setContactData({ ...contactData, name: event })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.name}
                            error={validationWarn ? !contactData.name : false}
                        />
                    </FieldContainer>
                    <FieldContainer title="Código externo">
                        <Input
                            type="text"
                            placeholder="Digite seu código externo"
                            onChange={(event) => setContactData({ ...contactData, externcode: event })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.externcode}
                        />
                    </FieldContainer>
                </div>

                <div className="container-full-line">
                    <FieldContainer title="E-mail">
                        <Input
                            type="email"
                            placeholder="email@hotmail.com"
                            onBlur={onBlurEventInputError}
                            onChange={(event) => setContactData({ ...contactData, email: event })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.email}
                            typeInputMessage={onBlurInputErrorEmail ? "error" : false}
                            error={inputErrorEmail ? (contactData.email && !/\S+@\S+\.\S+/.test(contactData.email)) || !contactData.email : false}
                            customInputMessage={"E-mail inválido"}
                        />
                    </FieldContainer>
                    <FieldContainer title="CPF">
                        <Input
                            type="text"
                            placeholder="XXX.XXX.XXX-XX"
                            onBlur={onBlurEventInputError}
                            onChange={(event) => setContactData({ ...contactData, cpf: maskCpfCnpj(event) })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.cpf}
                            typeInputMessage={inputErrorCpf ? "error" : false}
                            error={
                                onBlurInputErrorCpf ? (contactData.cpf && cpfValid.test(contactData.cpf)) === false ||
                                    contactData.cpf === "000.000.000-00" ||
                                    contactData.cpf === "111.111.111-11" ||
                                    contactData.cpf === "222.222.222-22" ||
                                    contactData.cpf === "333.333.333-33" ||
                                    contactData.cpf === "444.444.444-44" ||
                                    contactData.cpf === "555.555.555-55" ||
                                    contactData.cpf === "666.666.666-66" ||
                                    contactData.cpf === "888.888.888-88" ||
                                    contactData.cpf === "999.999.999-99" : false
                            }
                            customInputMessage={"Cpf inválido"}
                            maxLength="14"
                        />
                    </FieldContainer>
                    <FieldContainer title="RG">
                        <Input
                            type="text"
                            placeholder="XX.XXX.XXX-XX"
                            onBlur={onBlurEventInputError}
                            onChange={(event) => setContactData({ ...contactData, rg: maskRg(event) })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.rg}
                            typeInputMessage={inputErrorRg ? "error" : false}
                            error={
                                onBlurInputErrorRg ? (contactData.rg && validateRg(contactData.rg)) ||
                                    contactData.rg === "000.000.000-0" ||
                                    contactData.rg === "111.111.111-1" ||
                                    contactData.rg === "222.222.222-2" ||
                                    contactData.rg === "333.333.333-3" ||
                                    contactData.rg === "444.444.444-4" ||
                                    contactData.rg === "555.555.555-5" ||
                                    contactData.rg === "666.666.666-6" ||
                                    contactData.rg === "777.777.777-7" ||
                                    contactData.rg === "888.888.888-8" ||
                                    contactData.rg === "999.999.999-9" : false
                            }
                            customInputMessage={"Rg inválido"}
                            maxLength="13"
                        />
                    </FieldContainer>
                </div>

                <div className="divisor" style={{ marginTop: "10px" }}></div>

                <FieldContainer title="Contato">
                    {contactData.numbers.map((phone, index) => {
                        let { telephone, type_telephone } = phone;

                        return (
                            <div key={index} className="container-full-line-contact" style={{ marginTop: "10px" }}>
                                <Input
                                    width="300px"
                                    type="numberMask"
                                    placeholder="XX 9 1234-5678"
                                    mask={"(99) 9 9999-9999"}
                                    id="telephone"
                                    name="telephone"
                                    value={telephone}
                                    onChange={(e) => {
                                        const event = e;

                                        const updatedNumbersData = cloneDeep(contactData.numbers);

                                        updatedNumbersData[index].telephone = event;

                                        setContactData({ ...contactData, numbers: updatedNumbersData });
                                    }}
                                    onBlur={() => validatePhone(telephone)}
                                    error={inputErrorTel ? true : false}
                                />

                                <Select
                                    disabled={true}
                                    width="120px"
                                    placeholder="+ 55"
                                />

                                <Select
                                    width="300px"
                                    placeholder="Selecione o tipo de telefone"
                                    value={type_telephone}
                                    options={typeListPhone}
                                    onSelect={(type_phone) => {
                                        const selectPhone = cloneDeep(contactData);

                                        selectPhone.numbers[index].type_telephone = type_phone.id;

                                        setContactData(selectPhone)
                                    }}
                                    error={selectErrorPhoneType ? true : false}
                                />

                                <div className="container-add-minus">
                                    {contactData.numbers.length > 1 && (
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleRemoveInputsContact(index)
                                            }}
                                        >
                                            <IconMinusCircle className="remove-phone" />
                                        </div>
                                    )}

                                    {contactData.numbers.length - 1 === index && (
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleAddInputsContact()
                                            }}
                                        >
                                            <IconAddCircle className="add-phone" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </FieldContainer>

                <div className="divisor"></div>

                <div className="container-full-line" style={{ marginTop: "10px" }}>
                    <FieldContainer title="CEP">
                        <Input
                            type="text"
                            id="cep"
                            placeholder="XX.XXX-XXX"
                            onBlur={searchCep}
                            onChange={(event) => setContactData({ ...contactData, cep: maskCep(event) })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.cep}
                            error={inputErrorCep ? true : false}
                            maxLength="8"
                        />
                    </FieldContainer>
                    <FieldContainer title="Endereço">
                        <Input
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Digite seu endereço"
                            onChange={(event) => setContactData({ ...contactData, address: event })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.address}
                        />
                    </FieldContainer>
                    <FieldContainer title="Número">
                        <Input
                            type="number"
                            className="number"
                            placeholder="Digite seu número"
                            onChange={(event) => setContactData({ ...contactData, number: event })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.number}
                        />
                    </FieldContainer>
                </div>

                <div className="container-full-line">
                    <FieldContainer title="Estado">
                        <Input
                            type="text"
                            id="uf"
                            placeholder="Digite seu estado"
                            onChange={(event) => setContactData({ ...contactData, uf: event })}
                            onKeyPress={(e) => onKeyPress(e)}
                            value={contactData.uf}
                        />
                    </FieldContainer>
                    <FieldContainer title="Cidade">
                        <Input
                            type="text"
                            id="district"
                            placeholder="Digite sua cidade"
                            onChange={(event) => setContactData({ ...contactData, district: event })}
                            value={contactData.district}
                        />
                    </FieldContainer>
                    <FieldContainer title="Empresa">
                        <SelectSearchItems
                            upward
                            loadOnDemand
                            placeholder="Selecione a empresa"
                            options={enterprises}
                            value={contactData.id_empresa}
                            loadingOptions={loadingEnterprises}
                            optionsLimit={enterprisesLimit}
                            loadMoreOptions={loadMoreEnterprises}
                            onSelect={(company) => {
                                setContactData({ ...contactData, id_empresa: company.id })
                            }}
                        />
                    </FieldContainer>
                </div>

            </section>
        </Modal.Main>
    )
}
