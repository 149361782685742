const formatTime = time => {
  let seconds = 0;
  let minutes = 0;
  if(time < 60) {
    seconds = time;
  } else if ( time >= 60) {
    seconds = (time % 60) ;
    minutes = Math.floor(time / 60);
  }

  const minutesFormated = `${minutes}`.padStart(2,'0');
  const secondsFormated = `${seconds}`.padStart(2, "0");

  const timeFormated = `${minutesFormated}:${secondsFormated}`

  return timeFormated;
}

export default formatTime;