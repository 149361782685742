//GLOBAL - components from npm
import React, { useState, forwardRef } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { format, getYear, getMonth, eachYearOfInterval, subYears, addYears, isPast, addHours, getHours } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR';

//STYLES
import './date-range.scss'

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconCalendar } from '../../../../../src/assets/icons/Calendar.svg';

export default function DateRange({
  selected,
  setDate,
  selectRangeType,
  startDate,
  endDate,
  minDate,
  maxDate,
  dateFormat,
  placeholderText,
  width,
  widthSize,
  border,
  error,
  errorType = 'text', // types: 'text' and 'border'
  className,
  isClearable,
  disable,
  bottomless
}) {

  //GENERAL
  registerLocale('pt-BR', ptBR);
  setDefaultLocale('pt-BR');

  const years = eachYearOfInterval({
    start: subYears(new Date(), 5),
    end: addYears(new Date(), 5)
  })

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  //STATES
  const [isOpen, setIsOpen] = useState(false);

  //REDUX - Selectors


  //FUNCTIONS
  const CustomInput = forwardRef(({ value, onClick, placeholderText }, ref) => {
    return (
      <button
        type='button'
        className={`input-date-range ${isOpen ? 'input-date-range--open' : ''} ${!value ? 'input-date-range-placeholder' : ''} ${error && errorType === 'text' ? 'input-date-range--error-text' : ''} ${error && errorType === 'border' ? 'input-date-range--error-border' : ''}`}
        onClick={onClick}
        ref={ref}
        style={{ '--width': width ? width : '' || widthSize ? widthSize : '', backgroundColor: disable === false ? "#DBDDE2":"", border: border ? border : ''}}
      >
        {value ? value : placeholderText}
        <IconCalendar />
      </button>
    )
  });

  const checkDate = (date) => {
    const pastDate = isPast(date);

    if (minDate && pastDate) {
      setDate(addHours(minDate, 1))
    } else {
      setDate(date)
    }
  }

  //USE EFFECTS


  return (
    <div className={`${className ? className : ''} date-range-custom`}>
      <DatePicker
        onCalendarClose={() => setIsOpen(false)}
        onCalendarOpen={() => setIsOpen(true)}
        selected={selected}
        onChange={(date) => checkDate(date)}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        selectsStart={selectRangeType === 'start'}
        selectsEnd={selectRangeType === 'end'}
        showPopperArrow={false}
        dateFormat={dateFormat}
        locale="pt-BR"
        customInput={<CustomInput placeholderText={placeholderText} />}
        isClearable={isClearable}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth
        }) => (
          <div className={`date-range-inputs ${bottomless ? "bottomless" : ""}`}>
            <select value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((month, key) => (
                <option key={key} value={month}>
                  {month}
                </option>
              ))}
            </select>

            <select value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((year, key) => (
                <option key={key} value={format(year, 'uuuu')}>
                  {format(year, 'uuuu')}
                </option>
              ))}
            </select>
          </div>
        )}
      />
    </div>
  )
}
