import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { format, isValid, parse } from "date-fns";
import MaskedInput from "react-input-mask";

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { Loading, Modal } from "../../../../components/feedback";
import { FieldContainer } from "../../../../components/data-display";
import { Input, Select } from "../../../../components/data-entry";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APISla from "../../../../services/api/sla";
import * as APICase from "../../../../services/api/case";
import * as APIOrganization from "../../../../services/api/organization";

//GLOBAL STATE - redux, env...

// UTILS
import { convertHours } from "../../../../utils/convertHours";

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as AddCircleIcon } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as MinusCircleIcon } from "./../../../../assets/icons/minus-cirlce.svg";
import { ReactComponent as IconError } from "./../../../../assets/icons/error.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES
import "./update-sla.scss";

export default function UpdateSla({
    onUpdate,
    toGoBack,
    slaId,
    detailedSla,
    setUpdatedSla,
    handleTableDataLookup
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [loading, setLoading] = useState(false);
    const [validationWarn, setValidationWarn] = useState(false);
    const [isBasicDataModified, setIsBasicDataModified] = useState(false);
    const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
    const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);
    const [isStartHourBigger, setIsStartHourBigger] = useState(false);
    const [isOpenModalDeleteSla, setIsOpenModalDeleteSla] = useState(false);
    const [isHourOverlap, setIsHourOverlap] = useState(false);
    const [basicData, setBasicData] = useState(null);
    const [associatedSchedules, setAssociatedSchedules] = useState([]);
    const [originalAssociatedSchedules, setOriginalAssociatedSchedules] = useState([]);
    const [criticalityList, setCriticalityList] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [typesSla, setTypesSla] = useState([]);
    const [daysWeek, setDaysWeek] = useState([]);
    const [excludedHours, setExcludedHours] = useState([]);
    const [errors, setErrors] = useState([{
        start_time: "",
        end_time: "",
    }]);

    //FUNCTIONS
    // const allDaysWeekAssociations = () => {
    //     const allAssociated = daysWeek.filter(day => {
    //         return !associatedSchedules.some(item => item.day_week === day.id);
    //     }).length === 0;

    //     return allAssociated;
    // };

    const setDataModifiedBasicData = (newData) => {
        setBasicData(newData);
        setIsBasicDataModified(true);
    };

    const updateOriginalAssociatedSchedules = (data) => {
        setOriginalAssociatedSchedules(data)
    };

    const handleAddHour = () => {
        setAssociatedSchedules(prevState => [
            ...prevState,
            {
                day_week: null,
                start_time: "",
                end_time: "",
                sla_id: null
            }
        ])
    };

    const handleRemoveHour = (idx) => {
        const hours = associatedSchedules[idx];

        setExcludedHours((prevState) => [
            ...prevState,
            hours
        ]);

        setAssociatedSchedules(prevState => prevState.filter((_, index) => index !== idx));
    };

    const handleStartTimeChange = (index, e) => {
        const value = e;

        const updatedAssociatedSchedules = [...associatedSchedules];

        updatedAssociatedSchedules[index].start_time = value;

        setAssociatedSchedules(updatedAssociatedSchedules);
    };

    const handleEndTimeChange = (index, e) => {
        const value = e;

        const updatedAssociatedSchedules = [...associatedSchedules];

        updatedAssociatedSchedules[index].end_time = value;

        setAssociatedSchedules(updatedAssociatedSchedules);
    };

    const handleBlur = (index) => {
        const originalStartTime = associatedSchedules[index].start_time;
        const originalEndTime = associatedSchedules[index].end_time;

        const startTime = originalStartTime ? originalStartTime.split(':').slice(0, 2).join(':') : null;
        const endTime = originalEndTime ? originalEndTime.split(':').slice(0, 2).join(':') : null;

        const parsedStartTime = startTime ? parse(startTime, "HH:mm", new Date()) : null;
        const parsedEndTime = endTime ? parse(endTime, "HH:mm", new Date()) : null;

        let overlappingError;

        if (associatedSchedules[index].day_week !== null && associatedSchedules[index].day_week !== undefined) {
            overlappingError = associatedSchedules
                .filter((item, i) => i !== index && item.day_week === associatedSchedules[index].day_week)
                .some((item) => {
                    if (!item.start_time || !item.end_time || !associatedSchedules[index].start_time || !associatedSchedules[index].end_time) {
                        return false;
                    }

                    const updateStartTime = item.start_time ? item.start_time.split(':').slice(0, 2).join(':') : null;
                    const updateEndTime = item.end_time ? item.end_time.split(':').slice(0, 2).join(':') : null;

                    const startTimeA = parse(startTime, "HH:mm", new Date());
                    const endTimeA = parse(endTime, "HH:mm", new Date());
                    const startTimeB = parse(updateStartTime, "HH:mm", new Date());
                    const endTimeB = parse(updateEndTime, "HH:mm", new Date());

                    return (
                        (startTimeA >= startTimeB && startTimeA < endTimeB) || // Verifica se o início do A está dentro do intervalo B
                        (endTimeA > startTimeB && endTimeA <= endTimeB) || // Verifica se o final do A está dentro do intervalo B
                        (startTimeB >= startTimeA && startTimeB < endTimeA) || // Verifica se o início do B está dentro do intervalo A
                        (endTimeB > startTimeA && endTimeB <= endTimeA) // Verifica se o final do B está dentro do intervalo A
                    );
                });
        }

        let startError = "";
        let endError = "";

        if (startTime && !isValid(parsedStartTime)) {
            startError = "Hora inicial inválida";

            setIsInvalidStartTime(true);
        } else {
            setIsInvalidStartTime(false);
        }

        if (endTime && !isValid(parsedEndTime)) {
            endError = "Hora final inválida";

            setIsInvalidEndTime(true);
        } else {
            setIsInvalidEndTime(false);
        }

        if (
            !startError &&
            !endError &&
            parsedStartTime &&
            parsedEndTime &&
            parsedStartTime >
            parsedEndTime
        ) {
            Toast(
                "Atenção!",
                "Hora inicial não pode ser maior que hora final.",
                "warn",
            );

            setIsStartHourBigger(true);
        } else {
            setIsStartHourBigger(false);
        }

        if (overlappingError) {
            Toast(
                "Atenção!",
                "Horários não podem se sobrepor no mesmo dia da semana. Por favor, verifique os horários e tente novamente.",
                "warn",
            );

            setIsHourOverlap(true);
        } else {
            setIsHourOverlap(false);
        }

        const updatedErrors = [...errors];

        updatedErrors[index] = {
            start_time: startError,
            end_time: endError
        };

        setErrors(updatedErrors);
    }

    function isFieldsValid() {
        if (
            !basicData?.desc_sla ||
            !basicData?.id_organizacao ||
            !basicData?.id_criticidade ||
            !basicData?.sla_inicio ||
            !basicData?.sla_resolucao ||
            !basicData?.id_tipo_sla_inicio
        ) return false;

        return true;
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao adicionar SLA!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    function getValues() {
        const slaData = cloneDeep(detailedSla);

        slaData.forEach((sla) => {
            if (sla.id_sla === slaId) {
                setBasicData({
                    ...sla,
                    sla_inicio: convertHours(sla.sla_inicio),
                    sla_resolucao: convertHours(sla.sla_resolucao)
                });
            }
        })
    };

    async function handleUpdateSLA() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        } else if (
            isStartHourBigger ||
            isInvalidStartTime ||
            isInvalidEndTime ||
            isHourOverlap
        ) return;

        let hasError = false;
        let isNewHour = false;
        let isHourEdited = false;

        try {
            setPageStatus("updating-sla");

            const updateBasicData = { ...basicData };

            if (document.getElementById("start-time")?.value) {
                let inputStartTime = document.getElementById("start-time")?.value;
                let [hour, minutes] = inputStartTime.split(":");
                let calculationResult = Number(hour) * 60 + Number(minutes);

                updateBasicData.sla_inicio = calculationResult;
            }

            if (document.getElementById("deadline-resolution")?.value) {
                let inputDeadlineResolution = document.getElementById("deadline-resolution")?.value;
                let [hourResolution, resolutionMinutes] = inputDeadlineResolution.split(":");
                let calculationResultResolution = Number(hourResolution) * 60 + Number(resolutionMinutes);

                updateBasicData.sla_resolucao = calculationResultResolution;
            }

            if (isBasicDataModified) {
                const response = await APISla.updateSla(basicData?.id_sla, token, {
                    id_organizacao: updateBasicData.id_organizacao,
                    id_criticidade: updateBasicData.id_criticidade,
                    sla_inicio: updateBasicData.sla_inicio,
                    id_tipo_sla_inicio: updateBasicData.id_tipo_sla_inicio,
                    sla_resolucao: updateBasicData.sla_resolucao,
                    id_tipo_sla_resolucao: updateBasicData.id_tipo_sla_inicio === 1 ?
                        updateBasicData.id_tipo_sla_resolucao = 1 : updateBasicData.id_tipo_sla_resolucao = 2,
                    desc_sla: updateBasicData.desc_sla
                });

                if (response.status !== 200) {
                    hasError = true;
                }
            }

            await Promise.all(associatedSchedules.map(async (item, index) => {
                if (item.start_time && item.end_time) {
                    if (typeof item.start_time === 'string' && typeof item.end_time === 'string') {
                        const startTimeParts = item.start_time.split(':');
                        const endTimeParts = item.end_time.split(':');

                        item.start_time = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1]);
                        item.end_time = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1]);

                        item.start_time = format(item.start_time, 'HH:mm:ss');
                        item.end_time = format(item.end_time, 'HH:mm:ss');
                    }
                }

                const originalSchedule = originalAssociatedSchedules[index];

                const isHourModified = !originalSchedule ||
                    (item.day_week !== null && item.day_week !== undefined && item.day_week !== originalSchedule.day_week) ||
                    item.start_time !== originalSchedule.start_time ||
                    item.end_time !== originalSchedule.end_time

                if (!item.id_sla_horario) {
                    const isDataValid =
                        item.day_week !== null && item.day_week !== undefined &&
                        item.start_time &&
                        item.end_time

                    if (isDataValid) {
                        const response = await APISla.createSlaSchedules(token, {
                            dia_semana: item.day_week,
                            hora_inicio: item.start_time,
                            hora_fim: item.end_time,
                            id_sla: basicData?.id_sla
                        });

                        if (response.status !== 200) {
                            hasError = true;
                        }

                        isNewHour = true;
                    }
                } else {
                    if (isHourModified) {
                        const response = await APISla.updateSlaSchedules(token, {
                            dia_semana: item.day_week,
                            hora_inicio: item.start_time,
                            hora_fim: item.end_time,
                            id_sla: item.sla_id,
                            id_sla_horario: item.id_sla_horario
                        });

                        if (response.status !== 200) {
                            hasError = true;
                        }

                        isHourEdited = true;
                    }
                }
            }))

            if (excludedHours.length > 0) {
                for (const delHour of excludedHours) {
                    if (delHour.id_sla_horario) {
                        const response = await APISla.deleteSlaSchedules(token, {
                            id_sla_horario: delHour.id_sla_horario,
                            id_sla: delHour.sla_id,
                            id_usuario: userData.userId
                        });

                        if (response.status !== 200) {
                            hasError = true;
                        }
                    }
                }
            }

            if (hasError) {
                Toast(
                    "Não foi possível atualizar o SLA!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );

                throw new Error("Alguma operação falhou");
            } else {
                if (
                    isBasicDataModified ||
                    excludedHours.length > 0 ||
                    isNewHour ||
                    isHourEdited
                ) {
                    Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
                }

                onUpdate();
                toGoBack();
                setPageStatus("awaiting");
                setUpdatedSla(true);
            }
        } catch (error) {
            console.error("Erro ao atualizar SLA:", error);

            Toast(
                "Erro ao atualizar SLA!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        }
    };

    async function getAssociatedSchedules(slaId) {
        setLoading(true);

        try {
            const response = await APISla.getSlaSchedules(token, slaId);

            let associatedSchedulesData = null;

            const newValues = {
                day_week: null,
                start_time: "",
                end_time: "",
                sla_id: null
            };

            if (response.status === 200) {
                associatedSchedulesData = response.data.worktime;

                if (associatedSchedulesData.length > 0) {
                    associatedSchedulesData = associatedSchedulesData.reduce((acc, time) => {
                        acc.push({
                            day_week: time.dia_semana,
                            start_time: time.hora_inicio,
                            end_time: time.hora_fim,
                            sla_id: time.id_sla || basicData?.id_sla,
                            id_sla_horario: time.id_sla_horario
                        });

                        return acc;
                    }, [])
                } else {
                    associatedSchedulesData.push(newValues);
                }

                updateOriginalAssociatedSchedules(associatedSchedulesData);
                setAssociatedSchedules(associatedSchedulesData);
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } catch (error) {
            console.error("Erro ao processar a resposta da API:", error);

            Toast(
                "Erro ao processar a resposta da API",
                "Por favor, tente novamente mais tarde.",
                "error"
            );
        } finally {
            setLoading(false);
        }
    };

    async function getCriticalityList() {
        const response = await APICase.getPriorityList(token);

        if (response.status === 200) {
            let dataUpdated = [];

            response.data.forEach((datum) => {
                let requestedItem = {};

                requestedItem.id = datum.id_criticidade;
                requestedItem.label = datum.descricao;

                dataUpdated.push(requestedItem);
            });

            setCriticalityList(dataUpdated);
        } else {
            Toast(
                "Falha, não foi carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getOrganizationsList() {
        const response = await APISla.listOrganizations(token);

        if (response.status === 200) {
            let dataUpdatedOrganization = [];

            response.data.forEach((data) => {
                let requestedItemOrganization = {};

                requestedItemOrganization.id = data.id_organizacao;
                requestedItemOrganization.label = data.nome;

                dataUpdatedOrganization.push(requestedItemOrganization);
            });

            setOrganizations(dataUpdatedOrganization)
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getListTypeSla() {
        const response = await APISla.typeSla(token);

        if (response.status === 200) {
            let dataUpdatedTypeSla = [];

            response.data.forEach((data) => {
                let requestedItemTypeSla = {};

                requestedItemTypeSla.id = data.id_tipo_sla;
                requestedItemTypeSla.label = data.descricao;

                dataUpdatedTypeSla.push(requestedItemTypeSla);
            });

            setTypesSla(dataUpdatedTypeSla)
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getDaysOfWeek() {
        const response = await APIOrganization.getDaysOfWeek(token);

        if (response.status === 200) {
            let dataUpdatedDayWeek = [];

            response.data.forEach((item) => {
                let requestedItemDayWeek = {};

                requestedItemDayWeek.id = item.id_dia;
                requestedItemDayWeek.label = item.desc_dia;

                dataUpdatedDayWeek.push(requestedItemDayWeek);
            });

            setDaysWeek(dataUpdatedDayWeek);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getSelectionData() {
        try {
            await Promise.allSettled([
                getCriticalityList(),
                getOrganizationsList(),
                getListTypeSla(),
                getDaysOfWeek()
            ])
        } catch (error) {
            console.error("Erro ao obter informações dos selects", error);

            Toast(
                "Erro ao obter informações dos selects!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        }
    };

    //USE MEMOS

    //USE EFFECTS
    useEffect(() => {
        getSelectionData();
    }, []);

    useEffect(() => {
        if (slaId) {
            getValues(slaId);
            getAssociatedSchedules(slaId);
        }
    }, [slaId]);

    return (
        <>
            <main className="page-update-sla">
                <header className="page-update-sla__header">
                    <div className="page-update-sla__header__title">
                        <ArrowLefIcon
                            onClick={() => {
                                toGoBack();
                            }}
                        />

                        <Typography.H2 color="gray-180" weight="bold">
                            Adicionar SLA
                        </Typography.H2>
                    </div>

                    <div className="page-update-sla__header__save-or-cancel">
                        <Button
                            className="btn-remove-sla"
                            icon={<CloseIcon />}
                            onClick={() => setIsOpenModalDeleteSla({ modal: true })}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Excluir SLA
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-cancel-sla"
                            disabled={pageStatus === "updating-sla"}
                            onClick={() => {
                                toGoBack();
                            }}
                        >
                            <Typography.H4 weight="bold" color="blue-selected-4">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-sla"
                            disabled={pageStatus === "updating-sla"}
                            onClick={handleUpdateSLA}
                        >
                            {pageStatus !== "updating-sla" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="section-container">
                    <section className="basic-data-section">
                        <header className="basic-data-section-header">
                            <div className="basic-data-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Dados básicos
                                </Typography.H4>
                            </div>

                            <div className="basic-data-section-header__division" />
                        </header>

                        <div className="basic-data-content-container">
                            <div className="basic-data-content-container__content">
                                <div className="columns-data">
                                    <FieldContainer required title="Descrição da SLA">
                                        <Input
                                            type="text"
                                            placeholder="Digite uma descrição"
                                            value={basicData?.desc_sla}
                                            onChange={(event) => setDataModifiedBasicData({ ...basicData, desc_sla: event })}
                                            error={validationWarn ? !basicData?.desc_sla : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer required title="Organização">
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione a organização"
                                            value={basicData?.id_organizacao}
                                            options={organizations}
                                            onSelect={(organization) => {
                                                setDataModifiedBasicData({ ...basicData, id_organizacao: organization.id })
                                            }}
                                            error={validationWarn ? !basicData?.id_organizacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer required title="Tipo de SLA">
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Seleciona o tipo de SLA"
                                            options={typesSla}
                                            value={basicData?.id_tipo_sla_inicio}
                                            onSelect={(typeSla) => {
                                                setDataModifiedBasicData({ ...basicData, id_tipo_sla_inicio: typeSla.id })
                                            }}
                                            error={validationWarn ? !basicData?.id_tipo_sla_inicio : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer required title="Criticidade">
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Seleciona a criticidade"
                                            options={criticalityList}
                                            value={basicData?.id_criticidade}
                                            onSelect={(criticality) => {
                                                setDataModifiedBasicData({ ...basicData, id_criticidade: criticality.id })
                                            }}
                                            error={validationWarn ? !basicData?.id_criticidade : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer
                                        required
                                        title="Início"
                                        className="panel-registration__sla-time"
                                    >
                                        <Input
                                            type="dynamicMask"
                                            id="start-time"
                                            placeholder="00:00 ou 000:00"
                                            value={basicData?.sla_inicio}
                                            onChange={(event) => setDataModifiedBasicData({ ...basicData, sla_inicio: event })}
                                            error={validationWarn ? !basicData?.sla_inicio : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer
                                        required
                                        title="Prazo para resolução"
                                        className="panel-registration__sla-time"
                                    >
                                        <Input
                                            type="dynamicMask"
                                            id="deadline-resolution"
                                            placeholder="00:00 ou 000:00"
                                            value={basicData?.sla_resolucao}
                                            onChange={(event) => setDataModifiedBasicData({ ...basicData, sla_resolucao: event })}
                                            error={validationWarn ? !basicData?.sla_resolucao : false}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="associated-schedules">
                        <header className="associated-schedules-header">
                            <div className="associated-schedules-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Horários
                                </Typography.H4>
                            </div>

                            <div className="associated-schedules-header__division" />
                        </header>

                        <div className="hours-association-container">
                            <div className="hours-association-container__content">
                                {!loading ? (
                                    <>
                                        {associatedSchedules.map((item, index) => {
                                            const dayErrors = errors[index] || {};

                                            return (
                                                <div
                                                    key={item.id_sla_horario}
                                                    className="associated-hours-fields"
                                                >
                                                    <FieldContainer title="Dia da semana">
                                                        <Select
                                                            changeDownArrowIcon
                                                            uncheckTheOption
                                                            placeholder="Selecione"
                                                            value={item.day_week}
                                                            options={daysWeek}
                                                            onSelect={(day_week) => {
                                                                const dayWeekSelect = cloneDeep(associatedSchedules);

                                                                dayWeekSelect[index].day_week = day_week.id;

                                                                setAssociatedSchedules(dayWeekSelect);
                                                            }}
                                                        />
                                                    </FieldContainer>

                                                    <FieldContainer title="Hora inicial">
                                                        <label className="input-container">
                                                            <div className="input-wrapper">
                                                                <MaskedInput
                                                                    mask="99:99"
                                                                    className="input-wrapper__input"
                                                                    placeholder="HH:MM"
                                                                    value={item.start_time}
                                                                    onChange={(e) => handleStartTimeChange(index, e.target.value)}
                                                                    onBlur={() => handleBlur(index)}
                                                                    disabled={item.day_week === null || item.day_week === undefined}
                                                                />
                                                            </div>

                                                            {item.start_time !== "" && dayErrors.start_time && (
                                                                <div className="input-msg input-msg--error">
                                                                    <div className="input-msg__icon">
                                                                        <IconError />
                                                                    </div>

                                                                    <Typography.Paragraph className="input-msg__title">
                                                                        {dayErrors.start_time}
                                                                    </Typography.Paragraph>
                                                                </div>
                                                            )}
                                                        </label>
                                                    </FieldContainer>

                                                    <FieldContainer title="Hora final">
                                                        <label className="input-container">
                                                            <div className="input-wrapper">
                                                                <MaskedInput
                                                                    mask="99:99"
                                                                    className="input-wrapper__input"
                                                                    placeholder="HH:MM"
                                                                    value={item.end_time}
                                                                    onChange={(e) => handleEndTimeChange(index, e.target.value)}
                                                                    onBlur={() => handleBlur(index)}
                                                                    disabled={item.day_week === null || item.day_week === undefined}
                                                                />
                                                            </div>

                                                            {item.end_time !== "" && dayErrors.end_time && (
                                                                <div className="input-msg input-msg--error">
                                                                    <div className="input-msg__icon">
                                                                        <IconError />
                                                                    </div>

                                                                    <Typography.Paragraph className="input-msg__title">
                                                                        {dayErrors.end_time}
                                                                    </Typography.Paragraph>
                                                                </div>
                                                            )}
                                                        </label>
                                                    </FieldContainer>

                                                    <div className="associated-hours-fields__actions">
                                                        <MinusCircleIcon
                                                            className={`delete-hour ${associatedSchedules.length === 1 && "disabled"}`}
                                                            onClick={(e) => {
                                                                if (associatedSchedules.length === 1) return;

                                                                e.preventDefault();
                                                                handleRemoveHour(index);
                                                            }}
                                                        />

                                                        <AddCircleIcon
                                                            className="add-hour"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleAddHour();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <div className="hours-association-container__content__loading">
                                        <Loading.Paperplane />
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </main>

            <Modal.DeleteSLA
                returnToSlaList={toGoBack}
                isOpen={isOpenModalDeleteSla}
                onClose={(event) => setIsOpenModalDeleteSla(event)}
                slaData={detailedSla}
                deletedSlaId={basicData?.id_sla}
                onDelete={onUpdate}
                handleTableDataLookup={handleTableDataLookup}
            />
        </>
    )
}
