//GLOBAL - components from npm
import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { sub } from "date-fns";

// UTILS
// import formatDate from "../../../utils/formatDate";

//STYLES
import "./header.scss";

//COMPONENTS
import { Typography } from "../../components/general";

//SERVICES - api, conectors...
// import * as APITask from "../../../services/api/task";

//GLOBAL STATE - redux, env...
import { useSelector, useDispatch } from "react-redux";
import { doLogout } from "../../store/auth";
import { setStorageFilter, setFilter } from "../../store/filters";

//ASSETS - icons, images...
// import { ReactComponent as IconLogo } from "../../assets/icons/Logo.svg";
import { ReactComponent as IconLogo } from "../../assets/icons/Logo_IngDeskNew.svg";
// import { ReactComponent as IconLogoDark } from "../../assets/icons/Logo-dark.svg";
import { ReactComponent as IconLogoDark } from "../../assets/icons/Logo_IngDesk_DarkModeNew.svg";
import { ReactComponent as IconLogoff } from "../../assets/icons/Logoff.svg";
import { ReactComponent as IconBell } from "../../assets/icons/BellHeader.svg";
import { ReactComponent as IconSetting } from "../../assets/icons/Setting.svg";
import { ReactComponent as IconArrow } from "../../assets/icons/ArrowDownTwo.svg";
import { ReactComponent as IconAvatar } from "../../assets/icons/Avatar.svg";

export default function Header() {
  //GENERAL
  const history = useHistory();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.userData);

  //STATES
  //   const [pageStatus, setPageStatus] = useState("loading");

  //REDUX - Selectors
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.filters.darkMode);

  //FUNCTIONS

  //USE EFFECTS

  return (
    <div
      className={`task-header ${
        darkMode && location.pathname === "/dashboard" ? "dark-mode" : ""
      }`}
    >
      <div className="task-header-container">
        <div className="task-header-container-box">
          <div className="task-header-container-box__logo">
            {darkMode && location.pathname === "/dashboard" ? (
              <>
                <IconLogoDark />
                {/* <IconMarkOficial className="oficial_mark" /> */}
              </>
            ) : (
              <>
                <IconLogo />
                {/* <IconMarkOficial className="oficial_mark" /> */}
              </>
            )}
          </div>
        </div>
        <div className="task-header-container-nav">
          <div className="task-header-container-nav__welcome">
            <Typography.H4
              color={
                darkMode && location.pathname === "/dashboard"
                  ? "white"
                  : "gray-180"
              }
            >
              Olá,&nbsp;{" "}
            </Typography.H4>
            <Typography.H4
              color={
                darkMode && location.pathname === "/dashboard"
                  ? "white"
                  : "blue-selected-4"
              }
              weight={700}
            >
              {user?.userName}
            </Typography.H4>
            {/* <p>
              Olá, <span>{user?.userName}</span>
            </p> */}
          </div>
          <div className="task-header-container-nav__avatar">
            <IconAvatar />
          </div>
          <div className="task-header-container-nav__arrow">
            <IconArrow />
          </div>
          <div className="task-header-container-nav__setting">
            <IconSetting
              className={
                darkMode && location.pathname === "/dashboard"
                  ? "dark-mode"
                  : ""
              }
            />
          </div>
          <div className="task-header-container-nav__bell">
            <IconBell
              className={
                darkMode && location.pathname === "/dashboard"
                  ? "dark-mode"
                  : ""
              }
            />
          </div>
          <div
            className="task-header-container-nav__logoff"
            onClick={() => {
              dispatch(doLogout());
              dispatch(
                setFilter({
                  to: "cases",
                  type: "dateStart",
                  value: sub(new Date(), { months: 1 }),
                })
              );
            }}
          >
            <IconLogoff
              className={
                darkMode && location.pathname === "/dashboard"
                  ? "dark-mode"
                  : ""
              }
            />
          </div>
        </div>
      </div>
      {/* <hr
        className="task-header__border"
        // style={{ opacity: "30%", marginTop: "8.5px" }}
        style={{ opacity: "30%" }}
      ></hr> */}
    </div>
  );
}
