import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";

import { Loading, Modal } from "./../../../feedback";
import { Button, Typography } from "../../../general";
import { FieldContainer } from "../../../data-display";
import { Toast } from "../../../../utils/toast";
import { CustomSelect, Input, Select } from "../../../data-entry";

import * as APISla from "./../../../../services/api/sla";
import * as APIMotive from "./../../../../services/api/motives";
import * as APITypeSolicitation from "./../../../../services/api/typeSolicitation";

import "./create-type.scss"

export default function CreateType({
    isOpen,
    onClose,
    onCreateReason,
    reasonListValues,
    treeData,
    setReasonListValues,
    organizationId,
    setTypesLoading,
    setShouldReloadTypes,
    typesLoading
}) {
    const token = useSelector((state) => state.auth.token);

    const [pageStatus, setPageStatus] = useState("requesting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [listSla, setListSla] = useState([]);
    const [reasonData, setReasonData] = useState(() => { return { desc_motivo: "", }; });
    const [formValues] = useState(() => {
        return {
            descricao_solicitacao: "",
            id_motivo: [],
            id_sla: null,
            editavel: true,
        }
    });
    const [typeValues, setTypeValues] = useState({ ...formValues });

    const customStyles = {
        input: (provided) => ({
            ...provided,
            flexGrow: "1",
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            color: "#27282e",
            transition: "all 0.25s",
            outline: "none",
        }),
        menu: (provided) => ({
            ...provided,
            ...(
                reasonData.desc_motivo?.length > 1 ?
                    {
                        maxHeight: "110px",
                        position: "absolute",
                        display: "flex",
                        zIndex: 15,
                        flexDirection: "column",
                        borderTop: "0",
                        overflow: "auto",
                        border: "1px solid #2684FF",
                        boxShadow: "none",
                        borderColor: "#455cc7",
                    } : {
                        maxHeight: "145px",
                        position: "absolute",
                        display: "flex",
                        zIndex: 15,
                        flexDirection: "column",
                        borderTop: "0",
                        overflow: "auto",
                        border: "1px solid #2684FF",
                        boxShadow: "none",
                        borderColor: "#455cc7",
                    }
            )
        }),
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
            boxShadow: "none",
        }),
        valueContainer: (provided) => ({
            ...provided,
            ...(
                reasonData.desc_motivo?.length > 0 ?
                    {
                        maxHeight: "80px",
                        overflow: "auto",
                        padding: "10px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        "::-webkit-scrollbar": {
                            width: "5px",
                        },
                    } : {
                        maxHeight: "80px",
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                            width: "5px",
                        },
                    }
            )
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            ":active": {
                color: "hsl(0, 0%, 80%)",
                transform: "rotate(180deg)"
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#bfc3ca",
        }),
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            minHeight: "min-content",
            padding: "12px",
            textAlign: "left",

            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#828292",
            cursor: "pointer",

            ":active": {
                backgroundColor: "#ebf2ff",
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px 8px",
            gap: "4px",
            height: "24px",
            backgroundColor: "#f4f4f4",
            borderRadius: "24px",
            border: "1px solid rgba(216, 213, 213, 0.25)",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "112.5%",
            padding: "5px",
            alignItems: "flex-end",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#455cc7",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            height: "20px",
            width: "20px",
            background: "#bfc3ca",
            color: "#fff",
            borderRadius: "50%",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "0.5em",
            fontSize: "18px",
            cursor: "pointer",

            ":hover": {
                backgroundColor: "none",
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,

            ":hover": {
                transform: "rotate(180deg)",
            },
        }),
    };

    const handleRadioChange = (event) => {
        const value = event.target.value === "yes" ? true : false;

        setTypeValues((prevValue) => ({
            ...prevValue,
            editavel: value,
        }))
    };

    function onCloseCustom() {
        setTypeValues({
            ...typeValues,
            descricao_solicitacao: "",
            id_motivo: [],
            id_sla: null,
            editavel: true
        })
        setReasonData({ ...reasonData, desc_motivo: [] });

        onClose();
    };

    function cleanFields() {
        setTimeout(() => {
            setTypeValues({
                ...typeValues,
                descricao_solicitacao: "",
                id_motivo: [],
                id_sla: null,
                editavel: true,
            });
            setReasonData({ ...reasonData, desc_motivo: [] });

            setPageStatus("awaiting");
        }, 50);
    };

    function isFormValidated() {
        if (
            !typeValues.descricao_solicitacao ||
            !typeValues.id_sla
        )
            return false;

        return true;
    };

    async function onKeyDownCreateReason(event) {
        if (event.key !== "Enter") return;

        const inputValue = event.target.value;

        if (!inputValue.trim()) return;

        const index = reasonListValues?.findIndex(
            (value) => value.desc_motivo === inputValue
        );

        if (index < 0) {
            setReasonListValues([
                ...reasonListValues,
                {
                    id_motivo: null,
                    desc_motivo: inputValue,
                },
            ]);

            reasonData.desc_motivo = inputValue;

            const response = await APIMotive.createMotive(token, reasonData);

            if (response.status === 200) {
                onCreateReason();

                Toast(
                    "Motivo adicionado!",
                    "Motivo adicionado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível adicionar o motivo",
                    "Tente novamente mais tarde!",
                    "error"
                );
            }
        } else {
            Toast(
                "Não foi possível criar esse motivo!",
                "Esse motivo ja existe, adicione outro com o nome diferente",
                "warn"
            );
        }
    };

    async function getListSla() {
        const response = await APISla.listSla(token);

        const data = response.data;
        let updatedData = [];

        data.forEach((sla) => {
            let updateSla = {
                id: sla.id_sla,
                label: sla.desc_sla,
            };

            updatedData.push(updateSla);
        });

        setListSla(updatedData);
    };

    async function handleCreateType() {
        if (isFormValidated()) {
            setPageStatus("creating-type");

            const index = treeData.types?.findIndex(
                (value) =>
                    value.desc_solicitation === typeValues.descricao_solicitacao &&
                    value.id_sla === typeValues.id_sla &&
                    value.reasonIdList?.forEach(item => item) === typeValues.id_motivo.forEach(item => item)
            );

            if (index < 0) {
                const registrationValues = {
                    descricao_solicitacao: typeValues.descricao_solicitacao,
                    id_motivo: typeValues.id_motivo,
                    id_sla: typeValues.id_sla,
                    editavel: typeValues.editavel,
                    id_organizacao: organizationId && organizationId
                };

                typeValues.id_motivo = [...new Set(typeValues.id_motivo)];

                const response = await APITypeSolicitation.createSolicitationV2(token, registrationValues);

                if (response.status === 200) {
                    cleanFields();
                    onClose();

                    setShouldReloadTypes(true);
                    setTypesLoading(true);

                    Toast(
                        "Tipo adicionado!",
                        "Tipo adicionado com sucesso.",
                        "success"
                    );
                } else {
                    Toast(
                        "Não foi possível adicionar o Tipo",
                        "Tente novamente mais tarde!",
                        "error"
                    );
                }
            } else {
                Toast(
                    "ATENÇÃO!",
                    "Não é possível inserir duas vezes a mesma condição(Nome do Tipo + SLA + Motivo)",
                    "warn"
                );
            }

            setPageStatus("awaiting");
        } else if (
            !typeValues.descricao_solicitacao ||
            !typeValues.id_sla
        ) {
            setValidationWarn(true);

            Toast(
                "Erro ao adicionar o Tipo!",
                "Verifique o preenchimento dos campos em destaque",
                "error"
            );
        }
    };

    useEffect(() => {
        if (isOpen) {
            getListSla();
        }
    }, [isOpen]);

    useEffect(() => {
        if (reasonData.desc_motivo) {
            reasonData.desc_motivo?.forEach((value) => {
                typeValues.id_motivo.push(value.value);
            });
        }
    }, [reasonData]);

    useEffect(() => {
        if (typesLoading) {
            const loading = setTimeout(() => {
                setTypesLoading(false);
            }, 2000);

            return () => clearTimeout(loading);
        }
    }, [typesLoading, setTypesLoading]);

    return (
        <Modal.Main
            className="modal-create-type"
            title="Adicionar tipo"
            height="500px"
            width="700px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <>
                    <Button
                        className="modal-create-type__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "creating-type"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-create-type__btn-confirm"
                        disabled={pageStatus === "creating-type"}
                        onClick={() => handleCreateType()}
                    >
                        {pageStatus !== "creating-type" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </>
            }
        >
            <section className="modal-create-type__content">
                <div className="modal-create-type__content__first">
                    <FieldContainer required title="Descrição">
                        <Input
                            type="text"
                            placeholder="Digite o nome da descrição"
                            value={typeValues.descricao_solicitacao}
                            onChange={(event) => {
                                setTypeValues({ ...typeValues, descricao_solicitacao: event })
                            }}
                            error={
                                validationWarn ? !typeValues.descricao_solicitacao : false
                            }
                        />
                    </FieldContainer>
                </div>

                <div className="modal-create-type__content__second">
                    <FieldContainer required title="SLA">
                        <Select
                            placeholder="Selecione o SLA"
                            options={listSla}
                            value={typeValues.id_sla}
                            onSelect={(sla) => {
                                setTypeValues({ ...typeValues, id_sla: sla.id })
                            }}
                            error={
                                validationWarn ? !typeValues.id_sla : false
                            }
                        />
                    </FieldContainer>

                    <FieldContainer required title="Editável">
                        <div className="field-container-radio">
                            <div className="container-input-radio">
                                <label htmlFor="yes" className="radio-label">
                                    <input
                                        type="radio"
                                        id="yes"
                                        name="radio-option"
                                        value="yes"
                                        className="custom-radio"
                                        checked={typeValues.editavel === true}
                                        onChange={handleRadioChange}
                                    />
                                    Sim
                                </label>
                            </div>

                            <div className="container-input-radio">
                                <label htmlFor="no" className="radio-label">
                                    <input
                                        type="radio"
                                        id="no"
                                        name="radio-option"
                                        value="no"
                                        className="custom-radio"
                                        checked={typeValues.editavel === false}
                                        onChange={handleRadioChange}
                                    />
                                    Não
                                </label>
                            </div>
                        </div>
                    </FieldContainer>
                </div>

                <div className="divisor" />

                <div className="modal-create-type__content__third">
                    <FieldContainer title="Motivo">
                        <CustomSelect
                            placeholder="Selecione ou adicione um novo motivo"
                            value={reasonData.desc_motivo}
                            styles={customStyles}
                            onKeyDown={onKeyDownCreateReason}
                            onChange={(newReason) =>
                                setReasonData({ ...reasonData, desc_motivo: newReason })
                            }
                            options={reasonListValues?.map((motive) => {
                                return {
                                    value: motive.id_motivo,
                                    label: motive.desc_motivo

                                };
                            })}
                        />
                    </FieldContainer>
                </div>
            </section>
        </Modal.Main>
    )
}
