import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Loading, Modal } from "./../../../feedback";
import { Button, Typography } from "../../../general";
import { FieldContainer } from "../../../data-display";
import { CustomSelect, Select } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";

import * as APITypeManagement from "./../../../../services/api/typeManagement";
import * as APICase from "./../../../../services/api/case";
import * as APISla from "./../../../../services/api/sla";

import "./associati-subreason.scss"

export default function AssociatiSubreason({
  isOpen,
  onClose,
  selectedReasonId,
  selectedTypeId,
  treeData,
  setTreeData,
  organizationId,
  valuesSelectedReason
}) {
  const token = useSelector((state) => state.auth.token);

  const [pageStatus, setPageStatus] = useState("requesting");
  const [subreasonListData, setSubreasonListData] = useState([]);
  const [listSla, setListSla] = useState([]);
  const [subreasonData, setSubreasonData] = useState(() => { return { desc_submotivo: "", }; });
  const [membershipValues, setMembershipValues] = useState(() => { return { id_sla: null, desc_sla: "", editavel: null } });


  const customStyles = {
    input: (provided) => ({
      ...provided,
      flexGrow: "1",
      fontFamily: "Inter, sans-serif",
      fontSize: "14px",
      color: "#27282e",
      transition: "all 0.25s",
      outline: "none",
    }),
    menu: (provided) => ({
      ...provided,
      ...(
        subreasonData.desc_submotivo?.length > 0 ?
          {
            maxHeight: "110px",
            position: "absolute",
            display: "flex",
            zIndex: 15,
            flexDirection: "column",
            borderTop: "0",
            overflow: "auto",
            border: "1px solid #2684FF",
            boxShadow: "none",
            borderColor: "#455cc7",
          } : {
            maxHeight: "130px",
            position: "absolute",
            display: "flex",
            zIndex: 15,
            flexDirection: "column",
            borderTop: "0",
            overflow: "auto",
            border: "1px solid #2684FF",
            boxShadow: "none",
            borderColor: "#455cc7",
          }
      )
    }),
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...(
        subreasonData.desc_submotivo?.length > 0 ?
          {
            maxHeight: "50px",
            overflow: "auto",
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            "::-webkit-scrollbar": {
              width: "5px",
            },
          } : {
            maxHeight: "80px",
            overflow: "auto",
            "::-webkit-scrollbar": {
              width: "5px",
            },
          }
      )
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      ":active": {
        color: "hsl(0, 0%, 80%)",
        transform: "rotate(180deg)"
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#bfc3ca",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      minHeight: "min-content",
      padding: "12px",
      textAlign: "left",

      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#828292",
      cursor: "pointer",

      ":active": {
        backgroundColor: "#ebf2ff",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px 8px",
      gap: "4px",
      height: "24px",
      backgroundColor: "#f4f4f4",
      borderRadius: "24px",
      border: "1px solid rgba(216, 213, 213, 0.25)",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "112.5%",
      padding: "5px",
      alignItems: "flex-end",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "#455cc7",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      height: "20px",
      width: "20px",
      background: "#bfc3ca",
      color: "#fff",
      borderRadius: "50%",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "0.5em",
      fontSize: "18px",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "none",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,

      ":hover": {
        transform: "rotate(180deg)",
      },
    }),
  };

  const handleRadioChange = (event) => {
    const value = event.target.value === "yes" ? true : false;

    setMembershipValues((prevValue) => ({
      ...prevValue,
      editavel: prevValue.id_sla !== null ? value : null,
    }))
  };

  const filteredOptions = subreasonListData?.filter(
    (subreason) =>
      !valuesSelectedReason?.subreasons?.some(
        (option) => Number(option.id_submotivo) === subreason.id_submotivo
      )
  );

  function onCloseCustom() {
    onClose();
    setSubreasonData({ ...subreasonData, desc_submotivo: [] });
    setMembershipValues({
      ...membershipValues,
      id_sla: null,
      editavel: null
    })
  };

  async function getListSla() {
    const response = await APISla.listSla(token);

    const data = response.data;
    let updatedData = [];

    data.forEach((sla) => {
      let updateSla = {
        id: sla.id_sla,
        label: sla.desc_sla,
      };

      updatedData.push(updateSla);
    });

    setListSla(updatedData);
  };

  async function onKeyDownCreateSubreason(event) {
    if (event.key !== "Enter") return;

    const inputValue = event.target.value;

    if (!inputValue.trim()) return;

    const index = subreasonListData?.findIndex(
      (value) => value.desc_submotivo === inputValue
    );

    if (index < 0) {
      setSubreasonListData([
        ...subreasonListData,
        { desc_submotivo: inputValue }
      ]);

      subreasonData.desc_submotivo = inputValue;

      const response = await APITypeManagement.createSubreason(token, subreasonData);

      if (response.status === 200) {
        getSubreasonList();

        setSubreasonData({ ...subreasonData, desc_submotivo: [] });

        Toast(
          "Submotivo adicionado!",
          "Submotivo adicionado com sucesso.",
          "success"
        );
      } else {
        Toast(
          "Não foi possível adicionar o submotivo",
          "Tente novamente mais tarde!",
          "error"
        );
      }
    } else {
      Toast(
        "Não foi possível criar esse submotivo!",
        "Esse submotivo ja existe, adicione outro com o nome diferente",
        "warn"
      );
    }
  };

  async function getSubreasonList() {
    setPageStatus("requesting");

    const response = await APICase.getSubReasonsList(token);

    if (response.status === 200) {
      setSubreasonListData(response.data)
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    };

    setPageStatus("awaiting");
  };

  async function handleSubreasonAssociati() {
    if (subreasonData.desc_submotivo?.length > 0) {
      try {
        setPageStatus("creating-subreason");

        if (selectedReasonId) {
          let updatedTreeData = { ...treeData };

          const selectedType = updatedTreeData.types?.find(
            (type) => type.id_solicitation === selectedTypeId
          );

          const selectedReason = selectedType?.arrayOfReasons?.find(
            (reason) => reason.id_motivo === selectedReasonId
          );

          if (selectedReason) {
            for (const selectedOption of subreasonData.desc_submotivo) {
              const reasonId = selectedReasonId
              const subreasonId = selectedOption.value

              const values = {
                id_motivo: Number(reasonId),
                id_submotivo: subreasonId
              }

              await APITypeManagement.AssociateReasonSubreason(token, values);

              selectedReason.subreasons.push({
                id_submotivo: selectedOption.value.toString(),
                desc_submotivo: selectedOption.label,
                reason_id: selectedReasonId,
                id_sla: membershipValues.id_sla,
                desc_sla: membershipValues.desc_sla,
                editavel: membershipValues.editavel,
                qtde_submotivo: (selectedReason.subreasons.length + 1).toString()
              })
            }

            selectedType.arrayOfReasons = selectedType.arrayOfReasons.map((reason) => {
              if (reason.id_motivo === selectedReasonId) {
                return {
                  ...reason,
                  qtde_submotivo: selectedReason.subreasons.length.toString()
                };
              }
              return reason;
            });
          }

          onCloseCustom();
          setSubreasonData({ ...subreasonData, desc_submotivo: [] })
          setTreeData(updatedTreeData);

          Toast(
            "Submotivo associado!",
            "Submotivo associado com sucesso!",
            "success"
          );
        }

        setPageStatus("awaiting");
      } catch (error) {
        Toast(
          "Não foi possível associar o submotivo!",
          "Tente novamente mais tarde!",
          "error"
        );
      }
    } else {
      Toast(
        "Não foi possível associar o submotivo!",
        "Selecione pelo menos um submotivo para fazer a associação!",
        "error"
      );
    }
  };

  async function handleDealwithassociationosubreasonreasonAndSla() {
    if (subreasonData.desc_submotivo?.length > 0) {
      try {
        setPageStatus("creating-subreason");

        if (selectedReasonId) {
          let updatedTreeData = { ...treeData };

          const selectedType = updatedTreeData.types?.find(
            (type) => type.id_solicitation === selectedTypeId
          );

          const selectedReason = selectedType?.arrayOfReasons?.find(
            (reason) => reason.id_motivo === selectedReasonId
          );

          if (selectedReason) {
            for (const selectedOption of subreasonData.desc_submotivo) {
              const reasonId = selectedReasonId
              const subreasonId = selectedOption.value

              const valuesForAssociationOfSlaWithSubreason = {
                id_motivo: Number(reasonId),
                id_submotivo: subreasonId,
                id_sla: membershipValues.id_sla,
                desc_sla: membershipValues.desc_sla,
                editavel: membershipValues.editavel,
                id_tipo_solicitacao: Number(selectedTypeId),
                id_organizacao: organizationId && organizationId
              }

              const valuesForAssociationOfAubreasonWithReason = {
                id_motivo: Number(reasonId),
                id_submotivo: subreasonId
              }

              await APITypeManagement.AssociateReasonSubreasonSlaV2(
                token,
                valuesForAssociationOfSlaWithSubreason
              );
              await APITypeManagement.AssociateReasonSubreason(
                token,
                valuesForAssociationOfAubreasonWithReason
              );

              selectedReason.subreasons.push({
                id_submotivo: selectedOption.value.toString(),
                desc_submotivo: selectedOption.label,
                reason_id: selectedReasonId,
                id_sla: membershipValues.id_sla,
                desc_sla: membershipValues.desc_sla,
                editavel: membershipValues.editavel,
                qtde_submotivo: (selectedReason.subreasons.length + 1).toString()
              })
            }

            selectedType.arrayOfReasons = selectedType.arrayOfReasons.map((reason) => {
              if (reason.id_motivo === selectedReasonId) {
                return {
                  ...reason,
                  qtde_submotivo: selectedReason.subreasons.length.toString()
                };
              }
              return reason;
            });
          }

          onCloseCustom();
          setSubreasonData({ ...subreasonData, desc_submotivo: [] })
          setTreeData(updatedTreeData);

          Toast(
            "Submotivo associado!",
            "Submotivo associado com sucesso!",
            "success"
          );
        }

        setPageStatus("awaiting");
      } catch (error) {
        Toast(
          "Não foi possível associar o submotivo!",
          "Tente novamente mais tarde!",
          "error"
        );
      }
    } else {
      Toast(
        "Não foi possível associar o submotivo!",
        "Selecione pelo menos um submotivo para fazer a associação!",
        "error"
      );
    }
  }

  useEffect(() => {
    if (isOpen) {
      getSubreasonList();
      getListSla();
    }
  }, [isOpen]);

  useEffect(() => {
    if (membershipValues.id_sla === null) {
      setMembershipValues((preValue) => ({
        ...preValue,
        editavel: null,
      }))
    }
  }, [membershipValues.id_sla])

  return (
    <Modal.Main
      className="modal-associati-subreason"
      title="Adicionar submotivo"
      width="600px"
      height="400px"
      isOpen={isOpen}
      onClose={onCloseCustom}
      shouldCloseOnOverlayClick={false}
      footer={
        <>
          <Button
            className="modal-associati-subreason__btn-cancel"
            onClick={() => onCloseCustom()}
            disabled={pageStatus === "creating-subreason"}
          >
            <Typography.H4 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H4>
          </Button>

          <Button
            className="modal-associati-subreason__btn-confirm"
            disabled={pageStatus === "creating-subreason"}
            onClick={() => {
              if (membershipValues.id_sla !== null && membershipValues.editavel !== null) {
                handleDealwithassociationosubreasonreasonAndSla()
              } else {
                handleSubreasonAssociati()
              }
            }}
          >
            {pageStatus !== "creating-subreason" ? (
              <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
            ) : (
              <Loading.Dots />
            )}
          </Button>
        </>
      }
    >
      <section className="modal-associati-subreason__content">
        <div className="container-other-associations">
          <FieldContainer title="SLA">
            <Select
              uncheckTheOption
              placeholder="Selecione o SLA"
              options={listSla}
              value={membershipValues.id_sla}
              onSelect={(newValue) => {
                setMembershipValues((prevState) => ({
                  ...prevState,
                  editavel: newValue !== null ? prevState.editavel : null,
                  id_sla: newValue.id || null,
                  desc_sla: newValue.label
                }))
              }}
            />
          </FieldContainer>

          <FieldContainer title="Editável">
            <div className="editable-field-container">
              <div className="container-input-radio">
                <label htmlFor="yes">
                  <input
                    type="radio"
                    id="yes"
                    name="options"
                    value="yes"
                    onChange={handleRadioChange}
                    checked={membershipValues.editavel === true}
                    disabled={membershipValues.id_sla === null}
                  />
                  Sim
                </label>
              </div>

              <div className="container-input-radio">
                <label htmlFor="no">
                  <input
                    type="radio"
                    id="no"
                    name="options"
                    value="no"
                    onChange={handleRadioChange}
                    checked={membershipValues.editavel === false}
                    disabled={membershipValues.id_sla === null}
                  />
                  Não
                </label>
              </div>
            </div>
          </FieldContainer>
        </div>

        <div className="modal-associati-subreason__content__divisor" />

        <div>
          <FieldContainer title="Submotivos">
            <CustomSelect
              subreason
              placeholder={"Selecione ou adicione um novo submotivo"}
              value={subreasonData.desc_submotivo}
              onChange={(newSubreason) =>
                setSubreasonData({ ...subreasonData, desc_submotivo: newSubreason })
              }
              onKeyDown={onKeyDownCreateSubreason}
              styles={customStyles}
              options={
                filteredOptions?.map((subreason) => {
                  return {
                    value: subreason.id_submotivo,
                    label: subreason.desc_submotivo
                  }
                })
              }
            />
          </FieldContainer>
        </div>
      </section>
    </Modal.Main>
  )
}
