//GLOBAL - components from npm
import React from "react";

//STYLES
import "./filter-tag.scss";

//COMPONENTS
import { Typography } from "../../general";
import { ImageProfile } from "..";
import { ReactComponent as IconPriorityLow } from "../../../assets/icons/PriorityLow.svg";
import { ReactComponent as IconPriorityDefault } from "../../../assets/icons/PriorityDefault.svg";
import { ReactComponent as IconPriorityAverage } from "../../../assets/icons/PriorityAverage.svg";
import { ReactComponent as IconPriorityHigh } from "../../../assets/icons/PriorityHigh.svg";
import { ReactComponent as IconPriorityCritical } from "../../../assets/icons/PriorityCritical.svg";
import { ReactComponent as FlagPriorityAverage } from "../../..//assets/icons/PriorityAverage2.svg";
import { ReactComponent as FlagPriorityCritical } from "../../..//assets/icons/PriorityCritical2.svg";
import { ReactComponent as FlagPriorityHigh } from "../../..//assets/icons/PriorityHigh2.svg";
import { ReactComponent as FlagPriorityLow } from "../../..//assets/icons/PriorityLow2.svg";
import { ReactComponent as IconCalendar } from "../../../assets/icons/Calendar.svg";
import { ReactComponent as IconCaso } from "../../../assets/icons/Caso.svg";
import { ReactComponent as IconGoBack } from "../../../assets/icons/GoBack.svg";
import { ReactComponent as IconFilterAdvanced } from "../../../assets/icons/IconFilterAdvanced.svg";
import { Channel } from "../../../components/data-display";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconClose } from "../../../assets/icons/Close.svg";
import { ReactComponent as Escalate } from "../../../assets/icons/Escalate.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning-tag.svg";
import { ReactComponent as Error } from "../../../assets/icons/error-tag.svg";

export default function FilterTag({
  label,
  onClose,
  profile = false,
  amount,
  amountLabel,
  type,
  priority,
  agent,
  alertIcon = false,
  onClickAlert,
  id,
  className = "",
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  const handlePriority = (option) => {
    switch (option) {
      case 3:
        return <FlagPriorityLow style={{ width: 20, height: 20 }} />;

      case 2:
        return <FlagPriorityAverage style={{ width: 20, height: 20 }} />;

      case 0:
        return <IconPriorityDefault />;

      case 1:
        return <FlagPriorityHigh style={{ width: 20, height: 20 }} />;

      case 4:
        return <FlagPriorityCritical style={{ width: 20, height: 20 }} />;

      default:
        break;
    }
  };

  const handleType = (value) => {
    switch (value) {
      case "priority":
        return (
          <div className="filter-tag__priority">
            {handlePriority(priority)}&nbsp;&nbsp;
          </div>
        );
      case "agents":
        return (
          <div className="filter-tag__profile">
            <ImageProfile profile={agent} tooltip={false} />
          </div>
        );

      case "channels":
        return (
          <div className="filter-tag__channel">
            <Channel media={id} nameMedia={label.toLowerCase()} tippy={false} />
          </div>
        );

      case "escalate":
        return (
          <div className="filter-tag__escalate">
            <Escalate />
          </div>
        );

      case "ticketLated":
        return (
          <div className="filter-tag__icon">
            <IconCalendar />
          </div>
        );

      case "ticketPrimary":
        return (
          <div className="filter-tag__icon">
            <IconCaso />
          </div>
        );

      case "ticketSecondary":
        return (
          <div className="filter-tag__icon">
            <IconGoBack />
          </div>
        );

      default:
        return (
          <div className="filter-tag__icon-filter">
            <IconFilterAdvanced />
          </div>
        );
    }
  };

  //USE EFFECTS

  return (
    <div className={`filter-tag ${className}`} style={{ alignItems: "center" }}>
      {alertIcon &&
        (alertIcon === "warning" ? (
          <Warning onClick={onClickAlert} className="filter-tag__alertIcon" />
        ) : (
          <Error onClick={onClickAlert} className="filter-tag__alertIcon" />
        ))}
      {profile && (
        <ImageProfile profile={{ nome_usuario: label }} tooltip={false} />
      )}
      {amount ? (
        <div className="filter-tag__amount">
          <Typography.H6 color="primary-dark" weight="bold">
            {amount}&nbsp;
          </Typography.H6>
          <Typography.H6 color="primary-dark">{amountLabel}</Typography.H6>
        </div>
      ) : (
        <>
          {handleType(type)}
          <Typography.H5
            color="blue-selected-4"
            weight={500}
            className="filter-tag__label"
          >
            {label}
          </Typography.H5>
        </>
      )}

      {onClose && (
        <IconClose onClick={onClose} className="filter-tag__btn-close" />
      )}
    </div>
  );
}
