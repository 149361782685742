//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Typography } from "../../../general";

//COMPONENTS
import { Modal } from "./../../../feedback";
import { FieldContainer } from "../../../data-display";
import { Toast } from "../../../../utils/toast";
import { Input, Select } from "../../../data-entry";
import Loading from "../../Loading";
import { convertHours } from "../../../../utils/convertHours";

//SERVICES - api, conectors...
import * as APISla from "./../../../../services/api/sla";
import * as APICase from "./../../../../services/api/case";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...

//STYLES
import "./update-sla.scss";

export default function UpdateSLA({ isOpen, onClose, onEdit, slaData }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL
    const slaId = isOpen || null;

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [data, setData] = useState()
    const [criticalityList, setCriticalityList] = useState();
    const [organizations, setOrganizations] = useState();
    const [typeSla, setTypeSla] = useState();
    const [id, setId] = useState();

    function onCloseCustom() {
        onClose();
    };

    //FUNCTIONS
    async function getListTypeSla() {
        setPageStatus("requesting");

        const response = await APISla.typeSla(token);

        if (response.status === 200) {
            let dataUpdatedTypeSla = [];

            response.data.forEach((data) => {
                let requestedItemTypeSla = {};

                requestedItemTypeSla.id = data.id_tipo_sla;
                requestedItemTypeSla.label = data.descricao;

                dataUpdatedTypeSla.push(requestedItemTypeSla);
            });

            setTypeSla(dataUpdatedTypeSla)
        } else {
            Toast(
                "Falha, não foi carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    async function getOrganizationsList() {
        setPageStatus("requesting");

        const response = await APISla.listOrganizations(token);

        if (response.status === 200) {
            let dataUpdatedOrganization = [];

            response.data.forEach((data) => {
                let requestedItemOrganization = {};

                requestedItemOrganization.id = data.id_organizacao;
                requestedItemOrganization.label = data.nome;

                dataUpdatedOrganization.push(requestedItemOrganization);
            });

            setOrganizations(dataUpdatedOrganization)
        } else {
            Toast(
                "Falha, não foi carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    async function getCriticalitysList() {
        setPageStatus("requesting");

        const response = await APICase.getPriorityList(token);

        if (response.status === 200) {
            let dataUpdated = [];

            response.data.forEach((datum) => {
                let requestedItem = {};

                requestedItem.id = datum.id_criticidade;
                requestedItem.label = datum.descricao;

                dataUpdated.push(requestedItem);
            });

            setCriticalityList(dataUpdated);
        } else {
            Toast(
                "Falha, não foi carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    function getValues(id) {
        slaData?.forEach((item) => {
            if (item.id_sla === id) {
                setData({
                    ...item,
                    sla_inicio: convertHours(item.sla_inicio),
                    sla_resolucao: convertHours(item.sla_resolucao)
                });
                setId(item.id_sla);
            }
        })
    };

    function isFormValidated() {
        if (
            !data?.desc_sla ||
            !data?.id_organizacao ||
            !data?.id_criticidade ||
            !data?.sla_inicio ||
            !data?.sla_resolucao ||
            !data?.id_tipo_sla_inicio
        )
            return false;

        return true;
    };

    async function handleEditSla() {
        if (isFormValidated()) {
            setPageStatus("update-sla");

            const object = { ...data };

            if (document.getElementById("start_time")?.value) {
                let inputStartTime = document.getElementById("start_time")?.value;
                let toSeparate = inputStartTime?.split(":").toString();

                var result = toSeparate?.replace(',', ' ');
                var hour = result?.slice(0, 2);
                var minutes = result?.substring(3);
                var calculation = Number(hour) * 60;
                var resultCalculation = calculation + Number(minutes)

                object.sla_inicio = resultCalculation;
            }

            if (document.getElementById("deadline_resolution")?.value) {
                let inputDeadlineResolution = document.getElementById("deadline_resolution")?.value;
                let toSeparate = inputDeadlineResolution?.split(":").toString();

                var resultData = toSeparate?.replace(',', ' ');
                var hourResolution = resultData?.slice(0, 2);
                var minutesResolution = resultData?.substring(3);
                var calculationResolution = Number(hourResolution) * 60
                var resultCalculationResolution = calculationResolution + Number(minutesResolution)

                object.sla_resolucao = resultCalculationResolution;
            }

            const updateSla = {
                id_organizacao: data?.id_organizacao,
                id_criticidade: data?.id_criticidade,
                sla_inicio: object.sla_inicio,
                id_tipo_sla_inicio: data?.id_tipo_sla_inicio,
                sla_resolucao: object.sla_resolucao,
                id_tipo_sla_resolucao: data?.id_tipo_sla_inicio === 1 ?
                    object.id_tipo_sla_resolucao = 1 : object.id_tipo_sla_resolucao = 2,
                desc_sla: data?.desc_sla
            };

            const response = await APISla.updateSla(id, token, updateSla);

            if (response.status === 200) {
                Toast("SLA Atualizado!", "SLA atualizado com sucesso.", "success");
                onEdit();
                onCloseCustom();
            } else {
                Toast(
                    "Não foi possível atualizar o SLA",
                    "Tente novamente mais tarde!",
                    "error"
                )
            }

            setPageStatus("awaiting");
        } else if (
            !data?.desc_sla ||
            !data?.id_criticidade ||
            !data?.sla_inicio ||
            !data?.sla_resolucao ||
            !data?.id_tipo_sla_inicio
        ) {
            setValidationWarn(true);

            Toast(
                "Erro ao atualizar SLA!",
                "Verifique o preenchimento dos campos em destaque",
                "error",
            )
        }
    };

    //USE EFFECTS
    useEffect(() => {
        getCriticalitysList();
        getOrganizationsList();
        getListTypeSla();
    }, []);

    useEffect(() => {
        if (slaId) {
            getValues(slaId);
        }
    }, [slaId]);

    return (
        <Modal.Main
            className="modal-update-sla"
            title="Editar SLA"
            width="371px"
            height="650px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <div className="container-footer">
                    <Button
                        className="modal-update-sla__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "update-sla"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-update-sla__btn-confirm"
                        disabled={pageStatus === "update-sla"}
                        onClick={() => handleEditSla()}
                    >
                        {pageStatus !== "update-sla" ? (
                            <Typography.H4 weight={"bold"}>Salvar alterações</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <section className="modal-update-sla__content">
                <FieldContainer required title="Descrição da SLA">
                    <Input
                        type="text"
                        placeholder="Descrição da SLA"
                        value={data?.desc_sla}
                        onChange={(event) => setData({ ...data, desc_sla: event })}
                        error={validationWarn ? !data?.desc_sla : false}
                    />
                </FieldContainer>

                <FieldContainer required title="Organização">
                    <Select
                        placeholder="Organização"
                        value={data?.id_organizacao}
                        options={organizations}
                        onSelect={(company) => {
                            const object = { ...data };

                            object.id_organizacao = company.id
                            object.nome = company.label

                            setData(object);
                        }}
                        error={validationWarn ? !data?.id_organizacao : false}
                    />
                </FieldContainer>

                <FieldContainer required title="Tipo de SLA">
                    <Select
                        placeholder="Tipo de SLA"
                        value={data?.id_tipo_sla_inicio}
                        options={typeSla}
                        onSelect={(typeSla) => {
                            const object = { ...data };

                            object.id_tipo_sla_inicio = typeSla.id;
                            object.descricao = typeSla.label;

                            setData(object);
                        }}
                        error={validationWarn ? !data?.id_tipo_sla_inicio : false}
                    />
                </FieldContainer>

                <FieldContainer required title="Criticidade">
                    <Select
                        placeholder="Criticidade"
                        value={data?.id_criticidade}
                        options={criticalityList}
                        onSelect={(option) => {
                            const object = { ...data };

                            object.id_criticidade = option.id;
                            object.criticidade = option.label;

                            setData(object);
                        }}
                    />
                </FieldContainer>

                <FieldContainer required title="Início">
                    <Input
                        type="numberMask"
                        id="start_time"
                        placeholder="00:00"
                        mask={"99:99"}
                        value={data?.sla_inicio}
                        onChange={(event) => setData({ ...data, sla_inicio: event })}
                        error={validationWarn ? !data?.sla_inicio : false}
                    />
                </FieldContainer>

                <FieldContainer required title="Prazo para solução">
                    <Input
                        type="numberMask"
                        id="deadline_resolution"
                        placeholder="00:00"
                        mask={"99:99"}
                        value={data?.sla_resolucao}
                        onChange={(event) => setData({ ...data, sla_resolucao: event })}
                        error={validationWarn ? !data?.sla_resolucao : false}
                    />
                </FieldContainer>
            </section>
        </Modal.Main>
    )
}
