//GLOBAL - components from npm
import React, { useState, useRef } from "react";

//STYLES
import "./select.scss";

//COMPONENTS
import { Typography } from "../../../components/general";
import { Tippy } from "../../feedback";
import { Input } from "..";

// CUSTOM HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowDownIcon2 } from "../../../assets/icons/arrow-down-2.svg";
// import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";

export default function Select({
  placeholder = "Selecione uma opção",
  value,
  className,
  disabled,
  changeDownArrowIcon,
  options = [
    {
      id: 1,
      label: "Option1",
    },
    {
      id: 2,
      label: "Option2",
    },
    {
      id: 3,
      label: "Option3",
    },
  ],
  onSelect = () => null,
  width,
  upward,
  error,
  canBeEditable = true,
  uncheckTheOption,
  includeSearchField,
}) {
  //GENERAL
  const selectRef = useRef();

  //STATES
  // const [selectedItem, setSelectedItem] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  // CUSTOM HOOKS
  useOutsideClick(selectRef, () => {
    setIsOpen(false);
  });

  //REDUX - Selectors

  //FUNCTIONS
  // function renderButtonContent() {
  //   const currentOption = options.find(
  //     (opt) => opt.id === value || opt.label === value
  //   );

  //   if (value && currentOption) {
  //     return (
  //       <Tippy content={currentOption.label}>
  //         <div className="option-content">
  //           <Typography.H5
  //             color="gray-400"
  //             className={`option-labelResume ${!canBeEditable ? "max-width" : ""}`}
  //           >
  //             {/* {currentOption.label} */}
  //             {currentOption.label.length > 15
  //               ? currentOption.label.substring(0, 15) + "..."
  //               : currentOption.label}
  //           </Typography.H5>
  //           {canBeEditable && changeDownArrowIcon ? <ArrowDownIcon2 /> : <IconArrowDown />}
  //         </div>
  //       </Tippy>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <Typography.H5 color="gray-125" weight={400}>
  //           {placeholder}
  //         </Typography.H5>
  //         <div>
  //           {value.map((option) => {
  //             return <FilterTag label={option.name} />;
  //           })}
  //         </div>
  //         {canBeEditable && changeDownArrowIcon ? <ArrowDownIcon2 /> : <IconArrowDown />}
  //       </>
  //     );
  //   }
  // }

  function renderButtonContent() {
    const currentOption = options.find(option => option.id === value);
    const selectedLabel = currentOption ? currentOption.label : '';

    return (
      <>
        {currentOption && (
          <Tippy content={selectedLabel}>
            <div className="option-content">
              <Typography.H5 color="gray-400" className={!canBeEditable ? "max-width" : ""}>
                {selectedLabel.length > 15 ? `${selectedLabel.substring(0, 15)}...` : selectedLabel || placeholder}
              </Typography.H5>
              {canBeEditable && changeDownArrowIcon ? <ArrowDownIcon2 /> : <IconArrowDown />}
            </div>
          </Tippy>
        )}
        {!currentOption && (
          <div className="option-content">
            <Typography.H5 color="gray-125" className={!canBeEditable ? "max-width" : ""}>
              {placeholder}
            </Typography.H5>
            {canBeEditable && changeDownArrowIcon ? <ArrowDownIcon2 /> : <IconArrowDown />}
          </div>
        )}
      </>
    );
  };

  const listFilter = (item) =>
    inputSearch
      ? item.label.toLowerCase().includes(inputSearch.toLowerCase()) : true;

  const handleSelect = (option) => {
    if (option && (value === option.id || value === option.label)) {
      onSelect({})
    } else {
      onSelect(option)
    }
  };

  const handleOptionClick = (e, option) => {
    e.preventDefault();

    setIsOpen(!isOpen);
    setInputSearch("");

    if (uncheckTheOption) {
      handleSelect(option);
    } else {
      onSelect(option);
    }
  };

  const filteredOptions = options.filter(listFilter);

  //USE EFFECTS

  return (
    <div
      className={`select ${upward ? "select--upwards" : ""} 
      ${className ? className : ""}`}
      style={{ maxWidth: width ? width : "" }}
      ref={selectRef}
    >
      <button
        className={`select-btn  ${isOpen ? "active" : ""} 
        ${error ? "select-btn--error" : ""}
        ${disabled === true ? "select-btn--disabled" : ""}`}
        onClick={() => {
          if (disabled === true || !canBeEditable) {
            return null;
          } else {
            setIsOpen(!isOpen);
          }
        }}
      >
        {renderButtonContent()}
      </button>

      <div
        className={`select-list ${isOpen ? "active" : ""} ${error ? "select-list--error" : ""}`}
      >
        {includeSearchField && options.length > 6 && (
          <Input
            type="text"
            placeholder="Buscar"
            className="select-list__input"
            value={inputSearch}
            onChange={(e) => setInputSearch(e)}
          />
        )}

        {filteredOptions.length ? (
          filteredOptions.map((option, index) => (
            <Tippy content={option.label}>
              <button
                key={index}
                type="button"
                className={`select-list__item ${option.id === value || option.label === value ? "active" : ""}`}
                onClick={(e) => handleOptionClick(e, option)}
              >
                <Typography.H5 color="gray-200">{option.label}</Typography.H5>
              </button>
            </Tippy>
          ))
        ) : (
          <div className="select-list__empty">
            <Typography.H5 color="gray-200">
              Nenhum resultado encontrado
            </Typography.H5>
          </div>
        )}
      </div>
    </div >
  );
}
