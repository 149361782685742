//GLOBAL - components from npm
import React from "react";

//STYLES
import "./logo.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
// import { ReactComponent as SVGLogoExtended } from "./../../../assets/icons/Logo.svg";
import { ReactComponent as SVGLogoExtended } from "./../../../assets/icons/Logo_IngDeskNew.svg";
import { ReactComponent as SVGLogoIcon } from "./../../../assets/images/logo-icon.svg";
import { ReactComponent as SVGLogoIngSystem } from "./../../../assets/icons/Logo_IngSystems.svg";

export default function Logo({
  className = "",
  width = 165,
  height = 34,
  type = "extended",
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  switch (type) {
    case "extended":
      return (
        <SVGLogoExtended
          className={`${className}`}
          width={width}
          height={height}
        />
      );

    case "icon":
      return (
        <SVGLogoIcon className={`${className}`} width={width} height={height} />
      );

    case "iconSystems":
      return <SVGLogoIngSystem className={`${className}`} />;

    default:
      return "";
  }
}
