//GLOBAL - components from npm
import React, { useState } from "react";
import urlIngWeb from "../../../utils/env";
//import axios from 'axios';

//STYLES
import "./column-attach.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconTrash } from "../../../assets/icons/DeleteTwo.svg";
import { ReactComponent as IconClips } from "../../../assets/icons/Clips.svg";

export default function ColumnAttach({
  files,
  setFiles,
  limit = false,
  type = "insert",
  className,
  download,
  setDeleteFiles,
  deleteFiles,
}) {
  //GENERAL

  //STATES
  const [value, setValue] = useState(0);

  //REDUX - Selectors

  //FUNCTIONS
  const deleteFile = (indexFile, file) => {
    const arrayFiles = [...files];
    const filteredFiles = arrayFiles.filter(
      (file, index) => index !== indexFile + value
    );

    if (value > 0) {
      setValue((value) => value - 1);
    }

    setFiles(filteredFiles);

    const arrayDeleteFiles = deleteFiles;
    if (file.file.id) {
      arrayDeleteFiles.push(file.file.id);
      setDeleteFiles(arrayDeleteFiles);
    }
  };

  const onDownload = (file) => {
    fetch(`${urlIngWeb + file.file.path_arquivos}`).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = file.file.name;
        a.click();
      });
    });
  };

  return (
    <>
      {files.length > 0 ? (
        <div
          className={`${className} ${
            limit ? "column-attach--limit" : ""
          } column-attach`}
        >
          <div className="column-attach-list">
            {files.map((file, index) => (
              <Tippy content={file.file.name} key={index}>
                <div className="column-attach-list-file">
                  <div className="column-attach-list-file_text-icon">
                    <IconClips />
                    {download ? (
                      <button onClick={() => onDownload(file)}>
                        <Typography.H5
                          className="column-attach-list-file__text"
                          color="primary-dark"
                        >
                          {file.file.name}
                        </Typography.H5>
                      </button>
                    ) : (
                      <Typography.H5
                        className="column-attach-list-file__text"
                        color="primary-dark"
                      >
                        {file.file.name}
                      </Typography.H5>
                    )}
                  </div>
                  {type === "insert" && (
                    <IconTrash
                      className="column-attach-list-file__icon"
                      onClick={() => deleteFile(index, file)}
                    />
                  )}
                </div>
              </Tippy>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
