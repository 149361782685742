//GLOBAL - components from npm
import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashExhibColumn: [
      {
        title: "FCR",
        exhib: true,
      },
      {
        title: "Resolvidos",
        exhib: true,
      },
      {
        title: "Andamento",
        exhib: true,
      },
      {
        title: "Pendente",
        exhib: true,
      },
      {
        title: "Fora do Prazo",
        exhib: true,
      },
      {
        title: "No Prazo",
        exhib: true,
      },
    ],
    initialDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    finalDate: new Date(),
  },
  reducers: {
    setDashTableFilter(state, action) {
      state.dashExhibColumn = action.payload.value;
    },
    setDateFilter(state, action) {
      state[action.payload.to] = action.payload.value;
    },
  },
});

export const { setDashTableFilter, setDateFilter } = dashboardSlice.actions;
export default dashboardSlice.reducer;
