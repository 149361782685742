import React, { useEffect, useState } from "react";
import { Button, Typography } from "../../../general";
import { useSelector } from "react-redux";
import { Toast } from "../../../../utils/toast";

import { Modal } from "./../../../feedback";
import Loading from "../../Loading";

import * as APITypeSolicitation from "./../../../../services/api/typeSolicitation";

import { ReactComponent as IconTrash } from "../../../../assets/icons/Trash-3.svg";

import "./delete-type-solicitation.scss";

export default function DeleteTypeSolicitation({
    isOpen,
    onClose,
    typeManagement,
    organizationId,
    typesLoading,
    setTypesLoading,
    setShouldReloadTypes
}) {
    const token = useSelector((state) => state.auth.token);

    const idTypeSolicitation = isOpen || null;

    const [pageStatus, setPageStatus] = useState("awaiting");
    const [idSolicitation, setIdSolicitation] = useState();

    function onCloseCustom() {
        onClose();
    };

    async function getIdSolicitation(id_solicitation) {
        const response = await APITypeSolicitation.listTypesSolicitation(token);

        if (response.status === 200) {
            response.data.forEach((item) => {
                if (item.id_solicitacao === id_solicitation) {
                    setIdSolicitation(item.id_solicitacao);
                }
            })
        }
    };

    async function handleDeleteSolicitation() {
        setPageStatus("delete-solicitation");

        const response = await APITypeSolicitation.deleteSolicitationV2(
            Number(idSolicitation),
            organizationId && organizationId,
            token
        );

        if (response.status === 200) {
            if (typeManagement) {
                Toast(
                    "Tipo excluído!",
                    "Tipo excluído com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Solicitação excluída!",
                    "Solicitação excluída com sucesso.",
                    "success"
                );
            }

            setShouldReloadTypes(true);
            setTypesLoading(true);

            onCloseCustom();
        } else {
            Toast(
                `Falha, não foi possível deletar ${typeManagement ? "o Tipo!" : "a Solicitação!"}`,
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    useEffect(() => {
        if (idTypeSolicitation) {
            getIdSolicitation(idTypeSolicitation)
        }
    }, [idTypeSolicitation]);

    useEffect(() => {
        if (typesLoading) {
            const loading = setTimeout(() => {
                setTypesLoading(false);
            }, 2000);

            return () => clearTimeout(loading);
        }
    }, [typesLoading, setTypesLoading]);

    return (
        <Modal.Main
            className="modal-delete-solicitation"
            title={`Excluir ${typeManagement ? "tipo" : "tipo de solicitação"}`}
            titleColor={"black"}
            headerContainerStyle={{ alignItems: "center" }}
            width="443px"
            height="300px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            icon={
                <div className="modal-delete-solicitation__icon">
                    <IconTrash />
                </div>
            }
            footer={
                <div className="container-footer">
                    <Button
                        className="modal-delete-solicitation__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "delete-solicitation"}
                    >
                        <Typography.H4 weight={"bold"} color="blue-selected-4">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-delete-solicitation__btn-confirm"
                        disabled={pageStatus === "delete-solicitation"}
                        onClick={handleDeleteSolicitation}
                    >
                        {pageStatus !== "delete-solicitation" ? (
                            <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4
                color="gray-180"
                weight="400"
                className="information-text"
            >
                Você tem certeza que deseja excluir? Ao excluir todos os motivos e submotivos vinculados a este tipo, serão excluído e esta ação não poderá ser desfeita.
            </Typography.H4>
        </Modal.Main>
    )
}
