//GLOBAL - components from npm
import React from "react";
import { useHistory } from "react-router";

//STYLES
import "./popover-menu.scss";
import "tippy.js/dist/tippy.css";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function PopoverMenu({
  className,
  children,
  zIndex = 10,
  menuData = [
    {
      title: (
        <Typography.H5 color="primary-dark" weight="500">
          Item example - Route
        </Typography.H5>
      ),
      route: "/menu-example",
    },
    {
      title: (
        <Typography.H5 color="primary-dark" weight="500">
          Item example - onClick
        </Typography.H5>
      ),
      onClick: () => {
        console.log("Menu example 2 - On Click test");
      },
    },
  ],
  theme = "popover-default",
  maxWidth = "none",
  delay = [0, 0],
  trigger = "click",
  placement = "bottom",
  interactive = true,
  ...rest
}) {
  //GENERAL
  const history = useHistory();

  //STATES

  //REDUX - Selectors

  //FUNCTIONS
  const renderContent = (data) => {
    return (
      <ul className={`popover-menu-list ${className}`}>
        {data.map((menu, key) => {
          return (
            <li
              onClick={
                menu.onClick ? menu.onClick : () => history.push(menu.route)
              }
              className="popover-menu-list__item"
              key={key}
            >
              {menu.title}
            </li>
          );
        })}
      </ul>
    );
  };

  //USE EFFECTS

  return (
    <Tippy
      theme={theme}
      trigger={trigger}
      content={renderContent(menuData)}
      placement={placement}
      delay={delay}
      zIndex={zIndex}
      allowHTML
      interactive={interactive}
      {...rest}
    >
      {children}
    </Tippy>
  );
}
