//GLOBAL - components from npm
import React, { useState, useRef, useEffect, Fragment } from 'react';

//STYLES
import './fill-contact-search.scss'

//COMPONENTS
import { ImageProfile } from '../../data-display';
import { Typography } from '../../general';

// CUSTOM HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as IconSearch } from '../../../assets/icons/Search.svg';
import { ReactComponent as EmptyFolder } from '../../../assets/images/empty-folder.svg';
import { Loading } from '../../feedback';

export default function FillContactSearch({
  placeholder = 'Digite um titulo para buscar',
  value = undefined,
  width,
  className,
  icon,
  error = false,
  paginated = false,
  getOptions = () => null,
  showAvatar = true,
  onSelect = () => null,
  options = [
    {
      id: 1,
      label: "João Alves",
      caption1: "Ingenium",
      caption2: "#01232294101"
    },
    {
      id: 2,
      label: "João Costa",
      caption1: "Ingenium",
      caption2: "#01232294101"
    },
    {
      id: 3,
      label: "Lorenço Saraiva"
    },
    {
      id: 4,
      label: "Guilherme Gomes",
      caption1: "IngDesk",
    }
  ],
  emailList,
  setEmailList
}) {
  //GENERAL

  // REFS
  const selectSearchRef = useRef(null);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const sentinelRef = useRef(null);

  //STATES
  const [isOpen, setIsOpen] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [optionsInternal, setOptionsInternal] = useState(!paginated ? options : []);
  const [currentOptionData, setCurrentOptionData] = useState();
  const [currentOptionDisplay, setCurrentOptionDisplay] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [page, setPage] = useState(1);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSentinelVisible, setIsSentinelVisible] = useState(paginated);

  //REDUX - Selectors
  
  //FUNCTIONS
  async function updateOptions(title, page) {
    if (!paginated)
      return

    setIsRequesting(true);
    const data = await getOptions(title, page);

    if (data.length > 0) {
      setIsSentinelVisible(true);
    }

    if (page > 1) {
      setOptionsInternal(oldState => oldState.concat(data));
    }
    else {
      setOptionsInternal(data);
    }
    setIsRequesting(false);
  }

  function filterOptions(option) {
    if (paginated)
      return option

    if (currentOptionDisplay) {
      if (option.label.toLowerCase().includes(currentOptionDisplay.toLowerCase()))
        return option
      else
        return
    } else {
      return option
    }
  }

  function resetState(props = { options: false, listScrollPosition: false, currentOptionDisplay: false, page: false, searchTerm: false }) {
    props.options && updateOptions('', 1);
    props.listScrollPosition && listRef.current && listRef.current.scroll({ top: 0 })
    props.currentOptionDisplay && setCurrentOptionDisplay(currentOptionData ? currentOptionData.label : '');
    props.page && setPage(1);
    props.searchTerm && setSearchTerm('');
  }

  function onClickLabel() {
    if (!isOpen) {
      setIsOpen(true);
      setCurrentOptionDisplay('');
    }

    //inputRef.current.focus();
  }

  function onClickOption(option) {
    setCurrentOptionDisplay(option.label);
    setCurrentOptionData(option);
    onSelect(option);

    setIsOpen(false);
    resetState({
      options: true,
      listScrollPosition: true,
      page: page > 1,
      searchTerm: true
    });
    setInputEmail("");
  }

  function observerCallback(entries) {
    if (isOpen && entries[0].isIntersecting) {
      setIsSentinelVisible(false);
      updateOptions(searchTerm, page + 1);
      setPage(page + 1);
    }
  }

  // // CUSTOM HOOKS
  useOutsideClick(selectSearchRef, () => {
    if (isOpen) {
      setIsOpen(false);
      resetState({
        options: true,
        listScrollPosition: true,
        currentOptionDisplay: true,
        searchTerm: true
      });

      if (page !== 1)
        setPage(1);
    }
  });

  // //USE EFFECT
  useEffect(() => {
    updateOptions(currentOptionDisplay, page);
  }, [])

  useEffect(() => {
    if(!paginated) {
      setOptionsInternal(options)
    }
  }, [options])

  useEffect(() => {
    if (value && value.id !== currentOptionData?.id) {
      let valueObject;

      if (value?.id || paginated) {
        valueObject = value;
      } else {
        valueObject = options.find(opt => opt.id === value);
      }

      setCurrentOptionData(valueObject);
      setCurrentOptionDisplay(valueObject?.label);
    }
  }, [value])

  useEffect(() => {
    if (paginated && isOpen && sentinelRef.current) {
      let options = {
        root: listRef.current,
        rootMargin: '0px',
        threshold: 0.1
      }

      let observer = new IntersectionObserver(observerCallback, options);
      observer.observe(sentinelRef.current);

      return () => observer.disconnect();
    }
  }, [sentinelRef.current, isOpen, isRequesting])

  useEffect(() => {
    if(inputEmail.length > 1 && isOpen === true){
      resetState({
        options: paginated,
        listScrollPosition: true,
        page: paginated
      })

      updateOptions(inputEmail, 1);
      setSearchTerm(inputEmail);
    }

    if(inputEmail.length >= 1 && isOpen === false){
      onClickLabel();
      resetState({
        options: paginated,
        listScrollPosition: true,
        page: paginated
      })

      updateOptions(inputEmail, 1);
      setSearchTerm(inputEmail);
    }

    if(inputEmail === "" && isOpen === true){
      setIsOpen(false);
    }

  }, [inputEmail])

  const changeInputSearch = (e) => {
    setInputEmail(e.target.value)
    console.log("E#$3", e)
    // console.log("E#$3", e)
  }

  return (
    <>
    <input
      type="text"
      className="fill-contact-input"
      value={inputEmail}
      onChange={(e) => changeInputSearch(e)}
      onKeyPress={event => {
        if ((event.code === 'Enter' || event.code === 'NumpadEnter')) {
          if(inputEmail !== ""){
            setEmailList([
              ...emailList,
              { label: inputEmail, caption1: inputEmail },
            ]);
            setInputEmail("");
          }
        }
      }}
    />
    <div
      ref={selectSearchRef}
      className={`fill-contact-search ${className ? className : ''} ${isOpen ? 'fill-contact-search--active' : ''} ${error ? 'fill-contact-search--error' : ''}`}
      style={{ width: width ? width : "" }}
      // onClick={() => onClickLabel()}
    >
      <div className='fill-contact-search-list' ref={listRef}>
        {
          optionsInternal.filter(filterOptions)?.length > 0
            ?
            <>
                    {/* <Input
                      rightIcon={<IconSearch />}
                      type="text"
                      placeholder={placeholder}
                      autoComplete="on"
                      value={currentOptionDisplay}
                      onChange={(event) => {
                        setCurrentOptionDisplay(event);
          
                        if (paginated && event.length === 0) {
                          resetState({
                            options: true,
                            page: true,
                            searchTerm: true,
                            listScrollPosition: true,
                          })
                        }
                      }}
                      onKeyPress={event => {
                        if ((event.code === 'Enter' || event.code === 'NumpadEnter') && (event.target.value !== searchTerm)) {
                          resetState({
                            options: paginated,
                            listScrollPosition: true,
                            page: paginated
                          })
          
                          updateOptions(inputEmail, 1);
                          setSearchTerm(inputEmail);
                        }
                      }}
                    /> */}
                    <div className='fill-contact-search-list__list'>
                        {
                            optionsInternal.filter(filterOptions).map((opt, index) =>
                            <button
                                key={`fill-contact-search-${index}`}
                                type='button'
                                className={`fill-contact-search-list__item `}
                                onClick={(event) => {
                                event.stopPropagation();
                                onClickOption(opt);
                                }}
                            >
                                {
                                showAvatar &&
                                <ImageProfile
                                    profile={{
                                    nome_usuario: opt.label,
                                    nome_organizacao: opt.caption1
                                    }}
                                    tooltip={false}
                                />
                                }

                                <div className='item-info'>
                                <Typography.Paragraph weight='bold' color='gray-400' className='item-info__label'>{opt.label ? opt.label : 'Title'}</Typography.Paragraph>
                                {opt.caption1 && <Typography.Paragraph color='gray-400' className='item-info__caption'>{opt.caption1}</Typography.Paragraph>}
                                {opt.caption2 && <Typography.Paragraph color='gray-400' className='item-info__caption'>{opt.caption2}</Typography.Paragraph>}
                                </div>
                            </button>
                            )
                        }
                        {
                            (isOpen && paginated && isSentinelVisible) &&
                            <div className='fill-contact-search-list__sentinel' ref={sentinelRef}></div>
                        }
                        {
                            (isOpen && paginated && isRequesting) &&
                            <div className='loading-container'>
                            <Loading.Dots width={50} height={50} />
                            </div>
                        }
                    </div>
            </>
            :
            <div className='fill-contact-search-list__empty'>
              <EmptyFolder />
              <Typography.H6>Não encontramos resultados.</Typography.H6>
            </div>
        }
      </div>
    </div>
    </>
  )
}
