//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

// UTILS
import { Toast } from "../../../../utils/toast";
import renderPhone from "../../../../utils/renderPhoneFormat";

//STYLES
import "./filters-dashboard.scss";

//COMPONENTS
import { Drawer } from "../..";
import {
  SelectSearch,
  SelectSearchTag,
  Select,
  ProfileList,
  SelectTag,
  Input,
  DatePicker,
} from "../../../data-entry";
import { FieldContainer, FilterTag } from "../../../data-display";
import {
  DropdownPriority,
  DropdownChannel,
  DropdownEscalate,
} from "../../../layout";
import { Button, Typography } from "../../../general";

//SERVICES - api, conectors...
import * as APITask from "../../../../services/api/task";
import * as APICase from "../../../../services/api/case";
import * as APIClient from "../../../../services/api/client";
import * as APICompany from "../../../../services/api/company";

//GLOBAL STATE - redux, env...
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setStorageFilter } from "../../../../store/filters";

//ASSETS - icons, images...
import { ReactComponent as IconAddUser } from "../../../../assets/icons/AddUser2.svg";
import { ReactComponent as IconCalendar } from "../../../../assets/icons/Calendar.svg";
import { ReactComponent as IconCaso } from "../../../../assets/icons/Caso.svg";
import { ReactComponent as IconGoBack } from "../../../../assets/icons/GoBack.svg";
import { ReactComponent as CleanIcon } from "../../../../assets/icons/Clean.svg";

export default function FiltersDashboard({
  screen,
  dataAdditionalFields = [],
  ...others
}) {
  //GENERAL
  const finalDateDefault = new Date();
  const initialDateDefault = new Date(
    new Date().setDate(new Date().getDate() - 30)
  );
  const token = useSelector((state) => state.auth.token);
  const filtersDashboard = useSelector(
    (state) => state.filters.filtersDashboard
  );
  const filtersContacts = useSelector((state) => state.filters.contacts);
  const user = useSelector((state) => state.auth.userData);

  //STATES
  const [initialDate, setInitialDate] = useState(filtersDashboard.initialDate);
  const [finalDate, setFinalDate] = useState(filtersDashboard.finalDate);
  const [allProfiles, setAllProfiles] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [controlActive, setControlActive] = useState(false);
  const [listRequest, setListRequest] = useState([]);
  const [clientSelected, setClientSelected] = useState(
    filtersDashboard.clients
  );
  const [companySelected, setCompanySelected] = useState(
    filtersDashboard.companys
  );
  const [requestedSelected, setRequestedSelected] = useState(
    filtersDashboard.requesteds
  );
  const [profiles, setProfiles] = useState(filtersDashboard.agents);
  const [prioritySelected, setPrioritySelected] = useState(
    filtersDashboard.prioritys
  );
  const [channelSelected, setChannelSelected] = useState(
    filtersDashboard.channels
  );
  const [organizationSelected, setOrganizationSelected] = useState(
    filtersDashboard.organizations
  );
  const [organizationsList, setOrganizationsList] = useState([]);

  //REDUX - Selectors
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.filters.darkMode);

  //FUNCTIONS

  const getProfiles = async () => {
    const response = await APITask.listUsers(token);

    const data = response?.data;

    setAllProfiles(data);
  };

  async function getChannelList() {
    const response = await APICase.getChannelList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_canal;
        requestItem.label = datum.nome;
        requestItem.ativo = datum.ativo;
        requestItem.dt_exclusao = datum.dt_exclusao;

        dataUpdated.push(requestItem);
      });
      setChannelList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  //USE EFFECTS
  useEffect(() => {
    getListOrganizations();
    getListRequest();
    getProfiles();
    getChannelList();
  }, []);

  // useEffect(() => {
  //   if (initialDate !== others.valueDateInitial) {
  //     setInitialDate(others.valueDateInitial);
  //   }
  //   if (finalDate !== others.valueDateFinal) {
  //     setFinalDate(others.valueDateFinal);
  //   }
  // }, [finalDate, initialDate, others.valueDateFinal, others.valueDateInitial]);

  useEffect(() => {
    setClientSelected(filtersDashboard.clients);
    setCompanySelected(filtersDashboard.companys);
    setRequestedSelected(filtersDashboard.requesteds);
    setProfiles(filtersDashboard.agents);
    setPrioritySelected(filtersDashboard.prioritys);
    setChannelSelected(filtersDashboard.channels);
    setOrganizationSelected(filtersDashboard.organizations);
    setInitialDate(filtersDashboard.initialDate);
    setFinalDate(filtersDashboard.finalDate);

    window.localStorage.setItem(
      "dashboardFilters",
      JSON.stringify(filtersDashboard)
    );
  }, [filtersDashboard]);

  useEffect(() => {
    if (
      clientSelected.length === 0 &&
      companySelected.length === 0 &&
      requestedSelected.length === 0 &&
      profiles.length === 0 &&
      prioritySelected.length === 0 &&
      channelSelected.length === 0 &&
      organizationSelected.length === 0 &&
      initialDate.getDate() === initialDateDefault.getDate() &&
      finalDate.getDate() === finalDateDefault.getDate()
    ) {
      setControlActive(false);
    } else {
      setControlActive(true);
    }
  }, [
    clientSelected.length,
    companySelected.length,
    requestedSelected.length,
    profiles.length,
    prioritySelected.length,
    channelSelected.length,
    organizationSelected.length,
    initialDate,
    finalDate,
  ]);

  //Função que busca a lista de Contatos na API e transforma no modelo padrão dos componentes.
  async function getListClients(searchParams, page) {
    const response = await APIClient.all(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const formatTelephones = client.telefone.split(",");
      const formatedTelephones = formatTelephones.map((telephone) =>
        renderPhone(telephone)
      );

      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.nome_empresa,
        caption2: client.codigo_externo && `Cód. Ext: ${client.codigo_externo}`,
        caption3: client.cpf && `CPF: ${client.cpf}`,
        caption4:
          formatTelephones.length > 1
            ? formatedTelephones.join(", ")
            : renderPhone(formatTelephones[0]),
        caption5: client.email,
      };

      updatedData.push(updatedClient);
    });

    return updatedData;
  }

  //Função que busca a lista de Empresas na API e transforma no modelo padrão dos componentes.
  async function getListCompanys(searchParams, page) {
    // const response = await APICompany.all(token, page, searchParams, 50);
    const response = await APICompany.newListAllCompanysUsers(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((company) => {
      const updatedCompany = {
        id: company.id_empresa,
        label: company.nome,
        caption1: company.cnpj,
        caption2: company.nome_fantasia,
      };

      updatedData.push(updatedCompany);
    });

    return updatedData;
  }

  //Função que busca a lista de solicitações na API e transforma no modelo padrão dos componentes.
  const getListRequest = async () => {
    const response = await APICase.getRequestList(token, user.userId);

    if (response.status === 200) {
      const data = response.data;
      const updatedData = [];

      data.forEach((datum) => {
        let updatedDatum = {};

        updatedDatum.id = datum.id_solicitacao;
        updatedDatum.label = datum.descricao_solicitacao;

        updatedData.push(updatedDatum);
      });

      setListRequest(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que busca a lista de organizações e transforma no modelo padrão dos componentes.
  function getListOrganizations() {
    if (user.organizationsView) {
      const data = JSON.parse(user.organizationsView);
      let dataUpdated = [];

      data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.idorganizacao;
        requestItem.label = datum.nomeorganizacao;

        dataUpdated.push(requestItem);
      });

      setOrganizationsList(dataUpdated);
    }
  }

  const removeOptionRequested = (idRemoved) => {
    let elements = arrayRemove(requestedSelected, idRemoved);
    setRequestedSelected(elements);
  };

  const removeOptionClient = (idRemoved) => {
    let elements = arrayRemove(clientSelected, idRemoved);
    setClientSelected(elements);
  };

  const changeOptionsRequest = (option) => {
    const arrayFound = requestedSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionRequested(arrayFound.id);
    } else {
      setRequestedSelected([
        ...requestedSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsClient = (option) => {
    const arrayFound = clientSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionClient(arrayFound.id);
    } else {
      setClientSelected([
        ...clientSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsPriority = (option) => {
    const arrayFound = prioritySelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionPriority(arrayFound.id);
    } else {
      setPrioritySelected([
        ...prioritySelected,
        { id: option.id, label: option.label },
      ]);
    }
  };

  const changeOptionsChannel = (option) => {
    const arrayFound = channelSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionChannel(arrayFound.id);
    } else {
      setChannelSelected([
        ...channelSelected,
        { id: option.id, label: option.label },
      ]);
    }
  };

  const changeOptionsCompany = (option) => {
    const arrayFound = companySelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionCompany(arrayFound.id);
    } else {
      setCompanySelected([
        ...companySelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsOrganization = (option) => {
    const arrayFound = organizationSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionOrganization(arrayFound.id);
    } else {
      setOrganizationSelected([
        ...organizationSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const removeOptionCompany = (idRemoved) => {
    let elements = arrayRemove(companySelected, idRemoved);
    setCompanySelected(elements);
  };

  const removeOptionPriority = (idRemoved) => {
    let elements = arrayRemove(prioritySelected, idRemoved);
    setPrioritySelected(elements);
  };

  const removeOptionChannel = (idRemoved) => {
    let elements = arrayRemove(channelSelected, idRemoved);
    setChannelSelected(elements);
  };

  const removeOptionOrganization = (idRemoved) => {
    let elements = arrayRemove(organizationSelected, idRemoved);
    setOrganizationSelected(elements);
  };

  function arrayRemove(arr, value) {
    return arr.filter(function (elem) {
      return elem.id != value;
    });
  }

  const getCasesFilterAdvanceds = () => {
    dispatch(
      setFilter({ to: "filtersDashboard", type: "active", value: true })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "clients",
        value: clientSelected,
      })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "companys",
        value: companySelected,
      })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "requesteds",
        value: requestedSelected,
      })
    );
    dispatch(
      setFilter({ to: "filtersDashboard", type: "agents", value: profiles })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "prioritys",
        value: prioritySelected,
      })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "channels",
        value: channelSelected,
      })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "organizations",
        value: organizationSelected,
      })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "initialDate",
        value: initialDate,
      })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "finalDate",
        value: finalDate,
      })
    );

    others.onClose(false);
  };

  const cleanFiltersAdvanceds = () => {
    dispatch(
      setFilter({ to: "filtersDashboard", type: "active", value: false })
    );
    dispatch(setFilter({ to: "filtersDashboard", type: "clients", value: [] }));
    dispatch(
      setFilter({ to: "filtersDashboard", type: "companys", value: [] })
    );
    dispatch(
      setFilter({ to: "filtersDashboard", type: "requesteds", value: [] })
    );
    dispatch(setFilter({ to: "filtersDashboard", type: "agents", value: [] }));
    dispatch(
      setFilter({ to: "filtersDashboard", type: "prioritys", value: [] })
    );
    dispatch(
      setFilter({ to: "filtersDashboard", type: "channels", value: [] })
    );
    dispatch(
      setFilter({ to: "filtersDashboard", type: "organizations", value: [] })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "initialDate",
        value: initialDateDefault,
      })
    );
    dispatch(
      setFilter({
        to: "filtersDashboard",
        type: "finalDate",
        value: finalDateDefault,
      })
    );

    others.onClose(false);
  };

  const cleanFilters = () => {
    cleanFiltersAdvanceds();
    setClientSelected([]);
    setCompanySelected([]);
    setRequestedSelected([]);
    setProfiles([]);
    setPrioritySelected([]);
    setChannelSelected([]);
    setOrganizationSelected([]);
    setInitialDate(initialDateDefault);
    setFinalDate(finalDateDefault);
  };

  return (
    <Drawer.Main title="Filtros" darkMode={darkMode} {...others}>
      <div
        className={`filters-dashboard-container ${darkMode ? "dark-mode" : ""}`}
      >
        <div className="filters-dashboard-container__date-range-container">
          <FieldContainer title="De:" className="case-client" htmlFor="">
            <DatePicker.DateRangeInput
              className={darkMode ? "dark-mode" : ""}
              selectRangeType="start"
              value={initialDate}
              setDate={(event) => {
                const newDate = event;
                setInitialDate(newDate);

                others.refInitialDate.current = true;
              }}
              ref={others.refInitialDate}
              endDate={finalDate}
              isClearable={false}
            />
          </FieldContainer>

          <Typography.H5
            className={"dot-dates"}
            color={darkMode ? "white" : "blue-selected-4"}
          >
            •
          </Typography.H5>

          <FieldContainer title="Até:" className="case-client" htmlFor="">
            <DatePicker.DateRangeInput
              className={darkMode ? "dark-mode" : ""}
              value={finalDate}
              setDate={(event) => {
                const newDate = event;
                setFinalDate(newDate);

                others.refFinalDate.current = true;
              }}
              selectRangeType="end"
              ref={others.refFinalDate}
              startDate={initialDate}
              isClearable={false}
            />
          </FieldContainer>
        </div>
        <div className="filters-dashboard">
          <FieldContainer
            title="Contato, CPF ou Código Externo:"
            className="case-client"
            htmlFor=""
          >
            <div className="filters-dashboard-container-tag">
              <SelectSearchTag
                paginated
                width="100%"
                placeholder="Busque pelo nome do contato"
                getOptions={getListClients}
                value={clientSelected}
                onSelect={(client) => {
                  changeOptionsClient(client);
                }}
                onClose={removeOptionClient}
              />
            </div>
          </FieldContainer>
          <FieldContainer title="Empresa ou CNPJ:">
            <div className="filters-dashboard-container-tag">
              <SelectSearchTag
                paginated
                width="100%"
                placeholder="Buscar"
                getOptions={getListCompanys}
                value={companySelected}
                onSelect={(company) => {
                  changeOptionsCompany(company);
                }}
              />
            </div>
          </FieldContainer>
          {organizationsList.length > 0 && (
            <FieldContainer title="Organização">
              <SelectTag
                valueTag={organizationSelected}
                options={organizationsList}
                onSelect={(option) => {
                  changeOptionsOrganization(option);
                }}
              />
            </FieldContainer>
          )}
          <FieldContainer title="Tipo de Solicitação:" htmlFor="">
            <div className="filters-dashboard-container-tag">
              <SelectTag
                valueTag={requestedSelected}
                options={listRequest}
                onSelect={(option) => {
                  changeOptionsRequest(option);
                }}
              />
            </div>
          </FieldContainer>

          <div className="filters-dashboard__divider"></div>
          <FieldContainer title="Agentes:" htmlFor="">
            <div className="filters-dashboard-agents">
              <IconAddUser />
              <ProfileList
                buttonTooltip
                profiles={profiles}
                allProfileList={allProfiles}
                setProfiles={setProfiles}
                type="add"
              />
            </div>
          </FieldContainer>
          <div className="filters-dashboard__divider"></div>

          <FieldContainer title="Prioridade:">
            <SelectTag
              valueTag={prioritySelected}
              priority
              onSelect={(option) => {
                changeOptionsPriority(option);
              }}
            />
          </FieldContainer>

          <FieldContainer title="Canais Originários:">
            <SelectTag
              valueTag={channelSelected}
              channel
              options={channelList}
              onSelect={(option) => {
                changeOptionsChannel(option);
              }}
            />
          </FieldContainer>
        </div>
        <div className="filters-dashboard__buttons">
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              cursor: `${!controlActive ? "default" : "pointer"}`,
            }}
            onClick={controlActive && cleanFilters}
          >
            <CleanIcon
              className={`${!controlActive ? "disabled-icon" : "clean-icon"}`}
            />
            <div>
              <Typography.H5
                weight="400"
                color={`${!controlActive ? "gray-125" : "red-2"}`}
              >
                Limpar Filtros
              </Typography.H5>
            </div>
          </div>
          <Button
            className={"buttons-aplyfilters"}
            onClick={getCasesFilterAdvanceds}
          >
            Aplicar Filtros
          </Button>
        </div>
      </div>
    </Drawer.Main>
  );
}
