//GLOBAL - components from npm
import React from "react";

//STYLES
import "./image-profile.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import Contact from "../../../assets/icons/history-icons/HistoricContact.svg";
import AddMotive from "../../../assets/icons/history-icons/HistoricAddMotive.svg";
import ChangeMotive from "../../../assets/icons/history-icons/HistoricChangeMotive.svg";
import Status from "../../../assets/icons/history-icons/HistoricStatus.svg";
import Solicitation from "../../../assets/icons/history-icons/HistoricSolicitation.svg";
import Priority from "../../../assets/icons/history-icons/HistoricPriority.svg";
import Deadline from "../../../assets/icons/history-icons/HistoricDeadline.svg";
import Subject from "../../../assets/icons/history-icons/HistoricSubject.svg";
import Description from "../../../assets/icons/history-icons/HistoricDescription.svg";
import LinkedTask from "../../../assets/icons/history-icons/HistoricLinkedTask.svg";
import TextComment from "../../../assets/icons/history-icons/HistoricTextComment.svg";
import UnlinkedTask from "../../../assets/icons/history-icons/HistoricUnlinkedTask.svg";
import Origin from "../../../assets/icons/history-icons/HistoricOrigin.svg";
import AudioComment from "../../../assets/icons/history-icons/HistoricAudioComment.svg";
import Attach from "../../../assets/icons/history-icons/HistoricAttach.svg";
import ProfileUser from "../../../assets/icons/history-icons/HistoricUser.svg";

export default function ImageProfile({
  profile = {
    nome_usuario: "User name",
    nome_organizacao: "Company name",
  },
  iconHistory,
  iconChange,
  className,
  iconClassName,
  changeName,
  theName,
  tooltip = true,
  client = false,
}) {
  // Icons functions
  let pathIcons = "";

  //GENERAL
  // switch (iconHistory) {

  //   case "user":
  //     pathIcons = ProfileUser;
  //     break;

  //   default:
  //     iconHistory = false;
  //     break;
  // }

  //STATES

  //REDUX - Selectors

  //FUNCTIONS
  var name = profile.nome_usuario?.split(".").toString();
  var resultName = name?.replace(",", " ");

  function renderProfileName(fullname) {
    fullname = fullname?.replace(/\s(de|da|dos|das)\s/g, " ");
    var start = fullname?.match(/\b(\w)/gi);
    var name = fullname?.slice(0, 1).toUpperCase();
    var surname = start
      ?.splice(1, start.length - 1)
      .join("")
      .toUpperCase();

    return name + surname;
  }

  function renderImageProfile() {
    if (tooltip)
      return (
        <>
          <Tippy
            customContent={
              <>
                {changeName ? (
                  <Typography.H5>
                    {theName && `${theName}: ${profile.nome_usuario}`}
                  </Typography.H5>
                ) : (
                  <Typography.H5>
                    {client && `Cliente: ${profile.nome_usuario}`}
                    {!client && `Agente: ${profile.nome_usuario}`}
                  </Typography.H5>
                )}

                {profile.nome_organizacao && (
                  <Typography.H5>
                    Empresa: {profile.nome_organizacao}
                  </Typography.H5>
                )}
              </>
            }
          >
            {profile.image ? (
              <img
                alt="imagem de perfil"
                src={profile.image}
                className={`image-profile__img ${className}`}
              />
            ) : (
              <div className={`image-profile ${className}`}>
                {iconHistory === "user" ? (
                  <img
                    src={ProfileUser}
                    alt="Icone do historico"
                    className={`icon-history-color ${iconClassName}`}
                  />
                ) : (
                  <Typography.H5 weight="bold" color="white">
                    {renderProfileName(resultName)}
                  </Typography.H5>
                )}
              </div>
            )}
          </Tippy>
        </>
      );
    else
      return (
        <>
          {profile.image ? (
            <img
              alt="imagem de perfil"
              src={profile.image}
              className={`image-profile__img ${className}`}
            />
          ) : (
            <div className={`image-profile ${className}`}>
              {iconHistory === "user" ? (
                <img
                  src={ProfileUser}
                  alt="Icone do historico"
                  className={`icon-history-color ${iconClassName}`}
                />
              ) : (
                <Typography.H4 weight="bold" color="white">
                  {profile.nome_usuario?.slice(0, 1)}
                </Typography.H4>
              )}
            </div>
          )}
        </>
      );
  }

  //USE EFFECTS

  return renderImageProfile();
}
