//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import MaskedInput from "react-input-mask";
import { useSelector } from "react-redux";
import { format, isValid, parse } from "date-fns";
import { cloneDeep } from "lodash";
import Switch from "react-switch";


//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { Loading } from "../../../../components/feedback";
import { FieldContainer } from "../../../../components/data-display";
import { Toast } from "../../../../utils/toast";
import { Input, Select } from "../../../../components/data-entry";

//SERVICES - api, conectors...
import * as APISla from "../../../../services/api/sla";
import * as APIOrganization from "../../../../services/api/organization";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Trash-3.svg";
import { ReactComponent as AddCircleIcon } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as MinusCircleIcon } from "./../../../../assets/icons/minus-cirlce.svg";
import { ReactComponent as IconError } from "./../../../../assets/icons/error.svg";

//STYLES
import "./create-organization.scss";

export default function CreateOrganization({ toGoBack, onCreate }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [shiftWorkersHourAssociation, setShiftWorkersHourAssociation] = useState(false);
    const [validationWarn, setValidationWarn] = useState(false);
    const [isStartHourBigger, setIsStartHourBigger] = useState(false);
    const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
    const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);
    const [basicData, setBasicData] = useState(() => {
        return {
            nome_organizacao: "",
            codigo_organizacao: ""
        }
    });
    const [slaAssociations, setSlaAssociations] = useState(() => {
        return [{
            sla_id: null,
            days: [
                {
                    id_dayWeek: null,
                    start_time: "",
                    end_time: ""
                }
            ]
        }]
    });
    const [slaOptions, setSlaOption] = useState([]);
    const [daysWeek, setDaysWeek] = useState([]);
    const [errors, setErrors] = useState([{
        start_time: "",
        end_time: "",
    }]);

    //FUNCTIONS
    const allSlaAssociations = () => {
        const allAssociated = slaOptions.filter(sla => {
            return !slaAssociations.some(item => item.sla_id === sla.id);
        }).length === 0;

        return allAssociated;
    };

    const allDaysWeek = (slaIndex) => {
        const allAssociated = daysWeek.filter(day => {
            return !slaAssociations[slaIndex].days.some(item => item.id_dayWeek === day.id);
        }).length === 0;

        return allAssociated;
    };

    const handleAddSla = () => {
        setSlaAssociations(prevState => [
            ...prevState,
            {
                sla_id: null,
                days: [
                    {
                        id_dayWeek: null,
                        start_time: "",
                        end_time: ""
                    }
                ]
            }
        ]);
    };

    const handleRemoveSla = (slaIndex) => {
        setSlaAssociations(prevState => prevState.filter((_, index) => index !== slaIndex));
    };

    const handleAddDayWeek = (slaIndex) => {
        setSlaAssociations(prevState => prevState.map((item, index) => {
            if (index === slaIndex) {
                return {
                    ...item,
                    days: [
                        ...item.days,
                        {
                            id_dayWeek: null,
                            start_time: "",
                            end_time: ""
                        }
                    ]
                };
            }
            return item;
        }));
    };

    const handleRemoveDayWeek = (slaIndex, dayIndex) => {
        setSlaAssociations(prevState => prevState.map((item, index) => {
            if (index === slaIndex) {
                return {
                    ...item,
                    days: item.days.filter((_, i) => i !== dayIndex)
                };
            }
            return item;
        }));
    };

    const handleStartTimeChange = (slaIndex, dayIndex, e) => {
        const value = e;

        const updatedShiftWorkerData = [...slaAssociations];

        updatedShiftWorkerData[slaIndex].days[dayIndex].start_time = value;

        setSlaAssociations(updatedShiftWorkerData);
    };

    const handleEndTimeChange = (slaIndex, dayIndex, e) => {
        const value = e;

        const updatedShiftWorkerData = [...slaAssociations];

        updatedShiftWorkerData[slaIndex].days[dayIndex].end_time = value;

        setSlaAssociations(updatedShiftWorkerData);
    };

    const handleBlur = (slaIndex, dayIndex) => {
        const startTime = slaAssociations[slaIndex].days[dayIndex].start_time;
        const endTime = slaAssociations[slaIndex].days[dayIndex].end_time;
        const parsedStartTime = startTime ? parse(startTime, "HH:mm", new Date()) : null;
        const parsedEndTime = endTime ? parse(endTime, "HH:mm", new Date()) : null;

        let startError = "";
        let endError = "";

        if (startTime && !isValid(parsedStartTime)) {
            startError = "Hora inicial inválida";

            setIsInvalidStartTime(true);
        } else {
            setIsInvalidStartTime(false);
        }

        if (endTime && !isValid(parsedEndTime)) {
            endError = "Hora final inválida";

            setIsInvalidEndTime(true);
        } else {
            setIsInvalidEndTime(false);
        }

        if (!startError && !endError && parsedStartTime && parsedEndTime && parsedStartTime > parsedEndTime) {
            Toast(
                "Atenção!",
                "Hora inicial não pode ser maior que hora final.",
                "warn",
            );

            setIsStartHourBigger(true);
        } else {
            setIsStartHourBigger(false);
        }

        const updatedErrors = [...errors];

        updatedErrors[slaIndex] = updatedErrors[slaIndex] || {};
        updatedErrors[slaIndex].days = updatedErrors[slaIndex].days || [];
        updatedErrors[slaIndex].days[dayIndex] = {
            start_time: startError,
            end_time: endError
        };

        setErrors(updatedErrors);
    };

    function isFieldsValid() {
        if (!basicData.nome_organizacao) {
            return false
        }

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao adicionar organização!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    async function handleCreateOrganization() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        } else if (
            isStartHourBigger ||
            isInvalidStartTime ||
            isInvalidEndTime
        ) return;

        let organizationCreated;
        let hasError = false;

        try {
            setPageStatus("creating-organization");

            const response = await APIOrganization.createOrganization(token, basicData);

            if (response.status === 200) {
                organizationCreated = response.data.Dados[0].id_organizacao;
            } else {
                hasError = true;
            }

            await Promise.all(slaAssociations.map(async (slaData) => {
                await Promise.all(slaData.days.map(async (day) => {
                    if (slaData.sla_id && day.id_dayWeek && day.start_time && day.end_time) {
                        let startTime = day.start_time;
                        let endTime = day.end_time;

                        if (typeof startTime === 'string' && typeof endTime === 'string') {
                            const startTimeParts = startTime.split(':');
                            const endTimeParts = endTime.split(':');

                            startTime = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1]);
                            endTime = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1]);

                            startTime = format(startTime, 'HH:mm:ss');
                            endTime = format(endTime, 'HH:mm:ss');
                        }

                        const data = {
                            idsla: slaData.sla_id,
                            dia: day.id_dayWeek,
                            hora_inicio: startTime,
                            hora_fim: endTime,
                            id_organizacao: organizationCreated
                        };

                        const slaResponse = await APIOrganization.createSlaForOrganization(token, data);

                        if (slaResponse.status !== 200) {
                            hasError = true;
                        }
                    }
                }))
            }))

            if (hasError) {
                Toast(
                    "Não foi possível adicionar a organização!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );

                throw new Error("Alguma operação falhou");
            } else {
                Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");

                onCreate();
                toGoBack();
                setPageStatus("awaiting");
            }

        } catch (error) {
            console.error("Erro ao criar organização:", error);

            Toast(
                "Erro ao criar organização!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        }
    };

    async function getSlaList() {
        const response = await APISla.listSla(token);

        if (response.status === 200) {
            let dataUpdatedSla = [];

            response.data.forEach((item) => {
                let requestedItemSla = {};

                requestedItemSla.id = item.id_sla;
                requestedItemSla.label = item.desc_sla;

                dataUpdatedSla.push(requestedItemSla);
            });

            setSlaOption(dataUpdatedSla);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getDaysOfWeek() {
        const response = await APIOrganization.getDaysOfWeek(token);

        if (response.status === 200) {
            let dataUpdatedDayWeek = [];

            response.data.forEach((item) => {
                let requestedItemDayWeek = {};

                requestedItemDayWeek.id = item.id_dia;
                requestedItemDayWeek.label = item.desc_dia;

                dataUpdatedDayWeek.push(requestedItemDayWeek);
            });

            setDaysWeek(dataUpdatedDayWeek);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getSelectionData() {
        try {
            await Promise.allSettled([
                getSlaList(),
                getDaysOfWeek(),
            ])
        } catch (error) {
            console.error("Erro ao obter informações dos selects", error);
        }
    };

    //USE MEMOS

    //USE EFFECTS
    useEffect(() => {
        getSelectionData();
    }, []);

    return (
        <>
            <main className="page-create-organization">
                <header className="page-create-organization__header">
                    <div className="page-create-organization__header__title">
                        <ArrowLefIcon
                            onClick={() => {
                                toGoBack();
                            }}
                        />

                        <Typography.H2 color="gray-180" weight="bold">
                            Adicionar organização
                        </Typography.H2>
                    </div>

                    <div className="page-create-organization__header__save-or-cancel">
                        <Button
                            className="btn-cancel-organization"
                            disabled={pageStatus === "creating-organization"}
                            onClick={() => {
                                toGoBack();
                            }}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-organization"
                            disabled={pageStatus === "creating-organization"}
                            onClick={handleCreateOrganization}
                        >
                            {pageStatus !== "creating-organization" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="section-container">
                    <section className="basic-data-section">
                        <header className="basic-data-section-header">
                            <div className="basic-data-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Dados básicos
                                </Typography.H4>
                            </div>

                            <div className="basic-data-section-header__division" />
                        </header>

                        <div className="basic-data-content-container">
                            <div className="basic-data-content-container__content">
                                <div className="columns-data">
                                    <FieldContainer title="Nome" required>
                                        <Input
                                            type="text"
                                            placeholder="Digite o nome aqui"
                                            value={basicData.name}
                                            onChange={(event) => setBasicData({ ...basicData, nome_organizacao: event })}
                                            error={validationWarn ? !basicData.nome_organizacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Cód externo(Opcional)">
                                        <Input
                                            type="text"
                                            placeholder="Digite o cód externo aqui"
                                            value={basicData.codigo_organizacao}
                                            onChange={(event) => setBasicData({ ...basicData, codigo_organizacao: event })}
                                        />
                                    </FieldContainer>

                                    <FieldContainer untitled>
                                        <div className="container-hour-association">
                                            <Switch
                                                id="hour-association"
                                                checked={shiftWorkersHourAssociation}
                                                onChange={() => setShiftWorkersHourAssociation(!shiftWorkersHourAssociation)}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                onColor={"#b8c1ea"}
                                                onHandleColor={"#455CC7"}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                width={34}
                                                height={14}
                                                handleDiameter={20}
                                            />

                                            <label htmlFor="hour-association">
                                                <Typography.H5 color="gray-180">
                                                    Associar horário dos plantonistas
                                                </Typography.H5>
                                            </label>
                                        </div>
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    {shiftWorkersHourAssociation && (
                        <section className="shift-worker-section">
                            <header className="shift-worker-section-header">
                                <div className="shift-worker-section-header__content">
                                    <Typography.H4 weight="700" color="gray-180">
                                        Associar horário dos plantonistas
                                    </Typography.H4>
                                </div>

                                <div className="shift-worker-section-header__division" />
                            </header>

                            <div className="shift-worker-content-container">
                                {slaAssociations.map((item, slaIndex) => {
                                    const filteredSlaAssociations = slaOptions.filter(sla => {
                                        return !slaAssociations
                                            .slice(0, slaIndex)
                                            .some(prevItem => prevItem.sla_id === sla.id)
                                    });

                                    return (
                                        <div key={slaIndex}>
                                            <div
                                                className="shift-worker-content-container__content"
                                            >
                                                <div className="sla-column">
                                                    <FieldContainer title="Sla">
                                                        <Select
                                                            changeDownArrowIcon
                                                            uncheckTheOption
                                                            placeholder="Selecione"
                                                            value={item.sla_id}
                                                            options={filteredSlaAssociations}
                                                            onSelect={(sla) => {
                                                                const slaSelect = cloneDeep(slaAssociations);

                                                                slaSelect[slaIndex].sla_id = sla.id;

                                                                setSlaAssociations(slaSelect);
                                                            }}
                                                        />
                                                    </FieldContainer>

                                                    {slaAssociations.length > 1 && (
                                                        <span
                                                            className="delete-sla"
                                                            onClick={() => handleRemoveSla(slaIndex)}
                                                        >
                                                            <CloseIcon />

                                                            <Typography.H4 weight="bold" color="red-2">
                                                                Excluir SLA
                                                            </Typography.H4>
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="days-week-column">
                                                    {item.days.map((day, dayIndex) => {
                                                        const dayErrors = errors[slaIndex]?.days?.[dayIndex] || {};

                                                        const filteredDaysWeek = daysWeek.filter(day => {
                                                            return !slaAssociations[slaIndex].days
                                                                .slice(0, dayIndex)
                                                                .some(prevItem => prevItem.id_dayWeek === day.id)
                                                        });

                                                        return (
                                                            <div
                                                                key={dayIndex}
                                                                className="days-week-column__content"
                                                            >
                                                                <FieldContainer title="Dias da semana">
                                                                    <Select
                                                                        changeDownArrowIcon
                                                                        uncheckTheOption
                                                                        placeholder="Selecione"
                                                                        value={day.id_dayWeek}
                                                                        options={filteredDaysWeek}
                                                                        onSelect={(day_week) => {
                                                                            const dayWeekSelect = cloneDeep(slaAssociations);

                                                                            dayWeekSelect[slaIndex].days[dayIndex].id_dayWeek = day_week.id;

                                                                            setSlaAssociations(dayWeekSelect);
                                                                        }}
                                                                    />
                                                                </FieldContainer>

                                                                <FieldContainer title="Hora inicial">
                                                                    <label className="input-container">
                                                                        <div className="input-wrapper">
                                                                            <MaskedInput
                                                                                mask="99:99"
                                                                                className="input-wrapper__input"
                                                                                placeholder="HH:MM"
                                                                                value={day.start_time}
                                                                                onChange={(e) => handleStartTimeChange(slaIndex, dayIndex, e.target.value)}
                                                                                onBlur={() => handleBlur(slaIndex, dayIndex)}
                                                                            />
                                                                        </div>

                                                                        {day.start_time !== "" && dayErrors.start_time && (
                                                                            <div className="input-msg input-msg--error">
                                                                                <div className="input-msg__icon">
                                                                                    <IconError />
                                                                                </div>

                                                                                <Typography.Paragraph className="input-msg__title">
                                                                                    {dayErrors.start_time}
                                                                                </Typography.Paragraph>
                                                                            </div>
                                                                        )}
                                                                    </label>
                                                                </FieldContainer>

                                                                <FieldContainer title="Hora final">
                                                                    <label className="input-container">
                                                                        <div className="input-wrapper">
                                                                            <MaskedInput
                                                                                mask="99:99"
                                                                                className="input-wrapper__input"
                                                                                placeholder="HH:MM"
                                                                                value={day.end_time}
                                                                                onChange={(e) => handleEndTimeChange(slaIndex, dayIndex, e.target.value)}
                                                                                onBlur={() => handleBlur(slaIndex, dayIndex)}
                                                                            />
                                                                        </div>

                                                                        {day.end_time !== "" && dayErrors.end_time && (
                                                                            <div className="input-msg input-msg--error">
                                                                                <div className="input-msg__icon">
                                                                                    <IconError />
                                                                                </div>

                                                                                <Typography.Paragraph className="input-msg__title">
                                                                                    {dayErrors.end_time}
                                                                                </Typography.Paragraph>
                                                                            </div>
                                                                        )}
                                                                    </label>
                                                                </FieldContainer>

                                                                <div className="days-week-column__content__actions">
                                                                    <MinusCircleIcon
                                                                        className={`delete-day-week ${item.days.length === 1 && "disabled"}`}
                                                                        onClick={(e) => {
                                                                            if (item.days.length === 1) return;

                                                                            e.preventDefault();
                                                                            handleRemoveDayWeek(slaIndex, dayIndex);
                                                                        }}
                                                                    />

                                                                    {!allDaysWeek(slaIndex) && (
                                                                        <AddCircleIcon
                                                                            className="add-day-week"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handleAddDayWeek(slaIndex)
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            {slaAssociations.length - 1 === slaIndex && !allSlaAssociations() && (
                                                <span
                                                    className="add-sla"
                                                    onClick={() => handleAddSla()}
                                                >
                                                    <AddCircleIcon />

                                                    <Typography.H4 weight="400" color="blue-selected-4">
                                                        Adicionar SLA
                                                    </Typography.H4>
                                                </span>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    )}
                </div>
            </main>
        </>
    )
}
