export const getJsonTask = async () => {
  try {
    const response = await fetch('http://localhost:3000/tasks.json', {
      headers: {
        Accept: 'application/json'
      }
    })
    const dados = await response.json();

    return dados;

  } catch(error) {
    console.log(error);
  }
}