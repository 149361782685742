import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode";

export async function listMotives(token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get('/list-reasons', config);

        for (const item of response.data) {
            item.desc_motivo = unicodeToChar(item.desc_motivo);
        }

        return response;
    } catch (error) {
        return error.message;
    }
};

export async function createMotive(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/create-reason', data, config);

        return response;
    } catch (error) {
        return error
    }
};

export async function deleteMotive(idReason, token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.delete(`/delete-reason/${idReason}`, config);

        return response;
    } catch (error) {
        return error;
    }
};