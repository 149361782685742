import { useEffect, useState, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import ReactQuill, { Quill } from "react-quill";
import axios from "axios";

import { AttachFile, Select } from "../../data-entry";
import { FieldContainer } from "../../data-display";
import { Typography } from "../../general";
import { Tippy } from "../../feedback";

//STYLES
import "./quill-toolbar.scss";

import instance from "./../../../services/api/configAxios";

import { useSelector } from "react-redux";

import { ReactComponent as IconUndo } from "../../../assets/icons/toolbar-mail/undo.svg";
import { ReactComponent as IconRedo } from "../../../assets/icons/toolbar-mail/redo.svg";
import { ReactComponent as IconAttachment } from "../../../assets/icons/Attachment.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/Trash-2.svg";
import { ReactComponent as QlBold } from "../../../assets/icons/toolbar-mail/text-bold.svg";
import { ReactComponent as QlItalic } from "../../../assets/icons/toolbar-mail/text-italic.svg";
import { ReactComponent as QlUnderline } from "../../../assets/icons/toolbar-mail/text-underline.svg";
import { ReactComponent as QlImage } from "../../../assets/icons/toolbar-mail/gallery.svg";
import { ReactComponent as QlLink } from "../../../assets/icons/toolbar-mail/link-2.svg";
import { ReactComponent as QlAudio } from "../../../assets/icons/toolbar-mail/microphone-2.svg";
import { ReactComponent as QlTemplate } from "../../../assets/icons/toolbar-mail/message-text.svg";
import { red } from "@mui/material/colors";

let icons = ReactQuill.Quill.import("ui/icons");
icons.bold = null;
icons.italic = null;
icons.underline = null;
icons.image = null;
icons.link = null;

let Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = ["roboto", "poppins", "arial"];
Quill.register(Font, true);

let Delta = ReactQuill.Quill.import("delta");

export default function QuillToolbar({
  setFiles,
  removeAll,
  sendQuill,
  screen,
  openAudio,
  changeExib = "",
  contentTemplate,
  editVariable,
  placement = "top",
}) {
  const token = useSelector((state) => state.auth.token);

  const [visible, setVisible] = useState(false);
  const quill = sendQuill?.current?.getEditor();

  const show = () => setVisible(true);

  const hide = () => {
    setVisible(false);
  };

  return (
    <div className={`quill-toolbar-mail ${changeExib}`}>
      <>
        <div id={"toolbar" + screen} className={"ql-toolbar ql-snow"}>
          {/* <IconUndo onClick={() => document.execCommand("undo")} /> */}
          <span className="ql-formats">
            <button
              className={`ql-undo ${quill ? "active" : "disabled"}`}
              id="buttons-quill"
              onClick={() => quill.history.undo()}
            >
              <IconUndo />
            </button>
            <button
              className={`ql-redo ${quill ? "active" : "disabled"}`}
              id="buttons-quill"
              onClick={() => quill.history.redo()}
            >
              <IconRedo />
            </button>
            <button className="ql-bold" id="buttons-quill">
              <QlBold />
            </button>
            <button className="ql-italic" id="buttons-quill">
              <QlItalic />
            </button>
            {/* <select class="ql-font" id="buttons-quill">
              <option value="Inter" selected>
                Inter
              </option>
              <option value="poppins">Poppins</option>
              <option value="roboto">Roboto</option>
              <option value="arial">Arial</option>
            </select> */}
            <button className="ql-underline" id="buttons-quill">
              {" "}
              <QlUnderline />{" "}
            </button>
            <button className="ql-attach" id="buttons-quill">
              <AttachFile
                setFiles={setFiles}
                textIcon=" "
                icon={<IconAttachment />}
                isDownload={true}
                screen="communicated"
              />
            </button>
            {/* <button className="ql-image" id="buttons-quill">
              {" "}
              <QlImage />{" "}
            </button> */}
            <button className="ql-link" id="buttons-quill">
              {" "}
              <QlLink />{" "}
            </button>
            <button className="ql-template" id="buttons-quill">
              <Tippy
                theme="popover-default"
                // #TOVERIFY: TRIGGER
                // trigger="click"
                visible={visible}
                onClickOutside={hide}
                placement={placement}
                delay={[0, 0]}
                allowHTML
                interactive
                content={contentTemplate}
              >
                <QlTemplate
                  className="comment-icon__menu"
                  onClick={() => show()}
                />
              </Tippy>
            </button>
            <button className="ql-audio" id="buttons-quill" onClick={openAudio}>
              {" "}
              <QlAudio />{" "}
            </button>
            {editVariable && (
              <button
                className="ql-variableEdit"
                id="buttons-quill"
                onClick={editVariable}
              >
                <Typography.H5 color={"blue-selected-4"}>
                  Editar variáveis
                </Typography.H5>
              </button>
            )}
          </span>
        </div>
        <IconTrash onClick={removeAll} width={16} height={16} />
      </>
    </div>
  );
}
