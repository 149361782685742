//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";

// UTILS

//COMPONENTS
import { Button, Typography } from "../../../general";
import { Modal } from "./../../../feedback";
import { Toast } from "./../../../../utils/toast";
import { Input, Select, SelectSearchItems } from "../../../data-entry";
import { FieldContainer } from "../../../data-display";
import { maskCep, maskCpfCnpj, maskRg, phoneMask } from "../../../../utils/masks";
import { fetchWithTimeout } from "../../../../utils/fetchWithTimeout";
import { validateRg } from "../../../../utils/validateRg";
import { onKeyPress } from "../../../../utils/onKeyPress";
import Loading from "../../Loading";

//SERVICES - api, conectors...
import * as APIContact from "./../../../../services/api/APIContact";
import * as APICompany from "./../../../../services/api/company";
import * as APITypesPhones from "./../../../../services/api/typesPhones";

//ASSETS - icons, images...
import { ReactComponent as IconUser } from "./../../../../assets/images/user.svg";
import { ReactComponent as IconAddCircle } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as IconMinusCircle } from "./../../../../assets/icons/minus-cirlce.svg";

//STYLES
import "./update-contact.scss";

export default function UpdateContact({ isOpen, onClose, onEdit, contactsData }) {
  //REDUX - Selectors
  const token = useSelector((state) => state.auth.token);
  const filtersEnterprises = useSelector((state) => state.filters.enterprises);

  //GENERAL
  const contactId = isOpen || null;
  var cpfValid = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [validationWarn, setValidationWarn] = useState(false);
  const [inputErrorEmail, setInputErrorEmail] = useState(false);
  const [inputErrorCpf, setInputErrorCpf] = useState(false);
  const [inputErrorRg, setInputErrorRg] = useState(false);
  const [inputErrorCep, setInputErrorCep] = useState(false);
  const [onBlurInputErrorEmail, setonBlurInputErrorEmail] = useState(false);
  const [onBlurInputErrorCpf, setonBlurInputErrorCpf] = useState(false);
  const [onBlurInputErrorRg, setonBlurInputErrorRg] = useState(false);
  const [loadingEnterprises, setLoadingEnterprises] = useState(false);
  const [data, setData] = useState(null);
  const [id, setId] = useState();
  const [companys, setCompanys] = useState([]);
  const [phoneExcluded, setPhoneExcluded] = useState({ list: [] });
  const [typeListPhone, setTypeListPhone] = useState([]);
  const [inputErrorTel, setInputErrorTel] = useState([]);
  const [selectErrorPhoneType, setSelectErrorPhoneType] = useState([]);
  const [enterprisesLimit, setEnterprisesLimit] = useState(50);

  console.log("endPoint Contact", data)

  //FUNCTIONS
  function onCloseCustom() {
    onClose();
    setPhoneExcluded({ ...phoneExcluded, list: [] })
    setSelectErrorPhoneType([]);
    setInputErrorTel([]);
    setInputErrorEmail(false);
    setInputErrorCpf(false);
    setInputErrorRg(false);
    setInputErrorCep(false);
  };

  function onlyNumber(evt) {
    var theEvent = evt || window.event;
    var key = theEvent?.keyCode || theEvent?.which;
    key = String.fromCharCode(key);

    var regex = /^[0-9.]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleAddInputsContact = () => {
    setData({
      ...data, telephones: [
        ...data.telephones, {
          phone: "", id_phone: "", id_tipo_phone: null
        }
      ]
    })
  };

  const handleRemoveInputContact = (index) => {
    const updatedPhones = [...data?.telephones];
    const removedPhone = updatedPhones.splice(index, 1)[0];

    setPhoneExcluded(prevState => ({
      list: [...prevState.list, { id_phone: removedPhone.id_phone }]
    }));

    setData(prevState => ({
      ...prevState,
      telephones: updatedPhones,
    }))
  };

  async function getListTypesPhones() {
    setPageStatus("requesting");

    const response = await APITypesPhones.getTypesPhones(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((phone) => {
        let requestTypesPhones = {};

        requestTypesPhones.id = phone.id_tipo_telefone;
        requestTypesPhones.label = phone.desc_tipo_telefone;

        dataUpdated.push(requestTypesPhones);
      });

      setTypeListPhone(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }

    setPageStatus("awaiting");
  };

  async function getListEnterprises(page, title, limit) {
    setLoadingEnterprises(true);

    const response = await APICompany.newListAllCompanysUsers(
      token,
      page,
      title,
      limit,
      filtersEnterprises
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((company) => {
      let updatedCompany = {};

      updatedCompany.id = company.id_empresa
      updatedCompany.label = company.nome

      updatedData.push(updatedCompany);
    });

    setLoadingEnterprises(false)
    setCompanys(updatedData)
  };

  function loadMoreEnterprises() {
    setEnterprisesLimit(enterprisesLimit + 50)
  };

  function getValues(idContact) {
    contactsData?.forEach((element) => {
      if (element.id_contato === idContact) {
        setData({
          ...element,
          cpf: element.cpf && maskCpfCnpj(element.cpf),
          rg: element.rg && maskRg(element.rg),
          cep: element.cep && maskCep(element.cep)
        });
        setId(element.id_contato);
      }
    })
  };

  function cleanFieldsCep() {
    setData({ ...data, endereco: "" });
  };

  async function searchCep(e) {
    const cep = e.target.value.replace(/\D/g, "");

    if (cep != "") {
      var validacep = /^[0-9]{8}$/;

      if (validacep.test(cep)) {

        document.getElementById("cep").value = cep.substring(0, 5)
          + "-"
          + cep.substring(5);

        //* Preenche os campos com "..." enquanto consulta webservice.
        // setContactData({ ...contactData, address: "...", district: "...", uf: "..." })

        try {
          const response = await fetchWithTimeout(`https://viacep.com.br/ws/${cep}/json/`, {
            timeout: 6000
          }).then(response => {
            if (response.status === 200) {
              return response.json()
            }
          }).then(datum => {
            if (datum.erro) {
              setInputErrorCep(true);

              Toast(
                "Erro ao adicionar CEP", "Cep não encontrado", "error"
              )
            } else {
              setInputErrorCep(false);

              setData({ ...data, endereco: datum.logradouro })
            }
          })

          return response;
        } catch (error) {
          if (error) {
            Toast(
              "Erro ao adicionar CEP", "Não foi possível carregar as informações", "error"
            )
          }
        }
      } else {
        //cep é inválido.
        cleanFieldsCep();
        Toast(
          "Erro ao adicionar CEP", "Formato de CEP inválido", "error"
        )
      }
    } else {
      //cep sem valor, limpa formulário.
      cleanFieldsCep();
    }
  };

  function isFormValidated() {
    if (!data?.nome_contato) return false;
    else if (data?.email && !/\S+@\S+\.\S+/.test(data?.email)) return false;
    else if (
      (data?.cpf && cpfValid.test(data?.cpf)) === false ||
      data?.cpf === "000.000.000-00" ||
      data?.cpf === "111.111.111-11" ||
      data?.cpf === "222.222.222-22" ||
      data?.cpf === "333.333.333-33" ||
      data?.cpf === "444.444.444-44" ||
      data?.cpf === "555.555.555-55" ||
      data?.cpf === "666.666.666-66" ||
      data?.cpf === "888.888.888-88" ||
      data?.cpf === "999.999.999-99"
    ) return false;
    else if (
      (data?.rg && validateRg(data?.rg)) ||
      data?.rg === "000.000.000-0" ||
      data?.rg === "111.111.111-1" ||
      data?.rg === "222.222.222-2" ||
      data?.rg === "333.333.333-3" ||
      data?.rg === "444.444.444-4" ||
      data?.rg === "555.555.555-5" ||
      data?.rg === "666.666.666-6" ||
      data?.rg === "777.777.777-7" ||
      data?.rg === "888.888.888-8" ||
      data?.rg === "999.999.999-9"
    ) return false;

    return true;
  };

  async function handleEditContact() {
    if (isFormValidated()) {
      setPageStatus("update-contact");

      const value = { ...data };

      const isPhoneId = phoneExcluded.list.some(phone => phone.id_phone);

      const updatedContact = {
        nome_contato: data?.nome_contato,
        nome_apresentacao: document.getElementById("nome_apresentacao")?.value ?
          value.nome_apresentacao = document.getElementById("nome_apresentacao")?.value : data?.nome_apresentacao,
        cpf: data?.cpf ? value.cpf = value.cpf.replace(/[-/\.//"]/g, '') : data?.cpf,
        rg: data?.rg ? value.rg = value.rg.replace(/[-/\.//"]/g, '') : data?.rg,
        endereco: data?.endereco,
        numero: data?.numero,
        complemento: data?.complemento,
        bairro: data?.bairro,
        cep: data?.cep ? value.cep = value.cep.replace(/[-/\.//"]/g, '') : data?.cep,
        email: data?.email,
        id_usuario: data?.id_usuario,
        id_cidade: data?.id_cidade,
        id_empresa: data?.id_empresa || null,
        numbers_deleted: phoneExcluded.list.length > 0 && isPhoneId ? phoneExcluded.list : [],
        numbers: document.getElementById("telephone")?.value === "" ? [] : data?.telephones,
        estado_civil: data?.estado_civil,
        profissao: data?.profissao,
        tipo_residencia: data?.tipo_residencia,
        codigo_externo: data?.codigo_externo
      };

      const response = await APIContact.updateContact(id, token, updatedContact);

      if (response.status === 200) {
        Toast("Contato Atualizado!", "Contato atualizado com sucesso.", "success");
        onEdit();
        onCloseCustom();
      } else {
        Toast(
          "Falha, não foi possível atualizar o seu contato!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );
      }

      setPageStatus("awaiting");
    } else if (!data?.nome_contato || !data?.nome_apresentacao) {
      setValidationWarn(true);

      Toast(
        "Erro ao editar contato!",
        "Verifique o preenchimento dos campos em destaque",
        "error",
      )
    } else if (data?.email && !/\S+@\S+\.\S+/.test(data?.email)) {
      setInputErrorEmail(true);
      setonBlurInputErrorEmail(true);
    } else if (
      (data?.cpf && cpfValid.test(data?.cpf)) === false ||
      data?.cpf === "000.000.000-00" ||
      data?.cpf === "111.111.111-11" ||
      data?.cpf === "222.222.222-22" ||
      data?.cpf === "333.333.333-33" ||
      data?.cpf === "444.444.444-44" ||
      data?.cpf === "555.555.555-55" ||
      data?.cpf === "666.666.666-66" ||
      data?.cpf === "888.888.888-88" ||
      data?.cpf === "999.999.999-99"
    ) {
      setInputErrorCpf(true);
      setonBlurInputErrorCpf(true);
    } else if (
      (data?.rg && validateRg(data?.rg)) ||
      data?.rg === "000.000.000-0" ||
      data?.rg === "111.111.111-1" ||
      data?.rg === "222.222.222-2" ||
      data?.rg === "333.333.333-3" ||
      data?.rg === "444.444.444-4" ||
      data?.rg === "555.555.555-5" ||
      data?.rg === "666.666.666-6" ||
      data?.rg === "777.777.777-7" ||
      data?.rg === "888.888.888-8" ||
      data?.rg === "999.999.999-9"
    ) {
      setInputErrorRg(true);
      setonBlurInputErrorRg(true);
    }
  };

  function onBlurEventInputError() {
    if (data?.email && !/\S+@\S+\.\S+/.test(data?.email)) {
      setInputErrorEmail(true);
      setonBlurInputErrorEmail(true);
    } else {
      setInputErrorEmail(false);
      setonBlurInputErrorEmail(false);
    };

    if (
      (data?.cpf && cpfValid.test(data?.cpf)) === false ||
      data?.cpf === "000.000.000-00" ||
      data?.cpf === "111.111.111-11" ||
      data?.cpf === "222.222.222-22" ||
      data?.cpf === "333.333.333-33" ||
      data?.cpf === "444.444.444-44" ||
      data?.cpf === "555.555.555-55" ||
      data?.cpf === "666.666.666-66" ||
      data?.cpf === "888.888.888-88" ||
      data?.cpf === "999.999.999-99"
    ) {
      setInputErrorCpf(true);
      setonBlurInputErrorCpf(true);
    } else {
      setInputErrorCpf(false);
      setonBlurInputErrorCpf(false);
    };

    if (
      (data?.rg && validateRg(data?.rg)) ||
      data?.rg === "000.000.000-0" ||
      data?.rg === "111.111.111-1" ||
      data?.rg === "222.222.222-2" ||
      data?.rg === "333.333.333-3" ||
      data?.rg === "444.444.444-4" ||
      data?.rg === "555.555.555-5" ||
      data?.rg === "666.666.666-6" ||
      data?.rg === "777.777.777-7" ||
      data?.rg === "888.888.888-8" ||
      data?.rg === "999.999.999-9"
    ) {
      setInputErrorRg(true);
      setonBlurInputErrorRg(true);
    } else {
      setInputErrorRg(false);
      setonBlurInputErrorRg(false);
    };
  };

  //USE EFFECTS
  useEffect(() => {
    getListEnterprises(null, null, enterprisesLimit);
    getListTypesPhones()
  }, [enterprisesLimit]);

  useEffect(() => {
    if (contactId) {
      getValues(contactId);
    }
  }, [contactId]);

  return (
    <Modal.Main
      className="modal-update-contact"
      title="Editar contato"
      width="1001px"
      isOpen={isOpen}
      onClose={onCloseCustom}
      shouldCloseOnOverlayClick={false}
      footer={
        <>
          <Button
            className="modal-update-contact__btn-cancel"
            onClick={() => onCloseCustom()}
            disabled={pageStatus === "update-contact"}
          >
            <Typography.H4 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H4>
          </Button>

          <Button
            className="modal-update-contact__btn-confirm"
            disabled={pageStatus === "update-contact"}
            onClick={() => {
              const phoneTypes = data?.telephones?.map((item) => item.id_tipo_phone === null);
              const hasEmptyTelephone = data?.telephones?.some((item) => !item.phone);

              if (hasEmptyTelephone || phoneTypes.some(type => type)) {
                const newInputErrorTel = data?.telephones?.map((item) => !item.phone);
                const newSelectErrorPhoneType = data?.telephones?.map((item) => item.id_tipo_phone === null);

                setInputErrorTel(newInputErrorTel);
                setSelectErrorPhoneType(newSelectErrorPhoneType);

                Toast(
                  "Erro ao editar contato",
                  "Digite um número e/ou selecione um tipo de telefone",
                  "error"
                );

                return null;
              } else handleEditContact();
            }}
          >
            {pageStatus !== "update-contact" ? (
              <Typography.H4 weight={"bold"}>Salvar alterações</Typography.H4>
            ) : (
              <Loading.Dots />
            )}
          </Button>
        </>
      }
    >
      <section className="modal-update-contact__content">
        <div className="container-full-line">
          <div className="img">
            <IconUser className="icon-user" />
          </div>
        </div>

        <div className="container-full-line">
          <FieldContainer required title="Nome completo">
            <Input
              type="text"
              id="nome_completo"
              placeholder="Digite seu nome"
              onChange={(event) => setData({ ...data, nome_contato: event })}
              value={data?.nome_contato}
              onKeyPress={(e) => onKeyPress(e)}
              error={validationWarn ? !data?.nome_contato : false}
            />
          </FieldContainer>
          <FieldContainer required title="Nome de apresentação">
            <Input
              type="text"
              id="nome_apresentacao"
              placeholder="Digite seu nome apresentação"
              onChange={(event) => setData({ ...data, nome_contato: event })}
              value={data?.nome_contato}
              onKeyPress={(e) => onKeyPress(e)}
              error={validationWarn ? !data?.nome_contato : false}
            />
          </FieldContainer>
          <FieldContainer title="Código externo">
            <Input
              type="text"
              placeholder="Digite seu código externo"
              onChange={(event) => setData({ ...data, codigo_externo: event })}
              onKeyPress={(e) => onKeyPress(e)}
              value={data?.codigo_externo}
            />
          </FieldContainer>
        </div>

        <div className="container-full-line">
          <FieldContainer title="E-mail">
            <Input
              type="email"
              placeholder="email@hotmail.com"
              onBlur={onBlurEventInputError}
              onChange={(event) => setData({ ...data, email: event })}
              onKeyPress={(e) => onKeyPress(e)}
              value={data?.email}
              typeInputMessage={inputErrorEmail ? "error" : false}
              error={onBlurInputErrorEmail ? data?.email && !/\S+@\S+\.\S+/.test(data?.email) : false}
              customInputMessage={"E-mail inválido"}
            />
          </FieldContainer>
          <FieldContainer title="CPF">
            <Input
              type="text"
              placeholder="XXX.XXX.XXX-XX"
              onBlur={onBlurEventInputError}
              onChange={(event) => setData({ ...data, cpf: maskCpfCnpj(event) })}
              onKeyPress={(e) => onKeyPress(e)}
              value={data?.cpf}
              typeInputMessage={inputErrorCpf ? "error" : false}
              error={
                onBlurInputErrorCpf ? (data?.cpf && cpfValid.test(data?.cpf)) === false ||
                  data?.cpf === "000.000.000-00" ||
                  data?.cpf === "111.111.111-11" ||
                  data?.cpf === "222.222.222-22" ||
                  data?.cpf === "333.333.333-33" ||
                  data?.cpf === "444.444.444-44" ||
                  data?.cpf === "555.555.555-55" ||
                  data?.cpf === "666.666.666-66" ||
                  data?.cpf === "888.888.888-88" ||
                  data?.cpf === "999.999.999-99" : false
              }
              customInputMessage={"Cpf inválido"}
              maxLength="14"
            />
          </FieldContainer>
          <FieldContainer title="RG">
            <Input
              type="text"
              placeholder="XX.XXX.XXX-XX"
              onBlur={onBlurEventInputError}
              onChange={(event) => setData({ ...data, rg: maskRg(event) })}
              onKeyPress={(e) => onKeyPress(e)}
              value={data?.rg}
              typeInputMessage={inputErrorRg ? "error" : false}
              error={
                onBlurInputErrorRg ? (data?.rg && validateRg(data?.rg)) ||
                  data?.rg === "000.000.000-0" ||
                  data?.rg === "111.111.111-1" ||
                  data?.rg === "222.222.222-2" ||
                  data?.rg === "333.333.333-3" ||
                  data?.rg === "444.444.444-4" ||
                  data?.rg === "555.555.555-5" ||
                  data?.rg === "666.666.666-6" ||
                  data?.rg === "777.777.777-7" ||
                  data?.rg === "888.888.888-8" ||
                  data?.rg === "999.999.999-9" : false
              }
              customInputMessage={"Rg inválido"}
              maxLength="13"
            />
          </FieldContainer>
        </div>

        <div className="divisor" style={{ marginTop: "10px" }}></div>

        <FieldContainer title="Contato">
          {data?.telephones?.map((item, index) => {
            let { phone, id_tipo_phone, id_phone } = item;

            return (
              <div
                key={id_phone}
                style={{ marginTop: "10px" }}
                className="container-full-line-contact"
              >
                <Input
                  disabled={phone?.length > 0 && data?.telephones?.length === 1}
                  width="300px"
                  type="numberMask"
                  placeholder="XX 9 1234-5678"
                  mask={"(99) 9 9999-9999"}
                  id="telephone"
                  name="telephone"
                  value={phone}  // replace(/[-/{()}]/g, '')
                  onKeyPress={(event) => onlyNumber(event)}
                  onChange={(e) => {
                    const event = e

                    const updatedNumbersData = cloneDeep(data?.telephones);
                    updatedNumbersData[index].phone = event;

                    const updatedTelephones = updatedNumbersData.map(item => item.phone);

                    updatedNumbersData.map(item => item.id_phone);

                    const updatedTelephoneString = updatedTelephones.join(',');

                    const telefoneIdArray = data.telefone_id.split('§');
                    const telefoneNumbers = telefoneIdArray.filter((item, idx) => idx % 2 === 0);

                    telefoneNumbers[index] = event;

                    const updatedTelephoneIdString = telefoneIdArray.map(
                      (item, idx) => idx % 2 === 0 ?
                        telefoneNumbers[Math.floor(idx / 2)] : item
                    ).join('§');

                    setData({
                      ...data,
                      telephones: updatedNumbersData,
                      telefone: updatedTelephoneString,
                      telefone_id: updatedTelephoneIdString
                    });
                  }}
                  error={inputErrorTel[index] ? true : false}
                />

                <Select
                  disabled={true}
                  width="120px"
                  placeholder="+ 55"
                />

                <Select
                  width="300px"
                  placeholder="Tipo de telefone"
                  value={Number(id_tipo_phone)}
                  options={typeListPhone}
                  onSelect={(type_phone) => {
                    const typePhoneUpdate = cloneDeep(data?.telephones);

                    typePhoneUpdate[index].id_tipo_phone = type_phone.id.toString();

                    setData({ ...data, telephones: typePhoneUpdate });
                  }}
                  error={selectErrorPhoneType[index] ? true : false}
                />

                <div className="container-add-minus">
                  {data?.telephones?.length > 1 && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveInputContact(index)
                      }}
                    >
                      <IconMinusCircle className="remove-phone" />
                    </div>
                  )}

                  {data?.telephones?.length - 1 === index && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleAddInputsContact();
                      }}
                    >
                      <IconAddCircle className="add-phone" />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </FieldContainer>

        <div className="divisor"></div>

        <div className="container-full-line" style={{ marginTop: "10px" }}>
          <FieldContainer title="CEP">
            <Input
              type="text"
              id="cep"
              placeholder="XX.XXX-XXX"
              onBlur={searchCep}
              onChange={(event) => setData({ ...data, cep: maskCep(event) })}
              onKeyPress={(e) => onKeyPress(e)}
              value={data?.cep}
              error={inputErrorCep ? true : false}
              maxLength="8"
            />
          </FieldContainer>
          <FieldContainer title="Endereço">
            <Input
              type="text"
              id="address"
              name="address"
              placeholder="Digite seu endereço"
              onChange={(event) => setData({ ...data, endereco: event })}
              onKeyPress={(e) => onKeyPress(e)}
              value={data?.endereco}
            />
          </FieldContainer>
          <FieldContainer title="Número">
            <Input
              type="number"
              className="number"
              placeholder="Digite seu número"
              onChange={(event) => setData({ ...data, numero: event })}
              onKeyPress={(e) => onKeyPress(e)}
              value={data?.numero}
            />
          </FieldContainer>
        </div>

        <div className="container-full-line">
          <FieldContainer title="Estado">
            <Input
              disabled={true}
              type="text"
              id="uf"
              placeholder="Digite seu estado"
              value={""}
            />
          </FieldContainer>
          <FieldContainer title="Cidade">
            <Input
              disabled={true}
              type="text"
              id="district"
              placeholder="Digite sua cidade"
              value={""}
            />
          </FieldContainer>
          <FieldContainer title="Empresa">
            <SelectSearchItems
              upward
              loadOnDemand
              placeholder="Busque pelo nome da empresa"
              value={data?.id_empresa}
              options={companys}
              loadingOptions={loadingEnterprises}
              optionsLimit={enterprisesLimit}
              loadMoreOptions={loadMoreEnterprises}
              onSelect={(company) => {
                const objContact = { ...data };

                objContact.id_empresa = company.id
                objContact.nome = company.label

                setData(objContact)
              }}
            />
          </FieldContainer>
        </div>
      </section>
    </Modal.Main>
  )
}
