//GLOBAL - components from npm
import React from "react";
import { Route, Redirect } from "react-router-dom";

// UTILS

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...

export default function PrivateRoute({ children, ...rest }) {
  //GENERAL
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.userData);

  //STATES
  //   pathname
  //REDUX - Selectors

  //FUNCTIONS
  function permissionUser(pathname) {
    let permission = true;

    if (pathname.includes("externo")) return true;

    if (user.permissionId === 1) {
      permission =
        pathname.includes("casos") ||
        pathname.includes("tarefas") ||
        pathname.includes("adicionar-casos-em-massa") ||
        rest.path === "/";
    }

    if (user.permissionId === 3) {
      permission =
        pathname.includes("casos") ||
        pathname.includes("tarefas") ||
        pathname.includes("dashboard") ||
        pathname.includes("adicionar-casos-em-massa") ||
        rest.path === "/";
    }

    return permission;
  }

  //USE EFFECTS

  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          isAuthenticated && permissionUser(location.pathname) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        //   }
      }
    />
  );
}
