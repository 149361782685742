//GLOBAL - components from npm
import React, { useEffect, useState } from 'react';
import { format, isAfter, isPast } from "date-fns";
import { Link } from 'react-router-dom';

// UTILS
import { Toast } from '../../../utils/toast';

//STYLES
import './task-list-category.scss';

//COMPONENTS
import { ProfileList, TagList } from './../../../components/data-entry';
import { Modal, Tippy } from './../../../components/feedback';
import { Typography } from './../../../components/general';
import { PopoverMenu } from './../../../components/navigation';

//SERVICES - api, conectors...
import * as APITask from "../../../services/api/task";

//GLOBAL STATE - redux, env...
import { useSelector } from 'react-redux';

//ASSETS - icons, images...
import { ReactComponent as IconAdd } from './../../../assets/icons/Add.svg';
import { ReactComponent as IconNotification } from "./../../../assets/icons/NotificationThree.svg";
import { ReactComponent as IconMoreVertical } from "./../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as IconAttach } from "./../../../assets/icons/history-icons/HistoricAttach.svg";
import { ReactComponent as IconTags } from "./../../../assets/icons/Tags.svg";
import { ReactComponent as IconArrowUp } from './../../../assets/icons/ArrowUpTwo.svg';
import { ReactComponent as IconAgent } from "./../../../assets/icons/ProfileCircle.svg";
import { ReactComponent as IconAddAgent } from "./../../../assets/icons/IconAddWithColor.svg";
import { ReactComponent as IconClips } from './../../../assets/icons/Clips.svg';
import { ReactComponent as IconGallery } from "./../../../assets/icons/Gallery.svg";
import { ReactComponent as IconDocument } from "./../../../assets/icons/Document.svg";
import { ReactComponent as IconNotifications } from './../../../assets/icons/Notifications.svg';

export default function TaskListCategory({
  categoryData,
  updateMonthlyTasks,
  onClickNewTask,
  getTags,
  allTags,
  allProfileList
}) {

  //GENERAL
  const categoryTasksLenght = categoryData.tasks.length;

  //STATES
  // const [isOpen, setIsOpen] = useState(categoryData.id !== 4 ? true : false)
  const [isOpen, setIsOpen] = useState(true)
  const [isOpenModalDeleteTask, setIsOpenModalDeleteTask] = useState(false);
  const [dataToDeleteTask, setDataToDeleteTask] = useState({
    taskId: null,
    userId: null
  });

  //REDUX - Selectors
  const token = useSelector(state => state.auth.token);
  const userData = useSelector(state => state.auth.userData);

  //FUNCTIONS
  // #TOIMPROVE: Corrigir quando o banco salvar/mandar o esquema de cores
  function categoryScheme() {
    switch (categoryData.id) {
      case 2:
        return 'blue'
      case 3:
        return 'yellow'
      case 4:
        return 'green'
      default:
        break;
    }
  }

  async function deleteTask(taskId, idUser) {
    const response = await APITask.deleteTask(taskId, idUser, token);

    if (response.status === 200) {
      Toast('Tarefa Deletada!', 'Tarefa deletada com sucesso.', 'success');
      updateMonthlyTasks();
    } else {
      Toast(
        'Falha ao deletar a tarefa!',
        'Verifique sua conexão e tente novamente mais tarde.',
        'error'
      );
    }
  }

  function renderPopoverMenuContent(currentTask) {
    if (userData.permissionId === 1 && currentTask.id_solicitante !== userData.userId) {
      return (
        [
          {
            /* title: <Typography.H6 color='primary-dark' weight='medium'>Editar</Typography.H6>, */
            title: <Typography.H5 color='gray-180' weight='500'>Editar</Typography.H5>,
            route: `/tarefas/${currentTask.id_tarefa}`
          }
        ]
      )
    } else {
      return (
        [
          {
            /* title: <Typography.H6 color='primary-dark' weight='medium'>Editar</Typography.H6>, */
            title: <Typography.H5 color='gray-180' weight='500'>Editar</Typography.H5>,
            route: `/tarefas/${currentTask.id_tarefa}`
          },
          {
            title: <Typography.H5 color='red' weight='medium'>Remover</Typography.H5>,
            onClick: () => {
              setDataToDeleteTask({
                taskId: currentTask.id_tarefa,
                userId: userData.userId
              })
              setIsOpenModalDeleteTask(true);
            }
          },
        ]
      )
    }
  }

  //USE EFFECTS

  return (
    <>
      <li className={`category category--scheme-${categoryScheme()} ${isOpen ? 'category--active' : ''}`}>
        <header className='category__header'>
          {/* <button type='button' className='category__btn-expand' onClick={() => setIsOpen(!isOpen)}>
            <IconArrowUp />
          </button> */}

          <div className='category__title'>
            <div className='category__title__content'>
              <Typography.H5 weight="bold" color='white'>{categoryData.title}</Typography.H5>
              <Typography.H5 weight="400" color='white'>
                {`(${categoryTasksLenght < 10 ? '0' : ''}${categoryTasksLenght} tarefa${categoryTasksLenght === 1 ? '' : 's'})`}
              </Typography.H5>
            </div>

            <button type='button' className='category__btn-new-task' onClick={onClickNewTask}>
              <IconAdd />
              <Typography.H5 color='blue-selected-4'>Nova tarefa</Typography.H5>
            </button>
          </div>

          {/* <button type='button' className='category__btn-new-task' onClick={onClickNewTask}>
            <IconAdd />
            <Typography.H5 color='gray-300'>Nova tarefa</Typography.H5>
          </button> */}

          <button type='button' className='category__btn-expand' onClick={() => setIsOpen(!isOpen)}>
            <IconArrowUp />
          </button>
        </header>

        <ul className='category__container-tasks'>
          {
            categoryData.tasks.map((currentTask, currentTaskKey) => {
              // const remindersAssociatedTask = currentTask.remindersAssociated.filter(currentTaskReminder => !currentTaskReminder.concluido);

              return (
                <li className='task' key={`currentTask-${currentTaskKey}`}>
                  <Link className='task__title' to={`/tarefas/${currentTask.id_tarefa}`}>
                    <Tippy
                      content={currentTask.assunto}
                      offset={[0, 5]}
                      followCursor='horizontal'
                      maxWidth='300px'
                    >
                      <div className='container-title'>
                        <Typography.H5
                          color='gray-200'
                          className='container-title__text'
                        >
                          {currentTask.assunto}
                        </Typography.H5>
                      </div>
                    </Tippy>
                  </Link>

                  {/* #TOFIX: Melhorar a performance desse componente */}
                  <div className="task__tags">
                    {currentTask.tagsAssociated.length > 0 && <IconTags />}

                    <TagList
                      tagLimit={2}
                      tags={currentTask.tagsAssociated}
                      idTask={currentTask.id_tarefa}
                      buttonTooltip
                      getTags={getTags}
                      allTags={allTags}
                    />
                  </div>

                  <div className='task__features'>
                    {/* <Tippy content={'Lembrete'} offset={[0, 5]}>
                      <div
                        className={`icon-notification ${remindersAssociatedTask &&
                          remindersAssociatedTask.length > 0 ?
                          'active' : ''
                          }`}>
                        <IconNotifications className='fill' />
                      </div>
                    </Tippy> */}

                    <Tippy content={'Lembrete'} offset={[0, 5]}>
                      <div className={`icon-attachment ${(currentTask.remindersAssociated) &&
                        (currentTask.remindersAssociated.length > 0) ? 'active' : ''}`}>
                        <IconNotification className='stroke' />
                      </div>
                    </Tippy>

                    <Tippy content={'Anexo'} offset={[0, 5]}>
                      <div
                        className={`icon-attachment ${(currentTask.filesAssociated) &&
                          (currentTask.filesAssociated.length > 0) ?
                          'active' : ''
                          }`}>
                        {/* <IconClips className='stroke' /> */}
                        <IconAttach className='stroke' />
                      </div>
                    </Tippy>
                  </div>

                  <Tippy content={'Prazo de Entrega'} offset={[0, 5]}>
                    <div className='task__deadline'>
                      <Typography.H6
                        color={
                          // DEADLINE OVERDUE
                          isPast(new Date(currentTask.prazo_resolucao))
                            ?
                            (
                              // DEADLINE OVERDUE - STATUS COMPLETED
                              currentTask.id_fila === 4
                                ?
                                (
                                  // DEADLINE OVERDUE - STATUS COMPLETED - RESOLUTION DATE AFTER DEADLINE DATE
                                  isAfter(new Date(currentTask.dt_fechamento), new Date(currentTask.prazo_resolucao))
                                    ?
                                    (
                                      'red'
                                    )
                                    :
                                    // DEADLINE OVERDUE - STATUS COMPLETED - RESOLUTION DATE BEFORE DEADLINE DATE
                                    (
                                      'gray-400'
                                    )
                                )
                                :
                                (
                                  // DEADLINE OVERDUE - STATUS NOT COMPLETED
                                  'red'
                                )
                            )
                            :
                            (
                              // ON TIME WITH DEADLINE
                              'gray-200'
                            )

                        }
                      >
                        {format(new Date(currentTask.prazo_resolucao), "dd'/'MM'/'yyy")}
                      </Typography.H6>
                    </div>
                  </Tippy>

                  <Tippy content='Contato' offset={[0, 5]}>
                    <div className='container-client-name'>
                      <Typography.H6 color='gray-200' className='container-client-name__text'>
                        {currentTask.nome_contato}
                      </Typography.H6>
                    </div>
                  </Tippy>

                  <div className='task__profiles'>
                    {/* #TOFIX: Melhorar a performance desse componente */}
                    <div className='task__profiles__content'>
                      {currentTask.usersAssociated.length > 0 && (
                        <IconAgent />
                      )}

                      <ProfileList
                        idTask={currentTask.id_tarefa}
                        profiles={currentTask.usersAssociated}
                        allProfileList={allProfileList}
                        buttonTooltip
                        stringFormatId
                      />
                    </div>
                  </div>

                  <PopoverMenu
                    placement='bottom-end'
                    menuData={renderPopoverMenuContent(currentTask)}
                  >
                    <button className='task__btn-options' type='button'>
                      <IconMoreVertical />
                    </button>
                  </PopoverMenu>
                </li>
              )
            })
          }
        </ul>
      </li>

      <Modal.GenericDeleteModal
        title="Excluir Tarefa"
        isOpen={isOpenModalDeleteTask}
        onClose={(event) => { setIsOpenModalDeleteTask(event) }}
        onDeleteAction={async () => {
          await deleteTask(
            dataToDeleteTask.taskId,
            dataToDeleteTask.userId
          );
        }}
      />
    </>
  )
}
