//GLOBAL - components from npm
import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { format, isPast, isAfter } from "date-fns";
import { useHistory } from "react-router-dom";
import moment from "moment-business-time";
import { FaQuestionCircle } from "react-icons/fa";
import { cloneDeep } from "lodash";

//STYLES
import "./split-view.scss";

//COMPONENTS
import {
  Channel,
  FieldContainer,
  FieldContainerEdit,
  ImageProfile,
  StatusSymbol,
  Table,
} from "../../../../components/data-display";
import { MenuTabs, PopoverMenu } from "../../../../components/navigation";
import CaseComments from "../../Details/CaseComments";
import CaseCommunications from "../../Details/CaseCommunications";
import CaseHistoric from "../../Details/CaseHistoric";
import CaseInteractions from "../../Details/CaseInteractions/CaseInteractions";
import TaskList from "../../Details/TaskList";
import { Loading, Tippy } from "../../../../components/feedback";
import { Typography, Button } from "../../../../components/general";
import { Toast } from "../../../../utils/toast";
import {
  DropdownChannel,
  DropdownEscalate,
  DropdownPriority,
  DropdownStatus,
} from "../../../../components/layout";
import {
  ButtonSearch,
  DatePicker,
  Delete,
  Select,
  SelectSearch,
  Input,
  Checkbox,
} from "../../../../components/data-entry";
import postMessage from "../../../../utils/postMessage";
import renderPhone from "../../../../utils/renderPhoneFormat";

//SERVICES - api, conectors...
import * as APICase from "../../../../services/api/case";
import * as APITask from "../../../../services/api/task";
import * as APIInteraction from "../../../../services/api/interaction";
import * as APIUser from "../../../../services/api/task";
import * as APIClient from "../../../../services/api/client";
import * as APIComments from "../../../../services/api/comments";
import * as APIAdditionalFields from "../../../../services/api/additionalFields";

//GLOBAL STATE - redux, env...
import { useDispatch, useSelector } from "react-redux";
import { saveRoute } from "../../../../store/audit";

// UTILS
import formatDate from "../../../../utils/formatDate";

//ASSETS - icons, images...
import { ReactComponent as IconEmptyStateCases } from "../../../../assets/icons/EmptyStateCases.svg";
import { ReactComponent as IconViewUser } from "../../../../assets/icons/ViewUser.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/ArrowDownTwo.svg";

export default function SplitView({
  casesData,
  chooseStatus,
  cleanFilters,
  setCasesData,
  filteredCaseData,
  researchPerforms,
  contactMoreInfoMenu,
}) {
  //GENERAL
  const history = useHistory();
  const dispatch = useDispatch();
  const refPage = useRef();

  //STATES
  const [pageStatus, setPageStatus] = useState("loading");
  const [tabHide, setTabHide] = useState("");
  const [detailedCase, setDeitaledCase] = useState(false || null);
  const [editableField, setEditableField] = useState(false);
  const [loading, setLoading] = useState(false);

  const detailCaseId = detailedCase;

  const [emptyState, setEmptyState] = useState();
  const [startDate, setStartDate] = useState();
  const [interactionList, setInteractionList] = useState();
  const [channelList, setChannelList] = useState();
  const [listRequest, setListRequest] = useState();
  const [statusList, setStatusList] = useState();
  const [escalateList, setEscalateList] = useState();
  const [requiredField, setRequiredField] = useState(false);
  const [requiredChild, setRequiredChild] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [isUpdatingCustomFields, setIsUpdatingCustomFields] = useState(false);
  const [isOpenCustomFields, setIsOpenCustomFields] = useState(false);
  // const [openVariable, setOpenVariable] = useState(false);
  const [caseDetail, setCaseDetail] = useState({});
  const [customEmptyTaskData, setCustomEmptyTaskData] = useState({});
  const [userCreator, setUserCreator] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [clientData, setClientData] = useState({});
  const [priority, setPriority] = useState({});
  const [currentStatusTexts, setCurrentStatusTexts] = useState({});
  const [additionalChildFields, setAdditionalChildFields] = useState({});
  const [additionalFieldsParent, setAdditionalFieldsParent] = useState([]);
  const [additionalFieldsIntegration, setAdditionalFieldsIntegration] = useState([]);
  const [, setProcessedAdditionalFields] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [listReasons, setListReasons] = useState([]);
  const [users, setUsers] = useState([]);
  const [variableValues, setVariableValues] = useState([]);

  //REDUX - Selectors
  const interaction = useSelector((state) => state.interaction);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.userData);
  const permissionProfile = useSelector((state) => state.auth.permissionProfile);
  const filtersContacts = useSelector((state) => state.filters.contacts);
  const principalAdditionalField = useSelector((state) => state.filters.principalAdditionalField);

  // armazenar o mais recente additionalFieldsParent e evitar execução sempre que houver alterações
  const additionalFieldsRef = useRef(additionalFieldsParent);

  //FUNCTIONS
  const value = casesData?.find((c) => c.id_ticket === detailCaseId) || null;

  const handleBackspace = (event, field) => {
    if (event.key !== 'Backspace') return;

    const isFocusedOnRequiredField = additionalFieldsIntegration.some(({ campos_adicionais_obrigatorios }) =>
      campos_adicionais_obrigatorios.some(({ idcampos }) => idcampos === field.id_campo_adicional)
    );

    if (!isFocusedOnRequiredField) return;

    setAdditionalFieldsParent(prevFields => {
      const triggerField = additionalFieldsIntegration.find(({ campos_adicionais_obrigatorios }) =>
        campos_adicionais_obrigatorios.some(({ idcampos }) => idcampos === field.id_campo_adicional)
      );

      if (!triggerField) return prevFields;

      const allRequiredFieldsFilled = triggerField.campos_adicionais_obrigatorios.every(({ idcampos }) => {
        const correspondingField = prevFields.find(({ id_campo_adicional }) => id_campo_adicional === idcampos);
        return correspondingField && correspondingField.valor_default;
      });

      if (!allRequiredFieldsFilled) return prevFields;

      const triggerFieldValue =
        prevFields.find(({ id_campo_adicional }) => id_campo_adicional === triggerField.id_campo_gatilho)?.valor_default;

      if (!triggerFieldValue) return prevFields;

      const matchingField = prevFields.find(
        ({ id_campo_adicional }) => id_campo_adicional === triggerField.id_campo_adicional_retorno
      )

      if (matchingField) {
        matchingField.valor_default = null;
      }

      return prevFields.map(prevField => {
        if (prevField.id_campo_adicional === triggerField.id_campo_gatilho) {
          return { ...prevField, valor_default: null };
        }
        return prevField;
      });
    });
  };

  const getDetails = async (data) => {
    const value = data?.find((c) => c.id_ticket === detailCaseId) || null;

    if (value) {
      const response = await APICase.caseDetail(
        value && value.id_ticket,
        token
      );
      const data = response?.data;

      if (!data || data.length === 0) {
        return history.push("/caso/nao-encontrado");
      }

      data.telefone = data.telefone.split(",");

      data.campos_adicionais.forEach(field => {
        field.id_tipo_campo_adicional = null;
      });

      setCaseDetail(data);
      setUserCreator({ nome_usuario: data.nome_solicitante });
      setUserProfile({
        nome_usuario: data.usersAssociated[0]?.nome_usuario,
        nome_organizacao: data.usersAssociated[0]?.nome_organizacao,
        gestor: data.usersAssociated[0]?.gestor,
      });
      setStartDate(new Date(data.prazo_resolucao));
      setCustomEmptyTaskData({
        id_ticket: parseInt(data.id_ticket),
        id_contato: data.id_contato,
      });
      setClientData({
        id: data?.id_contato,
        label: data?.nome_contato,
        caption1: data?.nome_empresa,
      });
      setPriority({
        id: data?.id_criticidade,
        label: data?.descricao_criticidade,
      });
      changeTypeRequest(data.id_solicitacao, false);

      // getListAdditionalFields(data.campos_adicionais);
    }
  };

  const getListRequest = async () => {
    const response = await APICase.getRequestList(token, user.userId);

    if (response.status === 200) {
      const data = response.data;
      let updatedData = [];

      data.forEach((datum) => {
        let updatedDatum = {};

        updatedDatum.id = datum.id_solicitacao;
        updatedDatum.label = datum.descricao_solicitacao;

        updatedData.push(updatedDatum);
      });

      setListRequest(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  const getListPriority = async () => {
    const response = await APICase.getPriorityList(token);
    const data = response?.data;
    let updatedData = [];

    data.forEach((datum) => {
      let updatedDatum = {};

      updatedDatum.id = datum.id_criticidade;
      updatedDatum.label = datum.descricao;

      updatedData.push(updatedDatum);
    });

    setPriorityList(updatedData);
  };

  const getListStatus = async () => {
    const response = await APICase.getListStatus(token);
    const data = response.data;
    let updatedData = [];

    data.forEach((datum) => {
      let updatedDatum = {};

      updatedDatum.id = datum.id_ticket_status;
      updatedDatum.label = datum.descricao_status;
      updatedDatum.finalizar = datum.termino;
      updatedDatum.cor = datum.cor_alerta;

      updatedData.push(updatedDatum);
    });

    setStatusList(updatedData);
  };

  const getRouteAudit = async () => {
    const response = await APICase.getListIntegrations(token);

    if (response.status === 200) {
      const data = response.data;
      const route = data.filter(
        (option) => option.desc_tipo_integracao === "IngWeb"
      );
      if (route.length !== 0) {
        dispatch(
          saveRoute({
            route: route[0].caminho_integracao,
          })
        );
      }
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  const postComment = async (text, files, responseComment, audio) => {
    try {
      let idType = 0;

      if (text) {
        idType = 1;
      } else if (audio) {
        idType = 3;
      } else {
        idType = 2;
      }

      const data = {
        desc_ticket_comentario: text || "",
        id_ticket: value && value.id_ticket,
        id_mimetype: 2,
        id_tipo_comentario: idType,
        id_ticket_comentario_parente: responseComment?.id_comentario || null,
      };

      const form = new FormData();
      form.append("data", JSON.stringify(data));

      for (const file of files) {
        form.append("data", file.file);
      }

      await APIComments.createCommentCase(form, token);

      await getDetails();
    } catch (error) {
      console.log(error);
    }
  };

  // ################ ANTIGA FUNÇÃO PARA CALCULAR SLA ################
  // const calcSlaDayAndHoliday = async (idSla, timeToAddicionated, now) => {
  //   const response = await APICase.getWorkTimeAndHolidaySla(token, idSla);

  //   let jsonWorkTime = [];
  //   let jsonHoliday = [];
  //   let dateServer = new Date(now);

  //   if (response.data.worktime.length !== 0) {
  //     let arrayWorktime = response.data.worktime;
  //     let arrayHolidays = response.data.holiday;

  //     jsonWorkTime = {
  //       0: mountHourWorkTime(arrayWorktime, 0),
  //       1: mountHourWorkTime(arrayWorktime, 1),
  //       2: mountHourWorkTime(arrayWorktime, 2),
  //       3: mountHourWorkTime(arrayWorktime, 3),
  //       4: mountHourWorkTime(arrayWorktime, 4),
  //       5: mountHourWorkTime(arrayWorktime, 5),
  //       6: mountHourWorkTime(arrayWorktime, 6),
  //     };

  //     await arrayHolidays.forEach((holiday) => {
  //       jsonHoliday.push(mountHoliday(holiday.data_feriado));
  //     });

  //     let objectWorkTimeAndHoliday = {
  //       workinghours: jsonWorkTime,
  //       holidays: jsonHoliday,
  //     };
  //     moment.locale("pt", objectWorkTimeAndHoliday);
  //     let dateFormatted = moment(dateServer).addWorkingTime(
  //       timeToAddicionated,
  //       "minutes"
  //     );
  //     dateFormatted = new Date(dateFormatted);
  //     return dateFormatted;
  //   }

  //   let dateSla = dateServer;
  //   dateSla.setMinutes(dateSla.getMinutes() + timeToAddicionated);

  //   return dateSla;
  // };

  // ################ NOVA FUNÇÃO PARA CALCULAR SLA - FUNÇÃO MENOS REDUNTANDE E CONCISA ################
  const calcSlaDayAndHoliday = async (idSla, timeToAddicionated, now) => {
    try {
      const response = await APICase.getWorkTimeAndHolidaySla(token, idSla);
      const { worktime, holiday } = response.data;

      if (worktime.length === 0) {
        const dateSla = new Date(now);

        dateSla.setMinutes(dateSla.getMinutes() + timeToAddicionated);

        return dateSla;
      }

      const jsonWorkTime = {};
      for (let i = 0; i < 7; i++) {
        jsonWorkTime[i] = mountHourWorkTime(worktime, i);
      }

      const jsonHoliday = holiday.map(h => mountHoliday(h.data_feriado));

      const objectWorkTimeAndHoliday = {
        workinghours: jsonWorkTime,
        holidays: jsonHoliday,
      };

      moment.locale("pt", objectWorkTimeAndHoliday);

      const dateFormatted = moment(now).addWorkingTime(timeToAddicionated, "minutes");

      return new Date(dateFormatted);
    } catch (error) {
      console.error("Erro ao calcular a data SLA:", error);

      Toast(
        "Erro interno!",
        "Erro ao calcular a data SLA.",
        "error"
      );
    }
  };

  const deleteCase = async () => {
    const response = await APICase.deleteCase(value && value.id_ticket, token);

    if (response.status === 200) {
      Toast("Caso Deletado!", "Caso deletado com sucesso.", "success");
    }

    setPageStatus("loading");

    cleanFilters();

    setDeitaledCase(null);
  };

  const handleDeleteTask = async (idTask, id, token) => {
    const response = await APITask.deleteTask(idTask, user.userId, token);

    if (response.status === 200) {
      Toast("Tarefa Deletada!", "Tarefa deletada com sucesso.", "success");
      setCasesData([...casesData]);
      setLoading(true);
    } else {
      Toast(
        "Falha, não foi possível deletar tarefa!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }

    if (response.status === 200 && caseDetail.usersAssociated.length === 0) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.associateUser(dataAssociate, token);
    }

    if (
      caseDetail.usersAssociated.length > 0 &&
      caseDetail.usersAssociated[0].id_usuario !== user.userId &&
      !caseDetail.usersAssociated[0].gestor
    ) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.disassociateUser(
        id,
        caseDetail.usersAssociated[0].id_usuario,
        token
      );

      await APICase.associateUser(dataAssociate, token);
    }

    await getDetails();
  };

  const handleCreateTask = async (id, token) => {
    if (caseDetail.usersAssociated.length === 0) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.associateUser(dataAssociate, token);
    }

    if (
      caseDetail.usersAssociated.length > 0 &&
      caseDetail.usersAssociated[0].id_usuario !== user.userId &&
      !caseDetail.usersAssociated[0].gestor
    ) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.disassociateUser(
        id,
        caseDetail.usersAssociated[0].id_usuario,
        token
      );

      await APICase.associateUser(dataAssociate, token);
    }
    setCasesData([...casesData]);
    setLoading(true);
  };

  const handleDisassociateTask = async (idTask, id, token) => {
    const data = {
      id_task: idTask,
      id_ticket: null,
      id_campo: 16,
      id_ticket_atual: id,
    };
    const response = await APITask.associateTaskToCase(data, token);

    if (response.status === 200) {
      Toast(
        "Tarefa Desvinculada!",
        "Tarefa desvinculada com sucesso.",
        "success"
      );
    } else {
      Toast(
        "Falha, não foi possível desvincular tarefa!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }

    if (response.status === 200 && caseDetail.usersAssociated.length === 0) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.associateUser(dataAssociate, token);
    }

    if (
      caseDetail.usersAssociated.length > 0 &&
      caseDetail.usersAssociated[0].id_usuario !== user.userId &&
      !caseDetail.usersAssociated[0].gestor
    ) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.disassociateUser(
        id,
        caseDetail.usersAssociated[0].id_usuario,
        token
      );

      await APICase.associateUser(dataAssociate, token);
    }

    await getDetails();
    setCasesData([...casesData]);
    setLoading(true);
  };

  const updateCase = async (data, date) => {
    const objData = { ...data };

    objData.dt_abertura = format(new Date(objData.dt_abertura), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    objData.dt_ultima_atualizacao = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    objData.prazo_inicio = format(new Date(objData.prazo_inicio), "yyyy-MM-dd'T'HH:mm:ss.SSS");

    // objData.dt_fechamento = objData.dt_fechamento
    //   ? objData.dt_fechamento
    //   : format(new Date(objData.prazo_inicio), "yyyy-MM-dd'T'HH:mm:ss.SSS");

    if (date) {
      objData.prazo_resolucao = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS");
      objData.id_campo = 5;
    } else {
      objData.prazo_resolucao = format(new Date(objData.prazo_resolucao), "yyyy-MM-dd'T'HH:mm:ss.SSS");
    }

    setCaseDetail(objData);

    const response = await APICase.updateCase(
      value && value.id_ticket,
      objData,
      token
    );

    if (response.status === 200 && caseDetail?.usersAssociated.length === 0) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: value && value.id_ticket,
      };

      await APICase.associateUser(dataAssociate, token);
    }

    if (
      caseDetail?.usersAssociated.length > 0 &&
      caseDetail?.usersAssociated[0].id_usuario !== user.userId
    ) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: value && value.id_ticket,
      };

      await APICase.disassociateUser(
        value && value.id_ticket,
        caseDetail?.usersAssociated[0].id_usuario,
        token
      );

      await APICase.associateUser(dataAssociate, token);
    }

    if (interaction.destination && !interaction.clientId) {
      handleInteractionSaveImmediate(value && value.id_ticket);
    }

    sendSaveInteractionPost();

    await getDetails();
    await getInteractionList();
  };

  const changeStatusAndEscalateTicket = async (option) => {
    const objCase = { ...caseDetail };
    const arrayEscalated = [
      {
        id_escalonamento: option.id,
        desc_escalonamento: option.label,
        id_ticket: value && value.id_ticket,
        id_escalonamento_atual:
          caseDetail?.escalatedAssociated.length !== 0
            ? caseDetail?.escalatedAssociated[0].id_escalonamento
            : 0,
      },
    ];
    objCase.escalatedAssociated = arrayEscalated;

    const response = await APICase.createStaggerSectorCase(
      token,
      arrayEscalated[0]
    );

    if (response.status !== 200) {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    } else {
      const statusEscalate = statusList.find((item) => item.id === 5);
      objCase.id_ticket_status = statusEscalate.id;
      objCase.dt_fechamento = null;
      objCase.id_campo = 2;
      objCase.descricao_ticket_status = statusEscalate.label;
      setCaseDetail(objCase);
      updateCase(objCase);
    }
  };

  const changeEscalateTicket = async (option) => {
    const objCase = { ...caseDetail };
    const arrayEscalated = [
      {
        id_escalonamento: option.id,
        desc_escalonamento: option.label,
        id_ticket: value && value.id_ticket,
        id_escalonamento_atual:
          caseDetail?.escalatedAssociated.length !== 0
            ? caseDetail?.escalatedAssociated[0].id_escalonamento
            : 0,
      },
    ];

    objCase.escalatedAssociated = arrayEscalated;
    setCaseDetail(objCase);

    const response = await APICase.createStaggerSectorCase(
      token,
      arrayEscalated[0]
    );

    if (response.status !== 200) {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  const changeStatusTicket = (option) => {
    if (option.id === 5) {
      return true;
    }
    const objCase = { ...caseDetail };
    objCase.id_ticket_status = option.id;
    if (option.finalizar === true) {
      objCase.dt_fechamento = formatDate(new Date());
    } else {
      objCase.dt_fechamento = null;
    }
    objCase.id_campo = 2;
    objCase.descricao_ticket_status = option.label;
    setCaseDetail(objCase);
    updateCase(objCase);
  };

  async function getListClients(searchParams, page) {
    const response = await APIClient.all(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const formatTelephones = client.telefone.split(",");
      const formatedTelephones = formatTelephones.map((telephone) =>
        renderPhone(telephone)
      );

      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.nome_empresa,
        caption2: client.codigo_externo,
        caption3: client.cpf,
        caption4:
          formatTelephones.length > 1
            ? formatedTelephones.join(", ")
            : renderPhone(formatTelephones[0]),
        caption5: client.email,
      };

      updatedData.push(updatedClient);
    });

    return updatedData;
  }

  async function getChannelList() {
    const response = await APICase.getChannelList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_canal;
        requestItem.label = datum.nome;
        requestItem.ativo = datum.ativo;
        requestItem.dt_exclusao = datum.dt_exclusao;

        dataUpdated.push(requestItem);
      });
      setChannelList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getListUsers() {
    const response = await APIUser.listUsers(token);
    if (response.status === 200) {
      const arrayUsers = [];
      for (const item of response.data) {
        const object = {
          id: item.id_usuario,
          label: item.nome_usuario,
          caption1: item.nome_organizacao,
        };
        arrayUsers.push(object);
      }
      setUsers(arrayUsers);
    } else {
      Toast(
        "Ops... algo deu errado.",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getEscalateList() {
    const response = await APICase.getEscalateList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_escalonamento;
        requestItem.label = datum.descricao;

        dataUpdated.push(requestItem);
      });
      setEscalateList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getInteractionList(data) {
    const value = data?.find((c) => c.id_ticket === detailCaseId) || null;

    if (value) {
      const response = await APIInteraction.listInteractionCase(
        token,
        value && value.id_ticket
      );

      if (response.status === 200) {
        setInteractionList(response.data);
      } else {
        Toast(
          "Falha, não foi possível carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );
      }
    }
  }

  async function getSlaRuleDefault(idReason, idRequest, nameReason) {
    const response = await APICase.getSlaRule(token, null, idRequest);

    if (response.status === 200) {
      if (response.data.length !== 0) {
        let dateSla = await calcSlaDayAndHoliday(
          response.data[0].id_sla,
          response.data[0].sla_inicio + response.data[0].sla_resolucao,
          response.data[0].now
        );

        setEditableField(response.data[0].editavel);
        const filterPriority = priorityList.filter(
          (option) => option.id === response.data[0].id_criticidade
        );
        const arrayCase = { ...caseDetail };
        arrayCase.id_motivo = idReason;
        arrayCase.descricao_motivo = nameReason;
        arrayCase.id_campo = 15;
        arrayCase.id_motivo = idReason;
        arrayCase.id_solicitacao = idRequest;
        arrayCase.id_criticidade = filterPriority[0].id;
        arrayCase.descricao_criticidade = filterPriority[0].label;
        arrayCase.prazo_resolucao = dateSla;
        setCaseDetail(arrayCase);
        updateCase(arrayCase);
      } else {
        setCaseDetail({
          ...caseDetail,
          id_criticidade: 3,
          descricao_criticidade: "Baixa",
          id_solicitacao: idRequest,
          id_motivo: idReason,
          prazo_resolucao: undefined,
        });
        updateCase({
          ...caseDetail,
          id_criticidade: 3,
          descricao_criticidade: "Baixa",
          id_solicitacao: idRequest,
          id_motivo: idReason,
          prazo_resolucao: new Date(),
        });
        setEditableField(true);
      }
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getSlaRule(idReason, idRequest, nameReason) {
    const response = await APICase.getSlaRule(token, idReason, idRequest);

    if (response.status === 200) {
      if (response.data.length !== 0) {
        let dateSla = await calcSlaDayAndHoliday(
          response.data[0].id_sla,
          response.data[0].sla_inicio + response.data[0].sla_resolucao,
          response.data[0].now
        );
        setEditableField(response.data[0].editavel);
        const filterPriority = priorityList.filter(
          (option) => option.id === response.data[0].id_criticidade
        );
        const arrayCase = { ...caseDetail };
        arrayCase.id_motivo = idReason;
        arrayCase.descricao_motivo = nameReason;
        arrayCase.id_campo = 15;
        arrayCase.id_motivo = idReason;
        arrayCase.id_solicitacao = idRequest;
        arrayCase.id_criticidade = filterPriority[0].id;
        arrayCase.descricao_criticidade = filterPriority[0].label;
        arrayCase.prazo_resolucao = dateSla;
        setCaseDetail(arrayCase);
        updateCase(arrayCase);
      } else {
        await getSlaRuleDefault(idReason, idRequest, nameReason);
      }
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function changeTypeRequest(idTypeRequest, isMount) {
    const response = await APICase.getReasonsListToType(token, idTypeRequest);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_motivo;
        requestItem.label = datum.desc_motivo;

        dataUpdated.push(requestItem);
      });

      if (isMount) {
        if (dataUpdated.length === 1) {
          await getSlaRule(dataUpdated[0].id, idTypeRequest);
        } else {
          if (dataUpdated.length >= 2 || dataUpdated.length === 0) {
            await getSlaRule(null, idTypeRequest);
          }
          // if (dataUpdated.length === 0) {
          //   setCaseDetail({
          //     ...caseDetail,
          //     id_criticidade: 3,
          //     descricao_criticidade: "Baixa",
          //     id_solicitacao: idTypeRequest,
          //     prazo_resolucao: undefined,
          //   });
          //   setEditableField(true);
          // }
        }
      }
      setListReasons(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function associateUser(agent) {
    if (caseDetail?.usersAssociated.length === 0) {
      const dataAssociate = {
        id_usuario: agent.id,
        id_ticket: value && value.id_ticket,
      };

      await APICase.associateUser(dataAssociate, token);
    }

    if (
      caseDetail?.usersAssociated.length > 0 &&
      caseDetail?.usersAssociated[0].id_usuario !== agent.id
    ) {
      const dataAssociate = {
        id_usuario: agent.id,
        id_ticket: value && value.id_ticket,
      };

      await APICase.disassociateUser(
        value && value.id_ticket,
        caseDetail?.usersAssociated[0].id_usuario,
        token
      );

      await APICase.associateUser(dataAssociate, token);
    }

    await getDetails();
  }

  function checkResize() {
    if (refPage?.current?.clientWidth > 800 && !tabHide) {
      setTabHide("1");
    } else if (refPage?.current?.clientWidth <= 800 && tabHide) {
      setTabHide("");
    }
  }

  function defaultSorting(rowA, rowB, columnId) {
    const objectRowA = rowA.original[columnId].data;
    const objectRowB = rowB.original[columnId].data;

    if (objectRowA > objectRowB) return -1;
    if (objectRowB > objectRowA) return 1;
    return 0;
  }

  function handleInteractionSaveImmediate() {
    let objeto = {};

    // if (principalAdditionalField.length > 0) {
    //   variableValues.forEach(async (variable) => {
    //     const infoData = principalAdditionalField.find(
    //       (data) => data.id_campo_adicional === variable.id
    //     );

    //     if (infoData) {
    //       objeto = {
    //         ...objeto,
    //         [infoData.desc_campo_adicional]: variable.value,
    //       };
    //     }
    //   });
    // }

    if (caseDetail && caseDetail?.campos_adicionais?.length > 0) {
      caseDetail?.campos_adicionais.forEach(async (field) => {
        if (field) {
          objeto = {
            ...objeto,
            [field.nomecampoadd]: field.valorcampoadd,
          };
        }
      });
    }

    postMessage("sendMeLogInteraction", {
      active: true,
      destination: interaction.destination,
      caseId: value && value.id_ticket,
      clientId: caseDetail?.id_contato,
      interactionId: interaction.interactionId,
      infos: {
        assunto: caseDetail.assunto,
        observacao: caseDetail.observacao,
        dt_abertura: caseDetail.dt_abertura,
        dt_ultima_atualizacao: caseDetail.dt_ultima_atualizacao,
        prazo_inicio: caseDetail.prazo_inicio,
        prazo_resolucao: caseDetail.prazo_resolucao,
        codigo_ticket: caseDetail.codigo_ticket,
        ...objeto,
      },
    });
  }

  function refactoryCasesListSplitView(data) {
    let dataRefactored = [];

    data.forEach((dt) => {
      let clientInfo = {
        data: dt.nome_contato,
        config: {
          textEllipsis: true,
        },
      };

      clientInfo.data = (
        <PopoverMenu
          theme="default"
          trigger="mouseenter"
          cursor="default"
          menuData={contactMoreInfoMenu(
            dt.nome_contato,
            (dt.telefone !== "" || dt.telefone !== null) &&
            dt.telefone.split(","),
            dt.email,
            dt.codigo_externo
          )}
        >
          <div>{dt.nome_contato}</div>
        </PopoverMenu>
      );

      const caseRefactored = {
        cases: {
          data: (
            <div
              onClick={() => {
                setDeitaledCase(dt.id_ticket, true);
                setLoading(true);
                setIsOpenCustomFields(false);
              }}
            >
              {dt.codigo_ticket}
            </div>
          ),
          config: { className: "cell-blue-text" },
        },
        term: {
          data: new Date(dt.prazo_resolucao),
          config: {},
        },
        motive: clientInfo,
        status: {
          data: (
            <Tippy content={dt.descricao_ticket_status}>
              <div style={{ width: 24 }}>
                <StatusSymbol id={dt.id_ticket_status} />
              </div>
            </Tippy>
          ),
          config: {
            customOrder: chooseStatus(dt.id_ticket_status),
            filterValue: dt.id_ticket_status,
          },
        },
      };
      dataRefactored.push(caseRefactored);
    });

    return dataRefactored;
  }

  function handleInteractionStart(channel, destination) {
    postMessage("startInteraction", {
      caseId: caseDetail.id_ticket,
      channelId: channel.id,
      channelName: channel.label,
      destination: destination,
    });
    postMessage("willLogInteraction", {
      active: true,
      destination: destination,
      caseId: caseDetail.id_ticket,
      clientId: caseDetail.id_contato,
      clientName: caseDetail.nome_contato,
    });
    postMessage("sendMeLogInteractionDuration", {
      active: true,
      destination: destination,
      caseId: caseDetail.id_ticket,
      clientId: caseDetail.id_contato,
      clientName: caseDetail.nome_contato,
    });
  }

  function renderChannels() {
    const activeChannels = channelList.filter((channel) => channel.ativo);

    return activeChannels.map((channel) =>
      permissionProfile.canalComunicacao.edicao ? (
        <PopoverMenu
          key={channel.label}
          className="popover-details-channel"
          placement="bottom-start"
          menuData={caseDetail.telefone.map((phone) => ({
            title: phone,
            onClick: () => handleInteractionStart(channel, phone),
          }))}
        >
          <div>
            <Channel
              className="btn-channel-interaction"
              tippy={true}
              nameMedia={channel.label}
              media={channel.id}
              onClick={() => true}
            />
          </div>
        </PopoverMenu>
      ) : (
        <div>
          <Channel
            className="btn-channel-interaction"
            tippy={true}
            media={channel.id}
            nameMedia={channel.label}
            onClick={() => true}
            disabled
          />
        </div>
      )
    );
  }

  function mountHoliday(date) {
    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let dateFormatted = `*-${month >= 10 ? month : "0" + month}-${day}`;

    return dateFormatted;
  }

  // ################ ANTIGA FUNÇÃO PARA MONTAR HORAS DE TRABALHO ################
  // function mountHourWorkTime(arrayWorktime, day) {
  //   let getDayWorkTime = arrayWorktime.filter(
  //     (time) => time.dia_semana === day
  //   );

  //   if (getDayWorkTime.length !== 0) {
  //     let struct = [getDayWorkTime[0].hora_inicio, getDayWorkTime[0].hora_fim];
  //     return struct;
  //   }

  //   return null;
  // }

  // ################ NOVA FUNÇÃO PARA MONTAR HORAS DE TRABALHO - RETORNA TODOS COJUNTOS DE TRABALHOS ENCONTRADOS ################
  function mountHourWorkTime(arrayWorktime, day) {
    let getDayWorkTime = arrayWorktime.filter(
      (time) => time.dia_semana === day
    );

    let struct = [];

    for (const exemplo of getDayWorkTime) {
      const horarios = [exemplo.hora_inicio, exemplo.hora_fim];
      struct.push(...horarios);
    }

    return struct.length > 0 ? struct : null;
  }

  const sendSaveInteractionPost = () => {
    let objeto = {};

    // if (principalAdditionalField.length > 0) {
    //   variableValues.forEach(async (variable) => {
    //     const infoData = principalAdditionalField.find(
    //       (data) => data.id_campo_adicional === variable.id
    //     );

    //     if (infoData) {
    //       objeto = {
    //         ...objeto,
    //         [infoData.desc_campo_adicional]: variable.value,
    //       };
    //     }
    //   });
    // }

    if (caseDetail && caseDetail?.campos_adicionais?.length > 0) {
      caseDetail?.campos_adicionais.forEach(async (field) => {
        if (field) {
          objeto = {
            ...objeto,
            [field.nomecampoadd]: field.valorcampoadd,
          };
        }
      });
    }

    postMessage("saveThisInteraction", {
      payload: true,
      caseId: value && value.id_ticket,
      destination: interaction.destination,
      infos: {
        assunto: caseDetail.assunto,
        observacao: caseDetail.observacao,
        dt_abertura: caseDetail.dt_abertura,
        dt_ultima_atualizacao: caseDetail.dt_ultima_atualizacao,
        prazo_inicio: caseDetail.prazo_inicio,
        prazo_resolucao: caseDetail.prazo_resolucao,
        codigo_ticket: caseDetail.codigo_ticket,
        ...objeto,
      },
    });
  };

  const columnsSplitView = useMemo(
    () => [
      {
        Header: "Casos",
        accessor: "cases",
        id: "cases",
        disableFilters: true,
        display: true,
        sortType: defaultSorting,
      },
      {
        Header: "Prazo",
        accessor: "term",
        id: "term",
        disableFilters: true,
        display: true,
        sortType: defaultSorting,
      },
      {
        Header: "Contato",
        accessor: "motive",
        id: "motive",
        disableFilters: true,
        display: true,
        sortType: defaultSorting,
      },
      {
        Header: "Status",
        accessor: "status",
        id: "status",
        disableFilters: true,
        display: true,
        sortType: defaultSorting,
      },
    ],
    []
  );

  const cases = useMemo(() => {
    if (casesData) {
      const dataRefactored = refactoryCasesListSplitView(casesData);
      setPageStatus("awaiting");
      return dataRefactored;
    }
  }, [casesData]);

  const casesFiltered = useMemo(() => {
    if (filteredCaseData) {
      const dataRefactored = refactoryCasesListSplitView(filteredCaseData);
      setPageStatus("awaiting");
      return dataRefactored;
    }
  }, [filteredCaseData]);

  const getCaseInfo = async () => {
    setPageStatus("loading");

    try {
      await Promise.all([
        getChannelList(),
        getListRequest(),
        getListPriority(),
        getListStatus(),
        getListUsers(),
        getEscalateList(),
        getRouteAudit(),
        getAdditionalFieldsIntegration()
      ])
    } catch (error) {
      console.error("Erro ao obter informações do caso:", error);
    }

    setPageStatus("awaiting");
  };

  // function getListAdditionalFields(campos_adicionais) {
  //   if (principalAdditionalField.length > 0) {
  //     let valores = [];

  //     principalAdditionalField.forEach((datum) => {
  //       let findValue = campos_adicionais.find(
  //         (value) => value.idcampoadd === datum.id_campo_adicional
  //       );

  //       let requestItem = {};

  //       requestItem.id = datum.id_campo_adicional;
  //       requestItem.value = findValue ? findValue.valorcampoadd : "";

  //       valores.push(requestItem);
  //     });

  //     setVariableValues(valores);
  //     // setHeadersAdditional(valores);

  //     // setReasonsTypesList(dataUpdated);
  //   }
  // }

  function isValidateCustomFields() {
    const fieldsRequiredParent = additionalFieldsParent.filter(
      (field) => field?.obrigatorio === true && !field.valor_default
    );

    const childFieldsArray = Object.values(additionalChildFields);

    const fieldsRequiredChild = childFieldsArray.flatMap(childFields =>
      childFields.filter((child) => child?.obrigatorio === true && !child.valor_default)
    );

    if (fieldsRequiredParent.length > 0) {
      setRequiredField(true);
      return false;
    } else if (fieldsRequiredChild.length > 0) {
      setRequiredChild(true);
      return false;
    }

    return true;
  }

  //################### FUNÇÃO QUE ATUALIZA OS CAMPOS PERSONALIZADOS PAI ###################
  const updateAdditionalFields = async (fields, token, arrayCase) => {
    const promises = fields.map(async (field) => {
      const existingCampo = arrayCase?.campos_adicionais.find(campo => campo.idcampoadd === field.id_campo_adicional);

      const shouldUpdate = existingCampo ? field.valor_default !== existingCampo.valorcampoadd : true;

      if (!shouldUpdate) {
        return 200;
      }

      const dataField = {
        id_ticket: arrayCase.id_ticket,
        id_campo: field.id_campo_adicional,
        valor_campo: field.valor_default ?? null,
        label: currentStatusTexts[field.id_campo_adicional] || ""
      };

      let response;

      if (existingCampo) {
        response = await APICase.updateTicketAdditionalFields(token, dataField)
      } else {
        if (field.valor_default !== null) {
          response = await APICase.createTicketAdditionalFields(token, dataField)
        } else {
          return 200
        }
      }

      return response.status;
    });

    return Promise.all(promises);
  };

  //################### FUNÇÃO QUE ATUALIZA OS CAMPOS PERSONALIZADOS FILHO ###################
  const updateAdditionalChildFields = async (childs, token, arrayCase) => {
    const promises = childs.map(async (childFields) => {
      const childPromises = childFields.map(async (childField) => {
        const existingCampo = arrayCase?.campos_adicionais.find(campo => campo.idcampoadd === childField.id_campo_adicional);

        const shouldUpdate = existingCampo ? childField.valor_default !== existingCampo.valorcampoadd : true;

        if (!shouldUpdate) {
          return 200;
        }

        const dataChild = {
          id_ticket: arrayCase.id_ticket,
          id_campo: childField.id_campo_adicional,
          valor_campo: childField.valor_default ?? null,
          label: currentStatusTexts[childField.id_campo_adicional] || ""
        };

        let response;

        if (existingCampo) {
          response = await APICase.updateTicketAdditionalFields(token, dataChild)
        } else {
          if (childField.valor_default !== null) {
            response = await APICase.createTicketAdditionalFields(token, dataChild)
          } else {
            return 200
          }
        }

        if (response.status !== 200) {
          Toast(
            "Não foi possível atualizar os campos adicionais filho!",
            "Verifique sua conexão e tente novamente mais tarde.",
            "error"
          );
        }

        return response.status;
      });

      return Promise.all(childPromises);
    });

    return Promise.all(promises);
  };

  //################### NOVA FUNÇÃO QUE LIDA COM A ATUALIZAÇÃO DOS CAMPOS PERSONALIZADOS ###################
  const handleUpdateAdditional = async () => {
    if (!isValidateCustomFields()) {
      setRequiredField(true);
      setRequiredChild(true);

      Toast(
        "Erro ao atualizar campos adicionais!",
        "Preencha os campos obrigatórios.",
        "error",
      );

      return;
    }

    setIsUpdatingCustomFields(true);

    const arrayCase = { ...caseDetail };

    const logInfoField = {
      tipo_operacao: 2,
      id_campo: 26,
      valor_atual: "",
      valor_novo: "",
      id_campo_adicional: null,
      id_ticket: value && value.id_ticket,
    };

    const logInfoChild = {
      tipo_operacao: 2,
      id_campo: 26,
      valor_atual: "",
      valor_novo: "",
      id_campo_adicional: null,
      id_ticket: value && value.id_ticket,
    };

    const updateParentFieldsPromise = updateAdditionalFields(additionalFieldsParent, token, arrayCase);

    const childFieldsArray = Object.values(additionalChildFields);

    const updateChildFieldsPromise = childFieldsArray.length > 0 ?
      updateAdditionalChildFields(childFieldsArray, token, arrayCase) : Promise.resolve([]);

    const [verificarResponse, checkChildCustomFields] = await Promise.all([
      updateParentFieldsPromise,
      updateChildFieldsPromise,
    ]);

    for (const field of additionalFieldsParent) {
      const correspondingField = caseDetail?.campos_adicionais?.find(
        item => item.idcampoadd === field.id_campo_adicional
      );

      let valorCampoAdd = correspondingField?.valorcampoadd;

      if (correspondingField && valorCampoAdd !== null && valorCampoAdd !== field.valor_default) {
        logInfoField.valor_atual = correspondingField.valorcampoadd;
        logInfoField.valor_novo = field.valor_default;
        logInfoField.id_campo_adicional = field.id_campo_adicional;

        await updateCase(arrayCase, null, logInfoField);
      }
    }

    for (const childFields of childFieldsArray) {
      for (const childField of childFields) {
        const correspondingField = caseDetail?.campos_adicionais?.find(
          item => item.idcampoadd === childField.id_campo_adicional
        );

        let valorCampoAdd = correspondingField?.valorcampoadd;

        if (correspondingField && valorCampoAdd !== null && valorCampoAdd !== childField.valor_default) {
          logInfoChild.valor_atual = correspondingField.valorcampoadd;
          logInfoChild.valor_novo = childField.valor_default;
          logInfoChild.id_campo_adicional = childField.id_campo_adicional;

          await updateCase(arrayCase, null, logInfoChild);
        }
      }
    }

    if (
      verificarResponse.every(response => response === 200) ||
      checkChildCustomFields.every(response => response === 200)
    ) {
      Toast(
        "Campos atualizados!",
        "Campos atualizados com sucesso.",
        "success"
      );

      handleInteractionSaveImmediate(value && value.id_ticket);
    }

    await getDetails();

    setIsUpdatingCustomFields(false);
  };

  //################### ANTIGA FUNÇÃO QUE LIDA COM A ATUALIZAÇÃO DOS CAMPOS PERSONALIZADOS ###################
  // const handleUpdateAdditional = async () => {
  //   let verificarTodos = 0;
  //   const arrayCase = { ...caseDetail };

  //   for (let index = 0; index < variableValues.length; index++) {
  //     if (arrayCase.campos_adicionais.length === 0) {
  //       const valor1 = variableValues[index].value;

  //       if (valor1 === "") verificarTodos++;
  //     } else {
  //       const valor1 = variableValues[index].value;
  //       let valor2 = "";

  //       if (arrayCase.campos_adicionais[index]) {
  //         valor2 = arrayCase.campos_adicionais[index].valorcampoadd;
  //         if (valor1 === valor2) verificarTodos++;
  //       }
  //     }
  //   }

  //   if (verificarTodos === variableValues.length) {
  //     return console.log("Nenhum valor foi alterado.");
  //   }

  //   let verificarResponse = [];
  //   for (const variable of variableValues) {
  //     //adicionar campo a mais, problema
  //     let responseField;
  //     let dataField = {
  //       id_ticket: arrayCase.id_ticket,
  //       id_campo: variable.id,
  //       valor_campo: variable.value,
  //     };
  //     if (arrayCase.campos_adicionais.length > 0) {
  //       const newValor = arrayCase.campos_adicionais.find(
  //         (campo) => campo.idcampoadd === variable.id
  //       );

  //       if (newValor) {
  //         newValor.valorcampoadd = variable.value;
  //         responseField = await APICase.updateTicketAdditionalFields(
  //           token,
  //           dataField
  //         );
  //       } else {
  //         responseField = await APICase.createTicketAdditionalFields(
  //           token,
  //           dataField
  //         );
  //       }
  //     } else {
  //       responseField = await APICase.createTicketAdditionalFields(
  //         token,
  //         dataField
  //       );
  //     }

  //     verificarResponse.push(responseField.status);
  //     if (responseField.status !== 200) {
  //       Toast(
  //         "Não foi possível adicionar os campos variaveis a esse caso!",
  //         "Tente novamente mais tarde!.",
  //         "warn"
  //       );
  //     }
  //   }

  //   if (verificarResponse.every((response) => response === 200)) {
  //     Toast(
  //       "Campos atualizados!",
  //       "Campos atualizados com sucesso.",
  //       "success"
  //     );
  //     handleInteractionSaveImmediate();
  //   }

  //   await getDetails(casesData);
  // };

  const checkRequiredFields = (field, additionalFieldsParent) => {
    const integrationField = additionalFieldsIntegration.find(
      integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
    );

    if (!integrationField) return true;

    const allRequiredFieldsFilled = integrationField.campos_adicionais_obrigatorios.every(integrationField => {
      const correspondingField = additionalFieldsParent.find(
        parentField => parentField.id_campo_adicional === integrationField.idcampos
      );

      return correspondingField &&
        correspondingField.valor_default !== "" &&
        correspondingField.valor_default !== null &&
        correspondingField.valor_default !== undefined;
    });

    return allRequiredFieldsFilled;
  };

  const handleSelectChange = (field, value, onChangeHandler) => {
    const requiredFieldsFilled = checkRequiredFields(field, additionalFieldsParent);

    if (!requiredFieldsFilled) {
      const integrationField = additionalFieldsIntegration.find(
        integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
      );

      if (integrationField) {
        const requiredFields = integrationField.campos_adicionais_obrigatorios.map(integrationField => {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          return correspondingField ? correspondingField.desc_campo_adicional : null;
        }).filter(desc => desc !== null);

        Toast(
          "Não foi possível selecionar esse campo!",
          <p>Para selecionar, preencha os campos obrigatórios: <b>{requiredFields.join(', ')}</b></p>,
          "warn"
        );
      }
      return;
    }

    onChangeHandler(field.id_campo_adicional, value.id);
    getAdditionalChildFields(field.id_campo_adicional, value.label);

    setCurrentStatusTexts(prev => ({
      ...prev,
      [field.id_campo_adicional]: value.label,
    }));

    const triggerField = additionalFieldsIntegration.find(
      integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
    );

    if (triggerField) {
      setIsOptionSelected(true);
    }
  };

  const renderAdditionalChildFields = (field, onChangeHandler, fieldError) => {
    let renderedFields = [
      <FieldContainer
        key={field.id_campo_adicional}
        title={field.desc_campo_adicional}
        required={field.obrigatorio === true && field.obrigatorio}
        titleSize="small"
        className="custom-field-container"
        labelRightIcon={<FaQuestionCircle />}
        labelRightTooltipContent={field.label_campo_adicional}
      >
        <Select
          placeholder="Selecione"
          className="modal-create-case__dropdown"
          value={field.valor_default}
          options={JSON.parse(field.json)}
          onSelect={(value) => handleSelectChange(field, value, onChangeHandler)}
          error={fieldError}
        />
      </FieldContainer>,
    ];

    const childFields = additionalChildFields[field.id_campo_adicional] || [];

    if (childFields?.length > 0) {
      const additionalRenderedFields = childFields?.map((childField, index) => {
        const isChildRequiredAndEmpty = (child) => {
          return child.obrigatorio === true && !child.valor_default;
        };

        const childFieldError = requiredChild && isChildRequiredAndEmpty(childField);

        return (
          <FieldContainer
            key={childField.id_campo_adicional}
            title={childField.desc_campo_adicional}
            required={childField.obrigatorio === true && childField.obrigatorio}
            titleSize="small"
            className={`custom-field-container ${childField.id_tipo_campo_adicional === 10 && "textarea-container"}`}
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={childField.label_campo_adicional}
          >
            {childField.id_tipo_campo_adicional === 1 && (
              <Input
                type="number"
                placeholder="Digite a prioridade"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 2 && (
              <DatePicker.TimeRangeInput
                automaticSet
                className=""
                valueTime={childField.valor_default}
                setValueTime={(date) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: date };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                errorInput={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 3 && (
              <DatePicker.DateRangeInput
                selectRangeType="start"
                value={childField.valor_default}
                setDate={(date) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: date };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                isClearable={false}
              />
            )}

            {childField.id_tipo_campo_adicional === 4 && (
              <Select
                placeholder="Selecione"
                value={childField.valor_default}
                onSelect={(value) => {
                  const newValue = value;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue.id };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 5 && (
              <Input
                placeholder="Digite as horas"
                type={childField.mascara ? "numberMask" : "text"}
                mask={childField.mascara ? childField.mascara : ""}
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 6 && (
              <Input
                type={childField.mascara ? "numberMask" : "text"}
                mask={childField.mascara ? childField.mascara : ""}
                placeholder="Digite o prazo em horas"
                value={field.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 7 && (
              <Input
                type="email"
                placeholder="Digite o e-mail"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 8 && (
              <Input
                type="monetary"
                placeholder="Digite o valor"
                prefix={childField.mascara ?? "R$"}
                value={childField.valor_default ?? ""}
                onValueChange={(values, sourceInfo) => {
                  const { floatValue } = values;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: floatValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 9 && (
              <Checkbox
                isChecked={childField.valor_default}
                onChange={(newValue) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue ? true : null };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
              />
            )}

            {childField.id_tipo_campo_adicional === 10 && (
              <Input
                placeholder="Digite a informação aqui"
                type="textarea"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}
          </FieldContainer>
        );
      });

      renderedFields = renderedFields.concat(additionalRenderedFields);
    }

    return renderedFields;
  }

  //################### NOVA FUNÇÃO PARA RENDERIZAR CAMPOS PERSONALIZADOS ###################
  const renderCustomFields = (field, onChangeHandler, onBackspaceHandler) => {
    const isFieldRequiredAndEmpty = (field) => {
      return field.obrigatorio === true && !field.valor_default;
    };

    const fieldError = requiredField && isFieldRequiredAndEmpty(field);

    switch (field.id_tipo_campo_adicional) {
      case 1:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={`${field.desc_campo_adicional}`}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="number"
              placeholder="Digite a prioridade aqui"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        )
        break;

      case 2:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.TimeRangeInput
              automaticSet
              className=""
              valueTime={field.valor_default}
              setValueTime={(date) => onChangeHandler(field.id_campo_adicional, date)}
              errorInput={fieldError}
            />
          </FieldContainer>
        )
        break;

      case 3:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.DateRangeInput
              selectRangeType="start"
              value={field.valor_default}
              setDate={(date) => onChangeHandler(field.id_campo_adicional, date)}
              isClearable={false}
            />
          </FieldContainer>
        )
        break;

      case 4:
        return renderAdditionalChildFields(field, onChangeHandler, fieldError)
        break;

      case 5:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              placeholder="Digite a informação aqui"
              type={field.mascara ? "numberMask" : "text"}
              mask={field.mascara ? field.mascara : ""}
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        )
        break;

      case 6:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type={field.mascara ? "numberMask" : "text"}
              mask={field.mascara ? field.mascara : ""}
              placeholder="Digite o prazo em horas"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer >
        )
        break;

      case 7:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="email"
              placeholder="Digite o e-mail"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer >
        )
        break;

      case 8:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="monetary"
              placeholder="Digite o valor"
              prefix={field.mascara ?? "R$"}
              value={field.valor_default ?? ""}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              onValueChange={(values, sourceInfo) => {
                const { floatValue } = values;

                onChangeHandler(field.id_campo_adicional, floatValue)
              }}
            />
          </FieldContainer >
        )
        break;

      case 9:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Checkbox
              isChecked={field.valor_default}
              onChange={(newValue) => {
                onChangeHandler(field.id_campo_adicional, newValue ? true : null);
              }}
            />
          </FieldContainer >
        )
        break;

      case 10:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className={`custom-field-container ${field.id_tipo_campo_adicional === 10 && "textarea-container"}`}
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              placeholder="Digite a informação aqui"
              type="textarea"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        )
        break;
      default:
        break;
    }
  }

  //################### ANTIGA FUNÇÃO PARA RENDERIZAR CAMPOS PERSONALIZADOS ###################
  // const handleVariableFields = (field) => {
  //   let actualValue = variableValues.find(
  //     (value) => value.id === field.id_campo_adicional
  //   );

  //   switch (field.tipo_campo_adicional) {
  //     case "text":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="field-container-case-subject"
  //         >
  //           <Input
  //             type={field.mascara ? "numberMask" : "text"}
  //             mask={field.mascara ? field.mascara : ""}
  //             placeholder="Digite a informação aqui"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = event;
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //             disabled={
  //               permissionProfile.camposAdicionais.edicao ? false : true
  //             }
  //           />
  //         </FieldContainer>
  //       );

  //     case "select":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="field-container-case-request-type "
  //           htmlFor={""}
  //         >
  //           <Select
  //             placeholder="Selecione"
  //             className={"modal-create-case__dropdown"}
  //             value={actualValue.value}
  //             options={JSON.parse(field.json)}
  //             onSelect={(event) => {
  //               actualValue.value = event.label;
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={false}
  //             canBeEditable={permissionProfile.camposAdicionais.edicao}
  //           />
  //         </FieldContainer>
  //       );

  //     case "integer":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="container-addContact"
  //         >
  //           <Input
  //             className="input-telephone"
  //             type="number"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = Number(event);
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //             disabled={
  //               permissionProfile.camposAdicionais.edicao ? false : true
  //             }
  //             placeholder={"Digite o valor aqui"}
  //           />
  //         </FieldContainer>
  //       );

  //     default:
  //       return <></>;
  //   }
  // };

  window.onresize = checkResize;

  async function getAdditionalFieldsParent() {
    const response = await APIAdditionalFields.getAdditionalFieldsParent(token);

    if (response.status === 200) {
      const additionalFields = response.data;

      if (caseDetail?.campos_adicionais) {
        const combinedFields = additionalFields.map(field => {
          const correspondingField = caseDetail.campos_adicionais?.find(
            detailField => detailField.idcampoadd === field.id_campo_adicional
          );

          if (correspondingField) {
            return {
              ...field,
              valor_default: field.id_tipo_campo_adicional === 4 ?
                Number(correspondingField.valorcampoadd) : correspondingField.valorcampoadd === "null" ?
                  null : correspondingField.valorcampoadd,
              desc_campo_adicional: field.desc_campo_adicional,
            };
          } else {
            return field;
          }
        });

        setAdditionalFieldsParent(combinedFields);
      } else {
        setAdditionalFieldsParent(additionalFields);
      }
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  };

  async function getAdditionalChildFields(fieldId, statusText) {
    const response = await APIAdditionalFields.getAdditionalChildFields(token, fieldId, statusText);

    if (response.status === 200) {
      const additionalChildFields = response.data;

      const combinedFields = additionalChildFields.map(field => {
        const correspondingField = caseDetail.campos_adicionais.find(
          detailField => detailField.idcampoadd === field.id_campo_adicional
        );

        if (correspondingField) {
          return {
            ...field,
            valor_default: field.id_tipo_campo_adicional === 4 ?
              Number(correspondingField.valorcampoadd) : correspondingField.valorcampoadd === "null" ?
                null : correspondingField.valorcampoadd,
            desc_campo_adicional: field.desc_campo_adicional,
          };
        } else {
          return field;
        }
      });

      setAdditionalChildFields(prevMap => ({
        ...prevMap,
        [fieldId]: combinedFields
      }));
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getAdditionalFieldsIntegration() {
    const response = await APIAdditionalFields.getAdditionalFieldsIntegration(token);

    if (response.status === 200) {
      const updateData = response.data.map(item => ({
        ...item,
        campos_adicionais_obrigatorios: JSON.parse(item.campos_adicionais_obrigatorios),
        valor_padrao: item.valor_padrao === "null" && null
      }))

      setAdditionalFieldsIntegration(updateData)
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function postProcessedAdditionalFields(
    additionalFieldsIntegration,
    additionalFieldsParent
  ) {
    try {
      const results = [];

      for (const field of additionalFieldsIntegration) {
        const {
          url,
          metodo,
          token,
          campos_adicionais_obrigatorios,
          id_campo_gatilho,
          id_campo_adicional_retorno,
          valor_padrao
        } = field;

        const triggerField = additionalFieldsParent.find(
          parentField => parentField.id_campo_adicional === id_campo_gatilho
        );

        const triggerFieldFilled =
          triggerField &&
          triggerField.valor_default !== "" &&
          triggerField.valor_default !== null &&
          triggerField.valor_default !== undefined;

        if (!triggerFieldFilled || !isOptionSelected) {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
          continue;
        }

        const allRequiredFieldsFilled = campos_adicionais_obrigatorios.every(integrationField => {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          return correspondingField &&
            correspondingField.valor_default !== "" &&
            correspondingField.valor_default !== null &&
            correspondingField.valor_default !== undefined;
        });

        if (!allRequiredFieldsFilled) {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
          continue;
        }

        let payload = {};

        for (const integrationField of campos_adicionais_obrigatorios) {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          payload[correspondingField.desc_campo_adicional] =
            correspondingField ? correspondingField.valor_default : valor_padrao;
        }

        const triggerFieldDesc = triggerField.desc_campo_adicional;
        payload[triggerFieldDesc] = triggerField.valor_default;

        const config = {
          method: metodo.toLowerCase(),
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(payload)
        };

        const response = await axios(config);

        if (response.status === 200 && response.data) {
          const returnValue = response.data;

          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: response.data || valor_padrao
          });

          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === id_campo_adicional_retorno
          );

          if (correspondingField) {
            correspondingField.valor_default = returnValue.id || returnValue.valor;
          }
        } else {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
        }
      }

      return results;
    } catch (error) {
      console.log('postProcessedAdditionalFields -> Error en el servidor:', error);
      return error;
    }
  }

  //USE MEMOS
  const memoizedFields = useMemo(
    () => additionalFieldsRef.current, [additionalFieldsRef.current]
  );

  //USE EFFECTS
  useEffect(() => {
    if (pageStatus === "awaiting") {
      checkResize();
    }
  }, [pageStatus]);

  // useEffect(async () => {
  //   setTimeout(() => {
  //     getCaseInfo();
  //   }, [3000]);
  //   // sendSaveInteractionPost();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(async () => {
        await getCaseInfo();
      }, 3000);
    };

    fetchData();
  }, []);

  useEffect(() => {
    getAdditionalFieldsParent();
  }, [caseDetail?.campos_adicionais]);

  useEffect(() => {
    additionalFieldsRef.current = additionalFieldsParent;
  }, [additionalFieldsParent]);

  useEffect(() => {
    if (additionalFieldsIntegration.length > 0 && memoizedFields.length > 0) {
      (async () => {
        const data = await postProcessedAdditionalFields(
          additionalFieldsIntegration,
          memoizedFields
        );

        setProcessedAdditionalFields(data);
        setIsOptionSelected(false);
      })();
    }
  }, [additionalFieldsIntegration, memoizedFields, isOptionSelected]);

  useEffect(() => {
    if (caseDetail?.campos_adicionais?.length > 0) {
      caseDetail.campos_adicionais?.forEach(field => {
        const matchingField = additionalFieldsParent.find(
          fieldParent => fieldParent.id_campo_adicional === field.idcampoadd
        );

        if (matchingField) {
          field.id_tipo_campo_adicional = matchingField.id_tipo_campo_adicional;
        }
      });
    }
  }, [caseDetail?.campos_adicionais]);

  useEffect(() => {
    if (caseDetail?.campos_adicionais?.length > 0) {
      caseDetail.campos_adicionais?.forEach(fields => {
        const { idcampoadd, label, id_tipo_campo_adicional } = fields;

        if (id_tipo_campo_adicional === 4) {
          fields.valorcampoadd = Number(fields.valorcampoadd);
        } else if (fields.valorcampoadd === "null") {
          fields.valorcampoadd = null
        }

        if (id_tipo_campo_adicional === 4 && label !== "") {
          getAdditionalChildFields(idcampoadd, label);
        }
      });
    }
  }, [caseDetail?.campos_adicionais]);

  // useEffect(() => {
  //   if (caseDetail) {
  //     sendSaveInteractionPost();
  //   }
  // }, [caseDetail, variableValues]);

  useEffect(() => {
    if (caseDetail && Object.keys(caseDetail).length > 0) {
      sendSaveInteractionPost();
    }
  }, [caseDetail]);

  useEffect(() => {
    if (casesData && detailCaseId) {
      getDetails(casesData);
      getInteractionList(casesData);
    }
  }, [casesData, detailCaseId]);

  useEffect(() => {
    if (detailCaseId && loading) {
      const id = setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => clearTimeout(id);
    }
  }, [detailCaseId, loading, pageStatus]);

  return (
    <main
      className={`${interaction.clientId === caseDetail.id_contato
        ? "case-details-main-interaction"
        : ""
        } cases-details-splitview`}
    >
      <div className="splitView-table">
        <Table
          className="table-splitView"
          columns={columnsSplitView}
          data={researchPerforms ? casesFiltered : cases}
          emptyState={emptyState}
          setEmptyState={setEmptyState}
          keepLinePerPage
          fixedHeader
        />
      </div>

      {detailCaseId ? (
        <>
          {loading ? (
            <Loading.Paperplane className="loading-cases-list-splitView" />
          ) : (
            <div className="splitView-tabs">
              <MenuTabs
                limit={refPage?.current?.clientWidth > 800 ? 4 : 3}
                informationElements={caseDetail}
                hide={tabHide}
                tabs={[
                  {
                    id: "1",
                    title: {
                      text: "Casos",
                      type: "fill",
                    },
                    content: (
                      <div className="splitView-tab-information">
                        <div className="case-splitView-client">
                          <div className="case-splitView-client__case-information">
                            <div className="contact-client">
                              <FieldContainer>
                                <Typography.H4 weight="400" color="gray-180">
                                  {caseDetail?.codigo_ticket}
                                </Typography.H4>
                              </FieldContainer>

                              {user.permissionId === 2 ? (
                                <>
                                  <ButtonSearch
                                    createBy={userCreator?.nome_usuario}
                                    list={users}
                                    type="image"
                                    value={userProfile?.nome_usuario}
                                    placeholder="Nenhum agente atribuído"
                                    onClick={(value) => associateUser(value)}
                                  />
                                </>
                              ) : (
                                <>
                                  {userProfile.nome_usuario ? (
                                    <>
                                      <IconViewUser />
                                      <ImageProfile profile={userProfile} />

                                      <Typography.H6 color="gray-400">
                                        {userProfile.nome_usuario}
                                      </Typography.H6>
                                      {userProfile.gestor && (
                                        <Typography.Paragraph color="gray-200">
                                          Atribuído pelo gestor
                                        </Typography.Paragraph>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <IconViewUser />
                                      <Typography.H6 color="red">
                                        Nenhum agente atribuído
                                      </Typography.H6>
                                    </>
                                  )}
                                </>
                              )}
                            </div>

                            {permissionProfile.casos.exclusao && (
                              <Delete
                                onDelete={deleteCase}
                                title="Tem certeza que deseja remover este caso?"
                              />
                            )}
                          </div>

                          <div className="case-splitView-client__priority-status">
                            <DropdownPriority
                              autoWidth
                              className="case-priority"
                              value={priority}
                              options={priorityList}
                              label
                              onClick={(value) => {
                                setPriority(value);
                                const arrayCase = { ...caseDetail };
                                arrayCase.descricao_criticidade = value.label;
                                arrayCase.id_criticidade = value.id;
                                arrayCase.id_campo = 4;
                                setCaseDetail(arrayCase);
                                updateCase(arrayCase);
                              }}
                            />

                            <DropdownStatus
                              className="case-status"
                              value={caseDetail?.id_ticket_status}
                              options={statusList}
                              commentIdStatus={3}
                              idTicket={value && value.id_ticket}
                              escalateIdStatus={5}
                              escalateList={escalateList}
                              changeStatusAndEscalateTicket={
                                changeStatusAndEscalateTicket
                              }
                              onAdd={(text, files, response, audio) =>
                                postComment(text, files, response, audio)
                              }
                              onClick={(option) => {
                                changeStatusTicket(option);
                              }}
                              screen="details"
                              type={
                                permissionProfile.status.edicao ? "select" : ""
                              }
                            />

                            {caseDetail.escalatedAssociated &&
                              caseDetail?.escalatedAssociated.length !== 0 && (
                                <DropdownEscalate
                                  value={
                                    caseDetail?.escalatedAssociated[0]
                                      .id_escalonamento
                                  }
                                  options={escalateList}
                                  disable={
                                    caseDetail?.id_ticket_status !== 5
                                      ? true
                                      : false
                                  }
                                  onClick={(option) => {
                                    changeEscalateTicket(option);
                                  }}
                                />
                              )}
                          </div>

                          <div className="case-splitView-client__case-client">
                            <FieldContainer
                              title="Contato:"
                              titleSize="small"
                              htmlFor=""
                            >
                              <SelectSearch
                                paginated
                                style={{ padding: "0 3px" }}
                                placeholder="Busque pelo nome do contato"
                                getOptions={getListClients}
                                value={clientData}
                                onSelect={(client) => {
                                  setClientData(client);
                                  const objCase = { ...caseDetail };
                                  objCase.id_contato = client.id;
                                  objCase.nome_contato = client.label;
                                  objCase.nome_empresa = client.caption1;
                                  objCase.id_campo = 1;
                                  setCaseDetail(objCase);
                                  updateCase(objCase);
                                }}
                                canBeEditable={permissionProfile.contato.edicao}
                              />
                            </FieldContainer>

                            <FieldContainer title="Empresa:" titleSize="small">
                              {caseDetail?.nome_empresa ? (
                                <Typography.H5 color="gray-180">
                                  {caseDetail?.nome_empresa}
                                </Typography.H5>
                              ) : (
                                <Typography.H5 color="gray-180">
                                  Sem Empresa
                                </Typography.H5>
                              )}
                            </FieldContainer>

                            <FieldContainer
                              title="Canal de Origem:"
                              titleSize="small"
                              className="case-splitView-client__case-client__channel"
                            >
                              {caseDetail?.possui_interacao ? (
                                <div className="case-splitView-client__case-client__channel__origin">
                                  {caseDetail?.protocolo_externo ? (
                                    <>
                                      <Channel
                                        media={caseDetail?.nome_canal?.toLowerCase()}
                                      />
                                      <Typography.H5 color="gray-400">
                                        {caseDetail?.protocolo_externo}
                                      </Typography.H5>
                                    </>
                                  ) : (
                                    <>
                                      <Channel
                                        media={caseDetail?.nome_canal?.toLowerCase()}
                                      />
                                      <Typography.H5 color="gray-400">
                                        {caseDetail?.nome_canal}
                                      </Typography.H5>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <DropdownChannel
                                  value={{
                                    id: caseDetail?.id_canal
                                      ? caseDetail?.id_canal
                                      : 0,
                                    label: caseDetail?.nome_canal
                                      ? caseDetail?.nome_canal
                                      : " ",
                                  }}
                                  options={channelList}
                                  onClick={(event) => {
                                    const arrayCase = { ...caseDetail };
                                    arrayCase.id_canal = event.id;
                                    arrayCase.nome_canal = event.label;
                                    arrayCase.id_campo = 17;
                                    setCaseDetail(arrayCase);
                                    updateCase(arrayCase);
                                  }}
                                  errorType="border"
                                  label
                                />
                              )}
                            </FieldContainer>
                          </div>

                          <div className="case-splitView-client__case-deadline">
                            <FieldContainer
                              title="Prazo final do caso:"
                              titleSize="small"
                              htmlFor=""
                            >
                              <div
                                style={{
                                  pointerEvents: !editableField ? "none" : "",
                                }}
                              >
                                <DatePicker.TimeRangeInput
                                  valueTime={startDate}
                                  widthTotal={216}
                                  errorTime={
                                    isPast(new Date(startDate))
                                      ? caseDetail?.id_ticket_status === 3 ||
                                        caseDetail?.id_ticket_status === 4
                                        ? isAfter(
                                          new Date(caseDetail?.dt_fechamento),
                                          new Date(startDate)
                                        )
                                          ? true
                                          : false
                                        : true
                                      : false
                                  }
                                  setValueTime={(data) => {
                                    setStartDate(data);
                                    updateCase(caseDetail, data);
                                  }}
                                  disable={editableField}
                                />
                              </div>
                            </FieldContainer>
                            <FieldContainer
                              title="Solicitação:"
                              titleSize="small"
                            >
                              <SelectSearch
                                value={caseDetail?.id_solicitacao}
                                options={listRequest}
                                onSelect={(option) => {
                                  const arrayCase = { ...caseDetail };
                                  arrayCase.id_solicitacao = option.id;
                                  arrayCase.descricao_solicitacao =
                                    option.label;
                                  arrayCase.id_campo = 3;
                                  setCaseDetail(arrayCase);
                                  changeTypeRequest(option.id, true);
                                }}
                                placeholder="Selecione"
                                canBeEditable={
                                  permissionProfile.solicitacao.edicao
                                }
                                showAvatar={false}
                                valueDirect
                              />
                            </FieldContainer>
                            <FieldContainer title="Motivo:" titleSize="small">
                              <SelectSearch
                                value={caseDetail?.id_motivo}
                                options={listReasons}
                                onSelect={(option) => {
                                  getSlaRule(
                                    option.id,
                                    caseDetail?.id_solicitacao,
                                    option.label
                                  );
                                }}
                                placeholder="Selecione"
                                canBeEditable={permissionProfile.motivo.edicao}
                                showAvatar={false}
                                valueDirect
                              />
                            </FieldContainer>
                          </div>

                          <div className="container-interection-channels">
                            {interaction.clientId === caseDetail.id_contato ||
                              !caseDetail.telefone[0] ||
                              interaction.clientName ===
                              caseDetail.nome_contato ? (
                              ""
                            ) : (
                              <FieldContainer
                                titleSize="large"
                                title="Selecione o canal de comunicação:"
                                className="case-splitView-client__interection-channels"
                              >
                                <div className="case-splitView-client__interection-channels__content">
                                  {renderChannels()}
                                </div>
                              </FieldContainer>
                            )}
                          </div>

                          {/* ################ NOVA RENDERIZAÇÃO DE CAMPOS PERSONALIZADOS ################ */}
                          {additionalFieldsParent.length > 0 && (
                            permissionProfile.camposAdicionais.visualizacao && (
                              <div
                                className={`field-container-case-variable grid-span-2 
                                ${isOpenCustomFields ? "active-variable" : ""}`}
                              >
                                <div
                                  className="field-container-case-variable-control"
                                  onClick={() => setIsOpenCustomFields(!isOpenCustomFields)}
                                >
                                  <Typography.H5
                                    color={isOpenCustomFields ? "blue-selected-4" : "black"}
                                    weight={500}
                                  >
                                    Campos personalizados
                                  </Typography.H5>

                                  <ArrowIcon />
                                </div>

                                {isOpenCustomFields && (
                                  <div className="field-container-case-variable-container">
                                    <div className="field-container-case-variable-fields">
                                      {additionalFieldsParent.map((field) =>
                                        renderCustomFields(field, (fieldId, value) => {
                                          setAdditionalFieldsParent(prevFields =>
                                            prevFields.map(prevField =>
                                              prevField.id_campo_adicional === fieldId ? { ...prevField, valor_default: value } : prevField
                                            )
                                          );
                                        }, handleBackspace)
                                      )}
                                    </div>

                                    <Button
                                      className="field-container-case-variable__btn-confirm"
                                      disabled={isUpdatingCustomFields === true}
                                      onClick={() => handleUpdateAdditional()}
                                    >
                                      {isUpdatingCustomFields ? (
                                        <Loading.Dots />
                                      ) : (
                                        <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                                      )}
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )
                          )}

                          {/* {principalAdditionalField.length > 0 &&
                            permissionProfile.camposAdicionais.visualizacao && (
                              <div
                                className={`field-container-case-variable grid-span-2 ${openVariable ? "active-variable" : ""
                                  }`}
                              >
                                <div
                                  className="field-container-case-variable-control"
                                  onClick={() => setOpenVariable(!openVariable)}
                                >
                                  <Typography.H5
                                    color={
                                      openVariable ? "blue-selected-4" : "black"
                                    }
                                    weight={500}
                                  >
                                    Campos personalizados
                                  </Typography.H5>
                                  <ArrowIcon />
                                </div>
                                {openVariable && (
                                  <div className="field-container-case-variable-container">
                                    <div className="field-container-case-variable-fields">
                                      {principalAdditionalField.map((field) =>
                                        handleVariableFields(field)
                                      )}
                                    </div>
                                    <Button
                                      className="field-container-case-variable__btn-confirm"
                                      onClick={() => {
                                        handleUpdateAdditional();
                                      }}
                                    >
                                      <Typography.H4 weight={"bold"}>
                                        Salvar
                                      </Typography.H4>
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )} */}

                          <div className="case-splitView-client__subject">
                            <FieldContainerEdit
                              // title="Assunto"
                              typography={
                                <Typography.H6 color="gray-600" weight="400">
                                  Assunto
                                </Typography.H6>
                              }
                              className="textarea-subject"
                              type="no-scroll"
                              iconsEdit={true}
                              content={caseDetail?.assunto}
                              required
                              onEdit={(value) => {
                                const arrayCase = { ...caseDetail };
                                arrayCase.assunto = value;
                                arrayCase.id_campo = 6;
                                updateCase(arrayCase);
                                setCaseDetail(arrayCase);
                              }}
                              canBeEditable={permissionProfile.assunto.edicao}
                            />
                          </div>

                          <div className="case-splitView-client__description">
                            <FieldContainerEdit
                              // title="Descrição do caso"
                              typography={
                                <Typography.H6 color="gray-600" weight="400">
                                  Descrição do caso
                                </Typography.H6>
                              }
                              className="textarea-description"
                              content={caseDetail?.observacao}
                              required
                              onEdit={(value) => {
                                const arrayCase = { ...caseDetail };
                                arrayCase.observacao = value;
                                arrayCase.id_campo = 7;
                                updateCase(arrayCase);
                                setCaseDetail(arrayCase);
                              }}
                              canBeEditable={permissionProfile.descricao.edicao}
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: "2",
                    title: {
                      text: "Comentários",
                      type: "fill",
                    },
                    content: (
                      <CaseComments
                        id={value && value.id_ticket}
                        handleInteractionSaveImmediate={
                          handleInteractionSaveImmediate
                        }
                        updateAgent={associateUser}
                        permissions={permissionProfile.comentarios}
                      />
                    ),
                  },
                  {
                    id: "3",
                    title: {
                      text: "Interações",
                      type: "fill",
                    },
                    content: (
                      <CaseInteractions
                        interactionList={interactionList}
                        canBeVisualized={
                          permissionProfile.interacoes.visualizacao
                        }
                        actionVisualization={
                          permissionProfile.acaoInteracoes.visualizacao
                        }
                      />
                    ),
                  },
                  {
                    id: "4",
                    title: {
                      text: "Tarefas",
                      type: "fill",
                    },
                    content: (
                      <TaskList
                        customEmptyTaskDataSplitview
                        createTaskSplitview
                        deleteTaskSplitview
                        disassociateTaskSplitview
                        caseDetail={caseDetail}
                        casesData={casesData}
                        setCasesData={setCasesData}
                        onRefreshPage={getCaseInfo}
                        customEmptyTaskData={customEmptyTaskData}
                        clientData={clientData}
                        tagLimit={tabHide ? 2 : 1}
                        getDetails={getDetails}
                        permissions={permissionProfile.tarefas}
                        caseIdSplitView={value && value.id_ticket}
                        setLoading={setLoading}
                        handleCreateTask={handleCreateTask}
                        handleDeleteTask={handleDeleteTask}
                        handleDisassociateTask={handleDisassociateTask}
                      />
                    ),
                  },
                  {
                    id: "5",
                    title: {
                      text: "Comunicados",
                      type: "fill",
                    },
                    content: (
                      <CaseCommunications
                        id={value && value.id_ticket}
                        case={caseDetail}
                        handleInteractionSaveImmediate={
                          handleInteractionSaveImmediate
                        }
                        updateAgent={associateUser}
                        permissions={permissionProfile.comunicados}
                      />
                    ),
                  },
                  {
                    id: "6",
                    title: {
                      text: "Histórico",
                      type: "fill",
                    },
                    content: (
                      <CaseHistoric
                        id={value && value.id_ticket}
                        token={token}
                        canBeVisualized={
                          permissionProfile.historico.visualizacao
                        }
                      />
                    ),
                  },
                ]}
              />
            </div>
          )}
        </>
      ) : (
        <div className="empty-state-cases-list-splitView">
          <div className="empty-state-cases-list-splitView__icon">
            <IconEmptyStateCases />
          </div>
          <Typography.H4 weight="400" color="black">
            Nenhum caso selecionado
          </Typography.H4>
        </div>
      )}
    </main>
  );
}
