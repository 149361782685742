//GLOBAL - components from npm
import React, { useState } from "react";
import { useHistory } from "react-router";

//STYLES
import "./create.scss";

//COMPONENTS
import { Typography, Button } from "../../../components/general";
import { FieldContainer } from "../../../components/data-display";
import {
  Input,
  DatePicker,
  TagList,
  Dropzone,
} from "../../../components/data-entry";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconUndo } from "../../../assets/icons/Undo.svg";
import { ReactComponent as IconTags } from "../../../assets/icons/Tags.svg";
import { ReactComponent as IconViewUser } from "../../../assets/icons/ViewUser.svg";

export default function Create() {
  //GENERAL
  const history = useHistory();

  //STATES
  const [imageProfile, setImageProfile] = useState();
  const [agente, setAgente] = useState("Lucas Romero Cardim");
  const [startDate, setStartDate] = useState(new Date());
  const [tagsList, setTagsList] = useState([]);

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <div className="create-task">
      <header className="create-task__header">
        <IconUndo onClick={() => history.push("/interacao-ativa/tarefas")} />
        <Typography.H4 color="gray-400">Adicionar nova tarefa</Typography.H4>
      </header>
      <div className="create-task-container">
        <div className="create-task-container__client">
          {imageProfile ? (
            <img alt="imagem do agente" src={imageProfile} />
          ) : (
            <div className="create-task-container__client__img">
              <Typography.H2 weight="bold" color="white">
                {agente.slice(0, 1)}
              </Typography.H2>
            </div>
          )}
          <Typography.H5 weight="bold" color="gray-400">
            {agente}
          </Typography.H5>
        </div>
        <div className="create-task-container__client-inputs">
          <FieldContainer title="Contato" required>
            <Input type="text" placeholder="Contato" />
          </FieldContainer>
          <FieldContainer title="Nome da empresa">
            <Input type="text" placeholder="Nome da empresa" />
          </FieldContainer>
        </div>
        <FieldContainer title="Nome da tarefa" required>
          <Input placeholder="Digite o nome da tarefa" />
        </FieldContainer>
        <FieldContainer title="Descrição" required>
          <Input type="textarea" placeholder="Placeholder" />
        </FieldContainer>
        <div className="create-task-information">
          <FieldContainer title="Data de conclusão da tarefa" required>
            <DatePicker.DateRange
              selectRangeType="start"
              selected={startDate}
              setDate={setStartDate}
              dateFormat="dd 'de' MMMM', 'u"
              className="date-details"
            />
            <DatePicker.TimeRange
              selected={startDate}
              setDate={setStartDate}
              dateFormat="HH:mm"
            />
          </FieldContainer>
          <div className="create-task-information__tags-profile">
            <FieldContainer title="Tags" required>
              <div className="create-task-information__tags">
                <IconTags />
                <TagList
                  tagLimit={2}
                  tags={tagsList}
                  setTagsList={setTagsList}
                  onClick={(value) => setTagsList(value)}
                />
              </div>
            </FieldContainer>
            <FieldContainer title="Atribuições das tarefas" required>
              <IconViewUser />
            </FieldContainer>
          </div>
        </div>
        <Dropzone />
        <Button>Adicionar tarefa</Button>
      </div>
    </div>
  );
}
