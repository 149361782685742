//GLOBAL - components from npm
import React, { useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//STYLES
import "./select-tag.scss";

//COMPONENTS
import { Typography } from "../../../components/general";
import { FilterTag, Channel } from "../../../components/data-display";
import { Input } from "..";

// CUSTOM HOOKS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as FlagPriorityAverage } from "../../..//assets/icons/PriorityAverage2.svg";
import { ReactComponent as FlagPriorityCritical } from "../../..//assets/icons/PriorityCritical2.svg";
import { ReactComponent as FlagPriorityHigh } from "../../..//assets/icons/PriorityHigh2.svg";
import { ReactComponent as FlagPriorityLow } from "../../..//assets/icons/PriorityLow2.svg";
import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";

export default function SelectTag({
  valueTag,
  priority = false,
  channel = false,
  options = [
    {
      id: 1,
      label: "Option1",
    },
    {
      id: 2,
      label: "Option2",
    },
    {
      id: 3,
      label: "Option3",
    },
  ],
  onSelect,
  onKeyDown,
  placeholder,
}) {
  //GENERAL
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const priorityOptions = [
    {
      id: 3,
      label: "Baixa",
    },
    {
      id: 2,
      label: "Média",
    },
    {
      id: 1,
      label: "Alta",
    },
    {
      id: 4,
      label: "Crítica",
    },
  ];

  //REFS
  const inputRef = useRef(null);

  //STATES
  const [open, setOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  //REDUX - Selectors

  //FUNCTIONS
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleValuePriority = (value) => {
    switch (value) {
      case "Baixa":
        return 3;

      case "Média":
        return 2;

      case "Alta":
        return 1;

      case "Crítica":
        return 4;

      default:
        break;
    }
  };

  const handlePriority = (option) => {
    switch (option) {
      case 3:
        return <FlagPriorityLow />;

      case 2:
        return <FlagPriorityAverage />;

      case 1:
        return <FlagPriorityHigh />;

      case 4:
        return <FlagPriorityCritical />;

      default:
        break;
    }
  };

  const listFilter = (item) => {
    if (inputSearch) {
      if (item.label.toLowerCase().includes(inputSearch.toLowerCase())) {
        return item;
      } else {
        return;
      }
    } else {
      return item;
    }
  };

  const renderItems = () => {
    if (priority) {
      return (
        <>
          {priorityOptions.filter(listFilter).map((option) => (
            <div
              key={option.label}
              value={option.label}
              onClick={() => {
                onSelect(option);
                handleOpen();
              }}
              className={`menu-item ${
                valueTag.find((vlue) => vlue.id === option.id) ? "active" : ""
              } priority-item`}
            >
              {handlePriority(option.id)}
              {option.label}
            </div>
          ))}
        </>
      );
    } else if (channel) {
      return (
        <>
          {options.filter(listFilter).map((option) => (
            <div
              key={option.label}
              value={option.label}
              onClick={() => {
                onSelect(option);
                handleOpen();
              }}
              className={`menu-item ${
                valueTag.find((vlue) => vlue.id === option.id) ? "active" : ""
              }`}
            >
              <Channel
                media={option.id}
                nameMedia={option.label.toLowerCase()}
                tippy={false}
              />
              {option.label}
            </div>
          ))}
        </>
      );
    } else {
      return (
        <>
          {options.filter(listFilter).map((option) => (
            <>
              <div
                key={option.label}
                value={option.label}
                onClick={() => {
                  onSelect(option);
                  handleOpen();
                }}
                className={`menu-item ${
                  valueTag.find((vlue) => vlue.id === option.id) ? "active" : ""
                }`}
              >
                <Typography.H5 weight={"500"} color="gray-400">
                  {option.label}
                </Typography.H5>
              </div>
            </>
          ))}
        </>
      );
    }
  };

  // CUSTOM HOOKS

  //USE EFFECT

  return (
    <div>
      <FormControl sx={{ width: "100%", minHeight: 40 }}>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          open={open}
          // onOpen={handleOpen}
          onClose={handleOpen}
          multiple
          displayEmpty
          value={valueTag}
          input={<OutlinedInput id="select-multiple-chip" />}
          onKeyDown={onKeyDown}
          renderValue={(selected) => {
            return (
              <>
                <Box
                  onClick={() => handleOpen()}
                  sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                >
                  <ul className="multiple-chip-list">
                    {selected.length === 0 ? (
                      <Typography.H5 weight={400} color="gray-125">
                        {placeholder ? placeholder : "Selecione"}
                      </Typography.H5>
                    ) : (
                      selected.map((value) => {
                        const priorityValue = priority
                          ? handleValuePriority(value.label)
                          : "";

                        return (
                          <li>
                            <FilterTag
                              key={value.label}
                              label={value.label || value.name}
                              id={value.id}
                              type={
                                priority
                                  ? "priority"
                                  : channel
                                  ? "channels"
                                  : ""
                              }
                              priority={priorityValue}
                              onClose={(e) => {
                                onSelect(value);
                                e.stopPropagation();
                              }}
                            />
                          </li>
                        );
                      })
                    )}
                  </ul>
                </Box>
              </>
            );

            // if (selected.length === 0) {
            //   return (
            //     <Typography.H5 weight={400} color="gray-125">
            //       Selecione
            //     </Typography.H5>
            //   );
            // }
            // return (
            //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            //     <ul onClick={(e) => e.stopPropagation()}>
            //       {selected.map((value) => {
            //         const priorityValue = priority
            //           ? handleValuePriority(value.label)
            //           : "";

            //         return (
            //           <li>
            //             <FilterTag
            //               key={value.label}
            //               label={value.label || value.name}
            //               type={
            //                 priority ? "priority" : channel ? "channels" : ""
            //               }
            //               priority={priorityValue}
            //               onClose={(e) => {
            //                 e.stopPropagation();
            //                 onSelect(value);
            //               }}
            //               // onClick={(e) => e.stopPropagation()}
            //             />
            //           </li>
            //         );
            //       })}
            //     </ul>

            //     {/* <div
            //       style={{ width: "calc(100% - 120px)", height: 30 }}
            //       onClick={() => setOpen(true)}
            //     ></div> */}
            //   </Box>
            // );
          }}
          IconComponent={IconArrowDown}
          MenuProps={MenuProps}
        >
          <Input
            ref={inputRef}
            rightIcon={<IconSearch />}
            type="text"
            placeholder="Busca"
            autoComplete="on"
            value={inputSearch}
            onChange={(value) => setInputSearch(value)}
          />
          {renderItems()}
        </Select>
      </FormControl>
    </div>
  );
}
