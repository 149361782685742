//GLOBAL - components from npm
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

// UTILS
import { Toast } from "../../../../utils/toast";

//COMPONENTS
import { PopoverMenu } from "../../../../components/navigation";
import { Typography } from "../../../../components/general";
import { Loading, Modal } from "../../../../components/feedback";
import { TabulatedTable } from "../../../../components/data-display";

//SERVICES - api, conectors...
import * as APIContact from "./../../../../services/api/APIContact";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconMoreVertical } from "./../../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/Edit-2.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash-2.svg";
import { ReactComponent as EyeIcon } from "./../../../../assets/icons/eye02.svg";
import { ReactComponent as EmptyStateIcon } from "./../../../../assets/images/no-result.svg";

//STYLES

export default function PersonJuridic({
    updateCompanys,
    companysData,
    pageStatus,
    setPageStatus,
    loading,
    currentPage,
    count,
    linesLimit,
    setCurrentPage,
    filtersEnterprises,
    setLinesLimit,
    enterprises,
    setEnterprises
}) {
    //GENERAL

    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //STATES
    const [isOpenModalViewDataCompany, setIsOpenModalViewDataCompany] = useState(false);
    const [isOpenModalUpdateCompany, setIsOpenModalUpdateCompany] = useState(false);
    const [isOpenModalDeleteCompany, setIsOpenModalDeleteCompany] = useState(false);
    const [emptyState, setEmptyState] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [UsersLinkedToCompany, setUsersLinkedToCompany] = useState([])

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function renderContentModalDeleteCompany() {
        const userCount = UsersLinkedToCompany.length;

        return (
            <>
                <p>Você tem certeza que deseja excluir?</p>
                {userCount > 0 && (
                    <b>Existe{userCount > 1 ? `m ${userCount} contatos` : ` ${userCount} contato`} vinculado{userCount > 1 ? 's' : ''} a esta empresa.</b>
                )}
                <p>Ao excluir, todos os dados serão excluídos e esta ação não poderá ser desfeita.</p>
            </>
        );
    };

    const fetchUsersLinkedToCompany = useCallback(async (companyId) => {
        const response = await APIContact.getUsersLinkedToCompany(companyId, token);

        if (response.status === 200) {
            setUsersLinkedToCompany(response.data);
        } else {
            Toast(
                "Falha, não foi possível buscar os contatos vinculados a esta empresa!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    }, [token]);

    async function deleteCompany(companyId) {
        setPageStatus("requesting");

        const response = await APIContact.deleteCompany(companyId, token);

        if (response.status === 200) {
            Toast("Empresa Deletada!", "Empresa deletada com sucesso.", "success");
            setEnterprises([...enterprises])
            updateCompanys();
        } else {
            Toast(
                "Falha, não foi possível deletar a empresa!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    function refactorCompanyListing(data) {
        let dataRefactored = [];

        data.forEach((item) => {
            const companyRefactored = {
                company: {
                    data: item.nome,
                    config: {
                        textEllipsis: true,
                        className: "separator-name-company",
                    },
                },
                actions: {
                    data: (
                        <div className="container-actions">
                            <button
                                onClick={() =>
                                    setIsOpenModalViewDataCompany(item.id_empresa, true)
                                }
                            >
                                <EyeIcon />
                            </button>
                            <PopoverMenu
                                placement="bottom-end"
                                menuData={renderPopoverMenuContentCompany(
                                    item.id_empresa,
                                    item.user_create
                                )}
                            >
                                <button className="case-btn-options" type="button">
                                    <IconMoreVertical />
                                </button>
                            </PopoverMenu>
                        </div>
                    ),
                    config: {
                        className: "separator-options-company",
                    },
                },
            };

            dataRefactored.push(companyRefactored);
        });

        return dataRefactored;
    };

    function renderPopoverMenuContentCompany(companyId) {
        if (userData.permissionId === 1 && companyId !== userData.userId) {
            return [
                {
                    title: (
                        <>
                            <EditIcon />
                            <Typography.H5 color="gray-180" weight="500">
                                Editar
                            </Typography.H5>
                        </>
                    ),
                    onClick: () => {
                        setIsOpenModalUpdateCompany(companyId, true);
                    },
                },
            ];
        } else {
            return [
                {
                    title: (
                        <>
                            <Typography.H5 color="gray-180" weight="500">
                                <EditIcon />
                                Editar
                            </Typography.H5>
                        </>
                    ),
                    onClick: () => {
                        setIsOpenModalUpdateCompany(companyId, true);
                    },
                },
                {
                    title: (
                        <>
                            <Typography.H5 color="red" weight="500">
                                <TrashIcon className="popover-icon" />
                                Remover
                            </Typography.H5>
                        </>
                    ),
                    onClick: () => {
                        setCompanyId(companyId)
                        setIsOpenModalDeleteCompany(true)
                        fetchUsersLinkedToCompany(companyId);
                    },
                },
            ];
        }
    };

    const columnsCompany = useMemo(
        () => [
            {
                Header: "Empresa",
                accessor: "company",
                id: "company",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ],
        []
    );

    const dataCompany = useMemo(() => {
        if (companysData) {
            const dataCompanyRefactored = refactorCompanyListing(companysData);
            setPageStatus("awaiting");

            return dataCompanyRefactored;
        }
    }, [companysData]);

    //USE EFFECTS
    useEffect(() => {
        if (companysData?.length > 0) setEmptyState(false);
    }, [companysData]);

    useEffect(() => {
        if (pageStatus === "awaiting") updateCompanys();
    }, [filtersEnterprises]);

    useEffect(() => {
        if (isOpenModalDeleteCompany) {
            fetchUsersLinkedToCompany(companyId);
        } else if (!isOpenModalDeleteCompany) {
            setUsersLinkedToCompany([]);
        }
    }, [isOpenModalDeleteCompany, companyId, fetchUsersLinkedToCompany])

    return (
        <>
            {pageStatus === "requesting" ? (
                <section className="contact-content-main">
                    <Loading.Paperplane className="loading-contact-list" />
                </section>
            ) : (
                <>
                    {pageStatus === "awaiting" && (
                        <>
                            {emptyState ? (
                                <section className="emptySate-company-list">
                                    <EmptyStateIcon />
                                </section>
                            ) : (
                                <section className="contact-content-main">
                                    <TabulatedTable
                                        fixedHeader
                                        className="table-company"
                                        columns={columnsCompany}
                                        data={dataCompany}
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        loading={loading}
                                        currentPage={currentPage}
                                        count={count}
                                        linesLimit={linesLimit}
                                        setCurrentPage={setCurrentPage}
                                        setLinesLimit={setLinesLimit}
                                    />
                                </section>
                            )}
                        </>
                    )}
                </>
            )}

            <Modal.ViewDataCompany
                companysData={companysData}
                isOpen={isOpenModalViewDataCompany}
                onClose={(event) => setIsOpenModalViewDataCompany(event)}
            />

            <Modal.UpdateCompany
                companysData={companysData}
                isOpen={isOpenModalUpdateCompany}
                onClose={(event) => setIsOpenModalUpdateCompany(event)}
                onEdit={updateCompanys}
            />

            <Modal.GenericDeleteModal
                title="Excluir empresa"
                height="308px"
                increaseHeight={UsersLinkedToCompany.length > 0 && true}
                isOpen={isOpenModalDeleteCompany}
                onClose={(event) => { setIsOpenModalDeleteCompany(event) }}
                onDeleteAction={async () => {
                    await deleteCompany(companyId);
                }}
                content={renderContentModalDeleteCompany()}
            />
        </>
    )
}
