//GLOBAL - components from npm
import React, {useState} from 'react';
import urlIngWeb from '../../../utils/env'
//import axios from 'axios';

//STYLES
import "./carousel-attach.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/ArrowRight.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/Trash.svg";
import { ReactComponent as IconClips } from "../../../assets/icons/Clips.svg";

export default function CarouselAttach({
  files,
  setFiles,
  limit = 3,
  type = "insert",
  className,
  download,
  setDeleteFiles,
  deleteFiles
}) {
  //GENERAL

  //STATES
  const [value, setValue] = useState(0);

  //REDUX - Selectors

  //FUNCTIONS
  const deleteFile = (indexFile, file) => {
    const arrayFiles = [...files];
    const filteredFiles = arrayFiles.filter(
      (file, index) => index !== indexFile + value
    );

    if (value > 0) {
      setValue((value) => value - 1);
    }

    setFiles(filteredFiles);

     const arrayDeleteFiles = deleteFiles;
     if(file.file.id) {
       arrayDeleteFiles.push(file.file.id);
       setDeleteFiles(arrayDeleteFiles)
     }
  };

  const decreaseValue = () => {
    if (value > 0) {
      return setValue((value) => value - 1);
    } else {
      return;
    }
  };

  const increaseValue = () => {
    if (value + limit < files.length) {
      setValue((value) => value + 1);
    } else {
      return;
    }
  };

  const onDownload = (file) => {
    fetch(`${urlIngWeb + file.file.path_arquivos}`).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = file.file.name;
        a.click();
      });
    });
  };

  return (
    <>
      {files.length > 0 ? (
        <div
          className={`${className} ${
            files.length <= limit ? "carousel-attach--left" : ""
          } carousel-attach`}
        >
          <IconArrowLeft
            className={value > 0 ? "carousel-attach__icon" : ""}
            onClick={() => decreaseValue()}
          />
          <div className="carousel-attach-list">
            {files.slice(value, limit + value).map((file, index) => (
              <Tippy content={file.file.name} key={index}>
                <div className="carousel-attach-list-file" >
                  <IconClips />
                  {download ? (
                    <button onClick={() => onDownload(file)}>
                      <Typography.Paragraph
                        className="carousel-attach-list-file__text"
                        color="primary-dark"
                      >
                        {file.file.name}
                      </Typography.Paragraph>
                    </button>
                  ) : (
                    <Typography.Paragraph
                      className="carousel-attach-list-file__text"
                      color="primary-dark"
                    >
                      {file.file.name}
                    </Typography.Paragraph>
                  )}
                  {type === "insert" && (
                    <IconTrash
                      className="carousel-attach-list-file__icon"
                      onClick={() => deleteFile(index, file)}
                    />
                  )}
                </div>
              </Tippy>
            ))}
          </div>
          <IconArrowRight
            className={
              value + limit < files.length ? "carousel-attach__icon" : ""
            }
            onClick={() => increaseValue()}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
