import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Loading, Modal } from "./../../../feedback";
import { Button, Typography } from "../../../general";
import { FieldContainer } from "../../../data-display";
import { CustomSelect, Select } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";

import * as APITypeManagement from "./../../../../services/api/typeManagement";
import * as APIMotive from "./../../../../services/api/motives";
import * as APISla from "./../../../../services/api/sla";

import "./create-reason.scss"

export default function AssociatiTypeReason({
    isOpen,
    onClose,
    selectedTypeId,
    reasonListValues,
    setReasonListValues,
    treeData,
    setTreeData,
    onCreateReason,
    organizationId,
    valuesSelectedType
}) {
    const token = useSelector((state) => state.auth.token);

    const [pageStatus, setPageStatus] = useState("requesting");
    const [listSla, setListSla] = useState([]);
    const [reasonData, setReasonData] = useState(() => { return { desc_motivo: "" } });
    const [membershipValues, setMembershipValues] = useState(() => { return { id_sla: null, desc_sla: "", editavel: null } });

    const customStyles = {
        input: (provided) => ({
            ...provided,
            flexGrow: "1",
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            color: "#27282e",
            transition: "all 0.25s",
            outline: "none",
        }),
        menu: (provided) => ({
            ...provided,
            ...(
                reasonData.desc_motivo?.length > 0 ?
                    {
                        maxHeight: "110px",
                        position: "absolute",
                        display: "flex",
                        zIndex: 15,
                        flexDirection: "column",
                        borderTop: "0",
                        overflow: "auto",
                        border: "1px solid #2684FF",
                        boxShadow: "none",
                        borderColor: "#455cc7",
                    } : {
                        maxHeight: "130px",
                        position: "absolute",
                        display: "flex",
                        zIndex: 15,
                        flexDirection: "column",
                        borderTop: "0",
                        overflow: "auto",
                        border: "1px solid #2684FF",
                        boxShadow: "none",
                        borderColor: "#455cc7",
                    }
            )
        }),
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
            boxShadow: "none",
        }),
        valueContainer: (provided) => ({
            ...provided,
            ...(
                reasonData.desc_motivo?.length > 0 ?
                    {
                        maxHeight: "50px",
                        overflow: "auto",
                        padding: "10px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        "::-webkit-scrollbar": {
                            width: "5px",
                        },
                    } : {
                        maxHeight: "80px",
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                            width: "5px",
                        },
                    }
            )
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            ":active": {
                color: "hsl(0, 0%, 80%)",
                transform: "rotate(180deg)"
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#bfc3ca",
        }),
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            minHeight: "min-content",
            padding: "12px",
            textAlign: "left",

            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#828292",
            cursor: "pointer",

            ":active": {
                backgroundColor: "#ebf2ff",
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px 8px",
            gap: "4px",
            height: "24px",
            backgroundColor: "#f4f4f4",
            borderRadius: "24px",
            border: "1px solid rgba(216, 213, 213, 0.25)",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "112.5%",
            padding: "5px",
            alignItems: "flex-end",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#455cc7",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            height: "20px",
            width: "20px",
            background: "#bfc3ca",
            color: "#fff",
            borderRadius: "50%",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "0.5em",
            fontSize: "18px",
            cursor: "pointer",

            ":hover": {
                backgroundColor: "none",
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,

            ":hover": {
                transform: "rotate(180deg)",
            },
        }),
    };

    const handleRadioChange = (event) => {
        const value = event.target.value === "yes" ? true : false;

        setMembershipValues((prevValue) => ({
            ...prevValue,
            editavel: prevValue.id_sla !== null ? value : null,
        }))
    };

    const filteredOptions = reasonListValues?.filter(
        (reason) =>
            !valuesSelectedType?.arrayOfReasons?.some(
                (option) => Number(option.id_motivo) === reason.id_motivo
            )
    );

    function onCloseCustom() {
        onClose();
        setReasonData({ ...reasonData, desc_motivo: [] })
        setMembershipValues({
            ...membershipValues,
            id_sla: null,
            editavel: null
        })
    };

    async function getListSla() {
        const response = await APISla.listSla(token);

        const data = response.data;
        let updatedData = [];

        data.forEach((sla) => {
            let updateSla = {
                id: sla.id_sla,
                label: sla.desc_sla,
            };

            updatedData.push(updateSla);
        });

        setListSla(updatedData);
    };

    async function onKeyDownCreateReason(event) {
        if (event.key !== "Enter") return;

        const inputValue = event.target.value;

        if (!inputValue.trim()) return;

        const index = reasonListValues?.findIndex(
            (value) => value.desc_motivo === inputValue
        );

        if (index < 0) {
            setReasonListValues([
                ...reasonListValues,
                {
                    id_motivo: null,
                    desc_motivo: inputValue,
                },
            ]);

            reasonData.desc_motivo = inputValue;

            const response = await APIMotive.createMotive(token, reasonData);

            if (response.status === 200) {
                onCreateReason();

                setReasonData({ ...reasonData, desc_motivo: [] })

                Toast(
                    "Motivo adicionado!",
                    "Motivo adicionado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível adicionar o motivo",
                    "Tente novamente mais tarde!",
                    "error"
                );
            }
        } else {
            Toast(
                "Não foi possível criar esse motivo!",
                "Esse motivo ja existe, adicione outro com o nome diferente",
                "warn"
            );
        }
    };

    async function handleReasonAssociati() {
        if (reasonData.desc_motivo?.length > 0) {
            try {
                setPageStatus("creating-reason");

                if (selectedTypeId) {
                    let updatedTreeData = { ...treeData };

                    for (const selectedOption of reasonData.desc_motivo) {
                        const solicitationId = selectedTypeId;
                        const reasonId = selectedOption.value;

                        const values = {
                            id_tipo_solicitacao: solicitationId,
                            id_motivo: reasonId
                        };

                        await APITypeManagement.AssociateTypeReason(token, values);

                        updatedTreeData = {
                            ...updatedTreeData,
                            types: updatedTreeData.types?.map((type) => {
                                if (type.id_solicitation === selectedTypeId) {
                                    return {
                                        ...type,
                                        arrayOfReasons: [
                                            ...type.arrayOfReasons,
                                            {
                                                id_motivo: selectedOption.value.toString(),
                                                desc_motivo: selectedOption.label,
                                                solicitation_id: solicitationId,
                                                editavel: membershipValues.editavel,
                                                id_sla: membershipValues.id_sla,
                                                qtde_submotivo: "0",
                                                subreasons: []
                                            }
                                        ]
                                    };
                                }

                                return type;
                            })
                        };
                    }


                    onCloseCustom();
                    setReasonData({ ...reasonData, desc_motivo: [] })
                    setTreeData(updatedTreeData);

                    Toast(
                        "Motivo associado!",
                        "Motivo associado com sucesso!",
                        "success"
                    );
                }

                setPageStatus("awaiting");
            } catch (error) {
                Toast(
                    "Não foi possível associar o motivo!",
                    "Tente novamente mais tarde!",
                    "error"
                );
            }
        } else {
            Toast(
                "Não foi possível associar o motivo!",
                "Selecione pelo menos um motivo para fazer a associação!",
                "error"
            );
        }
    };

    async function handleSlaMembershipAndReason() {
        if (reasonData.desc_motivo?.length > 0) {
            try {
                setPageStatus("creating-reason");

                if (selectedTypeId && valuesSelectedType) {
                    let updatedTreeData = { ...treeData };

                    for (const selectedOption of reasonData.desc_motivo) {
                        const solicitationId = selectedTypeId;
                        const reasonId = selectedOption.value;

                        const valuesToAssociateSlaWithReason = {
                            id_tipo_solicitacao: Number(solicitationId),
                            id_motivo: reasonId,
                            id_sla: membershipValues.id_sla,
                            editavel: membershipValues.editavel,
                            id_organizacao: organizationId && organizationId
                        };

                        const valuesToAssociateReasonWithType = {
                            id_tipo_solicitacao: Number(solicitationId),
                            id_motivo: reasonId
                        };

                        const index = valuesSelectedType?.arrayOfReasons?.findIndex(
                            (reason) =>
                                reason.id_motivo === selectedOption.value
                        );

                        if (index < 0) {
                            await APITypeManagement.associateSlaWithReasonV2(
                                token,
                                valuesToAssociateSlaWithReason
                            );
                            await APITypeManagement.AssociateTypeReason(
                                token,
                                valuesToAssociateReasonWithType
                            );

                            updatedTreeData = {
                                ...updatedTreeData,
                                types: updatedTreeData.types?.map((type) => {
                                    if (type.id_solicitation === selectedTypeId) {
                                        return {
                                            ...type,
                                            arrayOfReasons: [
                                                ...type.arrayOfReasons,
                                                {
                                                    id_motivo: selectedOption.value.toString(),
                                                    desc_motivo: selectedOption.label,
                                                    editavel: membershipValues.editavel,
                                                    id_sla: membershipValues.id_sla,
                                                    desc_sla: membershipValues.desc_sla,
                                                    qtde_submotivo: "0",
                                                    subreasons: []
                                                }
                                            ]
                                        };
                                    }

                                    return type;
                                })
                            };
                        } else {
                            Toast(
                                "ATENÇÃO!",
                                "Esse motivo já está associado",
                                "warn"
                            );
                        }
                    }


                    onCloseCustom();
                    setReasonData({ ...reasonData, desc_motivo: [] })
                    setTreeData(updatedTreeData);

                    Toast(
                        "Motivo associado!",
                        "Motivo associado com sucesso!",
                        "success"
                    );
                }

                setPageStatus("awaiting");
            } catch (error) {
                Toast(
                    "Não foi possível associar o motivo!",
                    "Tente novamente mais tarde!",
                    "error"
                );
            }
        } else {
            Toast(
                "Não foi possível associar o motivo!",
                "Selecione pelo menos um motivo para fazer a associação!",
                "error"
            );
        }
    }

    useEffect(() => {
        if (isOpen) {
            getListSla();
        }
    }, [isOpen]);

    useEffect(() => {
        if (membershipValues.id_sla === null) {
            setMembershipValues((preValue) => ({
                ...preValue,
                editavel: null,
            }))
        }
    }, [membershipValues.id_sla])

    return (
        <Modal.Main
            className="modal-create-reason"
            title="Adicionar motivo"
            width="600px"
            height="400px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <>
                    <Button
                        className="modal-create-reason__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "creating-reason"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-create-reason__btn-confirm"
                        disabled={pageStatus === "creating-reason"}
                        onClick={() => {
                            if (membershipValues.id_sla !== null && membershipValues.editavel !== null) {
                                handleSlaMembershipAndReason();
                            } else {
                                handleReasonAssociati()
                            }
                        }}
                    >
                        {pageStatus !== "creating-reason" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </>
            }
        >
            <section className="modal-create-reason__content">
                <div className="container-other-associations">
                    <FieldContainer title="SLA">
                        <Select
                            uncheckTheOption
                            placeholder="Selecione o SLA"
                            options={listSla}
                            value={membershipValues.id_sla}
                            onSelect={(newValue) => {
                                setMembershipValues((prevState) => ({
                                    ...prevState,
                                    editavel: newValue !== null ? prevState.editavel : null,
                                    id_sla: newValue.id || null,
                                    desc_sla: newValue.label
                                }))
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer title="Editável">
                        <div className="editable-field-container">
                            <div className="container-input-radio">
                                <label htmlFor="yes" className="radio-label">
                                    <input
                                        type="radio"
                                        id="yes"
                                        name="radio-option"
                                        value="yes"
                                        className="custom-radio"
                                        checked={membershipValues.editavel === true}
                                        onChange={handleRadioChange}
                                        disabled={membershipValues.id_sla === null}
                                    />
                                    Sim
                                </label>
                            </div>

                            <div className="container-input-radio">
                                <label htmlFor="no" className="radio-label">
                                    <input
                                        type="radio"
                                        id="no"
                                        name="radio-option"
                                        value="no"
                                        className="custom-radio"
                                        checked={membershipValues.editavel === false}
                                        onChange={handleRadioChange}
                                        disabled={membershipValues.id_sla === null}
                                    />
                                    Não
                                </label>
                            </div>
                        </div>
                    </FieldContainer>
                </div>

                <div className="modal-create-reason__content__divisor" />

                <div>
                    <FieldContainer title="Motivos">
                        <CustomSelect
                            placeholder={"Selecione ou adicione um novo motivo"}
                            value={reasonData.desc_motivo}
                            onChange={(newReason) =>
                                setReasonData({ ...reasonData, desc_motivo: newReason })
                            }
                            onKeyDown={onKeyDownCreateReason}
                            styles={customStyles}
                            options={
                                filteredOptions?.map((reason) => {
                                    return {
                                        value: reason.id_motivo,
                                        label: reason.desc_motivo

                                    };
                                })
                            }
                        />
                    </FieldContainer>
                </div>
            </section>
        </Modal.Main>
    )
}
