//GLOBAL - components from npm
import React, { useState, useRef } from "react";
import { format } from "date-fns";
import ptBR from "../../../locale/pt-BR";
import { useHistory } from "react-router";

//STYLES
import "./card-task.scss";

//COMPONENTS
import { Typography } from "../../../components/general";
import { Tippy } from "./../../../components/feedback";
import { TagList, ProfileList } from "../../../components/data-entry";
import { DropdownStatus } from "../../../components/layout";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconNotification } from "../../../../src/assets/icons/NotificationThree.svg";
import { ReactComponent as IconMoreVertical } from "../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as IconCalendar } from "../../../../src/assets/icons/Calendar.svg";
import { ReactComponent as IconNotifications } from "../../../../src/assets/icons/Notifications.svg";
import { ReactComponent as IconOverflow } from "../../../../src/assets/icons/Overflow.svg";

export default function CardTask({
  deadline,
  title,
  description,
  tags,
  profiles,
  status,
  lembretes,
  id,
  idSolicitante,
  onDelete,
  onUnlink,
  tagLimit,
  permissions,
}) {
  //GENERAL
  const history = useHistory();
  const user = useSelector((state) => state.auth.userData);
  const profileAssociated = profiles?.find(
    (item) => item.id_usuario === user.userId
  );

  //STATES
  const [visible, setVisible] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const show = () => setVisible(true);

  const hide = () => {
    setVisible(false);
  };

  const handleClick = () => {
    if (
      user.userId === idSolicitante ||
      profileAssociated ||
      user.permissionId === 2
    ) {
      if (history.location.pathname.indexOf("externo") === 1)
        return history.push({
          pathname: `/externo/tarefas/${id}`,
          state: { from: history.location.pathname },
        });
      else
        return history.push({
          pathname: `/tarefas/${id}`,
          state: { from: history.location.pathname },
        });
    } else {
      return;
    }
  };

  //USE EFFECTS

  return (
    <div
      className={`card-task 
      ${user.userId === idSolicitante ||
          profileAssociated || user.permissionId === 2 ? "" : "card-task--disabled"}`}
      onClick={() => (permissions.edicao ? handleClick() : null)}
    >
      <div className="card-task-header">
        <div className="card-task-header__date">
          {/* <IconCalendar /> */}
          <Typography.H6 color="gray-200">
            {/* {format(new Date(deadline), "dd 'de' MMMM, yyyy", { locale: ptBR })} */}
            {format(new Date(deadline), "dd/MM/yyyy", { locale: ptBR })}
          </Typography.H6>
        </div>
        <div className={`card-task-header__notification ${lembretes?.length > 0 ? "icon--active" : ""}`}>
          <IconNotification />
        </div>
        {user.userId === idSolicitante ||
          profileAssociated || user.permissionId === 2 ?
          (!permissions.edicao && !permissions.exclusao ? (
            <></>
          ) : (
            <Tippy
              theme="popover-default"
              visible={visible}
              onClickOutside={hide}
              placement="left-start"
              delay={[0, 0]}
              allowHTML
              interactive
              content={
                <div className="popover-card">
                  {permissions.edicao && (
                    <>
                      <div
                        className="popover-card__item"
                        onClick={() => {
                          history.push(`/tarefas/${id}`);
                        }}
                      >
                        <div className="popover-card__item__option">Editar</div>
                        {/* <Typography.H6 weight="500" color="primary-dark">
                          Editar
                        </Typography.H6> */}
                      </div>
                      <div
                        className="popover-card__item"
                        onClick={(e) => {
                          e.stopPropagation();
                          onUnlink();
                          hide();
                        }}
                      >
                        <div className="popover-card__item__option">Desvincular</div>
                        {/* <Typography.H6 weight="500" color="primary-dark">
                      Desvincular
                    </Typography.H6> */}
                      </div>
                    </>
                  )}
                  {(user.userId === idSolicitante || permissions.exclusao) && (
                    <div
                      className="popover-card__item"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                        hide();
                      }}
                    >
                      <div className="popover-card__item__option__remove">Remover</div>
                      {/* <Typography.H6 weight="500" color="red">
                    Remover
                  </Typography.H6> */}
                    </div>
                  )}
                </div>
              }
            >
              <div>
                <div
                  className="card-task-header__status-color"
                  style={{ backgroundColor: "" }}
                >
                </div>

                <div
                  className="card-task-header__menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisible(true);
                  }}
                >
                  <IconMoreVertical />
                </div>
              </div>
            </Tippy>
          )
          ) : (
            ""
          )}
      </div>
      <div className="card-task-content">
        <Tippy content={title}>
          <div className="card-task-content__title">
            <Typography.H6 weight="bold" color="gray-300">
              {title}
            </Typography.H6>
          </div>
        </Tippy>
        <div className="card-task-content__description">
          <Typography.Paragraph color="gray-300">
            {description}
          </Typography.Paragraph>
        </div>
      </div>
      <div className="card-task-tags">
        {/* <IconTags /> */}
        <TagList tagLimit={tagLimit} tags={tags} viewOnly />
      </div>
      <div className="card-task-footer">
        <div className="card-task-profile">
          {/* <IconViewUser /> */}
          <ProfileList
            viewOnly
            disabledVisibleView
            profiles={profiles}
          />
        </div>
        <DropdownStatus
          value={status}
          statusIcon
          type="tag"
        />
      </div>
    </div>
  )
}
