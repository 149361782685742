//GLOBAL - components from npm
import { configureStore, combineReducers } from "@reduxjs/toolkit";

// MIDDLEWARES
import localStorage from "./middleware/localStorage";

// REDUCERS
import filters from "./filters";
import auth from "./auth";
import interaction from "./interaction";
import browserComms from "./browserComms";
import audit from "./audit";
import tableFilters from "./tableFilters";
import dashboard from "./dashboard";
import searchSlice from "./searchSlice";
const rootReducer = combineReducers({
  auth,
  browserComms,
  interaction,
  audit,
  filters,
  tableFilters,
  dashboard,
  searchSlice
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorage),
});

export default store;
