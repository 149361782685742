import React, { useEffect, useMemo, useState } from "react";

//STYLES
import "./table-create-cases.scss";
import { Table } from "../data-display";
import { cloneDeep } from "lodash";
import SelectCreateCases from "../data-entry/SelectCreateCases";
import { Tippy } from "../feedback";
import { reduceString } from "../../utils/reduceString";

export default function TableCreateCases({
  tableHeader = [
    {
      Header: "teste1",
      accessor: "teste1",
      id: "teste1",
    },
    {
      Header: "teste2",
      accessor: "teste2",
      id: "teste2",
    },
    {
      Header: "teste3",
      accessor: "teste3",
      id: "teste3",
    },
  ],
  tableData,
  initialOptionsSelecteds = {
    teste1: { id: "1", label: "teste1", active: false },
    teste2: { id: "2", label: "teste2", active: false },
    teste3: { id: "undefined", label: "Ignorar", active: true },
  },
  selectOptions = [
    { id: "1", label: "teste1", active: false },
    { id: "2", label: "teste2", active: false },
    { id: "3", label: "teste3", active: true },
    { id: "4", label: "teste4", active: true },
    { id: "undefined", label: "Ignorar", active: true },
  ],
  optionsSelecteds,
  setOptionsSelecteds,
  data,
  setData,
}) {
  const [options, setOptions] = useState(selectOptions);
  // const [optionsSelecteds, setOptionsSelecteds] = useState(
  //   initialOptionsSelecteds
  // );
  // const [data, setData] = useState(tableData);

  useEffect(() => {
    setOptions(selectOptions);
  }, [selectOptions]);

  useEffect(() => {
    setOptionsSelecteds(initialOptionsSelecteds);
  }, [initialOptionsSelecteds]);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columns = useMemo(() => {
    let updatedColumns = [];

    if (tableHeader && tableHeader.length) {
      updatedColumns = tableHeader.map((headerInfo, index) => {
        return {
          Header: () => (
            <Tippy content={headerInfo.Header}>
              <div>{reduceString(headerInfo.Header, 20)}</div>
            </Tippy>
          ),
          accessor: headerInfo.accessor,
          id: headerInfo.id,
        };
      });
    }

    return updatedColumns;
  }, [tableHeader, options, optionsSelecteds]);

  return (
    <div className="table-create-cases-v2">
      <div className="table-create-cases-v2__selects-container">
        {tableHeader && tableHeader.length
          ? tableHeader.map((headerInfo, index) => {
              return (
                <div key={index}>
                  <SelectCreateCases
                    width={"217px"}
                    className="table-create-cases-v2__select"
                    showAvatar={false}
                    options={options}
                    value={optionsSelecteds[headerInfo.id]}
                    onSelect={(newOption) => {
                      const updatedOptionsSelecteds =
                        cloneDeep(optionsSelecteds);
                      const updatedOptions = cloneDeep(options);
                      const lastValue = optionsSelecteds[headerInfo.id];
                      const idOfLastValue = lastValue.id;

                      const indexOfOptionWithLastValue =
                        updatedOptions.findIndex((opt) => {
                          return opt.id === idOfLastValue;
                        });

                      if (indexOfOptionWithLastValue !== -1) {
                        updatedOptions[indexOfOptionWithLastValue] = {
                          ...lastValue,
                          active: true,
                        };
                      }

                      if (newOption) {
                        updatedOptionsSelecteds[headerInfo.id] = {
                          ...newOption,
                          id: newOption.id,
                          label: newOption.label,
                          active: false,
                        };

                        const indexOfOptionSelected = updatedOptions.findIndex(
                          (opt) => {
                            return opt.id === newOption.id;
                          }
                        );

                        if (indexOfOptionSelected !== -1) {
                          const isIgnoreOption = newOption.id === "undefined";
                          if (!isIgnoreOption) {
                            updatedOptions[indexOfOptionSelected] = {
                              ...newOption,
                              active: false,
                            };
                          }
                        }
                      } else {
                        updatedOptionsSelecteds[headerInfo.id] = {
                          id: "undefined",
                          label: "Ignorar",
                          active: true,
                        };
                      }
                      setOptionsSelecteds(updatedOptionsSelecteds);
                      setOptions(updatedOptions);
                    }}
                  />
                </div>
              );
            })
          : null}
      </div>
      {data?.length ? (
        <Table
          className="table-create-cases-v2__table"
          columns={columns}
          data={data}
          fixedHeader
        />
      ) : null}
    </div>
  );
}
