//GLOBAL - components from npm
import React from "react";
import ReactModal from "react-modal";

//STYLES
import "./main.scss";

//COMPONENTS
import { Typography } from "./../../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconClose } from "../../../../assets/icons/Close-Circle.svg";
import { ReactComponent as Minimize } from "../../../../assets/icons/Minimize.svg";

export default function Main({
  title = "Modal Title",
  // subtitle,
  // customSubtitle,
  className = "",
  isOpen = false,
  onClose,
  footer,
  children,
  size,
  width = "600px",
  height = "unset",
  animated = true,
  shouldCloseOnOverlayClick = true,
  screenType,
  icon,
  titleColor,
  headerContainerStyle,
  isDealing = false
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //USE EFFECTS

  //FUNCTIONS

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={250}
      style={{
        content: {
          "--width": width,
          "--height": height,
        },
      }}
      isOpen={isOpen}
      className={`modal ${className} ${size ? `modal--${size}` : ""} ${animated ? "modal--animated" : ""}`}
      overlayClassName="modal__overlay"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className="modal__header" style={headerContainerStyle}>
        {icon && (
          <>
            {icon}
          </>
        )}

        <div className="modal__header__title">
          <Typography.H1 color={titleColor ? titleColor : "blue-selected-4"} weight={"bold"}>
            {title ? title : ""}
          </Typography.H1>

          <div className="modal__header__title-action">
            {screenType === "modal--communicated" &&
              <button className="modal__close" onClick={() => onClose()}>
                <Minimize />
              </button>
            }

            {(!icon && !isDealing) && (
              <button className="modal__close" onClick={() => onClose()}>
                <IconClose />
              </button>
            )}
          </div>
        </div>

        {/* {customSubtitle ? customSubtitle : (subtitle ? <Typography.H4 color='gray-700'>{subtitle}</Typography.H4> : '')} */}
      </div>

      {children && <div className="modal__body">{children}</div>}

      {footer && <div className="modal__footer">{footer}</div>}
    </ReactModal>
  );
}
