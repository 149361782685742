//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { format } from "date-fns";
import TablePagination from "@mui/material/TablePagination";
import { Box, LinearProgress } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

// HOOKS

//STYLES
import "./tabulated-table.scss";

// UTILS
import { totalTableRowCount } from "../../../utils/totalTableRowCount";

//COMPONENTS
import { OrderButton, Typography } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import { useDispatch } from "react-redux";
import { setInitialSort } from "../../../store/tableFilters";
import { useLocation } from "react-router-dom";

//ASSETS - icons, images...

export default function TabulatedTable({
  className = "",
  data,
  columns,
  fixedHeader,
  fixedFirstColumn,
  filtersActive,
  emptyState = false,
  setEmptyState = () => null,
  controlledTableState,
  initialState,
  darkModeState,
  keepLinePerPage,
  loading,
  count,
  linesLimit,
  currentPage,
  setCurrentPage,
  setLinesLimit,
  canSaveInitialState = false,
  ...others
}) {
  // DEFAULT PROPS
  const defaultColumns = useMemo(
    () => [
      {
        Header: "Column 1",
        accessor: "column1",
        id: "column1",
      },
      {
        Header: "Column 2",
        accessor: "column2",
        id: "column2",
      },
      {
        Header: "Column 3",
        accessor: "column3",
        id: "column3",
      },
    ],
    []
  );
  const defaultData = useMemo(
    () => [
      {
        column1: {
          data: "Content column 1",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column2: {
          data: "Content column 2",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column3: {
          data: "Content column 3",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
      },
      {
        column1: {
          data: "Content column 1",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column2: {
          data: "Content column 2",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column3: {
          data: "Content column 3",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
      },
    ],
    []
  );

  if (!data || !columns) {
    data = defaultData;
    columns = defaultColumns;
  }

  //GENERAL
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        // console.debug('state: ', state);
        return useMemo(
          () => ({
            ...state,
            ...controlledTableState,
          }),
          [state, controlledTableState]
        );
      },
      initialState: initialState,
    },
    useFilters,
    useSortBy
  );

  const theme = useTheme();
  const location = useLocation();

  //STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    keepLinePerPage
      ? () => {
          const saved = localStorage.getItem("linesPerPage");
          const initialValue = JSON.parse(saved);
          return initialValue || 10;
        }
      : className === "table-dashboard"
      ? 5
      : 10
  );

  const formatNumber = new Intl.NumberFormat(); // uso -> formatNumber.format(count)

  //REDUX - Selectors
  const dispatch = useDispatch();

  //FUNCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLinesLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayHeader = (head) => {
    return head.display !== false;
  };

  const displayBody = (body) => {
    return body.column.display !== false;
  };

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, ptBR),
    [ptBR, theme]
  );

  function defaultLabelDisplayedRows({ from, to, count }) {
    return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`} itens`;
  }

  function handleInitialSort(columnInfo) {
    if (canSaveInitialState) {
      dispatch(
        setInitialSort({
          localStorageKey: "initialSorted",
          localStorageValue: [
            {
              id: columnInfo.id,
              desc: columnInfo.isSortedDesc,
            },
          ],
        })
      );
    }
  }

  function renderCellContent(cell, row) {
    if (cell.value.config.customData) {
      // CUSTOM DATA
      return cell.value?.data;
    } else if (cell.value?.data instanceof Date) {
      // DATE INSTANCES
      return (
        <Typography.H6
          color={darkModeState ? "white" : "gray-180"}
          weight={400}
        >
          {format(cell.value.data, "dd'/'MM'/'y HH:mm")}
        </Typography.H6>
      );
    } else if (cell.value?.data?.type?.displayName === "Link") {
      // LINK COMPONENTS
      return (
        <Typography.H6
          weight={400}
          color={darkModeState ? "white" : "blue-selected-4"}
        >
          {cell.value?.data}
        </Typography.H6>
      );
    } else if (cell.value?.data?.label) {
      // OBJECTS
      return (
        <Typography.H6
          color={darkModeState ? "white" : "gray-180"}
          weight={400}
        >
          {cell.value.data.label}
        </Typography.H6>
      );
    } else {
      // OTHERS
      return (
        <Typography.H6
          color={darkModeState ? "white" : "gray-180"}
          weight={400}
        >
          {cell.value.data}
        </Typography.H6>
      );
    }
  }

  function renderCell(row) {
    return row.cells.filter(displayBody).map((cell) => {
      if (cell.value?.config.tippy) {
        return (
          <td
            {...cell.getCellProps()}
            className={`${
              cell.value?.config.className ? cell.value.config.className : ""
            } 
            ${cell.value?.config.textEllipsis ? "cell-ellipsis" : ""}`}
          >
            <Tippy content={cell.value.data}>
              <div className="cell-container">
                {renderCellContent(cell, row)}
              </div>
            </Tippy>
          </td>
        );
      } else {
        return (
          <td
            {...cell.getCellProps()}
            className={`${
              cell.value?.config.className ? cell.value.config.className : ""
            } ${cell.value?.config.textEllipsis ? "cell-ellipsis" : ""} ${
              cell.column.id === "client" ||
              cell.column.id === "tasks" ||
              cell.column.id === "agent"
                ? "cell-width"
                : ""
            }`}
          >
            <div className={`cell-container`}>
              {renderCellContent(cell, row)}
            </div>
          </td>
        );
      }
    });
  }

  function checkEmptyState() {
    if (!emptyState && rows.length === 0) {
      setEmptyState(true);
    } else if (emptyState && rows.length > 0) {
      setEmptyState(false);
    } else if (count === 0 && !loading) {
      setEmptyState(true);
    }
  }

  checkEmptyState();
  // #TOIMPROVE: remover a função de checar o empty state se conseguir ter acesso aos estado atual das linhas (após filtrado) fora do component table

  //USE EFFECTS
  useEffect(() => {
    if (filtersActive?.status) {
      setFilter("status", filtersActive.status);
    }
  }, [filtersActive?.status]);

  useEffect(() => {
    if (keepLinePerPage && location.pathname === "/casos") {
      window.localStorage.setItem("linesPerPage", JSON.stringify(rowsPerPage));
    }
  }, [rowsPerPage]);

  return (
    <>
      <table
        className={`tabulated-table 
                ${className} 
                ${fixedFirstColumn ? "table--fixed-first-column" : ""} 
                ${fixedHeader ? "table--fixed-header" : ""}`}
        {...getTableProps()}
        cellSpacing={0}
        {...others}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.filter(displayHeader).map((column) => {
                column.isSorted && handleInitialSort(column);
                return (
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: column.Header })
                    )}
                  >
                    <div className={`flex-container align-center`}>
                      <Typography.H6
                        weight="400"
                        color="white"
                        className={"header-column"}
                      >
                        {column.render("Header")}
                      </Typography.H6>
                      {column.canSort && (
                        <OrderButton
                          direction={
                            column.isSorted &&
                            (column.isSortedDesc ? "desc" : "asc")
                          }
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows
            .slice(page * linesLimit, page * linesLimit + linesLimit)
            .map((row) => {
              prepareRow(row);
              return (
                <tr
                  className={darkModeState ? "darkModeRow" : ""}
                  {...row.getRowProps()}
                >
                  {renderCell(row)}
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className={`pagination-table ${darkModeState ? "dark-mode" : ""}`}>
        {!loading && (
          <ThemeProvider theme={themeWithLocale}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={linesLimit}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={defaultLabelDisplayedRows}
              backIconButtonProps={{ sx: { display: "none" } }}
              nextIconButtonProps={{ sx: { display: "none" } }}
              sx={{ fontFamily: "Inter" }}
            />
          </ThemeProvider>
        )}

        {loading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress variant="indeterminate" />
          </Box>
        ) : (
          <>
            {count > 0 && count > linesLimit && (
              <Stack spacing={2}>
                <Pagination
                  id="pagination-numbers"
                  page={currentPage}
                  count={totalTableRowCount(count, linesLimit)}
                  siblingCount={1}
                  color="primary"
                  onChange={(_, newPage) => {
                    setCurrentPage(newPage);
                  }}
                />
              </Stack>
            )}
          </>
        )}
      </div>
    </>
  );
}
