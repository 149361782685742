//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { FiCheck } from "react-icons/fi";

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { FieldContainer, Table } from "../../../../components/data-display";
import { Toast } from "../../../../utils/toast";
import { CustomDropdown, Input, Select } from "../../../../components/data-entry";
import { Loading } from "../../../../components/feedback";

//SERVICES - api, conectors...
import * as APIUser from "../../../../services/api/User";
import * as APISla from "../../../../services/api/sla";
import * as APIGroup from "../../../../services/api/group";
import * as APICase from "../../../../services/api/case";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/Search-normal.svg";
import { ReactComponent as AddCircleIcon } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as MinusCircleIcon } from "./../../../../assets/icons/minus-cirlce.svg"
import { ReactComponent as TickSquareIcon } from "./../../../../assets/icons/tick-square.svg";
import { ReactComponent as CleanIcon } from "./../../../../assets/icons/Clean.svg";

//STYLES
import "./create-user.scss";

export default function CreateUser({ toGoBack, onCreate }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL
    const permissions = {
        toView: 'to_view',
    };

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usersData, setUsersData] = useState(() => {
        return {
            nome: "",
            login: "",
            id_organizacao: null,
            idfuncao: null,
            id_perfil: null,
            matricula: "",
            idgrupo: null,
            id_usuario: userData.userId
        }
    });
    const [organizationsData, setOrganizationsData] = useState(() => {
        return {
            id_criacao: null,
            tipo_visualizacao: null,
            id_fecharcaso: null,
            criacao: false,
            fecharcaso: false,
            organizations_associations: [
                {
                    id_organizacao: null,
                    id_usuario: null,
                    criacao: false,
                    fecharcaso: null,
                    id_criacao: null,
                    tipo_visualizacao: null,
                    id_fecharcaso: null,
                    id_usuario_insercao: null,
                }
            ]
        }
    });
    const [organizations, setOrganizations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [viewType, setViewType] = useState([]);
    const [staggeredList, setStaggeredList] = useState([]);
    const [optionsCaseCreation] = useState([
        { id: 1, label: "Sim" },
        { id: 2, label: "Não" },
    ]);
    const [caseClosureOptions] = useState([
        { id: 1, label: "Sim" },
        { id: 2, label: "Não" },
    ]);

    //FUNCTIONS
    const handleAssociationAddition = () => {
        setOrganizationsData({
            ...organizationsData, organizations_associations: [
                ...organizationsData.organizations_associations, {
                    id_organizacao: null,
                    id_usuario: null,
                    criacao: false,
                    fecharcaso: false,
                    id_criacao: null,
                    tipo_visualizacao: null,
                    id_fecharcaso: null,
                    id_usuario_insercao: null,
                }
            ]
        })
    };

    const handleRemoveAssociation = (idx) => {
        setOrganizationsData({
            ...organizationsData, organizations_associations: [
                ...organizationsData.organizations_associations.filter((_, index) => index !== idx)
            ]
        })
    };

    const handleCreationOption = (userPermission, index) => {
        setOrganizationsData((prevState) => {
            const updatedAssociations = [...prevState.organizations_associations];
            const updatedAssociation = { ...updatedAssociations[index] };

            updatedAssociation.id_criacao = userPermission.id;

            updatedAssociation.criacao = userPermission.id === 1 ? true : false;

            updatedAssociations[index] = updatedAssociation;

            return {
                ...prevState,
                organizations_associations: updatedAssociations
            };
        });
    };

    const handleCaseClosureOption = (closeCase, index) => {
        setOrganizationsData((prevState) => {
            const updatedOptions = [...prevState.organizations_associations];
            const updatedOption = { ...updatedOptions[index] };

            updatedOption.id_fecharcaso = closeCase.id;

            updatedOption.fecharcaso = closeCase.id === 1 ? true : false;

            updatedOptions[index] = updatedOption;

            return {
                ...prevState,
                organizations_associations: updatedOptions
            }
        })
    };

    const allOrganizationsAssociated = () => {
        const { organizations_associations } = organizationsData;
        const { id_organizacao } = usersData;

        const allAssociated = organizations.filter(org => {
            return !organizations_associations.some(item => item.id_organizacao === org.id || id_organizacao === org.id);
        }).length === 0;

        return allAssociated;
    };

    function handleCheckboxChange(id, permission) {
        setStaggeredList(prevData =>
            prevData.map(item =>
                item.id_escalonamento === id ? { ...item, [permission]: !item[permission] } : item
            )
        );
    };

    function handleSelectAll() {
        setStaggeredList(prevData => {
            const allChecked = prevData.every(
                item =>
                    Object.values(item).every(value => typeof value !== 'boolean' || value === true)
            );

            // Define o estado de seleção para o oposto do estado atual de todos os itens
            return prevData.map(item => {
                const updatedItem = { ...item };

                for (const permission in permissions) {
                    if (Object.prototype.hasOwnProperty.call(permissions, permission)) {
                        updatedItem[permissions[permission]] = !allChecked;
                    }
                }

                return updatedItem;
            });
        });
    };

    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function cleanFields() {
        setTimeout(() => {
            setUsersData({
                ...usersData,
                nome: "",
                id_organizacao: null,
                id_perfil: null,
                id_usuario: null,
                idfuncao: null,
                idgrupo: null,
                matricula: "",
                login: "",
            });
            setOrganizationsData({
                ...organizationsData,
                organizations_associations: [{
                    criacao: false,
                    id_criacao: null,
                    id_organizacao: null,
                    id_usuario: null,
                    id_usuario_insercao: null,
                    tipo_visualizacao: null,
                }]
            })
        }, 50)
    };

    function isFieldsValid() {
        if (
            !usersData.nome ||
            !usersData.login ||
            !usersData.id_organizacao ||
            !usersData.id_perfil ||
            !usersData.idfuncao ||
            !usersData.idgrupo ||
            !organizationsData.id_criacao ||
            !organizationsData.tipo_visualizacao ||
            !organizationsData.id_fecharcaso
        ) {
            return false
        }

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao adicionar usuário!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    function refactorStaggeredListing(data) {
        if (!data) throw new Error('Error when fetching data!');

        return data.map((item) => {
            const id = item.id_escalonamento;

            const newObject = {
                name: {
                    data: item.descricao,
                    config: {
                        className: "staggered-name"
                    },
                },
                toView: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`to-view${id}`}
                        >
                            <input
                                type="checkbox"
                                id={`to-view${id}`}
                                name={`to-view${id}`}
                                checked={item.to_view || false}
                                onChange={() => handleCheckboxChange(id, 'to_view')}
                            />

                            <span className={`checkmark ${item.to_view ? "checked" : ""}`}>
                                {item.to_view && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "staggered-toView"
                    },
                }
            };

            return newObject;
        });
    };

    async function handleGroupCreationOnDownKey(event) {
        if (event.key !== "Enter") return;

        const inputValue = event.target.value;

        const data = {
            desc_grupo: inputValue
        }

        const response = await APIGroup.createGroup(token, data);

        if (response.status === 200) {
            getGroupsList();

            Toast(
                "Grupo adicionado!",
                "Grupo adicionado com sucesso.",
                "success"
            );
        } else {
            Toast(
                "Não foi possível adicionar o grupo!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function handleCreateUser() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        }

        setPageStatus("creating-user");

        let userCreated;
        let hasError = false;

        try {
            const response = await APIUser.createUser(token, usersData);

            if (response.status === 200) {
                userCreated = response.data.Dados[0].id_usuario_criado;
            } else {
                hasError = true;
            }

            const data = {
                id_organizacao: usersData.id_organizacao,
                id_usuario: userCreated,
                criacao: organizationsData.criacao,
                tipo_visualizacao: organizationsData.tipo_visualizacao,
                id_usuario_insercao: userData.userId,
                fecharcaso: organizationsData.fecharcaso
            }

            const responseCreateOrganization = await APIUser.createUserOrganization(token, data);

            if (responseCreateOrganization.status !== 200) {
                hasError = true;
            }

            for (const data of organizationsData.organizations_associations) {
                const newData = {
                    id_organizacao: data.id_organizacao,
                    id_usuario: userCreated,
                    criacao: data.criacao,
                    fecharcaso: data.fecharcaso,
                    tipo_visualizacao: data.tipo_visualizacao,
                    id_usuario_insercao: userData.userId
                }

                if (data.id_organizacao && data.id_criacao && data.tipo_visualizacao && data.id_fecharcaso) {
                    const response = await APIUser.createUserOrganization(token, newData);

                    if (response.status !== 200) {
                        hasError = true;
                    }
                }
            }

            for (const item of staggeredList) {
                if (item.to_view) {
                    const data = {
                        id_escalonamento: item.id_escalonamento,
                        id_usuario: userCreated
                    }

                    const response = await APIUser.createStaggeredUser(token, data);

                    if (response.status !== 200) {
                        hasError = true;
                    }
                }
            }

            if (hasError) {
                Toast(
                    "Não foi possível fazer a associação das organizações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            } else {
                Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
            }

        } catch (error) {
            console.error("Erro ao criar usuário:", error);
            hasError = true;

            Toast(
                "Erro ao criar usuário!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        }

        if (hasError) {
            Toast(
                "Não foi possível adicionar o usuário!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        } else {
            onCreate();
            toGoBack();
            setPageStatus("awaiting");
        }
    };

    async function getOrganizationsList() {
        const response = await APISla.listOrganizations(token);

        if (response.status === 200) {
            let dataUpdatedOrganization = [];

            response.data.forEach((data) => {
                let requestedItemOrganization = {};

                requestedItemOrganization.id = data.id_organizacao;
                requestedItemOrganization.label = data.nome;

                dataUpdatedOrganization.push(requestedItemOrganization);
            });

            setOrganizations(dataUpdatedOrganization)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem de organizações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getGroupsList() {
        const response = await APIUser.getUserGroup(token)

        if (response.status === 200) {
            let dataUpdatedGroup = [];

            response.data.forEach((data) => {
                let requestedItemGroup = {};

                requestedItemGroup.id = data.idgrupo;
                requestedItemGroup.label = data.desc_grupo;

                dataUpdatedGroup.push(requestedItemGroup);
            });

            setGroups(dataUpdatedGroup);
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem dos grupos!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getProfilesList() {
        const response = await APIUser.getUserProfile(token)

        if (response.status === 200) {
            let dataUpdatedProfile = [];

            response.data.forEach((data) => {
                let requestedItemProfile = {};

                requestedItemProfile.id = data.id_perfil;
                requestedItemProfile.label = data.desc_perfil;

                dataUpdatedProfile.push(requestedItemProfile)
            });

            setProfiles(dataUpdatedProfile)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem dos perfis!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getFunctionsList() {
        const response = await APIUser.getUserFunction(token)

        if (response.status === 200) {
            let dataUpdatedFunction = [];

            response.data.forEach((data) => {
                let requestedItemFunction = {};

                requestedItemFunction.id = data.idfuncao;
                requestedItemFunction.label = data.desc_funcao;

                dataUpdatedFunction.push(requestedItemFunction)
            });

            setFunctions(dataUpdatedFunction)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem das funções!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getViewTypeList() {
        const response = await APIUser.getViewType(token)

        if (response.status === 200) {
            let dataUpdatedViewType = [];

            response.data.forEach((data) => {
                let requestedItemViewType = {};

                requestedItemViewType.id = data.tipo_visualizacao;
                requestedItemViewType.label = data.desc_tipo_visualizacao;

                dataUpdatedViewType.push(requestedItemViewType);
            });

            setViewType(dataUpdatedViewType)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem dos tipos de visualizações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getStaggeredList() {
        setLoading(true);

        const response = await APICase.getEscalateList(token);

        if (response.status === 200) {
            setStaggeredList(response.data);
        } else {
            Toast(
                "Falha, ocorreu um erro ao tentar obter os dados da lista de escalonados.",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    async function getDataFromSelects() {
        setPageStatus("requesting");

        try {
            await Promise.allSettled([
                getOrganizationsList(),
                getGroupsList(),
                getProfilesList(),
                getFunctionsList(),
                getViewTypeList()
            ])
        } catch (error) {
            console.error('Ocorreu um erro ao buscar dados dos selects:', error);

            Toast(
                "Falha, não foi possível carregar os dados dos selects",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    //USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                id: "name",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Visualizar",
                accessor: "toView",
                id: "toView",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            }
        ]
    );

    const staggeredData = useMemo(() => {
        if (staggeredList) {
            const refactoredData = refactorStaggeredListing(staggeredList);
            return refactoredData;
        }
    }, [staggeredList]);

    //USE EFFECTS
    useEffect(() => {
        getDataFromSelects();
        getStaggeredList();
    }, []);

    useEffect(() => {
        staggeredList.forEach(staggered => {
            Object.assign(staggered, { to_view: false })
        })
    }, [staggeredList.length === 0]);

    return (
        <>
            <main className="page-create-user">
                <header className="page-create-user__header">
                    <div className="page-create-user__header__title">
                        <ArrowLefIcon
                            onClick={() => {
                                cleanFields();
                                toGoBack();
                            }}
                        />

                        <Typography.H2 color="gray-180" weight="bold">
                            Adicionar usuário
                        </Typography.H2>
                    </div>

                    <div className="page-create-user__header__save-or-cancel">
                        <Button
                            className="btn-cancel-user"
                            disabled={pageStatus === "creating-user"}
                            onClick={() => {
                                cleanFields();
                                toGoBack();
                            }}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-user"
                            disabled={pageStatus === "creating-user"}
                            onClick={handleCreateUser}
                        >
                            {pageStatus !== "creating-user" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="section-container">
                    <section className="basic-data-section">
                        <header className="basic-data-section-header">
                            <div className="basic-data-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Dados básicos
                                </Typography.H4>
                            </div>

                            <div className="basic-data-section-header__division" />
                        </header>

                        <div className="basic-data-content-container">
                            <div className="basic-data-content-container__content">
                                <div className="columns-data">
                                    <FieldContainer title="Nome" required>
                                        <Input
                                            type="text"
                                            placeholder="Digite o nome aqui"
                                            value={usersData.nome}
                                            onChange={(event) => setUsersData({ ...usersData, nome: event })}
                                            error={validationWarn ? !usersData.nome : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Login" required>
                                        <Input
                                            type="text"
                                            placeholder="Digite o login aqui"
                                            value={usersData.login}
                                            onChange={(event) => setUsersData({ ...usersData, login: event })}
                                            error={validationWarn ? !usersData.login : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Matrícula(Opcional)">
                                        <Input
                                            type="text"
                                            placeholder="Digite a matrícula aqui"
                                            value={usersData.matricula}
                                            onChange={(event) => setUsersData({ ...usersData, matricula: event })}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Perfil" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione uma opção"
                                            value={usersData.id_perfil}
                                            options={profiles}
                                            onSelect={(profile) => setUsersData({ ...usersData, id_perfil: profile.id })}
                                            error={validationWarn ? !usersData.id_perfil : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Função" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione uma opção"
                                            value={usersData.idfuncao}
                                            options={functions}
                                            onSelect={(item) => setUsersData({ ...usersData, idfuncao: item.id })}
                                            error={validationWarn ? !usersData.idfuncao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Grupo" required>
                                        <CustomDropdown
                                            noTippy
                                            changeDownArrowIcon
                                            placeholder="Selecione"
                                            value={usersData.idgrupo}
                                            options={groups}
                                            // optionsLimit={enterprisesLimit}
                                            // setOptionsLimit={setEnterprisesLimit}
                                            onSelect={(group) => setUsersData({ ...usersData, idgrupo: group.id })}
                                            showCustomEmptyState={true}
                                            customEmptyStateIcon={
                                                <SearchIcon style={{ width: "48px", height: "48px" }} />
                                            }
                                            // onCustomEmptyStateClick={() => { }}
                                            customEmptyStateMessage={
                                                // <span>
                                                //     Não encontramos nenhum grupo. <br />
                                                //     Deseja adicionar este contato?
                                                // </span>
                                                <span>
                                                    Não foram encontrados grupos. <br />
                                                    Para adicionar um novo grupo, digite o nome e clique em "Enter".
                                                </span>
                                            }
                                            // buttonText="Adicionar Grupo"
                                            onEnterPress={handleGroupCreationOnDownKey}
                                            error={validationWarn ? !usersData.idgrupo : false}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="join-organization-section">
                        <header className="join-organization-section-header">
                            <div className="join-organization-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Organização
                                </Typography.H4>
                            </div>

                            <div className="join-organization-section-header__division" />
                        </header>

                        <div className="join-organization-content-container">
                            <div className="join-organization-content-container__content">
                                <div className="first-column-data">
                                    <FieldContainer title="Organização" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione uma opção"
                                            value={usersData.id_organizacao}
                                            options={organizations.filter(org => {
                                                return !organizationsData.organizations_associations.some(item => item.id_organizacao === org.id);
                                            })}
                                            onSelect={(organization) => {
                                                setUsersData({ ...usersData, id_organizacao: organization.id });
                                            }}
                                            error={validationWarn ? !usersData.id_organizacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Permitir o usuário criar casos?" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione"
                                            options={optionsCaseCreation}
                                            value={organizationsData.id_criacao}
                                            onSelect={(userPermission) => {
                                                setOrganizationsData((prevState) => ({
                                                    ...prevState,
                                                    id_criacao: userPermission.id,
                                                    criacao: userPermission.id === 1 ? true : false,
                                                }));
                                            }}
                                            error={validationWarn ? !organizationsData.id_criacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Permitir o usuário fechar casos?" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione"
                                            value={organizationsData.id_fecharcaso}
                                            options={caseClosureOptions}
                                            onSelect={(closeCase) => {
                                                setOrganizationsData((prevState) => ({
                                                    ...prevState,
                                                    id_fecharcaso: closeCase.id,
                                                    fecharcaso: closeCase.id === 1 ? true : false
                                                }));
                                            }}
                                            error={validationWarn ? !organizationsData.id_fecharcaso : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Tipo de visualização" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Apenas casos criados pelo usuário"
                                            options={viewType}
                                            value={organizationsData.tipo_visualizacao}
                                            onSelect={(viewType) => {
                                                setOrganizationsData((prevState) => ({
                                                    ...prevState,
                                                    tipo_visualizacao: viewType.id,
                                                }));
                                            }}
                                            error={validationWarn ? !organizationsData.tipo_visualizacao : false}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="join-organization-section">
                        <header className="join-organization-section-header">
                            <div className="join-organization-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Associar a outras organizações
                                </Typography.H4>

                                <div className="join-organization-section-header__clear-fields">
                                    <CleanIcon className="disabled-icon" />

                                    <Typography.H5
                                        weight="400"
                                        color="gray-125"
                                    >
                                        Limpar campos
                                    </Typography.H5>
                                </div>
                            </div>

                            <div className="join-organization-section-header__division" />
                        </header>

                        <div className="join-organization-content-container">
                            <div className="join-organization-content-container__content">
                                {organizationsData.organizations_associations.map((item, index) => {
                                    const { id_organizacao, id_criacao, tipo_visualizacao, id_fecharcaso } = item;

                                    const filteredOrganizations = organizations.filter(org => {
                                        return !organizationsData.organizations_associations
                                            .slice(0, index)
                                            .some(prevItem => prevItem.id_organizacao === org.id);
                                    });

                                    const filteredMainOrganization = filteredOrganizations.filter(org => org.id !== usersData.id_organizacao);

                                    return (
                                        <div
                                            key={index}
                                            className="first-column-data"
                                        >
                                            <FieldContainer title="Organização">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Selecione"
                                                    options={filteredMainOrganization}
                                                    value={id_organizacao}
                                                    onSelect={(organization) => {
                                                        const organizationSelect = cloneDeep(organizationsData);

                                                        const isOrganizationSelected = organizationsData.organizations_associations
                                                            .slice(0, index)
                                                            .some(prevItem => prevItem.id_organizacao === organization.id);

                                                        if (!isOrganizationSelected) {
                                                            organizationSelect.organizations_associations[index].id_organizacao = organization.id;
                                                            setOrganizationsData(organizationSelect);
                                                        }
                                                    }}
                                                />
                                            </FieldContainer>

                                            <FieldContainer title="Permitir o usuário criar casos?">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Selecione"
                                                    value={id_criacao}
                                                    options={optionsCaseCreation}
                                                    onSelect={(userPermission) => handleCreationOption(userPermission, index)}
                                                />
                                            </FieldContainer>

                                            <FieldContainer title="Permitir o usuário fechar casos?">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Selecione"
                                                    value={id_fecharcaso}
                                                    options={caseClosureOptions}
                                                    onSelect={(closeCase) => handleCaseClosureOption(closeCase, index)}
                                                />
                                            </FieldContainer>

                                            <FieldContainer title="Tipo de visualização">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Apenas casos criados pelo usuário"
                                                    value={tipo_visualizacao}
                                                    options={viewType}
                                                    onSelect={(viewType) => {
                                                        const viewTypeSelect = cloneDeep(organizationsData);

                                                        viewTypeSelect.organizations_associations[index].tipo_visualizacao = viewType.id;

                                                        setOrganizationsData(viewTypeSelect);
                                                    }}
                                                />
                                            </FieldContainer>

                                            <div className="first-column-data__actions">
                                                <MinusCircleIcon
                                                    className={`remove-association ${organizationsData.organizations_associations.length === 1 && "disabled"}`}
                                                    onClick={(e) => {
                                                        if (organizationsData.organizations_associations.length === 1) return;

                                                        e.preventDefault();
                                                        handleRemoveAssociation(index)
                                                    }}
                                                />

                                                {!allOrganizationsAssociated() && (
                                                    <AddCircleIcon
                                                        className="add-association"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleAssociationAddition()
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="staggered-views-section">
                        <header className="staggered-views-section-header">
                            <div className="staggered-views-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Quais escalonados posso visualizar?
                                </Typography.H4>

                                <button onClick={handleSelectAll}>
                                    <TickSquareIcon />

                                    <Typography.H4 weight="500" color="blue-selected-4">
                                        Marcar todos
                                    </Typography.H4>
                                </button>
                            </div>

                            <div className="staggered-views-section-header__division" />
                        </header>

                        <div className="staggered-views-content-container">
                            <div className="staggered-views-content-container__content">
                                {loading ? (
                                    <div className="staggered-loading">
                                        <Loading.Paperplane />
                                    </div>
                                ) : (
                                    <Table
                                        fixedHeader
                                        noPagination
                                        className="table-staggered"
                                        columns={tableColumns}
                                        data={staggeredData}
                                    />
                                )}
                            </div>
                        </div>
                    </section>
                </div >
            </main >
        </>
    )
}
