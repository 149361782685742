import instance from "./configAxios";

export async function listReminder(idTask, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.get(`/list-reminders?id_task=${idTask}`, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function listAllReminders(token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.get(`/list-reminders`, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function createReminder(data, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.post('/create-reminder', data, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateReminder(data, token, idReminder) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.put(`/update-reminder/${idReminder}`, data, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteReminder(token, idReminder) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.delete(`/delete-reminder/${idReminder}`, config);
    return response;
  } catch (error) {
    return error.response;
  }
}