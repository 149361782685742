//GLOBAL - components from npm
import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import formatTime from "../../../utils/formatTime";
import axios from "axios";

// UTILS
import { Toast } from "../../../utils/toast";

//STYLES
import "./sending-comment.scss";

//COMPONENTS
import { AttachFile } from "../../data-entry";
import { Typography, Button } from "../../general";
import { Loading, Tippy } from "./../../../components/feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconMicrophone } from "../../../assets/icons/history-icons/HistoricMicrophone2.svg";
import { ReactComponent as IconSend } from "../../../assets/icons/Send-2.svg";
import { ReactComponent as Trash } from "../../../assets/icons/Trash-2.svg";
import { ReactComponent as Pause } from "../../../assets/icons/Pause-2.svg";
import { ReactComponent as Play } from "../../../assets/icons/PlayIcon.svg";
import { ReactComponent as IconGallery } from "../../../assets/icons/Gallery.svg";
import { ReactComponent as IconDocument } from "../../../assets/icons/Document.svg";
import { ReactComponent as IconAttach } from "../../../assets/icons/history-icons/HistoricAttach.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/Trash.svg";
import { ReactComponent as IconMic } from "../../../assets/icons/Mic.svg";
import { ReactComponent as IconRec } from "../../../assets/icons/Rec.svg";

const SendingComment = forwardRef(
  (
    {
      onAdd,
      setFiles,
      files,
      commentResponse = {},
      setCommentResponse = (value) => ({}),
      setLoadingComment = (value) => false,
      loadingComment,
      onClose,
      microphoneRemoved,
      audioToTheCenter,
      wFull
    },
    ref
  ) => {
    //GENERAL
    const audioTime = useRef(0);
    const {
      startRecording,
      stopRecording,
      pauseRecording,
      resumeRecording,
      status,
      mediaBlobUrl,
      clearBlobUrl,
    } = useReactMediaRecorder({ audio: true });

    //STATES
    const [inputValue, setInputValue] = useState("");
    const [audioOpen, setAudioOpen] = useState(false);
    const [timeAudio, setTimeAudio] = useState("00:00");
    const [sendAudio, setSendAudio] = useState(false);
    const [visible, setVisible] = useState(false);

    //REDUX - Selectors

    //FUNCTIONS
    const postComment = async () => {
      if (!inputValue && files.length === 0) {
        return;
      }

      setLoadingComment("posting");

      await onAdd(inputValue, files, commentResponse);

      setLoadingComment("awaiting");

      setInputValue("");
      setFiles([]);
      setCommentResponse();
      if (onClose) {
        onClose();
      }
    };

    const postFileAudio = async () => {
      const response = await axios({
        url: mediaBlobUrl,
        method: "GET",
        responseType: "blob",
      });

      const arrayFile = [];
      const file = new File([response.data], "audio.wav", {
        type: response.data.type,
      });
      arrayFile.push({ file: file });

      setLoadingComment("posting");

      await onAdd(null, arrayFile, commentResponse, true);
      // console.log(mediaBlobUrl)

      setLoadingComment("awaiting");
      setCommentResponse();
      if (onClose) {
        onClose();
      }

      clearBlobUrl();
    };

    function getLocalStream() {
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          window.localStream = stream; // A
          window.localAudio.srcObject = stream; // B
          window.localAudio.autoplay = true; // C
        })
        .catch((err) => {
          console.log("u got an error:" + err);
        });
    }

    const checkMicrophone = () => {
      navigator.permissions
        .query(
          // { name: 'camera' }
          { name: "microphone" }
          // { name: 'geolocation' }
          // { name: 'notifications' }
          // { name: 'midi', sysex: false }
          // { name: 'midi', sysex: true }
          // { name: 'push', userVisibleOnly: true }
          // { name: 'push' } // without userVisibleOnly isn't supported in chrome M45, yet
        )
        .then(function (permissionStatus) {
          if (permissionStatus.state !== "granted") {
            Toast(
              "Microfone Desativado!",
              "Por favor, ative a permissão do microfone.",
              "warn"
            );
            getLocalStream();
          } else {
            startRecording();
            setAudioOpen(true);
          }
        });
    };

    const show = () => setVisible(true);

    const hide = () => {
      setVisible(false);
    };

    //USE EFFECTS
    useEffect(() => {
      const intervalId = setInterval(() => {
        audioTime.current = audioTime.current + 1;
        setTimeAudio(formatTime(audioTime.current));
      }, 1000);

      if (!audioOpen || status === "paused") {
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }, [audioOpen, status]);

    useEffect(() => {
      if (mediaBlobUrl && sendAudio) {
        postFileAudio();
      }
    }, [mediaBlobUrl]);

    return (
      <>
        {audioOpen ? (
          <>
            <div className={`audio-recorder ${audioToTheCenter ? "center" : ""}`}>
              <button
                onClick={() => {
                  setSendAudio(false);
                  stopRecording();
                  setAudioOpen(false);
                  setTimeAudio("00:00");
                  clearBlobUrl();
                  audioTime.current = 0;
                }}
                className="buttons-action__cancelButton"
              >
                <Trash />
              </button>
              <div className="audio-recorder_audioComment" style={wFull && { width: "100%" }}>
                <div className={`audio-recorder_audioComment__recordBar ${wFull ? "wFull" : ""}`}></div>
                <Typography.H6
                  className={"audio-recorder_audioComment__timeProgress"}
                  weight="600"
                >
                  {timeAudio}
                </Typography.H6>
              </div>
              {status !== "paused" && (
                <button
                  onClick={() => {
                    pauseRecording();
                  }}
                  className="buttons-action__cancelButton"
                >
                  <Pause />
                </button>
              )}
              {status === "paused" && (
                <button
                  onClick={() => {
                    resumeRecording();
                  }}
                  className="buttons-action__cancelButton"
                >
                  <Play />
                </button>
              )}
              <button
                onClick={() => {
                  setSendAudio(true);
                  setTimeAudio("00:00");
                  setAudioOpen(false);
                  audioTime.current = 0;
                  stopRecording();
                }}
                className="buttons-action__cancelButton"
              >
                <IconSend />
              </button>
            </div>
            {/* <div className="audio-recorder">
              <IconTrash
                onClick={() => {
                  setSendAudio(false);
                  stopRecording();
                  setAudioOpen(false);
                  setTimeAudio("00:00");
                  clearBlobUrl();
                  audioTime.current = 0;
                }}
              />
              <div className="audio-recorder-send">
                <button
                  onClick={() => {
                    setSendAudio(true);
                    setTimeAudio("00:00");
                    setAudioOpen(false);
                    audioTime.current = 0;
                    stopRecording();
                  }}
                >
                  <Typography.Paragraph color="primary-dark">
                    Enviar
                  </Typography.Paragraph>
                </button>
                <div className="audio-recorder-send-time">
                  <Typography.Paragraph color="gray-400">
                    {timeAudio}
                  </Typography.Paragraph>
                </div>
                <IconRec />
              </div>
            </div> */}
          </>
        ) : (
          <div className="sending-comment">
            <label className="sending-comment__input">
              <input
                ref={ref}
                placeholder="Escreva aqui seu comentário..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) =>
                  (e.code === "Enter" || e.code === "NumpadEnter") &&
                  loadingComment === "awaiting" &&
                  postComment()
                }
              />
              <>
                <Tippy
                  theme="popover-default"
                  // #TOVERIFY: TRIGGER
                  // trigger="click"
                  visible={visible}
                  onClickOutside={hide}
                  placement="top-end"
                  delay={[0, 0]}
                  allowHTML
                  interactive
                  content={
                    <div className="popover-send_comment">
                      <div className="popover-send_comment__title">
                        <Typography.H4 color={"gray-180"} weight="bold">
                          Anexo
                        </Typography.H4>
                      </div>
                      <div
                        className="popover-send_comment__item"
                        onClick={() => hide()}
                      >
                        <AttachFile
                          textIcon={"Imagem"}
                          icon={<IconGallery />}
                          setFiles={setFiles}
                          imageFile
                        />
                      </div>
                      <div
                        className="popover-send_comment__item"
                        onClick={() => hide()}
                      >
                        <AttachFile
                          textIcon={"Arquivo"}
                          icon={<IconDocument />}
                          setFiles={setFiles}
                        />
                      </div>
                    </div>
                  }
                >
                  <IconAttach
                    className="comment-icon__menu"
                    onClick={() => show()}
                  />
                </Tippy>
                {
                  microphoneRemoved ? null :
                    <IconMicrophone
                      className="sending-comment__mic"
                      onClick={() => {
                        checkMicrophone();
                        // startRecording();
                        // setAudioOpen(true);
                      }}
                    />
                }
              </>
            </label>
            {loadingComment === "posting" || loadingComment === "deleting" ? (
              <Loading.Dots width="50px" height="40px" className="loading" />
            ) : (
              <Button
                className="sending-comment__btn-send"
                onClick={() => postComment()}
              >
                <IconSend />
              </Button>
            )}
            {/* <AttachFile setFiles={setFiles} />
            <label className="sending-comment__input">
              <input
                ref={ref}
                placeholder="Digite seu comentário aqui"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) =>
                  (e.code === "Enter" || e.code === "NumpadEnter") &&
                  loadingComment === "awaiting" &&
                  postComment()
                }
              />
              {loadingComment === "posting" || loadingComment === "deleting" ? (
                <Loading.Dots width="50px" height="40px" className="loading" />
              ) : (
                <button onClick={() => postComment()}>
                  <Typography.Paragraph color="primary-dark">
                    Enviar
                  </Typography.Paragraph>
                </button>
              )}
            </label>
            <IconMic
              className="sending-comment__mic"
              onClick={() => {
                checkMicrophone();
                // startRecording();
                // setAudioOpen(true);
              }}
              /> */}
          </div>
        )}
      </>
    );
  }
);

export default SendingComment;
