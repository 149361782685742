//GLOBAL - components from npm
import React from "react";

//STYLES
import "./button.scss";

//COMPONENTS
import { Typography } from "./../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function Button({
  className,
  type = "primary",
  width,
  height,
  children,
  icon,
  iconFillType = "fill",
  onClick,
  htmlType = "button",
  disabled,
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <button
      type={htmlType}
      className={`btn ${type ? `btn-${type}` : ""} ${
        iconFillType ? `btn-icon--${iconFillType}` : ""
      } ${className ? className : ""}`}
      style={{ width: width, height: height }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled}
    >
      {icon}
      {children}
    </button>
  );
}
