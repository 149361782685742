//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import { Button, Typography } from "../../../components/general";
import { Input } from "../../../components/data-entry";
import { Loading, Modal } from "../../../components/feedback";
import { Table } from "../../../components/data-display";
import { Toast } from "../../../utils/toast";
import CreateProfile from "./CreateProfile/CreateProfile";
import UpdateProfile from "./UpdateProfile/UpdateProfile";

//SERVICES - api, conectors...
import * as APIProfile from "../../../services/api/profile";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search-normal.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-3.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";
import { ReactComponent as UserOctagonIcon } from "../../../assets/icons/user-octagon.svg";

// STYLES
import "./profile.scss";

export default function Profile({ returnToRecord }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [searchData, setSearchData] = useState("");
    const [emptyState, setEmptyState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(false);
    const [registerProfile, setRegisterProfile] = useState(false);
    const [updatedProfile, setUpdatedProfile] = useState(false);
    const [updateProfile, setUpdateProfile] = useState({
        state: false,
        profileId: null
    });
    const [isOpenModalDeleteProfile, setIsOpenModalDeleteProfile] = useState({
        state: false,
        profileId: null,
        profileName: null
    });
    const [profiles, setProfiles] = useState([]);
    const [filteredProfiles, setFilteredProfiles] = useState([]);

    console.log("profiles", profiles)

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleSearchProfiles(event) {
        const inputValue = event;

        setSearchData(inputValue);

        if (event === "") {
            setFilteredData(false);
            setFilteredProfiles([]);
        }
    };

    function handleTableDataLookup() {
        const searchTerm = searchData.toLowerCase();

        const filteredData = profiles.filter(
            (data) =>
            (data.desc_perfil != null &&
                data.desc_perfil !== "" &&
                data.desc_perfil.toLowerCase().includes(searchTerm))
        );

        setFilteredProfiles(filteredData);
        setFilteredData(true);
    };

    function refactorProfileListing(data) {
        let refactoredData = [];

        if (!data) throw new Error('Error when fetching data!');

        data && data.map((item) => {
            const newObject = {
                profileName: {
                    data: item.desc_perfil,
                    config: {
                        className: "profile-name"
                    },
                },
                actions: {
                    data: (
                        <div className="actions-profiles">
                            <button
                                className="actions-profiles__edit"
                            >
                                <EditIcon
                                    onClick={() => setUpdateProfile({
                                        state: true,
                                        profileId: item.id_perfil
                                    })}
                                />
                            </button>

                            <button
                                className="actions-profiles__delete"
                            >
                                <TrashIcon
                                    onClick={() => setIsOpenModalDeleteProfile({
                                        state: true,
                                        profileId: item.id_perfil,
                                        profileName: item.desc_perfil
                                    })}
                                />
                            </button>
                        </div>
                    ),
                    config: {
                        className: "profile-action-options"
                    }
                },
            }

            refactoredData.push(newObject);

            return refactoredData;
        });

        return refactoredData;
    };

    function renderTableContent() {
        switch (loading) {
            case true:
                return (
                    <div className="table-card-content__loading">
                        <Loading.Paperplane />
                    </div>
                )

            case false:
                if (profiles.length > 0) {
                    return (
                        <>
                            {searchData && filteredData && filteredProfiles.length < 1 ? (
                                <div className="table-card-content__empty-state">
                                    <div className="table-card-content__empty-state__icon">
                                        <UserOctagonIcon />
                                    </div>

                                    <div className="table-card-content__empty-state__text">
                                        <Typography.H4 weight="bold" color="blue-selected-4">
                                            Ops! Sem perfis cadastrados.
                                        </Typography.H4>

                                        <Typography.H5 color="gray-180" className="text-center">
                                            Clique no botão <span className="text-bold">"Adicionar perfil"</span>. <br />
                                            Preencha os dados do novo perfil e atribua as permissões.
                                        </Typography.H5>
                                    </div>
                                </div>
                            ) : (
                                <div className="table-card-content__table-container">
                                    <Table
                                        fixedHeader
                                        className="table-profiles"
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        columns={tableColumns}
                                        data={searchData && filteredData ? profilesFiltered : profilesData}
                                    />
                                </div>
                            )}
                        </>
                    )
                } else {
                    return (
                        <div className="table-card-content__empty-state">
                            <div className="table-card-content__empty-state__icon">
                                <UserOctagonIcon />
                            </div>

                            <div className="table-card-content__empty-state__text">
                                <Typography.H4 weight="bold" color="blue-selected-4">
                                    Ops! Sem perfis cadastrados.
                                </Typography.H4>

                                <Typography.H5 color="gray-180" className="text-center">
                                    Clique no botão <span className="text-bold">"Adicionar perfil"</span>. <br />
                                    Preencha os dados do novo perfil e atribua as permissões.
                                </Typography.H5>
                            </div>
                        </div>
                    )
                }

            default:
                break;
        }
    };

    async function getListOfProfiles(profileId) {
        setLoading(true);

        try {
            const response = await APIProfile.getListOfProfiles(token, profileId);

            if (response.status === 200) {
                const profilesWithParsedOrganizations = response.data.map(profile => ({
                    ...profile,
                    organizacoes_autorizadas: JSON.parse(profile.organizacoes_autorizadas)
                }));

                setProfiles(profilesWithParsedOrganizations);
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } catch (error) {
            console.error("Erro ao processar a resposta da API:", error);

            Toast(
                "Erro ao processar a resposta da API",
                "Por favor, tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    // USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Nome do perfil",
                accessor: "profileName",
                id: "profileName",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ]
    );

    const profilesData = useMemo(() => {
        if (profiles) {
            const refactoredData = refactorProfileListing(profiles);
            return refactoredData;
        }
    }, [profiles]);

    const profilesFiltered = useMemo(() => {
        if (Array.isArray(filteredProfiles)) {
            const refactoredData = refactorProfileListing(filteredProfiles);
            return refactoredData;
        }
    }, [filteredProfiles]);


    //USE EFFECTS
    useEffect(() => {
        if (!searchData || searchData.trim() === "") {
            setFilteredData(false);
            setFilteredProfiles([]);
        }
    }, []);

    useEffect(() => {
        if (updatedProfile) {
            handleTableDataLookup()
        }
    }, [updatedProfile, profiles]);

    useEffect(() => {
        getListOfProfiles();
    }, []);

    return (
        <>
            {registerProfile ? (
                <CreateProfile
                    toGoBack={() => setRegisterProfile(false)}
                    onCreate={getListOfProfiles}
                />
            ) : updateProfile.state ? (
                <UpdateProfile
                    toGoBack={() => setUpdateProfile({ state: false })}
                    profileId={updateProfile.profileId}
                    detailedProfile={profiles}
                    setUpdatedProfile={setUpdatedProfile}
                    onUpdate={getListOfProfiles}
                    handleTableDataLookup={handleTableDataLookup}
                />
            ) : (
                <main className="page-profile">
                    <header className="page-profile__header">
                        <div className="page-profile__header__title">
                            <ArrowLefIcon onClick={returnToRecord} />

                            <Typography.H2 color="gray-180" weight="bold">
                                Cadastro de perfis
                            </Typography.H2>
                        </div>

                        <div>
                            <Button
                                className=""
                                onClick={() => setRegisterProfile(true)}
                            >
                                <Typography.H4 weight="bold" color="white">
                                    Adicionar perfil
                                </Typography.H4>
                            </Button>
                        </div>
                    </header>

                    <div className="table-card">
                        <div className="table-card-content">
                            <Input
                                type="text"
                                width="432px"
                                placeholder="Pesquisar"
                                value={searchData}
                                onChange={handleSearchProfiles}
                                onKeyPress={(event) =>
                                    (event.code === "Enter" || event.code === "NumpadEnter") &&
                                    handleTableDataLookup()
                                }
                                rightIcon={
                                    <button onClick={handleTableDataLookup}>
                                        <SearchIcon />
                                    </button>
                                }
                            />

                            {renderTableContent()}
                        </div>
                    </div>
                </main>
            )}

            <Modal.DeleteProfile
                isOpen={isOpenModalDeleteProfile.state}
                onClose={(event) => setIsOpenModalDeleteProfile({
                    ...isOpenModalDeleteProfile, state: event
                })}
                profileId={isOpenModalDeleteProfile.profileId}
                profileName={isOpenModalDeleteProfile.profileName}
                onDelete={getListOfProfiles}
                profiles={profiles}
                handleTableDataLookup={handleTableDataLookup}
            />
        </>
    )
}
