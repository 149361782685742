import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Modal } from "./../../../feedback";
import { Button, Typography } from "./../../../general";
import { FieldContainer } from "../../../data-display";
import { Input } from "../../../data-entry";
import { Toast } from "./../../../../utils/toast";
import { onKeyPress } from "./../../../../utils/onKeyPress";
import { maskCpfCnpj } from "../../../../utils/masks";
import Loading from "../../Loading";

import * as APIContact from "./../../../../services/api/APIContact";

import { ReactComponent as ImgShop } from "./../../../../assets/images/shop.svg";

import "./create-company.scss";

export default function CreateCompany({ isOpen, onClose, onCreate }) {
  const token = useSelector((state) => state.auth.token);
  const interaction = useSelector((state) => state.interaction);

  const [pageStatus, setPageStatus] = useState("awaiting");
  const [validationWarn, setValidationWarn] = useState(false);
  const [inputErrorEmail, setInputErrorEmail] = useState(false);
  const [inputErrorCnpj, setInputErrorCnpj] = useState(false);
  const [inputErrorSite, setInputErrorSite] = useState(false);
  const [onBlurInputErrorEmail, setonBlurInputErrorEmail] = useState(false);
  const [onBlurInputErrorCnpj, setonBlurInputErrorCnpj] = useState(false);
  const [onBlurInputErrorSite, setonBlurInputErrorSite] = useState(false);
  const [emptyCompanyData] = useState(() => {
    return {
      nome: "",
      nome_fantasia: "",
      cnpj: "",
      email: "",
      site: "",
      inscricaoestadual: "",
      inscricaomunicipal: "",
    };
  });
  const [companyData, setCompanyData] = useState({ ...emptyCompanyData });

  var validURL =
    /((https?:\/\/)|(ftp:\/\/)|(^))([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+([a-zA-Z]{2,9})(:\d{1,4})?([-\w\/#~:.?+=&amp;%@~]*)/;
  var validCNPJ = /(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/;

  function onCloseCustom() {
    onClose();
    setTimeout(() => {
      setCompanyData({ ...emptyCompanyData });
      setValidationWarn(false);
      setInputErrorCnpj(false);
      setInputErrorEmail(false);
      setInputErrorSite(false);
      setPageStatus("awaiting");
    }, 250);
  }

  function isFormValidated() {
    if (!companyData.nome) return false;
    else if (companyData.email && !/\S+@\S+\.\S+/.test(companyData.email))
      return false;
    else if (
      (companyData.cnpj && validCNPJ.test(companyData.cnpj) === false) ||
      companyData.cnpj === "00.000.000/0000-00" ||
      companyData.cnpj === "11.111.111/1111-11" ||
      companyData.cnpj === "22.222.222/2222-22" ||
      companyData.cnpj === "33.333.333/3333-33" ||
      companyData.cnpj === "44.444.444/4444-44" ||
      companyData.cnpj === "55.555.555/5555-55" ||
      companyData.cnpj === "66.666.666/6666-66" ||
      companyData.cnpj === "77.777.777/7777-77" ||
      companyData.cnpj === "88.888.888/8888-88" ||
      companyData.cnpj === "99.999.999/9999-99"
      /* companyData.cnpj && companyData.cnpj.length < 14 ||
            companyData.cnpj === "00000000000000" ||
            companyData.cnpj === "11111111111111" ||
            companyData.cnpj === "22222222222222" ||
            companyData.cnpj === "33333333333333" ||
            companyData.cnpj === "44444444444444" ||
            companyData.cnpj === "55555555555555" ||
            companyData.cnpj === "66666666666666" ||
            companyData.cnpj === "77777777777777" ||
            companyData.cnpj === "88888888888888" ||
            companyData.cnpj === "99999999999999" */
    )
      return false;
    else if (companyData.site && validURL.test(companyData.site) === false)
      return false;

    return true;
  }

  async function handleCreateCompany() {
    if (isFormValidated()) {
      setPageStatus("creating-company");

      if (companyData.cnpj) {
        companyData.cnpj = companyData.cnpj.replace(/[-/\.//"]/g, "");
      }

      const response = await APIContact.createEnterprise(token, companyData);

      if (response.status === 200) {
        Toast(
          "Empresa adicionada!",
          "Empresa adicionada com sucesso.",
          "success"
        );
        onCreate();
        onCloseCustom();
      } else {
        Toast(
          "Não foi possível adicionar empresa",
          "Tente novamente mais tarde!",
          "error"
        );
      }

      setPageStatus("awaiting");
    } else if (!companyData.nome) {
      setValidationWarn(true);

      Toast(
        "Erro ao adicionar empresa!",
        "Verifique o preenchimento dos campos em destaque",
        "error"
      );
    } else if (companyData.email && !/\S+@\S+\.\S+/.test(companyData.email)) {
      setInputErrorEmail(true);
      setonBlurInputErrorEmail(true);
    } else if (
      (companyData.cnpj && validCNPJ.test(companyData.cnpj) === false) ||
      companyData.cnpj === "00.000.000/0000-00" ||
      companyData.cnpj === "11.111.111/1111-11" ||
      companyData.cnpj === "22.222.222/2222-22" ||
      companyData.cnpj === "33.333.333/3333-33" ||
      companyData.cnpj === "44.444.444/4444-44" ||
      companyData.cnpj === "55.555.555/5555-55" ||
      companyData.cnpj === "66.666.666/6666-66" ||
      companyData.cnpj === "77.777.777/7777-77" ||
      companyData.cnpj === "88.888.888/8888-88" ||
      companyData.cnpj === "99.999.999/9999-99"
      /* companyData.cnpj && companyData.cnpj.length < 14 ||
            companyData.cnpj === "00000000000000" ||
            companyData.cnpj === "11111111111111" ||
            companyData.cnpj === "22222222222222" ||
            companyData.cnpj === "33333333333333" ||
            companyData.cnpj === "44444444444444" ||
            companyData.cnpj === "55555555555555" ||
            companyData.cnpj === "66666666666666" ||
            companyData.cnpj === "77777777777777" ||
            companyData.cnpj === "88888888888888" ||
            companyData.cnpj === "99999999999999" */
    ) {
      setInputErrorCnpj(true);
      setonBlurInputErrorCnpj(true);
    } else if (companyData.site && validURL.test(companyData.site) === false) {
      setInputErrorSite(true);
      setonBlurInputErrorSite(true);
    }
  }

  function onBlurEventInputError() {
    if (companyData.email && !/\S+@\S+\.\S+/.test(companyData.email)) {
      setInputErrorEmail(true);
      setonBlurInputErrorEmail(true);
    } else {
      setInputErrorEmail(false);
      setonBlurInputErrorEmail(false);
    }

    if (
      (companyData.cnpj && validCNPJ.test(companyData.cnpj) === false) ||
      companyData.cnpj === "00.000.000/0000-00" ||
      companyData.cnpj === "11.111.111/1111-11" ||
      companyData.cnpj === "22.222.222/2222-22" ||
      companyData.cnpj === "33.333.333/3333-33" ||
      companyData.cnpj === "44.444.444/4444-44" ||
      companyData.cnpj === "55.555.555/5555-55" ||
      companyData.cnpj === "66.666.666/6666-66" ||
      companyData.cnpj === "77.777.777/7777-77" ||
      companyData.cnpj === "88.888.888/8888-88" ||
      companyData.cnpj === "99.999.999/9999-99"
      /* companyData.cnpj && companyData.cnpj.length < 14 ||
            companyData.cnpj === "00000000000000" ||
            companyData.cnpj === "11111111111111" ||
            companyData.cnpj === "22222222222222" ||
            companyData.cnpj === "33333333333333" ||
            companyData.cnpj === "44444444444444" ||
            companyData.cnpj === "55555555555555" ||
            companyData.cnpj === "66666666666666" ||
            companyData.cnpj === "77777777777777" ||
            companyData.cnpj === "88888888888888" ||
            companyData.cnpj === "99999999999999" */
    ) {
      setInputErrorCnpj(true);
      setonBlurInputErrorCnpj(true);
    } else {
      setInputErrorCnpj(false);
      setonBlurInputErrorCnpj(false);
    }

    if (companyData.site && validURL.test(companyData.site) === false) {
      setInputErrorSite(true);
      setonBlurInputErrorSite(true);
    } else {
      setInputErrorSite(false);
      setonBlurInputErrorSite(false);
    }
  }

  useEffect(() => {
    if (emptyCompanyData) {
      setCompanyData({ ...emptyCompanyData });
    }
  }, [emptyCompanyData]);

  return (
    <Modal.Main
      className="modal-create-company"
      title="Adicionar empresa"
      width={interaction?.destination !== null ? "550px" : "1000px"}
      isOpen={isOpen}
      onClose={onCloseCustom}
      shouldCloseOnOverlayClick={false}
      footer={
        <div className="container-footer">
          <Button
            className="modal-create-company__btn-cancel"
            onClick={() => onCloseCustom()}
            disabled={pageStatus === "creating-company"}
          >
            <Typography.H4 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H4>
          </Button>

          <Button
            className="modal-create-company__btn-confirm"
            disabled={pageStatus === "creating-company"}
            onClick={() => handleCreateCompany()}
          >
            {pageStatus !== "creating-company" ? (
              <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
            ) : (
              <Loading.Dots />
            )}
          </Button>
        </div>
      }
    >
      <section className="modal-create-company__content">
        <div className="modal-create-icon">
          <ImgShop className="icon-shop" />
        </div>

        <div className="container-full-line">
          <FieldContainer required title="Nome da empresa">
            <Input
              type="text"
              placeholder="Digite o nome da empresa"
              onChange={(event) =>
                setCompanyData({ ...companyData, nome: event })
              }
              onKeyPress={(e) => onKeyPress(e)}
              value={companyData.nome}
              error={validationWarn ? !companyData.nome : false}
            />
          </FieldContainer>
          <FieldContainer title="CNPJ">
            <Input
              type="text"
              placeholder="XX.XXX.XXX/XXXX-XX"
              onBlur={onBlurEventInputError}
              onChange={(event) =>
                setCompanyData({ ...companyData, cnpj: maskCpfCnpj(event) })
              }
              onKeyPress={(e) => onKeyPress(e)}
              value={companyData.cnpj} // replace(/[-/\.//"]/g, '')
              typeInputMessage={inputErrorCnpj ? "error" : false}
              error={
                onBlurInputErrorCnpj
                  ? /* companyData.cnpj && companyData.cnpj.length < 14 ||
                                    companyData.cnpj === "00000000000000" ||
                                    companyData.cnpj === "11111111111111" ||
                                    companyData.cnpj === "22222222222222" ||
                                    companyData.cnpj === "33333333333333" ||
                                    companyData.cnpj === "44444444444444" ||
                                    companyData.cnpj === "55555555555555" ||
                                    companyData.cnpj === "66666666666666" ||
                                    companyData.cnpj === "77777777777777" ||
                                    companyData.cnpj === "88888888888888" ||
                                    companyData.cnpj === "99999999999999" : false */
                    (companyData.cnpj &&
                      validCNPJ.test(companyData.cnpj) === false) ||
                    companyData.cnpj === "00.000.000/0000-00" ||
                    companyData.cnpj === "11.111.111/1111-11" ||
                    companyData.cnpj === "22.222.222/2222-22" ||
                    companyData.cnpj === "33.333.333/3333-33" ||
                    companyData.cnpj === "44.444.444/4444-44" ||
                    companyData.cnpj === "55.555.555/5555-55" ||
                    companyData.cnpj === "66.666.666/6666-66" ||
                    companyData.cnpj === "77.777.777/7777-77" ||
                    companyData.cnpj === "88.888.888/8888-88" ||
                    companyData.cnpj === "99.999.999/9999-99"
                  : false
              }
              customInputMessage={"Cnpj inválido"}
              maxLength="18"
              // maxLength="14"
            />
          </FieldContainer>
          <FieldContainer title="Código Externo">
            <Input
              type="text"
              placeholder="Digite o código"
              onChange={(event) =>
                setCompanyData({ ...companyData, codigo_empresa: event })
              }
              onKeyPress={(e) => onKeyPress(e)}
              value={companyData.codext}
            />
          </FieldContainer>
          <FieldContainer title="E-mail">
            <Input
              type="email"
              placeholder="email@hotmail.com"
              onBlur={onBlurEventInputError}
              onChange={(event) =>
                setCompanyData({ ...companyData, email: event })
              }
              onKeyPress={(e) => onKeyPress(e)}
              value={companyData.email}
              typeInputMessage={inputErrorEmail ? "error" : false}
              error={
                onBlurInputErrorEmail
                  ? companyData.email && !/\S+@\S+\.\S+/.test(companyData.email)
                  : false
              }
              customInputMessage={"E-mail inválido"}
            />
          </FieldContainer>
        </div>

        <div className="container-full-line" style={{ marginTop: "10px" }}>
          <FieldContainer title="Inscrição estudal">
            <Input
              type="number"
              placeholder="XXX.XXX.XXX-XX"
              onChange={(event) =>
                setCompanyData({ ...companyData, inscricaoestadual: event })
              }
              onKeyPress={(e) => onKeyPress(e)}
              value={companyData.inscricaoestadual}
              maxLength="14"
            />
          </FieldContainer>
          <FieldContainer title="Incrição mucipal">
            <Input
              type="number"
              placeholder="XX.XXX.XXX-XX"
              onChange={(event) =>
                setCompanyData({ ...companyData, inscricaomunicipal: event })
              }
              onKeyPress={(e) => onKeyPress(e)}
              value={companyData.inscricaomunicipal}
              maxLength="14"
            />
          </FieldContainer>
          <FieldContainer title="Site">
            <Input
              type="text"
              placeholder="www.exemplo.com"
              onBlur={onBlurEventInputError}
              onChange={(event) =>
                setCompanyData({ ...companyData, site: event })
              }
              value={companyData.site}
              typeInputMessage={inputErrorSite ? "error" : false}
              error={
                onBlurInputErrorSite
                  ? companyData.site &&
                    validURL.test(companyData.site) === false
                  : false
              }
              customInputMessage={"Url inválida"}
            />
          </FieldContainer>
        </div>
      </section>
    </Modal.Main>
  );
}
