//GLOBAL - components from npm
import React, { useEffect, useState, useRef } from "react";
import { format, parseISO, isValid } from "date-fns";
import ptBR from "../../../../../locale/pt-BR";
import { useReactMediaRecorder } from "react-media-recorder";
import axios from "axios";

//UTILS
import { Toast } from "../../../../../utils/toast";
import formatTime from "../../../../../utils/formatTime";

//STYLES
import "./history-element.scss";

//COMPONENTS
import {
  ImageProfile,
  CarouselAttach,
  Channel,
} from "../../../../../components/data-display";
import { Typography } from "../../../../../components/general";
import { Audio, Input } from "../../../../../components/data-entry";
import { Tippy } from "./../../../../../components/feedback";

//SERVICES - api, conectors...
import * as APIFiles from "../../../../../utils/files";
import * as APIComments from "../../../../../services/api/comments";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as MessageText } from "../../../../../assets/icons/history-icons/HistoricMessageText.svg";
import { ReactComponent as Microphone } from "../../../../../assets/icons/history-icons/HistoricMicrophone2.svg";
import { ReactComponent as IconArrowDown } from "../../../../../assets/icons/ArrowDown.svg";
import { ReactComponent as IconMoreHorizontal } from "../../../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as Trash } from "../../../../../assets/icons/Trash-2.svg";
import { ReactComponent as Pause } from "../../../../../assets/icons/Pause-2.svg";
import { ReactComponent as Send } from "../../../../../assets/icons/Send-2.svg";
import { ReactComponent as Play } from "../../../../../assets/icons/PlayIcon.svg";
import { ReactComponent as Contact } from "../../../../../assets/icons/history-icons/HistoricContact.svg";
import { ReactComponent as AddMotive } from "../../../../../assets/icons/history-icons/HistoricAddMotive.svg";
import { ReactComponent as ChangeMotive } from "../../../../../assets/icons/history-icons/HistoricChangeMotive.svg";
import { ReactComponent as Status } from "../../../../../assets/icons/history-icons/HistoricStatus.svg";
import { ReactComponent as Solicitation } from "../../../../../assets/icons/history-icons/HistoricSolicitation.svg";
import { ReactComponent as Priority } from "../../../../../assets/icons/history-icons/HistoricPriority.svg";
import { ReactComponent as Deadline } from "../../../../../assets/icons/history-icons/HistoricDeadline.svg";
import { ReactComponent as Subject } from "../../../../../assets/icons/history-icons/HistoricSubject.svg";
import { ReactComponent as Description } from "../../../../../assets/icons/history-icons/HistoricDescription.svg";
import { ReactComponent as LinkedTask } from "../../../../../assets/icons/history-icons/HistoricLinkedTask.svg";
import { ReactComponent as TextComment } from "../../../../../assets/icons/history-icons/HistoricTextComment.svg";
import { ReactComponent as UnlinkedTask } from "../../../../../assets/icons/history-icons/HistoricUnlinkedTask.svg";
import { ReactComponent as Origin } from "../../../../../assets/icons/history-icons/HistoricOrigin.svg";
import { ReactComponent as AudioComment } from "../../../../../assets/icons/history-icons/HistoricAudioComment.svg";
import { ReactComponent as Attach } from "../../../../../assets/icons/history-icons/HistoricAttach.svg";
import { ReactComponent as InfoCircle } from "../../../../../assets/icons/history-icons/HistoricInfoCircle.svg";
import { ReactComponent as Business } from "../../../../../assets/icons/history-icons/HistoricBusiness.svg";
import { ReactComponent as Organization } from "../../../../../assets/icons/history-icons/HistoricOrg.svg";
import { ReactComponent as ChangeSubMotive } from "../../../../../assets/icons/history-icons/HistoricChangeSubM.svg";
import { ReactComponent as Escalonate } from "../../../../../assets/icons/history-icons/HistoricEscalonate.svg";
import { ReactComponent as Proprietary } from "../../../../../assets/icons/history-icons/HistoricProprietary.svg";
import { ReactComponent as CustomFields } from "../../../../../assets/icons/history-icons/HistoricCustomFields.svg";

export default function HistoryElement({ content }) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);
  const commentAnswer = [
    // {
    //   nome: "Iuri Pereira",
    //   horario: "há 3 horas",
    //   comentario:
    //     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using content.",
    // },
    // {
    //   nome: "Iuri Pereira",
    //   horario: "há 3 horas",
    //   comentario:
    //     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using content.",
    // },
    // {
    //   nome: "Iuri Pereira",
    //   horario: "há 3 horas",
    //   comentario:
    //     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using content.",
    // },
  ];
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({ audio: true });
  //STATES
  const [openHistoric, setOpenHistoric] = useState(false);
  const [openAnswers, setOpenAnswers] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentFile, setCommentFile] = useState([]);
  const [openInputAudio, setOpenInputAudio] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [arrowState, setArrowState] = useState(true);
  const audioTime = useRef(0);
  const [timeAudio, setTimeAudio] = useState("00:00");

  //REDUX - Selectors

  //FUNCTIONS
  const extractFieldDescription = (fieldDescription) => {
    return fieldDescription.replace(/¿\d+$/, '');
  };

  const extractAdditionalFieldTypeId = (fieldDescription) => {
    const match = fieldDescription.match(/¿(\d+)$/);
    return match ? parseInt(match[1], 10) : null;
  };

  const fieldDescription = extractFieldDescription(content.descricao_campo);
  const additionalFieldTypeId = extractAdditionalFieldTypeId(content.descricao_campo);

  const formatContent = (content) => {
    if (content.includes('T') && content.includes('Z')) {
      const datePart = content.split('T')[0];

      const date = parseISO(datePart);

      if (isValid(date)) {
        return format(date, 'dd/MM/yyyy');
      } else {
        return content;
      }
    } else if (additionalFieldTypeId === 9) {
      const value = content === "null" ? "Não" : content === "true" && "Sim";

      return value
    } else if (additionalFieldTypeId === 6) {
      const formattedTime = `${content.slice(0, 2)}:${content.slice(2)}`;

      return formattedTime;
    } else if (additionalFieldTypeId === 8) {
      return `R$ ${content}`
    } else {
      return content;
    }
  };

  //Função para registrar os comentários e audios como resposta, ainda falta a parte para exibir
  const postComment = async (text, files, responseComment, audio) => {
    try {
      let idType = 0;

      if (text) {
        idType = 1;
      } else if (audio) {
        idType = 3;
      } else {
        idType = 2;
      }

      const data = {
        desc_ticket_comentario: text || "",
        id_ticket: content.id_ticket,
        id_mimetype: 2,
        id_tipo_comentario: idType,
        id_ticket_comentario_parente: responseComment?.id_comentario || null,
      };

      const form = new FormData();
      form.append("data", JSON.stringify(data));

      for (const file of files) {
        form.append("data", file.file);
      }

      const response = await APIComments.createCommentCase(form, token);

      if (response.status === 200) {
        if (text) {
          setOpenInputAudio("");
          setInputValue("");
        } else {
          setOpenInputAudio("");
          setTimeAudio("00:00");
          audioTime.current = 0;
          clearBlobUrl();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getLocalStream() {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        window.localStream = stream; // A
        window.localAudio.srcObject = stream; // B
        window.localAudio.autoplay = true; // C
      })
      .catch((err) => {
        console.log("u got an error:" + err);
      });
  }

  const checkMicrophone = () => {
    navigator.permissions
      .query(
        // { name: 'camera' }
        { name: "microphone" }
        // { name: 'geolocation' }
        // { name: 'notifications' }
        // { name: 'midi', sysex: false }
        // { name: 'midi', sysex: true }
        // { name: 'push', userVisibleOnly: true }
        // { name: 'push' } // without userVisibleOnly isn't supported in chrome M45, yet
      )
      .then(function (permissionStatus) {
        if (permissionStatus.state !== "granted") {
          Toast(
            "Microfone Desativado!",
            "Por favor, ative a permissão do microfone.",
            "warn"
          );
          getLocalStream();
        } else {
          startRecording();
          setOpenInputAudio("audio");
        }
      });
  };

  //Função que para a gravação de audio ou transforma em arquivo para enviar para postagem
  const handleAudio = async (audioType) => {
    stopRecording();

    if (audioType === "send" && status === "stopped") {
      try {
        const response = await axios({
          url: mediaBlobUrl,
          method: "GET",
          responseType: "blob",
        });

        const arrayFile = [];
        const file = new File([response.data], "audio.wav", {
          type: response.data.type,
        });
        arrayFile.push({ file: file });

        await postComment(null, arrayFile, true, true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenInputAudio("");
      setTimeAudio("00:00");
      audioTime.current = 0;
      clearBlobUrl();
    }
  };

  const renderIconHistory = (iconHistory, iconChange) => {
    switch (iconHistory) {
      case 1:
        return <Contact />;

      case 2:
        return <Status />;

      case 3:
        return <Solicitation />;

      case 4:
        return <Priority />;

      case 5:
        return <Deadline />;

      case 6:
        return <Subject />;

      case 7:
        return <Description />;

      case 8:
        return <LinkedTask />;

      case 9:
        return <TextComment />;

      case 15:
        return iconChange !== "null" ? <ChangeMotive /> : <AddMotive />;

      case 16:
        return <UnlinkedTask />;

      case 17:
        return <Origin />;

      case 18:
        return <AudioComment />;

      case 19:
        return <Attach />;

      case 20:
        return <Escalonate />;

      case 21:
        return <InfoCircle />;

      case 22:
        return <ChangeSubMotive />;

      case 23:
        return <Organization />;

      case 24:
        return <Business />;

      case 25:
        return <Proprietary />;

      case 26:
        return <CustomFields />

      default:
        break;
    }
  };

  const renderSide = () => {
    return (
      <div className="history-element__side">
        <div className="history-element__side__icon">
          {renderIconHistory(content.id_campo, content.valor_atual)}
          {/* <ImageProfile
            profile={content}
            iconHistory={content.id_campo}
            iconChange={content.valor_atual}
            className="history-element__side__icon"
          /> */}
        </div>
        <div className="history-element__side__separator"></div>
      </div>
    );
  };

  const renderHeader = (textBold, currentText, newText, arrow, icon) => {
    return (
      <div className="history-element__header">
        <div className="history-element-information">
          <div className="history-element-information__text">
            {/* design antigo */}
            {/* <Typography.Paragraph color="gray-180">
              {content.nome_usuario}&nbsp;
            </Typography.Paragraph>
            <Typography.Paragraph weight="bold" color="gray-180">
              {textBold}&nbsp;
            </Typography.Paragraph>
            <Tippy content={currentText}>
              <div>
            <Typography.Paragraph
              className="history-element-info"
                  color="gray-180"
            >
              {currentText}&nbsp;
            </Typography.Paragraph>
              </div>
            </Tippy>
            {newText && (
              <Tippy content={newText}>
                <div>
                  <Typography.Paragraph
                    className="history-element-info"
                    color="gray-200"
                  >
                    {newText}.
                  </Typography.Paragraph>
                </div>
              </Tippy>
          )} */}
            <Typography.H5 color="gray-180">
              {content.nome_usuario} <b>{textBold}</b> {currentText}{" "}
              {newText && `${newText}.`}
            </Typography.H5>
            {arrow && (
              <div
                className={`history-element-date__button ${openHistoric ? "active" : ""
                  }`}
                onClick={() => setOpenHistoric(!openHistoric)}
              >
                <IconArrowDown />
              </div>
            )}
          </div>
          {icon && <div className="history-element-channel">{icon}</div>}
          {/* {content.canal_comunicacao && (
            <Channel media={content.canal_comunicacao} />
          )} */}

          <div className="history-element-date">
            <Typography.Paragraph color="gray-200" fontChanges="date-font">
              {format(new Date(content.dt_operacao), "dd/MM/yyyy 'às' HH:mm", {
                locale: ptBR,
              })}
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    );
  };

  //Função que vai renderizar o comentário com base no id_campo.
  const renderComment = () => {
    if (content.id_campo === 9) {
      return (
        <div className={`comment-history ${openHistoric && "active"}`}>
          <div className={`comment-history-content`}>
            <div className="comment-history-content__text">
              <Typography.H6
                color="gray-180"
                weight={400}
                className={"text-comment"}
              >
                {viewMore
                  ? commentText
                  : commentText.length > 250
                    ? `${commentText.slice(0, 250)}... `
                    : commentText}
                {commentText.length > 250 && (
                  <button
                    className="comment-history-content-buttons__button"
                    onClick={() => setViewMore(!viewMore)}
                  >
                    <Typography.H6 color="primary-dark">
                      {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                    </Typography.H6>
                  </button>
                )}
              </Typography.H6>
            </div>
            {commentAnswer.length > 0 && (
              <div className="comment-history-content-answers">
                <div className="comment-history-content-answers__header">
                  <div
                    className={`history-element-date__button ${openAnswers ? "active" : ""
                      }`}
                    onClick={() => setOpenAnswers(!openAnswers)}
                  >
                    <IconArrowDown />
                  </div>
                  <Typography.H6 className={"answers__header-title"}>
                    {commentAnswer.length} respostas
                  </Typography.H6>
                </div>
                <div
                  className={`comment-history-content-answers__container ${openAnswers ? "active" : ""
                    }`}
                >
                  {commentAnswer.map((answer, index) => (
                    <div className={`content-answers__container`} key={index}>
                      <div className="content-answers__container_header">
                        <div className="content-answers__container_header-iconTitle">
                          <ImageProfile
                            iconHistory={"user"}
                            className="content-answers__container_header-icon"
                          />

                          <div className="content-answers__container_header-title">
                            <Typography.H4 weight={"bold"} color="gray-180">
                              {answer.nome}
                            </Typography.H4>
                            <Typography.H6 className={"title_horario"}>
                              {answer.horario}
                            </Typography.H6>
                          </div>
                        </div>
                        <IconMoreHorizontal className="content-answers__container_header-iconMore" />
                      </div>
                      <Typography.H5 weight={400} color="gray-180">
                        {answer.comentario}
                      </Typography.H5>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {commentFile.length > 0 && (
              <div className="comment-history-content-buttons">
                <div className="comment-history-content-buttons-attachment-list">
                  <CarouselAttach
                    files={commentFile}
                    type="comment"
                    limit={3}
                    className="comment-history-carousel"
                    download
                  />
                </div>
              </div>
            )}
            {/* {renderChangeComment()} */}
          </div>
        </div>
      );
    } else {
      if (content.id_campo === 18) {
        return (
          <div className={`comment-history ${openHistoric && "active"}`}>
            <div
              className={`comment-history-content__audio ${content.nome_componente === "comunicado"
                ? "comment-history-content--gray"
                : ""
                }`}
            >
              <Audio link={commentText} />
            </div>

            {/* {renderChangeComment()} */}
          </div>
        );
      } else {
        return (
          <div className={`comment-history ${openHistoric && "active"}`}>
            <div className={`comment-history-content`}>
              <div className="comment-history-content__files">
                <CarouselAttach
                  files={commentFile}
                  type="comment"
                  limit={3}
                  className="comment-carousel"
                  download
                />
              </div>
            </div>

            {/* {renderChangeComment()} */}
          </div>
        );
      }
    }
  };

  const renderChangeComment = () => {
    if (!openInputAudio) {
      return (
        <div className="comment-history-content_editButtons">
          <button
            type="button"
            className="comment-history-content_editButtons_comment"
            onClick={() => setOpenInputAudio("input")}
          >
            <MessageText />
            <Typography.Paragraph
              color={"blue-selected-4"}
              weight={500}
              className="editButtonsTypography"
            >
              Comentar
            </Typography.Paragraph>
          </button>
          <button
            type="button"
            className="comment-history-content_editButtons_audio"
            onClick={() => checkMicrophone()}
          >
            <Microphone />
            <Typography.Paragraph
              color={"blue-selected-4"}
              weight={500}
              className="editButtonsTypography"
            >
              Gravar áudio
            </Typography.Paragraph>
          </button>
        </div>
      );
    } else if (openInputAudio === "input") {
      return (
        <div className="comment-history-content_inputComment">
          <Input
            type="text"
            placeholder={"Comentar..."}
            inputClassName="inputComment-principal"
            rightIcon="@"
            rightIconClassname="rightIcon-text"
            value={inputValue}
            onChange={setInputValue}
          />
          <div className="buttons-action">
            <button
              onClick={() => {
                setOpenInputAudio("");
                setInputValue("");
              }}
              className={"buttons-action__cancelButton"}
            >
              <Typography.Paragraph>Cancelar</Typography.Paragraph>
            </button>
            <button
              className={"buttons-action__sendButton"}
              disabled={inputValue ? false : true}
              onClick={() => postComment(inputValue, commentFile, true)}
            >
              <Typography.Paragraph>Enviar</Typography.Paragraph>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="comment-history-content_audioComment">
            <button
              onClick={() => handleAudio("quit")}
              className="buttons-action__cancelButton"
            >
              <Trash />
            </button>
            <div className="history-content_audioComment">
              <div className="history-content_audioComment__recordBar"></div>
              <Typography.H6
                className={"history-content_audioComment__timeProgress"}
                weight="600"
              >
                {timeAudio}
              </Typography.H6>
            </div>
            {status !== "paused" && (
              <button
                onClick={() => {
                  pauseRecording();
                }}
                className="buttons-action__cancelButton"
              >
                <Pause />
              </button>
            )}
            {status === "paused" && (
              <button
                onClick={() => {
                  resumeRecording();
                }}
                className="buttons-action__cancelButton"
              >
                <Play />
              </button>
            )}
            <button
              onClick={() => {
                handleAudio("send");
              }}
              className="buttons-action__cancelButton"
            >
              <Send />
            </button>
          </div>
        </>
      );
    }
  };

  // const typeComment = () => {
  //   if (content.tipo_solicitacao === "delete") {
  //     return "deletou um comentário.";
  //   } else if (content.tipo_solicitacao === "update") {
  //     if (content.informacao_elemento.id_tipo_comentario === 1) {
  //       return "editou um comentário de texto.";
  //     } else if (content.informacao_elemento.id_tipo_comentario === 2) {
  //       return "editou um comentário de anexo.";
  //     } else {
  //       return "editou um comentário de áudio.";
  //     }
  //   } else {
  //     if (content.informacao_elemento.id_tipo_comentario === 1) {
  //       return "adicionou um comentário de texto.";
  //     } else if (content.informacao_elemento.id_tipo_comentario === 2) {
  //       return "adicionou um comentário de anexo.";
  //     } else {
  //       return "adicionou um comentário de áudio.";
  //     }
  //   }
  // };

  //   const checkComponentLabel = (label) => {
  //     if (
  //       label === "comunicado" ||
  //       label === "comentário" ||
  //       label === "status"
  //     ) {
  //       return "o";
  //     } else {
  //       return "a";
  //     }
  //   };

  //   const renderComment = () => {
  //     if (content.nome_componente === "comentário") {
  //       return;
  //     } else if (content.nome_componente === "comunicado") {
  //     }
  //   };

  //Função que recebe a url do arquivo e busca as informações dele, como 'nome', 'size', 'mimetype'.
  const fetchFile = async (file) => {
    const response = await APIFiles.handleFiles(file);
    const fileObject = {
      file: {
        name: response.name,
        size: response.size,
        type: response.typeMime,
        path_arquivos: file,
        extension: response.type,
      },
    };

    return fileObject;
  };

  //Função que com base no tipo de comentário verifica o campo "valor_novo" e transforma em array para poder retirar dele a mensagem de texto e os links dos anexos.
  // Com o link dos anexos chama a função fetchFile para pegar as informações do anexo.
  const handleComments = async () => {
    if (content.id_campo === 9) {
      const arrayValue = content.valor_novo.split("|");
      setCommentText(arrayValue[0]);
      const arrayStringFile = arrayValue.filter((item) =>
        item.includes("database/")
      );
      const arrayFile = [];
      for (const item of arrayStringFile) {
        const objectFile = await fetchFile(item);
        arrayFile.push(objectFile);
      }
      setCommentFile(arrayFile);
    } else if (content.id_campo === 18) {
      const arrayValue = content.valor_novo.split("|");
      const arrayStringFile = arrayValue.filter((item) =>
        item.includes("database/")
      );
      setCommentText(arrayStringFile[0]);
    } else if (content.id_campo === 19) {
      const arrayValue = content.valor_novo.split("|");
      const arrayStringFile = arrayValue.filter((item) =>
        item.includes("database/")
      );
      const arrayFile = [];
      for (const item of arrayStringFile) {
        const objectFile = await fetchFile(item);
        arrayFile.push(objectFile);
      }
      setCommentFile(arrayFile);
    }
  };

  //USE EFFECTS
  useEffect(() => {
    handleComments();
  }, [content]);

  useEffect(() => {
    if (content.id_campo === 9) {
      if (commentAnswer.length > 0) {
        setArrowState(false);
        setOpenHistoric(true);
      } else {
        setArrowState(true);
      }
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      audioTime.current = audioTime.current + 1;
      setTimeAudio(formatTime(audioTime.current));
    }, 1000);

    if (openInputAudio !== "audio" || status === "paused") {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [openInputAudio, status]);

  //Switch que vai renderizar o elemento do histórico com base no id_campo dele.
  switch (content.id_campo) {
    case 1:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "alterou o contato",
              `de ${content.valor_atual} para`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 2:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "alterou o status",
              `de ${content.valor_atual.toLowerCase()} para`,
              `${content.valor_novo.toLowerCase()}`
            )}
          </div>
        </div>
      );
    case 3:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "alterou o tipo de solicitação",
              `de ${content.valor_atual} para`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 4:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "alterou a prioridade",
              `de ${content.valor_atual.toLowerCase()} para`,
              `${content.valor_novo.toLowerCase()}`
            )}
          </div>
        </div>
      );
    case 5:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "alterou o prazo",
              `de ${format(
                new Date(content.valor_atual),
                "dd/MM/yyyy 'às' H:mm"
              )} para`,
              `${format(new Date(content.valor_novo), "dd/MM/yyyy 'às' H:mm")}`
            )}
          </div>
        </div>
      );
    case 6:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader("alterou o assunto", ``, null, true)}
            <div
              className={`description-history-container ${openHistoric ? "active" : ""
                }`}
            >
              <div className="description-history-content">
                <Typography.H5 color="gray-200">De:</Typography.H5>
                <div className={`description-history`}>
                  <Typography.H5 color="gray-400">
                    {viewMore
                      ? content.valor_atual
                      : content.valor_atual.length > 250
                        ? `${content.valor_atual.slice(0, 250)}... `
                        : content.valor_atual}
                    {content.valor_atual.length > 250 && (
                      <button
                        className="comment-history-content-buttons__button"
                        onClick={() => setViewMore(!viewMore)}
                      >
                        <Typography.H5 color="primary-dark">
                          {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                        </Typography.H5>
                      </button>
                    )}
                  </Typography.H5>
                </div>
              </div>
              <div className="description-history-content">
                <Typography.H5 color="gray-200">Para:</Typography.H5>
                <div className={`description-history`}>
                  <Typography.H5 color="gray-400">
                    {viewMore
                      ? content.valor_novo
                      : content.valor_novo.length > 250
                        ? `${content.valor_novo.slice(0, 250)}... `
                        : content.valor_novo}
                    {content.valor_novo.length > 250 && (
                      <button
                        className="comment-history-content-buttons__button"
                        onClick={() => setViewMore(!viewMore)}
                      >
                        <Typography.H6 color="primary-dark">
                          {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                        </Typography.H6>
                      </button>
                    )}
                  </Typography.H5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 7:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader("alterou a descrição", ``, null, true)}
            <div
              className={`description-history-container ${openHistoric ? "active" : ""
                }`}
            >
              <div className="description-history-content">
                <Typography.H5 color="gray-200">De:</Typography.H5>
                <div className={`description-history`}>
                  <Typography.H5 color="gray-400">
                    {viewMore
                      ? content.valor_atual
                      : content.valor_atual.length > 250
                        ? `${content.valor_atual.slice(0, 250)}... `
                        : content.valor_atual}
                    {content.valor_atual.length > 250 && (
                      <button
                        className="comment-history-content-buttons__button"
                        onClick={() => setViewMore(!viewMore)}
                      >
                        <Typography.H6 color="primary-dark">
                          {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                        </Typography.H6>
                      </button>
                    )}
                  </Typography.H5>
                </div>
              </div>
              <div className="description-history-content">
                <Typography.H5 color="gray-200">Para:</Typography.H5>
                <div className={`description-history`}>
                  <Typography.H5 color="gray-400">
                    {viewMore
                      ? content.valor_novo
                      : content.valor_novo.length > 250
                        ? `${content.valor_novo.slice(0, 250)}... `
                        : content.valor_novo}
                    {content.valor_novo.length > 250 && (
                      <button
                        className="comment-history-content-buttons__button"
                        onClick={() => setViewMore(!viewMore)}
                      >
                        <Typography.H6 color="primary-dark">
                          {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                        </Typography.H6>
                      </button>
                    )}
                  </Typography.H5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 8:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "vinculou este caso a tarefa:",
              `${content.valor_novo}.`
            )}
          </div>
        </div>
      );
    case 9:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `adicionou um comentário de texto`,
              `ao caso`,
              null,
              arrowState
            )}
            {renderComment()}
          </div>
        </div>
      );
    case 15:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `${content.valor_atual !== "null"
                ? "alterou o motivo"
                : "inseriu o motivo"
              }`,
              `${content.valor_atual !== "null"
                ? `de ${content.valor_atual} para`
                : "de"
              }`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 16:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "desvinculou este caso a tarefa:",
              `${content.valor_novo}.`
            )}
          </div>
        </div>
      );
    case 17:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "alterou o canal de origem",
              `de ${content.valor_atual} para`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 18:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `gravou um comentário em áudio`,
              `ao caso`,
              null,
              true
            )}
            {renderComment()}
          </div>
        </div>
      );
    case 19:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `adicionou um comentário de anexo`,
              `ao caso`,
              null,
              true
            )}
            {renderComment()}
          </div>
        </div>
      );
    case 20:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `${content.valor_novo === "null" || content.valor_novo === null
                ? "removeu o setor escalado"
                : content.valor_atual !== "null" &&
                  content.valor_atual !== null
                  ? "alterou o setor escalado"
                  : "inseriu o setor escalado"
              }`,
              `${content.valor_novo === "null" || content.valor_novo === null
                ? ""
                : content.valor_atual !== "null" &&
                  content.valor_atual !== null
                  ? `de ${content.valor_atual} para`
                  : "de"
              }`,
              `${content.valor_novo === "null" || content.valor_novo === null
                ? content.valor_atual
                : content.valor_novo
              }`
            )}
          </div>
        </div>
      );
    case 21:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(`criou este caso`, null)}
            {renderComment()}
          </div>
        </div>
      );
    case 22:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `${content.valor_atual !== "null"
                ? "alterou o sub-motivo"
                : "inseriu o sub-motivo"
              }`,
              `${content.valor_atual !== "null"
                ? `de ${content.valor_atual} para`
                : "de"
              }`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 23:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `${content.valor_atual !== "null"
                ? "alterou a organização"
                : "inseriu a organização"
              }`,
              `${content.valor_atual !== "null"
                ? `de ${content.valor_atual} para`
                : "de"
              }`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 24:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `${content.valor_atual !== "null"
                ? "alterou a empresa"
                : "inseriu a empresa"
              }`,
              `${content.valor_atual !== "null"
                ? `de ${content.valor_atual} para`
                : "de"
              }`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 25:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              `${content.valor_atual !== "null"
                ? "alterou o proprietário"
                : "inseriu o proprietário"
              }`,
              `${content.valor_atual !== "null"
                ? `de ${content.valor_atual} para`
                : "como"
              }`,
              `${content.valor_novo}`
            )}
          </div>
        </div>
      );
    case 26:
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(`${content.valor_atual === null || content.valor_atual === "" ? "Inseriu" : "alterou"} ${fieldDescription}`, "", null, true)}

            <div className={`description-history-container ${openHistoric ? "active" : ""}`}>
              <div className="description-history-content">
                <Typography.H5 color="gray-200">De:</Typography.H5>

                <div className={`description-history`}>
                  <Typography.H5 color="gray-400">
                    {viewMore
                      ? formatContent(content.valor_atual)
                      : formatContent(content.valor_atual).length > 250
                        ? `${formatContent(content.valor_atual).slice(0, 250)}... `
                        : formatContent(content.valor_atual)}
                    {formatContent(content.valor_atual).length > 250 && (
                      <button
                        className="comment-history-content-buttons__button"
                        onClick={() => setViewMore(!viewMore)}
                      >
                        <Typography.H5 color="primary-dark">
                          {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                        </Typography.H5>
                      </button>
                    )}
                  </Typography.H5>
                </div>

                <div className="description-history-content">
                  <Typography.H5 color="gray-200">Para:</Typography.H5>
                  <div className={`description-history`}>
                    <Typography.H5 color="gray-400">
                      {viewMore
                        ? formatContent(content.valor_novo)
                        : formatContent(content.valor_novo).length > 250
                          ? `${formatContent(content.valor_novo).slice(0, 250)}... `
                          : formatContent(content.valor_novo)}
                      {formatContent(content.valor_novo).length > 250 && (
                        <button
                          className="comment-history-content-buttons__button"
                          onClick={() => setViewMore(!viewMore)}
                        >
                          <Typography.H6 color="primary-dark">
                            {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                          </Typography.H6>
                        </button>
                      )}
                    </Typography.H5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case "comunicado":
      return (
        <div className="history-element">
          {renderSide()}
          <div className="history-element__comments">
            {renderHeader(
              "adicionou um comunicado",
              `para o cliente via:`,
              null,
              true,
              <Channel media={content.canal_comunicacao} />
            )}
            {renderComment()}
          </div>
        </div>
      );
    default:
      return <></>;
  }

  // switch (content.nome_componente) {
  //   case "tarefa vinculada":
  //     return (
  //       <div className="history-element">
  //         {renderHeader("vinculou a tarefa:", `${content.nome_elemento}.`)}
  //       </div>
  //     );
  //   case "tarefa desvinculada":
  //     return (
  //       <div className="history-element">
  //         {renderHeader("desvinculou a tarefa:", `${content.nome_elemento}.`)}
  //       </div>
  //     );
  //   case "status":
  //     return (
  //       <div className="history-element">
  //         {renderHeader("alterou o status", `para ${content.nome_elemento}.`)}
  //       </div>
  //     );
  //   case "contato":
  //     return (
  //       <div className="history-element">
  //         {renderHeader("alterou o contato", `para ${content.nome_elemento}.`)}
  //       </div>
  //     );
  //   case "solicitação":
  //     return (
  //       <div className="history-element">
  //         {renderHeader(
  //           "alterou a solicitação",
  //           `para ${content.nome_elemento}.`
  //         )}
  //       </div>
  //     );
  //   case "prioridade":
  //     return (
  //       <div className="history-element">
  //         {renderHeader(
  //           "alterou a prioridade",
  //           `para ${content.nome_elemento}.`
  //         )}
  //       </div>
  //     );
  //   case "prazo":
  //     return (
  //       <div className="history-element">
  //         {renderHeader(
  //           "alterou o prazo",
  //           `para ${format(
  //             new Date(content.nome_elemento),
  //             "dd/MM/yyyy 'às' H:mm"
  //           )}.`
  //         )}
  //       </div>
  //     );
  //   case "assunto":
  //     return (
  //       <div className="history-element">
  //         {renderHeader("alterou o assunto", `do Caso.`)}
  //       </div>
  //     );
  //   case "descrição":
  //     return (
  //       <div className="history-element">
  //         {renderHeader("alterou a descrição", `do Caso.`)}
  //       </div>
  //     );
  //   case "comentário":
  //     return (
  //       <div className="history-element">
  //         {renderHeader(
  //           typeComment(),
  //           ``,
  //           content.tipo_solicitacao !== "delete" ? true : false
  //         )}
  //         {content.tipo_solicitacao !== "delete" ? renderComment() : ""}
  //       </div>
  //     );
  //   case "comunicado":
  //     return (
  //       <div className="history-element">
  //         {renderHeader(
  //           "adicionou um comunicado",
  //           `para o cliente via:`,
  //           true,
  //           <Channel media={content.canal_comunicacao} />
  //         )}
  //         {renderComment()}
  //       </div>
  //     );
  //   default:
  //     return <></>;
  // }

  //   if (content.tipo_solicitacao === "post") {
  //     return (
  //       <div className="history-element">
  //         <div className="history-element__header">
  //           <div className="history-element-information">
  //             <ImageProfile
  //               profile={content}
  //               className="history-element-information__image"
  //             />
  //             <Typography.Paragraph color="gray-200">
  //               {content.nome_usuario}&nbsp;
  //               <Typography.Paragraph weight="bold" color="gray-200">
  //                 adicionou {checkComponentLabel(content.nome_componente)}{" "}
  //                 {content.nome_componente} &nbsp;
  //               </Typography.Paragraph>
  //               {content.canal_comunicacao ? "via:" : ":"}
  //             </Typography.Paragraph>
  //             {content.canal_comunicacao && (
  //               <Channel media={content.canal_comunicacao} />
  //             )}
  //           </div>
  //           <div className="history-element-date">
  //             <Typography.Paragraph color="gray-200">
  //               {format(
  //                 new Date(content.data),
  //                 "dd 'de' MMM'./'yyyy 'às' HH:mm",
  //                 { locale: ptBR }
  //               )}
  //             </Typography.Paragraph>
  //             {content.nome_componente === "comentário" ||
  //               content.nome_componente === "comunicado" ? (
  //                 <div
  //                   className={`history-element-date__button ${
  //                     openHistoric ? "active" : ""
  //                   }`}
  //                   onClick={() => setOpenHistoric(!openHistoric)}
  //                 >
  //                   <IconArrowDown />
  //                 </div>
  //               )
  //             :
  //             <></>}
  //           </div>
  //         </div>
  //         {content.nome_componente === "comentário" || content.nome_componente === "comunicado"
  //         ?
  //         <>
  //         {renderComment()}
  //         </>
  //         :
  //         <></>
  //         }
  //       </div>
  //     );
  //   } else if (content.tipo_solicitacao === "update") {
  //     return (
  //       <div className="history-element">
  //         <div className="history-element__header">
  //           <div className="history-element-information">
  //             <ImageProfile
  //               profile={content}
  //               className="history-element-information__image"
  //             />
  //             <Typography.Paragraph color="gray-200">
  //               {content.nome_usuario}&nbsp;
  //               <Typography.Paragraph weight="bold" color="gray-200">
  //                 alterou {checkComponentLabel(content.nome_componente)}{" "}
  //                 {content.nome_componente} &nbsp;
  //               </Typography.Paragraph>
  //               {content.canal_comunicacao ? "via:" : "para:"}
  //             </Typography.Paragraph>
  //             {content.canal_comunicacao && (
  //               <Channel media={content.canal_comunicacao} />
  //             )}
  //           </div>
  //           <div className="history-element-date">
  //             <Typography.Paragraph color="gray-200">
  //               {format(
  //                 new Date(content.data),
  //                 "dd 'de' MMM'./'yyyy 'às' HH:mm",
  //                 { locale: ptBR }
  //               )}
  //             </Typography.Paragraph>
  //             {content.nome_componente === "comentário" ||
  //               content.nome_componente === "comunicado" ? (
  //                 <div
  //                   className={`history-element-date__button ${
  //                     openHistoric ? "active" : ""
  //                   }`}
  //                   onClick={() => setOpenHistoric(!openHistoric)}
  //                 >
  //                   <IconArrowDown />
  //                 </div>
  //               )
  //             :
  //             <></>}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (content.tipo_solicitacao === "delete") {
  //     return (
  //       <div className="history-element">
  //         <div className="history-element__header">
  //           <div className="history-element-information">
  //             <ImageProfile
  //               profile={content}
  //               className="history-element-information__image"
  //             />
  //             <Typography.Paragraph color="gray-200">
  //               {content.nome_usuario}&nbsp;
  //               <Typography.Paragraph weight="bold" color="gray-200">
  //                 deletou {checkComponentLabel(content.nome_componente)}{" "}
  //                 {content.nome_componente} &nbsp;
  //               </Typography.Paragraph>
  //               {content.canal_comunicacao ? "via:" : "para:"}
  //             </Typography.Paragraph>
  //             {content.canal_comunicacao && (
  //               <Channel media={content.canal_comunicacao} />
  //             )}
  //           </div>
  //           <div className="history-element-date">
  //             <Typography.Paragraph color="gray-200">
  //               {format(
  //                 new Date(content.data),
  //                 "dd 'de' MMM'./'yyyy 'às' HH:mm",
  //                 { locale: ptBR }
  //               )}
  //             </Typography.Paragraph>
  //             {content.nome_componente === "comentário" ||
  //               content.nome_componente === "comunicado" ? (
  //                 <div
  //                   className={`history-element-date__button ${
  //                     openHistoric ? "active" : ""
  //                   }`}
  //                   onClick={() => setOpenHistoric(!openHistoric)}
  //                 >
  //                   <IconArrowDown />
  //                 </div>
  //               )
  //             :
  //             <></>}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <></>;
  //   }
}
