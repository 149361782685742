//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

// UTILS

//STYLES
import "./edit-variable-communication.scss";

//COMPONENTS
import { Modal } from "../..";
import { Button, Typography } from "../../../general";
import { FieldContainer } from "../../../data-display";
import { Input } from "../../../data-entry";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function SendCommunicatedMaximize({
  isOpen,
  onSend,
  onClose,
  valueQuill,
  setValueQuill,
  isSending,
  infosCase,
}) {
  //REDUX - Selectors

  //GENERAL

  //STATES
  const [valueEdit, setValueEdit] = useState("");
  const [greetingValue, setGreetingValue] = useState(verifierGreeting());
  const [contactValue, setContactValue] = useState(infosCase.nome_contato);
  const [caseValue, setCaseValue] = useState(infosCase.codigo_ticket);

  //FUNCTIONS
  const verifierVariable = (message) => {
    message = message.replace(
      /\${SYS.NOMECLIENTE}/gi,
      `<span>${" "}${contactValue}</span> `
    );
    message = message.replace(
      /\${SYS.NUMEROCASO}/gi,
      `<span>${caseValue}</span>`
    );
    message = message.replace(
      /\${SYS.SAUDACAO}/gi,
      `<span>${greetingValue}</span>`
    );
    return message;
  };

  function verifierGreeting() {
    let complete = new Date();
    let hours = complete.getHours();
    let minutes = complete.getMinutes();
    let seconds = complete.getSeconds();
    if (hours < 12 && minutes < 60 && seconds < 60) return "Bom dia";
    else if (hours < 18 && minutes < 60 && seconds < 60) return "Boa tarde";
    else if (hours < 23 && minutes < 60 && seconds < 60) return "Boa noite";

    return "";
  }

  //Função que é ativada ao fechar o modal, resetando os valores dos componentes.
  function onCloseCustom() {
    onClose();
    setGreetingValue(verifierGreeting());
    setContactValue(infosCase.nome_contato);
    setCaseValue(infosCase.codigo_ticket);
  }

  useEffect(() => {
    if (isOpen) {
      const newValue = verifierVariable(valueQuill);
      setValueEdit(newValue);
      setValueQuill(newValue);
    }

    if (!isOpen) {
      setGreetingValue(verifierGreeting());
      setContactValue(infosCase.nome_contato);
      setCaseValue(infosCase.codigo_ticket);
    }
  }, [isOpen, contactValue, caseValue, greetingValue]);

  return (
    <Modal.Main
      className="modal-edit-variable"
      title="Editar variáveis da mensagem"
      isOpen={isOpen}
      onClose={onCloseCustom}
      width="70%"
      height="70%"
      footer={
        <>
          <Button
            className="modal-create-case__btn-cancel"
            onClick={onCloseCustom}
          >
            <Typography.H5 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H5>
          </Button>

          <Button
            className="btn-secondary"
            disabled={
              valueQuill !== "" && valueQuill !== "<p><br></p>"
                ? isSending === true
                  ? true
                  : false
                : true
            }
            onClick={onSend}
          >
            Enviar mensagem
          </Button>
        </>
      }
    >
      <div className="modal-edit-variable_main">
        <FieldContainer title="Preview da Mensagem">
          <div className="modal-edit-variable_main__message">
            {ReactHtmlParser(valueEdit)}
          </div>
        </FieldContainer>
        <FieldContainer
          title={"Alterar variáveis"}
          className={"container-variables-box"}
        >
          <div className="variables-box">
            <Typography.H4 color={"gray-180"}>{"${SAUDACAO}"}</Typography.H4>
            <Input value={greetingValue} onChange={setGreetingValue} />
          </div>
          <div className="variables-box">
            <Typography.H4 color={"gray-180"}>{"${NOMECLIENTE}"}</Typography.H4>
            <Input value={contactValue} onChange={setContactValue} />
          </div>
          <div className="variables-box">
            <Typography.H4 color={"gray-180"}>{"${NUMEROCASO}"}</Typography.H4>
            <Input value={caseValue} onChange={setCaseValue} />
          </div>
        </FieldContainer>
      </div>
    </Modal.Main>
  );
}
