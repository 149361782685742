//GLOBAL - components from npm
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, Autoplay } from "swiper";

// UTILS
import { Toast } from "../../utils/toast";

//STYLES
import "./register-solicitation-motive.scss";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/autoplay/autoplay.scss";

//COMPONENTS
import { Page } from "../../components/layout";
import { Logo, FieldContainer } from "../../components/data-display";
import { Input, Select } from "../../components/data-entry";
import { Typography, Button } from "../../components/general";

//SERVICES - api, conectors...
import * as APIAuth from "../../services/api/auth";

//GLOBAL STATE - redux, env...
import { useDispatch } from "react-redux";
import { doLogin } from "../../store/auth";

//ASSETS - icons, images...
import { ReactComponent as IconEye } from "../../assets/icons/eye.svg";
import { ReactComponent as IconPassword } from "../../assets/icons/password.svg";
import { ReactComponent as IconMail } from "../../assets/icons/mail.svg";
import { ReactComponent as IconArrowLeft } from "../../assets/icons/Arrow-left.svg";
import { ReactComponent as IconTimerStart } from "../../assets/icons/TimerStart.svg";
import { ReactComponent as SVGSlideshowImg2 } from "../../assets/images/slideshow-img2.svg";

export default function RegisterSolicitationMotive() {
  //GENERAL
  const dispatch = useDispatch();

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [loginData, setLoginData] = useState({
    login: "",
    password: "",
  });
  const [inputsError, setInputsError] = useState({
    login: false,
    password: false,
  });

  //REDUX - Selectors

  //FUNCTIONS
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!loginData.login) {
      Toast(
        "Preencha seu e-mail!",
        "Verifique seus dados e tente novamente",
        "error"
      );
    } else if (!loginData.password) {
      Toast(
        "Preencha sua senha!",
        "Verifique seus dados e tente novamente",
        "error"
      );
    } else {
      setPageStatus("requesting");

      const response = await APIAuth.login(loginData);

      if (response.status === 200) {
        dispatch(
          doLogin({
            token: response.data.token,
            userData: response.data.dados[0],
            localStorageKey: "token",
            localStorageValue: response.data.token,
          })
        );
      } else if (response.status === 500) {
        Toast(
          "Dados incorretos!",
          "Verifique seus dados e tente novamente",
          "error"
        );
        setPageStatus("awaiting");
      } else {
        Toast(
          "Falha, não foi possível fazer login!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );
        setPageStatus("awaiting");
      }
    }
  };

  //USE EFFECTS

  return (
    <div className="pages-register-typeSolicitation">
      <div className="pages-register-typeSolicitation__header">
        <IconArrowLeft />

        <div className="case-details-title">
          <Typography.H2 color="gray-180" weight="bold">
            Cadastrar Tipos de Solicitação e Motivos
          </Typography.H2>
        </div>
      </div>
      <Page.Split
        className="pages-register-typeSolicitation__main"
        sectionA={
          <>
            <section className="main__content">
              <FieldContainer
                title="Descrição"
                titleSize="small"
                required
                className="field-container-case-request-type "
                htmlFor={""}
              >
                <Input
                  placeholder="Selecione"
                  className={"modal-create-case__dropdown"}
                />
              </FieldContainer>
              <FieldContainer
                title="SLA"
                titleSize="small"
                required
                className="field-container-case-request-type "
                htmlFor={""}
              >
                <Select
                  placeholder="Selecione"
                  className={"modal-create-case__dropdown"}
                />
              </FieldContainer>
              <FieldContainer
                title="Editável"
                titleSize="small"
                required
                className="field-container-case-request-type "
                htmlFor={""}
              >
                <Select
                  placeholder="Selecione"
                  className={"modal-create-case__dropdown"}
                />
              </FieldContainer>
              <FieldContainer
                title="Motivos"
                titleSize="small"
                className="field-container-case-request-type "
                htmlFor={""}
              >
                <Select
                  placeholder="Selecione"
                  className={"modal-create-case__dropdown"}
                />
              </FieldContainer>
            </section>
            <div className="main__content-footer">
              <Button type="tertiary">Cancelar</Button>
              <Button>Salvar</Button>
            </div>
          </>
        }
        sectionB={
          <>
            <div className="typeSolicitation-list-container__empty-state">
              <div className="container-icon">
                <IconTimerStart />
              </div>
              <Typography.H4 color="black">
                Não há nenhum Tipo de solicitação ou Motivo cadastrado.
              </Typography.H4>
            </div>
          </>
        }
      />
    </div>
  );
}
