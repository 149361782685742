import React from "react";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import { Typography } from "../../general";
import { RadialBarChart } from "../../data-graphics/";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as FolderOpenIcon } from "../../../assets/icons/FolderOpen.svg";
import { ReactComponent as FileSearching } from "../../../assets/icons/File-searching.svg";
import { ReactComponent as TrendDownRed } from "../../../assets/icons/TrendDownRed.svg";

export default function CardPrimary({ card, chooseColors, darkMode, onClick, dynamicCard, cardPerRow }) {
  //GENERAL
  const colors = chooseColors(card.typeIndicator);

  const data = [
    {
      id: card.title,
      data: [
        {
          x: "Total",
          y: card.percentage === "100.0" ? 100 : card.percentage,
          color: colors && colors[0],
          colorTrack: colors && colors[1],
        },
      ],
    },
  ];

  //FUNCTIONS

  return (
    <div
      className={`card-dashboard ${card.isPrimary ? "icon-padding" : ""} ${dynamicCard ? "customize-card-dashboard" : ""}`}
      // style={{ flex: `1 1 calc(${100 / cardPerRow}% - 16px)` }}
    >
      <div className="card-dashboard__container">
        <div className="card-dashboard__container-title">
          <Typography.H6 color={darkMode ? "white" : "gray-180"}>
            {card.title}
          </Typography.H6>
        </div>
        <div className="card-dashboard__container-info">
          {card.emptySpace ? (
            <div className="card-dashboard-emptySpace">
              <FileSearching />
              <Typography.H6 color={darkMode ? "white" : "gray-180"}>
                Oops! A sua pesquisa não gerou resultados.
              </Typography.H6>
            </div>
          ) : (
            <Typography.H1
              weight={"bold"}
              color={
                !darkMode
                  ? card.isPrimary
                    ? "blue-selected-4"
                    : "gray-600"
                  : "white"
              }
            >
              {card.valueIndicator}
            </Typography.H1>
          )}
        </div>
        {card.isTrendUp && (
          <div className="card-dashboard__container-trend">
            {/* {card.trendUpValue <= 4 ? <TrendDownRed /> : <TrendUpGreen />}
            <Typography.H6
              className={
                card.trendUpValue <= 4
                  ? "card-dashboard__container-trend__text-secondary"
                  : "card-dashboard__container-trend__text-primary"
              }
            >
              +{card.trendUpValue}%
            </Typography.H6> */}
          </div>
        )}
      </div>
      {card.emptySpace ? (
        ""
      ) : card.percentage <= 0 ? (
        <Tippy
          theme="ingdesk-dark"
          placement={"top"}
          delay={[0, 0]}
          allowHTML
          interactive
          content={"Nenhum conteúdo encontrado."}
        >
          <div
            className={
              card.isPrimary
                ? "card-dashboard__view-separator"
                : "card-dashboard__view"
            }
            onClick={card.percentage > 0 ? onClick : null}
            onMouseMove={(e) =>
            (e.target.style.cursor =
              card.percentage !== "0" ? "pointer" : "default")
            }
          >
            {card.isPrimary ? (
              <FolderOpenIcon />
            ) : (
              <RadialBarChart data={data} darkMode={darkMode} />
            )}
          </div>
        </Tippy>
      ) : (
        <div
          className={
            card.isPrimary
              ? "card-dashboard__view-separator"
              : "card-dashboard__view"
          }
          onClick={card.percentage > 0 ? onClick : null}
          onMouseMove={(e) =>
          (e.target.style.cursor =
            card.percentage !== "0" ? "pointer" : "default")
          }
        >
          {card.isPrimary ? (
            <FolderOpenIcon />
          ) : (
            <RadialBarChart data={data} darkMode={darkMode} />
          )}
        </div>
      )}
    </div>
  );
}
