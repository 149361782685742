import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode";

export async function getTasks(titleData, datestart, dateend, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      params: {
        title: titleData,
        datestart,
        dateend,
      },
    };
    const response = await instance.get("/list-tasks-new", config);

    for (const item of response.data) {
      item.nome_contato = unicodeToChar(item.nome_contato);
      let user = item.dados_usuarios.split("¿");
      let reminder = item.dados_lembrete.split("¿");
      let tag = item.dados_tag.split("¿");
      let arrayUsers = [];
      let arrayTags = [];
      let arrayReminders = [];
      if (item.dados_usuarios !== "") {
        for (const todo of user) {
          let temp = todo.split("§");
          arrayUsers.push({
            id_tarefa: temp[0],
            id_usuario: temp[1],
            nome_usuario: temp[2],
            nome_organizacao: temp[3],
          });
        }
      }
      if (item.dados_lembrete !== "") {
        for (const todo of reminder) {
          let temp = todo.split("§");
          arrayReminders.push({
            concluido: temp[4],
            data_fim: temp[2],
            data_inicio: temp[3],
            id_tarefa: parseInt(temp[1]),
            id_tarefas_lembrete: parseInt(temp[0]),
          });
        }
      }
      if (item.dados_tag !== "") {
        for (const todo of tag) {
          let temp = todo.split("§");
          arrayTags.push({
            id_tag: temp[0],
            desc_tag: temp[1],
            id_tarefa: temp[2],
          });
        }
      }
      item.usersAssociated = arrayUsers;
      item.remindersAssociated = arrayReminders;
      item.tagsAssociated = arrayTags;
    }

    return response;
  } catch (error) {
    console.debug("getTasks error: ", error);
    return error;
  }
}

export async function createTask(data, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post("/create-task", data, config);

    return response;
  } catch (error) {
    return error.response;
  }
}

export async function details(id, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/details-task/${id}`, config);

    response.data.nome_contato = unicodeToChar(response.data.nome_contato);

    // console.log('RESPONSE:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function listStatus(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-queues`, config);

    // console.log('RESPONSE-STATUS:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function createStatus(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post("/create-queue", data, config);

    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteStatus(statusId, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.delete(
      `/delete-list-queues/${statusId}`,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function updateStatus(statusId, token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.put(
      `/update-list-queue/${statusId}`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateTaskPosition(body, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      `/update-tarefas-status`,
      body,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function taskUpdate(id, body, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.put(`/update-task/${id}`, body, config);

    // console.log('RESPONSE-UPDATE:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function taskUpdateMove(id, body, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.put(
      `/update-task-move/${id}`,
      body,
      config
    );

    // console.log('RESPONSE-UPDATE:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function listUsers(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/list-users`, config);

    // console.log('RESPONSE-USERS:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function associateUser(body, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.post(
      `/associate-user-to-task`,
      body,
      config
    );

    // console.log('RESPONSE-ASSOCIATE-USER:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function disassociateUser(idUser, idTask, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.delete(
      `/delete-user-associated-task/${idTask}/${idUser}`,
      config
    );

    // console.log('RESPONSE-DISASSOCIATE-USER:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteTask(idTask, idUser, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.delete(
      `/delete-task/${idTask}/${idUser}`,
      config
    );

    // console.log('RESPONSE-DELETE-TASK:', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function associateTaskToCase(data, token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    console.log(data);
    const response = await instance.post(
      `/create-associate-task-to-ticket`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
}
