import instance from "./configAxios";

export async function getListStaggered(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-escalonamento', {}, config);

        return response;
    } catch (error) {
        console.log('getListStaggered -> Error en el servidor:', error);

        return error
    }
};

export async function createStaggered(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = descricao

        const response = await instance.post('/insert-escalonamento', data, config);

        return response;
    } catch (error) {
        console.log('createStaggered -> Error en el servidor:', error);

        return error
    }
};

export async function updateStaggered(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = descricao, id_escalonamento

        const response = await instance.put('/update-escalonamento', data, config);

        return response;
    } catch (error) {
        console.log('updateStaggered -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteStaggered(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_escalonamento

        const response = await instance.put('/delete-escalonamento', data, config);

        return response;
    } catch (error) {
        console.log('deleteStaggered -> Error en el servidor:', error);

        return error;
    }
};