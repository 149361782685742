//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// UTILS

//COMPONENTS
import { Modal } from "./../../../feedback";
import { FieldContainer } from "../../../data-display";
import { Input, Select } from "../../../data-entry";
import { maskCep, maskCpfCnpj, maskRg } from "../../../../utils/masks";
import { formatPhone } from "../../../../utils/formatPhone";
import renderPhone from "../../../../utils/renderPhoneFormat";

//SERVICES - api, conectors...
import * as APICompany from "./../../../../services/api/company";
import * as APITypesPhones from "./../../../../services/api/typesPhones";

//ASSETS - icons, images...
import { ReactComponent as IconUser } from "./../../../../assets/images/user.svg";

//STYLES
import "./view-data-contact.scss";

export default function ViewDataContact({ isOpen, onClose, contactsData }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL
    const contactId = isOpen || null;

    //STATES
    const [value, setValue] = useState(null);
    const [companys, setCompanys] = useState();
    const [typeListPhone, setTypeListPhone] = useState();

    console.log("onValue", value)
    console.log("typeListPhone", typeListPhone)

    //FUNCTIONS
    function onCloseCustom() {
        onClose();
    };

    async function getListEnterprises() {
        const response = await APICompany.allEnterprises(token);
        const data = response.data;
        const updatedData = [];

        data.forEach((company) => {
            let updatedCompany = {};

            updatedCompany.id = company.id_empresa
            updatedCompany.label = company.nome

            updatedData.push(updatedCompany);
        });

        setCompanys(updatedData)
    };

    async function getListTypesPhones() {
        const response = await APITypesPhones.getTypesPhones(token);
        const data = response.data;
        const updatedData = [];

        data.forEach((phone) => {
            let updatedPhone = {
                id: phone.id_tipo_telefone,
                label: phone.desc_tipo_telefone
            };

            updatedData.push(updatedPhone);
        });

        setTypeListPhone(updatedData);
    };

    function getRegisteredData(idContact) {
        contactsData?.forEach((element) => {
            if (element.id_contato === idContact) {
                setValue({
                    ...element,
                    cpf: element.cpf && maskCpfCnpj(element.cpf),
                    rg: element.rg && maskRg(element?.rg),
                    cep: element.cep && maskCep(element.cep)
                });
            }
        })
    };

    //USE EFFECTS
    useEffect(() => {
        getListEnterprises();
        getListTypesPhones();
    }, []);

    useEffect(() => {
        if (contactId) {
            getRegisteredData(contactId)
        }
    }, [contactId]);

    useEffect(() => {
        const data = { ...value };

        data.nome_contato = document.getElementById("nome_apresentacao")?.value;

    }, [value?.nome_apresentacao]);

    return (
        <Modal.Main
            className="modal-viewDataContact"
            title="Contato"
            width="1001px"
            isOpen={isOpen}
            onClose={onCloseCustom}
        >
            <section className="modal-create-contact__content">
                <div className="container-full-line">
                    <div className="img">
                        <IconUser className="icon-user" />
                    </div>
                </div>

                <div className="container-full-line">
                    <FieldContainer required title="Nome completo">
                        <Input
                            disabled={true}
                            type="text"
                            id="nome_completo"
                            placeholder="Nome"
                            value={value?.nome_contato}
                        />
                    </FieldContainer>
                    <FieldContainer required title="Nome de apresentação">
                        <Input
                            disabled={true}
                            type="text"
                            id="nome_apresentacao"
                            placeholder="Nome apresentação"
                            value={value?.nome_contato}
                        />
                    </FieldContainer>
                    <FieldContainer title="Código externo">
                        <Input
                            disabled={true}
                            type="text"
                            placeholder="Código externo"
                            value={value?.codigo_externo}
                        />
                    </FieldContainer>
                </div>

                <div className="container-full-line">
                    <FieldContainer title="E-mail">
                        <Input
                            disabled={true}
                            type="email"
                            placeholder="email@hotmail.com"
                            value={value?.email}
                        />
                    </FieldContainer>
                    <FieldContainer title="CPF">
                        <Input
                            disabled={true}
                            type="text"
                            placeholder="XXX.XXX.XXX-XX"
                            maxLength="14"
                            value={value?.cpf}
                        />
                    </FieldContainer>
                    <FieldContainer title="RG">
                        <Input
                            disabled={true}
                            type="text"
                            placeholder="XX.XXX.XXX-XX"
                            maxLength="13"
                            value={value?.rg}
                        />
                    </FieldContainer>
                </div>

                <div className="divisor" style={{ marginTop: "10px" }}></div>

                <FieldContainer title="Contato">
                    {value?.telephones?.map((element, index) => {
                        let { phone, id_tipo_phone } = element;

                        return (
                            <div key={index} className="container-full-line-contact" style={{ marginTop: "10px" }}>
                                <Input
                                    disabled
                                    width="300px"
                                    className="input-wrapper__input"
                                    placeholder="XX 9 1234-5678"
                                    value={phone?.length > 14 ? phone : renderPhone(phone)}
                                />

                                <Select
                                    disabled={true}
                                    width="120px"
                                    placeholder="+ 55"
                                />

                                <Select
                                    width="300px"
                                    disabled={true}
                                    placeholder="Tipo de telefone"
                                    options={typeListPhone}
                                    value={Number(id_tipo_phone)}
                                />
                            </div>
                        )
                    })}
                </FieldContainer>

                <div className="divisor"></div>

                <div className="container-full-line" style={{ marginTop: "10px" }}>
                    <FieldContainer title="CEP">
                        <Input
                            disabled={true}
                            type="text"
                            id="cep"
                            placeholder="XX.XXX-XXX"
                            maxLength="8"
                            value={value?.cep}
                        />
                    </FieldContainer>
                    <FieldContainer title="Endereço">
                        <Input
                            disabled={true}
                            type="text"
                            id="address"
                            name="address"
                            placeholder="Endereço"
                            value={value?.endereco}
                        />
                    </FieldContainer>
                    <FieldContainer title="Número">
                        <Input
                            disabled={true}
                            type="number"
                            className="number"
                            placeholder="Número"
                            value={value?.numero}
                        />
                    </FieldContainer>
                </div>

                <div className="container-full-line">
                    <FieldContainer title="Estado">
                        <Input
                            disabled={true}
                            type="text"
                            id="uf"
                            placeholder="Estado"
                        />
                    </FieldContainer>
                    <FieldContainer title="Cidade">
                        <Input
                            disabled={true}
                            type="text"
                            id="district"
                            placeholder="Cidade"
                        />
                    </FieldContainer>
                    <FieldContainer title="Empresa">
                        <Select
                            upward
                            disabled={true}
                            placeholder="Nome da empresa"
                            value={value?.id_empresa}
                            options={companys}
                            onSelect={(company) => {
                                const objContact = { ...value };

                                objContact.id_empresa = company.id
                                objContact.nome = company.label

                                setValue(objContact)
                            }}
                        />
                    </FieldContainer>
                </div>
            </section>
        </Modal.Main>
    )
}














