//GLOBAL - components from npm
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";

// UTILS

//STYLES
import "./nav-bar.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconClose } from "../../assets/icons/CloseBar.svg";
import { ReactComponent as IconTicket } from "../../assets/icons/Ticket.svg";
import { ReactComponent as IconDashboard } from "../../assets/icons/Dashboard.svg";
import { ReactComponent as IconTask } from "../../assets/icons/Task.svg";
import { ReactComponent as IconMenu } from "../../assets/icons/Menu.svg";
import { ReactComponent as IconSLA } from "../../assets/icons/SLA-timerStart.svg";
import { ReactComponent as IconTypeManagement } from "../../assets/icons/TypeManagement.svg";
import { ReactComponent as IconContact } from "../../assets/icons/IconContact.svg";
import { ReactComponent as IconFiles } from "../../assets/icons/Files.svg";
import { FaUserPlus } from "react-icons/fa";

export default function Header() {
  //GENERAL
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  //STATES
  const [visibleBox, setVisibleBox] = useState(false);

  //REDUX - Selectors
  const darkMode = useSelector((state) => state.filters.darkMode);
  const user = useSelector((state) => state.auth.userData);

  //FUNCTIONS

  const teste = () => {
    window.location.href = `/casos`;
  };

  //USE EFFECTS

  return (
    <>
      <div
        className={`nav-bar`}
        //  style={{ width: visibleBox ? "145px" : "auto" }}
      >
        <div
          className={`nav-bar-container ${visibleBox ? "exhib-navbar" : ""} ${
            darkMode && location.pathname === "/dashboard" ? "dark-mode" : ""
          }`}
          // onMouseOver={() => setVisibleBox(true)}
          // onMouseOut={() => setVisibleBox(false)}
        >
          <div
            className="nav-bar-container__option"
            style={{ marginTop: visibleBox ? "4px" : "0" }}
            onClick={() => setVisibleBox(!visibleBox)}
          >
            {!visibleBox ? <IconMenu /> : <IconClose />}
          </div>

          {user.permissionId !== 1 && (
            <div
              className="nav-bar-container__option"
              onClick={() => history.push(`/dashboard`)}
            >
              <div
                className={
                  location.pathname === "/dashboard"
                    ? "icon_option-twoactive dashboard-icon"
                    : "icon_option-inactive dashboard-icon"
                }
              >
                <IconDashboard />
              </div>

              <span
                className={
                  location.pathname === "/dashboard"
                    ? "text_option-active"
                    : "text_option-inactive"
                }
              >
                Dashboard
              </span>
            </div>
          )}

          <div
            className="nav-bar-container__option"
            onClick={() => history.push(`/tarefas`)}
          >
            <div
              className={
                location.pathname === "/" ||
                location.pathname === "/tarefas" ||
                location.pathname === `/tarefas/${id}`
                  ? "icon_option-active default-icon"
                  : "icon_option-inactive default-icon"
              }
            >
              <IconTask />
            </div>

            <span
              className={
                location.pathname === "/" ||
                location.pathname === "/tarefas" ||
                location.pathname === `/tarefas/${id}`
                  ? "text_option-active"
                  : "text_option-inactive"
              }
            >
              Tarefas
            </span>
          </div>

          {/* <div className="nav-bar-container__option" onClick={() => history.push(`/casos`)}> */}
          <div
            className="nav-bar-container__option"
            onClick={() => history.push(`/casos`)}
          >
            <div
              className={
                location.pathname === "/casos" ||
                location.pathname === `/casos/${id}`
                  ? "icon_option-active"
                  : "icon_option-inactive"
              }
            >
              <IconTicket />
            </div>

            <span
              className={
                location.pathname === "/casos" ||
                location.pathname === `/casos/${id}`
                  ? "text_option-active"
                  : "text_option-inactive"
              }
            >
              Casos
            </span>
          </div>

          {/* <div className="nav-bar-container__option" onClick={() => history.push(`/templates`)}>
            <div
              className={
                location.pathname === "/templates"
                  ? "icon_option-active"
                  : "icon_option-inactive"
              }
            >
              <IconTicket />
            </div>
            {visibleBox && (
              <span
                className={
                  location.pathname === "/templates"
                    ? "text_option-active"
                    : "text_option-inactive"
                }
              >
                Templates
              </span>
            )}
          </div> */}

          {/* {user.permissionId === 2 && (
            <div
              className="nav-bar-container__option"
              onClick={() => history.push(`/contato`)}
            >
              <div
                className={
                  location.pathname === "/contato"
                    ? "icon_option-active"
                    : "icon_option-inactive"
                }
              >
                <IconContact />
              </div>

              <span
                className={
                  location.pathname === "/contato"
                    ? "text_option-active"
                    : "text_option-inactive"
                }
              >
                Contato
              </span>
            </div>
          )} */}

          {/* {user.permissionId === 2 && (
            <div
              className="nav-bar-container__option"
              onClick={() => history.push(`/sla`)}
            >
              <div
                className={
                  location.pathname === "/sla"
                    ? "icon_option-active"
                    : "icon_option-inactive"
                }
              >
                <IconSLA />
              </div>

              <span
                className={
                  location.pathname === "/sla"
                    ? "icon_option-active"
                    : "icon_option-inactive"
                }
              >
                SLA
              </span>
            </div>
          )} */}
          {/* <div
            className="nav-bar-container__option"
            onClick={() => history.push(`/typeSolicitation`)}
          >
            <div
              className={
                location.pathname === "/typeSolicitation"
                  ? "icon_option-active"
                  : "icon_option-inactive"
              }
            >
              <IconTypeSolicitationReason />
            </div>

            <div>
              <span
                className={
                  location.pathname === "/typeSolicitation"
                    ? "text_option-active"
                    : "text_option-inactive"
                }
              >
                Tps. solic/motivos
              </span>
            </div>
          </div> */}
          {user.permissionId === 2 && (
            <div
              className="nav-bar-container__option"
              onClick={() => history.push(`/type-management`)}
            >
              <div
                className={
                  location.pathname === "/type-management"
                    ? "icon_option-active"
                    : "icon_option-inactive"
                }
              >
                <IconTypeManagement />
              </div>

              <span
                className={
                  location.pathname === "/type-management"
                    ? "text_option-active"
                    : "text_option-inactive"
                }
                style={{ width: "inherit" }}
              >
                Tipos
              </span>
            </div>
          )}

          {user.permissionId === 2 && (
            <div
              className="nav-bar-container__option"
              onClick={() => history.push(`/cadastro`)}
            >
              <div
                className={
                  location.pathname === "/cadastro"
                    ? "icon_option-active"
                    : "icon_option-inactive"
                }
              >
                <FaUserPlus color="#757575" />
              </div>

              <span
                className={
                  location.pathname === "/cadastro"
                    ? "text_option-active"
                    : "text_option-inactive"
                }
                style={{ width: "inherit" }}
              >
                Cadastro
              </span>
            </div>
          )}
          {/* <div
            className="nav-bar-container__option"
            onClick={() => history.push(`/contact-import`)}
          >
            <div
              className={
                location.pathname === "/contact-import"
                  ? "icon_option-active"
                  : "icon_option-inactive"
              }
            >
              <IconFiles />
            </div>

            <span
              className={
                location.pathname === "/contact-import"
                  ? "text_option-active"
                  : "text_option-inactive"
              }
              style={{ width: "inherit" }}
            >
              Arquivos
            </span>
          </div> */}
        </div>
      </div>
      {/* <hr
        className="nav-bar-border"
        style={{
          marginRight: location.pathname === `/casos/${id}` ? "5px" : "0",
          opacity: "5%",
        }}
      ></hr> */}
    </>
  );
}
