import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { Loading, Modal } from "./../../../feedback";
import { Button, Typography } from "../../../general";
import { FieldContainer } from "../../../data-display";
import { CustomSelect, Input, Select } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";

import * as APISla from "./../../../../services/api/sla";
import * as APITypeSolicitation from "./../../../../services/api/typeSolicitation";

import "./update-type.scss";

export default function UpdateType({
    isOpen,
    onClose,
    reasonListValues,
    treeData,
    organizationId,
    typesLoading,
    setTypesLoading,
    setShouldReloadTypes,
    isOpenModalUpdateType
}) {
    const token = useSelector((state) => state.auth.token);

    const typeId = isOpenModalUpdateType.typeId || null;

    const [pageStatus, setPageStatus] = useState("awaiting");
    const [dataUpdate, setDataUpdate] = useState(null);
    const [solicitationId, setSolicitationId] = useState(null);
    const [selectReason, setSelectReason] = useState([]);
    const [listSla, setListSla] = useState([]);

    const customStyles = {
        input: (provided) => ({
            ...provided,
            flexGrow: "1",
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            color: "#27282e",
            transition: "all 0.25s",
            outline: "none",
        }),
        menu: (provided) => ({
            ...provided,
            ...(
                dataUpdate?.reasonsList?.length > 0 || selectReason.length > 0 ?
                    {
                        maxHeight: "110px",
                        position: "absolute",
                        display: "flex",
                        zIndex: 15,
                        flexDirection: "column",
                        borderTop: "0",
                        overflow: "auto",
                        border: "1px solid #2684FF",
                        boxShadow: "none",
                        borderColor: "#455cc7",
                    } : {
                        maxHeight: "145px",
                        position: "absolute",
                        display: "flex",
                        zIndex: 15,
                        flexDirection: "column",
                        borderTop: "0",
                        overflow: "auto",
                        border: "1px solid #2684FF",
                        boxShadow: "none",
                        borderColor: "#455cc7",
                    }
            )
        }),
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
            boxShadow: "none",
        }),
        valueContainer: (provided) => ({
            ...provided,
            ...(
                dataUpdate?.reasonsList?.length > 0 || selectReason.length > 0 ?
                    {
                        maxHeight: "80px",
                        overflow: "auto",
                        padding: "10px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        "::-webkit-scrollbar": {
                            width: "5px",
                        },
                    } : {
                        maxHeight: "80px",
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                            width: "5px",
                        },
                    }
            )
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            ":active": {
                color: "hsl(0, 0%, 80%)",
                transform: "rotate(180deg)"
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#bfc3ca",
        }),
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            minHeight: "min-content",
            padding: "12px",
            textAlign: "left",

            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#828292",
            cursor: "pointer",

            ":active": {
                backgroundColor: "#ebf2ff",
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            gap: "4px",
            griGap: "4px",
            padding: "4px 4px 4px 8px",
            backgroundColor: "#f4f4f4",
            borderRadius: "24px",
            minWidth: "max-content",
            height: "25px",
            marginRight: "8px",
            marginBottom: "4px",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "112.5%",
            padding: "5px",
            alignItems: "flex-end",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#455cc7",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            display: "none"
        }),
        dropdownIndicator: (provided) => ({
            ...provided,

            ":hover": {
                transform: "rotate(180deg)",
            },
        }),
    };

    const reasonsIdArray = useMemo(() => {
        return { list: [] }
    }, []);

    const handleRadioChange = (event) => {
        const value = event.target.value === "yes" ? true : false;

        setDataUpdate((prevValue) => ({
            ...prevValue,
            editavel: prevValue.id_sla !== null ? value : null,
        }))
    };

    const filteredOptions = reasonListValues?.filter(
        (reason) =>
            !dataUpdate?.reasonsList?.some(
                (option) => Number(option.id_motivo) === reason.id_motivo
            )
    );

    const generateCreateLabel = () => {
        return (
            <Typography.H4>
                Nenhum motivo cadastrado com esse nome!
            </Typography.H4>
        )
    };

    function onCloseCustom() {
        reasonsIdArray.list = [];

        onClose();
    };

    function getValues(typeId) {
        treeData.types?.forEach((item) => {
            if (item.id_solicitation === typeId) {
                setDataUpdate(item);
                setSolicitationId(item.id_solicitation);
            }
        })
    };

    async function getListSla() {
        const response = await APISla.listSla(token);

        const data = response.data;
        let updatedData = [];

        data.forEach((sla) => {
            let updateSla = {
                id: sla.id_sla,
                label: sla.desc_sla,
            };

            updatedData.push(updateSla);
        });

        setListSla(updatedData);
    };

    async function handleEditType() {
        setPageStatus("editing-type");

        const obj = { ...dataUpdate };

        const updatedType = {
            id_solicitacao: Number(solicitationId),
            descricao_solicitacao: dataUpdate?.desc_solicitation,
            id_sla: dataUpdate?.id_sla,
            editavel: dataUpdate?.editavel,
            id_tipo_solicitacao_sla: dataUpdate?.id_type_solicitation_sla || null,
            id_motivo: selectReason ? obj.reasonIdList = reasonsIdArray.list : [],
            id_organizacao: organizationId && organizationId
        };

        const response = await APITypeSolicitation.updateSolicitationV2(token, updatedType);

        if (response.status === 200) {
            onClose();

            setShouldReloadTypes(true);
            setTypesLoading(true);

            Toast(
                "Tipo atualizado!",
                "Tipo atualizado com sucesso.",
                "success"
            );
        } else {
            Toast(
                "Não foi possível atualizar o Tipo",
                "Tente novamente mais tarde!",
                "error"
            )
        }

        setPageStatus("awaiting");
    }

    useEffect(() => {
        if (isOpen) {
            getListSla();
        }
    }, [isOpen]);

    useEffect(() => {
        if (typeId) {
            getValues(typeId);
        }
    }, [typeId]);

    useEffect(() => {
        if (selectReason) {
            const isType = treeData.types?.find(item => item.id_solicitation === typeId)

            selectReason?.forEach((value, index) => {
                if (selectReason.length - 1 === index) {
                    reasonsIdArray.list.push(value.value)

                    if (isType) {
                        isType.reasonsList.push({
                            id_motivo: value.value,
                            desc_motivo: value.label
                        })
                    }
                }
            })
        }
    }, [selectReason, typeId, treeData.types]);

    useEffect(() => {
        if (dataUpdate?.id_sla === null) {
            setDataUpdate((preValue) => ({
                ...preValue,
                editavel: null,
            }))
        }
    }, [dataUpdate?.id_sla]);

    useEffect(() => {
        if (typesLoading) {
            const loading = setTimeout(() => {
                setTypesLoading(false);
            }, 2000);

            return () => clearTimeout(loading);
        }
    }, [typesLoading, setTypesLoading]);

    return (
        <Modal.Main
            className="modal-update-type"
            title="Editar tipo"
            height={dataUpdate?.reasonsList?.length > 0 ? "500px" : "395px"}
            width="700px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <>
                    <Button
                        className="modal-update-type__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "editing-type"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-update-type__btn-confirm"
                        disabled={pageStatus === "editing-type"}
                        onClick={handleEditType}
                    >
                        {pageStatus !== "editing-type" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </>
            }
        >
            <section
                className="modal-update-type__content"
                style={dataUpdate?.reasonsList?.length > 0 ? { height: "360px" } : { height: "255px" }}
            >
                <div className="modal-update-type__content__first">
                    <FieldContainer required title="Descrição">
                        <Input
                            type="text"
                            placeholder="Digite o nome da descrição"
                            value={dataUpdate?.desc_solicitation}
                            onChange={(event) => setDataUpdate({ ...dataUpdate, desc_solicitation: event })}
                        />
                    </FieldContainer>
                </div>

                <div className="modal-update-type__content__second">
                    <FieldContainer required title="SLA">
                        <Select
                            placeholder="Selecione o SLA"
                            options={listSla}
                            value={dataUpdate?.id_sla}
                            onSelect={(option) => {
                                const obj = { ...dataUpdate };

                                obj.id_sla = option.id;
                                obj.desc_sla = option.label;

                                setDataUpdate(obj);
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer required title="Editável">
                        <div className="field-container-radio">
                            <div className="container-input-radio">
                                <label htmlFor="yes" className="radio-label">
                                    <input
                                        type="radio"
                                        id="yes"
                                        name="radio-option"
                                        value="yes"
                                        className="custom-radio"
                                        checked={dataUpdate?.editavel === true}
                                        onChange={handleRadioChange}
                                        disabled={dataUpdate?.id_sla === null}
                                    />
                                    Sim
                                </label>
                            </div>

                            <div className="container-input-radio">
                                <label htmlFor="no" className="radio-label">
                                    <input
                                        type="radio"
                                        id="no"
                                        name="radio-option"
                                        value="no"
                                        className="custom-radio"
                                        checked={dataUpdate?.editavel === false}
                                        onChange={handleRadioChange}
                                        disabled={dataUpdate?.id_sla === null}
                                    />
                                    Não
                                </label>
                            </div>
                        </div>
                    </FieldContainer>
                </div>

                {
                    dataUpdate?.reasonsList?.length > 0 && (
                        <>
                            <div className="divisor" />

                            <div className="modal-update-type__content__third">
                                <FieldContainer title="Motivo">
                                    <CustomSelect
                                        noClearIndicator
                                        backspaceRemovesValue
                                        openMenuOnClick
                                        openMenuOnFocus
                                        placeholder="Selecione um ou mais motivos"
                                        formatCreateLabel={generateCreateLabel}
                                        onChange={(newReason) => setSelectReason(newReason)}
                                        styles={customStyles}
                                        options={
                                            filteredOptions?.map((reason) => {
                                                return {
                                                    value: reason.id_motivo,
                                                    label: reason.desc_motivo
                                                }
                                            })
                                        }
                                        defaultValue={
                                            dataUpdate?.reasonsList?.map((reason) => {
                                                return {
                                                    value: reason.id_motivo.toString(),
                                                    label: reason.desc_motivo
                                                }
                                            })
                                        }
                                    />
                                </FieldContainer>
                            </div>
                        </>
                    )
                }
            </section>
        </Modal.Main>
    )
}
