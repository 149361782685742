//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIUser from "../../../../services/api/User";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES

import "./delete-user.scss";

export default function DeleteUser({
    isOpen,
    onClose,
    loggedInUserId,
    deletedUserId,
    users,
    handleTableDataLookup,
    returnToUserList = false,
    onDelete
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [deletedUser, setDeletedUser] = useState(false);

    //FUNCTIONS
    function onCloseCustom() {
        onClose();
    };

    async function handleDeleteUser() {
        setPageStatus("delete-shift-worker");

        const data = {
            id_usuario: deletedUserId,
            id_usuario_logado: loggedInUserId
        };

        try {
            const response = await APIUser.deleteUser(token, data)

            if (response.status === 200) {
                onDelete();
                onCloseCustom();
                Toast(
                    "Usuário excluído!",
                    "Usuário excluído com sucesso.",
                    "success"
                );

                setDeletedUser(true);

                if (returnToUserList) {
                    returnToUserList()
                }
            } else {
                const responseData = await response.json();
                throw new Error(responseData.message || "Falha ao excluir o usuário.");
            }
        } catch (error) {
            console.error("Erro ao excluir o usuário:", error);

            Toast(
                "Não foi possível excluir o usuário!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        } finally {
            setPageStatus("awaiting");
        }
    };

    //USE MEMOS

    //USE EFFECTS 
    useEffect(() => {
        if (deletedUser) {
            handleTableDataLookup()
        }
    }, [deletedUser, users]);

    return (
        <Modal.Main
            className="modal-delete-user"
            title="Excluir usuário"
            width="443px"
            height="280px"
            titleColor={"black"}
            isOpen={isOpen}
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            headerContainerStyle={{ alignItems: "center" }}
            icon={
                <div className="modal-delete-user__icon">
                    <TrashIcon />
                </div>
            }
            footer={
                <div className="container-footer">
                    <Button
                        onClick={() => onCloseCustom()}
                        className="modal-delete-user__btn-cancel"
                        disabled={pageStatus === "delete-shift-worker"}
                    >
                        <Typography.H4 weight={"bold"} color="blue-selected-4">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-delete-user__btn-confirm"
                        disabled={pageStatus === "delete-shift-worker"}
                        onClick={handleDeleteUser}
                    >
                        {pageStatus !== "delete-shift-worker" ? (
                            <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4
                color="gray-180"
                weight="400"
                className="information-text"
            >
                Você tem certeza que deseja excluir? Ao excluir todos os dados, serão excluídos e esta ação não poderá ser desfeita.
            </Typography.H4>
        </Modal.Main>
    )
}
