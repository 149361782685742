export const reduceString = (text, numberOfCharacters) => {
  let truncText = text;
  if (typeof text === "string") {
    const isTextShort = text.length <= numberOfCharacters;
    if (!isTextShort) {
      truncText = text.slice(0, numberOfCharacters) + "...";
    }
  }

  return truncText;
};
