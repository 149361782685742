export function validateRg(rg) {
    var digitos = rg.split("");
    var totais = [];
    var total = 0;

    //* Multiplicar os que seriam da primeira linha com os da segunda    
    digitos.forEach(function (digito, index) {
        totais.push(Number(digito) * (2 + index));
    });

    //* Multiplicar as colunas
    totais.forEach(function (numero) { total += numero });

    //* Descobrir o resto da divisão
    var resto = total % 11;

    return 11 - resto;
};