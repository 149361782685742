// import React from "react";
import { toast } from "react-toastify";

//COMPONENTS
import { Typography } from "../components/general";

// ASSETS
import { ReactComponent as TicketCircleIcon } from "./../assets/icons/tick-circle.svg";

//STYLES
import "../styles/toast/customizable-toast.scss";

export const Toast = (title, message, type, toastId) => {
  const renderToastContent = () => (
    <div className="toast-container" style={{ display: "flex", flexDirection: "column" }}>
      {title && (
        <Typography.H5 color="gray-500" className="toast-container__title">
          {title}
        </Typography.H5>
      )}
      {message && (
        <Typography.Paragraph color="gray-500" className="toast-container__message">
          {message}
        </Typography.Paragraph>
      )}
    </div>
  );

  const renderSuccessToast = () => {
    if (type === "personalized success message") {
      return (
        <div className="toast-custom-success">
          <div className="toast-custom-success-content">
            <div className="toast-custom-success-content__icon">
              <TicketCircleIcon />
            </div>
            <div className="toast-custom-success-content__message">
              {title && (
                <Typography.H2 weight="700" color="black">
                  {title}
                </Typography.H2>
              )}
              {message && (
                <Typography.Paragraph weight="400" color="gray-180">
                  {message}
                </Typography.Paragraph>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return renderToastContent();
    }
  };

  switch (type) {
    case "success":
      return toast.success(renderToastContent, { icon: "", toastId });

    case "personalized success message":
      return toast.success(renderSuccessToast, { icon: "", toastId });

    case "error":
      return toast.error(renderToastContent, { icon: "", toastId });

    case "warn":
      return toast.warn(renderToastContent, { icon: "", toastId });

    default:
      return toast.info("Toast não definido!!", { icon: "" });
  }
};
