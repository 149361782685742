import Auth from "./Auth";
import Tasks from "./Tasks";
import ActiveInteraction from "./ActiveInteraction";
import Page404 from "./Page404";
import Cases from "./Cases";
import Dashboard from "./Dashboard";
import Templates from "./Templates";
import RegisterSolicitationMotive from "./RegisterSolicitationMotive";
// import Contact from './Contact';
// import SLA from './SLA';
import TypeSolicitationReason from "./TypeSolicitationReason";
import TypeManagement from "./TypeManagement";
import ContactImport from "./ContactImport";
import Records from "./Records";
import CreateBatchCases from "./CreateBatchCases";

const Pages = {
  Auth,
  Tasks,
  ActiveInteraction,
  Page404,
  Cases,
  Dashboard,
  Templates,
  RegisterSolicitationMotive,
  // Contact,
  // SLA,
  TypeSolicitationReason,
  TypeManagement,
  ContactImport,
  Records,
  CreateBatchCases,
};

export default Pages;
