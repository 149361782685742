//GLOBAL - components from npm
import React from "react";

//STYLES
import "./tag-chips.scss";

//COMPONENTS
import { Typography } from "../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function TagChips({ children, selected, disabled, onClick }) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <button
      type="button"
      className={`tag-chips ${selected ? "selected" : "unselected"}`}
      disabled={disabled}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <Typography.H5 weight="500" color="blue-selected-4">
        {children}
      </Typography.H5>
    </button>
  );
}
