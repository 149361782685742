module.exports = {
  connection_platform: {
    urlViewDocsApache: `https://api-voip.uzecartoes.com.br:443`,
    urlCommunicatedBackend: "https://webrtcadm.uzecartoes.com.br:8082",
    urlsPostMessage: [
        "http://ingweb.uzecartoes.com.br/",
        "https://ingweb.uzecartoes.com.br/",
    ],
    urlProjectIngWeb: "https://api-voip.uzecartoes.com.br:443",
    urlSendMail: "https://webrtcadm.uzecartoes.com.br:470",
    urlCommunicatedDashboard: "https://webrtcadm.uzecartoes.com.br:8084",
    time_atualization: 600000,
    organization: true,
    numberDays: 10,
    companyRequired: false,
    staggeredRequired: false,
    reasonRequired: false,
    customFilters: false,
    uploadFile: `https://api-voip.uzecartoes.com.br:443`,
    microserviceToImportCases: "",
    tokenToInsertCases: "",

  },
};

