//GLOBAL - components from npm
import React, { useState, useRef } from 'react';
import { PageTitle, FieldContainer } from './../../components/data-display';
import { Input } from './../../components/data-entry';
import { Button, Typography } from './../../components/general';

//STYLES
import './templates.scss'

//ASSETS - icons, images...
import {ReactComponent as IconSearch } from "../../assets/icons/Search-normal.svg";
import { ReactComponent as IconPlusCircle } from '../../assets/icons/PlusCircle.svg';
import { ReactComponent as MoreHorizontal } from '../../assets/icons/MoreHorizontal.svg';
import { ReactComponent as EmptyTemplate } from '../../assets/icons/EmptyTemplate.svg';
import { ReactComponent as Arrow } from '../../assets/icons/ArrowUpTwo.svg';

export default function Templates() {
	//GENERAL
    const refPage = useRef();

	//STATES
    const [categories, setCategories] = useState([
        {
            id: 1,
            label: 'Envio de Fatura'
        },
        {
            id: 2,
            label: 'Consulta de Saldo'
        },
        {
            id: 3,
            label: 'Problemas técnicos'
        },
        {
            id: 4,
            label: 'Portabilidade'
        },
        {
            id: 5,
            label: 'Comercial'
        },
        {
            id: 6,
            label: 'Compra de Aparelhos'
        },
        {
            id: 7,
            label: 'Explicação de Serviços'
        },
        {
            id: 8,
            label: 'Tutoriais'
        },
        {
            id: 9,
            label: 'Aplicativo'
        },
        {
            id: 10,
            label: 'Ouvidoria'
        },
        {
            id: 11,
            label: 'Carreiras'
        },
        {
            id: 12,
            label: 'Contratos'
        },
]);
const [categorySelected, setCategorySelected] = useState(0);

	//REDUX - Selectors


	//FUNCTIONS
	

	//USE EFFECTS

	return (
        <main className="page-template" ref={refPage}>
            <div className="page-template-container">
                <PageTitle
                    className="header-template-container"
                    title='Templates'
                    leftContent={
                            <div className="page-template-container__header">
                                <div className="page-template-container__header-date-range">
                                    <input type="text" placeholder="Buscar Template" />
                                    <div className="page-template-container__header-date-range__icon">
                                        <IconSearch />
                                    </div>
                                </div>
                                <div className="page-template-container__header-add-template">
                                    <Button type="primary" icon={<IconPlusCircle />} iconFillType="stroke">Adicionar Categoria</Button>  
                                </div>
                            </div>
                    }
                />
                <div className="page-template-container__actions-categories">
                    <div className="page-template-container__actions-categories__table">
                        <div className="page-template-container__actions-categories__table-title">
                            <div className="page-template-container__actions-categories__table-title__text">
                                <Typography.H6
                                    weight="400"
                                    color="white"
                                    className={"header-column"}
                                >
                                    CATEGORIA DO TEMPLATE               
                                </Typography.H6>
                            </div>
                        </div>
                        <div className="page-template-container__actions-categories__table-values">
                        {categories.map((category, index) => (
                            <div className="page-template-container__actions-categories__table-values__box" onClick={() => setCategorySelected(category.id)}>
                                <div className={index % 2 ? "page-template-container__actions-categories__table-rows_primary":"page-template-container__actions-categories__table-rows_secondary"}>
                                    <Typography.H6
                                        weight="400"
                                        color="blue-selected-4"
                                    >
                                        {category.label}
                                    </Typography.H6>
                                </div>
                                <div className="page-template-container__actions-categories__table-actions">
                                    <MoreHorizontal />
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    {categorySelected === 0 ? 
                        <div className="page-template-container__actions-categories__empty">
                            <EmptyTemplate />
                        </div> :
                        <div className="page-template-container__actions-categories__info">
                            <div className="page-template-container__actions-categories__info__header">
                                <div className="page-template-container__actions-categories__info__header-input">
                                    <FieldContainer
                                        title="Categoria de Templates:"
                                        htmlFor=""
                                        className="category-template"
                                    >
                                    <Input
                                        type="text"
                                        placeholder="Categoria"
                                        // value={inputSearch}
                                        // onChange={(value) => setInputSearch(value)}
                                    />
                                    </FieldContainer>
                                </div>
                                <div className="page-template-container__actions-categories__info__header-button">                                
                                    <Button type="primary">Adicionar Subcategoria</Button>  
                                </div>
                            </div>
                            <div className="page-template-container__actions-categories__info__box">
                                <div className="page-template-container__actions-categories__info__box-container">
                                    <div className="page-template-container__actions-categories__info__box-container__items">
                                        <Arrow />
                                        <Typography.H5
                                            weight="700"
                                            color="blue-selected-4"
                                        >
                                            Segunda Via
                                        </Typography.H5>
                                    </div>
                                </div>
                                <div className="page-template-container__actions-categories__info__box-container">
                                    <div className="page-template-container__actions-categories__info__box-container__items">
                                        <Arrow />
                                        <Typography.H5
                                            weight="700"
                                            color="blue-selected-4"
                                        >
                                            Segunda Via
                                        </Typography.H5>
                                    </div>
                                </div>
                                <div className="page-template-container__actions-categories__info__box-container">
                                    <div className="page-template-container__actions-categories__info__box-container__items">
                                        <Arrow />
                                        <Typography.H5
                                            weight="700"
                                            color="blue-selected-4"
                                        >
                                            Segunda Via
                                        </Typography.H5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </main>
	)
}