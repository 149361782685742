//GLOBAL - components from npm
import React, { useState, useRef, useEffect, Fragment } from "react";

//STYLES
import "./select-search.scss";

//COMPONENTS
import { ImageProfile, FieldContainer } from "../../data-display";
import { Typography, Button } from "../../general";
import { Tippy, Loading } from "../../feedback";
import { DropdownStatus } from "../../layout";
import unicodeToChar from "../../../utils/formatUnicode";

// CUSTOM HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as IconSearch } from "../../../assets/icons/Search-normal.svg";
import { ReactComponent as EmptyFolder } from "../../../assets/images/empty-folder.svg";
import { ReactComponent as UserSearch } from "../../../assets/icons/User-search.svg";

export default function SelectSearch({
  placeholder = "Digite um titulo para buscar",
  value = undefined,
  width,
  top,
  style,
  disabled,
  className,
  nameField = "other",
  tooltip = false,
  error = false,
  paginated = false,
  getOptions = () => null,
  showAvatar = true,
  onSelect = () => null,
  options = [
    {
      id: 1,
      label: "João Alves",
      caption1: "Ingenium",
      caption2: "#01232294101",
    },
    {
      id: 2,
      label: "João Costa",
      caption1: "Ingenium",
      caption2: "#01232294101",
    },
    {
      id: 3,
      label: "Lorenço Saraiva",
    },
    {
      id: 4,
      label: "Guilherme Gomes",
      caption1: "IngDesk",
    },
  ],
  onClose = () => null,
  contactSelect = false,
  contactEmpty = () => null,
  canBeEditable = true,
  valueDirect = false,
}) {
  //GENERAL
  const listStatus = [
    {
      id: 3,
      label: "Concluidos",
    },
    {
      id: 4,
      label: "Cancelados",
    },
    {
      id: 2,
      label: "Pendentes",
    },
    {
      id: 1,
      label: "Em andamento",
    },
    {
      id: 5,
      label: "Escalado",
    },
  ];

  // REFS
  const selectSearchRef = useRef(null);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const sentinelRef = useRef(null);

  //STATES
  const [isOpen, setIsOpen] = useState(false);
  const [optionsInternal, setOptionsInternal] = useState(
    !paginated ? options : []
  );
  const [currentOptionData, setCurrentOptionData] = useState();
  const [currentOptionDisplay, setCurrentOptionDisplay] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [page, setPage] = useState(1);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSentinelVisible, setIsSentinelVisible] = useState(paginated);
  const [lengthOption, setLengthOption] = useState(50);

  //REDUX - Selectors

  //FUNCTIONS
  const maxHeight =
    optionsInternal.filter(filterOptions)?.length < 1 ? "none" : "280px";

  const combinedStyle = {
    maxHeight: maxHeight,
    top: top ? top : "",
  };

  async function updateOptions(title, page) {
    if (!paginated) return;

    setIsRequesting(true);
    const data = await getOptions(title, page);

    if (data.length > 0) {
      setIsSentinelVisible(true);
    }

    if (page > 1) {
      setOptionsInternal((oldState) => oldState.concat(data));
    } else {
      setOptionsInternal(data);
    }
    setIsRequesting(false);
  }

  function filterOptions(option) {
    if (paginated) return option;

    if (currentOptionDisplay) {
      if (
        option.label.toLowerCase().includes(currentOptionDisplay.toLowerCase())
      ) {
        return option;
      } else return;
    } else {
      return option;
    }
  }

  function resetState(
    props = {
      options: false,
      listScrollPosition: false,
      currentOptionDisplay: false,
      page: false,
      searchTerm: false,
    }
  ) {
    props.options && updateOptions("", 1);
    props.listScrollPosition &&
      listRef.current &&
      listRef.current.scroll({ top: 0 });
    props.currentOptionDisplay &&
      setCurrentOptionDisplay(currentOptionData ? currentOptionData.label : "");
    props.page && setPage(1);
    props.searchTerm && setSearchTerm("");
  }

  function onClickLabel() {
    if (!isOpen) {
      setIsOpen(true);
      if (canBeEditable) {
        setCurrentOptionDisplay("");
      }
    }

    inputRef.current?.focus();
  }

  function handleOnClickLabel() {
    if (disabled || !canBeEditable) {
      return null;
    } else {
      onClickLabel();
    }
  }

  function onClickOption(option) {
    setCurrentOptionDisplay(option.label ?? option.nome);
    setCurrentOptionData(option);
    onSelect(option);

    setIsOpen(false);
    resetState({
      options: true,
      listScrollPosition: true,
      page: page > 1,
      searchTerm: true,
    });
  }

  function observerCallback(entries) {
    if (isOpen && entries[0].isIntersecting) {
      setIsSentinelVisible(false);
      updateOptions(searchTerm, page + 1);
      setPage(page + 1);
    }
  }

  function observerCallbackDefault(entries) {
    setIsRequesting(true);
    if (isOpen && entries[0].isIntersecting) {
      if (lengthOption < optionsInternal.length) {
        setLengthOption((prevValue) => prevValue + 50);
      }

      setIsRequesting(false);
    }
  }

  // CUSTOM HOOKS
  useOutsideClick(selectSearchRef, () => {
    if (isOpen) {
      setIsOpen(false);
      resetState({
        options: true,
        listScrollPosition: true,
        currentOptionDisplay: true,
        searchTerm: true,
      });

      if (page !== 1) setPage(1);
    }
  });

  //USE EFFECT
  useEffect(() => {
    updateOptions(currentOptionDisplay, page);
  }, []);

  useEffect(() => {
    if (!paginated) {
      setOptionsInternal(options);
    }
  }, [options]);

  useEffect(() => {
    if (valueDirect) {
      let valueObject = options.find((opt) => opt.id === value);

      setCurrentOptionData(valueObject);
      setCurrentOptionDisplay(valueObject?.label);
    } else if (value && value.id !== currentOptionData?.id) {
      let valueObject;

      if (value?.id || paginated) {
        valueObject = value;
      } else {
        valueObject = options.find((opt) => opt.id === value);
      }

      setCurrentOptionData(valueObject);
      setCurrentOptionDisplay(valueObject?.label);
    }
  }, [value]);

  useEffect(() => {
    if (paginated && isOpen && sentinelRef.current) {
      let options = {
        root: listRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };

      if (listRef.current.scrollHeight > 260) {
        let observer = new IntersectionObserver(observerCallback, options);
        observer.observe(sentinelRef.current);

        return () => observer.disconnect();
      }
    }
  }, [sentinelRef.current, isOpen, isRequesting]);

  useEffect(() => {
    if (!paginated && isOpen && sentinelRef.current) {
      let options = {
        root: listRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };

      if (listRef.current.scrollHeight > 260) {
        let observer = new IntersectionObserver(
          observerCallbackDefault,
          options
        );
        observer.observe(sentinelRef.current);

        return () => observer.disconnect();
      }
    } else {
      setLengthOption(50);
    }
  }, [isOpen, sentinelRef.current]);

  return (
    <>
      <div
        ref={selectSearchRef}
        className={`select-search ${className ? className : ""} ${
          isOpen ? "select-search--active" : ""
        } ${error ? "select-search--error" : ""}`}
        style={{ width: width ? width : "" }}
      >
        <div
          style={style}
          className={`select-search-btn 
          ${disabled ? "select-search-btn--disabled" : "select-search-btn"}`}
          onClick={handleOnClickLabel}
        >
          {
            <Tippy
              offset={[0, 15]}
              content={currentOptionDisplay}
              disabled={isOpen ? isOpen : currentOptionDisplay ? false : true}
              followCursor="horizontal"
            >
              <input
                ref={inputRef}
                id={`field-select-${nameField}`}
                className={`select-search-btn__input ${
                  !canBeEditable ? "width" : ""
                }`}
                value={currentOptionDisplay}
                placeholder={placeholder}
                disabled={canBeEditable ? false : true}
                onChange={(event) => {
                  !isOpen && setIsOpen(true);
                  setCurrentOptionDisplay(event.target.value);

                  if (paginated && event.target.value.length === 0) {
                    resetState({
                      options: true,
                      page: true,
                      searchTerm: true,
                      listScrollPosition: true,
                    });
                  }
                }}
                onKeyPress={(event) => {
                  if (
                    (event.code === "Enter" || event.code === "NumpadEnter") &&
                    event.target.value !== searchTerm
                  ) {
                    resetState({
                      //options: paginated,
                      listScrollPosition: true,
                      page: paginated,
                    });

                    updateOptions(event.target.value, 1);
                    setSearchTerm(event.target.value);
                  }
                }}
              />
            </Tippy>
          }
          {canBeEditable &&
            (isOpen ? (
              <IconSearch
                className="select-search-btn__iconBase"
                onClick={(event) => {
                  event.stopPropagation();
                  resetState({
                    //options: paginated,
                    listScrollPosition: true,
                    page: paginated,
                  });
                  setSearchTerm(currentOptionDisplay);
                  updateOptions(currentOptionDisplay, 1);
                }}
              />
            ) : (
              <IconArrowDown
                className="select-search-btn__iconBase"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnClickLabel();
                }}
              />
            ))}
        </div>

        {canBeEditable && (
          <div
            className="select-search-list"
            ref={listRef}
            style={combinedStyle}
          >
            {optionsInternal.filter(filterOptions)?.length > 0 ? (
              <>
                {optionsInternal
                  .filter(filterOptions)
                  .slice(0, paginated ? optionsInternal.length : lengthOption)
                  .map((opt, index) => (
                    <Fragment key={`select-search-${index}`}>
                      {tooltip ? (
                        <Tippy
                          theme="popover-default"
                          zIndex={9999}
                          content={
                            <div className="select-search-popover">
                              <FieldContainer
                                title="Contato:"
                                className="select-search-popover-contact"
                                htmlFor=""
                              >
                                <Typography.H5 color="gray-200">
                                  {opt.option1}
                                </Typography.H5>
                              </FieldContainer>
                              <FieldContainer
                                title="Empresa:"
                                className="select-search-popover-company"
                                htmlFor=""
                              >
                                <Typography.H5 color="gray-200">
                                  {opt.option2 ? opt.option2 : "Sem Empresa"}
                                </Typography.H5>
                              </FieldContainer>
                              <FieldContainer
                                title="Solicitado em:"
                                className="select-search-popover-date"
                                htmlFor=""
                              >
                                <Typography.H5 color="gray-200">
                                  {opt.option3}
                                </Typography.H5>
                              </FieldContainer>
                              <div className="select-search-popover-status">
                                <DropdownStatus
                                  value={opt.option4}
                                  options={listStatus}
                                  type="tag"
                                  screen="tasks"
                                />
                              </div>
                              <FieldContainer
                                title="Assunto:"
                                className="select-search-popover-subject"
                                htmlFor=""
                              >
                                <Typography.H5 color="gray-200">
                                  {opt.option5}
                                </Typography.H5>
                              </FieldContainer>
                            </div>
                          }
                          followCursor="horizontal"
                        >
                          <button
                            type="button"
                            id={`select-search-list__item ${opt.id}`}
                            className={`select-search-list__item ${
                              opt.id === currentOptionData?.id ? "active" : ""
                            }`}
                            onClick={(event) => {
                              event.stopPropagation();
                              onClickOption(opt);
                            }}
                          >
                            {showAvatar && (
                              <ImageProfile
                                profile={{
                                  nome_usuario: opt.label,
                                  nome_organizacao: opt.caption1,
                                }}
                                tooltip={false}
                              />
                            )}

                            <div className="item-info">
                              <Typography.H5
                                weight="bold"
                                color="gray-400"
                                className="item-info__label"
                              >
                                {opt.label ? opt.label : "Title"}
                              </Typography.H5>
                              {opt.caption1 && (
                                <Typography.H5
                                  color="gray-400"
                                  className="item-info__caption"
                                >
                                  {opt.caption1}
                                </Typography.H5>
                              )}
                              {opt.caption2 && (
                                <Typography.H5
                                  color="gray-400"
                                  className="item-info__caption"
                                >
                                  {opt.caption2}
                                </Typography.H5>
                              )}
                            </div>
                          </button>
                        </Tippy>
                      ) : (
                        <button
                          type="button"
                          id={`select-search-list__item ${opt.id}`}
                          className={`select-search-list__item ${
                            opt.id === currentOptionData?.id ? "active" : ""
                          }`}
                          onClick={(event) => {
                            event.stopPropagation();
                            onClickOption(opt);
                          }}
                        >
                          {showAvatar && (
                            <ImageProfile
                              profile={{
                                nome_usuario: opt.label,
                                nome_organizacao: opt.caption1,
                              }}
                              tooltip={false}
                            />
                          )}

                          <div className="item-info">
                            <Typography.H5
                              weight="bold"
                              color="gray-400"
                              className="item-info__label"
                            >
                              {opt.label ? opt.label : "Title"}
                            </Typography.H5>
                            <div className="captions-container">
                              {opt.caption1 && (
                                <Typography.H5
                                  color="gray-180"
                                  className="item-info__caption"
                                >
                                  {opt.caption1}
                                </Typography.H5>
                              )}
                              {opt.caption1 && opt.caption2 && (
                                <Typography.H5
                                  color="gray-180"
                                  className="item-info__caption"
                                >
                                  •
                                </Typography.H5>
                              )}
                              {opt.caption2 && (
                                <Typography.H5
                                  color="gray-180"
                                  className="item-info__caption"
                                >
                                  {opt.caption2}
                                </Typography.H5>
                              )}
                            </div>
                            <div className="captions-container">
                              {opt.caption3 && (
                                <Typography.H5
                                  color="gray-180"
                                  className="item-info__caption"
                                >
                                  {opt.caption3}
                                </Typography.H5>
                              )}
                              {opt.caption3 && opt.caption4 && (
                                <Typography.H5
                                  color="gray-180"
                                  className="item-info__caption"
                                >
                                  •
                                </Typography.H5>
                              )}
                              {opt.caption4 && (
                                <Typography.H5
                                  color="gray-180"
                                  className={`item-info__caption ${
                                    opt.caption3 ? "second-caption" : ""
                                  }`}
                                >
                                  {opt.caption4}
                                </Typography.H5>
                              )}
                            </div>
                            {opt.caption5 && (
                              <Typography.H5
                                color="gray-180"
                                className="item-info__caption"
                              >
                                {opt.caption5}
                              </Typography.H5>
                            )}
                          </div>
                        </button>
                      )}
                    </Fragment>
                  ))}
                {isOpen && paginated && isSentinelVisible && (
                  <div
                    className="select-search-list__sentinel"
                    ref={sentinelRef}
                  ></div>
                )}
                {isOpen && !paginated && (
                  <div
                    className="select-search-list__sentinel"
                    ref={sentinelRef}
                  ></div>
                )}
                {isOpen && isRequesting && (
                  <div className="loading-container">
                    <Loading.Dots width={50} height={50} />
                  </div>
                )}
              </>
            ) : contactSelect ? (
              <div className="select-search-list__contact-empty">
                <div className="container-icon">
                  <UserSearch />
                </div>
                <Typography.H5>
                  Não encontramos nenhum contato. Deseja adicionar este contato?
                </Typography.H5>
                <Button onClick={contactEmpty}>Adicionar Contato</Button>
              </div>
            ) : (
              <div className="select-search-list__empty">
                <EmptyFolder />
                <Typography.H6>Não encontramos resultados.</Typography.H6>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
