//GLOBAL - components from npm
import React, { useState } from 'react';

//STYLES
import './drop-zone.scss'

//COMPONENTS
import { Typography } from "../../../components/general";
import { FilePreview } from "../../../components/data-display";


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconUpload } from '../../../assets/icons/Upload.svg'
import { ReactComponent as IconTrash } from '../../../assets/icons/Trash.svg'
import { ReactComponent as IconClips } from '../../../assets/icons/Clips.svg'
import { ReactComponent as IconEmptyStateFile } from '../../../assets/icons/EmptyStateFile.svg'


export default function DropZone({
    className = '',
    large
}) {

    //GENERAL


    //STATES
    const [valueFiles, setValueFiles] = useState([])


    //REDUX - Selectors


    //FUNCTIONS
    const uploadFiles = (e) => {
        const arrayFiles = e.target.files
        setValueFiles([...arrayFiles]);
    }

    const deleteFile = (indexFile) => {
        const arrayFiles = [...valueFiles];
        const filteredFiles = arrayFiles.filter((file, index) => index !== indexFile);

        setValueFiles(filteredFiles);
    }

    //USE EFFECTS


    if (large) {
        return (
            <div className={`drop-zone-container drop-zone-container-large ${className}`}>
                <label htmlFor="input-file-large" className="drop-zone">
                    <div className="drop-zone__text">
                        <IconUpload />
                        <Typography.H6 color="gray-175">Clique ou arraste um arquivo</Typography.H6>
                    </div>
                    <input id="input-file-large" type="file" multiple onChange={(e) => uploadFiles(e)} />
                </label>
                <div className="files-list">
                    {valueFiles.length > 0 ?
                        <>
                            {valueFiles.map((file, index) => (
                                <FilePreview key={index} type={file.type} name={file.name} size={file.size} onDelete={() => deleteFile(index)} />
                            ))}
                        </>
                        :
                        <div className="files-list__empty-state"><IconEmptyStateFile /></div>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className={`drop-zone-container ${className}`}>
                <label htmlFor="input-file" className="drop-zone">
                    <div className="drop-zone__text">
                        <IconUpload />
                        <Typography.H6 color="gray-175">Clique ou arraste um arquivo</Typography.H6>
                    </div>
                    <input id="input-file" type="file" multiple onChange={(e) => uploadFiles(e)} />
                </label>
                <div className="files-list">
                    {valueFiles.map((file, index) => (
                        <div className="file" key={index}>
                            <IconClips />
                            <Typography.Hyperlink color="primary-dark">{file.name}</Typography.Hyperlink>
                            <IconTrash onClick={() => deleteFile(index)} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
