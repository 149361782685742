import React, { useState } from "react";
import Creatable from "react-select/creatable";
import { components } from 'react-select';

import { Typography } from "../../general";
import { customStylesOne } from "./styles";

import { ReactComponent as IconArrowDown } from "./../../../assets/icons/ArrowDown.svg";
import { ReactComponent as IconCloseCircle } from "./../../../assets/icons/CloseCircle.svg";

export default function CustomSelect({
    placeholder,
    customClose,
    customDropdownIndicator,
    formatCreateLabel,
    customText,
    formatCreateLabelText,
    options,
    defaultValue,
    onKeyDown,
    onChange,
    value,
    isLoading,
    styles,
    isClearable,
    color,
    weight,
    openMenuOnFocus,
    openMenuOnClick,
    backspaceRemovesValue,
    noOptionsMessage,
    noClearIndicator,
    subreason,
    noOptionsLabel
}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
    };

    const generateCreateLabel = () => {
        if (customText) {
            return (
                <Typography.H4 color={color} weight={weight}>
                    {formatCreateLabelText}
                </Typography.H4>
            )
        } else {
            if (subreason) {
                return (
                    <p>
                        Esse submotivo não existe.
                        Pressione "Enter" para poder criá-lo
                    </p>
                );
            } else {
                return (
                    <p>
                        Esse motivo não existe.
                        Pressione "Enter" para poder criá-lo
                    </p>
                );
            }
        }
    };

    const CustomClearIndicator = (props) => {
        return (
            <components.ClearIndicator {...props}>
                <span
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <IconCloseCircle />
                </span>
            </components.ClearIndicator>
        )
    };

    const NoClearIndicator = () => {
        return null;
    };

    const DropdownIndicator = ({ isDropdownOpen }) => {
        return (
            <span
                style={{
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s ease-in-out',
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px"
                }}
            >
                <IconArrowDown />
            </span>
        );
    };

    return (
        <Creatable
            isMulti
            isClearable={isClearable && isClearable}
            formatCreateLabel={formatCreateLabel ? formatCreateLabel : generateCreateLabel}
            styles={styles ? styles : customStylesOne}
            isLoading={isLoading}
            value={value}
            noOptionsMessage={() => {
                return (
                    <Typography.H4>Sem opções</Typography.H4>
                )
            }}
            components={{
                ClearIndicator: noClearIndicator ? NoClearIndicator
                    : customClose ? customClose : CustomClearIndicator,
                DropdownIndicator: () =>
                    customDropdownIndicator ? customDropdownIndicator :
                        <DropdownIndicator isDropdownOpen={isDropdownOpen} />
            }}
            onFocus={() => setIsDropdownOpen(true)}
            onBlur={() => setIsDropdownOpen(false)}
            placeholder={placeholder ? placeholder : "Selecione ou adicione um novo motivo"}
            onKeyDown={onKeyDown}
            onChange={onChange}
            options={options}
            defaultValue={defaultValue}
            openMenuOnClick={openMenuOnClick && false}
            openMenuOnFocus={openMenuOnFocus && false}
            backspaceRemovesValue={backspaceRemovesValue && false}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: "#455cc7",
                },
            })}
        />
    )
}
