//GLOBAL - components from npm
import React, { useState, useRef } from "react";

//STYLES
import "./select-search-items.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy } from "../../feedback";
import { Input } from "..";

// CUSTOM HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
// import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";

export default function SelectSearchItems({
    placeholder,
    value,
    className,
    disabled,
    loadOnDemand,
    loadMoreOptions,
    loadingOptions,
    optionsLimit,
    style,
    options = [
        {
            id: 1,
            label: "Option1",
        },
        {
            id: 2,
            label: "Option2",
        },
        {
            id: 3,
            label: "Option3",
        },
    ],
    onSelect = () => null,
    width,
    upward,
    error,
    canBeEditable = true,
}) {
    //GENERAL
    const selectRef = useRef();

    //STATES
    const [isOpen, setIsOpen] = useState(false);
    const [inputSearch, setInputSearch] = useState("");

    // CUSTOM HOOKS
    useOutsideClick(selectRef, () => {
        setIsOpen(false);
    });

    //REDUX - Selectors

    //FUNCTIONS
    // function renderButtonContent() {
    //     const currentOption = options.find((opt) => opt.id === value);

    //     if (isOpen) {
    //         return (
    //             <Input
    //                 rightIcon={<IconSearch />}
    //                 type="text"
    //                 placeholder="Busca"
    //                 autoComplete="on"
    //                 value={inputSearch}
    //                 onChange={(value) => setInputSearch(value)}
    //             />
    //         );
    //     } else {
    //         if (value && currentOption) {
    //             return (
    //                 <Tippy content={currentOption.label}>
    //                     <div className="option-content">
    //                         <Typography.H5
    //                             color="gray-400"
    //                             className={`option-labelResume ${!canBeEditable ? "max-width" : ""}`}
    //                         >
    //                             {currentOption.label}
    //                         </Typography.H5>
    //                         {canBeEditable && <IconArrowDown />}
    //                     </div>
    //                 </Tippy>
    //             );
    //         } else {
    //             return (
    //                 <>
    //                     <Typography.H5 color="gray-125" weight={400}>
    //                         {placeholder}
    //                     </Typography.H5>
    //                     <IconArrowDown />
    //                 </>
    //             );
    //         }
    //     }
    // };

    const currentOption = options.find((opt) => opt.id === value);

    const listFilter = (item) => {
        if (inputSearch) {
            if (item.label.toLowerCase().includes(inputSearch.toLowerCase())) {
                return item;
            } else {
                return;
            }
        } else {
            return item;
        }
    };

    //USE EFFECTS

    return (
        <div
            ref={selectRef}
            className={`select-search-item
            ${upward ? "select-search-item--upwards" : ""} 
            ${className ? className : ""}`}
            style={{ maxWidth: width ? width : "" }}
        >
            <button
                className={`select-btn  
                ${isOpen ? "active" : ""} 
                ${error ? "select-btn--error" : ""}
                ${disabled === true ? "select-btn--disabled" : ""}`}
                onClick={() => {
                    if (disabled === true) {
                        return null;
                    } else {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                {/* {renderButtonContent()} */}

                {value && currentOption ? (
                    <Tippy content={currentOption.label}>
                        <div className="option-content">
                            <Typography.H5
                                color="gray-400"
                                className={`option-labelResume ${!canBeEditable ? "max-width" : ""}`}
                            >
                                {currentOption.label}
                            </Typography.H5>
                        </div>
                    </Tippy>
                ) : (
                    <>
                        <Typography.H5 color="gray-125" weight={400}>
                            {placeholder}
                        </Typography.H5>
                    </>
                )}

                <IconArrowDown />
            </button>

            <div
                style={style}
                className={`select-list ${isOpen ? "active" : ""} ${error ? "select-list--error" : ""}`}
            >
                {options.length > 10 && (
                    <Input
                        type="text"
                        placeholder="Buscar"
                        value={inputSearch}
                        onChange={(value) => setInputSearch(value)}
                    />
                )}

                {options.length > 0 ? (
                    options.filter(listFilter).map((option, index) => (
                        <button
                            type="button"
                            key={index}
                            className={`select-list__item ${option.id === value ? "active" : ""}`}
                            onClick={(e) => {
                                setIsOpen(!isOpen);
                                e.preventDefault();
                                onSelect(option);
                                setInputSearch("");
                            }}
                        >
                            <Typography.H5 color="gray-200">{option.label}</Typography.H5>
                        </button>
                    ))
                ) : (
                    <div className={`select-list__item select-list__item--empty`}>
                        <Typography.H5 color="gray-200">Não há nenhum item</Typography.H5>
                    </div>
                )}

                {loadOnDemand && (
                    <>
                        {optionsLimit <= options.length && (
                            <button
                                type="button"
                                className={`select-list__item ${loadOnDemand ? "loadOnDemand" : ""}`}
                                onClick={loadMoreOptions}
                            >
                                <Typography.H5 color="gray-200">
                                    {loadingOptions ? "Carregando..." : "Carregar mais"}
                                </Typography.H5>
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
