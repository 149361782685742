//GLOBAL - components from npm
import { createSlice } from "@reduxjs/toolkit";

const interactionSlice = createSlice({
  name: "interaction",
  initialState: {
    clientId: null,
    clientName: null,
    channelId: null,
    channelName: null,
    destination: null,
    interactionId: null,
    observation: null, 
    protocol: null,
    interaction: null,
  },
  reducers: {
    saveInteraction(state, action) {
      state.clientId = action.payload.clientId;
      state.clientName = action.payload.clientName;
      state.channelId = action.payload.channelId;
      state.channelName = action.payload.channelName;
      state.destination = action.payload.destination;
      state.interactionId = action.payload.interactionId;
      state.observation = action.payload.observation;
      state.companyName = action.payload.companyName;
      state.protocol = action.payload.protocol;
      state.interaction = action.payload.interaction;
    },
    removeInteraction(state) {
      state.clientId = null;
      state.clientName = null;
      state.channelId = null;
      state.channelName = null;
      state.destination = null;
      state.interactionId = null;
      state.companyName = null;
      state.protocol = null;
      state.interaction = null;
      state.observation = null;
    },
  },
});

export const { saveInteraction, removeInteraction } = interactionSlice.actions;
export default interactionSlice.reducer;
