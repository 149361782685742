//GLOBAL - components from npm
import React, { useEffect, useRef, useState } from "react";

// HOOKS
import useOutsideClick from "../../../../hooks/useOutsideClick";

//STYLES
import "./main.scss";

//COMPONENTS
import { Typography } from "../../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
// import { ReactComponent as ArrowBack } from '../../../../assets/icons/arrow-back.svg'
import { ReactComponent as IconClose } from "../../../../assets/icons/Close-Circle.svg";

export default function Main({
  title = "Drawer Title",
  className = "",
  isOpen,
  onClose = () => null,
  headerContent,
  children,
  footerContent,
  darkMode,
}) {
  //GENERAL
  const drawerRef = useRef();

  //STATES
  const [isVisible, setIsVisible] = useState(false);

  // CUSTOM HOOKS
  // useOutsideClick(drawerRef, () => {
  //     if (isOpen) {
  //         setIsVisible(false)
  //         setTimeout(() => onClose(), 500)
  //     }
  // })

  //REDUX - Selectors

  //USE EFFECTS
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    }
  }, [isOpen]);

  //FUNCTIONS
  const setCloseDrawer = () => {
    setIsVisible(false);
    setTimeout(() => onClose(), 500);
  };

  if (isOpen) {
    return (
      <div className={`drawer ${isVisible ? "drawer-visible" : ""}`}>
        <div
          className={`drawer__div ${
            isVisible ? "drawer-div-visible" : ""
          } ${className} ${darkMode ? "dark-mode" : ""}`}
          ref={drawerRef}
        >
          <header className="drawer__div__header">
            <div className="drawer__div__header__title">
              {/* <div className="drawer__div__clickable">
                                <ArrowBack onClick={() => setCloseDrawer()} />
                            </div> */}

              <Typography.H2
                color="blue-selected-4"
                weight={700}
                className="color-gray-900 "
              >
                {title}
              </Typography.H2>
              <div onClick={() => setCloseDrawer()}>
                <IconClose />
              </div>
            </div>
            {headerContent ? headerContent : ""}
          </header>

          <div className="drawer__div__content">{children}</div>

          {footerContent ? (
            <footer className="drawer__div__footer">{footerContent}</footer>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else {
    return "";
  }
}
