//GLOBAL - components from npm
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { format, isPast, isAfter, formatDistanceToNow } from "date-fns";
import { cloneDeep, throttle } from "lodash";
import ptBR from "../../../locale/pt-BR";
import TextareaAutosize from "react-textarea-autosize";

// UTILS
import { Toast } from "../../../utils/toast";
import postMessage from "../../../utils/postMessage";
import formatDate from "../../../utils/formatDate";

//STYLES
import "./details.scss";

//COMPONENTS
import { Button, Typography } from "../../../components/general";
import {
  FieldContainer,
  CommentList,
  ImageProfile,
  FilePreview,
  AttachTypeFiles,
  CarouselAttach,
  ColumnAttach,
} from "../../../components/data-display";
import {
  Dropzone,
  DatePicker,
  TagList,
  Delete,
  ProfileList,
  ReminderList,
  Select,
  SelectSearch,
  SendingComment,
} from "../../../components/data-entry";
import { DropdownSelect, DropdownStatus } from "../../../components/layout";
import { Loading, Tippy } from "./../../../components/feedback";
import { PopoverMenu } from "../../../components/navigation";

//SERVICES - api, conectors...
import * as APITask from "../../../services/api/task";
import * as APIComments from "../../../services/api/comments";
import * as APICase from "../../../services/api/case";
import * as APITag from "../../../services/api/tag";
import * as APIFiles from "../../../utils/files";
import * as APIClient from "./../../../services/api/client";

//GLOBAL STATE - redux, env...
import { useSelector, useDispatch } from "react-redux";
import { removeSentItem } from "../../../store/browserComms";

//ASSETS - icons, images...
import { ReactComponent as IconViewUser } from "../../../assets/icons/ViewUser02.svg";
import { ReactComponent as IconPencil } from "../../../assets/icons/Pencil.svg";
import { ReactComponent as IconCancel } from "../../../assets/icons/Cancel.svg";
import { ReactComponent as IconConfirm } from "../../../assets/icons/Confirm.svg";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as IconTags } from "../../../assets/icons/Tags.svg";
import { ReactComponent as IconNotes } from "../../../assets/icons/Notes.svg";
import { ReactComponent as IconMessage } from "../../../assets/icons/Message.svg";
import { ReactComponent as IconMoreVertical } from "../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as IconUser } from "../../../assets/icons/ImgUser.svg";
import { ReactComponent as IconAdd } from "../../../assets/icons/IconAddWithColor.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/Trash-4.svg";
import { ReactComponent as IconSend } from "../../../assets/icons/Send-2.svg";
// import { ReactComponent as IconDoc } from "../../../assets/icons/IconDoc.svg";
// import { ReactComponent as IconPdf } from "../../../assets/icons/IconPdf.svg";
// import { ReactComponent as IconExl } from "../../../assets/icons/IconExl.svg";
// import { ReactComponent as IconGallery } from "../../../assets/icons/Gallery.svg";
// import { ReactComponent as IconDocument } from "../../../assets/icons/Document.svg";
// import { ReactComponent as IconTags } from "../../../assets/icons/Tags.svg";
// import { ReactComponent as IconUndo } from "../../../assets/icons/Undo.svg";
// import { ReactComponent as IconViewUser } from "../../../assets/icons/ViewUser.svg";
// import { ReactComponent as IconAnnounce } from "../../../assets/icons/Announce.svg";
// import { ReactComponent as IconLink } from "../../../assets/icons/Link-desk.svg";
// import { ReactComponent as IconWhatsapp } from "../../../assets/icons/Whatsapp-desk.svg";
// import { ReactComponent as IconFacebook } from "../../../assets/icons/Facebook-desk.svg";
// import { ReactComponent as IconTelegram } from "../../../assets/icons/Telegram-desk.svg";
// import { ReactComponent as IconEmail } from "../../../assets/icons/Email-desk.svg";
// import { ReactComponent as IconInstagram } from "../../../assets/icons/Instagram-desk.svg";
// import { ReactComponent as IconTwitter } from "../../../assets/icons/Twitter-desk.svg";

//import ImageProfile from "../../../assets/images/agent-profile.png";

export default function Details() {
  //GENERAL
  const refSendingComment = useRef();
  const history = useHistory();
  const { id } = useParams();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.userData);
  const filtersContacts = useSelector((state) => state.filters.contacts);
  const location = useLocation();
  // const cameFrom = history.location.state && history.location.state.from;

  // console.debug('history.location.state: ', history.location);

  //STATES
  const [pageStatus, setPageStatus] = useState("loading");
  const [taskDetails, setTaskDetails] = useState();
  const [openDescription, setOpenDescription] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [activeBorderBottom, setActiveBorderBottom] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [valueTitle, setValueTitle] = useState("");
  const [valueDescription, setValueDescription] = useState("");
  const [valueTextComment, setValueTextComment] = useState("");
  const [startDate, setStartDate] = useState();
  const [commentsList, setCommentsList] = useState([]);
  const [commentsFormatted, setCommentsFormatted] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [listCases, setListCases] = useState([]);
  const [statusTask, setStatusTask] = useState();
  const [allProfileList, setAllProfileList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [cameFrom, setCameFrom] = useState();
  const [clientData, setClientData] = useState({});
  const [linkedCase, setLinkedCase] = useState({});
  const [objComment, setObjComment] = useState({});
  const [isRunning, setIsRunning] = useState(0);
  const [percentageProgressBar, setPercentageProgressBar] = useState(0);

  //REDUX - Selectors
  const dispatch = useDispatch();

  //FUNCTIONS
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];

    if (newFile) {
      const updatedFiles = [...fileList, newFile];
      setFileList(updatedFiles);
    }
  };

  const fileRemove = (file) => {
    const updatedFiles = [...fileList];
    updatedFiles.splice(fileList.indexOf(file), 1);

    setFileList(updatedFiles);
  };

  const getTaskInfo = async () => {
    setPageStatus("loading");
    await getListStatus();
    await getCommentList();
    await getDetails();
    await getListCases();
    await getTags();
    await getProfiles();
    setPageStatus("awaiting");
  };

  const getDetails = async () => {
    const response = await APITask.details(id, token);
    const data = response?.data;

    if (!data || data.length === 0) {
      return history.push("/tarefa/nao-encontrada");
    }

    if (data.id_ticket) {
      //#TOIMPROVE: DEIXAR ENQUANTO O BACK NÃO DESASSOCIA A TAREFA NA EXCLUSÃO DO CASO.
      const existingCase = await handleCheckCase(data);

      if (!existingCase) {
        data.id_ticket = null;
        data.codigo_ticket = null;
      }
    }

    setTaskDetails({ ...data });
    setValueDescription(data.descricao_tarefa);
    setValueTitle(data.assunto);
    setStatusTask(data.id_fila);
    setStartDate(new Date(data.prazo_resolucao));
    setClientData({
      id: data.id_contato,
      label: data.nome_contato,
      caption1: data?.nome_empresa,
    });
    setLinkedCase({
      id: data.id_ticket,
      label: data.codigo_ticket,
    });
  };

  async function getListClients(searchParams, page) {
    const response = await APIClient.all(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.nome_empresa,
        caption2: client.codigo_externo,
      };

      updatedData.push(updatedClient);
    });

    return updatedData;
  }

  const getListCases = async () => {
    const response = await APICase.getCasesToTasks(token, "");
    const data = response.data;

    const arrayData = [];
    for (const item of data) {
      const objData = {
        id: item.id_ticket,
        label: item.codigo_ticket,
        option1: item.nome_contato,
        option2: item.nome_empresa,
        option3: format(new Date(item.dt_abertura), "dd/MM/yyyy"),
        option4: item.id_ticket_status,
        option5: item.assunto,
      };
      arrayData.push(objData);
    }

    setListCases(arrayData);
  };

  const handleCheckCase = async (data) => {
    const response = await APICase.getCasesToTasks(token, "");
    const caseList = response.data;
    const linkedTask = caseList.find(
      (item) => item.id_ticket === data.id_ticket
    );

    if (!linkedTask) {
      await postAssociateTaskToCase(null);
      return false;
    } else {
      return true;
    }
  };

  const getListStatus = async () => {
    const response = await APITask.listStatus(token);
    const data = response.data;
    let updatedData = [];

    data.forEach((datum) => {
      let updatedDatum = {};

      updatedDatum.id = datum.id_fila;
      updatedDatum.label = datum.descricao;
      updatedDatum.cor = datum.cor_fila;

      updatedData.push(updatedDatum);
    });

    setStatusList(updatedData);
  };

  const getTags = async () => {
    const response = await APITag.listTags(token);

    const data = response?.data;

    setAllTags(data);
  };

  const getProfiles = async () => {
    const response = await APITask.listUsers(token);

    const data = response?.data;

    setAllProfileList(data);
  };

  const getCommentList = async () => {
    const response = await APIComments.listComments(id, token);
    const data = response?.data;

    const arrayComments = await fetchFile(response.data);

    setCommentsList(arrayComments);
    setObjComment({ ...data });
    setValueTextComment(objComment.desc_tarefa_comentario);
  };

  const postAssociateTaskToCase = async (idCase) => {
    const data = {
      id_task: id,
      id_ticket: idCase,
      id_campo: 8,
    };

    const response = await APITask.associateTaskToCase(data, token);

    if (response.status === 200) {
      return;
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  const postComment = async (text, files, responseComment, audio) => {
    try {
      let idType = 0;

      if (text) {
        idType = 1;
      } else if (audio) {
        idType = 3;
      } else {
        idType = 2;
      }
      const data = {
        desc_tarefa_comentario: text || "",
        id_tarefa: id,
        id_mimetype: 2,
        id_tipo_comentario: idType,
        id_tarefa_comentario_parente: responseComment?.id_comentario || null,
      };

      const form = new FormData();
      form.append("data", JSON.stringify(data));

      for (const file of files) {
        form.append("data", file.file);
      }

      // for (let [key, value] of form) {
      //   console.log(`${key}: ${value}`)
      // }

      const response = await APIComments.createComment(form, token);

      await getCommentList();

      //console.log('response', response);
    } catch (error) {
      console.log(error);
    }
  };

  const updateComment = async (text, files, deleteFiles, comment) => {
    try {
      let idType = 0;
      if (text) {
        idType = 1;
      } else {
        idType = 2;
      }
      const data = {
        desc_tarefa_comentario: text,
        id_tarefa: id,
        id_mimetype: 2,
        id_tipo_comentario: idType,
        id_tarefa_comentario: comment.id_comentario,
        id_tarefa_comentario_parente:
          comment.commentParent.id_comentario || null,
        filesExclused: deleteFiles,
      };

      const form = new FormData();
      form.append("data", JSON.stringify(data));

      for (const file of files) {
        form.append("data", file.file);
      }

      const response = await APIComments.updateComment(form, token);

      await getCommentList();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteComment = async (idComment) => {
    try {
      const response = await APIComments.deleteComment(idComment, token);

      await getCommentList();

      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const putTask = async (task) => {
    task.dt_ultima_atualizacao = format(new Date(), "yyyy-MM-dd");
    task.dt_fechamento = task.dt_fechamento
      ? format(new Date(task.dt_fechamento), "yyyy-MM-dd'T'HH:mm:ss.SSS")
      : task.dt_fechamento;
    task.prazo_inicio = format(
      new Date(task.prazo_inicio),
      "yyyy-MM-dd'T'HH:mm:ss.SSS"
    );
    task.dt_abertura = format(new Date(task.dt_abertura), "yyyy-MM-dd");

    setTaskDetails(task);

    await APITask.taskUpdate(id, task, token);
  };

  const deleteTask = async () => {
    const response = await APITask.deleteTask(id, user.userId, token);

    if (response.status === 200) {
      Toast("Tarefa Deletada!", "Tarefa deletada com sucesso.", "success");
      history.push("/tarefas");
    }
  };

  const fetchFile = async (arrayComments) => {
    for (const comment of arrayComments) {
      const files = [];
      if (comment.filesAssociated.length > 0) {
        for (const file of comment.filesAssociated) {
          const response = await APIFiles.handleFiles(
            file.path_tarefas_arquivos
          );
          const fileObject = {
            file: {
              name: response.name,
              size: response.size,
              type: response.typeMime,
              path_arquivos: file.path_tarefas_arquivos,
              extension: response.type,
              id: file.id_tarefa_comentario_arquivo,
            },
          };

          files.push(fileObject);
        }
      }
      comment.files = files;
    }
    return arrayComments;
  };

  const onScroll = useMemo(() => {
    const throttled = throttle(
      (e) => setActiveBorderBottom(e.target.scrollTop > 5),
      300
    );

    return (e) => {
      e.persist();

      return throttled(e);
    };
  }, []);

  // function renderImage(typeFile) {
  //   if (
  //     typeFile ===
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //   ) {
  //     return <img src={ImageConfig['exl']} alt="" />
  //   } else {
  //     return <img
  //       style={{ width: "32px", height: "32px" }}
  //       src={
  //         ImageConfig[typeFile.split('/')[1]] ||
  //         ImageConfig['default']
  //       }
  //       alt=""
  //     />
  //   }
  // };

  //USE EFFECTS
  useEffect(() => {
    if (history.location.state) setCameFrom(history.location.state.from);
  }, [history.location.state]);

  useEffect(() => {
    getTaskInfo();
  }, []);

  useEffect(() => {
    if (startDate) {
      const task = { ...taskDetails };
      const dateFormated = format(
        new Date(startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSS"
      );

      task.prazo_resolucao = dateFormated;
      putTask(task);
    }
  }, [startDate]);

  useEffect(() => {
    const arrayComment = cloneDeep(commentsList);
    const newArray = [];

    for (const item of arrayComment) {
      const newObject = {
        id_comentario: item.id_tarefa_comentario,
        desc_comentario: item.desc_tarefa_comentario,
        data_insercao: item.data_insercao,
        data_exclusao: item.data_exclusao,
        id: item.id_tarefa,
        path_comentarios: item.path_tarefas_comentarios,
        id_mimetype: item.id_mimetype,
        id_tipo_comentario: item.id_tipo_comentario,
        usuario_insercao: item.usuario_insercao,
        nome_usuario: item.nome_usuario,
        // id_comentario_parente: item.id_tarefa_comentario_parente,
        // texto_parente: item.texto_parente,
        // nome_parente: item.nome_parente,
        filesAssociated: [],
        files: item.files,
        commentParent: {
          id_comentario: item.commentParent[0]?.id_tarefa_comentario,
          desc_comentario: item.commentParent[0]?.desc_tarefa_comentario,
          id_tipo_comentario: item.commentParent[0]?.id_tipo_comentario,
          usuario_insercao: item.commentParent[0]?.usuario_insercao,
          nome_usuario: item.commentParent[0]?.nome_usuario,
          filesAssociated: [],
        },
      };

      if (item.commentParent[0]?.filesAssociated) {
        for (const subitem of item.commentParent[0].filesAssociated) {
          const object = {
            id_comentario_arquivo: subitem.id_tarefa_comentario_arquivo,
            path_arquivos: subitem.path_tarefas_arquivos,
            data_insercao: subitem.data_insercao,
            data_exclusao: subitem.data_exclusao,
            id_mimetype: subitem.id_mimetype,
            id_comentario: subitem.id_tarefa_comentario,
          };
          newObject.commentParent.filesAssociated.push(object);
        }
      }

      for (const subitem of item.filesAssociated) {
        const object = {
          id_comentario_arquivo: subitem.id_tarefa_comentario_arquivo,
          path_arquivos: subitem.path_tarefas_arquivos,
          data_insercao: subitem.data_insercao,
          data_exclusao: subitem.data_exclusao,
          id_mimetype: subitem.id_mimetype,
          id_comentario: subitem.id_tarefa_comentario,
        };
        newObject.filesAssociated.push(object);
      }
      newArray.push(newObject);
    }

    setCommentsFormatted(newArray);
  }, [commentsList]);

  // useEffect(() => {
  //   if (percentageProgressBar < 100 && isRunning) {
  //     setTimeout(() => setPercentageProgressBar(prev => prev += 2), 50)
  //   }
  // }, [percentageProgressBar, isRunning]);

  // useEffect(() => {
  //   setOrderComments(commentsFormatted)
  // }, [commentsFormatted])

  return (
    <>
      {pageStatus === "awaiting" ? (
        <div
          className={`task-details ${
            location.pathname === `/tarefas/${id}` ? "tasks-details-modify" : ""
          }`}
        >
          <header className="task-details__header">
            <div className="task-details-header__title">
              <IconArrowLeft
                onClick={() => history.push(cameFrom ? cameFrom : "/tarefas")}
              />

              <div className="task-details-header__title__text">
                {openTitle ? (
                  <>
                    <input
                      value={valueTitle}
                      onChange={(e) => setValueTitle(e.target.value)}
                      style={{ width: `${valueTitle.length + 1}ch` }}
                    />
                    <div className="task-details-header__title__text__buttons">
                      <button
                        onClick={() => {
                          setOpenTitle(false);
                          if (!valueTitle || !valueTitle.trim()) {
                            setValueTitle(taskDetails.assunto);
                            return Toast(
                              "Erro ao editar título!",
                              "Por favor, digite um título para poder atualizar.",
                              "error"
                            );
                          }
                          const task = { ...taskDetails };
                          task.assunto = valueTitle;
                          putTask(task);
                        }}
                      >
                        <IconConfirm />
                      </button>
                      <button
                        onClick={() => {
                          setValueTitle(taskDetails.assunto);
                          setOpenTitle(false);
                        }}
                      >
                        <IconCancel />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <Tippy content={valueTitle}>
                      <div className="task-details__title-task">
                        <Typography.H2 color="gray-500" weight="bold">
                          {valueTitle}
                        </Typography.H2>
                      </div>
                    </Tippy>
                    <IconPencil onClick={() => setOpenTitle(true)} />
                  </>
                )}
              </div>
            </div>
            <div className="task-details-header__options">
              {/* <DropdownStatus
                width={"330px"}
                value={statusTask}
                options={statusList}
                onClick={(option) => {
                  setStatusTask(option.id);
                  const task = { ...taskDetails };
                  task.id_fila = option.id;

                  if (option.id === 4) {
                    task.dt_fechamento = formatDate(new Date());
                  } else {
                    task.dt_fechamento = null;
                  }

                  putTask(task);
                }}
                commentIdStatus={4}
                onAdd={(text, files, response, audio) =>
                  postComment(text, files, response, audio)
                }
              /> */}
              <div className="task-details-header__profile">
                {/* <IconViewUser />
                <ProfileList
                  buttonTooltip
                  idTask={id}
                  profiles={taskDetails?.usersAssociated}
                  allProfileList={allProfileList}
                /> */}
              </div>
              {(user.permissionId === 2 ||
                user.userId === taskDetails.id_solicitante) && (
                <Delete
                  onDelete={deleteTask}
                  title="Tem certeza que deseja remover esta tarefa?"
                />
              )}
            </div>
          </header>

          {/* =========== ##ESTRUTURA / LAYOUT NOVO## =========== */}
          <main className="task-details__body">
            <section
              className={`task-details__body__client ${
                activeBorderBottom ? "margin-top-auto" : ""
              }`}
              onScroll={onScroll}
            >
              <div className="task-details__body-description">
                <div className="task-details__title">
                  <Typography.H6 color="gray-500">
                    Descrição da Tarefa
                  </Typography.H6>
                  {openDescription ? (
                    <div className="task-details__title__icons">
                      <button
                        onClick={() => {
                          setOpenDescription(false);
                          if (!valueDescription || !valueDescription.trim()) {
                            setValueDescription(taskDetails.descricao_tarefa);
                            return Toast(
                              "Erro ao editar descrição!",
                              "Por favor, digite uma descrição para poder atualizar.",
                              "error"
                            );
                          }
                          const task = { ...taskDetails };
                          task.descricao_tarefa = valueDescription;
                          putTask(task);
                        }}
                      >
                        <IconConfirm />
                      </button>
                      <button
                        onClick={() => {
                          setValueDescription(taskDetails.descricao_tarefa);
                          setOpenDescription(false);
                        }}
                      >
                        <IconCancel />
                      </button>
                    </div>
                  ) : (
                    <div className="task-details__title__icons">
                      <button onClick={() => setOpenDescription(true)}>
                        <IconPencil />
                      </button>
                    </div>
                  )}
                </div>
                <div className="task-details-description">
                  {openDescription ? (
                    <>
                      <TextareaAutosize
                        maxRows={6}
                        value={valueDescription}
                        onChange={(e) => setValueDescription(e.target.value)}
                        className="task-details-description__textarea"
                      />

                      {/* <textarea
                        value={valueDescription}
                        onChange={(e) => setValueDescription(e.target.value)}
                      /> */}
                    </>
                  ) : (
                    <>
                      <TextareaAutosize
                        disabled
                        maxRows={6}
                        value={valueDescription}
                        className="task-details-description__textarea"
                      />

                      {/* <textarea
                        value={valueDescription}
                        disabled
                      /> */}
                    </>
                  )}
                </div>
              </div>

              <div className="task-details__body__client__details-content">
                {/* =================== ANEXOS =================== */}
                {/* <div className="container-attachments"> //* CRIAR COMPONENTE SEPARADO
                  <div className="task-details__body__title">
                    <Typography.H5 color="blue-selected-4" weight="bold">
                      Anexos
                    </Typography.H5>
                  </div>

                  <div className="container-attachments__list">
                    {fileList.length > 0 ? (
                      <>
                        {fileList.map((item, index) => {
                          return (
                            <div className="attachment" key={index}>
                              {renderImage(item.type)}

                              <div className="attachment__progress-bar">
                                <div className="attachment__progress-bar__header">
                                  <div className="file-information">
                                    <div className="file-information__name-archive">
                                      <Typography.H5
                                        color="gray-600"
                                        weight="bold"
                                      >
                                        <Tippy content={item.name}>
                                          <div className="file-information__name-archive__text">
                                            {item.name}
                                          </div>
                                        </Tippy>
                                      </Typography.H5>
                                    </div>

                                    <Typography.H6 color="gray-180">
                                      {
                                        item.size <= 1000000 ?
                                          (item.size / 1000).toFixed(2) + 'kb' :
                                          (item.size / 1000 / 1024).toFixed(2) + 'mb'
                                      }
                                    </Typography.H6>
                                  </div>

                                  {isRunning === true ? (
                                    <>
                                      <Typography.H6 color="gray-180">
                                        {percentageProgressBar}%
                                      </Typography.H6>
                                    </>
                                  ) : (
                                    <button
                                      className="button-download"
                                      onClick={() => {
                                        setIsRunning(true);
                                      }}
                                    >
                                      Download
                                    </button>
                                  )}
                                </div>

                                <div className="progress">
                                  {
                                    isRunning === true ?
                                      <div
                                        style={{
                                          width: `${percentageProgressBar}%`,
                                          height: "4px",
                                          backgroundColor: "#455cc7",
                                          borderRadius: "100px"
                                        }}
                                        className="line"></div> :
                                      null
                                  }
                                </div>
                              </div>

                              <IconTrash
                                className="delete-archive"
                                onClick={() => fileRemove(item)}
                              />
                            </div>
                          )
                        })}
                      </>
                    ) : null}
                  </div>

                  <div className="container-attachments__add-archive">
                    <IconAdd />

                    <label
                      htmlFor="input-file-large"
                      className="container-attachments__add-archive__text"
                    >
                      <Typography.H5 color="gray-550">
                        Adicionar arquivo
                      </Typography.H5>
                    </label>

                    <input
                      type="file"
                      id="input-file-large"
                      multiple
                      onChange={onFileDrop}
                    />

                  </div>
                </div> */}

                {/* =================== COMENTÁRIOS =================== */}
                <CommentList
                  commentType={2}
                  commentsList={commentsFormatted}
                  activeBorderBottom={activeBorderBottom}
                  onAdd={(text, files, response, audio) =>
                    postComment(text, files, response, audio)
                  }
                  onUpdate={(text, files, deleteFiles, comment) =>
                    updateComment(text, files, deleteFiles, comment)
                  }
                  onDelete={(id) => deleteComment(id)}
                />
              </div>
            </section>

            <section className="task-details-panel">
              <div className="task-details-panel__panel">
                <FieldContainer
                  title="Responsáveis pela tarefa"
                  titleSize="small"
                  className="task-details-profile-list"
                >
                  <div className="task-details-header__profile">
                    <IconViewUser />
                    <ProfileList
                      buttonTooltip
                      idTask={id}
                      profiles={taskDetails?.usersAssociated}
                      allProfileList={allProfileList}
                    />
                  </div>
                </FieldContainer>

                <FieldContainer
                  title="Status"
                  titleSize="small"
                  className="task-details-status"
                >
                  <DropdownStatus
                    searchStatus
                    isNewStatusList
                    maxHeight="200px"
                    value={statusTask}
                    newStatusListOptions={statusList}
                    onClick={(option) => {
                      setStatusTask(option.id);
                      const task = { ...taskDetails };
                      task.id_fila = option.id;

                      if (option.id === 4) {
                        task.dt_fechamento = formatDate(new Date());
                      } else {
                        task.dt_fechamento = null;
                      }

                      putTask(task);
                    }}
                    commentIdStatus={4}
                    onAdd={(text, files, response, audio) =>
                      postComment(text, files, response, audio)
                    }
                    screen="task"
                  />
                </FieldContainer>

                <FieldContainer
                  title="Contato"
                  titleSize="small"
                  className="task-details-client__name"
                  htmlFor=""
                >
                  <SelectSearch
                    paginated
                    width="100%"
                    placeholder="Busque pelo nome do contato"
                    getOptions={getListClients}
                    value={clientData}
                    onSelect={(client) => {
                      setClientData(client);
                      const objCase = { ...taskDetails };
                      objCase.id_contato = client.id;
                      objCase.nome_contato = client.label;
                      objCase.nome_empresa = client.caption1;

                      setTaskDetails(objCase);
                      putTask(objCase);
                    }}
                  />
                </FieldContainer>

                <FieldContainer
                  title="Vincular a um caso existente"
                  htmlFor=""
                  titleSize="small"
                  className="task-details-case-vinculation"
                >
                  <SelectSearch
                    placeholder="Número do Caso"
                    value={linkedCase}
                    tooltip={true}
                    options={listCases}
                    showAvatar={false}
                    onSelect={(option) => {
                      setLinkedCase(option);
                      const objTask = { ...taskDetails };
                      objTask.id_ticket = option.id;
                      setTaskDetails(objTask);
                      postAssociateTaskToCase(option.id);
                    }}
                  />
                </FieldContainer>

                <FieldContainer
                  title="Empresa"
                  titleSize="small"
                  className="task-details-client__company"
                >
                  {taskDetails?.nome_empresa ? (
                    <Typography.H5 color="gray-300">
                      {taskDetails.nome_empresa}
                    </Typography.H5>
                  ) : (
                    <Typography.H5 color="gray-175">Sem Empresa</Typography.H5>
                  )}
                </FieldContainer>

                <FieldContainer
                  title="Prazo"
                  htmlFor=""
                  titleSize="small"
                  className="task-details-date"
                >
                  <div className={`task-details-client-date-container `}>
                    {/* <DatePicker.TimeRangeInput
                      valueTime={startDate}
                      widthTotal={216}
                      errorTime={
                        isPast(new Date(startDate))
                          ? taskDetails.id_fila === 4 ? isAfter(
                            new Date(taskDetails.dt_fechamento),
                            new Date(startDate)
                          ) ? true : false : true : false
                      }
                      setValueTime={(data) => {
                        if (isPast(startDate)) {
                          const oldTaskOverdue = {
                            author: "IngDesk",
                            mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                              0,
                              20
                            )}${taskDetails.assunto.length > 20 ? "..." : ""
                              } - ${format(
                                new Date(taskDetails.prazo_resolucao),
                                "dd'/'MM'/'y"
                              )} às ${format(
                                new Date(taskDetails.prazo_resolucao),
                                "HH':'mm'h'"
                              )}`,
                            display: "taskOverdue",
                            idTask: parseInt(id),
                            mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                              0,
                              20
                            )}${taskDetails.assunto.length > 20 ? "..." : ""
                              } - ${format(
                                new Date(taskDetails.prazo_resolucao),
                                "dd'/'MM'/'y"
                              )} às ${format(
                                new Date(taskDetails.prazo_resolucao),
                                "HH':'mm'h'"
                              )}`,
                          };

                          // send message to delete the notification
                          postMessage(
                            "tasksOverdueDeleted",
                            [oldTaskOverdue],
                            true
                          );
                        }
                        setStartDate(data);
                      }}
                    /> */}

                    <DatePicker.DateRange
                      widthSize="280px"
                      border="1px solid #bfc3ca"
                      selectRangeType="start"
                      error={
                        isPast(new Date(startDate))
                          ? taskDetails.id_fila === 4
                            ? isAfter(
                                new Date(taskDetails.dt_fechamento),
                                new Date(startDate)
                              )
                              ? true
                              : false
                            : true
                          : false
                      }
                      selected={startDate}
                      setDate={(data) => {
                        // if old date is past (have notification)
                        if (isPast(startDate)) {
                          const oldTaskOverdue = {
                            author: "IngDesk",
                            mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                              0,
                              20
                            )}${
                              taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                            display: "taskOverdue",
                            idTask: parseInt(id),
                            mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                              0,
                              20
                            )}${
                              taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                          };

                          // send message to delete the notification
                          postMessage(
                            "tasksOverdueDeleted",
                            [oldTaskOverdue],
                            true
                          );
                        }
                        setStartDate(data);
                      }}
                      dateFormat="dd 'de' MMMM', 'u"
                      className="date-details"
                    />
                    <DatePicker.TimeRange
                      border="1px solid #bfc3ca"
                      error={
                        isPast(new Date(startDate))
                          ? taskDetails.id_fila === 4
                            ? isAfter(
                                new Date(taskDetails.dt_fechamento),
                                new Date(startDate)
                              )
                              ? true
                              : false
                            : true
                          : false
                      }
                      selected={startDate}
                      setDate={(data) => {
                        // if old date is past (have notification)
                        if (isPast(startDate)) {
                          const oldTaskOverdue = {
                            author: "IngDesk",
                            mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                              0,
                              20
                            )}${
                              taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                            display: "taskOverdue",
                            idTask: parseInt(id),
                            mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                              0,
                              20
                            )}${
                              taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                          };
                          // send message to delete the notification
                          postMessage("tasksOverdueDeleted", [oldTaskOverdue]);
                        }

                        setStartDate(data);
                      }}
                      dateFormat="HH:mm"
                      placeholderText="Hora"
                    />
                  </div>
                </FieldContainer>

                <FieldContainer
                  title="Lembrete"
                  titleSize="small"
                  className="task-details-remider"
                  htmlFor=""
                >
                  <div className="field-container-reminder__container-list">
                    {taskDetails?.remindersAssociated.map(
                      (reminder, reminderKey) => {
                        return (
                          <div
                            className="reminder-item"
                            key={reminderKey}
                          ></div>
                        );
                      }
                    )}
                  </div>

                  <ReminderList idTask={id} marginTop="10px" />
                </FieldContainer>

                <FieldContainer
                  title="Tags / Categorias"
                  titleSize="small"
                  className="task-details-tags-categories"
                >
                  <div className="task-details-tag">
                    <IconTags />
                    <TagList
                      tagLimit={2}
                      tags={taskDetails?.tagsAssociated}
                      idTask={id}
                      buttonTooltip
                      getTags={getTags}
                      allTags={allTags}
                    />
                  </div>
                </FieldContainer>
              </div>
            </section>
          </main>

          {/* =========== ESTRUTURA / LAYOUT ANTIGO =========== */}
          {/* <main className="task-details__main"> */}
          {/* <div className="task-details-client">
              <FieldContainer
                title="Contato:"
                className="task-details-client__name"
                htmlFor=""
              >
                <SelectSearch
                  paginated
                  // width="275px"
                  placeholder="Busque pelo nome do contato"
                  getOptions={getListClients}
                  value={clientData}
                  onSelect={(client) => {
                    setClientData(client);
                    const objCase = { ...taskDetails };
                    objCase.id_contato = client.id;
                    objCase.nome_contato = client.label;
                    objCase.nome_empresa = client.caption1;

                    setTaskDetails(objCase);
                    putTask(objCase);
                  }}
                />
              </FieldContainer>
              <FieldContainer
                title="Empresa:"
                className="task-details-client__company"
              >
                {taskDetails?.nome_empresa ? (
                  <Typography.H5 color="gray-300">
                    {taskDetails.nome_empresa}
                  </Typography.H5>
                ) : (
                  <Typography.H5 color="gray-175">Sem Empresa</Typography.H5>
                )}
              </FieldContainer>
              <FieldContainer title="Vincular a um caso existente:" htmlFor="">
                <SelectSearch
                  // width="180px"
                  placeholder="Número do Caso"
                  value={linkedCase}
                  tooltip={true}
                  options={listCases}
                  showAvatar={false}
                  onSelect={(option) => {
                    setLinkedCase(option);
                    const objTask = { ...taskDetails };
                    objTask.id_ticket = option.id;
                    setTaskDetails(objTask);
                    postAssociateTaskToCase(option.id);
                  }}
                />
              </FieldContainer>
            </div> */}

          {/* <div className="task-details-deadline">
              <FieldContainer title="Prazo de entrega:" htmlFor="">
                <div className={`task-details-client-date-container `}>
                  <DatePicker.DateRange
                    selectRangeType="start"
                    error={
                      isPast(new Date(startDate))
                        ? taskDetails.id_fila === 4
                          ? isAfter(
                            new Date(taskDetails.dt_fechamento),
                            new Date(startDate)
                          )
                            ? true
                            : false
                          : true
                        : false
                    }
                    selected={startDate}
                    setDate={(data) => {
                      // if old date is past (have notification)
                      if (isPast(startDate)) {
                        const oldTaskOverdue = {
                          author: "IngDesk",
                          mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                            0,
                            20
                          )}${taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                          display: "taskOverdue",
                          idTask: parseInt(id),
                          mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                            0,
                            20
                          )}${taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                        };

                        // send message to delete the notification
                        postMessage(
                          "tasksOverdueDeleted",
                          [oldTaskOverdue],
                          true
                        );
                      }
                      setStartDate(data);
                    }}
                    dateFormat="dd 'de' MMMM', 'u"
                    className="date-details"
                  />
                  <DatePicker.TimeRange
                    error={
                      isPast(new Date(startDate))
                        ? taskDetails.id_fila === 4
                          ? isAfter(
                            new Date(taskDetails.dt_fechamento),
                            new Date(startDate)
                          )
                            ? true
                            : false
                          : true
                        : false
                    }
                    selected={startDate}
                    setDate={(data) => {
                      // if old date is past (have notification)
                      if (isPast(startDate)) {
                        const oldTaskOverdue = {
                          author: "IngDesk",
                          mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                            0,
                            20
                          )}${taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                          display: "taskOverdue",
                          idTask: parseInt(id),
                          mensagem: `Tarefa Atrasada: ${taskDetails.assunto.substring(
                            0,
                            20
                          )}${taskDetails.assunto.length > 20 ? "..." : ""
                            } - ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "dd'/'MM'/'y"
                            )} às ${format(
                              new Date(taskDetails.prazo_resolucao),
                              "HH':'mm'h'"
                            )}`,
                        };
                        // send message to delete the notification
                        postMessage("tasksOverdueDeleted", [oldTaskOverdue]);
                      }

                      setStartDate(data);
                    }}
                    dateFormat="HH:mm"
                  />
                </div>
              </FieldContainer>
              <ReminderList idTask={id} />
            </div> */}

          {/* <div className="task-details-communication"> //* NÃO UTILIZAR
              <div className="task-details__title">
                <IconAnnounce />
                <Typography.H5 color="gray-500" weight="bold">
                  Seleciona o canal de comunicação
                </Typography.H5>
              </div>
              <div className="task-details-communication__list">
                <IconLink />
                <IconWhatsapp />
                <IconEmail />
                <IconTelegram />
                <IconInstagram />
                <IconTwitter />
                <IconFacebook />
              </div>
            </div> */}

          {/* <div className="task-details-tag">
              <div className="task-details__title">
                <IconTags />
                <Typography.H5 color="gray-500" weight="bold">
                  Tags
                </Typography.H5>
              </div>
              <TagList
                tagLimit={5}
                tags={taskDetails?.tagsAssociated}
                idTask={id}
                getTags={getTags}
                allTags={allTags}
                buttonTooltip
              />
            </div> */}

          {/* <div className="task-details__main-description">
              <div className="task-details__title">
                <IconNotes />
                <Typography.H5 color="gray-500" weight="bold">
                  Descrição da Tarefa
                </Typography.H5>
                {openDescription ? (
                  <div className="task-details__title__icons">
                    <button
                      onClick={() => {
                        setOpenDescription(false);
                        if (!valueDescription || !valueDescription.trim()) {
                          setValueDescription(taskDetails.descricao_tarefa);
                          return Toast(
                            "Erro ao editar descrição!",
                            "Por favor, digite uma descrição para poder atualizar.",
                            "error"
                          );
                        }
                        const task = { ...taskDetails };
                        task.descricao_tarefa = valueDescription;
                        putTask(task);
                      }}
                    >
                      <IconConfirm />
                    </button>
                    <button
                      onClick={() => {
                        setValueDescription(taskDetails.descricao_tarefa);
                        setOpenDescription(false);
                      }}
                    >
                      <IconCancel />
                    </button>
                  </div>
                ) : (
                  <div className="task-details__title__icons">
                    <button onClick={() => setOpenDescription(true)}>
                      <IconPencil />
                    </button>
                  </div>
                )}
              </div>
              <div className="task-details-description">
                {openDescription ? (
                  <>
                    <textarea
                      value={valueDescription}
                      onChange={(e) => setValueDescription(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <textarea value={valueDescription} disabled />
                  </>
                )}
              </div>
            </div> */}

          {/* <div className="task-details-comment">
              <div className="task-details__title">
                <IconMessage />
                <Typography.H5 color="gray-500" weight="bold">
                  Comentários
                </Typography.H5>
              </div>
              <CommentList
                commentsList={commentsFormatted}
                onAdd={(text, files, response, audio) =>
                  postComment(text, files, response, audio)
                }
                onUpdate={(text, files, deleteFiles, comment) =>
                  updateComment(text, files, deleteFiles, comment)
                }
                onDelete={(id) => deleteComment(id)}
              />
            </div> */}

          {/* <div className="task-details-files"> //* NÃO UTILIZAR
              <div className="task-details__title">
                <IconTags />
                <Typography.H5 color="gray-500" weight="bold">
                  Anexos
                </Typography.H5>
              </div>
              <div className="input-file">
                <Dropzone large />
              </div>
            </div> */}

          {/* </main> */}
        </div>
      ) : (
        <Loading.Paperplane fullPage className="intern-page" />
      )}
    </>
  );
}
