import instance from "./configAxios";

export async function getListOfShiftWorkers(token, shiftWorkerId) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = { // para buscar um plantonista
            id_plantonista: shiftWorkerId
        }

        const response = await instance.post('/buscar-plantonista', data, config);

        return response;
    } catch (error) {
        console.log('getListOfShiftWorkers -> Error en el servidor:', error);

        return error
    }
};

export async function createShiftWorker(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_usuario, nome_plantonista, telefone_plantonista, email_plantonista, id_cargo, id_organizacao

        const response = await instance.post('/insert-plantonista', data, config);

        return response;
    } catch (error) {
        console.log('createShiftWorker -> Error en el servidor:', error);

        return error
    }
};

export async function updateShiftWorker(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_plantonista, nome_plantonista, telefone_plantonista, email_plantonista, id_cargo, id_organizacao

        const response = await instance.put('/update-plantonista', data, config);

        return response;
    } catch (error) {
        console.log('updateShiftWorker -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteShiftWorker(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_usuario, id_plantonista

        const response = await instance.put('/delete-plantonista', data, config);
        // const response = await instance.delete(`/delete-plantonista/${userId}/${shiftWorkerId}`, config);

        return response;
    } catch (error) {
        return error;
    }
};