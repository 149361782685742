//GLOBAL - components from npm
import React, { useState, useEffect } from "react";

//STYLES
import "./menu-tabs.scss";

//COMPONENTS
import { Typography } from "../../../components/general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/ArrowRight.svg";

export default function MenuTabs({
  //   tabs = [{ id: 1, title: "Tarefas", content: <div>teste</div> },{ id: 2, title: "Comentários", content: <div>teste 2</div> },{ id: 2, title: "Comentários", content: <div>teste 2</div> },{ id: 2, title: "Comentários", content: <div>teste 2</div> }],
  tabs,
  hide,
  selected,
  content,
  limit = 3,
  informationElements,
  variableElements,
  variableValues,
  setTab,
}) {
  //GENERAL

  //STATES
  const [currentTabs, setCurrentTabs] = useState(
    hide ? tabs.filter((tab) => tab.id !== hide) : tabs
  );
  const [active, setActive] = useState(selected);
  // const [active, setActive] = useState(selected || tabs[0].id);
  const [value, setValue] = useState(0);

  //REDUX - Selectors

  //FUNCTIONS
  const contentTab = () => {
    const page = currentTabs.find((tab) => {
      if (active) {
        return tab.id === active;
      } else {
        setActive(tab.id);
        return tab;
      }
    });
    if (!page) {
      setActive(currentTabs[0].id);
      return currentTabs[0].content;
    }
    return page.content;
  };

  const decreaseValue = () => {
    if (value > 0) {
      return setValue((value) => value - 1);
    } else {
      return;
    }
  };

  const increaseValue = () => {
    if (value + limit < currentTabs.length) {
      setValue((value) => value + 1);
    } else {
      return;
    }
  };

  //USE EFFECTS
  useEffect(() => {
    setCurrentTabs(hide ? tabs.filter((tab) => tab.id !== hide) : tabs);
  }, [hide, tabs]);

  return (
    <>
      {currentTabs ? (
        <>
          <nav className="menu-tabs">
            <ul>
              {/* <IconArrowLeft
                className={value > 0 ? "menu-tabs__icon" : ""}
                onClick={() => decreaseValue()}
              /> */}
              <div className="menu-tabs-list">
                {currentTabs.map((tab, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setActive(tab.id);
                      setTab(parseInt(tab.id));
                    }}
                    className={`menu-tabs__item  ${
                      active === tab.id ? "active" : ""
                    }`}
                  >
                    {tab.title.icon && (
                      <div className={tab.title.type}>{tab.title.icon}</div>
                    )}
                    <Typography.Hyperlink
                      weight={`${active === tab.id ? "700" : "400"}`}
                      color="gray-180"
                      className={"menu-tabs__hyperlink"}
                    >
                      {tab.title.text}
                    </Typography.Hyperlink>
                  </li>
                ))}
              </div>
              {/* <IconArrowRight
                className={value + limit < currentTabs.length ? "menu-tabs__icon" : ""}
                onClick={() => increaseValue()}
              /> */}
            </ul>
          </nav>
          {contentTab()}
        </>
      ) : (
        <>{content}</>
      )}
    </>
  );
}
