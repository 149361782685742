//GLOBAL - components from npm
import React from 'react';

//STYLES
import './file-preview.scss'

//COMPONENTS
import { Typography } from "../../../components/general";
import { Tippy } from '../../../components/feedback';
import { Audio } from '../../../components/data-entry';


//SERVICES - api, conectors...
import urlIngWeb from '../../../utils/env'


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconTrash } from '../../../assets/icons/Trash.svg'
import { ReactComponent as IconFileVideo } from '../../../assets/icons/FileVideo.svg'
import { ReactComponent as IconFileDocument } from '../../../assets/icons/FileDocument.svg'
import { ReactComponent as IconFileImage } from '../../../assets/icons/FileImage.svg'
import { ReactComponent as IconFileAudio } from '../../../assets/icons/FileAudio.svg'
import { ReactComponent as IconFile } from '../../../assets/icons/File.svg'


export default function FilePreview({type, name, size, onDelete, user = 'Courtney Henry', link, view, preview, download}) {
    //GENERAL
    let fileSplit = name.split('.')
    let fileExtension = fileSplit[fileSplit.length - 1]
    
    //STATES
    
    
    //REDUX - Selectors

    
    //FUNCTIONS
    const onDownload = () => {
        fetch(`${urlIngWeb + link}`).then((response) => {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = name;
            a.click();
          });
        });
    };

    
    //USE EFFECTS
    
    
    switch (type) {
        case ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'):
        case ('application/vnd.ms-excel.addin.macroEnabled.12'):
        case ('application/vnd.ms-excel.sheet.macroEnabled.12'):
        case ('application/vnd.ms-excel.template.macroEnabled.12'):
        case ('application/vnd.openxmlformats-officedocument.spreadsheetml.template'):
        case ('application/vnd.openxmlformats-officedocument.wordprocessingml.document'):
        case ('application/vnd.openxmlformats-officedocument.presentationml.presentation'):
        case ('application/pdf'):
        case ('text/plain'):
        case ('application/msword'):
        case ('application/x-zip-compressed'):

            return (
                <div className={`file-preview ${download ? 'file-preview-download' : ''}`} onClick={() => download ? onDownload() : null}>
                    <div className="file-preview__icon">
                        <IconFileDocument/>
                        <div className="file-preview__icon__type file-preview__icon__type--blue">{fileExtension}</div>
                    </div>
                    <div className="file-preview__name">
                        <Tippy content={name}>
                            <div>
                                <Typography.H5 color="gray-300" weight="bold">{name}</Typography.H5>
                            </div>
                        </Tippy>
                        <Typography.H6 color="gray-200">{user}</Typography.H6>
                    </div>
                    {size && <div className="file-preview__size"><Typography.H6 color="gray-300">{size <= 1000000 ? (size / 1000).toFixed(2) + ' kb' : (size / 1000 / 1024).toFixed(2) + ' mb'}</Typography.H6></div>}
                    {/* <div className="file-preview__download">
                    <Typography.Hyperlink color="primary-dark" weight="500"><a href={`${urlIngWeb}${link}`} target="_blank" download="nome">Download</a></Typography.Hyperlink>
                        {!view && <div className="file-preview__delete" onClick={() => onDelete()}><IconTrash/></div>}
                    </div> */}
                </div>
            )

        
        case ('audio/mpeg'):
        case ('audio/mp3'):
        case ('audio/ogg'):
        case ('audio/wav'):
        case ('audio/aac'):

            return (
                <>
                {preview ? 
                    <div className={`file-preview-audio`}>
                        <Audio
                            link={link}
                        />
                    </div>
                :
                    <div className={`file-preview ${download ? 'file-preview-download' : ''}`} onClick={() => download ? onDownload() : null}>
                        <div className="file-preview__icon">
                            <IconFileAudio/>
                            <div className="file-preview__icon__type  file-preview__icon__type--yellow">{fileExtension}</div>
                        </div>
                        <div className="file-preview__name">
                            <Tippy content={name}>
                                <div>
                                    <Typography.H5 color="gray-300" weight="bold">{name}</Typography.H5>
                                </div>
                            </Tippy>
                            <Typography.H6 color="gray-200">{user}</Typography.H6>
                        </div>
                        {size && <div className="file-preview__size"><Typography.H6 color="gray-300">{size <= 1000000 ? (size / 1000).toFixed(2) + ' kb' : (size / 1000 / 1024).toFixed(2) + ' mb'}</Typography.H6></div>}
                        {/* <div className="file-preview__download">
                        <Typography.Hyperlink color="primary-dark" weight="500"><a href={`${urlIngWeb}${link}`} target="_blank" download>Download</a></Typography.Hyperlink>
                            {!view && <div className="file-preview__delete" onClick={() => onDelete()}><IconTrash/></div>}
                        </div> */}
                    </div>
                }
                </>
            )

        case ('video/mp4'):
        case ('video/webm'):

            return (
                <div className={`file-preview ${download ? 'file-preview-download' : ''}`} onClick={() => download ? onDownload() : null}>
                    <div className="file-preview__icon">
                        <IconFileVideo/>
                        <div className="file-preview__icon__type  file-preview__icon__type--red">{fileExtension}</div>
                    </div>
                    <div className="file-preview__name">
                        <Tippy content={name}>
                            <div>
                                <Typography.H5 color="gray-300" weight="bold">{name}</Typography.H5>
                            </div>
                        </Tippy>
                        <Typography.H6 color="gray-200">{user}</Typography.H6>
                    </div>
                    {size && <div className="file-preview__size"><Typography.H6 color="gray-300">{size <= 1000000 ? (size / 1000).toFixed(2) + ' kb' : (size / 1000 / 1024).toFixed(2) + ' mb'}</Typography.H6></div>}
                    {/* <div className="file-preview__download">
                    <Typography.Hyperlink color="primary-dark" weight="500"><a href={`${urlIngWeb}${link}`} target="_blank" download>Download</a></Typography.Hyperlink>
                        {!view && <div className="file-preview__delete" onClick={() => onDelete()}><IconTrash/></div>}
                    </div> */}
                </div>
            )

        case ('image/jpeg'):
        case ('image/gif'):
        case ('image/jpg'):
        case ('image/png'):
        case ('image/bmp'):
        case ('image/webp'):

            return (
                <div className={`file-preview-image ${download ? 'file-preview-download' : ''}`} onClick={() => download ? onDownload() : null}>
                    {preview && <img alt={name} src={`${urlIngWeb}${link}`}/>}
                    <div className="file-preview">
                        <div className="file-preview__icon">
                            <IconFileImage/>
                            <div className="file-preview__icon__type  file-preview__icon__type--green">{fileExtension}</div>
                        </div>
                        <div className="file-preview__name">
                            <Tippy content={name}>
                                <div>
                                    <Typography.H5 color="gray-300" weight="bold">{name}</Typography.H5>
                                </div>
                            </Tippy>
                            <Typography.H6 color="gray-200">{user}</Typography.H6>
                        </div>
                        {size && <div className="file-preview__size"><Typography.H6 color="gray-300">{size <= 1000000 ? (size / 1000).toFixed(2) + ' kb' : (size / 1000 / 1024).toFixed(2) + ' mb'}</Typography.H6></div>}
                        {/* <div className="file-preview__download">
                        <Typography.Hyperlink color="primary-dark" weight="500"><a href={`${urlIngWeb}${link}`} target="_blank" download>Download</a></Typography.Hyperlink>
                            {!view && <div className="file-preview__delete" onClick={() => onDelete()}><IconTrash/></div>}
                        </div> */}
                    </div>
                </div>
            )
        default:
            return (
                <div className={`file-preview ${download ? 'file-preview-download' : ''}`} onClick={() => download ? onDownload() : null}>
                    <div className="file-preview__icon">
                        <IconFile/>
                        <div className="file-preview__icon__type  file-preview__icon__type--gray">{fileExtension}</div>
                    </div>
                    <div className="file-preview__name">
                        <Tippy content={name}>
                            <div>
                                <Typography.H5 color="gray-300" weight="bold">{name}</Typography.H5>
                            </div>
                        </Tippy>
                        <Typography.H6 color="gray-200">{user}</Typography.H6>
                    </div>
                    {size && <div className="file-preview__size"><Typography.H6 color="gray-300">{size <= 1000000 ? (size / 1000).toFixed(2) + ' kb' : (size / 1000 / 1024).toFixed(2) + ' mb'}</Typography.H6></div>}
                    {/* <div className="file-preview__download">
                        <Typography.Hyperlink color="primary-dark" weight="500"><a href={`${urlIngWeb}${link}`} target="_blank" download>Download</a></Typography.Hyperlink>
                        {!view && <div className="file-preview__delete" onClick={() => onDelete()}><IconTrash/></div>}
                    </div> */}
                </div>
            )
    }
}
