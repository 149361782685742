import instance from "./configAxios";

export async function createStatus(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = descricao_status, ordem_apresentacao, termino, id_usuario_insercao, cor_alerta

        const response = await instance.post('/insert-ticket-status', data, config);

        return response;
    } catch (error) {
        console.log('createStatus -> Error en el servidor:', error);

        return error
    }
};

export async function updateStatus(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_ticket_status, descricao_status, ordem_apresentacao, termino, cor_alerta

        const response = await instance.put('/update-status-ticket', data, config);

        return response;
    } catch (error) {
        console.log('updateStatus -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteStatus(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_usuario_exclusao, id_ticket_status

        const response = await instance.put('/delete-status_ticket', data, config);

        return response;
    } catch (error) {
        console.log('deleteStatus -> Error en el servidor:', error);

        return error;
    }
};