//GLOBAL - components from npm
import React, { useState } from "react";

//STYLES
import "./dropdown-task.scss";

//COMPONENTS
import { Typography } from "../../../components/general";
import { TagList } from "../../../components/data-entry";
import { DropdownStatus } from "../../../components/layout";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowUp } from "../../../assets/icons/ArrowUp.svg";
import { ReactComponent as IconNotes } from "../../../assets/icons/Notes.svg";
import { ReactComponent as IconCalendar } from "../../../assets/icons/Calendar.svg";
import { ReactComponent as IconAnnounce } from "../../../assets/icons/Announce.svg";
import { ReactComponent as IconLink } from "../../../assets/icons/Link-desk.svg";
import { ReactComponent as IconWhatsapp } from "../../../assets/icons/Whatsapp-desk.svg";
import { ReactComponent as IconFacebook } from "../../../assets/icons/Facebook-desk.svg";
import { ReactComponent as IconTelegram } from "../../../assets/icons/Telegram-desk.svg";
import { ReactComponent as IconEmail } from "../../../assets/icons/Email-desk.svg";
import { ReactComponent as IconInstagram } from "../../../assets/icons/Instagram-desk.svg";
import { ReactComponent as IconTwitter } from "../../../assets/icons/Twitter-desk.svg";
import { ReactComponent as IconTags } from "../../../assets/icons/Tags.svg";
import ImageProfile from "../../../assets/images/agent-profile.png";

export default function DropdownTask({
  title,
  description,
  date,
  image,
  status,
  tags,
  channelList,
}) {
  //GENERAL

  //STATES
  const [open, setOpen] = useState(false);
  const [tagsList, setTagsList] = useState(tags);
  const [statusTask, setStatusTask] = useState(status);

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <>
      <div
        className={`${open ? "active" : ""} dropdown-task`}
        onClick={() => setOpen(!open)}
      >
        <Typography.H5 weight="bold" color="gray-500">
          {title}
        </Typography.H5>
        <IconArrowUp />
      </div>
      {open && (
        <div className="task-container">
          <div className="task-container__title">
            <IconNotes />
            <Typography.H5 weight="bold" color="gray-500">
              {title}
            </Typography.H5>
          </div>
          <div className="task-container__description">
            <Typography.Paragraph color="gray-300">
              {description}
            </Typography.Paragraph>
          </div>
          <div className="task-container__information">
            <div className="task-container__information__tags">
              <IconTags />
              <TagList
                viewOnly
                tagLimit={3}
                tags={tagsList}
                setTagsList={setTagsList}
                onClick={(value) => setTagsList(value)}
              />
            </div>
            <div className="task-container__information__status">
              <DropdownStatus
                value={statusTask}
                options={[
                  { id: 1, label: "Concluido" },
                  { id: 2, label: "A Fazer" },
                  { id: 3, label: "Andamento" },
                ]}
                onClick={(option) => setStatusTask(option.id)}
              />
              <img alt="Imagem do agente" src={image || ImageProfile} />
            </div>
          </div>
          <div className="task-container__date">
            <IconCalendar />
            <Typography.H6 color="gray-200">{date}</Typography.H6>
          </div>
          <div className="task-container__communication-channel">
            <div className="task-container__title">
              <IconAnnounce />
              <Typography.H5 weight="bold" color="gray-500">
                Seleciona o canal de comunicação
              </Typography.H5>
            </div>
            <div className="task-container__communication-channel__list">
              {channelList?.map((channel, index) => (
                <div key={index}>
                  {channel === "Link" && <IconLink />}
                  {channel === "Whatsapp" && <IconWhatsapp />}
                  {channel === "Facebook" && <IconFacebook />}
                  {channel === "Email" && <IconEmail />}
                  {channel === "Telegram" && <IconTelegram />}
                  {channel === "Instagram" && <IconInstagram />}
                  {channel === "Twitter" && <IconTwitter />}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
