//GLOBAL - components from npm
import React from "react";

//STYLES
import "./order-button.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconAngleUp } from "./../../../assets/icons/AngleUp.svg";
import { ReactComponent as IconAngleDown } from "./../../../assets/icons/AngleDown.svg";
import { ReactComponent as IconArrowUp } from "./../../../assets/icons/Arrow-up.svg";

export default function OrderButton({ direction }) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <button
      type="button"
      className={`order-button ${
        direction ? `order-button--${direction}` : ""
      }`}
    >
      {/* <IconAngleUp className="order-button__icon-asc" />
      <IconAngleDown className="order-button__icon-desc" /> */}

      <IconArrowUp
        className={
          direction
            ? `order-button__icon-${direction}`
            : "order-button__icon_display"
        }
      />
    </button>
  );
}
