import instance from "./configAxios";

export async function getDaysOfWeek(token) { //* VALIDADA
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-dias-semana', {}, config);

        return response;
    } catch (error) {
        console.log('getDaysOfWeek -> Error en el servidor:', error);

        return error
    }
};

export async function getListOfOrganizations(token, organizationId) { //* VALIDADA
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = { // para buscar uma organização
            id_organizacao: organizationId
        }

        const response = await instance.post('/buscar-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('getListOfOrganizations -> Error en el servidor:', error);

        return error
    }
};

export async function createOrganization(token, data) { //* ERROR 500
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = nome_organizacao, codigo_organizacao

        const response = await instance.post('/insert-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('createOrganization -> Error en el servidor:', error);

        return error
    }
};

export async function updateOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = nome_organizacao, codigo_organizacao, id_organizacao

        const response = await instance.put('/update-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('updateOrganization -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_organizacao

        const response = await instance.put('/delete-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('deleteOrganization -> Error en el servidor:', error);

        return error;
    }
};

export async function getSlaAssociatedWithOrganization(token, organizationId, slaId) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = {
            id_organizacao: organizationId, // parâmetro obrigatório
            id_sla: slaId
        }

        const response = await instance.post('/buscar-sla-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('getSlaAssociatedWithOrganization -> Error en el servidor:', error);

        return error
    }
};

export async function createSlaForOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = idsla, dia, hora_inicio , hora_fim, id_organizacao

        const response = await instance.post('/insert-sla-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('createSlaForOrganization -> Error en el servidor:', error);

        return error
    }
};

export async function updateSlaForOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_sla_organizacao, idsla, dia, hora_inicio, hora_fim

        const response = await instance.put('/update-sla-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('updateSlaForOrganization -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteSlaForOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_sla_organizacao

        const response = await instance.put('/delete-sla-organizacao', data, config);

        return response;
    } catch (error) {
        console.log('deleteSlaForOrganization -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteGeneralSlaForOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_organizacao, id_sla

        const response = await instance.put('/delete-sla-organizacao-geral', data, config);

        return response;
    } catch (error) {
        console.log('deleteGeneralSlaForOrganization -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteDaysWeek(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_sla_organizacao, iddia

        const response = await instance.put('/delete-sla-organizacao-dia', data, config);

        return response;
    } catch (error) {
        console.log('deleteDaysWeek -> Error en el servidor:', error);

        return error;
    }
};