import React, { useEffect, useState } from "react";

import { Button, Typography } from "../../../general";
import { useSelector } from "react-redux";
import { Toast } from "../../../../utils/toast";
import { Modal } from "./../../../feedback";
import Loading from "../../Loading";

import * as APITypeManagement from "./../../../../services/api/typeManagement";

import { ReactComponent as IconTrash } from "../../../../assets/icons/Trash-3.svg";

import "./delete-reason.scss";

export default function DeleteReason({
    isOpen,
    onClose,
    selectedType,
    treeData,
    setTreeData,
    organizationId
}) {
    const token = useSelector((state) => state.auth.token);

    const reasonId = isOpen || null;

    const [pageStatus, setPageStatus] = useState("awaiting");
    const [idReason, setIdReason] = useState(null);

    function onCloseCustom() {
        onClose();
    };

    function getReasonId(reasonId) {
        selectedType.arrayOfReasons?.forEach((item) => {
            if (item.id_motivo === reasonId) {
                setIdReason(item.id_motivo)
            }
        })
    };

    async function handleUnbindReason() {
        setPageStatus("delete-reason");

        if (selectedType && idReason) {
            try {
                const response = await APITypeManagement.deleteReasonV2(
                    token,
                    Number(selectedType.id_solicitation),
                    Number(idReason),
                    organizationId && organizationId
                );

                if (response.status === 200) {
                    onCloseCustom();

                    const updatedTreeData = { ...treeData };

                    const selectedTypeIndex = updatedTreeData.types?.findIndex(
                        (type) => type.id_solicitation === selectedType.id_solicitation
                    );

                    if (selectedTypeIndex !== -1) {
                        const selectedReasonIndex =
                            updatedTreeData.types[selectedTypeIndex].arrayOfReasons?.findIndex(
                                (reason) => reason.id_motivo === idReason
                            );

                        if (selectedReasonIndex !== -1) {
                            updatedTreeData.types[selectedTypeIndex]
                                .arrayOfReasons.splice(selectedReasonIndex, 1);
                        }
                    }
                    setTreeData(updatedTreeData);

                    Toast(
                        "Motivo excluído!",
                        "Motivo excluído com sucesso.",
                        "success"
                    );
                } else {
                    Toast(
                        "Não foi possível excluir o Motivo",
                        "Tente novamente mais tarde!",
                        "error"
                    )
                }
            } catch (error) {
                Toast(
                    "Não foi possível excluir o Motivo",
                    "Tente novamente mais tarde!",
                    "error"
                )
            }
        }

        setPageStatus("Waiting");
    };

    useEffect(() => {
        if (reasonId) {
            getReasonId(reasonId)
        }
    }, [reasonId]);

    return (
        <Modal.Main
            className="modal-delete-reason"
            title={`Excluir motivo`}
            titleColor={"black"}
            headerContainerStyle={{ alignItems: "center" }}
            width="443px"
            height="300px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            icon={
                <div className="modal-delete-reason__icon">
                    <IconTrash />
                </div>
            }
            footer={
                <div className="container-footer">
                    <Button
                        className="modal-delete-reason__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "delete-reason"}
                    >
                        <Typography.H4 weight={"bold"} color="blue-selected-4">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-delete-reason__btn-confirm"
                        disabled={pageStatus === "delete-reason"}
                        onClick={handleUnbindReason}
                    >
                        {pageStatus !== "delete-reason" ? (
                            <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4
                color="gray-180"
                weight="400"
                className="information-text"
            >
                Você tem certeza que deseja excluir? Ao excluir todos os submotivos vinculados a este motivo, serão excluído e esta ação não poderá ser desfeita.
            </Typography.H4>
        </Modal.Main>
    )
}
