//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Modal from "..";
import Loading from "../../Loading";
import { Button, Typography } from "../../../general";
import { Input, Select } from "../../../data-entry";
import { FieldContainer } from "../../../data-display";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIShiftWorker from "./../../../../services/api/shiftWorker";
import * as APISla from "./../../../../services/api/sla";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES
import "./update-shift-worker.scss";

export default function UpdateShiftWorker({
    isOpen,
    onClose,
    shiftWorkerId,
    detailedShiftWorker,
    setUpdatedShiftWorker,
    onUpdate
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("requesting");
    const [originalPhoneNumber, setOriginalPhoneNumber] = useState("");
    const [validationWarn, setValidationWarn] = useState(false);
    const [errorEmailInput, setErrorEmailInput] = useState(false);
    const [onBlurErrorEmailInput, setonBlurErrorEmailInput] = useState(false);
    const [errorPhoneInput, setErrorPhoneInput] = useState(false);
    const [onBlurErrorPhoneInput, setonBlurErrorPhoneInput] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [isPhoneNumberModified, setIsPhoneNumberModified] = useState(false);
    const [isOpenModalDeleteShiftWorker, setIsOpenModalDeleteShiftWorker] = useState(false);
    const [data, setData] = useState(null);
    const [originalData, setOriginalData] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [positions] = useState([
        { id: 1, label: "Analista" },
        { id: 2, label: "Supervisor" },
        { id: 3, label: "Gerente" },
    ]);

    //FUNCTIONS
    function cleanFields() {
        setTimeout(() => {
            setErrorEmailInput(false);
            setonBlurErrorEmailInput(false);
            setErrorPhoneInput(false);
            setonBlurErrorPhoneInput(false);
            setValidationWarn(false);
        }, 50)
    };

    function onCloseCustom() {
        onClose();
        cleanFields();
        setIsDataModified(false);
        setIsPhoneNumberModified(false);
        setData(originalData);
    };

    function setDataAndMarkModified(newData) {
        setData(newData);
        setIsDataModified(true);

        if (newData.telefone_plantonista !== originalPhoneNumber) {
            setIsPhoneNumberModified(true);
        }
    };

    function onBlurEventInputError() {
        if (data?.email_plantonista && !/\S+@\S+\.\S+/.test(data?.email_plantonista)) {
            setErrorEmailInput(true);
            setonBlurErrorEmailInput(true);
        } else {
            setErrorEmailInput(false);
            setonBlurErrorEmailInput(false);
        }

        if (data?.telefone_plantonista && data?.telefone_plantonista.length < 11) {
            setErrorPhoneInput(true);
            setonBlurErrorPhoneInput(true);
        } else {
            setErrorPhoneInput(false);
            setonBlurErrorPhoneInput(false);
        }
    };

    function isFormValid() {
        if (!data?.nome_plantonista ||
            !data?.id_cargo ||
            !data?.id_organizacao ||
            !data?.telefone_plantonista ||
            !data?.email_plantonista) {
            return false;
        }

        if (data?.email_plantonista && !/\S+@\S+\.\S+/.test(data?.email_plantonista)) {
            return false;
        }

        if (data?.telefone_plantonista && data?.telefone_plantonista.length < 11) {
            return false;
        }

        return true;
    };

    function handleInvalidForm() {
        setValidationWarn(true);
        setErrorPhoneInput(!data?.telefone_plantonista);
        setErrorEmailInput(!data?.email_plantonista || !/\S+@\S+\.\S+/.test(data?.email_plantonista));

        Toast(
            "Erro ao adicionar plantonista!",
            "Verifique o preenchimento dos campos em destaque.",
            "error"
        );
    };

    function getValues(shiftWorkerId) {
        detailedShiftWorker.forEach((item) => {
            if (item.id_plantonista === shiftWorkerId) {
                setData(item);
                setIsDataModified(false);
                setIsPhoneNumberModified(false);
            }
        })
    };

    async function handleUpdateShiftWorker() {
        if (!isDataModified) {
            onCloseCustom();
            return;
        }

        if (!isFormValid()) {
            handleInvalidForm();
            return;
        }

        setPageStatus("updating-shift-worker");

        const newValues = {
            id_plantonista: data?.id_plantonista,
            nome_plantonista: data?.nome_plantonista,
            telefone_plantonista: data?.telefone_plantonista,
            email_plantonista: data?.email_plantonista,
            id_cargo: data?.id_cargo,
            id_organizacao: data?.id_organizacao
        };

        const registeredPhone = detailedShiftWorker?.some(
            item =>
                item.telefone_plantonista === data?.telefone_plantonista &&
                item.id_organizacao === data?.id_organizacao
        );

        if (isPhoneNumberModified && registeredPhone && data?.telefone_plantonista === originalPhoneNumber) {
            onCloseCustom();
            return;
        }

        if (isPhoneNumberModified && registeredPhone) {
            setPageStatus("awaiting");
            Toast(
                "Atenção!",
                "Este número já está cadastrado para esta organização. Por favor, insira um número diferente.",
                "warn"
            );
            return;
        }

        const response = await APIShiftWorker.updateShiftWorker(token, newValues);

        if (response.status === 200) {
            Toast("Plantonista atualizado!", "Plantonista atualizado com sucesso.", "success");
            onUpdate();
            onCloseCustom();
            setIsDataModified(false);
            setUpdatedShiftWorker(true);
        } else {
            Toast(
                "Não foi possível atualizar o plantonista!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getOrganizationsList() {
        setPageStatus("requesting");

        const response = await APISla.listOrganizations(token);

        if (response.status === 200) {
            let dataUpdatedOrganization = [];

            response.data.forEach((data) => {
                let requestedItemOrganization = {};

                requestedItemOrganization.id = data.id_organizacao;
                requestedItemOrganization.label = data.nome;

                dataUpdatedOrganization.push(requestedItemOrganization);
            });

            setOrganizations(dataUpdatedOrganization)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem de organizações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    //USE EFFECTS
    useEffect(() => {
        if (isOpen) getOrganizationsList()
    }, [isOpen]);

    useEffect(() => {
        if (shiftWorkerId) {
            getValues(shiftWorkerId);

            setOriginalData(
                detailedShiftWorker.find(
                    item => item.id_plantonista === shiftWorkerId
                )
            );

            setOriginalPhoneNumber(
                detailedShiftWorker.find(
                    item => item.id_plantonista === shiftWorkerId
                )?.telefone_plantonista || ""
            );
        }
    }, [shiftWorkerId, detailedShiftWorker]);

    return (
        <>
            <Modal.Main
                className="modal-update-shift-worker"
                title="Editar plantonista"
                width="704px"
                height="384px"
                isOpen={isOpen}
                onClose={onCloseCustom}
                shouldCloseOnOverlayClick={false}
                footer={
                    <div className="modal-update-shift-worker__container-footer">
                        <div className="remove-button">
                            <Button
                                className="modal-update-shift-worker__btn-remove"
                                onClick={() => setIsOpenModalDeleteShiftWorker(true)}
                                disabled={pageStatus === "removing-shift-worker"}
                                icon={<CloseIcon />}
                            >
                                <Typography.H4 weight="bold" color="red-2">
                                    Excluir
                                </Typography.H4>
                            </Button>
                        </div>

                        <div className="save-and-cancel-actions">
                            <Button
                                className="modal-update-shift-worker__btn-cancel"
                                disabled={pageStatus === "updating-shift-worker"}
                                onClick={() => onCloseCustom()}
                            >
                                <Typography.H4
                                    weight="bold"
                                    color="blue-selected-4"
                                >
                                    Cancelar
                                </Typography.H4>
                            </Button>

                            <Button
                                className="modal-update-shift-worker__btn-confirm"
                                disabled={pageStatus === "updating-shift-worker"}
                                onClick={handleUpdateShiftWorker}
                            >
                                {pageStatus !== "updating-shift-worker" ? (
                                    <Typography.H4 weight="bold">
                                        Salvar
                                    </Typography.H4>
                                ) : (
                                    <Loading.Dots />
                                )}
                            </Button>
                        </div>
                    </div>
                }
            >
                <main className="modal-update-shift-worker-content">
                    <FieldContainer title="Nome" required>
                        <Input
                            placeholder="Digite seu nome aqui"
                            value={data?.nome_plantonista}
                            onChange={(event) => setDataAndMarkModified({ ...data, nome_plantonista: event })}
                            error={validationWarn ? !data?.nome_plantonista : false}
                        />
                    </FieldContainer>

                    <div className="modal-update-shift-worker-content__section1">
                        <FieldContainer title="Telefone" required>
                            <Input
                                type="numberMask"
                                placeholder="XX 9 9123-5678"
                                mask={"(99) 9 9999-9999"}
                                value={data?.telefone_plantonista}
                                onBlur={onBlurEventInputError}
                                onChange={(event) => setDataAndMarkModified({ ...data, telefone_plantonista: event })}
                                typeInputMessage={onBlurErrorPhoneInput ? "error" : false}
                                error={
                                    errorPhoneInput ?
                                        (data?.telefone_plantonista && data?.telefone_plantonista.length < 11) ||
                                        !data?.telefone_plantonista : false
                                }
                                customInputMessage={"O número deve ter 11 dígitos"}
                            />
                        </FieldContainer>

                        <FieldContainer title="Email" required>
                            <Input
                                type="email"
                                placeholder="email@hotmail.com"
                                value={data?.email_plantonista}
                                onBlur={onBlurEventInputError}
                                onChange={(event) => setDataAndMarkModified({ ...data, email_plantonista: event })}
                                typeInputMessage={onBlurErrorEmailInput ? "error" : false}
                                error={
                                    errorEmailInput ?
                                        (data?.email_plantonista && !/\S+@\S+\.\S+/.test(data?.email_plantonista)) ||
                                        !data?.email_plantonista : false
                                }
                                customInputMessage={"E-mail inválido"}
                            />
                        </FieldContainer>
                    </div>

                    <div className="modal-update-shift-worker-content__section2">
                        <FieldContainer title="Cargo" required>
                            <Select
                                upward
                                placeholder="Selecione o cargo"
                                value={data?.id_cargo}
                                options={positions}
                                onSelect={(position) => {
                                    const values = { ...data };

                                    values.id_cargo = position.id;
                                    values.nome_cargo = position.label;

                                    setDataAndMarkModified(values);
                                }}
                            />
                        </FieldContainer>

                        <FieldContainer title="Organização" required>
                            <Select
                                upward
                                placeholder="Selecione a organização"
                                value={data?.id_organizacao}
                                options={organizations}
                                onSelect={(organization) => {
                                    const values = { ...data };

                                    values.id_organizacao = organization.id;
                                    values.nome_organizacao = organization.label;

                                    setDataAndMarkModified(values);
                                }}
                            />
                        </FieldContainer>
                    </div>
                </main>
            </Modal.Main>

            <Modal.DeleteShiftWorker
                isOpen={isOpenModalDeleteShiftWorker}
                onClose={(event) => setIsOpenModalDeleteShiftWorker(event)}
                shiftWorkerId={data?.id_plantonista}
                userId={userData.userId}
                onDelete={onUpdate}
            />
        </>
    )
}
