import instance from "./configAxios";

export async function listComments(idTask, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.get(`/list-comments?id_task=${idTask}`, config);
    return response;
  } catch (error) {
    return error.response;
  }
}
 
export async function createComment(data, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await instance.post('/create-comment', data, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateComment(data, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await instance.put('/update-comment', data, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteComment(idComment, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.delete(`/delete-comment/${idComment}`, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function listCommentsCase(id, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.get(`/list-comments-ticket?id_ticket=${id}`, config);
    return response;
  } catch (error) {
    return error.response;
  }
}
 
export async function createCommentCase(data, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await instance.post('/create-comment-ticket', data, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateCommentCase(data, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await instance.put('/update-comment-ticket', data, config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteCommentCase(idComment, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.delete(`/delete-comment_ticket/${idComment}`, config);
    return response;
  } catch (error) {
    return error.response;
  }
}