//GLOBAL - components from npm
import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useHistory, useParams, useLocation } from "react-router";
import { format, isPast, isAfter } from "date-fns";
import moment from "moment-business-time";
import { cloneDeep } from "lodash";
import { FaQuestionCircle } from "react-icons/fa";

// UTILS
import { Toast } from "../../../utils/toast";
import formatDate from "../../../utils/formatDate";
import postMessage from "../../../utils/postMessage";
import renderPhone from "../../../utils/renderPhoneFormat";

//STYLES
import "./details.scss";

//COMPONENTS
import useQuery from "../../../hooks/useQuery";
import { Typography, Button } from "../../../components/general";
import { Loading, Modal, Tippy } from "./../../../components/feedback";
import { MenuTabs, PopoverMenu } from "./../../../components/navigation";
import {
  Delete,
  DatePicker,
  Select,
  SelectSearch,
  ButtonSearch,
  Input,
  CustomDropdown,
  ButtonAgentSearch,
  Checkbox,
} from "../../../components/data-entry";
import {
  DropdownStatus,
  DropdownPriority,
  DropdownChannel,
  DropdownEscalate,
} from "../../../components/layout";
import {
  ImageProfile,
  FieldContainer,
  FieldContainerEdit,
  Channel,
} from "../../../components/data-display";
import TaskList from "./TaskList";
import CaseComments from "./CaseComments";
import CaseInteractions from "./CaseInteractions/CaseInteractions";
import CaseHistoric from "./CaseHistoric";
import CaseCommunications from "./CaseCommunications";

//SERVICES - api, conectors...
import * as APICase from "../../../services/api/case";
import * as APIUser from "../../../services/api/task";
import * as APIClient from "./../../../services/api/client";
import * as APIComments from "../../../services/api/comments";
import * as APIInteraction from "./../../../services/api/interaction";
import * as APIAdditionalFields from "./../../../services/api/additionalFields";
// import * as APITypeManagement from "./../../../services/api/typeManagement";
import * as APICompany from "./../../../services/api/company";

//GLOBAL STATE - redux, env...
import { useSelector, useDispatch } from "react-redux";
import { saveRoute } from "../../../store/audit";

//ASSETS - icons, images...
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/ArrowDownTwo.svg";
// import { ReactComponent as IconUndo } from "../../../assets/icons/Undo.svg";
// import { ReactComponent as IconViewUser } from "../../../assets/icons/ViewUser.svg";
// import { ReactComponent as IconTasks } from "../../../assets/icons/Tasks.svg";
// import { ReactComponent as IconMessage } from "../../../assets/icons/Message.svg";
// import { ReactComponent as IconNotes } from "../../../assets/icons/Notes.svg";
// import { ReactComponent as IconInfo } from "../../../assets/icons/Info.svg";
// import { ReactComponent as IconHistoric } from "../../../assets/icons/Historic.svg";
// import { ReactComponent as IconEmail } from "../../../assets/icons/mail.svg";
// import { ReactComponent as IconAnnounce } from "../../../assets/icons/Announce.svg";

export default function Details() {
  //GENERAL
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.userData);
  const permissionProfile = useSelector((state) => state.auth.permissionProfile);
  const interaction = useSelector((state) => state.interaction);
  const filtersContacts = useSelector((state) => state.filters.contacts);
  // const principalAdditionalField = useSelector((state) => state.filters.principalAdditionalField);
  const { id } = useParams();
  const refPage = useRef();
  const location = useLocation();

  const ClearFieldTrigger = {
    ALL: "ALL",
    REASON_AND_SUBREASON: "REASON_AND_SUBREASON",
    SUBREASON: "SUBREASON",
  };

  //STATES
  const [pageStatus, setPageStatus] = useState("loading");
  const [clearFieldTrigger, setClearFieldTrigger] = useState("");
  const [caseDetail, setCaseDetail] = useState();
  const [statusList, setStatusList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState();
  const [listRequest, setListRequest] = useState([]);
  const [listReasons, setListReasons] = useState([]);
  const [listSubReasons, setListSubReasons] = useState([]);
  const [customEmptyTaskData, setCustomEmptyTaskData] = useState({ id_ticket: parseInt(id), });
  const [clientData, setClientData] = useState({});
  const [priority, setPriority] = useState({});
  const [cameFrom, setCameFrom] = useState();
  const [tabHide, setTabHide] = useState("");
  const [channelList, setChannelList] = useState();
  const [escalateList, setEscalateList] = useState();
  const [interactionList, setInteractionList] = useState();
  const [userCreator, setUserCreator] = useState({});
  const [openVariable, setOpenVariable] = useState(false);
  // const [variableValues, setVariableValues] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [newStatus, setNewStatus] = useState([]);
  const [additionalFieldsParent, setAdditionalFieldsParent] = useState([]);
  const [additionalFieldsIntegration, setAdditionalFieldsIntegration] = useState([]);
  const [, setProcessedAdditionalFields] = useState([]);
  const [slaData, setSlaData] = useState([]);
  const [additionalChildFields, setAdditionalChildFields] = useState({});
  const [currentStatusTexts, setCurrentStatusTexts] = useState({});
  const [changedStatus, setChangedStatus] = useState(false);
  // const [organizationTeste, setOrganizationTeste] = useState(null);
  const [editableField, setEditableField] = useState(false);
  // const [controlPost, setControlPost] = useState(false);
  const [logControl, setLogControl] = useState(false);
  const [tabChoose, setTabChoose] = useState(0);
  const [caseUpdates, setCaseUpdates] = useState(false);
  const [isUpdatingCustomFields, setIsUpdatingCustomFields] = useState(false);
  const [isOpenCustomFields, setIsOpenCustomFields] = useState(true);
  const [requiredField, setRequiredField] = useState(false);
  const [requiredChild, setRequiredChild] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  // console.log("caseDetail###", caseDetail, additionalFieldsParent);

  const query = useQuery();
  const isDealing = query.get("dealing");

  // armazenar o mais recente additionalFieldsParent e evitar execução sempre que houver alterações
  const additionalFieldsRef = useRef(additionalFieldsParent);

  // console.log("case details####", {
  //   additionalFieldsParent,
  //   additionalFieldsIntegration,
  //   processedAdditionalFields,
  // })

  //REDUX - Selectors

  //FUNCTIONS
  // let isExtern = history.location.pathname.indexOf("externo") === 1 ? "/externo/casos" : "/casos";

  const clearRequestTypeFields = (trigger) => {
    const updatedCaseDetail = { ...caseDetail };

    switch (trigger) {
      case ClearFieldTrigger.ALL:
        updatedCaseDetail.id_solicitacao = undefined;
        updatedCaseDetail.id_motivo = null;
        updatedCaseDetail.id_submotivo = null;
        break;
      case ClearFieldTrigger.REASON_AND_SUBREASON:
        updatedCaseDetail.id_motivo = null;
        updatedCaseDetail.id_submotivo = null;
        break;
      case ClearFieldTrigger.SUBREASON:
        updatedCaseDetail.id_submotivo = null;
        break;
      default:
        break;
    }

    setCaseDetail(updatedCaseDetail);
  };

  const handleBackspaceOnRequiredCustomFields = (event, field) => {
    if (event.key !== 'Backspace') return;

    const isFocusedOnRequiredField = additionalFieldsIntegration.some(({ campos_adicionais_obrigatorios }) =>
      campos_adicionais_obrigatorios.some(({ idcampos }) => idcampos === field.id_campo_adicional)
    );

    if (!isFocusedOnRequiredField) return;

    setAdditionalFieldsParent(prevFields => {
      const triggerField = additionalFieldsIntegration.find(({ campos_adicionais_obrigatorios }) =>
        campos_adicionais_obrigatorios.some(({ idcampos }) => idcampos === field.id_campo_adicional)
      );

      if (!triggerField) return prevFields;

      const allRequiredFieldsFilled = triggerField.campos_adicionais_obrigatorios.every(({ idcampos }) => {
        const correspondingField = prevFields.find(({ id_campo_adicional }) => id_campo_adicional === idcampos);
        return correspondingField && correspondingField.valor_default;
      });

      if (!allRequiredFieldsFilled) return prevFields;

      const triggerFieldValue =
        prevFields.find(({ id_campo_adicional }) => id_campo_adicional === triggerField.id_campo_gatilho)?.valor_default;

      if (!triggerFieldValue) return prevFields;

      const matchingField = prevFields.find(
        ({ id_campo_adicional }) => id_campo_adicional === triggerField.id_campo_adicional_retorno
      )

      if (matchingField) {
        matchingField.valor_default = null;
      }

      return prevFields.map(prevField => {
        if (prevField.id_campo_adicional === triggerField.id_campo_gatilho) {
          return { ...prevField, valor_default: null };
        }
        return prevField;
      });
    });
  };

  // Função que envia para o ingweb as informações necessárias para abrir uma interação ativa.
  function handleInteractionStart(channel, destination) {
    postMessage("startInteraction", {
      caseId: caseDetail.id_ticket,
      channelId: channel.id,
      channelName: channel.label,
      destination: destination,
    });
    postMessage("willLogInteraction", {
      active: true,
      destination: destination,
      caseId: caseDetail.id_ticket,
      clientId: caseDetail.id_contato,
      clientName: caseDetail.nome_contato,
    });
    postMessage("sendMeLogInteractionDuration", {
      active: true,
      destination: destination,
      caseId: caseDetail.id_ticket,
      clientId: caseDetail.id_contato,
      clientName: caseDetail.nome_contato,
    });
  }

  // Versão anterior de onde buscava a rota para o backend do ingweb
  // function getAuditRoute() {
  //   postMessage("getAuditRoute", true);
  // }

  // Função que renderiza os canais de interação com base em se ele é ativo.
  function renderChannels() {
    const activeChannels = channelList.filter((channel) => channel.ativo);

    return activeChannels.map((channel) =>
      permissionProfile.canalComunicacao.edicao ? (
        <PopoverMenu
          key={channel.label}
          className="popover-details-channel"
          placement="bottom-start"
          menuData={caseDetail.telefone.map((phone) => ({
            title: phone,
            onClick: () => handleInteractionStart(channel, phone),
          }))}
        >
          <div>
            <Channel
              className="btn-channel-interaction"
              tippy={true}
              media={channel.id}
              nameMedia={channel.label}
              onClick={() => true}
            />
          </div>
        </PopoverMenu>
      ) : (
        <div>
          <Channel
            className="btn-channel-interaction"
            tippy={true}
            media={channel.id}
            nameMedia={channel.label}
            onClick={() => true}
            disabled
          />
        </div>
      )
    );
  }

  function modifyArray(data) {
    const newArray = data.filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.id === item.id && t.label === item.label)
    );

    return newArray;
  }

  //Função que busca a lista de canais na API e transforma no modelo padrão dos componentes.
  async function getChannelList() {
    const response = await APICase.getChannelList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_canal;
        requestItem.label = datum.nome;
        requestItem.ativo = datum.ativo;
        requestItem.dt_exclusao = datum.dt_exclusao;

        dataUpdated.push(requestItem);
      });
      setChannelList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  //Função que busca a lista dos setores do escalonamento na API e transforma no modelo padrão dos componentes.
  async function getEscalateList() {
    const response = await APICase.getEscalateList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_escalonamento;
        requestItem.label = datum.descricao;

        dataUpdated.push(requestItem);
      });
      setEscalateList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  //Função que busca a lista das interações na API.
  async function getInteractionList() {
    const response = await APIInteraction.listInteractionCase(token, id);
    if (response.status === 200) {
      setInteractionList(response.data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  // Função que faz a tela ficar em loading enquanto chama todas as outras funções que listam as informações que aparecem na tela.
  const getCaseInfo = async () => {
    setPageStatus("loading");

    try {
      await Promise.all([
        getChannelList(),
        getDetails(),
        getListRequest(),
        getListPriority(),
        // getListStatus(),
        getListUsers(),
        getEscalateList(),
        getInteractionList(),
        getRouteAudit(),
        getListOrganizations(),
        getAdditionalFieldsIntegration()
      ]);
    } catch (error) {
      console.error("Erro ao obter informações do caso:", error);
    }

    setPageStatus("awaiting");
  };

  //Função que busca a lista de Contatos na API e transforma no modelo padrão dos componentes.
  async function getListClients(searchParams, page) {
    const response = await APIClient.all(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const formatTelephones = client.telefone.split(",");
      const formatedTelephones = formatTelephones.map((telephone) =>
        renderPhone(telephone)
      );

      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.nome_empresa,
        caption2: client.codigo_externo,
        caption3: client.cpf,
        caption4:
          formatTelephones.length > 1
            ? formatedTelephones.join(", ")
            : renderPhone(formatTelephones[0]),
        caption5: client.email,
      };

      updatedData.push(updatedClient);
    });

    return updatedData;
  }

  //Função que busca a lista de empresas na API e transforma no modelo padrão dos componentes.
  async function getListEnterprises2(title, page, limit) {
    const response = await APICompany.newListAllCompanysUsers(
      token,
      page,
      title,
      limit,
      ""
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((company) => {
      const updatedCompany = {
        id: company.id_empresa,
        label: company.nome,
        caption1: company.cnpj,
        caption2: company.site,
        caption3: company.codigo_empresa,
      };

      updatedData.push(updatedCompany);
    });

    return updatedData;
  }

  //Função que busca a lista de Agentes na API e transforma no modelo padrão dos componentes.
  async function getListUsers() {
    const response = await APIUser.listUsers(token);
    if (response.status === 200) {
      const arrayUsers = [];
      for (const item of response.data) {
        const object = {
          id: item.id_usuario,
          label: item.nome_usuario,
          caption1: item.nome_organizacao,
        };
        arrayUsers.push(object);
      }
      setUsers(arrayUsers);
    } else {
      Toast(
        "Ops... algo deu errado.",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  // Função que lista os motivos com base no tipo de solicitação do sla configurado
  async function changeTypeRequest(
    idTypeRequest,
    isMount,
    idOrganization,
    logInfo
  ) {
    const response = await APICase.getReasonsListToType(
      token,
      idTypeRequest,
      idOrganization
    );

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_motivo;
        requestItem.label = datum.desc_motivo;

        dataUpdated.push(requestItem);
      });

      if (isMount) {
        if (dataUpdated.length === 1) {
          await getSlaRule(
            dataUpdated[0].id,
            idTypeRequest,
            null,
            idOrganization,
            logInfo
          );
          changeSubTypeRequest(
            dataUpdated[0].id,
            true,
            idOrganization,
            idTypeRequest
          );
        } else {
          if (dataUpdated.length >= 2 || dataUpdated.length === 0) {
            await getSlaRule(
              null,
              idTypeRequest,
              null,
              idOrganization,
              logInfo
            );
          }
          // if (dataUpdated.length === 0) {
          //   setCaseDetail({
          //     ...caseDetail,
          //     id_criticidade: 3,
          //     descricao_criticidade: "Baixa",
          //     id_solicitacao: idTypeRequest,
          //     prazo_resolucao: undefined,
          //   });
          //   setEditableField(true);
          // }
        }
      }

      dataUpdated = modifyArray(dataUpdated);

      setListReasons(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  // Função que lista os submotivos com base no tipo de motivo do sla configurado
  async function changeSubTypeRequest(
    idReason,
    isMount,
    idOrganization,
    idRequest
  ) {
    const response = await APICase.getSubReasonsListToType(
      token,
      idReason,
      idOrganization
    );

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_submotivo;
        requestItem.label = datum.desc_submotivo;

        dataUpdated.push(requestItem);
      });

      if (isMount) {
        if (dataUpdated.length === 1) {
          await getSlaRuleSub(
            dataUpdated[0].id,
            idRequest,
            null,
            idReason,
            idOrganization
          );
        } else {
          if (dataUpdated.length >= 2) {
            // await getSlaRule(null, idReason, null, idOrganization);
            await getSlaRuleSub(
              null,
              idRequest,
              null,
              idReason,
              idOrganization
            );
          }
          if (dataUpdated.length === 0) {
            setCaseDetail({
              ...caseDetail,
              id_criticidade: 3,
              descricao_criticidade: "Baixa",
              id_motivo: idReason,
              prazo_resolucao: undefined,
            });
          }
        }
      }

      dataUpdated = modifyArray(dataUpdated);

      setListSubReasons(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  // ################ ANTIGA FUNÇÃO PARA CALCULAR SLA ################
  // const calcSlaDayAndHoliday = async (idSla, timeToAddicionated, now) => {
  //   const response = await APICase.getWorkTimeAndHolidaySla(token, idSla);

  //   let jsonWorkTime = [];
  //   let jsonHoliday = [];
  //   let dateServer = new Date(now);

  //   if (response.data.worktime.length !== 0) {
  //     let arrayWorktime = response.data.worktime;
  //     let arrayHolidays = response.data.holiday;

  //     jsonWorkTime = {
  //       0: mountHourWorkTime(arrayWorktime, 0),
  //       1: mountHourWorkTime(arrayWorktime, 1),
  //       2: mountHourWorkTime(arrayWorktime, 2),
  //       3: mountHourWorkTime(arrayWorktime, 3),
  //       4: mountHourWorkTime(arrayWorktime, 4),
  //       5: mountHourWorkTime(arrayWorktime, 5),
  //       6: mountHourWorkTime(arrayWorktime, 6),
  //     };

  //     await arrayHolidays.forEach((holiday) => {
  //       jsonHoliday.push(mountHoliday(holiday.data_feriado));
  //     });

  //     let objectWorkTimeAndHoliday = {
  //       workinghours: jsonWorkTime,
  //       holidays: jsonHoliday,
  //     };
  //     moment.locale("pt", objectWorkTimeAndHoliday);
  //     let dateFormatted = moment(dateServer).addWorkingTime(
  //       timeToAddicionated,
  //       "minutes"
  //     );
  //     dateFormatted = new Date(dateFormatted);
  //     return dateFormatted;
  //   }

  //   let dateSla = dateServer;
  //   dateSla.setMinutes(dateSla.getMinutes() + timeToAddicionated);

  //   return dateSla;
  // };

  // ################ NOVA FUNÇÃO PARA CALCULAR SLA - FUNÇÃO MENOS REDUNTANDE E CONCISA ################
  const calcSlaDayAndHoliday = async (idSla, timeToAddicionated, now) => {
    try {
      const response = await APICase.getWorkTimeAndHolidaySla(token, idSla);
      const { worktime, holiday } = response.data;

      if (worktime.length === 0) {
        const dateSla = new Date(now);

        dateSla.setMinutes(dateSla.getMinutes() + timeToAddicionated);

        return dateSla;
      }

      const jsonWorkTime = {};
      for (let i = 0; i < 7; i++) {
        jsonWorkTime[i] = mountHourWorkTime(worktime, i);
      }

      const jsonHoliday = holiday.map((h) => mountHoliday(h.data_feriado));

      const objectWorkTimeAndHoliday = {
        workinghours: jsonWorkTime,
        holidays: jsonHoliday,
      };

      moment.locale("pt", objectWorkTimeAndHoliday);

      const dateFormatted = moment(now).addWorkingTime(
        timeToAddicionated,
        "minutes"
      );

      return new Date(dateFormatted);
    } catch (error) {
      console.error("Erro ao calcular a data SLA:", error);

      Toast("Erro interno!", "Erro ao calcular a data SLA.", "error");
    }
  };

  // ################ ANTIGA FUNÇÃO PARA MONTAR HORAS DE TRABALHO ################
  // function mountHourWorkTime(arrayWorktime, day) {
  //   let getDayWorkTime = arrayWorktime.filter(
  //     (time) => time.dia_semana === day
  //   );

  //   if (getDayWorkTime.length !== 0) {
  //     let struct = [getDayWorkTime[0].hora_inicio, getDayWorkTime[0].hora_fim];
  //     return struct;
  //   }

  //   return null;
  // }

  // ################ NOVA FUNÇÃO PARA MONTAR HORAS DE TRABALHO - RETORNA TODOS COJUNTOS DE TRABALHOS ENCONTRADOS ################
  function mountHourWorkTime(arrayWorktime, day) {
    let getDayWorkTime = arrayWorktime.filter(
      (time) => time.dia_semana === day
    );

    let struct = [];

    for (const exemplo of getDayWorkTime) {
      const horarios = [exemplo.hora_inicio, exemplo.hora_fim];
      struct.push(...horarios);
    }

    return struct.length > 0 ? struct : null;
  }

  function mountHoliday(date) {
    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let dateFormatted = `*-${month >= 10 ? month : "0" + month}-${day}`;

    return dateFormatted;
  }

  async function getSlaRule(
    idReason,
    idRequest,
    nameReason,
    idOrganization,
    logInfo
  ) {
    try {
      const response = await APICase.getSlaRule(
        token,
        idReason,
        idRequest,
        idOrganization
      );

      if (response.status === 200) {
        if (response.data.length !== 0) {
          const slaData = response.data[0];

          const filterPriority = priorityList.find(
            (option) => option.id === slaData.id_criticidade
          );

          if (!filterPriority) {
            Toast("Falha!", "Criticidade não encontrada.", "error");
          }

          const idEscalonamento =
            slaData.id_escalonamento !== null ? slaData.id_escalonamento : null;

          const updatedCaseDetail = {
            ...caseDetail,
            id_motivo: idReason,
            descricao_motivo: nameReason,
            id_campo: 15,
            id_solicitacao: idRequest,
            id_criticidade: filterPriority.id,
            descricao_criticidade: filterPriority.label,
            id_escalonamento: idEscalonamento
          };

          setCaseDetail(updatedCaseDetail);
          updateCase(updatedCaseDetail, null, logInfo);
          setSlaData(response.data)
        } else {
          await getSlaRuleDefault(
            idReason,
            idRequest,
            nameReason,
            idOrganization,
            logInfo
          );
        }
      } else {
        Toast(
          "Falha ao carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);

      Toast(
        "Erro interno!",
        "Ocorreu um erro ao processar a solicitação. Tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getSlaRuleDefault(
    idReason,
    idRequest,
    nameReason,
    idOrganization,
    logInfo
  ) {
    try {
      const response = await APICase.getSlaRule(
        token,
        null,
        idRequest,
        idOrganization
      );

      if (response.status === 200) {
        const responseData = response.data[0];
        if (responseData) {
          const dateSla = await calcSlaDayAndHoliday(
            responseData.id_sla,
            responseData.sla_inicio + responseData.sla_resolucao,
            responseData.now
          );

          const filterPriority = priorityList.find(
            (option) => option.id === responseData.id_criticidade
          );

          if (!filterPriority) {
            Toast("Falha!", "Criticidade não encontrada.", "error");
          }

          const updatedCaseDetail = {
            ...caseDetail,
            id_motivo: idReason,
            descricao_motivo: nameReason,
            id_campo: 15,
            id_solicitacao: idRequest,
            id_criticidade: filterPriority.id,
            descricao_criticidade: filterPriority.label,
            prazo_resolucao: dateSla,
          };

          setCaseDetail(updatedCaseDetail);
          updateCase(updatedCaseDetail, null, logInfo);
        } else {
          setCaseDetail({
            ...caseDetail,
            id_criticidade: 3,
            descricao_criticidade: "Baixa",
            id_solicitacao: idRequest,
            id_motivo: idReason,
            prazo_resolucao: undefined,
          });

          updateCase(
            {
              ...caseDetail,
              id_criticidade: 3,
              descricao_criticidade: "Baixa",
              id_solicitacao: idRequest,
              id_motivo: idReason,
              prazo_resolucao: new Date(),
            },
            null,
            logInfo
          );
        }
      } else {
        Toast(
          "Falha ao carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);
      Toast(
        "Falha ao carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getSlaRuleSub(
    idSubReason,
    idRequest,
    nameReason,
    idReason,
    idOrganization,
    logInfo
  ) {
    try {
      const response = await APICase.getSlaRule2(
        token,
        idSubReason,
        idRequest,
        idReason,
        idOrganization
      );

      if (response.status === 200) {
        const responseData = response.data[0];
        if (responseData) {
          const dateSla = await calcSlaDayAndHoliday(
            responseData.id_sla,
            responseData.sla_inicio + responseData.sla_resolucao,
            responseData.now
          );

          const filterPriority = priorityList.find(
            (option) => option.id === responseData.id_criticidade
          );

          if (!filterPriority) {
            Toast("Falha!", "Criticidade não encontrada.", "error");
          }

          const updatedCaseDetail = {
            ...caseDetail,
            id_submotivo: idSubReason,
            desc_submotivo: nameReason,
            id_campo: 15,
            id_motivo: idReason,
            id_solicitacao: idRequest,
            id_criticidade: filterPriority.id,
            descricao_criticidade: filterPriority.label,
            prazo_resolucao: dateSla,
          };

          setCaseDetail(updatedCaseDetail);
          updateCase(updatedCaseDetail, null, logInfo);
        } else {
          await getSlaRuleSubDefault(
            idSubReason,
            idRequest,
            nameReason,
            idReason,
            idOrganization,
            logInfo
          );
        }
      } else {
        Toast(
          "Falha ao carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);

      Toast(
        "Falha ao carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getSlaRuleSubDefault(
    idSubReason,
    idRequest,
    nameReason,
    idReason,
    idOrganization,
    logInfo
  ) {
    try {
      const response = await APICase.getSlaRule2(
        token,
        null,
        idRequest,
        idReason,
        idOrganization
      );

      if (response.status === 200) {
        const responseData = response.data[0];
        if (responseData) {
          const dateSla = await calcSlaDayAndHoliday(
            responseData.id_sla,
            responseData.sla_inicio + responseData.sla_resolucao,
            responseData.now
          );

          const filterPriority = priorityList.find(
            (option) => option.id === responseData.id_criticidade
          );

          if (!filterPriority) {
            Toast("Falha!", "Criticidade não encontrada.", "error");
          }

          const updatedCaseDetail = {
            ...caseDetail,
            id_submotivo: idSubReason,
            desc_submotivo: nameReason,
            id_campo: 15,
            id_motivo: idReason,
            id_solicitacao: idRequest,
            id_criticidade: filterPriority.id,
            descricao_criticidade: filterPriority.label,
            prazo_resolucao: dateSla,
          };
          setCaseDetail(updatedCaseDetail);
          updateCase(updatedCaseDetail, null, logInfo);
        } else {
          setCaseDetail({
            ...caseDetail,
            id_criticidade: 3,
            descricao_criticidade: "Baixa",
            id_solicitacao: idRequest,
            id_submotivo: idSubReason,
            id_motivo: idReason,
            prazo_resolucao: undefined,
          });
          updateCase(
            {
              ...caseDetail,
              id_criticidade: 3,
              descricao_criticidade: "Baixa",
              id_solicitacao: idRequest,
              id_submotivo: idSubReason,
              id_motivo: idReason,
              prazo_resolucao: new Date(),
            },
            null,
            logInfo
          );
        }
      } else {
        Toast(
          "Falha ao carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);

      Toast(
        "Falha ao carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  //Função que busca o detalhamento do caso na API e salva as informações em seus estados.
  const getDetails = async () => {
    const response = await APICase.caseDetail(id, token);
    const data = response?.data;

    if (!data || data.length === 0) {
      return history.push("/caso/nao-encontrado");
    }

    // fix: converts 'telefone' field, that returns string into an array
    data.telefone = data.telefone.split(",");

    // fix: retirar valor protocolo e id_interacao quando estiver vindo do back
    //data.protocolo_externo = null;
    // data.interacao = null;

    data.campos_adicionais.forEach(field => {
      field.id_tipo_campo_adicional = null;
    });

    setCaseDetail(data);

    setUserCreator({ nome_usuario: data.nome_solicitante });
    setUserProfile({
      nome_usuario: data.nome_responsavel,
      id_usuario: data.id_responsavel,
    });
    setStartDate(new Date(data.prazo_resolucao));
    setCustomEmptyTaskData({
      ...customEmptyTaskData,
      id_contato: data.id_contato,
    });
    setClientData({
      id: data.id_contato,
      label: data.nome_contato,
      caption1: data?.nome_empresa,
    });
    setPriority({
      id: data.id_criticidade,
      label: data.descricao_criticidade,
    });
    changeTypeRequest(data.id_solicitacao, false);
    changeSubTypeRequest(data.id_motivo, false);

    // await getListAdditionalFields(data.campos_adicionais);
  };

  //Função que busca a lista de Prioridades na API e transforma no modelo padrão dos componentes.
  const getListPriority = async () => {
    const response = await APICase.getPriorityList(token);
    const data = response.data;
    let updatedData = [];

    data.forEach((datum) => {
      let updatedDatum = {};

      updatedDatum.id = datum.id_criticidade;
      updatedDatum.label = datum.descricao;

      updatedData.push(updatedDatum);
    });

    setPriorityList(updatedData);
  };

  //Função que busca a lista de status na API e transforma no modelo padrão dos componentes.
  const getListStatus = async () => {
    const response = await APICase.getListStatus(token);
    let data = response.data;

    // if (caseDetail) {
    //   const orgFilter = organizationsList.filter(
    //     (org) => org.id === caseDetail.id_organizacao
    //   );

    //   data = data.filter((datum) => {
    //     if (orgFilter[0].statusFechar) {
    //       return true;
    //     } else {
    //       return datum.termino === orgFilter[0].statusFechar;
    //     }
    //   });
    // }
    // else {
    //   data = data.filter((datum) => !datum.termino);
    // }

    let updatedData = [];

    data.forEach((datum) => {
      let updatedDatum = {};
      let display = true;

      if (caseDetail) {
        const orgFilter = organizationsList.filter(
          (org) => org.id === caseDetail.id_organizacao
        );

        if (!orgFilter[0].statusFechar) {
          display = datum.termino === orgFilter[0].statusFechar;
        }
      }

      updatedDatum.id = datum.id_ticket_status;
      updatedDatum.label = datum.descricao_status;
      updatedDatum.finalizar = datum.termino;
      updatedDatum.display = display;
      updatedDatum.cor = datum.cor_alerta;

      updatedData.push(updatedDatum);
    });

    setStatusList(updatedData);
  };

  // Função que busca a lista de solicitações na API e transforma no modelo padrão dos componentes.
  const getListRequest = async (organizationId) => {
    const response = await APICase.getRequestList(
      token,
      user.userId,
      organizationId
    );

    if (response.status === 200) {
      const data = response.data;
      let updatedData = [];

      data.forEach((datum) => {
        let requestsIds = String(datum.id_solicitacao);

        requestsIds = requestsIds.split(',');

        requestsIds.forEach(id => {
          let updatedDatum = {};

          updatedDatum.id = parseInt(id.trim());
          updatedDatum.label = datum.descricao_solicitacao;

          updatedData.push(updatedDatum);
        });
      });

      updatedData = modifyArray(updatedData);

      setListRequest(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que busca a lista de motivos na API e transforma no modelo padrão dos componentes.
  const getListReasons = async () => {
    const response = await APICase.getReasonsList(token);

    if (response.status === 200) {
      const data = response.data;
      let updatedData = [];

      data.forEach((datum) => {
        let updatedDatum = {};

        updatedDatum.id = datum.id_motivo;
        updatedDatum.label = datum.desc_motivo;

        updatedData.push(updatedDatum);
      });

      setListReasons(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que busca a lista de integrações para o ingdesk inclusive a rota para o backend do ingweb
  const getRouteAudit = async () => {
    const response = await APICase.getListIntegrations(token);

    if (response.status === 200) {
      const data = response.data;
      const route = data.filter(
        (option) => option.desc_tipo_integracao === "IngWeb"
      );
      if (route.length !== 0) {
        dispatch(
          saveRoute({
            route: route[0].caminho_integracao,
          })
        );
      }
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que deleta o caso.
  const deleteCase = async () => {
    const response = await APICase.deleteCase(id, token);

    if (response.status === 200) {
      Toast("Caso Deletado!", "Caso deletado com sucesso.", "success");
    }

    history.push("/casos");
  };

  //Função que transforma as datas no padrão a ser enviado para a API e envia os dados necessários para atualizar o caso. Atualiza também o agente responsável pelo caso.
  const updateCase = async (data, date, logInfo) => {
    const objData = { ...data };

    objData.dt_abertura = format(
      new Date(objData.dt_abertura),
      "yyyy-MM-dd'T'HH:mm:ss.SSS"
    );
    objData.dt_ultima_atualizacao = format(
      new Date(),
      "yyyy-MM-dd'T'HH:mm:ss.SSS"
    );
    objData.prazo_inicio = format(
      new Date(objData.prazo_inicio),
      "yyyy-MM-dd'T'HH:mm:ss.SSS"
    );

    if (date) {
      objData.prazo_resolucao = format(
        new Date(date),
        "yyyy-MM-dd'T'HH:mm:ss.SSS"
      );
      objData.id_campo = 5;
    } else {
      objData.prazo_resolucao = format(
        new Date(objData.prazo_resolucao),
        "yyyy-MM-dd'T'HH:mm:ss.SSS"
      );
    }

    setCaseDetail(objData);

    const response = await APICase.updateCase(id, objData, token);

    if (response.status === 200 && caseDetail.usersAssociated.length === 0) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.associateUser(dataAssociate, token);
    }

    if (logInfo) {
      const responseLog = await APICase.insertLogTicket(token, logInfo);

      if (responseLog.status === 200) {
        setLogControl(false);
      }
    }

    // if (
    //   caseDetail.usersAssociated.length > 0 &&
    //   caseDetail.usersAssociated[0].id_usuario !== user.userId &&
    //   !caseDetail.usersAssociated[0].gestor
    // )

    if (
      caseDetail.usersAssociated.length > 0 &&
      caseDetail.usersAssociated[0].id_usuario !== user.userId
    ) {
      const dataAssociate = {
        id_usuario: user.userId,
        id_ticket: id,
      };

      await APICase.disassociateUser(
        id,
        caseDetail.usersAssociated[0].id_usuario,
        token
      );
      await APICase.associateUser(dataAssociate, token);
    }

    if (interaction.destination && !interaction.clientId) {
      handleInteractionSaveImmediate(id);
    }

    if (tabChoose === 6) {
      setCaseUpdates(true);
    }

    sendSaveInteractionPost();

    await Promise.allSettled([getDetails(), getInteractionList()]);
  };

  function handleInteractionSaveImmediate(caseId) {
    let objeto = {};

    // if (principalAdditionalField.length > 0) {
    //   variableValues.forEach(async (variable) => {
    //     const infoData = principalAdditionalField.find(
    //       (data) => data.id_campo_adicional === variable.id
    //     );

    //     if (infoData) {
    //       objeto = {
    //         ...objeto,
    //         [infoData.desc_campo_adicional]: variable.value,
    //       };
    //     }
    //   });
    // }

    if (caseDetail && caseDetail?.campos_adicionais?.length > 0) {
      caseDetail?.campos_adicionais.forEach(async (field) => {
        if (field) {
          objeto = {
            ...objeto,
            [field.nomecampoadd]: field.valorcampoadd,
          };
        }
      });
    }

    postMessage("sendMeLogInteraction", {
      active: true,
      destination: interaction.destination,
      caseId: caseId,
      clientId: caseDetail.id_contato,
      interactionId: interaction.interactionId,
      infos: {
        assunto: caseDetail.assunto,
        observacao: caseDetail.observacao,
        dt_abertura: caseDetail.dt_abertura,
        dt_ultima_atualizacao: caseDetail.dt_ultima_atualizacao,
        prazo_inicio: caseDetail.prazo_inicio,
        prazo_resolucao: caseDetail.prazo_resolucao,
        codigo_ticket: caseDetail.codigo_ticket,
        ...objeto,
      },
    });
  }

  const sendSaveInteractionPost = () => {
    let objeto = {};

    // if (principalAdditionalField.length > 0) {
    //   variableValues.forEach(async (variable) => {
    //     const infoData = principalAdditionalField.find(
    //       (data) => data.id_campo_adicional === variable.id
    //     );

    //     if (infoData) {
    //       objeto = {
    //         ...objeto,
    //         [infoData.desc_campo_adicional]: variable.value,
    //       };
    //     }
    //   });
    // }

    if (caseDetail && caseDetail?.campos_adicionais?.length > 0) {
      caseDetail?.campos_adicionais.forEach(async (field) => {
        if (field) {
          objeto = {
            ...objeto,
            [field.nomecampoadd]: field.valorcampoadd,
          };
        }
      });
    }

    postMessage("saveThisInteraction", {
      payload: true,
      caseId: id,
      destination: interaction.destination,
      infos: {
        assunto: caseDetail.assunto,
        observacao: caseDetail.observacao,
        dt_abertura: caseDetail.dt_abertura,
        dt_ultima_atualizacao: caseDetail.dt_ultima_atualizacao,
        prazo_inicio: caseDetail.prazo_inicio,
        prazo_resolucao: caseDetail.prazo_resolucao,
        codigo_ticket: caseDetail.codigo_ticket,
        ...objeto,
      },
    });
  };

  async function associateUser(agent) {
    if (caseDetail.usersAssociated.length === 0) {
      const dataAssociate = {
        id_usuario: agent.id,
        id_ticket: id,
      };

      const responseAssociate = await APICase.associateUser(
        dataAssociate,
        token
      );
    }

    if (
      caseDetail.usersAssociated.length > 0 &&
      caseDetail.usersAssociated[0].id_usuario !== agent.id
    ) {
      const dataAssociate = {
        id_usuario: agent.id,
        id_ticket: id,
      };

      await APICase.disassociateUser(
        id,
        caseDetail.usersAssociated[0].id_usuario,
        token
      );

      await APICase.associateUser(dataAssociate, token);
    }

    await getDetails();
  }

  async function changeCaseOwner(agent, sendNotif) {
    const objCase = cloneDeep(caseDetail);

    objCase.id_responsavel = agent.id;
    objCase.nome_responsavel = agent.label;
    objCase.nome_responsavel_atual = agent.label;

    const logInfo = {
      tipo_operacao: 2,
      id_campo: 25,
      id_campo_adicional: null,
      valor_atual: caseDetail.nome_responsavel,
      valor_novo: agent.label,
      id_ticket: id,
    };

    if (sendNotif) {
      const sendAlerts = {
        id_usuario_origem: user.userId,
        id_usuario_destino: agent.id,
        id_tipo_alerta: 1,
        id_ticket: id,
      };

      const response = await APICase.insertAlerts(token, sendAlerts);
    }

    updateCase(objCase, null, logInfo);
  }

  // function getListAdditionalFields(campos_adicionais) {
  //   const valores = principalAdditionalField.map(datum => {
  //     const findValue = campos_adicionais?.find(value => value.idcampoadd === datum.id_campo_adicional);

  //     return {
  //       id: datum.id_campo_adicional,
  //       value: findValue ? findValue.valorcampoadd : ""
  //     };
  //   });

  //   setAdditionalField(principalAdditionalField);
  //   setVariableValues(valores);
  // }

  async function getStatusRequestType(requestTypeId) {
    const response = await APICase.getStatusRequestType(token, requestTypeId);

    if (response.status === 200) {
      setNewStatus(response.data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  //Função que recebe as informações do comentário que é feito através do tippy que abre quando é selecionado o status concluído e envia esse comentário para a API.
  const postComment = async (text, files, responseComment, audio) => {
    try {
      let idType = 0;

      if (text) {
        idType = 1;
      } else if (audio) {
        idType = 3;
      } else {
        idType = 2;
      }

      const data = {
        desc_ticket_comentario: text || "",
        id_ticket: id,
        id_mimetype: 2,
        id_tipo_comentario: idType,
        id_ticket_comentario_parente: responseComment?.id_comentario || null,
      };

      const form = new FormData();
      form.append("data", JSON.stringify(data));

      for (const file of files) {
        form.append("data", file.file);
      }

      const response = await APIComments.createCommentCase(form, token);

      await getDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusTicket = (option) => {
    // if (option.id === 5) {
    //   return true;
    // }
    const objCase = cloneDeep(caseDetail);
    const logInfo = {
      tipo_operacao: 2,
      id_campo: 2,
      id_campo_adicional: null,
      valor_atual: caseDetail.descricao_ticket_status,
      valor_novo: option.label,
      id_ticket: id,
      id_campo_adicional: null,
    };
    objCase.id_ticket_status = option.id;
    if (option.finalizar === true) {
      objCase.dt_fechamento = formatDate(new Date());
    } else {
      objCase.dt_fechamento = null;
    }
    objCase.id_campo = 2;
    objCase.descricao_ticket_status = option.label;
    setCaseDetail(objCase);
    updateCase(objCase, null, logInfo);
  };

  const changeStatusAndEscalateTicket = async (option) => {
    const objCase = { ...caseDetail };
    const arrayEscalated = [
      {
        id_escalonamento: option.id,
        desc_escalonamento: option.label,
        id_ticket: id,
        id_escalonamento_atual:
          caseDetail.escalatedAssociated.length !== 0
            ? caseDetail.escalatedAssociated[0].id_escalonamento
            : 0,
      },
    ];
    objCase.escalatedAssociated = arrayEscalated;

    const response = await APICase.createStaggerSectorCase(
      token,
      arrayEscalated[0]
    );

    if (response.status !== 200) {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    } else {
      const statusEscalate = statusList.find((item) => item.id === 5);
      objCase.id_ticket_status = statusEscalate.id;
      objCase.dt_fechamento = null;
      objCase.id_campo = 2;
      objCase.descricao_ticket_status = statusEscalate.label;
      setCaseDetail(objCase);
      updateCase(objCase);
    }
  };

  const changeEscalateTicket = async (option) => {
    const objCase = { ...caseDetail };
    const logInfo = {
      tipo_operacao: 2,
      id_campo: "20",
      id_campo_adicional: null,
      valor_atual: caseDetail.descricao_escalonamento,
      valor_novo: option.label,
      id_ticket: id,
      id_campo_adicional: null,
    };

    objCase.id_escalonamento = option.id;
    objCase.descricao_escalonamento = option.label;

    setCaseDetail(objCase);
    updateCase(objCase, null, logInfo);
  };

  //ANTIGA FUNÇÃO DE ESCALONAMENTO
  // const changeEscalateTicket = async (option) => {
  //   const objCase = { ...caseDetail };

  //   objCase.id_escalonamento = option.id;
  //   objCase.descricao_escalonamento = option.label;

  //   const arrayEscalated = [
  //     {
  //       id_escalonamento: option.id,
  //       desc_escalonamento: option.label,
  //       id_ticket: id,
  //       id_escalonamento_atual:
  //         caseDetail.escalatedAssociated.length !== 0
  //           ? caseDetail.escalatedAssociated[0].id_escalonamento
  //           : 0,
  //     },
  //   ];

  //   objCase.escalatedAssociated = arrayEscalated;
  //   setCaseDetail(objCase);

  //   const response = await APICase.createStaggerSectorCase(
  //     token,
  //     arrayEscalated[0]
  //   );

  //   if (response.status !== 200) {
  //     Toast(
  //       "Falha, não foi possível carregar as informações!",
  //       "Verifique sua conexão e tente novamente mais tarde",
  //       "error"
  //     );
  //   }
  // };

  //Função que verifica o tamanho da tela e dependendo do valor seta o estado de ocultar uma "tab"(tabHide) com o id da tab que deseja ocultar.
  function checkResize() {
    if (refPage?.current?.clientWidth > 800 && !tabHide) {
      setTabHide("1");
    } else if (refPage?.current?.clientWidth <= 800 && tabHide) {
      setTabHide("");
    }
  }

  const toggleVariableField = (e) => {
    if (openVariable === true) {
      setOpenVariable(false);
    } else {
      setOpenVariable(true);
    }
    // return setOpenVariable(!openVariable);
  };

  const checkRequiredFields = (field, additionalFieldsParent) => {
    const integrationField = additionalFieldsIntegration.find(
      integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
    );

    if (!integrationField) return true;

    const allRequiredFieldsFilled = integrationField.campos_adicionais_obrigatorios.every(integrationField => {
      const correspondingField = additionalFieldsParent.find(
        parentField => parentField.id_campo_adicional === integrationField.idcampos
      );

      return correspondingField &&
        correspondingField.valor_default !== "" &&
        correspondingField.valor_default !== null &&
        correspondingField.valor_default !== undefined;
    });

    return allRequiredFieldsFilled;
  };

  const handleCustomFieldTypeSelect = (field, value, onChangeHandler) => {
    const requiredFieldsFilled = checkRequiredFields(field, additionalFieldsParent);

    if (!requiredFieldsFilled) {
      const integrationField = additionalFieldsIntegration.find(
        integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
      );

      if (integrationField) {
        const requiredFields = integrationField.campos_adicionais_obrigatorios.map(integrationField => {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          return correspondingField ? correspondingField.desc_campo_adicional : null;
        }).filter(desc => desc !== null);

        Toast(
          "Não foi possível selecionar esse campo!",
          <p>Para selecionar, preencha os campos obrigatórios: <b>{requiredFields.join(', ')}</b></p>,
          "warn"
        );
      }
      return;
    }

    onChangeHandler(field.id_campo_adicional, value.id);
    getAdditionalChildFields(field.id_campo_adicional, value.label);

    setCurrentStatusTexts(prev => ({
      ...prev,
      [field.id_campo_adicional]: value.label,
    }));

    const triggerField = additionalFieldsIntegration.find(
      integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
    );

    if (triggerField) {
      setIsOptionSelected(true);
    }
  };

  const renderAdditionalChildFields = (field, onChangeHandler, fieldError) => {
    let renderedFields = [
      <FieldContainer
        key={field.id_campo_adicional}
        title={field.desc_campo_adicional}
        required={field.obrigatorio === true && field.obrigatorio}
        titleSize="small"
        className="custom-field-container"
        labelRightIcon={<FaQuestionCircle />}
        labelRightTooltipContent={field.label_campo_adicional}
      >
        <Select
          includeSearchField
          placeholder="Selecione"
          className="modal-create-case__dropdown"
          value={field.valor_default}
          options={JSON.parse(field.json)}
          onSelect={(value) => handleCustomFieldTypeSelect(field, value, onChangeHandler)}
          error={fieldError}
        />
      </FieldContainer>,
    ];

    const childFields = additionalChildFields[field.id_campo_adicional] || [];

    if (childFields?.length > 0) {
      const additionalRenderedFields = childFields?.map((childField, index) => {
        const isChildRequiredAndEmpty = (child) => {
          return child.obrigatorio === true && !child.valor_default;
        };

        const childFieldError = requiredChild && isChildRequiredAndEmpty(childField);

        return (
          <FieldContainer
            key={childField.id_campo_adicional}
            title={childField.desc_campo_adicional}
            required={childField.obrigatorio === true && childField.obrigatorio}
            titleSize="small"
            className={`custom-field-container ${childField.id_tipo_campo_adicional === 10 && "textarea-container"}`}
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={childField.label_campo_adicional}
          >
            {childField.id_tipo_campo_adicional === 1 && (
              <Input
                type="number"
                placeholder="Digite a prioridade"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 2 && (
              <DatePicker.TimeRangeInput
                automaticSet
                className=""
                valueTime={childField.valor_default}
                setValueTime={(date) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: date };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                errorInput={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 3 && (
              <DatePicker.DateRangeInput
                selectRangeType="start"
                value={childField.valor_default}
                setDate={(date) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: date };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                isClearable={false}
              />
            )}

            {childField.id_tipo_campo_adicional === 4 && (
              <Select
                placeholder="Selecione"
                value={childField.valor_default}
                onSelect={(value) => {
                  const newValue = value;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue.id };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 5 && (
              <Input
                placeholder="Digite as horas"
                type={childField.mascara ? "numberMask" : "text"}
                mask={childField.mascara ? childField.mascara : ""}
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 6 && (
              <Input
                type={childField.mascara ? "numberMask" : "text"}
                mask={childField.mascara ? childField.mascara : ""}
                placeholder="Digite o prazo em horas"
                value={field.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 7 && (
              <Input
                type="email"
                placeholder="Digite o e-mail"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 8 && (
              <Input
                type="monetary"
                placeholder="Digite o valor"
                prefix={childField.mascara ?? "R$"}
                value={childField.valor_default ?? ""}
                onValueChange={(values, sourceInfo) => {
                  const { floatValue } = values;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: floatValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 9 && (
              <Checkbox
                isChecked={childField.valor_default}
                onChange={(newValue) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue ? true : null };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
              />
            )}

            {childField.id_tipo_campo_adicional === 10 && (
              <Input
                placeholder="Digite a informação aqui"
                type="textarea"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}
          </FieldContainer>
        );
      });

      renderedFields = renderedFields.concat(additionalRenderedFields);
    }

    return renderedFields;
  }

  //################### NOVA FUNÇÃO PARA RENDERIZAR CAMPOS PERSONALIZADOS ###################
  const renderCustomFields = (field, onChangeHandler, onBackspaceHandler) => {
    const isFieldRequiredAndEmpty = (field) => {
      return field.obrigatorio === true && !field.valor_default;
    };

    const fieldError = requiredField && isFieldRequiredAndEmpty(field);

    switch (field.id_tipo_campo_adicional) {
      case 1:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={`${field.desc_campo_adicional}`}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="number"
              placeholder="Digite a prioridade aqui"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 2:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.TimeRangeInput
              automaticSet
              className=""
              valueTime={field.valor_default}
              setValueTime={(date) =>
                onChangeHandler(field.id_campo_adicional, date)
              }
              errorInput={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 3:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.DateRangeInput
              selectRangeType="start"
              value={field.valor_default}
              setDate={(date) =>
                onChangeHandler(field.id_campo_adicional, date)
              }
              isClearable={false}
            />
          </FieldContainer>
        );
        break;

      case 4:
        return renderAdditionalChildFields(field, onChangeHandler, fieldError)
        break;

      case 5:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              placeholder="Digite a informação aqui"
              type={field.mascara ? "numberMask" : "text"}
              mask={field.mascara ? field.mascara : ""}
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        )

      case 6:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type={field.mascara ? "numberMask" : "text"}
              mask={field.mascara ? field.mascara : ""}
              placeholder="Digite o prazo em horas"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 7:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="email"
              placeholder="Digite o e-mail"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 8:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="monetary"
              placeholder="Digite o valor"
              prefix={field.mascara ?? "R$"}
              value={field.valor_default ?? ""}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              onValueChange={(values, sourceInfo) => {
                const { floatValue } = values;

                onChangeHandler(field.id_campo_adicional, floatValue)
              }}
            />
          </FieldContainer>
        );
        break;

      case 9:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Checkbox
              isChecked={field.valor_default}
              onChange={(newValue) => {
                onChangeHandler(
                  field.id_campo_adicional,
                  newValue ? true : null
                );
              }}
            />
          </FieldContainer>
        );
        break;

      case 10:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className={`custom-field-container ${field.id_tipo_campo_adicional === 10 && "textarea-container"}`}
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              placeholder="Digite a informação aqui"
              type="textarea"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        )
        break;
      default:
        break;
    }
  };

  //################### ANTIGA FUNÇÃO PARA RENDERIZAR CAMPOS PERSONALIZADOS ###################
  // const handleVariableFields = (field) => {
  //   let actualValue = variableValues.find(
  //     (value) => value.id === field.id_campo_adicional
  //   );

  //   switch (field.tipo_campo_adicional) {
  //     case "text":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="field-container-case-subject"
  //         >
  //           <Input
  //             type={field.mascara ? "numberMask" : "text"}
  //             mask={field.mascara ? field.mascara : ""}
  //             placeholder="Digite a informação aqui"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = event;
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //             disabled={
  //               permissionProfile.camposAdicionais.edicao ? false : true
  //             }
  //           />
  //         </FieldContainer>
  //       );

  //     case "select":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="field-container-case-request-type "
  //           htmlFor={""}
  //         >
  //           <Select
  //             placeholder="Selecione"
  //             className={"modal-create-case__dropdown"}
  //             value={actualValue.value}
  //             options={JSON.parse(field.json)}
  //             onSelect={(event) => {
  //               actualValue.value = event.label;
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={false}
  //             canBeEditable={permissionProfile.camposAdicionais.edicao}
  //           />
  //         </FieldContainer>
  //       );

  //     case "integer":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="container-addContact"
  //         >
  //           <Input
  //             className="input-telephone"
  //             type="number"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = Number(event);
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //             disabled={
  //               permissionProfile.camposAdicionais.edicao ? false : true
  //             }
  //             placeholder={"Digite o valor aqui"}
  //           />
  //         </FieldContainer>
  //       );

  //     default:
  //       return <></>;
  //   }
  // };

  function isValidateCustomFields() {
    const fieldsRequiredParent = additionalFieldsParent.filter(
      (field) => field?.obrigatorio === true && !field.valor_default
    );

    const childFieldsArray = Object.values(additionalChildFields);

    const fieldsRequiredChild = childFieldsArray.flatMap(childFields =>
      childFields.filter((child) => child?.obrigatorio === true && !child.valor_default)
    );

    if (fieldsRequiredParent.length > 0) {
      setRequiredField(true);
      return false;
    } else if (fieldsRequiredChild.length > 0) {
      setRequiredChild(true);
      return false;
    }

    return true;
  }

  //################### FUNÇÃO QUE ATUALIZA OS CAMPOS PERSONALIZADOS PAI ###################
  const updateAdditionalFields = async (fields, token, arrayCase) => {
    const promises = fields.map(async (field) => {
      const existingCampo = arrayCase?.campos_adicionais.find(campo => campo.idcampoadd === field.id_campo_adicional);

      const shouldUpdate = existingCampo ? field.valor_default !== existingCampo.valorcampoadd : true;

      if (!shouldUpdate) {
        return 200;
      }

      const dataField = {
        id_ticket: arrayCase.id_ticket,
        id_campo: field.id_campo_adicional,
        valor_campo: field.valor_default,
        label: currentStatusTexts[field.id_campo_adicional] || ""
      };

      let response;

      if (existingCampo) {
        response = await APICase.updateTicketAdditionalFields(token, dataField)
      } else {
        if (field.valor_default !== null) {
          response = await APICase.createTicketAdditionalFields(token, dataField)
        } else {
          return 200
        }
      }

      if (response.status !== 200) {
        Toast(
          "Não foi possível atualizar os campos adicionais pai!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
      }

      return response.status;
    });

    return Promise.all(promises);
  };

  //################### FUNÇÃO QUE ATUALIZA OS CAMPOS PERSONALIZADOS FILHO ###################
  const updateAdditionalChildFields = async (childs, token, arrayCase) => {
    const promises = childs.map(async (childFields) => {
      const childPromises = childFields.map(async (childField) => {
        const existingCampo = arrayCase?.campos_adicionais.find(campo => campo.idcampoadd === childField.id_campo_adicional);

        const shouldUpdate = existingCampo ? childField.valor_default !== existingCampo.valorcampoadd : true;

        if (!shouldUpdate) {
          return 200;
        }

        const dataChild = {
          id_ticket: arrayCase.id_ticket,
          id_campo: childField.id_campo_adicional,
          valor_campo: childField.valor_default,
          label: currentStatusTexts[childField.id_campo_adicional] || ""
        };

        let response;

        if (existingCampo) {
          response = await APICase.updateTicketAdditionalFields(token, dataChild)
        } else {
          if (childField.valor_default !== null) {
            response = await APICase.createTicketAdditionalFields(token, dataChild)
          } else {
            return 200
          }
        }

        if (response.status !== 200) {
          Toast(
            "Não foi possível atualizar os campos adicionais filho!",
            "Verifique sua conexão e tente novamente mais tarde.",
            "error"
          );
        }

        return response.status;
      });

      return Promise.all(childPromises);
    });

    return Promise.all(promises);
  };

  //################### NOVA FUNÇÃO QUE LIDA COM A ATUALIZAÇÃO DOS CAMPOS PERSONALIZADOS ###################
  const handleUpdateAdditional = async () => {
    if (!isValidateCustomFields()) {
      setRequiredField(true);
      setRequiredChild(true);

      Toast(
        "Erro ao atualizar campos adicionais!",
        "Preencha os campos obrigatórios.",
        "error",
      );

      return;
    }

    setIsUpdatingCustomFields(true);

    const arrayCase = { ...caseDetail };

    const logInfoField = {
      tipo_operacao: 2,
      id_campo: 26,
      valor_atual: "",
      valor_novo: "",
      id_campo_adicional: null,
      id_ticket: id,
    };

    const logInfoChild = {
      tipo_operacao: 2,
      id_campo: 26,
      valor_atual: "",
      valor_novo: "",
      id_campo_adicional: null,
      id_ticket: id,
    };

    const updateParentFieldsPromise = updateAdditionalFields(additionalFieldsParent, token, arrayCase);

    const childFieldsArray = Object.values(additionalChildFields);

    const updateChildFieldsPromise = childFieldsArray.length > 0 ?
      updateAdditionalChildFields(childFieldsArray, token, arrayCase) : Promise.resolve([]);

    const [verificarResponse, checkChildCustomFields] = await Promise.all([
      updateParentFieldsPromise,
      updateChildFieldsPromise,
    ]);

    for (const field of additionalFieldsParent) {
      const correspondingField = caseDetail?.campos_adicionais?.find(
        item => item.idcampoadd === field.id_campo_adicional
      );

      let valorCampoAdd = correspondingField?.valorcampoadd;

      if (correspondingField && valorCampoAdd !== null && valorCampoAdd !== field.valor_default) {
        logInfoField.valor_atual = correspondingField.valorcampoadd;
        logInfoField.valor_novo = field.valor_default;
        logInfoField.id_campo_adicional = field.id_campo_adicional;

        await updateCase(arrayCase, null, logInfoField);
      }
    }

    for (const childFields of childFieldsArray) {
      for (const childField of childFields) {
        const correspondingField = caseDetail?.campos_adicionais?.find(
          item => item.idcampoadd === childField.id_campo_adicional
        );

        let valorCampoAdd = correspondingField?.valorcampoadd;

        if (correspondingField && valorCampoAdd !== null && valorCampoAdd !== childField.valor_default) {
          logInfoChild.valor_atual = correspondingField.valorcampoadd;
          logInfoChild.valor_novo = childField.valor_default;
          logInfoChild.id_campo_adicional = childField.id_campo_adicional;

          await updateCase(arrayCase, null, logInfoChild);
        }
      }
    }

    if (
      verificarResponse.every(response => response === 200) ||
      checkChildCustomFields.every(response => response === 200)
    ) {
      Toast(
        "Campos atualizados!",
        "Campos atualizados com sucesso.",
        "success"
      );

      handleInteractionSaveImmediate(id);
    }

    await getDetails();

    setIsUpdatingCustomFields(false);
  };

  //################### ANTIGA FUNÇÃO QUE LIDA COM A ATUALIZAÇÃO DOS CAMPOS PERSONALIZADOS ###################
  // const handleUpdateAdditional = async () => {
  //   let verificarTodos = 0;
  //   const arrayCase = { ...caseDetail };

  //   for (let index = 0; index < variableValues.length; index++) {
  //     if (arrayCase.campos_adicionais.length === 0) {
  //       const valor1 = variableValues[index].value;

  //       if (valor1 === "") verificarTodos++;
  //     } else {
  //       const valor1 = variableValues[index].value;
  //       let valor2 = "";

  //       if (arrayCase.campos_adicionais[index]) {
  //         valor2 = arrayCase.campos_adicionais[index].valorcampoadd;
  //         if (valor1 === valor2) verificarTodos++;
  //       }
  //     }
  //   }

  //   if (verificarTodos === variableValues.length) {
  //     return console.log("Nenhum valor foi alterado.");
  //   }

  //   let verificarResponse = [];
  //   for (const variable of variableValues) {
  //     //adicionar campo a mais, problema
  //     let responseField;
  //     let dataField = {
  //       id_ticket: arrayCase.id_ticket,
  //       id_campo: variable.id,
  //       valor_campo: variable.value,
  //     };
  //     if (arrayCase.campos_adicionais.length > 0) {
  //       const newValor = arrayCase.campos_adicionais.find(
  //         (campo) => campo.idcampoadd === variable.id
  //       );

  //       if (newValor) {
  //         newValor.valorcampoadd = variable.value;
  //         responseField = await APICase.updateTicketAdditionalFields(
  //           token,
  //           dataField
  //         );
  //       } else {
  //         responseField = await APICase.createTicketAdditionalFields(
  //           token,
  //           dataField
  //         );
  //       }
  //     } else {
  //       responseField = await APICase.createTicketAdditionalFields(
  //         token,
  //         dataField
  //       );
  //     }

  //     if (responseField.status !== 200) {
  //       Toast(
  //         "Não foi possível adicionar os campos variaveis a esse caso!",
  //         "Tente novamente mais tarde!.",
  //         "warn"
  //       );
  //     }

  //     verificarResponse.push(responseField.status);
  //   }

  //   if (verificarResponse.every((response) => response === 200)) {
  //     Toast(
  //       "Campos atualizados!",
  //       "Campos atualizados com sucesso.",
  //       "success"
  //     );

  //     handleInteractionSaveImmediate(id);
  //   }

  //   await getDetails();
  // };

  const handleOrganizationSelect = (event) => {
    const valuesObject = { ...caseDetail };

    const logInfo = {
      tipo_operacao: 2,
      id_campo: 23,
      id_campo_adicional: null,
      valor_atual: valuesObject.nome_empresa,
      valor_novo: event.label,
      id_ticket: id,
    };

    valuesObject.id_organizacao = event.id;
    valuesObject.nome_empresa = event.label;

    setCaseDetail(valuesObject);
    updateCase(valuesObject, null, logInfo);
    getListRequest(event.id);

    setClearFieldTrigger(ClearFieldTrigger.ALL);
    // setCaseData({ ...caseData, id_organizacao: event.id });
    // setOrganizationTeste(event.id);
  };

  const handleTypeSelect = (option) => {
    const valuesObject = { ...caseDetail };

    const logInfo = {
      tipo_operacao: 2,
      id_campo: 3,
      valor_atual: valuesObject.descricao_solicitacao,
      valor_novo: option.label,
      id_ticket: id,
      id_campo_adicional: null,
    };

    const requestTypeId = {
      id_tipo_solicitacao: option.id,
    };

    valuesObject.id_solicitacao = option.id;
    valuesObject.descricao_solicitacao = option.label;
    valuesObject.id_campo = 3;

    setCaseDetail(valuesObject);

    changeTypeRequest(option.id, true, caseDetail.id_organizacao, logInfo);
    getStatusRequestType(requestTypeId);

    setClearFieldTrigger(ClearFieldTrigger.REASON_AND_SUBREASON);
  };

  const handleReasonSelect = (option) => {
    const valuesObject = { ...caseDetail };

    const logInfo = {
      tipo_operacao: 2,
      id_campo: 15,
      id_campo_adicional: null,
      valor_atual: valuesObject.descricao_motivo,
      valor_novo: option.label,
      id_ticket: id,
    };

    getSlaRule(
      option.id,
      caseDetail.id_solicitacao,
      option.label,
      caseDetail.id_organizacao,
      logInfo
    );
    changeSubTypeRequest(
      option.id,
      true,
      caseDetail.id_organizacao,
      caseDetail.id_solicitacao
    );

    setClearFieldTrigger(ClearFieldTrigger.SUBREASON);
  };

  const handleSubreasonSelect = (option) => {
    const valuesObject = { ...caseDetail };

    const logInfo = {
      tipo_operacao: 2,
      id_campo: 22,
      id_campo_adicional: null,
      valor_atual: valuesObject.desc_submotivo,
      valor_novo: option.label,
      id_ticket: id,
    };

    valuesObject.id_submotivo = option.id;
    valuesObject.desc_submotivo = option.label;

    setCaseDetail(valuesObject);

    getSlaRuleSub(
      option.id,
      caseDetail.id_solicitacao,
      option.label,
      caseDetail.id_motivo,
      caseDetail.id_organizacao,
      logInfo
    );
  };

  //Função que busca a lista de organizações na API e transforma no modelo padrão dos componentes.
  async function getListOrganizations() {
    if (user.organizationsCreate) {
      const data = JSON.parse(user.organizationsCreate);
      let dataUpdated = [];

      data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.idorganizacao;
        requestItem.label = datum.nomeorganizacao;
        requestItem.statusFechar = datum.fecharcaso;

        dataUpdated.push(requestItem);
      });

      setOrganizationsList(dataUpdated);
    }
  }

  function renderCaseFields() {
    return (
      <div className="case-details-field">
        <FieldContainer
          title="Contato:"
          titleSize="small"
          className={`case-client ${organizationsList.length === 0 ? "grid-span-2" : ""}`}
          htmlFor=""
        >
          <SelectSearch
            paginated
            // width="219px"
            placeholder="Busque pelo nome do contato"
            getOptions={getListClients}
            value={clientData}
            onSelect={(client) => {
              setClientData(client);
              setLogControl({
                tipo_operacao: 2,
                id_campo: 1,
                valor_atual: caseDetail.nome_contato,
                valor_novo: client.label,
                id_ticket: id,
              });
              const logInfo = {
                tipo_operacao: 2,
                id_campo: 1,
                id_campo_adicional: null,
                valor_atual: caseDetail.nome_contato,
                valor_novo: client.label,
                id_ticket: id,
                id_campo_adicional: null,
              };
              const objCase = { ...caseDetail };
              objCase.id_contato = client.id;
              objCase.nome_contato = client.label;
              objCase.nome_empresa = client.caption1;
              objCase.id_campo = 1;
              setCaseDetail(objCase);
              updateCase(objCase, null, logInfo);
            }}
            canBeEditable={permissionProfile.contato.edicao}
          />
        </FieldContainer>

        <FieldContainer
          title="Empresa do contato:"
          titleSize="small"
          className="case-company"
        >
          {/* {caseDetail.nome_empresa ? (
            <Typography.H5 color="gray-180" className="case-company__name">
              {caseDetail.nome_empresa}
            </Typography.H5>
          ) : (
            <Typography.H5 color="gray-180" className="case-company__name">
              Sem Empresa
            </Typography.H5>
          )} */}
          <SelectSearch
            paginated
            placeholder="Busque pelo nome da empresa"
            className={"create-case-select"}
            getOptions={getListEnterprises2}
            value={{
              id: null,
              label: caseDetail.nome_empresa ?? "Sem Empresa",
            }}
            canBeEditable={false}
          />
        </FieldContainer>

        <FieldContainer
          title="Empresa:"
          titleSize="small"
          className="case-company"
        >
          <SelectSearch
            paginated
            placeholder="Busque pelo nome da empresa"
            className={"create-case-select"}
            // valueDirect
            getOptions={getListEnterprises2}
            value={{
              id: caseDetail.id_empresa_ticket,
              label: caseDetail.nome_empresa_ticket,
            }}
            onSelect={(client) => {
              const objCase = { ...caseDetail };
              const logInfo = {
                tipo_operacao: 2,
                id_campo: 24,
                id_campo_adicional: null,
                valor_atual: caseDetail.nome_empresa_ticket,
                valor_novo: client.label,
                id_ticket: id,
                id_campo_adicional: null,
              };
              objCase.id_empresa_ticket = client.id;
              objCase.nome_empresa_ticket = client.label;
              setCaseDetail(objCase);
              updateCase(objCase, null, logInfo);
            }}
            canBeEditable={permissionProfile.empresa.edicao}
          />
        </FieldContainer>

        {organizationsList.length > 0 &&
          permissionProfile.organizacao.visualizacao && (
            <FieldContainer
              title="Organização"
              titleSize="small"
              className={`case-organization`}
              htmlFor=""
            >
              <Select
                includeSearchField
                placeholder="Selecione"
                // className={"modal-create-case__dropdown"}
                value={caseDetail.id_organizacao}
                // value={organizationTeste}
                options={organizationsList}
                onSelect={(event) => handleOrganizationSelect(event)}
                // error={validationWarn ? !caseData.id_organizacao : false}
                canBeEditable={permissionProfile.organizacao.edicao}
              />
            </FieldContainer>
          )}

        <FieldContainer
          title="Tipo:"
          titleSize="small"
          className="case-details-deadline__select"
        >
          <CustomDropdown
            placeholder="Selecione"
            value={caseDetail.id_solicitacao}
            options={listRequest}
            onSelect={(option) => {
              handleTypeSelect(option);
            }}
            editable={permissionProfile.solicitacao.edicao}
          />
        </FieldContainer>

        <FieldContainer
          title="Motivo:"
          titleSize="small"
          className="case-details-deadline__select"
        >
          <CustomDropdown
            placeholder="Selecione"
            options={listReasons}
            value={caseDetail.id_motivo}
            onSelect={(option) => handleReasonSelect(option)}
            editable={permissionProfile.motivo.edicao}
          />
        </FieldContainer>

        <FieldContainer
          title="Sub-motivo:"
          titleSize="small"
          className="case-details-deadline__select"
        >
          <CustomDropdown
            placeholder="Selecione"
            options={listSubReasons}
            value={caseDetail.id_submotivo}
            onSelect={(option) => handleSubreasonSelect(option)}
            editable={permissionProfile.submotivo.edicao}
          />
        </FieldContainer>

        <FieldContainer title="Prazo:" titleSize="small" htmlFor="">
          <div
            className="field-container-case-date-time__container"
            style={{ pointerEvents: editableField ? "" : "none" }}
          >
            <DatePicker.TimeRangeInput
              valueTime={startDate}
              widthTotal={216}
              errorTime={
                isPast(new Date(startDate))
                  ? caseDetail.id_ticket_status === 3 ||
                    caseDetail.id_ticket_status === 4
                    ? isAfter(
                      new Date(caseDetail.dt_fechamento),
                      new Date(startDate)
                    )
                      ? true
                      : false
                    : true
                  : false
              }
              setValueTime={(data) => {
                setStartDate(data);
                const logInfo = {
                  tipo_operacao: 2,
                  id_campo: 5,
                  id_campo_adicional: null,
                  valor_atual: caseDetail.prazo_resolucao,
                  valor_novo: data,
                  id_ticket: id,
                  id_campo_adicional: null,
                };
                updateCase(caseDetail, data, logInfo);
              }}
              disable={editableField}
            />
          </div>
        </FieldContainer>

        <FieldContainer
          title="Prioridade"
          titleSize="small"
          className="case-priority"
        >
          <DropdownPriority
            value={priority}
            options={priorityList}
            label
            onClick={(value) => {
              setPriority(value);
              const arrayCase = { ...caseDetail };
              const logInfo = {
                tipo_operacao: 2,
                id_campo: 4,
                id_campo_adicional: null,
                valor_atual: caseDetail.descricao_criticidade,
                valor_novo: value.label,
                id_ticket: id,
                id_campo_adicional: null,
              };
              arrayCase.descricao_criticidade = value.label;
              arrayCase.id_criticidade = value.id;
              arrayCase.id_campo = 4;
              setCaseDetail(arrayCase);
              updateCase(arrayCase, null, logInfo);
            }}
          />
        </FieldContainer>

        <FieldContainer
          title="Canal de Origem:"
          titleSize="small"
          className="case-channel"
        >
          {caseDetail.possui_interacao ? (
            <div className="case-channel-origin">
              {caseDetail.protocolo_externo ? (
                <>
                  <Channel
                    media={caseDetail.id_canal}
                    nameMedia={caseDetail.nome_canal?.toLowerCase()}
                  />
                  <Typography.H5 color="gray-400">
                    {caseDetail.protocolo_externo}
                  </Typography.H5>
                </>
              ) : (
                <>
                  <Channel
                    media={caseDetail.id_canal}
                    nameMedia={caseDetail.nome_canal?.toLowerCase()}
                  />
                  <Typography.H5 color="gray-400">
                    {caseDetail.nome_canal}
                  </Typography.H5>
                </>
              )}
            </div>
          ) : (
            <DropdownChannel
              includeSearchField
              value={{
                id: caseDetail.id_canal ? caseDetail.id_canal : 0,
                label: caseDetail.nome_canal ? caseDetail.nome_canal : " ",
              }}
              options={channelList}
              onClick={(event) => {
                const arrayCase = { ...caseDetail };
                const logInfo = {
                  tipo_operacao: 2,
                  id_campo: 17,
                  id_campo_adicional: null,
                  valor_atual: caseDetail.nome_canal,
                  valor_novo: event.label,
                  id_ticket: id,
                  id_campo_adicional: null,
                };
                arrayCase.id_canal = event.id;
                arrayCase.nome_canal = event.label;
                arrayCase.id_campo = 17;
                setCaseDetail(arrayCase);
                updateCase(arrayCase, null, logInfo);
              }}
              errorType="border"
              label
            />
          )}
        </FieldContainer>

        <FieldContainer
          title="Status"
          titleSize="small"
          className="case-status"
        >
          <DropdownStatus
            searchStatus
            value={caseDetail.id_ticket_status}
            options={statusList}
            commentIdStatus={3}
            idTicket={id}
            escalateIdStatus={5}
            escalateList={escalateList}
            changeStatusAndEscalateTicket={changeStatusAndEscalateTicket}
            onAdd={(text, files, response, audio) =>
              postComment(text, files, response, audio)
            }
            onClick={(option) => {
              setNewStatus([]);
              setChangedStatus(false);
              changeStatusTicket(option);
            }}
            screen="details"
            type={permissionProfile.status.edicao ? "select" : ""}
            placement="top"
            newStatus={newStatus}
            changedStatus={changedStatus}
            changeDisplay
          />
        </FieldContainer>

        {/* {caseDetail.escalatedAssociated.length !== 0 && ( */}
        <FieldContainer
          title="Escalado para"
          titleSize="small"
          className="case-escalate"
        >
          <DropdownEscalate
            includeSearchField
            value={caseDetail.id_escalonamento}
            options={escalateList}
            disable={false}
            onClick={(option) => {
              changeEscalateTicket(option);
            }}
          />
        </FieldContainer>
        {/* )} */}
      </div>
    );
  }

  window.onresize = checkResize;

  // function getData() {
  //   setTimeout(async () => {
  //     await getCaseInfo();
  //   }, [1000]);
  //   // sendSaveInteractionPost();
  // }

  // async function getData() {
  //   setTimeout(async () => {
  //     await getCaseInfo();
  //   }, 1000);
  // }

  async function getAdditionalFieldsParent() {
    const response = await APIAdditionalFields.getAdditionalFieldsParent(token);

    if (response.status === 200) {
      const additionalFields = response.data;

      if (caseDetail?.campos_adicionais) {
        const combinedFields = additionalFields.map(field => {
          const correspondingField = caseDetail.campos_adicionais?.find(
            detailField => detailField.idcampoadd === field.id_campo_adicional
          );

          if (correspondingField) {
            return {
              ...field,
              valor_default: field.id_tipo_campo_adicional === 4 ?
                Number(correspondingField.valorcampoadd) : correspondingField.valorcampoadd === "null" ?
                  null : correspondingField.valorcampoadd,
              desc_campo_adicional: field.desc_campo_adicional,
            };
          } else {
            return field;
          }
        });

        setAdditionalFieldsParent(combinedFields);
      } else {
        setAdditionalFieldsParent(additionalFields);
      }
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  };

  async function getAdditionalChildFields(fieldId, statusText) {
    const response = await APIAdditionalFields.getAdditionalChildFields(token, fieldId, statusText);

    if (response.status === 200) {
      const additionalChildFields = response.data;

      const combinedFields = additionalChildFields.map(field => {
        const correspondingField = caseDetail.campos_adicionais.find(
          detailField => detailField.idcampoadd === field.id_campo_adicional
        );

        if (correspondingField) {
          return {
            ...field,
            valor_default: field.id_tipo_campo_adicional === 4 ?
              Number(correspondingField.valorcampoadd) : correspondingField.valorcampoadd === "null" ?
                null : correspondingField.valorcampoadd,
            desc_campo_adicional: field.desc_campo_adicional,
          };
        } else {
          return field;
        }
      });

      setAdditionalChildFields(prevMap => ({
        ...prevMap,
        [fieldId]: combinedFields
      }));
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getAdditionalFieldsIntegration() {
    const response = await APIAdditionalFields.getAdditionalFieldsIntegration(token);

    if (response.status === 200) {
      const updateData = response.data.map(item => ({
        ...item,
        campos_adicionais_obrigatorios: JSON.parse(item.campos_adicionais_obrigatorios),
        valor_padrao: item.valor_padrao === "null" && null
      }))

      setAdditionalFieldsIntegration(updateData)
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function postProcessedAdditionalFields(
    additionalFieldsIntegration,
    additionalFieldsParent
  ) {
    try {
      const results = [];

      for (const field of additionalFieldsIntegration) {
        const {
          url,
          metodo,
          token,
          campos_adicionais_obrigatorios,
          id_campo_gatilho,
          id_campo_adicional_retorno,
          valor_padrao
        } = field;

        const triggerField = additionalFieldsParent.find(
          parentField => parentField.id_campo_adicional === id_campo_gatilho
        );

        const triggerFieldFilled =
          triggerField &&
          triggerField.valor_default !== "" &&
          triggerField.valor_default !== null &&
          triggerField.valor_default !== undefined;

        if (!triggerFieldFilled || !isOptionSelected) {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
          continue;
        }

        const allRequiredFieldsFilled = campos_adicionais_obrigatorios.every(integrationField => {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          return correspondingField &&
            correspondingField.valor_default !== "" &&
            correspondingField.valor_default !== null &&
            correspondingField.valor_default !== undefined;
        });

        if (!allRequiredFieldsFilled) {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
          continue;
        }

        let payload = {};

        for (const integrationField of campos_adicionais_obrigatorios) {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          payload[correspondingField.desc_campo_adicional] =
            correspondingField ? correspondingField.valor_default : valor_padrao;
        }

        const triggerFieldDesc = triggerField.desc_campo_adicional;
        payload[triggerFieldDesc] = triggerField.valor_default;

        const config = {
          method: metodo.toLowerCase(),
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(payload)
        };

        const response = await axios(config);

        if (response.status === 200 && response.data) {
          const returnValue = response.data;

          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: response.data || valor_padrao
          });

          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === id_campo_adicional_retorno
          );

          if (correspondingField) {
            correspondingField.valor_default = returnValue.id || returnValue.valor;
          }
        } else {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
        }
      }

      return results;
    } catch (error) {
      console.log('postProcessedAdditionalFields -> Error en el servidor:', error);
      return error;
    }
  }

  //USE MEMOS
  const memoizedFields = useMemo(
    () => additionalFieldsRef.current, [additionalFieldsRef.current]
  );

  //USE EFFECTS
  useEffect(() => {
    const fetchData = async () => {
      setTimeout(async () => {
        await getCaseInfo();
      }, 1000);
    };

    fetchData();
  }, []);

  useEffect(() => {
    getAdditionalFieldsParent();
  }, [caseDetail?.campos_adicionais]);

  useEffect(() => {
    additionalFieldsRef.current = additionalFieldsParent;
  }, [additionalFieldsParent]);

  useEffect(() => {
    if (additionalFieldsIntegration.length > 0 && memoizedFields.length > 0) {
      (async () => {
        const data = await postProcessedAdditionalFields(
          additionalFieldsIntegration,
          memoizedFields
        );

        setProcessedAdditionalFields(data);
        setIsOptionSelected(false);
      })();
    }
  }, [additionalFieldsIntegration, memoizedFields, isOptionSelected]);

  useEffect(() => {
    if (caseDetail?.campos_adicionais?.length > 0) {
      caseDetail.campos_adicionais?.forEach(field => {
        const matchingField = additionalFieldsParent.find(
          fieldParent => fieldParent.id_campo_adicional === field.idcampoadd
        );

        if (matchingField) {
          field.id_tipo_campo_adicional = matchingField.id_tipo_campo_adicional;
        }
      });
    }
  }, [caseDetail?.campos_adicionais]);

  useEffect(() => {
    if (caseDetail?.campos_adicionais?.length > 0) {
      caseDetail.campos_adicionais?.forEach(fields => {
        const { idcampoadd, label, id_tipo_campo_adicional } = fields;

        if (id_tipo_campo_adicional === 4) {
          fields.valorcampoadd = Number(fields.valorcampoadd);
        } else if (fields.valorcampoadd === "null") {
          fields.valorcampoadd = null
        }

        if (id_tipo_campo_adicional === 4 && label !== "") {
          getAdditionalChildFields(idcampoadd, label);
        }
      });
    }
  }, [caseDetail?.campos_adicionais]);

  useEffect(() => {
    if (caseDetail?.id_organizacao) {
      getListStatus();
    }
  }, [caseDetail?.id_organizacao]);

  // useEffect(() => {
  //   if (caseDetail && principalAdditionalField.length > 0) {
  //     if (variableValues.length > 0 && !controlPost) {
  //       sendSaveInteractionPost();
  //       setControlPost(true);
  //     }
  //   } else if (caseDetail && principalAdditionalField.length === 0) {
  //     sendSaveInteractionPost();
  //   }
  // }, [caseDetail, principalAdditionalField, variableValues]);

  useEffect(() => {
    if (caseDetail && Object.keys(caseDetail).length > 0) {
      sendSaveInteractionPost();
    }
  }, [caseDetail]);

  useEffect(() => {
    if (pageStatus === "awaiting") {
      checkResize();
    }
  }, [pageStatus]);

  useEffect(() => {
    if (history.location.state) {
      setCameFrom(history.location.state.from);
    }
  }, [history.location.state]);

  useEffect(() => {
    clearRequestTypeFields(clearFieldTrigger);
  }, [clearFieldTrigger, caseDetail?.id_solicitacao, caseDetail?.id_motivo, caseDetail?.id_submotivo]);

  useEffect(() => {
    if (newStatus.length > 0) {
      setCaseDetail((prevData) => ({
        ...prevData,
        id_ticket_status: newStatus[0].id_ticket_status,
      }));
    }
  }, [newStatus, caseDetail?.id_ticket_status]);

  useEffect(() => {
    if (slaData.length > 0) {
      const slaDataItem = slaData[0];

      const calculateSlaDate = async () => {
        const dateSla = await calcSlaDayAndHoliday(
          slaDataItem.id_sla,
          slaDataItem.sla_inicio + slaDataItem.sla_resolucao,
          slaDataItem.now
        );

        setCaseDetail((prevData) => ({
          ...prevData,
          prazo_resolucao: dateSla,
        }));
      };

      setEditableField(slaDataItem.editavel)

      calculateSlaDate();
    } else {
      setCaseDetail((prevData) => ({
        ...prevData,
        prazo_resolucao: null,
      }));
    }

    if (slaData.length === 0) {
      setEditableField(true);
    }
  }, [slaData, editableField]);

  useEffect(() => {
    setChangedStatus(caseDetail?.id_ticket_status);
  }, [caseDetail?.id_ticket_status]);

  return (
    <>
      {pageStatus === "awaiting" ? (
        <div
          className={`case-details
           ${location.pathname === `/casos/${id}` ? "details-modify" : ""}`}
          ref={refPage}
        >
          <header className="case-details-header">
            <div className="case-details-header__title">
              {!isDealing && (
                <IconArrowLeft
                  onClick={() => history.push(cameFrom ? cameFrom : "/casos")}
                />
              )}

              <div className="case-details-title">
                {refPage?.current?.clientWidth > 800 && (
                  <Typography.H1 color="blue-selected-4" weight="bold">
                    Caso:
                  </Typography.H1>
                )}
                <div className="title-ticket">
                  <Typography.H1 weight="bold" color="blue-selected-4">
                    {caseDetail.codigo_ticket}
                  </Typography.H1>
                </div>
              </div>
            </div>
            <div className="case-details-header__options">
              {/* <DropdownPriority
                  value={priority}
                  options={priorityList}
                  className="case-priority__header"
                  label
                  onClick={(value) => {
                    setPriority(value);
                    const arrayCase = { ...caseDetail };
                    arrayCase.descricao_criticidade = value.label;
                    arrayCase.id_criticidade = value.id;
                    arrayCase.id_campo = 4;
                    setCaseDetail(arrayCase);
                    updateCase(arrayCase);
                  }}
                /> */}
              {/* {refPage?.current?.clientWidth > 800 && (
                <>
                  <DropdownStatus
                    className="case-status__header"
                    value={caseDetail.id_ticket_status}
                    options={statusList}
                    commentIdStatus={3}
                    idTicket={id}
                    escalateIdStatus={5}
                    escalateList={escalateList}
                    changeStatusAndEscalateTicket={
                      changeStatusAndEscalateTicket
                    }
                    onAdd={(text, files, response, audio) =>
                      postComment(text, files, response, audio)
                    }
                    onClick={(option) => {
                      changeStatusTicket(option);
                    }}
                    screen="details"
                    type={permissionProfile.status.edicao ? "select" : ""}
                  />
                  {caseDetail.escalatedAssociated.length !== 0 && (
                    <DropdownEscalate
                      className="case-escalate__header"
                      value={caseDetail.escalatedAssociated[0].id_escalonamento}
                      options={escalateList}
                      disable={caseDetail.id_ticket_status !== 5 ? true : false}
                      onClick={(option) => {
                        changeEscalateTicket(option);
                      }}
                    />
                  )}
                </>
              )} */}
              <div className="case-users">
                <ButtonAgentSearch
                  createBy={userCreator?.nome_usuario}
                  list={users}
                  type="image"
                  value={{
                    label: userProfile?.nome_usuario,
                    id: userProfile?.id_usuario,
                  }}
                  placeholder="Nenhum agente atribuído"
                  onClick={(value, sendNotif) =>
                    changeCaseOwner(value, sendNotif)
                  }
                  disabled={!permissionProfile.proprietario.edicao}
                />
                {/* {user.permissionId === 2 ? (
                  <>
                    <ButtonSearch
                      createBy={userCreator?.nome_usuario}
                      list={users}
                      type="image"
                      value={userProfile?.nome_usuario}
                      placeholder="Nenhum agente atribuído"
                      onClick={(value) => changeCaseOwner(value)}
                      disabled={permissionProfile.proprietario.edicao}
                    />
                  </>
                ) : (
                  <>
                    {userProfile.nome_usuario ? (
                      <div className="case-details-header__profile">
                        <IconViewUser />
                        <ImageProfile profile={userProfile} />

                        <div className="case-details-header-profile-info">
                          <Typography.H6 color="gray-400">
                            {userProfile.nome_usuario}
                          </Typography.H6>
                          {userProfile.gestor && (
                            <Typography.Paragraph color="gray-200">
                              Atribuído pelo gestor
                            </Typography.Paragraph>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="case-details-header__profile">
                        <IconViewUser />
                        <Typography.H6 color="red">
                          Nenhum agente atribuído
                        </Typography.H6>
                      </div>
                    )}
                  </>
                )} */}
              </div>
              {permissionProfile.casos.exclusao && !isDealing && (
                <Delete
                  onDelete={deleteCase}
                  title="Tem certeza que deseja remover este caso?"
                />
              )}
            </div>
          </header>
          <main
            className={`${interaction.clientId === caseDetail.id_contato
              ? "case-details-main-interaction" : ""} case-details-main`}
          >
            <div className="case-details-information-main">
              {interaction.clientId === caseDetail.id_contato ||
                !caseDetail?.telefone[0] ||
                interaction.clientName === caseDetail.nome_contato ||
                caseDetail.telefone.some(
                  (tel) => tel === interaction.destination
                ) ? (
                ""
              ) : (
                <FieldContainer
                  title="Selecione o canal de comunicação:"
                  className="case-details-interaction-channels"
                  titleSize="large"
                >
                  <div className="case-details-interaction-channels__content">
                    {renderChannels()}
                  </div>
                </FieldContainer>
              )}

              {renderCaseFields()}

              {/* ################ NOVA RENDERIZAÇÃO DE CAMPOS PERSONALIZADOS ################ */}
              {additionalFieldsParent.length > 0 &&
                permissionProfile.camposAdicionais.visualizacao && (
                  <div
                    className={`field-container-case-variable grid-span-2 
                    ${isOpenCustomFields ? "active-variable" : ""}`}
                  >
                    <div
                      className="field-container-case-variable-control"
                      onClick={() =>
                        setIsOpenCustomFields(!isOpenCustomFields)
                      }
                    >
                      <ArrowIcon />
                      <Typography.H4
                        color={"blue-selected-4"}
                        weight={700}
                      >
                        Campos personalizados
                      </Typography.H4>
                    </div>

                    {isOpenCustomFields && (
                      <div className="field-container-case-variable-container">
                        <div className="field-container-case-variable-fields">
                          {additionalFieldsParent.map((field) =>
                            renderCustomFields(field, (fieldId, value) => {
                              setAdditionalFieldsParent((prevFields) =>
                                prevFields.map((prevField) =>
                                  prevField.id_campo_adicional === fieldId ? { ...prevField, valor_default: value, } : prevField
                                )
                              );
                            }, handleBackspaceOnRequiredCustomFields)
                          )}
                        </div>

                        <Button
                          className="field-container-case-variable__btn-confirm"
                          disabled={isUpdatingCustomFields === true}
                          onClick={() => handleUpdateAdditional()}
                        >
                          {isUpdatingCustomFields ? (
                            <Loading.Dots />
                          ) : (
                            <Typography.H4 weight={"bold"}>
                              Salvar
                            </Typography.H4>
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                )}

              {/* {additionalField.length > 0 &&
                permissionProfile.camposAdicionais.visualizacao && (
                  <div className={`field-container-case-variable grid-span-2 
                    ${openVariable ? "active-variable" : ""}`}
                  >
                    <div
                      className="field-container-case-variable-control"
                      onClick={() => setOpenVariable(!openVariable)}
                    >
                      <ArrowIcon />
                      <Typography.H4 color={"blue-selected-4"} weight={700}>
                        Campos personalizados
                      </Typography.H4>
                    </div>
                    {openVariable && (
                      <div className="field-container-case-variable-container">
                        <div className="field-container-case-variable-fields">
                          {additionalField.map((field) =>
                            handleVariableFields(field)
                          )}
                        </div>
                        <Button
                          className="field-container-case-variable__btn-confirm"
                          onClick={() => {
                            handleUpdateAdditional();
                          }}
                        >
                          <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        </Button>
                      </div>
                    )}
                  </div>
                )} */}

              <div className="case-details-subject">
                <FieldContainerEdit
                  title="Assunto"
                  className="textarea-subject"
                  type="no-scroll"
                  iconsEdit={true}
                  content={caseDetail.assunto}
                  required
                  onEdit={(value) => {
                    const arrayCase = { ...caseDetail };
                    const logInfo = {
                      tipo_operacao: 2,
                      id_campo: 6,
                      id_campo_adicional: null,
                      valor_atual: caseDetail.assunto,
                      valor_novo: value,
                      id_ticket: id,
                      id_campo_adicional: null,
                    };
                    arrayCase.assunto = value;
                    arrayCase.id_campo = 6;
                    updateCase(arrayCase, null, logInfo);
                    setCaseDetail(arrayCase);
                  }}
                  canBeEditable={permissionProfile.assunto.edicao}
                />
              </div>

              <div className="case-details-description">
                <FieldContainerEdit
                  title="Descrição do caso"
                  className="textarea-description"
                  content={caseDetail.observacao}
                  required
                  onEdit={(value) => {
                    const arrayCase = { ...caseDetail };
                    const logInfo = {
                      tipo_operacao: 2,
                      id_campo: 7,
                      id_campo_adicional: null,
                      valor_atual: caseDetail.observacao,
                      valor_novo: value,
                      id_ticket: id,
                      id_campo_adicional: null,
                    };
                    arrayCase.observacao = value;
                    arrayCase.id_campo = 7;
                    updateCase(arrayCase, null, logInfo);
                    setCaseDetail(arrayCase);
                  }}
                  canBeEditable={permissionProfile.descricao.edicao}
                />
              </div>
            </div>

            <div className="case-details-tabs">
              <MenuTabs
                limit={refPage?.current?.clientWidth > 800 ? 4 : 3}
                informationElements={caseDetail}
                hide={tabHide}
                setTab={setTabChoose}
                tabs={[
                  {
                    id: "1",
                    title: {
                      text: "Casos",
                      type: "fill",
                    },
                    content: (
                      <div className="case-details-tab-information">
                        {/* <div className="case-details-tab-information__header">
                          <div className="agents-delete">
                            {user.permissionId === 2 ? (
                              <>
                                <ButtonSearch
                                  createBy={userCreator?.nome_usuario}
                                  list={users}
                                  type="image"
                                  value={userProfile?.nome_usuario}
                                  placeholder="Nenhum agente atribuído"
                                  onClick={(value) => associateUser(value)}
                                />
                              </>
                            ) : (
                              <>
                                {userProfile.nome_usuario ? (
                                  <div className="case-details-header__profile">
                                    <IconViewUser />
                                    <ImageProfile profile={userProfile} />

                                    <div className="case-details-header-profile-info">
                                      <Typography.H6 color="gray-400">
                                        {userProfile.nome_usuario}
                                      </Typography.H6>
                                      {userProfile.gestor && (
                                        <Typography.Paragraph color="gray-200">
                                          Atribuído pelo gestor
                                        </Typography.Paragraph>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="case-details-header__profile">
                                    <IconViewUser />
                                    <Typography.H6 color="red">
                                      Nenhum agente atribuído
                                    </Typography.H6>
                                  </div>
                                )}
                              </>
                            )}

                            {permissionProfile.casos.exclusao && (
                              <Delete
                                onDelete={deleteCase}
                                title="Tem certeza que deseja remover este caso?"
                              />
                            )}
                          </div>
                        </div> */}

                        {interaction.clientId === caseDetail.id_contato ||
                          !caseDetail.telefone[0] ||
                          interaction.clientName === caseDetail.nome_contato ||
                          caseDetail.telefone.some(
                            (tel) => tel === interaction.destination
                          ) ? (
                          ""
                        ) : (
                          <FieldContainer
                            title="Selecione o canal de comunicação:"
                            className="case-details-tab-information-channels"
                            titleSize="large"
                          >
                            <div className="case-details-interaction-channels__content">
                              {renderChannels()}
                            </div>
                          </FieldContainer>
                        )}

                        {renderCaseFields()}

                        {/* ################ NOVA RENDERIZAÇÃO DE CAMPOS PERSONALIZADOS ################ */}
                        {additionalFieldsParent.length > 0 &&
                          permissionProfile.camposAdicionais.visualizacao && (
                            <div
                              className={`field-container-case-variable grid-span-2 
                                ${isOpenCustomFields ? "active-variable" : ""}`}
                            >
                              <div
                                className="field-container-case-variable-control"
                                onClick={() =>
                                  setIsOpenCustomFields(!isOpenCustomFields)
                                }
                              >
                                <ArrowIcon />
                                <Typography.H4
                                  color={"blue-selected-4"}
                                  weight={700}
                                >
                                  Campos personalizados
                                </Typography.H4>
                              </div>

                              {isOpenCustomFields && (
                                <div className="field-container-case-variable-container">
                                  <div className="field-container-case-variable-fields">
                                    {additionalFieldsParent.map((field) =>
                                      renderCustomFields(
                                        field,
                                        (fieldId, value) => {
                                          setAdditionalFieldsParent(
                                            (prevFields) =>
                                              prevFields.map((prevField) =>
                                                prevField.id_campo_adicional ===
                                                  fieldId
                                                  ? {
                                                    ...prevField,
                                                    valor_default: value,
                                                  }
                                                  : prevField
                                              )
                                          );
                                        }, handleBackspaceOnRequiredCustomFields)
                                    )}
                                  </div>

                                  <Button
                                    className="field-container-case-variable__btn-confirm"
                                    disabled={isUpdatingCustomFields === true}
                                    onClick={() => handleUpdateAdditional()}
                                  >
                                    {isUpdatingCustomFields ? (
                                      <Loading.Dots />
                                    ) : (
                                      <Typography.H4 weight={"bold"}>
                                        Salvar
                                      </Typography.H4>
                                    )}
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}

                        {/* {additionalField.length > 0 &&
                          permissionProfile.camposAdicionais.visualizacao && (
                            <div
                              className={`field-container-case-variable grid-span-2 
                                ${openVariable ? "active-variable" : ""}`}
                            >
                              <div
                                className="field-container-case-variable-control"
                                onClick={() => setOpenVariable(!openVariable)}
                              >
                                <ArrowIcon />
                                <Typography.H4
                                  color={"blue-selected-4"}
                                  weight={700}
                                >
                                  Campos personalizados
                                </Typography.H4>
                              </div>
                              {openVariable && (
                                <div className="field-container-case-variable-container">
                                  <div className="field-container-case-variable-fields">
                                    {additionalField.map((field) =>
                                      handleVariableFields(field)
                                    )}
                                  </div>
                                  <Button
                                    className="field-container-case-variable__btn-confirm"
                                    onClick={() => {
                                      handleUpdateAdditional();
                                    }}
                                  >
                                    <Typography.H4 weight={"bold"}>
                                      Salvar
                                    </Typography.H4>
                                  </Button>
                                </div>
                              )}
                            </div>
                          )} */}

                        <div className="case-details-tab-information__description">
                          <FieldContainerEdit
                            title="Assunto"
                            className="textarea-subject"
                            type="no-scroll"
                            iconsEdit={true}
                            content={caseDetail.assunto}
                            required
                            onEdit={(value) => {
                              const arrayCase = { ...caseDetail };
                              arrayCase.assunto = value;
                              arrayCase.id_campo = 6;
                              updateCase(arrayCase);
                              setCaseDetail(arrayCase);
                            }}
                            canBeEditable={permissionProfile.assunto.edicao}
                          />

                          <FieldContainerEdit
                            title="Descrição do caso"
                            content={caseDetail.observacao}
                            required
                            onEdit={(value) => {
                              const arrayCase = { ...caseDetail };
                              arrayCase.observacao = value;
                              arrayCase.id_campo = 7;
                              updateCase(arrayCase);
                              setCaseDetail(arrayCase);
                            }}
                            height="165px"
                            canBeEditable={permissionProfile.descricao.edicao}
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: "2",
                    title: {
                      text: "Comentários",
                      type: "fill",
                    },
                    content: (
                      <CaseComments
                        id={id}
                        handleInteractionSaveImmediate={
                          handleInteractionSaveImmediate
                        }
                        updateAgent={associateUser}
                        permissions={permissionProfile.comentarios}
                      />
                    ),
                  },
                  {
                    id: "3",
                    title: {
                      text: "Interações",
                      type: "fill",
                    },
                    content: (
                      <CaseInteractions
                        interactionList={interactionList}
                        canBeVisualized={
                          permissionProfile.interacoes.visualizacao
                        }
                        actionVisualization={
                          permissionProfile.acaoInteracoes.visualizacao
                        }
                      />
                    ),
                  },
                  {
                    id: "4",
                    title: {
                      text: "Tarefas",
                      type: "fill",
                    },
                    content: (
                      <TaskList
                        caseDetail={caseDetail}
                        onRefreshPage={getCaseInfo}
                        customEmptyTaskData={customEmptyTaskData}
                        clientData={clientData}
                        tagLimit={tabHide ? 2 : 1}
                        getDetails={getDetails}
                        permissions={permissionProfile.tarefas}
                      />
                    ),
                  },
                  {
                    id: "5",
                    title: {
                      text: "Comunicados",
                      type: "fill",
                    },
                    content: (
                      <CaseCommunications
                        id={id}
                        case={caseDetail}
                        handleInteractionSaveImmediate={
                          handleInteractionSaveImmediate
                        }
                        updateAgent={associateUser}
                        permissions={permissionProfile.comunicados}
                      />
                    ),
                  },
                  {
                    id: "6",
                    title: {
                      text: "Histórico",
                      type: "fill",
                    },
                    content: (
                      <CaseHistoric
                        id={id}
                        token={token}
                        canBeVisualized={
                          permissionProfile.historico.visualizacao
                        }
                        tabId={tabChoose}
                        getCaseUpdate={caseUpdates}
                        updateHistoric={setCaseUpdates}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </main>
        </div>
      ) : (
        <Loading.Paperplane fullPage className="intern-page" />
      )}
    </>
  );
}
