//GLOBAL - components from npm
import React, { useState, useRef } from "react";
import { MdSearchOff } from "react-icons/md";

//HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//STYLES
import "./dropdown-escalate.scss";

//COMPONENTS
import { Typography } from "../../../components/general";
import { Tippy } from "../../../components/feedback";
import { FilterTag } from "../../../components/data-display";
import { Input } from "../../data-entry";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as Escalate } from "../../../assets/icons/Escalate.svg";

export default function DropdownEscalate({
  className = "",
  value,
  options = [],
  width,
  onClick,
  commentIdStatus,
  escalateIdStatus,
  disable = true,
  type = "select",
  error,
  includeSearchField
}) {
  //GENERAL
  const ref = useRef();
  const escalate = options.find((item) => item.id === value);

  //STATES
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [typeTippy, setTypeTippy] = useState("comment");
  const [inputSearch, setInputSearch] = useState("");

  //REDUX - Selectors

  //FUNCTIONS
  const show = () => setVisible(true);

  useOutsideClick(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  // const filterEscalate = (option) => {
  //   if (option.id === value) {
  //     return;
  //   } else {
  //     return option;
  //   }
  // };

  const handleOptionClick = (option) => {
    setIsOpen(false);
    setInputSearch("");

    if (onClick) {
      onClick(option);

      if (option.id === commentIdStatus) {
        setTypeTippy("comment");
        show();
      } else if (option.id === escalateIdStatus) {
        setTypeTippy("escalate");
        show();
      }
    }
  };

  const listFilter = (item) =>
    inputSearch ? item.label.toLowerCase().includes(inputSearch.toLowerCase()) : true;

  const filteredOptions = options.filter(listFilter);


  //USE EFFECTS

  return (
    <Tippy
      visible={visible}
      theme="popover-comment"
      interactive
      placement="bottom"
      delay={[0, 0]}
      allowHTML
    >
      <div
        className={`${disable
          ? `dropdown-escalate dropdown-escalate__disable`
          : `dropdown-escalate dropdown-escalate__enable`} 
            ${className}`}
        style={{ width: width ? width : "" }}
        ref={ref}
      >
        {
          <button
            className={`dropdown-escalate-btn 
              ${type} 
              ${isOpen ? "active" : ""} dropdown-escalate--black 
              ${error ? "dropdown-escalate--error" : ""}`}
            onClick={() => {
              if (type === "tag") {
                return;
              }

              setIsOpen(!isOpen);
            }}
          >
            {!escalate ? (
              <>
                <Escalate />
                <Typography.H5
                  weight="500"
                  color={escalate?.label ? `gray-180` : `gray-175`}
                >
                  {escalate?.label ?? "Selecione"}
                </Typography.H5>
              </>
            ) : (
              <FilterTag
                type={"escalate"}
                label={escalate?.label}
                onClose={() => onClick({ id: null, label: null })}
                className="escalate-details"
              />
            )}
            {type === "select" && disable !== true && <IconArrowDown />}
          </button>
        }

        <div className={`dropdown-escalate-list ${isOpen ? "active" : ""}`}>
          {/* {options.filter(filterEscalate).map((option, index) => (
            <button
              type="button"
              key={index}
              className={`dropdown-escalate-list__item `}
              onClick={() => handleOptionClick(option)}
            >
              <Typography.H5 weight="500" color={`gray-180`}>
                {option.label}
              </Typography.H5>
            </button>
          ))} */}

          {includeSearchField && options.length > 6 && (
            <Input
              type="text"
              placeholder="Buscar"
              className="dropdown-escalate-list__input"
              value={inputSearch}
              onChange={(e) => setInputSearch(e)}
            />
          )}

          {filteredOptions.length ? (
            filteredOptions.map((option, index) => (
              <button
                key={index}
                type="button"
                className={`dropdown-escalate-list__item `}
                onClick={() => handleOptionClick(option)}
              >
                <Typography.H5 weight="500" color={`gray-180`}>
                  {option.label}
                </Typography.H5>
              </button>
            ))
          ) : (
            <div className="dropdown-escalate-list__empty">
              <MdSearchOff size={24} color="gray" />

              <Typography.H5 color="gray-400">
                Nenhum resultado encontrado
              </Typography.H5>
            </div>
          )}
        </div>
      </div>
    </Tippy>
  );
}
