//GLOBAL - components from npm
import React, { useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIShiftWorker from "./../../../../services/api/shiftWorker";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES

import "./delete-shift-worker.scss";

export default function DeleteShiftWorker({ isOpen, onClose, onDelete, shiftWorkerId, userId }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");

    //FUNCTIONS
    function onCloseCustom() {
        onClose();
    };

    async function handleDeleteShiftWorker() {
        setPageStatus("delete-shift-worker");

        const data = {
            id_usuario: userId,
            id_plantonista: shiftWorkerId
        };

        try {
            const response = await APIShiftWorker.deleteShiftWorker(token, data);

            if (response.status === 200) {
                onDelete();
                onCloseCustom();
                Toast(
                    "Plantonista excluído!",
                    "Plantonista excluído com sucesso.",
                    "success"
                );
            } else {
                const responseData = await response.json();
                throw new Error(responseData.message || "Falha ao excluir o plantonista.");
            }
        } catch (error) {
            console.error("Erro ao excluir o plantonista:", error);

            Toast(
                "Não foi possível excluir o plantonista!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        } finally {
            setPageStatus("awaiting");
        }
    }

    //USE MEMOS

    //USE EFFECTS 
    return (
        <Modal.Main
            className="modal-delete-shift-worker"
            title="Excluir plantonista"
            width="443px"
            height="280px"
            titleColor={"black"}
            isOpen={isOpen}
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            headerContainerStyle={{ alignItems: "center" }}
            icon={
                <div className="modal-delete-shift-worker__icon">
                    <TrashIcon />
                </div>
            }
            footer={
                <div className="container-footer">
                    <Button
                        onClick={() => onCloseCustom()}
                        className="modal-delete-shift-worker__btn-cancel"
                        disabled={pageStatus === "delete-shift-worker"}
                    >
                        <Typography.H4 weight={"bold"} color="blue-selected-4">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-delete-shift-worker__btn-confirm"
                        disabled={pageStatus === "delete-shift-worker"}
                        onClick={handleDeleteShiftWorker}
                    >
                        {pageStatus !== "delete-shift-worker" ? (
                            <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4
                color="gray-180"
                weight="400"
                className="information-text"
            >
                Você tem certeza que deseja excluir? Ao excluir todos os dados, serão excluídos e esta ação não poderá ser desfeita.
            </Typography.H4>
        </Modal.Main>
    )
}
