//GLOBAL - components from npm
import { useHistory } from "react-router";
import React, { useState, useRef } from "react";
import { MdSearchOff } from "react-icons/md";

//HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//STYLES
import "./dropdown-channel.scss";

//COMPONENTS
import { Typography } from "../../../components/general";
import { Channel } from "../../../components/data-display";
import { Input } from "../../data-entry";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as DefaultIcon } from "./../../../assets/icons/medias/Default.svg";

export default function DropdownChannel({
  value = {
    id: 0,
    label: " ",
  },
  options = [
    {
      id: 1,
      label: "Whatsapp",
    },
    {
      id: 2,
      label: "Facebook",
    },
    {
      id: 3,
      label: "Instagram",
    },
    {
      id: 4,
      label: "Email",
    },
  ],
  width,
  onClick,
  className,
  label,
  error,
  screen,
  includeSearchField
}) {
  //GENERAL
  const ref = useRef();
  const history = useHistory();

  //STATES
  const [isOpen, setIsOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  //REDUX - Selectors

  //FUNCTIONS
  useOutsideClick(ref, () => isOpen && setIsOpen(false));

  const listFilter = (item) =>
    inputSearch
      ? item.label.toLowerCase().includes(inputSearch.toLowerCase()) : true;

  const handleOptionClick = (e, option) => {
    e.preventDefault();

    if (onClick) {
      onClick(option);
    }

    setIsOpen(false);
    setInputSearch("");
  };

  const filteredOptions = options.filter(listFilter);

  // const filterPriority = (option) => {
  //   if (value) {
  //     if (option.id === value.id) {
  //       return;
  //     } else {
  //       return option;
  //     }
  //   } else {
  //     if (option.label === "Baixa") {
  //       return;
  //     } else {
  //       return option;
  //     }
  //   }
  // };

  //USE EFFECTS

  return (
    <div
      className={`dropdown-channel ${className} ${error ? "dropdown-channel--error" : ""}`}
      style={{ width: width ? width : `` }}
      ref={ref}
    >
      <button
        className={`dropdown-channel-btn  ${isOpen ? "active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {screen === "filter-advanceds" ? (
          <div
            className="dropdown-channel-btn-container"
          // style={{ justifyContent: "flex-start" }}
          >
            <div className="dropdown-channel-btn-container__content">
              <div
                className="channel channel--gray"
                style={{ backgroundColor: "#C0C0C0" }}
              >
                <DefaultIcon />
              </div>

              {label && (
                <Typography.H5 color="gray-400">
                  {value.label}
                </Typography.H5>
              )}
            </div>

            <div
              className="dropdown-channel-btn-container__icon"
              style={{ marginLeft: screen === "filter-advanceds" ? "-10px" : "", }}
            >
              <IconArrowDown />
            </div>
          </div>
        ) : (
          <div className="dropdown-channel-btn-container">
            <div className="dropdown-channel-btn-container__content">
              <Channel
                media={value.id}
                nameMedia={value.label?.toLowerCase()}
                tippy={false}
              />

              {label && (
                <Typography.H5
                  color="gray-400"
                  className={history.location.pathname.indexOf("externo") === 1 ? "text-channel-extern" : "text-channel"}>
                  {value.label}
                </Typography.H5>
              )}
            </div>

            <div className="dropdown-channel-btn-container__icon">
              <IconArrowDown />
            </div>
          </div>
        )}
      </button>

      <div className={`dropdown-channel-list ${isOpen ? "active" : ""}`}>
        {includeSearchField && options.length > 6 && (
          <Input
            type="text"
            placeholder="Buscar"
            className="dropdown-channel-list__input"
            value={inputSearch}
            onChange={(e) => setInputSearch(e)}
          />
        )}

        {filteredOptions.length ? (
          filteredOptions.map((option, index) => (
            option.id !== value.id && (
              <div
                key={index}
                className={`dropdown-channel-list__item ${option.id === value.id ? "active" : ""}`}
                onClick={(e) => handleOptionClick(e, option)}
              >
                <Channel
                  media={option.id}
                  nameMedia={option.label?.toLowerCase()}
                  tippy={false}
                />

                {value.label && (
                  <Typography.H5
                    color="gray-400"
                    className={history.location.pathname.indexOf("externo") === 1 ? "text-select-channel-extern" : ""}>
                    {option.label}
                  </Typography.H5>
                )}
              </div>
            )
          ))
        ) : (
          <div className="dropdown-channel-list__empty">
            <MdSearchOff size={24} color="gray" />

            <Typography.H5 color="gray-400">
              Nenhum resultado encontrado
            </Typography.H5>
          </div>
        )}
      </div>
    </div>
  );
}
