import axios from "axios";
import instance from "./configAxios";
import { connection_platform } from "./configConnection";

export async function listImportationFields(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await instance.post(
      "/buscar-campos-importacao",
      "",
      config
    );
    return response;
  } catch (error) {}
}

export async function uploadFile(token, file) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await instance.post("/insert-file-no-base", file, config);
    return response;
  } catch (error) {
    console.log("uploadFile ERROR: ", error);
  }
}

export async function getTickets(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      "/buscar-tickets-importacao",
      "",
      config
    );
    return response;
  } catch (error) {
    console.log("getTickets ERROR: ", error);
  }
}

export async function insertTicket(token, userId, filePath, importObservation) {
  try {
    const data = {
      id_usuario: userId,
      caminho_arquivo_importado: filePath,
      observao_imoportacao: importObservation,
    };
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post(
      "/insert-tickets-importacao",
      data,
      config
    );
    return response;
  } catch (error) {
    console.log("insertTicket ERROR: ", error);
  }
}

export async function deleteTicket(token, userId, importationTicketId) {
  try {
    const data = {
      id_usuario: userId,
      id_ticket_importacao: importationTicketId,
    };
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.put(
      "/delete-tickets-importacao",
      data,
      config
    );
    return response;
  } catch (error) {
    console.log("deleteTicket ERROR: ", error);
  }
}

export async function createCaseByCase(data) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${connection_platform.tokenToInsertCases}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      connection_platform.microserviceToImportCases,
      data,
      config
    );
    return response;
  } catch (error) {
    console.log("deleteTicket ERROR: ", error);
  }
}
