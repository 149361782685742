import React, { useEffect, useState } from "react";

import { Button, Typography } from "../../../general";
import { useSelector } from "react-redux";
import { Toast } from "../../../../utils/toast";
import { Modal } from "./../../../feedback";
import Loading from "../../Loading";

import * as APITypeManagement from "./../../../../services/api/typeManagement";

import { ReactComponent as IconTrash } from "../../../../assets/icons/Trash-3.svg";

import "./delete-subreason.scss";

export default function DeleteSubreason({
    isOpen,
    onClose,
    selectedReason,
    treeData,
    setTreeData,
    selectedType,
    organizationId
}) {
    const token = useSelector((state) => state.auth.token);

    const subreasonId = isOpen || null;

    const [pageStatus, setPageStatus] = useState("awaiting");
    const [idSubreason, setIdSubreason] = useState(null);

    function onCloseCustom() {
        onClose();
    };

    function getSubreasonId(subreasonId) {
        selectedReason.subreasons?.forEach((item) => {
            if (item.id_submotivo === subreasonId.toString()) {
                setIdSubreason(item.id_submotivo)
            }
        })
    };

    async function handleDisassociateSubreason() {
        setPageStatus("delete-subreason");

        const id = organizationId && organizationId

        if (selectedReason && idSubreason) {
            try {
                const response = await APITypeManagement.deleteSubreasonV2(
                    token,
                    Number(selectedType?.id_solicitation),
                    Number(selectedReason.id_motivo),
                    Number(idSubreason),
                    Number(id)
                );

                if (response.status === 200) {
                    onCloseCustom();

                    const updatedTreeData = { ...treeData };

                    const selectedTypeIndex = updatedTreeData.types?.findIndex(
                        (type) => type.id_solicitation === selectedType?.id_solicitation
                    )

                    if (selectedTypeIndex !== -1) {
                        const selectedReasonIndex = updatedTreeData.types[selectedTypeIndex]
                            .arrayOfReasons?.findIndex(
                                (reason) => reason.id_motivo === selectedReason?.id_motivo
                            );

                        if (selectedReasonIndex !== -1) {
                            const selectedSubreasonIndex = updatedTreeData.types[selectedTypeIndex]
                                .arrayOfReasons[selectedReasonIndex].subreasons?.findIndex(
                                    (subreason) => subreason.id_submotivo === idSubreason.toString()
                                )

                            if (selectedSubreasonIndex !== -1) {
                                updatedTreeData.types[selectedTypeIndex]
                                    .arrayOfReasons[selectedReasonIndex].subreasons.splice(
                                        selectedSubreasonIndex,
                                        1
                                    );

                                updatedTreeData.types[selectedTypeIndex]
                                    .arrayOfReasons[selectedReasonIndex].qtde_submotivo =
                                    (updatedTreeData.types[selectedTypeIndex]
                                        .arrayOfReasons[selectedReasonIndex].subreasons.length).toString()
                            }
                        }
                    }

                    setTreeData(updatedTreeData);

                    Toast(
                        "Submotivo excluído!",
                        "Submotivo excluído com sucesso.",
                        "success"
                    );
                } else {
                    Toast(
                        "Não foi possível excluir o Submotivo",
                        "Tente novamente mais tarde!",
                        "error"
                    )
                }
            } catch (error) {
                Toast(
                    "Não foi possível excluir o Submotivo",
                    "Tente novamente mais tarde!",
                    "error"
                )
            }
        }

        setPageStatus("Waiting");
    }

    useEffect(() => {
        if (subreasonId) {
            getSubreasonId(subreasonId)
        }
    }, [subreasonId])

    return (
        <Modal.Main
            className="modal-delete-subreason"
            title={`Excluir submotivo`}
            titleColor={"black"}
            headerContainerStyle={{ alignItems: "center" }}
            width="443px"
            height="255px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            icon={
                <div className="modal-delete-subreason__icon">
                    <IconTrash />
                </div>
            }
            footer={
                <div className="container-footer">
                    <Button
                        className="modal-delete-subreason__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "delete-subreason"}
                    >
                        <Typography.H4 weight={"bold"} color="blue-selected-4">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-delete-subreason__btn-confirm"
                        disabled={pageStatus === "delete-subreason"}
                        onClick={handleDisassociateSubreason}
                    >
                        {pageStatus !== "delete-subreason" ? (
                            <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4
                color="gray-180"
                weight="400"
                className="information-text"
            >
                Você tem certeza que deseja excluir?
                Esta ação não pode ser desfeita.
            </Typography.H4>
        </Modal.Main>
    )
}
