//GLOBAL - components from npm
import React from "react";
import Lottie from "react-lottie";

//STYLES
import "./dots.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import animationData from "./dots.json";

export default function Dots({
  className = "",
  fullPage,
  width = 120,
  height = 120,
}) {
  //GENERAL
  const defaultOptions = {
    animationData: animationData,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <div
      className={`dots-container ${className} ${
        fullPage ? "dots-container--full-page" : ""
      }`}
      style={{ width: width, height: height}}
    >
      <Lottie options={defaultOptions} width={width} height={height} />
    </div>
  );
}
