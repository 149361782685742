import TasksAndCases from './TasksAndCases';
import Create from './Create';
import CreateCases from './CreateCases';

const ActiveInteraction = {
  TasksAndCases,
  Create,
  CreateCases
}

export default ActiveInteraction;