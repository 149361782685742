//GLOBAL - components from npm
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

//COMPONENTS
import { Typography } from "../../../components/general";
import Organization from "../Organization";
import ShiftWorker from "../ShiftWorker";
import Status from "../Status";
import User from "../User";
import Profile from "../Profile/Profile";
import Staggered from "../Staggered/Staggered";
import SLA from "../SLA/SLA";
import Contact from "../Contact/Contact";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as BuildingIcon } from "./../../../assets/icons/Building.svg";
import { ReactComponent as UserCircleAddIcon } from "./../../../assets/icons/user-cirlce-add.svg";
import { ReactComponent as UserOctagonIcon } from "./../../../assets/icons/user-octagon.svg";
import { ReactComponent as SaveAddIcon } from "./../../../assets/icons/save-add.svg";
import { ReactComponent as ArrowSwapIcon } from "./../../../assets/icons/arrow-swap.svg";
import { ReactComponent as AddUserIcon } from "./../../../assets/icons/add-user.svg";
import { ReactComponent as TimerIcon } from "./../../../assets/icons/timer.svg";
import { ReactComponent as BagIcon } from "./../../../assets/icons/bag.svg";
import { ReactComponent as TagIcon } from "./../../../assets/icons/tag.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/edit.svg";

//STYLES
import "./record-type.scss";

export default function RecordType() {
    //REDUX - Selectors

    //GENERAL
    const location = useLocation();

    const buttonsData = [
        { componentName: "Organization", icon: <BuildingIcon />, text: "Organização" },
        { componentName: "ShiftWorker", icon: <UserCircleAddIcon />, text: "Plantonista" },
        { componentName: "User", icon: <AddUserIcon />, text: "Usuário" },
        { componentName: "Profiles", icon: <UserOctagonIcon />, text: "Perfis" },
        { componentName: "Status", icon: <SaveAddIcon />, text: "Status" },
        { componentName: "Staggered", icon: <ArrowSwapIcon />, text: "Escalonado" },
        { componentName: "Sla", icon: <TimerIcon />, text: "SLA" },
        { componentName: "Contact", icon: <BagIcon />, text: "Contato e empresa" },
        // { componentName: "TypesReasonsSubreasons", icon: <TagIcon />, text: "Tipos, motivos e submotivos" },
        // { componentName: "CustomFields", icon: <EditIcon />, text: "Campos personalizados" },
    ];

    //STATES
    const [recordType, setRecordType] = useState(null);

    //FUNCTIONS
    const handleButtonClick = (componentName) => {
        setRecordType(componentName);
    };

    function recordTypeRender(value) {
        switch (value) {
            case "Organization":
                return <Organization returnToRecord={() => setRecordType(null)} />
                break;
            case "ShiftWorker":
                return <ShiftWorker returnToRecord={() => setRecordType(null)} />
                break;
            case "User":
                return <User returnToRecord={() => setRecordType(null)} />
                break;
            case "Staggered":
                return <Staggered returnToRecord={() => setRecordType(null)} />
                break;
            case "Status":
                return <Status returnToRecord={() => setRecordType(null)} />
            case "Profiles":
                return <Profile returnToRecord={() => setRecordType(null)} />
            case "Contact":
                return <Contact returnToRecord={() => setRecordType(null)} />
            case "Sla":
                return <SLA returnToRecord={() => setRecordType(null)} />
            default:
                break;
        }
    };

    //USE EFFECTS

    return (
        <div className={`page-records ${location.pathname === "/cadastro" ? "page-modify" : ""}`}>
            {recordType !== null ? (
                <>{recordTypeRender(recordType)}</>
            ) : (
                <div className="page-records-content">
                    <header className="page-records-content__header">
                        <Typography.H2 color="gray-180" weight="bold">
                            Cadastro
                        </Typography.H2>
                    </header>

                    <Typography.H4 color="gray-180" weight="400">
                        Selecione o tipo de cadastro desejado abaixo:
                    </Typography.H4>

                    <section className="page-records-content__container-buttons">
                        {buttonsData.map((button, index) => (
                            <button
                                key={index}
                                className="record-category"
                                onClick={() => handleButtonClick(button.componentName)}
                            >
                                {button.icon}

                                <Typography.H4 weight="500" color="gray-180">
                                    {button.text}
                                </Typography.H4>
                            </button>
                        ))}
                    </section>
                </div>
            )}
        </div>
    )
}
