//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";

//STYLES
import "./tag-list.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Input } from "..";
import { Tag } from "../../data-display";
import { Tippy } from './../../feedback';

//SERVICES - api, conectors...
import * as APITag from "../../../services/api/tag";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconClose } from "../../../assets/icons/Close.svg";
import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";
import { ReactComponent as IconAddCircle } from "../../../assets/icons/add-circle.svg";
import { ReactComponent as IconAddElements } from "../../../assets/icons/AddElements.svg";

export default function TagList({
  className = '',
  tags,
  viewOnly,
  tagLimit,
  idTask,
  setTags,
  type,
  buttonTooltip,
  getTags,
  allTags,
  showButtonAddTag,
  disabledVisibleView
}) {

  //GENERAL
  const token = useSelector((state) => state.auth.token);
  const user = useSelector(state => state.auth.userData);
  const ref = useRef();

  //STATES
  //const [openTagList, setOpenTagList] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  //const [allTags, setAllTags] = useState([]);
  const [tagsList, setTagsList] = useState(tags);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleView, setVisibleView] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS


  const show = () => setVisibleAdd(true);

  const hide = () => {
    setVisibleAdd(false);
    setInputSearch("");
  };

  const showView = () => setVisibleView(true);

  const hideView = () => {
    setVisibleView(false);
    setInputSearch("");
  };

  const putTag = async (idTag, value) => {
    const body = {
      desc_tag: value
    }

    await APITag.updateTag(idTag, body, token);

    await getTags();
  }

  const postCreateTag = async (description) => {
    const body = {
      description,
    };

    const response = await APITag.createTag(body, token);

    return response.data.Dados[0];
  };

  const postCreateAssociateTag = async (description, id_task) => {
    const body = {
      description,
      id_task,
    };

    const response = await APITag.createAssociateTag(body, token);

    await getTags();

    const data = response.data;

    const arrayTags = [...tagsList];
    arrayTags.push({
      id_tag: data.Dados[0].id_tag,
      desc_tag: description,
      id_tarefa: idTask,
    });

    setTagsList(arrayTags);
  };

  const postAssociateTag = async (id_tag, id_task) => {
    const body = {
      id_tag,
      id_task,
    };

    await APITag.associateTag(body, token);
  };

  const deleteDisassociateTag = async (id_tag, id_task) => {
    await APITag.disassociateTag(id_tag, id_task, token);
  };

  const renderTags = () => {
    const orderedTag = organizeTagList();

    const filteredTag = orderedTag?.filter(tagFilter);

    const tagList = filteredTag?.map(handleTags);

    return tagList;
  };

  const tagFilter = (tag) => {
    if (inputSearch) {
      if (tag.desc_tag.toLowerCase().includes(inputSearch.toLowerCase())) {
        return tag;
      } else {
        return;
      }
    } else {
      return tag;
    }
  };

  const organizeTagList = () => {
    // const tagUsed = tagsList?.filter((item) =>
    //   allTags.find((tag) => tag.id_tag === item.id_tag)
    // );

    if (viewOnly || visibleView) {
      return tagsList;
    }

    const otherTags = [];

    for (const tag of allTags) {
      const hasTag = tagsList?.find((item) => item.id_tag === tag.id_tag);

      if (!hasTag) {
        otherTags.push(tag);
      }
    }

    const allTagsOrganized = tagsList?.concat(otherTags);

    return allTagsOrganized;
  };

  const handleTags = (tag) => {
    if (tagsList.find((item) => tag.id_tag === item.id_tag)) {
      return (
        <Tag
          key={tag.id_tag}
          name={tag.desc_tag}
          icon={(visibleView && !viewOnly) ? true : false}
          options={viewOnly || user.permissionId === 1 ? false : true}
          editTag={(value) => {
            putTag(tag.id_tag, value);
            const arrayTag = [...tagsList];
            const tagCurrent = arrayTag.find(item => item.id_tag === tag.id_tag);
            tagCurrent.desc_tag = value;
            setTagsList([...arrayTag])
          }}
          onClick={() => {
            handleTagActive(tag);
          }}
          onClickIcon={() => {
            const arrayTag = [...tagsList];
            const tagsFiltered = arrayTag.filter(
              (item) => item.id_tag !== tag.id_tag
            );
            setTagsList(tagsFiltered);
            if (type === "add") {
              return setTags(tagsFiltered);
            }
            deleteDisassociateTag(tag.id_tag, idTask);
          }}
        />
      );
    } else {
      return (
        <Tag
          name={tag.desc_tag}
          key={`tag-item-${tag.id_tag}`}
          options={viewOnly || user.permissionId === 1 ? false : true}
          disabled
          editTag={(value) => {
            putTag(tag.id_tag, value);
            //  const arrayTag = [...allTags];
            //  const tagCurrent = arrayTag.find(item => item.id_tag === tag.id_tag);
            //  tagCurrent.desc_tag = value;
            //  setAllTags([...arrayTag])
          }}
          onClick={(e) => {
            e.preventDefault();
            handleTagInactive(tag);
          }}
        />
      );
    }
  };

  const addTag = async (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (!inputSearch || visibleView) {
        return;
      };

      const existingTag = allTags.find(
        (tag) => tag.desc_tag.toLowerCase() === inputSearch.toLowerCase()
      );

      const tagAdded = tagsList.find(
        (tag) => tag.desc_tag.toLowerCase() === inputSearch.toLowerCase()
      );

      if (existingTag && tagAdded) {
        return;
      };

      if (existingTag && !tagAdded) {
        const arrayTags = [...tagsList];
        arrayTags.push(existingTag);
        setTagsList(arrayTags);

        if (type === "add") {
          return setTags(arrayTags);
        }

        return postAssociateTag(existingTag.id_tag, idTask);
      };

      if (!existingTag && !tagAdded && !type) {
        if (user.permissionId !== 2) {
          return;
        }
        return postCreateAssociateTag(inputSearch, idTask);
      };

      if (!existingTag && !tagAdded && type === "add") {
        if (user.permissionId !== 2) {
          return;
        }

        const response = await postCreateTag(inputSearch);

        const arrayTags = [...tagsList];
        arrayTags.push({ id_tag: response.id_tag, desc_tag: inputSearch });

        getTags();

        setTagsList(arrayTags);
        setTags(arrayTags);
      }
    }
  };

  const handleTagActive = (tag) => {
    if (viewOnly || visibleView) {
      return;
    }
    const arrayTags = [...tagsList];
    const filteredTags = arrayTags.filter((item) => item.id_tag !== tag.id_tag);
    setTagsList(filteredTags);

    if (type === "add") {
      return setTags(filteredTags);
    }

    deleteDisassociateTag(tag.id_tag, idTask);
  };

  const handleTagInactive = (tag) => {
    const arrayTags = [...tagsList];
    arrayTags.push(tag);
    setTagsList(arrayTags);

    if (type === "add") {
      return setTags(arrayTags);
    }

    /* id_tag: number, desc_tag: string */
    console.log("ADIÇÃO DE TAG", tag)

    postAssociateTag(tag.id_tag, idTask);
  };

  //USE EFFECTS


  return !viewOnly || (viewOnly && tagsList?.length > 0) ? (
    <div className={`tag-list ${className} ${viewOnly ? 'tag-list--view-only' : ''}`} ref={ref}>
      <div className="tag-list__tags">
        {tagsList?.slice(0, tagLimit).map((tag, index) => (
          <Tag
            hideMenu
            icon={viewOnly ? false : true}
            options={viewOnly || user.permissionId === 1 ? false : true}
            name={tag.desc_tag}
            key={index}
            editTag={(value) => {
              putTag(tag.id_tag, value);
              const arrayTag = [...tagsList];
              const tagCurrent = arrayTag.find(item => item.id_tag === tag.id_tag);
              tagCurrent.desc_tag = value;
              setTagsList([...arrayTag])
            }}
            onClickIcon={() => {
              const arrayTag = [...tagsList];
              const tagsFiltered = arrayTag.filter(
                (item) => item.id_tag !== tag.id_tag
              );
              setTagsList(tagsFiltered);
              if (type === "add") {
                return setTags(tagsFiltered);
              }
              deleteDisassociateTag(tag.id_tag, idTask);
            }}
          />
        ))}
      </div>
      {(!viewOnly || (viewOnly && tagsList?.length > tagLimit)) && (
        <div className="tag-list__buttons">
          {tagsList?.length > tagLimit && (
            <div className="tag-list__buttons__view">
              <Tippy
                theme="popover-default"
                // #TOVERIFY: TRIGGER
                // trigger="click"
                visible={visibleView}
                onClickOutside={hideView}
                content={
                  <div className={`tag-list__popover tag-list-view`}>
                    <div className="tag-list__popover__header">
                      <Typography.H4 color="gray-400">Visualizar</Typography.H4>
                      <IconClose
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          hideView();
                        }}
                      />
                    </div>
                    <Input
                      rightIcon={<IconSearch />}
                      type="text"
                      placeholder="Busque ou adicione uma tag"
                      autoComplete="on"
                      value={inputSearch}
                      onChange={(value) => setInputSearch(value)}
                      onKeyPress={(e) => addTag(e)}
                    />
                    <div className="tag-list__popover__list">
                      {visibleView ? renderTags() : ""}
                    </div>
                  </div>
                }
                placement="top"
                delay={[0, 0]}
                allowHTML
                interactive
              >
                <button
                  style={disabledVisibleView && { cursor: "default" }}
                  onClick={(e) => {
                    if (!disabledVisibleView) {
                      e.stopPropagation();
                      visibleView ? hideView() : showView()
                    }
                  }}
                >

                  +{tagsList.length - tagLimit}

                  {/* <Typography.H6 color="primary-dark">
                    +{tagsList.length - tagLimit}
                  </Typography.H6> */}
                </button>
              </Tippy>
            </div>
          )}

          {!viewOnly && (
            <Tippy
              theme="popover-default"
              //trigger="click"
              visible={visibleAdd}
              onClickOutside={hide}
              content={
                <div className={`tag-list__popover `}>
                  <div className="tag-list__popover__header">
                    <Typography.H4 color="gray-400">
                      Ver e adicionar tags
                    </Typography.H4>
                    <IconClose
                      onClick={(e) => {
                        e.preventDefault();
                        hide();
                      }}
                    />
                  </div>
                  <Input
                    rightIcon={<IconSearch />}
                    type="text"
                    placeholder="Busque ou adicione uma tag"
                    autoComplete="on"
                    value={inputSearch}
                    onChange={(value) => setInputSearch(value)}
                    onKeyPress={(e) => addTag(e)}
                  />
                  <div className="tag-list__popover__list">{visibleAdd ? renderTags() : ''}</div>
                </div>
              }
              placement="top"
              delay={[0, 0]}
              zIndex={5}
              allowHTML
              interactive
            >
              {
                buttonTooltip ?
                  <div style={{ display: 'flex', height: '24px' }}>
                    <Tippy content='Adicionar Tags'>
                      <button onClick={visibleAdd ? hide : show}>
                        <IconAddCircle />
                      </button>
                    </Tippy>
                  </div>
                  :
                  <>
                    {
                      showButtonAddTag === false ? null :
                        <button onClick={visibleAdd ? hide : show}>
                          <IconAddCircle />
                        </button>
                    }
                  </>
              }
            </Tippy>
          )}
        </div>
      )}
    </div>
  ) : (
    ""
  );
}
