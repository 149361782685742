//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// UTILS

//STYLES

//COMPONENTS
import { DraggableCard } from "../../../../components/data-display";
import { Typography } from "../../../../components/general";
import { PopoverMenu } from "../../../../components/navigation";
import { Modal, Tippy } from "../../../../components/feedback";
import { Toast } from "../../../../utils/toast";
import Editable from "./Editable";

//SERVICES - api, conectors...
import * as APITask from "./../../../../services/api/task";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconAddTask } from "./../../../../assets/icons/IconAdd.svg";
import { ReactComponent as IconMoreVertical } from "./../../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/Edit-2.svg";
import { ReactComponent as TrashIcon } from "./../../../../assets/icons/Trash-2.svg";

export default function Kanban({
    permissionProfile,
    setIsOpenModalAddTask,
    token,
    taskStatus,
    setTaskStatus,
    addBoard,
    getStatus,
    updateMonthlyTasks,
    setIsFrameDeleted,
    setIsFrameUpdated
}) {
    //REDUX - Selectors

    //GENERAL

    //STATES
    const [isOpenModalDeleteStatus, setIsOpenModalDeleteStatus] = useState(false);
    const [isOpenModalUpdateStatus, setIsOpenModalUpdateStatus] = useState(false);
    const [boardId, setBoardId] = useState(null);
    const [boardEditId, setBoardEditId] = useState(null);

    //FUNCTIONS
    const onDragEnd = async ({ source, destination }) => {
        if (!destination) return;

        const sourceColIndex = taskStatus.findIndex(e => e.id_status === source.droppableId);
        const destinationColIndex = taskStatus.findIndex(e => e.id_status === destination.droppableId);

        const sourceCol = taskStatus[sourceColIndex];
        const destinationCol = taskStatus[destinationColIndex];

        if (sourceCol === destinationCol) {
            const newTask = Array.from(sourceCol.tasks);

            const swapTask = newTask[source.index];
            newTask.splice(source.index, 1);
            newTask.splice(destination.index, 0, swapTask);

            const newColumnsState = taskStatus.map((t) => {
                if (t.id_status === sourceCol.id_status) {
                    t.tasks = newTask;
                    return t;
                } else return t;
            });

            const newColumnState2 = [...newColumnsState];

            const updatePosition = {
                id_tarefa: swapTask.id_tarefa,
                id_fila: sourceCol.id_status
            }

            const response = await APITask.updateTaskPosition(updatePosition, token);

            if (response.status === 200) {
                setTaskStatus(newColumnState2);
            } else {
                Toast(
                    "Erro ao alterar posição da tarefa!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                )
            }
        } else {
            const startTask = Array.from(sourceCol.tasks);
            const finishTask = Array.from(destinationCol.tasks);

            const [item] = startTask.splice(source.index, 1);

            finishTask.splice(destination.index, 0, item);

            const newColumnState = taskStatus.map((t) => {
                if (t.id_status === sourceCol.id_status) {
                    t.tasks = startTask;
                    return t;
                } else if (t.id_status === destinationCol.id_status) {
                    t.tasks = finishTask;
                    return t;
                } else return t;
            });

            const newColumnState2 = [...newColumnState];

            const updatePosition = {
                id_tarefa: item.id_tarefa,
                id_fila: destinationCol.id_status
            }

            const response = await APITask.updateTaskPosition(updatePosition, token);

            if (response.status === 200) {
                updateMonthlyTasks();
                setTaskStatus(newColumnState2);
            } else {
                Toast(
                    "Erro ao alterar posição da tarefa!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                )
            }
        }
    };

    function renderQuantityTasks(task) {
        return <>
            {`(${task < 10 ? '0' : ''}${task} tarefa${task === 1 ? '' : 's'})`}
        </>
    };

    function renderPopoverRemoveBoard(boardId) {
        return [
            {
                title: (
                    <>
                        <Typography.H5 color="gray-180" weight="500">
                            <EditIcon />
                            Editar
                        </Typography.H5>
                    </>
                ),
                onClick: () => {
                    setBoardEditId(boardId);
                    setIsOpenModalUpdateStatus(true);
                },
            },
            {
                title: (
                    <>
                        <Typography.H5 color="red" weight="500">
                            <TrashIcon className="popover-icon" />
                            Remover
                        </Typography.H5>
                    </>
                ),
                onClick: () => {
                    setBoardId(boardId);
                    setIsOpenModalDeleteStatus(true);
                },
            },
        ];
    };

    //USE EFFECTS

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="section-tasks-kanban__board-container">
                    {taskStatus.map((currentTaskStatus) => {
                        return (
                            <>
                                {currentTaskStatus.id_status !== "4" && (
                                    <Droppable
                                        key={currentTaskStatus.id_status}
                                        droppableId={currentTaskStatus.id_status}
                                    >
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                className="board"
                                                ref={provided.innerRef}
                                            >
                                                <header className="board__header">
                                                    <div className="board__header__text">
                                                        {currentTaskStatus.id_status !== "2" || currentTaskStatus.id_status !== "3" ? (
                                                            <Typography.H5 weight="bold" color="black">
                                                                <Tippy content={currentTaskStatus.descricao_status}>
                                                                    <div className="description-status">
                                                                        {currentTaskStatus.descricao_status}
                                                                    </div>
                                                                </Tippy>
                                                            </Typography.H5>
                                                        ) : (
                                                            <Typography.H5 weight="bold" color="black">
                                                                {currentTaskStatus.descricao_status}
                                                            </Typography.H5>
                                                        )}

                                                        <Typography.H6 weight="500" color="black">
                                                            {renderQuantityTasks(currentTaskStatus.tasks?.length)}
                                                        </Typography.H6>
                                                    </div>

                                                    <div className="board__header__add-task">
                                                        <IconAddTask onClick={() => setIsOpenModalAddTask({
                                                            modal: true,
                                                            queueId: currentTaskStatus.id_status
                                                        })}
                                                        />

                                                        {currentTaskStatus.permit_delete != true && (
                                                            <PopoverMenu
                                                                placement="bottom-end"
                                                                menuData={renderPopoverRemoveBoard(currentTaskStatus.id_status)}
                                                            >
                                                                <button
                                                                    className="board__header__add-task__remove-board"
                                                                    type="button"
                                                                >
                                                                    <IconMoreVertical />
                                                                </button>
                                                            </PopoverMenu>
                                                        )}
                                                    </div>
                                                </header>

                                                <div
                                                    className="board__cards"
                                                >
                                                    {currentTaskStatus.tasks?.map((currentCard, cardIndex) => {
                                                        currentCard.id_tarefa = currentCard.id_tarefa.toString();

                                                        return (
                                                            <Draggable
                                                                key={currentCard.id_tarefa}
                                                                draggableId={currentCard.id_tarefa}
                                                                index={cardIndex}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <DraggableCard
                                                                            hideTaskUnlinkButton
                                                                            taskIndex={cardIndex}
                                                                            statusColor={currentTaskStatus.queueColor}
                                                                            cardStatusIcon={currentTaskStatus.icon}
                                                                            deadline={currentCard.prazo_resolucao}
                                                                            title={currentCard.assunto}
                                                                            description={currentCard.descricao}
                                                                            tags={currentCard.tagsAssociated}
                                                                            profiles={currentCard.usersAssociated}
                                                                            status={currentCard.id_fila}
                                                                            lembretes={currentCard.remindersAssociated}
                                                                            id={currentCard.id_tarefa}
                                                                            idSolicitante={currentCard.id_solicitante}
                                                                            tagLimit={"1"}
                                                                            permissions={permissionProfile}
                                                                            updateMonthlyTasks={updateMonthlyTasks}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })}
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                )}
                            </>
                        )
                    })}

                    {/* COLUNA DE STATUS/TAREFAS CONCLUÍDAS */}
                    {taskStatus.map((currentTaskStatus) => {
                        return (
                            <>
                                {currentTaskStatus.id_status === "4" && (
                                    <Droppable
                                        key={currentTaskStatus.id_status}
                                        droppableId={currentTaskStatus.id_status}
                                    >
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                className="board"
                                                ref={provided.innerRef}
                                            >
                                                <header className="board__header">
                                                    <div className="board__header__text">
                                                        {currentTaskStatus.id_status !== "2" || currentTaskStatus.id_status !== "3" ? (
                                                            <Typography.H5 weight="bold" color="black">
                                                                <Tippy content={currentTaskStatus.descricao_status}>
                                                                    <div className="description-status">
                                                                        {currentTaskStatus.descricao_status}
                                                                    </div>
                                                                </Tippy>
                                                            </Typography.H5>
                                                        ) : (
                                                            <Typography.H5 weight="bold" color="black">
                                                                {currentTaskStatus.descricao_status}
                                                            </Typography.H5>
                                                        )}

                                                        <Typography.H6 weight="500" color="black">
                                                            {renderQuantityTasks(currentTaskStatus.tasks?.length)}
                                                        </Typography.H6>
                                                    </div>

                                                    <div className="board__header__add-task">
                                                        <IconAddTask onClick={() => setIsOpenModalAddTask({
                                                            modal: true,
                                                            queueId: currentTaskStatus.id_status
                                                        })}
                                                        />

                                                        {currentTaskStatus.permit_delete != true && (
                                                            <PopoverMenu
                                                                placement="bottom-end"
                                                                menuData={renderPopoverRemoveBoard(currentTaskStatus.id_status)}
                                                            >
                                                                <button
                                                                    className="board__header__add-task__remove-board"
                                                                    type="button"
                                                                >
                                                                    <IconMoreVertical />
                                                                </button>
                                                            </PopoverMenu>
                                                        )}
                                                    </div>
                                                </header>

                                                <div
                                                    className="board__cards"
                                                >
                                                    {currentTaskStatus.tasks?.map((currentCard, cardIndex) => {
                                                        currentCard.id_tarefa = currentCard.id_tarefa.toString();

                                                        return (
                                                            <Draggable
                                                                key={currentCard.id_tarefa}
                                                                draggableId={currentCard.id_tarefa}
                                                                index={cardIndex}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <DraggableCard
                                                                            hideTaskUnlinkButton
                                                                            taskIndex={cardIndex}
                                                                            statusColor={currentTaskStatus.queueColor}
                                                                            cardStatusIcon={currentTaskStatus.icon}
                                                                            deadline={currentCard.prazo_resolucao}
                                                                            title={currentCard.assunto}
                                                                            description={currentCard.descricao}
                                                                            tags={currentCard.tagsAssociated}
                                                                            profiles={currentCard.usersAssociated}
                                                                            status={currentCard.id_fila}
                                                                            lembretes={currentCard.remindersAssociated}
                                                                            id={currentCard.id_tarefa}
                                                                            idSolicitante={currentCard.id_solicitante}
                                                                            tagLimit={"1"}
                                                                            permissions={permissionProfile}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })}
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                )}
                            </>
                        )
                    })}

                    <Editable
                        onSubmit={addBoard}
                        taskStatus={taskStatus}
                    />
                </div>
            </DragDropContext>

            <Modal.DeleteStatus
                isOpen={isOpenModalDeleteStatus}
                onClose={(event) => {
                    setIsOpenModalDeleteStatus(event);
                    setBoardId(null);
                }}
                boardId={boardId}
                taskStatus={taskStatus}
                setIsFrameDeleted={setIsFrameDeleted}
                onDelete={getStatus}
            />

            <Modal.UpdateStatus
                isOpen={isOpenModalUpdateStatus}
                onClose={(event) => {
                    setIsOpenModalUpdateStatus(event);
                }}
                boardEditId={boardEditId}
                taskStatus={taskStatus}
                setIsFrameUpdated={setIsFrameUpdated}
                onEdit={getStatus}
            />
        </>
    )
}
