import instance from "./configAxios";

export async function listTags(token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }
    const response = await instance.get(`/list-tags`, config);

    // console.log('RESPONSE-TAGS:', response);
    return response;
  } catch (error) {
    console.log(error)
  }
}

export async function associateTag(body, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }
    const response = await instance.post(`/associate-tag-to-task`, body, config);

    // console.log('RESPONSE-ASSOCIATE-TAG:', response);
    return response;
  } catch (error) {
    console.log(error)
  }
}

export async function disassociateTag(idTag, idTask, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }
    const response = await instance.delete(`/disassociate-tag-to-task/${idTag}/${idTask}`, config);

    // console.log('RESPONSE-DISASSOCIATE-TAG:', response);
    return response;
  } catch (error) {
    console.log(error)
  }
}

export async function createAssociateTag(body, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }
    const response = await instance.post(`/create-tag-associate`, body, config);

    // console.log('RESPONSE-CREATE-ASSOCIATE-TAG:', response);
    return response;
  } catch (error) {
    console.log(error)
  }
}

export async function createTag(body, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }
    const response = await instance.post(`/create-tag`, body, config);

    //console.log('RESPONSE-CREATE-TAG:', response);
    return response;
  } catch (error) {
    console.log(error)
  }
}

export async function updateTag(idTag, body, token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        'Content-Type': 'application/json'
      }
    }

    const response = await instance.put(`/update-tag/${idTag}`,body, config);

    //console.log('RESPONSE-UPDATE-TAG:', response);
    return response;
  } catch (error) {
    console.log(error)
  }
}