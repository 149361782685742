//GLOBAL - components from npm
import React, { useState, useEffect } from 'react';
import { getJsonTask } from '../../../services/local';
import { useHistory } from "react-router-dom";

//STYLES
import './tasks-and-cases.scss'

//COMPONENTS
import { Button, Typography } from "../../../components/general";
import { DropdownTask } from "../../../components/layout";


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import { ReactComponent as IconAssignment } from '../../../assets/icons/Assignment.svg'
import { ReactComponent as IconNotes } from '../../../assets/icons/Notes.svg'
import { ReactComponent as IconPlusCircle } from '../../../assets/icons/PlusCircle.svg'


export default function TasksAndCases() {
    //GENERAL
    const history = useHistory();


    //STATES
    const [openFlap, setOpenFlap] = useState("task");
    const [tasks, setTasks] = useState([]);


    //REDUX - Selectors


    //FUNCTIONS
    const getTasks = async () => {
        const response = await getJsonTask();

        setTasks(response.tasks);
    }


    //USE EFFECTS
    useEffect(() => {
        getTasks();
    }, [])


    return (
        <div className="tasks-and-cases">
            <header className="tasks-and-cases__header">
                <nav className="tasks-and-cases__header__nav">
                    <button className={`${openFlap === 'case' ? 'active' : ''} btn-tab`} onClick={() => setOpenFlap("case")}>
                        <IconAssignment />
                        <Typography.H4 color="gray-200">Caso</Typography.H4>
                    </button>
                    <button className={`${openFlap === 'task' ? 'active' : ''} btn-tab`} onClick={() => setOpenFlap("task")}>
                        <IconNotes />
                        <Typography.H4>Tarefa</Typography.H4>
                    </button>
                </nav>
                <div className="header-buttons">
                    <Button type="primary" icon={<IconPlusCircle />} iconFillType="stroke" onClick={() => history.push('/interacao-ativa/tarefas/novo')}>Tarefa</Button>
                    <Button type="primary" icon={<IconPlusCircle />} iconFillType="stroke">Lembrete</Button>
                </div>
            </header>
            {openFlap === 'task' &&
                <main className="main-task">
                    {tasks.map((task, index) => (
                        <DropdownTask key={index} title={task.name} date={task.date} description={task.description} status={task.status} tags={task.tags} channelList={task.communicationChannel} />
                    ))}
                </main>
            }
        </div>
    )
}
