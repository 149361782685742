//GLOBAL - components from npm
import React, { useEffect, useRef } from "react";
import { isPast, format } from "date-fns";
import { BroadcastChannel } from "broadcast-channel";

// UTILS
import getLocalStorage from "./../../../utils/getLocalStorage";
import formatDate from "./../../../utils/formatDate";
import postMessage from "../../../utils/postMessage";
import { Toast } from "../../../utils/toast";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import { useDispatch, useSelector } from "react-redux";
import { saveInteraction, removeInteraction } from "../../../store/interaction";
import { saveRoute } from "../../../store/audit";
import { setFilter } from "../../../store/filters";

//ASSETS - icons, images...

export default function BrowserComms() {
  //GENERAL
  const token = getLocalStorage("token", null);
  let advancedsFilter = getLocalStorage("advancedsFilter", null);
  const dispatch = useDispatch();

  // BROADCAST CHANNEL (INTERNAL COMMS)
  const channel = new BroadcastChannel("ingdesk-internal");

  // REFS

  //STATES

  //REDUX - Selectors
  const filtersAdvanceds = useSelector((state) => state.filters.advancedsCases);

  //FUNCTIONS
  // SENDING FUNCTIONS

  // LISTENING FUNCTIONS
  // external listener
  function messageListener(event) {
    const message = event.data;
    // console.debug('(INGDESK) MESSAGE RECEIVED: ', message);
    // // debbuging purpose only
    // if (message.origin === 'ingdesk') {
    //   console.debug('(INGDESK) MESSAGE RECEIVED FROM INGDESK: ', message);
    // }
    console.debug("(INGDESK) MESSAGE RECEIVED FROM INGWEB: ", message);

    if (message.origin === "ingweb") {
      console.debug("(INGDESK) MESSAGE RECEIVED FROM INGWEB: ", message);

      switch (message.id) {
        case "interactionFailed":
          Toast(
            "Não foi possível iniciar a interação",
            message.payload.messageError
              ? message.payload.messageError
              : "Tente novamente mais tarde",
            "error"
          );
          break;

        case "deliverAuditRoute":
          //Recebe a mensagem 'deliverAuditRoute' e manda uma mensagem interna com id 'saveAuditRoute' com as informações recebidas.
          console.debug(
            "(INGDESK) MESSAGE RECEIVED FROM INGWEB ROUTE AUDIT: ",
            message
          );
          postMessage("saveAuditRoute", message, true);
          break;

        // case ('currentInteraction'):
        //   console.debug('(INGDESK) MESSAGE RECEIVED FROM INGWEB: ', message);
        //   postMessage("saveInteraction", message.payload, true);
        //   break;

        // case ('logInteraction'):
        //   console.debug('(INGDESK) MESSAGE RECEIVED FROM INGWEB: ', message);
        //   postMessage("removeInteraction", message.payload, true);
        //   break;

        // case ('interactionEnded'):
        //     postMessage("removeInteraction", message.payload, true);
        //   break;

        default:
          break;
      }
    }
  }

  // internal listener
  function internalListener() {
    channel.onmessage = (message) => {
      console.debug("(INGDESK) INTERNAL MESSAGE RECEIVED: ", message);
      console.log("(INGDESK) INTERNAL MESSAGE RECEIVED: ", message);

      switch (message.id) {
        case "saveInteraction":
          //Recebe a mensagem 'saveInteraction' e salva no redux as informações da interação.
          //console.debug('(INGDESK) INTERNAL MESSAGE RECEIVED SAVE: ', message);
          //console.log("OROEOROOR JA TENHO AQUI", message)
          dispatch(
            saveInteraction({
              clientId: message.payload.clientId,
              clientName: message.payload.clientName,
              channelId: parseInt(message.payload.channelId),
              channelName: message.payload.channelName,
              destination: message.payload.destination,
              protocol: message.payload.protocol,
              interactionId: message.payload.interactionId,
              interaction: true,
              observation: message.payload.observation,
              companyName: message.payload.companyName
            })
          );

          break;

        case "removeInteraction":
          //Recebe a mensagem 'removeInteraction' e remove do redux as informações da interação.
          //console.debug('(INGDESK) INTERNAL MESSAGE RECEIVED REMOVE: ', message);
          dispatch(removeInteraction());
          dispatch(
            setFilter({
              to: "advancedsCases",
              type: "clients",
              value: [],
            })
          );
          dispatch(
            setFilter({ to: "advancedsCases", type: "active", value: false })
          );
          window.localStorage.setItem(
            "advancedsFilter",
            JSON.stringify(filtersAdvanceds)
          );
          break;

        case "saveAuditRoute":
          //Recebe a mensagem 'saveAuditRoute' e salva no redux a rota da auditória da interação.
          //console.debug('(INGDESK) INTERNAL MESSAGE RECEIVED SAVE ROUTE: ', message);
          dispatch(
            saveRoute({
              route: message.payload.url,
            })
          );
          break;

        default:
          break;
      }
    };
  }

  //USE EFFECTS
  useEffect(() => {
    window.addEventListener("message", messageListener);
    internalListener();

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return <></>;
}
