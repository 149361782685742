import instance from "./configAxios";

export async function getListOfUsers(token, userId) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = { // para buscar um usuário
            id_usuario: userId
        }

        const response = await instance.post('/buscar-usuario', data, config);

        return response;
    } catch (error) {
        console.log('getListOfUsers -> Error en el servidor:', error);

        return error
    }
};

export async function createUser(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = nome, id_organizacao, idfuncao, id_perfil, matricula, idgrupo, id_usuario(usuário logado)

        const response = await instance.post('/insert-usuario', data, config);

        return response;
    } catch (error) {
        console.log('createUser -> Error en el servidor:', error);

        return error
    }
};

export async function updateUser(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_usuario, nome, id_organizacao, idfuncao, id_perfil, matrícula, idgrupo

        const response = await instance.put('/update-usuario', data, config);

        return response;
    } catch (error) {
        console.log('updateUser -> Error en el servidor:', error);

        return error;
    }
};

export async function deleteUser(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_usuario(usuário que será excluído), id_usuario_logado

        const response = await instance.put('/delete-usuario', data, config);

        return response;
    } catch (error) {
        console.log('deleteUser -> Error en el servidor:', error);

        return error;
    }
};

export async function createUserOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_organizacao, id_usuario, criacao(se pode criar ou não, um caso - true || false), tipo_visualizacao, id_usuario_insercao(usuário logado)

        const response = await instance.post('/insert-organizacao-usuario', data, config);

        return response;
    } catch (error) {
        console.log('createUserOrganization -> Error en el servidor:', error);

        return error
    }
};

export async function updateUserOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_organizacao, criacao(se pode criar ou não, um caso - true || false), tipo_visualizacao e id_organizacao_usuario(id_organizacao_usuario que retorna no endpoint buscar-usuarios)

        const response = await instance.put('/update-organizacao-usuario', data, config);

        return response;
    } catch (error) {
        console.log('updateUserOrganization -> Error en el servidor:', error);

        return error
    }
};

export async function deleteUserOrganization(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_usuario_exclusao, id_organizacao_usuario

        const response = await instance.put('/delete-organizacao-usuario', data, config);

        return response;
    } catch (error) {
        console.log('deleteUserOrganization -> Error en el servidor:', error);

        return error
    }
};

export async function createStaggeredUser(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_escalonamento, id_usuario

        const response = await instance.post('/insert-escalonamento-usuario', data, config);

        return response;
    } catch (error) {
        console.log('createStaggeredUser -> Error en el servidor:', error);

        return error
    }
};

export async function deleteStaggeredUser(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_escalonamento, id_usuario

        const response = await instance.put('/delete-escalonamento-usuario', data, config);

        return response;
    } catch (error) {
        console.log('deleteStaggeredUser -> Error en el servidor:', error);

        return error
    }
};

export async function redefinePassword(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_usuario(usuário que a senha será redefinida)

        const response = await instance.put('/resetar-senha-usuario', data, config);

        return response;
    } catch (error) {
        console.log('redefinePassword -> Error en el servidor:', error);

        return error;
    }
};

// listagem de grupos existentes
export async function getUserGroup(token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/buscar-grupo-usuario', {}, config);

        return response;
    } catch (error) {
        console.log('getUserGroup -> Error en el servidor:', error);

        return error;
    }
};

// listagem de perfis existentes
export async function getUserProfile(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-perfil-usuario', {}, config);

        return response;
    } catch (error) {
        console.log('getUserProfile -> Error en el servidor:', error);

        return error
    }
};

// listagem de funções existentes - gestor, supervisor e analista
export async function getUserFunction(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-funcao-usuario', {}, config);

        return response;
    } catch (error) {
        console.log('getUserFunction -> Error en el servidor:', error);

        return error
    }
};

// listagem de tipos de visualizações
export async function getViewType(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-tipo-visualizacao', {}, config);

        return response;
    } catch (error) {
        console.log('getViewType -> Error en el servidor:', error);

        return error
    }
};