import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { styled } from "@mui/material/styles";
import ptBrLocale from "date-fns/locale/pt-BR";

//STYLES
import "./time-range-input.scss";

//COMPONENTS
import { Tippy } from "../../../feedback";

// UTILS
import formatDate from "./../../../../utils/formatDate";

//ASSETS - icons, images...
import { ReactComponent as IconCalendar2 } from "../../../../../src/assets/icons/Calendar-2.svg";

const CustomDateTimePicker = styled(DateTimePicker)({
  '& .MuiInputBase-input': {
    padding: '7px 10px',
  },
});

export default function TimeRangeInput({
  valueTime,
  setValueTime,
  widthTotal,
  errorTime,
  errorInput,
  disable,
  automaticSet = false,
}) {
  const [value, setValue] = useState(valueTime ?? null);
  const [labelsArrow, setLabelsArrow] = useState({
    label1: "Mês Anterior",
    label2: "Próximo Mês",
  });

  function handleViewChange(views) {
    if (views === "hours" || views === "minutes") {
      setLabelsArrow({ label1: "Ver horas", label2: "Ver minutos" });
    } else {
      setLabelsArrow({ label1: "Mês Anterior", label2: "Próximo Mês" });
    }
  }

  function handleDate(date) {
    let isValidDate = Date.parse(date);

    if (isNaN(isValidDate)) {
      return;
    } else {
      setValueTime(date);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
      <CustomDateTimePicker
        renderInput={(props) => (
          <TextField
            className={`time-rangeInput ${errorTime ? "error-time" : ""} ${disable === false ? "disabled" : " "
              } ${errorInput ? "error-input" : ""} `}
            sx={{ width: "100%" }}
            {...props}
          />
        )}
        value={valueTime}
        onChange={(newValue) => {
          setValue(newValue);
          if (automaticSet) {
            setValueTime(newValue);
          } else {
            handleDate(newValue);
          }
        }}
        leftArrowButtonText={labelsArrow.label1}
        rightArrowButtonText={labelsArrow.label2}
        onViewChange={(view) => handleViewChange(view)}
        components={{
          OpenPickerIcon: IconCalendar2,
        }}
      />
    </LocalizationProvider>
  );
}
