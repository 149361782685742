//GLOBAL - components from npm
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//COMPONENTS
import App from "./App";
import { BrowserComms } from "./components/other";

// STYLES
import "./styles/main.scss";
import "./styles/toast/main.scss";

//GLOBAL STATE - redux, env...
import { Provider } from "react-redux";
import store from "./store/configStore";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
      <BrowserComms />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        closeOnClick
        draggable
        pauseOnHover
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
