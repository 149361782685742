//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";

//COMPONENTS
import { Button, Typography } from "../../../components/general";
import { Loading, Modal } from "../../../components/feedback";
import { Table } from "../../../components/data-display";
import { Input } from "../../../components/data-entry";
import { Toast } from "../../../utils/toast";
import { formatPhone } from "../../../utils/formatPhone";
// import { PopoverMenu } from "../../../components/navigation";

//SERVICES - api, conectors...
import * as APIShiftWorker from "../../../services/api/shiftWorker";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search-normal.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-3.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";
import { ReactComponent as UserCircleAddIcon } from "./../../../assets/icons/user-cirlce-add.svg";
// import { ReactComponent as MoreVerticalIcon } from "../../../assets/icons/MoreHorizontal.svg";
// import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";
// import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-2.svg";

//STYLES
import "./shift-worker.scss";

export default function ShiftWorker({ returnToRecord }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL

    //STATES
    const [searchData, setSearchData] = useState("");
    const [emptyState, setEmptyState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const [updatedShiftWorker, setUpdatedShiftWorker] = useState(false);
    const [isOpenModalCreateShiftWorker, setIsOpenModalCreateShiftWorker] = useState(false);
    const [isOpenModalUpdateShiftWorker, setIsOpenModalUpdateShiftWorker] = useState({
        state: false,
        shift_worker_id: null
    });
    const [isOpenModalDeleteShiftWorker, setIsOpenModalDeleteShiftWorker] = useState({
        state: false,
        shift_worker_id: null,
        userId: null
    });
    const [shiftWorkers, setShiftWorkers] = useState([]);
    const [filteredShiftWorkers, setFilteredShiftWorkers] = useState([]);

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleSearchShiftWorkers(event) {
        const inputValue = event;

        setSearchData(inputValue);

        if (event === "") {
            setFiltered(false);
            setFilteredShiftWorkers([]);
        }
    };

    function handleTableDataLookup() {
        const searchTerm = searchData.toLowerCase();

        const filteredData = shiftWorkers.filter(
            (data) =>
                (data.nome_plantonista != null &&
                    data.nome_plantonista !== "" &&
                    data.nome_plantonista.toLowerCase().includes(searchTerm)) ||
                (data.telefone_plantonista != null &&
                    data.telefone_plantonista !== "" &&
                    data.telefone_plantonista.toLowerCase().includes(searchTerm)) ||
                (data.email_plantonista != null &&
                    data.email_plantonista !== "" &&
                    data.email_plantonista.toLowerCase().includes(searchTerm)) ||
                (data.nome_organizacao != null &&
                    data.nome_organizacao !== "" &&
                    data.nome_organizacao.toLowerCase().includes(searchTerm))
        );

        setFilteredShiftWorkers(filteredData);
        setFiltered(true);
    };

    function refactorShiftWorkersListing(data) {
        let refactoredData = [];

        if (!data) throw new Error('Error when fetching data!');

        data.map((item) => {
            const newObject = {
                name: {
                    data: item.nome_plantonista,
                    config: {
                        className: ""
                    },
                },
                phone: {
                    data: formatPhone(item.telefone_plantonista),
                    config: {
                        className: ""
                    }
                },
                email: {
                    data: item.email_plantonista,
                    config: {
                        className: ""
                    }
                },
                position: {
                    data: item.id_cargo === 1 ? "Analista" : item.id_cargo === 2 ? "Supervisor" : "Gerente",
                    config: {
                        className: ""
                    }
                },
                organization: {
                    data: item.nome_organizacao,
                    config: {
                        className: ""
                    }
                },
                actions: {
                    data: (
                        <div className="actions-shift-worker">
                            <button
                                className="actions-shift-worker__edit"
                                onClick={() => setIsOpenModalUpdateShiftWorker({
                                    state: true,
                                    shift_worker_id: item.id_plantonista
                                })}
                            >
                                <EditIcon />
                            </button>

                            <button
                                className="actions-shift-worker__delete"
                                onClick={() => setIsOpenModalDeleteShiftWorker({
                                    state: true,
                                    shift_worker_id: item.id_plantonista,
                                    userId: userData.userId
                                })}
                            >
                                <TrashIcon />
                            </button>
                        </div>
                    ),
                    config: {
                        className: "action-options"
                    }
                },
            };

            refactoredData.push(newObject);

            return refactoredData;
        });

        return refactoredData;
    };

    function renderTableContent() {
        switch (loading) {
            case true:
                return (
                    <div className="table-card-content__loading">
                        <Loading.Paperplane />
                    </div>
                )

            case false:
                if (shiftWorkers.length > 0) {
                    return (
                        <>
                            {searchData && filtered && filteredShiftWorkers.length < 1 ? (
                                <div className="table-card-content__empty-state">
                                    <div className="table-card-content__empty-state__icon">
                                        <UserCircleAddIcon />
                                    </div>

                                    <div className="table-card-content__empty-state__text">
                                        <Typography.H4 weight="bold" color="blue-selected-4">
                                            Ops! Sem plantonistas cadastrados.
                                        </Typography.H4>

                                        <Typography.H5 color="gray-180" className="text-center">
                                            Clique no botão <span className="text-bold">"Adicionar plantonista"</span>. <br />
                                            Preencha os dados do novo membro da equipe.
                                        </Typography.H5>

                                        <Typography.H5 color="gray-180">
                                            Pronto! O plantonista estará pronto para entrar em ação.
                                        </Typography.H5>
                                    </div>
                                </div>
                            ) : (
                                <div className="table-card-content__table-container">
                                    <Table
                                        fixedHeader
                                        className="table-shift-worker"
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        columns={tableColumns}
                                        data={searchData && filtered ? shiftWorkersFiltered : shiftWorkersData}
                                    />
                                </div>
                            )}
                        </>
                    )
                } else {
                    return (
                        <div className="table-card-content__empty-state">
                            <div className="table-card-content__empty-state__icon">
                                <UserCircleAddIcon />
                            </div>

                            <div className="table-card-content__empty-state__text">
                                <Typography.H4 weight="bold" color="blue-selected-4">
                                    Ops! Sem plantonistas cadastrados.
                                </Typography.H4>

                                <Typography.H5 color="gray-180" className="text-center">
                                    Para garantir que sempre haja alguém disponível: clique no botão <span className="text-bold">"Adicionar plantonista"</span>. <br />
                                    Preencha os dados do novo membro da equipe.
                                </Typography.H5>

                                <Typography.H5 color="gray-180">
                                    Pronto! O plantonista estará pronto para entrar em ação.
                                </Typography.H5>
                            </div>
                        </div>
                    )
                }

            default:
                break;
        }
    };

    async function getListOfShiftWorkers(shiftWorkerId) {
        setLoading(true);

        const response = await APIShiftWorker.getListOfShiftWorkers(token, shiftWorkerId);

        if (response.status === 200) {
            setShiftWorkers(response.data);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        };

        setLoading(false);
    };

    //USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                id: "name",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Telefone",
                accessor: "phone",
                id: "phone",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Email",
                accessor: "email",
                id: "email",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Cargo",
                accessor: "position",
                id: "position",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Organização",
                accessor: "organization",
                id: "organization",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ]
    );

    const shiftWorkersData = useMemo(() => {
        if (shiftWorkers) {
            const refactoredData = refactorShiftWorkersListing(shiftWorkers);
            return refactoredData;
        }
    }, [shiftWorkers]);

    const shiftWorkersFiltered = useMemo(() => {
        if (Array.isArray(filteredShiftWorkers)) {
            const refactoredData = refactorShiftWorkersListing(filteredShiftWorkers);
            return refactoredData;
        }
    }, [filteredShiftWorkers]);

    //USE EFFECTS
    useEffect(() => {
        getListOfShiftWorkers()
    }, []);

    useEffect(() => {
        shiftWorkers.forEach((item) => {
            item = Object.assign(item, { nome_cargo: "" });

            if (item.id_cargo === 1) {
                item.nome_cargo = "Analista"
            } else if (item.id_cargo === 2) {
                item.nome_cargo = "Supervisor"
            } else {
                item.nome_cargo = "Gerente"
            }
        })
    }, [shiftWorkers]);

    useEffect(() => {
        if (!searchData || searchData.trim() === "") {
            setFiltered(false)
            setFilteredShiftWorkers([])
        }
    }, []);

    useEffect(() => {
        if (updatedShiftWorker) {
            handleTableDataLookup()
        }
    }, [updatedShiftWorker, shiftWorkers]);

    return (
        <>
            <main className={`page-shift-worker ${""}`}>
                <header className="page-shift-worker__header">
                    <div className="page-shift-worker__header__title">
                        <ArrowLefIcon onClick={returnToRecord} />

                        <Typography.H2 color="gray-180" weight="bold">
                            Cadastro de plantonista
                        </Typography.H2>
                    </div>

                    <div>
                        <Button
                            className=""
                            onClick={() => setIsOpenModalCreateShiftWorker(true)}
                        >
                            <Typography.H4 weight="bold" color="white">
                                Adicionar plantonista
                            </Typography.H4>
                        </Button>
                    </div>
                </header>

                <div className="table-card">
                    <div className="table-card-content">
                        <Input
                            type="text"
                            width="432px"
                            placeholder="Pesquisar"
                            value={searchData}
                            onChange={handleSearchShiftWorkers}
                            onKeyPress={(event) =>
                                (event.code === "Enter" || event.code === "NumpadEnter") &&
                                handleTableDataLookup()
                            }
                            rightIcon={
                                <button onClick={handleTableDataLookup}>
                                    <SearchIcon />
                                </button>
                            }
                        />

                        {renderTableContent()}
                    </div>
                </div>
            </main>

            <Modal.CreateShiftWorker
                isOpen={isOpenModalCreateShiftWorker}
                onClose={(event) => setIsOpenModalCreateShiftWorker(event)}
                shiftWorkers={shiftWorkers}
                onCreate={getListOfShiftWorkers}
            />

            <Modal.UpdateShiftWorker
                isOpen={isOpenModalUpdateShiftWorker.state}
                shiftWorkerId={isOpenModalUpdateShiftWorker.shift_worker_id}
                onClose={(event) => setIsOpenModalUpdateShiftWorker({
                    ...isOpenModalUpdateShiftWorker, state: event
                })}
                detailedShiftWorker={shiftWorkers}
                onUpdate={getListOfShiftWorkers}
                setUpdatedShiftWorker={setUpdatedShiftWorker}
            />

            <Modal.DeleteShiftWorker
                isOpen={isOpenModalDeleteShiftWorker.state}
                shiftWorkerId={isOpenModalDeleteShiftWorker.shift_worker_id}
                userId={isOpenModalDeleteShiftWorker.userId}
                onClose={(event) => setIsOpenModalDeleteShiftWorker({
                    ...isOpenModalDeleteShiftWorker, state: event
                })}
                onDelete={getListOfShiftWorkers}
            />
        </>
    )
}


