export const customStylesOne = {
    input: (provided) => ({
        ...provided,
        flexGrow: "1",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        color: "#27282e",
        transition: "all 0.25s",
        outline: "none",
    }),
    menu: (provided) => ({
        ...provided,
        maxHeight: "160px",
        position: "absolute",
        display: "flex",
        zIndex: 15,
        flexDirection: "column",
        borderTop: "0",
        overflow: "auto",
        border: "1px solid #2684FF",
        boxShadow: "none",
        borderColor: "#455cc7",
    }),
    control: (provided) => ({
        ...provided,
        cursor: "pointer",
        boxShadow: "none",
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: "80px",
        overflow: "auto",
        "::-webkit-scrollbar": {
            width: "5px",
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
    indicatorContainer: (provided) => ({
        ...provided,
        ":active": {
            color: "hsl(0, 0%, 80%)",
            transform: "rotate(180deg)"
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        color: "#bfc3ca",
    }),
    option: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        minHeight: "min-content",
        padding: "12px",
        textAlign: "left",

        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        color: "#828292",
        cursor: "pointer",

        ":active": {
            backgroundColor: "#ebf2ff",
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        gap: "4px",
        griGap: "4px",
        padding: "4px 4px 4px 8px",
        backgroundColor: "#f4f4f4",
        borderRadius: "24px",
        minWidth: "max-content",
        height: "25px",
        marginRight: "8px",
        marginBottom: "4px",
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "112.5%",
        padding: "5px",
        alignItems: "flex-end",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100px",
        color: "#455cc7",
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        height: "20px",
        width: "20px",
        background: "#bfc3ca",
        color: "#fff",
        borderRadius: "50%",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "0.5em",
        fontSize: "18px",
        cursor: "pointer",

        ":hover": {
            backgroundColor: "none",
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,

        ":hover": {
            transform: "rotate(180deg)",
        },
    }),
};
