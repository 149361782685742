import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Loading, Modal } from "./../../../feedback";
import { Button, Typography } from "../../../general";
import { FieldContainer } from "../../../data-display";
import { Input, Select } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";

import * as APITypeManagement from "./../../../../services/api/typeManagement";
import * as APISla from "./../../../../services/api/sla";

import "./update-subreason.scss";

export default function UpdateSubreason({
    isOpen,
    onClose,
    treeData,
    setTreeData,
    selectedTypeId,
    selectedReasonId,
    organizationId,
    subreasonData,
    setSubreasonData
}) {
    const token = useSelector((state) => state.auth.token);

    console.log("subreasonData", subreasonData)

    const [pageStatus, setPageStatus] = useState("requesting");
    const [listSla, setListSla] = useState([]);

    function onCloseCustom() {
        onClose();
    };

    const handleRadioChange = (event) => {
        const value = event.target.value === "yes" ? true : false;

        const updatedReasonValues = { ...subreasonData.subreasonValues };

        for (const key in updatedReasonValues) {
            if (key === "editavel") {
                updatedReasonValues[key] = value
            }
        }

        setSubreasonData(prevValues => ({
            ...prevValues,
            subreasonValues: updatedReasonValues
        }))
    };

    async function getListSla() {
        const response = await APISla.listSla(token);

        const data = response.data;
        let updatedData = [];

        data.forEach((sla) => {
            let updateSla = {
                id: sla.id_sla,
                label: sla.desc_sla,
            };

            updatedData.push(updateSla);
        });

        setListSla(updatedData);
    };

    async function updateSubreasonDescription(subreasonId) {
        setPageStatus("updating-subreason");

        const values = {
            id_submotivo: Number(subreasonId),
            desc_submotivo: subreasonData.subreasonDesc
        };

        const response = await APITypeManagement.updateSubreason(token, values);

        if (selectedReasonId) {
            if (response.status === 200) {
                const updatedTypes = treeData.types.map((type) => {
                    if (type.id_solicitation === selectedTypeId) {
                        const updatedReasons = type.arrayOfReasons?.map((reason) => {
                            if (reason.id_motivo === selectedReasonId) {
                                const updatedSubreasons = reason.subreasons?.map(
                                    (subreason) => subreason.id_submotivo === subreasonId ?
                                        {
                                            reason_id: selectedReasonId,
                                            desc_submotivo: subreasonData.subreasonDesc,
                                            id_submotivo: subreasonId.toString(),
                                            id_sla: subreason.id_sla,
                                            desc_sla: subreason.desc_sla,
                                            editavel: subreason.editavel
                                        } : subreason
                                );

                                return {
                                    ...reason,
                                    subreasons: updatedSubreasons
                                }
                            }

                            return reason
                        });

                        return { ...type, arrayOfReasons: updatedReasons }
                    }

                    return type
                });

                onCloseCustom();
                setTreeData((prevData) => ({
                    ...prevData,
                    types: updatedTypes
                }))

                Toast(
                    "Submotivo atualizado!",
                    "Submotivo atualizado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível atualizar o Submotivo!",
                    "Verifique sua conexão  e tente novamente mais tarde.",
                    "error"
                )
            }
        }

        setPageStatus("awaiting");
    };

    async function updateSlaAssociatedWithSubreason(subreasonId) {
        setPageStatus("updating-subreason");

        const SLAValues = {
            id_tipo_solicitacao: Number(selectedTypeId),
            id_motivo: Number(selectedReasonId),
            id_submotivo: Number(subreasonId),
            desc_submotivo: subreasonData.subreasonDesc,
            id_sla: subreasonData.subreasonValues?.id_sla,
            editavel: subreasonData.subreasonValues?.editavel,
            id_organizacao: organizationId && organizationId
        };

        const ValuesWithoutSLA = {
            id_submotivo: Number(subreasonId),
            desc_submotivo: subreasonData.subreasonDesc
        };

        const response = await APITypeManagement.updateSlaAssociatedWithSubreasonV2(token, SLAValues);
        await APITypeManagement.updateSubreason(token, ValuesWithoutSLA);

        if (selectedReasonId) {
            if (response.status === 200) {
                const updatedTypes = treeData.types.map((type) => {
                    if (type.id_solicitation === selectedTypeId) {
                        const updatedReasons = type.arrayOfReasons?.map((reason) => {
                            if (reason.id_motivo === selectedReasonId) {
                                const updatedSubreasons = reason.subreasons?.map(
                                    (subreason) => subreason.id_submotivo === subreasonId ?
                                        {
                                            reason_id: selectedReasonId,
                                            desc_submotivo: subreasonData.subreasonDesc,
                                            id_submotivo: subreasonId.toString(),
                                            id_sla: subreasonData.subreasonValues?.id_sla,
                                            desc_sla: subreasonData.subreasonValues?.desc_sla,
                                            editavel: subreasonData.subreasonValues?.editavel
                                        } : subreason
                                );

                                return {
                                    ...reason,
                                    subreasons: updatedSubreasons
                                }
                            }

                            return reason
                        });

                        return { ...type, arrayOfReasons: updatedReasons }
                    }

                    return type
                });

                onCloseCustom();
                setTreeData((prevData) => ({
                    ...prevData,
                    types: updatedTypes
                }))

                Toast(
                    "Submotivo atualizado!",
                    "Submotivo atualizado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível atualizar o Submotivo!",
                    "Verifique sua conexão  e tente novamente mais tarde.",
                    "error"
                )
            }
        }

        setPageStatus("awaiting");
    }

    useEffect(() => {
        if (isOpen) {
            getListSla();
        }
    }, [isOpen]);

    return (
        <Modal.Main
            className="modal-update-subreason"
            title="Editar submotivo"
            width="600px"
            height={subreasonData.subreasonValues?.id_sla != null ? "390px" : "230px"}
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <>
                    <Button
                        className="modal-update-subreason__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "updating-subreason"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-update-subreason__btn-confirm"
                        disabled={pageStatus === "updating-subreason"}
                        onClick={() => {
                            if (subreasonData.subreasonValues?.id_sla !== null) {
                                updateSlaAssociatedWithSubreason(
                                    subreasonData.subreasonValues.id_submotivo
                                )
                            } else {
                                updateSubreasonDescription(
                                    subreasonData.subreasonValues.id_submotivo
                                )
                            }
                        }}
                    >
                        {pageStatus !== "updating-subreason" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </>
            }
        >
            <section
                className="modal-update-subreason__content"
                style={subreasonData.subreasonValues?.id_sla != null ? { height: "255px" } : { height: "95px" }}
            >
                <FieldContainer title="Descrição do submotivo">
                    <Input
                        placeholder="Digite o nome do seu submotivo"
                        value={subreasonData.subreasonDesc}
                        onChange={(event) =>
                            setSubreasonData({ ...subreasonData, subreasonDesc: event })
                        }
                    />
                </FieldContainer>

                {subreasonData.subreasonValues?.id_sla != null && (
                    <div className="container-other-associations">
                        <FieldContainer title="SLA">
                            <Select
                                placeholder="Selecione o SLA"
                                options={listSla}
                                value={subreasonData.subreasonValues.id_sla}
                                onSelect={(option) => {
                                    const updatedReasonValues = { ...subreasonData.subreasonValues };

                                    updatedReasonValues.id_sla = option.id;
                                    updatedReasonValues.desc_sla = option.label;

                                    setSubreasonData(prevValues => ({
                                        ...prevValues,
                                        subreasonValues: updatedReasonValues
                                    }))
                                }}
                            />
                        </FieldContainer>

                        <FieldContainer title="Editável">
                            <div className="editable-field-container">
                                <div className="container-input-radio">
                                    <label htmlFor="yes">
                                        <input
                                            type="radio"
                                            id="yes"
                                            name="options"
                                            value="yes"
                                            onChange={handleRadioChange}
                                            checked={
                                                subreasonData.subreasonValues.editavel === true
                                            }
                                            disabled={
                                                subreasonData.subreasonValues.id_sla === null
                                            }
                                        />
                                        Sim
                                    </label>
                                </div>

                                <div className="container-input-radio">
                                    <label htmlFor="no">
                                        <input
                                            type="radio"
                                            id="no"
                                            name="options"
                                            value="no"
                                            onChange={handleRadioChange}
                                            checked={
                                                subreasonData.subreasonValues.editavel === false
                                            }
                                            disabled={
                                                subreasonData.subreasonValues.id_sla === null
                                            }
                                        />
                                        Não
                                    </label>
                                </div>
                            </div>
                        </FieldContainer>
                    </div>
                )}
            </section>
        </Modal.Main>
    )
}
