//GLOBAL - components from npm
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as htmlToImage from "html-to-image";

// UTILS
import getLocalStorage from "./../../utils/getLocalStorage";
import { Toast } from "./../../utils/toast";

//STYLES
import "./dashboard.scss";

//COMPONENTS
import {
  CardDashboard,
  PageTitle,
  Balloon,
} from "./../../components/data-display";
import { Button, Typography } from "../../components/general";
import { PieChart, BarChart } from "../../components/data-graphics";
// import { PopoverMenu } from "../../components/navigation";
import { Loading, Modal, Drawer } from "../../components/feedback";
// import { DatePicker } from "../../components/data-entry";

//SERVICES - api, conectors...
import * as APIDashboard from "../../services/api/dashboard";
import * as APICase from "../../services/api/case";

//GLOBAL STATE - redux, env...
import { setStorageFilter } from "../../store/filters";
// import dashboard, { setDateFilter } from "../../store/dashboard";

//ASSETS - icons, images...
import { ReactComponent as IconArrowLeft } from "../../assets/icons/Arrow-left.svg";
import { ReactComponent as IconDark } from "../../assets/icons/Dark.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/Download.svg";
import { ReactComponent as IconFilter } from "../../assets/icons/Filter-search.svg";

export default function Dashboard() {
  //GENERAL
  const refPage = useRef();
  const refInitialDate = useRef();
  const refFinalDate = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const modeDark = getLocalStorage("darkMode", false);
  const jsonBalloons = [
    {
      title: "Concluídos",
      color: "#008908",
    },
    {
      title: "Em Andamento",
      color: "#455CC7",
    },
    {
      title: "Pendentes",
      color: "#F2BD00",
    },
    {
      title: "Escalonados",
      color: "#E27121",
    },
    {
      title: "Cancelados",
      color: "#757575",
    },
    {
      title: "Novos",
      color: "#1E88E5",
    },
  ];
  let totalCases = 0;

  const desired_order = {
    "Concluido": 0,
    "Novo": 1,
    "Pendente": 2,
    "Em andamento": 3,
    "Cancelado": 4,
    "Escalonado": 5
  };

  const colorMapping = {
    "red": '#f7404e',
    "blue": "#455cc7",
    "gray": "#75757599",
    "purple": "#9b3fc8",
    "green": "#45c777",
    "yellow": "#ffbb00",
    "orange": "#ff6b00",
    "light_orange": "#E27121",
    "primary-dark": "#5b78ff",
    "blue-light": "#bdeeff"
  };

  //STATES
  const [pageStatus, setPageStatus] = useState("requesting");
  // const [cardsOne, setCardsOne] = useState([]);
  const [cardsTwo, setCardsTwo] = useState([]);
  // const [cardsThree1, setCardsThree1] = useState([]);
  // const [cardsThree2, setCardsThree2] = useState([]);
  const [cardsThree3, setCardsThree3] = useState([]);
  const [cardsThree4, setCardsThree4] = useState([]);
  const [cardsFour, setCardsFour] = useState([]);
  const [cardsFive, setCardsFive] = useState([]);
  const [statusCard, setStatusCard] = useState([]);

  const principal = { type: "principal", titleHeader: "", titlePrincipal: "" };
  const [dataPizza, setDataPizza] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [pageType, setPageType] = useState(principal);
  const [finalDate, setFinalDate] = useState(new Date());
  const [initialDate, setInitialDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [slaMeta, setSlaMeta] = useState(0);
  // const [casesTotal, setCasesTotal] = useState(0);
  const [titleSave, setTitleSave] = useState("");
  const [captionPie, setCaptionPie] = useState(jsonBalloons);
  const [isDrawerFiltersOpen, setIsDrawerFiltersOpen] = useState(false);
  // const [cardPerRow, setCardPerRow] = useState(null);

  //REDUX - Selectors
  const darkMode = useSelector((state) => state.filters.darkMode);
  const token = useSelector((state) => state.auth.token);
  const filtersDashboard = useSelector((state) => state.filters.filtersDashboard);
  const userData = useSelector((state) => state.auth.userData);

  let totalFilters =
    filtersDashboard.clients.length +
    filtersDashboard.companys.length +
    filtersDashboard.requesteds.length +
    filtersDashboard.agents.length +
    filtersDashboard.prioritys.length +
    filtersDashboard.channels.length +
    filtersDashboard.organizations.length;

  //FUNCTIONS
  function getGradient(color) {
    return colorMapping[color] || "#000";
  }

  async function loadingDashboardDetails() {
    setPageStatus("requesting");

    if (statusList.length > 0) {
      await getDashboardDetails();
    }

    setPageStatus("awaiting");
  }

  async function getDashboardDetails() {
    await Promise.all([
      // getDashboardInfoCases(),
      getSLAInfos(),
      fetchCallsByStatus(),
      getFCR(),
      getCallForTypeSolicitation(),
      getLast30DaysActivity(),
      getCollaboratorsList(),
    ]);
  }

  // ################### NOVA FUNÇÃO PARA CARREGAR STATUS DO DASHBOARD ###################
  // async function getDashboardInfoCases() {
  //   const response = await APIDashboard.getCasesStatus(
  //     token,
  //     filtersDashboard.initialDate,
  //     filtersDashboard.finalDate,
  //     filtersDashboard,
  //     JSON.parse(userData.organizationsView)
  //   );

  //   if (response.status === 200) {
  //     const data = response.data;
  //     const qtds = data.map((datum) => Number(datum.qtde));
  //     const totalCases = qtds.reduce((total, numero) => total + numero, 0);

  //     setCasesTotal(totalCases);

  //     let dataTotal = [];
  //     let dataResponse = [];

  //     if (statusList.length > 0) {
  //       dataTotal = statusList.map((status) => {
  //         const statusData = data.filter(
  //           (datum) => datum.id_ticket_status === status.id_ticket_status
  //         );

  //         return {
  //           id: status.id_ticket_status,
  //           label: status.descricao_status,
  //           value: statusData.length > 0 ? statusData[0].qtde : 0,
  //           color: status.cor_alerta,
  //         };
  //       });

  //       // ######## MODIFICAR ORDENAÇÃO DOS STATUS
  //       let nextOrder = Object.keys(desired_order).length;

  //       statusList.forEach(status => {
  //         if (desired_order.hasOwnProperty(status.descricao_status)) {
  //           status.ordem_apresentacao = desired_order[status.descricao_status];
  //         } else {
  //           status.ordem_apresentacao = nextOrder++;
  //         }
  //       });

  //       statusList.sort((a, b) => a.ordem_apresentacao - b.ordem_apresentacao);

  //       // ######## ADAPTAR CARDS DINIMACAMENTE
  //       const filteredStatusList = statusList.filter(
  //         (status) => status.descricao_status !== 'Novo' && status.descricao_status !== 'Escalonado'
  //       );

  //       const totalStatus = filteredStatusList.length;
  //       const maxCardsPerRow = 4;
  //       const cardsPerRow = Math.min(totalStatus, maxCardsPerRow);

  //       setCardPerRow(cardsPerRow);

  //       dataResponse = [
  //         {
  //           line: 0,
  //           typeCard: 0,
  //           title: "Total de casos",
  //           subtitle: "",
  //           message: "",
  //           isPrimary: true,
  //           valueIndicator: totalCases ?? "0",
  //           percentage: "40",
  //           typeIndicator: "totalCases",
  //           isTrendUp: false,
  //           trendUpValue: 0,
  //         },
  //         ...statusList.map((status) => {
  //           const statusData = data.filter(
  //             (datum) => datum.id_ticket_status === status.id_ticket_status
  //           );

  //           const percentage =
  //             statusData.length > 0 && status.descricao_status !== "Total de casos"
  //               ? ((statusData[0].qtde / totalCases) * 100).toFixed(1) : "0";

  //           const lineValue =
  //             status.descricao_status === "Escalonado" ||
  //               status.descricao_status === "Novo" ? 2 : 0

  //           return {
  //             line: lineValue,
  //             typeCard: 0,
  //             title: status.descricao_status,
  //             subtitle: "",
  //             message: "",
  //             isPrimary: false,
  //             valueIndicator: statusData.length > 0 ? statusData[0].qtde : "0",
  //             percentage: percentage,
  //             typeIndicator: status.id_ticket_status,
  //             isTrendUp: true,
  //             trendUpValue: status.descricao_status === "Em andamento" ? 3.4 : 14.5,
  //           };
  //         })
  //       ];
  //     }

  //     setDataPizza(dataTotal);
  //     setCardsOne(
  //       dataResponse.filter((card) => card.typeCard === 0 && card.line === 0)
  //     );
  //     setCardsThree1(
  //       dataResponse.filter((card) => card.line === 2 && card.typeCard === 0)
  //     );
  //   } else {
  //     const dataResponse = [
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Total de casos",
  //         subtitle: "",
  //         message: "",
  //         typeIndicator: "totalCases",
  //         emptySpace: true,
  //       },
  //       ...statusList.map((status) => {
  //         const lineValue =
  //           status.descricao_status === "Escalonados" ||
  //             status.descricao_status === "Novos" ? 2 : 0

  //         return {
  //           line: lineValue,
  //           typeCard: 0,
  //           title: status.descricao_status,
  //           subtitle: "",
  //           message: "",
  //           emptySpace: true,
  //         }
  //       })
  //     ]

  //     setCardsOne(
  //       dataResponse.filter((card) => card.typeCard === 0 && card.line === 0)
  //     );
  //     setCardsThree1(
  //       dataResponse.filter((card) => card.line === 2 && card.typeCard === 0)
  //     );

  //     Toast(
  //       "Falha, não foi possível carregar as informações!",
  //       "Verifique sua conexão e tente novamente mais tarde.",
  //       "error"
  //     );
  //   }
  // }

  // ################### ANTIGA FUNÇÃO PARA CARREGAR STATUS DO DASHBOARD ###################
  // async function getDashboardInfoCases() {
  //   const response = await APIDashboard.getCasesStatus(
  //     token,
  //     filtersDashboard.initialDate,
  //     filtersDashboard.finalDate,
  //     filtersDashboard,
  //     JSON.parse(userData.organizationsView)
  //   );

  //   if (response.status === 200) {
  //     const data = response.data;
  //     const qtds = data.map((datum) => Number(datum.qtde));
  //     totalCases = qtds.reduce((total, numero) => total + numero, 0);
  //     setCasesTotal(totalCases);

  //     const concluidos = data.filter((datum) => datum.id_ticket_status === 3);
  //     const pendentes = data.filter((datum) => datum.id_ticket_status === 2);
  //     const emAndamento = data.filter((datum) => datum.id_ticket_status === 1);
  //     const escalonado = data.filter((datum) => datum.id_ticket_status === 5);
  //     const cancelado = data.filter((datum) => datum.id_ticket_status === 4);
  //     const novo = data.filter((datum) => datum.id_ticket_status === 6);

  //     const dataTotal = [
  //       {
  //         id: 3,
  //         label: "Concluídos",
  //         value: concluidos.length > 0 ? concluidos[0].qtde : 0,
  //         color: "#008908",
  //       },
  //       {
  //         id: 1,
  //         label: "Em Andamento",
  //         value: emAndamento.length > 0 ? emAndamento[0].qtde : 0,
  //         color: "#455CC7",
  //       },
  //       {
  //         id: 2,
  //         label: "Pendentes",
  //         value: pendentes.length > 0 ? pendentes[0].qtde : 0,
  //         color: "#F2BD00",
  //       },
  //       {
  //         id: 5,
  //         label: "Escalonados",
  //         value: escalonado.length > 0 ? escalonado[0].qtde : 0,
  //         color: "#E27121",
  //       },
  //       {
  //         id: 4,
  //         label: "Cancelados",
  //         value: cancelado.length > 0 ? cancelado[0].qtde : 0,
  //         color: "#757575",
  //       },
  //       {
  //         id: 6,
  //         label: "Novos",
  //         value: novo.length > 0 ? novo[0].qtde : 0,
  //         color: "#1E88E5",
  //       },
  //     ];

  //     const dataResponse = [
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Total de casos",
  //         subtitle: "",
  //         message: "",
  //         isPrimary: true,
  //         valueIndicator: totalCases ?? "0",
  //         percentage: "40",
  //         typeIndicator: "totalCases",
  //         isTrendUp: false,
  //         trendUpValue: 0,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Concluídos",
  //         subtitle: "",
  //         message: "",
  //         isPrimary: false,
  //         valueIndicator: concluidos[0]?.qtde ?? "0",
  //         percentage:
  //           concluidos.length > 0
  //             ? ((concluidos[0]?.qtde / totalCases) * 100).toFixed(1)
  //             : "0",
  //         typeIndicator: concluidos[0]?.id_ticket_status ?? 3,
  //         isTrendUp: true,
  //         trendUpValue: 14.5,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Novos",
  //         subtitle: "",
  //         message: "",
  //         isPrimary: false,
  //         valueIndicator: novo.length > 0 ? novo[0].qtde : "0",
  //         percentage:
  //           novo.length > 0
  //             ? ((novo[0].qtde / totalCases) * 100).toFixed(1)
  //             : "0",
  //         typeIndicator: novo[0]?.id_ticket_status ?? 6,
  //         isTrendUp: true,
  //         trendUpValue: 14.5,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Pendente",
  //         subtitle: "",
  //         message: "",
  //         isPrimary: false,
  //         valueIndicator: pendentes[0]?.qtde ?? "0",
  //         percentage:
  //           pendentes.length > 0
  //             ? ((pendentes[0].qtde / totalCases) * 100).toFixed(1)
  //             : "0",
  //         typeIndicator: pendentes[0]?.id_ticket_status ?? 2,
  //         isTrendUp: true,
  //         trendUpValue: 14.5,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Em andamento",
  //         subtitle: "",
  //         message: "",
  //         isPrimary: false,
  //         valueIndicator: emAndamento[0]?.qtde ?? "0",
  //         percentage:
  //           emAndamento.length > 0
  //             ? ((emAndamento[0]?.qtde / totalCases) * 100).toFixed(1)
  //             : "0",
  //         typeIndicator: emAndamento[0]?.id_ticket_status ?? 1,
  //         isTrendUp: true,
  //         trendUpValue: 3.5,
  //       },
  //       {
  //         line: 2,
  //         typeCard: 0,
  //         title: "Cancelados",
  //         subtitle: "",
  //         message: "",
  //         isPrimary: false,
  //         valueIndicator: cancelado[0]?.qtde ?? "0",
  //         percentage:
  //           cancelado.length > 0
  //             ? ((cancelado[0].qtde / totalCases) * 100).toFixed(1)
  //             : "0",
  //         typeIndicator: cancelado[0]?.id_ticket_status ?? 4,
  //         isTrendUp: true,
  //         trendUpValue: 14.5,
  //       },
  //       {
  //         line: 2,
  //         typeCard: 0,
  //         title: "Escalonados",
  //         subtitle: "",
  //         message: "",
  //         isPrimary: false,
  //         valueIndicator: escalonado.length > 0 ? escalonado[0].qtde : "0",
  //         percentage:
  //           escalonado.length > 0
  //             ? ((escalonado[0].qtde / totalCases) * 100).toFixed(1)
  //             : "0",
  //         typeIndicator: escalonado[0]?.id_ticket_status ?? 5,
  //         isTrendUp: true,
  //         trendUpValue: 14.5,
  //       },
  //     ];

  //     setDataPizza(dataTotal);
  //     setCardsOne(
  //       dataResponse.filter((card) => card.typeCard === 0 && card.line === 0)
  //     );
  //     setCardsThree1(
  //       dataResponse.filter((card) => card.line === 2 && card.typeCard === 0)
  //     );
  //   } else {
  //     const dataResponse = [
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Total de casos",
  //         subtitle: "",
  //         message: "",
  //         typeIndicator: "totalCases",
  //         emptySpace: true,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Concluídos",
  //         subtitle: "",
  //         message: "",
  //         emptySpace: true,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Em andamento",
  //         subtitle: "",
  //         message: "",
  //         emptySpace: true,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Pendente",
  //         subtitle: "",
  //         message: "",
  //         emptySpace: true,
  //       },
  //       {
  //         line: 2,
  //         typeCard: 0,
  //         title: "Escalonados",
  //         subtitle: "",
  //         message: "",
  //         emptySpace: true,
  //       },
  //       {
  //         line: 0,
  //         typeCard: 0,
  //         title: "Cancelados",
  //         subtitle: "",
  //         message: "",
  //         emptySpace: true,
  //       },
  //       {
  //         line: 2,
  //         typeCard: 0,
  //         title: "Novos",
  //         subtitle: "",
  //         message: "",
  //         emptySpace: true,
  //       },
  //     ];

  //     setCardsOne(
  //       dataResponse.filter((card) => card.typeCard === 0 && card.line === 0)
  //     );
  //     setCardsThree1(
  //       dataResponse.filter((card) => card.line === 2 && card.typeCard === 0)
  //     );

  //     Toast(
  //       "Falha, não foi carregar as informações!",
  //       "Verifique sua conexão e tente novamente mais tarde",
  //       "error"
  //     );
  //   }
  // }

  async function getSLAInfos() {
    const responseSlaMeta = await APIDashboard.getSlaMeta(token);
    const responseSla = await APIDashboard.getSlaStatus(
      token,
      filtersDashboard.initialDate,
      filtersDashboard.finalDate,
      "inside",
      filtersDashboard,
      JSON.parse(userData.organizationsView)
    );
    // const responseSlaExceed = await APIDashboard.getSlaStatus(
    //   token,
    //   filtersDashboard.initialDate,
    //   filtersDashboard.finalDate,
    //   "",
    //   filtersDashboard,
    //   JSON.parse(userData.organizationsView)
    // );

    if (responseSla.status === 200 && responseSlaMeta.status === 200) {
      const data = responseSla.data[0];
      const dataMeta = responseSlaMeta.data[0];
      setSlaMeta(dataMeta.meta_sla);
      const calcularSla = ((data.qtde_dentro_sla / totalCases) * 100).toFixed(
        1
      );

      const dataResponse = [
        {
          line: 2,
          typeCard: 3,
          title: "SLA",
          subtitle: `Meta: Atingir ${dataMeta?.meta_sla}% de SLA`,
          message: "",
          isPrimary: false,
          valueIndicator: "125",
          percentage:
            data.qtde_dentro_sla > 0 && totalCases > 0
              ? calcularSla > 100
                ? "100"
                : calcularSla
              : "0",
          typeIndicator: "slaGoalCases",
          isTrendUp: true,
          trendUpValue: 14.5,
          emptySpace: !data || !dataMeta ? true : false,
        },
      ];

      setCardsThree4(dataResponse.filter((card) => card.line === 2));
    } else {
      const dataResponse = [
        {
          line: 2,
          typeCard: 3,
          title: "SLA",
          subtitle: `Meta: Atingir % de SLA`,
          message: "",
          typeIndicator: "slaGoalCases",
          emptySpace: true,
        },
      ];

      setCardsThree4(dataResponse.filter((card) => card.line === 2));

      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }

    // if (responseSlaExceed.status === 200) {
    //   const data = responseSlaExceed.data[0];
    //   const calcularSlaAbaixo = (
    //     (data.qtde_abaixo_sla / totalCases) *
    //     100
    //   ).toFixed(1);

    //   const dataResponse = [
    //     {
    //       line: 2,
    //       typeCard: 0,
    //       title: "Ultrapassaram a SLA",
    //       subtitle: "",
    //       message: "",
    //       isPrimary: false,
    //       valueIndicator: data.qtde_abaixo_sla ?? 0,
    //       percentage:
    //         data.qtde_abaixo_sla > 0 && totalCases > 0
    //           ? calcularSlaAbaixo > 100
    //             ? "100"
    //             : calcularSlaAbaixo
    //           : "0",
    //       typeIndicator: "slaCases",
    //       isTrendUp: true,
    //       trendUpValue: 14.5,
    //     },
    //   ];

    //   setCardsThree2(dataResponse.filter((card) => card.line === 2));
    // } else {
    //   const dataResponse = [
    //     {
    //       line: 2,
    //       typeCard: 0,
    //       title: "Ultrapassaram a SLA",
    //       subtitle: "",
    //       message: "",
    //       typeIndicator: "slaCases",
    //       emptySpace: true,
    //     },
    //   ];

    //   setCardsThree2(dataResponse.filter((card) => card.line === 2));

    //   Toast(
    //     "Falha, não foi carregar as informações!",
    //     "Verifique sua conexão e tente novamente mais tarde",
    //     "error"
    //   );
    // }
  }

  async function getFCR() {
    const response = await APIDashboard.getFirstCallResolution(
      token,
      filtersDashboard.initialDate,
      filtersDashboard.finalDate,
      filtersDashboard,
      JSON.parse(userData.organizationsView)
    );

    if (response.status === 200) {
      const data = response.data[0];

      const dataResponse = [
        {
          line: 2,
          subLine: 2,
          typeCard: 2,
          title: "First Call Resolution",
          subtitle: "Total de problemas resolvidos no primeiro momento",
          message: "",
          isPrimary: false,
          valueIndicator: data.qtde_first_call_resolution,
          percentage: data.qtde_first_call_resolution
            ? ((data.qtde_first_call_resolution / totalCases) * 100).toFixed(1)
            : "0",
          typeIndicator: "firstCallResolutionCases",
          isTrendUp: true,
          trendUpValue: 14.5,
        },
      ];

      setCardsThree3(dataResponse.filter((card) => card.line === 2));
    } else {
      const dataResponse = [
        {
          line: 2,
          subLine: 2,
          typeCard: 2,
          title: "First Call Resolution",
          subtitle: "Total de problemas resolvidos no primeiro momento",
          message: "",
          typeIndicator: "firstCallResolutionCases",
          emptySpace: true,
        },
      ];

      setCardsThree3(dataResponse.filter((card) => card.line === 2));

      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getCallForTypeSolicitation() {
    const response = await APIDashboard.getTypesSolicitations(
      token,
      filtersDashboard.initialDate,
      filtersDashboard.finalDate,
      filtersDashboard,
      JSON.parse(userData.organizationsView)
    );

    if (response.status === 200) {
      const data = response.data;

      let descricao_solicitacao = [];
      let dataGraph = [];

      data.forEach((datum) => {
        const colorRandom = handleColors(datum.id_solicitacao);
        descricao_solicitacao.push({
          id: datum.id_solicitacao,
          title: /[a-zà-ü]/.test(datum.descricao_solicitacao)
            ? datum.descricao_solicitacao
            : datum.descricao_solicitacao.toLocaleLowerCase(),
          color: colorRandom,
        });

        dataGraph.push({
          id: datum.id_solicitacao,
          label: datum.descricao_solicitacao,
          value: Number(datum.qtde),
          color: colorRandom,
        });
      });
      // descricao_solicitacao = data.map((datum) => {
      //   return {
      //     id: datum.id_solicitacao,
      //     title: /[a-zà-ü]/.test(datum.descricao_solicitacao)
      //       ? datum.descricao_solicitacao
      //       : datum.descricao_solicitacao.toLocaleLowerCase(),
      //     color: handleColors(datum.id_solicitacao),
      //   };
      // });

      // dataGraph = data.map((datum) => ({
      //   id: datum.id_solicitacao,
      //   label: datum.descricao_solicitacao,
      //   value: Number(datum.qtde),
      //   color: handleColors(datum.id_solicitacao),
      // }));

      const dataResponse = [
        {
          line: 1,
          typeCard: 1,
          title: "Chamados por tipo de solicitação",
          subtitle: "",
          message: "",
          isPrimary: false,
          valueIndicator: "500",
          percentage: "40",
          typeIndicator: "typeSolicitationCases",
          isTrendUp: true,
          trendUpValue: 14.5,
          dataSubtitles: descricao_solicitacao,
          data: dataGraph,
          emptySpace: data.length === 0 ? true : false,
        },
      ];
      setCardsTwo(dataResponse.filter((card) => card.line === 1));
    } else {
      const dataResponse = [
        {
          line: 1,
          typeCard: 1,
          title: "Chamados por tipo de solicitação",
          subtitle: "",
          message: "",
          typeIndicator: "typeSolicitationCases",
          emptySpace: true,
        },
      ];
      setCardsTwo(dataResponse.filter((card) => card.line === 1));

      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  function handleFetchError() {
    const dataResponse = [
      {
        line: 0,
        typeCard: 6,
        title: "Total de casos",
        subtitle: "",
        message: "",
        isPrimary: true,
        valueIndicator: "0",
        percentage: "0",
        typeIndicator: "totalCases",
        isTrendUp: false,
        trendUpValue: 0,
      },
    ];

    setStatusCard(dataResponse);
    Toast(
      "Falha, não foi possível carregar as informações!",
      "Verifique sua conexão e tente novamente mais tarde",
      "error"
    );
  }

  async function fetchCallsByStatus() {
    try {
      const [responseCalls, responseSlaExceed] = await Promise.all([
        APIDashboard.getCasesStatus(
          token,
          filtersDashboard.initialDate,
          filtersDashboard.finalDate,
          filtersDashboard,
          JSON.parse(userData.organizationsView)
        ),
        APIDashboard.getSlaStatus(
          token,
          filtersDashboard.initialDate,
          filtersDashboard.finalDate,
          "",
          filtersDashboard,
          JSON.parse(userData.organizationsView)
        ),
      ]);

      if (responseCalls.status === 200 && responseSlaExceed.status === 200) {
        const responseData = responseCalls.data;
        const qtds = responseData.map((datum) => Number(datum.qtde));
        const totalCases = qtds.reduce((total, numero) => total + numero, 0);

        let data = [];

        if (statusList.length > 0) {
          data = statusList.map((status) => {
            const statusData = responseData.filter((datum) => datum.id_ticket_status === status.id_ticket_status);
            const valueIndicator = statusData.length > 0 ? statusData[0].qtde : 0;
            const percentage = valueIndicator && status.descricao_status !== "Total de casos"
              ? ((valueIndicator / totalCases) * 100).toFixed(1)
              : "0";

            return {
              id: `${percentage}%`,
              label: status.descricao_status,
              casesQuantity : valueIndicator,
              percentage: percentage,
              color: getGradient(status.cor_alerta),
            };
          });

          let nextOrder = Object.keys(desired_order).length;

          statusList.forEach((status) => {
            if (desired_order.hasOwnProperty(status.descricao_status)) {
              status.ordem_apresentacao = desired_order[status.descricao_status];
            } else {
              status.ordem_apresentacao = nextOrder++;
            }
          });

          statusList.sort((a, b) => a.ordem_apresentacao - b.ordem_apresentacao);

          const dataSlaExceed = responseSlaExceed.data[0];
          const calcularSlaAbaixo = ((dataSlaExceed.qtde_abaixo_sla / totalCases) * 100).toFixed(1);

          const slaData = {
            id: `${calcularSlaAbaixo}%`,
            label: "Ultrapassaram a SLA",
            casesQuantity: dataSlaExceed.qtde_abaixo_sla ?? 0,
            percentage: dataSlaExceed.qtde_abaixo_sla > 0 && totalCases > 0
              ? calcularSlaAbaixo > 100
                ? "100"
                : calcularSlaAbaixo
              : "0",
            color: "#E27121",
          };

          const updateData = [
            {
              line: 1,
              typeCard: 6,
              title: "Chamada por status",
              subtitle: ``,
              message: "",
              isPrimary: false,
              valueIndicator: "125",
              percentage: "40",
              typeIndicator: "callByStatus",
              isTrendUp: true,
              trendUpValue: 14.5,
              data: [...data, slaData],
              emptySpace: responseData.length === 0 ? true : false,
            },
          ];

          setStatusCard(updateData.filter((card) => card.line === 1));
        }
      } else {
        handleFetchError();
      }
    } catch (error) {
      handleFetchError();
    }
  }

  async function getLast30DaysActivity() {
    const response = await APIDashboard.getLastActivitys(
      token,
      new Date(new Date().setDate(new Date().getDate() - 30)),
      new Date(),
      filtersDashboard,
      JSON.parse(userData.organizationsView)
    );

    if (response.status === 200) {
      const dataTotal = response.data;
      const data = dataTotal.map((datum) => {
        const formatDate = new Date(datum.data_abertura).toLocaleDateString(
          "pt-br",
          { day: "numeric", month: "numeric" }
        );

        return {
          id: formatDate,
          concluidos: datum.qtde_concluido ?? 0,
          concluidosColor: "#008908",
          cancelado: datum.qtde_cancelado ?? 0,
          canceladoColor: "#757575",
          pendente: datum.qtde_pendente ?? 0,
          pendenteColor: "#F2BD00",
          "em andamento": datum.qtde_andamento ?? 0,
          "em andamentoColor": "#455CC7",
          escalonado: datum.qtde_escalado ?? 0,
          escalonadoColor: "#E27121",
        };
      });

      const dataResponse = [
        {
          line: 3,
          typeCard: 4,
          title: "Atividade de casos nos últimos 30 dias",
          subtitle: ``,
          message: "",
          isPrimary: false,
          valueIndicator: "125",
          percentage: "40",
          typeIndicator: "slaGoalCases",
          isTrendUp: true,
          trendUpValue: 14.5,
          data,
          emptySpace: dataTotal.length === 0 ? true : false,
        },
      ];

      setCardsFour(dataResponse.filter((card) => card.line === 3));
    } else {
      const dataResponse = [
        {
          line: 3,
          typeCard: 4,
          title: "Atividade de casos nos últimos 30 dias",
          subtitle: ``,
          message: "",
          typeIndicator: "slaGoalCases",
          emptySpace: true,
        },
      ];

      setCardsFour(dataResponse.filter((card) => card.line === 3));

      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getCollaboratorsList() {
    const response = await APIDashboard.getCollaborators(
      token,
      filtersDashboard.initialDate,
      filtersDashboard.finalDate,
      filtersDashboard,
      JSON.parse(userData.organizationsView)
    );

    if (response.status === 200) {
      const data = response.data;

      const dataResponse = [
        {
          line: 4,
          typeCard: 5,
          title: "Desempenho dos colaboradores",
          subtitle: ``,
          message: "",
          isPrimary: false,
          valueIndicator: "125",
          percentage: "40%",
          typeIndicator: "slaGoalCases",
          isTrendUp: true,
          collaboratorsData: data,
          emptySpace: data.length === 0 ? true : false,
        },
      ];

      setCardsFive(dataResponse.filter((card) => card.line === 4));
    } else {
      const dataResponse = [
        {
          line: 4,
          typeCard: 5,
          title: "Desempenho dos colaboradores",
          subtitle: ``,
          message: "",
          typeIndicator: "slaGoalCases",
          emptySpace: true,
        },
      ];

      setCardsFive(dataResponse.filter((card) => card.line === 4));

      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getSubReasonsList(idSolicitation) {
    const response = await APIDashboard.getSpecificSubTypeSolicitation(
      token,
      filtersDashboard.initialDate,
      filtersDashboard.finalDate,
      idSolicitation,
      filtersDashboard,
      JSON.parse(userData.organizationsView)
    );

    if (response.status === 200) {
      const data = response.data;
      let dataResponse = [];
      let dataBaloon = [];

      data.forEach((datum) => {
        let color = handleColors(datum.id_submotivo);
        dataResponse.push({
          id: datum.id_submotivo,
          label: datum.desc_submotivo,
          value: datum.qtde > 0 ? datum.qtde : 0,
          color: color,
        });

        dataBaloon.push({
          title: datum.desc_submotivo,
          color: color,
        });
      });

      setDataPizza(dataResponse);
      setCaptionPie(dataBaloon);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function handlePageType(card, typeSolicitation, idSolicitation) {
    setPageStatus("requesting");
    let response;

    switch (card.typeIndicator) {
      case "totalCases":
        // await getDashboardInfoCases();
        await fetchCallsByStatus();

        setPageType({
          type: "graphicPie",
          titleHeader: card.title,
          titlePrincipal: "Tipos de casos",
          card: card,
        });

        break;

      case 1:
      case 2:
      case 3:
      case 4:
      case 6:
        response = await APIDashboard.getSpecificStatus(
          token,
          filtersDashboard.initialDate,
          filtersDashboard.finalDate,
          card.typeIndicator,
          filtersDashboard,
          JSON.parse(userData.organizationsView)
        );

        setPageType({
          type: "graphicLine",
          titleHeader: card.title,
          titlePrincipal: "Tipo de Solicitações",
          card: card,
        });

        break;

      case "slaCases":
        response = await APIDashboard.getSpecificSLA(
          token,
          filtersDashboard.initialDate,
          filtersDashboard.finalDate,
          filtersDashboard,
          JSON.parse(userData.organizationsView)
        );

        setPageType({
          type: "graphicLine",
          titleHeader: card.title,
          titlePrincipal: "Tipo de Solicitações",
          card: card,
        });

        break;

      case 5:
        response = await APIDashboard.getSpecificTypeStaggered(
          token,
          filtersDashboard.initialDate,
          filtersDashboard.finalDate,
          filtersDashboard,
          JSON.parse(userData.organizationsView)
        );

        setPageType({
          type: "graphicLine",
          titleHeader: card.title,
          titlePrincipal: "Setores",
          card: card,
        });

        break;

      case "firstCallResolutionCases":
        response = await APIDashboard.getSpecificTypeFCR(
          token,
          filtersDashboard.initialDate,
          filtersDashboard.finalDate,
          filtersDashboard,
          JSON.parse(userData.organizationsView)
        );

        setPageType({
          type: "graphicLine",
          titleHeader: card.title,
          titlePrincipal: "Tipo de solicitações resolvidas",
          card: card,
        });

        break;

      case "typeSolicitationCases":
        response = await APIDashboard.getSpecificTypeSolicitation(
          token,
          filtersDashboard.initialDate,
          filtersDashboard.finalDate,
          idSolicitation,
          filtersDashboard,
          JSON.parse(userData.organizationsView)
        );
        setPageType({
          type: "graphicLine",
          titleHeader: `${typeSolicitation} - Motivos`,
          titlePrincipal: `${typeSolicitation} - Motivos`,
          card: card,
          typeSolicitation: typeSolicitation,
          idSolicitation: idSolicitation,
          typePage: "Motivos",
        });
        setTitleSave(typeSolicitation);

        break;

      case "subTypeSolicitationCases":
        setPageType({
          type: "graphicPie",
          titleHeader: `${typeSolicitation} - Sub Motivos`,
          titlePrincipal: `${typeSolicitation} - Sub Motivos`,
          card: card,
          typeSolicitation: `${typeSolicitation}`,
          idSolicitation: idSolicitation,
          typePage: "Submotivos",
        });

        await getSubReasonsList(idSolicitation);

        break;

      default:
        break;
    }

    if (
      card.typeIndicator !== "totalCases" &&
      card.typeIndicator !== "subTypeSolicitationCases"
    ) {
      if (response.status === 200) {
        const data = response.data;
        // const keys = data.map((datum) =>
        //   card.typeIndicator === "typeSolicitationCases"
        //     ? datum.desc_motivo
        //     : card.typeIndicator === 5
        //     ? datum.desc_escalonamento
        //     : datum.descricao_solicitacao
        // );
        // setKeyState(keys);

        const dataTotal = data.map((datum) => {
          if (card.typeIndicator === "typeSolicitationCases") {
            return {
              id: datum.id_motivo,
              // [datum.desc_motivo]: datum.qtde,
              // [datum.desc_motivo + "Color"]: "#455CC7",
              value: datum.qtde,
              label: datum.desc_motivo,
              valueColor: "#455CC7",
              onClick:
                datum.qtde_submotivo > 0
                  ? {
                    ...card,
                    typeIndicator: "subTypeSolicitationCases",
                    submotiveId: idSolicitation,
                  }
                  : false,
            };
          } else if (card.typeIndicator === 5) {
            return {
              id: datum.id_escalonamento,
              // [datum.desc_escalonamento]: datum.qtde,
              // [datum.desc_escalonamento + "Color"]: "#455CC7",
              value: datum.qtde,
              label: datum.desc_escalonamento,
              valueColor: "#455CC7",
            };
          } else {
            return {
              id: datum.id_solicitacao,
              // [datum.descricao_solicitacao]: datum.qtde,
              // [datum.descricao_solicitacao + "Color"]: "#455CC7",
              value: datum.qtde,
              label: datum.descricao_solicitacao,
              valueColor: "#455CC7",
            };
          }
        });

        setDataBar(dataTotal);
      } else {
        Toast(
          "Falha, não foi carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );
      }
    }

    setPageStatus("awaiting");
  }

  function handleColors(id) {
    switch (id) {
      case 1:
        return "#F4511E";
      case 2:
        return "#D81B60";
      case 3:
        return "#9CFF20";
      case 4:
        return "#5E35B1";
      case 6:
        return "#F22E2E";
      case 8:
        return "#4DFF75";
      case 9:
        return "#A356FD";
      case 10:
        return "#0A07E3";
      case 11:
        return "#1E88E5";
      case 13:
        return "#00ACC1";
      case 12:
        return "#FF69B4";
      case 14:
        return "#C0CA33";
      case 15:
        return "#FFB300";
      case 16:
        return "#43A047";
      case 17:
        return "#583632";
      case 18:
        return "#D9D9D9";
      case 19:
        return "#C90000";
      case 20:
        return "#757575";
      case 21:
        return "#DC5C5C";
      case 22:
        return "#745B00";
      case 23:
        return "#333333";
      case 24:
        return "#5C4672";
      case 25:
        return "#600000";
      case 282:
        return "#95425C";
      case 288:
        return "#24ADBA";

      default:
        return getRandomColor();
    }
  }

  const onDownload = ({ filename = "file.png" }) => {
    const element = document.querySelector("#alldata");
    const sectionTable = document.querySelector(".section-dashboard-list");
    sectionTable && (sectionTable.style.overflow = "overlay");

    htmlToImage
      .toPng(element, {
        height:
          pageType.type === "principal"
            ? window.outerHeight + window.innerHeight - 250
            : window.innerHeight,
      })
      .then(function (dataUrl) {
        const a = document.createElement("a");
        a.download = filename;
        a.href = dataUrl;
        a.click();
        sectionTable.style.overflow = "auto";
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // const firstLine = useMemo(() => cardsOne, [cardsOne]);
  const secondLine = useMemo(() => cardsTwo, [cardsTwo]);
  // const thirdLine1 = useMemo(() => cardsThree1, [cardsThree1]);
  // const thirdLine2 = useMemo(() => cardsThree2, [cardsThree2]);
  const thirdLine3 = useMemo(() => cardsThree3, [cardsThree3]);
  const thirdLine4 = useMemo(() => cardsThree4, [cardsThree4]);
  const fourthLine = useMemo(() => cardsFour, [cardsFour]); // GRÁFICO EM BARRA
  const fifthLine = useMemo(() => cardsFive, [cardsFive]);
  const status_card = useMemo(() => statusCard, [statusCard]);

  async function getStatusList() {
    const response = await APICase.getListStatus(token);

    try {
      if (response.status === 200) {
        setStatusList(response.data)
      } else {
        Toast(
          "Falha, não foi possível carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
      }
    } catch (error) {
      console.error("Erro ao processar a resposta da API:", error);

      Toast(
        "Erro ao processar a resposta da API!",
        "Por favor, tente novamente mais tarde.",
        "error"
      );
    }
  };

  //USE EFFECTS
  useEffect(() => {
    async function fetchData() {
      await getStatusList();
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (modeDark) {
      dispatch(setStorageFilter({ to: "darkMode", value: modeDark }));
    }

    loadingDashboardDetails();
  }, [statusList]);

  useEffect(() => {
    window.localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    if (refInitialDate.current || refFinalDate.current) {
      if (pageType.type === "principal") {
        loadingDashboardDetails();
      } else {
        handlePageType(
          pageType.card,
          pageType.typeSolicitation,
          pageType.idSolicitation
        );
      }
    }
  }, [
    filtersDashboard.finalDate,
    filtersDashboard.initialDate,
    filtersDashboard,
  ]);

  useEffect(() => {
    const comInterval = setInterval(async () => {
      if (pageType.type === "principal") {
        await getDashboardDetails();
      } else {
        await handlePageType(
          pageType.card,
          pageType.typeSolicitation,
          pageType.idSolicitation
        );
      }
    }, 300000);
    return () => clearInterval(comInterval);
  }, [
    pageType.type,
    filtersDashboard.initialDate,
    filtersDashboard.finalDate,
    filtersDashboard,
    statusList,
  ]);

  let title =
    history.location.pathname.indexOf("externo") !== 1 ? "Dashboard - CRM" : "";

  return (
    <>
      <main
        id="alldata"
        className={`page-dashboard-container ${darkMode ? "dark-mode" : ""}`}
        ref={refPage}
      >
        <PageTitle
          className={`${darkMode ? "dark-mode" : ""} 
            ${pageType.type !== "principal" ? "title-modify" : ""} header-dashboard-container`}
          title={`${title} ${pageType.typePage === "Submotivos" ? ` / ${titleSave} - Motivos` : ""}`}
          subtitle={`${pageType.type === "graphicPie" ? ` / ${pageType.titleHeader}` : ""} 
            ${pageType.type === "graphicLine" ? ` / ${pageType.titleHeader}` : ""}`}
          leftContent={
            pageType.type === "principal" && (
              <>
                {/* <div className="header-dashboard-container__date-range-container">
                  <DatePicker.DateRangeInput
                    className={darkMode ? "dark-mode" : ""}
                    selectRangeType="start"
                    value={initialDate}
                    setDate={(event) => {
                      const newDate = event;
                      setInitialDate(newDate);
                      refInitialDate.current = true;
                    }}
                    ref={refInitialDate}
                    endDate={finalDate}
                    isClearable={false}
                  />

                  <Typography.H5 color={darkMode ? "white" : "blue-selected-4"}>
                    .
                  </Typography.H5>

                  <DatePicker.DateRangeInput
                    className={darkMode ? "dark-mode" : ""}
                    value={finalDate}
                    setDate={(event) => {
                      const newDate = event;
                      setFinalDate(newDate);
                      refFinalDate.current = true;
                    }}
                    selectRangeType="end"
                    ref={refFinalDate}
                    startDate={initialDate}
                    isClearable={false}
                  />
                </div> */}
                <div className="header-dashboard-container__button-actions">
                  {/* <div className="header-dashboard-container__button-actions__share">
                  <ShareIcon className={darkMode ? "dark-mode" : ""} />
                </div> */}
                  {/* {history.location.pathname.indexOf("externo") !== 1 && */}
                  <div
                    className="header-dashboard-container__button-actions__download"
                    onClick={onDownload}
                  >
                    <DownloadIcon className={darkMode ? "dark-mode" : ""} />
                  </div>
                  {/* } */}
                  <Button
                    className={`btn-more-filters ${darkMode ? "dark-mode" : ""}`}
                    type="fourthiary"
                    icon={<IconFilter />}
                    border
                    onClick={() => setIsDrawerFiltersOpen(true)}
                  >
                    <div className="btn-more-filters__content">
                      <Typography.H5
                        weight="bold"
                        color={darkMode ? "white" : "blue-selected-4"}
                      >
                        Filtros
                      </Typography.H5>

                      {totalFilters > 0 && (
                        <div className="total-filters">
                          <span>{totalFilters}</span>
                        </div>
                      )}
                    </div>
                  </Button>
                </div>
              </>
            )
          }
        />

        {/* page-dashboard-container__cardsOne */}

        {pageStatus === "requesting" ? (
          <>
            <Loading.Paperplane width={"30%"} height={"30%"} />
          </>
        ) : (
          <>
            {pageType.type === "principal" && (
              <>
                {/* <div className="page-dashboard-container__cardsOne">
                  {firstLine.map((card) => {
                    console.log("firstLine", card)
                    return (
                      <CardDashboard
                        card={card}
                        setPage={() => handlePageType(card)}
                        cardPerRow={cardPerRow}
                      />
                    );
                  })}
                </div> */}
                <div className="page-dashboard-container__cardsBox">
                  <div className="page-dashboard-container__cardsBox__wrapper">
                    <div className="page-dashboard-container__statusCard">
                      {status_card.map((card) => {
                        return (
                          <CardDashboard key={card.line} card={card} />
                        )
                      })}
                    </div>

                    <div className="page-dashboard-container__cardsTwo">
                      {secondLine.map((card) => {
                        return (
                          <CardDashboard card={card} setPage={handlePageType} />
                        );
                      })}
                    </div>
                  </div>

                  <div className="page-dashboard-container__cardsThree">
                    {/* ######## CONTAINER COM OS STATUS NOVO, ESCALONADO E ULTRAPASSARAM A SLA ######## */}
                    {/* <div className="page-dashboard-container__cardsThree-container">
                      {thirdLine1.map((card) => {
                        return (
                          <CardDashboard
                            key={card.line}
                            card={card}
                            setPage={() => handlePageType(card)}
                          />
                        );
                      })}
                      {thirdLine2.map((card) => {
                        return (
                          <CardDashboard
                            key={card.line}
                            card={card}
                            setPage={() => handlePageType(card)}
                          />
                        );
                      })}
                    </div> */}

                    <div className="page-dashboard-container__cardsThree-container column-2">
                      {thirdLine3.map((card) => {
                        return (
                          <CardDashboard
                            key={card.line}
                            card={card}
                            setPage={() => handlePageType(card)}
                          />
                        );
                      })}
                      {thirdLine4.map((card) => {
                        return (
                          <CardDashboard
                            key={card.line}
                            card={card}
                            setPage={() => handlePageType(card)}
                            openModal={() => setOpenModal(true)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="page-dashboard-container__cardsFour">
                  {fourthLine.map((card) => {
                    return <CardDashboard key={card.line} card={card} />;
                  })}
                </div>
                <div className="page-dashboard-container__cardsFive">
                  {fifthLine.map((card, index) => {
                    return <CardDashboard key={card.line} card={card} />;
                  })}
                </div>
              </>
            )}

            {pageType.type !== "principal" && (
              <div className="page-dashboard-details">
                <div className="page-dashboard-details__title">
                  <IconArrowLeft
                    className={`page-dashboard-details__title-icon ${darkMode ? "dark-mode" : ""
                      }`}
                    onClick={
                      pageType.typePage === "Submotivos"
                        ? () => {
                          handlePageType(
                            {
                              ...pageType.card,
                              typeIndicator: "typeSolicitationCases",
                            },
                            titleSave,
                            pageType.card.submotiveId
                          );
                          setCaptionPie(jsonBalloons);
                        }
                        : () => {
                          setPageType(() => principal);
                          setTitleSave("");
                          loadingDashboardDetails();
                        }
                    }
                  />
                  <Typography.H1
                    weight={700}
                    color={darkMode ? "white" : "blue-selected-4"}
                  >
                    <span className="page-dashboard-details__title-span">
                      {pageType.titlePrincipal}
                    </span>{" "}
                  </Typography.H1>

                  {/* <div className="header-dashboard-container__date-range-container">
                    <DatePicker.DateRangeInput
                      className={darkMode ? "dark-mode" : ""}
                      selectRangeType="start"
                      value={initialDate}
                      setDate={(event) => {
                        const newDate = event;
                        setInitialDate(newDate);
                        refInitialDate.current = true;
                      }}
                      ref={refInitialDate}
                      endDate={finalDate}
                      isClearable={false}
                    />

                    <Typography.H5
                      color={darkMode ? "white" : "blue-selected-4"}
                    >
                      .
                    </Typography.H5>

                    <DatePicker.DateRangeInput
                      className={darkMode ? "dark-mode" : ""}
                      value={finalDate}
                      setDate={(event) => {
                        const newDate = event;
                        setFinalDate(newDate);
                        refFinalDate.current = true;
                      }}
                      selectRangeType="end"
                      ref={refFinalDate}
                      startDate={initialDate}
                      isClearable={false}
                    />
                  </div> */}

                  <div className="header-dashboard-container__button-actions">
                    {/* <div className="header-dashboard-container__button-actions__share">
                      <ShareIcon className={darkMode ? "dark-mode" : ""} />
                    </div> */}
                    <div
                      className="header-dashboard-container__button-actions__download"
                      onClick={onDownload}
                    >
                      <DownloadIcon className={darkMode ? "dark-mode" : ""} />
                    </div>
                    <Button
                      className={`btn-more-filters ${darkMode ? "dark-mode" : ""}`}
                      type="fourthiary"
                      icon={<IconFilter />}
                      border
                      onClick={() => setIsDrawerFiltersOpen(true)}
                    >
                      <div className="btn-more-filters__content">
                        <Typography.H5
                          weight="bold"
                          color={darkMode ? "white" : "blue-selected-4"}
                        >
                          Filtros
                        </Typography.H5>

                        {totalFilters > 0 && (
                          <div className="total-filters">
                            <span>{totalFilters}</span>
                          </div>
                        )}
                      </div>
                    </Button>
                  </div>
                </div>
                <div
                  className={`page-dashboard-details__graphic ${pageType.type === "graphicPie" ? "divisor-graphic" : ""}`}
                >
                  {pageType.type === "graphicLine" && (
                    <div style={{ height: "100%" }}>
                      <BarChart
                        data={dataBar}
                        darkMode={darkMode}
                        layout="horizontal"
                        onClick={handlePageType}
                      // showLabel
                      />
                    </div>
                  )}
                  {pageType.type === "graphicPie" && (
                    <>
                      <div className="subtitles_graphic">
                        {captionPie.map((balloon) => {
                          return (
                            <div
                              key={balloon.title}
                              className="page-dashboard-details__graphic-box"
                            >
                              <Balloon color={balloon.color} />
                              <Typography.H3
                                weight={"bold"}
                                color={darkMode ? "white" : "gray-180"}
                              >
                                {balloon.title}
                              </Typography.H3>
                            </div>
                          );
                        })}
                      </div>
                      <div className="graphic-container">
                        <PieChart
                          data={dataPizza}
                          internalRadius={
                            pageType.typePage === "Submotivos" ? 0.6 : 0
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {history.location.pathname.indexOf("externo") !== 1 && (
          <div className="page-dashboard-container__mode-dark">
            <Button className={`${darkMode ? "darkMode" : ""}`}>
              <IconDark
                onClick={() =>
                  dispatch(
                    setStorageFilter({ to: "darkMode", value: !darkMode })
                  )
                }
              />
            </Button>
          </div>
        )}
      </main>

      {thirdLine4.length > 0 && (
        <Modal.ChangeSLAMeta
          isOpen={openModal}
          onClose={(event) => {
            setOpenModal(false);
          }}
          valueBase={slaMeta}
          onCreate={async () => {
            // await getDashboardInfoCases();
            await fetchCallsByStatus();
            await getSLAInfos();
          }}
          darkMode={darkMode}
        />
      )}

      <Drawer.FiltersDashboard
        isOpen={isDrawerFiltersOpen}
        onClose={() => setIsDrawerFiltersOpen(!isDrawerFiltersOpen)}
        screen={"Casos"}
        valueDateInitial={initialDate}
        valueDateFinal={finalDate}
        refInitialDate={refInitialDate}
        refFinalDate={refFinalDate}
        setDateInitial={setInitialDate}
        setDateFinal={setFinalDate}
      />
    </>
  );
}
