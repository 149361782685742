//GLOBAL - components from npm
import { createSlice } from "@reduxjs/toolkit";

const auditSlice = createSlice({
  name: 'audit',
  initialState: {
    route: null,
  },
  reducers: {
    saveRoute(state, action) {
      state.route = action.payload.route;
    },
  }
})

export const { saveRoute } = auditSlice.actions;
export default auditSlice.reducer;