//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import { Button, Typography } from "../../../components/general";
import { Input } from "../../../components/data-entry";
import { Loading, Modal } from "../../../components/feedback";
import { Table } from "../../../components/data-display";
import { Toast } from "../../../utils/toast";
import CreateUser from "./CreateUser/CreateUser";
import UpdateUser from "./UpdateUser/UpdateUser";

//SERVICES - api, conectors...
import * as APIUser from "../../../services/api/User";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search-normal.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-3.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";
import { ReactComponent as UserCircleAddIcon } from "../../../assets/icons/user-cirlce-add.svg";

//STYLES
import "./user.scss";

export default function User({ returnToRecord }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL

    //STATES
    const [searchData, setSearchData] = useState("");
    const [emptyState, setEmptyState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(false);
    const [registerUser, setRegisterUser] = useState(false);
    const [updatedUser, setUpdatedUser] = useState(false);
    const [updateUser, setUpdateUser] = useState({
        state: false,
        userId: null
    });
    const [isOpenModalDeleteUser, setIsOpenModalDeleteUser] = useState({
        state: false,
        deletedUserId: null,
    });
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleSearchUsers(event) {
        const inputValue = event;

        setSearchData(inputValue);

        if (event === "") {
            setFilteredData(false);
            setFilteredUsers([]);
        }
    };

    function handleTableDataLookup() {
        const searchTerm = searchData.toLowerCase();

        const filteredData = users.filter(
            (data) =>
                (data.nome_usuario != null &&
                    data.nome_usuario !== "" &&
                    data.nome_usuario.toLowerCase().includes(searchTerm)) ||
                (data.login != null &&
                    data.login !== "" &&
                    data.login.toLowerCase().includes(searchTerm)) ||
                (data.registration != null &&
                    data.registration !== "" &&
                    data.registration.toLowerCase().includes(searchTerm)) ||
                (data.organization != null &&
                    data.organization !== "" &&
                    data.organization.toLowerCase().includes(searchTerm)) ||
                (data.profile != null &&
                    data.profile !== "" &&
                    data.profile.toLowerCase().includes(searchTerm)) ||
                (data.function != null &&
                    data.function !== "" &&
                    data.function.toLowerCase().includes(searchTerm)) ||
                (data.group != null &&
                    data.group !== "" &&
                    data.group.toLowerCase().includes(searchTerm))
        );

        setFilteredUsers(filteredData);
        setFilteredData(true);
    };

    function refactorUserListing(data) {
        let refactoredData = [];

        if (!data) throw new Error('Error when fetching data!');

        data && data.map((item) => {
            const newObject = {
                name: {
                    data: item.nome_usuario,
                    config: {
                        className: ""
                    },
                },
                login: {
                    data: item.login_usuario,
                    config: {
                        className: ""
                    }
                },
                registration: {
                    data: item.matricula ?? "",
                    config: {
                        className: ""
                    }
                },
                organization: {
                    data: item.nome_organizacao,
                    config: {
                        className: ""
                    }
                },
                profile: {
                    data: item.perfil_usuario,
                    config: {
                        className: ""
                    }
                },
                function: {
                    data: item.desc_funcao,
                    config: {
                        className: ""
                    }
                },
                group: {
                    data: item.desc_grupo,
                    config: {
                        className: ""
                    }
                },
                actions: {
                    data: (
                        <div className="actions-users">
                            <button
                                className="actions-users__edit"
                            >
                                <EditIcon
                                    onClick={() => setUpdateUser({
                                        state: true,
                                        userId: item.id_usuario_criado
                                    })}
                                />
                            </button>

                            <button
                                className="actions-users__delete"
                            >
                                <TrashIcon
                                    onClick={() => setIsOpenModalDeleteUser({
                                        state: true,
                                        deletedUserId: item.id_usuario_criado
                                    })}
                                />
                            </button>
                        </div>
                    ),
                    config: {
                        className: "action-options"
                    }
                },
            }

            refactoredData.push(newObject);

            return refactoredData;
        });

        return refactoredData;
    };

    function renderTableContent() {
        switch (loading) {
            case true:
                return (
                    <div className="table-card-content__loading">
                        <Loading.Paperplane />
                    </div>
                )

            case false:
                if (users.length > 0) {
                    return (
                        <>
                            {searchData && filteredData && filteredUsers.length < 1 ? (
                                <div className="table-card-content__empty-state">
                                    <div className="table-card-content__empty-state__icon">
                                        <UserCircleAddIcon />
                                    </div>

                                    <div className="table-card-content__empty-state__text">
                                        <Typography.H4 weight="bold" color="blue-selected-4">
                                            Ops! Sem usuários cadastrados.
                                        </Typography.H4>

                                        <Typography.H5 color="gray-180" className="text-center">
                                            Clique no botão <span className="text-bold">"Adicionar usuário"</span>. <br />
                                            Preencha os dados do novo membro da equipe.
                                        </Typography.H5>

                                        <Typography.H5 color="gray-180">
                                            Pronto! O usuário estará pronto para entrar em ação.
                                        </Typography.H5>
                                    </div>
                                </div>
                            ) : (
                                <div className="table-card-content__table-container">
                                    <Table
                                        fixedHeader
                                        className="table-users"
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        columns={tableColumns}
                                        data={searchData && filteredData ? usersFiltered : usersData}
                                    />
                                </div>
                            )}
                        </>
                    )
                } else {
                    return (
                        <div className="table-card-content__empty-state">
                            <div className="table-card-content__empty-state__icon">
                                <UserCircleAddIcon />
                            </div>

                            <div className="table-card-content__empty-state__text">
                                <Typography.H4 weight="bold" color="blue-selected-4">
                                    Ops! Sem usuários cadastrados.
                                </Typography.H4>

                                <Typography.H5 color="gray-180" className="text-center">
                                    Para garantir que sempre haja alguém disponível: clique no botão <span className="text-bold">"Adicionar usuário"</span>. <br />
                                    Preencha os dados do novo membro da equipe.
                                </Typography.H5>

                                <Typography.H5 color="gray-180">
                                    Pronto! O usuário estará pronto para entrar em ação.
                                </Typography.H5>
                            </div>
                        </div>
                    )
                }

            default:
                break;
        }
    };

    async function getListOfUsers(userId) {
        setLoading(true);

        try {
            const response = await APIUser.getListOfUsers(token, userId);

            if (response.status === 200) {
                const usersWithParsedOrganizations = response.data.map(user => ({
                    ...user,
                    organizacoes: JSON.parse(user.organizacoes),
                    escalonamentos: JSON.parse(user.escalonamentos)
                }));

                setUsers(usersWithParsedOrganizations);
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } catch (error) {
            console.error("Erro ao processar a resposta da API:", error);
            Toast(
                "Erro ao processar a resposta da API",
                "Por favor, tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    // USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                id: "name",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Login",
                accessor: "login",
                id: "login",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Matrícula",
                accessor: "registration",
                id: "registration",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Organização",
                accessor: "organization",
                id: "organization",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Perfil",
                accessor: "profile",
                id: "profile",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Função",
                accessor: "function",
                id: "function",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Grupo",
                accessor: "group",
                id: "group",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ]
    );

    const usersData = useMemo(() => {
        if (users) {
            const refactoredData = refactorUserListing(users);
            return refactoredData;
        }
    }, [users]);

    const usersFiltered = useMemo(() => {
        if (Array.isArray(filteredUsers)) {
            const refactoredData = refactorUserListing(filteredUsers);
            return refactoredData;
        }
    }, [filteredUsers]);

    //USE EFFECTS
    useEffect(() => {
        if (!searchData || searchData.trim() === "") {
            setFilteredData(false);
            setSearchData("");
            setFilteredUsers([]);
        }
    }, []);

    useEffect(() => {
        if (updatedUser) {
            handleTableDataLookup()
        }
    }, [updatedUser, users]);

    useEffect(() => {
        getListOfUsers();
    }, []);

    useEffect(() => {
        users.forEach(user => {
            if (user.escalonamentos.length > 0) {
                user.escalonamentos.forEach(item => {
                    Object.assign(item, { to_view: false })
                })
            }

            if (user.organizacoes && user.organizacoes.length > 0) {
                const organizations = user.organizacoes;

                organizations.forEach(org => {
                    const orgAssociationData = {
                        id_criacao: org.criarcaso ? 1 : 2,
                        desc_criacao: org.criarcaso ? "Sim" : "Não",
                        id_fecharcaso: org.fecharcaso ? 1 : 2,
                        desc_fecharcaso: org.fecharcaso ? "Sim" : "Não"
                    };

                    Object.assign(org, orgAssociationData);
                });

                const mainOrganizationIndex = organizations.findIndex(org => org.idorganizacao === user.id_organizacao);

                if (mainOrganizationIndex !== -1) {
                    const mainOrganization = organizations[mainOrganizationIndex];

                    const orgMainData = {
                        idorganizacao: mainOrganization.idorganizacao,
                        nome_organizacao: mainOrganization.nome_organizacao,
                        tipo_visualizacao: mainOrganization.tipo_visualizacao,
                        desc_tipo_visualizacao: mainOrganization.desc_tipo_visualizacao,
                        id_criacao: mainOrganization.id_criacao,
                        id_fecharcaso: mainOrganization.id_fecharcaso,
                        desc_criacao: mainOrganization.desc_criacao,
                        desc_fecharcaso: mainOrganization.desc_fecharcaso,
                        criarcaso: mainOrganization.criarcaso,
                        fecharcaso: mainOrganization.fecharcaso,
                        id_organizacao_usuario: mainOrganization.id_organizacao_usuario,
                    };

                    Object.assign(user, { org_data_main: orgMainData });

                    organizations.splice(mainOrganizationIndex, 1);

                    if (organizations.length === 0) {
                        organizations.push({
                            criarcaso: false,
                            desc_criacao: "",
                            desc_fecharcaso: "",
                            desc_tipo_visualizacao: "",
                            fecharcaso: false,
                            id_criacao: null,
                            id_fecharcaso: null,
                            id_organizacao_usuario: null,
                            idorganizacao: null,
                            nome_organizacao: "",
                            tipo_visualizacao: null
                        })
                    }
                }

                user.organizacoes = organizations;
            } else {
                user.organizacoes = [{
                    criarcaso: false,
                    desc_criacao: "",
                    desc_fecharcaso: "",
                    desc_tipo_visualizacao: "",
                    fecharcaso: false,
                    id_criacao: null,
                    id_fecharcaso: null,
                    id_organizacao_usuario: null,
                    idorganizacao: null,
                    nome_organizacao: "",
                    tipo_visualizacao: null
                }];

                const orgMainData = {
                    idorganizacao: null,
                    nome_organizacao: "",
                    tipo_visualizacao: null,
                    desc_tipo_visualizacao: "",
                    id_criacao: null,
                    id_fecharcaso: null,
                    desc_criacao: "",
                    desc_fecharcaso: "",
                    criarcaso: false,
                    fecharcaso: false,
                    id_organizacao_usuario: null,
                };

                Object.assign(user, { org_data_main: orgMainData });
            }
        });
    }, [users]);

    return (
        <>
            {registerUser ? (
                <CreateUser
                    toGoBack={() => setRegisterUser(false)}
                    onCreate={getListOfUsers}
                />
            ) : updateUser.state ? (
                <UpdateUser
                    toGoBack={() => setUpdateUser({ state: false })}
                    userId={updateUser.userId}
                    detailedUser={users}
                    setDetailedUser={setUsers}
                    onUpdate={getListOfUsers}
                    setUpdatedUser={setUpdatedUser}
                    handleTableDataLookup={handleTableDataLookup}
                />
            ) : (
                <main className="page-user">
                    <header className="page-user__header">
                        <div className="page-user__header__title">
                            <ArrowLefIcon onClick={returnToRecord} />

                            <Typography.H2 color="gray-180" weight="bold">
                                Cadastro de usuários
                            </Typography.H2>
                        </div>

                        <div>
                            <Button
                                className=""
                                onClick={() => setRegisterUser(true)}
                            >
                                <Typography.H4 weight="bold" color="white">
                                    Adicionar usuário
                                </Typography.H4>
                            </Button>
                        </div>
                    </header>

                    <div className="table-card">
                        <div className="table-card-content">
                            <Input
                                type="text"
                                width="432px"
                                placeholder="Pesquisar"
                                value={searchData}
                                onChange={handleSearchUsers}
                                onKeyPress={(event) =>
                                    (event.code === "Enter" || event.code === "NumpadEnter") &&
                                    handleTableDataLookup()
                                }
                                rightIcon={
                                    <button onClick={handleTableDataLookup}>
                                        <SearchIcon />
                                    </button>
                                }
                            />

                            {renderTableContent()}
                        </div>
                    </div>
                </main>
            )}

            <Modal.DeleteUser
                isOpen={isOpenModalDeleteUser.state}
                onClose={(event) => setIsOpenModalDeleteUser({
                    ...isOpenModalDeleteUser, state: event
                })}
                deletedUserId={isOpenModalDeleteUser.deletedUserId}
                loggedInUserId={userData.userId}
                onDelete={getListOfUsers}
                users={users}
                handleTableDataLookup={handleTableDataLookup}
            />
        </>
    )
}
