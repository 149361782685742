import instance from "./configAxios";

export async function getAdditionalFieldsParent(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-campos-adicionais-pai', {}, config);

        return response;
    } catch (error) {
        console.log('getAdditionalFieldsParent -> Error en el servidor:', error);

        return error
    }
};

export async function getAdditionalChildFields(token, fieldId, value) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = {
            id_campo: fieldId,
            valor: value
        }

        const response = await instance.post('/buscar-campos-adicionais-filho', data, config);

        return response;
    } catch (error) {
        console.log('getAdditionalChildFields -> Error en el servidor:', error);

        return error
    }
};

export async function getAdditionalFieldTypes(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-tipos-campos-adicionais', {}, config);

        return response;
    } catch (error) {
        console.log('getAdditionalFieldTypes -> Error en el servidor:', error);

        return error
    }
};

export async function getAdditionalFieldsIntegration(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/buscar-integracao-campos-adicionais', {}, config);

        return response;
    } catch (error) {
        console.log('getAdditionalFieldsIntegration -> Error en el servidor:', error);

        return error
    }
}