//GLOBAL - components from npm
import React, { useState } from "react";
import { format } from "date-fns";
import ptBR from "../../../locale/pt-BR";
import { useHistory } from "react-router";

//STYLES
import "./draggable-card.scss";

//COMPONENTS
import { Typography } from "../../../components/general";
import { Modal, Tippy } from "./../../../components/feedback";
import { TagList, ProfileList } from "../../../components/data-entry";
import { DropdownStatus } from "../../../components/layout";
import { Toast } from "../../../utils/toast";
import Tag from "../Tag/Tag";
import ImageProfile from "../ImageProfile/ImageProfile";

//SERVICES - api, conectors...
import * as APITask from "./../../../services/api/task";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconNotification } from "../../../../src/assets/icons/NotificationThree.svg";
import { ReactComponent as IconMoreVertical } from "../../../assets/icons/MoreHorizontal.svg";

export default function DraggableCard({
  cases,
  deadline,
  title,
  description,
  tags,
  profiles,
  status,
  lembretes,
  id,
  idSolicitante,
  onUnlink,
  tagLimit,
  permissions,
  statusColor,
  hideTaskUnlinkButton,
  cardStatusIcon,
  updateMonthlyTasks,
  idTicket,
  ticketCase,
  idSolicitanteCase,
  assunto,
  observacao,
  deadlineCase,
  iconTicketStatus,
  descriptionReason,
  descriptionRequest,
  requestingUser,
  companyName,
  lastUser,
}) {
  //
  const history = useHistory();
  const user = useSelector((state) => state.auth.userData);
  const profileAssociated = profiles?.find((item) => item.id_usuario === user.userId);
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.userData);

  //STATES
  const [visible, setVisible] = useState(false);
  const [isOpenModalDeleteTask, setIsOpenModalDeleteTask] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const hide = () => {
    setVisible(false);
  };

  const handleClick = () => {
    if (
      user.userId === idSolicitante ||
      profileAssociated ||
      user.permissionId === 2
    ) {
      if (history.location.pathname.indexOf("externo") === 1)
        return history.push({
          pathname: `/externo/tarefas/${id}`,
          state: { from: history.location.pathname },
        });
      else
        return history.push({
          pathname: `/tarefas/${id}`,
          state: { from: history.location.pathname },
        });
    } else {
      return;
    }
  };

  async function deleteTask(taskId) {
    const response = await APITask.deleteTask(taskId, userData.userId, token);

    if (response.status === 200) {
      Toast("Tarefa Deletada!", "Tarefa deletada com sucesso.", "success");
      updateMonthlyTasks();
    } else {
      Toast(
        "Erro ao deletar a tarefa!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  };

  function renderContent() {
    if (cases) {
      return (
        <div
          className={`card-case-draggable 
          ${user.userId === idSolicitanteCase ||
              profileAssociated ||
              user.permissionId === 2
              ? ""
              : "card-task-draggable--disabled"
            }`}
          onClick={() => {
            history.push(`/casos/${idTicket}`);
          }}
        >
          <div className="card-case-draggable-header">
            <div className="card-case-draggable-header__ticket">
              <Typography.H6 color="blue-selected-4" weight="400">
                {ticketCase}
              </Typography.H6>
            </div>

            <div className="card-case-draggable-header__date">
              <Typography.H6 color="gray-200">
                {format(new Date(deadlineCase), "dd/MM/yyyy", { locale: ptBR })}
              </Typography.H6>
            </div>
          </div>

          <div className="card-case-draggable-content">
            <Tippy content={assunto}>
              <div className="card-case-draggable-content__title">
                <Typography.H6 weight="bold" color="gray-300">
                  {assunto}
                </Typography.H6>
              </div>
            </Tippy>

            <div className="card-case-draggable-content__description">
              <Typography.Paragraph color="gray-300">
                {observacao}
              </Typography.Paragraph>
            </div>

            <div className="subcontent">
              <div className="subcontent__request">
                <Tag withheldName name={descriptionRequest} />
              </div>

              <div className="subcontent__reason">
                {descriptionReason != null && (
                  <Tag withheldName name={descriptionReason} />
                )}
              </div>
            </div>
          </div>

          <div className="card-case-draggable-footer">
            <div className="card-case-draggable-profile">
              <ImageProfile
                changeName
                theName={"Criador do caso"}
                profile={{
                  nome_usuario: requestingUser,
                  nome_organizacao: companyName != null && companyName,
                }}
                className="card-case-draggable-profile__icon"
              />

              {lastUser != null && (
                <ImageProfile
                  changeName
                  theName={"Última atualização"}
                  profile={{
                    nome_usuario: lastUser,
                    nome_organizacao: companyName != null && companyName,
                  }}
                  className="card-case-draggable-profile__icon"
                />
              )}
            </div>

            <div className="card-case-draggable-footer__status">
              {iconTicketStatus}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`card-task-draggable 
          ${user.userId === idSolicitante ||
              profileAssociated ||
              user.permissionId === 2
              ? ""
              : "card-task-draggable--disabled"
            }
          `}
          onClick={() => (permissions.edicao ? handleClick() : null)}
        >
          <div className="card-task-draggable-header">
            <div className="card-task-draggable-header__date">
              <Typography.H6 color="gray-200">
                {format(new Date(deadline), "dd/MM/yyyy", { locale: ptBR })}
              </Typography.H6>
            </div>
            <div
              className={`card-task-draggable-header__notification ${lembretes?.length > 0 ? "icon--active" : ""}`}
            >
              {lembretes?.length > 0 && <IconNotification />}
            </div>
            {user.userId === idSolicitante ||
              profileAssociated ||
              user.permissionId === 2 ? (
              !permissions?.edicao && !permissions?.exclusao ? (
                <></>
              ) : (
                <Tippy
                  theme="popover-default"
                  visible={visible}
                  onClickOutside={hide}
                  placement="left-start"
                  delay={[0, 0]}
                  allowHTML
                  interactive
                  content={
                    <div className="popover-card-draggable">
                      {permissions.edicao && (
                        <>
                          <div
                            className="popover-card-draggable__item"
                            onClick={() => {
                              history.push(`/tarefas/${id}`);
                            }}
                          >
                            <div className="popover-card-draggable__item__option">
                              Editar
                            </div>
                          </div>
                          {hideTaskUnlinkButton ? null : (
                            <div
                              className="popover-card-draggable__item"
                              onClick={(e) => {
                                e.stopPropagation();
                                onUnlink();
                                hide();
                              }}
                            >
                              <div className="popover-card-draggable__item__option">
                                Desvincular
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {(user.userId === idSolicitante ||
                        permissions.exclusao) && (
                          <div
                            className="popover-card-draggable__item"
                            onClick={(e) => {
                              e.stopPropagation();
                              hide();

                              setIsOpenModalDeleteTask(true);
                            }}
                          >
                            <div className="popover-card-draggable__item__option__remove">
                              Remover
                            </div>
                          </div>
                        )}
                    </div>
                  }
                >
                  <div>
                    <div
                      className="card-task-draggable-header__status-color"
                      style={{ backgroundColor: "" }}
                    />

                    <div
                      className="card-task-draggable-header__menu"
                      onClick={(e) => {
                        e.stopPropagation();
                        setVisible(true);
                      }}
                    >
                      <IconMoreVertical />
                    </div>
                  </div>
                </Tippy>
              )
            ) : (
              ""
            )}
          </div>
          <div className="card-task-draggable-content">
            <Tippy content={title}>
              <div className="card-task-draggable-content__title">
                <Typography.H6 weight="bold" color="gray-300">
                  {title}
                </Typography.H6>
              </div>
            </Tippy>
            <div className="card-task-draggable-content__description">
              <Typography.Paragraph color="gray-300">
                {description}
              </Typography.Paragraph>
            </div>
          </div>
          <div className="card-task-draggable-tags">
            <TagList
              tagLimit={tagLimit}
              tags={tags}
              viewOnly
              disabledVisibleView
            />
          </div>
          <div className="card-task-draggable-footer">
            <div className="card-task-draggable-profile">
              <ProfileList viewOnly disabledVisibleView profiles={profiles} />
            </div>

            <div className="card-task-draggable-footer__status">
              <div
                className="color-bar"
                style={{ backgroundColor: statusColor }}
              />

              <DropdownStatus
                cardStatusIcon={cardStatusIcon}
                value={status}
                statusIconKanban
                type="tag"
              />
            </div>
          </div>
        </div>
      );
    }
  }

  //USE EFFECTS

  return (
    <>
      {renderContent()}

      <Modal.GenericDeleteModal
        title="Excluir Tarefa"
        isOpen={isOpenModalDeleteTask}
        onClose={(event) => { setIsOpenModalDeleteTask(event) }}
        onDeleteAction={async () => {
          await deleteTask(parseInt(id));
        }}
      />
    </>
  );
}
