//GLOBAL - components from npm
import { createSlice } from "@reduxjs/toolkit";
import { sub, endOfDay } from "date-fns";

// UTILS
import getLocalStorage from "../utils/getLocalStorage";

let casesFilter = getLocalStorage("casesFilter", null);
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    token: undefined,
    externalAccess: undefined,
    userData: undefined,
    permissionProfile: [],
  },
  reducers: {
    doLogin(state, action) {
      // #TOIMPROVE: Melhorar armazenamento do token no redux/localstorage
      state.token = action.payload.token;
      state.userData = {
        userId: action.payload.userData.id_usuario,
        profileId: action.payload.userData.id_perfil,
        userName: action.payload.userData.nome_usuario,
        permissionId: action.payload.userData.id_tipo,
        organizationId: action.payload.userData.id_organizacao,
        organizationName: action.payload.userData.nome_organizacao,
        organizationsView:
          action.payload.userData.organizacoes_autorizadas_visualizar,
        organizationsCreate:
          action.payload.userData.organizacoes_autorizadas_criar,
        idgrupo: action.payload.userData.idgrupo,
      };
      state.isAuthenticated = true;
    },
    doExternalLogin(state, action) {
      // #TOIMPROVE: Melhorar armazenamento do token no redux/localstorage
      state.externalAccess = action.payload.payload;
    },
    doProfilePermissions(state, action) {
      state.permissionProfile = action.payload.permissionProfile;
    },
    doLogout(state) {
      state.isAuthenticated = false;
      state.token = undefined;
      state.externalAccess = undefined;
      state.userData = undefined;
      // #TOIMPROVE: Melhorar a forma de remover o token do localStorage
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("externalAccess");
      window.localStorage.setItem(
        "casesFilter",
        JSON.stringify({
          ...casesFilter,
          dateStart: sub(new Date(), {
            months: 1,
          }),
          dateEnd: endOfDay(new Date()),
        })
      );
    },
  },
});

export const { doLogin, doLogout, doExternalLogin, doProfilePermissions } =
  authSlice.actions;
export default authSlice.reducer;
