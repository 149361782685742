import Main from "./Main";
import AdvancedFilters from "./AdvancedFilters";
import FiltersDashboard from "./FiltersDashboard";

const Drawer = {
  Main,
  AdvancedFilters,
  FiltersDashboard,
};

export default Drawer;
