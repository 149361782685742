//GLOBAL - components from npm
import React from "react";

//STYLES
import "./paragraph.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function Paragraph({
  className,
  children,
  weight,
  color,
  fontChanges,
}) {
  //GENERAL
  const mainClassName = "typography-paragraph";

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <h6
      className={`${mainClassName} ${className ? className : ""} ${
        weight ? `${mainClassName}--${weight}` : ""
      } ${color ? `${mainClassName}--color-${color}` : ""} ${
        fontChanges && `${mainClassName}--${fontChanges}`
      }`}
    >
      {children}
    </h6>
  );
}
