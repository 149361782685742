//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

// UTILS

//STYLES
import "./radial-bar-chart.scss";

//COMPONENTS
import { Typography } from "../../general";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function RadialBarChart({
  data,
  halfCenter = false,
  darkMode = false,
  internalRadius = 0.8,
  borderWidth,
  centerSize = 20,
  centerWeight = 500,
  centerFill,
  centerValue,
  bottom = 0,
  onClick = () => null,
}) {
  //FUNCTIONS
  const CenteredMetric = ({ center, bars }) => {
    const ref = useRef(null);
    return (
      <text
        x={center[0]}
        y={center[1]}
        style={{
          fontSize: centerSize,
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: centerWeight,
          lineHeight: "28px",
          fill: centerFill ? centerFill : darkMode ? "#FFF" : "#000",
        }}
        textAnchor="middle"
        dominantBaseline="central"
        ref={ref}
      >
        {centerValue ?? `${bars[0].value}%`}
      </text>
    );
  };

  return (
    <div style={{ height: "95%", width: "99%" }}>
      <ResponsiveRadialBar
        data={data}
        onClick={onClick}
        // onMouseMove={(bar, event) =>
        //   (event.target.style.cursor =
        //     bar.value !== "0" ? "pointer" : "default")
        // }
        maxValue={100}
        // height={106}
        startAngle={halfCenter ? -90 : 0}
        endAngle={halfCenter ? 90 : 360}
        colors={{ datum: "data.color" }}
        tracksColor={data[0].data[0].colorTrack}
        borderWidth={borderWidth ?? 0}
        borderColor={{
          from: "color",
          modifiers: [["darker", "0"]],
        }}
        innerRadius={internalRadius}
        cornerRadius={2}
        margin={{ top: 0, bottom: bottom }}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
        tooltip={({ bar }) => {
          return (
            <div className="tooltip-text-radial">
              {/* <div
                style={{ width: 12, height: 12, backgroundColor: bar.color }}
              ></div> */}
              <Typography.H6 color={"white"}>
                {`${bar.groupId} - ${bar.value}%`}
              </Typography.H6>
            </div>
          );
        }}
        layers={["tracks", "bars", CenteredMetric]}
        legends={[]}
        // defs={[
        //   {
        //     colors: [
        //       {
        //         color: "inherit",
        //         offset: 0,
        //         opacity: 0.6,
        //       },
        //       {
        //         color: "inherit",
        //         offset: 100,
        //         opacity: 0,
        //       },
        //     ],
        //     id: "gradient",
        //     type: "linearGradient",
        //   },
        // ]}
        // fill={[
        //   {
        //     id: "gradient",
        //     match: "*",
        //   },
        // ]}
      />
    </div>
  );
}
