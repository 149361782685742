import React, { useState } from "react";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import Balloon from "../Balloon";
import { Typography } from "../../general";
import { PieChart } from "../../data-graphics/";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as ArrowDown } from "../../../assets/icons/ArrowDownTwo.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/ArrowUpTwo.svg";
import { ReactComponent as FileSearching } from "../../../assets/icons/File-searching.svg";

export default function CardSolicited({ card, darkMode, onClick }) {
  //GENERAL

  //STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const countPagination =
    parseInt(card.dataSubtitles.length / rowsPerPage) +
    (card.dataSubtitles.length % rowsPerPage !== 0 ? 1 : 0);

  //REDUX - Selectors

  //FUNCTIONS

  return (
    <div className="card-dashboard__solicited">
      <Typography.H4 weight={"bold"} color={darkMode ? "white" : "gray-180"}>
        {card.title}
      </Typography.H4>
      {card.emptySpace ? (
        <div className="card-dashboard-emptySpace line">
          <FileSearching width={120} height={120} />
          <Typography.H5 color={darkMode ? "white" : "gray-180"}>
            Oops! A sua pesquisa não gerou resultados.
          </Typography.H5>
        </div>
      ) : (
        <div className="card-dashboard__solicited-container">
          <div className="card-dashboard__solicited-container__graphic">
            <PieChart
              data={card.data}
              internalRadius={0.7}
              darkMode={darkMode}
              height={250}
              centerText
            />
          </div>
          <div className="card-dashboard__solicited-container__reasons">
            <div className="card-dashboard__solicited-container__reasons-boxes">
              {card.dataSubtitles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((balloon) => {
                  return (
                    <div
                      key={balloon.id}
                      className="card-dashboard__solicited-container__reasons-box"
                      onClick={() => onClick(card, balloon.title, balloon.id)}
                    >
                      <Balloon color={balloon.color} />
                      <Typography.H5
                        weight={"bold"}
                        color={darkMode ? "white" : "gray-180"}
                      >
                        {balloon.title}
                      </Typography.H5>
                    </div>
                  );
                })}
            </div>
            <div className="control-subtitle">
              <ArrowUp
                className={page === 0 ? "control-subtitle__icon" : ""}
                onClick={() =>
                  page === 0 ? null : setPage((value) => value - 1)
                }
              />
              <Typography.H5 color={"blue-selected-4"}>
                {" "}
                {page + 1}/{countPagination}{" "}
              </Typography.H5>
              <ArrowDown
                className={
                  page + 1 === countPagination ? "control-subtitle__icon" : ""
                }
                onClick={() =>
                  page + 1 === countPagination
                    ? null
                    : setPage((value) => value + 1)
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
