//GLOBAL - components from npm
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// UTILS

//STYLES
import "./kanban.scss";

//COMPONENTS
import { DraggableCard } from "../../../../components/data-display";
import { Typography } from "../../../../components/general";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APICase from "./../../../../services/api/case";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconAddTask } from "./../../../../assets/icons/IconAdd.svg";

export default function Kanban({
  token,
  dataKanban,
  setDataKanban,
  setIsOpenModalAddCase,
  updateCases
}) {
  //REDUX - Selectors

  //GENERAL

  //STATES
  const [isFetching, setIsFetching] = useState(false);
  const [referenceNode, setReferenceNode] = useState();

  //FUNCTIONS

  const onDragEnd = async ({ source, destination }) => {
    if (!destination) return;

    const sourceColIndex = dataKanban.findIndex(
      (e) => e.id_coluna === source.droppableId
    );
    const destinationColIndex = dataKanban.findIndex(
      (e) => e.id_coluna === destination.droppableId
    );

    const sourceCol = dataKanban[sourceColIndex];
    const destinationCol = dataKanban[destinationColIndex];

    if (sourceCol === destinationCol) {
      const newTask = Array.from(sourceCol.cases);

      const swapTask = newTask[source.index];
      newTask.splice(source.index, 1);
      newTask.splice(destination.index, 0, swapTask);

      console.log("swapTask", swapTask);

      const newColumnsState = dataKanban.map((t) => {
        if (t.id_coluna === sourceCol.id_coluna) {
          t.cases = newTask;
          return t;
        } else return t;
      });

      const newColumnState2 = [...newColumnsState];

      const updatePosition = {
        id_ticket: swapTask.id_ticket,
        id_ticket_status: sourceCol.id_coluna,
      };

      const response = await APICase.updateCasePosition(updatePosition, token);

      if (response.status === 200) {
        setDataKanban(newColumnState2);
        updateCases();
      } else {
        Toast(
          "Erro ao alterar posição do caso",
          "Não foi possível carregar as informações",
          "error"
        );
      }
    } else {
      const startTask = Array.from(sourceCol.cases);
      const finishTask = Array.from(destinationCol.cases);

      const [item] = startTask.splice(source.index, 1);

      finishTask.splice(destination.index, 0, item);

      const newColumnState = dataKanban.map((t) => {
        if (t.id_coluna === sourceCol.id_coluna) {
          t.cases = startTask;
          return t;
        } else if (t.id_coluna === destinationCol.id_coluna) {
          t.cases = finishTask;
          return t;
        } else return t;
      });

      const newColumnState2 = [...newColumnState];

      const updatePosition = {
        id_ticket: item.id_ticket,
        id_ticket_status: destinationCol.id_coluna,
      };

      const response = await APICase.updateCasePosition(updatePosition, token);

      if (response.status === 200) {
        setDataKanban(newColumnState2);
        updateCases();
      } else {
        Toast(
          "Erro ao alterar posição do caso",
          "Não foi possível carregar as informações",
          "error"
        );
      }
    }
  };

  function renderQuantityCases(cases) {
    return <>
      {`(${cases < 10 ? '0' : ''}${cases} caso${cases === 1 ? '' : 's'})`}
    </>
  };

  //USE EFFECTS

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="column-container">
          {dataKanban.map((currentDataKanban) => {
            return (
              // <>
              //     {currentDataKanban.id_coluna !== "3" && (
              <Droppable
                key={currentDataKanban.id_coluna}
                droppableId={currentDataKanban.id_coluna}
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    className="column"
                    ref={provided.innerRef}
                  >
                    <header className="column__header">
                      <div className="column__header__text">
                        <Typography.H5 weight="bold" color="black">
                          {currentDataKanban.descricao_coluna}
                        </Typography.H5>

                        <Typography.H6 weight="500" color="black">
                          {renderQuantityCases(currentDataKanban.cases?.length)}
                        </Typography.H6>
                      </div>

                      <div className="column__header__add-case">
                        <IconAddTask
                          onClick={() =>
                            setIsOpenModalAddCase(
                              currentDataKanban.id_coluna,
                              true
                            )
                          }
                        />
                      </div>
                    </header>

                    <div className="column__cards">
                      {currentDataKanban.cases
                        ?.slice(0, 70)
                        .map((currentCardCase, cardIndexCase) => {
                          let id = currentCardCase.id_ticket;
                          let ticketId = id.toString();

                          return (
                            <Draggable
                              key={ticketId}
                              draggableId={ticketId}
                              index={cardIndexCase}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <DraggableCard
                                    cases
                                    cardIndexCase={cardIndexCase}
                                    idTicket={ticketId}
                                    idTicketStatus={
                                      currentCardCase.id_ticket_status
                                    }
                                    descricaoTicketStatus={
                                      currentCardCase.descricao_ticket_status
                                    }
                                    iconTicketStatus={currentDataKanban.icon}
                                    ticketCase={currentCardCase.codigo_ticket}
                                    assunto={currentCardCase.assunto}
                                    observacao={currentCardCase.observacao}
                                    deadlineCase={
                                      currentCardCase.prazo_resolucao
                                    }
                                    idSolicitanteCase={
                                      currentCardCase.id_solicitante
                                    }
                                    descriptionReason={
                                      currentCardCase.descricao_motivo
                                    }
                                    descriptionRequest={
                                      currentCardCase.descricao_solicitacao
                                    }
                                    requestingUser={
                                      currentCardCase.nome_solicitante
                                    }
                                    companyName={currentCardCase.nome_empresa}
                                    lastUser={currentCardCase.ultimo_usuario}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              //     )}
              // </>
            );
          })}

          {/* ######## COLUNA DE STATUS/CASOS CONCLUÍDOS ######## */}
          {/* {dataKanban.map((currentDataKanban) => {
                        return (
                            <>
                                {currentDataKanban.id_coluna === "3" && (
                                    <Droppable
                                        key={currentDataKanban.id_coluna}
                                        droppableId={currentDataKanban.id_coluna}
                                    >
                                        {(provided) => (
                                            <div
                                                className="column"
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                <header className="column__header">
                                                    <div className="column__header__text">
                                                        <Typography.H5 weight="bold" color="black">
                                                            {currentDataKanban.descricao_coluna}
                                                        </Typography.H5>

                                                        <Typography.H6 weight="500" color="black">
                                                            {renderQuantityCases(currentDataKanban.cases?.length)}
                                                        </Typography.H6>
                                                    </div>

                                                    <div className="column__header__add-case">
                                                        <IconAddTask onClick={() => setIsOpenModalAddCase(currentDataKanban.id_coluna, true)} />
                                                    </div>
                                                </header>

                                                <div className="column__cards">
                                                    {currentDataKanban.cases?.slice(0, 70).map((currentCardCase, cardIndexCase) => {
                                                        currentCardCase.id_ticket = currentCardCase.id_ticket.toString();

                                                        return (
                                                            <>
                                                                {currentCardCase.id_ticket_status.toString() === currentDataKanban.id_coluna && (
                                                                    <Draggable
                                                                        key={currentCardCase.id_ticket}
                                                                        draggableId={currentCardCase.id_ticket}
                                                                        index={cardIndexCase}
                                                                    >
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <DraggableCard
                                                                                    cases
                                                                                    hideTaskUnlinkButton
                                                                                    cardIndexCase={cardIndexCase}
                                                                                    idTicket={currentCardCase.id_ticket}
                                                                                    idTicketStatus={currentCardCase.id_ticket_status}
                                                                                    descricaoTicketStatus={currentCardCase.descricao_ticket_status}
                                                                                    iconTicketStatus={currentDataKanban.icon}
                                                                                    ticketCase={currentCardCase.codigo_ticket}
                                                                                    assunto={currentCardCase.assunto}
                                                                                    observacao={currentCardCase.observacao}
                                                                                    deadlineCase={currentCardCase.prazo_resolucao}
                                                                                    idSolicitanteCase={currentCardCase.id_solicitante}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                )}
                            </>
                        )
                    })} */}
        </div>
      </DragDropContext>
    </>
  );
}
