//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
//import { format } from 'date-fns';

// UTILS
import unicodeToChar from "../../../utils/formatUnicode";
import urlIngWeb from "../../../utils/env";

//STYLES
import "./interaction-comment.scss";

//COMPONENTS
import { ImageProfile, FilePreview, AttachTypeFiles, CommentImage } from "..";
import { Audio } from "../../data-entry";
import { Typography } from "../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as SuporteIcon } from "../../../assets/icons/call.svg";
import { ReactComponent as DesenvolvimentoIcon } from "../../../assets/icons/monitor.svg";
import { ReactComponent as CEPIcon } from "../../../assets/icons/Gps.svg";
import { ReactComponent as DocApresentaIcon } from "../../../assets/icons/slider-vertical.svg";
import { ReactComponent as IconClips } from "../../../assets/icons/Clips.svg";
import { ReactComponent as Tick } from "../../../assets/icons/double-tick.svg";
import { ReactComponent as ErrorCircle } from "../../../assets/icons/info-circle.svg";

export default function InteractionComment({
  text,
  id,
  date,
  attachment,
  type,
  direction,
  name,
  mimetype,
  mimetypeId,
  size,
  inputSearch,
  buttons,
  botId,
  reply,
  buttonResponse,
  typeName,
  location,
  allInfo,
}) {
  //GENERAL
  let textInteraction = text;
  let buttonsInteraction = [];
  const mimetypesPrincipal = [
    "image",
    "voice",
    "document",
    "audio",
    "txt",
    "video",
  ];

  if (mimetypeId === 30 && isJson(text) === true) {
    textInteraction = tratarBOT(textInteraction);
  }

  if (typeName === "Interactive" && isJson(text) === true) {
    textInteraction = tratarBOT(textInteraction);
  }

  if (typeName === "location" && isJson(text) === true) {
    textInteraction = tratarBOT(textInteraction);
  }

  //STATES
  const [interactionComment, setInteractionComment] = useState(text);
  const [commentImg, setCommentImg] = useState(false);
  const [commentVideo, setCommentVideo] = useState(false);
  const [buttonsComment, setButtonsComment] = useState([]);

  //REDUX - Selectors

  //FUNCTIONS
  function isJson(item) {
    item = typeof item != "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item != null) {
      return true;
    }

    return false;
  }

  function tratarBOT(text) {
    let json = JSON.parse(text);
    let options = json.options;
    if (parseInt(json.MIMETYPE) === 30) {
      return json.MSG;
    } else if (parseInt(json.mimetype) >= 33 && parseInt(json.mimetype) <= 37) {
      if (json.header) {
        return `*${json.header}*\\r\\n${json.msg}`;
      } else {
        return json.msg;
      }
    } else if (json.address) {
      return json.address;
    } else if (json.HSM_BUTTONS) {
      return json.URL;
    }

    return text;
  }

  function formatEmoji(item) {
    if (item) {
      let mensagem_tratada = item.replace(/¿/gi, "\n");
      mensagem_tratada = mensagem_tratada.replace(/§/gi, "\r\n");
      let valor = 1;
      try {
        mensagem_tratada =
          '{"' +
          valor +
          '": [{"mensagem_tratada":"' +
          mensagem_tratada +
          '"}]}';
        let obj = JSON.parse(mensagem_tratada);
        mensagem_tratada = obj[1][0].mensagem_tratada;
      } catch (e) {
        mensagem_tratada = item;
      }
      return mensagem_tratada;
    }
  }

  const handleComment = (idComment) => {
    const comment = document.querySelector(`#comentario-${idComment}`);
    comment.classList.add("comment-active");
    setTimeout(() => {
      comment.classList.remove("comment-active");
    }, 2000);
  };

  const renderTitleComment = () => {
    return (
      <div className="comment-information-user">
        <Typography.H5 color="gray-180" weight={"bold"}>
          {formatEmoji(name)}
        </Typography.H5>
      </div>
    );
  };

  const renderCommentAnswered = () => {
    if (reply) {
      if (mimetypesPrincipal.includes(reply.typename)) {
        return (
          <a
            href={`#comentario-${reply.id}`}
            className="comment-content__response"
            onClick={() => handleComment(reply.id)}
          >
            <div className="response-divider"></div>
            <div className="response-content">
              <Typography.H6 color="gray-180" weight={"bold"}>
                {reply.username}
              </Typography.H6>

              <Typography.H6 color="gray-180">
                <IconClips />
                &nbsp; Ir para o(s) anexo(s)
              </Typography.H6>
            </div>
          </a>
        );
      } else if (reply.typename === "Interactive" || reply.mimetype === 30) {
        let text = tratarBOT(reply.mensagem);
        const textExhib = ReactHtmlParser(
          `<p>${unicodeToChar(text)}<p/>`
            ?.replace(/(?:\*)([^*<\n]+)(?:\*)/g, "<strong>$1</strong>")
            .replace(/\_(?=\w)/, "<em>")
            .replace(/(?<=\w)\_/, "</em>")
            .replaceAll("\n", "</br>")
            .replaceAll('"}', "")
            .replaceAll("\\n", "</br>")
            .replaceAll("\n", "</br>")
            .replaceAll("\\r", "")
            .replaceAll("\r", "")
        );
        if (reply.mimetype === 30) {
          text = ReactHtmlParser(
            `<p>${unicodeToChar(text)}<p/>`
              ?.replace(/(?:\*)([^*<\n]+)(?:\*)/g, "<strong>$1</strong>")
              .replace(/\_(?=\w)/, "<em>")
              .replace(/(?<=\w)\_/, "</em>")
              .replaceAll("\n", "</br>")
              .replaceAll('"}', "")
              .replaceAll("\\n", "</br>")
              .replaceAll("\n", "</br>")
              .replaceAll("\\r", "")
              .replaceAll("\r", "")
          );
        }

        return (
          <a
            href={`#comentario-${reply.id}`}
            className="comment-content__response"
            onClick={() => handleComment(reply.id)}
          >
            <div className="response-divider"></div>
            <div className="response-content">
              <Typography.H6 color="gray-180" weight={"bold"}>
                {reply.username}
              </Typography.H6>

              <Typography.H6 color="gray-180">
                {reply.typename === "Interactive" &&
                  (text.length > 150
                    ? `${textExhib.slice(0, 150)}... `
                    : textExhib)}
                {reply.mimetype === 30 &&
                  (text.length > 150 ? `${text.slice(0, 150)}... ` : text)}
              </Typography.H6>
            </div>
          </a>
        );
      } else {
        // const text = tratarBOT(reply.mensagem);
        const textExhib = ReactHtmlParser(
          `<p>${unicodeToChar(reply.mensagem)}<p/>`
            ?.replace(/(?:\*)([^*<\n]+)(?:\*)/g, "<strong>$1</strong>")
            .replace(/\_(?=\w)/, "<em>")
            .replace(/(?<=\w)\_/, "</em>")
            .replaceAll("\n", "</br>")
            .replaceAll('"}', "")
            .replaceAll("\\n", "</br>")
            .replaceAll("\n", "</br>")
            .replaceAll("\\r", "")
            .replaceAll("\r", "")
        );

        return (
          <a
            href={`#comentario-${reply.id}`}
            className="comment-content__response"
            onClick={() => handleComment(reply.id)}
          >
            <div className="response-divider"></div>
            <div className="response-content">
              <Typography.H6 color="gray-180" weight={"bold"}>
                {reply.username}
              </Typography.H6>

              <Typography.H6 color="gray-180">
                {text.length > 150
                  ? `${textExhib.slice(0, 150)}... `
                  : textExhib}
              </Typography.H6>
            </div>
          </a>
        );
      }
    }
  };

  const renderFileComment = () => {
    if (commentImg) {
      return (
        <CommentImage
          content={[{ file: attachment }]}
          linkImg={attachment ? "" : text}
        />
      );
    } else if (commentVideo) {
      return (
        <div className="bubble-media__video">
          <video className="bubble-media__video-frame" controls>
            <source
              className="bubble-media__video-file"
              src={`${urlIngWeb + attachment.path_arquivos}`}
              type={`${attachment.type}`}
            />
            Seu navegador nao suporta esse tipo de arquivo.
          </video>
        </div>
      );
    } else {
      return (
        <AttachTypeFiles
          files={attachment || text}
          type="comment"
          limit={3}
          className="comment-carousel"
          download
        />
      );
    }
  };

  const renderFooter = () => {
    return (
      <div className="comment-footer">
        <Typography.Paragraph color="gray-200">
          {date.horaEnvio.split(" ")[1]}
        </Typography.Paragraph>
        {!date.horaEnvio ? (
          <ErrorCircle />
        ) : date.horaLida ? (
          <>
            <Tick />
          </>
        ) : (
          <Tick className="gray-tick" />
        )}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="comment-buttons">
        {buttons.map((button) => {
          let key = "";
          let textButton = "";
          let color = "";
          if (button.reply) {
            key = button.reply.id;
            textButton = unicodeToChar(button.reply.title);
          } else if (mimetypeId === 37) {
            if (button.title) {
              key = button.payload;
              textButton = unicodeToChar(button.title);
            } else {
              key = id;
              textButton = "";
            }
          } else {
            key = button.payload;
            textButton = unicodeToChar(button.text);
          }

          if (mimetypeId === 37) {
            if (botId === 175) {
              return (
                <div className="comment-buttons__item-list" key={key}>
                  <Typography.H6 weight={400} color="gray-180">
                    {textButton}
                  </Typography.H6>
                  <div className="comment-buttons__item">
                    <div className="list-rows">
                      {button.rows.map((row) => (
                        <div className="list-rows_item" key={row.id}>
                          <Typography.H5
                            weight={500}
                            color={
                              buttonResponse
                                ? unicodeToChar(buttonResponse) ===
                                    unicodeToChar(row.title) ||
                                  buttonResponse === row.id
                                  ? `blue-selected-5`
                                  : "gray-125"
                                : `blue-selected-5`
                            }
                          >
                            • {row.title}
                          </Typography.H5>
                          <Typography.H6
                            weight={400}
                            color={
                              buttonResponse
                                ? unicodeToChar(buttonResponse) ===
                                    unicodeToChar(row.title) ||
                                  buttonResponse === row.id
                                  ? "gray-180"
                                  : "gray-125"
                                : `gray-180`
                            }
                          >
                            {row.description}
                          </Typography.H6>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            } else if (!button.title) {
              return button.rows.map((row) => (
                <div className="comment-buttons__item" key={row.id}>
                  <Typography.H5
                    weight={500}
                    color={
                      buttonResponse
                        ? unicodeToChar(buttonResponse) ===
                            unicodeToChar(row.title) ||
                          buttonResponse === row.id
                          ? `blue-selected-5`
                          : "gray-125"
                        : `blue-selected-5`
                    }
                  >
                    • {row.title}
                  </Typography.H5>
                </div>
              ));
            } else {
              return (
                <div className="comment-buttons__item" key={key}>
                  <Typography.H5
                    weight={500}
                    color={
                      buttonResponse
                        ? unicodeToChar(buttonResponse) === textButton ||
                          buttonResponse === key
                          ? `blue-selected-5`
                          : "gray-125"
                        : `blue-selected-5`
                    }
                  >
                    • {textButton}
                  </Typography.H5>
                </div>
              );
            }
          } else {
            return (
              <div className="comment-buttons__item" key={key}>
                <Typography.H5
                  weight={500}
                  color={
                    buttonResponse
                      ? unicodeToChar(buttonResponse) === textButton ||
                        buttonResponse === key
                        ? `blue-selected-5`
                        : "gray-125"
                      : `blue-selected-5`
                  }
                >
                  {textButton}
                </Typography.H5>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderMaps = () => {
    if (location) {
      return (
        <div className="comment-maps">
          <iframe
            title="Mapa da Localizão"
            src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.7632671208275!2d${location.longitude}!3d${location.latitude}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7161b057e31cf77%3A0xb4623fb4877dcc7!2s${location.nomeEndereco}!5e0!3m2!1spt-BR!2sbr!4v1683163051882!5m2!1spt-BR!2sbr`}
            width="324"
            height="168"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      );
    }
  };

  //USE EFFECTS

  useEffect(() => {
    if (mimetype?.typename.includes("image")) {
      setCommentImg(true);
    } else if (mimetype?.typename.includes("video")) {
      setCommentVideo(true);
    }

    if (attachment?.type.includes("image")) {
      setCommentImg(true);
    } else if (attachment?.type.includes("video")) {
      setCommentVideo(true);
    }
  }, [attachment, mimetype]);

  //UseEffect que recebe o filtro de palavras e com base nele coloca um marcador de texto em volta da palavra que foi filtrada.
  useEffect(() => {
    let currentComment = text;

    if (inputSearch) {
      const indexes = [];
      let i = 0;
      while (i < currentComment.length) {
        if (
          currentComment.toLowerCase().indexOf(inputSearch.toLowerCase(), i) !==
          -1
        ) {
          indexes.push(
            currentComment.toLowerCase().indexOf(inputSearch.toLowerCase(), i)
          );
          i =
            currentComment.toLowerCase().indexOf(inputSearch.toLowerCase(), i) +
            1;
        } else {
          i = currentComment.length;
        }
      }

      const words = [];

      for (const index of indexes) {
        const partName = currentComment.substr(index, inputSearch.length);
        words.push(partName);
      }

      const newWords = words.filter((item, i) => {
        return words.indexOf(item) === i;
      });

      const newWordsOrder = newWords.sort((a, b) => a.localeCompare(b));

      for (const item of newWordsOrder) {
        currentComment = currentComment.replaceAll(
          item,
          `<mark>${item}</mark>`
        );
      }
    }
    currentComment = currentComment?.replaceAll(` `, `&nbsp;`);

    setInteractionComment(`<p>${currentComment}</p>`);
  }, [inputSearch]);

  const renderInteractionComment = () => {
    if (type === "attachment") {
      if (typeName === "audio") {
        return (
          <div
            className={`interaction-comment ${
              direction === "left" ? "blue-color" : " "
            } comment-length-normal`}
            id={`comentario-${id}`}
          >
            <div className="comment-information">{renderTitleComment()}</div>
            <div className="comment-content">
              {renderCommentAnswered()}
              {attachment.path_arquivos && (
                <Audio link={attachment.path_arquivos} />
              )}
            </div>
            {renderFooter()}
          </div>
        );
      } else {
        return (
          <div
            className={`interaction-comment ${
              direction === "left" ? "blue-color" : " "
            } comment-length-normal`}
            id={`comentario-${id}`}
          >
            <div className="comment-information">{renderTitleComment()}</div>
            <div className="comment-content">
              <div className="comment-content__files">
                {renderFileComment()}
              </div>
              {allInfo.caption && (
                <div className="comment-content__text">
                  {/* let teste = isJson() */}
                  <Typography.H5 color="gray-180">
                    {ReactHtmlParser(
                      `<p>${unicodeToChar(allInfo.caption)}<p/>`
                        ?.replace(
                          /(?:\*)([^*<\n]+)(?:\*)/g,
                          "<strong>$1</strong>"
                        )
                        .replace(/\_(?=\w)/, "<em>")
                        .replace(/(?<=\w)\_/, "</em>")
                        .replaceAll("\n", "</br>")
                        .replaceAll('"}', "")
                        .replaceAll("\\n", "</br>")
                        .replaceAll("\n", "</br>")
                        .replaceAll("\\r", "")
                        .replaceAll("\r", "")
                    )}
                  </Typography.H5>
                </div>
              )}
            </div>
            {renderFooter()}
          </div>
        );
      }
    } else {
      return (
        <div
          className={`interaction-comment ${
            direction === "left" ? "blue-color" : " "
          } ${location ? "maps-width" : ""} comment-length-alternate `}
          id={`comentario-${id}`}
        >
          <div className="comment-information">{renderTitleComment()}</div>
          <div className="comment-content">
            {renderCommentAnswered()}
            {renderMaps()}
            {attachment?.path_arquivos && (
              <div className="comment-content-buttons">
                <div
                  className={`comment-content-buttons-attachment-list ${
                    attachment ? "column" : ""
                  }`}
                >
                  {renderFileComment()}
                </div>
              </div>
            )}
            <div className="comment-content__text">
              {/* let teste = isJson() */}
              <Typography.H5 color="gray-180">
                {ReactHtmlParser(
                  `<p>${unicodeToChar(textInteraction)}<p/>`
                    ?.replace(/(?:\*)([^*<\n]+)(?:\*)/g, "<strong>$1</strong>")
                    .replace(/\_(?=\w)/, "<em>")
                    .replace(/(?<=\w)\_/, "</em>")
                    .replaceAll("\n", "</br>")
                    .replaceAll('"}', "")
                    .replaceAll("\\n", "</br>")
                    .replaceAll("\n", "</br>")
                    .replaceAll("\\r", "")
                    .replaceAll("\r", "")
                )}
              </Typography.H5>
            </div>
          </div>
          {buttons && renderButtons()}
          {renderFooter()}
        </div>
      );
    }
  };

  return (
    <div
      className={`interaction-comment-container ${
        direction === "left" ? "reverse-row" : ""
      } `}
    >
      {renderInteractionComment()}

      <ImageProfile
        profile={{
          nome_usuario: formatEmoji(name) ?? "Não registrado",
        }}
        client={direction === "left" ? true : false}
        tooltip
        className="comment-list__profileImage"
        iconHistory={!name && "user"}
      />
    </div>
  );

  // return (
  //   <div className="interaction-comment-container">
  //     {direction === "left" && (
  //       <ImageProfile profile={{ nome_usuario: name }} tooltip={false} />
  //     )}
  //     <div className={`interaction-comment ${direction}`}>
  //       {type === "attachment" ? (
  //         <div
  //           className={`interaction-comment-attachment interaction-comment-attachment--${direction}`}
  //         >
  //           <FilePreview
  //             download={attachment ? true : false}
  //             type={attachment ? attachment.type : mimetype.template}
  //             name={attachment ? attachment.name : text}
  //             size={attachment ? attachment.size : size}
  //             user={name}
  //             link={attachment ? attachment.path_arquivos : text}
  //             view
  //             preview
  //           />
  //           <Typography.Paragraph
  //             className="interaction-comment-date"
  //             color="gray-200"
  //           >
  //             {date.split(" ")[1]}
  //           </Typography.Paragraph>
  //         </div>
  //       ) : (
  //         <div className="interaction-comment-text">
  //           <Typography.H6 color="gray-400">
  //             {ReactHtmlParser(
  //               interactionComment
  //                 ?.replace(/(?:\*)([^*<\n]+)(?:\*)/g, "<strong>$1</strong>")
  //                 .replace(/\_(?=\w)/, "<em>")
  //                 .replace(/(?<=\w)\_/, "</em>")
  //                 .replaceAll("\n", "</br>")
  //                 .replaceAll('"}', "")
  //                 .replaceAll("\\n", "</br>")
  //                 .replaceAll("\n", "</br>")
  //                 .replaceAll("\\r", "")
  //                 .replaceAll("\r", "")
  //             )}
  //           </Typography.H6>
  //           <Typography.Paragraph
  //             className="interaction-comment-date"
  //             color="gray-200"
  //           >
  //             {date.split(" ")[1]}
  //           </Typography.Paragraph>
  //         </div>
  //       )}
  //     </div>
  //     {direction === "right" && (
  //       <ImageProfile profile={{ nome_usuario: name }} tooltip={false} />
  //     )}
  //   </div>
  // );
}
