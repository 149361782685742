//GLOBAL - components from npm
import React from "react";

//STYLES
import "./assessment.scss";

//COMPONENTS
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as ExcellentIcon } from "./../../../assets/icons/Excellent.svg";
import { ReactComponent as GoodIcon } from "./../../../assets/icons/Good.svg";
import { ReactComponent as RegularIcon } from "./../../../assets/icons/Regular.svg";
import { ReactComponent as BadIcon } from "./../../../assets/icons/Bad.svg";
import { ReactComponent as TerribleIcon } from "./../../../assets/icons/Terrible.svg";
import { ReactComponent as ForbiddenIcon } from "./../../../assets/icons/Forbidden.svg";
import { ReactComponent as HappyIcon } from "./../../../assets/icons/NpsHappy.svg";
import { ReactComponent as SadIcon } from "./../../../assets/icons/NpsSad.svg";
import { ReactComponent as IndifferentIcon } from "./../../../assets/icons/NpsIndifferent.svg";

export default function Assessment({
  className = "",
  type, //'NPS' || 'CSAT'
  value, // 0 a 10 NPS || 0 A 5 CSAT
  onClick,
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  function renderIcon() {
    if (type === "NPS") {
      if (value >= 1 && value <= 6) {
        return (
          <div
            className={`assessment assessment--secondary-03-dark ${className} ${
              onClick ? "assessment--interactive" : ""
            }`}
            onClick={onClick}
          >
            <SadIcon className="assessment-icon" />
          </div>
        );
      } else if (value >= 7 && value <= 8) {
        return (
          <div
            className={`assessment assessment--secondary-03-dark ${className} ${
              onClick ? "assessment--interactive" : ""
            }`}
            onClick={onClick}
          >
            <IndifferentIcon className="assessment-icon" />
          </div>
        );
      } else if (value >= 9 && value <= 10) {
        return (
          <div
            className={`assessment assessment--secondary-03-dark ${className} ${
              onClick ? "assessment--interactive" : ""
            }`}
            onClick={onClick}
          >
            <HappyIcon className="assessment-icon" />
          </div>
        );
      } else {
        return (
          <div
            className={`assessment assessment--secondary-03-dark ${className} ${
              onClick ? "assessment--interactive" : ""
            }`}
            onClick={onClick}
          >
            <ForbiddenIcon className="assessment-icon" />
          </div>
        );
      }
    } else {
      switch (value) {
        case 1:
          return (
            <div
              className={`assessment assessment--secondary-03-dark ${className} ${
                onClick ? "assessment--interactive" : ""
              }`}
              onClick={onClick}
            >
              <TerribleIcon />
            </div>
          );
        case 2:
          return (
            <div
              className={`assessment assessment--secondary-03-dark ${className} ${
                onClick ? "assessment--interactive" : ""
              }`}
              onClick={onClick}
            >
              <BadIcon />
            </div>
          );
        case 3:
          return (
            <div
              className={`assessment assessment--green ${className} ${
                onClick ? "assessment--interactive" : ""
              }`}
              onClick={onClick}
            >
              <RegularIcon />
            </div>
          );

        case 4:
          return (
            <div
              className={`assessment assessment--yellow ${className} ${
                onClick ? "assessment--interactive" : ""
              }`}
              onClick={onClick}
            >
              <GoodIcon />
            </div>
          );

        case 5:
          return (
            <div
              className={`assessment assessment--primary-dark ${className} ${
                onClick ? "assessment--interactive" : ""
              }`}
              onClick={onClick}
            >
              <ExcellentIcon />
            </div>
          );

        default:
          return (
            <div
              className={`assessment assessment--primary-dark ${className} ${
                onClick ? "assessment--interactive" : ""
              } assessment--disabled`}
              onClick={onClick}
            >
              <ForbiddenIcon />
            </div>
          );
      }
    }
  }

  //USE EFFECTS

  return (
    <>
      <Tippy content={value ? `Nota ${value}` : "Não informado"}>
        {renderIcon()}
      </Tippy>
    </>
  );
}
