//GLOBAL - components from npm
import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";

//STYLES
import "./case-comments.scss";

//COMPONENTS
import { CommentList } from "../../../../components/data-display";
import { Loading } from "./../../../../components/feedback";

//SERVICES - api, conectors...
import * as APIComments from "../../../../services/api/comments";
import * as APIFiles from "../../../../utils/files";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...

export default function CaseComments({
  id,
  handleInteractionSaveImmediate,
  updateAgent,
  permissions,
}) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.userData);
  const agent = {
    id: user.userId,
    name: user.userName,
  };

  //STATES
  const [commentsList, setCommentsList] = useState([]);
  const [commentsFormatted, setCommentsFormatted] = useState([]);
  const [loading, setLoading] = useState(true);

  //REDUX - Selectors

  //FUNCTIONS

  // Função que faz a tela dos comentários ficar em loading enquanto chama todas as outras funções que listam as informações que aparecem nela.
  const getCommentInfo = async () => {
    setLoading(true);
    await getCommentList();
    setLoading(false);
  };

  //Função que busca a lista de comentários na API e modifica o array através da função fetchFile para ter acesso as informações dos arquivos.
  const getCommentList = async () => {
    const response = await APIComments.listCommentsCase(id, token);

    const arrayComments = await fetchFile(response.data);

    setCommentsList(arrayComments);
  };

  //Função que recebe as informações do comentário e envia esse comentário para a API.
  const postComment = async (text, files, responseComment, audio) => {
    try {
      let idType = 0;

      if (text) {
        idType = 1;
      } else if (audio) {
        idType = 3;
      } else {
        idType = 2;
      }

      const data = {
        desc_ticket_comentario: text || "",
        id_ticket: id,
        id_mimetype: 2,
        id_tipo_comentario: idType,
        id_ticket_comentario_parente: responseComment?.id_comentario || null,
      };

      const form = new FormData();
      form.append("data", JSON.stringify(data));

      for (const file of files) {
        form.append("data", file.file);
      }

      console.log("formas", form.getAll("data"));

      const response = await APIComments.createCommentCase(form, token);
      handleInteractionSaveImmediate(id);
      updateAgent(agent);

      await getCommentList();
    } catch (error) {
      console.log(error);
    }
  };

  //Função que recebe as informações de atualização do comentário e envia esse comentário para a API.
  const updateComment = async (text, files, deleteFiles, comment) => {
    try {
      let idType = 0;
      if (text) {
        idType = 1;
      } else {
        idType = 2;
      }

      const data = {
        desc_ticket_comentario: text,
        id_ticket: id,
        id_mimetype: 2,
        id_tipo_comentario: idType,
        id_ticket_comentario: comment.id_comentario,
        id_ticket_comentario_parente:
          comment.commentParent.id_comentario || null,
        filesExclused: deleteFiles,
      };

      const form = new FormData();
      form.append("data", JSON.stringify(data));

      for (const file of files) {
        form.append("data", file.file);
      }

      const response = await APIComments.updateCommentCase(form, token);

      updateAgent(agent);

      await getCommentList();
    } catch (error) {
      console.log(error);
    }
  };

  //Função que através do id do comentário, envia a solicitação para deletar este comentário.
  const deleteComment = async (idComment) => {
    try {
      const response = await APIComments.deleteCommentCase(idComment, token);

      updateAgent(agent);

      await getCommentList();

      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  //Função que recebe um array com a lista de comentários e verifica em quais elementos se tem anexos, pega os elementos
  //que possuem anexos e chama a função "handleFiles" que retorna as informações desse anexo, como nome, tamanho, extensão...,
  //adiciona essa informação no elemento e retorna o array com essa informção dos anexos acrescentada.
  const fetchFile = async (arrayComments) => {
    for (const comment of arrayComments) {
      const files = [];
      if (comment.filesAssociated.length > 0) {
        for (const file of comment.filesAssociated) {
          const response = await APIFiles.handleFiles(
            file.path_ticket_arquivos
          );
          const fileObject = {
            file: {
              name: response.name,
              size: response.size,
              type: response.typeMime,
              path_arquivos: file.path_ticket_arquivos,
              extension: response.type,
              id: file.id_ticket_comentario_arquivo,
            },
          };

          files.push(fileObject);
        }
      }
      comment.files = files;
    }
    return arrayComments;
  };

  //USE EFFECTS
  useEffect(() => {
    getCommentInfo();
  }, []);

  //useEffect que pega a lista de comentários e transforma no padrão que o componente "CommentList" aceita.
  useEffect(() => {
    const arrayComment = cloneDeep(commentsList);
    const newArray = [];

    for (const item of arrayComment) {
      const newObject = {
        id_comentario: item.id_ticket_comentario,
        desc_comentario: item.desc_ticket_comentario,
        data_insercao: item.data_insercao,
        data_exclusao: item.data_exclusao,
        id: item.id_ticket,
        //path_comentarios: item.path_tickets_comentarios,
        id_mimetype: item.id_mimetype,
        id_tipo_comentario: item.id_tipo_comentario,
        usuario_insercao: item.usuario_insercao,
        nome_usuario: item.nome_usuario,
        filesAssociated: [],
        files: item.files,
        commentParent: {
          id_comentario: item.commentParent[0]?.id_ticket_comentario,
          desc_comentario: item.commentParent[0]?.desc_ticket_comentario,
          id_tipo_comentario: item.commentParent[0]?.id_tipo_comentario,
          usuario_insercao: item.commentParent[0]?.usuario_insercao,
          nome_usuario: item.commentParent[0]?.nome_usuario,
          filesAssociated: [],
        },
      };

      if (item.commentParent[0]?.filesAssociated) {
        for (const subitem of item.commentParent[0].filesAssociated) {
          const object = {
            id_comentario_arquivo: subitem.id_ticket_comentario_arquivo,
            path_arquivos: subitem.path_ticket_arquivos,
            data_insercao: subitem.data_insercao,
            data_exclusao: subitem.data_exclusao,
            id_mimetype: subitem.id_mimetype,
            id_comentario: subitem.id_ticket_comentario,
          };
          newObject.commentParent.filesAssociated.push(object);
        }
      }

      for (const subitem of item.filesAssociated) {
        const object = {
          id_comentario_arquivo: subitem.id_ticket_comentario_arquivo,
          path_arquivos: subitem.path_ticket_arquivos,
          data_insercao: subitem.data_insercao,
          data_exclusao: subitem.data_exclusao,
          id_mimetype: subitem.id_mimetype,
          id_comentario: subitem.id_ticket_comentario,
        };
        newObject.filesAssociated.push(object);
      }
      newArray.push(newObject);
    }

    setCommentsFormatted(newArray);
  }, [commentsList]);

  return (
    <>
      {loading ? (
        <Loading.Paperplane />
      ) : (
        <CommentList
          commentsList={commentsFormatted}
          onAdd={(text, files, response, audio) =>
            postComment(text, files, response, audio)
          }
          onUpdate={(text, files, deleteFiles, comment) =>
            updateComment(text, files, deleteFiles, comment)
          }
          onDelete={(id) => deleteComment(id)}
          permissionUsers={permissions}
        />
      )}
    </>
  );
}
