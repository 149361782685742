//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import { Loading, Modal } from "../../../components/feedback";
import { Button, Typography } from "../../../components/general";
import { Table } from "../../../components/data-display";
import { Input } from "../../../components/data-entry";
import { Toast } from "../../../utils/toast";

//SERVICES - api, conectors...
import * as APIStaggered from "../../../services/api/staggered";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowSwapIcon } from "./../../../assets/icons/arrow-swap.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search-normal.svg";
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-3.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";

//STYLES
import "./staggered.scss";

export default function Staggered({ returnToRecord }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState(false);
    const [emptyState, setEmptyState] = useState(false);
    const [isOpenModalCreateStaggered, setIsOpenModalCreateStaggered] = useState(false);
    const [updatedStaggered, setUpdatedStaggered] = useState(false);
    const [isOpenModalUpdateStaggered, setIsOpenModalUpdateStaggered] = useState({ isOpen: false, staggeredId: null });
    const [isOpenModalDeleteStaggered, setIsOpenModalDeleteStaggered] = useState({ isOpen: false, staggeredId: null });
    const [searchData, setSearchData] = useState("");
    const [staggereds, setStaggereds] = useState([]);
    const [filteredStaggereds, setFilteredStaggereds] = useState([]);

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleSearchStaggered(event) {
        const inputValue = event;

        setSearchData(inputValue);

        if (event === "") {
            setFiltered(false);
            setFilteredStaggereds([]);
        }
    };

    function handleTableDataLookup() {
        const searchTerm = searchData.toLowerCase();

        const filteredData = staggereds.filter(
            (data) =>
            (data.descricao != null &&
                data.descricao !== "" &&
                data.descricao.toLowerCase().includes(searchTerm))
        );

        setFilteredStaggereds(filteredData);
        setFiltered(true);
    };

    function refactorStaggeredListing(data) {
        let refactoredData = [];

        if (!data) throw new Error('Error when fetching data!');

        data.map((item) => {
            const newObject = {
                name: {
                    data: item.descricao,
                    config: {
                        className: "table-staggered__name"
                    },
                },
                actions: {
                    data: (
                        <div className="actions-staggered">
                            <button
                                className="actions-staggered__edit"
                                onClick={() => setIsOpenModalUpdateStaggered({
                                    isOpen: true,
                                    staggeredId: item.id_escalonamento
                                })}
                            >
                                <EditIcon />
                            </button>

                            <button
                                className="actions-staggered__delete"
                                onClick={() => setIsOpenModalDeleteStaggered({
                                    isOpen: true,
                                    staggeredId: item.id_escalonamento
                                })}
                            >
                                <TrashIcon />
                            </button>
                        </div>
                    ),
                    config: {
                        className: "action-options"
                    }
                },
            };

            refactoredData.push(newObject);

            return refactoredData;
        });

        return refactoredData;
    }

    function renderTableContent() {
        switch (loading) {
            case true:
                return (
                    <div className="table-card-content__loading">
                        <Loading.Paperplane />
                    </div>
                )

            case false:
                if (staggereds.length > 0) {
                    return (
                        <>
                            {searchData && filtered && filteredStaggereds.length < 1 ? (
                                <div className="table-card-content__empty-state">
                                    <div className="table-card-content__empty-state__icon">
                                        <ArrowSwapIcon />
                                    </div>

                                    <div className="table-card-content__empty-state__text">
                                        <Typography.H4 weight="bold" color="blue-selected-4">
                                            Ops! Sem cadastrados.
                                        </Typography.H4>

                                        <Typography.H5 color="gray-180" className="text-center">
                                            Clique no botão <span className="text-bold">"Adicionar escalonado"</span>. <br />
                                            Preencha os dados e salve.
                                        </Typography.H5>
                                    </div>
                                </div>
                            ) : (
                                <div className="table-card-content__table-container">
                                    <Table
                                        fixedHeader
                                        className="table-staggered"
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        columns={tableColumns}
                                        data={searchData && filtered ? staggeredFiltered : staggeredData}
                                    />
                                </div>
                            )}
                        </>
                    )
                } else {
                    return (
                        <div className="table-card-content__empty-state">
                            <div className="table-card-content__empty-state__icon">
                                <ArrowSwapIcon />
                            </div>

                            <div className="table-card-content__empty-state__text">
                                <Typography.H4 weight="bold" color="blue-selected-4">
                                    Ops! Sem cadastrados.
                                </Typography.H4>

                                <Typography.H5 color="gray-180" className="text-center">
                                    Clique no botão <span className="text-bold">"Adicionar escalonado"</span>. <br />
                                    Preencha os dados e salve.
                                </Typography.H5>
                            </div>
                        </div>
                    )
                }

            default:
                break;
        }
    };

    async function getListStaggered() {
        setLoading(true);

        const response = await APIStaggered.getListStaggered(token);

        if (response.status === 200) {
            setStaggereds(response.data);
        } else {
            Toast(
                "Não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            )
        }

        setLoading(false);
    };

    //USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                id: "name",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            }
        ]
    );

    const staggeredData = useMemo(() => {
        if (staggereds) {
            const refactoredData = refactorStaggeredListing(staggereds);
            return refactoredData;
        }
    }, [staggereds]);

    const staggeredFiltered = useMemo(() => {
        if (filteredStaggereds) {
            const refactoredData = refactorStaggeredListing(filteredStaggereds);
            return refactoredData;
        }
    }, [filteredStaggereds]);

    //USE EFFECTS
    useEffect(() => {
        getListStaggered();
    }, []);

    useEffect(() => {
        if (!searchData || searchData.trim() === "") {
            setFiltered(false)
            setFilteredStaggereds([])
        }
    }, []);

    useEffect(() => {
        if (updatedStaggered) {
            handleTableDataLookup()
        }
    }, [updatedStaggered, staggereds]);

    return (
        <>
            <main className="page-staggered">
                <header className="page-staggered__header">
                    <div className="page-staggered__header__title">
                        <ArrowLefIcon onClick={returnToRecord} />

                        <Typography.H2 color="gray-180" weight="bold">
                            Cadastro de escalonado
                        </Typography.H2>
                    </div>

                    <div>
                        <Button
                            className=""
                            onClick={() => setIsOpenModalCreateStaggered(true)}
                        >
                            <Typography.H4 weight="bold" color="white">
                                Adicionar escalonado
                            </Typography.H4>
                        </Button>
                    </div>
                </header>

                <div className="table-card">
                    <div className="table-card-content">
                        <Input
                            type="text"
                            width="432px"
                            placeholder="Pesquisar"
                            value={searchData}
                            onChange={handleSearchStaggered}
                            onKeyPress={(event) =>
                                (event.code === "Enter" || event.code === "NumpadEnter") &&
                                handleTableDataLookup()
                            }
                            rightIcon={
                                <button onClick={handleTableDataLookup}>
                                    <SearchIcon />
                                </button>
                            }
                        />

                        {renderTableContent()}
                    </div>
                </div>
            </main>

            <Modal.CreateStaggered
                isOpen={isOpenModalCreateStaggered}
                onClose={(event) => setIsOpenModalCreateStaggered(event)}
                onCreate={getListStaggered}
            />

            <Modal.UpdateStaggered
                isOpen={isOpenModalUpdateStaggered.isOpen}
                onClose={(event) => setIsOpenModalUpdateStaggered({ isOpen: event })}
                staggeredId={isOpenModalUpdateStaggered.staggeredId}
                detailedStaggered={staggereds}
                setUpdatedStaggered={setUpdatedStaggered}
                onUpdate={getListStaggered}
            />

            <Modal.DeleteStaggered
                isOpen={isOpenModalDeleteStaggered.isOpen}
                onClose={(event) => setIsOpenModalDeleteStaggered({ isOpen: event })}
                staggereds={staggereds}
                staggeredId={isOpenModalDeleteStaggered.staggeredId}
                handleTableDataLookup={handleTableDataLookup}
                onDelete={getListStaggered}
            />
        </>
    )
}
