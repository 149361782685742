import urlIngWeb from './env'

export function handleFiles(fileUrl) {
  const newUrl = urlIngWeb+fileUrl;
  return new Promise(async (resolve, reject) => {
      await fetch(newUrl, {
          method: 'GET',
          
      })
          .then(async (response) => {
              if (response.ok) {
                  function bytesToMega(value) {
                      if (value <= 1000000) {
                          return Math.round(value / 1000) + 'kb'
                      } else {
                          return Math.round(value / 1000 / 1024) + 'mb'
                      }
                  }
                  const fileInfo = {
                      name: response.url
                          .split('/')
                          .pop(),
                          // .replace(/\.[^/.]+$/, ''),
                      size: response.headers.get('Content-Length'),
                      type: response.url.split('/').pop().split('.').pop(),
                      typeMime: response.headers.get('Content-Type'),
                  }

                  resolve(fileInfo)
              } else {
                  reject()
              }
          })
          .catch((error) => {
              console.log('FETCH FILE ERROR:', error)
              reject(error)
          })
  })
}