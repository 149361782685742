import React, { useState } from "react";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import { Typography } from "../../general";
import { BarChart } from "../../data-graphics";
import Balloon from "../Balloon";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as FileSearching } from "../../../assets/icons/File-searching.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/ArrowDownTwo.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/ArrowUpTwo.svg";

export default function StatusCard({ card, darkMode }) {
    //GENERAL
    const activityStyles = {
        minHeight: "570px"
    }

    const activityBoxStyles = {
        height: "350px"
    }

    const statusFromCard = card.data.map(item => ({
        title: item.label,
        color: item.color,
    }));

    const captionPerPage = 6;

    //STATES
    const [page, setPage] = useState(0);

    //REDUX - Selectors

    //FUNCTIONS
    const countPagination =
        parseInt(statusFromCard.length / captionPerPage) +
        (statusFromCard.length % captionPerPage !== 0 ? 1 : 0);

    return (
        <div
            className="card-dashboard__activity"
            style={activityStyles}
        >
            <Typography.H4 color={darkMode ? "white" : "gray-180"} weight={700}>
                {card.title}
            </Typography.H4>

            <div
                className="card-dashboard__activity-box"
                style={activityBoxStyles}
            >
                {card.emptySpace ? (
                    <div className="card-dashboard-emptySpace">
                        <FileSearching width={120} height={120} />
                        <Typography.H5 color={darkMode ? "white" : "gray-180"}>
                            Oops! A sua pesquisa não gerou resultados.
                        </Typography.H5>
                    </div>
                ) : (
                    <BarChart
                        isTicketSizeZero
                        colorsEventChange
                        padding={0.60}
                        darkMode={darkMode}
                        data={card.data}
                        keys={['casesQuantity']}
                    />
                )}
            </div>

            {!card.emptySpace && (
                <div className="card-dashboard__status-subtitle-container">
                    <div className="card-dashboard__status-subtitle-container__boxes">
                        {
                            statusFromCard
                                .slice(page * captionPerPage, page * captionPerPage + captionPerPage)
                                .map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="subtitle-boxe"
                                        >
                                            <Balloon color={item.color} />

                                            <Typography.H5
                                                weight={"bold"}
                                                color={darkMode ? "white" : "gray-180"}
                                            >
                                                {item.title}
                                            </Typography.H5>
                                        </div>
                                    )
                                })
                        }
                    </div>

                    <div className="control-container">
                        <ArrowUp
                            className={page === 0 ? "control-container__icon" : ""}
                            onClick={() =>
                                page === 0 ? null : setPage((value) => value - 1)
                            }
                        />

                        <Typography.H5 color="blue-selected-4">
                            {" "}
                            {page + 1}/{countPagination}{" "}
                        </Typography.H5>

                        <ArrowDown
                            className={page + 1 === countPagination ? "control-container__icon" : ""}
                            onClick={() =>
                                page + 1 === countPagination ? null :
                                    setPage((value) => value + 1)
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
