//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// UTILS

//COMPONENTS
import { FieldContainer } from "../../../components/data-display";
import { Button, Typography } from "../../../components/general";
import { Input, SelectSearchItems } from "../../../components/data-entry";
import { Loading, Modal } from "../../../components/feedback";
import { Toast } from "./../../../utils/toast";
import { onKeyPress } from "../../../utils/onKeyPress";
import PersonPhysical from "./PersonPhysical/PersonPhysical";
import PersonJuridic from "./PersonJuridic/PersonJuridic";

//SERVICES - api, conectors...
import * as APICompany from "./../../../services/api/company";
import * as APIClient from "./../../../services/api/client";

//GLOBAL STATE - redux, env...
import { setFilter } from "../../../store/filters";

//ASSETS - icons, images...
import { ReactComponent as CleanIcon } from "../../../assets/icons/Clean.svg";
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";

//STYLES
import "./contact.scss";

export default function Contact({ returnToRecord }) {
    //GENERAL
    const dispatch = useDispatch();
    const location = useLocation();

    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const filtersContacts = useSelector((state) => state.filters.contacts);
    const filtersEnterprises = useSelector((state) => state.filters.enterprises);

    // STATES
    const [person, setPerson] = useState("physical");
    const [pageStatus, setPageStatus] = useState("requesting");
    const [companyPageStatus, setCompanyPageStatus] = useState("requesting");
    const [name, setName] = useState(filtersContacts.name);
    const [email, setEmail] = useState(filtersContacts.email);
    const [telephone, setTelephone] = useState(filtersContacts.telephone);
    const [empresa, setEmpresa] = useState(filtersContacts.id_enterprise);
    const [externcode, setExterncode] = useState(filtersContacts.externcode);
    const [company, setCompany] = useState(filtersEnterprises.nome);
    const [cnpj, setCnpj] = useState(filtersEnterprises.cnpj);
    const [controlActiveCompany, setControlActiveCompany] = useState(false);
    const [isOpenModalAddCompany, setIsOpenModalAddCompany] = useState(false);
    const [isOpenModalAddContact, setIsOpenModalAddContact] = useState(false);
    const [controlActiveContact, setControlActiveContact] = useState(false);
    const [onBlurEventCnpj, setOnBlurEventCnpj] = useState(false);
    const [onBlurEventEmail, setOnBlurEventEmail] = useState(false);
    const [loadingEnterprises, setLoadingEnterprises] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingPagePhysycal, setLoadingPagePhysycal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [companyIsLoading, setCompanyIsLoading] = useState(true);
    const [contactsData, setContactsData] = useState([]);
    const [companysData, setCompanysData] = useState([]);
    const [enterprises, setEnterprises] = useState([]);
    const [enterprisesLimit, setEnterprisesLimit] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // quantidade de items por página
    const [companyTotalCount, setComapanyTotalCount] = useState(0);
    const [companyCurrentPage, setCompanyCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const [companysLinesLimit, setcompanysLinesLimit] = useState(10)

    // FUNCTIONS
    async function updateContact(page, title, limit) {
        setPageStatus("requesting");
        setIsLoading(true);

        const response = await APIClient.all(
            token,
            page,
            title,
            limit,
            filtersContacts
        );

        setTotalCount(Number(response.data[0]?.qtde_registros))
        setIsLoading(false);

        if (response.status === 200) {
            setContactsData(response.data);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }
    };

    async function updateCompanys(page, title, limit) {
        setCompanyPageStatus("requesting");
        setCompanyIsLoading(true);

        const response = await APICompany.newListAllCompanysUsers(
            token,
            page,
            title,
            limit,
            filtersEnterprises
        );

        setComapanyTotalCount(Number(response.data[0]?.contador))
        setCompanyIsLoading(false);

        if (response.status === 200) {
            const updateCompanysData = response.data.map(item => ({
                ...item,
                codigo_empresa: item.codigo_empresa ?? ""
            }))

            setCompanysData(updateCompanysData);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }
    };

    async function getListOfCompaniesInIndividuals(page, title, limit) {
        setLoadingEnterprises(true);

        const response = await APICompany.newListAllCompanysUsers(
            token,
            page,
            title,
            limit,
            filtersEnterprises
        );
        const data = [...response.data];
        const updatedData = [];

        data.forEach((company) => {
            const updatedCompany = {
                id: company.id_empresa,
                label: company.nome
            };

            updatedData.push(updatedCompany);
        });

        setLoadingEnterprises(false);
        setEnterprises(updatedData);
    };

    function onlyNumber(evt) {
        var theEvent = evt || window.event;
        var key = theEvent?.keyCode || theEvent?.which;
        key = String.fromCharCode(key);

        var regex = /^[0-9.]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    };

    function errorEventInTheEmailAndCpnj() {
        if (email && !/\S+@\S+\.\S+/.test(email)) {
            setOnBlurEventEmail(true);

            Toast("Erro", "Digite um e-mail válido", "error");
        } else {
            setOnBlurEventEmail(false);
        }

        if (cnpj && cnpj?.length < 14) {
            setOnBlurEventCnpj(true);

            Toast("Erro", "Digite cnpj válido", "error");
        } else {
            setOnBlurEventCnpj(false);
        }
    };

    function loadMoreEnterprises() {
        setEnterprisesLimit(enterprisesLimit + 50)
    };

    // ############################# CONTATOS #############################
    const getContactsFilters = () => {
        dispatch(setFilter({ to: "contacts", type: "active", value: true }));
        dispatch(setFilter({ to: "contacts", type: "name", value: name }));
        dispatch(setFilter({ to: "contacts", type: "email", value: email }));
        dispatch(setFilter({ to: "contacts", type: "telephone", value: telephone }));
        dispatch(setFilter({ to: "contacts", type: "id_enterprise", value: empresa }));
        dispatch(setFilter({ to: "contacts", type: "externcode", value: externcode }));
    };

    const cleanFiltersContacts = () => {
        dispatch(setFilter({ to: "contacts", type: "active", value: false }));
        dispatch(setFilter({ to: "contacts", type: "name", value: "" }));
        dispatch(setFilter({ to: "contacts", type: "email", value: "" }));
        dispatch(setFilter({ to: "contacts", type: "telephone", value: "" }));
        dispatch(setFilter({ to: "contacts", type: "id_enterprise", value: "" }));
        dispatch(setFilter({ to: "contacts", type: "externcode", value: "" }));
    };

    const cleanContactFields = () => {
        cleanFiltersContacts();
        setName("");
        setEmail("");
        setTelephone("");
        setEmpresa("");
        setExterncode("");
    };

    // ############################# EMPRESAS #############################
    const getCompanysFilters = () => {
        dispatch(setFilter({ to: "enterprises", type: "active", value: true }));
        dispatch(setFilter({ to: "enterprises", type: "nome", value: company }));
        dispatch(setFilter({ to: "enterprises", type: "cnpj", value: cnpj }));
    };

    const cleanFiltersCompany = () => {
        dispatch(setFilter({ to: "enterprises", type: "active", value: false }));
        dispatch(setFilter({ to: "enterprises", type: "nome", value: "" }));
        dispatch(setFilter({ to: "enterprises", type: "cnpj", value: "" }));
    };

    const cleanCompanyFields = () => {
        cleanFiltersCompany();
        setCompany("");
        setCnpj("");
    };

    // USE EFFECTS
    useEffect(() => {
        updateContact(currentPage, null, limit);
        updateCompanys(companyCurrentPage, null, companysLinesLimit)
    }, [currentPage, companyCurrentPage, limit, companysLinesLimit]);

    useEffect(() => {
        if (currentPage > 1) setPageStatus("awaiting");
        else if (currentPage === 1) setPageStatus("requesting");

        if (companyCurrentPage > 1) setCompanyPageStatus("awaiting");
        else if (companyCurrentPage === 1) setCompanyPageStatus("requesting");
    }, [currentPage, companyCurrentPage]);

    useEffect(() => {
        if (limit < 10 || limit > 10) setPageStatus("awaiting");
        else if (limit === 10) setPageStatus("requesting");

        if (companysLinesLimit < 10 || companysLinesLimit > 10) setCompanyPageStatus("awaiting");
        else if (companysLinesLimit === 10) setCompanyPageStatus("requesting")
    }, [limit, companysLinesLimit])

    useEffect(() => {
        if (person === "juridic" && loadingPage) {
            const content = setTimeout(() => {
                setLoadingPage(false);
            }, 3000);

            return () => clearTimeout(content);
        } else if (person === "physical") {
            const content = setTimeout(() => {
                setLoadingPagePhysycal(false);
            }, 3000);

            return () => clearTimeout(content);
        }
    }, [person, loadingPage, loadingPagePhysycal]);

    // ############################# EMPRESA #############################
    useEffect(() => {
        if (company.length === 0 && cnpj.length === 0) {
            setControlActiveCompany(false);
        } else {
            setControlActiveCompany(true);
        }
    }, [company.length, cnpj.length, controlActiveCompany, dispatch]);

    useEffect(() => {
        setCompany(filtersEnterprises.nome);
        setCnpj(filtersEnterprises.cnpj);
    }, [filtersEnterprises]);

    useEffect(() => {
        if (
            filtersEnterprises.nome.length === 0 &&
            filtersEnterprises.cnpj.length === 0
        ) {
            dispatch(setFilter({ to: "enterprises", type: "active", value: false }));
        }
    }, [filtersEnterprises]);

    useEffect(() => {
        if (company.length === 0 && cnpj.length === 0) cleanCompanyFields();
    }, [company.length, cnpj.length]);

    // ############################# CONTATO #############################
    useEffect(() => {
        getListOfCompaniesInIndividuals(null, null, enterprisesLimit);
    }, [enterprisesLimit]);

    useEffect(() => {
        if (
            name.length === 0 &&
            email.length === 0 &&
            telephone.length === 0 &&
            empresa.length === 0 &&
            externcode.length === 0
        ) {
            setControlActiveContact(false);
        } else {
            setControlActiveContact(true);
        }
    }, [
        name.length,
        email.length,
        telephone.length,
        empresa.length,
        externcode.length,
        controlActiveContact,
        dispatch,
    ]);

    useEffect(() => {
        setName(filtersContacts.name);
        setEmail(filtersContacts.email);
        setTelephone(filtersContacts.telephone);
        setEmpresa(filtersContacts.id_enterprise);
        setExterncode(filtersContacts.externcode);
    }, [filtersContacts]);

    useEffect(() => {
        if (
            name.length === 0 &&
            email.length === 0 &&
            telephone.length === 0 &&
            empresa.length === 0 &&
            externcode.length === 0
        ) {
            cleanContactFields();
        }
    }, [
        name.length,
        email.length,
        telephone.length,
        empresa.length,
        externcode.length,
    ]);

    useEffect(() => {
        contactsData?.forEach(item => {
            item = Object.assign(item, {
                telephones: []
            });

            var phones = item.telefone_id?.split("¿");

            var myObject = [];

            phones?.forEach((data) => {
                let aux = data?.split("§");

                myObject.push({
                    phone: aux[0] || "",
                    id_phone: aux[1] || "",
                    id_tipo_phone: aux[2] || null,
                });
            });

            item.telephones = myObject;
        })
    }, [contactsData]);

    return (
        <>
            <main
                className={`page-contact ${location.pathname === "/contato" ? "contact-modify" : ""}`}
            >
                <header className="page-contact-header">
                    <div className="page-contact-header__title">
                        <ArrowLefIcon onClick={returnToRecord} />

                        <div className="page-contact-title">
                            <Typography.H2 color="gray-180" weight="bold">
                                Contato
                            </Typography.H2>
                        </div>
                    </div>

                    <div className="page-contact-header__options">
                        <div className="add-contact-company">
                            <Button
                                border
                                className="btn-add-company"
                                type="fourthiary"
                                onClick={() => setIsOpenModalAddCompany(true)}
                            >
                                <Typography.H4 weight="bold" color={"blue-selected-4"}>
                                    Adicionar empresa
                                </Typography.H4>
                            </Button>

                            <Button
                                className="btn-add-contact"
                                iconFillType="stroke"
                                onClick={() => setIsOpenModalAddContact(true)}
                            >
                                <Typography.H4 weight="bold" color={"white"}>
                                    Adicionar contato
                                </Typography.H4>
                            </Button>
                        </div>
                    </div>
                </header>

                <div className="page-contact-body">
                    <section className="container-sections-contact">
                        <div className="panel-info">
                            <div className="panel-info__options-container">
                                <div className="person-container">
                                    <div
                                        className="person__physical"
                                        onClick={(event) => {
                                            setPerson("physical")
                                            setLoadingPagePhysycal(true)
                                        }}
                                    >
                                        <div
                                            className={person === "physical" ? "person__text active" : "person__text"}
                                        >
                                            Pessoa física
                                        </div>
                                    </div>

                                    <div className={person === "physical" ? "underline active" : ""}></div>
                                </div>

                                <div className="person-container">
                                    <div
                                        className="person__juridic"
                                        onClick={(event) => {
                                            setPerson("juridic")
                                            setLoadingPage(true)
                                        }}
                                    >
                                        <div
                                            className={person === "juridic" ? "person__text active" : "person__text"}
                                        >
                                            Pessoa jurídica
                                        </div>
                                    </div>

                                    <div className={person === "juridic" ? "underline active" : ""}></div>
                                </div>
                            </div>

                            <div className="panel-info__border"></div>
                        </div>

                        <p className="container-sections-contact__text-information">
                            Preencha os campos para buscar{" "}
                            {person === "physical" ? "um contato." : "uma empresa."}
                        </p>

                        <div className="panel-filters">
                            {person === "physical" ? (
                                <>
                                    <FieldContainer title="Nome">
                                        <Input
                                            type="text"
                                            placeholder="Nome do contato"
                                            onChange={(event) => setName(event)}
                                            value={name}
                                            onKeyPress={(e) => onKeyPress(e)}
                                        />
                                    </FieldContainer>
                                    <FieldContainer title="E-mail">
                                        <Input
                                            type="text"
                                            placeholder="email@hotmail.com"
                                            value={email}
                                            onBlur={errorEventInTheEmailAndCpnj}
                                            onChange={(event) => setEmail(event)}
                                            onKeyPress={(e) => onKeyPress(e)}
                                            error={
                                                onBlurEventEmail
                                                    ? email && !/\S+@\S+\.\S+/.test(email)
                                                    : false
                                            }
                                        />
                                    </FieldContainer>
                                    <FieldContainer title="Telefone">
                                        <Input
                                            type="numberMask"
                                            placeholder="(XX) X XXXX-XXXX"
                                            mask={"(99) 9 9999-9999"}
                                            onChange={(event) => setTelephone(event)}
                                            value={telephone}
                                            onKeyPress={(e) => {
                                                onKeyPress(e);
                                                onlyNumber(e);
                                            }}
                                        />
                                    </FieldContainer>
                                    <FieldContainer title="Código externo">
                                        <Input
                                            type="text"
                                            placeholder="Código externo"
                                            onChange={(event) => setExterncode(event)}
                                            value={externcode}
                                        />
                                    </FieldContainer>
                                    <FieldContainer title="Empresa">
                                        <SelectSearchItems
                                            upward
                                            loadOnDemand
                                            placeholder="Selecione a empresa"
                                            value={empresa}
                                            options={enterprises}
                                            loadingOptions={loadingEnterprises}
                                            optionsLimit={enterprisesLimit}
                                            loadMoreOptions={loadMoreEnterprises}
                                            onSelect={(enterprise) => {
                                                setEmpresa(enterprise.id)
                                            }}
                                        />
                                    </FieldContainer>
                                </>
                            ) : (
                                <>
                                    <FieldContainer title="Empresa">
                                        <Input
                                            type="text"
                                            placeholder="Nome da empresa"
                                            onChange={(event) => setCompany(event)}
                                            value={company}
                                            onKeyPress={(e) => onKeyPress(e)}
                                        />
                                    </FieldContainer>
                                    <FieldContainer title="CNPJ">
                                        <Input
                                            type="numberMask"
                                            placeholder="XX.XXX.XXX/XXXX-XX"
                                            mask={"99.999.999/9999-99"}
                                            onChange={(event) => setCnpj(event)}
                                            value={cnpj}
                                            onBlur={errorEventInTheEmailAndCpnj}
                                            onKeyPress={(event) => onlyNumber(event)}
                                            error={
                                                onBlurEventCnpj ? cnpj && cnpj?.length < 14 : false
                                            }
                                        />
                                    </FieldContainer>
                                </>
                            )}
                        </div>

                        <footer className="container-sections-contact__footer">
                            <div className="division"></div>

                            <div className="buttons-container">
                                {person === "physical" ? (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "center",
                                                cursor: `${!controlActiveContact ? "default" : "pointer"}`,
                                            }}
                                            onClick={controlActiveContact ? cleanContactFields : null}
                                        >
                                            <CleanIcon
                                                className={`${!controlActiveContact ? "disabled-icon" : "clean-icon"}`}
                                            />
                                            <div>
                                                <Typography.H4
                                                    weight="400"
                                                    color={`${!controlActiveContact ? "gray-125" : "red-2"}`}
                                                >
                                                    Limpar Filtros
                                                </Typography.H4>
                                            </div>
                                        </div>
                                        <Button
                                            className={`${!controlActiveContact ? "disabled-aplyfilters" : "buttons-aplyfilters"}`}
                                            onClick={getContactsFilters}
                                        >
                                            Aplicar Filtros
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "center",
                                                cursor: `${!controlActiveCompany ? "default" : "pointer"}`,
                                            }}
                                            onClick={controlActiveCompany ? cleanCompanyFields : null}
                                        >
                                            <CleanIcon
                                                className={`${!controlActiveCompany ? "disabled-icon" : "clean-icon"}`}
                                            />
                                            <div>
                                                <Typography.H4
                                                    weight="400"
                                                    color={`${!controlActiveCompany ? "gray-125" : "red-2"}`}
                                                >
                                                    Limpar Filtros
                                                </Typography.H4>
                                            </div>
                                        </div>
                                        <Button
                                            className={`${!controlActiveCompany ? "disabled-aplyfilters" : "buttons-aplyfilters"}`}
                                            onClick={getCompanysFilters}
                                        >
                                            Aplicar Filtros
                                        </Button>
                                    </>
                                )}
                            </div>
                        </footer>
                    </section>

                    {person === "physical" ? (
                        <>
                            {person === "physical" && loadingPagePhysycal ? (
                                <section className="contact-content-main">
                                    <Loading.Paperplane className="loading-contact-list" />
                                </section>
                            ) : (
                                <PersonPhysical
                                    pageStatus={pageStatus}
                                    filtersContacts={filtersContacts}
                                    contactsData={contactsData}
                                    loading={isLoading}
                                    currentPage={currentPage}
                                    count={totalCount}
                                    linesLimit={limit}
                                    updateContact={updateContact}
                                    setPageStatus={setPageStatus}
                                    setCurrentPage={setCurrentPage}
                                    setLinesLimit={setLimit}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {person === "juridic" && loadingPage ? (
                                <section className="contact-content-main">
                                    <Loading.Paperplane className="loading-contact-list" />
                                </section>
                            ) : (
                                <PersonJuridic
                                    pageStatus={companyPageStatus}
                                    filtersEnterprises={filtersEnterprises}
                                    companysData={companysData}
                                    loading={companyIsLoading}
                                    currentPage={companyCurrentPage}
                                    count={companyTotalCount}
                                    linesLimit={companysLinesLimit}
                                    enterprises={enterprises}
                                    updateCompanys={updateCompanys}
                                    setPageStatus={setCompanyPageStatus}
                                    setCurrentPage={setCompanyCurrentPage}
                                    setLinesLimit={setcompanysLinesLimit}
                                    setEnterprises={setEnterprises}
                                />
                            )}
                        </>
                    )}
                </div>
            </main>

            <Modal.CreateCompany
                isOpen={isOpenModalAddCompany}
                onClose={(event) => setIsOpenModalAddCompany(event)}
                onCreate={updateCompanys}
            />

            <Modal.CreateContact
                isOpen={isOpenModalAddContact}
                onClose={(event) => setIsOpenModalAddContact(event)}
                onCreate={updateContact}
                contactsData={contactsData}
            />
        </>
    );
}
