//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
//import { useParams } from "react-router";
import ptBR from "../../../../locale/pt-BR";

// UTILS
import { Toast } from "../../../../utils/toast";
import testJson from "../../../../utils/testJson";
import renderPhone from "../../../../utils/renderPhoneFormat";

//STYLES
import "./case-interactions.scss";

//COMPONENTS
import {
  Channel,
  Table,
  CommentsCount,
  Assessment,
  InteractionComment,
  Comment,
  ImageProfile,
} from "../../../../components/data-display";
import { Typography } from "../../../../components/general";
import { Audio, Input } from "../../../../components/data-entry";
import { Tippy, Loading } from "../../../../components/feedback";

//SERVICES - api, conectors...
import * as APIInteraction from "../../../../services/api/interaction";
import * as APIFiles from "../../../../utils/files";
import * as APIMimetypes from "../../../../services/api/mimetypes";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as PlayIcon } from "./../../../../assets/icons/PlayIcon.svg";
import { ReactComponent as ChatIcon } from "./../../../../assets/icons/Message-text.svg";
import { ReactComponent as ForbiddenIcon } from "./../../../../assets/icons/Forbidden.svg";
import { ReactComponent as GhostLetterImage } from "./../../../../assets/images/ghost-letter.svg";
import { ReactComponent as EmptyStateInteraction } from "./../../../../assets/icons/EmptyStateInteraction.svg";
import { ReactComponent as IconUndo } from "../../../../assets/icons/Undo.svg";
import { ReactComponent as IconArrows } from "../../../../assets/icons/Arrows.svg";
import { ReactComponent as IconSearch } from "../../../../assets/icons/Search.svg";
import { ReactComponent as IconArrowLeft } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as IconEyeSlash } from "../../../../assets/icons/Eye-slash.svg";

export default function CaseInteractions({
  interactionList,
  canBeVisualized = true,
  actionVisualization,
}) {
  //GENERAL
  const externalData = [
    {
      protocol: "2022455999",
      channel: "telefone",
      channelDetails: "75995456587",
      contact: "Ablo Henrique de Macedo Gomes da Costa",
      assessment: 2,
      date: "2021-11-16T19:39:53.196Z",
      auditType: 1, // telefone
    },
    {
      protocol: "2022456529",
      channel: "telefone",
      channelDetails: "75995456587",
      contact: "Bablo Henrique de Macedo Gomes da Costa",
      assessment: 5,
      date: "2021-11-12T19:39:53.196Z",
      auditType: 2, // chat
    },
    {
      protocol: "2022456886",
      channel: "facebook",
      channelDetails: "@pablohenrique",
      contact: "Pablo Henrique de Macedo Gomes da Costa",
      assessment: 1,
      date: "2021-09-12T19:39:53.196Z",
      auditType: null,
    },
    {
      protocol: "2022455999",
      channel: "twitter",
      channelDetails: "@pablohenrique",
      contact: "Ablo Henrique de Macedo Gomes da Costa",
      assessment: 5,
      date: "2021-11-16T19:39:53.196Z",
      auditType: 1,
    },
    {
      protocol: "2022456529",
      channel: "facebook",
      channelDetails: "@pablohenrique",
      contact: "Bablo Henrique de Macedo Gomes da Costa",
      assessment: 4,
      date: "2021-11-12T19:39:53.196Z",
      auditType: 2,
    },
    {
      protocol: "2022456886",
      channel: "facebook",
      channelDetails: "@pablohenrique",
      contact: "Pablo Henrique de Macedo Gomes da Costa",
      assessment: 3,
      date: "2021-09-12T19:39:53.196Z",
      auditType: 2,
    },
    {
      protocol: "2022455999",
      channel: "instagram",
      channelDetails: "@blohenrique",
      contact: "Ablo Henrique de Macedo Gomes da Costa",
      assessment: null,
      date: "2021-11-16T19:39:53.196Z",
      auditType: null,
    },
    {
      protocol: "2022456529",
      channel: "telegram",
      channelDetails: "@bablohenrique",
      contact: "Bablo Henrique de Macedo Gomes da Costa",
      assessment: 2,
      date: "2022-11-12T19:39:53.196Z",
      auditType: 2,
    },
    {
      protocol: "2022456886",
      channel: "telegram",
      channelDetails: "@bablohenrique",
      contact: "Pablo Henrique de Macedo Gomes da Costa",
      assessment: 4,
      date: "2021-09-12T19:39:53.196Z",
      auditType: 1,
    },
    {
      protocol: "2022455999",
      channel: "facebook",
      channelDetails: "@pablohenrique",
      contact: "Ablo Henrique de Macedo Gomes da Costa",
      assessment: null,
      date: "2021-11-16T19:39:53.196Z",
      auditType: 2,
    },
    {
      protocol: "2022456529",
      channel: "twitter",
      channelDetails: "@pablohenrique",
      contact: "Bablo Henrique de Macedo Gomes da Costa",
      assessment: 5,
      date: "2019-11-12T19:39:53.196Z",
      auditType: 1,
    },
    {
      protocol: "2022456886",
      channel: "email",
      channelDetails: "pablo@email.com",
      contact: "Pablo Henrique de Macedo Gomes da Costa",
      assessment: 4,
      date: "2021-09-12T19:39:53.196Z",
      auditType: 1,
    },
    {
      protocol: "2022455999",
      channel: "email",
      channelDetails: "pablo@email.com",
      contact: "Ablo Henrique de Macedo Gomes da Costa",
      assessment: 3,
      date: "2021-11-16T19:39:53.196Z",
      auditType: 1,
    },
    {
      protocol: "2022456529",
      channel: "instagram",
      channelDetails: "@blohenrique",
      contact: "Bablo Henrique de Macedo Gomes da Costa",
      assessment: 2,
      date: "2021-11-12T19:39:53.196Z",
      auditType: 1,
    },
    {
      protocol: "2022456886",
      channel: "facebook",
      channelDetails: "@pablohenrique",
      contact: "Pablo Henrique de Macedo Gomes da Costa",
      assessment: null,
      date: "2021-09-12T19:39:53.196Z",
      auditType: 1,
    },
  ];

  // const audio = {
  //   nome_usuario: 'Glauber',
  //   message: 'Teste 1',
  //   date: '2022-01-08T09:00:23.000Z',
  //   attachment: {
  //     type: 'audio/mp3',
  //     name: 'audio-teste.mp3',
  //     size: 1375,
  //     path_arquivos:"/database/rec/ingdesk/20220110/09/audio_20220110095939.wav"
  //   }
  // }

  // const chatList = [
  //   {
  //     nome_usuario: 'Glauber',
  //     message: 'Teste 1',
  //     date: '2022-01-08T09:00:23.000Z',
  //     attachment: null
  //   },
  //   {
  //     nome_contato: 'Lucas',
  //     message: 'Teste 2',
  //     date: '2022-01-08T09:04:23.000Z',
  //     attachment: {
  //       type: 'audio/mp3',
  //       name: 'audio-teste.mp3',
  //       size: 1375,
  //       path_arquivos: "/database/rec/ingdesk/20220110/09/audio_20220110095939.wav"
  //     }
  //   },
  //   {
  //     nome_contato: 'Lucas',
  //     message: 'Teste 2',
  //     date: '2022-01-08T09:04:23.000Z',
  //     attachment: {
  //       type: 'application/pdf',
  //       name: 'teste.pdf',
  //       size: 1375,
  //       path_arquivos: 'teste.pdf'
  //     }
  //   },
  //   {
  //     nome_usuario: 'Glauber',
  //     message: 'Teste 3',
  //     date: '2022-01-08T09:26:23.000Z',
  //     attachment: {
  //       type: 'image/jpeg',
  //       name: 'image-teste.jpeg',
  //       size: 1375,
  //       path_arquivos: '/database/rec/ingdesk/20220104/11/imagem-sol_20220104112019.jpg'
  //     }
  //   },
  //   {
  //     nome_usuario: 'Glauber',
  //     message: 'Teste 4',
  //     date: '2022-01-08T09:00:23.000Z',
  //     attachment: null
  //   },
  //   {
  //     nome_contato: 'Lucas',
  //     message: 'Teste 5',
  //     date: '2022-01-08T09:04:23.000Z',
  //   },
  //   {
  //     nome_contato: 'Lucas',
  //     date: '2022-01-08T09:26:23.000Z',
  //     attachment: {
  //       type: 'image/jpeg',
  //       name: 'image-teste.jpeg',
  //       size: 1375,
  //       path_arquivos: '/database/rec/ingdesk/20220104/11/imagem-sol_20220104112019.jpg'
  //     }
  //   },
  // ]

  const mimetypesPrincipal = [
    "image",
    "voice",
    "document",
    "audio",
    "txt",
    "video",
  ];

  const auditRoute = useSelector((state) => state.audit.route);
  const token = useSelector((state) => state.auth.token);

  //STATES
  const [audit, setAudit] = useState("");
  const [auditList, setAuditList] = useState([]);
  const [interactionListFormated, setInteractionListFormated] = useState([]);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState({});
  const [inputSearch, setInputSearch] = useState("");
  const [loadingFormated, setLoadingFormated] = useState(true);
  const [typeAvaliation, setTypeAvaliation] = useState();

  //REDUX - Selectors

  //FUNCTIONS
  function customSorting(rowA, rowB, columnId) {
    const customRowA = rowA.original[columnId].config.customOrder;
    const customRowB = rowB.original[columnId].config.customOrder;

    if (customRowA > customRowB) return -1;
    if (customRowB > customRowA) return 1;
    return 0;
  }

  function renderInformation() {
    return (
      <div className="table-case-interactions-audit__information">
        <div className="table-case-interactions-audit-channel">
          <ImageProfile
            tooltip
            profile={{
              nome_usuario: createJSON(client.nome_contato) ?? "Não registrado",
            }}
            client
            iconHistory={!client.nome_contato && "user"}
          />
          <Channel media={client.id_canal} />
          {/* <Typography.H6 color="gray-400">
            {client.protocolo_externo}
          </Typography.H6> */}
        </div>
        <div className="table-case-interactions-audit-contact-container">
          <div className="table-case-interactions-audit-contact">
            <Typography.H5 weight={"bold"} color="blue-selected-4">
              {createJSON(client.nome_contato) ?? "Não registrado"}
            </Typography.H5>
            <IconArrows className="icon-arrows" />
            <Typography.H5 weight={"bold"} color="blue-selected-4">
              {client.nome}
            </Typography.H5>
          </div>
          <div className="table-case-interactions-audit-date">
            <Typography.H6 weight={400} color="gray-180">
              {format(
                new Date(client.data_insercao),
                "dd 'de' MMM/yyyy 'às' HH:mm",
                { locale: ptBR }
              )}
            </Typography.H6>
          </div>
        </div>
      </div>
    );
  }

  function createJSON(item) {
    if (item) {
      let mensagem_tratada = item.replace(/¿/gi, "\n");
      mensagem_tratada = mensagem_tratada.replace(/§/gi, "\r\n");
      let valor = 1;
      try {
        mensagem_tratada =
          '{"' +
          valor +
          '": [{"mensagem_tratada":"' +
          mensagem_tratada +
          '"}]}';
        let obj = JSON.parse(mensagem_tratada);
        mensagem_tratada = obj[1][0].mensagem_tratada;
      } catch (e) {
        mensagem_tratada = item;
      }
      return mensagem_tratada;
    }
  }

  function isJson(item) {
    item = typeof item != "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item != null) {
      return true;
    }

    return false;
  }

  //Função que filtra os comentários da auditória com base no que foi digitado no input de busca.
  const commentFilter = (comment) => {
    if (inputSearch) {
      const commentFilter = comment.messagem
        ?.toLowerCase()
        .includes(inputSearch.toLowerCase());

      if (commentFilter && comment.mimetypeid === 1) {
        return comment;
      } else {
        return;
      }
    } else {
      return comment;
    }
  };

  //Função que busca a auditória da interação.
  async function getCommentAuditList(type, idInteracao, cdrid, datum) {
    //console.debug(idInteracao)
    //console.debug(auditRoute)
    if (auditRoute) {
      const response = await APIInteraction.listCommentAudit(
        idInteracao ? idInteracao : "",
        cdrid ? cdrid : "",
        auditRoute,
        type
      );
      //console.debug(response)
      if (response.status === 200) {
        const listAudit = response.data;
        for (const obj of response.data) {
          if (obj.replyid) {
            const findAudit = listAudit.find(
              (audit) => audit.id === obj.replyid
            );
            if (findAudit) {
              if (
                findAudit.typename === "Interactive" ||
                findAudit.typename === "hsm"
              ) {
                findAudit.buttonResponse = obj.messagem;
              }

              obj.commentResponse = {
                id: findAudit.id,
                username: findAudit.agente
                  ? findAudit.username
                  : client.nome_contato,
                mensagem: findAudit.messagem,
                mimetype: findAudit.mimetypeid,
                typename: findAudit.typename,
              };
            }
          }
        }

        setAuditList(listAudit);
        setAudit(type);
        setClient(datum);
      } else {
        Toast(
          "Não foi possível carregar interação",
          "Tente novamente mais tarde!.",
          "error"
        );
      }
    }
  }

  //função que busca os arquivos dos comentários da auditória
  async function fetchFile() {
    setLoading(true);

    if (auditList.length > 0) {
      for (const item of auditList) {
        //Busca as informações do arquivo com base no link
        if (
          mimetypesPrincipal.includes(item.typename) &&
          !item.messagem.includes("https")
        ) {
          const response = await APIFiles.handleFiles(item.messagem);

          const fileObject = {
            name: response.name,
            size: response.size,
            type: response.typeMime,
            path_arquivos: item.messagem,
            extension: response.type,
            id: item.id,
          };

          item.file = fileObject;

          //Busca as informações do mimetype caso venha o link completo do arquivo
        } else if (
          mimetypesPrincipal.includes(item.typename) &&
          item.messagem.includes("https")
        ) {
          const response = await APIMimetypes.listMimetypes(token);
          const mimetype = response.data.find(
            (element) => item.mimetypeid === element.id_mimetype
          );

          item.mimetype = mimetype;

          //Caso a mensagem seja um json, ele faz o parse para ler o json
        } else if (item.typename === "application") {
          // item.messagem = JSON.parse(item.messagem).msg || JSON.parse(item.messagem).MSG;
          try {
            if (
              JSON.parse(item.messagem).msg ||
              JSON.parse(item.messagem).MSG
            ) {
              item.messagem =
                JSON.parse(item.messagem).msg || JSON.parse(item.messagem).MSG;
            }

            item.messagem = createJSON(item.messagem);
          } catch (err) {
            console.log("Error: ", err.message);
          }

          //Caso seja auditória de voz, formata o link que vem do back (vem sem formatação) para poder chamar a função que busca as informações do arquivo.
        } else if (audit === "voz") {
          if (item.messagem) {
            const index = item.messagem.indexOf("\\rec");
            const url = item.messagem.slice(index, item.messagem.length);
            const response = await APIFiles.handleFiles(`\\database${url}`);

            const fileObject = {
              name: response.name,
              size: response.size,
              type: response.typeMime,
              path_arquivos: item.messagem,
              extension: response.type,
              id: item.id,
            };

            item.file = fileObject;
            item.messagem = `\\database${url}`;
          }
        } else if (item.typename === "Interactive") {
          if (item.mimetypeid >= 33 && item.mimetypeid <= 36) {
            let json = JSON.parse(item.messagem);

            item.buttons = json.buttons;
            item.botid = json.botid;

            if (json.link) {
              const response = await APIFiles.handleFiles(json.link);
              const fileObject = {
                name: response.name,
                size: response.size,
                type: response.typeMime,
                path_arquivos: json.link,
                extension: response.type,
                id: item.id,
              };

              item.file = fileObject;
            }
          } else if (item.mimetypeid === 37) {
            let json = JSON.parse(item.messagem);

            item.buttons = json.items;
            item.botid = json.botid;
          }
        } else if (item.typename === "location") {
          let json = JSON.parse(item.messagem);

          item.location = {
            latitude: json.latitude,
            longitude: json.longitude,
            nomeEndereco: json.name,
            endereco: json.address,
          };
        } else if (item.typename === "hsm") {
          // VERIFICAR AQUI
          const verifyJson = isJson(item.messagem);
          // let json = "";
          // console.log("VERIFYJSON", verifyJson);

          if (!verifyJson) {
            item.messagem = JSON.stringify(item.messagem.replace(/'/g, '"'));
          }

          // console.log("VERIFYJSON2", item.messagem);

          let json = JSON.parse(item.messagem);

          // console.log("JSON2", json);

          if (json.URL) {
            item.messagem = json.URL;
          }

          if (json.FILEPATH) {
            const response = await APIFiles.handleFiles(json.FILEPATH);
            const fileObject = {
              name: response.name,
              size: response.size,
              type: response.typeMime,
              path_arquivos: json.FILEPATH,
              extension: response.type,
              id: item.id,
            };

            item.file = fileObject;
            item.messagem = json.URL;
          }

          if (json.HSM_BUTTONS) {
            item.messagem = json.URL;
            let buttonsFormated = [];

            if (json.HSM_BUTTONS.buttons) {
              const buttons = json.HSM_BUTTONS.buttons;

              for (const btn of buttons) {
                const objBtn = {
                  payload: btn.index,
                  text: btn.parameters[0].text,
                };

                buttonsFormated.push(objBtn);
              }
            } else {
              const buttons = json.HSM_BUTTONS;

              for (const btn of buttons) {
                const objBtn = {
                  payload: btn.payload.index,
                  text: btn.payload.value,
                };

                buttonsFormated.push(objBtn);
              }
            }

            item.buttons = buttonsFormated;
          }
        }
      }
      setLoading(false);
    }
  }

  //função que edita a lista de interações, incluindo novas infos
  async function formatInteractionList() {
    const newList = [];
    const allInteractions = interactionList.filter(
      (interaction) => interaction.id_interacao
    );
    const allIdCdr = interactionList.filter((interaction) => interaction.cdrid);

    if (auditRoute) {
      const response = await APIInteraction.getInteractionsInfo(
        allInteractions.length > 0
          ? allInteractions
              .map((interaction) => interaction.id_interacao)
              .join()
          : "",
        allIdCdr.length > 0
          ? allIdCdr.map((interaction) => interaction.cdrid).join()
          : "",
        auditRoute
      );

      if (response.status === 200) {
        const data = response.data;
        for (const newResponse of data) {
          const interaction = interactionList.find(
            (resp) =>
              resp.id_interacao === newResponse.id_interacao ||
              resp.cdrid === newResponse.cdrid
          );

          if (interaction) {
            interaction.desc_auditLength =
              newResponse.qtde_msg_texto === "0" && !newResponse.gravacao
                ? false
                : true;
            interaction.client = newResponse.cliente;
            interaction.protocolo_externo = newResponse.protocolo;
            interaction.assessment =
              newResponse.tipo_avaliacao === 1 ? "NPS" : "CSAT";
            interaction.assessmentNote = newResponse.nota_avaliacao;

            newList.push(interaction);
            setClient(interaction);
          }
        }
      } else {
        Toast(
          "Não foi possível carregar interação",
          "Tente novamente mais tarde!.",
          "error"
        );
      }
    }

    setInteractionListFormated(() => newList);
  }

  async function handleInteractionList() {
    setLoadingFormated(true);

    await formatInteractionList();

    setLoadingFormated(false);
  }

  function renderButtonAudit(datum) {
    let auditIcon;
    if (datum.id_canal === 1) {
      auditIcon = (
        <PlayIcon
          onClick={() =>
            actionVisualization
              ? datum.desc_auditLength
                ? getCommentAuditList(
                    "voz",
                    datum.id_interacao,
                    datum.cdrid,
                    datum
                  )
                : null
              : null
          }
        />
      );
    } else if (datum.id_canal > 1 && datum.id_canal <= 10) {
      auditIcon = (
        <ChatIcon
          onClick={() =>
            actionVisualization
              ? datum.desc_auditLength
                ? getCommentAuditList(
                    "chat",
                    datum.id_interacao,
                    datum.cdrid,
                    datum
                  )
                : null
              : null
          }
        />
      );
    } else {
      auditIcon = <ForbiddenIcon />;
    }

    return (
      <Tippy
        content={"Nenhuma interação encontrada"}
        disabled={datum.desc_auditLength}
      >
        <button
          type="button"
          className={`btn-audit ${
            !datum.desc_auditLength || !actionVisualization
              ? `btn-audit--disabled ${datum.id_canal === 1 ? "voz" : "chat"}`
              : ""
          }`}
          onClick={datum.id_canal ? () => null : null}
        >
          {auditIcon}
        </button>
      </Tippy>
    );
  }

  //USE EFFECTS
  useEffect(() => {
    if (audit === "chat" || audit === "voz") {
      fetchFile();
    } else {
      setLoading(true);
    }
  }, [audit]);

  useEffect(() => {
    if (interactionList.length > 0) handleInteractionList();
  }, []);

  //USE MEMOS
  const columns = useMemo(
    () => [
      {
        Header: "Canais",
        accessor: "protocol",
        id: "protocol",
        sortType: customSorting,
      },
      {
        Header: "Contato",
        accessor: "contact",
        id: "contact",
      },
      {
        Header: "Data",
        accessor: "date",
        id: "date",
        sortType: customSorting,
      },
      {
        Header: interactionListFormated.length
          ? interactionListFormated[0]?.assessment
          : "Avaliação",
        accessor: "assessment",
        id: "assessment",
        sortType: customSorting,
      },
      {
        Header: "Ação",
        accessor: "action",
        id: "action",
        sortType: customSorting,
      },
    ],
    [interactionListFormated]
  );

  const data = useMemo(() => {
    return interactionListFormated.map((datum) => {
      return {
        protocol: {
          data: (
            <>
              <Channel
                media={datum.id_canal}
                nameMedia={datum.desc_canal}
                customTippy={
                  datum.channelDetails && (
                    <div className="channel-tooltip-contact-container">
                      <Typography.H6 color="gray-400" weight="600">
                        Contato:
                      </Typography.H6>
                      <Typography.Paragraph
                        color="gray-200"
                        className="channel-tooltip-contact-container__data"
                      >
                        {datum.channelDetails}
                      </Typography.Paragraph>
                    </div>
                  )
                }
              />
              <Typography.H6 color="gray-180">
                {datum.client ? (
                  <>
                    {datum.id_canal === 1 || datum.id_canal === 5
                      ? renderPhone(datum.client)
                      : datum.client}
                  </>
                ) : (
                  datum.protocolo_externo
                )}
                {/* {datum.client
                  ? renderPhone(datum.client)
                  : datum.protocolo_externo} */}
              </Typography.H6>
            </>
          ),
          config: {
            customData: true,
            className: "cell-channel",
            customOrder: datum.desc_canal,
          },
        },
        contact: {
          data: createJSON(datum.nome_contato) || "Não registrado",
          config: {
            textEllipsis: true,
            tippy: true,
            customOrder: datum.nome_contato,
          },
        },
        date: {
          data: format(
            new Date(datum.data_insercao),
            "dd'/'MM'/'yyyy 'às' HH:mm"
          ),
          config: {
            customOrder: new Date(datum.data_insercao),
            className: "cell-date",
          },
        },
        assessment: {
          data: (
            <Assessment value={datum.assessmentNote} type={datum.assessment} />
          ),
          config: {
            customData: true,
            className: "cell-assessment",
            customOrder: datum.assessmentNote,
          },
        },
        action: {
          data: renderButtonAudit(datum),
          config: {
            customData: true,
            className: "cell-audit",
            customOrder: datum.id_canal,
          },
        },
      };
    });
  }, [interactionListFormated]);

  return (
    <div
      className={`table-case-interactions-container 
        ${
          interactionList.length === 0 || !canBeVisualized
            ? "table-case-interactions-container--empty"
            : ""
        }`}
    >
      {audit === "chat" ? (
        <>
          {loading ? (
            <Loading.Paperplane />
          ) : (
            <div className={`table-case-interactions-audit`}>
              <div className="table-case-interactions-audit__header">
                <IconArrowLeft
                  onClick={() => {
                    setAudit("");
                    setInputSearch("");
                  }}
                />
                <Typography.H3 weight={"bold"} color="gray-180">
                  Auditória {"/"} Chat
                </Typography.H3>
              </div>
              {renderInformation()}
              {auditList.length > 0 ? (
                <Input
                  value={inputSearch}
                  onChange={(value) => setInputSearch(value)}
                  className="comment-list-search"
                  rightIcon={<IconSearch />}
                  placeholder="Buscar por palavras-chave"
                />
              ) : (
                <></>
              )}
              <div className="table-case-interactions-audit__list">
                {auditList.filter(commentFilter).length > 0 ? (
                  <>
                    {auditList.filter(commentFilter).map((chat, index) => (
                      <InteractionComment
                        key={index}
                        id={chat.id}
                        inputSearch={inputSearch}
                        text={chat.messagem}
                        type={
                          mimetypesPrincipal.includes(chat.typename)
                            ? "attachment"
                            : ""
                        }
                        date={{
                          horaEnvio: chat.data_hora_envio,
                          horaLida: chat.dateread,
                        }}
                        attachment={chat.file}
                        size={chat.filesize}
                        mimetypeId={chat.mimetypeid}
                        mimetype={chat.mimetype}
                        typeName={chat.typename}
                        name={chat.agente ? chat.username : client.nome_contato}
                        direction={chat.agente ? "right" : "left"}
                        buttons={chat.buttons}
                        botId={chat.botid}
                        reply={chat.commentResponse}
                        buttonResponse={chat.buttonResponse}
                        location={chat.location}
                        allInfo={chat}
                      />
                    ))}
                  </>
                ) : (
                  <div className="table-case-interactions-container__empty-state">
                    <GhostLetterImage />
                    <Typography.H3 color="gray-400">
                      Não foi encontrada nenhuma mensagem.
                    </Typography.H3>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : audit === "voz" ? (
        <>
          {loading ? (
            <Loading.Paperplane />
          ) : (
            <div className={`table-case-interactions-audit`}>
              <div className="table-case-interactions-audit__header">
                <IconArrowLeft onClick={() => setAudit("")} />
                <Typography.H3 weight={"bold"} color="gray-180">
                  Auditória {"/"} Audio Player
                </Typography.H3>
              </div>
              {renderInformation()}
              {auditList.length > 0 && auditList[0].messagem ? (
                <div className="table-case-interactions-audit__voice">
                  <Audio link={auditList[0].messagem} />
                </div>
              ) : (
                <div className="table-case-interactions-audit__list">
                  <div className="table-case-interactions-container__empty-state">
                    <GhostLetterImage />
                    <Typography.H3 color="gray-400">
                      Esta interação não possui áudio.
                    </Typography.H3>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {interactionList.length > 0 && canBeVisualized ? (
            loadingFormated ? (
              <Loading.Paperplane />
            ) : (
              <Table
                columns={columns}
                data={data}
                className="table-case-interactions"
                fixedHeader
                initialState={{
                  sortBy: [
                    {
                      id: "date",
                      cresc: true,
                    },
                  ],
                }}
              />
            )
          ) : (
            <div className="table-case-interactions-container__empty-state">
              <div
                className={`icon_empty-space ${
                  !canBeVisualized ? "red-color" : ""
                }`}
              >
                {canBeVisualized ? <EmptyStateInteraction /> : <IconEyeSlash />}
              </div>
              <Typography.H4 color="black">
                {!canBeVisualized
                  ? "Você não tem permissão para visualizar as interações"
                  : "Nenhuma interação registrada."}
              </Typography.H4>
            </div>
          )}
        </>
      )}
    </div>
  );
}
