//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import { Button, Typography } from "../../../components/general";
import { Input } from "../../../components/data-entry";
import { Loading, Modal } from "../../../components/feedback";
import { Table } from "../../../components/data-display";
import { Toast } from "../../../utils/toast";
import CreateOrganization from "./CreateOrganization/CreateOrganization";
import UpdateOrganization from "./UpdateOrganization/UpdateOrganization";

//SERVICES - api, conectors...
import * as APIOrganization from "../../../services/api/organization";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search-normal.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-3.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";
import { ReactComponent as BuildingIcon } from "../../../assets/icons/Building.svg";

//STYLES
import "./organization.scss";

export default function Organization({ returnToRecord }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [searchData, setSearchData] = useState("");
    const [organizationId, setOrganizationId] = useState(null);
    const [emptyState, setEmptyState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(false);
    const [registerOrganization, setRegisterOrganization] = useState(false);
    const [isOpenModalDeleteOrganization, setIsOpenModalDeleteOrganization] = useState(false);
    const [updatedOrganization, setUpdatedOrganization] = useState(false);
    const [updateOrganization, setUpdateOrganization] = useState({ state: false, organizationId: null });
    const [organizations, setOrganizations] = useState([]);
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleSearchOrganizations(event) {
        const inputValue = event;

        setSearchData(inputValue);

        if (event === "") {
            setFilteredData(false);
            setFilteredOrganizations([]);
        }
    };


    function handleTableDataLookup() {
        const searchTerm = searchData.toLowerCase();

        const filteredData = organizations.filter(
            (data) =>
                (data.nome_organizacao != null &&
                    data.nome_organizacao !== "" &&
                    data.nome_organizacao.toLowerCase().includes(searchTerm)) ||
                (data.codigo_externo != null &&
                    data.codigo_externo !== "" &&
                    data.codigo_externo.toString().toLowerCase().includes(searchTerm))
        );

        setFilteredOrganizations(filteredData);
        setFilteredData(true);
    };

    function refactorOrganizationListing(data) {
        let refactoredData = [];

        if (!data) throw new Error('Error when fetching data!');

        data && data.map((item) => {
            const newObject = {
                name: {
                    data: item.nome_organizacao,
                    config: {
                        className: "table-organizations__name"
                    },
                },
                externalCod: {
                    data: item.codigo_externo,
                    config: {
                        className: "table-organizations__external-cod"
                    }
                },
                actions: {
                    data: (
                        <div className="actions-organizations">
                            <button
                                className="actions-organizations__edit"
                            >
                                <EditIcon
                                    onClick={() => setUpdateOrganization({
                                        state: true,
                                        organizationId: item.id_organizacao
                                    })}
                                />
                            </button>

                            <button
                                className="actions-organizations__delete"
                            >
                                <TrashIcon onClick={() => {
                                    setOrganizationId(item.id_organizacao);
                                    setIsOpenModalDeleteOrganization(true);
                                }} />
                            </button>
                        </div>
                    ),
                    config: {
                        className: "action-options"
                    }
                },
            }

            refactoredData.push(newObject);

            return refactoredData;
        });

        return refactoredData;
    };

    function renderTableContent() {
        switch (loading) {
            case true:
                return (
                    <div className="table-card-content__loading">
                        <Loading.Paperplane />
                    </div>
                )

            case false:
                if (organizations.length > 0) {
                    return (
                        <>
                            {searchData && filteredData && filteredOrganizations.length < 1 ? (
                                <div className="table-card-content__empty-state">
                                    <div className="table-card-content__empty-state__icon">
                                        <BuildingIcon />
                                    </div>

                                    <div className="table-card-content__empty-state__text">
                                        <Typography.H4 weight="bold" color="blue-selected-4">
                                            Ops! Sem organizações cadastradas.
                                        </Typography.H4>

                                        <Typography.H5 color="gray-180" className="text-center">
                                            Clique no botão <span className="text-bold">"Adicionar organização"</span>. <br />
                                            Preencha os dados da nova organização.
                                        </Typography.H5>
                                    </div>
                                </div>
                            ) : (
                                <div className="table-card-content__table-container">
                                    <Table
                                        fixedHeader
                                        className="table-organizations"
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        columns={tableColumns}
                                        data={searchData && filteredData ? organizatonsFiltered : organizationsData}
                                    />
                                </div>
                            )}
                        </>
                    )
                } else {
                    return (
                        <div className="table-card-content__empty-state">
                            <div className="table-card-content__empty-state__icon">
                                <BuildingIcon />
                            </div>

                            <div className="table-card-content__empty-state__text">
                                <Typography.H4 weight="bold" color="blue-selected-4">
                                    Ops! Sem organizações cadastradas.
                                </Typography.H4>

                                <Typography.H5 color="gray-180" className="text-center">
                                    Clique no botão <span className="text-bold">"Adicionar organização"</span>. <br />
                                    Preencha os dados da nova organização.
                                </Typography.H5>
                            </div>
                        </div>
                    )
                }

            default:
                break;
        }
    };

    async function getListOfOrganizations(organizationId) {
        setLoading(true);

        try {
            const response = await APIOrganization.getListOfOrganizations(token, organizationId);

            if (response.status === 200) {
                const updatedOrganizations = response.data.map(org => ({
                    ...org,
                    codigo_externo: org.codigo_externo ?? ""
                }))

                setOrganizations(updatedOrganizations);
            } else {
                Toast(
                    "Falha, não foi possível carregar as informações!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
            }
        } catch (error) {
            console.error("Erro ao processar a resposta da API:", error);

            Toast(
                "Erro ao processar a resposta da API",
                "Por favor, tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    // USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                id: "name",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Cód Externo",
                accessor: "externalCod",
                id: "externalCod",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ]
    );

    const organizationsData = useMemo(() => {
        if (organizations) {
            const refactoredData = refactorOrganizationListing(organizations);
            return refactoredData;
        }
    }, [organizations]);

    const organizatonsFiltered = useMemo(() => {
        if (Array.isArray(filteredOrganizations)) {
            const refactoredData = refactorOrganizationListing(filteredOrganizations);
            return refactoredData;
        }
    }, [filteredOrganizations]);

    //USE EFFECTS
    useEffect(() => {
        if (!searchData || searchData.trim() === "") {
            setFilteredData(false);
            setFilteredOrganizations([]);
        }
    }, []);

    useEffect(() => {
        if (updatedOrganization) {
            handleTableDataLookup()
        }
    }, [updatedOrganization, organizations]);

    useEffect(() => {
        getListOfOrganizations();
    }, []);

    return (
        <>
            {registerOrganization ? (
                <CreateOrganization
                    toGoBack={() => setRegisterOrganization(false)}
                    onCreate={getListOfOrganizations}
                />
            ) : updateOrganization.state ? (
                <UpdateOrganization
                    toGoBack={() => setUpdateOrganization({ state: false })}
                    organizationId={updateOrganization.organizationId}
                    onUpdate={getListOfOrganizations}
                    setUpdatedOrganization={setUpdatedOrganization}
                    detailedOrganization={organizations}
                    handleTableDataLookup={handleTableDataLookup}
                />
            ) : (
                <main className="page-organization">
                    <header className="page-organization__header">
                        <div className="page-organization__header__title">
                            <ArrowLefIcon onClick={returnToRecord} />

                            <Typography.H2 color="gray-180" weight="bold">
                                Cadastro de organização
                            </Typography.H2>
                        </div>

                        <div>
                            <Button
                                className=""
                                onClick={() => setRegisterOrganization(true)}
                            >
                                <Typography.H4 weight="bold" color="white">
                                    Adicionar organização
                                </Typography.H4>
                            </Button>
                        </div>
                    </header>

                    <div className="table-card">
                        <div className="table-card-content">
                            <Input
                                type="text"
                                width="432px"
                                placeholder="Pesquisar"
                                value={searchData}
                                onChange={handleSearchOrganizations}
                                onKeyPress={(event) =>
                                    (event.code === "Enter" || event.code === "NumpadEnter") &&
                                    handleTableDataLookup()
                                }
                                rightIcon={
                                    <button onClick={handleTableDataLookup}>
                                        <SearchIcon />
                                    </button>
                                }
                            />

                            {renderTableContent()}
                        </div>
                    </div>
                </main>
            )}

            <Modal.DeleteOrganization
                isOpen={isOpenModalDeleteOrganization}
                onClose={(event) => setIsOpenModalDeleteOrganization(event)}
                organizationId={organizationId}
                organizations={organizations}
                handleTableDataLookup={handleTableDataLookup}
                onDelete={getListOfOrganizations}
            />
        </>
    )
}
