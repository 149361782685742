import instance from "./configAxios";
import axios from "axios";
import unicodeToChar from "../../utils/formatUnicode";

export async function createInteractionCase(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = instance.post("/create-interaction", data, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function listInteractionCase(token, caseId) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = instance.get(`/list-interactions/${caseId}`, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getInteractionsInfo(interactionId, idcdr, route) {
  try {
    const config = {
      headers: {
        //'x-access-token': token,
        "Content-Type": "application/json",
      },
      // params: {
      //   idatd: interactionId,
      // }
    };

    const response = await axios.get(
      `${route}/conn/carregamento-general-ingdesk?idatd=${interactionId}&cdrid=${idcdr}`,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function getCaseIdReturned(interactionId,token) {
  try {
    const config = {
      headers: {
        'x-access-token': token,
        "Content-Type": "application/json",
      },
      // params: {
      //   idatd: interactionId,
      // }
    };

    const response = await instance.get(`list-interactions-attendance/${interactionId}`,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
}

export async function listCommentAudit(interactionId, idcdr, route, type) {
  try {
    // const config = {
    //   headers: {
    //'x-access-token': token,
    // "Content-Type": "application/json",
    // },
    // params: {
    //   idatd: interactionId,
    // }
    // };

    const response = await axios.get(
      `${route}/conn/${
        type === "chat" ? "carregamento-ingdesk" : "carregamento-voz-ingdesk"
      }?idatd=${interactionId}&cdrid=${idcdr}`
    );
    console.log("item", response);
    for (const item of response.data) {
      console.log("item", item);
      //   if (type === "chat") {
      //     item.messagem = unicodeToChar(item.messagem);
      //   }
    }
    console.log("rrrrrr", response.data);
    return response;
  } catch (error) {
    return error;
  }
}
