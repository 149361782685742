//GLOBAL - components from npm
import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from 'react-tippy';
import { MdSearchOff } from "react-icons/md";

//HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//STYLES
import "./dropdown-status.scss";

//COMPONENTS
import { Typography, Button } from "../../../components/general";
import { Tippy } from "../../../components/feedback";
import { SendingComment, Input } from "../../data-entry";
import { CarouselAttach } from "../../data-display";
import { DropdownEscalate } from "../../layout";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/Close-Circle.svg";
import { ReactComponent as IconStatusCanceled } from "../../../assets/icons/StatusCanceled.svg";
import { ReactComponent as IconStatusComplete } from "../../../assets/icons/StatusComplete.svg";
import { ReactComponent as IconStatusInProgress } from "../../../assets/icons/StatusInProgress.svg";
import { ReactComponent as IconStatusPending } from "../../../assets/icons/StatusPending.svg";
import { ReactComponent as IconStatusScaled } from "../../../assets/icons/StatusScaled.svg";

export default function DropdownStatus({
  className = "",
  value = 2,
  options = [
    {
      id: 4,
      label: "Concluídos",
    },
    {
      id: 2,
      label: "A Fazer",
    },
    {
      id: 3,
      label: "Em andamento",
    },
  ],
  newStatusListOptions = [],
  isNewStatusList,
  width,
  onClick,
  commentIdStatus,
  escalateIdStatus,
  escalateList,
  onAdd,
  statusIcon,
  searchStatus,
  maxHeight,
  cardStatusIcon,
  statusIconKanban,
  changeStatusAndEscalateTicket,
  newStatus,
  changedStatus,
  type = "select",
  screen = "details",
  placement = "bottom",
  changeDisplay = false,
  error = false,
}) {
  //GENERAL
  const ref = useRef();
  const status = (isNewStatusList ? newStatusListOptions : options).find((item) => item.id === value);

  //STATES
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingComment, setLoadingComment] = useState("awaiting");
  const [typeTippy, setTypeTippy] = useState("comment");
  const [valueEscalate, setValueEscalate] = useState("");
  const [inputSearch, setInputSearch] = useState("");

  //REDUX - Selectors

  //FUNCTIONS
  const show = () => setVisible(true);

  const hide = () => {
    setFiles([]);
    setVisible(false);
  };

  // ############ FUNÇÃO DE DEFINIR COR SE isNewStatusList FOR TRUE ############
  const colorDefinition = (label) => {
    const option = newStatusListOptions.find(option => option.label === label);

    return option ? option.cor : "orange-f4511e";
  };

  const defineColor = (option) => {
    switch (option?.label) {
      case "Concluídos":
      case "Concluído":
      case "Concluido":
        return "green";

      case "A fazer":
      case "A Fazer":
        return "primary-dark";

      case "Em andamento":
        return "blue";

      case "Em tratamento":
        return "purple";

      case "Pendente":
      case "Pendentes":
        return "yellow";

      case "Cancelado":
      case "Cancelados":
        return "gray";

      case "Escalado":
        return "orange";

      case "Novo":
        return "blue-light";

      default:
        return "orange-f4511e";
    }
  };

  const renderTypeStatusIcon = (option) => {
    switch (option?.label) {
      case "Concluídos":
      case "Concluído":
      case "Concluídos":
      case "Concluido":
        return (
          <Tippy content="Concluído">
            <div className="status-icon">
              <IconStatusComplete />
            </div>
          </Tippy>
        );

      case "A fazer":
      case "A Fazer":
        return (
          <Tippy content="A fazer">
            <div className="status-icon">
              <IconStatusInProgress />
            </div>
          </Tippy>
        );

      case "Em andamento":
        return (
          <Tippy content="Em andamento">
            <div className="status-icon">
              <IconStatusPending />
            </div>
          </Tippy>
        );

      case "Pendente":
      case "Pendentes":
        return null;

      case "Cancelado":
      case "Cancelados":
        return (
          <Tippy content="Cancelado">
            <div className="status-icon">
              <IconStatusCanceled />
            </div>
          </Tippy>
        );

      case "Escalado":
        return (
          <Tippy content="Escalado">
            <div className="status-icon">
              <IconStatusScaled />
            </div>
          </Tippy>
        );

      default:
        break;
    }
  };

  const filterStatus = (option) =>
    inputSearch ? option.label.toLowerCase().includes(inputSearch.toLowerCase()) : true;

  const filteredStatus = (isNewStatusList ? newStatusListOptions : options).filter(filterStatus);

  const listLength = isNewStatusList ? newStatusListOptions.length : options.length;

  const selectedSectorEstagger = () => {
    const escalate = escalateList.find((item) => item.id === valueEscalate);
    changeStatusAndEscalateTicket({ id: valueEscalate, label: escalate.label });

    hide();
    setValueEscalate("");
  };

  const startFlash = () => {
    setIsFlashing(true);

    setTimeout(() => {
      setIsFlashing(false);
    }, 5000);
  };

  useEffect(() => {
    if (isNewStatusList) {
      newStatusListOptions?.forEach((status) => {
        if (status.id === 2) {
          status.cor = "#5b78ff";
        } else if (status.id === 3) {
          status.cor = "#ffbb00";
        } else if (status.id === 4) {
          status.cor = "#008908";
        }
      });
    }
  }, [isNewStatusList, newStatusListOptions]);

  function renderDropdownStatus() {
    if (statusIconKanban) {
      return <>{cardStatusIcon}</>;
    } else if (statusIcon) {
      return <>{renderTypeStatusIcon(status)}</>;
    } else {
      return (
        <Tippy
          visible={visible}
          onClickOutside={hide}
          theme="popover-comment"
          // #TOVERIFY: TRIGGER
          // trigger="click"
          interactive
          placement={placement}
          delay={[0, 0]}
          allowHTML
          content={
            <div
              className={`popover-status-comment ${typeTippy === "escalate" ? "escalate-tippy" : ""}`}
            >
              <div className="popover-status-comment__icon" onClick={hide}>
                <IconClose />
              </div>
              <div className="popover-status-comment__text">
                {typeTippy === "comment" && (
                  <Typography.H5 color="primary-dark" weight="500">
                    Mandou bem!
                  </Typography.H5>
                )}

                {typeTippy === "escalate" ? (
                  <Typography.H1 color="blue-selected-4" weight="bold">
                    Para qual setor (equipe) <br /> você deseja escalonar este
                    caso?
                  </Typography.H1>
                ) : (
                  <Typography.H5 color="gray-400" weight="500">
                    Sinta-se a vontade para deixar um comentário
                  </Typography.H5>
                )}
              </div>
              {typeTippy === "escalate" ? (
                <>
                  {/* <Select
                    value={valueEscalate}
                    options={escalateList}
                    onSelect={(option) => {
                      setValueEscalate(option.id);
                    }}
                    width="100%"
                    placeholder="Selecione"
                  /> */}
                  <DropdownEscalate
                    className="dropdown-status__body"
                    value={valueEscalate}
                    options={escalateList}
                    onClick={(option) => {
                      setValueEscalate(option.id);
                    }}
                    disable={false}
                    width="100%"
                    placeholder="Selecione"
                  />
                  <div className="dropdown-status__footer">
                    {/* <button
                      onClick={hide}
                      className="dropdown-status__footer-cancel"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={() => selectedSectorEstagger()}
                      className="dropdown-status__footer-confirm"
                    >
                      Confirmar
                    </button> */}
                    <Button
                      className="dropdown-status__footer-cancel"
                      onClick={hide}
                    >
                      <Typography.H4 weight={400} color="red-2">
                        Cancelar
                      </Typography.H4>
                    </Button>
                    <Button
                      className="dropdown-status__footer-confirm"
                      onClick={() => selectedSectorEstagger()}
                    >
                      <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {files.length > 0 && (
                    <CarouselAttach
                      files={files}
                      setFiles={setFiles}
                      limit={2}
                    />
                  )}
                  {screen === "details" && (
                    <SendingComment
                      onAdd={onAdd}
                      setFiles={setFiles}
                      files={files}
                      setLoadingComment={setLoadingComment}
                      loadingComment={loadingComment}
                      onClose={hide}
                    />
                  )}
                </>
              )}
            </div>
          }
        >
          <div
            className={`dropdown-status dropdown-status--${type} ${className}`}
            style={{ width: width ? width : "" }}
            ref={ref}
          >
            <button
              className={`dropdown-status-btn  
                ${type} ${isOpen ? "active" : ""} 
                ${isFlashing ? "flash-animation" : ""} 
                dropdown-status--border 
                ${error ? "dropdown-status--error" : ""}`}
              onClick={() => {
                if (type === "tag") {
                  return;
                }

                setIsOpen(!isOpen);
              }}
            >
              {status ? (
                <div className="dropdown-chip-status">
                  {isNewStatusList ? (
                    <div
                      className="circle-status"
                      style={{ backgroundColor: screen === "task" ? colorDefinition(status?.label) : (status?.cor ?? "orange-f4511e") }}
                    />
                  ) : (
                    <div
                      className={`circle-status 
                      ${screen === "task" ? defineColor(status) : status.cor ?? "orange-f4511e"}`}
                    />
                  )}

                  <Typography.H5
                    className={`label-tag ${status?.label === "Em andamento" ? "label-tag__small" : ""}`}
                    weight="500"
                    color={`gray-180`}
                  >
                    {status?.label}
                  </Typography.H5>
                </div>
              ) : (
                <Typography.H5
                  className={`label-tag ${status?.label === "Em andamento" ? "label-tag__small" : ""}`}
                  weight="500"
                  color={`gray-175`}
                >
                  Selecione
                </Typography.H5>
              )}

              {type === "select" && <IconArrowDown />}
            </button>

            {/* ################## DESIGN ANTIGO ################## */}
            {/* <button
              className={`dropdown-status-btn 
                ${type} 
                ${isOpen ? "active" : ""} dropdown-status--${defineColor(status)}`}
              onClick={() => {
                if (type === "tag") {
                  return;
                }
                setIsOpen(!isOpen);
              }}
            >

              <Typography.H6 weight="600" color={`${defineColor(status)}`}>
                {status?.label}
              </Typography.H6>
              {type === "select" && <IconArrowDown />}
            </button> */}

            {type === "select" && (
              <div
                className={`dropdown-status-list ${isOpen ? "active" : ""} 
                ${searchStatus ? "dropdown-status-list--search-status" : ""}`}
                style={{ maxHeight: maxHeight ? maxHeight : "" }}
              >
                {searchStatus && listLength > 5 && (
                  <Input
                    type="text"
                    placeholder="Buscar"
                    value={inputSearch}
                    onChange={(event) => setInputSearch(event)}
                  />
                )}

                {isNewStatusList ? (
                  <>
                    {filteredStatus.length ? (
                      filteredStatus.map((option, index) => (
                        <button
                          type="button"
                          key={index}
                          className={`dropdown-status-list__item 
                            ${changeDisplay && !option.display ? "disable-display" : ""}`}
                          onClick={() => {
                            setIsOpen(false);

                            if (onClick) {
                              onClick(option);

                              if (option.id === commentIdStatus) {
                                setTypeTippy("comment");
                                show();
                              }
                            }
                          }}
                        >
                          <div
                            className="circle-status"
                            style={{ backgroundColor: screen === "task" ? colorDefinition(option.label) : (option?.cor ?? "orange-f4511e") }}
                          />

                          {option.label.length > 15 ? (
                            <Tooltip
                              html={(
                                <Typography.H5>
                                  {option.label}
                                </Typography.H5>
                              )}
                            >
                              <Typography.Paragraph weight="600" color={`gray-180`}>
                                {`${option.label.slice(0, 15)}...`}
                              </Typography.Paragraph>
                            </Tooltip>
                          ) : (
                            <Typography.Paragraph weight="600" color={`gray-180`}>
                              {option.label}
                            </Typography.Paragraph>
                          )}
                        </button>
                      ))
                    ) : (
                      <div className="dropdown-status-list__empty">
                        <MdSearchOff size={24} color="gray" />

                        <Typography.H6 weight="600" color={`gray-180`}>
                          Nenhuma opção encontrada.
                        </Typography.H6>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {filteredStatus.length ? (
                      filteredStatus.map((option, index) => (
                        <button
                          type="button"
                          key={index}
                          className={`dropdown-status-list__item 
                            ${changeDisplay && !option.display ? "disable-display" : ""}`}
                          onClick={() => {
                            setIsOpen(false);

                            if (onClick) {
                              onClick(option);

                              if (option.id === commentIdStatus) {
                                setTypeTippy("comment");
                                show();
                              }
                            }
                          }}
                        >
                          <div
                            className={`circle-status 
                              ${screen === "task" ? defineColor(option) : option.cor ?? "orange-f4511e"} `}
                          />

                          {option.label.length > 15 ? (
                            <Tooltip
                              html={(
                                <Typography.H5>
                                  {option.label}
                                </Typography.H5>
                              )}
                            >
                              <Typography.Paragraph weight="600" color={`gray-180`}>
                                {`${option.label.slice(0, 15)}...`}
                              </Typography.Paragraph>
                            </Tooltip>
                          ) : (
                            <Typography.Paragraph weight="600" color={`gray-180`}>
                              {option.label}
                            </Typography.Paragraph>
                          )}
                        </button>
                      ))
                    ) : (
                      <div className="dropdown-status-list__empty">
                        <MdSearchOff size={24} color="gray" />

                        <Typography.H6 weight="600" color={`gray-180`}>
                          Nenhuma opção encontrada.
                        </Typography.H6>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </Tippy>
      );
    }
  }

  useOutsideClick(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  //USE EFFECTS
  useEffect(() => {
    if (newStatus?.length > 0 && changedStatus) {
      startFlash();
    }
  }, [newStatus?.length, changedStatus]);

  // console.log("screen", screen);

  return <>{renderDropdownStatus()}</>;
}
