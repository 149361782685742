//GLOBAL - components from npm
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { useHistory } from "react-router";
import jschardet from "jschardet";
import { CSVLink } from "react-csv";

//STYLES
import "./create-batch-cases.scss";

//ASSETS - icons, images...
import { ReactComponent as IconUploadFile } from "../../assets/icons/UploadFile.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/Trash-2.svg";
import { ReactComponent as IconCsv } from "../../assets/icons/file-csv-icon.svg";
import { ReactComponent as ArrowLefIcon } from "../../assets/icons/Arrow-left.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/create-success-icon.svg";

//COMPONENTS
import { Button, Typography } from "../../components/general";

// UTILS
import { cloneDeep } from "lodash";
import { Toast } from "../../utils/toast";
import { reduceString } from "../../utils/reduceString";
import { Loading, Tippy } from "../../components/feedback";
import TableCreateCases from "../../components/TableCreateCasesV2/TableCreateCases";
import {
  createCaseByCase,
  deleteTicket,
  insertTicket,
  listImportationFields,
  uploadFile,
} from "../../services/api/createBatchCases";
import { connection_platform } from "../../services/api/configConnection";
import { format, parse } from "date-fns";
import Paperplane from "../../components/feedback/Loading/Paperplane";
//import TableCreateCases from "../../components/TableCreateCases";
/*
const options = [
  { id: 1, label: "contato", active: true },
  { id: 2, label: "empresa", active: true },
  { id: 3, label: "organização", active: true },
  { id: 4, label: "tipo", active: true },
  { id: 5, label: "motivo", active: true },
  { id: 6, label: "sub-motivo", active: true },
  { id: 7, label: "prazo", active: true },
  { id: 8, label: "prioridade", active: true },
  { id: 9, label: "canal de origem", active: true },
  { id: 11, label: "status", active: true },
  { id: 12, label: "escalado para", active: true },
  { id: 13, label: "assunto", active: true },
  { id: 14, label: "descrição", active: true },
  { id: "undefined", label: "Ignorar", active: true },
];
*/
export default function CreateBatchCases({ isOpen, onClose }) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.userData);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: { "text/csv": [] },
    onDrop,
    onError,
  });
  const history = useHistory();

  //STATE
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [tableHeader, setTableHeader] = useState(null);
  const [initialOptionsSelecteds, setInitialOptionsSelecteds] = useState(null);
  const [selectOptions, setSelectOptions] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isloadingParsingData, setIsloadingParsingData] = useState(false);
  const [isTableActive, setIsTableActive] = useState(false);
  const [pathOfFile, setPathOfFile] = useState(null);
  const [importationTicketId, setImportationTicketId] = useState(null);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [noRenderPage, setNoRenderPage] = useState(false);
  const [options, setOptions] = useState();
  const [optionsSelecteds, setOptionsSelecteds] = useState(null);
  const [data, setData] = useState([]);
  const [isSuccessImport, setIsSuccessImport] = useState(false);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [totalFailded, setTotalFailded] = useState(0);
  const [dataToExcel, setDataToExcel] = useState(null);
  const [percentage, setPercentage] = useState("0.00%");
  const [isLoadingImportation, setIsLoadingImportation] = useState(false);

  const hasPathOfFile = !!pathOfFile;

  //FUNCTIONS
  const detectEncoding = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const binaryStr = new TextDecoder().decode(arrayBuffer);
      const result = jschardet.detect(binaryStr);
      return result.encoding;
    };

    reader.onerror = () => {
      console.log("detectEncoding ERROR");
    };

    reader.readAsArrayBuffer(file);
  };

  function renderPage() {
    if (isLoadingImportation) {
      return (
        <div className="modal-create-batch-cases__loading-importation">
          <Loading.Paperplane />
          <span className="modal-create-batch-cases__loading-importation__percentage">
            {percentage}
          </span>
        </div>
      );
    }
    if (isSuccessImport) {
      return (
        <div className="modal-create-batch-cases">
          <header className="modal-create-batch-cases__header">
            <div className="modal-create-batch-cases__header__title">
              <ArrowLefIcon
                onClick={() => {
                  history.push("/casos");
                }}
              />
              <Typography.H2 color="gray-180" weight="bold">
                Importar casos em massa
              </Typography.H2>
            </div>

            <div className="modal-create-batch-cases__header__buttons">
              <Button
                className="modal-create-batch-cases__header__buttons__btn-cancel"
                onClick={() => {
                  history.push("/casos");
                }}
              >
                <Typography.H4 weight={"bold"} color="red-2">
                  Cancelar
                </Typography.H4>
              </Button>
              <Button
                disabled={!isTableActive}
                className=""
                onClick={handleInportCases}
              >
                <Typography.H4 weight="bold" color="white">
                  Importar arquivo
                </Typography.H4>
              </Button>
            </div>
          </header>
          <div className="create-batch-cases-success">
            <div className="create-batch-cases-success__container">
              <SuccessIcon />
              <h1 className="create-batch-cases-success__container__title">
                Casos importados com sucesso!
              </h1>
              <span className="create-batch-cases-success__container__subtitle">
                {`${totalSuccess} novos casos foram importados com sucesso e ${totalFailded} falharam.`}
                {dataToExcel && (
                  <CSVLink
                    className="create-batch-cases-success__container__subtitle__download"
                    data={dataToExcel}
                    filename="casos-importados.csv"
                    separator=";"
                  >
                    Baixe os resultados
                  </CSVLink>
                )}
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="modal-create-batch-cases">
        <header className="modal-create-batch-cases__header">
          <div className="modal-create-batch-cases__header__title">
            <ArrowLefIcon
              onClick={() => {
                history.push("/casos");
              }}
            />
            <Typography.H2 color="gray-180" weight="bold">
              Importar casos em massa
            </Typography.H2>
          </div>

          <div className="modal-create-batch-cases__header__buttons">
            <Button
              className="modal-create-batch-cases__header__buttons__btn-cancel"
              onClick={() => {
                history.push("/casos");
              }}
            >
              <Typography.H4 weight={"bold"} color="red-2">
                Cancelar
              </Typography.H4>
            </Button>
            <Button
              disabled={!isTableActive}
              className=""
              onClick={handleInportCases}
            >
              <Typography.H4 weight="bold" color="white">
                Importar arquivo
              </Typography.H4>
            </Button>
          </div>
        </header>
        <div
          className={
            isTableActive
              ? "modal-create-batch-cases__content"
              : "modal-create-batch-cases__content empty"
          }
        >
          <div
            className={
              isTableActive
                ? "modal-create-batch-cases__content__file-input"
                : "modal-create-batch-cases__content__file-input empty"
            }
          >
            <div
              className={
                isTableActive
                  ? ""
                  : "modal-create-batch-cases__content__file-input__section empty"
              }
            >
              <section
                className={
                  isTableActive
                    ? ""
                    : "modal-create-batch-cases__content__file-input__section container empty"
                }
              >
                <div
                  className="modal-create-batch-cases__content__file-input__section__drop-container"
                  style={{
                    borderBottom: isTableActive
                      ? "1px solid #0000001A"
                      : "none",
                  }}
                >
                  {!isTableActive ? (
                    <Typography.H6>
                      Para importar os casos, selecione um arquivo CSV
                    </Typography.H6>
                  ) : null}
                  {file && file.length ? (
                    <div className="modal-create-batch-cases__content__file-input__file-selected">
                      <div
                        className="modal-create-batch-cases__content__file-input__file-selected__container-file-info"
                        style={{ paddingBottom: isTableActive ? 12 : 0 }}
                      >
                        <IconCsv />
                        <div className="modal-create-batch-cases__content__file-input__file-selected__file-info">
                          <Typography.H4 weight="700" color="blue-selected-4">
                            {reduceString(file[0].path, 30)}
                          </Typography.H4>
                          <span>{bytesToMegaBytes(file[0].size)}</span>
                        </div>
                      </div>
                      <div className="modal-create-batch-cases__content__file-input__file-selected__buttons">
                        {hasPathOfFile && (
                          <a
                            href={`${connection_platform.uploadFile}${pathOfFile}`}
                            download={file[0].path}
                            className="modal-create-batch-cases__content__file-input__file-selected__buttons__download"
                          >
                            <DownloadIcon />
                            <span>Download</span>
                          </a>
                        )}
                        <button
                          className="modal-create-batch-cases__content__file-input__file-selected__buttons__delete"
                          onClick={handleDeleteFile}
                        >
                          <div className="colored-svg">
                            <TrashIcon />
                          </div>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      {...getRootProps()}
                      className={`${
                        isTableActive
                          ? "modal-create-batch-cases__content__file-input__drop-zone"
                          : "modal-create-batch-cases__content__file-input__drop-zone empty"
                      } ${
                        errorMessage
                          ? "modal-create-batch-cases__content__file-input__drop-zone--error"
                          : ""
                      }`}
                    >
                      <input {...getInputProps()} />
                      <IconUploadFile />
                      <div>
                        <Typography.H5 weight="500" color="black">
                          Arraste e solte um arquivo CSV
                        </Typography.H5>
                        <span>Selecione o arquivo</span>
                      </div>
                    </div>
                  )}
                  {!isTableActive ? (
                    <Button
                      className="btn-create-batch-cases"
                      iconFillType="stroke"
                      disabled={file ? false : true}
                      onClick={() => {
                        setIsTableActive(true);
                        handleCreateBatchCase(file);
                      }}
                    >
                      <Typography.H5 weight="bold" color={"white"}>
                        Renderizar arquivo
                      </Typography.H5>
                    </Button>
                  ) : null}
                  {!isTableActive ? (
                    <div className="modal-create-batch-cases__content__file-input__section__drop-container__file-example">
                      <span className="modal-create-batch-cases__content__file-input__section__drop-container__file-example__title">
                        Não tem um arquivo CSV?
                        <br />
                        Faça o download do nosso template e utilize.
                      </span>
                      <div className="modal-create-batch-cases__content__file-input__section__drop-container__file-example__download-file">
                        <div>
                          <div className="modal-create-batch-cases__content__file-input__file-selected">
                            <div
                              className="modal-create-batch-cases__content__file-input__file-selected__container-file-info"
                              style={{ paddingBottom: isTableActive ? 12 : 0 }}
                            >
                              <IconCsv />
                              <div className="modal-create-batch-cases__content__file-input__file-selected__file-info">
                                <Typography.H4
                                  weight="700"
                                  color="blue-selected-4"
                                >
                                  Importar Casos_Template.csv
                                </Typography.H4>
                                <span>295 bytes</span>
                              </div>
                            </div>
                            <CSVLink
                              data={[
                                {
                                  Canal: "WhatsApp",
                                  Assunto: "assunto teste",
                                  Contato: "Raimundo",
                                  Descrição: "descrição teste",
                                  Prazo: "30/10/2024 18:30",
                                  Prioridade: "critica",
                                  Status: "Pendente",
                                  Tipo: "Desenvolvimento",
                                  Organização: "Ingenium",
                                  Telefone: "75991490038",
                                  "Data de Abertura": "20/05/2024 14:00",
                                },
                              ]}
                              filename="casos-template.csv"
                              separator=";"
                            >
                              <button>
                                <div>Download</div>
                              </button>
                            </CSVLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </section>
            </div>
          </div>
          {isTableActive ? (
            tableHeader &&
            initialOptionsSelecteds &&
            selectOptions &&
            tableData?.length &&
            !isloadingParsingData ? (
              <TableCreateCases
                tableHeader={tableHeader}
                initialOptionsSelecteds={initialOptionsSelecteds}
                selectOptions={selectOptions}
                tableData={tableData}
                data={data}
                setData={setData}
                optionsSelecteds={optionsSelecteds}
                setOptionsSelecteds={setOptionsSelecteds}
              />
            ) : (
              <Loading.Paperplane />
            )
          ) : null}
        </div>
      </div>
    );
  }

  function bytesToMegaBytes(valueInBytes) {
    let valueInMb = "0.00";

    const hasValueInBytes = !!valueInBytes || valueInBytes === 0;
    if (hasValueInBytes) {
      valueInMb = (valueInBytes / 1048576).toFixed(2) + "MB";

      const isValueInMbShort = valueInMb === "0.00MB";
      if (isValueInMbShort) {
        valueInMb = (valueInBytes / 1024).toFixed(2) + "KB";

        const isValueInKbShort = valueInMb === "0.00KB";
        if (isValueInKbShort) {
          valueInMb = valueInBytes + " Bytes";
        }
      }
    }
    return valueInMb;
  }

  function handleDownloadFile() {}
  async function handleInportCases() {
    setIsLoadingImportation(true);
    const hasOptions = !!options && options.length > 0;
    if (hasOptions) {
      const allMandatoryOptions = options.filter((option) => {
        return option.obrigatorio;
      });

      const hasMandatoryOptions =
        !!allMandatoryOptions && allMandatoryOptions.length > 0;
      if (hasMandatoryOptions) {
        const isAllMandatoryOptionsSelecteds = allMandatoryOptions.every(
          (mandatoryOption) => {
            for (const key in optionsSelecteds) {
              const selectedInfos = optionsSelecteds[key];
              const isSelectedIdEqualMandatoryId =
                selectedInfos.id === mandatoryOption.id;
              if (isSelectedIdEqualMandatoryId) {
                return true;
              }
            }

            return false;
          }
        );

        if (!isAllMandatoryOptionsSelecteds) {
          Toast(
            "",
            "É preciso relacionar todos os campos obrigatórios",
            "error"
          );
          setIsLoadingImportation(false);
          return;
        }
      }
    }

    const dataToImportCases = [];

    const hasAllData = !!allData && allData.length > 0;
    if (hasAllData) {
      allData.forEach((data) => {
        const valor = [];
        function insertDataToImport() {
          for (const key in data) {
            const value = data[key].data.props.content;
            const optionSelected = optionsSelecteds[key];
            const id_campo_importacao = optionSelected.id;
            const id_campo_personalizado =
              optionSelected.id_campo_personalizado;
            const id_tipo_campo_adicional =
              optionSelected.id_tipo_campo_adicional;

            const hasIdCampoPersonalizado =
              !!id_campo_personalizado || id_campo_personalizado === 0;
            const hasIdCampoImportacao = id_campo_importacao !== "undefined";

            if (hasIdCampoImportacao && hasIdCampoPersonalizado) {
              const hasIdTipoCampoAdicional = !!id_tipo_campo_adicional;
              const isDateHourType =
                hasIdTipoCampoAdicional && id_tipo_campo_adicional === 2;
              const isDateType =
                hasIdTipoCampoAdicional && id_tipo_campo_adicional === 3;

              if (isDateHourType) {
                let formatedValue = value;

                try {
                  const hasValue = !!value;

                  if (hasValue) {
                    let formatedDateTimeValue = value;

                    const hasOnlyDate =
                      !!value &&
                      typeof value === "string" &&
                      value.trim().length === 10;
                    if (hasOnlyDate) {
                      formatedDateTimeValue = value.trim() + " 23:59";
                    }

                    const hasSeconds =
                      !!value &&
                      typeof value === "string" &&
                      value.trim().length === 19;
                    if (hasSeconds) {
                      formatedDateTimeValue = value.trim().slice(0, 16);
                    }

                    const parsedDate = parse(
                      formatedDateTimeValue,
                      "dd/MM/yyyy HH:mm",
                      new Date()
                    );

                    const formatedDate = format(parsedDate, "yyyy-MM-dd HH:mm");
                    formatedValue = formatedDate;
                  }
                } catch (error) {
                  console.log("Data inválida");
                }

                valor.push({
                  id: id_campo_importacao,
                  valor: formatedValue,
                  id_campo_adicional: id_campo_personalizado.toString(),
                });
              } else if (isDateType) {
                let formatedValue = value;

                try {
                  const hasValue = !!value;

                  if (hasValue) {
                    const parsedDate = parse(value, "dd/MM/yyyy", new Date());

                    const formatedDate = format(parsedDate, "yyyy-MM-dd");
                    formatedValue = formatedDate;
                  }
                } catch (error) {
                  console.log("Data inválida");
                }

                valor.push({
                  id: id_campo_importacao,
                  valor: formatedValue,
                  id_campo_adicional: id_campo_personalizado.toString(),
                });
              } else {
                valor.push({
                  id: id_campo_importacao,
                  valor: !!value || value === 0 ? value.toString() : "",
                  id_campo_adicional: id_campo_personalizado.toString(),
                });
              }
            } else if (hasIdCampoImportacao && !hasIdCampoPersonalizado) {
              const isDateType =
                id_campo_importacao === 16 || id_campo_importacao === 5;
              if (isDateType) {
                let formatedValue = value;

                try {
                  const hasValue = !!value;
                  if (hasValue) {
                    let formatedDateTimeValue = value;

                    const hasOnlyDate =
                      !!value &&
                      typeof value === "string" &&
                      value.trim().length === 10;
                    if (hasOnlyDate) {
                      formatedDateTimeValue = value.trim() + " 23:59";
                    }

                    const hasSeconds =
                      !!value &&
                      typeof value === "string" &&
                      value.trim().length === 19;
                    if (hasSeconds) {
                      formatedDateTimeValue = value.trim().slice(0, 16);
                    }

                    const parsedDate = parse(
                      formatedDateTimeValue,
                      "dd/MM/yyyy HH:mm",
                      new Date()
                    );

                    const formatedDate = format(parsedDate, "yyyy-MM-dd HH:mm");

                    formatedValue = formatedDate;
                  }
                } catch (error) {
                  console.log("Data inválida");
                }

                valor.push({
                  id: id_campo_importacao,
                  valor: formatedValue,
                  id_campo_adicional: "",
                });
              } else {
                valor.push({
                  id: id_campo_importacao,
                  valor: value || value === 0 ? value.toString() : "",
                  id_campo_adicional: "",
                });
              }
            }
          }
        }

        insertDataToImport();

        dataToImportCases.push({
          id_ticket_importacao: importationTicketId,
          idusuario: user.userId,
          valor: valor,
        });
      });
    }

    const hasDataToImportCases =
      !!dataToImportCases && dataToImportCases.length > 0;
    if (hasDataToImportCases) {
      const dataInsertedSuccess = [];
      const dataNotInserted = [];
      let count = 0;
      const totalOfCases = dataToImportCases.length;
      const percentage = ((count / totalOfCases) * 100).toFixed(2) + "%";
      setPercentage(percentage);

      for (let caseToImport of dataToImportCases) {
        const response = await createCaseByCase(caseToImport);

        const isDataInserted =
          !!response &&
          response.status === 200 &&
          !!response.data &&
          response.data.success === true;
        if (isDataInserted) {
          dataInsertedSuccess.push({
            data: caseToImport,
            status: response.data,
          });
        }

        const isDataNotInserted =
          !!response &&
          response.status === 200 &&
          (!!response.data || response.data === "") &&
          !response.data.success;
        if (!isDataInserted) {
          dataNotInserted.push({
            data: caseToImport,
            status: response ? response.data : "",
          });
        }

        count = count + 1;
        const percentage = ((count / totalOfCases) * 100).toFixed(2) + "%";
        setPercentage(percentage);
      }

      setTotalSuccess(dataInsertedSuccess.length);
      setTotalFailded(dataNotInserted.length);

      function createExcelData(dataNotInserted, dataInsertedSuccess) {
        let updatedExcelData = [];

        const hasDataNotInserted =
          !!dataNotInserted && dataNotInserted.length > 0;
        if (hasDataNotInserted) {
          dataNotInserted.forEach((notInsertedData) => {
            const hasValue =
              !!notInsertedData.data &&
              !!notInsertedData.data.valor &&
              notInsertedData.data.valor.length > 0;

            if (hasValue) {
              let item = {
                "status da importação": "erro",
                "Código do caso": "",
              };
              notInsertedData.data.valor.forEach((value) => {
                const hasIdCustomField = !!value.id_campo_adicional;

                if (hasIdCustomField) {
                  const optionInfos = options.find((option) => {
                    return (
                      option.id_campo_adicional === value.id_campo_adicional
                    );
                  });

                  if (optionInfos) {
                    item[optionInfos.label] = value.valor;
                  }
                } else {
                  const optionInfos = options.find((option) => {
                    return option.id === value.id;
                  });

                  if (optionInfos) {
                    item[optionInfos.label] = value.valor;
                  }
                }
              });

              updatedExcelData.push(item);
            }
          });
        }

        const hasDataInserted =
          !!dataInsertedSuccess && dataInsertedSuccess.length > 0;
        if (hasDataInserted) {
          dataInsertedSuccess.forEach((insertedData) => {
            const hasValue =
              !!insertedData.data &&
              !!insertedData.data.valor &&
              insertedData.data.valor.length > 0;
            const hasStatus =
              !!insertedData.status && !!insertedData.status.content;
            if (hasValue && hasStatus) {
              let item = {
                "status da importação": "sucesso",
                "Código do caso": insertedData.status.content.codigo_ticket,
              };

              insertedData.data.valor.forEach((value) => {
                const hasIdCustomField = !!value.id_campo_adicional;

                if (hasIdCustomField) {
                  const optionInfos = options.find((option) => {
                    return (
                      option.id_campo_adicional === value.id_campo_adicional
                    );
                  });

                  if (optionInfos) {
                    item[optionInfos.label] = value.valor;
                  }
                } else {
                  const optionInfos = options.find((option) => {
                    return option.id === value.id;
                  });

                  if (optionInfos) {
                    item[optionInfos.label] = value.valor;
                  }
                }
              });

              updatedExcelData.push(item);
            }
          });
        }

        const hasUpdatedExcelData =
          !!updatedExcelData && updatedExcelData.length > 0;
        if (hasUpdatedExcelData) {
          setDataToExcel(updatedExcelData);
        } else {
          setDataToExcel(null);
        }
      }

      createExcelData(dataNotInserted, dataInsertedSuccess);
    }

    setIsSuccessImport(true);
    setIsLoadingImportation(false);
  }

  function parseCSVAsync(file) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  function returnToInitialState() {
    setErrorMessage(null);
    setFile(null);
    setIsTableActive(false);
  }
  function onCustomClose() {
    returnToInitialState();
    onClose();
  }

  async function parseCSVInChunks(csv) {
    let lineNumber = 0;
    let currentChunk = [];
    let lines = csv.split("\n");
    let allChunks = [];
    let dataOfCsv = [];

    //  setIsloadingParsingData(true);

    for (let i = 0; i < lines.length; i++) {
      currentChunk.push(lines[i]);

      if (++lineNumber % 100000 === 0) {
        allChunks.push(currentChunk);
        currentChunk = [];
      }
    }

    if (currentChunk.length > 0) {
      allChunks.push(currentChunk);
    }

    if (allChunks && allChunks.length) {
      let header = null;
      let index = 0;

      for (const chunk of allChunks) {
        let updatedChunk = cloneDeep(chunk);
        let csvDataChunk = null;

        if (index === 0 && chunk && chunk.length) {
          header = chunk[0];
        } else if (index !== 0 && header && Array.isArray(updatedChunk)) {
          updatedChunk.unshift(header);
        }

        if (updatedChunk && Array.isArray(updatedChunk)) {
          csvDataChunk = updatedChunk.join("\n");
        }

        if (csvDataChunk) {
          const results = await parseCSVAsync(csvDataChunk);

          if (results && results.data && results.data.length) {
            const updatedCsvData = results.data;
            dataOfCsv = [...dataOfCsv, ...updatedCsvData];
          }
        }

        index = index + 1;
      }
    }

    if (dataOfCsv && dataOfCsv.length) {
      const firstObject = dataOfCsv[0];
      const columnsNames = Object.keys(firstObject);

      let updatedTableHeader = [];
      let updatedInitialOptionsSelecteds = {};
      let updatedOptions = cloneDeep(options);
      if (dataOfCsv.length <= 10) {
        const updatedTableData = dataOfCsv.map((data) => {
          //const namesOfColumns = Object.keys(data);
          const namesOfColumns = columnsNames;
          let newData = {};
          if (namesOfColumns && namesOfColumns.length) {
            namesOfColumns.forEach((colName, index) => {
              newData[
                colName.trim().toLowerCase().replace(/\s/g, "")[0] + index
              ] = {
                data: (
                  <Tippy content={data[colName]}>
                    <div>{reduceString(data[colName], 20)}</div>
                  </Tippy>
                ),
                config: { textEllipsis: false },
              };
            });
          }
          return newData;
        });

        setTableData(updatedTableData);
        setData(updatedTableData);
        setAllData(updatedTableData);
      } else {
        // const partOfDataOfCsv = dataOfCsv.slice(0, 10);

        const updatedTableData = dataOfCsv.map((data) => {
          const namesOfColumns = Object.keys(data);
          let newData = {};
          if (namesOfColumns && namesOfColumns.length) {
            namesOfColumns.forEach((colName, index) => {
              newData[
                colName.trim().toLowerCase().replace(/\s/g, "")[0] + index
              ] = {
                data: (
                  <Tippy content={data[colName]}>
                    <div>{reduceString(data[colName], 20)}</div>
                  </Tippy>
                ),
                config: { textEllipsis: false },
              };
            });
          }
          return newData;
        });

        setTableData(updatedTableData.slice(0, 10));
        setData(updatedTableData.slice(0, 10));
        setAllData(updatedTableData);
      }

      if (columnsNames && columnsNames.length) {
        columnsNames.forEach((col, index) => {
          let indexOfRelatedOption = -1;
          if (updatedOptions && updatedOptions.length) {
            indexOfRelatedOption = updatedOptions.findIndex((opt) => {
              return opt.label.toLowerCase() === col.toLowerCase();
            });
          }

          if (indexOfRelatedOption !== -1) {
            const lastValue = updatedOptions[indexOfRelatedOption];
            updatedOptions[indexOfRelatedOption] = {
              ...lastValue,
              active: false,
            };
            updatedInitialOptionsSelecteds[
              `${col.trim().toLowerCase().replace(/\s/g, "")[0] + index}`
            ] = {
              ...lastValue,
              id: lastValue.id,
              label: lastValue.label,
              active: false,
            };
          } else {
            updatedInitialOptionsSelecteds[
              `${col.trim().toLowerCase().replace(/\s/g, "")[0] + index}`
            ] = {
              id: "undefined",
              label: "Ignorar",
              active: true,
            };
          }

          updatedTableHeader.push({
            Header: col,
            accessor: col.trim().toLowerCase().replace(/\s/g, "")[0] + index,
            id: col.trim().toLowerCase().replace(/\s/g, "")[0] + index,
          });
        });
      }

      if (updatedTableHeader.length) {
        setTableHeader(updatedTableHeader);
      } else {
        setTableHeader(null);
      }
      if (Object.keys(updatedInitialOptionsSelecteds).length !== 0) {
        setInitialOptionsSelecteds(updatedInitialOptionsSelecteds);
        setOptionsSelecteds(updatedInitialOptionsSelecteds);
      } else {
        setInitialOptionsSelecteds(null);
        setOptionsSelecteds(null);
      }

      setSelectOptions(updatedOptions);
    }
    //setIsloadingParsingData(false);

    return dataOfCsv;
  }

  async function handleCreateBatchCase(csvFile) {
    setIsloadingParsingData(true);
    const csvToParse = csvFile && csvFile.length ? csvFile[0] : null;

    const form = new FormData();
    if (csvToParse) {
      form.append("files", csvToParse);

      const response = await uploadFile(token, form);
      const hasResponse = !!response;
      const isFileUploaded =
        hasResponse &&
        response.status === 200 &&
        !!response.data &&
        response.data.length > 0;
      if (isFileUploaded) {
        const filePath = response.data[0].path;
        setPathOfFile(filePath);

        const responseOfInsert = await insertTicket(
          token,
          user.userId,
          filePath,
          csvToParse.path
        );
        const hasResponse = !!responseOfInsert;
        const isFileInserted =
          hasResponse &&
          responseOfInsert.status === 200 &&
          !!responseOfInsert.data &&
          !!responseOfInsert.data.Dados &&
          responseOfInsert.data.Dados.length > 0;

        if (isFileInserted) {
          const ticketId = responseOfInsert.data.Dados[0].id_ticket_importacao;
          setImportationTicketId(ticketId);

          const reader = new FileReader();
          reader.onload = async function (e) {
            const reader2 = new FileReader();
            reader2.onload = async function (e) {
              const csv = e.target.result;

              await parseCSVInChunks(csv);
            };

            const arrayBuffer = e.target.result;
            const textDecoder = new TextDecoder();
            const binaryStr = textDecoder.decode(arrayBuffer);
            const result = jschardet.detect(binaryStr);
            const decode = result.encoding;
            //console.log("decode", decode);
            reader2.readAsText(csvToParse, decode);
          };

          reader.readAsArrayBuffer(csvToParse);
        } else {
          Toast(
            "",
            "Erro ao tentar inserir o arquivo, tente novamente",
            "error"
          );
        }
      } else {
        Toast(
          "",
          "Erro ao tentar carregar o arquivo, tente novamente",
          "error"
        );
      }
    }

    setIsloadingParsingData(false);
  }

  function onError() {
    setErrorMessage("Erro ao carregar o arquivo, tente novamente");
    Toast("", "Erro ao carregar o arquivo, tente novamente", "error");
  }
  async function handleDeleteFile() {
    const response = await deleteTicket(
      token,
      user.userId,
      importationTicketId
    );
    const hasResponse = !!response;
    const isDeleteSuccess = hasResponse && response.status === 200;

    if (isDeleteSuccess) {
      setFile(null);
      setIsTableActive(false);
      setIsloadingParsingData(false);
      setTableData([]);
      setData([]);
      setSelectOptions(null);
      setInitialOptionsSelecteds(null);
      setOptionsSelecteds(null);
      setTableHeader(null);
      setPathOfFile(null);
      setImportationTicketId(null);
    } else {
      Toast(
        "",
        "Não foi possível deletar o arquivo, tente novamente mais tarde!",
        "error"
      );
    }
  }
  function onDrop(acceptedFiles, rejectedFiles) {
    const isFileRejected = rejectedFiles && rejectedFiles.length;
    const isFileAccepted = acceptedFiles && acceptedFiles.length;

    if (isFileRejected && rejectedFiles.length === 1) {
      setErrorMessage("Apenas arquivos do tivo CSV podem ser exportados");
      Toast("", "Apenas arquivos do tipo CSV podem ser exportados", "error");
      return;
    }
    if (isFileRejected && rejectedFiles.length > 1) {
      setErrorMessage("Só é permitido o upload de um arquivo");
      Toast("", "Só é permitido o upload de um arquivo", "error");
      return;
    }
    if (isFileAccepted) {
      setErrorMessage(null);
      setFile(acceptedFiles);
      setIsTableActive(true);
      handleCreateBatchCase(acceptedFiles);
    }
  }

  //USE EFFECT

  useEffect(() => {
    const getOptions = async () => {
      setIsLoadingOptions(true);
      const response = await listImportationFields(token);
      const hasResponse = !!response;
      const hasOptions =
        hasResponse &&
        response.status === 200 &&
        !!response.data &&
        response.data.length > 0;
      let updatedOptions = null;
      if (hasOptions) {
        const fields = response.data;
        updatedOptions = fields.map((field) => {
          field.id = field.id_campo_importacao;
          field.label = field.desc_campo_importacao;
          field.active = true;
          return field;
        });
      }

      const hasUpdatedOptions = updatedOptions !== null;
      if (!hasUpdatedOptions) {
        setNoRenderPage(true);
        Toast(
          "",
          "Erro ao carregar a página tente novamente mais tarde",
          "error"
        );
      } else {
        updatedOptions.unshift({
          id: "undefined",
          label: "Ignorar",
          active: true,
        });
      }

      setOptions(updatedOptions);
      setIsLoadingOptions(false);
    };

    getOptions();
  }, [token]);

  if (noRenderPage) {
    return null;
  }

  return isLoadingOptions ? <Loading.Paperplane /> : renderPage();
}
