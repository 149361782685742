import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import { Table, ImageProfile } from "../.";
import { PopoverMenu, PopoverColumnsFilter } from "../../navigation";
import { Typography, Button } from "../../general";

//SERVICES - api, conectors...
import { setDashTableFilter } from "../../../store/dashboard";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as IconMoreVertical } from "../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as ColumnFilterIcon } from "../../../assets/icons/ColumnFilterIcon.svg";
import { ReactComponent as FileSearching } from "../../../assets/icons/File-searching.svg";

export default function CardPrimary({ card, darkMode }) {
  //GENERAL
  const dispatch = useDispatch();

  //STATES

  const [collaboratorsData, setCollaboratorsData] = useState(
    card.collaboratorsData
  );
  const [emptyState, setEmptyState] = useState();

  //REDUX - Selectors
  const exhibColumn = useSelector((state) => state.dashboard.dashExhibColumn);

  //FUNCTIONS
  function chooseDisplay(nameDisplay) {
    const indexFind = exhibColumn.findIndex(
      (column) => column.title.toLowerCase() === nameDisplay.toLowerCase()
    );

    return exhibColumn[indexFind].exhib;
  }

  function defaultSorting(rowA, rowB, columnId) {
    const objectRowA = parseInt(rowA.original[columnId].data);
    const objectRowB = parseInt(rowB.original[columnId].data);

    if (objectRowA > objectRowB) return -1;
    if (objectRowB > objectRowA) return 1;
    return 0;
  }

  function textCustomSorting(rowA, rowB, columnId) {
    const objectRowA = rowA.original[columnId].config.orderCustom.toLowerCase();
    const objectRowB = rowB.original[columnId].config.orderCustom.toLowerCase();

    if (objectRowA > objectRowB) return -1;
    if (objectRowB > objectRowA) return 1;
    return 0;
  }

  function refactorCollaboratorsList(data) {
    let dataRefactored = [];

    data.forEach((datum) => {
      const collaboratorRefactored = {
        collaborators: {
          data: (
            <div className="collaborator-profile">
              <ImageProfile profile={{ nome_usuario: datum.nome }} />
              <span>{datum.nome}</span>
            </div>
          ),
          config: {
            className: "cell-blue-text",
            orderCustom: datum.nome,
          },
        },
        fcr: {
          data: datum.first_call_resolution,
          config: {
            textEllipsis: false,
          },
        },
        resolved: {
          data: datum.concluido,
          config: {
            textEllipsis: false,
          },
        },
        progress: {
          data: datum.andamento,
          config: {
            textEllipsis: false,
          },
        },
        pending: {
          data: datum.pendente,
          config: {
            textEllipsis: false,
          },
        },
        outTime: {
          data: datum.fora_sla,
          config: {
            textEllipsis: false,
          },
        },
        onTime: {
          data: datum.dentro_sla,
          config: {
            textEllipsis: false,
          },
        },
        action: {
          data: (
            <PopoverMenu
              placement="bottom-end"
              menuData={renderPopoverMenuContent(
                datum.id_solicitante,
                datum.id_ticket
              )}
            >
              <button className="dashboard-btn-options" type="button">
                <IconMoreVertical />
              </button>
            </PopoverMenu>
          ),
          config: {},
        },
      };
      dataRefactored.push(collaboratorRefactored);
    });
    return dataRefactored;
  }

  function renderPopoverMenuContent(idSolicitante, idTicket) {
    // if (userData.permissionId === 1 && idSolicitante !== userData.userId) {
    //     return (
    //         [
    //             {
    //                 title: <Typography.H6 color='primary-dark' weight='medium'>Editar</Typography.H6>,
    //                 route: `/casos/${idTicket}`
    //             }
    //         ]
    //     )
    // } else {
    //     return (
    //         [
    //             {
    //                 title: <Typography.H6 color='primary-dark' weight='medium'>Editar</Typography.H6>,
    //                 route: `/casos/${idTicket}`
    //             },
    //             {
    //                 title: <Typography.H6 color='red' weight='medium'>Remover</Typography.H6>,
    //                 onClick: () => {
    //                     deleteCase(idTicket)
    //                 }
    //             },
    //         ]
    //     )
    // }
  }

  //USE EFFECTS

  // MEMOS
  const columns = useMemo(() => [
    {
      Header: "Colaboradores",
      accessor: "collaborators",
      id: "collaborators",
      disableFilters: true,
      sortType: textCustomSorting,
      display: true,
    },
    {
      Header: "FCR",
      accessor: "fcr",
      id: "fcr",
      sortType: defaultSorting,
      disableFilters: true,
      display: chooseDisplay("FCR"),
    },
    {
      Header: "Resolvidos",
      accessor: "resolved",
      id: "resolved",
      sortType: defaultSorting,
      disableFilters: true,
      display: chooseDisplay("Resolvidos"),
    },
    {
      Header: "Andamento",
      accessor: "progress",
      id: "progress",
      sortType: defaultSorting,
      disableFilters: true,
      display: chooseDisplay("Andamento"),
    },
    {
      Header: "Pendente",
      accessor: "pending",
      id: "pending",
      sortType: defaultSorting,
      disableFilters: true,
      display: chooseDisplay("Pendente"),
    },
    {
      Header: "Fora do Prazo",
      accessor: "outTime",
      id: "outTime",
      sortType: defaultSorting,
      disableFilters: true,
      display: chooseDisplay("Fora do Prazo"),
    },
    {
      Header: "No Prazo",
      accessor: "onTime",
      id: "onTime",
      sortType: defaultSorting,
      disableFilters: true,
      display: chooseDisplay("No Prazo"),
    },
    {
      Header: "Ação",
      accessor: "action",
      id: "action",
      disableSortBy: true,
      disableFilters: true,
      display: false,
    },
  ]);

  const collaborators = useMemo(() => {
    if (collaboratorsData) {
      const dataRefactored = refactorCollaboratorsList(collaboratorsData);
      // setPageStatus("awaiting");
      return dataRefactored;
    }
  }, [collaboratorsData]);

  return (
    <div className="card-dashboard__perfomance">
      <div className="card-dashboard__perfomance-header">
        <Typography.H4 color={darkMode ? "white" : "gray-180"} weight={700}>
          {card.title}
        </Typography.H4>
        {!card.emptySpace && (
          <PopoverColumnsFilter
            className={"filters-columns"}
            placement="bottom-end"
            menuData={exhibColumn}
            setChangesActive={(event) =>
              dispatch(
                setDashTableFilter({
                  value: event,
                  localStorageKey: "dashExhibColumn",
                  localStorageValue: event,
                })
              )
            }
          >
            <div className="section-dashboard-columns">
              <Button
                className={"btn-filters-columns"}
                icon={
                  <ColumnFilterIcon
                    className={darkMode ? `column-filter-dark` : ""}
                  />
                }
              >
                <Typography.H5
                  weight={"bold"}
                  color={darkMode ? "white" : "blue-selected-4"}
                >
                  Colunas
                </Typography.H5>
              </Button>
            </div>
          </PopoverColumnsFilter>
        )}
      </div>
      {card.emptySpace ? (
        <div className="card-dashboard-emptySpace line1">
          <FileSearching width={120} height={120} />
          <Typography.H5 color={darkMode ? "white" : "gray-180"}>
            Oops! A sua pesquisa não gerou resultados.
          </Typography.H5>
        </div>
      ) : (
        <section className="section-dashboard-list">
          <Table
            className="table-dashboard"
            data={collaborators}
            columns={columns}
            fixedHeader
            filtersActive={[]}
            emptyState={emptyState}
            setEmptyState={setEmptyState}
            initialState={[]}
            darkModeState={darkMode}
          />
        </section>
      )}
    </div>
  );
}
