//GLOBAL - components from npm
import React from "react";

//STYLES
import "./box-status.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function BoxStatus({
  color
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <div className={`box-status__${color}`}></div>
  );
}
