import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Creatable from "react-select/creatable";

import { FieldContainer } from "../../../components/data-display";
import { Toast } from "../../../utils/toast";
import { Button, Typography } from "../../../components/general";
import { Loading, Modal } from "../../../components/feedback";
import { Input, Select, SelectTag } from "../../../components/data-entry";

import List from "../List/List";

import * as APISla from "./../../../services/api/sla";
import * as APITypeSolicitation from "./../../../services/api/typeSolicitation";
import * as APIMotive from "./../../../services/api/motives";

import { ReactComponent as EmptyStateIcon } from "../../../assets/images/empty-state-sla02.svg";
import { ReactComponent as IconConfig } from "./../../../assets/icons/IconConfig.svg";
import { ReactComponent as IconSearch } from "./../../../assets/icons/Search-normal.svg";
import { ReactComponent as IconArrowDown } from "./../../../assets/icons/ArrowDown.svg";

import "./datas.scss";

export default function Datas() {
    const location = useLocation();
    const dispatch = useDispatch();

    const token = useSelector((state) => state.auth.token);

    const customStyles = {
        input: (provided) => ({
            ...provided,
            flexGrow: "1",
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            color: "#27282e",
            transition: "all 0.25s",
            outline: "none",
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: "160px",
            position: "absolute",
            display: "flex",
            zIndex: 15,
            flexDirection: "column",
            borderTop: "0",
            overflow: "auto",
            border: "1px solid #2684FF",
            boxShadow: "none",
            borderColor: "#455cc7",
        }),
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
            boxShadow: "none",
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: "80px",
            overflow: "auto",
            "::-webkit-scrollbar": {
                width: "5px",
            },
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            ":active": {
                color: "hsl(0, 0%, 80%)",
                transform: "rotate(180deg)"
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#bfc3ca",
        }),
        option: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            minHeight: "min-content",
            padding: "12px",
            textAlign: "left",

            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
            color: "#828292",
            cursor: "pointer",

            ":active": {
                backgroundColor: "#ebf2ff",
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            display: "flex",
            alignItems: "center",
            gap: "4px",
            griGap: "4px",
            padding: "4px 4px 4px 8px",
            backgroundColor: "#f4f4f4",
            borderRadius: "24px",
            minWidth: "max-content",
            height: "25px",
            marginRight: "8px",
            marginBottom: "4px",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "112.5%",
            padding: "5px",
            alignItems: "flex-end",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100px",
            color: "#455cc7",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            height: "20px",
            width: "20px",
            background: "#bfc3ca",
            color: "#fff",
            borderRadius: "50%",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "0.5em",
            fontSize: "18px",
            cursor: "pointer",

            ":hover": {
                backgroundColor: "none",
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,

            ":hover": {
                transform: "rotate(180deg)",
            },
        }),
    };

    const [inputSearch, setInputSearch] = useState("");
    const [pageStatus, setPageStatus] = useState("requesting");
    const [activeButton, setActiveButton] = useState(false);
    const [validationWarn, setValidationWarn] = useState(false);
    const [isOpenModalManageMotives, setIsOpenModalManageMotives] = useState(false);
    const [createMotiveIsLoading, setCreateMotiveIsLoading] = useState(false);
    const [arrowActive, setArrowActive] = useState(false);
    const [listSla, setListSla] = useState();
    const [solicitationData, setSolicitationData] = useState();
    const [solicitationDataUniqueValues, setSolicitationDataUniqueValues] = useState();
    const [listOptionEditable, setListOptionEditable] = useState();
    const [motivesData, setMotivesData] = useState();
    const [idMotive, setIdMotive] = useState([]);
    const [optionsEditable] = useState([{ id_editavel: 1, desc_editavel: "Sim" }, { id_editavel: 2, desc_editavel: "Não" },]);
    const [valueMotive, setValueMotive] = useState(() => { return { desc_motivo: "" }; });
    const [values] = useState(() => {
        return {
            descricao_solicitacao: "",
            id_motivo: [],
            id_sla: null,
            editavel: 1,
        };
    });
    const [formValues, setFormvalues] = useState({ ...values });

    console.log("form values solicitation", formValues);
    console.log("solicitationDataUniqueValues", solicitationDataUniqueValues);

    let filterSolicitations = solicitationDataUniqueValues?.filter(
        (solicitation) => {
            return solicitation.descricao_solicitacao
                .toLowerCase()
                .includes(inputSearch.toLowerCase());
        }
    );

    const removeOptionMotive = (idRemoved) => {
        let elements = arrayRemove(idMotive, idRemoved);
        setIdMotive(elements);
    };

    const changeOptionsMotive = (option) => {
        const arrayFound = idMotive.find(
            (element) => element.id === option.id
        );
        if (arrayFound) {
            removeOptionMotive(arrayFound.id);
        } else {
            setIdMotive([
                ...idMotive,
                { id: option.id, label: option.label },
            ]);
        }
    };

    const DropdownIndicator = () => {
        return (
            <div className={arrowActive ? "indicatorArrow active" : "indicatorArrow"}>
                <IconArrowDown onClick={() => setArrowActive(!arrowActive)} />
            </div>
        )
    };

    function arrayRemove(arr, value) {
        return arr.filter(function (elem) {
            return elem.id != value;
        });
    }

    async function onKeyDownCreateMotive(event) {
        if (event.key !== "Enter") return;

        const inputValue = event.target.value;

        if (!inputValue.trim()) return;

        let index = motivesData?.findIndex(
            (value) => value.desc_motivo === inputValue
        );

        if (index < 0) {
            setCreateMotiveIsLoading(true);
            setMotivesData([
                ...motivesData,
                {
                    id_motivo: null,
                    desc_motivo: inputValue,
                },
            ]);

            valueMotive.desc_motivo = inputValue;

            const response = await APIMotive.createMotive(token, valueMotive);

            if (response.status === 200) {
                getMotivesList();

                Toast(
                    "Motivo adicionado!",
                    "Motivo adicionado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível adicionar o motivo",
                    "Tente novamente mais tarde!",
                    "error"
                );
            }

            setCreateMotiveIsLoading(false);
        } else {
            Toast(
                "Não foi possível criar esse o motivo!",
                "Esse motivo ja existe, adicione outro com o nome diferente",
                "warn"
            );
        }
    };

    async function getTypeSolicitationDataList() {
        setPageStatus("requesting");

        const response = await APITypeSolicitation.listTypesSolicitation(token);

        if (response.status === 200) {
            setSolicitationData(response.data);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    async function getMotivesList() {
        const response = await APIMotive.listMotives(token);

        if (response.status === 200) {
            setMotivesData(response.data);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde",
                "error"
            );
        }
    };

    async function getListSla() {
        const response = await APISla.listSla(token);

        const data = response.data;
        let updatedData = [];

        data.forEach((sla) => {
            let updateSla = {
                id: sla.id_sla,
                label: sla.desc_sla,
            };

            updatedData.push(updateSla);
        });

        setListSla(updatedData);
    };

    function optionsEdit() {
        const data = optionsEditable;

        let updateData = [];

        data.forEach((option) => {
            let updateEditable = {};

            updateEditable.id = option.id_editavel;
            updateEditable.label = option.desc_editavel;

            updateData.push(updateEditable);
        });

        setListOptionEditable(updateData);
    };

    function renderSolicitationsList() {
        if (pageStatus === "requesting") {
            return (
                <section className="solicitation-list-content">
                    <Loading.Paperplane className="loading-list" />
                </section>
            );
        } else if (pageStatus === "awaiting") {
            if (solicitationData?.length > 0) {
                return (
                    <>
                        {filterSolicitations?.length === 0 ? (
                            <section className="emptySate-solicitation">
                                <EmptyStateIcon />
                            </section>
                        ) : (
                            <section className="solicitation-list-content">
                                {filterSolicitations?.map((datum) => (
                                    <div className="card" key={datum.id_solicitacao}>
                                        <List
                                            datum={datum}
                                            listSla={listSla}
                                            listOptionEditable={listOptionEditable}
                                            solicitationData={solicitationData}
                                            motivesData={motivesData}
                                            solicitationDataUniqueValues={solicitationDataUniqueValues}
                                            setMotivesData={setMotivesData}
                                            setSolicitationData={setSolicitationData}
                                            setSolicitationDataUniqueValues={setSolicitationDataUniqueValues}
                                            getMotivesList={getMotivesList}
                                            listSolicitation={getTypeSolicitationDataList}
                                        />
                                    </div>
                                ))}
                            </section>
                        )}
                    </>
                );
            }
        } else {
            return (
                <section className="emptySate-solicitation">
                    <EmptyStateIcon />
                </section>
            );
        }
    };

    function cleanFields() {
        setTimeout(() => {
            setFormvalues({
                ...values,
                descricao_solicitacao: "",
                id_motivo: [],
                id_sla: null,
                editavel: 1,
            });
            setValueMotive({ ...valueMotive, desc_motivo: [] });
            setPageStatus("awaiting");
        }, 50);
    };

    function isFormValidated() {
        if (
            !formValues.descricao_solicitacao ||
            !formValues.id_sla ||
            !formValues.editavel
        )
            return false;

        return true;
    };

    async function handleCreateTypeSolicitation() {
        if (isFormValidated()) {
            setPageStatus("creating-solicitation");

            let index = solicitationDataUniqueValues?.findIndex(
                (value) =>
                    value.descricao_solicitacao === formValues.descricao_solicitacao &&
                    value.id_sla === formValues.id_sla &&
                    value.id_motivo?.forEach(item => item) === formValues.id_motivo.forEach(item => item)
            );

            if (index < 0) {
                if (formValues.editavel === 1) {
                    formValues.editavel = true;
                } else if (formValues.editavel === 2) {
                    formValues.editavel = false;
                }

                formValues.id_motivo = [...new Set(formValues.id_motivo)];

                const response = await APITypeSolicitation.createSolicitation(token, formValues);

                if (response.status === 200) {
                    cleanFields();
                    getTypeSolicitationDataList();

                    Toast(
                        "Solicitação adicionada!",
                        "Solicitação adicionada com sucesso.",
                        "success"
                    );
                } else {
                    Toast(
                        "Não foi possível adicionar a Solicitação",
                        "Tente novamente mais tarde!",
                        "error"
                    );
                }
            } else {
                Toast(
                    "ATENÇÃO!",
                    "Não é possível inserir duas vezes a mesma condição(Nome da solicitação + SLA + Motivo)",
                    "warn"
                );
            }

            setPageStatus("awaiting");
        } else if (
            !formValues.descricao_solicitacao ||
            !formValues.id_sla ||
            !formValues.editavel
        ) {
            setValidationWarn(true);

            Toast(
                "Erro ao adicionar Solicitação!",
                "Verifique o preenchimento dos campos em destaque",
                "error"
            );
        }
    };

    useEffect(() => {
        getTypeSolicitationDataList();
        getListSla();
        optionsEdit();
        getMotivesList();
    }, []);

    useEffect(() => {
        if (values) {
            setFormvalues({ ...values });
        }
    }, [values]);

    useEffect(() => {
        if (
            formValues.descricao_solicitacao === "" &&
            formValues.id_motivo === [] &&
            formValues.id_sla === null &&
            formValues.editavel === null
        ) {
            setActiveButton(false);
        } else {
            setActiveButton(true);
        }
    }, [
        formValues.descricao_solicitacao,
        formValues.id_motivo,
        formValues.id_sla,
        formValues.editavel,
        activeButton,
        dispatch,
    ]);

    useEffect(() => {
        if (valueMotive.desc_motivo) {
            valueMotive.desc_motivo?.forEach((value) => {
                formValues.id_motivo.push(value.value);
            });
        }
    }, [valueMotive]);

    useEffect(() => {
        let result = [...new Set(solicitationData?.map((item) => item.id_solicitacao))].map((item) => {
            return {
                desc_motivo: solicitationData?.find((value) => value.id_solicitacao == item).desc_motivo,
                desc_sla: solicitationData?.find((value) => value.id_solicitacao == item).desc_sla,
                descricao_solicitacao: solicitationData?.find((value) => value.id_solicitacao == item).descricao_solicitacao,
                editavel: solicitationData?.find((value) => value.id_solicitacao == item).editavel,
                id_sla: solicitationData?.find((value) => value.id_solicitacao == item).id_sla,
                id_tipo_solicitacao_sla: solicitationData?.find((value) => value.id_solicitacao == item).id_tipo_solicitacao_sla,
                id_solicitacao: item,
                id_motivo: solicitationData?.filter((value) => value.id_solicitacao == item).map((data) => data.id_motivo),
            };
        });

        setSolicitationDataUniqueValues(result);
    }, [solicitationData]);

    useEffect(() => {
        solicitationDataUniqueValues?.forEach(item => {
            item = Object.assign(item, { id_editavel: null, desc_editavel: null })

            if (item.editavel === true) {
                item.id_editavel = 1
                item.desc_editavel = "Sim"
            } else {
                item.id_editavel = 2
                item.desc_editavel = "Não"
            }
        })
    }, [solicitationDataUniqueValues]);

    return (
        <>
            <main className={`page-solicitation ${location.pathname === "/typeSolicitation" ? "page-modify" : ""}`}>
                <header className="header-solicitation">
                    <div className="header-solicitation__title">
                        <div className="header-title">
                            <Typography.H2 color="gray-180" weight="bold">
                                Cadastrar Tipos de Solicitação e Motivos
                            </Typography.H2>
                        </div>
                    </div>

                    <div className="header-solicitation__option">
                        <Input
                            width={"300px"}
                            type="text"
                            className="input-search-motives"
                            placeholder="Buscar"
                            onChange={(event) => setInputSearch(event)}
                            rightIcon={
                                <button>
                                    <IconSearch />
                                </button>
                            }
                        />

                        <Button
                            className="manage-motives"
                            iconFillType="stroke"
                            icon={<IconConfig />}
                            onClick={() => setIsOpenModalManageMotives(true)}
                        >
                            <Typography.H4 weight="bold" color={"white"}>
                                Gerenciar motivos
                            </Typography.H4>
                        </Button>
                    </div>
                </header>

                <div className="page-solicitation-body">
                    <section className="container-sections-solicitation">
                        <div className="panel-registration">
                            <FieldContainer required title="Nome da solicitação">
                                <Input
                                    type="text"
                                    placeholder="Digite o nome da sua solicitação"
                                    value={formValues.descricao_solicitacao}
                                    onChange={(event) =>
                                        setFormvalues({
                                            ...formValues,
                                            descricao_solicitacao: event,
                                        })
                                    }
                                    error={
                                        validationWarn ? !formValues.descricao_solicitacao : false
                                    }
                                />
                            </FieldContainer>

                            <FieldContainer required title="SLA">
                                <Select
                                    placeholder="Selecione o SLA"
                                    options={listSla}
                                    value={formValues.id_sla}
                                    onSelect={(sla) =>
                                        setFormvalues({ ...formValues, id_sla: sla.id })
                                    }
                                    error={validationWarn ? !formValues.id_sla : false}
                                />
                            </FieldContainer>

                            <FieldContainer required title="Editável">
                                <Select
                                    placeholder="Selecione"
                                    options={listOptionEditable}
                                    value={formValues.editavel}
                                    onSelect={(edit) =>
                                        setFormvalues({ ...formValues, editavel: edit.id })
                                    }
                                    error={validationWarn ? !formValues.editavel : false}
                                />
                            </FieldContainer>

                            <FieldContainer title="Motivos">
                                <Creatable
                                    isClearable
                                    isMulti
                                    formatCreateLabel={() => {
                                        return <p>
                                            Esse motivo não existe.
                                            Pressione "Enter" para poder criá-lo
                                        </p>
                                    }}
                                    styles={customStyles}
                                    isLoading={createMotiveIsLoading}
                                    value={valueMotive.desc_motivo}
                                    components={{ DropdownIndicator }}
                                    placeholder="Selecione ou adicione um novo motivo"
                                    onKeyDown={onKeyDownCreateMotive}
                                    onChange={(newReason) =>
                                        setValueMotive({ ...valueMotive, desc_motivo: newReason })
                                    }
                                    options={motivesData?.map((motive) => {
                                        return {
                                            value: motive.id_motivo,
                                            label: motive.desc_motivo

                                        };
                                    })}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: "#455cc7",
                                        },
                                    })}
                                />
                            </FieldContainer>
                        </div>

                        <footer className="container-sections-solicitation__footer">
                            <div className="buttons-container-solicitation">
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "8px",
                                        alignItems: "center",
                                        cursor: `${!activeButton ? "default" : "pointer"}`,
                                    }}
                                >
                                    <Button
                                        className="buttons-container-solicitation__btn-cancel"
                                        onClick={cleanFields}
                                        disabled={pageStatus === "creating-solicitation"}
                                    >
                                        <Typography.H4 weight={"bold"} color="red-2">
                                            Cancelar
                                        </Typography.H4>
                                    </Button>

                                    <Button
                                        className="buttons-container-solicitation__btn-confirm"
                                        onClick={() => handleCreateTypeSolicitation()}
                                        disabled={pageStatus === "creating-solicitation"}
                                    >
                                        {pageStatus !== "creating-solicitation" ? (
                                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                                        ) : (
                                            <Loading.Dots />
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </footer>
                    </section>

                    {renderSolicitationsList()}
                </div>
            </main>

            <Modal.ManageMotives
                isOpen={isOpenModalManageMotives}
                onClose={(event) => setIsOpenModalManageMotives(event)}
                motivesData={motivesData}
                setMotivesData={setMotivesData}
                getMotivesList={getMotivesList}
            />
        </>
    );
}
