//GLOBAL - components from npm
import React, { useEffect, useState, useRef } from "react";

// UTILS
import { Toast } from "../../../utils/toast";

//STYLES
import "./field-container-edit.scss";

//COMPONENTS
import { Typography, Button } from "../../../components/general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconPencil } from "../../../assets/icons/Pencil.svg";
import { ReactComponent as IconConfirm } from "../../../assets/icons/Confirm-circle.svg";
import { ReactComponent as IconCancel } from "../../../assets/icons/Cancel-circle.svg";

export default function FieldContainerEdit({
  iconTitle,
  title = "titulo",
  content,
  onEdit,
  className,
  height,
  required,
  type,
  iconsEdit,
  buttonsEdit,
  typography,
  canBeEditable = true,
}) {
  //GENERAL
  const ref = useRef();

  //STATES
  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState(content);

  //REDUX - Selectors

  //FUNCTIONS
  // const resizeRows = () => {
  //   while (ref.current.scrollHeight > ref.current.offsetHeight && type === "no-scroll") {
  //       ref.current.rows += 1;
  //     }
  // }

  // const autoResize = () => {
  //   while (ref.current.scrollHeight > ref.current.offsetHeight && type === "no-scroll") {
  //     ref.current.rows += 1;
  //   }
  // };

  //USE EFFECTS
  // useEffect(() => {
  //   resizeRows();
  // }, [])

  return (
    <div className={`${className} field-container-edit`}>
      <div className="field-container-edit-title">
        {iconTitle}
        {typography ? (
          <>{typography}</>
        ) : (
          <Typography.H5 color="gray-500" weight="bold">
            {title}
          </Typography.H5>
        )}
        {/* {openEdit ? (
          <div className="field-container-edit-title__icons">
            <button
              onClick={() => {
                setOpenEdit(false);
                if ((!value || !value.trim()) && required) {
                  setValue(content);
                  return Toast(
                    "Erro ao editar!",
                    `O campo '${title}' é obrigatório, por favor digite algo para editar.`,
                    "error"
                  );
                }
                onEdit(value);
              }}
            >
              <IconConfirm />
            </button>
            <button
              onClick={() => {
                setValue(content);
                setOpenEdit(false);
              }}
            >
              <IconCancel />
            </button>
          </div>
        ) : (
          <div className="field-container-edit-title__icons">
            <button onClick={() => setOpenEdit(true)}>
              <IconPencil />
            </button>
          </div>
        )} */}
      </div>
      <div
        className={`field-container-edit-content ${
          iconsEdit && "normal-flex"
        } `}
        onDoubleClick={(event) => {
          event.stopPropagation();
          if (canBeEditable) {
            setOpenEdit(true);
          }
        }}
      >
        <>
          <textarea
            className="textarea-edit"
            style={{
              height: height,
            }}
            //rows={1}
            ref={ref}
            //onKeyDown={() => autoResize()}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            readOnly={!openEdit}
          />

          {openEdit && iconsEdit ? (
            <div className="field-container-edit-title__icons">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenEdit(false);
                  if ((!value || !value.trim()) && required) {
                    setValue(content);
                    return Toast(
                      "Erro ao editar!",
                      `O campo '${title}' é obrigatório, por favor digite algo para editar.`,
                      "error"
                    );
                  }
                  onEdit(value);
                }}
              >
                <IconConfirm />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setValue(content);
                  setOpenEdit(false);
                }}
              >
                <IconCancel />
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      </div>
      {openEdit && !iconsEdit && (
        <div className="field-container-edit-title__buttons">
          <Button
            className="field-container-edit-title__btn-confirm"
            onClick={() => {
              setOpenEdit(false);
              if ((!value || !value.trim()) && required) {
                setValue(content);
                return Toast(
                  "Erro ao editar!",
                  `O campo '${title}' é obrigatório, por favor digite algo para editar.`,
                  "error"
                );
              }
              onEdit(value);
            }}
          >
            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
          </Button>

          <Button
            className="field-container-edit-title__btn-cancel"
            onClick={() => {
              setValue(content);
              setOpenEdit(false);
            }}
          >
            <Typography.H4 weight={"bold"} color="red-2">
              Cancelar
            </Typography.H4>
          </Button>
        </div>
      )}
    </div>
  );
}
