//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import { format } from "date-fns";
import TablePagination from "@mui/material/TablePagination";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

//STYLES
import "./table.scss";

//COMPONENTS
import { OrderButton, Typography } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...
import { useDispatch } from "react-redux";
import { setInitialSort } from "../../../store/tableFilters";
import { useLocation } from "react-router-dom";

//ASSETS - icons, images...

export default function Table({
  className = "",
  data,
  columns,
  fixedHeader,
  fixedFirstColumn,
  filtersActive,
  emptyState = false,
  setEmptyState = () => null,
  controlledTableState,
  initialState,
  darkModeState,
  keepLinePerPage,
  noPagination,
  canSaveInitialState = false,
  ...others
}) {
  // DEFAULT PROPS
  const defaultColumns = useMemo(
    () => [
      {
        Header: "Column 1",
        accessor: "column1",
        id: "column1",
        // disableFilters: true,
        // sortType: function customSortFunction(rowA, rowB, columnId) {
        //   const dataRowA = parseInt(rowA.original[columnId].data.props.children);
        //   const dataRowB = parseInt(rowB.original[columnId].data.props.children);

        //   if (dataRowA > dataRowB) return -1;
        //   if (dataRowB > dataRowA) return 1;
        //   return 0;
        // },
        // filter: function customFilterFunction(rows, id, filterValue) {
        //   if (filterValue.length === 0)
        //     return rows

        //   return (
        //     rows.filter(row => filterValue.some(filter => (
        //       filter.id === row.original[id].config.filterValue
        //     )))
        //   )
        // },
      },
      {
        Header: "Column 2",
        accessor: "column2",
        id: "column2",
      },
      {
        Header: "Column 3",
        accessor: "column3",
        id: "column3",
      },
    ],
    []
  );
  const defaultData = useMemo(
    () => [
      {
        column1: {
          data: "Content column 1",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column2: {
          data: "Content column 2",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column3: {
          data: "Content column 3",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
      },
      {
        column1: {
          data: "Content column 1",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column2: {
          data: "Content column 2",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
        column3: {
          data: "Content column 3",
          config: {
            className: "",
            filterValue: "",
            customOrder: "",
            textEllipsis: false,
            customData: false,
          },
        },
      },
    ],
    []
  );
  // const defaultColumn = React.useMemo(
  //   () => ({
  //     width: 150,
  //     minWidth: 100,
  //     maxWidth: 250,
  //   }),
  //   []
  // );

  if (!data || !columns) {
    data = defaultData;
    columns = defaultColumns;
  }

  //GENERAL
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn,
      useControlledState: (state) => {
        // console.debug('state: ', state);
        return useMemo(
          () => ({
            ...state,
            ...controlledTableState,
          }),
          [state, controlledTableState]
        );
      },
      initialState: initialState,
    },
    useFilters,
    useSortBy,
    useResizeColumns
  );

  const theme = useTheme();
  const location = useLocation();

  //STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    keepLinePerPage
      ? () => {
        const saved = localStorage.getItem("linesPerPage");
        const initialValue = JSON.parse(saved);
        return initialValue || 10;
      }
      : className === "table-dashboard"
        ? 5
        : 10
  );
  const countPagination =
    parseInt(rows.length / rowsPerPage) +
    (rows.length % rowsPerPage !== 0 ? 1 : 0);

  //REDUX - Selectors
  const dispatch = useDispatch();

  //FUNCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function defaultLabelDisplayedRows({ from, to, count }) {
    return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`} itens`;
  }

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, ptBR),
    [ptBR, theme]
  );

  const displayHeader = (head) => {
    return head.display !== false;
  };

  const displayBody = (body) => {
    return body.column.display !== false;
  };

  function handleInitialSort(columnInfo) {
    if (canSaveInitialState) {
      dispatch(
        setInitialSort({
          localStorageKey: "initialSorted",
          localStorageValue: [
            {
              id: columnInfo.id,
              desc: columnInfo.isSortedDesc,
            },
          ],
        })
      );
    }
  }

  function renderCellContent(cell, row) {
    if (cell.value.config.customData) {
      // CUSTOM DATA
      return cell.value?.data;
    } else if (cell.value?.data instanceof Date) {
      // DATE INSTANCES
      return (
        <Typography.H6
          color={darkModeState ? "white" : "gray-180"}
          weight={400}
        >
          {format(cell.value.data, "dd'/'MM'/'y HH:mm")}
        </Typography.H6>
      );
    } else if (cell.value?.data?.type?.displayName === "Link") {
      // LINK COMPONENTS
      return (
        <Typography.H6
          weight={400}
          color={darkModeState ? "white" : "blue-selected-4"}
        >
          {cell.value?.data}
        </Typography.H6>
      );
    } else if (cell.value?.data?.label) {
      // OBJECTS
      return (
        <Typography.H6
          color={darkModeState ? "white" : "gray-180"}
          weight={400}
        >
          {cell.value.data.label}
        </Typography.H6>
      );
    } else {
      // OTHERS
      return (
        <Typography.H6
          color={darkModeState ? "white" : "gray-180"}
          weight={400}
        >
          {cell.value.data}
        </Typography.H6>
      );
    }
  }

  function renderCell(row) {
    return row.cells.filter(displayBody).map((cell) => {
      if (cell.value?.config.tippy) {
        return (
          <td
            {...cell.getCellProps()}
            className={`${cell.value?.config.className ? cell.value.config.className : ""
              } ${cell.value?.config.textEllipsis ? "cell-ellipsis" : ""}`}
          >
            <Tippy content={cell.value.data}>
              <div className="cell-container">
                {renderCellContent(cell, row)}
              </div>
            </Tippy>
          </td>
        );
      } else {
        return (
          <td
            {...cell.getCellProps()}
            className={`${cell.value?.config.className ? cell.value.config.className : ""
              } ${cell.value?.config.textEllipsis ? "cell-ellipsis" : ""} ${cell.column.id === "client" ||
                cell.column.id === "tasks" ||
                cell.column.id === "agent"
                ? "cell-width"
                : ""
              }`}
          >
            <div className={`cell-container`}>
              {renderCellContent(cell, row)}
            </div>
          </td>
        );
      }
    });
  }

  function renderPagination() {
    if (noPagination) return null;

    return (
      <div className={`pagination-table ${darkModeState ? "dark-mode" : ""}`}>
        <ThemeProvider theme={themeWithLocale}>
          <TablePagination
            rowsPerPageOptions={[
              5, 10, 15, 25, 50, 100,
              // 500,
              // { label: "All", value: -1 },
            ]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={defaultLabelDisplayedRows}
            backIconButtonProps={{ sx: { display: "none" } }}
            nextIconButtonProps={{ sx: { display: "none" } }}
            sx={{ fontFamily: "Inter" }}
          />
        </ThemeProvider>

        <Stack spacing={2}>
          <Pagination
            id="pagination-numbers"
            count={countPagination}
            siblingCount={1}
            // boundaryCount={2}
            color="primary"
            onChange={handleChangePage}
          />
        </Stack>
      </div>
    );
  }

  function renderTableBody() {
    if (noPagination) {
      return rows.map((row) => {
        prepareRow(row);
        const statusBg = row.values.status;
        return (
          <tr
            className={`${darkModeState ? "darkModeRow" : ""} 
              ${statusBg && statusBg.config.filterValue === 6 ? "newModeRow" : ""}`}
            {...row.getRowProps()}
          >
            {renderCell(row)}
          </tr>
        );
      });
    } else {
      return rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          prepareRow(row);
          const statusBg = row.values.status;
          return (
            <tr
              className={`${darkModeState ? "darkModeRow" : ""} 
                ${statusBg && statusBg.config.filterValue === 6 ? "newModeRow" : ""}`}
              {...row.getRowProps()}
            >
              {renderCell(row)}
            </tr>
          );
        });
    }
  }

  function checkEmptyState() {
    if (!emptyState && rows.length === 0) {
      setEmptyState(true);
    } else if (emptyState && rows.length > 0) {
      setEmptyState(false);
    }
  }

  checkEmptyState();
  // #TOIMPROVE: remover a função de checar o empty state se conseguir ter acesso aos estado atual das linhas (após filtrado) fora do component table

  //USE EFFECTS
  useEffect(() => {
    if (filtersActive?.status) {
      setFilter("status", filtersActive.status);
    }
  }, [filtersActive?.status]);

  useEffect(() => {
    if (
      keepLinePerPage &&
      (location.pathname === "/casos" || location.pathname === "/externo/casos")
    ) {
      window.localStorage.setItem("linesPerPage", JSON.stringify(rowsPerPage));
    }
  }, [rowsPerPage]);

  // useEffect(() => {
  //   if (className === "table-dashboard") {
  //     setRowsPerPage(4);
  //   }
  // }, []);
  return (
    <>
      <table
        className={`table 
          ${className} 
          ${fixedFirstColumn ? "table--fixed-first-column" : ""} 
          ${fixedHeader ? "table--fixed-header" : ""}`}
        {...getTableProps()}
        cellSpacing={0}
        {...others}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.filter(displayHeader).map((column) => {
                column.isSorted && handleInitialSort(column);
                return (
                  <th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: column.Header })
                    )}
                  >
                    <div className={`flex-container align-center`}>
                      <Typography.H6
                        weight="400"
                        color="white"
                        className={"header-column"}
                      >
                        {column.render("Header")}
                      </Typography.H6>
                      {column.canSort && (
                        <OrderButton
                          direction={
                            column.isSorted &&
                            (column.isSortedDesc ? "desc" : "asc")
                          }
                        />
                      )}
                      {/* <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? "isResizing" : ""
                        }`}
                      /> */}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {renderTableBody()}
        </tbody>
      </table>

      {renderPagination()}
    </>
  );
}
