import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode";

export async function listTypesSolicitation(token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get('/list-type-solicitation', config);

        for (const item of response.data) {
            item.descricao_solicitacao = unicodeToChar(item.descricao_solicitacao);
        }

        return response;
    } catch (error) {
        return error.message;
    }
};

export async function createSolicitation(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/create-associate-sla-and-type-solicitation', data, config);

        return response;
    } catch (error) {
        return error
    }
};

export async function createSolicitationV2(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/create-associate-sla-and-type-solicitation-v2', data, config);

        return response;
    } catch (error) {
        return error
    }
};

export async function updateSolicitation(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put(`/update-type-solicitation`, data, config);

        return response
    } catch (error) {
        return error;
    }
};


export async function updateSolicitationV2(token, data) { // id_sla, editavel, id_solicitacao, descricao_solicitacao, id_tipo_solicitacao_sla, id_motivo, id_organizacao
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put(`/update-type-solicitation-v2`, data, config);

        return response
    } catch (error) {
        return error;
    }
};

export async function deleteSolicitation(idSolicitation, idOrganization, token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.delete(`/delete-type-solicitation/${idSolicitation}/${idOrganization}`, config);

        return response;
    } catch (error) {
        return error;
    }
};

export async function deleteSolicitationV2(idSolicitation, idOrganization, token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.delete(`/delete-type-solicitation-v2/${idSolicitation}/${idOrganization}`, config);

        return response;
    } catch (error) {
        return error;
    }
};