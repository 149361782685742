//GLOBAL - components from npm
import React, { useState, useRef, useEffect, Fragment } from "react";

//STYLES
import "./multiple-select.scss";

//COMPONENTS
import { ImageProfile, FieldContainer } from "../../data-display";
import { Typography } from "../../general";
import { Tippy } from "../../feedback";
import { DropdownStatus } from "../../layout";

// CUSTOM HOOKS
import useOutsideClick from "../../../hooks/useOutsideClick";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as IconSearch } from "../../../assets/icons/Search.svg";
import { ReactComponent as EmptyFolder } from "../../../assets/images/empty-folder.svg";
import { Loading } from "../../feedback";

export default function MultipleSelect({
  placeholder = "Digite um titulo para buscar",
  value = undefined,
  width,
  className,
  tooltip = false,
  error = false,
  paginated = false,
  getOptions = () => null,
  showAvatar = true,
  onSelect = () => null,
  optionsActive = [],
  options = [
    {
      id: 1,
      label: "João Alves",
      caption1: "Ingenium",
      caption2: "#01232294101",
    },
    {
      id: 2,
      label: "João Costa",
      caption1: "Ingenium",
      caption2: "#01232294101",
    },
    {
      id: 3,
      label: "Lorenço Saraiva",
    },
    {
      id: 4,
      label: "Guilherme Gomes",
      caption1: "IngDesk",
    },
  ],
}) {
  //GENERAL
  const listStatus = [
    {
      id: 3,
      label: "Concluidos",
    },
    {
      id: 4,
      label: "Cancelados",
    },
    {
      id: 2,
      label: "Pendentes",
    },
    {
      id: 1,
      label: "Em andamento",
    },
    {
      id: 5,
      label: "Escalado",
    },
  ];

  // REFS
  const selectSearchRef = useRef(null);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const sentinelRef = useRef(null);

  //STATES
  const [isOpen, setIsOpen] = useState(false);
  const [optionsInternal, setOptionsInternal] = useState(
    !paginated ? options : []
  );
  const [currentOptionDisplay, setCurrentOptionDisplay] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [page, setPage] = useState(1);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSentinelVisible, setIsSentinelVisible] = useState(paginated);

  //REDUX - Selectors

  //FUNCTIONS
  async function updateOptions(title, page) {
    if (!paginated) return;

    setIsRequesting(true);
    const data = await getOptions(title, page);

    if (data.length > 0) {
      setIsSentinelVisible(true);
    }

    if (page > 1) {
      setOptionsInternal((oldState) => oldState.concat(data));
    } else {
      setOptionsInternal(data);
    }
    setIsRequesting(false);
  }

  function filterOptions(option) {
    if (paginated) return option;
    if (currentOptionDisplay) {
      if (
        option.label.toLowerCase().includes(currentOptionDisplay.toLowerCase())
      )
        return option;
      else return;
    } else {
      return option;
    }
  }

  function resetState(
    props = {
      options: false,
      listScrollPosition: false,
      currentOptionDisplay: false,
      page: false,
      searchTerm: false,
    }
  ) {
    props.options && updateOptions("", 1);
    props.listScrollPosition &&
      listRef.current &&
      listRef.current.scroll({ top: 0 });
    // props.currentOptionDisplay &&
    //   setCurrentOptionDisplay(currentOptionData ? currentOptionData.label : "");
    props.page && setPage(1);
    props.searchTerm && setSearchTerm("");
  }

  function onClickLabel() {
    if (!isOpen) {
      setIsOpen(true);
      //setCurrentOptionDisplay("");
    }

    inputRef.current.focus();
  }

  function onClickOption(option) {
    //setCurrentOptionDisplay(option.label);
    onSelect(option);

    //setIsOpen(false);
    resetState({
      options: true,
      listScrollPosition: false,
      page: page > 1,
      searchTerm: true,
    });
  }

  function observerCallback(entries) {
    if (isOpen && entries[0].isIntersecting) {
      setIsSentinelVisible(false);
      updateOptions(searchTerm, page + 1);
      setPage(page + 1);
    }
  }

  // CUSTOM HOOKS
  useOutsideClick(selectSearchRef, () => {
    if (isOpen) {
      setIsOpen(false);
      resetState({
        options: true,
        listScrollPosition: true,
        currentOptionDisplay: true,
        searchTerm: true,
      });

      if (page !== 1) setPage(1);
    }
  });

  //USE EFFECT
  useEffect(() => {
    updateOptions(currentOptionDisplay, page);
  }, []);

  useEffect(() => {
    if (!paginated) {
      setOptionsInternal(options);
    }
  }, [options]);

  

  useEffect(() => {
    if (paginated && isOpen && sentinelRef.current) {
      let options = {
        root: listRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };

      let observer = new IntersectionObserver(observerCallback, options);
      observer.observe(sentinelRef.current);

      return () => observer.disconnect();
    }
  }, [sentinelRef.current, isOpen, isRequesting]);

  return (
    <div
      ref={selectSearchRef}
      className={`multiple-select ${className ? className : ""} ${
        isOpen ? "multiple-select--active" : ""
      } ${error ? "multiple-select--error" : ""}`}
      style={{ width: width ? width : "" }}
    >
      <div className="multiple-select-btn" onClick={onClickLabel}>
        <input
          ref={inputRef}
          className="multiple-select-btn__input"
          value={value}
          placeholder={placeholder}
          onChange={(event) => {
            !isOpen && setIsOpen(true);
            setCurrentOptionDisplay(event.target.value);

            if (paginated && event.target.value.length === 0) {
              resetState({
                options: true,
                page: true,
                searchTerm: true,
                listScrollPosition: true,
              });
            }
          }}
          onKeyPress={(event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              resetState({
                options: paginated,
                listScrollPosition: true,
                page: paginated,
              });

              setSearchTerm(event.target.value);
              updateOptions(event.target.value, 1);
            }
          }}
        />
        {isOpen ? (
          <IconSearch
            className="multiple-select-btn__icon"
            onClick={(event) => {
              event.stopPropagation();
              resetState({
                options: paginated,
                listScrollPosition: true,
                page: paginated,
              });
              setSearchTerm(currentOptionDisplay);
              updateOptions(currentOptionDisplay, 1);
            }}
          />
        ) : (
          <IconArrowDown
            onClick={(e) => {
              e.stopPropagation();
              onClickLabel();
            }}
          />
        )}
      </div>

      <div className="multiple-select-list" ref={listRef}>
        {optionsInternal.filter(filterOptions)?.length > 0 ? (
          <>
            {optionsInternal.filter(filterOptions).map((opt, index) => (
              <Fragment key={`multiple-select-${index}`}>
                {tooltip ? (
                  <Tippy
                    theme="popover-default"
                    zIndex={9999}
                    content={
                      <div className="multiple-select-popover">
                        <FieldContainer
                          title="Contato:"
                          className="multiple-select-popover-contact"
                          htmlFor=""
                        >
                          <Typography.Paragraph color="gray-200">
                            {opt.option1}
                          </Typography.Paragraph>
                        </FieldContainer>
                        <FieldContainer
                          title="Empresa:"
                          className="multiple-select-popover-company"
                          htmlFor=""
                        >
                          <Typography.Paragraph color="gray-200">
                            {opt.option2 ? opt.option2 : "Sem Empresa"}
                          </Typography.Paragraph>
                        </FieldContainer>
                        <FieldContainer
                          title="Solicitado em:"
                          className="multiple-select-popover-date"
                          htmlFor=""
                        >
                          <Typography.Paragraph color="gray-200">
                            {opt.option3}
                          </Typography.Paragraph>
                        </FieldContainer>
                        <div className="multiple-select-popover-status">
                          <DropdownStatus
                            value={opt.option4}
                            options={listStatus}
                            type="tag"
                          />
                        </div>
                        <FieldContainer
                          title="Assunto:"
                          className="multiple-select-popover-subject"
                          htmlFor=""
                        >
                          <Typography.Paragraph color="gray-200">
                            {opt.option5}
                          </Typography.Paragraph>
                        </FieldContainer>
                      </div>
                    }
                    followCursor="horizontal"
                  >
                    <button
                      type="button"
                      className={`multiple-select-list__item ${
                        optionsActive.find(item => item.label === opt.label) ? "active" : ""
                      }`}
                      onClick={(event) => {
                        event.stopPropagation();
                        onClickOption(opt);
                      }}
                    >
                      {showAvatar && (
                        <ImageProfile
                          profile={{
                            nome_usuario: opt.label,
                            nome_organizacao: opt.caption1,
                          }}
                          tooltip={false}
                        />
                      )}

                      <div className="item-info">
                        <Typography.Paragraph
                          weight="bold"
                          color="gray-400"
                          className="item-info__label"
                        >
                          {opt.label ? opt.label : "Title"}
                        </Typography.Paragraph>
                        {opt.caption1 && (
                          <Typography.Paragraph
                            color="gray-400"
                            className="item-info__caption"
                          >
                            {opt.caption1}
                          </Typography.Paragraph>
                        )}
                        {opt.caption2 && (
                          <Typography.Paragraph
                            color="gray-400"
                            className="item-info__caption"
                          >
                            {opt.caption2}
                          </Typography.Paragraph>
                        )}
                      </div>
                    </button>
                  </Tippy>
                ) : (
                  <button
                    type="button"
                    className={`multiple-select-list__item ${
                      optionsActive.find(item => item.label === opt.label) ? "active" : ""
                    }`}
                    onClick={(event) => {
                      event.stopPropagation();
                      onClickOption(opt);
                    }}
                  >
                    {showAvatar && (
                      <ImageProfile
                        profile={{
                          nome_usuario: opt.label,
                          nome_organizacao: opt.caption1,
                        }}
                        tooltip={false}
                      />
                    )}

                    <div className="item-info">
                      <Typography.Paragraph
                        weight="bold"
                        color="gray-400"
                        className="item-info__label"
                      >
                        {opt.label ? opt.label : "Title"}
                      </Typography.Paragraph>
                      {opt.caption1 && (
                        <Typography.Paragraph
                          color="gray-400"
                          className="item-info__caption"
                        >
                          {opt.caption1}
                        </Typography.Paragraph>
                      )}
                      {opt.caption2 && (
                        <Typography.Paragraph
                          color="gray-400"
                          className="item-info__caption"
                        >
                          {opt.caption2}
                        </Typography.Paragraph>
                      )}
                    </div>
                  </button>
                )}
              </Fragment>
            ))}
            {isOpen && paginated && isSentinelVisible && (
              <div
                className="multiple-select-list__sentinel"
                ref={sentinelRef}
              ></div>
            )}
            {isOpen && paginated && isRequesting && (
              <div className="loading-container">
                <Loading.Dots width={50} height={50} />
              </div>
            )}
          </>
        ) : (
          <div className="multiple-select-list__empty">
            <EmptyFolder />
            <Typography.H6>Não encontramos resultados.</Typography.H6>
          </div>
        )}
      </div>
    </div>
  );
}
