//GLOBAL - components from npm
//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import ptBR from "../../../locale/pt-BR";
import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

//STYLES
import "./communicated.scss";

//COMPONENTS
import { CarouselAttach, AttachTypeFiles } from "..";
import { Typography } from "../../general";
import { Audio } from "../../data-entry";
import { FilterTag } from "../../data-display";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as SmsTracking } from "../../../assets/icons/SmsTracking.svg";
import { ReactComponent as Notifications } from "../../../assets/icons/NotificationsTwo.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/ArrowUpTwo.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/ArrowDownTwo.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning-tag.svg";
import { ReactComponent as Error } from "../../../assets/icons/error-tag.svg";
import { ReactComponent as Close } from "../../../assets/icons/Close02.svg";

export default function Communicated({
  communicated = {
    id_comunicado: 1,
    desc_comunicado: "teste",
    data_insercao: "2022-01-04T13:59:57.653Z",
    id_tipo_comunicado: 1,
    usuario_insercao: 6,
    nome_usuario: "Glauber",
    filesAssociated: [
      {
        id_comunicado_arquivo: 217,
        path_arquivos:
          "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
        id_comunicado: 333,
      },
    ],
    files: [
      {
        file: {
          name: "anexo",
          size: 1000,
          type: 2,
          path_arquivos:
            "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
          extension: ".gif",
          id: 20,
        },
      },
    ],
  },
  inputSearch = "",
}) {
  //GENERAL
  const typeAlert = communicated.mensagem_erro ? "error" : "";

  //STATES
  const [viewMore, setViewMore] = useState(false);
  const [isViewContent, setIsViewContent] = useState([]);
  const [arrayFiles, setArrayFiles] = useState(communicated?.files || []);
  const [descCommunicated, setDescCommunicated] = useState(
    communicated.desc_comunicado
  );
  const [alertType, setAlertType] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS

  const renderTitleCommunicated = (communicated) => {
    return (
      <div className="communicated-information-user">
        <div className="communicated-information-user__box">
          <div className="communicated-information-user__box-image">
            {communicated.midias.length !== 0 ? (
              <SmsTracking />
            ) : (
              <Notifications />
            )}
          </div>
          <Typography.H5 color="gray-180">
            {communicated.nome_usuario}
          </Typography.H5>
          <div className="communicated-information-user__box-space" />
          <Typography.H5 weight={700} color="gray-700">
            {communicated.midias.length !== 0
              ? `comunicou o cliente`
              : " fez uma publicação"}
          </Typography.H5>
          {communicated.midias.length !== 0 && (
            <>
              <div className="communicated-information-user__box-space" />
              <Typography.H5 color="gray-180">via</Typography.H5>
              <div className="communicated-information-user__box-space" />
              <Typography.H5 weight={700} color="gray-700">
                {renderIconsMidias(communicated.midias)}
              </Typography.H5>
            </>
          )}
        </div>
        <div className="communicated-information-user__icon">
          {isViewContent.findIndex(function (val) {
            return val === communicated.id_comunicado;
          }) !== -1 ? (
            <ArrowUp />
          ) : (
            <ArrowDown />
          )}
        </div>
      </div>
    );
  };

  const renderIconsMidias = (midias) => {
    if (midias.length !== 0) {
      let midia = communicated.midias.filter((midia) => midia === midias[0]);
      if (midia.length !== 0) {
        if (midia[0] === 3) return "Email";
        else return "Nenhum";
      }
    }
  };

  const handleViewContent = (idCommunicated) => {
    let check = isViewContent.findIndex(function (val) {
      return val === idCommunicated;
    });
    if (check !== -1) {
      setIsViewContent(
        isViewContent.filter(function (val) {
          return val != idCommunicated;
        })
      );
    } else {
      setIsViewContent(isViewContent.concat([idCommunicated]));
    }
  };

  const filterDestinatarios = (destinatario) => {
    return destinatario.desc_destinatario;
  };

  //USE EFFECTS

  useEffect(() => {
    let currentCommunicated = communicated.desc_comunicado;

    if (inputSearch) {
      const indexes = [];
      let i = 0;
      while (i < currentCommunicated.length) {
        if (
          currentCommunicated
            .toLowerCase()
            .indexOf(inputSearch.toLowerCase(), i) !== -1
        ) {
          indexes.push(
            currentCommunicated
              .toLowerCase()
              .indexOf(inputSearch.toLowerCase(), i)
          );
          i =
            currentCommunicated
              .toLowerCase()
              .indexOf(inputSearch.toLowerCase(), i) + 1;
        } else {
          i = currentCommunicated.length;
        }
      }

      const words = [];

      for (const index of indexes) {
        const partName = currentCommunicated.substr(index, inputSearch.length);
        words.push(partName);
      }

      const newWords = words.filter((item, i) => {
        return words.indexOf(item) === i;
      });

      const newWordsOrder = newWords.sort((a, b) => a.localeCompare(b));

      for (const item of newWordsOrder) {
        currentCommunicated = currentCommunicated.replaceAll(
          item,
          `<mark>${item}</mark>`
        );
      }
    }

    currentCommunicated = currentCommunicated.replaceAll(`&nbsp;`, ` `);

    setDescCommunicated(`<div>${currentCommunicated}</div>`);
  }, [inputSearch, communicated]);

  if (communicated.desc_comunicado) {
    return (
      <div
        className="communicated"
        id={`comunicado-${communicated.id_comunicado}`}
      >
        <div
          className="communicated-information"
          onClick={() => handleViewContent(communicated.id_comunicado)}
        >
          {renderTitleCommunicated(communicated)}
          <div className="communicated-information-date">
            <Typography.H5 color="gray-200">
              {format(
                new Date(communicated.data_insercao),
                "dd'/'MM'/'yyyy 'às' HH:mm",
                { locale: ptBR }
              )}
            </Typography.H5>
          </div>
        </div>
        {communicated.mensagem_erro && (
          <div
            className={`communicated-alert ${
              alertType === "error" ? "error" : "warning"
            }`}
          >
            {alertType === "error" ? (
              <Error className="communicated-alert__icon" />
            ) : (
              <Warning className="communicated-alert__icon" />
            )}

            <div className="communicated-alert__content">
              <div className="communicated-alert__content-title">
                <Typography.H4 weight={500}>
                  {alertType === "error"
                    ? "Email não enviado"
                    : "Mensagem não entregue"}
                </Typography.H4>
                <Close onClick={() => setAlertType(false)} />
              </div>
              <Typography.H5 weight={400} color={"gray-180"}>
                {alertType === "error"
                  ? communicated.mensagem_erro
                  : "A mensagem não foi entregue porque a de caixa de entrada deste contato está cheia."}
              </Typography.H5>
            </div>
          </div>
        )}
        <div
          className={
            isViewContent.findIndex(function (val) {
              return val === communicated.id_comunicado;
            }) !== -1
              ? "communicated-content"
              : "communicated-content__disabled"
          }
        >
          <div className="communicated-content__recipient">
            {communicated.destinatarios.map((destinatario) => (
              <div className="communicated-information-recipient__option">
                <FilterTag
                  label={destinatario.desc_destinatario}
                  client={destinatario.id_comunicado_destinatario}
                  agent={{
                    ...destinatario,
                    nome_usuario: destinatario.desc_destinatario.toUpperCase(),
                  }}
                  type={"agents"}
                  alertIcon={typeAlert}
                  onClickAlert={() => setAlertType(typeAlert)}
                />
              </div>
            ))}
          </div>
          <div className="communicated-content__text">
            <Typography.H6 color="gray-400">
              <p>
                {viewMore
                  ? ReactHtmlParser(descCommunicated)
                  : communicated.desc_comunicado.length > 250
                  ? ReactHtmlParser(
                      '<div class="communicated-content__text-crop">' +
                        descCommunicated +
                        "..." +
                        "</div>"
                    )
                  : ReactHtmlParser(descCommunicated)}
                {communicated.desc_comunicado.length > 250 && (
                  <button
                    className="communicated-content-buttons__button"
                    onClick={() => setViewMore(!viewMore)}
                  >
                    <Typography.H6 color="primary-dark">
                      {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                    </Typography.H6>
                  </button>
                )}
              </p>
            </Typography.H6>
          </div>
          {arrayFiles.length > 0 && (
            <div className="communicated-content-buttons">
              <div className="communicated-content-buttons-attachment-list">
                {arrayFiles.map((file) => (
                  <AttachTypeFiles
                    files={file.file}
                    type="comment"
                    limit={3}
                    className="communicated-carousel"
                    download
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    if (communicated.id_tipo_comunicado === 3) {
      return (
        <div
          className="communicated"
          id={`comunicado-${communicated.id_comunicado}`}
        >
          <div className="communicated-information">
            {renderTitleCommunicated(communicated)}
            <div className="communicated-information-date">
              <Typography.Paragraph color="gray-200">
                {format(
                  new Date(communicated.data_insercao),
                  "dd 'de' MMM'./'yyyy 'às' HH:mm",
                  { locale: ptBR }
                )}
              </Typography.Paragraph>
            </div>
          </div>
          <div className="communicated-content">
            {arrayFiles[0]?.file.path_arquivos && (
              <Audio link={arrayFiles[0]?.file.path_arquivos} />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="communicated"
          id={`comunicado-${communicated.id_comunicado}`}
        >
          <div className="communicated-information">
            {renderTitleCommunicated(communicated)}
            <div className="communicated-information-date">
              <Typography.Paragraph color="gray-200">
                {format(
                  new Date(communicated.data_insercao),
                  "dd 'de' MMM'./'yyyy 'às' HH:mm",
                  { locale: ptBR }
                )}
              </Typography.Paragraph>
            </div>
          </div>
          <div className="communicated-content">
            <div className="communicated-content__files">
              <CarouselAttach
                files={arrayFiles}
                type="comment"
                limit={3}
                className="communicated-carousel"
                download
              />
            </div>
          </div>
        </div>
      );
    }
  }
}
