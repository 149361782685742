//GLOBAL - components from npm
import React, { useState, useRef } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";

// UTILS

//STYLES
import "./delete.scss";

//COMPONENTS
import { Typography, Button } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
// import { ReactComponent as IconTrash } from "../../../assets/icons/Trash.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/Close.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/Trash-3.svg";

export default function DeleteTask({ onDelete, title }) {
  //GENERAL
  const ref = useRef();

  //STATES
  const [visible, setVisible] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  useOutsideClick(ref, () => {
    if (visible) {
      setVisible(false);
    }
  });

  //USE EFFECTS

  return (
    <div className="delete" ref={ref}>
      <Tippy
        theme="popover-default"
        // #TOVERIFY: TRIGGER
        // trigger="click"
        visible={visible}
        onClickOutside={() => setVisible(false)}
        placement="bottom"
        delay={[0, 0]}
        allowHTML
        interactive
        content={
          <div className="delete__popover">
            <IconClose onClick={() => setVisible(false)} />
            <div className="delete__popover__text">
              <Typography.H4 color="gray-400">{title}</Typography.H4>
            </div>
            <div className="delete__popover__buttons">
              <Button className="btn-delete" onClick={() => onDelete()}>
                Remover
              </Button>
              <Button onClick={() => setVisible(false)}>Manter</Button>
            </div>
          </div>
        }
      >
        <button className="delete__button" onClick={() => setVisible(true)}>
          {/* <Typography.H6 color="red" weight="500">
            Remover
          </Typography.H6> */}
          <IconTrash />
        </button>
      </Tippy>
    </div>
  );
}
