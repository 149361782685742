//GLOBAL - components from npm
import React, { useEffect, useRef, useState } from "react";
import { isPast, format } from "date-fns";
import { BroadcastChannel } from "broadcast-channel";

// UTILS
import getLocalStorage from "./../../../utils/getLocalStorage";
import formatDate from "./../../../utils/formatDate";
import postMessage from "../../../utils/postMessage";

//COMPONENTS

//SERVICES - api, conectors...
import * as APIReminder from "./../../../services/api/reminder";
import * as APITask from "./../../../services/api/task";
import * as APIInteraction from "./../../../services/api/interaction";
import * as APIClient from "./../../../services/api/client";
import * as APICase from "./../../../services/api/case";
import { connection_platform } from "./../../../services/api/configConnection";

//GLOBAL STATE - redux, env...
import { useDispatch, useSelector } from "react-redux";
import { insertSentItem, removeSentItem } from "./../../../store/browserComms";
import { doLogout } from "./../../../store/auth";

//ASSETS - icons, images...

export default function BrowserCommsAPI() {
  //GENERAL
  const token = getLocalStorage("token", null);
  const dispatch = useDispatch();
  const config = {
    refetchInterval: 30000,
    // refetchInterval: 60000, // 60s / 1min
  };
  let intervalCurrentInteraction;

  // BROADCAST CHANNEL (INTERNAL COMMS)
  const channel = new BroadcastChannel("ingdesk-internal");

  // REFS
  const sentReminderRef = useRef();
  const sentOverdueTasksRef = useRef();
  const alertsCaseRef = useRef();

  //STATES
  // const [alertsCases, setAlertsCases] = useState([]);

  //REDUX - Selectors
  const userData = useSelector((state) => state.auth.userData);
  let sentReminders = useSelector((state) => state.browserComms.sentReminders);
  let sentOverdueTasks = useSelector(
    (state) => state.browserComms.sentOverdueTasks
  );
  let alertsCase = useSelector((state) => state.browserComms.alertsCase);
  // #TOIMPROVE: Remover refs e pegar o valor atual dos useSelectors dentro do setInterval
  sentReminderRef.current = sentReminders;
  sentOverdueTasksRef.current = sentOverdueTasks;
  alertsCaseRef.current = alertsCase;

  //FUNCTIONS

  // SENDING FUNCTIONS
  // overdue tasks
  async function sendOverdueTasksNotify() {
    // pull all tasks
    const response = await APITask.getTasks("", null, null, token);
    // console.debug('1 - response getTasks: ', response);

    // filters only overdue tasks and not concluded tasks
    const overdueTasks = response.data.filter(
      (task) =>
        isPast(new Date(task.prazo_resolucao)) &&
        task.id_fila !== 4 &&
        task.nome_usuario_solicitante === userData.userName
    );
    // console.debug('2 - overdueTasks: ', overdueTasks);

    // filters only unsent tasks (is not present in sentOverdueTasks)
    const unsentOverdueTasks = overdueTasks.filter(
      (task) =>
        !sentOverdueTasksRef.current.some((sentTask) => {
          // console.debug('sentTask.idTask: ', sentTask.idTask);
          // console.debug('task.id_tarefa: ', task.id_tarefa);
          return sentTask.idTask == task.id_tarefa;
        })
    );
    // console.debug('3 - unsentOverdueTasks: ', unsentOverdueTasks);

    if (unsentOverdueTasks.length > 0) {
      // formats the objects of the tasks to notifications

      //old description
      // `Tarefa Atrasada: ${task.assunto.substring(0, 20)}${
      //   task.assunto.length > 20 ? "..." : ""
      // } - ${format(
      //   new Date(task.prazo_resolucao),
      //   "dd'/'MM'/'y"
      // )} às ${format(new Date(task.prazo_resolucao), "HH':'mm'h'")}`},
      const updatedUnsentOverdueTasks = unsentOverdueTasks.map((task) => ({
        description: [
          {
            title: "Tarefa Atrasada",
            task: `${task.assunto.substring(0, 20)}${
              task.assunto.length > 20 ? "..." : ""
            }`,
            deadline: `${format(
              new Date(task.prazo_resolucao),
              "dd'/'MM'/'y"
            )} às ${format(new Date(task.prazo_resolucao), "HH':'mm'h'")}`,
          },
        ],
        display: "taskOverdue",
        author: "IngDesk",
        mensagem: `Tarefa Atrasada: ${task.assunto.substring(0, 20)}${
          task.assunto.length > 20 ? "..." : ""
        } - ${format(
          new Date(task.prazo_resolucao),
          "dd'/'MM'/'y"
        )} às ${format(new Date(task.prazo_resolucao), "HH':'mm'h'")}`,
        idTask: task.id_tarefa,
      }));
      // console.debug('4 - updatedUnsentOverdueTasks: ', updatedUnsentOverdueTasks);

      // post the payload with 'taskOverdue' id
      postMessage("tasksOverdue", updatedUnsentOverdueTasks);

      // records sent tasks
      dispatch(
        insertSentItem({
          data: updatedUnsentOverdueTasks,
          into: "sentOverdueTasks",
        })
      );
      // console.debug('5 - sentOverdueTasks: ', sentOverdueTasks);
    }
  }

  // reminders
  async function sendRemindersNotify() {
    // pull all reminders
    const response = await APIReminder.listAllReminders(token);
    // console.debug('1 - response listReminder: ', response);

    // filters only unseen and overdue reminders
    const unseenOverdueReminders = response.data.filter(
      (reminder) =>
        !reminder.concluido &&
        isPast(new Date(reminder.data_fim)) &&
        reminder.id_fila !== 4
    );
    // console.debug('2 - unseenOverdueReminders: ', unseenOverdueReminders);

    // filters only unsent reminders (not present in sentReminders)
    const unsentReminders = unseenOverdueReminders.filter((reminder) => {
      return !sentReminderRef.current.some(
        (sentReminder) =>
          sentReminder.idReminder == reminder.id_tarefas_lembrete
      );
    });
    // console.debug('3 - unsentReminders: ', unsentReminders);

    if (unsentReminders.length > 0) {
      //old description
      // `Lembrete de Tarefa: ${reminder.assunto.substring(0, 15)}${
      //   reminder.assunto.length > 15 ? "..." : ""
      // } - (${format(new Date(reminder.data_fim), "dd'/'MM'/'y")} às ${format(
      //   new Date(reminder.data_fim),
      //   "HH':'mm'h'"
      // )})`,
      // formats the objects of the reminders to notifications
      const updatedUnseenReminders = unsentReminders.map((reminder) => ({
        description: [
          {
            title: "Lembrete de Tarefa",
            task: `${reminder.assunto.substring(0, 15)}${
              reminder.assunto.length > 15 ? "..." : ""
            }`,
            deadline: `${format(
              new Date(reminder.data_fim),
              "dd'/'MM'/'y"
            )} às ${format(new Date(reminder.data_fim), "HH':'mm'h'")}`,
          },
        ],
        display: "reminder",
        author: "IngDesk",
        mensagem: `Lembrete de Tarefa: ${reminder.assunto.substring(0, 15)}${
          reminder.assunto.length > 15 ? "..." : ""
        } - (${format(new Date(reminder.data_fim), "dd'/'MM'/'y")} às ${format(
          new Date(reminder.data_fim),
          "HH':'mm'h'"
        )})`,
        idReminder: reminder.id_tarefas_lembrete,
        idTask: reminder.id_tarefa,
        concluded: false,
      }));
      // console.debug('4 - updatedUnseenReminders: ', updatedUnseenReminders);

      // post the payload with 'reminders' id
      postMessage("reminders", updatedUnseenReminders);

      // records sent reminders
      dispatch(
        insertSentItem({
          data: updatedUnseenReminders,
          into: "sentReminders",
        })
      );
      // console.log('5 - sentReminders: ', sentReminders);
    }
  }

  // case alerts
  async function sendAlertsCase() {
    // pull all reminders
    const response = await APICase.searchAlerts(token, {
      id_usuario: userData.userId,
    });
    // console.debug('1 - response listReminder: ', response);
    if (response.status === 200) {
      const data = response.data;
      const filterData = data.filter((datum) => {
        return !alertsCaseRef.current.some(
          (data) => data.idAlert == datum.id_alerta
        );
      });

      if (filterData.length > 0) {
        const getCaseAlerts = filterData.map((datum) => ({
          description: [
            {
              title: "Proprietário de Caso",
              task: `Você foi colocado como proprietário de um caso`,
              deadline: `${format(
                padronizeDate(datum.to_char),
                "dd'/'MM'/'y"
              )} às ${format(padronizeDate(datum.to_char), "HH':'mm'h'")}`,
            },
          ],
          display: "caseAlert",
          author: "IngDesk",
          mensagem: `Proprietário de Caso: Você foi colocado como proprietário de um caso - (${format(
            padronizeDate(datum.to_char),
            "dd'/'MM'/'y"
          )} às ${format(padronizeDate(datum.to_char), "HH':'mm'h'")})`,
          idAlert: datum.id_alerta,
          idCase: datum.id_ticket,
          concluded: false,
        }));
        postMessage("caseReminders", getCaseAlerts);
        // setAlertsCases([...alertsCases, ...getCaseAlerts]);
        dispatch(
          insertSentItem({
            data: getCaseAlerts,
            into: "alertsCase",
          })
        );
      }
    }
  }

  function padronizeDate(dateString) {
    const dataHoraString = dateString;
    const partes = dataHoraString.split(" ");
    const dataPartes = partes[0].split("/");
    const horaPartes = partes[1].split(":");

    const ano = parseInt(dataPartes[2], 10);
    const mes = parseInt(dataPartes[1], 10) - 1;
    const dia = parseInt(dataPartes[0], 10);
    const hora = parseInt(horaPartes[0], 10);
    const minuto = parseInt(horaPartes[1], 10);

    return new Date(ano, mes, dia, hora, minuto);
  }

  // LISTENING FUNCTIONS
  // external listener
  function messageListener(event) {
    const message = event.data;
    // // debbuging purpose only
    // if (message.origin === 'ingdesk') {
    //   console.debug('(INGDESK) MESSAGE RECEIVED FROM INGDESK: ', message);
    // }

    if (message.origin === "ingweb") {
      console.debug("(INGDESK) MESSAGE RECEIVED FROM INGWEB: ", message);
      switch (message.id) {
        case "logout":
          dispatch(doLogout());
          break;

        case "sendingTokenEndPoint":
          postMessage("deliveryTokenEndPoint", localStorage.getItem("token"));
          break;
        case "caseCreatedDealing":
          if (message.payload.interactionId) {
            getCaseIdInteraction(message.payload.interactionId);
          }

          async function getCaseIdInteraction(interactionId) {
            const response = await APIInteraction.getCaseIdReturned(
              interactionId,
              token
            );
            postMessage("deliveryCaseIdInteraction", {
              clientId: response.data,
            });
          }

          break;

        case "caseAlertConcluded":
          message.payload.forEach(async (caseAlertConcluded) => {
            const body = {
              id_alerta: caseAlertConcluded.idAlert,
            };

            const response = await APICase.updateAlertsRead(token, body);
            // console.debug('(INGDESK) RESPONSE FOR EACH: ', response);

            if (
              response.data.Message !== "Atualizado com sucesso!" ||
              response.status !== 200
            ) {
              console.debug(
                `ERROR: message receive from ingweb (caseAlertConcluded - ${caseAlertConcluded.idAlert})`
              );
            }
          });
          break;

        case "remindersConcluded":
          // console.debug('(INGDESK) SWITCH: remindersConcluded', message.payload);
          message.payload.forEach(async (reminderConcluded) => {
            // console.debug('(INGDESK) FOR EACH: remindersConcluded')
            const body = {
              id_tarefa: reminderConcluded.idTask,
              data_fim: formatDate(new Date()),
              concluido: true,
            };

            const response = await APIReminder.updateReminder(
              body,
              token,
              reminderConcluded.idReminder
            );
            // console.debug('(INGDESK) RESPONSE FOR EACH: ', response);

            if (
              response.data.Message !== "Atualizado com sucesso!" ||
              response.status !== 200
            ) {
              console.debug(
                `ERROR: message receive from ingweb (remindersConcluded - ${reminderConcluded.idTask})`
              );
            }
          });
          break;

        case "logInteraction":
          //Ouve a mensagem com id 'logInteraction' vinda do ingWeb e salva seu conteúdo no banco de dados. Salvando a interação na api do ingDesk.
          //console.debug('(INGDESK) MESSAGE LOGINTERACTION RECEIVED FROM INGWEB: ', message);
          if (message.payload.interactionId) {
            const data = {
              id_ticket: message.payload.caseId,
              id_interacao: message.payload.interactionId,
              protocolo_externo: message.payload.externalProtocol,
              id_contato: message.payload.clientId,
              id_canal: message.payload.channelId,
            };
            async function createInteractionCase() {
              const response = await APIInteraction.createInteractionCase(
                token,
                data
              );

              if (response.status !== 200) {
                console.debug(
                  `ERROR: message receive from ingweb (createInteractionCase)`
                );
              }
            }
            createInteractionCase();
          }

          break;
        case "getContactIdInteraction":
          async function getClientIdToDelivery(telephone) {
            const response = await APIClient.getIdClient(token, telephone);

            if (response.status !== 200) {
              console.debug(
                `ERROR: message receive from ingweb (getClientIdToDelivery)`
              );
            } else {
              if (response.data.length !== 0)
                postMessage("deliveryContactIdInteraction", {
                  clientId: response.data[0].id_contato,
                });
            }
          }

          getClientIdToDelivery(message.telefone);
          break;
        case "currentInteraction":
          //Recebe a mensagem 'currentInteraction' e envia essa mensagem internamente entre os iframes com o id de 'saveInteraction', para salvar no redux as informações da interação.
          //console.debug('currentInteraction switch')
          //console.debug('(INGDESK) MESSAGE RECEIVED FROM INGWEB: ', message);
          if (intervalCurrentInteraction) {
            clearInterval(intervalCurrentInteraction);
          }

          postMessage("saveInteraction", message.payload, true);

          intervalCurrentInteraction = setInterval(() => {
            //console.debug('SetInterval');

            postMessage("saveInteraction", message.payload, true);
          }, 2000);
          break;

        case "interactionEnded":
          //Recebe a mensagem 'interactionEnded' e envia essa mensagem internamente entre os iframes com o id de 'removeInteraction', para remover do redux as informações da interação.
          //console.debug('interactionEnded switch')
          clearInterval(intervalCurrentInteraction);
          postMessage("removeInteraction", message.payload, true);
          break;

        default:
          break;
      }
    }
  }

  // internal listener
  function internalListener() {
    channel.onmessage = (message) => {
      //console.debug('(INGDESK) INTERNAL MESSAGE RECEIVED: ', message);

      switch (message.id) {
        case "tasksOverdueDeleted":
          // remove item from sentOverdueTasks Redux
          message.payload.forEach((taskOverdueDeleted) => {
            dispatch(
              removeSentItem({
                from: "sentOverdueTasks",
                idLabel: "idTask",
                id: taskOverdueDeleted.idTask,
              })
            );
          });
          break;

        case "remindersDeleted":
          // remove item from sentReminders Redux
          message.payload.forEach((sentReminder) => {
            dispatch(
              removeSentItem({
                from: "sentReminders",
                idLabel: "idReminder",
                id: sentReminder.id_tarefas_lembrete,
              })
            );
          });

        default:
          break;
      }
    };
  }

  //USE EFFECTS
  useEffect(() => {
    window.addEventListener("message", messageListener);
    internalListener();

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  useEffect(() => {
    if (token) {
      sendRemindersNotify();
      sendOverdueTasksNotify();
      sendAlertsCase();

      const checkReminders = setInterval(() => {
        console.debug("(INGDESK) setNotifications - setInterval");
        sendRemindersNotify();
        sendOverdueTasksNotify();
        sendAlertsCase();
      }, connection_platform.time_atualization);

      return () => {
        clearInterval(checkReminders);
      };
    }
  }, [token]);

  return <></>;
}
