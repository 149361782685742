import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode"

export async function getTypesPhones(token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        };

        const response = await instance.get('/list-types-phones', config);

        for (const item of response.data) {
            item.desc_tipo_telefone = unicodeToChar(item.desc_tipo_telefone)
        }

        return response;
    } catch (error) {
        return error;
    }
}