//GLOBAL - components from npm
import { createSlice } from "@reduxjs/toolkit";

const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState: {
    exhibColumn: [
      {
        title: "Contato",
        exhib: true,
      },
      {
        title: "Organização",
        exhib: false,
      },
      {
        title: "Código Externo",
        exhib: false,
      },
      {
        title: "Empresa do Contato",
        exhib: false,
      },
      {
        title: "Empresa",
        exhib: false,
      },
      {
        title: "CNPJ",
        exhib: false,
      },
      {
        title: "Cód. Empresa",
        exhib: false,
      },
      {
        title: "Assunto",
        exhib: false,
      },
      {
        title: "Solicitação",
        exhib: false,
      },
      {
        title: "Motivo",
        exhib: false,
      },
      {
        title: "Sub-motivo",
        exhib: false,
      },
      {
        title: "Criado Em",
        exhib: true,
      },
      {
        title: "Status",
        exhib: true,
      },
      {
        title: "Prazo",
        exhib: false,
      },
      {
        title: "Concluído em",
        exhib: true,
      },
      {
        title: "Tarefas",
        exhib: false,
      },
      {
        title: "Agente",
        exhib: true,
      },
      {
        title: "Criado Por",
        exhib: true,
      },
      {
        title: "Matricula",
        exhib: false,
      },
      {
        title: "Canal de Origem",
        exhib: true,
      },
      {
        title: "Escalonado Para",
        exhib: true,
      },
      {
        title: "Ultima Atualização",
        exhib: true,
      },
      {
        title: "Prioridade",
        exhib: true,
      },
    ],
    initialSort: {
      sortBy: [
        {
          id: "date",
          desc: false,
        },
      ],
    },
    exhibColumnVariable: [],
  },
  reducers: {
    setTableFilter(state, action) {
      state.exhibColumn = action.payload.value;
    },
    setVariableTableFilter(state, action) {
      state.exhibColumnVariable = action.payload.value;
    },
    setInitialSort(state, action) {
      state.initialSort = action.payload.value;
    },
  },
});

export const { setTableFilter, setInitialSort, setVariableTableFilter } =
  tableFiltersSlice.actions;
export default tableFiltersSlice.reducer;
