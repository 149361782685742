//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

// UTILS
import { formatPhone } from "../../../../utils/formatPhone";
import { Toast } from "../../../../utils/toast";

//COMPONENTS
import { Typography } from "../../../../components/general";
import { PopoverMenu } from "../../../../components/navigation";
import { Loading, Modal } from "../../../../components/feedback";
import { TabulatedTable } from "../../../../components/data-display";

//SERVICES - api, conectors...
import * as APIContact from "./../../../../services/api/APIContact";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconMoreVertical } from "./../../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/Edit-2.svg";
import { ReactComponent as TrashIcon } from "./../../../../assets/icons/Trash-2.svg";
import { ReactComponent as EyeIcon } from "./../../../../assets/icons/eye02.svg";
import { ReactComponent as EmptyStateIcon } from "./../../../../assets/images/no-result.svg";

//STYLES

export default function PersonPhysical({
    filtersContacts,
    updateContact,
    pageStatus,
    contactsData,
    setPageStatus,
    loading,
    currentPage,
    count,
    linesLimit,
    setCurrentPage,
    setLinesLimit
}) {
    //GENERAL

    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //STATES
    const [isOpenModalViewDataContact, setIsOpenModalViewDataContact] = useState(false);
    const [isOpenModalUpdateContact, setIsOpenModalUpdateContact] = useState(false);
    const [isOpenModalDeleteContact, setIsOpenModalDeleteContact] = useState(false);
    const [contactId, setContactId] = useState(null);
    const [emptyState, setEmptyState] = useState();

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    async function deleteContact(contactId) {
        setPageStatus("requesting");

        const response = await APIContact.deleteContact(contactId, token);

        if (response.status === 200) {
            Toast("Contato Deletado!", "Contato deletado com sucesso.", "success");
            updateContact();
        } else {
            Toast(
                "Falha, não foi possível deletar o seu contato!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    function refactoryContactList(data) {
        let dataRefactored = [];

        data.map((item) => {
            const contactRefactored = {
                name: {
                    data: item.nome_contato,
                    config: {
                        textEllipsis: true,
                        className: "separator-name-contact",
                    },
                },
                telephone: {
                    data:
                        item.telefone.length > 12
                            ? formatPhone(item.telefone.substring(0, 12)) + "..."
                            : formatPhone(item.telefone),
                    config: {
                        textEllipsis: true,
                        className: "separator-phone-contact",
                    },
                },
                actions: {
                    data: (
                        <div className="container-actions">
                            <button
                                onClick={() =>
                                    setIsOpenModalViewDataContact(item.id_contato, true)
                                }
                            >
                                <EyeIcon />
                            </button>
                            <PopoverMenu
                                placement="bottom-end"
                                menuData={renderPopoverMenuContentContact(
                                    item.id_contato,
                                    item.id_usuario
                                )}
                            >
                                <button className="case-btn-options" type="button">
                                    <IconMoreVertical />
                                </button>
                            </PopoverMenu>
                        </div>
                    ),
                    config: {
                        className: "separator-options-contact",
                    },
                },
            };

            dataRefactored.push(contactRefactored)

            return dataRefactored;
        });

        return dataRefactored;
    };

    const dataContact = useMemo(() => {
        if (contactsData) {
            const dataContactRefactored = refactoryContactList(contactsData);
            setPageStatus("awaiting");

            return dataContactRefactored;
        }
    }, [contactsData]);

    function renderPopoverMenuContentContact(contactId) {
        if (userData.permissionId === 1 && contactId !== userData.userId) {
            return [
                {
                    title: (
                        <>
                            <EditIcon />
                            <Typography.H5 onClick color="gray-180" weight="500">
                                Editar
                            </Typography.H5>
                        </>
                    ),
                    onClick: () => {
                        setIsOpenModalUpdateContact(contactId, true);
                    },
                },
            ];
        } else {
            return [
                {
                    title: (
                        <>
                            <Typography.H5 color="gray-180" weight="500">
                                <EditIcon />
                                Editar
                            </Typography.H5>
                        </>
                    ),
                    onClick: () => {
                        setIsOpenModalUpdateContact(contactId, true);
                    },
                },
                {
                    title: (
                        <>
                            <Typography.H5 color="red" weight="500">
                                <TrashIcon className="popover-icon" />
                                Remover
                            </Typography.H5>
                        </>
                    ),
                    onClick: () => {
                        setContactId(contactId)
                        setIsOpenModalDeleteContact(true)
                    },
                },
            ];
        }
    };

    const columnsContact = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                id: "name",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Telefone",
                accessor: "telephone",
                id: "telephone",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableSortBy: true,
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
        ],
        []
    );


    // USE EFFECTS
    useEffect(() => {
        if (pageStatus === "awaiting") updateContact();
    }, [filtersContacts]);

    useEffect(() => {
        if (contactsData?.length > 0) setEmptyState(false);
        else if (contactsData?.length < 0) setEmptyState(true);
    }, [contactsData]);

    return (
        <>
            {pageStatus === "requesting" ? (
                <section className="contact-content-main">
                    <Loading.Paperplane className="loading-contact-list" />
                </section>
            ) : (
                <>
                    {pageStatus === "awaiting" && (
                        <>
                            {emptyState ? (
                                <section className="emptySate-contact-list">
                                    <EmptyStateIcon />
                                </section>
                            ) : (
                                <section className="contact-content-main">
                                    <TabulatedTable
                                        fixedHeader
                                        className="table-contact"
                                        columns={columnsContact}
                                        data={dataContact}
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        loading={loading}
                                        currentPage={currentPage}
                                        count={count}
                                        linesLimit={linesLimit}
                                        setCurrentPage={setCurrentPage}
                                        setLinesLimit={setLinesLimit}
                                    />
                                </section>
                            )}
                        </>
                    )}
                </>
            )}

            <Modal.ViewDataContact
                contactsData={contactsData}
                isOpen={isOpenModalViewDataContact}
                onClose={(event) => setIsOpenModalViewDataContact(event)}
            />

            <Modal.UpdateContact
                contactsData={contactsData}
                isOpen={isOpenModalUpdateContact}
                onClose={(event) => setIsOpenModalUpdateContact(event)}
                onEdit={updateContact}
            />

            <Modal.GenericDeleteModal
                title="Excluir contato"
                isOpen={isOpenModalDeleteContact}
                onClose={(event) => { setIsOpenModalDeleteContact(event) }}
                onDeleteAction={async () => {
                    await deleteContact(contactId);
                }}
            />
        </>
    )
}
