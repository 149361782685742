import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { format, isValid, parse } from "date-fns";
import MaskedInput from "react-input-mask";

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { Loading } from "../../../../components/feedback";
import { FieldContainer } from "../../../../components/data-display";
import { Input, Select } from "../../../../components/data-entry";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APISla from "../../../../services/api/sla";
import * as APICase from "../../../../services/api/case";
import * as APIOrganization from "../../../../services/api/organization";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as AddCircleIcon } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as MinusCircleIcon } from "./../../../../assets/icons/minus-cirlce.svg";
import { ReactComponent as IconError } from "./../../../../assets/icons/error.svg";

//STYLES
import "./create-sla.scss";

export default function CreateSla({ onCreate, toGoBack }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
    const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);
    const [isStartHourBigger, setIsStartHourBigger] = useState(false);
    const [isHourOverlap, setIsHourOverlap] = useState(false);
    const [basicData, setBasicData] = useState(() => {
        return {
            id_organizacao: null,
            id_criticidade: 3,
            sla_inicio: "",
            id_tipo_sla_inicio: null,
            sla_resolucao: "",
            id_tipo_sla_resolucao: null,
            desc_sla: ""
        }
    });
    const [associatedSchedules, setAssociatedSchedules] = useState(() => {
        return [{
            day_week: null,
            start_time: "",
            end_time: "",
            sla_id: null
        }]
    });
    const [criticalityList, setCriticalityList] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [typesSla, setTypesSla] = useState([]);
    const [daysWeek, setDaysWeek] = useState([]);
    const [errors, setErrors] = useState([{
        start_time: "",
        end_time: "",
    }]);

    console.log("basicData", {
        basicData,
        field_id_mask: document.getElementById("start-time")?.value
    })

    //FUNCTIONS
    // const allDaysWeekAssociations = () => {
    //     const allAssociated = daysWeek.filter(day => {
    //         return !associatedSchedules.some(item => item.day_week === day.id);
    //     }).length === 0;

    //     return allAssociated;
    // };

    const handleAddHour = () => {
        setAssociatedSchedules(prevState => [
            ...prevState,
            {
                day_week: null,
                start_time: "",
                end_time: "",
                sla_id: null
            }
        ])
    };

    const handleRemoveHour = (idx) => {
        setAssociatedSchedules(prevState => prevState.filter((_, index) => index !== idx));
    };

    const handleStartTimeChange = (index, e) => {
        const value = e;

        const updatedAssociatedSchedules = [...associatedSchedules];

        updatedAssociatedSchedules[index].start_time = value;

        setAssociatedSchedules(updatedAssociatedSchedules);
    };

    const handleEndTimeChange = (index, e) => {
        const value = e;

        const updatedAssociatedSchedules = [...associatedSchedules];

        updatedAssociatedSchedules[index].end_time = value;

        setAssociatedSchedules(updatedAssociatedSchedules);
    };

    const handleBlur = (index) => {
        const startTime = associatedSchedules[index].start_time;
        const endTime = associatedSchedules[index].end_time;
        const parsedStartTime = startTime ? parse(startTime, "HH:mm", new Date()) : null;
        const parsedEndTime = endTime ? parse(endTime, "HH:mm", new Date()) : null;

        let overlappingError;

        if (associatedSchedules[index].day_week !== null && associatedSchedules[index].day_week !== undefined) {
            overlappingError = associatedSchedules
                .filter((item, i) => i !== index && item.day_week === associatedSchedules[index].day_week)
                .some((item) => {
                    if (!item.start_time || !item.end_time || !associatedSchedules[index].start_time || !associatedSchedules[index].end_time) {
                        return false;
                    }

                    const startTimeA = parse(associatedSchedules[index].start_time, "HH:mm", new Date());
                    const endTimeA = parse(associatedSchedules[index].end_time, "HH:mm", new Date());
                    const startTimeB = parse(item.start_time, "HH:mm", new Date());
                    const endTimeB = parse(item.end_time, "HH:mm", new Date());

                    return (
                        (startTimeA >= startTimeB && startTimeA < endTimeB) || // Verifica se o início do A está dentro do intervalo B
                        (endTimeA > startTimeB && endTimeA <= endTimeB) || // Verifica se o final do A está dentro do intervalo B
                        (startTimeB >= startTimeA && startTimeB < endTimeA) || // Verifica se o início do B está dentro do intervalo A
                        (endTimeB > startTimeA && endTimeB <= endTimeA) // Verifica se o final do B está dentro do intervalo A
                    );
                });
        }

        let startError = "";
        let endError = "";

        if (startTime && !isValid(parsedStartTime)) {
            startError = "Hora inicial inválida";

            setIsInvalidStartTime(true);
        } else {
            setIsInvalidStartTime(false);
        }

        if (endTime && !isValid(parsedEndTime)) {
            endError = "Hora final inválida";

            setIsInvalidEndTime(true);
        } else {
            setIsInvalidEndTime(false);
        }

        if (
            !startError &&
            !endError &&
            parsedStartTime &&
            parsedEndTime &&
            parsedStartTime >
            parsedEndTime
        ) {
            Toast(
                "Atenção!",
                "Hora inicial não pode ser maior que hora final.",
                "warn",
            );

            setIsStartHourBigger(true);
        } else {
            setIsStartHourBigger(false);
        }

        if (overlappingError) {
            Toast(
                "Atenção!",
                "Horários não podem se sobrepor no mesmo dia da semana. Por favor, verifique os horários e tente novamente.",
                "warn",
            );

            setIsHourOverlap(true);
        } else {
            setIsHourOverlap(false);
        }

        const updatedErrors = [...errors];

        updatedErrors[index] = {
            start_time: startError,
            end_time: endError
        };

        setErrors(updatedErrors);
    };

    function isFieldsValid() {
        if (
            !basicData.desc_sla ||
            !basicData.id_organizacao ||
            !basicData.id_criticidade ||
            !basicData.sla_inicio ||
            !basicData.sla_resolucao ||
            !basicData.id_tipo_sla_inicio
        ) return false;

        return true;
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao adicionar SLA!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    async function handleCreateSLA() {
        if (!isFieldsValid()) {
            handleInvalidFields();
            return;
        } else if (
            isStartHourBigger ||
            isInvalidStartTime ||
            isInvalidEndTime ||
            isHourOverlap
        ) return;

        let slaCreated;
        let hasError = false;

        try {
            setPageStatus("creating-sla");

            if (basicData.id_tipo_sla_inicio) {
                basicData.id_tipo_sla_resolucao = basicData.id_tipo_sla_inicio;
            }

            if (document.getElementById("start-time")?.value) {
                let inputStartTime = document.getElementById("start-time")?.value;
                let [hour, minutes] = inputStartTime.split(":");
                let calculationResult = parseInt(hour) * 60 + parseInt(minutes);

                basicData.sla_inicio = calculationResult;
            }

            if (document.getElementById("deadline-resolution")?.value) {
                let inputDeadlineResolution = document.getElementById("deadline-resolution")?.value;
                let [hourResolution, resolutionMinutes] = inputDeadlineResolution.split(":");
                let calculationResultResolution = parseInt(hourResolution) * 60 + parseInt(resolutionMinutes);

                basicData.sla_resolucao = calculationResultResolution;
            }

            const response = await APISla.createSla(token, basicData);

            if (response.status === 200) {
                slaCreated = response.data.Dados[0].id_sla;
            } else {
                hasError = true;
            }

            for (const item of associatedSchedules) {
                if (item.day_week !== null && item.day_week !== undefined && item.start_time && item.end_time) {
                    let startTime = item.start_time;
                    let endTime = item.end_time;

                    if (typeof startTime === 'string' && typeof endTime === 'string') {
                        const startTimeParts = startTime.split(':');
                        const endTimeParts = endTime.split(':');

                        startTime = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1]);
                        endTime = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1]);

                        startTime = format(startTime, 'HH:mm:ss');
                        endTime = format(endTime, 'HH:mm:ss');
                    }

                    const data = {
                        dia_semana: item.day_week,
                        hora_inicio: startTime,
                        hora_fim: endTime,
                        id_sla: slaCreated
                    }

                    const response = await APISla.createSlaSchedules(token, data);

                    if (response.status !== 200) {
                        hasError = true;
                    }
                }
            }

            if (hasError) {
                Toast(
                    "Não foi possível adicionar o SLA!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );

                throw new Error("Alguma operação falhou");
            } else {
                Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");

                onCreate();
                toGoBack();
                setPageStatus("awaiting");
            }
        } catch (error) {
            console.error("Erro ao criar SLA:", error);

            Toast(
                "Erro ao criar SLA!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        }
    };

    async function getCriticalityList() {
        const response = await APICase.getPriorityList(token);

        if (response.status === 200) {
            let dataUpdated = [];

            response.data.forEach((datum) => {
                let requestedItem = {};

                requestedItem.id = datum.id_criticidade;
                requestedItem.label = datum.descricao;

                dataUpdated.push(requestedItem);
            });

            setCriticalityList(dataUpdated);
        } else {
            Toast(
                "Falha, não foi carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getOrganizationsList() {
        const response = await APISla.listOrganizations(token);

        if (response.status === 200) {
            let dataUpdatedOrganization = [];

            response.data.forEach((data) => {
                let requestedItemOrganization = {};

                requestedItemOrganization.id = data.id_organizacao;
                requestedItemOrganization.label = data.nome;

                dataUpdatedOrganization.push(requestedItemOrganization);
            });

            setOrganizations(dataUpdatedOrganization)
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getListTypeSla() {
        const response = await APISla.typeSla(token);

        if (response.status === 200) {
            let dataUpdatedTypeSla = [];

            response.data.forEach((data) => {
                let requestedItemTypeSla = {};

                requestedItemTypeSla.id = data.id_tipo_sla;
                requestedItemTypeSla.label = data.descricao;

                dataUpdatedTypeSla.push(requestedItemTypeSla);
            });

            setTypesSla(dataUpdatedTypeSla)
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getDaysOfWeek() {
        const response = await APIOrganization.getDaysOfWeek(token);

        if (response.status === 200) {
            let dataUpdatedDayWeek = [];

            response.data.forEach((item) => {
                let requestedItemDayWeek = {};

                requestedItemDayWeek.id = item.id_dia;
                requestedItemDayWeek.label = item.desc_dia;

                dataUpdatedDayWeek.push(requestedItemDayWeek);
            });

            setDaysWeek(dataUpdatedDayWeek);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getSelectionData() {
        try {
            await Promise.allSettled([
                getCriticalityList(),
                getOrganizationsList(),
                getListTypeSla(),
                getDaysOfWeek()
            ])
        } catch (error) {
            console.error("Erro ao obter informações dos selects", error);

            Toast(
                "Erro ao obter informações dos selects!",
                "Um erro inesperado aconteceu.",
                "error"
            );
        }
    };

    //USE MEMOS

    //USE EFFECTS
    useEffect(() => {
        getSelectionData();
    }, []);

    return (
        <>
            <main className="page-create-sla">
                <header className="page-create-sla__header">
                    <div className="page-create-sla__header__title">
                        <ArrowLefIcon
                            onClick={() => {
                                toGoBack();
                            }}
                        />

                        <Typography.H2 color="gray-180" weight="bold">
                            Adicionar SLA
                        </Typography.H2>
                    </div>

                    <div className="page-create-sla__header__save-or-cancel">
                        <Button
                            className="btn-cancel-sla"
                            disabled={pageStatus === "creating-sla"}
                            onClick={() => {
                                toGoBack();
                            }}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-sla"
                            disabled={pageStatus === "creating-sla"}
                            onClick={handleCreateSLA}
                        >
                            {pageStatus !== "creating-sla" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="section-container">
                    <section className="basic-data-section">
                        <header className="basic-data-section-header">
                            <div className="basic-data-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Dados básicos
                                </Typography.H4>
                            </div>

                            <div className="basic-data-section-header__division" />
                        </header>

                        <div className="basic-data-content-container">
                            <div className="basic-data-content-container__content">
                                <div className="columns-data">
                                    <FieldContainer required title="Descrição da SLA">
                                        <Input
                                            type="text"
                                            placeholder="Digite uma descrição"
                                            value={basicData.desc_sla}
                                            onChange={(event) => setBasicData({ ...basicData, desc_sla: event })}
                                            error={validationWarn ? !basicData.desc_sla : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer required title="Organização">
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione a organização"
                                            value={basicData.id_organizacao}
                                            options={organizations}
                                            onSelect={(organization) => {
                                                setBasicData({ ...basicData, id_organizacao: organization.id })
                                            }}
                                            error={validationWarn ? !basicData.id_organizacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer required title="Tipo de SLA">
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione o tipo de SLA"
                                            options={typesSla}
                                            value={basicData.id_tipo_sla_inicio}
                                            onSelect={(typeSla) => {
                                                setBasicData({ ...basicData, id_tipo_sla_inicio: typeSla.id })
                                            }}
                                            error={validationWarn ? !basicData.id_tipo_sla_inicio : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer required title="Criticidade">
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Seleciona a criticidade"
                                            options={criticalityList}
                                            value={basicData.id_criticidade}
                                            onSelect={(criticality) => {
                                                setBasicData({ ...basicData, id_criticidade: criticality.id })
                                            }}
                                            error={validationWarn ? !basicData.id_criticidade : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer
                                        required
                                        title="Início"
                                        className="panel-registration__sla-time"
                                    >
                                        <Input
                                            type="dynamicMask"
                                            id="start-time"
                                            placeholder="00:00 ou 000:00"
                                            value={basicData.sla_inicio}
                                            onChange={(event) => setBasicData({ ...basicData, sla_inicio: event })}
                                            error={validationWarn ? !basicData.sla_inicio : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer
                                        required
                                        title="Prazo para resolução"
                                        className="panel-registration__sla-time"
                                    >
                                        <Input
                                            type="dynamicMask"
                                            id="deadline-resolution"
                                            placeholder="00:00 ou 000:00"
                                            value={basicData.sla_resolucao}
                                            onChange={(event) => setBasicData({ ...basicData, sla_resolucao: event })}
                                            error={validationWarn ? !basicData.sla_resolucao : false}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="associated-schedules">
                        <header className="associated-schedules-header">
                            <div className="associated-schedules-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Horários
                                </Typography.H4>
                            </div>

                            <div className="associated-schedules-header__division" />
                        </header>

                        <div className="hours-association-container">
                            <div className="hours-association-container__content">
                                {associatedSchedules.map((item, index) => {
                                    const dayErrors = errors[index] || {};

                                    return (
                                        <div
                                            key={index}
                                            className="associated-hours-fields"
                                        >
                                            <FieldContainer title="Dia da semana">
                                                <Select
                                                    changeDownArrowIcon
                                                    uncheckTheOption
                                                    placeholder="Selecione"
                                                    value={item.day_week}
                                                    options={daysWeek}
                                                    onSelect={(day_week) => {
                                                        const dayWeekSelect = cloneDeep(associatedSchedules);

                                                        dayWeekSelect[index].day_week = day_week.id;

                                                        setAssociatedSchedules(dayWeekSelect);
                                                    }}
                                                />
                                            </FieldContainer>

                                            <FieldContainer title="Hora inicial">
                                                <label className="input-container">
                                                    <div className="input-wrapper">
                                                        <MaskedInput
                                                            mask="99:99"
                                                            className="input-wrapper__input"
                                                            placeholder="HH:MM"
                                                            value={item.start_time}
                                                            onChange={(e) => handleStartTimeChange(index, e.target.value)}
                                                            onBlur={() => handleBlur(index)}
                                                            disabled={item.day_week === null || item.day_week === undefined}
                                                        />
                                                    </div>

                                                    {item.start_time !== "" && dayErrors.start_time && (
                                                        <div className="input-msg input-msg--error">
                                                            <div className="input-msg__icon">
                                                                <IconError />
                                                            </div>

                                                            <Typography.Paragraph className="input-msg__title">
                                                                {dayErrors.start_time}
                                                            </Typography.Paragraph>
                                                        </div>
                                                    )}
                                                </label>
                                            </FieldContainer>

                                            <FieldContainer title="Hora final">
                                                <label className="input-container">
                                                    <div className="input-wrapper">
                                                        <MaskedInput
                                                            mask="99:99"
                                                            className="input-wrapper__input"
                                                            placeholder="HH:MM"
                                                            value={item.end_time}
                                                            onChange={(e) => handleEndTimeChange(index, e.target.value)}
                                                            onBlur={() => handleBlur(index)}
                                                            disabled={item.day_week === null || item.day_week === undefined}
                                                        />
                                                    </div>

                                                    {item.end_time !== "" && dayErrors.end_time && (
                                                        <div className="input-msg input-msg--error">
                                                            <div className="input-msg__icon">
                                                                <IconError />
                                                            </div>

                                                            <Typography.Paragraph className="input-msg__title">
                                                                {dayErrors.end_time}
                                                            </Typography.Paragraph>
                                                        </div>
                                                    )}
                                                </label>
                                            </FieldContainer>

                                            <div className="associated-hours-fields__actions">
                                                <MinusCircleIcon
                                                    className={`delete-hour ${associatedSchedules.length === 1 && "disabled"}`}
                                                    onClick={(e) => {
                                                        if (associatedSchedules.length === 1) return;

                                                        e.preventDefault();
                                                        handleRemoveHour(index);
                                                    }}
                                                />

                                                <AddCircleIcon
                                                    className="add-hour"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddHour();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}
