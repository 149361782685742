//GLOBAL - components from npm
import React, { } from "react";

//STYLES
import "./reminder.scss";

//COMPONENTS
import { DatePicker } from "../../data-entry";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconClose } from '../../../assets/icons/Close.svg'

export default function Reminder({ onClick, reminder, setReminder }) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS


  return (
    <div className="reminder">
      <DatePicker.DateRange
        selectRangeType="start"
        error={new Date(reminder.data_fim) < new Date() ? true : false}
        selected={reminder.data_fim ? new Date(reminder.data_fim) : ''}
        minDate={new Date()}
        setDate={(event => {
          //setStartDate(event);
          setReminder(event);

        })}
        dateFormat="dd 'de' MMMM', 'u"
        placeholderText="Selecione uma data"
        width="180px"
      />
      <DatePicker.TimeRange
        minTime={new Date()}
        error={new Date(reminder.data_fim) < new Date() ? true : false}
        selected={reminder.data_fim ? new Date(reminder.data_fim) : undefined}
        setDate={(event => {
          //setStartDate(event);
          setReminder(event);
        })}
        dateFormat="HH:mm"
        placeholderText="Hora"
        width="85px"
      />
      <IconClose onClick={onClick} />
    </div>
  );
}
