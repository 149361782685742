import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode";

export async function all(token, page, title, limit) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      params: {
        page,
        limit: page && !limit ? 100 : limit,
        title: title ? title : "",
      },
    };

    const response = await instance.get(`/list-companys-users`, config);

    for (const item of response.data) {
      item.nome = unicodeToChar(item.nome);
    }

    return response;
  } catch (error) {
    return error.message;
  }
}

export async function newListAllCompanysUsers(token, page, title, limit, filtersEnterprises) {
  try {
    let config = {};

    if (filtersEnterprises && filtersEnterprises.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          page,
          limit: page && !limit ? 100 : limit,
          title: filtersEnterprises.nome,
          cnpj: filtersEnterprises.cnpj
        },
      };
    } else {
      config = {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        },
        params: {
          page,
          limit: page && !limit ? 100 : limit,
          title: title ? title : "",
        },
      }
    }

    let response = []

    if (filtersEnterprises.active) {
      response = await instance.get(`/list-enterprises-filter-new`, config);
    } else {
      response = await instance.get(`/list-companys-users-new`, config)
    }

    for (const item of response.data) {
      item.nome = unicodeToChar(item.nome);
    }

    return response;
  } catch (error) {
    return error;
  }
}

export async function allEnterprises(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.get(`/list-enterprises`, config);

    for (const item of response.data) {
      item.nome = unicodeToChar(item.nome);
    }

    return response;
  } catch (error) {
    return error.message;
  }
}

export async function allEnterprise(token, filtersEnterprises) {
  try {
    let config = {};

    if (filtersEnterprises && filtersEnterprises.active === true) {
      config = {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        },
        params: {
          title: filtersEnterprises.nome,
          cnpj: filtersEnterprises.cnpj
        }
      }
    } else {
      config = {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        },
      }
    }

    let response = [];

    if (filtersEnterprises.active)
      response = await instance.get(`/list-enterprises-filter`, config);
    else
      response = await instance.get(`/list-enterprises`, config);

    for (const item of response.data) {
      item.nome = unicodeToChar(item.nome);
    }

    return response;
  } catch (error) {
    return error;
  }
}
