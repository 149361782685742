//GLOBAL - components from npm
import React from "react";
import TippyJs from "@tippyjs/react";
import { followCursor } from "tippy.js";

//STYLES
import "./tippy.scss";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/translucent.css";

//COMPONENTS
import { Typography } from "../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function Tippy({
  children,
  customContent,
  content = "Text example",
  theme = "default",
  maxWidth = "none",
  delay = [600, 100],
  ...rest
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <TippyJs
      plugins={[followCursor]}
      theme={theme}
      content={
        customContent ? customContent : <Typography.H5>{content}</Typography.H5>
      }
      maxWidth={maxWidth}
      delay={delay}
      {...rest}
    >
      {children}
    </TippyJs>
  );
}
