export const convertToHoursOrMinutes = (minutos, h, m) => {
    const horas = Math.floor(minutos / 60);
    const min = minutos % 60;
    const textoHoras = horas.toString();
    const textoMinutos = (`00${min}`).slice(-2);

    if (h && m) {
        if (horas > 0 && min > 0) {
            return `${textoHoras}h${textoMinutos}m`;
        } else if (horas === 1 && min === 1) {
            return `${textoHoras}h${textoMinutos}m`;
        } else if (horas === 1 && min > 1) {
            return `${textoHoras}h${textoMinutos}m`;
        } else if (horas === 0 && min > 1) {
            return `${textoMinutos}m`;
        } else if (horas === 0 && min <= 1) {
            return `${textoMinutos}m`;
        } else {
            return `${textoHoras}h`;
        }
    } else {
        if (horas > 1 && min > 1) {
            return `${textoHoras} horas e ${textoMinutos} minutos`;
        } else if (horas === 1 && min === 1) {
            return `${textoHoras} hora e ${textoMinutos} minuto`;
        } else if (horas === 1 && min > 1) {
            return `${textoHoras} hora e ${textoMinutos} minutos`;
        } else if (horas === 0 && min > 1) {
            return `${textoMinutos} minutos`;
        } else if (horas === 0 && min <= 1) {
            return `${textoMinutos} minuto`;
        } else {
            return `${textoHoras} horas`;
        }
    }
};