import instance from "./configDashboard";

export async function getCasesStatus(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(`/list-tasks-status`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSlaStatus(
  token,
  datestart,
  dateend,
  slaType,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    let response;

    if (slaType === "inside") {
      response = await instance.get(`/list-inside-sla`, config);
    } else {
      response = await instance.get(`/list-exceeded-sla`, config);
    }

    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSlaMeta(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.get(`/get-sla`, config);

    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function changeSlaMeta(token, slaValue) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const data = [];

    const response = await instance.put(`change-sla/${slaValue}`, data, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getTypesSolicitations(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(`/list-request-type`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getFirstCallResolution(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(`list-first-call-resolution`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getLastActivitys(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(`/list-tickets-date`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getCollaborators(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(`/list-collaborators`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSpecificStatus(
  token,
  datestart,
  dateend,
  idstatus,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          idstatus,
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          idstatus,
        },
      };
    }

    const response = await instance.get(`/list-request-type-status`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSpecificSLA(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(`/list-request-sla`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSpecificTypeSolicitation(
  token,
  datestart,
  dateend,
  id_tipo,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          id_tipo,
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          id_tipo,
        },
      };
    }

    const response = await instance.get(`/list-request-reason`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSpecificSubTypeSolicitation(
  token,
  datestart,
  dateend,
  id_motivo,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          id_motivo,
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          id_motivo,
        },
      };
    }

    const response = await instance.get(`/list-request-subreason`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSpecificTypeStaggered(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(`/list-stagger`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSpecificTypeFCR(
  token,
  datestart,
  dateend,
  filtersDashboard,
  organizationsProfile
) {
  try {
    let config = {};

    if (filtersDashboard && filtersDashboard.active === true) {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
          contact: filtersDashboard.clients.map((option) => option.id).join(),
          company: filtersDashboard.companys.map((option) => option.id).join(),
          requested: filtersDashboard.requesteds
            .map((option) => option.id)
            .join(),
          agent: filtersDashboard.agents
            .map((option) => option.id_usuario)
            .join(),
          priority: filtersDashboard.prioritys
            .map((option) => option.id)
            .join(),
          channel: filtersDashboard.channels.map((option) => option.id).join(),
          organizations: filtersDashboard.organizations
            .map((option) => option.id)
            .join(),
        },
      };
    } else {
      config = {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        params: {
          datestart,
          dateend,
          perfil_organizacoes: organizationsProfile
            .map((opt) => opt.idorganizacao)
            .join(),
        },
      };
    }

    const response = await instance.get(
      `/list-request-first-call-resolution`,
      config
    );
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}
