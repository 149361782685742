//GLOBAL - components from npm
import React from "react";

//STYLES
import "./page-title.scss";

//COMPONENTS
import { Typography } from "../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function PageTitle({
  className = "",
  title = "Page Title",
  subtitle = "",
  leftContent,
  rightContent,
  classTitle,
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <header className={`page-title ${className}`}>
      <div
        className={`left-container ${
          className ? `${className}__left-content` : ""
        }`}
      >
        <Typography.H2
          className={classTitle ? classTitle : "page-title__title"}
          color="gray-180"
          weight="bold"
        >
          {title}
          <span>{subtitle}</span>
        </Typography.H2>
        {leftContent}
      </div>

      {rightContent}
    </header>
  );
}
