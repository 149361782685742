//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Modal from "..";
import Loading from "../../Loading";
import { Button, Typography } from "../../../general";
import { Input, Select } from "../../../data-entry";
import { FieldContainer } from "../../../data-display";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIShiftWorker from "./../../../../services/api/shiftWorker";
import * as APISla from "./../../../../services/api/sla";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...

//STYLES
import "./create-shift-worker.scss";

export default function CreateShiftWorker({ isOpen, onClose, onCreate, shiftWorkers }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("requesting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [errorEmailInput, setErrorEmailInput] = useState(false);
    const [onBlurErrorEmailInput, setonBlurErrorEmailInput] = useState(false);
    const [errorPhoneInput, setErrorPhoneInput] = useState(false);
    const [onBlurErrorPhoneInput, setonBlurErrorPhoneInput] = useState(false);
    // const [isValidPhone, setIsValidPhone] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [positions] = useState([
        { id: 1, label: "Analista" },
        { id: 2, label: "Supervisor" },
        { id: 3, label: "Gerente" },
    ]);
    const [formValues, setFormValues] = useState(() => {
        return {
            id_usuario: null,
            nome_plantonista: "",
            telefone_plantonista: "",
            email_plantonista: "",
            id_cargo: null,
            id_organizacao: null
        }
    });

    //FUNCTIONS
    function cleanFields() {
        setTimeout(() => {
            setFormValues({
                ...formValues,
                id_usuario: null,
                nome_plantonista: "",
                telefone_plantonista: "",
                email_plantonista: "",
                id_cargo: null,
                id_organizacao: null
            });
            setErrorEmailInput(false);
            setonBlurErrorEmailInput(false);
            setValidationWarn(false);
            setErrorPhoneInput(false);
            setonBlurErrorPhoneInput(false);
        }, 50)
    };

    function onCloseCustom() {
        onClose();
        cleanFields();
    };

    function onBlurEventInputError() {
        if (formValues.email_plantonista && !/\S+@\S+\.\S+/.test(formValues.email_plantonista)) {
            setErrorEmailInput(true);
            setonBlurErrorEmailInput(true);
        } else {
            setErrorEmailInput(false);
            setonBlurErrorEmailInput(false);
        }

        if (formValues.telefone_plantonista && formValues.telefone_plantonista.length < 11) {
            setErrorPhoneInput(true);
            setonBlurErrorPhoneInput(true);
        } else {
            setErrorPhoneInput(false);
            setonBlurErrorPhoneInput(false);
        }
    };

    function isFormValid() {
        if (!formValues.nome_plantonista ||
            !formValues.id_cargo ||
            !formValues.id_organizacao ||
            !formValues.telefone_plantonista ||
            !formValues.email_plantonista) {
            return false;
        }

        if (formValues.email_plantonista && !/\S+@\S+\.\S+/.test(formValues.email_plantonista)) {
            return false;
        }

        if (formValues.telefone_plantonista && formValues.telefone_plantonista.length < 11) {
            return false;
        }

        return true;
    };

    function handleInvalidForm() {
        setValidationWarn(true);
        setErrorPhoneInput(!formValues.telefone_plantonista);
        setErrorEmailInput(!formValues.email_plantonista || !/\S+@\S+\.\S+/.test(formValues.email_plantonista));

        Toast(
            "Erro ao adicionar plantonista!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    async function handleCreateShiftWorker() {
        if (!isFormValid()) {
            handleInvalidForm();
            return;
        }

        setPageStatus("creating-shift-worker");

        const values = { ...formValues, id_usuario: userData.userId };

        const registeredPhone = shiftWorkers?.some(
            item =>
                item.telefone_plantonista === formValues.telefone_plantonista &&
                item.id_organizacao === formValues.id_organizacao
        );

        if (registeredPhone) {
            Toast(
                "Atenção!",
                "Este número já está cadastrado para esta organização. Por favor, insira um número diferente.",
                "warn"
            );
            setPageStatus("awaiting");
            return;
        }

        const response = await APIShiftWorker.createShiftWorker(token, values);

        if (response.status === 200) {
            Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
            onCreate();
            onCloseCustom();
        } else {
            Toast(
                "Não foi possível adicionar o plantonista!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    async function getOrganizationsList() {
        setPageStatus("requesting");

        const response = await APISla.listOrganizations(token);

        if (response.status === 200) {
            let dataUpdatedOrganization = [];

            response.data.forEach((data) => {
                let requestedItemOrganization = {};

                requestedItemOrganization.id = data.id_organizacao;
                requestedItemOrganization.label = data.nome;

                dataUpdatedOrganization.push(requestedItemOrganization);
            });

            setOrganizations(dataUpdatedOrganization)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem de organizações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    //USE EFFECTS
    useEffect(() => {
        if (isOpen) getOrganizationsList()
    }, [isOpen]);

    return (
        <Modal.Main
            className="modal-create-shift-worker"
            title="Adicionar plantonista"
            width="704px"
            height="384px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <>
                    <Button
                        className="modal-create-shift-worker__btn-confirm"
                        disabled={pageStatus === "creating-shift-worker"}
                        onClick={handleCreateShiftWorker}
                    >
                        {pageStatus !== "creating-shift-worker" ? (
                            <Typography.H4 weight="bold">
                                Adicionar
                            </Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>

                    <Button
                        className="modal-create-shift-worker__btn-cancel"
                        disabled={pageStatus === "creating-shift-worker"}
                        onClick={() => onCloseCustom()}
                    >
                        <Typography.H4
                            weight="bold"
                            color="red-2"
                        >
                            Cancelar
                        </Typography.H4>
                    </Button>
                </>
            }
        >
            <main className="modal-create-shift-worker-content">
                <FieldContainer title="Nome" required>
                    <Input
                        placeholder="Digite seu nome aqui"
                        value={formValues.nome_plantonista}
                        onChange={(event) => setFormValues({ ...formValues, nome_plantonista: event })}
                        error={validationWarn ? !formValues.nome_plantonista : false}
                    />
                </FieldContainer>

                <div className="modal-create-shift-worker-content__section1">
                    <FieldContainer title="Telefone" required>
                        <Input
                            type="numberMask"
                            placeholder="XX 9 9124-5678"
                            mask={"(99) 9 9999-9999"}
                            value={formValues.telefone_plantonista}
                            onBlur={onBlurEventInputError}
                            onChange={(event) => setFormValues({ ...formValues, telefone_plantonista: event })}
                            typeInputMessage={onBlurErrorPhoneInput ? "error" : false}
                            error={
                                errorPhoneInput ?
                                    (formValues.telefone_plantonista && formValues.telefone_plantonista.length < 11) ||
                                    !formValues.telefone_plantonista : false
                            }
                            customInputMessage={"O número deve ter 11 dígitos"}
                        />
                    </FieldContainer>

                    <FieldContainer title="Email" required>
                        <Input
                            type="email"
                            placeholder="email@hotmail.com"
                            value={formValues.email_plantonista}
                            onBlur={onBlurEventInputError}
                            onChange={(event) => setFormValues({ ...formValues, email_plantonista: event })}
                            typeInputMessage={onBlurErrorEmailInput ? "error" : false}
                            error={
                                errorEmailInput ?
                                    (formValues.email_plantonista && !/\S+@\S+\.\S+/.test(formValues.email_plantonista)) ||
                                    !formValues.email_plantonista : false
                            }
                            customInputMessage={"E-mail inválido"}
                        />
                    </FieldContainer>
                </div>

                <div className="modal-create-shift-worker-content__section2">
                    <FieldContainer title="Cargo" required>
                        <Select
                            upward
                            placeholder="Selecione o cargo"
                            options={positions}
                            value={formValues.id_cargo}
                            onSelect={(position) => {
                                setFormValues({ ...formValues, id_cargo: position.id });
                            }}
                            error={validationWarn ? !formValues.id_cargo : false}
                        />
                    </FieldContainer>

                    <FieldContainer title="Organização" required>
                        <Select
                            upward
                            placeholder="Selecione a organização"
                            options={organizations}
                            value={formValues.id_organizacao}
                            onSelect={(organization) => {
                                setFormValues({ ...formValues, id_organizacao: organization.id });
                            }}
                            error={validationWarn ? !formValues.id_organizacao : false}
                        />
                    </FieldContainer>
                </div>
            </main>
        </Modal.Main>
    )
}
