import Staggered from "./Staggered";
import Organization from "./Organization";
import ShiftWorker from "./ShiftWorker";
import Status from "./Status";
import User from "./User";
import Profile from "./Profile";
import RecordType from "./RecordType";

const Records = {
    Organization,
    ShiftWorker,
    Status,
    User,
    Profile,
    Staggered,
    RecordType
}

export default Records;