//GLOBAL - components from npm
import React from 'react';

//STYLES
import './hyperlink.scss';

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...


export default function Hyperlink({
  className,
  children,
  weight,
  color
}) {

  //GENERAL
  const mainClassName = 'typography-hyperlink'

  //STATES


  //REDUX - Selectors


  //FUNCTIONS


  //USE EFFECTS


  return (
    <h6 className={`${mainClassName} ${className ? className : ''} ${weight ? `${mainClassName}--${weight}` : ''} ${color ? `${mainClassName}--color-${color}` : ''}`}>
      {children}
    </h6>
  )
}
