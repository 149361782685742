//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import { Table } from "../../../components/data-display";
import { Input } from "../../../components/data-entry";
import { Button, Typography } from "../../../components/general";
import { Loading, Modal } from "../../../components/feedback";
import { Toast } from "../../../utils/toast";
import { convertToHoursOrMinutes } from "../../../utils/convertToHoursOrMinutes";
import CreateSla from "./CreateSla/CreateSla"
import UpdateSla from "./UpdateSla/UpdateSla"

//SERVICES - api, conectors...
import * as APISla from "./../../../services/api/sla";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-2.svg";
import { ReactComponent as ArrowLefIcon } from "../../../assets/icons/Arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search-normal.svg";
import { ReactComponent as TimerIcon } from "../../../assets/icons/timer.svg";

//STYLES
import "./sla.scss";

export default function SLA({ returnToRecord }) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [searchData, setSearchData] = useState("");
    const [emptyState, setEmptyState] = useState(false);
    const [slaData, setSlaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [registerSla, setRegisterSla] = useState(false);
    const [filteredData, setFilteredData] = useState(false);
    const [updatedSla, setUpdatedSla] = useState(false);
    const [isOpenModalDeleteSla, setIsOpenModalDeleteSla] = useState({ modal: false, slaId: null });
    const [updateSla, setUpdateSla] = useState({ state: false, slaId: null });
    const [filteredSLAs, setFilteredSLAs] = useState([]);

    //FUNCTIONS
    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function handleSearchSla(event) {
        const inputValue = event;

        setSearchData(inputValue);

        if (event === "") {
            setFilteredData(false);
            setFilteredSLAs([]);
        }
    };

    function handleTableDataLookup() {
        const searchTerm = searchData.toLowerCase();

        const filteredData = slaData.filter(
            (data) =>
                (data.desc_sla != null &&
                    data.desc_sla !== "" &&
                    data.desc_sla.toLowerCase().includes(searchTerm)) ||
                (data.criticidade != null &&
                    data.criticidade !== "" &&
                    data.criticidade.toLowerCase().includes(searchTerm)) ||
                (data.nome_organizacao != null &&
                    data.nome_organizacao !== "" &&
                    data.nome_organizacao.toLowerCase().includes(searchTerm))
        );

        setFilteredSLAs(filteredData);
        setFilteredData(true);
    };

    function refactorSLAListing(data) {
        let refactoredData = [];

        if (!data) throw new Error('Error when fetching data!');

        data && data.map((item) => {
            const newObject = {
                description: {
                    data: item.desc_sla,
                    config: {
                        textEllipsis: true,
                        className: "table-sla__description"
                    }
                },
                organization: {
                    data: item.nome_organizacao,
                    config: {
                        textEllipsis: true,
                        className: "table-sla__organization"
                    }
                },
                criticality: {
                    data: item.criticidade,
                    config: {
                        textEllipsis: true,
                    }
                },
                start: {
                    data: <span>{convertToHoursOrMinutes(item.sla_inicio)}</span>,
                    config: {
                        textEllipsis: true,
                        className: "table-sla__start"
                    }
                },
                final: {
                    data: <span>{convertToHoursOrMinutes(item.sla_resolucao)}</span>,
                    config: {
                        textEllipsis: true,
                        className: "table-sla__final"
                    }
                },
                actions: {
                    data: (
                        <div className="actions-sla">
                            <button
                                className="actions-sla__edit"
                            >
                                <EditIcon
                                    onClick={() => setUpdateSla({
                                        state: true,
                                        slaId: item.id_sla
                                    })}
                                />
                            </button>

                            <button
                                className="actions-sla__delete"
                            >
                                <TrashIcon
                                    onClick={() => setIsOpenModalDeleteSla({
                                        modal: true,
                                        slaId: item.id_sla
                                    })}
                                />
                            </button>
                        </div>
                    ),
                    config: {
                        className: "actions-sla",
                    }
                }
            };

            refactoredData.push(newObject);

            return refactoredData;
        });

        return refactoredData;
    };

    function renderTableContent() {
        switch (loading) {
            case true:
                return (
                    <div className="table-card-content__loading">
                        <Loading.Paperplane />
                    </div>
                )

            case false:
                if (slaData.length > 0) {
                    return (
                        <>
                            {searchData && filteredData && filteredSLAs.length < 1 ? (
                                <div className="table-card-content__empty-state">
                                    <div className="table-card-content__empty-state__icon">
                                        <TimerIcon />
                                    </div>

                                    <div className="table-card-content__empty-state__text">
                                        <Typography.H4 weight="bold" color="blue-selected-4">
                                            Ops! Sem SLAs cadastrados.
                                        </Typography.H4>

                                        <Typography.H5 color="gray-180" className="text-center">
                                            Clique no botão <span className="text-bold">"Adicionar SLA"</span>. <br />
                                            Preencha os dados do novo SLA.
                                        </Typography.H5>
                                    </div>
                                </div>
                            ) : (
                                <div className="table-card-content__table-container">
                                    <Table
                                        fixedHeader
                                        className="table-sla"
                                        emptyState={emptyState}
                                        setEmptyState={setEmptyState}
                                        columns={tableColumns}
                                        data={searchData && filteredData ? slasFiltered : slasData}
                                    />
                                </div>
                            )}
                        </>
                    )
                } else {
                    return (
                        <div className="table-card-content__empty-state">
                            <div className="table-card-content__empty-state__icon">
                                <TimerIcon />
                            </div>

                            <div className="table-card-content__empty-state__text">
                                <Typography.H4 weight="bold" color="blue-selected-4">
                                    Ops! Sem SLAs cadastrados.
                                </Typography.H4>

                                <Typography.H5 color="gray-180" className="text-center">
                                    Clique no botão <span className="text-bold">"Adicionar SLA"</span>. <br />
                                    Preencha os dados da novO SLA.
                                </Typography.H5>
                            </div>
                        </div>
                    )
                }

            default:
                break;
        }
    };

    async function getSlaDataList() {
        setLoading(true);

        const response = await APISla.listSla(token);

        if (response.status === 200) {
            setSlaData(response.data);
        } else {
            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    //USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Descrição",
                accessor: "description",
                id: "description",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Organização",
                accessor: "organization",
                id: "organization",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Criticidade",
                accessor: "criticality",
                id: "criticality",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Início",
                accessor: "start",
                id: "start",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Final",
                accessor: "final",
                id: "final",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Ações",
                accessor: "actions",
                id: "actions",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            }
        ],
        []
    );

    const slasData = useMemo(() => {
        if (slaData) {
            const refactoredData = refactorSLAListing(slaData);
            return refactoredData;
        }
    }, [slaData]);

    const slasFiltered = useMemo(() => {
        if (filteredSLAs) {
            const refactoredData = refactorSLAListing(filteredSLAs);
            return refactoredData;
        }
    }, [filteredSLAs]);

    //USE EFFECTS
    useEffect(() => {
        getSlaDataList();
    }, []);

    useEffect(() => {
        if (!searchData || searchData.trim() === "") {
            setFilteredData(false)
            setFilteredSLAs([])
        }
    }, []);

    useEffect(() => {
        if (updatedSla) {
            handleTableDataLookup()
        }
    }, [updatedSla, slaData]);

    return (
        <>
            {registerSla ? (
                <CreateSla
                    toGoBack={() => setRegisterSla(false)}
                    onCreate={getSlaDataList}
                />
            ) : updateSla.state ? (
                <UpdateSla
                    toGoBack={() => setUpdateSla({ state: false })}
                    slaId={updateSla.slaId}
                    onUpdate={getSlaDataList}
                    detailedSla={slaData}
                    setUpdatedSla={setUpdatedSla}
                    handleTableDataLookup={handleTableDataLookup}
                />
            ) : (
                <main className="page-sla">
                    <header className="page-sla__header">
                        <div className="page-sla__header__title">
                            <ArrowLefIcon onClick={returnToRecord} />

                            <Typography.H2 color="gray-180" weight="bold">
                                Cadastro de SLA
                            </Typography.H2>
                        </div>

                        <div>
                            <Button
                                className=""
                                onClick={() => setRegisterSla(true)}
                            >
                                <Typography.H4 weight="bold" color="white">
                                    Adicionar SLA
                                </Typography.H4>
                            </Button>
                        </div>
                    </header>

                    <div className="table-card">
                        <div className="table-card-content">
                            <Input
                                type="text"
                                width="432px"
                                placeholder="Pesquisar"
                                value={searchData}
                                onChange={handleSearchSla}
                                onKeyPress={(event) =>
                                    (event.code === "Enter" || event.code === "NumpadEnter") &&
                                    handleTableDataLookup()
                                }
                                rightIcon={
                                    <button onClick={handleTableDataLookup}>
                                        <SearchIcon />
                                    </button>
                                }
                            />

                            {renderTableContent()}
                        </div>
                    </div>
                </main>
            )}

            <Modal.UpdateSLA
                isOpen={isOpenModalUpdate}
                slaData={slaData}
                onClose={(event) => setIsOpenModalUpdate(event)}
                onEdit={getSlaDataList}
            />

            <Modal.DeleteSLA
                isOpen={isOpenModalDeleteSla.modal}
                onClose={(event) => setIsOpenModalDeleteSla({ modal: event })}
                slaData={slaData}
                deletedSlaId={isOpenModalDeleteSla.slaId}
                onDelete={getSlaDataList}
                handleTableDataLookup={handleTableDataLookup}
            />
        </>
    )
}
