import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/material/styles";
import ptBrLocale from "date-fns/locale/pt-BR";
import { isPast, addHours, format, isValid, sub } from "date-fns";

import { ReactComponent as IconCalendar } from "../../../../../src/assets/icons/Calendar.svg";
import { ReactComponent as IconCalendar2 } from "../../../../../src/assets/icons/Calendar-2.svg";

import "./date-range-input.scss";

const CustomDatePicker = styled(DatePicker)({
  '& .MuiInputBase-input': {
    padding: '7px 10px',
  },
});

export default function DateRangeInput({
  value,
  setDate,
  startDate,
  endDate,
  minimDate,
  label,
  selectRangeType,
  className,
  isClearable = true,
  ref,
  casesDefault = false,
  dateInStringFormat
}) {
  const maxDateDefault = (new Date().getFullYear() + 100).toString();
  const casesDateDefault = React.useMemo(() => sub(new Date(), { months: 1 }), []);

  const [valueDate, setValueDate] = useState(value);
  const [verifyDate, setVerifyDate] = useState(false);

  const checkDate = (date) => {
    const pastDate = isPast(date);
    if (minimDate && pastDate) {
      if (dateInStringFormat) {
        setDate(addHours(minimDate, 1).toISOString());
      } else {
        setDate(addHours(minimDate, 1));
      }
    } else {
      if (dateInStringFormat) {
        setDate(date?.toISOString());
      } else {
        setDate(date);
      }
    }
  };

  const modifyDate = (dateParams) => {
    if (dateParams?.length === 10) {
      setVerifyDate(true);
    } else {
      setVerifyDate(false);
    }
    if (!dateParams && casesDefault) {
      checkDate(casesDateDefault);
    }
  };

  const handleIconPicker = (range) => (range === "start" ? IconCalendar : IconCalendar2);

  useEffect(() => {
    setValueDate(value);
  }, [value]);

  useEffect(() => {
    if (verifyDate && isValid(valueDate)) {
      checkDate(valueDate);
    }
  }, [verifyDate, valueDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
      <CustomDatePicker
        ref={ref}
        label={label}
        value={valueDate}
        onChange={(newValue) => {
          if (newValue === null && casesDefault) {
            setValueDate(casesDateDefault);
          } else {
            setValueDate(newValue);
          }

          if (newValue !== startDate || newValue !== endDate) {
            modifyDate(newValue);
          }
        }}
        clearable={isClearable}
        clearText={"Limpar"}
        showTodayButton={true}
        minDate={startDate ?? -0}
        maxDate={endDate ?? new Date(maxDateDefault)}
        components={{ OpenPickerIcon: handleIconPicker(selectRangeType) }}
        OpenPickerButtonProps={{ "aria-label": "Escolha a data" }}
        leftArrowButtonText="Mês Anterior"
        rightArrowButtonText="Próximo Mês"
        renderInput={(params) => {
          params.inputProps.placeholder = "__/__/____";

          if (!params.inputProps.value && casesDefault) {
            modifyDate(format(casesDateDefault, "dd/MM/yyyy"));
            params.inputProps.value = format(casesDateDefault, "dd/MM/yyyy");
          } else {
            modifyDate(params.inputProps.value);
          }
          return <TextField className={"dateRangeInput " + className} {...params} />;
        }}
      />
    </LocalizationProvider>
  );
}
