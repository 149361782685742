//GLOBAL - components from npm
import React from "react";
import { Tooltip } from 'react-tippy';

//STYLES
import "./field-container.scss";
import 'react-tippy/dist/tippy.css';

//COMPONENTS
import { Typography } from "../../../components/general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function FieldContainer({
  className = "",
  title,
  untitled,
  children = "content example",
  orientation,
  required,
  requiredText = "*",
  htmlFor,
  icon,
  labelRightIcon,
  labelRightTooltipContent,
  titleSize = "medium", // small || medium || large
  disabled,
}) {
  //GENERAL
  const tooltipTitle =
    <Typography.H5>{labelRightTooltipContent}</Typography.H5>

  //STATES

  //REDUX - Selectors

  //FUNCTIONS
  function renderTitle() {
    const titleElement = (size, color, weight) => (
      <Typography.H6
        className="field-container__title"
        color={color}
        weight={weight}
      >
        {title}
        {required && <span className="symbol-warning">{requiredText}</span>}
      </Typography.H6>
    );

    switch (titleSize) {
      case "medium":
        return titleElement("H5", "gray-600", "400");
      case "large":
        return titleElement("H4", "black", "bold");
      default:
        return titleElement("H6", "gray-600", "400");
    }
  }

  //USE EFFECTS

  return (
    <label
      style={{ pointerEvents: disabled ? "none" : "" }}
      htmlFor={htmlFor}
      className={`field-container ${className} ${orientation === "horizontal" ? "field-container--horizontal" : ""}`}
    >
      {title && (
        <div className="field-container__header">
          {icon && <div className="field-container__icon-container">{icon}</div>}

          {renderTitle()}

          {labelRightIcon && (
            <div className="field-container__icon-container">
              {labelRightTooltipContent ? (
                <Tooltip
                  html={(tooltipTitle)}
                >
                  {labelRightIcon}
                </Tooltip>
              ) : (
                labelRightIcon
              )}
            </div>
          )}
        </div>
      )}
      {untitled && (
        <div className="field-container__header">
          <Typography.H6></Typography.H6>
        </div>
      )}
      {children}
    </label>
  );
}
