import instance from "./configAxios";

export async function getList0fRequestTypesV2(token, organizationId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/list-type-solicitation-v2/${organizationId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function getList0fRequestTypesV3(token, organizationId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/list-type-solicitation-v3/${organizationId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function getReasonsListToTypeV2(token, typeId, organizationId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/list-reasons-to-type-v3/${typeId}/${organizationId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function getSubReasonsListToTypeV4(token, reasonId, typeId, organizationId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/list-subreasons-to-reason-v4/${reasonId}/${typeId}/${organizationId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function getOrganizationsList(token, userId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/list-organizacao/${userId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function AssociateTypeReason(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/associate-type-solicitation-reason', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function AssociateReasonSubreason(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/associate-reason-subreason', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function AssociateReasonSubreasonSla(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/associate-reason-subreason-sla', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function AssociateReasonSubreasonSlaV2(token, data) { // data = id_sla, id_tipo_solicitacao, id_motivo, id_submotivo, editavel, id_organizacao
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/associate-reason-subreason-sla-v2', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function associateSlaWithReason(token, data) { // data = id_sla, editavel, id_motivo, id_tipo_solicitacao
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/associate-type-solicitation-reason-sla', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function associateSlaWithReasonV2(token, data) { // data = id_sla, id_tipo_solicitacao, id_motivo, editavel, id_organizacao
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/associate-type-solicitation-reason-sla-v2', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function updateReason(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put('/update-reason', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function updateSlaAssociatedWithReason(token, data) { // id_sla, id_tipo_solicitacao, id_motivo, editavel
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put('/update-type-solicitation-reason', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function updateSlaAssociatedWithReasonV2(token, data) { // id_tipo_solicitacao,id_motivo,id_sla, editavel,desc_motivo, id_organizacao
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put('/update-type-solicitation-reason-v2', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function updateSubreason(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put('/update-subreason', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function updateSlaAssociatedWithSubreason(token, data) { // id_tipo_solicitacao, id_motivo, id_submotivo, id_sla, editavel
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put('/update-type-solicitation-subreason', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function updateSlaAssociatedWithSubreasonV2(token, data) { // id_tipo_solicitacao,id_motivo,id_submotivo, id_sla, editavel,desc_submotivo,id_organizacao
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put('/update-type-solicitation-subreason-v2', data, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function deleteSubreason(token, subreasonId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.delete(`/delete-subreason/${subreasonId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function deleteSubreasonV2(token, typeId, reasonId, subreasonId, organizationId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.delete(`/delete-subreasonv2/${typeId}/${reasonId}/${subreasonId}/${organizationId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function deleteReasonV2(token, typeId, idReason, organizationId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.delete(`/delete-reasonv2/${typeId}/${idReason}/${organizationId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}

export async function createSubreason(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/create-subreason', data, config);

        return response;
    } catch (error) {
        return error
    }
};

export async function getSubReasonsListToTypeV3(token, reasonId, typeId) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/list-subreasons-to-reason-v3/${reasonId}/${typeId}`, config);

        return response;
    } catch (error) {
        return error.message;
    }
}