//GLOBAL - components from npm
import React, { useEffect, forwardRef, useState, useRef } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { addHours, set } from "date-fns";
import ReactInputMask from 'react-input-mask';

//UTILS
import { Toast } from "../../../../utils/toast";

//STYLES
import "./time-range.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconClock } from "../../../../../src/assets/icons/Clock.svg";

export default function TimeRange({
  selected,
  setDate,
  dateFormat,
  timeIntervals = 15,
  minTime,
  maxTime,
  placeholderText = '00:00',
  width,
  widthSize,
  border,
  error,
  errorType = 'text', // error types: 'text' and 'border'
  disable
}) {
  //GENERAL
  registerLocale("pt-BR", ptBR);
  setDefaultLocale("pt-BR");

  //STATES
  const [isOpen, setIsOpen] = useState(false);
  const [errorTime, setErrorTime] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => {
    return (
      <label 
      ref={ref}
      onClick={onClick}
      style={{ '--width': width ? width : '' || widthSize ? widthSize : '', backgroundColor: disable === false ? "#DBDDE2":"", border: border ? border : '' }}
      className={`input-time-range ${isOpen ? 'input-time-range--open' : ''} ${!value ? 'input-time-range-placeholder' : ''} ${(error && errorType === 'text') ? 'input-time-range--error-text' : ''} ${error && errorType === 'border' || errorTime ? 'input-time-range--error-border' : ''}`}
      >
        <TimeInput value={value} placeholder={placeholderText} onChange={(e) => {onChange(e)}}/>
        <IconClock/>
      </label>
    );
  });

  function TimeInput(props) {
    let mask = '12:34';
    
    let formatChars = {
      '1': '[0-2]',
      '2': '[0-9]',
      '3': '[0-5]',
      '4': '[0-9]'
    };
  
    let beforeMaskedValueChange = (newState, oldState, userInput) => {
      let { value } = newState;

      // Conditional mask for the 2nd digit base on the first digit
      if(value.startsWith('2'))
        formatChars['2'] = '[0-3]'; // To block 24, 25, etc.
      else
        formatChars['2'] = '[0-9]'; // To allow 05, 12, etc.
      return {value, selection: newState.selection};
    }
  return (
      <ReactInputMask 
        mask={mask}
        value={props.value} 
        autoFocus={isOpen ? true : false}
        onChange={(e) => {
        setErrorTime(false)
        props.onChange(e)}}
        formatChars={formatChars}
        placeholder={props.placeholder}
        beforeMaskedValueChange={beforeMaskedValueChange}
        className={disable === false ? "input-class":""}
        >
      </ReactInputMask>
    );
  }

  const filterOutTime = (time) => {
    const selectedDate = new Date(time);

    return minTime.getTime() < selectedDate.getTime();
  };

  //USE EFFECTS
  useEffect(() => {
    if (selected) {
      if (selected === maxTime && selected?.getTime() <= minTime.getTime()) {
        setDate(addHours(minTime, 1));
      }
    }
  }, [minTime, maxTime]);

  useEffect(() => {
    setErrorTime(false)
  }, [selected])

  // useEffect(() => {
  //   if (internalSelected !== selected) {
  //       setDate(internalSelected)
  //   }
  // }, [internalSelected])


  return (
    <div className="time-range-custom">
      <DatePicker
        onCalendarClose={() => {
          setErrorTime(false);
          setIsOpen(false)}}
        onCalendarOpen={() => setIsOpen(true)}
        showTimeSelect
        showTimeSelectOnly
        onChange={date => {
          if(minTime) {
            if(selected) {
              const oldDate = selected;
              const newDate = set(new Date(oldDate), { hours: date.getHours(), minutes: date.getMinutes()})

              if(newDate.getTime() <= minTime.getTime()) {
                setErrorTime(true)
                Toast(
                  "Hora selecionada inválida!",
                  "Não é possível adicionar hora menor que a atual.",
                  "error"
                );
                return;
              }
            } else {
              if (date.getTime() <= minTime.getTime()) {
                setErrorTime(true)
                Toast(
                  "Hora selecionada inválida!",
                  "Não é possível adicionar hora menor que a atual.",
                  "error"
                );
                return;
              }
            }
          }

          if(selected) {
            const oldDate = selected;
            const newDate = set(new Date(oldDate), { hours: date.getHours(), minutes: date.getMinutes()})

            return setDate(newDate)
          }
          setDate(date)
        }}
        customInput={<CustomInput placeholderText={placeholderText} />}
        timeCaption={false}
        timeIntervals={timeIntervals}
        selected={selected}
        dateFormat={dateFormat}
        showPopperArrow={false}
        filterTime={minTime ? filterOutTime : false}
      />
    </div>
  );
}
