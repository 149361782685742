import instance from "./configAxios";

export async function contactImport(formData, token, onProgress) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                onProgress(percentCompleted);
            }
        }

        const response = await instance.post("/uploadcontact", formData, config);

        return response;
    } catch (error) {
        return error.response;
    }
}