//GLOBAL - components from npm
import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { isAfter, isPast, format } from "date-fns";
import * as XLSX from "xlsx/xlsx.mjs";
// import { cloneDeep, debounce } from "lodash";
import { useHistory } from "react-router";
// import { useSpring, animated } from "react-spring";

//STYLES
import "./list.scss";

//COMPONENTS
import Kanban from "./Kanban/Kanban";
import SplitView from "./SplitView/SplitView";
import {
  PageTitle,
  Table,
  CriticalityFlag,
  StatusSymbol,
  Channel,
} from "./../../../components/data-display";
import {
  Input,
  DatePicker,
  FilterList,
} from "./../../../components/data-entry";
import { Button, Typography } from "./../../../components/general";
import { Loading, Modal, Tippy, Drawer } from "./../../../components/feedback";
import {
  PopoverMenu,
  PopoverColumnsFilter,
} from "../../../components/navigation";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import ThreeDotsOptions from "../../../components/ThreeDotsOptions";

//SERVICES - api, conectors...
import * as APICase from "./../../../services/api/case";
// import * as APIClient from "./../../../services/api/client";
import * as APIAdditionalFields from "./../../../services/api/additionalFields";
import { connection_platform } from "./../../../services/api/configConnection";

//GLOBAL STATE - redux, env...
import { setFilter, setStorageFilter } from "../../../store/filters";
import {
  setTableFilter,
  setVariableTableFilter,
} from "../../../store/tableFilters";
import { setFilteredCaseData, setSearchCase } from "../../../store/searchSlice";
// import { doProfilePermissions } from "../../../store/auth";

// UTILS
import formatDate from "./../../../utils/formatDate";
import { maskCpfCnpj } from "../../../utils/masks";
import { Toast } from "./../../../utils/toast";
import { formatPhone } from "../../../utils/formatPhone";
// import { dateFormatWithoutTimes } from "../../../utils/dateFormatWithoutTimes";
import getLocalStorage from "./../../../utils/getLocalStorage";

//ASSETS - icons, images...
import { ReactComponent as IconSearch } from "./../../../assets/icons/Search-normal.svg";
import { ReactComponent as ImgEmptyState } from "./../../../assets/images/empty-state.svg";
import { ReactComponent as IconMoreVertical } from "./../../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as IconFilter } from "./../../../assets/icons/Filter-search.svg";
import { ReactComponent as IconAddCircle } from "./../../../assets/icons/AddCircle.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-2.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash-2.svg";
import { ReactComponent as IconHamburguer } from "../../../assets/icons/Hamburguer.svg";
import { ReactComponent as IconKanban } from "../../../assets/icons/Kanban.svg";
import { ReactComponent as IconSplitView } from "../../../assets/icons/SplitView.svg";
import { ReactComponent as ColumnFilterIcon } from "../../../assets/icons/ColumnFilterIcon.svg";
import { ReactComponent as ExcelDownloadIcon } from "../../../assets/icons/ExcelDownloadIcon.svg";
import { ReactComponent as IconStatusCanceled } from "../../../assets/icons/StatusCanceled.svg";
import { ReactComponent as IconStatusComplete } from "../../../assets/icons/StatusComplete.svg";
import { ReactComponent as IconStatusInProgress } from "../../../assets/icons/StatusInProgress.svg";
import { ReactComponent as IconStatusPending } from "../../../assets/icons/StatusPending.svg";
import { ReactComponent as IconStatusScaled } from "../../../assets/icons/StatusScaled.svg";
import { ReactComponent as IconStatusNew } from "../../../assets/icons/StatusNew.svg";
// import { ReactComponent as CleanIcon } from "../../../assets/icons/Clean.svg";
// import { ReactComponent as ColumnTabelIcon } from "../../../assets/icons/Column_Tabel.svg";
// import { ReactComponent as ColumnTabelIconActive } from "../../../assets/icons/Column_TabelActive.svg";
// import { ReactComponent as ColumnKanbanIcon } from "../../../assets/icons/Column_Kanban.svg";
// import { ReactComponent as ColumnKanbanIconActive } from "../../../assets/icons/Column_KanbanActive.svg";
// import { ReactComponent as ColumnSplitViewIcon } from "../../../assets/icons/Column_SplitView.svg";
// import { ReactComponent as ColumnSplitViewIconActive } from "../../../assets/icons/Column_SplitViewActive.svg";
import { ReactComponent as IconImportCases } from "../../../assets/icons/import-icon.svg";

export default function List() {
  //GENERAL
  const dispatch = useDispatch();
  const refPage = useRef();
  const location = useLocation();
  const initialSorted = getLocalStorage("initialSorted", null);
  const effectRan = useRef(false);
  const history = useHistory();

  //STATES
  const [isOpenModalAddCase, setIsOpenModalAddCase] = useState(false);
  const [isOpenModalDeleteCase, setIsOpenModalDeleteCase] = useState(false);
  const [isOpenModalExportCase, setIsOpenModalExportCase] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [pageStatus, setPageStatus] = useState("requesting");
  const [typeView, setTypeView] = useLocalStorage("caseViewType", "normal");
  const [casesData, setCasesData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [dataKanban, setDataKanban] = useState([]);
  const [isDrawerFiltersOpen, setIsDrawerFiltersOpen] = useState(false);
  const [emptyState, setEmptyState] = useState();
  const [filtersList, setFiltersList] = useState({ status: [] });
  const [applyFilters, setApplyFilters] = useState(false);
  const [researchPerforms, setResearchPerforms] = useLocalStorage("keepGeneralCaseFilter", false); // ou persistGeneralCaseFilter
  const [researchPerformed, setResearchPerformed] = useState(false);
  const [caseRemoved, setCaseRemoved] = useState(false);
  const [loadedCases, setLoadedCases] = useState(false);
  // const [viewTable, setViewTable] = useState("default");
  const [additionalField, setAdditionalField] = useState([]);
  const [headersAdditional, setHeadersAdditional] = useState("");
  // const [exhibVariable, setExhibVariable] = useState([]);
  const [additionalFieldsParent, setAdditionalFieldsParent] = useState([]);

  //REDUX - Selectors
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.userData);
  const filtersActive = useSelector((state) => state.filters.cases);
  const filtersAdvanceds = useSelector((state) => state.filters.advancedsCases);
  // const filtersContacts = useSelector((state) => state.filters.contacts);
  const exhibColumn = useSelector((state) => state.tableFilters.exhibColumn);
  // const initialSort = useSelector((state) => state.tableFilters.initialSort);
  const permissionProfile = useSelector((state) => state.auth.permissionProfile.casos);
  // const interaction = useSelector((state) => state.interaction);
  const fieldsAdditional = useSelector((state) => state.filters.fieldsAdditional);
  const principalAdditionalField = useSelector((state) => state.filters.principalAdditionalField);
  const exhibColumnVariable = useSelector((state) => state.tableFilters.exhibColumnVariable);
  const filteredCaseData = useSelector((state) => state.searchSlice.filteredCases);
  const searchCase = useSelector((state) => state.searchSlice.searchCase);

  // console.log("ANÁLISE", {
  //   searchCase: searchCase,
  //   filteredCaseData: filteredCaseData,
  //   researchPerforms: researchPerforms,
  // });

  //FUNCTIONS
  let totalFilters =
    filtersAdvanceds.reasons.length +
    filtersAdvanceds.subReasons.length +
    filtersAdvanceds.prioritys.length +
    filtersAdvanceds.clients.length +
    filtersAdvanceds.requesteds.length +
    filtersAdvanceds.companys.length +
    filtersAdvanceds.agents.length +
    filtersAdvanceds.channels.length +
    filtersAdvanceds.escalate.length +
    filtersAdvanceds.ticketPrimary +
    filtersAdvanceds.ticketSecondary +
    filtersAdvanceds.ticketLated +
    filtersAdvanceds.ticketExceededsla +
    fieldsAdditional.length +
    filtersAdvanceds.organizations.length +
    filtersAdvanceds.userGroups.length;

  const getFilteredCasesFromLocalStorage = () => {
    const savedFilteredCases = localStorage.getItem(
      "casesFilteredBySearchField"
    );
    return savedFilteredCases ? JSON.parse(savedFilteredCases) : [];
  };

  const performCaseResearch = () => {
    const searchTerm = searchCase.toLowerCase();

    const filteredCases = casesData.filter(
      (value) =>
        (value.codigo_ticket != null &&
          value.codigo_ticket.toLowerCase().includes(searchTerm)) ||
        (value.nome_contato != null &&
          value.nome_contato.toLowerCase().includes(searchTerm)) ||
        (value.assunto != null &&
          value.assunto.toLowerCase().includes(searchTerm)) ||
        (value.descricao_solicitacao != null &&
          value.descricao_solicitacao.toLowerCase().includes(searchTerm)) ||
        (value.nome_solicitante != null &&
          value.nome_solicitante.toLowerCase().includes(searchTerm)) ||
        (value.nome_canal != null &&
          value.nome_canal.toLowerCase().includes(searchTerm)) ||
        (value.descricao_ticket_status != null &&
          value.descricao_ticket_status.toLowerCase().includes(searchTerm)) ||
        (value.descricao_escalonamento != null &&
          value.descricao_escalonamento.toLowerCase().includes(searchTerm)) ||
        (value.descricao_criticidade != null &&
          value.descricao_criticidade.toLowerCase().includes(searchTerm)) ||
        (value.ultimo_usuario != null &&
          value.ultimo_usuario.toLowerCase().includes(searchTerm)) ||
        (value.desc_submotivo != null &&
          value.desc_submotivo.toLowerCase().includes(searchTerm)) ||
        (value.descricao_motivo != null &&
          value.descricao_motivo.toLowerCase().includes(searchTerm)) ||
        (value.descricao_status != null &&
          value.descricao_status.toLowerCase().includes(searchTerm)) ||
        (value.observacao != null &&
          value.observacao.toLowerCase().includes(searchTerm)) ||
        (value.cnpj != null && value.cnpj.toLowerCase().includes(searchTerm)) ||
        (value.codigo_empresa != null &&
          value.codigo_empresa.toLowerCase().includes(searchTerm)) ||
        (value.codigo_externo != null &&
          value.codigo_externo.toLowerCase().includes(searchTerm))
    );

    dispatch(setFilteredCaseData(filteredCases));
    setResearchPerforms(true);
    setResearchPerformed(true);

    if (filteredCases.length > 0) {
      localStorage.setItem(
        "casesFilteredBySearchField",
        JSON.stringify(filteredCases)
      );
    } else {
      setResearchPerforms(false);
      localStorage.removeItem("casesFilteredBySearchField");
    }
  };

  const handleSearchCases = (event) => {
    const newValue = event;

    dispatch(setSearchCase(newValue));

    localStorage.setItem("keepValueInSearchField#Cases", newValue);

    if (event === "") {
      setResearchPerforms(false);
      dispatch(setFilteredCaseData([]));

      localStorage.removeItem("casesFilteredBySearchField");
    }
  };

  const cleanFilters = () => {
    dispatch(setFilter({ to: "advancedsCases", type: "active", value: false }));
    dispatch(setFilter({ to: "advancedsCases", type: "prioritys", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "reasons", value: [] }));
    dispatch(
      setFilter({ to: "advancedsCases", type: "subReasons", value: [] })
    );
    dispatch(setFilter({ to: "advancedsCases", type: "clients", value: [] }));
    dispatch(
      setFilter({ to: "advancedsCases", type: "requesteds", value: [] })
    );
    dispatch(setFilter({ to: "advancedsCases", type: "agents", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "companys", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "channels", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "escalate", value: [] }));
    dispatch(
      setFilter({ to: "advancedsCases", type: "ticketPrimary", value: false })
    );
    dispatch(
      setFilter({ to: "advancedsCases", type: "ticketSecondary", value: false })
    );
    dispatch(
      setFilter({ to: "advancedsCases", type: "ticketLated", value: false })
    );
    dispatch(setStorageFilter({ to: "fieldsAdditional", value: [] }));
  };

  const removeFilters = (type, idRemoved) => {
    let elements = [];
    switch (type) {
      case "priority":
        elements = arrayRemove(filtersAdvanceds.prioritys, idRemoved);
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "prioritys",
            value: elements,
          })
        );
        break;
      case "reason":
        elements = arrayRemove(filtersAdvanceds.reasons, idRemoved);
        dispatch(
          setFilter({ to: "advancedsCases", type: "reasons", value: elements })
        );
        break;
      case "subReasons":
        elements = arrayRemove(filtersAdvanceds.subReasons, idRemoved);
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "subReasons",
            value: elements,
          })
        );
        break;
      case "client":
        console.log("RROOE");
        elements = arrayRemove(filtersAdvanceds.clients, idRemoved);
        dispatch(
          setFilter({ to: "advancedsCases", type: "clients", value: elements })
        );
        break;
      case "agent":
        elements = arrayRemoveAgent(filtersAdvanceds.agents, idRemoved);
        dispatch(
          setFilter({ to: "advancedsCases", type: "agents", value: elements })
        );
        break;
      case "requested":
        elements = arrayRemove(filtersAdvanceds.requesteds, idRemoved);
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "requesteds",
            value: elements,
          })
        );
        break;
      case "company":
        elements = arrayRemove(filtersAdvanceds.companys, idRemoved);
        dispatch(
          setFilter({ to: "advancedsCases", type: "companys", value: elements })
        );
        break;
      case "channel":
        elements = arrayRemove(filtersAdvanceds.channels, idRemoved);
        dispatch(
          setFilter({ to: "advancedsCases", type: "channels", value: elements })
        );
        break;
      case "escalate":
        elements = arrayRemove(filtersAdvanceds.escalate, idRemoved);
        dispatch(
          setFilter({ to: "advancedsCases", type: "escalate", value: elements })
        );
        break;
      case "ticketPrimary":
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "ticketPrimary",
            value: false,
          })
        );
        break;
      case "ticketSecondary":
        dispatch(
          setFilter({
            to: "advancedsCases",
            type: "ticketSecondary",
            value: false,
          })
        );
        break;
      case "ticketLated":
        dispatch(
          setFilter({ to: "advancedsCases", type: "ticketLated", value: false })
        );
        break;

      default:
        break;
    }
  };

  function customSorting(rowA, rowB, columnId) {
    const customRowA = rowA.original[columnId].config.customOrder;
    const customRowB = rowB.original[columnId].config.customOrder;

    if (customRowA > customRowB) return -1;
    if (customRowB > customRowA) return 1;
    return 0;
  }

  function dateSorting(rowA, rowB, columnId) {
    const dateRowA = rowA.original[columnId].data.getTime();
    const dateRowB = rowB.original[columnId].data.getTime();

    if (dateRowA > dateRowB) return -1;
    if (dateRowB > dateRowA) return 1;
    return 0;
  }

  function caseSorting(rowA, rowB, columnId) {
    const caseRowA = parseInt(rowA.original[columnId].data.props.children);
    const caseRowB = parseInt(rowB.original[columnId].data.props.children);

    if (caseRowA > caseRowB) return -1;
    if (caseRowB > caseRowA) return 1;
    return 0;
  }

  function defaultSorting(rowA, rowB, columnId) {
    const objectRowA = rowA.original[columnId].data;
    const objectRowB = rowB.original[columnId].data;

    if (objectRowA > objectRowB) return -1;
    if (objectRowB > objectRowA) return 1;
    return 0;
  }

  function statusFilter(rows, id, filterValue) {
    if (filterValue.length === 0) {
      return rows;
    }

    return rows.filter((row) =>
      filterValue.some(
        (filter) => filter.id === row.original[id].config.filterValue
      )
    );
  }

  async function getListStatus() {
    setPageStatus("requesting");

    const response = await APICase.getListStatus(token);

    if (response.status === 200) {
      const data = response.data;

      setStatusList(data);

      let updatedData = [];
      data.forEach((datum) => {
        const updatedDatum = {
          id: datum.id_ticket_status,
          label: datum.descricao_status,
        };

        updatedData.push(updatedDatum);
      });

      setFiltersList({
        ...filtersList,
        status: updatedData,
      });
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function updateCases(automaticLoad, researchPerforms, searchCase) {
    if (!automaticLoad) {
      setPageStatus("requesting");
    }

    setLoadedCases(true);

    const response = await APICase.getCases(
      token,
      filtersActive.title,
      filtersActive.dateStart && formatDate(filtersActive.dateStart),
      filtersActive.dateEnd && formatDate(filtersActive.dateEnd),
      filtersAdvanceds,
      additionalField,
      fieldsAdditional,
      JSON.parse(userData.organizationsView),
      userData.idgrupo,
      userData.userId
    );

    if (response.status === 200) {
      const updatedData = response.data.map((item) => {
        if (item.codigo_empresa === "undefined") {
          item.codigo_empresa = "";
        }

        if (item.codigo_externo === "null") {
          item.codigo_externo = null;
        }

        if (item["campos_adicionais"]) {
          item["campos_adicionais"].forEach((field) => {
            if (field.nomecampoadd === "Prioridade") {
              field.valorcampoadd = parseInt(field.valorcampoadd);
            }
          });
        }

        return item;
      });

      dispatch(setFilteredCaseData(filteredCaseData));
      setCasesData(updatedData);

      if (researchPerforms) {
        localStorage.setItem(
          "casesFilteredBySearchField",
          JSON.stringify(filteredCaseData)
        );
      } else if (searchCase !== "") {
        dispatch(setFilteredCaseData(filteredCaseData));
      } else {
        dispatch(setFilteredCaseData([]));
        localStorage.removeItem("casesFilteredBySearchField");
      }
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }

    setLoadedCases(false);
  }

  async function getAdditionalFieldsParent() {
    const response = await APIAdditionalFields.getAdditionalFieldsParent(token);

    if (response.status === 200) {
      setAdditionalFieldsParent(response.data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function deleteCase(idTicket) {
    const response = await APICase.deleteCase(idTicket, token);

    if (response.status === 200) {
      Toast("Caso Deletado!", "Caso deletado com sucesso.", "success");
      setCaseRemoved(true);
      updateCases();
    } else {
      Toast(
        "Erro ao excluir o caso!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  function getListAdditionalFields() {
    setPageStatus("requesting");

    if (principalAdditionalField.length > 0) {
      setAdditionalField(principalAdditionalField);

      handleAdditionalHeaders(principalAdditionalField);

      // setReasonsTypesList(dataUpdated);
    }
  }

  function handleAdditionalHeaders(data) {
    let valores = [];
    const arrayOptions = [...data];

    if (arrayOptions.length > 0) {
      arrayOptions.forEach((datum) => {
        const valor = {
          Header: datum.desc_campo_adicional,
          accessor: datum.desc_campo_adicional.toLowerCase(),
          id: datum.desc_campo_adicional.toLowerCase(),
          sortType: defaultSorting,
          disableFilters: true,
          // display: exhibColumnVariable.find(
          //   (column) => column.id === datum.id_campo_adicional
          // ).exhib,
          display: chooseDisplayVariable(datum.id_campo_adicional),
          // display: chooseDisplay("Campos Adicionais"),
        };
        valores.push(valor);
      });

      setHeadersAdditional(valores);
    }
  }

  function chooseStatus(idStatus) {
    switch (idStatus) {
      //Novo
      case 6:
        return 6;
      //Concluído
      case 3:
        return 5;
      // Em andamento
      case 1:
        return 4;
      //Escalado
      case 5:
        return 3;
      //Pendente
      case 2:
        return 2;
      //Cancelado
      case 4:
        return 1;

      default:
        return;
    }
  }

  function choosePriority(idCriticidade) {
    switch (idCriticidade) {
      // Alta
      case 1:
        return 3;
      // Média
      case 2:
        return 2;
      // Baixa
      case 3:
        return 1;
      // Critica
      case 4:
        return 4;
    }
  }

  function ProgressBar({
    datePrazo,
    dateFechamento,
    status,
    abertura,
    contato,
  }) {
    let level = 0;
    let data3 = new Date();
    let calcular = true;
    let foraDoPrazo = false;

    if (status === 3) {
      if (isAfter(new Date(dateFechamento), new Date(datePrazo))) {
        level = 100;
        calcular = false;
        foraDoPrazo = true;
      } else {
        data3 = new Date(dateFechamento);
      }
    } else if (isPast(new Date(datePrazo))) {
      level = 100;
      calcular = false;
      foraDoPrazo = true;
    }

    if (calcular) {
      const data1 = new Date(abertura);
      const data2 = new Date(datePrazo);

      const dataDifereTotal = new Date(data2 - data1);
      const dataDifereParc = new Date(data3 - data1);

      const porcentagem = (dataDifereParc / dataDifereTotal) * 100;

      level = porcentagem < 1 ? Math.abs(porcentagem) : Math.floor(porcentagem);
    }

    const someStyle = {
      "--width": `${level.toFixed()}%`,
      "--level": level.toFixed() >= 10 ? `${level.toFixed() / 2}%` : `10%`,
    };

    let color =
      level < 61
        ? "green"
        : level < 91
          ? "yellow"
          : foraDoPrazo
            ? "red"
            : "orange";

    return (
      <div className="manager-container__level-service__progress" datacustom>
        {`${format(new Date(datePrazo), "dd/MM/yyyy HH:mm")}`}
        <div className={`progress-bar ${color}`} style={someStyle}>
          <div className="progress-bar__title">
            <Typography.H5 color="white">{`${level.toFixed()}%`}</Typography.H5>
          </div>
        </div>
      </div>
    );
  }

  function organizationLabel(organizationId) {
    const options = JSON.parse(userData.organizationsView);

    const label = options.find(
      (opt) => parseInt(opt.idorganizacao) === parseInt(organizationId)
    );
    // requestItem.id = datum.idorganizacao;
    //     requestItem.label = datum.nomeorganizacao;

    return `${label ? label.nomeorganizacao : ""}`;
  }

  function contactMoreInfoMenu(contactName, telephones, email, externalCode) {
    return [
      {
        title: (
          <Typography.H5 color="white" weight="500">
            {contactName}
          </Typography.H5>
        ),
      },
      {
        title: (
          <>
            {telephones.length > 0 && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: "7px" }}
              >
                {telephones?.map((telephone, index) => (
                  <div key={index}>{formatPhone(telephone)}</div>
                ))}
              </div>
            )}
          </>
        ),
      },
      {
        title: (
          <Typography.H5 color="white" weight="500">
            {(email !== null || email !== "") && email}
          </Typography.H5>
        ),
      },
      {
        title: (
          <Typography.H5 color="white" weight="500">
            {(externalCode !== null || externalCode !== "") && externalCode}
          </Typography.H5>
        ),
      },
    ];
  }

  function applyMask(value, mask) {
    if (!mask) return value;

    const dateTimePattern = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;

    if (typeof value === "string" && dateTimePattern.test(value)) {
      return value;
    }

    let maskedValue = "";
    let valueIndex = 0;

    const prefixMatch = mask.match(/^[^\d]+/);
    let prefix = "";

    if (prefixMatch) {
      prefix = prefixMatch[0];
      mask = mask.slice(prefix.length);
    }

    maskedValue += prefix;

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === "9") {
        if (value[valueIndex] !== undefined) {
          maskedValue += value[valueIndex];
          valueIndex++;
        } else {
          maskedValue += "0";
        }
      } else {
        maskedValue += mask[i];
      }
    }

    if (valueIndex < value.length) {
      maskedValue += value.slice(valueIndex);
    }

    return maskedValue;
  }

  function refactorCasesList(data) {
    let dataRefactored = [];

    data.forEach((datum) => {
      let clientInfo = {
        data: datum.nome_contato,
        config: {
          textEllipsis: true,
        },
      };

      clientInfo.data = (
        <PopoverMenu
          theme="default"
          trigger="mouseenter"
          cursor="default"
          menuData={contactMoreInfoMenu(
            datum.nome_contato,
            (datum.telefone !== "" || datum.telefone !== null) &&
            datum.telefone.split(","),
            datum.email,
            datum.codigo_externo
          )}
        >
          <div>{datum.nome_contato}</div>
        </PopoverMenu>
      );

      const caseRefactored = {
        case: {
          data: (
            <Link to={`/casos/${datum.id_ticket}`}>{datum.codigo_ticket}</Link>
          ),
          config: {
            className: "cell-blue-text",
          },
        },
        organization: {
          data: organizationLabel(datum.id_organizacao),
          config: {
            textEllipsis: true,
          },
        },
        client: clientInfo,
        // client: {
        //   data: datum.nome_contato,
        //   config: {
        //     textEllipsis: true,
        //   },
        // },

        externalCode: {
          data:
            datum.codigo_externo || datum.codigo_externo?.length > 0
              ? datum.codigo_externo
              : "",
          config: {
            textEllipsis: true,
          },
        },
        companyContact: {
          data: datum.nome_empresa ? datum.nome_empresa : "",
          config: {
            textEllipsis: true,
          },
        },
        company: {
          data: datum.nome_empresa_ticket ? datum.nome_empresa_ticket : "",
          config: {
            textEllipsis: true,
          },
        },
        cnpj: {
          data: datum.cnpj ? maskCpfCnpj(datum.cnpj) : "",
          config: {
            textEllipsis: true,
          },
        },
        companyCode: {
          data: datum.codigo_empresa ?? "",
          config: {
            textEllipsis: true,
          },
        },
        subject: {
          data: (
            <Tippy content={datum.assunto}>
              <div>{datum.assunto}</div>
            </Tippy>
          ),
          config: {
            textEllipsis: true,
            customOrder: datum.assunto,
          },
        },
        request: {
          data: datum.descricao_solicitacao,
          config: {
            textEllipsis: true,
          },
        },
        reason: {
          data: datum.descricao_motivo,
          config: {
            textEllipsis: true,
          },
        },
        subReason: {
          data: datum.desc_submotivo,
          config: {
            textEllipsis: true,
          },
        },
        date: {
          data: (
            <>
              {/* {new Date(datum.prazo_resolucao)} */}
              <ProgressBar
                datePrazo={datum.prazo_resolucao}
                dateFechamento={datum.dt_fechamento}
                status={datum.id_ticket_status}
                abertura={datum.dt_abertura}
              />
            </>
          ),
          config: {
            className: isPast(new Date(datum.prazo_resolucao))
              ? datum.id_ticket_status === 3 || datum.id_ticket_status === 4
                ? isAfter(
                  new Date(datum.dt_fechamento),
                  new Date(datum.prazo_resolucao)
                )
                  ? "cell-red-text"
                  : ""
                : "cell-red-text"
              : "",
            customOrder: datum.prazo_resolucao,
          },
        },
        tasks: {
          data:
            datum.qtde_tarefas > 0
              ? `${datum.qtde_tarefas < 10 ? 0 : ""}${datum.qtde_tarefas} 
                  tarefa${datum.qtde_tarefas === "1" ? "" : "s"}`
              : "",
          config: {
            customOrder: datum.qtde_tarefas,
          },
        },
        agent: {
          data: datum.nome_responsavel ?? "",
          config: {
            className: datum.nome_responsavel ? "" : "cell-red-text",
            textEllipsis: true,
          },
        },
        createBy: {
          data: datum.nome_solicitante,
          config: {
            textEllipsis: true,
          },
        },
        registration: {
          data: datum.matricula_solicitante ?? " ",
          config: {
            textEllipsis: true,
          },
        },
        createIn: {
          data: new Date(datum.dt_abertura),
          config: {},
        },
        channelOrigin: {
          data: (
            <Channel
              media={datum.id_canal}
              nameMedia={datum.nome_canal?.toLowerCase()}
            />
          ),
          config: { customOrder: datum.nome_canal },
        },
        status: {
          data: (
            <Tippy content={datum.descricao_ticket_status}>
              <div style={{ width: 24 }}>
                <StatusSymbol id={datum.id_ticket_status} />
              </div>
            </Tippy>
          ),
          config: {
            customOrder: chooseStatus(datum.id_ticket_status),
            filterValue: datum.id_ticket_status,
          },
        },
        escalatedTo: {
          data: datum.descricao_escalonamento ?? "",
          config: {
            textEllipsis: true,
          },
        },
        lastAtt: {
          data: new Date(datum.dt_ultima_atualizacao),
          config: {},
        },
        caseClosed: {
          data: datum.dt_fechamento ? new Date(datum.dt_fechamento) : "",
          config: {},
        },
        priority: {
          data: (
            <Tippy content={datum.descricao_criticidade}>
              <div className="center-tippy">
                <CriticalityFlag
                  id={datum.id_criticidade}
                  flagOrder={datum.id_criticidade}
                />
              </div>
            </Tippy>
          ),
          config: {
            // #TOFIX: BACK implementar o campo ordem_criticidade
            customOrder: choosePriority(datum.id_criticidade),
          },
        },
        action: {
          data: (
            <PopoverMenu
              placement="bottom-end"
              menuData={renderPopoverMenuContent(
                datum.id_solicitante,
                datum.id_ticket
              )}
            >
              <button className="case-btn-options" type="button">
                <IconMoreVertical />
              </button>
            </PopoverMenu>
          ),
          config: {},
        },
      };

      const isIsoDateString = (value) => {
        const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        return typeof value === "string" && isoDateRegex.test(value);
      };

      const formatAdditionalFields = (fields) => {
        return fields.map((field) => {
          let formattedValue = field.valorcampoadd;

          if (formattedValue === "null") {
            formattedValue = null;
          } else if (isIsoDateString(formattedValue)) {
            formattedValue = format(
              new Date(formattedValue),
              "dd/MM/yyyy HH:mm"
            );
          }

          return { ...field, valorcampoadd: formattedValue };
        });
      };

      const newDatum = { ...datum };

      newDatum.campos_adicionais = formatAdditionalFields(
        newDatum.campos_adicionais
      );

      if (principalAdditionalField.length > 0 && newDatum.campos_adicionais) {
        principalAdditionalField.forEach((field) => {
          const correspondingField = newDatum.campos_adicionais.filter(
            (data) => data.idcampoadd === field.id_campo_adicional
          );

          let fieldValue =
            correspondingField.length > 0
              ? correspondingField[0].valorcampoadd
              : "";

          if (field.mascara && fieldValue?.length > 0) {
            fieldValue = applyMask(fieldValue, field.mascara);
          }

          if (fieldValue === true || fieldValue === "true") {
            fieldValue = "Sim";
          } else if (fieldValue === false || fieldValue === "false") {
            fieldValue = "Não";
          }

          Object.assign(caseRefactored, {
            [field.desc_campo_adicional.toLowerCase()]: {
              data: fieldValue,
              config: {
                textEllipsis: true,
              },
            },
          });
        });
      }

      dataRefactored.push(caseRefactored);
    });

    return dataRefactored;
  }

  function renderPopoverMenuContent(idSolicitante, idTicket) {
    if (
      (userData.permissionId === 1 && idSolicitante !== userData.userId) ||
      !permissionProfile?.exclusao
    ) {
      return [
        {
          title: (
            <>
              <Typography.H5 color="gray-180" weight="500">
                <EditIcon />
                Editar
              </Typography.H5>
            </>
          ),
          route: `/casos/${idTicket}`,
        },
      ];
    } else {
      return [
        {
          title: (
            <>
              <Typography.H5 color="gray-180" weight="500">
                <EditIcon />
                Editar
              </Typography.H5>
            </>
          ),
          route: `/casos/${idTicket}`,
        },
        {
          title: (
            <>
              <Typography.H5 color="red" weight="500">
                <TrashIcon className="popover-icon" />
                Remover
              </Typography.H5>
            </>
          ),
          onClick: () => {
            setTicketId(idTicket);
            setIsOpenModalDeleteCase(true);
          },
        },
      ];
    }
  }

  function chooseDisplay(nameDisplay) {
    const indexFind = exhibColumn.findIndex(
      (column) => column?.title.toLowerCase() === nameDisplay.toLowerCase()
    );

    return exhibColumn[indexFind].exhib;
  }

  function chooseDisplayVariable(idDisplay) {
    const indexFind = exhibColumnVariable.findIndex(
      (column) => column?.id === idDisplay
    );

    return exhibColumnVariable[indexFind].exhib;
  }

  // MEMOS
  const columns = useMemo(() => {
    return [
      {
        Header: "Casos",
        accessor: "case",
        id: "case",
        disableFilters: true,
        sortType: caseSorting,
        display: true,
      },
      {
        Header: "Organização",
        accessor: "organization",
        id: "organization",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Organização"),
      },
      {
        Header: "Contato",
        accessor: "client",
        id: "client",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Contato"),
      },
      {
        Header: "Código Externo",
        accessor: "externalCode",
        id: "externalCode",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Código Externo"),
      },
      {
        Header: "Empresa do Contato",
        accessor: "companyContact",
        id: "companyContact",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Empresa do Contato"),
      },
      {
        Header: "Empresa",
        accessor: "company",
        id: "company",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Empresa"),
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        id: "cnpj",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("CNPJ"),
      },
      {
        Header: "Cód. Empresa",
        accessor: "companyCode",
        id: "companyCode",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Cód. Empresa"),
      },
      {
        Header: "Assunto",
        accessor: "subject",
        id: "subject",
        sortType: customSorting,
        disableFilters: true,
        display: chooseDisplay("Assunto"),
      },
      {
        Header: "Solicitação",
        accessor: "request",
        id: "request",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Solicitação"),
      },
      {
        Header: "Motivo",
        accessor: "reason",
        id: "reason",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Motivo"),
      },
      {
        Header: "Sub-motivo",
        accessor: "subReason",
        id: "subReason",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Sub-motivo"),
      },
      ...headersAdditional,
      {
        Header: "Criado Em",
        accessor: "createIn",
        id: "createIn",
        sortType: dateSorting,
        disableFilters: true,
        display: chooseDisplay("Criado Em"),
      },
      {
        Header: "Status",
        accessor: "status",
        id: "status",
        sortType: customSorting,
        filter: statusFilter,
        display: chooseDisplay("Status"),
      },
      {
        Header: "Prazo",
        accessor: "date",
        id: "date",
        sortType: customSorting,
        disableFilters: true,
        display: chooseDisplay("Prazo"),
      },
      {
        Header: "Concluído em",
        accessor: "caseClosed",
        id: "caseClosed",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Concluído em"),
      },
      {
        Header: "Tarefas",
        accessor: "tasks",
        id: "tasks",
        sortType: customSorting,
        disableFilters: true,
        display: chooseDisplay("Tarefas"),
      },
      {
        Header: "Agente",
        accessor: "agent",
        id: "agent",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Agente"),
      },
      {
        Header: "Criado Por",
        accessor: "createBy",
        id: "createBy",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Criado Por"),
      },
      {
        Header: "Matricula",
        accessor: "registration",
        id: "registration",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Matricula"),
      },
      {
        Header: "Canal de Origem",
        accessor: "channelOrigin",
        id: "channelOrigin",
        sortType: customSorting,
        disableFilters: true,
        display: chooseDisplay("Canal de Origem"),
      },

      {
        Header: "Escalonado Para",
        accessor: "escalatedTo",
        id: "escalatedTo",
        sortType: defaultSorting,
        disableFilters: true,
        display: chooseDisplay("Escalonado Para"),
      },
      {
        Header: "Ultima Atualização",
        accessor: "lastAtt",
        id: "lastAtt",
        sortType: dateSorting,
        disableFilters: true,
        display: chooseDisplay("Ultima Atualização"),
      },

      {
        Header: "Prioridade",
        accessor: "priority",
        id: "priority",
        sortType: customSorting,
        disableFilters: true,
        display: chooseDisplay("Prioridade"),
      },
      {
        Header: "Ação",
        accessor: "action",
        id: "action",
        disableSortBy: true,
        disableFilters: true,
        display: true,
      },
    ];
  }, [chooseDisplay]);

  const cases = useMemo(() => {
    if (casesData) {
      const dataRefactored = refactorCasesList(casesData);
      setPageStatus("awaiting");
      return dataRefactored;
    }
  }, [casesData]);

  const casesFiltered = useMemo(() => {
    if (Array.isArray(filteredCaseData)) {
      const dataRefactored = refactorCasesList(filteredCaseData);
      setPageStatus("awaiting");
      return dataRefactored;
    }
  }, [filteredCaseData]);

  function handleExportTable(includeObservacao, includeComments) {
    const excelTable = [];
    const headerAdjust = columns.filter((column) => column.id !== "action");
    const excelHeader = headerAdjust.map((column) => column.Header);
    const headerDisplay = headerAdjust.map((column) => column.display);
    const headerConfigs = [];

    if (includeObservacao) {
      excelHeader.push("Descrição");
    }

    if (includeComments) {
      excelHeader.push("Comentários")
    }

    casesData.forEach((datum) => {
      let organizationName = organizationLabel(datum.id_organizacao);
      let objAdditionalFields = {};

      // let additional_fields = additionalField.map((field) => {
      //   const values = datum.campos_adicionais.filter(
      //     (data) => data.idcampoadd === field.id_campo_adicional
      //   );
      //   let obj = {
      //     [field.desc_campo_adicional.toLowerCase()]:
      //       values.length > 0 ? values[0].valorcampoadd : "",
      //   };
      //   Object.assign(objAdditionalFields, obj);
      // });

      principalAdditionalField.forEach((field) => {
        const correspondingField = datum.campos_adicionais.filter(
          (data) => data.idcampoadd === field.id_campo_adicional
        );

        let fieldValue =
          correspondingField.length > 0 ? correspondingField[0].valorcampoadd : "";

        if (field.mascara && fieldValue?.length > 0) {
          fieldValue = applyMask(fieldValue, field.mascara);
        }

        if (fieldValue === true || fieldValue === "true") {
          fieldValue = "Sim";
        } else if (fieldValue === false || fieldValue === "false") {
          fieldValue = "Não";
        }

        objAdditionalFields[field.desc_campo_adicional.toLowerCase()] =
          fieldValue;
      });

      const caseRefactored = {
        case: datum.codigo_ticket.toString(),
        organization: organizationName,
        client: datum.nome_contato,
        externalCode:
          datum.codigo_externo || datum.codigo_externo?.length > 0
            ? datum.codigo_externo
            : "",
        companyContact: datum.nome_empresa ? datum.nome_empresa : "",
        company: datum.nome_empresa_ticket ? datum.nome_empresa_ticket : "",
        cnpj: datum.cnpj ? maskCpfCnpj(datum.cnpj) : "",
        companyCode: datum.codigo_empresa ?? "",
        subject: datum.assunto,
        request: datum.descricao_solicitacao,
        reason: datum.descricao_motivo,
        subReason: datum.desc_submotivo,
        ...objAdditionalFields,
        createIn: new Date(datum.dt_abertura).toLocaleString(),
        status: datum.descricao_ticket_status,
        date: new Date(datum.prazo_resolucao).toLocaleString(),
        caseClosed: datum.dt_fechamento
          ? new Date(datum.dt_fechamento).toLocaleString()
          : "",
        tasks:
          datum.qtde_tarefas > 0
            ? `${datum.qtde_tarefas < 10 ? 0 : ""}
                ${datum.qtde_tarefas} 
                tarefa${datum.qtde_tarefas === "1" ? "" : "s"}`
            : "",
        agent: datum.nome_responsavel ?? "",
        createBy: datum.nome_solicitante,
        registration: datum.matricula_solicitante,
        channelOrigin: datum.nome_canal,
        escalatedTo: datum.descricao_escalonamento ?? "",
        lastAtt: new Date(datum.dt_ultima_atualizacao).toLocaleString(),
        priority: datum.descricao_criticidade,
      };

      if (includeObservacao) {
        caseRefactored.observacao = datum.observacao;
      }

      const comments = JSON.parse(datum.comentarios)

      if (includeComments && comments.length > 0) {
        const formattedComments = comments.map(
          (comment) => `${comment.data} - ${comment.usuario} - ${comment.msg}`
        ).join(" | ");

        caseRefactored.comentarios = formattedComments;
      }

      excelTable.push(caseRefactored);
    });

    let worksheet;

    if (filtersActive.status.length > 0) {
      const statusActive = filtersActive.status.map((status) => status.label);
      const excelFiltered = excelTable.filter((column) =>
        statusActive.includes(column.status)
      );

      worksheet = XLSX.utils.json_to_sheet(excelFiltered);
    } else {
      worksheet = XLSX.utils.json_to_sheet(excelTable);
    }

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Casos");
    XLSX.utils.sheet_add_aoa(worksheet, [excelHeader]);

    // const max_width = excelTable.reduce(
    //   (w, r) => Math.max(w, r.client.length),
    //   10
    // );

    const max_width = excelTable.reduce((max, row) => {
      const clientLength = row.client ? row.client.length : "";
      if (clientLength > max) {
        return clientLength;
      }
      return max;
    }, 10);

    while (headerConfigs.length < headerDisplay.length) {
      headerDisplay.forEach((value) => {
        const objectHeader = {
          wch: max_width,
          hidden: !value,
        };

        headerConfigs.push(objectHeader);
      });
    }

    worksheet["!cols"] = headerConfigs;
    XLSX.writeFile(workbook, "ListagemDeCasos.xlsx");
  }

  function arrayRemove(arr, value) {
    return arr.filter(function (elem) {
      return elem.id != value;
    });
  }

  function arrayRemoveAgent(arr, value) {
    return arr.filter(function (elem) {
      return elem.id_usuario != value;
    });
  }

  function renderScreens() {
    if (typeView === "kanban") {
      return (
        <section className="section-cases-kanban">
          <Kanban
            token={token}
            dataKanban={dataKanban}
            setDataKanban={setDataKanban}
            updateCases={updateCases}
            setIsOpenModalAddCase={setIsOpenModalAddCase}
          />
        </section>
      );
    } else if (typeView === "splitView") {
      return (
        <section className="section-cases-splitView">
          <SplitView
            casesData={casesData}
            setTypeView={setTypeView}
            filteredCaseData={filteredCaseData}
            researchPerforms={researchPerforms}
            setCasesData={setCasesData}
            cleanFilters={cleanFilters}
            chooseStatus={chooseStatus}
            contactMoreInfoMenu={contactMoreInfoMenu}
          />
        </section>
      );
    } else {
      return (
        <section className="section-cases-list">
          <Table
            className="table-cases"
            data={searchCase && researchPerforms ? casesFiltered : cases}
            columns={columns}
            fixedHeader
            // fixedFirstColumn
            filtersActive={filtersActive}
            emptyState={emptyState}
            setEmptyState={setEmptyState}
            initialState={{
              sortBy: initialSorted ?? [
                {
                  id: "date",
                  desc: false,
                },
              ],
            }}
            keepLinePerPage
            canSaveInitialState
          />
        </section>
      );
    }
  }

  function renderCasesList() {
    switch (pageStatus) {
      case "requesting":
        return <Loading.Paperplane className="loading-cases-list" />;

      case "awaiting":
        if (emptyState) {
          return (
            <div className="empty-state-cases-list">
              <ImgEmptyState className="empty-state-cases-list__img" />
              <Typography.H3 color="gray-400">
                Não encontramos nenhum resultado.
              </Typography.H3>
              <Typography.H3 color="gray-400">
                Que tal reformular sua busca?
              </Typography.H3>
            </div>
          );
        } else {
          return <>{renderScreens()}</>;
        }
      default:
        break;
    }
  }

  const getAllInfos = useCallback(() => {
    getListStatus();
    getAdditionalFieldsParent();
    getListAdditionalFields();
    updateCases(researchPerforms, searchCase);
  }, [researchPerforms, searchCase]);

  // const getAllInfos = async () => {
  //   getListStatus();
  //   getListAdditionalFields();
  //   await updateCases();
  // };

  //USE EFFECTS
  useEffect(() => {
    if (!effectRan.current) {
      getAllInfos();
      setApplyFilters(true);
      // console.log(
      //   "COMEÇOU AQUI 1.1",
      //   new Date().toLocaleTimeString(),
      //   effectRan.current
      // );
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    if (
      additionalFieldsParent.length > 0 &&
      principalAdditionalField.length > 0
    ) {
      const updatedFields = principalAdditionalField.map((field) => {
        const matchingField = additionalFieldsParent.find(
          (fieldParent) =>
            fieldParent.id_campo_adicional === field.id_campo_adicional
        );

        if (matchingField) {
          return {
            ...field,
            id_tipo_campo_adicional: matchingField.id_tipo_campo_adicional,
            label_campo_adicional: matchingField.label_campo_adicional,
          };
        }

        return field;
      });

      dispatch(
        setStorageFilter({
          to: "principalAdditionalField",
          value: updatedFields,
        })
      );
    }
  }, [additionalFieldsParent, dispatch]);

  useEffect(() => {
    const comInterval = setInterval(async () => {
      updateCases(true);
      // console.log("COMEÇOU AQUI 2");
    }, connection_platform.time_atualization);
    return () => clearInterval(comInterval);
  }, [casesData]);

  useEffect(() => {
    if (pageStatus === "awaiting" && applyFilters) {
      // console.log("COMEÇOU AQUI 3");
      updateCases();
    }
  }, [filtersActive.dateStart, filtersActive.dateEnd]);

  useEffect(() => {
    if (pageStatus === "awaiting" && applyFilters) {
      updateCases();
      // console.log("COMEÇOU AQUI 4");
    }
  }, [filtersAdvanceds, fieldsAdditional]);

  useEffect(() => {
    if (
      pageStatus === "awaiting" &&
      filtersActive.title?.length === 0 &&
      applyFilters
    ) {
      updateCases();
      // console.log("COMEÇOU AQUI 5");
    }
  }, [filtersActive.title]);

  useEffect(() => {
    if (loadedCases) setPageStatus("requesting");
    else setPageStatus("awaiting");
  }, [loadedCases]);

  useEffect(() => {
    if (
      filtersAdvanceds.ticketLated === false &&
      filtersAdvanceds.ticketPrimary === false &&
      filtersAdvanceds.ticketSecondary === false &&
      filtersAdvanceds.ticketExceededsla === false &&
      filtersAdvanceds.prioritys.length === 0 &&
      filtersAdvanceds.reasons.length === 0 &&
      filtersAdvanceds.subReasons.length === 0 &&
      filtersAdvanceds.clients.length === 0 &&
      filtersAdvanceds.requesteds.length === 0 &&
      filtersAdvanceds.agents.length === 0 &&
      filtersAdvanceds.companys.length === 0 &&
      filtersAdvanceds.channels.length === 0 &&
      filtersAdvanceds.escalate.length === 0 &&
      filtersAdvanceds.organizations.length === 0 &&
      filtersAdvanceds.userGroups.length === 0 &&
      fieldsAdditional.length === 0
    ) {
      dispatch(
        setFilter({ to: "advancedsCases", type: "active", value: false })
      );
    }
  }, [filtersAdvanceds, fieldsAdditional]);

  useEffect(() => {
    if (pageStatus === "awaiting") setEmptyState(false);
  }, [filtersActive.status, filtersActive.dateStart, filtersActive.dateEnd]);

  useEffect(() => {
    if (casesData.length > 0) setEmptyState(false);
  }, [casesData]);

  useEffect(() => {
    if (searchCase === "" && filteredCaseData.length < 1) setEmptyState(false);
  }, [searchCase, filteredCaseData]);

  useEffect(() => {
    if (researchPerformed) {
      if (searchCase !== "" && filteredCaseData.length === 0) {
        setEmptyState(true);
      } else {
        setEmptyState(false);
      }
    }
  }, [filteredCaseData, researchPerformed]);

  useEffect(() => {
    window.localStorage.setItem("casesFilter", JSON.stringify(filtersActive));
  }, [filtersActive]);

  // useEffect(() => {
  //   window.localStorage.setItem(
  //     "advancedsFilter",
  //     JSON.stringify(filtersAdvanceds)
  //   );
  // }, [filtersAdvanceds]);

  useEffect(() => {
    const savedSearchQuery = localStorage.getItem(
      "keepValueInSearchField#Cases"
    );
    const savedFilteredCases = getFilteredCasesFromLocalStorage();

    dispatch(setFilteredCaseData(savedFilteredCases));

    if (savedSearchQuery) setSearchCase(savedSearchQuery);
  }, []);

  useEffect(() => {
    statusList?.forEach((item) => {
      item = Object.assign(item, { icon_ticket_status: null });

      if (item.id_ticket_status === 4) {
        item.icon_ticket_status = <IconStatusCanceled />;
      } else if (item.id_ticket_status === 3) {
        item.icon_ticket_status = <IconStatusComplete />;
      } else if (item.id_ticket_status === 1) {
        item.icon_ticket_status = <IconStatusInProgress />;
      } else if (item.id_ticket_status === 5) {
        item.icon_ticket_status = <IconStatusScaled />;
      } else if (item.id_ticket_status === 6) {
        item.icon_ticket_status = <IconStatusNew />;
      } else {
        item.icon_ticket_status = <IconStatusPending />;
      }
    });
  }, [statusList]);

  useEffect(() => {
    let kanbanList = [];

    statusList?.forEach((item) => {
      const data = casesData?.filter(
        (c) => c.id_ticket_status === item.id_ticket_status
      );

      let filteredData = [];

      const originalArray = [...filteredCaseData];

      if (originalArray.length > 0) {
        filteredData = originalArray
          .filter((v) => v.id_ticket_status === item.id_ticket_status)
          .map((v) => ({
            ...v,
            id_ticket: v.id_ticket.toString(),
          }));
      }

      if (data) {
        kanbanList.push({
          id_coluna: item.id_ticket_status.toString(),
          descricao_coluna: item.descricao_status,
          icon: item.icon_ticket_status,
          cases: researchPerforms ? filteredData : data,
        });
      }
    });

    setDataKanban(kanbanList);
  }, [casesData, statusList, researchPerforms, filteredCaseData]);

  useEffect(() => {
    handleAdditionalHeaders(principalAdditionalField);
  }, [exhibColumnVariable]);

  useEffect(() => {
    const isAnyArrayNotEmpty =
      filtersAdvanceds.reasons.length > 0 ||
      filtersAdvanceds.subReasons.length > 0 ||
      filtersAdvanceds.prioritys.length > 0 ||
      filtersAdvanceds.clients.length > 0 ||
      filtersAdvanceds.requesteds.length > 0 ||
      filtersAdvanceds.companys.length > 0 ||
      filtersAdvanceds.agents.length > 0 ||
      filtersAdvanceds.channels.length > 0 ||
      filtersAdvanceds.escalate.length > 0 ||
      filtersAdvanceds.organizations.length > 0 ||
      filtersAdvanceds.userGroups.length > 0;

    const areFalseValuesConvertedToTrue =
      filtersAdvanceds.ticketPrimary === true ||
      filtersAdvanceds.ticketSecondary === true ||
      filtersAdvanceds.ticketLated === true ||
      filtersAdvanceds.ticketExceededsla === true;

    if (searchCase !== "") {
      if (isAnyArrayNotEmpty || areFalseValuesConvertedToTrue) {
        performCaseResearch();
      }
    }

    if (caseRemoved) {
      performCaseResearch();
    }
  }, [filtersAdvanceds, caseRemoved, casesData]);

  useEffect(() => {
    if (!searchCase || searchCase.trim() === "") {
      setResearchPerforms(false);
      dispatch(setSearchCase(""));
      dispatch(setFilteredCaseData([]));
      localStorage.setItem("keepValueInSearchField#Cases", "");
      localStorage.removeItem("casesFilteredBySearchField");
    }
  }, []);

  return (
    <>
      <main
        className={`page-cases-list ${location.pathname === "/casos" ? "page-modify" : ""}`}
        ref={refPage}
      >
        <PageTitle
          className="header-cases-list"
          title="Casos"
          leftContent={
            <>
              {/* <Input
                onKeyPress={(event) =>
                  (event.code === "Enter" || event.code === "NumpadEnter") &&
                  updateCases()
                }
                onChange={(event) =>
                  dispatch(
                    setFilter({ to: "cases", type: "title", value: event })
                  )
                }
                value={filtersActive.title}
                className="header-cases-list__input-search"
                type="text"
                placeholder="Pesquisar por número do caso"
                rightIcon={
                  <button onClick={() => updateCases()}>
                    <IconSearch />
                  </button>
                }
              /> */}
              <Input
                onKeyPress={(event) =>
                  (event.code === "Enter" || event.code === "NumpadEnter") &&
                  performCaseResearch()
                }
                onChange={handleSearchCases}
                value={searchCase}
                className="header-cases-list__input-search"
                type="text"
                placeholder="Pesquisar"
                rightIcon={
                  <button onClick={searchCase ? performCaseResearch : null}>
                    <IconSearch />
                  </button>
                }
              />
              {/* #POS-MVP: Implementar filtros avançados */}

              <div className="header-cases-list__date-range-container">
                <DatePicker.DateRangeInput
                  selectRangeType="start"
                  value={filtersActive.dateStart}
                  setDate={(event) => {
                    dispatch(
                      setFilter({
                        to: "cases",
                        type: "dateStart",
                        value: event,
                      })
                    );
                  }}
                  label="Data Inicial"
                  endDate={filtersActive.dateEnd}
                  isClearable={false}
                  casesDefault
                />

                <Typography.H5>até</Typography.H5>

                <DatePicker.DateRangeInput
                  selectRangeType="end"
                  value={filtersActive.dateEnd}
                  setDate={(event) => {
                    dispatch(
                      setFilter({
                        to: "cases",
                        type: "dateEnd",
                        value: event,
                      })
                    );
                  }}
                  label="Data Final"
                  startDate={filtersActive.dateStart}
                  isClearable={true}
                />

                {/* {refPage?.current?.clientWidth > 800 && (
                      <div className="header-cases-list__views-buttons">
                        <Button
                          className={`btn-views-tabels ${viewTable === "default" && "selected"
                            }`}
                          onClick={() => setViewTable("default")}
                          icon={
                            viewTable === "default" ? (
                              <ColumnTabelIconActive />
                            ) : (
                              <ColumnTabelIcon />
                            )
                          }
                        />
                        <Button
                          className={`btn-views-tabels ${viewTable === "kanban" && "selected"
                            }`}
                          onClick={() => setViewTable("kanban")}
                          icon={
                            viewTable === "kanban" ? (
                              <ColumnKanbanIconActive />
                            ) : (
                              <ColumnKanbanIcon />
                            )
                          }
                        />
                        <Button
                          className={`btn-views-tabels ${viewTable === "splitview" && "selected"
                            }`}
                          onClick={() => setViewTable("splitview")}
                          icon={
                            viewTable === "splitview" ? (
                              <ColumnSplitViewIconActive />
                            ) : (
                              <ColumnSplitViewIcon />
                            )
                          }
                        />
                      </div>
                    )} */}
              </div>

              {/*<div className="header-cases-list__types-views">
                <Tippy content="Lista">
                  <div
                    className={
                      typeView === "normal"
                        ? "preview-option normal active"
                        : "preview-option"
                    }
                    onClick={() => setTypeView("normal")}
                  >
                    <IconHamburguer />
                  </div>
                </Tippy>

                <Tippy content="Kanban">
                  <div
                    className={
                      typeView === "kanban"
                        ? "preview-option kanban active"
                        : "preview-option"
                    }
                    onClick={() => setTypeView("kanban")}
                  >
                    <IconKanban />
                  </div>
                </Tippy>

                <Tippy content="Splitview">
                  <div
                    className={
                      typeView === "splitView"
                        ? "preview-option-splitView active"
                        : "preview-option"
                    }
                    onClick={() => setTypeView("splitView")}
                  >
                    <IconSplitView />
                  </div>
                </Tippy>
              </div>*/}

              <Button
                className={"btn-more-filters"}
                type="fourthiary"
                icon={<IconFilter />}
                border
                onClick={() => setIsDrawerFiltersOpen(true)}
              >
                <div className="btn-more-filters__content">
                  <Typography.H5 weight="bold" color={"blue-selected-4"}>
                    Mais filtros
                  </Typography.H5>

                  {totalFilters > 0 && (
                    <div className="total-filters">
                      <span>{totalFilters}</span>
                    </div>
                  )}
                </div>
              </Button>

              <Button
                className="btn-create-case"
                iconFillType="stroke"
                icon={refPage?.current?.clientWidth < 800 && <IconAddCircle />}
                onClick={() => {
                  setIsOpenModalAddCase(true);
                }}
              >
                <Typography.H5 weight="bold" color={"white"}>
                  + Adicionar caso
                </Typography.H5>
              </Button>
              <ThreeDotsOptions
                options={
                  userData.permissionId !== 1
                    ? [
                      <Tippy content="Lista">
                        <button
                          className="header-cases-list__three-dots__list-view"
                          onClick={() => setTypeView("normal")}
                        >
                          <div
                            className={
                              typeView === "normal"
                                ? "preview-option normal active"
                                : "preview-option"
                            }
                          >
                            <IconHamburguer />
                          </div>
                          <span
                            className={
                              typeView === "normal"
                                ? "header-cases-list__three-dots__list-view__text active"
                                : "header-cases-list__three-dots__list-view__text"
                            }
                          >
                            Visualizar em lista
                          </span>
                        </button>
                      </Tippy>,
                      <Tippy content="Kanban">
                        <button
                          className="header-cases-list__three-dots__list-view"
                          onClick={() => setTypeView("kanban")}
                        >
                          <div
                            className={
                              typeView === "kanban"
                                ? "preview-option kanban active"
                                : "preview-option"
                            }
                          >
                            <IconKanban />
                          </div>
                          <span
                            className={
                              typeView === "kanban"
                                ? "header-cases-list__three-dots__list-view__text active"
                                : "header-cases-list__three-dots__list-view__text"
                            }
                          >
                            Visualizar em kanban
                          </span>
                        </button>
                      </Tippy>,
                      <Tippy content="Splitview">
                        <button
                          className="header-cases-list__three-dots__list-view"
                          onClick={() => setTypeView("splitView")}
                        >
                          <div
                            className={
                              typeView === "splitView"
                                ? "preview-option-splitView active"
                                : "preview-option"
                            }
                          >
                            <IconSplitView />
                          </div>
                          <span
                            className={
                              typeView === "splitView"
                                ? "header-cases-list__three-dots__list-view__text active"
                                : "header-cases-list__three-dots__list-view__text"
                            }
                          >
                            Visualizar em split view
                          </span>
                        </button>
                      </Tippy>,
                      <button
                        className="header-cases-list__three-dots__add-cases"
                        onClick={() => {
                          history.push("/adicionar-casos-em-massa");
                        }}
                      >
                        <IconImportCases />
                        <span className="header-cases-list__three-dots__add-cases__text">
                          Adicionar casos em massa
                        </span>
                      </button>,
                    ]
                    : [
                      <Tippy content="Lista">
                        <button
                          className="header-cases-list__three-dots__list-view"
                          onClick={() => setTypeView("normal")}
                        >
                          <div
                            className={
                              typeView === "normal"
                                ? "preview-option normal active"
                                : "preview-option"
                            }
                          >
                            <IconHamburguer />
                          </div>
                          <span
                            className={
                              typeView === "normal"
                                ? "header-cases-list__three-dots__list-view__text active"
                                : "header-cases-list__three-dots__list-view__text"
                            }
                          >
                            Visualizar em lista
                          </span>
                        </button>
                      </Tippy>,
                      <Tippy content="Kanban">
                        <button
                          className="header-cases-list__three-dots__list-view"
                          onClick={() => setTypeView("kanban")}
                        >
                          <div
                            className={
                              typeView === "kanban"
                                ? "preview-option kanban active"
                                : "preview-option"
                            }
                          >
                            <IconKanban />
                          </div>
                          <span
                            className={
                              typeView === "kanban"
                                ? "header-cases-list__three-dots__list-view__text active"
                                : "header-cases-list__three-dots__list-view__text"
                            }
                          >
                            Visualizar em kanban
                          </span>
                        </button>
                      </Tippy>,
                      <Tippy content="Splitview">
                        <button
                          className="header-cases-list__three-dots__list-view"
                          onClick={() => setTypeView("splitView")}
                        >
                          <div
                            className={
                              typeView === "splitView"
                                ? "preview-option-splitView active"
                                : "preview-option"
                            }
                          >
                            <IconSplitView />
                          </div>
                          <span
                            className={
                              typeView === "splitView"
                                ? "header-cases-list__three-dots__list-view__text active"
                                : "header-cases-list__three-dots__list-view__text"
                            }
                          >
                            Visualizar em split view
                          </span>
                        </button>
                      </Tippy>,
                    ]
                }
              />
            </>
          }
        />

        <div className="cases-list-separator"></div>

        <section className="container-sections-cases">
          {typeView === "kanban" || typeView === "splitView" ? null : (
            <div className="section-cases-filters">
              <FilterList
                className="section-cases-filters-status"
                label="Filtrar por status:"
                filtersList={filtersList.status}
                filtersActive={filtersActive.status}
                setFiltersActive={(event) =>
                  dispatch(
                    setFilter({ to: "cases", type: "status", value: event })
                  )
                }
                allButtonCustomLabel="Todos"
              />

              <div className="section-cases-filters-actions">
                <Tippy content="Exportar para Excel">
                  <div
                    className="excel-table"
                    onClick={() => setIsOpenModalExportCase(true)}
                  >
                    <ExcelDownloadIcon />
                  </div>
                </Tippy>

                <PopoverColumnsFilter
                  className={"filters-columns"}
                  placement="bottom-end"
                  menuData={exhibColumn}
                  setChangesActive={(event) =>
                    dispatch(
                      setTableFilter({
                        value: event,
                        localStorageKey: "columnExhib",
                        localStorageValue: event,
                      })
                    )
                  }
                  menuVariableData={exhibColumnVariable}
                  setChangesActiveVariable={(event) => {
                    dispatch(
                      setVariableTableFilter({
                        value: event,
                        localStorageKey: "exhibVariable",
                        localStorageValue: event,
                      })
                    );
                  }}
                >
                  <div className="section-cases-filters-columns">
                    <Button
                      className={"btn-filters-columns"}
                      icon={<ColumnFilterIcon />}
                    >
                      <Typography.H5 weight={"bold"} color="blue-selected-4">
                        Colunas
                      </Typography.H5>
                    </Button>
                  </div>
                </PopoverColumnsFilter>
              </div>
            </div>
          )}

          {/* ###### FILTROS VISÍVEIS EM TELA ###### */}
          {/* <div className={`container-sections-cases-filters ${typeView === "splitView" ? "padding" : ""}`}>
            {filtersAdvanceds.ticketLated && (
              <FilterTag
                label={"Casos Atrasados"}
                type={"ticketLated"}
                onClose={() => removeFilters("ticketLated", -1)}
              />
            )}
            {filtersAdvanceds.ticketPrimary && (
              <FilterTag
                label={"Casos Primários"}
                type={"ticketPrimary"}
                onClose={() => removeFilters("ticketPrimary", -1)}
              />
            )}
            {filtersAdvanceds.ticketSecondary && (
              <FilterTag
                label={"Casos Secundários"}
                type={"ticketSecondary"}
                onClose={() => removeFilters("ticketSecondary", -1)}
              />
            )}
            {filtersAdvanceds.clients.map((option) => {
              return (
                <FilterTag
                  label={option.name}
                  client={option.id}
                  type={"clients"}
                  onClose={() => removeFilters("client", option.id)}
                />
              );
            })}
            {filtersAdvanceds.companys.map((option) => {
              return (
                <FilterTag
                  label={option.name}
                  company={option.id}
                  type={"companys"}
                  onClose={() => removeFilters("company", option.id)}
                />
              );
            })}
            {filtersAdvanceds.requesteds.map((option) => {
              return (
                <FilterTag
                  label={option.name}
                  requested={option.id}
                  type={"requesteds"}
                  onClose={() => removeFilters("requested", option.id)}
                />
              );
            })}
            {filtersAdvanceds.reasons.map((option) => {
              return (
                <FilterTag
                  label={option.name}
                  reason={option.id}
                  type={"reasons"}
                  onClose={() => removeFilters("reason", option.id)}
                />
              );
            })}
            {filtersAdvanceds.agents.map((option) => {
              return (
                <FilterTag
                  label={option.nome_usuario}
                  agent={option}
                  type={"agents"}
                  onClose={() => removeFilters("agent", option.id_usuario)}
                />
              );
            })}
            {filtersAdvanceds.prioritys.map((option) => {
              return (
                <FilterTag
                  label={option.label}
                  priority={option.id}
                  type={"priority"}
                  onClose={() => removeFilters("priority", option.id)}
                />
              );
            })}
            {filtersAdvanceds.channels.map((option) => {
              return (
                <FilterTag
                  label={option.label}
                  channel={option.id}
                  type={"channels"}
                  onClose={() => removeFilters("channel", option.id)}
                />
              );
            })}
            {filtersAdvanceds.escalate.map((option) => {
              return (
                <FilterTag
                  label={option.label}
                  type={"escalate"}
                  onClose={() => removeFilters("escalate", option.id)}
                />
              );
            })}
            {filtersAdvanceds.active && (
              <div
                className="container-sections-cases-filters-clean"
                onClick={() => cleanFilters()}
              >
                <div
                  className="container-sections-cases-filters-clean-box"
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <CleanIcon className="clean-icon" />
                  <div>
                    <Typography.H5 weight="400" color="red-2">
                      Limpar Filtros
                    </Typography.H5>
                  </div>
                </div>
              </div>
            )}
          </div> */}
          {renderCasesList()}
        </section>
      </main>

      <Drawer.AdvancedFilters
        isOpen={isDrawerFiltersOpen}
        onClose={() => setIsDrawerFiltersOpen(!isDrawerFiltersOpen)}
        screen={"Casos"}
        dataAdditionalFields={additionalField}
        principalAdditionalField={principalAdditionalField}
      />

      <Modal.CreateCase
        dataKanban={dataKanban}
        isOpen={isOpenModalAddCase}
        onClose={(event) => {
          setIsOpenModalAddCase(event);
        }}
        onCreate={() => {
          updateCases();
        }}
      />

      <Modal.GenericDeleteModal
        title="Excluir caso"
        isOpen={isOpenModalDeleteCase}
        onClose={(event) => {
          setIsOpenModalDeleteCase(event);
        }}
        onDeleteAction={async () => {
          await deleteCase(ticketId);
        }}
      />

      <Modal.ExportCases
        isOpen={isOpenModalExportCase}
        onClose={(event) => setIsOpenModalExportCase(event)}
        onExportTable={handleExportTable}
      />
    </>
  );
}
