//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { format, isDate, isValid, parse, parseISO } from "date-fns";
import { FaQuestionCircle } from "react-icons/fa";

// UTILS
import { Toast } from "../../../../utils/toast";
import renderPhone from "../../../../utils/renderPhoneFormat";

//STYLES
import "./advanced-filters.scss";

// HOOKS
import useQuery from "./../../../../hooks/useQuery";

//COMPONENTS
import { Drawer } from "./../../../../components/feedback";
import {
  SelectSearch,
  SelectSearchTag,
  Select,
  ProfileList,
  SelectTag,
  Input,
  DatePicker,
} from "./../../../../components/data-entry";
import {
  FieldContainer,
  FilterTag,
} from "./../../../../components/data-display";
import {
  DropdownPriority,
  DropdownChannel,
  DropdownEscalate,
} from "./../../../../components/layout";
import { Button, Typography } from "./../../../../components/general";

//SERVICES - api, conectors...
import * as APITask from "../../../../services/api/task";
import * as APICase from "../../../../services/api/case";
import * as APIClient from "../../../../services/api/client";
import * as APICompany from "../../../../services/api/company";
import { connection_platform } from "./../../../../services/api/configConnection";

//GLOBAL STATE - redux, env...
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setStorageFilter } from "../../../../store/filters";

//ASSETS - icons, images...
import { ReactComponent as IconAddUser } from "../../../../assets/icons/AddUser2.svg";
import { ReactComponent as IconCalendar } from "../../../../assets/icons/Calendar.svg";
import { ReactComponent as IconCaso } from "../../../../assets/icons/Caso.svg";
import { ReactComponent as IconGoBack } from "../../../../assets/icons/GoBack.svg";
import { ReactComponent as CleanIcon } from "../../../../assets/icons/Clean.svg";

export default function AdvancedFilters({
  screen,
  dataAdditionalFields = [],
  principalAdditionalField = [],
  ...others
}) {
  //GENERAL
  const token = useSelector((state) => state.auth.token);
  const filtersAdvanceds = useSelector((state) => state.filters.advancedsCases);
  const fieldsAdditional = useSelector((state) => state.filters.fieldsAdditional);
  const filtersContacts = useSelector((state) => state.filters.contacts);
  const user = useSelector((state) => state.auth.userData);
  const query = useQuery();
  // const applyFilter = query.get("filters-allow");
  const applyFilter = useSelector((state) => state.filters.filtersCrm);

  //STATES
  const [profiles, setProfiles] = useState(filtersAdvanceds.agents);
  const [allProfiles, setAllProfiles] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [controlActive, setControlActive] = useState(false);
  const [radioLate, setRadioLate] = useState(filtersAdvanceds.ticketLated);
  const [radioPrimary, setRadioPrimary] = useState(filtersAdvanceds.ticketPrimary);
  const [radioSecondary, setRadioSecondary] = useState(filtersAdvanceds.ticketSecondary);
  const [radioExceededsla, setRadioExceededsla] = useState(filtersAdvanceds.ticketExceededsla);
  const [listRequest, setListRequest] = useState([]);
  const [requestedSelected, setRequestedSelected] = useState(filtersAdvanceds.requesteds);
  const [companySelected, setCompanySelected] = useState(filtersAdvanceds.companys);
  const [reasonSelected, setReasonSelected] = useState(filtersAdvanceds.reasons);
  const [subReasonSelected, setSubReasonSelected] = useState(filtersAdvanceds.subReasons);
  const [clientSelected, setClientSelected] = useState(filtersAdvanceds.clients);
  const [prioritySelected, setPrioritySelected] = useState(filtersAdvanceds.prioritys);
  const [channelSelected, setChannelSelected] = useState(filtersAdvanceds.channels);
  const [escalateSelected, setEscalateSelected] = useState(filtersAdvanceds.escalate);
  const [organizationSelected, setOrganizationSelected] = useState(filtersAdvanceds.organizations);
  const [userGroupsSelected, setUserGroupsSelected] = useState(filtersAdvanceds.userGroups);
  const [listReasons, setListReasons] = useState([]);
  const [listSubReasons, setListSubReasons] = useState([]);
  const [escalateList, setEscalateList] = useState();
  const [principalFilters, setPrincipalFilters] = useState(true);
  const [fieldsDataAdditional, setFieldsDataAdditional] = useState([]);
  const [variableValues, setVariableValues] = useState([]);
  const [emptyVariableValues, setEmptyVariableValues] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  //REDUX - Selectors
  const dispatch = useDispatch();

  //FUNCTIONS

  const getProfiles = async () => {
    const response = await APITask.listUsers(token);

    const data = response?.data;

    setAllProfiles(data);
  };

  async function fetchUserGroups() {
    const response = await APICase.getUserGroups(token);

    if (response.status === 200) {
      let updateData = [];

      response.data.forEach((userGroup) => {
        let itemRequest = {};

        itemRequest.id = userGroup.idgrupo;
        itemRequest.label = userGroup.desc_grupo;

        updateData.push(itemRequest);
      })

      setUserGroups(updateData);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getChannelList() {
    const response = await APICase.getChannelList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_canal;
        requestItem.label = datum.nome;
        requestItem.ativo = datum.ativo;
        requestItem.dt_exclusao = datum.dt_exclusao;

        dataUpdated.push(requestItem);
      });
      setChannelList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  //Função que busca a lista de Contatos na API e transforma no modelo padrão dos componentes.
  async function getListClients(searchParams, page) {
    const response = await APIClient.all(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const formatTelephones = client.telefone.split(",");
      const formatedTelephones = formatTelephones.map((telephone) =>
        renderPhone(telephone)
      );

      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.nome_empresa,
        caption2: client.codigo_externo && `Cód. Ext: ${client.codigo_externo}`,
        caption3: client.cpf && `CPF: ${client.cpf}`,
        caption4:
          formatTelephones.length > 1
            ? formatedTelephones.join(", ")
            : renderPhone(formatTelephones[0]),
        caption5: client.email,
      };

      updatedData.push(updatedClient);
    });

    return updatedData;
  }

  //Função que busca a lista de Empresas na API e transforma no modelo padrão dos componentes.
  async function getListCompanys(searchParams, page) {
    // const response = await APICompany.all(token, page, searchParams, 50);
    const response = await APICompany.newListAllCompanysUsers(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((company) => {
      const updatedCompany = {
        id: company.id_empresa,
        label: company.nome,
        caption1: company.cnpj,
        caption2: company.nome_fantasia,
      };

      updatedData.push(updatedCompany);
    });

    return updatedData;
  }

  //Função que busca a lista de motivos na API e transforma no modelo padrão dos componentes.
  const getListReasons = async () => {
    const response = await APICase.getReasonsList(token);

    if (response.status === 200) {
      const data = response.data;
      let updatedData = [];

      data.forEach((datum) => {
        let updatedDatum = {};

        updatedDatum.id = datum.id_motivo;
        updatedDatum.label = datum.desc_motivo;

        updatedData.push(updatedDatum);
      });

      setListReasons(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que busca a lista de submotivos na API e transforma no modelo padrão dos componentes.
  const getListSubReasons = async () => {
    const response = await APICase.getSubReasonsList(token);

    if (response.status === 200) {
      const data = response.data;
      let updatedData = [];

      data.forEach((datum) => {
        let updatedDatum = {};

        updatedDatum.id = datum.id_submotivo;
        updatedDatum.label = datum.desc_submotivo;

        updatedData.push(updatedDatum);
      });

      setListSubReasons(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que busca a lista de solicitações na API e transforma no modelo padrão dos componentes.
  const getListRequest = async () => {
    const response = await APICase.getRequestList(token, user.userId);

    if (response.status === 200) {
      const data = response.data;
      const updatedData = [];

      data.forEach((datum) => {
        let updatedDatum = {};

        updatedDatum.id = datum.id_solicitacao;
        updatedDatum.label = datum.descricao_solicitacao;

        updatedData.push(updatedDatum);
      });

      setListRequest(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  const getEscalateList = async () => {
    const response = await APICase.getEscalateList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_escalonamento;
        requestItem.label = datum.descricao;

        dataUpdated.push(requestItem);
      });
      setEscalateList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  // ################## NOVA VERSÃO DA FUNÇÃO handleAdditionalFields ##################
  const handleAdditionalFields = () => {
    if (principalAdditionalField?.length > 0) {
      let dataUpdated = [];

      principalAdditionalField.forEach((datum) => {
        let defaultValue = "";

        if (fieldsAdditional.length > 0) {
          const findValue = fieldsAdditional.find(
            (field) => field.id === datum.id_campo_adicional
          );

          defaultValue = findValue ? findValue.value : "";
        }
        let requestItem = {};

        requestItem.id = datum.id_campo_adicional;
        requestItem.value = defaultValue;

        dataUpdated.push(requestItem);
      });

      setVariableValues(dataUpdated);
    }
  };

  // ################## ANTIGA VERSÃO DA FUNÇÃO handleAdditionalFields ##################
  // const handleAdditionalFields = () => {
  //   if (dataAdditionalFields.length > 0) {
  //     let dataUpdated = [];

  //     dataAdditionalFields.forEach((datum) => {
  //       let defaultValue = "";
  //       if (fieldsAdditional.length > 0) {
  //         const findValue = fieldsAdditional.find(
  //           (field) => field.id === datum.id_campo_adicional
  //         );
  //         defaultValue = findValue ? findValue.value : "";
  //       }
  //       let requestItem = {};

  //       requestItem.id = datum.id_campo_adicional;
  //       requestItem.value = defaultValue;

  //       dataUpdated.push(requestItem);
  //     });

  //     setVariableValues(dataUpdated);
  //   }
  // };

  function getListOrganizations() {
    if (user.organizationsView) {
      const data = JSON.parse(user.organizationsView);
      let dataUpdated = [];

      data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.idorganizacao;
        requestItem.label = datum.nomeorganizacao;

        dataUpdated.push(requestItem);
      });

      setOrganizationsList(dataUpdated);
    }
  }

  const removeOptionRequested = (idRemoved) => {
    let elements = arrayRemove(requestedSelected, idRemoved);
    setRequestedSelected(elements);
  };

  const removeOptionClient = (idRemoved) => {
    let elements = arrayRemove(clientSelected, idRemoved);
    setClientSelected(elements);
  };

  const changeOptionsRequest = (option) => {
    const arrayFound = requestedSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionRequested(arrayFound.id);
    } else {
      setRequestedSelected([
        ...requestedSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsEscalated = (option) => {
    const arrayFound = escalateSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionEscalated(arrayFound.id);
    } else {
      setEscalateSelected([
        ...escalateSelected,
        { id: option.id, label: option.label },
      ]);
    }
  };

  const changeOptionsClient = (option) => {
    const arrayFound = clientSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionClient(arrayFound.id);
    } else {
      setClientSelected([
        ...clientSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsPriority = (option) => {
    const arrayFound = prioritySelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionPriority(arrayFound.id);
    } else {
      setPrioritySelected([
        ...prioritySelected,
        { id: option.id, label: option.label },
      ]);
    }
  };

  const changeOptionsChannel = (option) => {
    const arrayFound = channelSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionChannel(arrayFound.id);
    } else {
      setChannelSelected([
        ...channelSelected,
        { id: option.id, label: option.label },
      ]);
    }
  };

  const changeOptionsReason = (option) => {
    const arrayFound = reasonSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionReason(arrayFound.id);
    } else {
      setReasonSelected([
        ...reasonSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsSubReason = (option) => {
    const arrayFound = subReasonSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionSubReason(arrayFound.id);
    } else {
      setSubReasonSelected([
        ...subReasonSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsCompany = (option) => {
    const arrayFound = companySelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionCompany(arrayFound.id);
    } else {
      setCompanySelected([
        ...companySelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeOptionsOrganization = (option) => {
    const arrayFound = organizationSelected.find(
      (element) => element.id === option.id
    );
    if (arrayFound) {
      removeOptionOrganization(arrayFound.id);
    } else {
      setOrganizationSelected([
        ...organizationSelected,
        { id: option.id, name: option.label },
      ]);
    }
  };

  const changeUserGroupOption = (option) => {
    const foundArray = userGroupsSelected.find(
      (element) => element.id === option.id
    );

    if (foundArray) {
      removeUserGroupOption(foundArray.id)
    } else {
      setUserGroupsSelected([
        ...userGroupsSelected,
        { id: option.id, name: option.label }
      ])
    }
  };

  const removeOptionCompany = (idRemoved) => {
    let elements = arrayRemove(companySelected, idRemoved);
    setCompanySelected(elements);
  };

  const removeOptionReason = (idRemoved) => {
    let elements = arrayRemove(reasonSelected, idRemoved);
    setReasonSelected(elements);
  };

  const removeOptionSubReason = (idRemoved) => {
    let elements = arrayRemove(subReasonSelected, idRemoved);
    setSubReasonSelected(elements);
  };

  const removeOptionPriority = (idRemoved) => {
    let elements = arrayRemove(prioritySelected, idRemoved);
    setPrioritySelected(elements);
  };

  const removeOptionChannel = (idRemoved) => {
    let elements = arrayRemove(channelSelected, idRemoved);
    setChannelSelected(elements);
  };

  const removeOptionEscalated = (idRemoved) => {
    let elements = arrayRemove(escalateSelected, idRemoved);
    setEscalateSelected(elements);
  };

  const removeOptionOrganization = (idRemoved) => {
    let elements = arrayRemove(organizationSelected, idRemoved);
    setOrganizationSelected(elements);
  };

  const removeUserGroupOption = (removedId) => {
    let elements = arrayRemove(userGroupsSelected, removedId);
    setUserGroupsSelected(elements);
  };

  function arrayRemove(arr, value) {
    return arr.filter(function (elem) {
      return elem.id != value;
    });
  }

  const getCasesFilterAdvanceds = () => {
    dispatch(setFilter({ to: "advancedsCases", type: "active", value: true }));
    dispatch(setFilter({ to: "advancedsCases", type: "prioritys", value: prioritySelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "reasons", value: reasonSelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "subReasons", value: subReasonSelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "clients", value: clientSelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "agents", value: profiles }));
    dispatch(setFilter({ to: "advancedsCases", type: "requesteds", value: requestedSelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "companys", value: companySelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "channels", value: channelSelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "organizations", value: organizationSelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "userGroups", value: userGroupsSelected, }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketPrimary", value: radioPrimary, }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketSecondary", value: radioSecondary, }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketLated", value: radioLate }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketExceededsla", value: radioExceededsla, }));
    dispatch(setFilter({ to: "advancedsCases", type: "escalate", value: escalateSelected, }));
    dispatch(setStorageFilter({ to: "fieldsAdditional", value: variableValues.filter((opt) => opt.value), }));

    // dispatch(setFilter({to: "advancedsCases",type: "subject",value: subject}));
    // dispatch(setFilter({to: "advancedsCases",type: "protocol",value: protocol}));

    others.onClose(false);
  };

  const cleanFiltersAdvanceds = () => {
    dispatch(setFilter({ to: "advancedsCases", type: "active", value: false }));
    dispatch(setFilter({ to: "advancedsCases", type: "prioritys", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "reasons", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "subReasons", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "clients", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "requesteds", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "agents", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "companys", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "channels", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "escalate", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "organizations", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "userGroups", value: [] }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketPrimary", value: false }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketSecondary", value: false }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketLated", value: false }));
    dispatch(setFilter({ to: "advancedsCases", type: "ticketExceededsla", value: false, }));
    dispatch(setStorageFilter({ to: "fieldsAdditional", value: [], }));
  };

  const cleanFilters = () => {
    cleanFiltersAdvanceds();
    setRadioPrimary(false);
    setRadioSecondary(false);
    setRadioLate(false);
    setRadioExceededsla(false);
    setProfiles([]);
    setChannelSelected([]);
    setReasonSelected([]);
    setSubReasonSelected([]);
    setPrioritySelected([]);
    setClientSelected([]);
    setCompanySelected([]);
    setRequestedSelected([]);
    setEscalateSelected([]);
    setOrganizationSelected([]);
    setUserGroupsSelected([]);

    let findValues = cloneDeep(variableValues);
    findValues.forEach((datum) => {
      datum.value = "";
    });
    setVariableValues(findValues);
  };

  // ###################### NOVA VERSÃO DA FUNÇÃO handleVariableFields ######################
  const handleVariableFields = (field) => {
    let findValues = cloneDeep(variableValues);

    let actualValue = findValues.find(
      (value) => value.id === field.id_campo_adicional
    );

    const handleDateChange = (date, id) => {
      // if (!date || !(date instanceof Date)) return;

      const dateObject = new Date(date);
      const updatedValues = findValues.map((item) =>
        item.id === id ? { ...item, value: dateObject.toISOString() } : item
      );

      setVariableValues(updatedValues);
    };

    switch (field.id_tipo_campo_adicional) {
      case 1:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="number"
              placeholder="Digite a prioridade"
              value={actualValue.value}
              onChange={(event) => {
                actualValue.value = event;
                setVariableValues(findValues);
              }}
            />
          </FieldContainer>
        );
        break;

      case 2:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.TimeRangeInput
              automaticSet
              valueTime={actualValue.value}
              setValueTime={(date) => {
                handleDateChange(date, field.id_campo_adicional);
              }}
            />
          </FieldContainer>
        );
        break;

      case 3:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.DateRangeInput
              dateInStringFormat
              selectRangeType="start"
              value={actualValue.value}
              setDate={(date) => {
                handleDateChange(date, field.id_campo_adicional);
              }}
              isClearable={false}
            />
          </FieldContainer>
        );
        break;

      case 4:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Select
              includeSearchField
              placeholder="Selecione"
              className="modal-create-case__dropdown"
              value={actualValue.value}
              options={JSON.parse(field.json)}
              onSelect={(event) => {
                actualValue.value = event.id;
                setVariableValues(findValues);
              }}
            />
          </FieldContainer>
        );
        break;

      case 6:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type={field.mascara ? "numberMask" : "text"}
              mask={field.mascara ? field.mascara : ""}
              placeholder="Digite o prazo em horas"
              value={actualValue.value}
              onChange={(event) => {
                actualValue.value = event;
                setVariableValues(findValues);
              }}
            />
          </FieldContainer>
        );
        break;

      case 7:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="email"
              placeholder="Digite o e-mail"
              value={actualValue.value}
              onChange={(event) => {
                actualValue.value = event;
                setVariableValues(findValues);
              }}
            />
          </FieldContainer>
        );
        break;

      case 8:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="monetary"
              placeholder="Digite o valor"
              prefix={field.mascara}
              value={actualValue.value}
              onValueChange={(values, sourceInfo) => {
                const { floatValue } = values;

                actualValue.value = floatValue;
                setVariableValues(findValues);
              }}
            />
          </FieldContainer>
        );
        break;

      default:
        if (field.mascara === "$" || field.mascara === "R$") {
          return (
            <FieldContainer
              key={field.id_campo_adicional}
              title={field.desc_campo_adicional}
              titleSize="small"
              className="field-container-case-subject"
            >
              <Input
                type="monetary"
                placeholder="Digite o valor"
                prefix={field.mascara}
                value={actualValue.value}
                onValueChange={(values, sourceInfo) => {
                  const { floatValue } = values;

                  actualValue.value = floatValue;
                  setVariableValues(findValues);
                }}
              />
            </FieldContainer>
          )
        } else {
          return (
            <FieldContainer
              key={field.id_campo_adicional}
              title={field.desc_campo_adicional}
              titleSize="small"
              className="field-container-case-subject"
            >
              <Input
                type={field.mascara ? "numberMask" : "text"}
                mask={field.mascara ? field.mascara : ""}
                placeholder="Digite a informação aqui"
                value={actualValue.value}
                onChange={(event) => {
                  actualValue.value = event;
                  setVariableValues(findValues);
                }}
                maxLength={field.comprimento === 0 ? true : field.comprimento}
              />
            </FieldContainer>
          )
        }
    }
  };

  // ###################### ANTIGA VERSÃO DA FUNÇÃO handleVariableFields ######################
  // const handleVariableFields = (field) => {
  //   let findValues = cloneDeep(variableValues);
  //   let actualValue = findValues.find(
  //     (value) => value.id === field.id_campo_adicional
  //   );
  //   // let actualValue = {...findValue};

  //   switch (field.tipo_campo_adicional) {
  //     case "text":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           className="field-container-case-subject"
  //         >
  //           <Input
  //             type={field.mascara ? "numberMask" : "text"}
  //             mask={field.mascara ? field.mascara : ""}
  //             placeholder="Digite a informação aqui"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = event;
  //               setVariableValues(findValues);
  //             }}
  //             error={false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //           />
  //         </FieldContainer>
  //       );

  //     case "select":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           className="field-container-case-request-type "
  //           htmlFor={""}
  //         >
  //           <Select
  //             placeholder="Selecione"
  //             className={"modal-create-case__dropdown"}
  //             value={actualValue.value}
  //             options={JSON.parse(field.json)}
  //             onSelect={(event) => {
  //               actualValue.value = event.label;
  //               setVariableValues(findValues);
  //             }}
  //             error={false}
  //           />
  //         </FieldContainer>
  //       );

  //     case "integer":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           className="container-addContact"
  //         >
  //           <Input
  //             className="input-telephone"
  //             type="number"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = Number(event);
  //               setVariableValues(findValues);
  //             }}
  //             error={false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //             placeholder={"Digite o valor aqui"}
  //           />
  //         </FieldContainer>
  //       );

  //     default:
  //       return;
  //       <></>;
  //   }
  // };

  //USE EFFECTS
  useEffect(() => {
    getProfiles();
    getChannelList();
    getListRequest();
    getListReasons();
    getListSubReasons();
    getEscalateList();
    handleAdditionalFields();
    getListOrganizations();
    fetchUserGroups();
  }, []);

  useEffect(() => {
    setRadioPrimary(filtersAdvanceds.ticketPrimary);
    setRadioSecondary(filtersAdvanceds.ticketSecondary);
    setRadioLate(filtersAdvanceds.ticketLated);
    setRadioExceededsla(filtersAdvanceds.ticketExceededsla);
    setProfiles(filtersAdvanceds.agents);
    setChannelSelected(filtersAdvanceds.channels);
    setReasonSelected(filtersAdvanceds.reasons);
    setSubReasonSelected(filtersAdvanceds.subReasons);
    setPrioritySelected(filtersAdvanceds.prioritys);
    setClientSelected(filtersAdvanceds.clients);
    setCompanySelected(filtersAdvanceds.companys);
    setRequestedSelected(filtersAdvanceds.requesteds);
    setEscalateSelected(filtersAdvanceds.escalate);
    setOrganizationSelected(filtersAdvanceds.organizations);
    setUserGroupsSelected(filtersAdvanceds.userGroups);

    if (!applyFilter) {
      window.localStorage.setItem(
        "advancedsFilter",
        JSON.stringify(filtersAdvanceds)
      );

      window.localStorage.setItem(
        "additionalFields",
        JSON.stringify(fieldsAdditional)
      );
    }
  }, [filtersAdvanceds, fieldsAdditional]);

  useEffect(() => {
    if (
      radioLate === false &&
      radioPrimary === false &&
      radioSecondary === false &&
      radioExceededsla === false &&
      prioritySelected.length === 0 &&
      reasonSelected.length === 0 &&
      subReasonSelected.length === 0 &&
      clientSelected.length === 0 &&
      requestedSelected.length === 0 &&
      profiles.length === 0 &&
      companySelected.length === 0 &&
      channelSelected.length === 0 &&
      escalateSelected.length === 0 &&
      organizationSelected.length === 0 &&
      userGroupsSelected.length === 0 &&
      variableValues.filter((opt) => opt.value).length === 0
    ) {
      setControlActive(false);
    } else {
      setControlActive(true);
    }
  }, [
    channelSelected.length,
    clientSelected.length,
    companySelected.length,
    controlActive,
    dispatch,
    escalateSelected.length,
    prioritySelected.length,
    profiles.length,
    radioLate,
    radioPrimary,
    radioSecondary,
    radioExceededsla,
    reasonSelected.length,
    subReasonSelected.length,
    requestedSelected.length,
    variableValues,
    organizationSelected.length,
    userGroupsSelected.length
  ]);

  useEffect(() => {
    handleAdditionalFields();
  }, [principalAdditionalField]);

  // useEffect(() => {
  //   handleAdditionalFields();
  // }, [dataAdditionalFields]);

  useEffect(() => {
    variableValues.forEach((item) => {
      if (item.value === '') {
        item.value = null;
      }
    });
  }, [variableValues]);

  return (
    <Drawer.Main title="Filtros" {...others}>
      <div className="advanced-filters-case-container">
        {dataAdditionalFields.length > 0 && (
          <div className="advanced-filters-case__controls">
            <div
              className={`advanced-filters-case__controls-principal ${principalFilters ? "active" : ""}`}
              onClick={() => setPrincipalFilters(true)}
            >
              <Typography.H4
                weight="700"
                color={principalFilters ? `blue-selected-4` : "gray-180"}
              >
                Filtros principais
              </Typography.H4>
            </div>
            <div
              className={`advanced-filters-case__controls-others ${!principalFilters ? "active" : ""}`}
              onClick={() => setPrincipalFilters(false)}
            >
              <Typography.H4
                weight="700"
                color={!principalFilters ? `blue-selected-4` : "gray-180"}
              >
                Outros filtros
              </Typography.H4>
            </div>
          </div>
        )}
        <div className="advanced-filters-case">
          {principalFilters ? (
            <>
              {!connection_platform.customFilters && (
                <FieldContainer
                  title={"Filtrar por:"}
                  className="advanced-filters-case-inputs"
                  htmlFor={""}
                >
                  <div className="advanced-filters-case-inputs__filter">
                    <input
                      className="inputs__filter-checkbox"
                      id="lated"
                      type="checkbox"
                      checked={radioLate}
                      onClick={() => setRadioLate(!radioLate)}
                    />
                    {/* <IconCalendar /> */}
                    <Typography.H5 weight={400} color="gray-600">
                      <label htmlFor="lated">{screen} Atrasados</label>
                    </Typography.H5>
                  </div>
                  <div className="advanced-filters-case-inputs__filter">
                    <input
                      className="inputs__filter-checkbox"
                      id="exceededsla"
                      type="checkbox"
                      checked={radioExceededsla}
                      onClick={() => setRadioExceededsla(!radioExceededsla)}
                    />
                    {/* <IconCalendar /> */}
                    <Typography.H5 weight={400} color="gray-600">
                      <label htmlFor="lated">
                        {screen} que excederam o SLA
                      </label>
                    </Typography.H5>
                  </div>
                  <div className="advanced-filters-case-inputs__filter">
                    <input
                      className="inputs__filter-checkbox"
                      id="primary"
                      type="checkbox"
                      checked={radioPrimary}
                      onClick={() => setRadioPrimary(!radioPrimary)}
                    />
                    {/* <IconCaso /> */}
                    <Typography.H5 weight={400} color="gray-600">
                      <label htmlFor="primary">{screen} Primários</label>
                    </Typography.H5>
                  </div>
                  <div className="advanced-filters-case-inputs__filter">
                    <input
                      className="inputs__filter-checkbox"
                      id="secondary"
                      type="checkbox"
                      checked={radioSecondary}
                      onClick={() => setRadioSecondary(!radioSecondary)}
                    />
                    {/* <IconGoBack /> */}
                    <Typography.H5 weight={400} color="gray-600">
                      <label htmlFor="secondary">{screen} Secundários</label>
                    </Typography.H5>
                  </div>
                </FieldContainer>
              )}
              <FieldContainer
                title="Contato, CPF ou Código Externo:"
                className="case-client"
                htmlFor=""
              >
                <div className="advanced-filters-case-container-tag">
                  {/* <SelectSearch
                paginated
                width="100%"
                placeholder="Busque pelo nome do contato"
                getOptions={getListClients}
                value={clientSelected}
                onSelect={(client) => {
                  changeOptionsClient(client);
                }}
                onClose={removeOptionClient}
              /> */}
                  <SelectSearchTag
                    paginated
                    width="100%"
                    placeholder="Busque pelo nome do contato"
                    getOptions={getListClients}
                    value={clientSelected}
                    onSelect={(client) => {
                      changeOptionsClient(client);
                    }}
                    onClose={removeOptionClient}
                  />
                </div>
              </FieldContainer>
              <FieldContainer title="Empresa ou CNPJ:">
                <div className="advanced-filters-case-container-tag">
                  {/* <SelectSearch
                paginated
                width="100%"
                placeholder="Buscar"
                getOptions={getListCompanys}
                value={companySelected}
                onSelect={(company) => {
                  changeOptionsCompany(company);
                }}
              /> */}
                  <SelectSearchTag
                    paginated
                    width="100%"
                    placeholder="Buscar"
                    getOptions={getListCompanys}
                    value={companySelected}
                    onSelect={(company) => {
                      changeOptionsCompany(company);
                    }}
                  />
                </div>
              </FieldContainer>
              {!connection_platform.customFilters &&
                organizationsList.length > 0 && (
                  <FieldContainer title="Organização">
                    {/* <Select
                  placeholder="Selecione"
                  className={"modal-create-case__dropdown"}
                  value={caseData.id_organizacao}
                  options={organizationsList}
                  onSelect={(event) => {
                    setCaseData({ ...caseData, id_organizacao: event.id });
                    getListRequestTypes(event.id);
                  }}
                  error={validationWarn ? !caseData.id_organizacao : false}
                  /> */}
                    <SelectTag
                      valueTag={organizationSelected}
                      options={organizationsList}
                      onSelect={(option) => {
                        changeOptionsOrganization(option);
                      }}
                    />
                  </FieldContainer>
                )}
              <FieldContainer title="Tipo de Solicitação:" htmlFor="">
                <div className="advanced-filters-case-container-tag">
                  {/* <Select
                value={requestedSelected}
                options={listRequest}
                onSelect={(option) => {
                  changeOptionsRequest(option);
                }}
                width="100%"
                placeholder="Selecione"
              /> */}
                  <SelectTag
                    valueTag={requestedSelected}
                    options={listRequest}
                    onSelect={(option) => {
                      changeOptionsRequest(option);
                    }}
                  />
                </div>
              </FieldContainer>
              <FieldContainer title="Motivo:" htmlFor="">
                <div className="advanced-filters-case-container-tag">
                  {/* <Select
                value={reasonSelected}
                options={listReasons}
                onSelect={(option) => {
                  changeOptionsReason(option);
                }}
                width="100%"
                placeholder="Selecione"
              /> */}
                  <SelectTag
                    valueTag={reasonSelected}
                    options={listReasons}
                    onSelect={(option) => {
                      changeOptionsReason(option);
                    }}
                  />
                </div>
              </FieldContainer>
              <FieldContainer title="Sub-motivo:" htmlFor="">
                <div className="advanced-filters-case-container-tag">
                  <SelectTag
                    valueTag={subReasonSelected}
                    options={listSubReasons}
                    onSelect={(option) => {
                      changeOptionsSubReason(option);
                    }}
                  />
                </div>
              </FieldContainer>
              {/* <div className="advanced-filters-input">
            <Typography.H5
              className="field-container__title"
              color="gray-600"
              weight="400"
            >
              Assunto
            </Typography.H5>
            <input type="text" id="advanced-input-subject" placeholder="Escreva"/>
          </div> */}
              <div className="advanced-filters-case__divider"></div>
              <FieldContainer title="Agentes:" htmlFor="">
                <div className="advanced-filters-case-agents">
                  <IconAddUser />
                  <ProfileList
                    buttonTooltip
                    profiles={profiles}
                    allProfileList={allProfiles}
                    setProfiles={setProfiles}
                    type="add"
                  />
                </div>
              </FieldContainer>

              <FieldContainer title="Grupos de agentes:">
                <SelectTag
                  valueTag={userGroupsSelected}
                  options={userGroups}
                  onSelect={(option) => {
                    changeUserGroupOption(option);
                  }}
                />
              </FieldContainer>
              <div className="advanced-filters-case__divider"></div>
              <FieldContainer title="Escalonado para:">
                <SelectTag
                  valueTag={escalateSelected}
                  options={escalateList}
                  onSelect={(option) => {
                    changeOptionsEscalated(option);
                  }}
                />
              </FieldContainer>
              {!connection_platform.customFilters && (
                <FieldContainer title="Prioridade:">
                  {/* <DropdownPriority
                  className={"advanced-filters-case__priority"}
                  label
                  onClick={(option) => {
                    changeOptionsPriority(option);
                  }}
                /> */}
                  <SelectTag
                    valueTag={prioritySelected}
                    priority
                    onSelect={(option) => {
                      changeOptionsPriority(option);
                    }}
                  />
                </FieldContainer>
              )}

              <FieldContainer title="Canais Originários:">
                {/* <DropdownChannel
              className={"advanced-filters-case__channel"}
              options={channelList}
              label
              onClick={(option) => {
                changeOptionsChannel(option);
              }}
              screen={"filter-advanceds"}
            /> */}
                <SelectTag
                  valueTag={channelSelected}
                  channel
                  options={channelList}
                  onSelect={(option) => {
                    changeOptionsChannel(option);
                  }}
                />
              </FieldContainer>
            </>
          ) : (
            // dataAdditionalFields.map((field) => handleVariableFields(field))
            principalAdditionalField.map((field) => handleVariableFields(field))
          )}
        </div>
        <div className="advanced-filters-case__buttons">
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              cursor: `${!controlActive ? "default" : "pointer"}`,
            }}
            onClick={controlActive && cleanFilters}
          >
            <CleanIcon
              className={`${!controlActive ? "disabled-icon" : "clean-icon"}`}
            />
            <div>
              <Typography.H5
                weight="400"
                color={`${!controlActive ? "gray-125" : "red-2"}`}
              >
                Limpar Filtros
              </Typography.H5>
            </div>
          </div>
          <Button
            className={"buttons-aplyfilters"}
            onClick={getCasesFilterAdvanceds}
          >
            Aplicar Filtros
          </Button>
        </div>
      </div>
    </Drawer.Main>
  );
}
