import React, { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { Tooltip } from 'react-tippy';

import { Typography } from "../../general";

import "./checkbox.scss";
import 'react-tippy/dist/tippy.css';

export default function Checkbox({
    label,
    labelColor,
    isChecked,
    onChange,
    htmlFor,
    id,
    name,
    icon,
    onBlur,
    tooltipIcon
}) {
    const tooltipTitle =
        <Typography.H5>{tooltipIcon}</Typography.H5>

    const [checked, setChecked] = useState(isChecked)

    const handleCheckboxChange = () => {
        const newChecked = !checked;

        setChecked(newChecked);
        onChange(newChecked);
    }

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked])

    return (
        <label className="checkbox-container" htmlFor={htmlFor}>
            <input
                type="checkbox"
                id={id}
                name={name}
                onBlur={onBlur}
                checked={checked}
                onChange={handleCheckboxChange}
            />

            <span className={`checkmark ${checked ? "checked" : ""}`}>
                {checked && <FiCheck className="check-icon" />}
            </span>

            {label && (
                <Typography.H5 color={labelColor}>
                    {label}
                </Typography.H5>
            )}

            {icon && (
                <>
                    {tooltipIcon ? (
                        <Tooltip
                            html={(tooltipTitle)}
                        >
                            {icon}
                        </Tooltip>
                    ) : (
                        icon
                    )}
                </>
            )}
        </label>
    )
}
