//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import urlIngWeb from "../../../utils/env";
//import axios from 'axios';

//STYLES
import "./attach-type-files.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/ArrowRight.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/Trash.svg";
import { ReactComponent as IconClips } from "../../../assets/icons/Clips.svg";
import { ReactComponent as IconDOC } from "../../../assets/icons/type-files/IconDOC.svg";
import { ReactComponent as IconXLS } from "../../../assets/icons/type-files/IconXLS.svg";
import { ReactComponent as IconPDF } from "../../../assets/icons/type-files/IconPDF.svg";
import { ReactComponent as IconPPT } from "../../../assets/icons/type-files/IconPPT.svg";
import { ReactComponent as IconTXT } from "../../../assets/icons/type-files/IconTXT.svg";
import { ReactComponent as IconZIP } from "../../../assets/icons/type-files/IconZIP.svg";

export default function AttachTypeFiles({
  files,
  setFiles,
  limit = 3,
  type = "insert",
  className,
  download,
  setDeleteFiles,
  deleteFiles,
}) {
  //GENERAL

  //STATES
  const [value, setValue] = useState(0);

  //REDUX - Selectors

  //FUNCTIONS
  const deleteFile = (indexFile, file) => {
    const arrayFiles = [...files];
    const filteredFiles = arrayFiles.filter(
      (file, index) => index !== indexFile + value
    );

    if (value > 0) {
      setValue((value) => value - 1);
    }

    setFiles(filteredFiles);

    const arrayDeleteFiles = deleteFiles;
    if (file.file.id) {
      arrayDeleteFiles.push(file.file.id);
      setDeleteFiles(arrayDeleteFiles);
    }
  };

  const onDownload = (file) => {
    fetch(`${urlIngWeb + file.path_arquivos}`).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = file.name;
        a.click();
      });
    });
  };

  const renderTypeIcons = (extension) => {
    switch (extension) {
      case "doc":
      case "docx":
        return <IconDOC />;

      case "xls":
      case "xlsx":
        return <IconXLS />;

      case "pdf":
        return <IconPDF />;

      case "ppt":
      case "pptx":
        return <IconPPT />;

      case "txt":
        return <IconTXT />;

      case "zip":
        return <IconZIP />;

      default:
        return <IconClips />;
    }
  };

  return (
    <>
      <div className={`${className} ${"attach-typefile"} attach-typefiles`}>
        <div className="carousel-attach-list">
          <Tippy content={files?.name}>
            <div className="carousel-attach-list-file">
              <div
                className={`carousel-attach-list-file__icontype ${files?.extension}`}
              >
                {renderTypeIcons(files?.extension)}
              </div>
              {download ? (
                <button onClick={() => onDownload(files)}>
                  <Typography.H5
                    className="carousel-attach-list-file__text"
                    color="primary-dark"
                  >
                    {files?.name}
                  </Typography.H5>
                </button>
              ) : (
                <Typography.H5
                  className="carousel-attach-list-file__text"
                  color="primary-dark"
                >
                  {files?.name}
                </Typography.H5>
              )}
              {type === "insert" && (
                <IconTrash
                  className="carousel-attach-list-file__icon"
                  onClick={() => deleteFile(files)}
                />
              )}
            </div>
          </Tippy>
        </div>
      </div>
    </>
  );
}
