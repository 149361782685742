//GLOBAL - components from npm
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, Autoplay } from "swiper";

// UTILS
import { Toast } from "../../../utils/toast";

//STYLES
import "./login.scss";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/autoplay/autoplay.scss";

//COMPONENTS
import { Page } from "../../../components/layout";
import { Logo, FieldContainer } from "../../../components/data-display";
import { Input } from "../../../components/data-entry";
import { Typography, Button } from "../../../components/general";
import ChangePassword from "./ChangePassword/ChangePassword";

//SERVICES - api, conectors...
import * as APIAuth from "./../../../services/api/auth";

//GLOBAL STATE - redux, env...
import { useDispatch } from "react-redux";
import { doLogin } from "./../../../store/auth";

//ASSETS - icons, images...
import { ReactComponent as IconEye } from "./../../../assets/icons/eye.svg";
import { ReactComponent as IconUser } from "./../../../assets/icons/IconUser.svg";
import { ReactComponent as SVGSlideshowImg1 } from "./../../../assets/images/slideshow-img1.svg";
import { ReactComponent as SVGSlideshowImg2 } from "./../../../assets/images/slideshow-img2.svg";
// import { ReactComponent as IconPassword } from "./../../../assets/icons/password.svg";
// import { ReactComponent as IconMail } from "./../../../assets/icons/mail.svg";
// import { ReactComponent as IconMarkOficial } from "./../../../assets/icons/oficial_mark.svg";

export default function Login() {
  //GENERAL
  const dispatch = useDispatch();

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [changePassword, setChangePassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [loginData, setLoginData] = useState({
    login: "",
    password: "",
  });
  const [inputsError, setInputsError] = useState({
    login: false,
    password: false,
  });

  //REDUX - Selectors

  //FUNCTIONS
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!loginData.login) {
      Toast(
        "Preencha seu login!",
        "Verifique seus dados e tente novamente.",
        "error"
      );
    } else if (!loginData.password) {
      Toast(
        "Preencha sua senha!",
        "Verifique seus dados e tente novamente.",
        "error"
      );
    } else {
      setPageStatus("requesting");

      const response = await APIAuth.login(loginData);

      if (response.status === 200) {
        dispatch(
          doLogin({
            token: response.data.token,
            userData: response.data.dados[0],
            localStorageKey: "token",
            localStorageValue: response.data.token,
          })
        );
      } else if (response.status === 500) {
        Toast(
          "Dados incorretos!",
          "Verifique seus dados e tente novamente.",
          "error"
        );
        setPageStatus("awaiting");
      } else {
        Toast(
          "Falha, não foi possível fazer login!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
        setPageStatus("awaiting");
      }
    }
  };

  //USE EFFECTS
  useEffect(() => {
    if (changePassword && loginData.login !== "") setUserName(loginData.login);
  }, [changePassword, loginData.login]);

  return (
    <Page.Split
      className="page-login"
      type={"inverted"}
      sectionA={
        <>
          {/* <div className="logo">
            <Logo />
          </div> */}

          {changePassword ? (
            <ChangePassword
              setChangePassword={setChangePassword}
              userName={userName}
            />
          ) : (
            <main className="main">
              <header className="main__header">
                <Typography.H1 color="blue-selected-4" weight="bold">
                  Seja bem-vindo (a)!
                </Typography.H1>
                <Typography.H5 color="gray-180">
                  Entre com a sua conta para acessar nossa plataforma
                </Typography.H5>
              </header>

              <section className="main__content">
                <form className="signin-form" onSubmit={handleFormSubmit}>
                  <div className="field-container-wrapper">
                    <FieldContainer
                      title="Login (Entre com o seu usuário)"
                      required
                    >
                      <Input
                        error={inputsError.login}
                        type="text"
                        id="login"
                        name="login"
                        autoComplete="username"
                        // placeholder="Insira seu endereço de email"
                        placeholder="Insira seu login"
                        // leftIcon={<IconUser />}
                        rightIcon={<IconUser />}
                        value={loginData.login}
                        onChange={(newLogin) => {
                          setLoginData({ ...loginData, login: newLogin });
                          if (inputsError.login) {
                            setInputsError({ ...inputsError, login: false });
                          }
                        }}
                      />
                    </FieldContainer>

                    <FieldContainer title="Senha" required>
                      <Input
                        error={inputsError.password}
                        type="password"
                        id="current-password"
                        autoComplete="current-password"
                        placeholder="Insira sua senha"
                        // leftIcon={<IconPassword />}
                        rightIcon={
                          <button>
                            <IconEye />
                          </button>
                        }
                        value={loginData.password}
                        onChange={(newPassword) => {
                          setLoginData({ ...loginData, password: newPassword });
                          if (inputsError.password) {
                            setInputsError({ ...inputsError, password: false });
                          }
                        }}
                      />
                    </FieldContainer>
                  </div>

                  {pageStatus === "awaiting" ? (
                    <Button htmlType="submit" className="btn-login">
                      Entrar
                    </Button>
                  ) : (
                    <Button className="btn-login btn-login--loading">
                      Carregando...
                    </Button>
                  )}
                </form>

                <button
                  className="msg-create-account__btn"
                  onClick={() => setChangePassword(true)}
                  style={{ pointerEvents: loginData.login ? "auto" : "none" }}
                >
                  <Typography.H4
                    className="msg-create-account"
                    weight="400"
                    color={loginData.login ? "blue-selected-4" : "gray-180"}
                  >
                    Alterar minha senha
                  </Typography.H4>
                </button>

                {/* <button
                  onClick={() => setChangePassword(true)}
                  className="btn-forgot-password"
                >
                  <Typography.H6>Alterar senha</Typography.H6>
                </button> */}

                {/* <Link
                  to="/esqueci-senha"
                  type="button"
                  className="btn-forgot-password"
                >
                  <Typography.H6>Esqueci minha senha</Typography.H6>
                </Link> */}

                {/* <Typography.H5 className="msg-create-account">
                  Não tem uma conta?{" "}
                  <Link to="/cadastre-se" className="msg-create-account__btn">
                    Cadastre-se
                  </Link>
                </Typography.H5> */}
              </section>

              <div className="logo">
                <Typography.H6 color={"gray-180"}>Desenvolvido por:</Typography.H6>
                <Logo type="iconSystems" />
              </div>
            </main>
          )}

          {/* <div className="logo">
            <Typography.H6 color={"gray-180"}>Desenvolvido por:</Typography.H6>
            <Logo type="iconSystems" />
          </div> */}
        </>
      }
      sectionB={
        <>
          {/* <Swiper
            className="swiper-carousel"
            modules={[Pagination, Autoplay]}
            navigation
            autoplay={{
              delay: 8000,
            }}
            pagination={{ clickable: true }}
            speed={900}
          >
            <SwiperSlide>
              <div className="swiper-slide__img">
                <SVGSlideshowImg1 className="slideshow-img1" />
                <SVGSlideshowImg2 className="slideshow-img2" />
              </div>

              <div className="swiper-slide__text">
                <Typography.H3 weight="bold" color="gray-500">
                  Lorem ipsum dolor sit amet, consectetur
                </Typography.H3>
                <Typography.H3 color="gray-200">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
                  sed quisque turpis varius ullamcorper.{" "}
                </Typography.H3>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide__img">
                <SVGSlideshowImg1 className="slideshow-img1" />
                <SVGSlideshowImg2 className="slideshow-img2" />
              </div>

              <div className="swiper-slide__text">
                <Typography.H3 weight="bold" color="gray-500">
                  Lorem ipsum dolor sit amet, consectetur
                </Typography.H3>
                <Typography.H3 color="gray-200">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
                  sed quisque turpis varius ullamcorper.{" "}
                </Typography.H3>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide__img">
                <SVGSlideshowImg1 className="slideshow-img1" />
                <SVGSlideshowImg2 className="slideshow-img2" />
              </div>

              <div className="swiper-slide__text">
                <Typography.H3 weight="bold" color="gray-500">
                  Lorem ipsum dolor sit amet, consectetur
                </Typography.H3>
                <Typography.H3 color="gray-200">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
                  sed quisque turpis varius ullamcorper.{" "}
                </Typography.H3>
              </div>
            </SwiperSlide>
          </Swiper> */}
        </>
      }
    />
  );
}
