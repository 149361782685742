//GLOBAL - components from npm
import React from "react";

//STYLES
import "./criticality-flag.scss";

//COMPONENTS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as FlagPriorityAverage } from "./../../../assets/icons/PriorityAverage2.svg";
import { ReactComponent as FlagPriorityCritical } from "./../../../assets/icons/PriorityCritical2.svg";
import { ReactComponent as FlagPriorityHigh } from "./../../../assets/icons/PriorityHigh2.svg";
import { ReactComponent as FlagPriorityLow } from "./../../../assets/icons/PriorityLow2.svg";

export default function CriticalityFlag({ id = 1, className = "" }) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS
  function renderFlag() {
    switch (id) {
      case 3:
        return <FlagPriorityLow />;
      case 2:
        return <FlagPriorityAverage />;
      case 1:
        return <FlagPriorityHigh />;
      case 4:
        return <FlagPriorityCritical />;

      default:
        break;
    }
  }

  //USE EFFECTS

  return <div className={`criticality-flag ${className}`}>{renderFlag()}</div>;
}
