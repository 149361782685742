//GLOBAL - components from npm
import React, { useState, useRef } from "react";

//STYLES
import "./select-color.scss";

//COMPONENTS
import { Typography } from "../../general";

// CUSTOM HOOKS

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowDownIcon2 } from "../../../assets/icons/arrow-down-2.svg";

export default function SelectColor({
    placeholder = "Selecione uma cor",
    value,
    className,
    disabled,
    changeDownArrowIcon,
    uncheckTheOption,
    colors = [
        { id: 1, label: "Verde", color: "#45c777" },
        { id: 2, label: "Azul", color: "#455cc7" },
        { id: 3, label: "Roxo", color: "#9b3fc8" },
        { id: 4, label: "Amarelo", color: "#ffbb00" },
        { id: 5, label: "Cinza", color: "#75757599" },
        { id: 6, label: "Azul claro", color: "#bdeeff" },
        { id: 7, label: "Azul médio", color: "#5b78ff" },
        { id: 8, label: "Laranja", color: "#ff6b00" },
        { id: 9, label: "Vermelho", color: "#f7404e" },
    ],
    onSelect = () => null,
    width,
    upward,
    error,
    canBeEditable = true,
}) {
    //GENERAL
    const selectRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    // CUSTOM HOOKS

    //REDUX - Selectors

    //FUNCTIONS
    const handleSelect = (color) => {
        onSelect(color);
        setIsOpen(false);
    };

    const renderButtonContent = () => {
        const currentColor = colors.find((color) => color.id === value);
        const selectedLabel = currentColor ? currentColor.label : "";

        return (
            <>
                {currentColor && (
                    <div className="option-content">
                        <div className="color-option" style={{ backgroundColor: currentColor.color }} />

                        <Typography.H5
                            color="gray-400"
                            className={`label ${!canBeEditable ? "max-width" : ""}`}
                        >
                            {selectedLabel.length > 15 ? `${selectedLabel.substring(0, 15)}...` : selectedLabel || placeholder}
                        </Typography.H5>
                        {canBeEditable && changeDownArrowIcon ? <ArrowDownIcon2 /> : <IconArrowDown />}
                    </div>
                )}
                {!currentColor && (
                    <div className="option-content">
                        <Typography.H5
                            color="gray-125"
                            className={`label ${!canBeEditable ? "max-width" : ""}`}
                        >
                            {selectedLabel.length > 15 ? `${selectedLabel.substring(0, 15)}...` : selectedLabel || placeholder}
                        </Typography.H5>
                        {canBeEditable && changeDownArrowIcon ? <ArrowDownIcon2 /> : <IconArrowDown />}
                    </div>
                )}
            </>
        );
    };

    return (
        <div
            className={`select-color ${upward ? "select-color--upwards" : ""} ${className ? className : ""}`}
            style={{ maxWidth: width ? width : "" }}
            ref={selectRef}
        >
            <button
                className={`select-color-btn 
                    ${isOpen ? "active" : ""} 
                    ${error ? "select-color-btn--error" : ""}
                    ${disabled === true ? "select-color-btn--disabled" : ""}
                `}
                onClick={() => {
                    if (disabled === true || !canBeEditable) {
                        return null;
                    } else {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                {renderButtonContent()}
            </button>

            <div className={`select-color-list ${isOpen ? "active" : ""} ${error ? "select-color-list--error" : ""}`}>
                {colors.map((color) => (
                    <button
                        type="button"
                        key={color.id}
                        className={`select-color-list__item ${color.id === value ? "active" : ""}`}
                        onClick={() => handleSelect(color)}
                    >
                        <div className="color-option" style={{ backgroundColor: color.color }} />

                        <Typography.H5
                            color="gray-200"
                            className="label"
                        >
                            {color.label}
                        </Typography.H5>
                    </button>
                ))}
            </div>
        </div>
    );
}
