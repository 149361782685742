//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Modal from "..";
import { Button, Typography } from "../../../general";
import Loading from "../../Loading";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIProfile from "../../../../services/api/profile";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as TrashIcon } from "../../../../assets/icons/Trash-3.svg";

//STYLES

import "./delete-profile.scss";

export default function DeleteProfile({
    isOpen,
    onClose,
    profileId,
    profileName,
    profiles,
    handleTableDataLookup,
    returnToProfileList = false,
    onDelete
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);

    //GENERAL

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [deletedProfile, setDeletedProfile] = useState(false);

    //FUNCTIONS
    function onCloseCustom() {
        onClose();
    };

    async function handleDeleteProfile() {
        setPageStatus("delete-profile");

        const data = {
            id_perfil: profileId,
            nome_perfil: profileName,
        };

        try {
            const response = await APIProfile.deleteProfile(token, data)

            if (response.status === 200) {
                onDelete();
                onCloseCustom();
                Toast(
                    "Perfil excluído!",
                    "Perfil excluído com sucesso.",
                    "success"
                );

                setDeletedProfile(true);

                if (returnToProfileList) {
                    returnToProfileList()
                }
            } else {
                const responseData = await response.json();
                throw new Error(responseData.message || "Falha ao excluir o perfil.");
            }
        } catch (error) {
            console.error("Erro ao excluir o perfil:", error);

            Toast(
                "Não foi possível excluir o perfil!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        } finally {
            setPageStatus("awaiting");
        }
    };

    //USE MEMOS

    //USE EFFECTS 
    useEffect(() => {
        if (deletedProfile) {
            handleTableDataLookup()
        }
    }, [deletedProfile, profiles]);

    return (
        <Modal.Main
            className="modal-delete-profile"
            title="Excluir perfil"
            width="443px"
            height="280px"
            titleColor={"black"}
            isOpen={isOpen}
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            headerContainerStyle={{ alignItems: "center" }}
            icon={
                <div className="modal-delete-profile__icon">
                    <TrashIcon />
                </div>
            }
            footer={
                <div className="container-footer">
                    <Button
                        onClick={() => onCloseCustom()}
                        className="modal-delete-profile__btn-cancel"
                        disabled={pageStatus === "delete-profile"}
                    >
                        <Typography.H4 weight={"bold"} color="blue-selected-4">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-delete-profile__btn-confirm"
                        disabled={pageStatus === "delete-profile"}
                        onClick={handleDeleteProfile}
                    >
                        {pageStatus !== "delete-profile" ? (
                            <Typography.H4 weight={"bold"} color="white">Excluir</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <Typography.H4
                color="gray-180"
                weight="400"
                className="information-text"
            >
                Você tem certeza que deseja excluir? Ao excluir todos os dados, serão excluídos e esta ação não poderá ser desfeita.
            </Typography.H4>
        </Modal.Main>
    )
}
