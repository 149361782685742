//GLOBAL - components from npm
import React, { useEffect, useState } from "react";
import axios from "axios";
// import { format, addDays } from "date-fns";
// import { cloneDeep, intersection, method } from "lodash";
import moment from "moment-business-time";
import {
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js/max";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";

// UTILS
import { Toast } from "./../../../../utils/toast";
import formatDate from "./../../../../utils/formatDate";
import postMessage from "../../../../utils/postMessage";
import renderPhone from "../../../../utils/renderPhoneFormat";
import unicodeToChar from "../../../../utils/formatUnicode";

//STYLES
import "./create-case.scss";

// HOOKS
import useQuery from "./../../../../hooks/useQuery";

//COMPONENTS
import { Modal } from "./../../../feedback";
import { Button, Typography } from "./../../../general";
import { FieldContainer } from "../../../data-display";
import {
  Input,
  Dropzone,
  ClientList,
  Select,
  SelectSearch,
  SelectEnterprises,
  CustomDropdown,
  Checkbox,
} from "../../../data-entry";
import { DatePicker } from "../../../data-entry";
import {
  DropdownStatus,
  DropdownPriority,
  DropdownChannel,
  DropdownEscalate,
} from "../../../layout";
import Loading from "../../Loading";

//SERVICES - api, conectors...
import * as APICase from "./../../../../services/api/case";
import * as APIClient from "./../../../../services/api/client";
import * as APICompany from "./../../../../services/api/company";
import * as APIAdditionalFields from "./../../../../services/api/additionalFields";
import { connection_platform } from "./../../../../services/api/configConnection";

//GLOBAL STATE - redux, env...
import { setFilter } from "../../../../store/filters";

//ASSETS - icons, images...
import { ReactComponent as DangerIcon } from "../../../../assets/icons/Danger.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/ArrowDownTwo.svg";
import { ReactComponent as OrganizationIcon } from "../../../../assets/icons/Organization.svg";

export default function CreateCase({
  isOpen,
  onClose,
  onCreate,
  customEmptyTaskData,
  isDirect,
  dataKanban
}) {
  //REDUX - Selectors
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.token);
  const interaction = useSelector((state) => state.interaction);
  const filtersContacts = useSelector((state) => state.filters.contacts);
  // const filtersAdvanceds = useSelector((state) => state.filters.advancedsCases);
  const filtersEnterprises = useSelector((state) => state.filters.enterprises);
  const principalAdditionalField = useSelector((state) => state.filters.principalAdditionalField);
  const applyFilter = useSelector((state) => state.filters.filtersCrm);

  const [clientReturned, setClientReturned] = useState("");

  //GENERAL
  const caseKanbanColumnId = isOpen || null;
  const query = useQuery();
  const isDealing = query.get("dealing");
  const history = useHistory();
  // const activeFieldRef = useRef(null);

  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const ClearFieldTrigger = {
    ALL: "ALL",
    REASON_AND_SUBREASON: "REASON_AND_SUBREASON",
    SUBREASON: "SUBREASON",
  };

  //STATES
  const [pageStatus, setPageStatus] = useState("awaiting");
  const [clearFieldTrigger, setClearFieldTrigger] = useState("");
  const [validationWarn, setValidationWarn] = useState(false);
  const [validationWarnContact, setValidationWarnContact] = useState(false);
  // const [clearType, setClearType] = useState(false);

  const [clientData, setClientData] = useState();
  // const [clientList, setClientList] = useState();
  const [priorityList, setPriorityList] = useState();
  const [reasonsTypesList, setReasonsTypesList] = useState([]);
  const [subReasonsTypesList, setSubReasonsTypesList] = useState([]);
  const [requestTypesList, setRequestTypesList] = useState([]);
  const [isOpenContactExist, setIsOpenContactExist] = useState(false);
  const [channelTypesList, setChannelTypesList] = useState();
  const [statusList, setStatusList] = useState([]);
  const [emptyCaseData, setEmptyCaseData] = useState({
    assunto: undefined,
    observacao: undefined,
    id_solicitante: userData.userId,
    id_fila: 2,
    id_responsavel: null,
    id_status: 1,
    dt_fechamento: null,

    dt_abertura: undefined,
    dt_ultima_atualizacao: undefined,
    prazo_inicio: undefined,

    prazo_resolucao: null,
    id_criticidade: 3,
    descricao_criticidade: "Baixa",
    id_ticket_master: null,
    id_canal: interaction.channelId === "11" ? 11 : interaction.channelId || 3,
    desc_canal:
      interaction.channelId === "11"
        ? "GoogleGBM"
        : interaction.channelName || "Web",
    // id_canal: interaction.channelId || 3,
    // desc_canal: interaction.channelName || "Web",
    protocolo_externo: interaction.protocol || null,
    possui_interacao: interaction.interaction || false,
    id_contato: interaction.clientId || undefined,
    id_solicitacao: undefined,
    id_motivo: null,
    id_submotivo: null,
    id_ticket_status: null,
    id_organizacao: null,
    pode_finalizar: false,
    id_empresa: null,
    id_escalonamento: null,
  });
  const [prevSelectOrganization, setPrevSelectOrganization] = useState({
    organizationId: null,
  });
  const [newStatus, setNewStatus] = useState([]);
  const [changedStatus, setChangedStatus] = useState(false);
  const [caseData, setCaseData] = useState({ ...emptyCaseData });
  const [editableField, setEditableField] = useState(true);
  const [inputAddContact, setInputAddContact] = useState(false);
  const [blockChannelOrigin, setBlockChannelOrigin] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenModalOrganization, setIsOpenModalOrganization] = useState(false);
  const [emptyCompanyData, setEmptyCompanyData] = useState({
    telephone: "",
    name: "",
    cpf: "",
    email: "",
    externcode: "",
    empresa: {
      id: null,
      label: "",
    },
  });
  const [companyData, setCompanyData] = useState({ ...emptyCompanyData });
  // const [companyValue, setCompanyValue] = useState();
  const [typeNumber, setTypeNumber] = useState();
  // const [openVariable, setOpenVariable] = useState(false);
  const [isOpenCustomFields, setIsOpenCustomFields] = useState(false);
  // const [emptyVariableValues, setEmptyVariableValues] = useState([]);
  const [variableValues, setVariableValues] = useState([]);
  // const [listAdditionalFields, setListAdditionalFields] = useState(principalAdditionalField);
  const [requiredField, setRequiredField] = useState(false);
  const [requiredChild, setRequiredChild] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  // const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  // const [errorEmailParent, setErrorEmailParent] = useState(false);
  // const [errorEmailChild, setErrorEmailChild] = useState(false);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [slaData, setSlaData] = useState([]);
  const [additionalFieldsParent, setAdditionalFieldsParent] = useState([]);
  const [additionalFieldsIntegration, setAdditionalFieldsIntegration] = useState([]);
  const [processedAdditionalFields, setProcessedAdditionalFields] = useState([]);
  const [enterprisesLimit, setEnterprisesLimit] = useState(30);
  const [escalateList, setEscalateList] = useState();
  const [escalateValue, setEscalateValue] = useState([{ id_ticket_escalado: null, desc_ticket_escalado: "" }]);
  const [additionalChildFields, setAdditionalChildFields] = useState({});
  const [currentStatusTexts, setCurrentStatusTexts] = useState({});

  // console.log("createCase####", {
  //   additionalFieldsParent,
  //   additionalFieldsIntegration,
  //   processedAdditionalFields
  // })

  //FUNCTIONS
  const clearRequestTypeFields = (trigger) => {
    const updatedCaseDetail = { ...caseData };

    switch (trigger) {
      case ClearFieldTrigger.ALL:
        updatedCaseDetail.id_solicitacao = undefined;
        updatedCaseDetail.id_motivo = null;
        updatedCaseDetail.id_submotivo = null;
        break;
      case ClearFieldTrigger.REASON_AND_SUBREASON:
        updatedCaseDetail.id_motivo = null;
        updatedCaseDetail.id_submotivo = null;
        break;
      case ClearFieldTrigger.SUBREASON:
        updatedCaseDetail.id_submotivo = null;
        break;
      default:
        break;
    }

    setCaseData(updatedCaseDetail);
  };

  const handleBackspace = (event, field) => {
    if (event.key !== 'Backspace') return;

    const isFocusedOnRequiredField = additionalFieldsIntegration.some(({ campos_adicionais_obrigatorios }) =>
      campos_adicionais_obrigatorios.some(({ idcampos }) => idcampos === field.id_campo_adicional)
    );

    if (!isFocusedOnRequiredField) return;

    setAdditionalFieldsParent(prevFields => {
      const triggerField = additionalFieldsIntegration.find(({ campos_adicionais_obrigatorios }) =>
        campos_adicionais_obrigatorios.some(({ idcampos }) => idcampos === field.id_campo_adicional)
      );

      if (!triggerField) return prevFields;

      const allRequiredFieldsFilled = triggerField.campos_adicionais_obrigatorios.every(({ idcampos }) => {
        const correspondingField = prevFields.find(({ id_campo_adicional }) => id_campo_adicional === idcampos);
        return correspondingField && correspondingField.valor_default;
      });

      if (!allRequiredFieldsFilled) return prevFields;

      const triggerFieldValue =
        prevFields.find(({ id_campo_adicional }) => id_campo_adicional === triggerField.id_campo_gatilho)?.valor_default;

      if (!triggerFieldValue) return prevFields;

      const matchingField = prevFields.find(
        ({ id_campo_adicional }) => id_campo_adicional === triggerField.id_campo_adicional_retorno
      )

      if (matchingField) {
        matchingField.valor_default = null;
      }

      return prevFields.map(prevField => {
        if (prevField.id_campo_adicional === triggerField.id_campo_gatilho) {
          return { ...prevField, valor_default: null };
        }
        return prevField;
      });
    });
  };

  function modifyArray(data) {
    const newArray = data.filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.id === item.id && t.label === item.label)
    );

    return newArray;
  }

  function renderNumberTypeToLinkContact() {
    if (interaction?.destination) {
      if (interaction?.channelId === "1" || interaction?.channelId === "5") {
        return renderPhone(interaction?.destination);
      } else {
        return interaction?.destination;
      }
    }
  }

  function renderValueOfTheContactToAdd() {
    if (interaction?.destination) {
      if (interaction?.channelId === "1" || interaction?.channelId === "5") {
        return renderPhone(interaction?.destination);
      } else return interaction?.destination;
    } else return "";
  }

  function getIdTicketStatus(idTicketStatus) {
    dataKanban?.forEach((s) => {
      if (s.id_coluna === idTicketStatus) {
        setCaseData({
          ...caseData,
          id_ticket_status: Number(caseKanbanColumnId),
        });
      }
    });
  }

  const clearCustomFields = () => {
    const clearedParentFields = additionalFieldsParent.map((field) => {
      return { ...field, valor_default: null };
    });

    setAdditionalFieldsParent(clearedParentFields);
    setAdditionalChildFields({});
  };

  //Função que é ativada ao fechar o modal, resetando os valores dos componentes.
  function onCloseCustom() {
    onClose();
    setNewStatus([]);
    setChangedStatus(false);
    setTimeout(() => {
      if (interaction.destination) {
        const currentData = { ...caseData };
        currentData.id_canal = interaction.channelId || 3;
        currentData.desc_canal = interaction.channelName || "Web";
        currentData.protocolo_externo = interaction.protocol || null;
        currentData.id_contato = interaction.clientId || undefined;
        currentData.possui_interacao = interaction.interaction || false;
        setClientData(
          interaction.clientId
            ? {
              id: interaction.clientId,
              label: unicodeToChar(interaction.clientName),
            }
            : undefined
        );
        setCaseData(currentData);

        if (interaction.destination && !interaction.clientId) {
          getClient(interaction.destination, currentData);
        }
      } else {
        if (organizationsList.length === 1) {
          setCaseData({
            ...emptyCaseData,
            id_organizacao: organizationsList[0].id,
          });
        } else {
          setCaseData({ ...emptyCaseData });
          // setOrganizationsList([]);
        }
        setClientData(null);
        // setOpenVariable(false);
        setIsOpenCustomFields(false);
        setRequestTypesList([]);
        setReasonsTypesList([]);
        setSubReasonsTypesList([]);
        setStatusList([]);
        setEscalateValue([
          { id_ticket_escalado: null, desc_ticket_escalado: "" },
        ]);

        clearCustomFields();

        // variableValues.forEach((datum) => {
        //   datum.value = "";
        // });
        // setVariableValues(variableValues);
      }
      setValidationWarn(false);
      setRequiredField(false);
      setRequiredChild(false);
      setPageStatus("awaiting");
    }, 500);
  }

  function onCloseModalContact() {
    setValidationWarnContact(false);
    setIsOpenContact(false);
    // setCompanyData({ ...emptyCompanyData });
  }

  function isFormValidated() {
    const fieldsRequiredParent = additionalFieldsParent.filter(
      (field) => field?.obrigatorio === true && !field.valor_default
    );

    const childFieldsArray = Object.values(additionalChildFields);

    const fieldsRequiredChild = childFieldsArray.flatMap(childFields =>
      childFields.filter((child) => child?.obrigatorio === true && !child.valor_default)
    );

    if (isOpenContact) {
      if (
        !companyData.name ||
        !companyData.telephone ||
        companyData.telephone.length < 10
      )
        return false;
    } else if (
      !caseData.id_contato ||
      !caseData.id_solicitacao ||
      !caseData.assunto ||
      !caseData.observacao ||
      !caseData.prazo_resolucao ||
      !caseData.id_criticidade ||
      !caseData.id_ticket_status ||
      !caseData.id_organizacao
    ) {
      return false;
    } else if (fieldsRequiredParent.length > 0) {
      setRequiredField(true);
      return false;
    } else if (fieldsRequiredChild.length > 0) {
      setRequiredChild(true);
      return false;
    } else if (connection_platform.companyRequired && !caseData.id_empresa) {
      return false;
    } else if (connection_platform.reasonRequired && !caseData.id_motivo) {
      return false;
    } else if (connection_platform.staggeredRequired && !caseData.id_escalonamento) {
      return false;
    }

    return true;
  }

  //Função que manda mensagem para o ingWeb com as informações do caso e cliente quando cria um caso dentro de uma interação.
  function handleInteractionSave(caseId) {
    postMessage("willLogInteraction", {
      active: true,
      destination: interaction.destination,
      caseId: caseId,
      clientId: caseData.id_contato,
    });
  }

  //Função que manda mensagem para o ingWeb com as informações do caso e
  // cliente quando cria um caso dentro de uma interação,
  // para que a interação seja salva de imediato
  function handleInteractionSaveImmediate(caseId, caseDetails) {
    let objeto = {};
    if (principalAdditionalField.length > 0) {
      variableValues.forEach(async (variable) => {
        const infoData = principalAdditionalField.find(
          (data) => data.id_campo_adicional === variable.id
        );

        if (infoData) {
          objeto = {
            ...objeto,
            [infoData.desc_campo_adicional]: variable.value,
          };
        }
      });
    }

    postMessage("sendMeLogInteraction", {
      active: true,
      destination: interaction.destination,
      caseId: caseId,
      clientId: caseData.id_contato,
      interactionId: interaction.interactionId,
      infos: {
        assunto: caseDetails.assunto,
        observacao: caseDetails.observacao,
        dt_abertura: caseDetails.dt_abertura,
        dt_ultima_atualizacao: caseDetails.dt_ultima_atualizacao,
        prazo_inicio: caseDetails.prazo_inicio,
        prazo_resolucao: caseDetails.prazo_resolucao,
        codigo_ticket: caseDetails.codigo_ticket,
        ...objeto,
      },
    });
  }

  // ################ ANTIGA FUNÇÃO PARA MONTAR HORAS DE TRABALHO ################
  // function mountHourWorkTime(arrayWorktime, day) {
  //   let getDayWorkTime = arrayWorktime.filter(
  //     (time) => time.dia_semana === day
  //   );

  //   let struct = [];
  //   if (getDayWorkTime.length !== 0) {
  //     for (const exemplo of getDayWorkTime) {
  //       const horarios = [exemplo.hora_inicio, exemplo.hora_fim];
  //       struct.push(...horarios);
  //     }
  //     // let struct = [getDayWorkTime[0].hora_inicio, getDayWorkTime[0].hora_fim];
  //     return struct;
  //   }

  //   return null;
  // }

  // ################ NOVA FUNÇÃO PARA MONTAR HORAS DE TRABALHO - RETORNA TODOS COJUNTOS DE TRABALHOS ENCONTRADOS ################
  function mountHourWorkTime(arrayWorktime, day) {
    let getDayWorkTime = arrayWorktime.filter(
      (time) => time.dia_semana === day
    );

    let struct = [];

    for (const exemplo of getDayWorkTime) {
      const horarios = [exemplo.hora_inicio, exemplo.hora_fim];
      struct.push(...horarios);
    }

    return struct.length > 0 ? struct : null;
  }

  function mountHoliday(date) {
    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let dateFormatted = `*-${month >= 10 ? month : "0" + month}-${day}`;

    return dateFormatted;
  }

  const checkRequiredFields = (field, additionalFieldsParent) => {
    const integrationField = additionalFieldsIntegration.find(
      integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
    );

    if (!integrationField) return true;

    const allRequiredFieldsFilled = integrationField.campos_adicionais_obrigatorios.every(integrationField => {
      const correspondingField = additionalFieldsParent.find(
        parentField => parentField.id_campo_adicional === integrationField.idcampos
      );

      return correspondingField &&
        correspondingField.valor_default !== "" &&
        correspondingField.valor_default !== null &&
        correspondingField.valor_default !== undefined;
    });

    return allRequiredFieldsFilled;
  };

  const handleSelectChange = (field, value, onChangeHandler) => {
    const requiredFieldsFilled = checkRequiredFields(field, additionalFieldsParent);

    if (!requiredFieldsFilled) {
      const integrationField = additionalFieldsIntegration.find(
        integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
      );

      if (integrationField) {
        const requiredFields = integrationField.campos_adicionais_obrigatorios.map(integrationField => {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          return correspondingField ? correspondingField.desc_campo_adicional : null;
        }).filter(desc => desc !== null);

        Toast(
          "Não foi possível selecionar esse campo!",
          <p>Para selecionar, preencha os campos obrigatórios: <b>{requiredFields.join(', ')}</b></p>,
          "warn"
        );
      }
      return;
    }

    onChangeHandler(field.id_campo_adicional, value.id);
    getAdditionalChildFields(field.id_campo_adicional, value.label);

    setCurrentStatusTexts(prev => ({
      ...prev,
      [field.id_campo_adicional]: value.label,
    }));

    const triggerField = additionalFieldsIntegration.find(
      integrationField => integrationField.id_campo_gatilho === field.id_campo_adicional
    );

    if (triggerField) {
      setIsOptionSelected(true);
    }
  };

  const renderAdditionalChildFields = (field, onChangeHandler, fieldError) => {
    let renderedFields = [
      <FieldContainer
        key={field.id_campo_adicional}
        title={field.desc_campo_adicional}
        required={field.obrigatorio === true && field.obrigatorio}
        titleSize="small"
        className="custom-field-container"
        labelRightIcon={<FaQuestionCircle />}
        labelRightTooltipContent={field.label_campo_adicional}
      >
        <Select
          includeSearchField
          placeholder="Selecione"
          className="modal-create-case__dropdown"
          value={field.valor_default}
          options={JSON.parse(field.json)}
          onSelect={(value) => handleSelectChange(field, value, onChangeHandler)}
          error={fieldError}
        />
      </FieldContainer>,
    ];

    const childFields = additionalChildFields[field.id_campo_adicional] || [];

    if (childFields?.length > 0) {
      const additionalRenderedFields = childFields?.map((childField, index) => {
        const isChildRequiredAndEmpty = (child) => {
          return child.obrigatorio === true && !child.valor_default;
        };

        const childFieldError = requiredChild && isChildRequiredAndEmpty(childField);

        return (
          <FieldContainer
            key={childField.id_campo_adicional}
            title={childField.desc_campo_adicional}
            required={childField.obrigatorio === true && childField.obrigatorio}
            titleSize="small"
            className={`custom-field-container ${childField.id_tipo_campo_adicional === 10 && "textarea-container"}`}
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={childField.label_campo_adicional}
          >
            {childField.id_tipo_campo_adicional === 1 && (
              <Input
                type="number"
                placeholder="Digite a prioridade"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 2 && (
              <DatePicker.TimeRangeInput
                automaticSet
                className=""
                valueTime={childField.valor_default}
                setValueTime={(date) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: date };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 3 && (
              <DatePicker.DateRangeInput
                selectRangeType="start"
                value={childField.valor_default}
                setDate={(date) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: date };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                isClearable={false}
              />
            )}

            {childField.id_tipo_campo_adicional === 4 && (
              <Select
                includeSearchField
                placeholder="Selecione"
                value={childField.valor_default}
                onSelect={(value) => {
                  const newValue = value;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue.id };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 5 && (
              <Input
                placeholder="Digite as horas"
                type={childField.mascara ? "numberMask" : "text"}
                mask={childField.mascara ? childField.mascara : ""}
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 6 && (
              <Input
                type={childField.mascara ? "numberMask" : "text"}
                mask={childField.mascara ? childField.mascara : ""}
                placeholder="Digite o prazo em horas"
                value={field.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 7 && (
              <Input
                type="email"
                placeholder="Digite o e-mail"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 8 && (
              <Input
                type="monetary"
                placeholder="Digite o valor"
                prefix={childField.mascara ?? "R$"}
                value={childField.valor_default ?? ""}
                onValueChange={(values, sourceInfo) => {
                  const { floatValue } = values;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: floatValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}

            {childField.id_tipo_campo_adicional === 9 && (
              <Checkbox
                isChecked={childField.valor_default}
                onChange={(newValue) => {
                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue ? true : null };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
              />
            )}

            {childField.id_tipo_campo_adicional === 10 && (
              <Input
                placeholder="Digite a informação aqui"
                type="textarea"
                value={childField.valor_default ?? ""}
                onChange={(e) => {
                  const newValue = e;

                  setAdditionalChildFields(prevMap => {
                    const updatedFields = [...(prevMap[field.id_campo_adicional] || [])];
                    updatedFields[index] = { ...updatedFields[index], valor_default: newValue };

                    return {
                      ...prevMap,
                      [field.id_campo_adicional]: updatedFields
                    };
                  });
                }}
                error={childFieldError}
              />
            )}
          </FieldContainer>
        );
      });

      renderedFields = renderedFields.concat(additionalRenderedFields);
    }

    return renderedFields;
  }

  //################### NOVA FUNÇÃO PARA RENDERIZAR CAMPOS PERSONALIZADOS ###################
  const renderCustomFields = (field, onChangeHandler, onBackspaceHandler) => {
    const isFieldRequiredAndEmpty = (field) => {
      return field.obrigatorio === true && !field.valor_default;
    };

    const fieldError = requiredField && isFieldRequiredAndEmpty(field);

    switch (field.id_tipo_campo_adicional) {
      case 1:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="number"
              placeholder="Digite a prioridade"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 2:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.TimeRangeInput
              automaticSet
              className=""
              valueTime={field.valor_default}
              setValueTime={(date) =>
                onChangeHandler(field.id_campo_adicional, date)
              }
              errorInput={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 3:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <DatePicker.DateRangeInput
              selectRangeType="start"
              value={field.valor_default}
              setDate={(date) =>
                onChangeHandler(field.id_campo_adicional, date)
              }
              isClearable={false}
            />
          </FieldContainer>
        );
        break;

      case 4:
        return renderAdditionalChildFields(field, onChangeHandler, fieldError)
        break;

      case 5:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              placeholder="Digite a informação aqui"
              type={field.mascara ? "numberMask" : "text"}
              mask={field.mascara ? field.mascara : ""}
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        )

      case 6:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type={field.mascara ? "numberMask" : "text"}
              mask={field.mascara ? field.mascara : ""}
              placeholder="Digite o prazo em horas"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 7:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="email"
              placeholder="Digite o e-mail"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        );
        break;

      case 8:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              type="monetary"
              placeholder="Digite o valor"
              prefix={field.mascara ?? "R$"}
              value={field.valor_default ?? ""}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              onValueChange={(values, sourceInfo) => {
                const { floatValue } = values;

                onChangeHandler(field.id_campo_adicional, floatValue)
              }}
            />
          </FieldContainer>
        );
        break;

      case 9:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className="custom-field-container"
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Checkbox
              isChecked={field.valor_default}
              onChange={(newValue) => {
                onChangeHandler(
                  field.id_campo_adicional,
                  newValue ? true : null
                );
              }}
            />
          </FieldContainer>
        );
        break;

      case 10:
        return (
          <FieldContainer
            key={field.id_campo_adicional}
            title={field.desc_campo_adicional}
            required={field.obrigatorio === true && field.obrigatorio}
            titleSize="small"
            className={`custom-field-container ${field.id_tipo_campo_adicional === 10 && "textarea-container"}`}
            labelRightIcon={<FaQuestionCircle />}
            labelRightTooltipContent={field.label_campo_adicional}
          >
            <Input
              placeholder="Digite a informação aqui"
              type="textarea"
              value={field.valor_default ?? ""}
              onChange={(e) => onChangeHandler(field.id_campo_adicional, e)}
              onKeyDown={(e) => onBackspaceHandler(e, field)}
              error={fieldError}
            />
          </FieldContainer>
        )
        break;
      default:
        break;
    }
  };

  //################### ANTIGA FUNÇÃO PARA RENDERIZAR CAMPOS PERSONALIZADOS ###################
  // const handleVariableFields = (field) => {
  //   let actualValue = variableValues.find(
  //     (value) => value.id === field.id_campo_adicional
  //   );

  //   switch (field.tipo_campo_adicional) {
  //     case "text":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="field-container-case-subject"
  //         >
  //           <Input
  //             type={field.mascara ? "numberMask" : "text"}
  //             mask={field.mascara ? field.mascara : ""}
  //             placeholder="Digite a informação aqui"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = event;
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={requiredField && !actualValue.value ? true : false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //           />
  //         </FieldContainer>
  //       );

  //     case "select":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="field-container-case-request-type "
  //           htmlFor={""}
  //         >
  //           <Select
  //             placeholder="Selecione"
  //             className={"modal-create-case__dropdown"}
  //             value={actualValue.value}
  //             options={JSON.parse(field.json)}
  //             onSelect={(event) => {
  //               actualValue.value = event.label;
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={requiredField && !actualValue.value ? true : false}
  //           />
  //         </FieldContainer>
  //       );

  //     case "integer":
  //       return (
  //         <FieldContainer
  //           key={field.id_campo_adicional}
  //           title={field.desc_campo_adicional}
  //           titleSize="small"
  //           required={field.obrigatorio ?? false}
  //           className="container-addContact"
  //         >
  //           <Input
  //             className="input-telephone"
  //             type="number"
  //             value={actualValue.value}
  //             onChange={(event) => {
  //               actualValue.value = Number(event);
  //               setVariableValues([...variableValues]);
  //             }}
  //             error={requiredField && !actualValue.value ? true : false}
  //             maxLength={field.comprimento === 0 ? true : field.comprimento}
  //             placeholder={"Digite o valor aqui"}
  //           />
  //         </FieldContainer>
  //       );

  //     default:
  //       return;
  //       <></>;
  //   }
  // };

  async function getStatusRequestType(requestTypeId) {
    const response = await APICase.getStatusRequestType(token, requestTypeId);

    if (response.status === 200) {
      setNewStatus(response.data);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  // ################ ANTIGA FUNÇÃO PARA CALCULAR SLA ################
  // const calcSlaDayAndHoliday = async (idSla, timeToAddicionated, now) => {
  //   const response = await APICase.getWorkTimeAndHolidaySla(token, idSla);

  //   let jsonWorkTime = [];
  //   let jsonHoliday = [];
  //   let dateServer = new Date(now);

  //   if (response.data.worktime.length !== 0) {
  //     let arrayWorktime = response.data.worktime;
  //     let arrayHolidays = response.data.holiday;

  //     jsonWorkTime = {
  //       0: mountHourWorkTime(arrayWorktime, 0),
  //       1: mountHourWorkTime(arrayWorktime, 1),
  //       2: mountHourWorkTime(arrayWorktime, 2),
  //       3: mountHourWorkTime(arrayWorktime, 3),
  //       4: mountHourWorkTime(arrayWorktime, 4),
  //       5: mountHourWorkTime(arrayWorktime, 5),
  //       6: mountHourWorkTime(arrayWorktime, 6),
  //     };

  //     await arrayHolidays.forEach((holiday) => {
  //       jsonHoliday.push(mountHoliday(holiday.data_feriado));
  //     });

  //     let objectWorkTimeAndHoliday = {
  //       workinghours: jsonWorkTime,
  //       holidays: jsonHoliday,
  //     };
  //     moment.locale("pt", objectWorkTimeAndHoliday);
  //     let dateFormatted = moment(dateServer).addWorkingTime(
  //       timeToAddicionated,
  //       "minutes"
  //     );
  //     dateFormatted = new Date(dateFormatted);
  //     return dateFormatted;
  //   }

  //   let dateSla = dateServer;
  //   dateSla.setMinutes(dateSla.getMinutes() + timeToAddicionated);

  //   return dateSla;
  // };

  // ################ NOVA FUNÇÃO PARA CALCULAR SLA ################
  const calcSlaDayAndHoliday = async (idSla, timeToAddicionated, now) => {
    try {
      const response = await APICase.getWorkTimeAndHolidaySla(token, idSla);
      const { worktime, holiday } = response.data;

      if (worktime.length === 0) {
        const dateSla = new Date(now);

        dateSla.setMinutes(dateSla.getMinutes() + timeToAddicionated);

        return dateSla;
      }

      const jsonWorkTime = {};
      for (let i = 0; i < 7; i++) {
        jsonWorkTime[i] = mountHourWorkTime(worktime, i);
      }

      const jsonHoliday = holiday.map((h) => mountHoliday(h.data_feriado));

      const objectWorkTimeAndHoliday = {
        workinghours: jsonWorkTime,
        holidays: jsonHoliday,
      };

      moment.locale("pt", objectWorkTimeAndHoliday);

      const dateFormatted = moment(now).addWorkingTime(
        timeToAddicionated,
        "minutes"
      );

      return new Date(dateFormatted);
    } catch (error) {
      console.error("Erro ao calcular a data SLA:", error);

      Toast("Erro interno!", "Erro ao calcular a data SLA.", "error");
    }
  };

  // const clearTypeReasonAndSubreasonFields = (value) => {
  //   if (value === "all") {
  //     setCaseData((prevState) => ({
  //       ...prevState,
  //       id_solicitacao: undefined,
  //       id_motivo: null,
  //       id_submotivo: null,
  //     }));
  //   } else if (value === "reason and subreason") {
  //     setCaseData((prevState) => ({
  //       ...prevState,
  //       id_motivo: null,
  //       id_submotivo: null,
  //     }));
  //   } else if (value === "subreason") {
  //     setCaseData((prevState) => ({
  //       ...prevState,
  //       id_submotivo: null,
  //     }));
  //   }
  // };

  const handleOrganizationSelect = (event) => {
    setCaseData({ ...caseData, id_organizacao: event.id });
    getListRequestTypes(event.id);
    setClearFieldTrigger(ClearFieldTrigger.ALL);
  };

  const handleTypeSelect = (event) => {
    const requestTypeId = {
      id_tipo_solicitacao: event.id,
    };

    setCaseData({ ...caseData, id_solicitacao: event.id });
    changeTypeRequest(event.id, caseData.id_organizacao);
    getStatusRequestType(requestTypeId);
    setClearFieldTrigger(ClearFieldTrigger.REASON_AND_SUBREASON);
  };

  const handleReasonSelect = (event) => {
    setCaseData({ ...caseData, id_motivo: event.id });
    getSlaRule(event.id, caseData.id_solicitacao, caseData.id_organizacao);
    changeSubTypeRequest(event.id, caseData.id_organizacao);
    setClearFieldTrigger(ClearFieldTrigger.SUBREASON);
  };

  const handleSubreasonSelect = (event) => {
    setCaseData({ ...caseData, id_submotivo: event.id });
    getSlaRuleSub(
      event.id,
      caseData.id_solicitacao,
      caseData.id_motivo,
      caseData.id_organizacao
    );
  };

  const handleEscalateSelect = (option) => {
    setCaseData({ ...caseData, id_escalonamento: option.id, });
  };

  //Função que pega a lista de prioridades e transforma no array padrão que o componente recebe.
  async function getListPriorities() {
    setPageStatus("requesting");
    const response = await APICase.getPriorityList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestedItem = {};

        requestedItem.id = datum.id_criticidade;
        requestedItem.label = datum.descricao;

        dataUpdated.push(requestedItem);
      });

      setPriorityList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
    setPageStatus("awaiting");
  }

  //Função que pega a lista de status e transforma no array padrão que o componente recebe.
  async function getListStatus() {
    setPageStatus("requesting");

    const response = await APICase.getListStatus(token);

    if (response.status === 200) {
      let data = response.data;

      if (caseData.id_organizacao) {
        const orgFilter = organizationsList.filter(
          (org) => org.id === caseData.id_organizacao
        );

        data = data.filter((datum) => {
          if (orgFilter[0].statusFechar) {
            return true;
          } else {
            return datum.termino === orgFilter[0].statusFechar;
          }
        });
      } else {
        data = data.filter((datum) => !datum.termino);
      }

      let updatedData = [];
      data.forEach((datum) => {
        const updatedDatum = {
          id: datum.id_ticket_status,
          label: datum.descricao_status,
          finalizar: datum.termino,
          cor: datum.cor_alerta,
        };

        updatedData.push(updatedDatum);
      });

      setStatusList(updatedData);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  //Função que pega a lista de motivos e transforma no array padrão que o componente recebe.
  async function getListReasonsTypes() {
    setPageStatus("requesting");
    const response = await APICase.getReasonsList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_motivo;
        requestItem.label = datum.desc_motivo;

        dataUpdated.push(requestItem);
      });

      setReasonsTypesList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
    setPageStatus("awaiting");
  }

  //Função que pega a lista de canais e transforma no array padrão que o componente recebe.
  async function getListChannelTypes() {
    setPageStatus("requesting");
    const response = await APICase.getChannelList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_canal;
        requestItem.label = datum.nome;

        dataUpdated.push(requestItem);
      });

      setChannelTypesList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
    setPageStatus("awaiting");
  }

  async function getListOrganizations() {
    if (userData.organizationsCreate) {
      const data = JSON.parse(userData.organizationsCreate);
      let dataUpdated = [];

      data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.idorganizacao;
        requestItem.label = datum.nomeorganizacao;
        requestItem.statusFechar = datum.fecharcaso;

        dataUpdated.push(requestItem);
      });

      setOrganizationsList(dataUpdated);
    }
  }

  //Função que pega a lista de clientes com base na pagina, filtro e transforma no array padrão que o componente recebe.
  async function getListClients(searchParams, page) {
    const response = await APIClient.all(
      token,
      page,
      searchParams,
      50,
      filtersContacts
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((client) => {
      const formatTelephones = client.telefone.split(",");
      const formatedTelephones = formatTelephones.map((telephone) =>
        renderPhone(telephone)
      );

      const updatedClient = {
        id: client.id_contato,
        label: client.nome_contato,
        caption1: client.nome_empresa,
        caption2: client.codigo_externo && `Cód. Ext: ${client.codigo_externo}`,
        caption3: client.cpf && `CPF: ${client.cpf}`,
        caption4:
          formatTelephones.length > 1
            ? formatedTelephones.join(", ")
            : renderPhone(formatTelephones[0]),
        caption5: client.email,
      };

      updatedData.push(updatedClient);
    });

    return updatedData;
  }

  // async function getListEnterprises(searchParams, page) {
  //   // const response = await APICompany.allEnterprises(token);
  //   const response = await APICompany.newListAllCompanysUsers(
  //     token,
  //     page,
  //     searchParams,
  //     50,
  //     filtersEnterprises
  //   );
  //   const data = response.data;
  //   const updatedData = [];
  //   const updatedDataCompanyValue = [];

  //   data.forEach((company) => {
  //     const updatedCompany = {
  //       id: company.id_empresa,
  //       label: company.nome,
  //       caption1: company.site,
  //       caption2: company.email,
  //     };

  //     const updateValue = {
  //       id: company.id_empresa,
  //       label: company.nome,
  //     };

  //     updatedData.push(updatedCompany);
  //     updatedDataCompanyValue.push(updateValue);
  //   });

  //   // return setCompanyList(updatedData);
  //   setCompanyValue(updatedDataCompanyValue);
  //   return updatedData;
  // }

  async function getListEnterprises(page, title, limit) {
    const response = await APICompany.newListAllCompanysUsers(
      token,
      page,
      title,
      limit,
      filtersEnterprises
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((company) => {
      const updatedCompany = {
        id: company.id_empresa,
        label: company.nome,
        caption01: company.site,
        caption02: company.email,
      };

      updatedData.push(updatedCompany);
    });

    setEnterprises(updatedData);
  }

  async function getListEnterprises2(title, page, limit) {
    const response = await APICompany.newListAllCompanysUsers(
      token,
      page,
      title,
      limit,
      filtersEnterprises
    );
    const data = response.data;
    const updatedData = [];

    data.forEach((company) => {
      const updatedCompany = {
        id: company.id_empresa,
        label: company.nome,
        caption1: company.cnpj,
        caption2: company.site,
        caption3: company.codigo_empresa,
      };

      updatedData.push(updatedCompany);
    });

    setEnterprises(updatedData);
    return updatedData;
  }

  async function isTelephoneValidated(phoneNumber, currentData) {
    if (!phoneNumber) {
      setPageStatus("awaiting");
      return false;
    }

    const numberParse = parsePhoneNumber(phoneNumber, "BR");
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);

    if (numberParse.isValid()) {
      const response = await APIClient.getIdClient(token, phoneNumber);

      if (response.status === 200) {
        if (response.data.length > 0) {
          setInputAddContact(false);
          setIsOpenContact(false);
          setCompanyData({ ...emptyCompanyData });

          currentData.id_contato = response.data[0]?.id_contato;
          setCaseData(currentData);
          setClientData({
            id: response.data[0]?.id_contato,
            label: unicodeToChar(response.data[0]?.nome_contato),
          });
          Toast(
            "Atenção!",
            "O número que você está tentando adicionar, já está vinculado a um contato existente.",
            "warn"
          );
          return false;
        } else {
          const typeFromNumber = numberParse.getType() === "FIXED_LINE" ? 2 : 4;
          setTypeNumber(() => typeFromNumber);
          return true;
        }
      } else {
        Toast(
          "Falha, não foi carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );

        return false;
      }
    } else {
      setValidationWarnContact(true);
      Toast(
        "Erro ao criar contato!",
        "Verifique se o número do contato foi digitado corretamente.",
        "error"
      );

      return false;
    }
  }

  // async function isCpfValidated(cpfNumber, currentData) {
  //   if (!cpfNumber) return setValidationWarnContact(() => false);

  //   if (cpfNumber.length < 11) {
  //     setValidationWarnContact(() => true);
  //     Toast(
  //       "Erro ao criar contato!",
  //       "Verifique se o número do CPF foi digitado corretamente.",
  //       "error"
  //     );

  //     return false;
  //   }

  //   const response = await APIClient.all(
  //     token,
  //     1,
  //     cpfNumber,
  //     50,
  //     filtersContacts
  //   );

  //   const data = response.data;

  //   if (data.length > 0) {
  //     setInputAddContact(false);
  //     setIsOpenContact(false);
  //     setCompanyData({ ...emptyCompanyData });

  //     currentData.id_contato = data[0]?.id_contato;
  //     setCaseData(currentData);
  //     setClientData({
  //       id: data[0]?.id_contato,
  //       label: unicodeToChar(data[0]?.nome_contato),
  //     });
  //     Toast(
  //       "Atenção!",
  //       "O CPF que você está tentando adicionar, já está vinculado a um contato existente.",
  //       "warn"
  //     );
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  //################## NOVA FUNÇÃO CHAMADA PARA CRIAR CASO ##################
  async function handleCreateCase() {
    if (!isFormValidated()) {
      setValidationWarn(true);
      Toast(
        "Erro ao criar caso!",
        "Verifique o preenchimento dos campos em destaque.",
        "error"
      );
      return;
    }

    setPageStatus("creating-case");

    const fixedData = {
      ...caseData,
      prazo_resolucao: formatDate(caseData.prazo_resolucao),
      dt_abertura: formatDate(new Date()),
      dt_ultima_atualizacao: formatDate(new Date()),
      prazo_inicio: formatDate(new Date()),
      ...(caseData.pode_finalizar && { dt_fechamento: formatDate(new Date()) }),
    };

    const response = await APICase.createCase(token, fixedData);

    if (response.status === 200) {
      const idTicket = response.data.Dados.id_ticket;

      Toast("Caso criado!", "Caso criado com sucesso.", "success");

      if (additionalFieldsParent.length > 0) {
        await Promise.all(
          additionalFieldsParent.map(async (field) => {
            if (field.valor_default !== null) {
              const dataField = {
                id_ticket: idTicket,
                id_campo: field.id_campo_adicional,
                valor_campo: field.valor_default,
                label: currentStatusTexts[field.id_campo_adicional] || ""
              };

              const responseField = await APICase.createTicketAdditionalFields(
                token,
                dataField
              );

              if (responseField.status !== 200) {
                Toast(
                  "Não foi possível adicionar os campos variáveis a esse caso!",
                  "Verifique sua conexão e tente novamente mais tarde.",
                  "error"
                );
              }
            }
          })
        );
      }

      const childFieldsArray = Object.values(additionalChildFields);

      if (childFieldsArray.length > 0) {
        await Promise.all(childFieldsArray.map(async (childFields) => {
          await Promise.all(childFields.map(async (childField) => {
            if (childField.valor_default !== null) {
              const dataChild = {
                id_ticket: idTicket,
                id_campo: childField.id_campo_adicional,
                valor_campo: childField.valor_default,
                label: currentStatusTexts[childField.id_campo_adicional] || ""
              };

              const responseField = await APICase.createTicketAdditionalFields(token, dataChild);

              if (responseField.status !== 200) {
                Toast(
                  "Não foi possível adicionar os campos variáveis filhos a esse caso!",
                  "Verifique sua conexão e tente novamente mais tarde.",
                  "error"
                );
              }
            }
          }));
        }));
      }

      if (interaction.destination && !interaction.clientId) {
        setTimeout(() => {
          handleInteractionSaveImmediate(idTicket, response.data.Dados);
        }, 1500);
      }

      setTimeout(() => {
        onCloseCustom();
        onCreate();

        const path = history.location.pathname.indexOf("externo") === 1 ? "/externo/casos/" : "/casos/";
        history.push(`${path}${idTicket}${isDealing ? "?dealing=true" : ""}`);
      }, 2000);
    } else {
      Toast(
        "Não foi possível criar o Caso!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }

    setPageStatus("awaiting");
  }

  //################## ANTIGA FUNÇÃO CHAMADA PARA CRIAR CASO ##################
  // async function handleCreateCase() {
  //   if (isFormValidated()) {
  //     setPageStatus("creating-case");

  //     let fixedData = cloneDeep(caseData);

  //     // fixing date format
  //     fixedData.prazo_resolucao = formatDate(caseData.prazo_resolucao);

  //     // fullfil date info
  //     fixedData.dt_abertura = formatDate(new Date());
  //     fixedData.dt_ultima_atualizacao = formatDate(new Date());
  //     fixedData.prazo_inicio = formatDate(new Date());

  //     // fullfil dt_fechamento if status has permission to
  //     if (fixedData.pode_finalizar)
  //       fixedData.dt_fechamento = formatDate(new Date());

  //     const response = await APICase.createCase(token, fixedData);

  //     if (response.status === 200) {
  //       //Caso consiga criar o caso com sucesso, roda a função 'onCreate' da props, 'onCloseCustom' para resetar o modal e caso esteja em uma interação, chama a função 'handleInteractionSave'.

  //       let idTicket = response.data.Dados.id_ticket;

  //       Toast("Caso criado!", "Caso criado com sucesso.", "success");

  //       if (principalAdditionalField.length > 0) {
  //         variableValues.forEach(async (variable) => {
  //           if (variable.value) {
  //             let dataField = {
  //               id_ticket: idTicket,
  //               id_campo: variable.id,
  //               valor_campo: variable.value,
  //             };
  //             const responseField = await APICase.createTicketAdditionalFields(
  //               token,
  //               dataField
  //             );

  //             if (responseField.status !== 200) {
  //               Toast(
  //                 "Não foi possível adicionar os campos variaveis a esse caso!",
  //                 "Tente novamente mais tarde!.",
  //                 "warn"
  //               );
  //             }
  //           }
  //         });
  //       }

  //       if (interaction.destination && !interaction.clientId) {
  //         setTimeout(() => {
  //           handleInteractionSaveImmediate(
  //             response.data.Dados.id_ticket,
  //             response.data.Dados
  //           );
  //         }, [1500]);
  //       }

  //       setTimeout(() => {
  //         if (isDirect) {
  //           onCloseCustom();
  //         } else {
  //           onCloseCustom();
  //         }
  //         onCreate();
  //         // Comentado após descisão de salvar interação logo na criação do caso
  //         // if(interaction.destination && !interaction.clientId) {
  //         //   handleInteractionSave(response.data.Dados.id_ticket);
  //         // }

  //         // if (interaction.destination && !interaction.clientId) {
  //         //   setTimeout(() => {
  //         //     handleInteractionSaveImmediate(response.data.Dados.id_ticket);
  //         //   }, [1500]);
  //         // }

  //         if (history.location.pathname.indexOf("externo") === 1) {
  //           if (isDirect) {
  //             history.push(`/externo/casos/${response.data.Dados.id_ticket}${isDealing ? "?dealing=true" : ""}`);
  //           } else {
  //             history.push(`/externo/casos/${response.data.Dados.id_ticket}${isDealing ? "?dealing=true" : ""}`);
  //           }
  //         } else {
  //           history.push(`/casos/${response.data.Dados.id_ticket}`);
  //         }
  //       }, [2000]);
  //     } else {
  //       //Caso não consiga criar o caso com sucesso, retorna um toast com mensagem de erro.
  //       Toast(
  //         "Não foi possível criar a Tarefa!",
  //         "Tente novamente mais tarde.",
  //         "error"
  //       );
  //     }

  //     setPageStatus("awaiting");
  //   } else {
  //     setValidationWarn(true);
  //     Toast(
  //       "Erro ao criar caso!",
  //       "Verifique o preenchimento dos campos em destaque",
  //       "error"
  //     );
  //   }
  // }

  //Função chamada para criar um contato não registrado.
  async function handleCreateContact() {
    if (isFormValidated()) {
      setPageStatus("creating-contact");

      if (interaction?.channelId !== null) {
        if (interaction?.channelId === "1" || interaction?.channelId === "5") {
          const validateNumber = await isTelephoneValidated(
            companyData.telephone,
            caseData
          );

          if (!validateNumber) {
            setPageStatus("awaiting");
            setValidationWarnContact(true);

            return;
          }
        }
      } else if (interaction?.channelId === null) {
        const validateNumber = await isTelephoneValidated(
          companyData.telephone,
          caseData
        );

        if (!validateNumber) {
          setPageStatus("awaiting");
          setValidationWarnContact(true);

          return;
        }
      }

      // if (companyData.cpf) {
      //   const validateCpf = await isCpfValidated(companyData.cpf, caseData);

      //   if (!validateCpf) {
      //     setPageStatus("awaiting");
      //     setValidationWarnContact(true);

      //     return;
      //   }
      // }

      if (companyData.email) {
        const re = /\S+@\S+\.\S+/;
        const validateEmail = re.test(companyData.email);

        if (!validateEmail) {
          setPageStatus("awaiting");
          setValidationWarnContact(true);
          Toast(
            "Erro ao criar contato!",
            "Verifique o email digitado",
            "error"
          );

          return;
        }
      }

      const newContact = {
        numbers: [
          {
            telephone: companyData.telephone,
            type_telephone: typeNumber,
          },
        ],
        name: companyData.name,
        displayname: companyData.name,
        cpf: companyData.cpf,
        rg: "",
        cep: "",
        address: "",
        number: "",
        email: companyData.email,
        district: "",
        externcode: companyData.externcode,
        username: companyData.name,
        id_empresa: companyData.empresa.id,
      };

      const response = await APIClient.createContactUnify(token, newContact);

      if (response.status === 200) {
        if (response.data.Message === "Número já cadastrado!") {
          const isUserExist = await APIClient.getIdClient(
            token,
            companyData.telephone
          );
          if (isUserExist.status === 200) {
            if (isUserExist.data.length === 0) {
              Toast("Atenção!", "Ocorreu um erro ao buscar o contato", "warn");
            } else {
              setIsOpenContact(false);
              setIsOpenContactExist(true);
              setClientReturned(isUserExist.data[0].nome_contato);
            }
          }
        } else {
          Toast("Contato criado!", "Contato criado com sucesso.", "success");
          setValidationWarnContact(false);
          await getClient(companyData.telephone, caseData);
        }
      } else {
        //Caso não consiga criar o contato com sucesso, retorna um toast com mensagem de erro.
        Toast(
          "Não foi possível criar a Tarefa",
          "Tente novamente mais tarde!.",
          "error"
        );
      }

      setPageStatus("awaiting");
    } else {
      setValidationWarnContact(true);
      Toast(
        "Erro ao criar contato!",
        "Verifique o preenchimento dos campos em destaque",
        "error"
      );
    }
  }

  async function handleCancelContactExist() {
    setIsOpenContactExist(false);
    setIsOpenContact("addContact");
    setValidationWarnContact(false);
  }

  async function handleContinueContactExist() {
    setIsOpenContactExist(false);
    setValidationWarnContact(false);
    await getClient(companyData.telephone, caseData);
  }

  //Função chamada para unir um contato não registrado a um já existente.
  async function handleLinkContact() {
    if (clientData.id) {
      setPageStatus("creating-contact");

      const newNumber = {
        numbers: [
          {
            telephone: interaction?.destination,
          },
        ],
        id_contato: clientData.id,
      };

      const response = await APIClient.linkContact(token, newNumber);

      if (response.status === 200) {
        Toast(
          "Contato vinculado!",
          "Contato vinculado com sucesso.",
          "success"
        );
        setValidationWarnContact(false);
        await getClient(interaction?.destination, caseData);
      } else {
        //Caso não consiga vincular o contato com sucesso, retorna um toast com mensagem de erro.
        Toast(
          "Não foi possível vincular a Tarefa",
          "Tente novamente mais tarde!.",
          "error"
        );
      }

      setPageStatus("awaiting");
    } else {
      setValidationWarnContact(true);
      Toast(
        "Erro ao vincular contato!",
        "Verifique o preenchimento dos campos em destaque",
        "error"
      );
    }
  }

  //Função que busca o id do cliente com base no número do telefone dele.
  async function getClient(telephone, currentData) {
    setPageStatus("requesting");

    const response = await APIClient.getIdClient(token, telephone);

    if (response.status === 200) {
      if (response.data.length === 0) {
        setInputAddContact(true);
      } else {
        setInputAddContact(false);
        setIsOpenContact(false);
        setCompanyData({ ...emptyCompanyData });

        if (interaction.destination && applyFilter) {
          dispatch(
            setFilter({
              to: "advancedsCases",
              type: "clients",
              value: [
                {
                  id: response.data[0]?.id_contato,
                  name: unicodeToChar(response.data[0]?.nome_contato),
                },
              ],
            })
          );

          dispatch(
            setFilter({ to: "advancedsCases", type: "active", value: true })
          );
        }
      }

      if (interaction.companyName) {
        const responseCompany = await APICompany.newListAllCompanysUsers(
          token,
          1,
          interaction.companyName,
          1,
          filtersEnterprises
        );
        const data = responseCompany.data;

        if (data.length !== 0) {
          getListEnterprises(null, interaction.companyName, enterprisesLimit);
          currentData.id_empresa = data[0].id_empresa;
          document.getElementById(`field-select-empresa`).value =
            interaction.companyName;
        }
      }

      currentData.id_contato = response.data[0]?.id_contato;
      setCaseData(currentData);
      setClientData({
        id: response.data[0]?.id_contato,
        label: unicodeToChar(response.data[0]?.nome_contato),
      });
      // response.data[0]?.id_contato
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
    setPageStatus("awaiting");
  }

  //Função que pega a lista de solicitações e transforma no array padrão que o componente recebe.
  async function getListRequestTypes(idOrganization) {
    setPageStatus("requesting");
    let data = [];
    if (userData.organizationsAuth) {
      data = JSON.parse(userData.organizationsAuth);
    }
    const response = await APICase.getRequestList(
      token,
      userData.userId,
      data.length === 1 ? data[0].idorganizacao : idOrganization
    );

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_solicitacao;
        requestItem.label = datum.descricao_solicitacao;

        dataUpdated.push(requestItem);
      });

      dataUpdated = modifyArray(dataUpdated);

      setRequestTypesList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
    setPageStatus("awaiting");
  }

  async function changeTypeRequest(idTypeRequest, idOrganization) {
    setPageStatus("requesting");

    const response = await APICase.getReasonsListToType(
      token,
      idTypeRequest,
      idOrganization
    );

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_motivo;
        requestItem.label = datum.desc_motivo;

        dataUpdated.push(requestItem);
      });

      if (dataUpdated.length === 1) {
        await getSlaRule(dataUpdated[0].id, idTypeRequest, idOrganization);
        // setCaseData({ ...caseData, id_motivo: dataUpdated[0].id });
        changeSubTypeRequest(dataUpdated[0].id, idOrganization);
      } else {
        if (dataUpdated.length >= 2 || dataUpdated.length === 0) {
          await getSlaRule(null, idTypeRequest, idOrganization);
        }
        // if (dataUpdated.length === 0) {
        //   setCaseData({
        //     ...caseData,
        //     id_criticidade: 3,
        //     descricao_criticidade: "Baixa",
        //     id_solicitacao: idTypeRequest,
        //     prazo_resolucao: undefined,
        //   });
        //   setEditableField(true);
        // }
      }

      dataUpdated = modifyArray(dataUpdated);

      setReasonsTypesList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
    setPageStatus("awaiting");
  }

  async function changeSubTypeRequest(idReasonRequest, idOrganization) {
    setPageStatus("requesting");

    const response = await APICase.getSubReasonsListToType(
      token,
      idReasonRequest,
      idOrganization
    );

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_submotivo;
        requestItem.label = datum.desc_submotivo;

        dataUpdated.push(requestItem);
      });

      dataUpdated = modifyArray(dataUpdated);

      setSubReasonsTypesList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
    setPageStatus("awaiting");
  }

  async function getSlaRule(idReason, idRequest, idOrganization) {
    setPageStatus("requesting");

    try {
      const response = await APICase.getSlaRule(
        token,
        idReason,
        idRequest,
        idOrganization
      );

      if (response.status === 200) {
        if (response.data.length !== 0) {
          const slaData = response.data[0];

          const filterPriority = priorityList.find(
            (option) => option.id === slaData.id_criticidade
          );

          const idEscalonamento =
            slaData.id_escalonamento !== null ? slaData.id_escalonamento : null;

          setEditableField(slaData.editavel);
          setCaseData({
            ...caseData,
            id_motivo: idReason,
            id_solicitacao: idRequest,
            id_criticidade: filterPriority ? filterPriority.id : "",
            descricao_criticidade: filterPriority ? filterPriority.label : "",
            id_escalonamento: idEscalonamento
          });
        } else {
          await getSlaRuleDefault(idReason, idRequest, idOrganization);
        }

        setSlaData(response.data)
      } else {
        Toast(
          "Falha, não foi possível carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde",
          "error"
        );
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);

      Toast(
        "Erro interno!",
        "Ocorreu um erro ao processar a solicitação. Tente novamente mais tarde.",
        "error"
      );
    } finally {
      setPageStatus("awaiting");
    }
  }

  async function getSlaRuleDefault(idReason, idRequest, idOrganization, staggeredId) {
    try {
      setPageStatus("requesting");

      const response = await APICase.getSlaRule(
        token,
        null,
        idRequest,
        idOrganization,
        staggeredId
      );

      if (response.status === 200) {
        const responseData = response.data[0];
        if (responseData) {
          const dateSla = await calcSlaDayAndHoliday(
            responseData.id_sla,
            responseData.sla_inicio + responseData.sla_resolucao,
            responseData.now
          );

          const filterPriority = priorityList.find(
            (option) => option.id === responseData.id_criticidade
          );

          if (!filterPriority) {
            Toast("Falha!", "Criticidade não encontrada.", "error");
          }

          setEditableField(responseData.editavel);

          const updatedCaseData = {
            ...caseData,
            id_motivo: idReason,
            id_solicitacao: idRequest,
            id_escalonamento: staggeredId,
            id_criticidade: filterPriority.id,
            descricao_criticidade: filterPriority.label,
            prazo_resolucao: dateSla,
          };

          setCaseData(updatedCaseData);
        } else {
          setCaseData({
            ...caseData,
            id_criticidade: 3,
            descricao_criticidade: "Baixa",
            id_motivo: idReason,
            id_solicitacao: idRequest,
            id_escalonamento: staggeredId,
            prazo_resolucao: undefined,
          });
          setEditableField(true);
        }
      } else {
        throw new Error("Erro ao carregar informações");
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);
      Toast(
        "Falha ao carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    } finally {
      setPageStatus("awaiting");
    }
  }

  async function getSlaRuleSub(
    idSubReason,
    idRequest,
    idReason,
    idOrganization
  ) {
    try {
      setPageStatus("requesting");

      const response = await APICase.getSlaRule2(
        token,
        idSubReason,
        idRequest,
        idReason,
        idOrganization
      );

      if (response.status === 200) {
        const responseData = response.data[0];
        if (responseData) {
          const dateSla = await calcSlaDayAndHoliday(
            responseData.id_sla,
            responseData.sla_inicio + responseData.sla_resolucao,
            responseData.now
          );

          const filterPriority = priorityList.find(
            (option) => option.id === responseData.id_criticidade
          );

          if (!filterPriority) {
            Toast("Falha!", "Criticidade não encontrada.", "error");
          }

          setEditableField(responseData.editavel);
          setCaseData({
            ...caseData,
            id_submotivo: idSubReason,
            id_motivo: idReason,
            id_solicitacao: idRequest,
            id_criticidade: filterPriority.id,
            descricao_criticidade: filterPriority.label,
            prazo_resolucao: dateSla,
          });
        } else {
          await getSlaRuleSubDefault(
            idSubReason,
            idRequest,
            idReason,
            idOrganization
          );
        }
      } else {
        throw new Error("Erro ao carregar informações");
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);
      Toast(
        "Falha ao carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    } finally {
      setPageStatus("awaiting");
    }
  }

  async function getSlaRuleSubDefault(
    idSubReason,
    idRequest,
    idReason,
    idOrganization
  ) {
    try {
      setPageStatus("requesting");

      const response = await APICase.getSlaRule2(
        token,
        null,
        idRequest,
        idReason,
        idOrganization
      );

      if (response.status === 200) {
        const responseData = response.data[0];
        if (responseData) {
          const dateSla = await calcSlaDayAndHoliday(
            responseData.id_sla,
            responseData.sla_inicio + responseData.sla_resolucao,
            responseData.now
          );

          const filterPriority = priorityList.find(
            (option) => option.id === responseData.id_criticidade
          );

          if (!filterPriority) {
            Toast("Falha!", "Criticidade não encontrada.", "error");
          }

          setEditableField(responseData.editavel);
          setCaseData({
            ...caseData,
            id_submotivo: idSubReason,
            id_motivo: idReason,
            id_solicitacao: idRequest,
            id_criticidade: filterPriority.id,
            descricao_criticidade: filterPriority.label,
            prazo_resolucao: dateSla,
          });
        } else {
          setCaseData({
            ...caseData,
            id_criticidade: 3,
            descricao_criticidade: "Baixa",
            id_submotivo: idSubReason,
            id_motivo: idReason,
            id_solicitacao: idRequest,
            prazo_resolucao: undefined,
          });
          setEditableField(true);
        }
      } else {
        Toast(
          "Falha ao carregar as informações!",
          "Verifique sua conexão e tente novamente mais tarde.",
          "error"
        );
      }
    } catch (error) {
      console.error("Erro ao buscar regra de SLA:", error);

      Toast(
        "Falha ao carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    } finally {
      setPageStatus("awaiting");
    }
  }

  //Função que busca a lista dos setores do escalonamento na API e transforma no modelo padrão dos componentes.
  async function getEscalateList() {
    const response = await APICase.getEscalateList(token);

    if (response.status === 200) {
      let dataUpdated = [];

      response.data.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_escalonamento;
        requestItem.label = datum.descricao;

        dataUpdated.push(requestItem);
      });
      setEscalateList(dataUpdated);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  }

  async function getAdditionalFieldsParent() {
    const response = await APIAdditionalFields.getAdditionalFieldsParent(token);

    if (response.status === 200) {
      const updateData = response.data.map((field) => ({
        ...field,
        valor_default: field.id_tipo_campo_adicional === 4 &&
          field.valor_default !== null ?
          parseInt(field.valor_default) : field.valor_default
      }));

      setAdditionalFieldsParent(updateData);
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getAdditionalChildFields(fieldId, value) {
    const response = await APIAdditionalFields.getAdditionalChildFields(token, fieldId, value);

    if (response.status === 200) {
      const updateData = response.data.map(field => ({
        ...field,
        valor_default: field.id_tipo_campo_adicional === 4 &&
          field.valor_default !== null ?
          parseInt(field.valor_default) : field.valor_default
      }));

      setAdditionalChildFields(prevMap => ({
        ...prevMap,
        [fieldId]: updateData
      }));
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function getAdditionalFieldsIntegration() {
    const response = await APIAdditionalFields.getAdditionalFieldsIntegration(token);

    if (response.status === 200) {
      const updateData = response.data.map(item => ({
        ...item,
        campos_adicionais_obrigatorios: JSON.parse(item.campos_adicionais_obrigatorios),
        valor_padrao: item.valor_padrao === "null" && null
      }))

      setAdditionalFieldsIntegration(updateData)
    } else {
      Toast(
        "Falha, não foi possível carregar as informações!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  }

  async function postProcessedAdditionalFields(
    additionalFieldsIntegration,
    additionalFieldsParent
  ) {
    try {
      const results = [];

      for (const field of additionalFieldsIntegration) {
        const {
          url,
          metodo,
          token,
          campos_adicionais_obrigatorios,
          id_campo_gatilho,
          id_campo_adicional_retorno,
          valor_padrao
        } = field;

        const triggerField = additionalFieldsParent.find(
          parentField => parentField.id_campo_adicional === id_campo_gatilho
        );

        const triggerFieldFilled =
          triggerField &&
          triggerField.valor_default !== "" &&
          triggerField.valor_default !== null &&
          triggerField.valor_default !== undefined;

        if (!triggerFieldFilled || !isOptionSelected) {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
          continue;
        }

        const allRequiredFieldsFilled = campos_adicionais_obrigatorios.every(integrationField => {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          return correspondingField &&
            correspondingField.valor_default !== "" &&
            correspondingField.valor_default !== null &&
            correspondingField.valor_default !== undefined;
        });

        if (!allRequiredFieldsFilled) {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
          continue;
        }

        let payload = {};

        for (const integrationField of campos_adicionais_obrigatorios) {
          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === integrationField.idcampos
          );

          payload[correspondingField.desc_campo_adicional] =
            correspondingField ? correspondingField.valor_default : valor_padrao;
        }

        const triggerFieldDesc = triggerField.desc_campo_adicional;
        payload[triggerFieldDesc] = triggerField.valor_default;

        const config = {
          method: metodo.toLowerCase(),
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(payload)
        };

        const response = await axios(config);

        if (response.status === 200 && response.data) {
          const returnValue = response.data;

          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: response.data || valor_padrao
          });

          const correspondingField = additionalFieldsParent.find(
            parentField => parentField.id_campo_adicional === id_campo_adicional_retorno
          );

          if (correspondingField) {
            correspondingField.valor_default = returnValue.id || returnValue.valor;
          }
        } else {
          results.push({
            id_campo_adicional_retorno: id_campo_adicional_retorno,
            valor: valor_padrao
          });
        }
      }

      return results;
    } catch (error) {
      console.log('postProcessedAdditionalFields -> Error en el servidor:', error);
      return error;
    }
  }

  async function getDataInfo() {
    try {
      await Promise.allSettled([
        getListOrganizations(),
        getListPriorities(),
        getListChannelTypes(),
        getEscalateList(),
        getAdditionalFieldsParent(),
        getAdditionalFieldsIntegration(),
      ])
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os dados:', error);

      Toast(
        "Falha, não foi possível carregar os dados",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  };

  //USE CALLBACKS

  //USE EFFECTS
  useEffect(() => {
    getDataInfo();
    // getListStatus();
    // getListReasonsTypes();
    // getListRequestTypes(caseData.id_organizacao);
  }, []);

  useEffect(() => {
    if (additionalFieldsIntegration.length > 0 && additionalFieldsParent.length > 0) {
      (async () => {
        const data = await postProcessedAdditionalFields(
          additionalFieldsIntegration,
          additionalFieldsParent
        );

        setProcessedAdditionalFields(data);
        setIsOptionSelected(false);
      })();
    }
  }, [additionalFieldsIntegration, additionalFieldsParent, isOptionSelected]);

  useEffect(() => {
    if (customEmptyTaskData) {
      setEmptyCaseData({ ...emptyCaseData, ...customEmptyTaskData });
    }
  }, [customEmptyTaskData]);

  useEffect(() => {
    if (emptyCaseData) {
      setCaseData({ ...emptyCaseData });
    }
  }, [emptyCaseData]);

  useEffect(() => {
    if (organizationsList.length === 1) {
      setCaseData({ ...caseData, id_organizacao: organizationsList[0].id });
      getListRequestTypes(organizationsList[0].id);
    }
  }, [organizationsList]);

  useEffect(() => {
    if (caseData.id_organizacao) {
      getListStatus();
    }
  }, [caseData.id_organizacao]);

  useEffect(() => {
    if (isOpenContact) getListEnterprises(null, null, enterprisesLimit);
  }, [isOpenContact, enterprisesLimit]);

  useEffect(() => {
    if (principalAdditionalField.length > 0 && variableValues.length === 0) {
      let dataUpdated = [];

      principalAdditionalField.forEach((datum) => {
        let requestItem = {};

        requestItem.id = datum.id_campo_adicional;
        requestItem.value = "";

        dataUpdated.push(requestItem);
      });
      setVariableValues(dataUpdated);
    }
  }, [principalAdditionalField]);

  useEffect(() => {
    const currentData = { ...caseData };
    currentData.id_canal =
      interaction.channelId === "11" ? 11 : interaction.channelId || 3;
    currentData.desc_canal =
      interaction.channelId === "11"
        ? "GoogleGBM"
        : interaction.channelName || "Web";
    currentData.protocolo_externo = interaction.protocol || null;
    currentData.id_contato = interaction.clientId || undefined;
    currentData.possui_interacao = interaction.interaction || false;
    currentData.observacao = interaction.observation || undefined;
    setClientData(
      interaction.clientId
        ? {
          id: interaction.clientId,
          label: unicodeToChar(interaction.clientName),
        }
        : undefined
    );
    setCaseData(currentData);

    if (interaction.channelId === 14) {
      setBlockChannelOrigin(true);
    }

    if (interaction.destination && !interaction.clientId) {
      getClient(interaction.destination, currentData);
      setCompanyData({
        ...companyData,
        telephone: interaction.destination,
        name: unicodeToChar(interaction.clientName),
      });
    }

    // handleInputAddContact();
  }, [interaction]);

  // useEffect(() => {
  //   if (!applyFilter) {
  //     window.localStorage.setItem(
  //       "advancedsFilter",
  //       JSON.stringify(filtersAdvanceds)
  //     );
  //   }
  // }, [filtersAdvanceds]);

  useEffect(() => {
    if (caseKanbanColumnId) {
      getIdTicketStatus(caseKanbanColumnId);
    }
  }, [caseKanbanColumnId]);

  useEffect(() => {
    if (caseData.id_organizacao != null)
      setPrevSelectOrganization({ organizationId: caseData.id_organizacao });

    if (caseData.id_organizacao !== prevSelectOrganization.organizationId)
      setCaseData({ ...caseData, id_solicitacao: undefined });
  }, [caseData.id_organizacao]);

  useEffect(() => {
    clearRequestTypeFields(clearFieldTrigger);
  }, [clearFieldTrigger, caseData.id_solicitacao, caseData.id_motivo, caseData.id_submotivo]);

  useEffect(() => {
    if (newStatus.length > 0) {
      setCaseData((prevData) => ({
        ...prevData,
        id_ticket_status: newStatus[0].id_ticket_status,
      }));
    }
  }, [newStatus, caseData.id_ticket_status]);

  useEffect(() => {
    if (slaData.length > 0) {
      const slaDataItem = slaData[0];

      const calculateSlaDate = async () => {
        const dateSla = await calcSlaDayAndHoliday(
          slaDataItem.id_sla,
          slaDataItem.sla_inicio + slaDataItem.sla_resolucao,
          slaDataItem.now
        );

        setCaseData((prevData) => ({
          ...prevData,
          prazo_resolucao: dateSla,
        }));
      };

      calculateSlaDate();
    } else {
      setCaseData((prevData) => ({
        ...prevData,
        prazo_resolucao: null,
      }));
    }
  }, [slaData]);

  useEffect(() => {
    setChangedStatus(caseData.id_ticket_status);
  }, [caseData.id_ticket_status]);

  return (
    <Modal.Main
      className="modal-create-case"
      title="Adicionar novo caso"
      isOpen={isOpen}
      onClose={onCloseCustom}
      isDealing={isDealing}
      // width="704px"
      width="740px"
      footer={
        <>
          {!isDealing && (
            <Button
              className="modal-create-case__btn-cancel"
              onClick={() => onCloseCustom()}
              disabled={pageStatus === "creating-case"}
            >
              <Typography.H4 weight={"bold"} color="red-2">
                Cancelar
              </Typography.H4>
            </Button>
          )}
          <Button
            className="modal-create-case__btn-confirm"
            onClick={() => handleCreateCase()}
            disabled={pageStatus === "creating-case"}
          >
            {pageStatus !== "creating-case" ? (
              <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
            ) : (
              <Loading.Dots />
            )}
          </Button>
        </>
      }
    >
      <section className={`modal-create-case__content`}>
        <div className="container-full-line">
          <FieldContainer
            title="Contato"
            titleSize="small"
            required
            className={`field-container-case-client-list ${organizationsList.length === 0 ? "grid-span-2" : ""
              }`}
            htmlFor=""
          >
            {inputAddContact ? (
              <div className="addcontactWarning">
                <Input
                  disabled
                  value={renderValueOfTheContactToAdd()}
                // value={
                //   interaction.destination
                //     ? renderPhone(interaction?.destination)
                //     : ""
                // }
                />

                <div className="span-warning">
                  <DangerIcon />

                  <Typography.H5 color={"gray-180"}>
                    Contato não encontrado! Para seguir com a abertura de caso,
                    é necessário criar um novo contato ou vincular a um contato
                    já existente.
                  </Typography.H5>
                </div>

                <div className="buttons-addContactWarning">
                  <Button
                    type="secondary"
                    onClick={() => setIsOpenContact("addContact")}
                  >
                    Adicionar contato
                  </Button>
                  <Button
                    type="fourthiary"
                    onClick={() => setIsOpenContact("linkContact")}
                  >
                    Vincular a um contato
                  </Button>
                </div>
              </div>
            ) : (
              <SelectSearch
                className={"create-case-select"}
                paginated
                contactSelect
                contactEmpty={() => setIsOpenContact("addContact")}
                placeholder="Busque pelo nome do contato"
                getOptions={getListClients}
                value={clientData}
                onSelect={(client) => {
                  setClientData(client);
                  setCaseData({ ...caseData, id_contato: client.id });
                }}
                error={validationWarn ? !caseData.id_contato : false}
              />
            )}
          </FieldContainer>

          <FieldContainer
            title="Empresa"
            titleSize="small"
            required={connection_platform.companyRequired}
            className={`field-container-case-client-list ${organizationsList.length === 0 ? "grid-span-2" : ""
              }`}
            htmlFor=""
          >
            <SelectSearch
              paginated
              nameField="empresa"
              placeholder="Busque pelo nome da empresa"
              className={"create-case-select"}
              getOptions={getListEnterprises2}
              value={caseData.id_empresa}
              onSelect={(client) => {
                setCaseData({ ...caseData, id_empresa: client.id });
              }}
              error={
                validationWarn && connection_platform.companyRequired
                  ? !caseData.id_empresa
                  : false
              }
            />
          </FieldContainer>

          {organizationsList.length > 0 && (
            <FieldContainer
              htmlFor=""
              title="Organização"
              titleSize="small"
              required
              className={`field-container-case-channel ${inputAddContact ? "disabled-field" : ""
                }`}
            >
              <Select
                includeSearchField
                placeholder="Selecione"
                className={"modal-create-case__dropdown"}
                value={caseData.id_organizacao}
                options={organizationsList}
                onSelect={(event) => handleOrganizationSelect(event)}
                error={validationWarn ? !caseData.id_organizacao : false}
              />
            </FieldContainer>
          )}

          <FieldContainer
            title="Tipo"
            titleSize="small"
            required
            className="field-container-case-request-type "
            htmlFor={""}
          >
            {/* <SelectSearch
              dynamicValues
              placeholder="Selecione"
              className={"modal-create-case__dropdown"}
              value={caseData.id_solicitacao}
              options={requestTypesList}
              onSelect={(event) => {
                setCaseData({ ...caseData, id_solicitacao: event.id });
                changeTypeRequest(event.id, caseData.id_organizacao);
              }}
              error={validationWarn ? !caseData.id_solicitacao : false}
              showAvatar={false}
            /> */}

            <CustomDropdown
              placeholder="Selecione"
              value={caseData.id_solicitacao}
              options={requestTypesList}
              onSelect={(event) => handleTypeSelect(event)}
              error={validationWarn ? !caseData.id_solicitacao : false}
            />
          </FieldContainer>

          <FieldContainer
            title="Motivo"
            titleSize="small"
            required={connection_platform.reasonRequired}
            className="field-container-case-request-type "
            htmlFor={""}
          >
            {/* <SelectSearch
              placeholder="Selecione"
              className={"modal-create-case__dropdown"}
              value={caseData.id_motivo}
              options={reasonsTypesList}
              onSelect={(event) => {
                setCaseData({ ...caseData, id_motivo: event.id });
                getSlaRule(
                  event.id,
                  caseData.id_solicitacao,
                  caseData.id_organizacao
                );
                changeSubTypeRequest(event.id, caseData.id_organizacao);
              }}
              showAvatar={false}
            /> */}

            <CustomDropdown
              placeholder="Selecione"
              value={caseData.id_motivo}
              options={reasonsTypesList}
              onSelect={(event) => handleReasonSelect(event)}
              error={
                validationWarn && connection_platform.reasonRequired
                  ? !caseData.id_empresa
                  : false
              }
            />
          </FieldContainer>

          <FieldContainer
            title="Sub-motivo"
            titleSize="small"
            className="field-container-case-request-type "
            htmlFor={""}
          >
            {/* <SelectSearch
              placeholder="Selecione"
              className={"modal-create-case__dropdown"}
              value={caseData.id_submotivo}
              options={subReasonsTypesList}
              onSelect={(event) => {
                setCaseData({ ...caseData, id_submotivo: event.id });
                getSlaRuleSub(
                  event.id,
                  caseData.id_solicitacao,
                  caseData.id_motivo,
                  caseData.id_organizacao
                );
              }}
              showAvatar={false}
            /> */}

            <CustomDropdown
              placeholder="Selecione"
              value={caseData.id_submotivo}
              options={subReasonsTypesList}
              onSelect={(event) => handleSubreasonSelect(event)}
            />
          </FieldContainer>

          <FieldContainer
            title="Prazo"
            titleSize="small"
            required
            className="field-container-case-date-time"
            htmlFor=""
          >
            <div
              className="field-container-case-date-time__container"
              style={{ pointerEvents: !editableField ? "none" : "" }}
            >
              <DatePicker.TimeRangeInput
                valueTime={caseData.prazo_resolucao}
                setValueTime={(event) =>
                  setCaseData({ ...caseData, prazo_resolucao: event })
                }
                className="date-details"
                disable={editableField}
                errorInput={validationWarn ? !caseData.prazo_resolucao : false}
                automaticSet
              />
              {/* <DatePicker.DateRange
                selectRangeType="start"
                selected={caseData.prazo_resolucao}
                setDate={(event) =>
                  setCaseData({ ...caseData, prazo_resolucao: event })
                }
                dateFormat="dd 'de' MMMM', 'u"
                className="date-details"
                width="180px"
                placeholderText="Selecione uma data"
                error={validationWarn ? !caseData.prazo_resolucao : false}
                errorType="border"
                disable={editableField}
              />
              <DatePicker.TimeRange
                selected={caseData.prazo_resolucao}
                setDate={(event) =>
                  setCaseData({ ...caseData, prazo_resolucao: event })
                }
                dateFormat="HH:mm"
                width="85px"
                placeholderText={"Hora"}
                error={validationWarn ? !caseData.prazo_resolucao : false}
                errorType="border"
                disable={editableField}
              /> */}
            </div>
          </FieldContainer>

          <FieldContainer
            title="Prioridade"
            titleSize="small"
            required
            className="field-container-case-priority"
            htmlFor=""
          >
            <DropdownPriority
              value={{
                id: caseData.id_criticidade,
                label: caseData.descricao_criticidade,
              }}
              className="field-container-case-priority__list modal-create-case__dropdown"
              options={priorityList}
              onClick={(event) =>
                setCaseData({
                  ...caseData,
                  id_criticidade: event.id,
                  descricao_criticidade: event.label,
                })
              }
              error={validationWarn ? !caseData.id_criticidade : false}
              errorType="border"
              //width='58px'
              label
              disable={editableField}
            />
          </FieldContainer>

          <FieldContainer
            title="Canal de Origem"
            titleSize="small"
            required
            className={`field-container-case-channel ${blockChannelOrigin ? "disabled-field" : ""
              } ${inputAddContact ? "disabled-field" : ""}`}
            htmlFor=""
          >
            <DropdownChannel
              includeSearchField
              value={{ id: caseData.id_canal, label: caseData.desc_canal }}
              className="modal-create-case__dropdown"
              options={channelTypesList}
              onClick={(event) =>
                setCaseData({
                  ...caseData,
                  id_canal: event.id,
                  desc_canal: event.label,
                })
              }
              //error={validationWarn ? !caseData.id_criticidade : false}
              errorType="border"
              label
            //width='58px'
            />
          </FieldContainer>

          <FieldContainer
            title="Status"
            titleSize="small"
            htmlFor=""
            required
            className="field-container-case-status"
          >
            <div className="field-container-case-status__container">
              <DropdownStatus
                searchStatus
                value={caseData.id_ticket_status}
                className="modal-create-case__dropdown"
                options={statusList}
                onClick={(event) => {
                  setNewStatus([]);
                  setChangedStatus(false);

                  setCaseData({
                    ...caseData,
                    id_ticket_status: event.id,
                    pode_finalizar: event.finalizar,
                  });

                  // if (event.id !== 5 && escalateValue[0].id_ticket_escalado) {
                  //   setEscalateValue([
                  //     { id_ticket_escalado: null, desc_ticket_escalado: "" },
                  //   ]);
                  // }
                }}
                error={validationWarn ? !caseData.id_ticket_status : false}
                newStatus={newStatus}
                changedStatus={changedStatus}
              />
            </div>
          </FieldContainer>

          <FieldContainer
            title="Escalado para"
            titleSize="small"
            required={connection_platform.staggeredRequired}
            className="field-container-case-status"
          >
            <div className="field-container-case-status__container">
              <DropdownEscalate
                includeSearchField
                className="modal-create-case__dropdown"
                value={caseData.id_escalonamento}
                options={escalateList}
                disable={false}
                onClick={(option) => handleEscalateSelect(option)}
                error={
                  validationWarn && connection_platform.staggeredRequired
                    ? !caseData.id_empresa
                    : false
                }
              />
            </div>
          </FieldContainer>
        </div>

        {/* ################ NOVA RENDERIZAÇÃO DE CAMPOS PERSONALIZADOS ################ */}
        {additionalFieldsParent.length > 0 && (
          <div
            className={`field-container-case-variable grid-span-2 
            ${isOpenCustomFields ? "active-variable" : ""} 
            ${inputAddContact ? "disabled-field" : ""}`}
          >
            <div
              className="field-container-case-variable-control"
              onClick={() => setIsOpenCustomFields(!isOpenCustomFields)}
            >
              <ArrowIcon />
              <Typography.H4 color={"blue-selected-4"} weight={700}>
                Campos personalizados
              </Typography.H4>
            </div>

            {isOpenCustomFields && (
              <div className="field-container-case-variable-fields">
                {additionalFieldsParent.map((field) =>
                  renderCustomFields(field, (fieldId, value) => {
                    setAdditionalFieldsParent(
                      (prevFields) =>
                        prevFields.map((prevField) =>
                          prevField.id_campo_adicional === fieldId ?
                            { ...prevField, valor_default: value } : prevField
                        )
                    );
                  }, handleBackspace)
                )}
              </div>
            )}
          </div>
        )}

        {/* {principalAdditionalField.length > 0 && (
          <div
            className={`field-container-case-variable grid-span-2 
              ${openVariable ? "active-variable" : ""} 
              ${inputAddContact ? "disabled-field" : ""}`}
          >
            <div
              className="field-container-case-variable-control"
              onClick={() => setOpenVariable(!openVariable)}
            >
              <ArrowIcon />
              <Typography.H4 color={"blue-selected-4"} weight={700}>
                Campos personalizados
              </Typography.H4>
            </div>
            {openVariable && (
              <div className="field-container-case-variable-fields">
                {principalAdditionalField.map((field) =>
                  handleVariableFields(field)
                )}
              </div>
            )}
          </div>
        )} */}

        <div
          className={`field-container-case-subject grid-span-2 ${inputAddContact ? "disabled-field" : ""
            }`}
        >
          <FieldContainer
            title="Assunto"
            titleSize="small"
            required
            className="field-container-case-subject grid-span-2"
          >
            <Input
              type="text"
              placeholder="Digite o assunto do caso"
              value={caseData.assunto}
              onChange={(event) => setCaseData({ ...caseData, assunto: event })}
              error={validationWarn ? !caseData.assunto : false}
            />
          </FieldContainer>
        </div>

        <div
          className={`field-container-case-desc grid-span-2 ${inputAddContact ? "disabled-field" : ""
            }`}
        >
          <FieldContainer
            title="Descrição"
            titleSize="small"
            required
            className="field-container-case-desc grid-span-2"
          >
            <Input
              className="field-container-case-desc__input"
              type="textarea"
              placeholder="Digite uma descrição"
              value={caseData.observacao}
              onChange={(event) =>
                setCaseData({ ...caseData, observacao: event })
              }
              error={validationWarn ? !caseData.observacao : false}
            />
          </FieldContainer>
        </div>

        {/* #POS-MVP: Implementar files */}
        {/* <Dropzone className="dropzone-container" /> */}
      </section>

      <Modal.Main
        title={
          isOpenContact === "addContact"
            ? "Adicionar contato"
            : "Vincular contato"
        }
        width="45.3%"
        isOpen={isOpenContact}
        onClose={() => {
          onCloseModalContact();
        }}
        className={"modal-add-contact"}
        footer={
          <>
            <Button
              className="modal-create-case__btn-cancel"
              onClick={() => {
                onCloseModalContact();
              }}
              disabled={pageStatus === "creating-contact"}
            >
              <Typography.H4 weight={"bold"} color="red-2">
                Cancelar
              </Typography.H4>
            </Button>

            <Button
              className="modal-create-case__btn-confirm"
              disabled={pageStatus === "creating-contact"}
              onClick={() =>
                isOpenContact === "addContact"
                  ? handleCreateContact()
                  : handleLinkContact()
              }
            >
              {pageStatus !== "creating-contact" ? (
                <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
              ) : (
                <Loading.Dots />
              )}
            </Button>
          </>
        }
      >
        <div className="section-addLink-contact">
          {isOpenContact === "addContact" ? (
            <>
              {" "}
              <FieldContainer
                title="Nome Completo"
                titleSize="small"
                required
                className="container-addContact"
              >
                <Input
                  value={companyData.name}
                  onChange={(event) =>
                    setCompanyData({ ...companyData, name: event })
                  }
                  error={validationWarnContact ? !companyData.name : false}
                />
              </FieldContainer>
              <FieldContainer
                title="Nome da Empresa"
                titleSize="small"
                className="container-addContact"
              >
                <CustomDropdown
                  noTippy
                  placeholder="Selecione"
                  value={companyData.id}
                  options={enterprises}
                  optionsLimit={enterprisesLimit}
                  setOptionsLimit={setEnterprisesLimit}
                  onSelect={(client) => {
                    setCompanyData({ ...companyData, empresa: client });
                  }}
                  showCustomEmptyState={true}
                  customEmptyStateIcon={<OrganizationIcon />}
                  onCustomEmptyStateClick={() =>
                    setIsOpenModalOrganization(true)
                  }
                  customEmptyStateMessage={
                    "Não encontramos nenhuma empresa ou organização com este nome. Deseja adicionar esta empresa?"
                  }
                  buttonText="Adicionar empresa"
                />
                {/* <SelectSearch
                  paginated
                  placeholder="Selecione"
                  className={"modal-create-case__dropdown"}
                  getOptions={getListEnterprises}
                  value={companyData.id}
                  onSelect={(client) => {
                    setCompanyValue(client);
                    setCompanyData({ ...companyData, empresa: client });
                  }}
                /> */}
              </FieldContainer>
              <FieldContainer
                title={
                  interaction?.channelId !== null ? (
                    <>
                      {interaction?.channelId === "1" ||
                        interaction?.channelId === "5"
                        ? "Telefone"
                        : "Identificador"}
                    </>
                  ) : (
                    "Telefone"
                  )
                }
                titleSize="small"
                required
                className="container-addContact"
              >
                {interaction?.channelId !== null ? (
                  <>
                    {interaction?.channelId === "1" ||
                      interaction?.channelId === "5" ? ( // voz: 1, whatsapp: 5
                      <Input
                        className="input-telephone"
                        type="numberMask"
                        mask={
                          companyData.telephone.length <= 10
                            ? "(99) 9999-99999"
                            : "(99) 99999-9999"
                        }
                        value={companyData.telephone}
                        onChange={(event) => {
                          setCompanyData({
                            ...companyData,
                            telephone: event,
                          });
                        }}
                        error={
                          validationWarnContact ? !companyData.telephone : false
                        }
                        onBlur={() =>
                          isTelephoneValidated(companyData.telephone, caseData)
                        }
                      />
                    ) : (
                      <Input
                        className="input-telephone"
                        value={companyData.telephone}
                        onChange={(event) => {
                          setCompanyData({
                            ...companyData,
                            telephone: event,
                          });
                        }}
                        error={
                          validationWarnContact ? !companyData.telephone : false
                        }
                      />
                    )}
                  </>
                ) : (
                  <Input
                    className="input-telephone"
                    type="numberMask"
                    mask={
                      companyData.telephone.length <= 10
                        ? "(99) 9999-99999"
                        : "(99) 99999-9999"
                    }
                    value={companyData.telephone}
                    onChange={(event) => {
                      setCompanyData({
                        ...companyData,
                        telephone: event,
                      });
                    }}
                    error={
                      validationWarnContact ? !companyData.telephone : false
                    }
                    onBlur={() =>
                      isTelephoneValidated(companyData.telephone, caseData)
                    }
                  />
                )}
              </FieldContainer>
              <FieldContainer
                title="CPF"
                titleSize="small"
                className="container-addContact"
              >
                <Input
                  type="numberMask"
                  mask={"999.999.999-99"}
                  value={companyData.cpf}
                  onChange={(event) => {
                    setCompanyData({
                      ...companyData,
                      cpf: event,
                    });
                  }}
                // error={validationWarnContact ? !companyData.cpf : false}
                // onBlur={() => isCpfValidated(companyData.cpf, caseData)}
                />
              </FieldContainer>
              <FieldContainer
                title="E-mail"
                titleSize="small"
                className="container-addContact"
              >
                <Input
                  value={companyData.email}
                  onChange={(event) =>
                    setCompanyData({ ...companyData, email: event })
                  }
                  type="email"
                />
              </FieldContainer>
              <FieldContainer
                title="Código externo"
                titleSize="small"
                className="container-addContact"
              >
                <Input
                  type="number"
                  value={companyData.externcode}
                  onChange={(event) =>
                    setCompanyData({ ...companyData, externcode: event })
                  }
                />
              </FieldContainer>{" "}
            </>
          ) : (
            <>
              <Typography.H4 color={"gray-180"}>
                Selecione o contato que você deseja vincular a este número{" "}
                {renderNumberTypeToLinkContact()}
                {/* {interaction?.destination && renderPhone(interaction?.destination)} */}
                .
              </Typography.H4>

              <FieldContainer
                title="Contato"
                titleSize="small"
                required
                htmlFor=""
                className="container-addContact"
              >
                <SelectSearch
                  className={"create-case-select"}
                  paginated
                  placeholder="Busque pelo nome do contato"
                  getOptions={getListClients}
                  value={clientData}
                  onSelect={(client) => {
                    setClientData(client);
                  }}
                  error={validationWarnContact ? !clientData?.id : false}
                />
              </FieldContainer>
            </>
          )}
        </div>
      </Modal.Main>

      <Modal.Main
        title="Contato já cadastrado"
        width="45.3%"
        isOpen={isOpenContactExist}
        onClose={() => {
          setIsOpenContactExist(false);
        }}
        className={"modal-add-contact"}
        footer={
          <>
            <Button
              className="modal-create-case__btn-cancel"
              onClick={() => {
                handleCancelContactExist();
              }}
              disabled={pageStatus === "creating-contact"}
            >
              <Typography.H4 weight={"bold"} color="red-2">
                Cancelar
              </Typography.H4>
            </Button>

            <Button
              className="modal-create-case__btn-confirm"
              disabled={pageStatus === "creating-contact"}
              onClick={() => handleContinueContactExist()}
            >
              {pageStatus !== "creating-contact" ? (
                <Typography.H4 weight={"bold"}>Continuar</Typography.H4>
              ) : (
                <Loading.Dots />
              )}
            </Button>
          </>
        }
      >
        <Typography.H4 color={"gray-180"}>
          Esta identificação já está cadastrada como {clientReturned}. Caso
          deseje continuar, o contato será selecionado automaticamente.
        </Typography.H4>
      </Modal.Main>

      <Modal.CreateCompany
        isOpen={isOpenModalOrganization}
        onClose={(event) => setIsOpenModalOrganization(event)}
        onCreate={getListEnterprises}
      />
    </Modal.Main>
  );
}
