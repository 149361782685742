import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchCase: localStorage.getItem("caseScreenSearchField") || "",
    filteredCases: [],
};

const searchSlice = createSlice({
    name: "searchFilter",
    initialState,
    reducers: {
        setFilteredCaseData: (state, action) => {
            state.filteredCases = action.payload;
        },
        setSearchCase: (state, action) => {
            state.searchCase = action.payload;
        },
    }
});

export const { setFilteredCaseData, setSearchCase } = searchSlice.actions;
export default searchSlice.reducer;