//GLOBAL - components from npm
import React from 'react';
import Lottie from 'react-lottie';

//STYLES
import './paperplane.scss';

//COMPONENTS


//SERVICES - api, conectors...


//GLOBAL STATE - redux, env...


//ASSETS - icons, images...
import animationData from './paperplane.json';

export default function Paperplane({
  className = '',
  fullPage,
  width = 120,
  height = 120
}) {

  //GENERAL
  const defaultOptions = {
    animationData: animationData,
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  //STATES


  //REDUX - Selectors


  //FUNCTIONS


  //USE EFFECTS


  return (
    <div className={`paperplane-container ${className} ${fullPage ? 'paperplane-container--full-page' : ''}`}>
      <Lottie
        options={defaultOptions}
        width={width}
        height={height}
      />
    </div>
  )
}
