//GLOBAL - components from npm
import React from "react";
import { useEffect, useState, useRef, Fragment } from "react";

// UTILS
import { Toast } from "../../../../utils/toast";

//STYLES
import "./case-historic.scss";

//COMPONENTS
import HistoryElement from "./HistoryElement";
import { Loading } from "./../../../../components/feedback";
import { FieldContainer, FilterTag } from "../../../../components/data-display";
import { Select, MultipleSelect } from "../../../../components/data-entry";
import { Typography } from "../../../../components/general";

//SERVICES - api, conectors...
import * as APIFiles from "../../../../utils/files";
import * as APICase from "../../../../services/api/case";
import * as APIUser from "../../../../services/api/task";

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as IconEmptyStateHistoric } from "../../../../assets/icons/EmptyStateHistoric.svg";
import { ReactComponent as IconEyeSlash } from "../../../../assets/icons/Eye-slash.svg";

export default function CaseHistoric({
  id,
  token,
  canBeVisualized,
  tabId,
  getCaseUpdate,
  updateHistoric,
}) {
  //GENERAL
  const listHistory = [
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: "whatsapp",
      id_canal: 1,
      nome_componente: "comunicado",
      tipo_solicitacao: "post",
      informacao_elemento: {
        id: 5,
        desc_comentario: "texto...",
        id_tipo_comentario: 1,
        filesAssociated: [
          {
            id_tarefa_comentario_arquivo: 217,
            path_tarefas_arquivos:
              "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
            data_insercao: "2022-01-04T14:00:03.326Z",
            data_exclusao: null,
          },
        ],
      },
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "prioridade",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "Baixa",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "contato",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "Allan Kardec Carvalho",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "prazo",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "2022-01-04T13:59:54.594Z",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "solicitação",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "Problemas",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "assunto",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "Teste - dev",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "descrição",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "Teste de descrição",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "prioridade",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "Alta",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "tarefa vinculada",
      tipo_solicitacao: "post",
      id_elemento: 1,
      nome_elemento: "Tarefa Teste",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "prioridade",
      tipo_solicitacao: "update",
      id_elemento: 1,
      nome_elemento: "Baixa",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "tarefa desvinculada",
      tipo_solicitacao: "post",
      id_elemento: 1,
      nome_elemento: "Tarefa Teste",
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "comentário",
      tipo_solicitacao: "delete",
      informacao_elemento: {
        id: 5,
        desc_comentario: "texto...",
        id_tipo_comentario: 1,
        filesAssociated: [
          {
            id_tarefa_comentario_arquivo: 217,
            path_tarefas_arquivos:
              "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
            data_insercao: "2022-01-04T14:00:03.326Z",
            data_exclusao: null,
          },
        ],
      },
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "comentário",
      tipo_solicitacao: "post",
      informacao_elemento: {
        id: 6,
        desc_comentario: "texto 2...",
        id_tipo_comentario: 1,
        filesAssociated: [],
      },
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "comentário",
      tipo_solicitacao: "post",
      informacao_elemento: {
        id: 6,
        desc_comentario: "teste com anexo",
        id_tipo_comentario: 1,
        filesAssociated: [
          {
            id_tarefa_comentario_arquivo: 217,
            path_tarefas_arquivos:
              "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
            data_insercao: "2022-01-04T14:00:03.326Z",
            data_exclusao: null,
          },
        ],
      },
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: null,
      id_canal: null,
      nome_componente: "comentário",
      tipo_solicitacao: "post",
      informacao_elemento: {
        id: 6,
        desc_comentario: "",
        id_tipo_comentario: 3,
        filesAssociated: [
          {
            id_tarefa_comentario_arquivo: 217,
            path_tarefas_arquivos:
              "/database/rec/ingdesk/20220110/09/audio_20220110095939.wav",
            data_insercao: "2022-01-04T14:00:03.326Z",
            data_exclusao: null,
          },
        ],
      },
    },
    {
      id_ticket: "87",
      id_usuario: 6,
      nome_usuario: "Glauber",
      dt_operacao: "2022-01-04T13:59:54.594Z",
      canal_comunicacao: "facebook",
      id_canal: 2,
      nome_componente: "comunicado",
      tipo_solicitacao: "post",
      informacao_elemento: {
        id: 6,
        desc_comentario: "",
        id_tipo_comentario: 3,
        filesAssociated: [
          {
            id_tarefa_comentario_arquivo: 217,
            path_tarefas_arquivos:
              "/database/rec/ingdesk/20220110/09/audio_20220110095939.wav",
            data_insercao: "2022-01-04T14:00:03.326Z",
            data_exclusao: null,
          },
        ],
      },
    },
  ];
  const ref = useRef();
  const refIndexComment = useRef();

  //STATES
  const [loading, setLoading] = useState(true);
  // const [newHistoryList, setNewHistoryList] = useState([]);
  // const [historyList, setHistoryList] = useState([]);
  const [historyListV2, setHistoryListV2] = useState([]);
  const [filteredHistoryList, setFilteredHistoryList] = useState([]);
  const [users, setUsers] = useState([]);
  const [fields, setFields] = useState([]);
  const [filters, setFilters] = useState([]);

  //REDUX - Selectors

  //FUNCTIONS

  // Função que faz a tela de histórico ficar em loading enquanto chama todas as outras funções que listam as informações que aparecem nela.
  // const getHistoricInfo = async () => {
  //   setLoading(true);
  //   // await getListHistoric();
  //   await getListUsers();
  //   await getListFields();
  //   await getHistoryListV2();
  //   //await fetchFile();
  //   setLoading(false);
  // };

  const getHistoryInfo = async () => {
    setLoading(true);

    try {
      await Promise.allSettled([
        getListUsers(),
        getListFields(),
        getHistoryListV2(),
        // getListHistoric(),
        // fetchFile()
      ])
    } catch (error) {
      console.log("Erro ao obter informações do histórico", error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchFile = async (arrayComments) => {
  //   for (const comment of arrayComments) {
  //     if (comment.informacao_elemento?.id_tipo_comentario) {
  //       const files = [];
  //       if (comment.informacao_elemento.filesAssociated.length > 0) {
  //         for (const file of comment.informacao_elemento.filesAssociated) {
  //           const response = await APIFiles.handleFiles(
  //             file.path_tarefas_arquivos
  //           );
  //           const fileObject = {
  //             file: {
  //               name: response.name,
  //               size: response.size,
  //               type: response.typeMime,
  //               path_arquivos: file.path_tarefas_arquivos,
  //               extension: response.type,
  //               id: file.id_tarefa_comentario_arquivo,
  //             },
  //           };

  //           files.push(fileObject);
  //         }
  //       }
  //       comment.files = files;
  //     }
  //   }
  //   setNewHistoryList(arrayComments);
  //   //return arrayComments
  // };

  //Função que busca a lista do histórico na API.
  // const getListHistoric = async () => {
  //   const response = await APICase.getListHistoric(token, id);

  //   if (response.status === 200) {
  //     setHistoryList(response.data);
  //   } else {
  //     Toast(
  //       "Ops... algo deu errado.",
  //       "Verifique sua conexão e tente novamente mais tarde",
  //       "error"
  //     );
  //   }
  // };

  const getHistoryListV2 = async () => {
    const response = await APICase.getHistoryListV2(token, id);

    if (response.status === 200) {
      setHistoryListV2(response.data);
    } else {
      Toast(
        "Ops... algo deu errado!",
        "Verifique sua conexão e tente novamente mais tarde.",
        "error"
      );
    }
  };

  //Função que busca a lista de Agentes na API e transforma no modelo padrão dos componentes.
  const getListUsers = async () => {
    const response = await APIUser.listUsers(token);

    if (response.status === 200) {
      const arrayUsers = [];
      for (const item of response.data) {
        const object = {
          id: item.id_usuario,
          label: item.nome_usuario,
          caption1: item.nome_organizacao,
          type: "Agentes",
        };
        arrayUsers.push(object);
      }
      setUsers(arrayUsers);
    } else {
      Toast(
        "Ops... algo deu errado.",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que busca a lista dos campos do histórico na API e transforma no modelo padrão dos componentes.
  const getListFields = async () => {
    const response = await APICase.getHistoryFieldsList(token);

    if (response.status === 200) {
      const arrayFields = [];
      for (const item of response.data) {
        if (item.coluna_associada) {
          const object = {
            id: item.id_campo,
            label: item.descricao_campo,
            type: "Campos",
          };
          arrayFields.push(object);
        }
      }
      setFields(arrayFields);
    } else {
      Toast(
        "Ops... algo deu errado.",
        "Verifique sua conexão e tente novamente mais tarde",
        "error"
      );
    }
  };

  //Função que verifica quando o último elemento da lista é renderizado.
  const handleIndex = (index) => {
    if (index === historyListV2.length - 1) {
      refIndexComment.current = index;
    }
  };

  //USE EFFECTS
  useEffect(() => {
    // getHistoricInfo();
    getHistoryInfo();
  }, []);

  useEffect(() => {
    if (tabId === 6 && getCaseUpdate) {
      // getHistoricInfo();
      getHistoryInfo();
      updateHistoric(false);
    }
  }, [tabId, getCaseUpdate]);

  useEffect(() => {
    if (!loading && refIndexComment.current === historyListV2.length - 1) {
      ref.current?.scrollTo({ top: ref.current.scrollHeight });
    }
  }, [loading, refIndexComment.current, filteredHistoryList]);

  //useEffect que verifica se tem filtro e caso tenha, verifica todos os itens da lista de histórico e todos os tipos de filtros.
  // Ex: caso o tipo seja "agentes" ele verifica se o nome dos itens do histórico são iguais ao filtro de agente aplicado, caso seja adiciona o item ao novo array da lista do histórico
  // caso não seja ele passa e vai verificar outro item.
  useEffect(() => {
    if (filters.length > 0) {
      const arrayHistory = [];

      for (const filter of filters) {
        for (const item of historyListV2) {
          if (filter.type === "Agentes" && item.nome_usuario === filter.label) {
            arrayHistory.push(item);
          } else if (
            filter.type === "Campos" &&
            item.descricao_campo === filter.label
          ) {
            arrayHistory.push(item);
          } else if (filter.amount && filter.type === "Agentes") {
            for (const agent of filter.filters) {
              if (item.nome_usuario === agent.label) {
                arrayHistory.push(item);
              }
            }
          }
        }
      }

      arrayHistory.sort(
        (a, b) => new Date(a.dt_operacao) - new Date(b.dt_operacao)
      );

      setFilteredHistoryList(arrayHistory);
    } else {
      const arrayHistory = [...historyListV2];

      arrayHistory.sort(
        (a, b) => new Date(a.dt_operacao) - new Date(b.dt_operacao)
      );

      setFilteredHistoryList(arrayHistory);
    }
  }, [filters, historyListV2]);

  //useEffect que verifica se o filtro de agente tem tamanho maior que 5 e transforma todos esses itens em apenas 1 e coloca a quantidade de elementos que possue dentro dele na nova informação "amount"
  // e passa todos os elementos para o campo chamado filters.
  // caso n seja maior que 5, mas já possua o campo "amount" ele aumenta a quantidade desse campo e acrescenta o novo filtro no campo filters.
  useEffect(() => {
    const newFilters = [];

    const filtersAgent = filters.filter((item) => item.type === "Agentes");

    if (filtersAgent.length > 5) {
      const otherFilters = filters.filter((item) => item.type !== "Agentes");

      newFilters.push(...otherFilters, {
        type: "Agentes",
        label: "Agentes",
        amount: filtersAgent.length,
        filters: filtersAgent,
      });

      setFilters(newFilters);
    } else if (filtersAgent.length > 1 && filtersAgent[0].amount) {
      const otherFilters = filters.filter((item) => item.type !== "Agentes");
      const newAgent = filtersAgent.find((item) => !item.amount);
      const oldAgent = filtersAgent.find((item) => item.amount);

      oldAgent.filters.push(newAgent);
      newFilters.push(...otherFilters, {
        ...oldAgent,
        amount: oldAgent.filters.length,
      });

      setFilters(newFilters);
    }
  }, [filters]);

  return (
    <div className="case-historic">
      {loading ? (
        <Loading.Paperplane />
      ) : (
        <>
          {canBeVisualized && (
            <div className="case-historic-filters">
              <div className="case-historic-filters__selects">
                <FieldContainer title="Filtrar por agente:">
                  <MultipleSelect
                    width="100%"
                    placeholder="Selecionar agente"
                    value={null}
                    options={users}
                    optionsActive={
                      filters.find(
                        (item) => item.amount && item.type === "Agentes"
                      )
                        ? filters.find(
                          (item) => item.amount && item.type === "Agentes"
                        ).filters
                        : filters
                    }
                    showAvatar={true}
                    onSelect={(option) => {
                      setFilters([...filters, option]);
                    }}
                  />
                </FieldContainer>
                <FieldContainer title="Filtrar por alterações:">
                  <MultipleSelect
                    className="case-historic-filters-campos"
                    value={null}
                    options={fields}
                    optionsActive={filters}
                    showAvatar={false}
                    onSelect={(option) => {
                      setFilters([...filters, option]);
                    }}
                    width="100%"
                    placeholder="Selecionar opção"
                  />
                </FieldContainer>
              </div>
              {filters.length > 0 && (
                <div className="case-historic-filters__tags">
                  {filters.map((filter, index) => (
                    <FilterTag
                      key={index}
                      label={filter.label}
                      amount={filter.amount ? filter.amount : ""}
                      amountLabel={filter.amount ? filter.type : ""}
                      profile={filter.type === "Agentes" ? true : false}
                      onClose={() => {
                        const newFilters = [...filters];
                        newFilters.splice(index, 1);
                        setFilters(newFilters);
                      }}
                    ></FilterTag>
                  ))}
                </div>
              )}
            </div>
          )}
          <div ref={ref} className="case-historic__list">
            {filteredHistoryList.length > 0 && canBeVisualized ? (
              <>
                {filteredHistoryList.map((item, index) => (
                  <Fragment key={index}>
                    <HistoryElement content={item} />
                    {handleIndex(index)}
                  </Fragment>
                ))}
              </>
            ) : (
              <div className="case-historic-empty-state">
                {canBeVisualized ? (
                  <IconEmptyStateHistoric />
                ) : (
                  <div className="container-icon">
                    <IconEyeSlash />
                  </div>
                )}
                <Typography.H4 color="gray-400">
                  {canBeVisualized
                    ? "Não encontramos nenhum histórico."
                    : "Você não tem permissão para visualizar o histórico."}
                </Typography.H4>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
