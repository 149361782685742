import instance from "./configAxios";

export async function listMimetypes(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.get(`/list-mimetypes`, config);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}
