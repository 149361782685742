//GLOBAL - components from npm
import React from "react";

//STYLES
import "./channel.scss";

//COMPONENTS
import { Tippy } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...
import { ReactComponent as PhoneIcon } from "./../../../assets/icons/medias/Phone-Ativo.svg";
import { ReactComponent as PhoneIconDes } from "./../../../assets/icons/medias/Phone-Desativo.svg";
import { ReactComponent as EmailIcon } from "./../../../assets/icons/medias/Email-Ativo.svg";
import { ReactComponent as EmailIconDes } from "./../../../assets/icons/medias/Email-Desativo.svg";
import { ReactComponent as FacebookIcon } from "./../../../assets/icons/medias/Facebook-Ativo.svg";
import { ReactComponent as FacebookIconDes } from "./../../../assets/icons/medias/Facebook-Desativo.svg";
import { ReactComponent as InstagramIcon } from "./../../../assets/icons/medias/Instagram-Ativo.svg";
import { ReactComponent as InstagramIconDes } from "./../../../assets/icons/medias/Instagram-Desativo.svg";
import { ReactComponent as SuporteIcon } from "./../../../assets/icons/medias/Suporte.svg";
import { ReactComponent as WebchatIcon } from "./../../../assets/icons/medias/Webchat.svg";
import { ReactComponent as WebchatIconDes } from "./../../../assets/icons/medias/Webchat-Desativo.svg";
import { ReactComponent as TelegramIcon } from "./../../../assets/icons/medias/Telegram-Ativo.svg";
import { ReactComponent as TelegramIconDes } from "./../../../assets/icons/medias/Telegram-Desativo.svg";
import { ReactComponent as TwitterIcon } from "./../../../assets/icons/medias/Twitter-Ativo.svg";
import { ReactComponent as TwitterIconDes } from "./../../../assets/icons/medias/Twitter-Desativo.svg";
import { ReactComponent as WhatsappIcon } from "./../../../assets/icons/medias/Whatsapp-Ativo.svg";
import { ReactComponent as WhatsappIconDes } from "./../../../assets/icons/medias/Whatsapp-Desativo.svg";
import { ReactComponent as WebIcon } from "./../../../assets/icons/medias/Web.svg";
import { ReactComponent as GoogleGBMIcon } from "./../../../assets/icons/medias/GoogleGBM.svg";
import { ReactComponent as SMSIcon } from "./../../../assets/icons/medias/SMS.svg";
import { ReactComponent as ReclameAquiIcon } from "./../../../assets/icons/medias/Reclame-Aqui.svg";
import { ReactComponent as PesquisaSatisfacaoIcon } from "./../../../assets/icons/medias/Pesquisa-satisfacao.svg";

export default function Channel({
  className = "",
  media = 1,
  tippy = true,
  tippyContent,
  customTippy,
  disabled,
  onClick,
  nameMedia = "telefone", // 'telefone' || 'whatsapp' || 'email' || 'telegram' || 'instagram' || 'twitter' || 'suporte'
}) {
  //GENERAL

  // console.log("media", media)

  //STATES
  const [activeIcon, setActiveIcon] = React.useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function renderIcon() {
    switch (media) {
      case 1:
        return (
          <div
            className={`channel channel--secondary-03-dark 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <PhoneIconDes /> : <PhoneIcon />}
          </div>
        );
      case 5:
        return (
          <div
            className={`channel channel--green 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <WhatsappIconDes /> : <WhatsappIcon />}
          </div>
        );

      case 7:
        return (
          <div
            className={`channel channel--yellow 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <EmailIconDes /> : <EmailIcon />}
          </div>
        );

      case 8:
        return (
          <div
            className={`channel channel--primary-dark 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <TelegramIconDes /> : <TelegramIcon />}
          </div>
        );

      case 6:
        return (
          <div
            className={`channel channel--orange 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <InstagramIconDes /> : <InstagramIcon />}
          </div>
        );

      case 9:
        return (
          <div
            className={`channel channel--blue-selected-1 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <TwitterIconDes /> : <TwitterIcon />}
          </div>
        );

      case 4:
        return (
          <div
            className={`channel channel--primary-light 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <FacebookIconDes /> : <FacebookIcon />}
          </div>
        );

      case 10:
        return (
          <div
            className={`channel channel--primary-dark 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            <SuporteIcon />
          </div>
        );

      case 3:
        return (
          <div
            className={`channel channel--secondary-04-dark
             ${className} 
             ${media ? `channel--media-${media}` : ""} 
             ${disabled ? "channel--disabled" : ""} 
             ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            <WebIcon />
          </div>
        );

      case 11:
        return (
          <div
            className={`channel channel--primary-light 
              ${className}
              ${media ? `channel--media-${media}` : ""}
              ${disabled ? "channel--disabled" : ""}
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            <GoogleGBMIcon />
          </div>
        );

      case 12:
        return (
          <div
            className={`channel channel--primary-light 
              ${className}
              ${media ? `channel--media-${media}` : ""}
              ${disabled ? "channel--disabled" : ""}
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            <SMSIcon />
          </div>
        );

      case 13:
        return (
          <div
            className={`channel channel--primary-light 
              ${className}
              ${media ? `channel--media-${media}` : ""}
              ${disabled ? "channel--disabled" : ""}
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            <ReclameAquiIcon />
          </div>
        );
      case 14:
        return (
          <div
            className={`channel channel--primary-light 
              ${className}
              ${media ? `channel--media-${media}` : ""}
              ${disabled ? "channel--disabled" : ""}
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            <PesquisaSatisfacaoIcon />
          </div>
        );
      default:
        return (
          <div
            className={`channel channel--primary-dark 
              ${className} 
              ${media ? `channel--media-${media}` : ""} 
              ${disabled ? "channel--disabled" : ""} 
              ${onClick ? "channel--interactive" : ""}`}
            onClick={onClick}
          >
            {disabled ? <WebchatIconDes /> : <WebchatIcon />}
          </div>
        );
    }
  }

  //USE EFFECTS

  return (
    <Tippy
      disabled={!tippy}
      content={
        customTippy
          ? customTippy
          : tippyContent
          ? tippyContent
          : capitalizeFirstLetter(nameMedia)
      }
      theme={customTippy ? "popover-default" : "default"}
      interactive={customTippy}
    >
      {renderIcon()}
    </Tippy>
  );
}
