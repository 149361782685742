import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Modal } from "./../../../feedback";
import { Button, Typography } from "../../../general";
import { Input } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";
import Loading from "../../Loading";

import * as APIMotive from "./../../../../services/api/motives";

import { ReactComponent as IconSearch } from "./../../../../assets/icons/Search-normal.svg";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/Close02.svg";

import "./manage-motives.scss";

export default function ManageMotives({ isOpen, onClose, getMotivesList, motivesData, setMotivesData }) {
    const token = useSelector((state) => state.auth.token);

    const [searchMotive, setSearchMotive] = useState("");
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [value] = useState(() => { return { desc_motivo: "" } });
    const [valueMotive, setValueMotive] = useState({ ...value });

    let filterMotives = motivesData?.filter((value) => {
        return value.desc_motivo.toLowerCase().includes(searchMotive.toLowerCase())
    });

    function onCloseCustom() {
        onClose();
        setValueMotive({ ...valueMotive, desc_motivo: "" });
    };

    async function handleKeyDown(val) {
        if (val.key !== "Enter") return;

        const inputValue = val.target.value;

        if (!inputValue.trim()) return;

        let index = motivesData?.findIndex(value => value.desc_motivo === inputValue);

        if (index < 0) {
            setMotivesData([...motivesData, {
                id_motivo: null,
                desc_motivo: inputValue
            }]);

            const response = await APIMotive.createMotive(token, valueMotive);

            if (response.status === 200) {
                getMotivesList();
            } else {
                Toast(
                    "Não foi possível adicionar o motivo",
                    "Tente novamente mais tarde!",
                    "error"
                )
            }

            setValueMotive({ ...valueMotive, desc_motivo: "" });
        } else {
            val.target.value = "";

            Toast(
                "Não foi possível adicionar o motivo!",
                "Esse motivo ja existe, adicione outro com o nome diferente",
                "warn"
            );
        };
    };

    async function deleteMotive(idMotive) {
        const response = await APIMotive.deleteMotive(idMotive, token);

        if (response.status === 200) {
            setMotivesData(motivesData?.filter((motive) =>
                motive.id_motivo !== idMotive
            ));

            getMotivesList();
        } else {
            Toast(
                "Não foi possível deletar o Motivo",
                "Tente novamente mais tarde!",
                "error"
            )
        }
    };

    async function handleCreateMotive() {
        setPageStatus("Saved-successfully");

        const response = await APIMotive.createMotive(token, valueMotive);

        if (response.status === 200) {
            Toast("Motivo adicionado!", "Motivo adicionado com sucesso.", "success");
            getMotivesList();
            onCloseCustom();
        } else {
            Toast(
                "Não foi possível adicionar o Motivo",
                "Tente novamente mais tarde!",
                "error"
            )
        }

        setPageStatus("awaiting");
    };

    function renderMessage() {
        setPageStatus("Saved-successfully");

        onCloseCustom();

        Toast(
            "Salvo",
            "Salvo com sucesso!",
            "success"
        );

        setPageStatus("awaiting");
    };

    return (
        <Modal.Main
            className="modal-manage-motives"
            title="Gerenciar motivos"
            width="1001px"
            height="660px"
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <div className="container-footer">
                    <Button
                        className="modal-manage-motives__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "Saved-successfully"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-manage-motives__btn-confirm"
                        disabled={pageStatus === "Saved-successfully"}
                        onClick={renderMessage}
                    >
                        {pageStatus !== "Saved-successfully" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </div>
            }
        >
            <section className="modal-manage-motives__content">
                <Input
                    type="text"
                    className="input-search-motives"
                    placeholder="Buscar"
                    onChange={(event) => setSearchMotive(event)}
                    rightIcon={
                        <button>
                            <IconSearch />
                        </button>
                    }
                />

                <div className="rectangle-motives">

                    <div className="container-motives">
                        <ul className="container-motives__tags">
                            {
                                filterMotives?.map((motive) => (
                                    <div
                                        key={motive.id_motivo}
                                        className="tag-item"
                                        style={{ alignItems: "center", marginBottom: "4px" }}
                                    >
                                        <span className="text">{motive.desc_motivo}</span>
                                        <span className="close" onClick={() => deleteMotive(motive.id_motivo)}>
                                            <CloseIcon />
                                        </span>
                                    </div>
                                ))
                            }

                            <input
                                type="text"
                                className="tags-input"
                                placeholder="Adicionar motivo"
                                value={valueMotive.desc_motivo}
                                onKeyDown={(event) => handleKeyDown(event)}
                                onChange={(event) => setValueMotive({
                                    ...valueMotive,
                                    desc_motivo: event.target.value
                                })}
                            />
                        </ul>
                    </div>

                </div>
            </section>
        </Modal.Main>
    )
}






