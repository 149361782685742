//GLOBAL - components from npm
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

//UTILS
import getLocalStorage from "./../../../utils/getLocalStorage";

//STYLES
import "./card-dashboard.scss";

//COMPONENTS
import CardCampaign from "./CardCampaign";
import CardResolution from "./CardResolution";
import CardPrimary from "./CardPrimary";
import CardActivity from "./CardActivity";
import CardVaried from "./CardVaried";
import CardSolicited from "./CardSolicited";
import CardPerfomance from "./CardPerfomance";
import StatusCard from "./StatusCard";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as EllipseYellow } from "../../../assets/icons/EllipseYellow.svg";
import { ReactComponent as EllipseOrange } from "../../../assets/icons/EllipseOrange.svg";
import { ReactComponent as EllipseGreen } from "../../../assets/icons/EllipseGreen.svg";
import { ReactComponent as SlaImage } from "../../../assets/images/sla-image.svg";
import { ReactComponent as EscalateImage } from "../../../assets/images/escalate-image.svg";
import { ReactComponent as SlaGoalCases } from "../../../assets/images/sla-goal.svg";
import { ReactComponent as ResolutionCases } from "../../../assets/images/resolution.svg";

export default function CardDashboard({
  card,
  setPage = () => null,
  dataEdit,
  openModal,
  cardPerRow
}) {
  //GENERAL
  const darkMode = useSelector((state) => state.filters.darkMode);

  //STATES

  //REDUX - Selectors

  //FUNCTIONS
  const showIconType = (type) => {
    switch (type) {
      case "conclusedCases":
        return <EllipseGreen />;

      case "progressCases":
        return <EllipseYellow />;

      case "makingCases":
        return <EllipseOrange />;

      case "escalateCases":
        return <EscalateImage />;

      case "slaCases":
        return <SlaImage />;

      case "slaGoalCases":
        return <SlaGoalCases />;

      case "resolutionCases":
        return <ResolutionCases />;

      default:
        break;
    }
  };

  const handleColor = (type) => {
    switch (type) {
      case 3:
        return ["#008908", "rgba(96, 214, 106, 0.35)"];

      case 1:
        return ["#455CC7", "rgba(69, 92, 199, 0.4)"];

      case 2:
        return ["#F2BD00", "rgba(242, 189, 0, 0.4)"];

      case 5:
        return ["#E27121", "rgba(226, 113, 33, 0.35)"];

      case 4:
        return ["#757575", "rgba(117, 117, 117, 0.4)"];

      case 6:
        return ["#1E88E5", "rgba(30, 136, 229, 0.35)"];

      case "slaCases":
        return ["#C90000", "rgba(255, 90, 90, 0.35)"];

      // case "slaGoalCases":
      //   return <SlaGoalCases />;

      // case "resolutionCases":
      //   return <ResolutionCases />;

      default:
        break;
    }
  };

  return (
    <>
      {card.typeCard === 0 && (
        <CardPrimary
          dynamicCard
          key={card.title}
          card={card}
          darkMode={darkMode}
          onClick={setPage}
          chooseColors={handleColor}
          cardPerRow={cardPerRow}
        />
      )}
      {card.typeCard === 1 && (
        <CardSolicited
          key={card.title}
          card={card}
          darkMode={darkMode}
          onClick={setPage}
        />
      )}
      {card.typeCard === 2 && (
        <CardVaried
          key={card.title}
          card={card}
          showIconType={showIconType}
          darkMode={darkMode}
          onClick={setPage}
        />
      )}
      {card.typeCard === 3 && (
        <CardResolution
          key={card.title}
          card={card}
          openModal={openModal}
          darkMode={darkMode}
        />
      )}
      {card.typeCard === 4 && (
        <CardActivity
          key={card.title}
          card={card}
          darkMode={darkMode}
          data={dataEdit}
        />
      )}
      {card.typeCard === 5 && (
        <CardPerfomance key={card.title} card={card} darkMode={darkMode} />
      )}
      {card.typeCard === 6 && (
        <StatusCard
          key={card.title}
          card={card}
          darkMode={darkMode}
        />
      )}
      {/* {card.typeCard === 6 && <CardCampaign card={card} darkMode={darkMode} />} */}
    </>
  );
}
