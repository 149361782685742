import instance from "./configAxios";

export async function createEnterprise(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/create-enterprise', data, config);

        return response;
    } catch (error) {
        return error
    }
};

export async function updateEnterprise(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/update-enterprise', data, config);
        return response;
    } catch (error) {
        return error;
    }
};

export async function deleteCompany(idCompany, token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.delete(`/delete-company/${idCompany}`, config);

        return response;
    } catch (error) {
        return error;
    }
};

export async function createContact(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.post('/create-contact', data, config);

        return response;
    } catch (error) {
        return error
    }
};

export async function updateContact(id, token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.put(`/update-contact/${id}`, data, config);
        return response;
    } catch (error) {
        return error;
    }
};

export async function deleteContact(idContact, token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const response = await instance.delete(`/delete-contact/${idContact}`, config);

        return response;
    } catch (error) {
        return error;
    }
}

// ########## BUSCAR CONTATOS VICULADOS A UMA EMPRESA ##########
export async function getUsersLinkedToCompany(companyId, token) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = {
            id_empresa: companyId
        }

        const response = await instance.post(`/buscar-contatos-empresa`, data, config);

        return response;
    } catch (error) {
        return error;
    }
}