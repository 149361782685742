import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode";

export async function listSla(token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get('/list-sla', config);

        for (const item of response.data) {
            item.nome_organizacao = unicodeToChar(item.nome_organizacao);
        }

        return response;
    } catch (error) {
        return error.message;
    }
};

export async function listTypesSla(idTypeSla, token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/ist-reasons-to-type/${idTypeSla}`, config);

        return response;
    } catch (error) {
        return error;
    }
};

export async function listWorkingHoursHolidays(idWorkingHoliday, token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get(`/ist-worktimes-and-holidays-sl/${idWorkingHoliday}`, config);

        return response;
    } catch (error) {
        return error;
    }
};

export async function listOrganizations(token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get('/list-organization', config);

        for (const item of response.data) {
            item.nome = unicodeToChar(item.nome);
        }

        return response;
    } catch (error) {
        return error;
    }
};

export async function typeSla(token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get('/list-type-sla', config);

        for (const item of response.data) {
            item.descricao = unicodeToChar(item.descricao);
        }

        return response;
    } catch (error) {
        return error;
    }
};

export async function searchSla(token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.get('/get-sla', config);

        return response;
    } catch (error) {
        return error;
    }
};

export async function createSla(token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.post('/create-sla', data, config);

        return response;
    } catch (error) {
        return error
    }
};

export async function updateSla(id, token, data) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.put(`/update-sla/${id}`, data, config);

        return response
    } catch (error) {
        return error;
    }
};

export async function deleteSla(idSla, token) {
    try {
        const config = {
            headers: {
                "x-access-token": token,
                "Content-Type": "application/json"
            },
        };

        const response = await instance.delete(`/delete-sla/${idSla}`, config);

        return response;
    } catch (error) {
        return error;
    }
};


export async function getSlaSchedules(token, slaId) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        const data = { id_sla: slaId }

        const response = await instance.post('/buscar-horarios-sla', data, config);

        return response;
    } catch (error) {
        console.log('getSlaSchedules -> Error en el servidor:', error);

        return error
    }
}

export async function createSlaSchedules(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = dia_semana, hora_inicio, hora_fim, id_sla

        const response = await instance.post('/insert-horarios-sla', data, config);

        return response;
    } catch (error) {
        console.log('createSlaSchedules -> Error en el servidor:', error);

        return error
    }
}

export async function updateSlaSchedules(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = dia_semana, hora_inicio, hora_fim, id_sla, id_sla_horario

        const response = await instance.put('/update-horarios-sla', data, config);

        return response;
    } catch (error) {
        console.log('updateSlaSchedules -> Error en el servidor:', error);

        return error
    }
}

export async function deleteSlaSchedules(token, data) {
    try {
        const config = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            }
        }

        // data = id_sla_horario, id_sla, id_usuario

        const response = await instance.put('/delete-horarios-sla', data, config);

        return response;
    } catch (error) {
        console.log('deleteSlaSchedules -> Error en el servidor:', error);

        return error
    }
}