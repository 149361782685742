//GLOBAL - components from npm
import React, { useState, useEffect, useCallback } from "react";
import urlIngWeb from "../../../utils/env";
//import axios from 'axios';

//STYLES
import "./comment-image.scss";

//COMPONENTS
import { Typography } from "../../general";
import { Tippy, Modal } from "../../feedback";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images..
import { ReactComponent as IconClose } from "../../../assets/icons/Close-Circle.svg";
import { ReactComponent as IconDownload } from "../../../assets/icons/Document-download.svg";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/ArrowRight.svg";

export default function CommentImage({
  className,
  content = [],
  linkImg = "",
}) {
  //GENERAL

  //STATES
  const [openGallery, setOpenGallery] = useState(false);
  const [indexModal, setIndexModal] = useState(0);

  //REDUX - Selectors

  //FUNCTIONS
  const handlePropag = (event) => {
    event.stopPropagation();
  };

  const onDownload = (index) => {
    fetch(`${urlIngWeb + content[index].file.path_arquivos}`).then(
      (response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = content[index].file.name;
          a.click();
        });
      }
    );
  };

  const decreaseValue = useCallback(() => {
    if (indexModal > 0) {
      return setIndexModal((value) => value - 1);
    } else {
      return;
    }
  });

  const increaseValue = useCallback(() => {
    if (indexModal < content.length - 1) {
      setIndexModal((value) => value + 1);
    } else {
      return;
    }
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        decreaseValue();
      } else if (e.key === "ArrowRight") {
        increaseValue();
      } else {
        return;
      }
    },
    [decreaseValue, increaseValue]
  );

  const renderContent = () => {
    if (content.length === 1 || linkImg) {
      return (
        <div className="comment-image-container">
          <img
            onClick={() => {
              setOpenGallery(true);
              setIndexModal(linkImg ? linkImg : 0);
            }}
            src={linkImg ? linkImg : urlIngWeb + content[0].file.path_arquivos}
            height={301}
            alt={content[0].name}
          />
        </div>
      );
    } else if (content.length === 2) {
      return (
        <div className="comment-image-container row-divider">
          <img
            onClick={() => {
              setOpenGallery(true);
              setIndexModal(0);
            }}
            src={urlIngWeb + content[0].file.path_arquivos}
            height={143}
            alt={content[0].name}
          />
          <img
            onClick={() => {
              setOpenGallery(true);
              setIndexModal(1);
            }}
            src={urlIngWeb + content[1].file.path_arquivos}
            height={143}
            alt={content[1].name}
          />
        </div>
      );
    } else if (content.length === 3) {
      return (
        <div className="comment-image-container row-divider">
          <img
            onClick={() => {
              setOpenGallery(true);
              setIndexModal(0);
            }}
            src={urlIngWeb + content[0].file.path_arquivos}
            height={143}
            alt={content[0].name}
          />
          <img
            src={urlIngWeb + content[1].file.path_arquivos}
            height={143}
            alt={content[1].name}
          />

          <div
            className="comment-image-container__quantity"
            onClick={() => {
              setOpenGallery(true);
              setIndexModal(1);
            }}
          >
            <Typography.H1
              className={"lenght-content"}
              color={"white"}
              weight="bold"
            >
              + 2
            </Typography.H1>
          </div>
        </div>
      );
    } else if (content.length === 4) {
      return (
        <div className="comment-image-container row-divider">
          <div className="column-divider">
            <img
              onClick={() => {
                setOpenGallery(true);
                setIndexModal(0);
              }}
              src={urlIngWeb + content[0].file.path_arquivos}
              height={143}
              alt={content[0].name}
            />
            <img
              onClick={() => {
                setOpenGallery(true);
                setIndexModal(1);
              }}
              src={urlIngWeb + content[1].file.path_arquivos}
              height={143}
              alt={content[1].name}
            />
          </div>
          <div className="column-divider">
            <img
              onClick={() => {
                setOpenGallery(true);
                setIndexModal(2);
              }}
              src={urlIngWeb + content[2].file.path_arquivos}
              height={143}
              alt={content[2].name}
            />
            <img
              onClick={() => {
                setOpenGallery(true);
                setIndexModal(3);
              }}
              src={urlIngWeb + content[3].file.path_arquivos}
              height={143}
              alt={content[3].name}
            />
          </div>
        </div>
      );
    } else if (content.length > 4) {
      return (
        <div className="comment-image-container row-divider">
          <div className="column-divider">
            <img
              onClick={() => {
                setOpenGallery(true);
                setIndexModal(0);
              }}
              src={urlIngWeb + content[0].file.path_arquivos}
              height={143}
              alt={content[0].name}
            />
            <img
              onClick={() => {
                setOpenGallery(true);
                setIndexModal(1);
              }}
              src={urlIngWeb + content[1].file.path_arquivos}
              height={143}
              alt={content[1].name}
            />
          </div>
          <div className="column-divider">
            <img
              onClick={() => {
                setOpenGallery(true);
                setIndexModal(2);
              }}
              src={urlIngWeb + content[2].file.path_arquivos}
              height={143}
              alt={content[2].name}
            />
            <img
              src={urlIngWeb + content[3].file.path_arquivos}
              height={143}
              alt={content[3].name}
            />
          </div>

          <div
            onClick={() => {
              setOpenGallery(true);
              setIndexModal(3);
            }}
            className="comment-image-container__quantity more-four"
          >
            <Typography.H1
              className={"lenght-content"}
              color={"white"}
              weight="bold"
            >
              + {content.length - 3}
            </Typography.H1>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", keyPress);
    return () => document.removeEventListener("keyup", keyPress);
  }, [keyPress]);

  return (
    <>
      {renderContent()}

      {openGallery && (
        <div
          className={`modal-media ${!openGallery ? "hidden" : "visible"}`}
          onClick={() => setOpenGallery(false)}
        >
          <div className={"modal-media__container"}>
            <div className="modal-media__header">
              <button
                className="btn-download"
                onClick={(e) => {
                  handlePropag(e);
                  onDownload(indexModal);
                }}
              >
                <IconDownload />
                Download
              </button>
              <IconClose className="modal-icon_close" />
            </div>

            <div className="modal-media__body">
              <button
                className="btn-modal-image"
                disabled={
                  content.length === 1 || content[0] === content[indexModal]
                    ? true
                    : false
                }
                onClick={(e) => handlePropag(e)}
              >
                <IconArrowLeft
                  onClick={() => decreaseValue()}
                  className={`modal-image__icon ${
                    content.length === 1 || content[0] === content[indexModal]
                      ? "disabled"
                      : ""
                  }`}
                />
              </button>

              <div className="fit-container" onClick={(e) => handlePropag(e)}>
                <img
                  src={
                    linkImg
                      ? linkImg
                      : urlIngWeb + content[indexModal]?.file.path_arquivos
                  }
                  alt="Imagem do modal"
                />
              </div>

              <button
                className="btn-modal-image"
                disabled={
                  content.length === 1 ||
                  content[content.length - 1] === content[indexModal]
                    ? true
                    : false
                }
                onClick={(e) => handlePropag(e)}
              >
                <IconArrowRight
                  onClick={() => increaseValue()}
                  className={`modal-image__icon ${
                    content.length === 1 ||
                    content[content.length - 1] === content[indexModal]
                      ? "disabled"
                      : ""
                  }`}
                />
              </button>
            </div>

            <div className="modal-media__footer">
              <div
                className="footer-img__carrousel"
                onClick={(e) => handlePropag(e)}
              >
                {content.map((fileImg, index) => (
                  <div
                    key={index}
                    className={`img-footer ${
                      indexModal === index ? "img-choose" : ""
                    }`}
                  >
                    <img
                      onClick={() => setIndexModal(index)}
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      src={
                        linkImg
                          ? linkImg
                          : urlIngWeb + fileImg.file.path_arquivos
                      }
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <Modal.Main
        title=""
        animated
        width="50%"
        isOpen={openGallery}
        onClose={() => setOpenGallery(false)}
        footer={
          <div className="footer-img">
            <div className="footer-img__carrousel">
              {content.map((fileImg, index) => (
                <img
                  key={index}
                  onClick={() => setIndexModal(index)}
                  style={{ width: 50, height: 50, cursor: "pointer" }}
                  src={urlIngWeb + fileImg.file.path_arquivos}
                  alt=""
                />
              ))}
            </div>

            <button onClick={() => onDownload(indexModal)}>Download</button>
          </div>
        }
      >
        <>
          <button
            className="btn-modal-image"
            disabled={
              content.length === 1 || content[0] === content[indexModal]
                ? true
                : false
            }
          >
            <IconArrowLeft
              onClick={() => decreaseValue()}
              className={`modal-image__icon ${
                content.length === 1 || content[0] === content[indexModal]
                  ? "disabled"
                  : ""
              }`}
            />
          </button>

          <div className="fit-container">
            <img
              src={urlIngWeb + content[indexModal]?.file.path_arquivos}
              alt="Imagem do modal"
            />
          </div>

          <button
            className="btn-modal-image"
            disabled={
              content.length === 1 ||
              content[content.length - 1] === content[indexModal]
                ? true
                : false
            }
          >
            <IconArrowRight
              onClick={() => increaseValue()}
              className={`modal-image__icon ${
                content.length === 1 ||
                content[content.length - 1] === content[indexModal]
                  ? "disabled"
                  : ""
              }`}
            />
          </button>
        </>
      </Modal.Main> */}
    </>
  );
}
