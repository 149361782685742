import instance from "./configAxios";
import unicodeToChar from "../../utils/formatUnicode";

export async function all(token, page, title, limit, filtersContacts) {
  try {
    let config = {};

    if (filtersContacts && filtersContacts.active === true) {
      config = {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        },
        params: {
          page,
          limit: page && !limit ? 100 : limit,
          title: filtersContacts.name,
          email: filtersContacts.email,
          extern_code: filtersContacts.externcode,
          phone: filtersContacts.telephone,
          id_enterprise: filtersContacts.id_enterprise
        }
      }
    } else {
      config = {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        },
        params: {
          page,
          limit: page && !limit ? 100 : limit,
          title: title ? title : '',
        }
      }
    }

    let response = [];

    if (filtersContacts.active)
      response = await instance.get(`/list-companys-filter`, config);
    else
      response = await instance.get(`/list-companys`, config);

    for (const item of response.data) {
      item.nome_contato = unicodeToChar(item.nome_contato)
    }

    return response
  } catch (error) {
    return error;
  }
}

export async function getListCompanysEmails(token, page, title, limit) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      params: {
        page,
        limit: page && !limit ? 100 : limit,
        title: title ? title : "",
      },
    };

    const response = await instance.get('/list-companys-email', config);

    for (const item of response.data) {
      item.nome_contato = unicodeToChar(item.nome_contato);
    }

    return response;
  } catch (error) {
    return error.message;
  }
}

// export async function all(token, page, title, limit) {
//   try {
//     const config = {
//       headers: {
//         "x-access-token": token,
//         "Content-Type": "application/json",
//       },
//       params: {
//         page,
//         limit: page && !limit ? 100 : limit,
//         title: title ? title : "",
//       },
//     };
//     const response = await instance.get(`/list-companys`, config);

//     for (const item of response.data) {
//       item.nome_contato = unicodeToChar(item.nome_contato);
//     }

//     return response;
//   } catch (error) {
//     return error.message;
//   }
// }

export async function getIdClient(token, telephone) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const response = await instance.get(`/get-clientid/${telephone}`, config);

    return response;
  } catch (error) {
    return error.message;
  }
}

export async function createContactUnify(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post("/create-contact-unify", data, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function linkContact(token, data) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };

    const response = await instance.post("/link-contact-phone", data, config);

    return response;
  } catch (error) {
    return error;
  }
}
