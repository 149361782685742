//GLOBAL - components from npm
import React, { useEffect, useState, useRef } from "react";
import { format, formatDistanceToNow } from "date-fns";
import ptBR from "../../../locale/pt-BR";
import urlIngWeb from "../../../utils/env";
import ReactHtmlParser from "react-html-parser";

//STYLES
import "./comment.scss";

//COMPONENTS
import {
  ImageProfile,
  CarouselAttach,
  AttachTypeFiles,
  CommentImage,
  ColumnAttach,
} from "..";
import { Typography } from "../../general";
import { Audio, Input, AttachFile } from "../../data-entry";
import { Tippy, Loading } from "../../feedback";

//SERVICES - api, conectors...
import * as APIFiles from "../../../utils/files";

//GLOBAL STATE - redux, env...
import { useSelector } from "react-redux";

//ASSETS - icons, images...
import { ReactComponent as IconMoreVertical } from "./../../../assets/icons/MoreVertical.svg";
import { ReactComponent as IconClips } from "../../../assets/icons/Clips.svg";
import { ReactComponent as IconMic } from "../../../assets/icons/Mic.svg";
// import { ReactComponent as IconCancel } from "../../../assets/icons/Cancel.svg";
// import { ReactComponent as IconConfirm } from "../../../assets/icons/Confirm.svg";
import { ReactComponent as IconConfirm } from "../../../assets/icons/Confirm-circle.svg";
import { ReactComponent as IconCancel } from "../../../assets/icons/Cancel-circle.svg";
import { ReactComponent as IconClip } from "../../../assets/icons/history-icons/HistoricAttach.svg";
import { PopoverMenu } from "../../navigation";

export default function Comment({
  comment = {
    id_comentario: 1,
    desc_comentario: "teste",
    data_insercao: "2022-01-04T13:59:57.653Z",
    id_tipo_comentario: 1,
    usuario_insercao: 6,
    nome_usuario: "Glauber",
    filesAssociated: [
      {
        id_comentario_arquivo: 217,
        path_arquivos:
          "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
        id_comentario: 333,
      },
    ],
    files: [
      {
        file: {
          name: "anexo",
          size: 1000,
          type: "text/plain",
          path_arquivos:
            "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
          extension: ".gif",
          id: 20,
        },
      },
    ],
    commentParent: {
      id_comentario: 2,
      desc_comentario: "teste 2",
      id_tipo_comentario: 2,
      usuario_insercao: 6,
      nome_usuario: "Glauber",
      filesAssociated: [
        {
          id_comentario_arquivo: 217,
          path_arquivos:
            "/database/rec/ingdesk/20220104/11/arquivo-gif_20220104110002.gif",
          id_comentario: 333,
        },
      ],
    },
  },
  setCommentResponse,
  onUpdate,
  onDelete,
  setLoadingComment,
  commentAnswered,
  loadingUpdateComment,
  setLoadingUpdateComment,
  inputSearch = "",
  permissionUsers = null,
  commentType = 1,
}) {
  //GENERAL
  const refAudio = useRef();
  const user = useSelector((state) => state.auth.userData);

  //STATES
  const [viewMore, setViewMore] = useState(false);
  const [arrayFiles, setArrayFiles] = useState(comment?.files || []);
  const [visible, setVisible] = useState(false);
  const [audioDuration, setAudioDuration] = useState();
  const [edit, setEdit] = useState(false);
  const [valueTextComment, setValueTextComment] = useState("");
  const [files, setFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [descComment, setDescComment] = useState(comment.desc_comentario);
  const [commentImg, setCommentImg] = useState(false);
  const [tippyPermission, setTippyPermission] = useState(true);
  const [commentVideo, setCommentVideo] = useState(false);

  //REDUX - Selectors

  //FUNCTIONS
  const show = () => setVisible(true);

  const hide = () => {
    setVisible(false);
  };

  const handleCommentType = () => {
    if (arrayFiles.length === 0) return setCommentImg(false);
    // let imageFiles;

    const imageFiles = arrayFiles.every((imgFile) =>
      imgFile.file.type?.includes("image")
    );

    if (imageFiles) return setCommentImg(true);

    if (arrayFiles.length === 1) {
      const videoFiles = arrayFiles.every((imgFile) =>
        imgFile.file.type?.includes("video")
      );

      if (videoFiles) return setCommentVideo(true);
    }
  };

  //Função para enviar as informações do comentário para rota de atualização
  const updateComment = async () => {
    if (comment.id_tipo_comentario === 2 && arrayFiles.length === 0) {
      return deleteComment(comment);
    }
    setLoadingUpdateComment(`updating-${comment.id_comentario}`);

    handleCommentType();
    await onUpdate(valueTextComment, files, deleteFiles, comment);

    setDeleteFiles([]);
    setFiles([]);
    setLoadingUpdateComment("awaiting");
  };

  //Função para enviar as informações do comentário para rota de delete
  const deleteComment = async (comment) => {
    setLoadingComment("deleting");
    await onDelete(comment.id_comentario);

    setLoadingComment("awaiting");
  };

  //Função que ao ser chamada, adiciona uma classe ao comentário respondido para fazer a animação nele.
  const handleComment = (idComment) => {
    const comment = document.querySelector(`#comentario-${idComment}`);
    comment.classList.add("comment-active");
    setTimeout(() => {
      comment.classList.remove("comment-active");
    }, 2000);
  };

  function getDuration() {
    refAudio.current.currentTime = 0;
    refAudio.current.removeEventListener("timeupdate", getDuration);
    let data = new Date(null);
    data.setSeconds(refAudio.current.duration);
    let duracao = data.toISOString().substr(14, 5);
    setAudioDuration(duracao);
  }

  //Função que busca as informações dos arquivos do comentário
  const fetchFile = async () => {
    const files = [];
    if (comment.filesAssociated.length > 0) {
      for (const file of comment.filesAssociated) {
        const response = await APIFiles.handleFiles(file.path_arquivos);
        const fileObject = {
          file: {
            name: response.name,
            size: response.size,
            type: response.typeMime,
            path_arquivos: file.path_arquivos,
            extension: response.type,
            id: file.id_comentario_arquivo,
          },
        };

        files.push(fileObject);
      }
    }
    setArrayFiles(files);
  };

  //Função que renderiza o comentário respondido caso tenha um.
  const renderCommentAnswered = (comment) => {
    if (comment.commentParent?.id_comentario) {
      if (comment.commentParent.desc_comentario) {
        return (
          <a
            href={`#comentario-${comment.commentParent.id_comentario}`}
            className="comment-content__response"
            onClick={() => handleComment(comment.commentParent.id_comentario)}
          >
            <div className="response-divider"></div>
            <div className="response-content">
              <Typography.H6 color="gray-180" weight={"bold"}>
                {comment.commentParent.nome_usuario}
              </Typography.H6>

              <Typography.H6 color="gray-180">
                {comment.commentParent.desc_comentario.length > 150
                  ? `${comment.commentParent.desc_comentario.slice(0, 150)}... `
                  : comment.commentParent.desc_comentario}
              </Typography.H6>
            </div>
            {/* <Typography.Paragraph color="primary-dark">
              {comment.commentParent.desc_comentario}
            </Typography.Paragraph> */}
          </a>
        );
      } else if (comment.commentParent.id_tipo_comentario === 3) {
        return (
          <a
            href={`#comentario-${comment.commentParent.id_comentario}`}
            className="comment-content__response"
            onClick={() => handleComment(comment.commentParent.id_comentario)}
          >
            <div className="response-divider"></div>
            <div className="response-content">
              <audio
                ref={refAudio}
                src={`${urlIngWeb}${comment.commentParent.filesAssociated[0].path_arquivos}`}
                onLoadedData={() => {
                  if (refAudio.current.duration === Infinity) {
                    refAudio.current.currentTime = 1e101;
                    refAudio.current.addEventListener(
                      "timeupdate",
                      getDuration
                    );
                  }
                }}
              ></audio>
              <Typography.H6 color="gray-180" weight={"bold"}>
                {comment.commentParent.nome_usuario}
              </Typography.H6>
              <Typography.H6 color="gray-180">
                <IconMic />
                &nbsp; Mensagem de Voz - {audioDuration}
              </Typography.H6>
            </div>
            {/* <audio
              ref={refAudio}
              src={`${urlIngWeb}${comment.commentParent.filesAssociated[0].path_arquivos}`}
              onLoadedData={() => {
                if (refAudio.current.duration === Infinity) {
                  refAudio.current.currentTime = 1e101;
                  refAudio.current.addEventListener("timeupdate", getDuration);
                }
              }}
            ></audio>
            <Typography.H6 color="primary-dark">
              <IconMic />
              &nbsp; Mensagem de Voz - {audioDuration}
            </Typography.H6> */}
          </a>
        );
      } else {
        return (
          <a
            href={`#comentario-${comment.commentParent.id_comentario}`}
            className="comment-content__response"
            onClick={() => handleComment(comment.commentParent.id_comentario)}
          >
            <div className="response-divider"></div>
            <div className="response-content">
              <Typography.H6 color="gray-180" weight={"bold"}>
                {comment.commentParent.nome_usuario}
              </Typography.H6>
              <Typography.H6 color="gray-180">
                <IconClips />
                &nbsp; Ir para os anexos
              </Typography.H6>
            </div>

            {/* <Typography.H6 color="primary-dark">
              <IconClips />
              &nbsp; Ir para os anexos
            </Typography.H6> */}
          </a>
        );
      }
    }
  };

  const renderTitleComment = (comment) => {
    if (commentType === 2) {
      return (
        <header className="header-comment">
          <div className="header-comment__details-user">
            <div className="user-information">
              <Typography.H5 color="gray-180" weight="bold">
                {comment.nome_usuario}
              </Typography.H5>
            </div>
          </div>

          {tippyPermission && renderTippyCommentTypeTwo()}
        </header>
      );
    } else {
      return (
        <div className="comment-information-user">
          <Typography.H5 color="gray-180" weight={"bold"}>
            {comment.nome_usuario}
          </Typography.H5>
        </div>
      );
    }

    // if (comment.commentParent.id_comentario) {
    //   return (
    //     <div className="comment-information-user">
    //       <ImageProfile
    //         profile={comment}
    //         className="comment-information-user__image"
    //       />
    //       <Typography.Paragraph color="gray-200">
    //         {comment.nome_usuario} respondeu
    //       </Typography.Paragraph>
    //       <ImageProfile
    //         profile={comment.commentParent}
    //         className="comment-information-user__image"
    //       />
    //       <Typography.Paragraph color="gray-200">
    //         {comment.commentParent.nome_usuario}
    //       </Typography.Paragraph>
    //     </div>
    //   );
    // } else {
    //   return (
    //     <div className="comment-information-user">
    //       <ImageProfile
    //         profile={comment}
    //         className="comment-information-user__image"
    //       />
    //       {comment.desc_comentario ? (
    //         <Typography.Paragraph color="gray-200">
    //           {comment.nome_usuario} comentou
    //         </Typography.Paragraph>
    //       ) : comment.id_tipo_comentario === 3 ? (
    //         <Typography.Paragraph color="gray-200">
    //           {comment.nome_usuario} mandou mensagem de áudio
    //         </Typography.Paragraph>
    //       ) : (
    //         <Typography.Paragraph color="gray-200">
    //           {comment.nome_usuario} enviou{" "}
    //           {`(${comment.filesAssociated.length})`} anexo
    //           {comment.filesAssociated.length > 1 ? "s" : ""}
    //         </Typography.Paragraph>
    //       )}
    //     </div>
    //   );
    // }
  };

  const renderTippy = (comment) => {
    return (
      <Tippy
        theme="popover-default"
        // #TOVERIFY: TRIGGER
        // trigger="click"
        visible={visible}
        onClickOutside={hide}
        placement="left-start"
        delay={[0, 0]}
        allowHTML
        interactive
        content={
          <div className="popover-comment">
            {permissionUsers ? (
              <>
                {permissionUsers.edicao && (
                  <div
                    className="popover-comment__item"
                    onClick={() => {
                      setEdit(true);
                      setValueTextComment(comment.desc_comentario);
                      hide();
                    }}
                  >
                    <Typography.H6 weight="500" color="primary-dark">
                      Editar
                    </Typography.H6>
                  </div>
                )}
                {permissionUsers.insercao && (
                  <div
                    className="popover-comment__item"
                    onClick={() => {
                      setCommentResponse(comment);
                      hide();
                    }}
                  >
                    <Typography.H6 weight="500" color="primary-dark">
                      Responder
                    </Typography.H6>
                  </div>
                )}
                {permissionUsers.exclusao && (
                  <div
                    className="popover-comment__item"
                    onClick={() => deleteComment(comment)}
                  >
                    <Typography.H6 weight="500" color="red">
                      Remover
                    </Typography.H6>
                  </div>
                )}
              </>
            ) : (
              <div>
                {comment.id_tipo_comentario === 3 ||
                commentAnswered ||
                comment.usuario_insercao !== user.userId ? (
                  <></>
                ) : (
                  <div
                    className="popover-comment__item"
                    onClick={() => {
                      setEdit(true);
                      setValueTextComment(comment.desc_comentario);
                      hide();
                    }}
                  >
                    <Typography.H6 weight="500" color="primary-dark">
                      Editar
                    </Typography.H6>
                  </div>
                )}

                <div
                  className="popover-comment__item"
                  onClick={() => {
                    setCommentResponse(comment);
                    hide();
                  }}
                >
                  <Typography.H6 weight="500" color="primary-dark">
                    Responder
                  </Typography.H6>
                </div>

                {commentAnswered || user.userId !== comment.usuario_insercao ? (
                  <></>
                ) : (
                  <div
                    className="popover-comment__item"
                    onClick={() => deleteComment(comment)}
                  >
                    <Typography.H6 weight="500" color="red">
                      Remover
                    </Typography.H6>
                  </div>
                )}
              </div>
            )}
          </div>
        }
      >
        <IconMoreVertical
          className="comment-icon__menu"
          onClick={() => show()}
        />
      </Tippy>
    );
  };

  const renderFooter = () => {
    return (
      <div className="comment-footer">
        {commentType === 2 ? (
          <Typography.H5 color="gray-180" weight="400">
            {formatDistanceToNow(new Date(comment.data_insercao), {
              locale: ptBR,
              addSuffix: true,
            })}
          </Typography.H5>
        ) : (
          <Typography.Paragraph color="gray-200">
            {format(new Date(comment.data_insercao), "dd/MM/yyyy 'às' HH:mm", {
              locale: ptBR,
            })}
          </Typography.Paragraph>
        )}
      </div>
    );
  };

  const renderFileComment = () => {
    if (commentImg) {
      return <CommentImage content={arrayFiles} />;
    } else if (commentVideo) {
      return arrayFiles.map((file) => (
        <div className="bubble-media__video" key={file.name}>
          <video className="bubble-media__video-frame" controls>
            <source
              className="bubble-media__video-file"
              src={`${urlIngWeb + file.file.path_arquivos}`}
              type={`${file.file.type}`}
            />
            Seu navegador nao suporta esse tipo de arquivo.
          </video>
        </div>
      ));
    } else {
      return arrayFiles.map((file) => (
        <AttachTypeFiles
          key={file.name}
          renderIcons
          files={file.file}
          type="comment"
          limit={3}
          className="comment-carousel"
          download
        />
      ));
    }
  };

  const renderTippyCommentTypeTwo = () => {
    return (
      <Tippy
        theme="popover-default"
        visible={visible}
        onClickOutside={hide}
        placement="left-start"
        delay={[0, 0]}
        allowHTML
        interactive
        content={
          <div className="popover-comment">
            {permissionUsers ? (
              <>
                {permissionUsers.edicao && (
                  <>
                    {comment.id_tipo_comentario !== 3 && (
                      <div
                        className="popover-comment__item"
                        onClick={() => {
                          setEdit(true);
                          setValueTextComment(comment.desc_comentario);
                          hide();
                        }}
                      >
                        <Typography.H5 color="gray-180" weight="500">
                          Editar
                        </Typography.H5>
                      </div>
                    )}
                  </>
                )}

                {permissionUsers.insercao && (
                  <div
                    className="popover-comment__item"
                    onClick={() => {
                      setCommentResponse(comment);
                      hide();
                    }}
                  >
                    <Typography.H5 color="gray-180" weight="500">
                      Responder
                    </Typography.H5>
                  </div>
                )}

                {permissionUsers.exclusao && (
                  <div
                    className="popover-comment__item"
                    onClick={() => deleteComment(comment)}
                  >
                    <Typography.H5 color="red" weight="500">
                      Remover
                    </Typography.H5>
                  </div>
                )}
              </>
            ) : (
              <div>
                {comment.id_tipo_comentario === 3 ||
                commentAnswered ||
                comment.usuario_insercao !== user.userId ? (
                  <></>
                ) : (
                  <div
                    className="popover-comment__item"
                    onClick={() => {
                      setEdit(true);
                      setValueTextComment(comment.desc_comentario);
                      hide();
                    }}
                  >
                    <Typography.H5 color="gray-180" weight="500">
                      Editar
                    </Typography.H5>
                  </div>
                )}
              </div>
            )}

            <div
              className="popover-comment__item"
              onClick={() => {
                setCommentResponse(comment);
                hide();
              }}
            >
              <Typography.H5 color="gray-180" weight="500">
                Responder
              </Typography.H5>
            </div>

            {commentAnswered || user.userId !== comment.usuario_insercao ? (
              <></>
            ) : (
              <div
                className="popover-comment__item"
                onClick={() => deleteComment(comment)}
              >
                <Typography.H5 color="red" weight="500">
                  Remover
                </Typography.H5>
              </div>
            )}
          </div>
        }
      >
        <IconMoreVertical onClick={() => show()} />
      </Tippy>
    );
  };

  const renderComment = () => {
    if (comment.desc_comentario) {
      return (
        <div
          className={`comment ${
            user.userId !== comment.usuario_insercao ? "blue-color" : " "
          } ${
            commentImg ? "comment-length-normal" : "comment-length-alternate"
          }`}
          id={`comentario-${comment.id_comentario}`}
        >
          <div className="comment-information">
            {renderTitleComment(comment)}

            {commentType !== 2 && (
              <>{tippyPermission && renderTippy(comment)}</>
            )}
          </div>
          {edit ? (
            <div className="comment-content">
              {renderCommentAnswered(comment)}
              <div className="comment-content__text">
                <Input
                  type="textarea"
                  value={valueTextComment}
                  placeholder="Adicionar Comentário"
                  onChange={(value) => setValueTextComment(value)}
                  className="comment-content__text-edit"
                />
              </div>
              <div className="comment-content-buttons">
                <div className="comment-content-buttons-attachment-list">
                  <ColumnAttach
                    files={arrayFiles}
                    setFiles={setArrayFiles}
                    limit={3}
                    type="insert"
                    className="comment-carousel"
                    setDeleteFiles={setDeleteFiles}
                    deleteFiles={deleteFiles}
                  />

                  <AttachFile
                    setFiles={setFiles}
                    icon={<IconClip />}
                    textIcon="Anexar arquivo"
                  />

                  <div className="comment-content-edit-buttons">
                    <div
                      onClick={() => {
                        updateComment();
                        setEdit(false);
                      }}
                    >
                      <IconConfirm />
                    </div>
                    <div
                      onClick={() => {
                        setValueTextComment(comment.desc_comentario);
                        fetchFile();
                        setDeleteFiles([]);
                        setFiles([]);
                        setEdit(false);
                      }}
                    >
                      <IconCancel />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="comment-content">
              {renderCommentAnswered(comment)}
              {arrayFiles.length > 0 && (
                <div className="comment-content-buttons">
                  <div
                    className={`comment-content-buttons-attachment-list ${
                      arrayFiles.length > 0 ? "column" : ""
                    }`}
                  >
                    {loadingUpdateComment ===
                    `updating-${comment.id_comentario}` ? (
                      <Loading.Dots
                        width="50px"
                        height="24px"
                        className="loading"
                      />
                    ) : (
                      renderFileComment()
                    )}
                  </div>
                </div>
              )}
              <div className="comment-content__text">
                <Typography.H5 color="gray-180">
                  {viewMore
                    ? `${comment.desc_comentario}`
                    : comment.desc_comentario.length > 250
                    ? `${comment.desc_comentario.slice(0, 250)}... `
                    : `${comment.desc_comentario}`}
                  {comment.desc_comentario.length > 250 && (
                    <button
                      className="comment-content-buttons__button"
                      onClick={() => setViewMore(!viewMore)}
                    >
                      <Typography.H6 color="primary-dark">
                        {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
                      </Typography.H6>
                    </button>
                  )}
                </Typography.H5>
              </div>
            </div>
          )}

          {renderFooter()}
        </div>
      );
    } else {
      if (comment.id_tipo_comentario === 3) {
        return (
          <div
            className={`comment ${
              user.userId !== comment.usuario_insercao ? "blue-color" : " "
            } comment-length-normal`}
            id={`comentario-${comment.id_comentario}`}
          >
            <div className="comment-information">
              {renderTitleComment(comment)}

              {commentType !== 2 && (
                <>{tippyPermission && renderTippy(comment)}</>
              )}
            </div>
            <div className="comment-content">
              {renderCommentAnswered(comment)}
              {arrayFiles[0]?.file.path_arquivos && (
                <Audio link={arrayFiles[0]?.file.path_arquivos} />
              )}
            </div>
            {renderFooter()}
          </div>
        );
      } else {
        return (
          <div
            className={`comment ${
              user.userId !== comment.usuario_insercao ? "blue-color" : " "
            } comment-length-normal`}
            id={`comentario-${comment.id_comentario}`}
          >
            <div className="comment-information">
              {renderTitleComment(comment)}

              {commentType !== 2 && (
                <>{tippyPermission && renderTippy(comment)}</>
              )}
            </div>
            {edit ? (
              <div className="comment-content">
                {renderCommentAnswered(comment)}
                <div className="comment-content__text">
                  <Input
                    type="textarea"
                    value={valueTextComment}
                    placeholder="Adicionar Comentário"
                    onChange={(value) => setValueTextComment(value)}
                    className="comment-content__text-edit"
                  />
                </div>
                <div className="comment-content-buttons">
                  <div className="comment-content-buttons-attachment-list">
                    <ColumnAttach
                      files={arrayFiles}
                      setFiles={setArrayFiles}
                      limit={3}
                      type="insert"
                      className="comment-carousel"
                      setDeleteFiles={setDeleteFiles}
                      deleteFiles={deleteFiles}
                    />

                    <AttachFile
                      setFiles={setFiles}
                      icon={<IconClip />}
                      textIcon="Anexar arquivo"
                    />
                    <div className="comment-content-edit-buttons">
                      <div
                        onClick={() => {
                          updateComment();
                          setEdit(false);
                        }}
                      >
                        <IconConfirm />
                      </div>
                      <div
                        onClick={() => {
                          setValueTextComment(comment.desc_comentario);
                          fetchFile();
                          setDeleteFiles([]);
                          setFiles([]);
                          setEdit(false);
                        }}
                      >
                        <IconCancel />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="comment-content">
                {renderCommentAnswered(comment)}
                <div className="comment-content__files">
                  {loadingUpdateComment ===
                  `updating-${comment.id_comentario}` ? (
                    <Loading.Dots
                      width="50px"
                      height="24px"
                      className="loading"
                    />
                  ) : (
                    renderFileComment()
                  )}
                </div>
              </div>
            )}

            {renderFooter()}
          </div>
        );
      }
    }
  };

  //USE EFFECTS
  useEffect(() => {
    fetchFile();
  }, [comment]);

  //useEffect que com base no que foi pesquisado no input de busca(inputSearch) colocar uma tag de marcação no texto do comentário.
  useEffect(() => {
    let currentComment = comment.desc_comentario;

    if (inputSearch) {
      const indexes = [];
      let i = 0;
      while (i < currentComment.length) {
        if (
          currentComment.toLowerCase().indexOf(inputSearch.toLowerCase(), i) !==
          -1
        ) {
          indexes.push(
            currentComment.toLowerCase().indexOf(inputSearch.toLowerCase(), i)
          );
          i =
            currentComment.toLowerCase().indexOf(inputSearch.toLowerCase(), i) +
            1;
        } else {
          i = currentComment.length;
        }
      }

      const words = [];

      for (const index of indexes) {
        const partName = currentComment.substr(index, inputSearch.length);
        words.push(partName);
      }

      const newWords = words.filter((item, i) => {
        return words.indexOf(item) === i;
      });

      const newWordsOrder = newWords.sort((a, b) => a.localeCompare(b));

      for (const item of newWordsOrder) {
        currentComment = currentComment.replaceAll(
          item,
          `<mark>${item}</mark>`
        );
        //currentComment = currentComment.replaceAll(`<mark>${item.slice(0,item.length -1)} </mark>`, `<mark>${item.slice(0,item.length -1)}&nbsp;</mark>`);
        //currentComment = currentComment.replaceAll(item, `<div class="comment-filtered--marked">${item}</div>`);
        //currentComment = currentComment.replaceAll(`<div class="comment-filtered--marked">${item.slice(0,item.length -1)} </div>`, `<div class="comment-filtered--marked">${item.slice(0,item.length -1)}&nbsp;</div>`);
      }
    }
    currentComment = currentComment?.replaceAll(` `, `&nbsp;`);
    // currentComment = currentComment.replaceAll("</mark> ", '</mark>&nbsp;')
    // const index = currentComment.toLowerCase().indexOfAll(inputSearch.toLowerCase());

    // const newDescComment = currentComment.toLowerCase().replaceAll(inputSearch.toLowerCase(), inputSearch.toLowerCase().bold())

    setDescComment(`<div>${currentComment}</div>`);
  }, [inputSearch, comment]);

  useEffect(() => {
    if (files) {
      const newFiles = [...arrayFiles];
      const filesConcat = newFiles.concat(files);
      setArrayFiles(filesConcat);
    }
  }, [files]);

  useEffect(() => {
    handleCommentType();
  }, [arrayFiles]);

  useEffect(() => {
    if (permissionUsers) {
      if (
        !permissionUsers.edicao &&
        !permissionUsers.exclusao &&
        !permissionUsers.insercao
      ) {
        setTippyPermission(false);
      } else {
        setTippyPermission(true);
      }
    }
  }, [permissionUsers]);

  return (
    <div
      className={`comment-container 
        ${user.userId !== comment.usuario_insercao ? "reverse-row" : ""}
        ${commentType === 2 ? "padding-top" : ""}`}
    >
      {renderComment()}

      <ImageProfile
        profile={{
          nome_usuario: comment.nome_usuario,
        }}
        tooltip
        className={`comment-list__profileImage ${
          commentType === 2 ? "flex-end" : ""
        }`}
      />
    </div>
  );

  // if (comment.desc_comentario) {
  //   return (
  //     <div className="comment" id={`comentario-${comment.id_comentario}`}>
  //       <div className="comment-information">
  //         {renderTitleComment(comment)}
  //         <div className="comment-information-date">
  //           <Typography.Paragraph color="gray-200">
  //             {format(
  //               new Date(comment.data_insercao),
  //               "dd 'de' MMM'./'yyyy 'às' HH:mm",
  //               { locale: ptBR }
  //             )}
  //           </Typography.Paragraph>
  //           {renderTippy(comment)}
  //         </div>
  //       </div>
  //       {edit ? (
  //         <div className="comment-content">
  //           {renderCommentAnswered(comment)}
  //           <div className="comment-content__text">
  //             <Input
  //               type="textarea"
  //               value={valueTextComment}
  //               onChange={(value) => setValueTextComment(value)}
  //               className="comment-content__text-edit"
  //             />
  //           </div>
  //           <div className="comment-content-buttons">
  //             <div className="comment-content-buttons-attachment-list">
  //               <CarouselAttach
  //                 files={arrayFiles}
  //                 setFiles={setArrayFiles}
  //                 limit={3}
  //                 type="insert"
  //                 className="comment-carousel"
  //                 setDeleteFiles={setDeleteFiles}
  //                 deleteFiles={deleteFiles}
  //               />
  //               <AttachFile setFiles={setFiles} text="Novo Anexo" />
  //               <div className="comment-content-edit-buttons">
  //                 <div
  //                   onClick={() => {
  //                     updateComment();
  //                     setEdit(false);
  //                   }}
  //                 >
  //                   <IconConfirm />
  //                 </div>
  //                 <div
  //                   onClick={() => {
  //                     setValueTextComment(comment.desc_comentario);
  //                     fetchFile();
  //                     setDeleteFiles([]);
  //                     setFiles([]);
  //                     setEdit(false);
  //                   }}
  //                 >
  //                   <IconCancel />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       ) : (
  //         <div className="comment-content">
  //           {renderCommentAnswered(comment)}
  //           <div className="comment-content__text">
  //             <Typography.H6 color="gray-400">
  //               {viewMore
  //                 ? ReactHtmlParser(descComment)
  //                 : comment.desc_comentario.length > 250
  //                 ? `${ReactHtmlParser(descComment.slice(0, 250))}... `
  //                 : ReactHtmlParser(descComment)}
  //               {comment.desc_comentario.length > 250 && (
  //                 <button
  //                   className="comment-content-buttons__button"
  //                   onClick={() => setViewMore(!viewMore)}
  //                 >
  //                   <Typography.H6 color="primary-dark">
  //                     {viewMore ? <>&nbsp;Ler menos</> : "Ler mais"}
  //                   </Typography.H6>
  //                 </button>
  //               )}
  //             </Typography.H6>
  //           </div>
  //           {arrayFiles.length > 0 && (
  //             <div className="comment-content-buttons">
  //               <div className="comment-content-buttons-attachment-list">
  //                 {loadingUpdateComment ===
  //                 `updating-${comment.id_comentario}` ? (
  //                   <Loading.Dots
  //                     width="50px"
  //                     height="24px"
  //                     className="loading"
  //                   />
  //                 ) : (
  //                   <CarouselAttach
  //                     files={arrayFiles}
  //                     type="comment"
  //                     limit={3}
  //                     className="comment-carousel"
  //                     download
  //                   />
  //                 )}
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       )}
  //     </div>
  //   );
  // } else {
  //   if (comment.id_tipo_comentario === 3) {
  //     return (
  //       <div className="comment" id={`comentario-${comment.id_comentario}`}>
  //         <div className="comment-information">
  //           {renderTitleComment(comment)}
  //           <div className="comment-information-date">
  //             <Typography.Paragraph color="gray-200">
  //               {format(
  //                 new Date(comment.data_insercao),
  //                 "dd 'de' MMM'./'yyyy 'às' HH:mm",
  //                 { locale: ptBR }
  //               )}
  //             </Typography.Paragraph>
  //             {renderTippy(comment)}
  //           </div>
  //         </div>
  //         <div className="comment-content">
  //           {renderCommentAnswered(comment)}
  //           {arrayFiles[0]?.file.path_arquivos && (
  //             <Audio link={arrayFiles[0]?.file.path_arquivos} />
  //           )}
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="comment" id={`comentario-${comment.id_comentario}`}>
  //         <div className="comment-information">
  //           {renderTitleComment(comment)}
  //           <div className="comment-information-date">
  //             <Typography.Paragraph color="gray-200">
  //               {format(
  //                 new Date(comment.data_insercao),
  //                 "dd 'de' MMM'./'yyyy 'às' HH:mm",
  //                 { locale: ptBR }
  //               )}
  //             </Typography.Paragraph>
  //             {renderTippy(comment)}
  //           </div>
  //         </div>
  //         {edit ? (
  //           <div className="comment-content">
  //             {renderCommentAnswered(comment)}
  //             <div className="comment-content__text">
  //               <Input
  //                 type="text"
  //                 value={valueTextComment}
  //                 placeholder="Adicionar Comentário"
  //                 onChange={(value) => setValueTextComment(value)}
  //                 className="comment-content__text-edit-label"
  //               />
  //             </div>
  //             <div className="comment-content-buttons">
  //               <div className="comment-content-buttons-attachment-list">
  //                 <CarouselAttach
  //                   files={arrayFiles}
  //                   setFiles={setArrayFiles}
  //                   limit={3}
  //                   type="insert"
  //                   className="comment-carousel"
  //                   setDeleteFiles={setDeleteFiles}
  //                   deleteFiles={deleteFiles}
  //                 />
  //                 <AttachFile setFiles={setFiles} text="Novo Anexo" />
  //                 <div className="comment-content-edit-buttons">
  //                   <div
  //                     onClick={() => {
  //                       updateComment();
  //                       setEdit(false);
  //                     }}
  //                   >
  //                     <IconConfirm />
  //                   </div>
  //                   <div
  //                     onClick={() => {
  //                       setValueTextComment(comment.desc_comentario);
  //                       fetchFile();
  //                       setDeleteFiles([]);
  //                       setFiles([]);
  //                       setEdit(false);
  //                     }}
  //                   >
  //                     <IconCancel />
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         ) : (
  //           <div className="comment-content">
  //             {renderCommentAnswered(comment)}
  //             <div className="comment-content__files">
  //               {loadingUpdateComment ===
  //               `updating-${comment.id_comentario}` ? (
  //                 <Loading.Dots
  //                   width="50px"
  //                   height="24px"
  //                   className="loading"
  //                 />
  //               ) : (
  //                 <CarouselAttach
  //                   files={arrayFiles}
  //                   type="comment"
  //                   limit={3}
  //                   className="comment-carousel"
  //                   download
  //                 />
  //               )}
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     );
  //   }
  // }
}
