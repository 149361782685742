//GLOBAL - components from npm
import React, { useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { FiCheck } from "react-icons/fi";

//COMPONENTS
import { Button, Typography } from "../../../../components/general";
import { FieldContainer, Table } from "../../../../components/data-display";
import { CustomDropdown, Input, Select } from "../../../../components/data-entry";
import { Loading, Modal } from "../../../../components/feedback";
import { Toast } from "../../../../utils/toast";

//SERVICES - api, conectors...
import * as APIUser from "../../../../services/api/User";
import * as APISla from "../../../../services/api/sla";
import * as APIGroup from "../../../../services/api/group";
import * as APICase from "../../../../services/api/case";

//GLOBAL STATE - redux, env...

// UTILS

//ASSETS - icons, images...
import { ReactComponent as ArrowLefIcon } from "../../../../assets/icons/Arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/Search-normal.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Trash-3.svg";
import { ReactComponent as AddCircleIcon } from "./../../../../assets/icons/add-circle.svg";
import { ReactComponent as MinusCircleIcon } from "./../../../../assets/icons/minus-cirlce.svg";
import { ReactComponent as TickSquareIcon } from "./../../../../assets/icons/tick-square.svg";
import { ReactComponent as CleanIcon } from "./../../../../assets/icons/Clean.svg";

//STYLES
import "./update-user.scss";

export default function UpdateUser({
    toGoBack,
    userId,
    detailedUser,
    onUpdate,
    setUpdatedUser,
    handleTableDataLookup
}) {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL
    const permissions = {
        toView: 'to_view',
    };

    //STATES
    const [pageStatus, setPageStatus] = useState("awaiting");
    const [validationWarn, setValidationWarn] = useState(false);
    const [isOpenModalDeleteUser, setIsOpenModalDeleteUser] = useState({ state: false, id: null, });
    const [isOpenModalRedefinePassword, setIsOpenModalRedefinePassword] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [isOrganizationAssociationDataModified, setIsOrganizationAssociationDataModified] = useState(false);
    const [isOrganizationDataModified, setIsOrganizationDataModified] = useState(false);
    const [isStaggeredModified, setIsStaggeredModified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [organizationAssociationData, setOrganizationAssociationData] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [viewType, setViewType] = useState([]);
    const [staggeredList, setStaggeredList] = useState([]);
    const [staggeredNewData, setStaggeredNewData] = useState([]);
    const [excludedOrganizations, setExcludedOrganizations] = useState([]);
    const [optionsCaseCreation] = useState([
        { id: 1, label: "Sim" },
        { id: 2, label: "Não" },
    ]);
    const [caseClosureOptions] = useState([
        { id: 1, label: "Sim" },
        { id: 2, label: "Não" },
    ]);

    //FUNCTIONS
    const isClearFieldsEnabled = data?.organizacoes.some(association =>
        (association.id_organizacao !== null || association.idorganizacao !== null) &&
        association.id_criacao !== null &&
        association.tipo_visualizacao !== null &&
        association.id_fecharcaso !== null
    );

    const handleAssociationAddition = () => {
        setData({
            ...data, organizacoes: [
                ...data.organizacoes, {
                    id_organizacao: null,
                    id_usuario: null,
                    criarcaso: false, // = criacao
                    id_criacao: null,
                    id_fecharcaso: null,
                    fecharcaso: false,
                    tipo_visualizacao: null,
                    id_usuario_insercao: null,
                }
            ]
        });
    };

    const handleRemoveAssociation = (idx) => {
        const organizations = data?.organizacoes[idx];

        setExcludedOrganizations((prevDeleteOrganizations) => [
            ...prevDeleteOrganizations,
            organizations
        ]);

        setData({
            ...data, organizacoes: [
                ...data.organizacoes.filter((_, index) => index !== idx)
            ]
        })
    };

    const handleClearFields = () => {
        setExcludedOrganizations((prevDeleteOrganizations) => [
            ...prevDeleteOrganizations,
            ...data.organizacoes
        ]);

        setData(prevState => ({
            ...prevState,
            organizacoes: []
        }));

        setTimeout(() => {
            setData(prevState => ({
                ...prevState,
                organizacoes: [
                    {
                        id_organizacao: null,
                        id_usuario: null,
                        criacao: false,
                        fecharcaso: false,
                        id_criacao: null,
                        tipo_visualizacao: null,
                        id_fecharcaso: null,
                        id_usuario_insercao: null,
                    }
                ]
            }));
        }, 100);
    };

    const handleCreationOption = (userPermission, index) => {
        setData((prevState) => {
            const updatedAssociations = [...prevState.organizacoes];
            const updatedAssociation = { ...updatedAssociations[index] };

            updatedAssociation.id_criacao = userPermission.id;

            updatedAssociation.criarcaso = userPermission.id === 1 ? true : false;

            updatedAssociations[index] = updatedAssociation;

            setIsOrganizationAssociationDataModified(true);

            return {
                ...prevState,
                organizacoes: updatedAssociations
            };
        })
    };

    const handleCaseClosureOption = (closeCase, index) => {
        setData((prevState) => {
            const updatedOptions = [...prevState.organizacoes];
            const updatedOption = { ...updatedOptions[index] };

            updatedOption.id_fecharcaso = closeCase.id;

            updatedOption.fecharcaso = closeCase.id === 1 ? true : false;

            updatedOptions[index] = updatedOption;

            setIsOrganizationAssociationDataModified(true)

            return {
                ...prevState,
                organizacoes: updatedOptions
            }
        })
    };

    const handleFieldChange = (value, label, fieldId, textField, index) => {
        setData((prevState) => {
            const updatedAssociations = [...prevState.organizacoes];
            const updatedAssociation = { ...updatedAssociations[index] };

            updatedAssociation[fieldId] = value;
            updatedAssociation[textField] = label;

            updatedAssociations[index] = updatedAssociation;

            setIsOrganizationAssociationDataModified(true);

            return {
                ...prevState,
                organizacoes: updatedAssociations
            };
        });
    };

    const allOrganizationsAssociated = () => {
        const { organizacoes } = data;
        const { idorganizacao } = organizationAssociationData;

        const allAssociated = organizations?.filter(org => {
            return !organizacoes?.some(item => item.idorganizacao === org.id || idorganizacao === org.id);
        }).length === 0;

        return allAssociated;
    };

    function handleCheckboxChange(id, permission) {
        setStaggeredNewData(prevData =>
            prevData.map(item =>
                item.id_escalonamento === id ? { ...item, [permission]: !item[permission] } : item
            )
        );
        setIsStaggeredModified(true);
    };

    function handleSelectAll() {
        setStaggeredNewData(prevData => {
            const allChecked = prevData.every(
                item =>
                    Object.values(item).every(value => typeof value !== 'boolean' || value === true)
            );

            // Define o estado de seleção para o oposto do estado atual de todos os itens
            return prevData.map(item => {
                const updatedItem = { ...item };

                for (const permission in permissions) {
                    if (Object.prototype.hasOwnProperty.call(permissions, permission)) {
                        updatedItem[permissions[permission]] = !allChecked;
                    }
                }

                return updatedItem;
            });
        });
        setIsStaggeredModified(true);
    };

    function setDataAndMarkModified(newData) {
        setData(newData);
        setIsDataModified(true);
    };

    function setModifiedOrganizationData(newData) {
        setOrganizationAssociationData(newData);
        setIsOrganizationDataModified(true);
    };

    function defaultSorting(rowA, rowB, columnId) {
        const objectRowA = rowA.original[columnId].data;
        const objectRowB = rowB.original[columnId].data;

        if (objectRowA > objectRowB) return -1;
        if (objectRowB > objectRowA) return 1;
        return 0;
    };

    function isFieldsValid() {
        if (
            !data?.nome_usuario ||
            !data?.login_usuario ||
            !data?.id_organizacao ||
            !data?.id_perfil ||
            !data?.id_funcao ||
            !data?.idgrupo ||
            !organizationAssociationData?.id_criacao ||
            !organizationAssociationData?.tipo_visualizacao ||
            !organizationAssociationData?.id_fecharcaso
        ) {
            return false
        }

        return true
    };

    function handleInvalidFields() {
        setValidationWarn(true);

        Toast(
            "Erro ao adicionar usuário!",
            "Verifique o preenchimento dos campos em destaque.",
            "error",
        );
    };

    function getValues(userId) {
        let userDataToUpdate = null;

        const users = cloneDeep(detailedUser);

        users.forEach((item) => {
            if (item.id_usuario_criado === userId) {
                userDataToUpdate = {
                    ...item,
                    matricula: item.matricula ?? "",
                };
            }
        });

        if (userDataToUpdate) {
            setData(userDataToUpdate);
            setOrganizationAssociationData(userDataToUpdate.org_data_main);
        }
    };

    function refactorStaggeredListing(data) {
        if (!data) throw new Error('Error when fetching data!');

        return data.map((item) => {
            const id = item.id_escalonamento;

            const newObject = {
                name: {
                    data: item.descricao,
                    config: {
                        className: "staggered-name"
                    },
                },
                toView: {
                    data: (
                        <label
                            className="checkbox-container"
                            htmlFor={`to-view${id}`}
                        >
                            <input
                                type="checkbox"
                                id={`to-view${id}`}
                                name={`to-view${id}`}
                                checked={item.to_view || false}
                                onChange={() => handleCheckboxChange(id, 'to_view')}
                            />

                            <span className={`checkmark ${item.to_view ? "checked" : ""}`}>
                                {item.to_view && <FiCheck className="check-icon" />}
                            </span>
                        </label>
                    ),
                    config: {
                        className: "staggered-toView"
                    },
                }
            };

            return newObject;
        });
    };

    async function handleGroupCreationOnDownKey(event) {
        if (event.key !== "Enter") return;

        const inputValue = event.target.value;

        const data = {
            desc_grupo: inputValue
        }

        const response = await APIGroup.createGroup(token, data);

        if (response.status === 200) {
            getGroupsList();

            Toast(
                "Grupo adicionado!",
                "Grupo adicionado com sucesso.",
                "success"
            );
        } else {
            Toast(
                "Não foi possível adicionar o grupo!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function updateMainOrganization() {
        try {
            const updateOrganizationData = {
                id_organizacao: organizationAssociationData?.idorganizacao,
                criacao: organizationAssociationData.criarcaso,
                fecharcaso: organizationAssociationData.fecharcaso,
                tipo_visualizacao: organizationAssociationData.tipo_visualizacao,
                id_organizacao_usuario: organizationAssociationData.id_organizacao_usuario
            };

            const response = await APIUser.updateUserOrganization(token, updateOrganizationData);

            if (response.status !== 200) {
                throw new Error("Falha ao atualizar a organização principal");
            }
        } catch (error) {
            console.error("Erro ao atualizar a organização principal:", error);
            Toast("Erro ao atualizar a organização principal!", "Um erro inesperado aconteceu.", "error");
        }
    };

    async function CreateMainOrganization() {
        try {
            const newOrganizationData = {
                id_organizacao: organizationAssociationData?.idorganizacao,
                id_usuario: data?.id_usuario_criado,
                criacao: organizationAssociationData.criarcaso,
                fecharcaso: organizationAssociationData.fecharcaso,
                tipo_visualizacao: organizationAssociationData.tipo_visualizacao,
                id_usuario_insercao: userData.userId
            };

            const response = await APIUser.createUserOrganization(token, newOrganizationData);

            if (response.status !== 200) {
                throw new Error("Falha ao criar a organização principal");
            }
        } catch (error) {
            console.error("Erro ao criar a organização principal:", error);
            Toast("Erro ao criar a organização principal!", "Um erro inesperado aconteceu.", "error");
        }
    };

    async function handleUpdateUser() {
        try {
            if (!isFieldsValid()) {
                handleInvalidFields();
                return;
            }

            setPageStatus("updating-user");

            let hasError = false;

            if (isOrganizationDataModified) {
                if (!organizationAssociationData.id_organizacao_usuario) {
                    await CreateMainOrganization()
                } else {
                    await updateMainOrganization();
                }
            }

            if (isDataModified || isOrganizationDataModified) {
                const updateUserData = {
                    id_usuario: data?.id_usuario_criado,
                    nome: data?.nome_usuario,
                    login: data?.login_usuario,
                    id_organizacao: data?.id_organizacao,
                    idfuncao: data?.id_funcao,
                    id_perfil: data?.id_perfil,
                    matricula: data?.matricula,
                    idgrupo: data?.idgrupo
                };

                const response = await APIUser.updateUser(token, updateUserData);

                if (response.status !== 200) {
                    hasError = true;
                }
            }

            for (const item of data?.organizacoes) {
                if (!item.id_organizacao_usuario) {
                    const newOrganizationData = {
                        id_organizacao: item.idorganizacao,
                        id_usuario: data?.id_usuario_criado,
                        criacao: item.criarcaso,
                        fecharcaso: item.fecharcaso,
                        tipo_visualizacao: item.tipo_visualizacao,
                        id_usuario_insercao: userData.userId
                    };

                    if (item.idorganizacao && item.id_criacao && item.tipo_visualizacao && item.id_fecharcaso) {
                        const response = await APIUser.createUserOrganization(token, newOrganizationData);

                        if (response.status !== 200) {
                            hasError = true;
                            break; // Stop loop if error occurs
                        }
                    }
                } else {
                    if (isOrganizationAssociationDataModified) {
                        const updateOrganizationData = {
                            id_organizacao: item.idorganizacao,
                            criacao: item.criarcaso,
                            fecharcaso: item.fecharcaso,
                            tipo_visualizacao: item.tipo_visualizacao,
                            id_organizacao_usuario: item.id_organizacao_usuario
                        };

                        const response = await APIUser.updateUserOrganization(token, updateOrganizationData);

                        if (response.status !== 200) {
                            hasError = true;
                            break; // Stop loop if error occurs
                        }
                    }
                }
            }

            if (isStaggeredModified) {
                for (const item of staggeredNewData) {
                    if (item.to_view === true) {
                        const newData = {
                            id_escalonamento: item.id_escalonamento,
                            id_usuario: data?.id_usuario_criado
                        }

                        const response = await APIUser.createStaggeredUser(token, newData);

                        if (response.status !== 200) {
                            hasError = true;
                            break;
                        }
                    } else {
                        if (item.to_view === false) {
                            const newData = {
                                id_escalonamento: item.id_escalonamento,
                                id_usuario: data?.id_usuario_criado
                            }

                            const response = await APIUser.deleteStaggeredUser(token, newData);

                            if (response.status !== 200) {
                                hasError = true;
                                break;
                            }
                        }
                    }
                }
            }

            // modifiedOrganizationsLength

            if (excludedOrganizations.length > 0) {
                for (const deleteOrganization of excludedOrganizations) {
                    if (deleteOrganization.id_organizacao_usuario) {
                        const values = {
                            id_usuario_exclusao: data?.id_usuario_criado,
                            id_organizacao_usuario: deleteOrganization.id_organizacao_usuario
                        };

                        const response = await APIUser.deleteUserOrganization(token, values);

                        if (response.status !== 200) {
                            hasError = true;
                            break; // Stop loop if error occurs
                        }
                    }
                }
            }

            if (!hasError) {
                if (isDataModified || isOrganizationAssociationDataModified || isStaggeredModified || excludedOrganizations.length > 0) {
                    Toast("Dados salvos com sucesso!", "Suas informações estão seguras e protegidas.", "success");
                }

                onUpdate();
                toGoBack();
                setPageStatus("awaiting");
                setUpdatedUser(true);
                setIsDataModified(false);
                setIsOrganizationAssociationDataModified(false);
                setIsOrganizationDataModified(false);
                setIsStaggeredModified(false);
            } else {
                Toast(
                    "Não foi possível atualizar o usuário!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                );
                throw new Error("Alguma operação falhou");
            }
        } catch (error) {
            console.error("Erro ao atualizar usuário:", error);
            Toast("Erro ao atualizar usuário!", "Um erro inesperado aconteceu.", "error");
        } finally {
            setPageStatus("awaiting");
        }
    };

    async function getOrganizationsList() {
        const response = await APISla.listOrganizations(token);

        if (response.status === 200) {
            let dataUpdatedOrganization = [];

            response.data.forEach((data) => {
                let requestedItemOrganization = {};

                requestedItemOrganization.id = data.id_organizacao;
                requestedItemOrganization.label = data.nome;

                dataUpdatedOrganization.push(requestedItemOrganization);
            });

            setOrganizations(dataUpdatedOrganization)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem de organizações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getGroupsList() {
        const response = await APIUser.getUserGroup(token)

        if (response.status === 200) {
            let dataUpdatedGroup = [];

            response.data.forEach((data) => {
                let requestedItemGroup = {};

                requestedItemGroup.id = data.idgrupo;
                requestedItemGroup.label = data.desc_grupo

                dataUpdatedGroup.push(requestedItemGroup);
            });

            setGroups(dataUpdatedGroup)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem dos grupos!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getProfilesList() {
        const response = await APIUser.getUserProfile(token)

        if (response.status === 200) {
            let dataUpdatedProfile = [];

            response.data.forEach((data) => {
                let requestedItemProfile = {};

                requestedItemProfile.id = data.id_perfil;
                requestedItemProfile.label = data.desc_perfil;

                dataUpdatedProfile.push(requestedItemProfile)
            });

            setProfiles(dataUpdatedProfile)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem dos perfis!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getFunctionsList() {
        const response = await APIUser.getUserFunction(token)

        if (response.status === 200) {
            let dataUpdatedFunction = [];

            response.data.forEach((data) => {
                let requestedItemFunction = {};

                requestedItemFunction.id = data.idfuncao;
                requestedItemFunction.label = data.desc_funcao;

                dataUpdatedFunction.push(requestedItemFunction)
            });

            setFunctions(dataUpdatedFunction)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem das funções!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getViewTypeList() {
        const response = await APIUser.getViewType(token)

        if (response.status === 200) {
            let dataUpdatedViewType = [];

            response.data.forEach((data) => {
                let requestedItemViewType = {};

                requestedItemViewType.id = data.tipo_visualizacao;
                requestedItemViewType.label = data.desc_tipo_visualizacao;

                dataUpdatedViewType.push(requestedItemViewType);
            });

            setViewType(dataUpdatedViewType)
        } else {
            Toast(
                "Falha, não foi possível carregar a listagem dos tipos de visualizações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    };

    async function getStaggeredList() {
        setLoading(true);

        const response = await APICase.getEscalateList(token);

        if (response.status === 200) {
            setStaggeredList(response.data);
        } else {
            Toast(
                "Falha, ocorreu um erro ao tentar obter os dados da lista de escalonados.",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setLoading(false);
    };

    async function getDataFromSelects() {
        setPageStatus("requesting");

        try {
            await Promise.allSettled([
                getOrganizationsList(),
                getGroupsList(),
                getProfilesList(),
                getFunctionsList(),
                getViewTypeList()
            ])
        } catch (error) {
            console.error('Ocorreu um erro ao buscar dados dos selects:', error);

            Toast(
                "Falha, não foi possível carregar os dados dos selects",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }

        setPageStatus("awaiting");
    };

    //USE MEMOS
    const tableColumns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                id: "name",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            },
            {
                Header: "Visualizar",
                accessor: "toView",
                id: "toView",
                disableFilters: true,
                display: true,
                sortType: defaultSorting,
            }
        ]
    );

    const staggeredData = useMemo(() => {
        if (staggeredNewData) {
            const refactoredData = refactorStaggeredListing(staggeredNewData);
            return refactoredData;
        }
    }, [staggeredNewData])

    //USE EFFECTS
    useEffect(() => {
        if (userId) {
            getValues(userId);
        }
    }, [userId]);

    useEffect(() => {
        getDataFromSelects();
        getStaggeredList();
    }, []);

    useEffect(() => {
        let updatedData = [];

        staggeredList.forEach(staggered => {
            const staggeredData = data?.escalonamentos.find(
                s => s.id_escalonamento === staggered.id_escalonamento
            );

            if (staggeredData) {
                updatedData.push({
                    ...staggered,
                    to_view: true
                });
            } else {
                updatedData.push({
                    ...staggered,
                    to_view: false
                });
            }
        });

        setStaggeredNewData(updatedData);
    }, [staggeredList.length === 0, data?.escalonamentos]);

    useEffect(() => {
        setData(prevUserValues => {
            if (organizationAssociationData?.idorganizacao) {
                return {
                    ...prevUserValues,
                    id_organizacao: organizationAssociationData?.idorganizacao,
                    org_data_main: {
                        idorganizacao: organizationAssociationData.idorganizacao,
                        nome_organizacao: organizationAssociationData.nome_organizacao,
                        tipo_visualizacao: organizationAssociationData.tipo_visualizacao,
                        desc_tipo_visualizacao: organizationAssociationData.desc_tipo_visualizacao,
                        id_criacao: organizationAssociationData.id_criacao,
                        id_fecharcaso: organizationAssociationData.id_fecharcaso,
                        desc_criacao: organizationAssociationData.desc_criacao,
                        desc_fecharcaso: organizationAssociationData.desc_fecharcaso,
                        criarcaso: organizationAssociationData.criarcaso,
                        fecharcaso: organizationAssociationData.fecharcaso,
                        id_organizacao_usuario: organizationAssociationData.id_organizacao_usuario
                    }
                };
            }
            return prevUserValues;
        });
    }, [organizationAssociationData]);

    return (
        <>
            <main className="page-update-user">
                <header className="page-update-user__header">
                    <div className="page-update-user__header__title">
                        <ArrowLefIcon onClick={toGoBack} />

                        <Typography.H2 color="gray-180" weight="bold">
                            Editar usuário
                        </Typography.H2>
                    </div>

                    <div className="page-update-user__header__save-or-cancel">
                        <Button
                            className="btn-remove-user"
                            icon={<CloseIcon />}
                            onClick={() => setIsOpenModalDeleteUser({ state: true })}
                        >
                            <Typography.H4 weight="bold" color="red-2">
                                Excluir usuário
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-reset-user"
                            onClick={() => setIsOpenModalRedefinePassword(true)}
                        >
                            <Typography.H4 weight="bold" color="blue-selected-4">
                                Redefinir senha
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-cancel-user"
                            disabled={pageStatus === "updating-user"}
                            onClick={() => {
                                toGoBack();
                                setIsDataModified(false);
                                setIsOrganizationAssociationDataModified(false);
                            }}
                        >
                            <Typography.H4 weight="bold" color="blue-selected-4">
                                Cancelar
                            </Typography.H4>
                        </Button>

                        <Button
                            className="btn-save-user"
                            disabled={pageStatus === "updating-user"}
                            onClick={handleUpdateUser}
                        >
                            {pageStatus !== "updating-user" ? (
                                <Typography.H4 weight="bold" color="white">
                                    Salvar
                                </Typography.H4>
                            ) : (
                                <Loading.Dots />
                            )}
                        </Button>
                    </div>
                </header>

                <div className="section-container">
                    <section className="basic-data-section">
                        <header className="basic-data-section-header">
                            <div className="basic-data-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Dados básicos
                                </Typography.H4>
                            </div>

                            <div className="basic-data-section-header__division" />
                        </header>

                        <div className="basic-data-content-container">
                            <div className="basic-data-content-container__content">
                                <div className="columns-data">
                                    <FieldContainer title="Nome" required>
                                        <Input
                                            type="text"
                                            placeholder="Digite o nome aqui"
                                            value={data?.nome_usuario}
                                            onChange={(event) => setDataAndMarkModified({ ...data, nome_usuario: event })}
                                            error={validationWarn ? !data?.nome_usuario : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Login" required>
                                        <Input
                                            type="text"
                                            placeholder="Digite o login aqui"
                                            value={data?.login_usuario}
                                            onChange={(event) => setDataAndMarkModified({ ...data, login_usuario: event })}
                                            error={validationWarn ? !data?.login_usuario : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Matrícula(Opcional)">
                                        <Input
                                            type="text"
                                            placeholder="Digite a matrícula aqui"
                                            value={data?.matricula}
                                            onChange={(event) => setDataAndMarkModified({ ...data, matricula: event })}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Perfil" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione uma opção"
                                            value={data?.id_perfil}
                                            options={profiles}
                                            onSelect={(profile) => {
                                                const values = { ...data };

                                                values.id_perfil = profile.id
                                                values.perfil_usuario = profile.label

                                                setDataAndMarkModified(values);
                                            }}
                                            error={validationWarn ? !data?.id_perfil : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Função" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione uma opção"
                                            value={data?.id_funcao}
                                            options={functions}
                                            onSelect={(item) => {
                                                const values = { ...data };

                                                values.id_funcao = item.id
                                                values.desc_funcao = item.label

                                                setDataAndMarkModified(values);
                                            }}
                                            error={validationWarn ? !data?.id_funcao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Grupo" required>
                                        <CustomDropdown
                                            noTippy
                                            changeDownArrowIcon
                                            placeholder="Selecione"
                                            value={data?.idgrupo}
                                            options={groups}
                                            // optionsLimit={enterprisesLimit}
                                            // setOptionsLimit={setEnterprisesLimit}
                                            onSelect={(group) => {
                                                const values = { ...data };

                                                values.idgrupo = group.id
                                                values.desc_grupo = group.label

                                                setDataAndMarkModified(values);
                                            }}
                                            showCustomEmptyState={true}
                                            customEmptyStateIcon={
                                                <SearchIcon style={{ width: "48px", height: "48px" }} />
                                            }
                                            // onCustomEmptyStateClick={() => { }}
                                            customEmptyStateMessage={
                                                // <span>
                                                //     Não encontramos nenhum grupo. <br />
                                                //     Deseja adicionar este contato?
                                                // </span>
                                                <span>
                                                    Não foram encontrados grupos. <br />
                                                    Para adicionar um novo grupo, digite o nome e clique em "Enter".
                                                </span>
                                            }
                                            // buttonText="Adicionar Grupo"
                                            onEnterPress={handleGroupCreationOnDownKey}
                                            error={validationWarn ? !data?.idgrupo : false}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="join-organization-section">
                        <header className="join-organization-section-header">
                            <div className="join-organization-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Organização
                                </Typography.H4>
                            </div>

                            <div className="join-organization-section-header__division" />
                        </header>

                        <div className="join-organization-content-container">
                            <div className="join-organization-content-container__content">
                                <div className="first-column-data">
                                    <FieldContainer title="Organização" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione uma opção"
                                            value={organizationAssociationData?.idorganizacao}
                                            options={organizations.filter(org => !data?.organizacoes.some(item => item.idorganizacao === org.id))}
                                            onSelect={(organization) => {
                                                const copyingValues = { ...organizationAssociationData };

                                                copyingValues.idorganizacao = organization.id;
                                                copyingValues.nome_organizacao = organization.label

                                                setModifiedOrganizationData(copyingValues)
                                            }}
                                            error={validationWarn ? !organizationAssociationData?.idorganizacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Permitir o usuário criar casos?" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione"
                                            value={organizationAssociationData.id_criacao}
                                            options={optionsCaseCreation}
                                            onSelect={(userPermission) => {
                                                const copyingValues = { ...organizationAssociationData };

                                                copyingValues.id_criacao = userPermission.id;
                                                copyingValues.desc_criacao = userPermission.label;
                                                copyingValues.criarcaso = userPermission.id === 1 ? true : false

                                                setModifiedOrganizationData(copyingValues)
                                            }}
                                            error={validationWarn ? !organizationAssociationData?.id_criacao : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Permitir o usuário fechar casos?" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Selecione"
                                            value={organizationAssociationData.id_fecharcaso}
                                            options={caseClosureOptions}
                                            onSelect={(closeCase) => {
                                                const copyingValues = { ...organizationAssociationData };

                                                copyingValues.id_fecharcaso = closeCase.id;
                                                copyingValues.desc_fecharcaso = closeCase.label;
                                                copyingValues.fecharcaso = closeCase.id === 1 ? true : false;

                                                setModifiedOrganizationData(copyingValues)
                                            }}
                                            error={validationWarn ? !organizationAssociationData?.id_fecharcaso : false}
                                        />
                                    </FieldContainer>

                                    <FieldContainer title="Tipo de visualização" required>
                                        <Select
                                            changeDownArrowIcon
                                            placeholder="Apenas casos criados pelo usuário"
                                            value={organizationAssociationData.tipo_visualizacao}
                                            options={viewType}
                                            onSelect={(viewType) => {
                                                const copyingValues = { ...organizationAssociationData };

                                                copyingValues.tipo_visualizacao = viewType.id;
                                                copyingValues.desc_tipo_visualizacao = viewType.label

                                                setModifiedOrganizationData(copyingValues)
                                            }}
                                            error={validationWarn ? !organizationAssociationData?.id_fecharcaso : false}
                                        />
                                    </FieldContainer>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="join-organization-section">
                        <header className="join-organization-section-header">
                            <div className="join-organization-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Associar a outras organizações
                                </Typography.H4>

                                <div
                                    className={`join-organization-section-header__clear-fields ${!isClearFieldsEnabled && "cursor-notAllowed"}`}
                                    onClick={() => {
                                        if (isClearFieldsEnabled)
                                            handleClearFields()
                                    }}
                                >
                                    <CleanIcon
                                        className={`${isClearFieldsEnabled ? "clean-icon" : "disabled-icon"}`}
                                    />

                                    <Typography.H5
                                        weight="400"
                                        color={isClearFieldsEnabled ? "red-2" : "gray-125"}
                                    >
                                        Limpar campos
                                    </Typography.H5>
                                </div>
                            </div>

                            <div className="join-organization-section-header__division" />
                        </header>

                        <div className="join-organization-content-container">
                            <div className="join-organization-content-container__content">
                                {data?.organizacoes.map((item, index) => {
                                    const { idorganizacao, tipo_visualizacao, id_criacao, id_fecharcaso } = item;

                                    const filteredOrganizations = organizations.filter(org => {
                                        return !data?.organizacoes.slice(0, index)
                                            .some(prevItem => prevItem.idorganizacao === org.id);
                                    });

                                    const filteredMainOrganization = filteredOrganizations.filter(org => org.id !== organizationAssociationData?.idorganizacao);

                                    return (
                                        <div
                                            key={item.id_organizacao_usuario}
                                            className="first-column-data"
                                        >
                                            <FieldContainer title="Organização">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Selecione"
                                                    value={idorganizacao}
                                                    options={filteredMainOrganization}
                                                    onSelect={(organization) => handleFieldChange(
                                                        organization.id,
                                                        organization.label,
                                                        'idorganizacao',
                                                        'nome_organizacao',
                                                        index
                                                    )}
                                                />
                                            </FieldContainer>

                                            <FieldContainer title="Permitir o usuário criar casos?">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Selecione"
                                                    value={id_criacao}
                                                    options={optionsCaseCreation}
                                                    onSelect={(userPermission) => handleCreationOption(userPermission, index)}
                                                />
                                            </FieldContainer>

                                            <FieldContainer title="Permitir o usuário fechar casos?">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Selecione"
                                                    value={id_fecharcaso}
                                                    options={caseClosureOptions}
                                                    onSelect={(closeCase) => handleCaseClosureOption(closeCase, index)}
                                                />
                                            </FieldContainer>

                                            <FieldContainer title="Tipo de visualização">
                                                <Select
                                                    changeDownArrowIcon
                                                    placeholder="Apenas casos criados pelo usuário"
                                                    value={tipo_visualizacao}
                                                    options={viewType}
                                                    onSelect={(viewType) => handleFieldChange(
                                                        viewType.id,
                                                        viewType.label,
                                                        'tipo_visualizacao',
                                                        'desc_tipo_visualizacao',
                                                        index
                                                    )}
                                                />
                                            </FieldContainer>

                                            <div className="first-column-data__actions">
                                                <MinusCircleIcon
                                                    className={`remove-association ${data?.organizacoes.length === 1 && "disabled"}`}
                                                    onClick={(e) => {
                                                        if (data?.organizacoes.length === 1) return;

                                                        e.preventDefault();
                                                        handleRemoveAssociation(index)
                                                    }}
                                                />

                                                {!allOrganizationsAssociated() && (
                                                    <AddCircleIcon
                                                        className="add-association"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleAssociationAddition()
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="staggered-views-section">
                        <header className="staggered-views-section-header">
                            <div className="staggered-views-section-header__content">
                                <Typography.H4 weight="700" color="gray-180">
                                    Quais escalonados posso visualizar?
                                </Typography.H4>

                                <button onClick={handleSelectAll}>
                                    <TickSquareIcon />

                                    <Typography.H4 weight="500" color="blue-selected-4">
                                        Marcar todos
                                    </Typography.H4>
                                </button>
                            </div>

                            <div className="staggered-views-section-header__division" />
                        </header>

                        <div className="staggered-views-content-container">
                            <div className="staggered-views-content-container__content">
                                {loading ? (
                                    <div className="staggered-loading">
                                        <Loading.Paperplane />
                                    </div>
                                ) : (
                                    <Table
                                        fixedHeader
                                        noPagination
                                        className="table-staggered"
                                        columns={tableColumns}
                                        data={staggeredData}
                                    />
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </main>

            <Modal.DeleteUser
                returnToUserList={toGoBack}
                isOpen={isOpenModalDeleteUser.state}
                onClose={(event) => setIsOpenModalDeleteUser({
                    ...isOpenModalDeleteUser, state: event
                })}
                users={detailedUser}
                deletedUserId={data?.id_usuario_criado}
                loggedInUserId={userData.userId}
                onDelete={onUpdate}
                handleTableDataLookup={handleTableDataLookup}
            />

            <Modal.RedefinePassword
                isOpen={isOpenModalRedefinePassword}
                onClose={(event) => setIsOpenModalRedefinePassword(event)}
                userId={data?.id_usuario_criado}
            />
        </>
    )
}
