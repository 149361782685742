import instance from "./configAxios";

export async function login(loginData) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      login: loginData.login,
      password: loginData.password,
    };
    const response = await instance.post("/loginv2", data, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function loginMaster(loginData) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      login_user: loginData.login_user,
      login_master: loginData.login_master,
      password_master: loginData.password_master,
    };
    const response = await instance.post(
      "/login-master-integration",
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function loginMasterV2(loginData) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      login_user: loginData.login_user,
      login_master: loginData.login_master,
      password_master: loginData.password_master,
    };
    const response = await instance.post(
      "/login-master-integrationv3",
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function checkSession(token) {
  try {
    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    const data = {};
    const response = await instance.post("/validate-token", data, config);
    return response;
  } catch (error) {
    return error;
  }
}

export async function passwordReset(passwordResetData) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      login: passwordResetData.login,
      password: passwordResetData.password,
      passwordnew: passwordResetData.passwordnew,
    };
    const response = await instance.post("/alterarsenha", data, config);
    return response;
  } catch (error) {
    return error;
  }
}
