//GLOBAL - components from npm
import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//COMPONENTS
import { Loading, Modal } from "../../components/feedback";
import { Toast } from "../../utils/toast";
import { Button, Typography } from "../../components/general";
import { Input } from "../../components/data-entry";
import { PopoverMenu } from "../../components/navigation";

//SERVICES - api, conectors...
import * as APITypeManagement from "./../../services/api/typeManagement";
import * as APIMotive from "./../../services/api/motives";

//GLOBAL STATE - redux, env...

// UTILS
import { convertToHoursOrMinutes } from "../../utils/convertToHoursOrMinutes";

//ASSETS - icons, images...
import { ReactComponent as IconSearch } from "./../../assets/icons/Search-normal.svg";
import { ReactComponent as IconAddType } from "./../../assets/icons/IconAdd.svg";
import { ReactComponent as IconInfo } from "./../../assets/icons/InfoEmptyState.svg";
import { ReactComponent as IconDragDrop } from "./../../assets/icons/DragDropIcon.svg";
import { ReactComponent as IconMoreVertical } from "./../../assets/icons/MoreHorizontal.svg";
import { ReactComponent as IconArrowUp } from './../../assets/icons/ArrowUpTwo.svg';
import { ReactComponent as IconLittleBall } from './../../assets/icons/LittleBall.svg';
import { ReactComponent as EditIcon } from "./../../assets/icons/Edit-2.svg";
import { ReactComponent as TrashIcon } from "./../../assets/icons/Trash-2.svg";
import { ReactComponent as IconTickSquare } from "./../../assets/icons/TickSquare.svg";
import { ReactComponent as IconCloseSquare } from "./../../assets/icons/CloseSquare.svg";
import { ReactComponent as IconArrowLeft } from "./../../assets/icons/Arrow-left.svg";
import { ReactComponent as IconBuilding } from "./../../assets/icons/Building.svg";
import { ReactComponent as IconClose } from "./../../assets/icons/Close-Circle.svg";

//STYLES
import "./type-management.scss"

export default function TypeManagement() {
    //REDUX - Selectors
    const token = useSelector((state) => state.auth.token);
    const userData = useSelector((state) => state.auth.userData);

    //GENERAL
    const location = useLocation();
    const inputRef = useRef(null);
    const inputRefReason = useRef(null);
    const inputRefSubreason = useRef(null);
    const typeRef = useRef(null);
    const reasonRef = useRef(null);
    const subreasonRef = useRef(null);

    //STATES
    const [isOpenModalAddType, setIsOpenModalAddType] = useState(false);
    const [isOpenModalAddReason, setIsOpenModalAddReason] = useState(false);
    const [isOpenModalAddSubreason, setIsOpenModalAddSubreason] = useState(false);
    const [isOpenModalDeleteType, setIsOpenModalDeleteType] = useState(false);
    const [isOpenModalDeleteReason, setIsOpenModalDeleteReason] = useState(false);
    const [isOpenModalDeleteSubreason, setIsOpenModalDeleteSubreason] = useState(false);
    const [inputVisible, setInputVisible] = useState(false);
    const [reasonInputVisible, setReasonInputVisible] = useState(false);
    const [subreasonInputVisible, setSubreasonInputVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [typesLoading, setTypesLoading] = useState(false);
    const [reasonsLoading, setReasonsLoading] = useState(false);
    const [subreasonsLoading, setSuberasonsLoading] = useState(false);
    const [shouldReloadTypes, setShouldReloadTypes] = useState(false);
    const [organizationId, setOrganizationId] = useState(false || null);
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [selectedReasonId, setSelectedReasonId] = useState(null);
    const [editedReason, setEditedReason] = useState(null);
    const [editedSubreason, setEditedSubreason] = useState(null);
    const [valuesSelectedType, setValuesSelectedType] = useState(null);
    const [valuesSelectedReason, setValuesSelectedReason] = useState(null);
    const [pageStatus, setPageStatus] = useState("requesting");
    const [newReasonDesc, setNewReasonDesc] = useState("");
    const [newSubreasonDesc, setNewSubreasonDesc] = useState("");
    const [searchOrganizations, setSearchOrganizations] = useState("");
    const [searchTypes, setSearchTypes] = useState("");
    const [searchReasons, setSearchReasons] = useState("");
    const [searchSubreasons, setSearchSubreasons] = useState("");
    const [selectedTypeName, setSelectedTypeName] = useState("");
    const [selectedReasonName, setSelectedReasonName] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [treeData, setTreeData] = useState({ types: [] });
    const [reasonListValues, setReasonListValues] = useState([]);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [isOpenModalUpdateType, setIsOpenModalUpdateType] = useState({
        modal: false,
        typeValues: null,
        typeId: null
    });
    const [isOpenModalUpdateReason, setIsOpenModalUpdateReason] = useState({
        reasonValues: null,
        reasonDesc: "",
        modal: false
    });
    const [isOpenModalUpdateSubreason, setIsOpenModalUpdateSubreason] = useState({
        subreasonValues: null,
        subreasonDesc: "",
        modal: false
    });

    //FUNCTIONS
    const selectedType = treeData.types?.find(
        (type) => type.id_solicitation === selectedTypeId
    ) || null;

    const selectedReason = selectedType?.arrayOfReasons?.find(
        (type) => type.id_motivo === selectedReasonId
    ) || null;

    const filterOrganizations = organizationsList?.filter(
        (organization) => {
            return organization.nome
                .toLowerCase()
                .includes(searchOrganizations.toLowerCase());
        }
    );

    const filterTypes = treeData.types?.filter(
        (type) => {
            return type?.desc_solicitation
                .toLowerCase()
                .includes(searchTypes.toLowerCase());
        }
    );

    const filterReasons = selectedTypeId && selectedType?.arrayOfReasons?.filter(
        (reason) => {
            return reason.desc_motivo
                .toLowerCase()
                .includes(searchReasons.toLowerCase());
        }
    );

    const filterSubreasons = selectedReasonId && selectedReason?.subreasons?.filter(
        (reason) => {
            return reason.desc_submotivo
                .toLowerCase()
                .includes(searchSubreasons.toLowerCase());
        }
    );

    const handleOrganizationClick = (organizationId) => {
        setOrganizationId(organizationId)
    };

    const handleTypeClick = (type) => {
        setSelectedTypeId(
            (prevSelectedType) =>
                prevSelectedType === type.id_solicitation ?
                    null : type.id_solicitation
        )
    };

    const handleReasonClick = (reason) => {
        setSelectedReasonId(
            (prevSelectedReason) =>
                prevSelectedReason === reason.id_motivo ?
                    null : reason.id_motivo
        )
    };

    const handleVisibleInput = () => {
        setInputVisible(true);
    };

    const handleVisibleInputReason = () => {
        setReasonInputVisible(true);
    };

    const handleVisibleInputSubreason = () => {
        setSubreasonInputVisible(true);
    };

    const handleOutsideClick = (e) => {
        if (inputVisible && typeRef.current && !typeRef.current.contains(e.target)) {
            setInputVisible(false);
        }
    };

    const handleOutsideClickReason = (e) => {
        if (reasonInputVisible && reasonRef.current && !reasonRef.current.contains(e.target)) {
            setReasonInputVisible(false);
        }
    };

    const handleOutsideClickSubreason = (e) => {
        if (subreasonInputVisible && subreasonRef.current && !subreasonRef.current.contains(e.target)) {
            setSubreasonInputVisible(false);
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination, draggableId, type } = result;

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) return;

        let updatedTypes;

        if (type === "type") {
            updatedTypes = Array.from(treeData.types);
            const [removed] = updatedTypes.splice(source.index, 1);

            updatedTypes.splice(destination.index, 0, removed);

            setTreeData({ ...treeData, types: updatedTypes });
        } else if (type === "reason") {
            const sourceType = treeData.types.find((type) => type.id_solicitation === source.droppableId);
            const updatedReasons = Array.from(sourceType?.arrayOfReasons);
            const [removed] = updatedReasons.splice(source.index, 1);

            updatedReasons.splice(destination.index, 0, removed);

            updatedTypes = treeData.types.map((type) =>
                type.id_solicitation === source.droppableId ?
                    { ...type, arrayOfReasons: updatedReasons } : type
            );

            setTreeData({ ...treeData, types: updatedTypes });
            localStorage.setItem('dragAndDropTypeManagement', JSON.stringify(updatedTypes));
        }
    };

    function renderPopoverMenu(typeId, typeValues) {
        return [
            {
                title: (
                    <>
                        <Typography.H5 color="gray-180" weight="500">
                            <EditIcon />
                            Editar
                        </Typography.H5>
                    </>
                ),
                onClick: () => {
                    setIsOpenModalUpdateType({
                        modal: true,
                        typeId: typeId,
                        typeValues: typeValues
                    })
                },
            },
            {
                title: (
                    <>
                        <Typography.H5 color="red" weight="500">
                            <TrashIcon className="popover-icon" />
                            Excluir
                        </Typography.H5>
                    </>
                ),
                onClick: () => { setIsOpenModalDeleteType(Number(typeId), true) },
            },
        ];
    };

    function renderPopoverMenuReason(reasonId, reason) {
        return [
            {
                title: (
                    <>
                        <Typography.H5 color="gray-180" weight="500">
                            <EditIcon />
                            Editar
                        </Typography.H5>
                    </>
                ),
                onClick: () => {
                    setIsOpenModalUpdateReason({
                        ...isOpenModalUpdateReason,
                        modal: true,
                        reasonValues: reason,
                        reasonDesc: reason.desc_motivo
                    });
                },
            },
            {
                title: (
                    <>
                        <Typography.H5 color="red" weight="500">
                            <TrashIcon className="popover-icon" />
                            Excluir
                        </Typography.H5>
                    </>
                ),
                onClick: () => { setIsOpenModalDeleteReason(reasonId, true) },
            },
        ];
    };

    function renderPopoverMenuSubreason(subreasonId, subreason) {
        return [
            {
                title: (
                    <>
                        <Typography.H5 color="gray-180" weight="500">
                            <EditIcon />
                            Editar
                        </Typography.H5>
                    </>
                ),
                onClick: () => {
                    setIsOpenModalUpdateSubreason({
                        ...isOpenModalUpdateSubreason,
                        modal: true,
                        subreasonValues: subreason,
                        subreasonDesc: subreason.desc_submotivo
                    })
                },
            },
            {
                title: (
                    <>
                        <Typography.H5 color="red" weight="500">
                            <TrashIcon className="popover-icon" />
                            Excluir
                        </Typography.H5>
                    </>
                ),
                onClick: () => {
                    setIsOpenModalDeleteSubreason(subreasonId, true)
                },
            },
        ];
    };

    async function handleReasonUpdated(reasonId) {
        const values = {
            id_motivo: Number(reasonId),
            desc_motivo: newReasonDesc
        };

        const response = await APITypeManagement.updateReason(token, values);

        if (selectedTypeId) {
            if (response.status === 200) {
                const updatedTypes = treeData.types?.map((type) => {
                    if (type.id_solicitation === selectedTypeId) {
                        const updatedReasons = type.arrayOfReasons?.map(
                            (reason) => reason.id_motivo === reasonId ?
                                {
                                    desc_motivo: newReasonDesc,
                                    id_motivo: reasonId.toString(),
                                    editavel: reason.editavel,
                                    desc_sla: reason.desc_sla,
                                    solicitation_id: reason.solicitation_id,
                                    subreasons: reason.subreasons
                                } : reason
                        );

                        return { ...type, arrayOfReasons: updatedReasons }
                    }

                    return type
                });

                setTreeData((prevData) => ({
                    ...prevData,
                    types: updatedTypes
                }));

                Toast(
                    "Motivo atualizado!",
                    "Motivo atualizado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível atualizar o Motivo!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                )
            }
        }
    };

    async function handleSubreasonUpdated(subreasonId) {
        const values = {
            id_submotivo: Number(subreasonId),
            desc_submotivo: newSubreasonDesc
        };

        const response = await APITypeManagement.updateSubreason(token, values);

        if (selectedReasonId) {
            if (response.status === 200) {
                const updatedTypes = treeData.types.map((type) => {
                    if (type.id_solicitation === selectedTypeId) {
                        const updatedReasons = type.arrayOfReasons?.map((reason) => {
                            if (reason.id_motivo === selectedReasonId) {
                                const updatedSubreasons = reason.subreasons?.map(
                                    (subreason) => subreason.id_submotivo === subreasonId ?
                                        {
                                            reason_id: selectedReasonId,
                                            desc_submotivo: newSubreasonDesc,
                                            editavel: subreason.editavel,
                                            desc_sla: subreason.desc_sla,
                                            id_submotivo: subreasonId.toString()
                                        } : subreason
                                );

                                return {
                                    ...reason,
                                    subreasons: updatedSubreasons
                                }
                            }

                            return reason
                        });

                        return { ...type, arrayOfReasons: updatedReasons }
                    }

                    return type
                });

                setTreeData((prevData) => ({
                    ...prevData,
                    types: updatedTypes
                }))

                Toast(
                    "Submotivo atualizado!",
                    "Submotivo atualizado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível atualizar o Submotivo!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                )
            }
        }
    };

    async function getOrganizationsList() {
        setPageStatus("requesting");

        const response = await APITypeManagement.getOrganizationsList(token, userData.userId);

        if (response.status === 200) {
            setOrganizationsList(response.data)
        }

        if (loading === false) {
            setPageStatus("awaiting");
        }
    };

    async function getListOfReasonsForAddingType() {
        const response = await APIMotive.listMotives(token);

        if (response.status === 200) {
            setReasonListValues(response.data);
        }
    };

    async function getLoadedData() {
        try {
            await Promise.all([
                getOrganizationsList(),
                getListOfReasonsForAddingType()
            ])
        } catch (error) {
            console.error("Erro ao obter informações do endPoint", error);

            Toast(
                "Falha, não foi possível carregar as informações!",
                "Verifique sua conexão e tente novamente mais tarde.",
                "error"
            );
        }
    }

    //USE EFFECTS
    useEffect(() => {
        getLoadedData();
    }, []);

    useEffect(() => {
        const requestData = async () => {
            setLoading(true);

            const organization = organizationsList.find(org => org.id_organizacao === organizationId);

            if (!organization) {
                setLoading(false);
                return;
            }

            const typesResponse = await APITypeManagement.getList0fRequestTypesV3(
                token,
                organization.id_organizacao
            );
            const typesDataForOrganization = typesResponse.data;

            const uniqueDataTypes = [...new Set(typesDataForOrganization?.map((item) => item.id_solicitacao))].map((item) => {
                return {
                    desc_motivo: typesDataForOrganization?.find((value) => value.id_solicitacao == item).desc_motivo,
                    desc_sla: typesDataForOrganization?.find((value) => value.id_solicitacao == item).desc_sla,
                    descricao_solicitacao: typesDataForOrganization?.find((value) => value.id_solicitacao == item).descricao_solicitacao,
                    editavel: typesDataForOrganization?.find((value) => value.id_solicitacao == item).editavel,
                    id_sla: typesDataForOrganization?.find((value) => value.id_solicitacao == item).id_sla,
                    id_tipo_solicitacao_sla: typesDataForOrganization?.find((value) => value.id_solicitacao == item).id_tipo_solicitacao_sla,
                    sla_resolucao: typesDataForOrganization?.find((value) => value.id_solicitacao == item).sla_resolucao,
                    qtde_motivo: typesDataForOrganization?.find((value) => value.id_solicitacao == item).qtde_motivo,
                    id_solicitacao: item,
                    idmotivos: typesDataForOrganization?.filter((value) => value.id_solicitacao == item).map((data) => data.idmotivos),
                };
            });

            const transformedData = uniqueDataTypes?.map((type) => {
                const uniqueReasons = [...new Set(type.idmotivos)];

                const reasons = uniqueReasons.map((reasonId) => {
                    if (reasonId !== "") {
                        const numeros = reasonId?.replace('[', '')
                            .replace(']', '')
                            .split(',')
                            .map(substring => parseFloat(substring.trim()));

                        return numeros
                    } else return []
                });

                const correspondingReasons = [];

                reasonListValues.forEach((reason) => {
                    const reasonFound = reasons.find((value) =>
                        value.some((id => id === reason.id_motivo))
                    );

                    if (reasonFound) {
                        correspondingReasons.push({
                            id_motivo: reason.id_motivo,
                            desc_motivo: reason.desc_motivo,
                        });
                    }
                });

                return {
                    organization_id: organization.id_organizacao,
                    organization_name: organization.nome,
                    desc_reason: type.desc_motivo,
                    desc_sla: type.desc_sla,
                    desc_solicitation: type.descricao_solicitacao,
                    editavel: type.editavel,
                    id_sla: type.id_sla,
                    sla_resolution: type.sla_resolucao,
                    amountOfReasons: type.qtde_motivo,
                    id_type_solicitation_sla: type.id_tipo_solicitacao_sla,
                    id_solicitation: type.id_solicitacao.toString(),
                    reasonIdList: reasons,
                    reasonsList: correspondingReasons
                }
            });

            const typesDataWithReasons = await Promise.all(
                transformedData.map(async (type) => {
                    if (selectedTypeId !== null && selectedTypeId === type.id_solicitation) {
                        const reasonsResponse = await APITypeManagement.getReasonsListToTypeV2(
                            token,
                            Number(type.id_solicitation),
                            organization.id_organizacao
                        );
                        const reasonsDataForType = reasonsResponse.data;

                        const reasonsDataWithSubreasons = await Promise.all(
                            reasonsDataForType.map(async (reason) => {
                                if (selectedReasonId !== null && Number(selectedReasonId) === reason.id_motivo) {
                                    setSuberasonsLoading(true);

                                    const subreasonsResponse = await APITypeManagement.getSubReasonsListToTypeV4(
                                        token,
                                        reason.id_motivo,
                                        Number(type.id_solicitation),
                                        organization.id_organizacao
                                    );
                                    const subreasonsDataForReason = subreasonsResponse.data;

                                    setSuberasonsLoading(false);

                                    setValuesSelectedReason({
                                        ...reason,
                                        solicitation_id: type.id_solicitation,
                                        subreasons: subreasonsDataForReason?.map(subreason => {
                                            return {
                                                reason_id: reason.id_motivo.toString(),
                                                id_submotivo: subreason.id_submotivo.toString(),
                                                desc_submotivo: subreason.desc_submotivo,
                                                id_sla: subreason.id_sla,
                                                editavel: subreason.editavel,
                                                desc_sla: subreason.desc_sla
                                            };
                                        })
                                    })

                                    reason.id_motivo = reason.id_motivo.toString();

                                    return {
                                        ...reason,
                                        solicitation_id: type.id_solicitation,
                                        subreasons: subreasonsDataForReason?.map(subreason => {
                                            return {
                                                reason_id: reason.id_motivo.toString(),
                                                id_submotivo: subreason.id_submotivo.toString(),
                                                desc_submotivo: subreason.desc_submotivo,
                                                id_sla: subreason.id_sla,
                                                editavel: subreason.editavel,
                                                desc_sla: subreason.desc_sla
                                            };
                                        })
                                    };
                                } else {
                                    reason.id_motivo = reason.id_motivo.toString();

                                    return { ...reason }
                                }
                            })
                        );

                        setValuesSelectedType({
                            ...type,
                            arrayOfReasons: reasonsDataWithSubreasons
                        })

                        return {
                            ...type,
                            arrayOfReasons: reasonsDataWithSubreasons
                        };
                    } else {
                        return { ...type }
                    }
                })
            );

            if (typesDataWithReasons.length > 0) {
                setTreeData({ types: typesDataWithReasons });

                setShouldReloadTypes(false);
                setLoading(false);
            }
        }

        if (shouldReloadTypes || organizationId !== null) {
            requestData();
        }
    }, [
        organizationId,
        shouldReloadTypes,
        organizationsList,
        selectedReasonId,
        selectedTypeId,
        reasonListValues,
    ]);

    useEffect(() => {
        const eventListeners = [
            { visible: inputVisible, handler: handleOutsideClick },
            { visible: reasonInputVisible, handler: handleOutsideClickReason },
            { visible: subreasonInputVisible, handler: handleOutsideClickSubreason }
        ];

        eventListeners.forEach(({ visible, handler }) => {
            if (visible) {
                document.addEventListener('click', handler);
            } else {
                document.removeEventListener('click', handler);
            }
        });

        return () => {
            eventListeners.forEach(({ handler }) => {
                document.removeEventListener('click', handler);
            });
        };
    }, [inputVisible, reasonInputVisible, subreasonInputVisible]);

    useEffect(() => {
        treeData.types?.forEach(item => {
            item = Object.assign(item, { id_editavel: null, desc_editavel: null })

            if (item.editavel === true) {
                item.id_editavel = 1
                item.desc_editavel = "Sim"
            } else {
                item.id_editavel = 2
                item.desc_editavel = "Não"
            }
        })
    }, [treeData]);

    return (
        <>
            <main className={`type-management ${location.pathname === "/type-management" ? "page-modify" : ""}`}>
                {
                    pageStatus === "requesting" ? (
                        <section className="type-management__empty-state">
                            <Loading.Paperplane className="loading-list-type" />
                        </section>
                    ) : (
                        <>
                            {
                                pageStatus === "awaiting" && (
                                    <>
                                        {organizationId === null && (
                                            <>
                                                <header className="type-management__header" style={{ marginLeft: "10px" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <div className="type-management__header__organization">
                                                            <Typography.H2 color="gray-180" weight="bold">
                                                                Gerenciador de tipos
                                                            </Typography.H2>

                                                            <Typography.H4 color="gray-180" weight="400">
                                                                Selecione uma organização para gerenciar os tipos, motivos e submotivos.
                                                            </Typography.H4>

                                                            {organizationsList.length > 10 && (
                                                                <Input
                                                                    placeholder="Buscar por organização"
                                                                    value={searchOrganizations}
                                                                    onChange={(event) => setSearchOrganizations(event)}
                                                                    rightIcon={
                                                                        searchOrganizations !== "" ?
                                                                            <IconClose
                                                                                onClick={() => setSearchOrganizations("")}
                                                                                style={{ cursor: "pointer" }}
                                                                            /> :
                                                                            <IconSearch />
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </header>

                                                <div className="type-management__body" style={{ height: "auto", marginLeft: "10px" }}>
                                                    <section className="organizations-container-section">
                                                        <div className="organization-list">
                                                            {filterOrganizations.length === 0 ? (
                                                                <Typography.H4 weight="500" color="gray-180">
                                                                    Nenhum resultado foi encontrado.
                                                                    Tente novamente!
                                                                </Typography.H4>
                                                            ) : (
                                                                filterOrganizations?.map((organization) => {
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                key={organization.id_organizacao}
                                                                                className="organization-list__organization"
                                                                                onClick={() => {
                                                                                    handleOrganizationClick(organization.id_organizacao);
                                                                                    setOrganizationName(organization.nome)
                                                                                    setTypesLoading(true);
                                                                                }}
                                                                            >
                                                                                <IconBuilding />

                                                                                <Typography.H4 weight="500" color="gray-180">
                                                                                    {organization.nome}
                                                                                </Typography.H4>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            )}
                                                        </div>
                                                    </section>
                                                </div>
                                            </>
                                        )}

                                        {organizationId && (
                                            <>
                                                {typesLoading ? (
                                                    <section className="type-management__empty-state">
                                                        <Loading.Paperplane className="loading-list-type" />
                                                    </section>
                                                ) : (
                                                    <>
                                                        {treeData.types.length === 0 && (
                                                            <header className="type-management__header">
                                                                <div>
                                                                    <div className="type-management__header__title">
                                                                        <Typography.H2 color="gray-180" weight="bold">
                                                                            Gerenciador de tipos
                                                                        </Typography.H2>
                                                                    </div>
                                                                </div>
                                                            </header>
                                                        )}

                                                        {treeData.types.length > 0 ? (
                                                            <>
                                                                <div className="type-management__body">
                                                                    <section className={`typ-container-section ${!selectedType ? "overall-width" : ""}`}>
                                                                        <div className="typ-container-section__left">
                                                                            {inputVisible ? (
                                                                                <Input
                                                                                    placeholder="Buscar"
                                                                                    inputRef={inputRef}
                                                                                    className="search-types"
                                                                                    onChange={(event) => setSearchTypes(event)}
                                                                                    rightIcon={
                                                                                        <><IconSearch /></>
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <header className="typ-container-section__left__header">
                                                                                    <div className="title">
                                                                                        <IconArrowLeft onClick={() => {
                                                                                            setOrganizationId(null);
                                                                                            setTreeData({ types: [] })
                                                                                        }} />

                                                                                        <Typography.H2 color="gray-180" weight="bold">
                                                                                            Gerenciador de tipos
                                                                                        </Typography.H2>
                                                                                    </div>

                                                                                    <div className="options">
                                                                                        <button onClick={handleVisibleInput}>
                                                                                            <IconSearch />
                                                                                        </button>

                                                                                        <button onClick={() => setIsOpenModalAddType(true)}>
                                                                                            <IconAddType />
                                                                                        </button>
                                                                                    </div>
                                                                                </header>
                                                                            )}

                                                                            <DragDropContext>
                                                                                <div className="types-list">
                                                                                    <div>
                                                                                        <Typography.H4 color="blue-selected-4" weight="500">
                                                                                            {organizationName}
                                                                                        </Typography.H4>

                                                                                        <Typography.H4 color="gray-180" weight="500">
                                                                                            /  Gerenciador de tipos
                                                                                        </Typography.H4>
                                                                                    </div>

                                                                                    <Droppable droppableId="types" type="type">
                                                                                        {(provided) => (
                                                                                            <div
                                                                                                className="droppable"
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.droppableProps}
                                                                                            >
                                                                                                {filterTypes.length === 0 ? (
                                                                                                    <Typography.H4 color="gray-180">
                                                                                                        Nenhum Tipo cadastrado com esse nome!
                                                                                                    </Typography.H4>
                                                                                                ) : (
                                                                                                    filterTypes?.map((type, typeIndex) => {
                                                                                                        return (
                                                                                                            <Draggable
                                                                                                                key={type.id_solicitation}
                                                                                                                draggableId={type.id_solicitation}
                                                                                                                index={typeIndex}
                                                                                                                type="type"
                                                                                                            >
                                                                                                                {(provided) => (
                                                                                                                    <div
                                                                                                                        className={`types-list__content ${type === selectedType ? "active" : ""}`}
                                                                                                                        ref={provided.innerRef}
                                                                                                                        {...provided.draggableProps}
                                                                                                                        {...provided.dragHandleProps}
                                                                                                                    >
                                                                                                                        <div className="types-list__content__items">
                                                                                                                            <button
                                                                                                                                className={`button-drag-drop ${type === selectedType ? "active" : ""}`}
                                                                                                                            >
                                                                                                                                <IconDragDrop />
                                                                                                                            </button>

                                                                                                                            <div className="data-container">
                                                                                                                                <div className="data-container__text">
                                                                                                                                    <Typography.H4
                                                                                                                                        weight="bold"
                                                                                                                                        color={type === selectedType ? "blue-selected-4" : "gray-180"}
                                                                                                                                    >
                                                                                                                                        {type.desc_solicitation}
                                                                                                                                    </Typography.H4>
                                                                                                                                    <Typography.H5
                                                                                                                                        weight="400"
                                                                                                                                        color={type === selectedType ? "blue-selected-4" : "gray-180"}
                                                                                                                                        className="sla-information"
                                                                                                                                    >
                                                                                                                                        {type.desc_sla != null ? (
                                                                                                                                            <>
                                                                                                                                                {
                                                                                                                                                    type.desc_sla?.length > 15 ?
                                                                                                                                                        type.desc_sla?.substring(0, 15).concat("...") : type.desc_sla
                                                                                                                                                }

                                                                                                                                                <IconLittleBall />

                                                                                                                                                {convertToHoursOrMinutes(
                                                                                                                                                    type.sla_resolution,
                                                                                                                                                    "h",
                                                                                                                                                    "m"
                                                                                                                                                )}

                                                                                                                                                <IconLittleBall />

                                                                                                                                                {type.editavel === true ? "Editável" : "Não editável"}
                                                                                                                                            </>
                                                                                                                                        ) : "Nenhum SLA associado"}
                                                                                                                                    </Typography.H5>
                                                                                                                                    <Typography.H5
                                                                                                                                        weight="400"
                                                                                                                                        color={type === selectedType ? "blue-selected-4" : "gray-180"}
                                                                                                                                    >
                                                                                                                                        {type.amountOfReasons !== "0" ? (
                                                                                                                                            <>
                                                                                                                                                {`${type.amountOfReasons} motivo${type.amountOfReasons === "1" ? "" : "s"}`}
                                                                                                                                            </>
                                                                                                                                        ) : "Nenhum motivo cadastrado"}
                                                                                                                                    </Typography.H5>
                                                                                                                                </div>

                                                                                                                                <div className="data-container__menu">
                                                                                                                                    <button className={`icon-more-vertical ${type === selectedType ? "active" : ""}`}>
                                                                                                                                        <PopoverMenu
                                                                                                                                            placement="bottom-end"
                                                                                                                                            menuData={renderPopoverMenu(type.id_solicitation, type)}
                                                                                                                                        >
                                                                                                                                            <IconMoreVertical />
                                                                                                                                        </PopoverMenu>
                                                                                                                                    </button>

                                                                                                                                    <button
                                                                                                                                        ref={typeRef}
                                                                                                                                        className={`icon-arrow-up ${type === selectedType ? "active" : ""}`}
                                                                                                                                        onClick={() => {
                                                                                                                                            handleTypeClick(type)

                                                                                                                                            setSelectedTypeName(type.desc_solicitation)
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <IconArrowUp />
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Draggable>
                                                                                                        )
                                                                                                    })
                                                                                                )}
                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Droppable>
                                                                                </div>
                                                                            </DragDropContext>
                                                                        </div>

                                                                        {!selectedType ? (
                                                                            <section className="typ-container-section__empty-state">
                                                                                <div className="typ-container-section__empty-state__icon">
                                                                                    <IconInfo />
                                                                                </div>

                                                                                <Typography.H4 weight="500" color="gray-180">
                                                                                    Nenhum tipo foi selecionado
                                                                                </Typography.H4>
                                                                            </section>
                                                                        ) : (
                                                                            <>
                                                                                {reasonsLoading ? (
                                                                                    <div className="conatiner-emptyState-reason">
                                                                                        <section className="typ-management-empty-state-reason">
                                                                                            <Loading.Paperplane className="loading-list-reason" />
                                                                                        </section>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            selectedType && selectedType?.arrayOfReasons?.length === 0 ? (
                                                                                                <div className="conatiner-emptyState-reason">
                                                                                                    <header className="typ-container-section__middle__header">
                                                                                                        <div className="title">
                                                                                                            <Typography.H2 color="gray-180" weight="bold">
                                                                                                                {selectedTypeName}
                                                                                                            </Typography.H2>
                                                                                                        </div>

                                                                                                        <div className="options">
                                                                                                            <button>
                                                                                                                <IconSearch />
                                                                                                            </button>

                                                                                                            <button onClick={() => setIsOpenModalAddReason(true)}>
                                                                                                                <IconAddType />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </header>

                                                                                                    <section className="typ-management-empty-state-reason">
                                                                                                        <div className="typ-management-empty-state-reason__icon">
                                                                                                            <IconInfo />
                                                                                                        </div>

                                                                                                        <Typography.H4 weight="500" color="gray-180">
                                                                                                            Nenhum motivo associado a esse tipo
                                                                                                        </Typography.H4>
                                                                                                    </section>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {
                                                                                                        selectedType && selectedType?.arrayOfReasons?.length > 0 && (
                                                                                                            <div className="typ-container-section__middle">
                                                                                                                {reasonInputVisible ? (
                                                                                                                    <Input
                                                                                                                        placeholder="Buscar"
                                                                                                                        inputRef={inputRefReason}
                                                                                                                        className="search-types"
                                                                                                                        onChange={(event) => setSearchReasons(event)}
                                                                                                                        rightIcon={
                                                                                                                            <><IconSearch /></>
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <header className="typ-container-section__middle__header">
                                                                                                                        <div className="title">
                                                                                                                            <Typography.H2 color="gray-180" weight="bold">
                                                                                                                                {selectedTypeName}
                                                                                                                            </Typography.H2>
                                                                                                                        </div>

                                                                                                                        <div className="options">
                                                                                                                            <button onClick={handleVisibleInputReason}>
                                                                                                                                <IconSearch />
                                                                                                                            </button>

                                                                                                                            <button onClick={() => setIsOpenModalAddReason(true)}>
                                                                                                                                <IconAddType />
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </header>
                                                                                                                )}

                                                                                                                <DragDropContext>
                                                                                                                    <div className="types-list">
                                                                                                                        <Droppable droppableId="types" type="reason">
                                                                                                                            {(provided) => (
                                                                                                                                <div
                                                                                                                                    className="droppable"
                                                                                                                                    ref={provided.innerRef}
                                                                                                                                    {...provided.droppableProps}
                                                                                                                                >
                                                                                                                                    {filterReasons?.length === 0 ? (
                                                                                                                                        <Typography.H4 color="gray-180">
                                                                                                                                            Nenhum Motivo associado com esse nome!
                                                                                                                                        </Typography.H4>
                                                                                                                                    ) : (
                                                                                                                                        <>
                                                                                                                                            {filterReasons.map((reason, reasonIndex) => {
                                                                                                                                                return (
                                                                                                                                                    <Draggable
                                                                                                                                                        key={reason.id_motivo}
                                                                                                                                                        draggableId={reason.id_motivo}
                                                                                                                                                        index={reasonIndex}
                                                                                                                                                        type="reason"
                                                                                                                                                    >
                                                                                                                                                        {(provided) => (
                                                                                                                                                            <div
                                                                                                                                                                className={`types-list__content ${reason === selectedReason ? "active" : ""}`}
                                                                                                                                                                ref={provided.innerRef}
                                                                                                                                                                {...provided.draggableProps}
                                                                                                                                                                {...provided.dragHandleProps}
                                                                                                                                                            >
                                                                                                                                                                <div className="types-list__content__items">
                                                                                                                                                                    {
                                                                                                                                                                        editedReason === reason ? (
                                                                                                                                                                            <Input
                                                                                                                                                                                noBreadthRightIcon
                                                                                                                                                                                withSpacingRightIcon
                                                                                                                                                                                width="100%"
                                                                                                                                                                                value={newReasonDesc}
                                                                                                                                                                                onChange={(event) => setNewReasonDesc(event)}
                                                                                                                                                                                rightIcon={
                                                                                                                                                                                    <>
                                                                                                                                                                                        <button
                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                handleReasonUpdated(reason.id_motivo)
                                                                                                                                                                                                setEditedReason(null)
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <IconTickSquare />
                                                                                                                                                                                        </button>

                                                                                                                                                                                        <button onClick={() => setEditedReason(null)}>
                                                                                                                                                                                            <IconCloseSquare />
                                                                                                                                                                                        </button>

                                                                                                                                                                                    </>
                                                                                                                                                                                }
                                                                                                                                                                            />
                                                                                                                                                                        ) : (
                                                                                                                                                                            <>
                                                                                                                                                                                <button
                                                                                                                                                                                    className={`button-drag-drop ${reason === selectedReason ? "active" : ""}`}
                                                                                                                                                                                >
                                                                                                                                                                                    <IconDragDrop />
                                                                                                                                                                                </button>

                                                                                                                                                                                <div className="data-container">
                                                                                                                                                                                    <div className="data-container__text">
                                                                                                                                                                                        <Typography.H4
                                                                                                                                                                                            weight="bold"
                                                                                                                                                                                            color={reason === selectedReason ? "blue-selected-4" : "gray-180"}
                                                                                                                                                                                        >
                                                                                                                                                                                            {reason.desc_motivo}
                                                                                                                                                                                        </Typography.H4>
                                                                                                                                                                                        <Typography.H5
                                                                                                                                                                                            weight="400"
                                                                                                                                                                                            color={reason === selectedReason ? "blue-selected-4" : "gray-180"}
                                                                                                                                                                                            className="sla-information"
                                                                                                                                                                                        >
                                                                                                                                                                                            {reason.desc_sla != null ? (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    {
                                                                                                                                                                                                        reason.desc_sla?.length > 15 ?
                                                                                                                                                                                                            reason.desc_sla?.substring(0, 15).concat("...") : reason.desc_sla
                                                                                                                                                                                                    }

                                                                                                                                                                                                    <IconLittleBall />

                                                                                                                                                                                                    {reason.editavel === true ? "Editável" : "Não editável"}
                                                                                                                                                                                                </>
                                                                                                                                                                                            ) : "Nenhum SLA associado"}
                                                                                                                                                                                        </Typography.H5>
                                                                                                                                                                                        <Typography.H5
                                                                                                                                                                                            weight="400"
                                                                                                                                                                                            color={reason === selectedReason ? "blue-selected-4" : "gray-180"}
                                                                                                                                                                                        >
                                                                                                                                                                                            {reason.qtde_submotivo !== "0" ? (
                                                                                                                                                                                                <>
                                                                                                                                                                                                    {`
                                                                                                                                                                                                ${reason.qtde_submotivo}
                                                                                                                                                                                                submotivo${reason.qtde_submotivo === "1" ? "" : "s"}
                                                                                                                                                                                            `}
                                                                                                                                                                                                </>
                                                                                                                                                                                            ) : "Nenhum submotivo cadastrado"}
                                                                                                                                                                                        </Typography.H5>
                                                                                                                                                                                    </div>

                                                                                                                                                                                    <div className="data-container__menu">
                                                                                                                                                                                        <button className={`icon-more-vertical ${reason === selectedReason ? "active" : ""}`}>
                                                                                                                                                                                            <PopoverMenu
                                                                                                                                                                                                placement="bottom-end"
                                                                                                                                                                                                menuData={renderPopoverMenuReason(reason.id_motivo, reason)}
                                                                                                                                                                                            >
                                                                                                                                                                                                <IconMoreVertical />
                                                                                                                                                                                            </PopoverMenu>
                                                                                                                                                                                        </button>

                                                                                                                                                                                        <button
                                                                                                                                                                                            ref={reasonRef}
                                                                                                                                                                                            className={`icon-arrow-up ${reason === selectedReason ? "active" : ""}`}
                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                handleReasonClick(reason)

                                                                                                                                                                                                setSelectedReasonName(reason.desc_motivo)
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <IconArrowUp />
                                                                                                                                                                                        </button>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            </>
                                                                                                                                                                        )}
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        )}

                                                                                                                                                    </Draggable>
                                                                                                                                                )
                                                                                                                                            })}
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </Droppable>
                                                                                                                    </div>
                                                                                                                </DragDropContext>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )}

                                                                                {subreasonsLoading ? (
                                                                                    <div className="conatiner-emptyState-subreason">
                                                                                        <section className="typ-management-empty-state-subreason">
                                                                                            <Loading.Paperplane className="loading-list-reason" />
                                                                                        </section>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            selectedReason && selectedReason.subreasons?.length === 0 ? (
                                                                                                <>
                                                                                                    <div className="conatiner-emptyState-subreason">
                                                                                                        <header className="typ-container-section__right__header">
                                                                                                            <div className="title">
                                                                                                                <Typography.H2 color="gray-180" weight="bold">
                                                                                                                    {selectedReasonName}
                                                                                                                </Typography.H2>
                                                                                                            </div>

                                                                                                            <div className="options">
                                                                                                                <button>
                                                                                                                    <IconSearch />
                                                                                                                </button>

                                                                                                                <button onClick={() => setIsOpenModalAddSubreason(true)}>
                                                                                                                    <IconAddType />
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </header>

                                                                                                        <section className="typ-management-empty-state-subreason">
                                                                                                            <div className="typ-management-empty-state-subreason__icon">
                                                                                                                <IconInfo />
                                                                                                            </div>

                                                                                                            <Typography.H4 weight="500" color="gray-180">
                                                                                                                Nenhum submotivo associado a esse motivo
                                                                                                            </Typography.H4>
                                                                                                        </section>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {
                                                                                                        selectedReason && selectedReason.subreasons?.length > 0 ? (
                                                                                                            <div className="typ-container-section__right">
                                                                                                                {subreasonInputVisible ? (
                                                                                                                    <Input
                                                                                                                        placeholder="Buscar"
                                                                                                                        inputRef={inputRefSubreason}
                                                                                                                        className="search-types"
                                                                                                                        onChange={(event) => setSearchSubreasons(event)}
                                                                                                                        rightIcon={
                                                                                                                            <><IconSearch /></>
                                                                                                                        }
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <header className="typ-container-section__right__header">
                                                                                                                        <div className="title">
                                                                                                                            <Typography.H2 color="gray-180" weight="bold">
                                                                                                                                {selectedReasonName}
                                                                                                                            </Typography.H2>
                                                                                                                        </div>

                                                                                                                        <div className="options">
                                                                                                                            <button onClick={handleVisibleInputSubreason}>
                                                                                                                                <IconSearch />
                                                                                                                            </button>

                                                                                                                            <button onClick={() => setIsOpenModalAddSubreason(true)}>
                                                                                                                                <IconAddType />
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </header>
                                                                                                                )}

                                                                                                                <DragDropContext>
                                                                                                                    <div className="types-list">
                                                                                                                        <Droppable droppableId="types" type="subreason">
                                                                                                                            {(provided) => (
                                                                                                                                <div
                                                                                                                                    className="droppable"
                                                                                                                                    ref={provided.innerRef}
                                                                                                                                    {...provided.droppableProps}
                                                                                                                                >

                                                                                                                                    {filterSubreasons?.map((subreason, subreasonIndex) => {
                                                                                                                                        return (
                                                                                                                                            <Draggable
                                                                                                                                                key={subreason.id_subreason}
                                                                                                                                                draggableId={subreason.id_subreason}
                                                                                                                                                index={subreasonIndex}
                                                                                                                                                type="subreason"
                                                                                                                                            >
                                                                                                                                                {(provided) => (
                                                                                                                                                    <div
                                                                                                                                                        className="types-list__content"
                                                                                                                                                        ref={provided.innerRef}
                                                                                                                                                        {...provided.draggableProps}
                                                                                                                                                        {...provided.dragHandleProps}
                                                                                                                                                    >
                                                                                                                                                        <div className="types-list__content__items">
                                                                                                                                                            {
                                                                                                                                                                editedSubreason === subreason ? (
                                                                                                                                                                    <Input
                                                                                                                                                                        noBreadthRightIcon
                                                                                                                                                                        withSpacingRightIcon
                                                                                                                                                                        width="100%"
                                                                                                                                                                        value={newSubreasonDesc}
                                                                                                                                                                        onChange={(event) => setNewSubreasonDesc(event)}
                                                                                                                                                                        rightIcon={
                                                                                                                                                                            <>
                                                                                                                                                                                <button
                                                                                                                                                                                    onClick={() => {
                                                                                                                                                                                        handleSubreasonUpdated(subreason.id_submotivo)
                                                                                                                                                                                        setEditedSubreason(null)
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <IconTickSquare />
                                                                                                                                                                                </button>

                                                                                                                                                                                <button onClick={() => setEditedSubreason(null)}>
                                                                                                                                                                                    <IconCloseSquare />
                                                                                                                                                                                </button>

                                                                                                                                                                            </>
                                                                                                                                                                        }
                                                                                                                                                                    />
                                                                                                                                                                ) : (
                                                                                                                                                                    <>
                                                                                                                                                                        <button
                                                                                                                                                                            className="button-drag-drop"
                                                                                                                                                                        >
                                                                                                                                                                            <IconDragDrop />
                                                                                                                                                                        </button>

                                                                                                                                                                        <div className="data-container">
                                                                                                                                                                            <div className="data-container__text">
                                                                                                                                                                                <Typography.H4
                                                                                                                                                                                    weight="bold"
                                                                                                                                                                                    color="gray-180"
                                                                                                                                                                                >
                                                                                                                                                                                    {subreason.desc_submotivo}
                                                                                                                                                                                </Typography.H4>
                                                                                                                                                                                <Typography.H5
                                                                                                                                                                                    weight="400"
                                                                                                                                                                                    color={"gray-180"}
                                                                                                                                                                                    className="sla-information"
                                                                                                                                                                                >
                                                                                                                                                                                    {subreason.desc_sla != null ? (
                                                                                                                                                                                        <>
                                                                                                                                                                                            {
                                                                                                                                                                                                subreason.desc_sla?.length > 15 ?
                                                                                                                                                                                                    subreason.desc_sla?.substring(0, 15).concat("...") : subreason.desc_sla
                                                                                                                                                                                            }

                                                                                                                                                                                            <IconLittleBall />

                                                                                                                                                                                            {subreason.editavel === true ? "Editável" : "Não editável"}
                                                                                                                                                                                        </>
                                                                                                                                                                                    ) : "Nenhum SLA associado"}
                                                                                                                                                                                </Typography.H5>
                                                                                                                                                                            </div>

                                                                                                                                                                            <div className="data-container__menu">
                                                                                                                                                                                <button className="icon-more-vertical">
                                                                                                                                                                                    <PopoverMenu
                                                                                                                                                                                        placement="bottom-end"
                                                                                                                                                                                        menuData={renderPopoverMenuSubreason(subreason.id_submotivo, subreason)}
                                                                                                                                                                                    >
                                                                                                                                                                                        <IconMoreVertical />
                                                                                                                                                                                    </PopoverMenu>
                                                                                                                                                                                </button>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    </>
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )}
                                                                                                                                            </Draggable>
                                                                                                                                        )
                                                                                                                                    })}
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </Droppable>
                                                                                                                    </div>
                                                                                                                </DragDropContext>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {selectedTypeId && selectedType.arrayOfReasons && (
                                                                                                                    <section
                                                                                                                        className="typ-management-empty-state-subreason"
                                                                                                                        style={{ width: "35%" }}
                                                                                                                    >
                                                                                                                        <div className="typ-management-empty-state-subreason__icon">
                                                                                                                            <IconInfo />
                                                                                                                        </div>

                                                                                                                        <Typography.H4 weight="500" color="gray-180">
                                                                                                                            Nenhum motivo foi selecionado
                                                                                                                        </Typography.H4>
                                                                                                                    </section>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </section>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <section className="typ-management-empty-state">
                                                                <div className="typ-management-empty-state__icon">
                                                                    <IconInfo />
                                                                </div>

                                                                <Typography.H4 weight="500" color="gray-180">
                                                                    Nenhum tipo foi cadastrado.
                                                                </Typography.H4>

                                                                <Button
                                                                    width="150px"
                                                                    className="typ-management-empty-state__button-create-type"
                                                                    onClick={() => setIsOpenModalAddType(true)}
                                                                >
                                                                    Cadastrar tipo
                                                                </Button>
                                                            </section>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </>
                    )
                }
            </main>

            <Modal.CreateType
                isOpen={isOpenModalAddType}
                reasonListValues={reasonListValues}
                treeData={treeData}
                organizationId={organizationId}
                typesLoading={typesLoading}
                setTypesLoading={setTypesLoading}
                setShouldReloadTypes={setShouldReloadTypes}
                setReasonListValues={setReasonListValues}
                onCreateReason={getListOfReasonsForAddingType}
                onClose={(event) => setIsOpenModalAddType(event)}
            />

            <Modal.UpdateType
                isOpen={isOpenModalUpdateType.modal}
                isOpenModalUpdateType={isOpenModalUpdateType}
                reasonListValues={reasonListValues}
                treeData={treeData}
                organizationId={organizationId}
                typesLoading={typesLoading}
                setTypesLoading={setTypesLoading}
                setShouldReloadTypes={setShouldReloadTypes}
                onCreateReason={getListOfReasonsForAddingType}
                onClose={(event) => {
                    setIsOpenModalUpdateType({ ...isOpenModalUpdateType, modal: event })
                }}
            />

            <Modal.AssociatiTypeReason
                selectedTypeId={selectedTypeId}
                isOpen={isOpenModalAddReason}
                reasonListValues={reasonListValues}
                treeData={treeData}
                organizationId={organizationId}
                valuesSelectedType={valuesSelectedType}
                setReasonListValues={setReasonListValues}
                setTreeData={setTreeData}
                onCreateReason={getListOfReasonsForAddingType}
                onClose={(event) => setIsOpenModalAddReason(event)}
            />

            <Modal.AssociatiSubreason
                isOpen={isOpenModalAddSubreason}
                selectedReasonId={selectedReasonId}
                selectedTypeId={selectedTypeId}
                treeData={treeData}
                organizationId={organizationId}
                valuesSelectedReason={valuesSelectedReason}
                selectedType={selectedType}
                setTreeData={setTreeData}
                onClose={(event) => setIsOpenModalAddSubreason(event)}
            />

            <Modal.UpdateReason
                isOpen={isOpenModalUpdateReason.modal}
                reasonData={isOpenModalUpdateReason}
                setReasonData={setIsOpenModalUpdateReason}
                selectedTypeId={selectedTypeId}
                treeData={treeData}
                organizationId={organizationId}
                setTreeData={setTreeData}
                onClose={(event) => {
                    setIsOpenModalUpdateReason({ ...isOpenModalUpdateReason, modal: event });
                }}
            />

            <Modal.UpdateSubreason
                isOpen={isOpenModalUpdateSubreason.modal}
                subreasonData={isOpenModalUpdateSubreason}
                setSubreasonData={setIsOpenModalUpdateSubreason}
                selectedTypeId={selectedTypeId}
                selectedReasonId={selectedReasonId}
                treeData={treeData}
                organizationId={organizationId}
                setTreeData={setTreeData}
                onClose={(event) => {
                    setIsOpenModalUpdateSubreason({ ...isOpenModalUpdateSubreason, modal: event })
                }}
            />

            <Modal.DeleteTypeSolicitation
                typeManagement
                modalTitleText={"Tipo"}
                isOpen={isOpenModalDeleteType}
                organizationId={organizationId}
                typesLoading={typesLoading}
                setTypesLoading={setTypesLoading}
                setShouldReloadTypes={setShouldReloadTypes}
                onClose={(event) => setIsOpenModalDeleteType(event)}
            />

            <Modal.DeleteReason
                isOpen={isOpenModalDeleteReason}
                selectedType={selectedType}
                treeData={treeData}
                organizationId={organizationId}
                setTreeData={setTreeData}
                onClose={(event) => setIsOpenModalDeleteReason(event)}
            />

            <Modal.DeleteSubreason
                isOpen={isOpenModalDeleteSubreason}
                selectedReason={selectedReason}
                selectedType={selectedType}
                treeData={treeData}
                organizationId={organizationId}
                setTreeData={setTreeData}
                onClose={(event) => setIsOpenModalDeleteSubreason(event)}
            />
        </>
    )
}

