import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Loading, Modal } from "./../../../feedback";
import { Button, Typography } from "../../../general";
import { FieldContainer } from "../../../data-display";
import { Input, Select } from "../../../data-entry";
import { Toast } from "../../../../utils/toast";

import * as APITypeManagement from "./../../../../services/api/typeManagement";
import * as APISla from "./../../../../services/api/sla";

import "./update-reason.scss"

export default function UpdateReason({
    isOpen,
    onClose,
    selectedTypeId,
    treeData,
    setTreeData,
    organizationId,
    reasonData,
    setReasonData
}) {
    const token = useSelector((state) => state.auth.token);

    const [pageStatus, setPageStatus] = useState("requesting");
    const [listSla, setListSla] = useState([]);

    const handleRadioChange = (event) => {
        const value = event.target.value === "yes" ? true : false;

        const updatedReasonValues = { ...reasonData.reasonValues };

        for (const key in updatedReasonValues) {
            if (key === "editavel") {
                updatedReasonValues[key] = value
            }
        }

        setReasonData(prevValues => ({
            ...prevValues,
            reasonValues: updatedReasonValues
        }))
    };

    function onCloseCustom() {
        onClose();
    };

    async function getListSla() {
        const response = await APISla.listSla(token);

        const data = response.data;
        let updatedData = [];

        data.forEach((sla) => {
            let updateSla = {
                id: sla.id_sla,
                label: sla.desc_sla,
            };

            updatedData.push(updateSla);
        });

        setListSla(updatedData);
    };

    async function updateReasonDescription(reasonId) {
        setPageStatus("updating-reason");

        const values = {
            id_motivo: Number(reasonId),
            desc_motivo: reasonData.reasonDesc
        };

        const response = await APITypeManagement.updateReason(token, values);

        if (selectedTypeId) {
            if (response.status === 200) {
                const updatedTypes = treeData.types?.map((type) => {
                    if (type.id_solicitation === selectedTypeId) {
                        const updatedReasons = type.arrayOfReasons?.map(
                            (reason) => reason.id_motivo === reasonId ?
                                {
                                    desc_motivo: reasonData.reasonDesc,
                                    id_motivo: reasonId.toString(),
                                    subreasons: reason.subreasons,
                                    id_sla: reason.id_sla,
                                    desc_sla: reason.desc_sla,
                                    editavel: reason.editavel,
                                    qtde_submotivo: reason.qtde_submotivo,
                                } : reason
                        );

                        return { ...type, arrayOfReasons: updatedReasons }
                    }

                    return type
                });

                onCloseCustom();
                setTreeData((prevData) => ({
                    ...prevData,
                    types: updatedTypes
                }));


                Toast(
                    "Motivo atualizado!",
                    "Motivo atualizado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível atualizar o Motivo!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                )
            }
        }

        setPageStatus("awaiting");
    }

    async function updateSlaAssociatedWithReason(reasonId) {
        setPageStatus("updating-reason");

        const SLAValues = {
            id_tipo_solicitacao: Number(selectedTypeId),
            id_motivo: Number(reasonId),
            desc_motivo: reasonData.reasonDesc,
            id_sla: reasonData.reasonValues?.id_sla,
            editavel: reasonData.reasonValues?.editavel,
            id_organizacao: organizationId && organizationId
        };

        const ValuesWithoutSLA = {
            id_motivo: Number(reasonId),
            desc_motivo: reasonData.reasonDesc
        };

        const SLAResponse = await APITypeManagement.updateSlaAssociatedWithReasonV2(token, SLAValues);
        await APITypeManagement.updateReason(token, ValuesWithoutSLA);

        if (selectedTypeId) {
            if (SLAResponse.status === 200) {
                const updatedTypes = treeData.types?.map((type) => {
                    if (type.id_solicitation === selectedTypeId) {
                        const updatedReasons = type.arrayOfReasons?.map(
                            (reason) => reason.id_motivo === reasonId ?
                                {
                                    desc_motivo: reasonData.reasonDesc,
                                    id_motivo: reasonId.toString(),
                                    subreasons: reason.subreasons,
                                    id_sla: reasonData.reasonValues?.id_sla,
                                    desc_sla: reasonData.reasonValues?.desc_sla,
                                    editavel: reasonData.reasonValues?.editavel,
                                    qtde_submotivo: reason.qtde_submotivo,
                                } : reason
                        );

                        return { ...type, arrayOfReasons: updatedReasons }
                    }

                    return type
                });

                onCloseCustom();
                setTreeData((prevData) => ({
                    ...prevData,
                    types: updatedTypes
                }));


                Toast(
                    "Motivo atualizado!",
                    "Motivo atualizado com sucesso.",
                    "success"
                );
            } else {
                Toast(
                    "Não foi possível atualizar o Motivo!",
                    "Verifique sua conexão e tente novamente mais tarde.",
                    "error"
                )
            }
        }

        setPageStatus("awaiting");
    }

    useEffect(() => {
        if (isOpen) {
            getListSla();
        }
    }, [isOpen]);

    return (
        <Modal.Main
            className="modal-update-reason"
            title="Editar motivo"
            width="600px"
            height={reasonData.reasonValues?.id_sla != null ? "390px" : "230px"}
            isOpen={isOpen}
            onClose={onCloseCustom}
            shouldCloseOnOverlayClick={false}
            footer={
                <>
                    <Button
                        className="modal-update-reason__btn-cancel"
                        onClick={() => onCloseCustom()}
                        disabled={pageStatus === "updating-reason"}
                    >
                        <Typography.H4 weight={"bold"} color="red-2">
                            Cancelar
                        </Typography.H4>
                    </Button>

                    <Button
                        className="modal-update-reason__btn-confirm"
                        disabled={pageStatus === "updating-reason"}
                        onClick={() => {
                            if (reasonData.reasonValues?.id_sla !== null) {
                                updateSlaAssociatedWithReason(
                                    reasonData.reasonValues.id_motivo
                                )
                            } else {
                                updateReasonDescription(
                                    reasonData.reasonValues.id_motivo
                                )
                            }
                        }}
                    >
                        {pageStatus !== "updating-reason" ? (
                            <Typography.H4 weight={"bold"}>Salvar</Typography.H4>
                        ) : (
                            <Loading.Dots />
                        )}
                    </Button>
                </>
            }
        >
            <section
                className="modal-update-reason__content"
                style={reasonData.reasonValues?.id_sla != null ? { height: "255px" } : { height: "95px" }}
            >
                <FieldContainer title="Descrição do motivo">
                    <Input
                        placeholder="Digite o nome do seu motivo"
                        value={reasonData.reasonDesc}
                        onChange={(event) =>
                            setReasonData({ ...reasonData, reasonDesc: event })
                        }
                    />
                </FieldContainer>

                {reasonData.reasonValues?.id_sla != null && (
                    <div className="container-other-associations">
                        <FieldContainer title="SLA">
                            <Select
                                placeholder="Selecione o SLA"
                                options={listSla}
                                value={reasonData.reasonValues.id_sla}
                                onSelect={(option) => {
                                    const updatedReasonValues = { ...reasonData.reasonValues };

                                    updatedReasonValues.id_sla = option.id;
                                    updatedReasonValues.desc_sla = option.label;

                                    setReasonData(prevValues => ({
                                        ...prevValues,
                                        reasonValues: updatedReasonValues
                                    }))
                                }}
                            />
                        </FieldContainer>

                        <FieldContainer title="Editável">
                            <div className="editable-field-container">
                                <div className="container-input-radio">
                                    <label htmlFor="yes">
                                        <input
                                            type="radio"
                                            id="yes"
                                            name="options"
                                            value="yes"
                                            onChange={handleRadioChange}
                                            checked={
                                                reasonData.reasonValues.editavel === true
                                            }
                                            disabled={
                                                reasonData.reasonValues.id_sla === null
                                            }
                                        />
                                        Sim
                                    </label>
                                </div>

                                <div className="container-input-radio">
                                    <label htmlFor="no">
                                        <input
                                            type="radio"
                                            id="no"
                                            name="options"
                                            value="no"
                                            onChange={handleRadioChange}
                                            checked={
                                                reasonData.reasonValues.editavel === false
                                            }
                                            disabled={
                                                reasonData.reasonValues.id_sla === null
                                            }
                                        />
                                        Não
                                    </label>
                                </div>
                            </div>
                        </FieldContainer>
                    </div>
                )}
            </section>
        </Modal.Main>
    )
}
