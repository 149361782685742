//GLOBAL - components from npm
import React from "react";

//STYLES
import "./filter-list.scss";

//COMPONENTS
import { TagChips } from "../../data-display";
import { Typography } from "../../general";

//SERVICES - api, conectors...

//GLOBAL STATE - redux, env...

//ASSETS - icons, images...

export default function FilterList({
  className = "",
  label = "Label:",
  filtersList = [
    {
      id: 1,
      label: "button",
    },
    {
      id: 2,
      label: "button2",
    },
  ],
  filtersActive = [
    {
      id: 1,
      label: "button",
    },
  ],
  setFiltersActive = () => null,
  allButton = true,
  allButtonCustomLabel,
}) {
  //GENERAL

  //STATES

  //REDUX - Selectors

  //FUNCTIONS

  //USE EFFECTS

  return (
    <div className={`filter-list ${className}`}>
      <Typography.H5
        color="gray-180"
        weight={400}
        className="filter-list__title"
      >
        {label}
      </Typography.H5>
      <div className="filter-list__tags">
        {allButton && (
          <TagChips
            selected={filtersActive.length === 0}
            onClick={() => {
              if (filtersActive.length !== 0) {
                setFiltersActive([]);
              }
            }}
          >
            {allButtonCustomLabel ? allButtonCustomLabel : "All"}
          </TagChips>
        )}

        {filtersList.map((filterCurrent, key) => {
          return (
            <TagChips
              key={key}
              selected={filtersActive.some(
                (filter) => filter.id === filterCurrent.id
              )}
              onClick={() => {
                if (
                  filtersActive.some((filter) => filter.id === filterCurrent.id)
                ) {
                  let updatedfiltersActiveState = [...filtersActive];
                  const filterIndex = updatedfiltersActiveState.findIndex(
                    (item) => item.id === filterCurrent.id
                  );
                  updatedfiltersActiveState.splice(filterIndex, 1);
                  setFiltersActive(updatedfiltersActiveState);
                } else {
                  setFiltersActive([...filtersActive, filterCurrent]);
                }
              }}
            >
              {filterCurrent.label}
            </TagChips>
          );
        })}
      </div>
    </div>
  );
}
